import { parseObjectToKeyPath } from 'utils/payload';
import { get, set } from 'lodash';
import { parseKeyPathToObject } from './payload';

export function removeItemFromFieldList<T>(prefix: string, index: number, values: T): T {
  const fullPrefix = `${prefix}[${index}]`;

  const item = get(values, prefix);
  // mm3 case
  if (Array.isArray(item)) {
    if (item[index]?.id) {
      set(values as unknown as object, `${fullPrefix}._destroy`, true);
    } else {
      item.splice(index, 1);
      set(values as unknown as object, `${prefix}`, item);
    }
    return values;
  }

  // Needed to an existing phone in BE
  if (values[`${fullPrefix}.id`]) {
    const parsedValues = { ...values };
    parsedValues[`${fullPrefix}._destroy`] = true;

    return parsedValues as T;
  }

  let parsedValues = parseKeyPathToObject(values) as T;
  const list = get(parsedValues, prefix);

  if (!Array.isArray(list)) {
    return values;
  }

  const remainingValues = list.filter((_, i) => i !== index);

  // fix for cases like phones[0].label and social_media_profiles[0].label
  if (remainingValues.length === 0) {
    delete parsedValues[prefix];
  } else {
    parsedValues = { ...parsedValues, [prefix]: remainingValues };
  }

  return parseObjectToKeyPath(parsedValues || {}) as T;
}
