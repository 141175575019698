import { merge } from 'lodash';
import { IJsonSchemaSpec } from '@mediafellows/chipmunk';

import { IDeepPartial } from 'types';
import { FormStateActionType } from 'helpers/form/state';

import { IUpdateFormActions } from './actions';
import { IFormState, ITouched } from './types';
import { deepFillFields, touchedFieldsReducer, valuesReducer } from './utils';

const combineContexts = (
  appContext: IJsonSchemaSpec,
  customContext: IDeepPartial<IJsonSchemaSpec>,
): IDeepPartial<IJsonSchemaSpec> => {
  return merge(appContext || {}, customContext || {});
};

export const formReducer = <T>(state: IFormState<T>, action: IUpdateFormActions<T>): IFormState<T> => {
  switch (action.type) {
    case FormStateActionType.UpdateSchema:
      const schema = combineContexts(action.schema, state.schema);

      return {
        ...state,
        schema,
      };

    case FormStateActionType.UpdateValues:
      return valuesReducer(state, action);

    case FormStateActionType.SetFields:
      return { ...state, values: action.values };

    case FormStateActionType.UpdateTouched:
      return touchedFieldsReducer(state, action);

    default:
      return state;
  }
};

export const formStateInit = <T>(initialData: T): IFormState<T> => {
  return {
    schema: {},
    customSchema: {},
    values: initialData,
    // on edit mode (the entity has an id) we want to mark all fields as touched so error messages would be displayed by default
    fieldsTouched: deepFillFields<T, ITouched>(initialData, { touched: Boolean(initialData?.['id']) }),
  };
};
