import React from 'react';

import DateElement from 'components/date-list-element/date-list-element';

import { ILoginTableItem } from './types';
import { formatDuration } from 'utils/date';
import { AnalyticsContact, AnalyticsOrganization, UserTerritory, AnalyticsContext, UserAgent } from 'utils/ui';

export const ContactLoginColumns: { title: string; renderer: (data: ILoginTableItem) => React.ReactNode }[] = [
  { title: 'Contact', renderer: ({ user }) => <AnalyticsContact user={user} /> },
  {
    title: 'Organization',
    renderer: ({ user }) => <AnalyticsOrganization user={user} />,
  },
  {
    title: 'Country (IP country)',
    renderer: ({ user, country }) => {
      return <UserTerritory countryId={user.country_id} geoCountry={country} />;
    },
  },
  { title: 'Active Time', renderer: ({ total_sec_duration }) => formatDuration(total_sec_duration, 'seconds') },
  { title: 'Login context', renderer: ({ group }) => <AnalyticsContext group={group} /> },
  { title: 'Device info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  { title: 'Logged in at', renderer: ({ visit_start }) => <DateElement date={visit_start} /> },
  { title: 'Logged out at', renderer: ({ visit_end }) => <DateElement date={visit_end} /> },
];
