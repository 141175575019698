import React, { useCallback } from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormInput, FieldListV2, FormMultiInput } from 'helpers/form';

import type { IOrganizationFormPart } from './types';

export const financialsDataKeys = ['tax_number', 'vat_number', 'vat_rate', 'withholding_tax_rate', 'bank_accounts'];

export const FormFinancials: React.FC<IOrganizationFormPart> = ({ formHandlers, formData, formValues }) => {
  const banksProps = useCallback(
    () => ({
      form: { data: formData, handlers: formHandlers },
      large: true,
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'iban', label: 'IBAN' },
        { key: 'swift_bic', label: 'SWIFT-BIC' },
        { key: 'account_number', label: 'Account Number' },
        { key: 'sort_code', label: 'Sort Code' },
        { key: 'description', label: 'Description' },
      ],
    }),
    [formData, formHandlers],
  );

  return (
    <>
      <FormSectionWrapperV2
        title="Financial"
        className="entity-detail__infobox-wrapper"
        wrapperClassName="py-3"
        withBorder
      >
        <FormInput name="tax_number" type="text" label="Tax Number" {...formHandlers} {...formData.tax_number} />
        <FormInput name="vat_number" type="text" label="VAT Number" {...formHandlers} {...formData.vat_number} />
        <FormInput name="vat_rate" type="text" label="VAT Rate" {...formHandlers} {...formData.vat_rate} />
        <FormInput
          name="withholding_tax_rate"
          type="text"
          label="Withholding Tax Rate"
          {...formHandlers}
          {...formData.withholding_tax_rate}
        />
      </FormSectionWrapperV2>

      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
        <FieldListV2
          prefix="bank_accounts"
          label="Banks"
          component={FormMultiInput}
          fieldProps={banksProps}
          formValues={formValues}
          formHandlers={formHandlers}
        />
      </FormSectionWrapperV2>
    </>
  );
};
