import { Pages } from 'utils/actions/types';
import { IShouldDisplayAccessPrivilegeActionFunction } from './types';
import { areAllNotDeleted } from 'utils/is-entity-deleted';

export const shouldDisplayEmail: IShouldDisplayAccessPrivilegeActionFunction = (action, index, user, entities) => {
  return Boolean(entities?.length === 1 && areAllNotDeleted(entities));
};

export const shouldDisplayAccessPrivilege: IShouldDisplayAccessPrivilegeActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.ACCESS_PRIVILEGES_DETAILS, Pages.ACCESS_PRIVILEGES_LIST].includes(options?.page)
  );
};
