import { useEditExpiryAction } from './edit-expiry';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import type { IRecommendationActionConfig } from './types';
import { IRecommendationActionName } from './types';
import { useDeleteAction } from './delete-action';
import { useEditAction } from './edit';
import { useSendAction } from './send-action';
import { useRevokeAction } from './revoke';

export type { IRecommendationActionConfig };
export { useEditAction as useEditRecommendationAction, IRecommendationActionName };

export const useRecommendationActions = (
  entities: McGenericRecommendationProduct[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IRecommendationActionName> => {
  const editAction = useEditAction(entities, options);
  const editExpiry = useEditExpiryAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);
  const sendAction = useSendAction(entities, options);
  const revokeAction = useRevokeAction(entities, options);
  const actions = [editAction, editExpiry, sendAction, revokeAction, deleteAction];

  return useActions(actions as IRecommendationActionConfig[], options, entities as McGenericRecommendationProduct[]);
};
