import React from 'react';
import { IElementDiv } from './types';
import { RichTextEditor } from '@mantine/tiptap';

type IIconProps = React.ComponentPropsWithoutRef<'svg'>;

const IconFullScreen: React.FC = (props: IIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#bfccd6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ padding: '5px' }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M16 4l4 0l0 4" />
      <path d="M14 10l6 -6" />
      <path d="M8 20l-4 0l0 -4" />
      <path d="M4 20l6 -6" />
      <path d="M16 20l4 0l0 -4" />
      <path d="M14 14l6 6" />
      <path d="M8 4l-4 0l0 4" />
      <path d="M4 4l6 6" />
    </svg>
  );
};

const IconEscapeFullScreen: React.FC = (props: IIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#bfccd6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ padding: '5px' }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 9l4 0l0 -4" />
      <path d="M3 3l6 6" />
      <path d="M5 15l4 0l0 4" />
      <path d="M3 21l6 -6" />
      <path d="M19 9l-4 0l0 -4" />
      <path d="M15 9l6 -6" />
      <path d="M19 15l-4 0l0 4" />
      <path d="M15 15l6 6" />
    </svg>
  );
};

export const GoFullScreenButton: React.FC<{
  editorRef: React.RefObject<IElementDiv>;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ editorRef, setIsFullScreen }) => {
  const enterFullscreen = (): void => {
    if (!document.fullscreenElement) {
      const elem = editorRef.current;
      if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
      } else if (elem?.mozRequestFullScreen) {
        elem?.mozRequestFullScreen();
      } else if (elem?.webkitRequestFullscreen) {
        elem?.webkitRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };
  return (
    <RichTextEditor.Control onClick={enterFullscreen} aria-label="Full screen mode" title="Full screen">
      <IconFullScreen />
    </RichTextEditor.Control>
  );
};

export const ExitFullScreenButton: React.FC<{
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsFullScreen }) => {
  const exitFullscreen = (): void => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      }
      setIsFullScreen(false);
    }
  };
  return (
    <RichTextEditor.Control onClick={exitFullscreen} aria-label="Exit full screen mode" title="Exit full screen">
      <IconEscapeFullScreen />
    </RichTextEditor.Control>
  );
};
