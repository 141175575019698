import { ISubtitle } from 'types';

import { updateSubtitle } from 'utils/apis/subtitle';
import { IconAlias } from 'icons';
import { useEditSubtitleDialog } from 'components/asset/edit-subtitle-dialog';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { ISubtitleActionConfig, ISubtitleActionName } from './types';

export const useEditSubtitleAction = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): ISubtitleActionConfig => {
  const subtitle = items[0];
  const [openDialog] = useEditSubtitleDialog({
    onSubmit: async (subtitle: ISubtitle) => {
      await updateSubtitle(subtitle);
      options.onSuccess?.(ISubtitleActionName.EDIT, subtitle);
    },
    subtitle,
  });

  return {
    name: ISubtitleActionName.EDIT,
    icon: IconAlias.EDIT,
    title: 'Edit',
    handler: openDialog,
  };
};
