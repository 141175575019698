import { IUseActionsOptionParam, useActions, IUseActionsResponse } from 'utils/actions';

import { IConnectionActionName, IConnectionActionConfig } from './types';
import { IDeliveryConnection } from 'components/delivery/connections/types';

import { useEditConnectionAction } from './edit';
import { useDeleteConnectionAction } from './delete';
import { useValidateConnectionAction } from './validate';
import { useActivateConnectionAction } from './activate';
import { useDeactivateConnectionAction } from './deactivate';

export const useConnectionActions = (
  connections: IDeliveryConnection[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IConnectionActionName> => {
  const editAction = useEditConnectionAction(connections[0], options);
  const deleteAction = useDeleteConnectionAction(connections, options);
  const validateAction = useValidateConnectionAction(connections, options);
  const activateAction = useActivateConnectionAction(connections, options);
  const deActivateAction = useDeactivateConnectionAction(connections, options);

  const actions = [editAction, validateAction, activateAction, deActivateAction, deleteAction];

  return useActions<IDeliveryConnection, IConnectionActionName>(
    actions as IConnectionActionConfig[],
    options,
    connections,
  );
};
