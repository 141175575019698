import { omit, toNumber, capitalize, filter, forEach } from 'lodash';
import { addMinutes } from 'date-fns';
import { IQueryParams, IContact, ItemId, IProduct } from 'types';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import type { IAssetArtifact, ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { emailValidation } from 'utils/validations';
import { queryContacts } from 'utils/apis/contacts';
import { IFormMultiSelectOption } from 'helpers/form';
import { IRecipient } from 'utils/actions/types';
import { IItemId } from 'components/form-selection-items';
import { IWizardStep } from 'components/wizard/utils';

export enum StepIds {
  Recipients,
  Products,
  Assets,
  Summary,
}

interface IParcedRecipients {
  recipient_list: IRecipient[];
  recipients: string[];
}

export const organizationKeys = ['organization_id'];
export const recipientsKeys = ['recipients'];
export const conntectionKeys = ['connection_id'];
export const assetsKeys = ['asset_ids'];
export const productKeys = ['product_ids'];
export const summaryKeys = ['name', 'scheduled_at'];

export const isExternalUser = (id: ItemId): boolean => {
  const [valid] = emailValidation({ allow_blank: false }, `${id}`);
  return valid;
};

export const validateEmail = (query: string): boolean => {
  return isExternalUser(query);
};

export const getTabId = (tab: IItemId): number => {
  if (typeof tab === 'string') {
    tab = capitalize(tab);
    return StepIds[tab];
  }

  return tab;
};

export const fetchRecipients = async ({
  ids,
  q,
  include_deleted = false,
}: IQueryParams): Promise<(IContact | string)[]> => {
  if (!ids?.length) {
    return queryContacts({ q, include_deleted }) as Promise<(IContact | string)[]>;
  }

  const { externalIds, internalIds } = ids.reduce(
    ({ externalIds, internalIds }, id) => {
      if (typeof id === 'string' && isExternalUser(id)) {
        return { internalIds, externalIds: [...externalIds, id] };
      }
      return { externalIds, internalIds: [...internalIds, id] };
    },
    { externalIds: [] as string[], internalIds: [] as string[] },
  );

  const internalUsers = (await queryContacts({ ids: internalIds, q, include_deleted })) as (IContact | string)[];
  return [...internalUsers, ...externalIds];
};

export const parseRecipients = (value: IFormMultiSelectOption[] = []): IParcedRecipients => {
  return value.reduce(
    (acc: IParcedRecipients, item) => {
      if (!item?.value) {
        return acc;
      }
      const { value } = item;

      const recipient_type = isExternalUser(value) ? 'email' : 'user';
      return {
        ...acc,
        recipient_list: [...acc.recipient_list, { recipient_type, recipient_id: `${value}` }],
        recipients: [...acc.recipients, value.toString()],
      };
    },

    { recipient_list: [], recipients: [] } as IParcedRecipients,
  );
};

const fieldsToOmit = ['asset_ids', 'product_ids'];

export function removeInternalAttributes<T extends DeliveriesGenericDeliveryPackage>(
  values: ICreateWorkFlowDeliveryPayload<T>,
): T {
  return omit<T>(values, ...fieldsToOmit) as T;
}

export const parseProductAncestors = (value: IFormMultiSelectOption[] = []): string => {
  return (value as unknown as IProduct)?.ancestry_info?.ancestors.map((val) => val?.product_id).join('/');
};

const findProductId = (assetWithProduct, asset_id: IItemId): number => {
  const productId = assetWithProduct.find((obj) => obj.id == asset_id)?.parent_id;

  return toNumber(productId);
};

export const buildDeliveryContent = (
  values: Partial<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): Partial<DeliveriesGenericDeliveryPackage['delivery_items']> => {
  const { product_ancestry, artefacts, parent_child: asset_product } = values;

  const deliveryObjects = artefacts?.map(({ id, asset_id, overwritten_artefact_name, checked }) => {
    if (checked === false) return;

    return {
      product_id: asset_product ? findProductId(asset_product, asset_id) : null,
      asset_id: toNumber(asset_id),
      artefact_id: toNumber(id),
      overwritten_artefact_name,
      product_ancestry,
    };
  });

  return deliveryObjects as unknown as Partial<DeliveriesGenericDeliveryPackage['delivery_items']>;
};

export const mergeArtifacts = (artifacts, oldArtifacts): IAssetArtifact[] => {
  const newArtefacts = artifacts?.map((artefact) => {
    const foundItem = oldArtifacts?.find((art) => art.artefact_id == artefact.id);
    const foundAsset = oldArtifacts?.find((art) => art.asset_id == artefact.asset_id);

    if (foundItem) {
      const updatedItem = {
        ...artefact,
        ...foundItem,
        checked: true,
      };

      return updatedItem;
    }

    if (foundAsset && !foundItem) {
      const updatedItem = {
        ...artefact,
        checked: false,
      };

      return updatedItem;
    }

    return { ...artefact, checked: true };
  });

  return newArtefacts;
};

export const filteredSteps = (steps: IWizardStep[]): IWizardStep[] => {
  const reducedSteps = filter(steps, (step) => step.id !== StepIds.Products);

  return forEach(reducedSteps, (step, index) => {
    step.id = index;

    return step;
  });
};

export const getFutureDateTime = (min: number): Date => {
  const now = Date.now();
  const currentDate = addMinutes(now, min);

  return currentDate;
};
