import React, { useCallback } from 'react';
import { Popover } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';

import { IQueryParams, ISearchFilter } from 'types';
import { ToastError } from 'components/toast';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';
import { ActionIcon, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';
import { Export } from 'blueprint5-icons';
import { useDisclosure } from '@mantine/hooks';

const EXPORT_LIMIT = 10000;

export const EntityExportButton: React.FC<{
  entity: 'products' | 'assets' | 'contacts';
  apiCall: (params: IQueryParams, filters: ISearchFilter[]) => Promise<void | unknown>;
}> = observer(({ apiCall, entity }) => {
  const { dialogStore, toastStore } = useStore();
  const { dataSectionStore } = useStore();
  const [opened, { close, open }] = useDisclosure(false);

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      const params = dataSectionStore.getParams();

      toastStore.info('Requesting export');
      await apiCall(params, dataSectionStore.getFilters());

      toastStore.clearAll();
      toastStore.success('The export will be generated and sent to your email address');
      dialogStore.close();
    } catch (error) {
      toastStore.clearAll();
      toastStore.error(<ToastError error={error} placeholder="Sorry, Something went wrong..." />);
    }
  }, [apiCall, dataSectionStore, dialogStore, toastStore]);
  const { onSubmit, disabled } = useDisableWhileSubmitting(handleSubmit);

  const totalItems = dataSectionStore?.searchStore.totalCount;
  const cantExport = totalItems > EXPORT_LIMIT;

  const handleClick = (e): void => (cantExport || disabled ? e.preventDefault() : onSubmit(e));

  const exportButton = (
    <Tooltip label={`Export ${entity} list`} position="bottom">
      <ActionIcon
        variant="subtle"
        color="gray.5"
        radius="sm"
        data-disabled={cantExport || disabled}
        className="mx-2"
        onClick={handleClick}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        <MantineIcon icon={<Export />} />
      </ActionIcon>
    </Tooltip>
  );

  if (cantExport) {
    return (
      <Popover withArrow opened={opened} position="top">
        <Popover.Target>{exportButton}</Popover.Target>
        <Popover.Dropdown>
          <div className="p-2">
            This will export data for all {entity} meeting current filter criteria to xlsx.
            <br />
            Lists over 10,000 items cannot be exported.
          </div>
        </Popover.Dropdown>
      </Popover>
    );
  }

  return exportButton;
});
