import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store/hooks';

import { IConference } from 'types';
import { conferenceListSchema } from 'utils/schemas';
import { InFilter, Model, QFilter } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { ConferencePreview } from 'components/conference/conference-preview/conference-preview';
import { ConferenceDataSectionItem } from 'components/conference/conference-data-section-item/conference-data-section-item';
import { ConferenceFilters } from 'components/conference/conference-filters/conference-filters';
import { ConferenceItem } from 'components/conference/conference-item/conference-item';
import { ConferenceActionsList } from 'components/action';

import { useControlDataSection, useRefreshDataSection } from 'utils/hooks';
import { Pages } from 'utils/actions/types';
import { IConferenceActionName } from 'utils/actions/conference/types';
import { DataSectionPage } from 'components/layout';
import { ActionsMenu } from 'components/action/utils';

const defaultFilters = {
  _: new QFilter(''),
  owner_id: new InFilter('owner_id', []),
  status: new InFilter('status', []),
};
const params = {
  model: Model.CONFERENCE,
  presetSuffix: 'conference',
  defaultFilters,
  schema: conferenceListSchema,
  persistFilters: true,
};

const sidebarTabs = {
  preview: <ConferencePreview />,
  filters: <ConferenceFilters />,
  selectedItemRenderer(conference: IConference): React.ReactElement {
    return <ConferenceItem conference={conference} />;
  },
};

export const Conferences: React.FC<{}> = observer(() => {
  useControlDataSection(params);
  const { checked, searchStore } = useDataSectionStore<IConference>();
  const { objects, update } = searchStore || {};

  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action: IConferenceActionName, conference: IConference) => {
      switch (action) {
        case IConferenceActionName.ADD_CONTACTS:
        case IConferenceActionName.ADD_ASSETS:
          if (!objects || !update) {
            return;
          }

          return update({
            objects: objects.map((conf) => {
              return conf.code === conference.code ? { ...conf, ...conference } : conf;
            }),
          });

        case IConferenceActionName.START:
        case IConferenceActionName.RESET:
        case IConferenceActionName.COPY:
        case IConferenceActionName.DELETE:
          return refreshDataSection();
      }
    },
    [objects, refreshDataSection, update],
  );
  const itemRenderer = useCallback(
    (conference: IConference): React.ReactElement => {
      if (!conference) {
        return <></>;
      }

      return (
        <ConferenceDataSectionItem
          key={conference.id}
          conference={conference}
          contextMenu={
            <ActionsMenu
              component={ConferenceActionsList}
              items={[conference]}
              options={{ context: 'single', page: Pages.LIST, onSuccess }}
            />
          }
        />
      );
    },
    [onSuccess],
  );
  return (
    <DataSectionPage>
      <DataSection
        className="contacts__data-section"
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={ConferenceActionsList}
            items={checked}
            options={{ context: 'multi', page: Pages.LIST, onSuccess }}
          />
        }
      />
    </DataSectionPage>
  );
});
