import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';

import { IProduct } from 'types';
import { SortDataSection } from 'components/sort-data-section';
import { useSortDataSectionStore } from 'store/hooks';
import { Model } from 'helpers/filters/types';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';
import { loadAllGroupItems, updateGroupItemsSequenceNumber } from 'utils/apis/groups';
import { ISortableItem } from 'store/sort-data-store';

export function productItemRenderer(product: ISortableItem): JSX.Element {
  if (!product?.id) {
    return <></>;
  }

  return (
    <ProductDataSectionListItem
      product={product as IProduct}
      isAllItemsSelected={false}
      id={product.id}
      active={NaN}
      handleClick={noop}
      isChecked={false}
      contextMenu={<></>}
    />
  );
}

export const GroupProductsSortDataSection: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const { initStore } = useSortDataSectionStore<ISortableItem>();
  const fetcher = useCallback(() => loadAllGroupItems(groupId, 'product_id'), [groupId]);

  const onSave = useCallback(
    async (list) => {
      await updateGroupItemsSequenceNumber(Model.PRODUCT_GROUP_ITEMS, groupId, list);
    },
    [groupId],
  );

  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={productItemRenderer} />;
});

export default GroupProductsSortDataSection;
