import { DetailsPageTabs, IOrganization } from 'types';
import { IOrganizationActionName, IOrganizationActionConfig } from 'utils/actions/organization/types';
import { Intent } from 'utils/ui';

import { shouldDisplayEditAction as shouldDisplay } from './actions-acl';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';

export const useEditAction = (item: IOrganization): IOrganizationActionConfig => {
  const navigate = useNavigate();
  const { tabLevel1 } = useParams<{ tabLevel1: DetailsPageTabs }>();

  const handler = useCallback(() => {
    navigate(`${Routes.ORGANIZATIONS}/${item.id}/${DetailsPageTabs.OVERVIEW}?edit=1`);
  }, [navigate, item]);

  return {
    name: IOrganizationActionName.EDIT,
    shouldDisplay: (...args) => shouldDisplay(...args) && !tabLevel1,
    icon: 'edit',
    title: 'Edit',
    intent: Intent.NONE,
    handler,
  };
};
