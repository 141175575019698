import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormContacts } from 'pages/upsert-organization/form-contacts';
import { FormKeyData } from 'pages/upsert-organization/form-key-data';

import { ScrollWrapper } from 'components/scroll-wrapper';
import { FormAddresses } from 'pages/upsert-organization/form-addresses';
import { FormFinancials } from 'pages/upsert-organization/form-financials';
import { FormDefaults } from 'pages/upsert-organization/form-defaults';
import { IOrganizationFields } from 'pages/upsert-organization/types';
import { IUseFormReturn } from 'helpers/form';

import './style.scss';

const OrganizationEditTab: React.FC<IUseFormReturn<IOrganizationFields>> = observer(
  ({ formData, handlers, values }) => {
    return (
      <ScrollWrapper>
        <form className="h-100">
          <FormKeyData formData={formData} formHandlers={handlers} formValues={values} />
          <FormContacts formData={formData} formHandlers={handlers} formValues={values} />
          <FormAddresses formData={formData} formHandlers={handlers} formValues={values} />
          <FormFinancials formData={formData} formHandlers={handlers} formValues={values} />
          <FormDefaults formData={formData} formHandlers={handlers} formValues={values} />
        </form>
      </ScrollWrapper>
    );
  },
);
export default OrganizationEditTab;
