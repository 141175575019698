import React, { useCallback, useEffect, useState } from 'react';
import { IGroup, IIdentifiable, ItemId } from 'types';
import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions';
import { loadAccessPrivilegeAllUserIds } from 'utils/apis/access-privilege';

import { ScreeningRoomEmailSetup } from 'components/screening-room/screening-room-email-setup';

import { IScreeningRoomActionConfig, IScreeningRoomActionName } from './types';
import { shouldDisplayEmail as shouldDisplay } from './actions-acl';

export const useEmailAction = (
  items: (IGroup & IIdentifiable)[],
  options: IUseActionsOptionParam,
): IScreeningRoomActionConfig => {
  const {
    dialogStore: { openModal, close: closeModal },
  } = useStore();
  const [user_ids, setUserIds] = useState<ItemId[]>([]);
  const group_id = items[0].id;

  useEffect(() => {
    const prefillUsersForm = async (): Promise<void> => {
      const users = await loadAccessPrivilegeAllUserIds(group_id);
      setUserIds(users);
    };

    prefillUsersForm();
  }, [group_id]);

  const body = useCallback(() => {
    const onSuccess = (): void => {
      options?.onSuccess?.();
      closeModal();
    };

    const onFailure = (): void => {
      options?.onFailure?.();
      closeModal();
    };
    return <ScreeningRoomEmailSetup item={items[0]} user_ids={user_ids} onSuccess={onSuccess} onFailure={onFailure} />;
  }, [items, user_ids, closeModal, options]);

  const openDialog = (): void => {
    openModal({
      title: 'Send emails',
      className: 'screening-rooms-email-modal',
      body,
    });
  };

  return {
    name: IScreeningRoomActionName.EMAIL,
    shouldDisplay,
    icon: 'envelope',
    title: 'Email',
    handler: openDialog,
  };
};
