import React, { useMemo, useCallback } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { deliveryPackageConnectionSchema } from 'utils/schemas';
import { Model } from 'helpers/filters/types';

import { DataSection } from 'components/data-section';
import { ConnectionListItem } from 'components/connection-list-item';
import { ConnectionActionsList } from 'components/action';
import { ConnectionPreviewTab } from 'components/connection-preview-tab';
import { Pages } from 'utils/actions/types';
import { IContactActionName, IUseActionsOptionParam } from 'utils/actions';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { useRefreshDataSection } from 'utils/hooks';
import { CreateConnectionButton } from 'components/connection-data-section/create-connection-button';
import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const sidebarTabs = {
  preview: <ConnectionPreviewTab />,
};

interface IConnectionDataSectionProps {
  page: Pages;
  actionsOptions?: Partial<IUseActionsOptionParam>;
  persistentFilters?: IFilterOption[];
  organizationId: string;
}

export const ConnectionDataSection: React.FC<IConnectionDataSectionProps> = observer((props) => {
  const { page = Pages.LIST, actionsOptions, persistentFilters, organizationId } = props;
  const {
    dataSectionStore: { checked },
  } = useStore();

  const refreshDataSection = useRefreshDataSection();

  const controlDataSectionProps = useMemo(
    () => ({
      schema: deliveryPackageConnectionSchema,
      filters: persistentFilters,
      model: Model.CONNECTION,
    }),
    [persistentFilters],
  );

  useControlDataSection(controlDataSectionProps);

  const onSuccess = useCallback(
    async (action: IContactActionName): Promise<void> => {
      actionsOptions?.onSuccess?.(action);
      if (action === IContactActionName.REMOVE_FROM_GROUP) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection, actionsOptions],
  );

  const handleItemRenderer = (connection): React.ReactElement => {
    return (
      <ConnectionListItem
        key={connection.id}
        connection={connection}
        contextMenu={
          <ActionsMenu
            component={ConnectionActionsList}
            items={[connection]}
            options={{ ...(actionsOptions || {}), context: 'single', page, onSuccess }}
          />
        }
      />
    );
  };

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={handleItemRenderer}
      contextMenu={
        <ActionsMenu
          component={ConnectionActionsList}
          items={checked}
          options={{ ...(actionsOptions || {}), context: 'multi', page, onSuccess }}
        />
      }
      headerRightSection={<CreateConnectionButton organizationId={organizationId} />}
      hideSearchButton
    />
  );
});

export default ConnectionDataSection;
