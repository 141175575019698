import React from 'react';
import { ActionIcon } from '@mantine/core';
import { IAnalyticsFiltersProps } from './types';
import { MantineIcon } from 'utils/ui/icon';
import { Export } from 'blueprint5-icons';

export const ExportButton: React.FC<Pick<IAnalyticsFiltersProps, 'exportQuery' | 'exportGlobalAnalytics'>> = ({
  exportGlobalAnalytics,
}) => {
  return (
    <ActionIcon
      color="gray.5"
      radius="sm"
      className="analytics-filters__export-button"
      variant="subtle"
      onClick={exportGlobalAnalytics}
    >
      <MantineIcon icon={<Export />} />
    </ActionIcon>
  );
};
