import React, { useState } from 'react';
import { filter } from 'lodash';
import { ActionIcon, Collapse } from '@mantine/core';

import { Thumbnail } from 'components/thumbnail';
import { ISummaryListViewProps } from 'components/delivery/types';
import { getTotalSize } from 'pages/delivery-details/utils';
import { IAssetArtifact } from 'utils/actions/types';
import { parseDataSize } from 'utils/general';

import { ArtifactItem } from './summary-list-item';
import { MantineIcon } from 'utils/ui/icon';

export const SummaryListView: React.FC<ISummaryListViewProps> = ({ form, asset, removeAsset }) => {
  const { name, classification, preview_image } = asset;
  const artefacts = form?.values.artefacts?.filter((artefact) => artefact.asset_id === asset.id) as IAssetArtifact[];
  const [open, setOpen] = useState(false);
  const checkedArtifacts = filter(artefacts, { checked: true });

  return (
    <div className="entity_summary-list">
      <div className="entity-list-item-row">
        <ActionIcon variant="subtle" color="gray.5" onClick={() => setOpen(!open)}>
          <MantineIcon icon={`${open ? 'chevron-down' : 'chevron-right'}`} />
        </ActionIcon>
        <div className="entity-list-item-row__thumb">
          <Thumbnail image={preview_image?.url} />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title text-nowrap text-truncate">{name}</div>
          <div className="delivery-list-item__attributes bp3-text-muted">
            <div className="entity-list-item-row__classification">{classification}</div>
            <div className="entity-list-item-row__classification">{`${checkedArtifacts.length} / ${artefacts.length} Files`}</div>
            <div className="entity-list-item-row__classification">{parseDataSize(getTotalSize(checkedArtifacts))}</div>
          </div>
        </div>
        <ActionIcon variant="subtle" color="gray.5" onClick={() => removeAsset(asset.id)}>
          <MantineIcon icon="trash" />
        </ActionIcon>
      </div>
      <Collapse in={open}>
        <div className="entity_summary-list__sublist">
          {artefacts.map((artefact) => (
            <ArtifactItem key={artefact.id} form={form} artefact={artefact} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
