import React from 'react';
import cx from 'classnames';

import { getProductSubtitle } from 'components/product/product-translated-title/get-product-translated-title';

import { IProduct } from 'types';

export const ProductSearchTitle: React.FC<{
  product: IProduct;
  isAncestryMode?: boolean;
  className?: string;
  title?: string;
}> = ({ product, isAncestryMode, className, title }) => {
  const { full_title, display_title } = product;
  const mainTitle = title || isAncestryMode ? display_title : full_title;

  const subtitle = getProductSubtitle(product);
  return (
    <div className={cx('d-flex', className)}>
      {subtitle ? (
        <span className="text-truncate">
          <span>{mainTitle}</span>
          <span className="product-list__pipe ms-1 me-1">|</span>
          <span>{subtitle}</span>
        </span>
      ) : (
        <span className="text-truncate">{mainTitle}</span>
      )}
    </div>
  );
};
