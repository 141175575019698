import React, { useCallback } from 'react';
import { upperFirst } from 'lodash';

import { IAnalyticsListProps, IGetAnalyticsTableDataParams } from 'types';
import { ListTable } from 'components/list-table';

import { exportAnalyticsTableData, getDetailedAnalyticsTableData } from 'utils/apis/analytics';
import * as listColumns from './columns';
import './style.scss';

const VideoScreeningsListTable: React.FC<IAnalyticsListProps> = ({
  title,
  entity,
  queryName,
  entityId,
  dateRange,
  geoScopeIds,
  userIds,
  productIds,
  assetIds,
  includeDescendants,
  columns,
}) => {
  const columnsToApply = columns ?? listColumns[upperFirst(`${entity}Columns`)];

  const getTableData = useCallback((params: IGetAnalyticsTableDataParams) => {
    return getDetailedAnalyticsTableData(params, true);
  }, []);

  return (
    <ListTable
      entity={entity}
      title={title}
      entityId={entityId}
      query_name={queryName}
      from_date={dateRange[0]}
      to_date={dateRange[1]}
      geo_scope_ids={geoScopeIds}
      user_ids={userIds}
      product_ids={productIds}
      asset_ids={assetIds}
      include_descendants={includeDescendants}
      columns={columnsToApply}
      getTableData={getTableData}
      exportTableData={exportAnalyticsTableData}
    />
  );
};

export default VideoScreeningsListTable;
