import { IQueryParams } from 'types';

import { getSuggestions } from 'helpers/form/fields/form-select';

export const getCommissioning = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.commissioning_broadcasters');

export const getExternalProducers = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.produced_by_external');

export const getCoProducers = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.co_producers');
