import { IUseFormReturn } from 'helpers/form';
import React from 'react';
import { IConference, IAsset } from 'types';

import { ConferenceOverviewEditTab } from './conference-overview-edit-tab';
import { ConferenceOverviewPreviewTab } from './conference-overview-tab';

export interface IConferenceOverviewTabProps {
  conferenceForm: IUseFormReturn<IConference>;
  assets: IAsset[];
  conference: IConference;
  editModeEnabled: boolean;
}

export const ConferenceOverviewTab: React.FC<IConferenceOverviewTabProps> = ({
  assets,
  conference,
  conferenceForm,
  editModeEnabled,
}) => {
  if (editModeEnabled) {
    return <ConferenceOverviewEditTab conferenceForm={conferenceForm} />;
  }

  return <ConferenceOverviewPreviewTab conference={conference} assets={assets} />;
};
