import React, { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';
import { ToastError } from 'components/toast';

import { deleteCastCrewPreviewImage } from './api';
import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { shouldDisplayForSingleItem } from './acl';

export const useDeletePreviewImageAction = (
  items: PmGenericCastCrew[],
  options: IUseActionsOptionParam,
): ICastCrewActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const item = items[0];
      const updatedCastCrew = await deleteCastCrewPreviewImage(item);
      toastStore.success('Success');
      options?.onSuccess?.(ICastCrewActionName.DELETE_PREVIEW, { ...item, ...updatedCastCrew });
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: 'Delete Avatar',
      body: 'Are you sure you want to delete this avatar?',
      onConfirm,
    });
  };

  return {
    name: ICastCrewActionName.DELETE_PREVIEW,
    shouldDisplay: shouldDisplayForSingleItem,
    icon: 'delete',
    title: 'Delete avatar',
    intent: Intent.DANGER,
    handler,
  };
};
