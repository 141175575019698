import React from 'react';
import { observer } from 'mobx-react-lite';
import { Thumbnail } from 'components/thumbnail';

import { getEntityType, formatAssetType } from 'utils/general';
import { IAsset, IMm3Asset } from 'types';
import { Classes } from 'utils/ui';

import './style.scss';

const AssetSelectedTabItem: React.FC<{
  asset: IAsset | IMm3Asset;
  customParser?: (entity?: IAsset | IMm3Asset, entityType?: string) => string;
}> = observer(({ asset, customParser }) => {
  const { name, classification, preview_image } = asset;
  const assetType = getEntityType(classification);

  return (
    <div className={'asset-selected-tab-item'}>
      <div className="asset-selected-tab-item__thumb px-2">
        <Thumbnail image={preview_image?.url} showType assetType={assetType} customTag={customParser?.(asset)} />
      </div>
      <div className="asset-selected-tab-item__content">
        <div className="asset-selected-tab-item__title text-truncate mb-1">{name}</div>
        <div className="d-flex">
          <span className={Classes.TEXT_MUTED}>{formatAssetType(classification)}</span>
        </div>
      </div>
    </div>
  );
});

export default AssetSelectedTabItem;
