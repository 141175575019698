import { ITreeNode } from '@blueprintjs/core';
import { IJsonLDSpec, IJsonSchemaSpec, IResult } from '@mediafellows/chipmunk';
import { action, IObservableArray, observable } from 'mobx';
import { ITreeDataProvider } from './tree-data-provider';

export interface ISimpleTreeNode {
  value: string | number;
  label: string;
  ancestry: string;
}

export type IDataProviderExecutor = () => Promise<IResult | IJsonLDSpec | IJsonSchemaSpec>;
export type IDataProviderMapper = (data: IResult | IJsonLDSpec | IJsonSchemaSpec) => ITreeNode[];

export class DynamicTreeDataProvider implements ITreeDataProvider {
  @observable loading = false;

  @observable
  public data: IObservableArray<ITreeNode> = observable([]);

  protected dataLoaded = false;

  constructor(protected executor: IDataProviderExecutor, protected mapper: IDataProviderMapper) {}

  @action async init(): Promise<void> {
    if (!this.dataLoaded) {
      await this.loadData();
    }
  }

  @action expandNode(nodePath: number[]): void {
    const treeNode = this.getNodeByPath(this.data, nodePath);
    treeNode.isExpanded = true;
  }

  @action collapseNode(nodePath: number[]): void {
    const treeNode = this.getNodeByPath(this.data, nodePath);
    treeNode.isExpanded = false;
  }

  async loadData(): Promise<void> {
    this.loading = true;
    const result = await this.executor();
    const mappedResult = (this.mapper(result) || []).map((n) => ({ ...n, isExpanded: true })); // initially expand first level
    this.data.replace(mappedResult);

    this.dataLoaded = true;
    this.loading = false;
  }

  protected getNodeByPath(nodes: ITreeNode[], nodePath: number[]): ITreeNode {
    const currentPath = nodePath[0];
    if (nodePath.length === 1) {
      return nodes[currentPath];
    }

    return this.getNodeByPath(nodes[currentPath].childNodes || [], nodePath.slice(1));
  }
}
