import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from '@mantine/core';

import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { ICustomSortMenuProp } from 'components/data-section';
import { ParametronStore } from 'store/parametron-store';
import { fetchNews } from 'utils/apis/news';
import { byId } from 'utils/general';
import { useInterval } from 'utils/hooks/use-interval';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { useDataSectionStore } from 'store/hooks';

import cx from 'classnames';

export const defaultParams = {
  sort: ParametronSort.DATE,
  order: ParametronOrder.DESCENDING,
};

export const getSortMenu: ICustomSortMenuProp = (sort, handleSort) => (
  <Menu>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.DATE}
      className={cx({ active: sort === ParametronSort.DATE })}
    >
      Date
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.TITLE}
      className={cx({ active: sort === ParametronSort.TITLE })}
    >
      Title
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.CREATED_AT}
      className={cx({ active: sort === ParametronSort.CREATED_AT })}
    >
      Date Created
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.UPDATED_AT}
      className={cx({ active: sort === ParametronSort.UPDATED_AT })}
    >
      Date Updated
    </Menu.Item>
  </Menu>
);

export const refreshIngestingPreviewImages = async (
  id: number,
  searchStore?: ParametronStore<AmGenericNews> | null,
): Promise<void> => {
  if (!searchStore?.objects?.length) {
    return;
  }
  const { objects, update } = searchStore;
  const previewsInIngest = objects.filter((e) => e?.preview_image_id && !e.preview_image?.url);
  if (!previewsInIngest.length) {
    clearInterval(id);
    return;
  }
  const refreshedNewsItems = await fetchNews({
    ids: previewsInIngest.reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []),
  });
  const newsById = byId(refreshedNewsItems);
  update({ objects: objects.map((e) => newsById[e.id || 0] || e) });
};

export const useRefreshIngestingPreviewImages = (): void => {
  const { searchStore } = useDataSectionStore<AmGenericNews>();
  const refresh = useCallback(
    (id: number) => {
      refreshIngestingPreviewImages(id, searchStore);
    },
    [searchStore],
  );

  // update the delay to trigger the useInterval loop on page refresh
  const [delay, setDelay] = useState(30 * 1000);
  const { running } = searchStore || {};
  useEffect(() => {
    if (running) {
      return;
    }

    setDelay((delay) => delay + 1);
  }, [running]);

  useInterval(refresh, delay);
};
