import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { renderContactNamePart } from 'components/contact-list-item/utils';
import { Thumbnail } from 'components/thumbnail';
import { UserAvatar } from 'components/user-avatar';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, IAnalyticsProps, IContact } from 'types';

export const LinkOrText: React.FC<{
  url?: string;
  children?: JSX.Element[] | JSX.Element;
  state?: IAnalyticsProps;
  className?: string;
}> = ({ url, children, ...props }) => {
  return url ? (
    <Link to={url} {...props}>
      {children}
    </Link>
  ) : (
    <span {...props}>{children}</span>
  );
};

export const TopCardAssignedProducts: React.FC<{ assetId: string; products: { id: number; title: string }[] }> = ({
  products,
  assetId,
}) => {
  return products?.length ? (
    <div className="statistic-top-card__content__product">
      <span className="text-muted">Product: </span>
      <Link
        className={cx('text-truncate', { 'statistic-top-card__content__product__link': products.length > 1 })}
        to={`${Routes.PRODUCTS}/${products[0]?.id}`}
      >
        {products[0]?.title}
      </Link>
      {products.length > 1 && <Link to={`${Routes.ASSETS}/${assetId}/${DetailsPageTabs.PRODUCTS}`}> + More</Link>}
    </div>
  ) : null;
};

export const TopCardItemImage: React.FC<{ contact?: IContact; thumbnail?: string }> = ({ contact, thumbnail }) => {
  return (
    <div className="statistic-top-card__item__image">
      {contact ? (
        <UserAvatar disabled user={contact} className="statistic-top-card-avatar" />
      ) : (
        <Thumbnail image={thumbnail} />
      )}
    </div>
  );
};

export const TopCardItemTitle: React.FC<{
  contact;
  url: string;
  state: IAnalyticsProps;
  title: string;
}> = ({ contact, title, state, url }) => {
  const contactTitle = contact
    ? renderContactNamePart(
        {
          ...contact,
          title,
          organization: { name: contact?.organization_name },
        },
        `${Routes.CONTACTS}/${contact.id}/${DetailsPageTabs.ANALYTICS}`,
        state,
        'd-block text-truncate',
      )
    : null;
  return contactTitle ? (
    contactTitle
  ) : (
    <LinkOrText url={url} state={state} className="statistic-top-card__content__title d-block">
      <div className="text-truncate">{title}</div>
    </LinkOrText>
  );
};
