import React from 'react';
import { observer } from 'mobx-react-lite';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { ProductDescendantsCount } from 'components/product/overview/descendants-count';

import { IProduct, IProductAncestryInfo } from 'types';
import { formatLayerDuration } from 'utils/date';
import { formatType } from 'utils/general';
import { useLanguageVersionInfo } from 'utils/hooks';
import { CategoriesInfo } from 'utils/ui';

import { CustomRow } from 'components/product/overview/utils';

export interface IProductOverviewAboutProps {
  product?: IProduct;
  ancestry?: IProductAncestryInfo;
  className?: string;
  wrapperClassName?: string;
}

export const ProductOverviewAbout: React.FC<IProductOverviewAboutProps> = observer(
  ({ product, ancestry, className, wrapperClassName }) => {
    const { type, default_layer, sequence_number, parent_id } = product || {};
    const { category_ids, year_of_production, year_of_production_text, broadcaster, language_versions } =
      default_layer?.meta || {};

    const languageInfo = useLanguageVersionInfo(language_versions);

    return (
      <InfoBoxV2 gridTemplateColumns="1fr 1fr" title="ABOUT" className={className} wrapperClassName={wrapperClassName}>
        <Row hideIfNoValue label="Type" value={formatType(type)} />
        {parent_id ? <Row label="Sequence number" value={sequence_number} /> : <></>}
        <ProductDescendantsCount id={product?.id} ancestry={ancestry} />
        <Row rawLabel hideIfNoValue label="Year of production" value={year_of_production_text || year_of_production} />
        <Row hideIfNoValue className="text-lowercase" label="Duration" value={formatLayerDuration(default_layer)} />
        <Row hideIfNoValue label="Genres" value={<CategoriesInfo category_ids={category_ids} />} />
        <Row hideIfNoValue label="Language Version" value={languageInfo} />
        <CustomRow product={product as IProduct} />
        <Row label="Broadcaster" hideIfNoValue value={broadcaster} />
      </InfoBoxV2>
    );
  },
);
