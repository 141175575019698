import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import cx from 'classnames';

import { IAssetMainClassification, IAssetAggregation, IMm3Asset, ItemId } from 'types';
import { Loading } from 'components/loading';

import './style.scss';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { fetchGroupAssets } from 'utils/apis/asset';
import { useRemote } from 'utils/hooks';
import { withAssetVersion } from 'utils/asset';
import { flags } from 'utils/flags';

const initialAssetsCount: Record<IAssetMainClassification, number> = {
  video: 0,
  image: 0,
  document: 0,
  audio: 0,
};

const iconByType = {
  video: 'mobile-video',
  image: 'media',
  audio: 'volume-down',
  document: 'document',
  'Asset3::Digital::Video': 'mobile-video',
  'Asset3::Digital::Image': 'media',
  'Asset3::Digital::Audio': 'volume-down',
  'Asset3::Digital::Document': 'document',
  'Asset3::Digital::Other': 'manually-entered-data',
  'Asset3::Digital::Subtitle': 'align-center',
};

const assetClassifications = Object.keys(iconByType).sort().reverse();

interface IAssetLinkUIBasicProps {
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
  className?: string;
}

interface IAssetLinkUIProps extends IAssetLinkUIBasicProps {
  assetTypesCount: Record<IAssetMainClassification, number>;
}

const AssetsLinkUI: React.FC<IAssetLinkUIProps> = ({
  assetTypesCount,
  baseUrl,
  muted,
  disabled,
  className,
  fallback,
}) => {
  const hasAssets = Object.values(assetTypesCount).some((e) => e > 0);

  return (
    <div className={cx(className, { ['d-flex flex-row']: !className?.includes('d-block') })}>
      {hasAssets
        ? assetClassifications.reduce((acc, assetType) => {
            return assetTypesCount[assetType]
              ? [
                  ...acc,
                  <AssetIconLink
                    disabled={disabled}
                    muted={muted}
                    key={assetType}
                    count={assetTypesCount[assetType]}
                    type={assetType}
                    baseUrl={baseUrl}
                  />,
                ]
              : acc;
          }, [])
        : fallback}
    </div>
  );
};

interface IProductAssetsProps extends IAssetLinkUIBasicProps {
  assets?: { classification?: string | null; type?: string | null }[];
}

export const AssetIconLink: React.FC<{
  type: string;
  count?: number;
  disabled?: boolean;
  baseUrl?: string;
  muted?: boolean;
  addTypeParam?: boolean;
}> = ({ type, count, baseUrl = '.', muted = true, disabled, addTypeParam = true }) => {
  let link = baseUrl;

  if (addTypeParam) {
    link += `?${flags.isMm3Assets ? 'type' : 'main_classification'}=${type}`;
  }

  return (
    <Link
      className={cx('asset-icon-link', { [`${Classes.TEXT_MUTED}`]: muted, 'asset-icon-link--disabled': disabled })}
      key={type}
      to={link}
    >
      <MantineIcon icon={iconByType[type]} />
      <span className="mx-1">{count}</span>
    </Link>
  );
};

export const ProductOldAssets: React.FC<IProductAssetsProps> = ({
  assets,
  baseUrl,
  muted,
  fallback = '0',
  disabled,
  className,
}) => {
  const [assetTypesCount, setAssetTypesCount] = useState<Record<IAssetMainClassification, number>>(initialAssetsCount);

  useEffect(() => {
    const assetsCountByType = { ...initialAssetsCount };

    compact(assets).map(({ classification }) => {
      Object.entries(assetsCountByType).map(([assetType, count]) => {
        if (classification?.startsWith(assetType)) {
          assetsCountByType[assetType] = count + 1;
        }
      });
    });

    setAssetTypesCount(assetsCountByType);
  }, [assets]);

  return <AssetsLinkUI {...{ assetTypesCount, baseUrl, muted, fallback, disabled, className }} />;
};

export const ProductMm3Assets: React.FC<
  IAssetLinkUIBasicProps & { assets?: { asset: { type?: string | null } }[] }
> = ({ assets, baseUrl, muted, fallback = '0', disabled, className }) => {
  const [assetTypesCount, setAssetTypesCount] = useState<Record<string, number>>({});

  useEffect(() => {
    const assetsCountByType = {};

    assets?.map(({ asset }) => {
      const { type } = (asset || {}) as IMm3Asset;
      if (!type) {
        return;
      }

      assetsCountByType[type] = (assetsCountByType[type] || 0) + 1;
    });

    setAssetTypesCount(assetsCountByType);
  }, [assets]);

  return <AssetsLinkUI {...{ assetTypesCount, baseUrl, muted, fallback, disabled, className }} />;
};

export const ProductAssets = withAssetVersion(ProductOldAssets, ProductMm3Assets);

export const Mm3AssetTypesLink: React.FC<IProductAssetsProps> = ({ assets, ...rest }) => {
  const parsedAssets = useMemo(() => (assets || []).map((asset) => ({ asset })), [assets]);
  return <ProductMm3Assets assets={parsedAssets} {...rest} />;
};

export const AssetTypeLinks = withAssetVersion(ProductOldAssets, Mm3AssetTypesLink);

interface IAssetTypeAggregationsProps {
  aggregations?:
    | IAssetAggregation & {
        count_by_type?: {
          count: number;
          value: IAssetMainClassification;
        }[];
      };
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
}

export const AssetTypeAggregations: React.FC<IAssetTypeAggregationsProps> = ({
  aggregations,
  baseUrl,
  muted,
  disabled = false,
}) => {
  const buckets = aggregations?.count_by_main_classification || aggregations?.count_by_type || [];
  if (!buckets.length) {
    return <div>0</div>;
  }

  const sortedData = sortBy(buckets, ({ value }) => assetClassifications.indexOf(value));
  return (
    <div className="d-flex flex-row">
      {sortedData.map(({ value, count }) => {
        return (
          <AssetIconLink muted={muted} key={value} count={count} type={value} baseUrl={baseUrl} disabled={disabled} />
        );
      })}
    </div>
  );
};

interface IGroupAssetTypeAggregationsProps {
  groupId?: ItemId;
  baseUrl?: string;
  muted?: boolean;
}

export const GroupAssetTypeAggregations: React.FC<IGroupAssetTypeAggregationsProps> = ({ groupId, baseUrl, muted }) => {
  const fetcher = useCallback(
    () => fetchGroupAssets(groupId, { per: 1 }, { rawResult: true, stats: 'main_classification' }),
    [groupId],
  );

  const [result, loading] = useRemote(fetcher);

  if (loading) {
    return (
      <div className="w-25">
        <Loading />
      </div>
    );
  }

  return <AssetTypeAggregations baseUrl={baseUrl} muted={muted} aggregations={result?.aggregations} />;
};

export const AssetsShortcut: React.FC<{ route: string }> = ({ route }) => {
  return (
    <Link className="bp3-text-muted" to={`${route}/assets`}>
      <MantineIcon className="mx-1" icon="folder-shared-open" />
    </Link>
  );
};
export default ProductAssets;
