import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Row } from 'components/label-value-pair';

import { IGroupItem, IProduct, ItemId } from 'types';
import { queryProductGroupItems } from 'utils/apis/groups';

export const useGroupItemInfo = (productId?: ItemId | null): IGroupItem | undefined => {
  const { accessPrivilegeId } = useParams<{ accessPrivilegeId: string }>();
  const [groupItem, setGroupItem] = useState<IGroupItem>();

  useEffect(() => {
    if (!productId || !accessPrivilegeId) {
      return;
    }

    queryProductGroupItems(accessPrivilegeId, [productId]).then(({ object }) => setGroupItem(object as IGroupItem));
  }, [accessPrivilegeId, productId]);

  return groupItem;
};

export const getIncludedAttribute = (attribute?: boolean): string | undefined => {
  const attributeIsDefined = attribute !== undefined && attribute !== null;
  return attributeIsDefined ? (attribute ? 'Included' : 'Not included') : undefined;
};
export const CustomRow: React.FC<{ product: IProduct }> = ({ product: { default_layer } }) => {
  const { produced_by } = default_layer?.meta || {};

  return <Row rawLabel label="Produced by" value={produced_by} size="m" />;
};
