import { IBasket, IBasketStandardized } from 'types';

const mapList = (listData: IBasket): IBasketStandardized => ({
  id: listData.id,
  name: listData.name,
  entityCount: listData.products_count,
  entityType: 'products',
  ownerId: listData.owner_id,
  owner: `${listData.owner?.first_name} ${listData.owner?.last_name}`,
  updatedAt: listData.updated_at,
});

export default mapList;
