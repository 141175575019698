import React from 'react';
import { IProduct } from 'types/index';
import { forEach, isArray, map, compact } from 'lodash';

export const extractKeywords = (inputString): string | null => {
  const regex = /meta\.(.+)/;
  const ftsRegex = /(?:meta\.)?([a-zA-Z_]+)\.fts/;

  const match = inputString.includes('.fts') ? ftsRegex.exec(inputString) : inputString?.match(regex);

  if (match) {
    return match[1]; // Return the captured keyword
  }

  return null;
};

export const humanizeFilter = (filter: string): string | undefined => {
  if (!filter) return undefined;

  const keyword = filter === 'parent_id' ? 'parent_id' : extractKeywords(filter);

  switch (keyword) {
    case 'full_category_ids':
      return 'Genres';
    case 'produced_by':
      return 'Produced by';
    case 'produced_by_external':
      return 'Produced by (External)';
    case 'language_versions.language_id':
      return 'Language versions';
    default:
      return keyword
        ?.replace(/_/g, ' ')
        .replace(/\..*/, ' ')
        .replace(/\b\w/g, (l) => l.toUpperCase());
  }
};

export const normalizeSearchFilters = (filters: string): Array<string> => {
  if (!filters) return [];

  const keys: string[] = [];
  const parsedFilters = JSON.parse(filters);
  let relevantFilters = map(parsedFilters, (filter) => {
    if (filter.includes('+main')) return;

    if (filter.includes('q') && isArray(filter[0])) {
      return filter[0].join(',');
    }

    return filter;
  });

  relevantFilters = compact(relevantFilters);

  if (relevantFilters?.length === 0) return keys;

  forEach(relevantFilters, (filter: string[] | string) => {
    if (filter.includes('.fts')) {
      const ftsStrings = (filter as string).split(',');
      const names = ftsStrings.map(humanizeFilter);

      if (names?.length) {
        keys.push(...(names as string[]));
      }
    } else {
      const name = humanizeFilter(filter[0]);

      if (name) {
        keys.push(name);
      }
    }
  });

  return keys;
};

type IFilterOrProduct = string | IProduct[];

export const listRenderer = (items: IFilterOrProduct, type = 'filters'): React.ReactNode => {
  if (!items || !items.length) return null;
  const isFilters = type === 'filters';

  const limitedItems = !isFilters ? (items as IProduct[]).slice(0, 10) : normalizeSearchFilters(items as string);

  return (
    <div className="products-list__popover-content">
      {limitedItems?.map((item: IProduct | string, index: number) => (
        <div className="text-nowrap text-truncate" key={index}>
          {isFilters ? item : (item as IProduct).title}
        </div>
      ))}
      {!isFilters && items.length > 10 && <div className="mt-2">{`+ ${items.length - limitedItems.length} more`}</div>}
    </div>
  );
};
