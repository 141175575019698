import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { IUseAction } from 'utils/actions';
import { useDeliveryDialog } from 'components/delivery';

import { IDeliveryActionName } from './types';
import { shouldDisplayEdit as shouldDisplay } from './actions-acl';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

export const useEditAction: IUseAction<
  ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
  IDeliveryActionName
> = (items, options) => {
  const [delivery] = items;
  const [handler] = useDeliveryDialog({
    delivery: delivery,
    tab: options?.tab as unknown as number,
    excludeProducts: !delivery.product_ids?.[0],
    options,
  });

  return {
    name: IDeliveryActionName.EDIT,
    icon: 'edit',
    shouldDisplay,
    title: 'Edit',
    handler,
  };
};
