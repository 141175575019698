import React from 'react';
import { AmGenericNews } from '@mediafellows/mm3-types';

import { IItemActionsMenuProps } from './utils';
import { useNewsActions } from 'utils/actions/news';
import { renderMenuActionItems } from 'utils/ui';

export function NewsActionsList(props: IItemActionsMenuProps<AmGenericNews>): JSX.Element {
  const { items, options } = props;

  const actions = useNewsActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
}
