import { ISortableItem } from 'store/sort-data-store';

export const childrenUpdateParser = (list: ISortableItem[], from: number, to: number): ISortableItem[] => {
  if (to < 0) {
    return list;
  }

  const result: ISortableItem[] = [];
  list.map((item: ISortableItem, index: number) => {
    if (index === 0 || index < to) {
      result.push(item);
    } else {
      result.push({
        ...item,
        sequence_number: Math.max(result[index - 1]?.sequence_number + 1, item.sequence_number),
      });
    }
  });

  return result;
};
