import React from 'react';

import { Thumbnail } from 'components/thumbnail';

import './style.scss';

interface IPreviewSelected {
  image?: string;
  assetType?: string;
}

export const PreviewSelected: React.FC<IPreviewSelected> = ({ image, assetType, children }) => {
  return (
    <div className="selected-preview__container">
      <Thumbnail image={image} assetType={assetType} />
      <div className="selected-preview__description px-2">{children}</div>
    </div>
  );
};
