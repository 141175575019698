import React from 'react';
import { Progress } from '@mantine/core';
import { Intent } from 'utils/ui';

const DeliveryProgressBar: React.FC<{ value: number; variant: Intent; className: string }> = ({
  value,
  variant: intent,
  className,
}) => {
  return <Progress className={className} variant={intent} value={value ?? null} />;
};

export default DeliveryProgressBar;
