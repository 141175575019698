import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Tooltip } from '@mantine/core';

import { Routes } from 'utils/routes';

import DateElement from 'components/date-list-element/date-list-element';
import { IProductViewsTableItem } from 'components/product-views-list-table/api';
import { AnalyticsContact, AnalyticsOrganization, UserTerritory, AnalyticsContext, UserAgent } from 'utils/ui';
import { formatType } from 'utils/format-type';

export const EventColumns: { title: string; renderer: (data: IProductViewsTableItem) => React.ReactNode }[] = [
  {
    title: 'Contact',
    renderer: ({ user }) => <AnalyticsContact user={user} />,
  },
  {
    title: 'Platform Role',
    renderer: ({ user }) => get(user, 'role_name', 'N/A'),
  },
  {
    title: 'Organization',
    renderer: ({ user, organization }) => <AnalyticsOrganization user={user} organization={organization} />,
  },
  {
    title: 'Country (IP Country)',
    renderer: ({ user, country }) => {
      return <UserTerritory countryId={user.country_id} geoCountry={country as string} />;
    },
  },
  {
    title: 'Product',
    renderer: ({ product }) => (
      <Tooltip label={product?.title} position={'top'}>
        <div className="product-views-list-table__product-title text-truncate">
          <Link to={`${Routes.PRODUCTS}/${product?.id}`}>{product?.title || 'N/A'}</Link>
        </div>
      </Tooltip>
    ),
  },
  { title: 'Product Type', renderer: ({ product }) => formatType(product?.type) || 'N/A' },
  {
    title: 'View context',
    renderer: ({ group }) => <AnalyticsContext group={group} />,
  },
  { title: 'Device Info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  { title: 'Viewed at', renderer: ({ last_occured_at }) => <DateElement date={last_occured_at} /> },
  {
    title: 'Responsible Contact',
    renderer: ({ responsible_user: { id, full_name, email } }) => (
      <span className="product-views-list-table__responsible-contact">
        {id !== '0' ? (
          <Tooltip label={full_name || email} position={'top'}>
            <Link className="product-views-list-table__responsible-contact-name text-truncate" to={`/contacts/${id}`}>
              {full_name || email}
            </Link>
          </Tooltip>
        ) : (
          <span className="product-views-list-table__responsible-contact-name text-truncate">
            {email || 'Not assigned'}
          </span>
        )}
      </span>
    ),
  },
];
