import { IContact } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { useUploadImageDialog } from 'components/upload-preview-image';

import { shouldDisplayUploadPreviewImageAction as shouldDisplay } from './actions-acl';
import { uploadContactAvatar } from './api';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';

export const useUploadPreviewImageAction = (
  items: IContact[],
  options: IUseActionsOptionParam,
): IContactActionConfig => {
  const [openDialog] = useUploadImageDialog({
    onSubmit: (file) => {
      const { id, preview_image_id } = items[0];
      if (file) {
        uploadContactAvatar(id, file, preview_image_id);
        setTimeout(() => {
          options?.onSuccess?.(IContactActionName.UPLOAD_PREVIEW_IMAGE);
        }, 2000);
      } else {
        options?.onFailure?.();
      }
    },
  });

  return {
    name: IContactActionName.UPLOAD_PREVIEW_IMAGE,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload avatar',
    handler: openDialog,
  };
};
