import React, { useCallback } from 'react';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IAsset, IMm3Asset, ItemId } from 'types';

import { IUseActionsOptionParam } from 'utils/actions';
import { IRecommendationActionName } from 'utils/actions/recommendation';
import { IModalSize } from 'components/dialogs/types';
import { ICreateWorkFlowPayload } from 'utils/actions/types';
import { RecommendAssetsWizardContainer } from './recommend-assets-wizard-container';

interface IUseRecommendAssetsDialogProps {
  assets?: (IAsset | IMm3Asset)[];
  groupId?: ItemId;
  recommendation?: Partial<ICreateWorkFlowPayload<McGenericRecommendationAsset>>;
  tab?: number;
  options?: IUseActionsOptionParam<McGenericRecommendationAsset>;
}

export const useRecommendAssetsDialog = ({
  assets,
  recommendation,
  groupId,
  tab,
  options,
}: IUseRecommendAssetsDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <RecommendAssetsWizardContainer
        assets={assets}
        onFinish={(newValue: McGenericRecommendationAsset) => {
          close();
          options?.onSuccess?.(IRecommendationActionName.EDIT, newValue);
        }}
        groupId={groupId}
        recommendation={recommendation}
        tab={tab}
      />
    );
  }, [assets, groupId, recommendation, tab, close, options]);

  const openDialog = (): void => {
    openModal({
      title: 'Recommend Assets',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
