import React from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { useWizardSteps, Wizard, WizardStep } from 'components/wizard';
import { isValid } from 'helpers/form';
import { useEventCreateForm } from './use-event-create-form';
import {
  keysGeneralInfo,
  StepUsers,
  StepProductsAssets,
  keysProductsAssets,
  StepLayout,
  StepSummary,
  IMarketingEventEditForm,
  StepGeneralInfoV2,
} from 'components/event-create/steps';
import { FormActionSection } from 'components/form-submit-section';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

import { StepIds } from './utils';

const EventCreate: React.FC<{ saveEvent: (values: Partial<IMarketingEventEditForm>) => void }> = ({ saveEvent }) => {
  const form = useEventCreateForm({ saveEvent });
  const { formData, onSubmit } = form;
  const subevent = form.values.subevent;

  const steps = [
    {
      id: StepIds.General,
      disabled: !isValid(formData, keysGeneralInfo),
    },
    {
      id: StepIds.Layout,
    },
    {
      id: StepIds.Users,
    },
    {
      id: StepIds.ProductsAssets,
      disabled: !isValid(formData, keysProductsAssets),
    },
    {
      id: StepIds.Summary,
      action: onSubmit,
      disabled: !isValid(formData),
      nextButtonTitle: 'Create',
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  return (
    <>
      <Wizard
        onChange={setCurrentStepId}
        selectedStepId={currentStepId}
        footerPadded={false}
        footer={
          <FormActionSection className="d-flex justify-content-between w-100 flex-fill">
            <Button
              className={cx('m-0', { invisible: backButton.hide })}
              onClick={
                currentStepId === StepIds.ProductsAssets && subevent
                  ? () => setCurrentStepId(StepIds.Layout)
                  : backButton.action
              }
              variant="subtle"
              leftSection={<MantineIcon icon={<ArrowLeft />} />}
            >
              {backButton.title}
            </Button>
            <Button
              className="m-0"
              variant="primary"
              onClick={
                currentStepId === StepIds.Layout && subevent
                  ? () => setCurrentStepId(StepIds.ProductsAssets)
                  : nextButton.action
              }
              disabled={nextButton.disabled}
            >
              {nextButton.title}
            </Button>
          </FormActionSection>
        }
        fitInParent
      >
        <WizardStep
          id={StepIds.General}
          title="General Information"
          icon="media"
          disabled={isStepDisabled(StepIds.General)}
          panel={<StepGeneralInfoV2 form={form} showCountdownLabel />}
        />
        <WizardStep
          id={StepIds.Layout}
          title="Layout"
          icon="layout"
          disabled={isStepDisabled(StepIds.Layout)}
          panel={<StepLayout form={form} />}
        />
        <WizardStep
          id={StepIds.Users}
          title="Attendees"
          icon="people"
          disabled={isStepDisabled(StepIds.Users) || subevent}
          panel={<StepUsers form={form} />}
        />
        <WizardStep
          id={StepIds.ProductsAssets}
          title="Products and assets"
          icon="video"
          disabled={isStepDisabled(StepIds.ProductsAssets)}
          panel={<StepProductsAssets form={form} />}
        />
        <WizardStep
          id={StepIds.Summary}
          title="Summary"
          icon="properties"
          disabled={isStepDisabled(StepIds.Summary)}
          panel={<StepSummary form={form} />}
        />
      </Wizard>
    </>
  );
};

export { EventCreate };
