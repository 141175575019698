import { useCallback } from 'react';

import { IConference } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { copyConference } from 'utils/apis/conference';

import { shouldDisplayCopy } from './actions-acl';
import { IConferenceActionConfig, IConferenceActionName } from './types';

export const useCopyAction = (items: IConference[], options: IUseActionsOptionParam): IConferenceActionConfig => {
  const { toastStore } = useStore();

  const handler = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }

      await copyConference(items[0]);
      toastStore.success('Conference copied successfully!');
      return options?.onSuccess?.(IConferenceActionName.COPY);
    } catch (error) {
      toastStore.error(`Conference copy failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [toastStore, items, options]);

  return {
    name: IConferenceActionName.COPY,
    shouldDisplay: shouldDisplayCopy,
    icon: 'duplicate',
    title: 'Copy',
    handler,
  };
};
