import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Divider, Switch } from '@mantine/core';

import { useAssetsEditStore } from 'store/hooks';
import { FormCheckbox, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import '../index.scss';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IMultipleAssetFormHeader {
  openPresetModal: () => void;
}

export const MultipleAssetEditHeader: React.FC<IMultipleAssetFormHeader> = observer(({ openPresetModal }) => {
  const { assets, setAssets } = useAssetsEditStore();
  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedAssets = useMemo(
    () => (assets as { selected?: boolean }[]).filter((a) => a?.selected).length,
    [assets],
  );

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedAssets > 0 });
  }, [onChange, numberOfSelectedAssets]);

  const onSelectedAssetChange = useCallback(
    ({ selectedAssets }) => {
      onChange({ selectedAssets });
      setAssets(
        assets.map((asset) => (asset.selected === selectedAssets ? asset : { ...asset, selected: selectedAssets })),
      );
    },
    [onChange, setAssets, assets],
  );

  const onSelectAllChange = useCallback(
    (evt) => {
      const selectAll = evt.target.checked;
      setAssets(assets.map((asset) => (asset.selected === selectAll ? asset : { ...asset, selected: selectAll })));
    },
    [setAssets, assets],
  );

  return (
    <div className="d-flex w-100 m-0 align-items-center justify-content-between">
      <div className="d-flex gap-2">
        <FormCheckbox
          label={`${numberOfSelectedAssets}/${assets.length}`}
          name="selectedAssets"
          {...formData.selectedAssets}
          {...handlers}
          onChange={onSelectedAssetChange}
          hasBottomMargin={false}
        />
        <Divider orientation="vertical" mx={5} />
        <Switch
          checked={numberOfSelectedAssets === assets?.length}
          label="Select All"
          name="selectAll"
          onChange={onSelectAllChange}
        />
      </div>
      <Button variant="outline" color="white" className="flex-end" onClick={openPresetModal}>
        Select Preset
      </Button>
    </div>
  );
});

export default React.memo(MultipleAssetEditHeader);
