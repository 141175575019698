import { UmGenericListShowcase, UmGenericListCollection } from '@mediafellows/mm3-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { deleteList } from 'utils/apis/showcase';
import getPathAfterContentDeletion from 'utils/get-path-after-content-deletion';
import { pluralWordInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { formatWebsiteTypes } from 'components/showcase-form/utils';
import { shouldDisplayDelete } from './acl';

export const useDeleteAction = (
  items: (UmGenericListShowcase | UmGenericListCollection)[],
  options: IUseActionsOptionParam<UmGenericListShowcase | UmGenericListCollection>,
): IShowcaseActionConfig => {
  const {
    toastStore,
    dialogStore,
    dataSectionStore: { updateStore },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();

  const type = formatWebsiteTypes(items[0].type);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.reduce((acc, item) => (item?.id ? [...acc, item.id] : acc), []);
      await deleteList(itemIds);
      navigate({
        pathname: getPathAfterContentDeletion(location.pathname, itemIds[0]),
        search: location.search,
      });
      updateStore({
        active: null,
      });
      toastStore.success(`${pluralWordInflect(type, items?.length)} was deleted`);
    } catch (error) {
      toastStore.error(`${type} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, navigate, updateStore, toastStore, type, options, location]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete this ${type}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, executeDelete, type]);

  return {
    name: IShowcaseActionName.DELETE,
    shouldDisplay: shouldDisplayDelete,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    requiredPowers: items[0].type === 'List::Showcase' ? ['um/list_delete_showcases'] : undefined,
    handler,
  };
};
