import { map } from 'lodash';

import { useMobileAppSyncDialog } from 'components/mobile-app-sync-wizard';
import { IconAlias } from 'icons';

import { IUseActionsOptionParam } from 'utils/actions';

import { IIdentifiable } from 'types';
import {
  IMobileAppSyncActionName,
  IMobileAppSyncActionConfig,
  IShouldDisplayMobileAppSyncActionFunction,
} from './types';

export interface IUseCreateMobileSyncOptions extends IUseActionsOptionParam {
  shouldDisplay: IShouldDisplayMobileAppSyncActionFunction;
}

export const useCreateMobileSyncAction = (
  { products }: { products?: IIdentifiable[] },
  options?: IUseCreateMobileSyncOptions,
): IMobileAppSyncActionConfig => {
  const [openDialog] = useMobileAppSyncDialog({
    productIds: map(products, 'id'),
    options,
  });

  return {
    name: IMobileAppSyncActionName.CREATE_MOBILE_SYNC,
    shouldDisplay: options?.shouldDisplay,
    icon: IconAlias.MOBILE_APP_SYNC,
    title: 'Create Mobile Selection',
    handler: openDialog,
  };
};
