import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useStore } from 'store';

import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';

import { groupItemsAccessChange, loadPropsFromExistingProductItems } from 'utils/apis/groups';
import { IAccessPrivilegeActionName } from 'utils/actions/access-privilege';
import { IActionName } from 'utils/actions/types';
import { accessPrivilegeDetailsSchema } from 'utils/schemas';

import { IGroup, IIdentifiable } from 'types';
import { IGroupAccessChangeForm, IGroupAccessChange } from './types';

interface IUseGroupAccessChangeFormArgs {
  groups: (IGroup & IIdentifiable)[];
  onSuccess?: (action?: IActionName, entity?: IGroupAccessChange) => Promise<void> | void;
  hideAccessCheckboxes?: boolean;
}

type IUseGroupAccessChangeForm = (args: IUseGroupAccessChangeFormArgs) => IGroupAccessChangeForm;

export const useGroupAccessChangeForm: IUseGroupAccessChangeForm = ({
  groups = [],
  onSuccess,
  hideAccessCheckboxes,
}) => {
  const { toastStore } = useStore();

  const submitForm = useCallback(
    async (data: IGroupAccessChange) => {
      try {
        toastStore.success('Access changed');
        const updatedAccessPrivilege = await groupItemsAccessChange(
          data,
          accessPrivilegeDetailsSchema,
          hideAccessCheckboxes,
        );

        updatedAccessPrivilege &&
          onSuccess?.(IAccessPrivilegeActionName.CHANGE_ACCESS, updatedAccessPrivilege.object as IGroupAccessChange);
      } catch (error) {
        toastStore.error(error);
      }
    },
    [hideAccessCheckboxes, toastStore, onSuccess],
  );

  const initialFormValues: IGroupAccessChange = useMemo(
    () => ({
      group_ids: groups.map((g) => g.id),
      delegates_access: false,
      permit_download: false,
      protection_levels: [],
      expires_at: '',
    }),
    [groups],
  );

  const form = useForm<IGroupAccessChange>(initialFormValues, Model.GROUPS, submitForm);
  const {
    handlers: { onSetFields },
    values,
  } = form;

  const initialValues = useRef({ groups, onSetFields, values });

  useEffect(() => {
    const prefillFormAsync = async (): Promise<void> => {
      const { groups, onSetFields, values } = initialValues.current;
      const { delegates_access, permissions } = await loadPropsFromExistingProductItems(groups[0]?.id);

      const permitDownloadValue = permissions.includes('download');

      onSetFields({
        ...values,
        delegates_access,
        permit_download: permitDownloadValue,
        expires_at: groups[0]?.expires_at,
      });
    };

    prefillFormAsync();
  }, []);

  return form;
};
