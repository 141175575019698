import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import ScrollWrapper from 'components/scroll-wrapper';
import { LoaderWithText, Thumbnail } from 'components/thumbnail';

import { IAffiliation } from 'types';
import { isProcessingPreview } from 'utils/general';

import './style.scss';

const AffiliationMobileAppTab: React.FC<{ affiliation?: IAffiliation }> = ({ affiliation }) => {
  const {
    uses_conferencing,
    uses_meeting_calendars,
    uses_product_baskets,
    uses_mm3_products,
    uses_mm3_assets,
    mobile_app_background_color,
    mobile_app_signature_color,
    splash_screen,
    splash_screen_id,
    mobile_app_splash_screen,
  } = affiliation || {};
  const videoProps = mobile_app_splash_screen ? { className: 'pt-3', labelClassName: 'pt-5' } : {};
  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="1fr 1fr 1fr" wrapperClassName="py-3" className="text-no-transform ">
        <InfoBoxV2 gridTemplateColumns="2fr 1fr" className="text-no-transform ">
          <Row label="Uses Conferencing" value={yn(uses_conferencing)} />
          <Row label="Uses MM3 Products" rawLabel value={yn(uses_mm3_products)} />
          <Row label="Background Color (Generic App)" rawLabel value={mobile_app_background_color} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="2fr 1fr" className="text-no-transform ">
          <Row label="Uses Meeting Calendars" value={yn(uses_meeting_calendars)} />
          <Row label="Uses MM3 Assets" rawLabel value={yn(uses_mm3_assets)} />
          <Row label="Signature Color (Generic App)" rawLabel value={mobile_app_signature_color} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="2fr 1fr" className="text-no-transform ">
          <Row label="Uses Product Baskets" value={yn(uses_product_baskets)} />
        </InfoBoxV2>
      </InfoBoxV2>
      <InfoBoxV2 wrapperClassName="py-3" gridTemplateColumns="1fr 1fr">
        <InfoBoxV2 gridTemplateColumns="2fr 1fr">
          <Row
            label="Splash Screen Image"
            className="affiliation-image__row"
            value={
              <Thumbnail
                image={splash_screen?.url}
                placeholder={
                  isProcessingPreview({ preview_image: splash_screen, preview_image_id: splash_screen_id }) && (
                    <LoaderWithText text="processing" />
                  )
                }
              />
            }
          />
          <Row
            label="Splash Screen Video"
            {...videoProps}
            value={
              mobile_app_splash_screen ? (
                <video width="320" height="240" controls autoPlay={false}>
                  <source src={mobile_app_splash_screen} type="video/mp4" />
                </video>
              ) : (
                '—'
              )
            }
          />
        </InfoBoxV2>
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationMobileAppTab;
