import { IResolution } from 'types';

interface IVideoResolution {
  width: number;
  label: string;
}

export const videoResolutions: IVideoResolution[] = [
  { width: 640, label: 'SD' },
  { width: 720, label: 'HD' },
  { width: 1920, label: 'Full HD' },
  { width: 3840, label: 'UHD' },
  { width: 7680, label: 'Full UHD' },
];

export const getVideoResolution = (resolution?: IResolution): string => {
  if (!resolution) return '';
  const { width } = resolution;
  if (!width) return '';
  for (let index = 1; index < videoResolutions.length; index++) {
    const currentResolution = videoResolutions[index];
    if (width < currentResolution.width) {
      return videoResolutions[index - 1].label;
    }
  }

  return 'Full UHD';
};
