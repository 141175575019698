import { IMarketingEvent } from 'types';
import { countStringItems } from 'utils/general';
import { useBasicStore } from 'store';

interface ITVMarketingEvent extends IMarketingEvent {
  standalone?: boolean;
  text_color?: string | null;
}

export const getAttendeesCount = (event?: Pick<IMarketingEvent, 'approved_emails'>): number =>
  countStringItems(event?.approved_emails);

export type IEventStatus = 'upcoming' | 'ongoing' | 'finished' | 'unknown';

export const getEventStatus = (event: IMarketingEvent): IEventStatus => {
  if (!event.is_timed_event) {
    return 'ongoing';
  }

  const { event_starts_at = '', event_ends_at = '' } = event;

  const now = new Date().getTime();
  const start = new Date(event_starts_at).getTime();
  const end = new Date(event_ends_at).getTime();

  if (isNaN(start) || isNaN(end)) {
    return 'unknown';
  }

  if (now < start) {
    return 'upcoming';
  }

  if (now > end) {
    return 'finished';
  }

  return 'ongoing';
};

export const useEventUrl = (event?: ITVMarketingEvent): string | null => {
  const { affiliation } = useBasicStore();
  const { primary_buyer_domain } = affiliation;

  if (!event) {
    return null;
  }

  if (!primary_buyer_domain) {
    return null;
  }

  if (event?.standalone) {
    return `${primary_buyer_domain.protocol}://${primary_buyer_domain.name}/events/standalone/${encodeURIComponent(
      event.purpose,
    )}`;
  }

  return `${primary_buyer_domain.protocol}://${primary_buyer_domain.name}/events/${encodeURIComponent(event.purpose)}`;
};
export const useScreeningRoomUrl = (): void => {
  return undefined;
};
