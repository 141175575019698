import React, { useCallback } from 'react';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useDialogStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions';
import { IRecommendationActionName } from 'utils/actions/recommendation';
import { IModalSize } from 'components/dialogs/types';
import { getRecommendationDataFromGroup, IPartialRecommendation } from './apis';
import { useRemote } from 'utils/hooks';
import { GroupTypes, IGroup, ItemId } from 'types';

import { GroupToRecommendationMapper } from './mobile-selection-to-recommendation-mapper';

interface IUseRecommendProductsDialogProps {
  group?: Pick<IGroup, 'id' | '@type' | 'main_entity_type'>;
  options?: IUseActionsOptionParam<IGroup | McGenericRecommendationProduct | McGenericRecommendationAsset>;
}

const Body: React.FC<{
  groupId: ItemId;
  groupType: GroupTypes | `${GroupTypes.SELECTION}/${'users' | 'assets' | 'products'}`;
  onFinish: (nweValue: IGroup) => void;
}> = ({ onFinish, groupId, groupType }): JSX.Element => {
  const fetchRecommendationItems = useCallback((): Promise<IPartialRecommendation> => {
    return getRecommendationDataFromGroup(groupId, groupType);
  }, [groupId, groupType]);

  const [recommendation, loading] = useRemote(fetchRecommendationItems);
  const isAssetRecommendation =
    groupType?.startsWith(`${GroupTypes.SELECTION}/`) && groupType.split('/').at(-1) === 'assets';

  return (
    <GroupToRecommendationMapper
      onFinish={onFinish}
      recommendation={recommendation}
      isAssetRecommendation={isAssetRecommendation}
      loading={loading}
    />
  );
};

export const useRecommendGroupDialog = ({ options, group }: IUseRecommendProductsDialogProps): [VoidFunction] => {
  const { openModal, close } = useDialogStore();
  const { ['@type']: type = '', main_entity_type, id = '' } = group || {};
  const groupType = type + (type === GroupTypes.SELECTION ? `/${main_entity_type}` : '');

  const body = useCallback(
    (): JSX.Element => (
      <Body
        onFinish={(newValue: IGroup) => {
          close();
          options?.onSuccess?.(IRecommendationActionName.EDIT, newValue);
        }}
        groupType={groupType as GroupTypes | `${GroupTypes.SELECTION}/${'users' | 'assets' | 'products'}`}
        groupId={id}
      />
    ),
    [close, groupType, id, options],
  );

  const openDialog = useCallback(
    (): void =>
      openModal({
        title: 'Recommendation Dialog',
        body,
        fitInView: true,
        size: IModalSize.S,
      }),
    [body, openModal],
  );

  return [openDialog];
};
