import React from 'react';

import { IContact } from 'types';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import { MeetingNotificationsSection } from 'pages/contact-details/contact-overview-tab/sections/meeting-notifications';
import { showNotificationPreferences } from 'pages/contact-details/utils';

export const SubscriptionsSection: React.FC<IContact> = (contact) => {
  const {
    analytics_subscription,
    analytics_playback_attachment_all,
    supervisor_analytics_subscription,
    supervisor_analytics_playback_attachment,
    supervisor_analytics_playback_attachment_all,
    supervisor_analytics_cc,
    skip_marketing_emails,
  } = contact;
  return (
    <InfoBoxV2 wrapperClassName="py-3" title="Subscriptions" gridTemplateColumns={'minmax(130px, 1fr) 2fr'}>
      <Row label="Analytics Report (Global)" rawLabel value={yn(analytics_subscription)} size="m" />
      {analytics_subscription && (
        <Row
          label="Include Screenings Attachment (Global)"
          rawLabel
          value={yn(analytics_playback_attachment_all)}
          size="m"
          labelClassName="ms-3"
        />
      )}
      <Row label="Analytics Report (My Clients)" rawLabel value={yn(supervisor_analytics_subscription)} size="m" />
      {supervisor_analytics_subscription && (
        <>
          <Row
            label="Include Screenings Attachment (My Clients)"
            rawLabel
            value={yn(supervisor_analytics_playback_attachment)}
            size="m"
            labelClassName="ms-3"
          />
          <Row
            label="Include Screenings Attachment (Global)"
            rawLabel
            value={yn(supervisor_analytics_playback_attachment_all)}
            size="m"
            labelClassName="ms-3"
          />
          <Row label="Cc Recipient" value={supervisor_analytics_cc || `—`} size="m" labelClassName="ms-3" />
        </>
      )}
      <Row label="Opt out from marketing emails" value={yn(skip_marketing_emails)} size="m" />

      {showNotificationPreferences(contact.id) && <MeetingNotificationsSection />}
    </InfoBoxV2>
  );
};
