import React from 'react';

import { AccessPrivilegesDataSection } from 'components/access-privileges-data-section';
import { Pages } from 'utils/actions/types';
import { DataSectionPage } from 'components/layout';

const AccessPrivileges: React.FC = () => {
  return (
    <DataSectionPage>
      <AccessPrivilegesDataSection persistFilters page={Pages.ACCESS_PRIVILEGES_LIST} />
    </DataSectionPage>
  );
};

export default AccessPrivileges;
