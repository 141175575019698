import React from 'react';
import cx from 'classnames';
import { ISidebarSection } from 'store/profile-store';
import { Collapse, Menu, Tooltip } from '@mantine/core';
import { IconName } from 'blueprint5-icons';

import { CheckPowers } from 'components/check-power';
import { hideSideBarFlags } from 'utils/flags';
import { MantineIcon } from 'utils/ui/icon';
import { Routes } from 'utils/routes';
import { getSidebarSectionPowers } from 'components/sidebar/utils';

export interface ICollapseMenuProps {
  onMenuItemClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  activeSidebarSection?: ISidebarSection | null;
  sidebarSection: ISidebarSection;
  text?: string;
  iconName?: IconName;
}

const CollapsableMenuItem: React.FC<React.PropsWithChildren<ICollapseMenuProps>> = ({
  onMenuItemClick,
  activeSidebarSection,
  sidebarSection,
  iconName,
  text,
  children,
}) => {
  return (
    <>
      <div className="sidebar__mode--default">
        <Menu.Item
          onClick={onMenuItemClick}
          data-section={sidebarSection}
          className="sidebar__item"
          leftSection={<MantineIcon icon={iconName} />}
          rightSection={
            <div>
              <MantineIcon
                className="sidebar__caret"
                icon={`caret-${activeSidebarSection === sidebarSection ? 'up' : 'down'}` as IconName}
              />
            </div>
          }
        >
          {text}
        </Menu.Item>
        <Collapse in={activeSidebarSection === sidebarSection}>
          <div className="sidebar__subitem-menu">{children}</div>
        </Collapse>
      </div>
      <div className="sidebar__mode--icons">
        <Menu position="right-start" offset={0}>
          <Tooltip label={text} position="right">
            <Menu.Target>
              <div
                className={cx('sidebar__item sidebar__item-icons', {
                  'collapsable-menu-item--active': activeSidebarSection === sidebarSection,
                })}
              >
                <MantineIcon className="sidebar__icon" icon={iconName} />
                <MantineIcon className="sidebar__caret" icon="caret-right" />
              </div>
            </Menu.Target>
          </Tooltip>
          <Menu.Dropdown>
            <ul className="popover-menu">
              <Menu.Label component="li" className="sidebar__divider">
                {text}
              </Menu.Label>
              {children}
            </ul>
          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
};

const CollapsibleMenuItem: React.FC<React.PropsWithChildren<ICollapseMenuProps & { routes: Routes[] }>> = ({
  sidebarSection,
  routes,
  ...props
}) => {
  const requiredPowers = getSidebarSectionPowers(routes);

  return (
    <CheckPowers anyOf requiredPowers={requiredPowers} hideAnyway={hideSideBarFlags[sidebarSection]}>
      <CollapsableMenuItem sidebarSection={sidebarSection} {...props} />
    </CheckPowers>
  );
};

export default CollapsibleMenuItem;
