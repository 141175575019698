class Logger {
  public info(...msg: unknown[]): void {
    // eslint-disable-next-line no-console
    console.log(...msg);
  }
  public warn(...msg: unknown[]): void {
    // eslint-disable-next-line no-console
    console.warn(...msg);
  }
  public error(...msg: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(...msg);
  }
}
const logger = new Logger();

export { logger };
