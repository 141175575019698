import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { useSortDataSectionStore } from 'store';
import { productItemRenderer } from 'components/group-items-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';
import { IProduct } from 'types';
import { getProducts, updateProduct } from 'utils/apis/product';
import { byId } from 'utils/general';

const fetchRelatedProducts = async (related_product_ids: number[]): Promise<IProduct[]> => {
  if (related_product_ids?.length < 1) {
    return [];
  }
  const products = await getProducts({ product_ids: related_product_ids });
  const productsById = byId(products);
  return related_product_ids.map((id) => productsById[id]);
};

const saveOrder = (id: number, products: IProduct[]): Promise<IProduct> => {
  return updateProduct({
    id,
    meta: { related_product_ids: products.map((p) => p.id) },
  });
};

const ProductRelatedSortTab: React.FC<{ product: IProduct; setProduct: Dispatch<SetStateAction<IProduct>> }> = observer(
  ({ product, setProduct }) => {
    const { initStore } = useSortDataSectionStore();
    const fetcher = useCallback(() => fetchRelatedProducts(product?.meta?.related_product_ids), [product]);

    const onSave = useCallback(
      async (list) => {
        saveOrder(product.id, list);
        setProduct(({ meta, ...p }) => ({ ...p, meta: { ...meta, related_product_ids: list.map(({ id }) => id) } }));
      },
      [product.id, setProduct],
    );

    initStore({ fetcher, onSave });

    return <SortDataSection itemRenderer={productItemRenderer} />;
  },
);

export default ProductRelatedSortTab;
