import React from 'react';
import { IUseFormReturn } from 'helpers/form';

import { EventAttendeeListItem } from './event-attendees-emails-list-item';
import { StepUsers } from 'components/event-create';
import { IAttendees } from './types';
import { IMarketingEventEditForm } from 'components/event-create/steps';

export interface IMarketingContactListItemProps {
  attendees: IAttendees;
  isActiveUsersAttendanceAllowed: boolean;
  isEditModeEnabled: boolean;
  form: IUseFormReturn<IMarketingEventEditForm>;
}

export const EventAttendeeList: React.FC<IMarketingContactListItemProps> = ({
  attendees,
  isEditModeEnabled,
  isActiveUsersAttendanceAllowed,
  form,
}) => {
  return isEditModeEnabled ? (
    <StepUsers form={form} />
  ) : (
    <EventAttendeeListItem attendees={attendees} isActiveUsersAttendanceAllowed={isActiveUsersAttendanceAllowed} />
  );
};
