import { Model } from 'helpers/filters/types';
import { IDivision } from 'types';
import { chipmunk } from 'utils/chipmunk';

export function getDivisions(division_ids: number[] = []): Promise<IDivision[]> {
  return chipmunk.run(async (chipmunk) => {
    // without params chipmunk is complaining. Probably the param marked as required
    const { objects } = await chipmunk.action(Model.DIVISIONS, 'get', { params: { division_ids } });
    return objects;
  });
}
