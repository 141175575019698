import { IMarketingEvent } from 'types';
import { flags } from 'utils/flags';

import { IUseActionsOptionParam, IUseActionsResponse, dividerAction, useActions } from 'utils/actions';

import { IMarketingEventActionName } from './types';
import { useDeleteAction } from './delete-action';
import { useEditAction } from './edit-action';
import { useUploadGroupPreviewAction } from 'utils/actions/common/upload-group-preview';
import { useManageAttendeesAction } from './manage-attendees-action';
import { useAddProductsAction } from './add-products-action';
import { useAddAssetsAction } from './add-assets-action';
import { useAddEventsAction } from './add-events-action';
import { useRemoveFromGroupAction } from './remove-from-group';
import { useRestoreAction } from './restore-action';
import { useManageSliderShowcase } from './manage-slider-showcase';
import { useManageHostsShowcase } from './manage-hosts';
import { IGroupActionName } from 'utils/actions/common';

export { IMarketingEventActionName } from './types';
export { addAttendees } from './api';

const { showAutonomousShowcaseButtons } = flags;

export const useEventActions = (
  entities: IMarketingEvent[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IGroupActionName | IMarketingEventActionName> => {
  const editAction = useEditAction(entities);
  const uploadPreviewAction = useUploadGroupPreviewAction(entities, options);
  const manageAttendeesAction = useManageAttendeesAction(entities, options);
  const manageShowcaseAction = useManageSliderShowcase(entities, options);
  const manageHostsAction = useManageHostsShowcase(entities, options);
  const addProductsAction = useAddProductsAction(entities, options);
  const addAssetsAction = useAddAssetsAction(entities, options);
  const addEventsAction = useAddEventsAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);
  const removeFromGroupAction = useRemoveFromGroupAction(entities, options);

  const eventActionList = [
    removeFromGroupAction,
    editAction,
    uploadPreviewAction,
    manageAttendeesAction,
    addProductsAction,
    addAssetsAction,
    addEventsAction,
    deleteAction,
    restoreAction,
    dividerAction,
  ];

  const actions = showAutonomousShowcaseButtons
    ? [...eventActionList, manageShowcaseAction, manageHostsAction]
    : eventActionList;

  return useActions<IMarketingEvent, IGroupActionName | IMarketingEventActionName>(actions, options, entities);
};
