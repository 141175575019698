import { IOption, IOptionColor } from 'types';
import { addYears, endOfYear, subYears, startOfYear, startOfDay } from 'date-fns';
import { today } from './date';

export const ACCESS_LEVELS_OPTIONS: IOption[] = [
  {
    label: 'private',
    value: 'private',
    color: IOptionColor.red,
  },
  {
    label: 'division',
    value: 'division',
    color: IOptionColor.orange,
  },
  {
    label: 'company',
    value: 'company',
    color: IOptionColor.yellow,
  },
  {
    label: 'viewable',
    value: 'viewable',
    color: IOptionColor.green,
  },
  {
    label: 'public',
    value: 'public',
    color: IOptionColor.white,
  },
];

export const minAccessDate = startOfDay(today);
export const maxAccessDate = endOfYear(addYears(new Date(today.getFullYear(), 11, 31), 10));
export const minBirthDate = startOfYear(subYears(new Date(), 100));
export const maxBirthDate = endOfYear(subYears(new Date(today.getFullYear(), 11, 31), 18));
export const maxDateFiveYears = endOfYear(addYears(new Date(today.getFullYear(), 11, 31), 5));
export const refreshPreviewIngestingDelay = 20 * 1000;
