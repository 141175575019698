import React, { useMemo, useCallback } from 'react';

import { FormInput, FieldList, FieldListV2, FormPhone, FormMultiInput } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { SocialMediaProfileItems } from 'helpers/form/custom-fields/social-media-profile-fields';

import type { IOrganizationFormPart } from './types';

const phoneKeys = ['label', 'number'];
const socialMediaKeys = ['label', 'link'];

export const contactsDataKeys = ['email', 'phones', 'social_media_profiles', 'urls'];

export const FormContacts: React.FC<IOrganizationFormPart> = ({ formData, formHandlers, formValues }) => {
  const fieldProps = useMemo(
    () => ({
      form: { data: formData, handlers: formHandlers },
      large: true,
    }),
    [formData, formHandlers],
  );

  const websitesProps = useCallback(
    () => ({
      ...fieldProps,
      fields: [{ key: 'url', label: 'URL' }],
    }),
    [fieldProps],
  );

  return (
    <>
      <FormSectionWrapperV2 title="Contact Information" wrapperClassName="py-3" withBorder>
        <FormInput name="email" type="text" label="Email" {...formHandlers} {...formData.email} />
      </FormSectionWrapperV2>

      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
        <FieldList
          label="Phones"
          Fields={FormPhone}
          {...{ handlers: formHandlers, values: formValues, formData }}
          prefix="phones"
          keys={phoneKeys}
        />
      </FormSectionWrapperV2>

      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
        <FieldList
          prefix="social_media_profiles"
          label="Social Media Profiles"
          Fields={SocialMediaProfileItems}
          {...{ handlers: formHandlers, values: formValues, formData }}
          keys={socialMediaKeys}
        />
      </FormSectionWrapperV2>

      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
        <FieldListV2
          prefix="urls"
          label="Websites"
          component={FormMultiInput}
          fieldProps={websitesProps}
          formValues={formValues}
          formHandlers={formHandlers}
        />
      </FormSectionWrapperV2>
    </>
  );
};
