import React from 'react';

import { DataSectionPage } from 'components/layout';
import { NewsDataSection } from 'components/news';

export const NewsPage: React.FC = () => {
  return (
    <DataSectionPage>
      <NewsDataSection persistFilters />
    </DataSectionPage>
  );
};

export default NewsPage;
