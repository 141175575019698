import React from 'react';
import cx from 'classnames';

import { ITabValue, Tab, TabPanel } from 'components/tabs';

import { DetailsPageTabs } from 'types';

export const MobileSelectionDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  hidden?: boolean;
  disabled?: boolean;
  editModeEnabled?: boolean;
  valueParser?: (value?: ITabValue) => string;
}> = ({ value, activeTab, hidden = false, disabled, valueParser, editModeEnabled }) => {
  return (
    <Tab
      value={value}
      className={cx(`entity-detail__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={disabled || editModeEnabled}
      hidden={hidden}
      valueParser={valueParser}
    />
  );
};

export const MobileSelectionTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({
  value,
  content,
}) => {
  return <TabPanel value={value} className="entity-detail__tab-content h-100" content={content} />;
};

export enum MobileSyncAssetTabSubTabs {
  PRODUCT_ASSETS = 'PRODUCT ASSETS',
  OTHER_ASSETS = 'OTHER ASSETS',
}
