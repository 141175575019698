import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';

import { useStore } from 'store';

import { pluralEntityInflect } from 'utils/general';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { removeElementFromSelection } from 'utils/actions/selection/api';
import { loadGroupItems, queryProductGroupItems } from 'utils/apis/groups';
import { queryClassificationAssetsOfProducts } from 'utils/apis/product-asset';
import { Intent } from 'utils/ui';

import { shouldDisplayRemoveFromMobileSync as shouldDisplay } from './actions-acl';

import { IProductActionName } from './types';
import { IGroupAssetItem, IProduct } from 'types';
import { Model } from 'helpers/filters/types';

export const useRemoveFromMobileSyncAction = (
  products: IProduct[],
  options: IUseActionsOptionParam,
): IActionConfig<IProduct, IProductActionName> => {
  const { toastStore, dialogStore } = useStore();
  const { mobileSyncId } = useParams<{ mobileSyncId: string }>();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Product', products.length);

  const executeRemove = useCallback(async () => {
    try {
      const productIds = map(products, 'id');
      if (!mobileSyncId || !productIds.length) {
        return;
      }
      const productAssets = await queryClassificationAssetsOfProducts(productIds, true, false);
      if (productAssets.length) {
        const productAssetsIds = map(productAssets, 'id');

        const groupAssets = await loadGroupItems<IGroupAssetItem>({
          group_ids: [mobileSyncId],
          target: Model.ASSET_GROUP_ITEMS,
        });

        const groupAssetIdsToDelete = groupAssets.reduce((acc, groupAsset) => {
          if (productAssetsIds.includes(groupAsset.asset_id)) return [...acc, groupAsset.id];
          return acc;
        }, []);

        await removeElementFromSelection(mobileSyncId, groupAssetIdsToDelete, Model.ASSET_GROUP_ITEMS);
      }
      const { objects: groupProducts } = await queryProductGroupItems(mobileSyncId, productIds);
      const groupProductIds = map(groupProducts, 'id');

      await removeElementFromSelection(mobileSyncId, groupProductIds, Model.PRODUCT_GROUP_ITEMS);

      toastStore.success(`${entityWithCount} will be removed from Mobile Selection`);
      await options?.onSuccess?.(IProductActionName.REMOVE_FROM_MOBILE_SYNC);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, mobileSyncId, options, products, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} and their assets from the Mobile Selection?`,
      onConfirm: executeRemove,
    });
  }, [dialogStore, entityWithDemonstrative, executeRemove]);

  return {
    name: IProductActionName.REMOVE_FROM_MOBILE_SYNC,
    shouldDisplay,
    icon: 'remove',
    title: 'Remove from Mobile Selection',
    intent: Intent.DANGER,
    handler,
  };
};
