import React, { useCallback } from 'react';
import { IAsset, IAssetToProduct, IMm3Asset } from 'types';

import { useStore } from 'store';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { IUseActionsOptionParam } from 'utils/actions';
import { getValidIds, pluralEntityInflect } from 'utils/general';
import { IModalSize } from 'components/dialogs/types';
import { AssetProductForm } from 'components/assign-asset-to-product-form';

import { assignAssetsToProducts } from './api';
import { shouldDisplayAssignToProduct as shouldDisplay } from './actions-acl';

export const useAssignToProductAction = (
  items: (IAsset | IMm3Asset)[],
  options?: IUseActionsOptionParam,
): IAssetActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const assetIds = getValidIds(items);
  const { entity } = pluralEntityInflect('Asset', items.length);

  const handleSubmit = useCallback(
    async (values: IAssetToProduct) => {
      try {
        await assignAssetsToProducts(values);
        toastStore.success(`${entity} will be assigned`);
        options?.onSuccess?.(IAssetActionName.ASSIGN_TO_PRODUCT);
        return dialogStore.close();
      } catch (e) {
        toastStore.error(`Error happened while assigning assets to products: ${e.text}`);
      }
    },
    [dialogStore, toastStore, options, entity],
  );

  const body = useCallback(() => {
    return <AssetProductForm assetIds={assetIds} onSubmit={handleSubmit} />;
  }, [assetIds, handleSubmit]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Assign asset to product',
      size: IModalSize.S,
      body,
    });
  };

  return {
    name: IAssetActionName.ASSIGN_TO_PRODUCT,
    shouldDisplay,
    icon: 'group-objects',
    title: 'Assign to a product',
    handler: openDialog,
  };
};
