import { useCallback } from 'react';

import { useStore } from 'store';
import { IFormData, IFormHandlers, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { customContext } from 'helpers/form/fields/form-address';

import { saveOrganization } from './api';
import type { IOrganizationFields } from './types';
import { useNavigate } from 'react-router';
import { initialValues } from 'pages/upsert-organization/initial-values';

export interface IUpsertOrganizationFormProps {
  initialValues: IOrganizationFields;
  submitTitle: string;
  onSubmit: (formData: IOrganizationFields) => void;
}

const additionalContextConfig = [
  { prefix: 'phones', model: 'um.organization/phone' },
  { prefix: 'addresses', model: 'um.organization/address' },
  { prefix: 'social_media_profiles', model: 'um.organization/social_media_profile' },
];

interface IUseOrganizationFormReturn {
  formData: IFormData<IOrganizationFields>;
  formHandlers: IFormHandlers<IOrganizationFields>;
  formValues: IOrganizationFields;
  isFormValid: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement> & React.MouseEventHandler<HTMLElement>;
}

export const useOrganizationForm = (): IUseOrganizationFormReturn => {
  const { toastStore } = useStore();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (fields: IOrganizationFields): Promise<void> => {
      try {
        toastStore.info('Creating organization');
        const organization = await saveOrganization(fields);

        toastStore.clearAll();
        toastStore.success('Organization created');
        navigate(`/organizations/${organization.id}`);
      } catch (err) {
        toastStore.error(err.text);
      }
    },
    [toastStore, navigate],
  );

  const {
    formData,
    handlers: formHandlers,
    valid,
    values: formValues,
    onSubmit,
  } = useForm<IOrganizationFields>(
    initialValues,
    Model.ORGANIZATIONS,
    handleSubmit,
    customContext,
    additionalContextConfig,
  );

  return { formData, formHandlers, formValues, isFormValid: valid, onSubmit };
};
