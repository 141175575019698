import React, { useCallback, useMemo } from 'react';

import { removeItemFromFieldList } from 'utils/form';
import { FormDynamicFields } from 'components/form-dynamic-fields';
import { IFormHandlers, IFormData } from 'helpers/form/types';

export interface IFieldListProps<T> {
  label: string;
  handlers: IFormHandlers<T>;
  prefix: string;
  keys: string[];
  values: T;
  formData: IFormData<T>;
  Fields: ({ index, formData, handlers }) => JSX.Element | null;
}

/**
 * The generic typing works by inferring T from either:
 * - values
 * - formData
 * - handlers
 * So if any of them has correct typing others will be checked against them
 *
 * Examples are in
 * - upsert-organization/form.tsx
 * - upsert-contact/step-contact/step-contact.tsx
 *
 * Another way is to use it like this:
 * but it needs implementation `ofType`
 * const ConcreteFieldList = FieldList.ofType<ConcreteType>()
 *
 * example in form/fields/form-select/form-select.tsx
 *
 */
export function FieldList<T extends object>({
  label,
  handlers,
  formData,
  values,
  prefix,
  keys,
  Fields,
}: IFieldListProps<T>): React.ReactElement {
  const isInFieldsList = useCallback((key) => key.startsWith(prefix) && key.endsWith(keys[0]), [prefix, keys]);

  const openFields = useMemo(() => {
    return Object.keys(values).reduce((acc, key) => {
      return isInFieldsList(key) ? [...acc, acc.length] : acc;
    }, []);
  }, [values, isInFieldsList]);

  const removeItem = useCallback(
    (indexToRemove) => {
      const parsedValues = removeItemFromFieldList<T>(prefix, indexToRemove, values);
      handlers.onSetFields(parsedValues);
    },
    [values, prefix, handlers],
  );

  const addItem = useCallback((): void => {
    handlers.onChange(
      keys.reduce(
        (acc, key) => ({
          ...acc,
          [`${prefix}[${openFields.length}].${key}`]: '',
        }),
        {},
      ),
    );
  }, [handlers, keys, prefix, openFields.length]);

  const getItemsProps = useCallback(
    (index) => {
      return {
        index,
        handlers,
        formData,
        isRemoved: Boolean(values[`${prefix}[${index}]._destroy`]),
      };
    },
    [handlers, formData, values, prefix],
  );

  return (
    <FormDynamicFields
      values={openFields}
      label={label}
      Item={Fields}
      itemProps={getItemsProps}
      onAddItem={addItem}
      onRemoveItem={removeItem}
    />
  );
}
