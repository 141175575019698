import { IOmniTreeNode, IOmniTreeSelectOption, IOptionId, IRawTreeOption, ITreeNode, ITreeSelectOption } from './types';

export const createDataTree = (
  dataset: Array<ITreeSelectOption | IOmniTreeSelectOption>,
  formatParentNodes?: ((node: ITreeNode) => ITreeNode) | unknown,
): ITreeNode[] => {
  const hashTable = dataset.reduce((acc, node) => ({ ...acc, [node.value]: node }), {});

  const dataTree: ITreeNode[] = [];
  dataset.forEach((node) => {
    if (node.parentId && hashTable[node.parentId]) {
      const parentNode = hashTable[node.parentId];
      if (Array.isArray(parentNode.children)) {
        parentNode.children.push(hashTable[node.value]);
      } else {
        parentNode.children = [hashTable[node.value]];
      }
    } else {
      dataTree.push(hashTable[node.value]);
    }
  });

  if (typeof formatParentNodes === 'function') {
    return dataTree.map(formatParentNodes as (node: ITreeNode) => ITreeNode);
  }

  return dataTree;
};

export const getParentCheckedNodes = (tree: Array<ITreeNode | IOmniTreeNode>, selectedIds: string[]): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    if (selectedIds.includes(node.value)) {
      return [...acc, node.value];
    }
    return [...acc, ...getParentCheckedNodes(node.children || [], selectedIds)];
  }, []);
};

export const getAllChildrenNodes = (tree?: Array<IOmniTreeNode>): Array<IOmniTreeNode> => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc: Array<IOmniTreeNode>, node) => {
    if (node?.children) {
      return [...acc, ...getAllChildrenNodes(node.children || [])] as Array<IOmniTreeNode>;
    }
    return [...acc, node];
  }, [] as Array<IOmniTreeNode>);
};

export const getChildrenCheckedNodes = (tree: Array<ITreeNode | IOmniTreeNode>, selectedIds: string[]): string[] => {
  if (!Array.isArray(tree)) return [];
  return tree.reduce((acc, node) => {
    if (selectedIds.includes(node.value) && node?.parentId) {
      return [...acc, node.value];
    }
    return [...acc, ...getChildrenCheckedNodes(node.children || [], selectedIds)];
  }, []);
};

export const getAllChildren = (tree?: Array<ITreeNode | IOmniTreeNode>): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    return [...acc, node.value, ...getAllChildren(node.children || [])];
  }, []);
};

export const getAllAncestorNodes = (
  dataset: Array<ITreeSelectOption | IOmniTreeSelectOption>,
  selectedIds: string[],
): string[] => {
  if (!Array.isArray(dataset)) return [];

  return dataset.reduce((acc, node) => {
    if (selectedIds.includes(node.value) && node.parentId) {
      return [...acc, node.parentId, ...getAllAncestorNodes(dataset, [node.parentId])];
    }
    return acc;
  }, []);
};

export const uncheckNode = (tree: ITreeNode[], nodeId: string, parentIds: string[] = []): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    if (node.value === nodeId) {
      return [...acc, ...parentIds, node.value, ...getAllChildren(node.children || [])];
    }
    return [...acc, ...uncheckNode(node.children || [], nodeId, [...parentIds, node.value])];
  }, []);
};

export const getAllCheckedNodes = (
  tree?: Array<ITreeNode | IOmniTreeNode>,
  checked: IOptionId[] | null = [],
): string[] => {
  if (!Array.isArray(tree)) return [];

  // we might have values as strings and numbers
  // so we want to use include with the same types
  // otherwice it will not work
  const checkedStrings = (checked || []).map((el) => `${el}`);

  return tree.reduce((acc, node) => {
    if (checkedStrings.includes(`${node.value}`)) {
      return [...acc, node.value, ...getAllChildren(node.children || [])];
    }
    return [...acc, ...getAllCheckedNodes(node.children || [], checked)];
  }, []);
};

export const tagRenderer = ({ label }: ITreeSelectOption): string => label;

export const parseOption = (op: IRawTreeOption): ITreeSelectOption => {
  return { label: op.name, value: op.id, parentId: op.parent_id, className: 'form-tree-select-option' };
};

export const preparePropOptions = (options?: IRawTreeOption[] | null): ITreeSelectOption[] => {
  return options?.map(parseOption) || [];
};
