import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { validate } from 'uuid';
import { Model } from 'helpers/filters/types';
import { useMm3Form } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import {
  initialValues as emptyConnectionValues,
  IConnectiontFormProps,
  IDeliveryConnection,
  customFormValidator,
} from 'components/delivery/connections';

export const useConnectionCreateForm = ({
  saveConnection,
  values,
}: IConnectiontFormProps): IUseMm3FormReturn<IDeliveryConnection> => {
  const initialValues = {
    ...emptyConnectionValues,
    ...values,
  };

  const form = useMm3Form<IDeliveryConnection>(initialValues, Model.CONNECTION, saveConnection, customFormValidator);

  const {
    handlers: { onChange },
  } = form;

  useEffect(() => {
    if (!values || isEmpty(values)) {
      return;
    }

    onChange({
      ...values,
      id: validate(values.id) ? null : values.id,
    });
  }, [values, onChange]);

  return form;
};
