import flatMapDeep from 'lodash/flatMapDeep';

import { IAssetTreeOption } from 'helpers/form';
import { queryAllProducts, queryProductsWithAssets, queryProductsWithClassificationAssets } from 'utils/apis/product';
import { rearrangeItems } from 'utils/general';
import type { IProduct, ItemId } from 'types';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { ICreateWorkFlowDeliveryPayload, ICreateWorkFlowPayload } from 'utils/actions/types';
import { IMobileAppSyncFormData } from 'components/mobile-app-sync-wizard';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

function getChildren<T>(item: T & { children?: T[] }): T[] {
  if (!item.children?.length) {
    return [item];
  }
  return [item, ...flatMapDeep(item.children, getChildren)];
}

export const makeChildParentTree = <T extends { parent_id?: number | null; id: ItemId; children?: T[] }>(
  data: T[],
): (T & { children?: T[] })[] => {
  const idMapping = data.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  const allIds = Object.keys(idMapping).map((id) => Number(id));

  const roots: T[] = [];
  data.map((el: T) => {
    // Handle the root elements
    if (!el.parent_id || !allIds.includes(el.parent_id)) {
      roots.push(el);
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = data[idMapping[el.parent_id]];
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el];
  });

  return roots;
};

type ITreeOption = IProduct & { children?: IProduct[] };

export const fetchProductsWithAssets = async (
  product_ids?: ItemId[],
  assetType?: string,
): Promise<IAssetTreeOption[]> => {
  if (!product_ids?.length) {
    return [];
  }

  const selectedProducts = await queryAllProducts({ ids: product_ids });
  const sortedProducts = rearrangeItems(selectedProducts, product_ids);
  const tree = makeChildParentTree(sortedProducts);
  const restructuredProducts = flatMapDeep<ITreeOption, IProduct>(tree, getChildren);

  if (!restructuredProducts?.length) {
    return [];
  }

  if (assetType === 'all') {
    return await queryProductsWithAssets(restructuredProducts, false);
  }

  if (assetType === 'recommendation') {
    return await queryProductsWithClassificationAssets(restructuredProducts, {
      marketingUseOnly: true,
      classification: 'video*,document*',
      assetFilters: getRecommendationAssetsFilters(),
    });
  }

  return await queryProductsWithClassificationAssets(restructuredProducts, { marketingUseOnly: true });
};

export const getAssetIdsFromOptions = (assetOptions?: IAssetTreeOption[]): string[] => {
  return (assetOptions || []).reduce((acc, cur) => {
    if (!cur?.parent_ids?.length) {
      return acc;
    }

    return [...acc, String(cur.id)];
  }, []);
};

const separator = '---';
export function serializeIds(ids?: null | ItemId[]): string {
  if (!ids) {
    return '';
  }

  return ids.join(separator);
}

export function deserializeIds(serializedIds: string): number[] {
  if (!serializedIds) {
    return [];
  }

  return serializedIds.split(separator).map((e) => parseInt(e));
}

export const isMobileAppSyncFormData = (
  data:
    | ICreateWorkFlowPayload
    | IMobileAppSyncFormData
    | ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
): boolean => {
  return (data as IMobileAppSyncFormData).include_related_products !== undefined;
};
