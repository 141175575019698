import React from 'react';

import { FormCheckbox } from 'helpers/form';

import type { IAccessPrivilegeProductSettingsFormReturn } from './types';

interface IAccessPrivilegeProductSettingsChangeForm {
  form: IAccessPrivilegeProductSettingsFormReturn;
}

export const AccessPrivilegeProductSettingsChangeForm: React.FC<IAccessPrivilegeProductSettingsChangeForm> = ({
  form,
}) => {
  return (
    <>
      <FormCheckbox
        label="Include the Products’ unpublished Assets"
        name="delegates_access"
        large
        {...form.formData.delegates_access}
        {...form.handlers}
      />
      <FormCheckbox
        label="Permit asset download"
        name="permit_download"
        large
        {...form.formData.permit_download}
        {...form.handlers}
      />
    </>
  );
};
