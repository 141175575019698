import React, { useCallback, useState } from 'react';
import { Tabs } from '@mantine/core';

import { EmptySectionMessage } from 'components/section-message/section-message';
import { GroupDetailProductsTab } from 'components/marketing-entity-detail';
import { DataSectionControls } from 'components/data-section-controls';
import { AddItemsToMobileAppSyncButton } from './tab-buttons';
import { addGroupAssets, deleteGroupAssets } from 'components/marketing-entity-detail/group-asset-tree';
import { MobileAppSyncAssetsTab } from './mobile-app-sync-assets-tab';
import { SortButton } from 'components/sort-buttons';
import { TabButtonsWrapper } from 'components/tabs';
import { MobileSelectionDetailTab, MobileSelectionTabPanel } from './utils';

import { ITitleDividerActionName, Pages } from 'utils/actions';
import { DetailsPageTabs, IGroupAssetItem, IMobileAppSync } from 'types';
import { useRemote } from 'utils/hooks';
import { Model } from 'helpers/filters/types';
import { loadGroupItems } from 'utils/apis/groups';

const options = {
  page: Pages.DETAILS,
  groupName: ITitleDividerActionName.MOBILE_APP_SYNC,
  groupParamName: 'mobileSyncId',
};

interface IMovileAppSyncDetailsTabsView {
  mobileAppSync?: IMobileAppSync;
  editModeEnabled?: boolean;
  currentTab: DetailsPageTabs;
  onTabChangeHandle: (DetailsPageTabs) => void;
  onSuccess?: () => void;
}

export const MobileAppSyncDetailsTabsView: React.FC<IMovileAppSyncDetailsTabsView> = ({
  mobileAppSync,
  currentTab,
  onTabChangeHandle,
  onSuccess,
  editModeEnabled,
}) => {
  const { id } = mobileAppSync || {};
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const [isAssetEditEnabled, setIsAssetEditEnabled] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [isAssetUpdateLoading, setIsAssetUpdateLoading] = useState<boolean>(false);
  const [isCancelClicked, setIsCancelClicked] = useState<boolean>(false);

  const fetchGroupAssets = useCallback(async (): Promise<IGroupAssetItem[]> => {
    if (id) {
      return await loadGroupItems<IGroupAssetItem>({
        group_ids: [id],
        target: Model.ASSET_GROUP_ITEMS,
        allItems: true,
      });
    }
    return [];
  }, [id]);

  const [groupAssets, , setGroupAssets] = useRemote(fetchGroupAssets);

  const resetStatesOnSave = (): void => {
    setIsSaveClicked(false);
    setIsAssetUpdateLoading(false);
    setIsAssetEditEnabled(false);
  };

  const onAssetUpdate = useCallback(
    async (
      assetsToUpdate?: { assetsToAdd?: string[]; assetsToDelete?: string[] },
      assetIdsToGroupIds?: Record<string, string>,
    ): Promise<void> => {
      if (!assetsToUpdate) return;
      const { assetsToAdd = [], assetsToDelete = [] } = assetsToUpdate;
      setIsAssetUpdateLoading(true);
      let deletedAssetsResult;
      let addedAssetsResult;
      if (assetsToAdd.length) addedAssetsResult = await addGroupAssets({ groupId: id, assetsToUpdate: assetsToAdd });
      if (assetsToDelete.length)
        deletedAssetsResult = await deleteGroupAssets({
          groupId: id,
          assetsToUpdate: assetsToDelete,
          assetIdsToGroupIds,
        });

      if (addedAssetsResult || deletedAssetsResult) {
        setGroupAssets((grpAssets) => {
          let newGrpAssets = [...grpAssets];

          newGrpAssets = [...grpAssets, ...(addedAssetsResult || [])];
          if (deletedAssetsResult?.length) {
            const deletedAssetsIds = deletedAssetsResult?.map(({ id }) => id);

            newGrpAssets = newGrpAssets.filter((asset) => !deletedAssetsIds?.includes(asset.id));
          }
          return newGrpAssets;
        });
      }
      resetStatesOnSave();
    },
    [id, setGroupAssets],
  );
  if (!id) {
    return <EmptySectionMessage />;
  }

  return (
    <Tabs value={currentTab} onChange={onTabChangeHandle} className="marketing-entity-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <MobileSelectionDetailTab
          value={DetailsPageTabs.PRODUCTS}
          activeTab={currentTab}
          editModeEnabled={editModeEnabled}
        />
        <MobileSelectionDetailTab
          value={DetailsPageTabs.ASSETS}
          activeTab={currentTab}
          editModeEnabled={editModeEnabled}
        />
        <TabButtonsWrapper>
          <SortButton
            isSortModeEnabled={isSortModeEnabled}
            setIsSortModeEnabled={setIsSortModeEnabled}
            hidden={currentTab !== DetailsPageTabs.PRODUCTS}
          />
          <AddItemsToMobileAppSyncButton
            mobileAppSync={mobileAppSync}
            currentTab={currentTab}
            onSuccess={onSuccess}
            hidden={currentTab !== DetailsPageTabs.PRODUCTS}
          />
          <DataSectionControls hidden={currentTab !== DetailsPageTabs.PRODUCTS} disabled={isSortModeEnabled} />
        </TabButtonsWrapper>
      </Tabs.List>
      <MobileSelectionTabPanel
        value={DetailsPageTabs.PRODUCTS}
        content={
          <GroupDetailProductsTab
            isSortModeEnabled={isSortModeEnabled}
            groupId={id}
            options={{ ...options, page: Pages.MOBILE_APP_SYNC_PRODUCTS_TAB, onSuccess }}
            page={Pages.MOBILE_APP_SYNC_PRODUCTS_TAB}
          />
        }
      />
      <MobileSelectionTabPanel
        value={DetailsPageTabs.ASSETS}
        content={
          <MobileAppSyncAssetsTab
            onAssetUpdate={onAssetUpdate}
            disabled={!isAssetEditEnabled}
            propGroupId={id}
            isSaveClicked={isSaveClicked}
            groupAssets={groupAssets}
            isCancelClicked={isCancelClicked}
            setIsCancelClicked={setIsCancelClicked}
            currentTab={currentTab}
            isAssetEditEnabled={isAssetEditEnabled}
            setIsAssetEditEnabled={setIsAssetEditEnabled}
            setIsSaveClicked={setIsSaveClicked}
            isLoading={isAssetUpdateLoading}
            mobileAppSync={mobileAppSync}
            onSuccess={onSuccess}
          />
        }
      />
    </Tabs>
  );
};
