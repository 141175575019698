import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { DataSectionPreset } from 'components/data-section-preset';
import { customEventParam } from 'components/event-data-section-item';

import { useStore } from 'store';

import { FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { EqFilter, IFiltersDefinition, QFilter, RangeFilter } from 'helpers/filters/types';
import { toggleParam, submitFilters } from 'helpers/filters/helpers';
import { ownersOptionsProvider, useFilters } from 'helpers/filters';

import { parseQueryParams } from 'utils/general';

export interface IScreeningRoomFilters extends IFiltersDefinition {
  _: QFilter;
  updated_at: RangeFilter;
  created_at: RangeFilter;
  owner_id: EqFilter;
}

export const defaultFilters: IScreeningRoomFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
};

const defaultEventParamValues = (queryParams): typeof customEventParam => ({
  include_deleted: queryParams['include_deleted'] === true,
});

export const ScreeningRoomFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const location = useLocation();
  const queryParams = parseQueryParams(location.search);
  const [paramComponentValues, setParamComponentValues] = useState(defaultEventParamValues(queryParams));

  const handleSubmit = useCallback((action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: customEventParam,
    });
  }, []);

  const [filterValues, filterHandlers] = useFilters<IScreeningRoomFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />

          <Switch
            label="Include deleted"
            data-param="include_deleted"
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});
