import React from 'react';
import { UmGenericListItem } from '@mediafellows/mm3-types';

import { IItemActionsMenuProps } from './utils';
import { useListItemActions } from 'utils/actions/list-item';
import { renderMenuActionItems } from 'utils/ui';

export const ListItemActionsList: React.FC<IItemActionsMenuProps<UmGenericListItem>> = (props) => {
  const { items, options } = props;

  const actions = useListItemActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
