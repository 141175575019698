import React, { useCallback, useState } from 'react';
import { toInteger } from 'lodash';

import { useStore } from 'store';
import { createConnection, updateConnection } from 'utils/apis/delivery-connections';

import { ToastError } from 'components/toast';
import { Loading } from 'components/loading';
import {
  IConnectionContainerProps,
  IDeliveryConnection,
  ConnectionForm,
  useConnectionCreateForm,
} from 'components/delivery/connections';

import '../style.scss';

export const ConnectionContainer: React.FC<IConnectionContainerProps> = ({
  connection,
  organizationId: organization_id,
  onFinish,
}) => {
  const { toastStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const handleConfirm = useCallback(
    async (values: IDeliveryConnection): Promise<void> => {
      try {
        if (!values) {
          return;
        }
        setLoading(true);

        const action = values?.id ? updateConnection : createConnection;
        const connection = await action({
          ...values,
          organization_id: toInteger(organization_id),
        });
        onFinish(connection);
        toastStore.success(`Connection was successfully ${values?.id ? 'updated' : 'created'}!`);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }

      setLoading(false);
    },
    [toastStore, organization_id, onFinish],
  );

  const form = useConnectionCreateForm({ values: connection, saveConnection: handleConfirm });

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loading text="validating" />
        </div>
      )}

      <ConnectionForm form={form} />
    </>
  );
};
