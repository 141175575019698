import { IIdentifiable, IGroup } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IAccessPrivilegeActionName {
  CHANGE_ACCESS = 'change-access',
  DELETE = 'delete',
  EMAIL = 'email',
  RESTORE = 'restore',
  EXPIRY = 'group-expiry',
  GRANT_ACCESS_PRIVILEGE = 'grant-access-privilege',
  ADD_ENTITIES = 'add-entities',
}

export type IAccessPrivilegeActionConfig = IActionConfig<IIdentifiable, IAccessPrivilegeActionName>;

export type IShouldDisplayAccessPrivilegeActionFunction = IShouldDisplayActionFunction<
  IGroup,
  IAccessPrivilegeActionName
>;
