import get from 'lodash/get';

export function extractS3Url(httpsUrl: string): string {
  const withoutHttps = httpsUrl.replace('https://', '');
  const urlParts = withoutHttps.split('/');

  // Extract the relevant parts: subdomain, domain, and path
  const subdomain = urlParts[0].split('.')[0];
  const path = urlParts.slice(1).join('/');

  const s3Url = `s3://${subdomain}/${path}`;

  return s3Url;
}

export const getAttachmentsToDelete = <T>(original: T, updated: T, fields: string[]): string[] => {
  const attachmentsToDelete = fields.filter((key) => get(original, key) && get(original, key) !== get(updated, key));
  return attachmentsToDelete.map((key) => {
    const url = get(original, key) || '';
    return extractS3Url(url);
  });
};
