import { useParams } from 'react-router-dom';
import { unassignProductAsset } from 'utils/apis/product-asset';
import { useCallback } from 'react';

import { IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';

import { shouldDisplayUnassign as shouldDisplay } from './actions-acl';

import { IProductActionConfig, IProductActionName } from './types';

export const useUnassignAssetsAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { assetId } = useParams<{ assetId: string }>();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Product', items.length);

  const executeUnassign = useCallback(async () => {
    try {
      if (!assetId) {
        throw new Error('Could not read asset Id from URL!');
      }

      await unassignProductAsset(items.map(({ id }) => ({ asset_id: assetId, product_id: id })));
      if (options?.onSuccess) {
        setTimeout(options.onSuccess, 1000);
      }
      toastStore.success(`${entityWithCount} will be unassigned`);
    } catch (error) {
      toastStore.error(`${entity} Unassign failed: ${error.text || error}`);
      await options?.onFailure?.();
    }
  }, [assetId, entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Unassign',
      body: `Are you sure you want to unassign ${entityWithDemonstrative}?`,
      onConfirm: executeUnassign,
    });
  }, [dialogStore, entityWithDemonstrative, executeUnassign]);

  return {
    name: IProductActionName.UNASSIGN,
    shouldDisplay,
    icon: 'cross',
    title: 'Unassign',
    handler,
  };
};
