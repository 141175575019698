import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { camelCase, values, some, capitalize } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useParams, useLocation } from 'react-router-dom';
import { DetailsPageTabs, IAnalyticsStateProps } from 'types';

import * as productQueries from 'components/analytics/queries/product-queries';
import { getProduct } from 'pages/product-details/product-analytics-tab/api';
import { getProductsAncestry } from 'pages/contact-details/api';
import { AnalyticsInteractionsProductViews as ProductViews } from 'components/analytics-interactions-product-views';
import { AnalyticsInteractionsVideoScreenings as VideoScreenings } from 'components/analytics-interactions-video-screenings';
import { AnalyticsSectionFilters, ProductInteractions, productInteractionOptions } from 'components/analytics';

import { useAnalytics } from 'utils/hooks';
import { IAnalyticsEntityValues } from 'types/analytics';

const ProductAnalyticsTab: React.FC<{}> = observer(() => {
  const { productId: product_id } = useParams<{ productId: string }>();
  const { state }: { state: IAnalyticsStateProps } = useLocation();

  const getMinDate = useCallback(async () => {
    if (!product_id) return;
    const { created_at } = await getProduct(product_id);
    return new Date(created_at);
  }, [product_id]);

  const [interaction, setInteraction] = useState<ProductInteractions>(
    (capitalize(state?.interaction) as ProductInteractions) || ProductInteractions.SCREENINGS,
  );
  const [toggleStatus, setToggleStatus] = useState(true);

  useEffect(() => {
    const fetchProductAncestry = async (productId): Promise<void> => {
      const ancestryInfos = await getProductsAncestry(productId);
      const hasDescendants: boolean = some(ancestryInfos, (ancestry) => {
        return some(values(ancestry), (val) => Number(val) > 0);
      });
      setToggleStatus(!hasDescendants);
    };

    fetchProductAncestry(product_id);
  }, [product_id]);

  const {
    dateRange,
    onDescendantsChange,
    includeDescendants,
    onTerritoriesSelect,
    onContactsSelect,
    territories,
    contactIds,
    onDateRangeChange,
    minDate,
    resetFilters,
  } = useAnalytics({
    getMinDate,
    state,
    interaction,
    tab: DetailsPageTabs.ANALYTICS,
    entity: IAnalyticsEntityValues.PRODUCT,
  });
  const onInteractionChange = useCallback(({ interaction }: { interaction: ProductInteractions }) => {
    setInteraction(interaction || ProductInteractions.VIEWS);
  }, []);

  const ContentComponent = useMemo(() => {
    switch (interaction) {
      case ProductInteractions.SCREENINGS:
        return VideoScreenings;
      case ProductInteractions.VIEWS:
        return ProductViews;
      default:
        return ProductViews;
    }
  }, [interaction]);

  return (
    <div className="analytics-tab">
      <AnalyticsSectionFilters
        interaction={interaction}
        onInteractionChange={onInteractionChange}
        interactionOptions={productInteractionOptions}
        territories={territories}
        onTerritoriesSelect={onTerritoriesSelect}
        handleDescendantsChange={onDescendantsChange}
        includeDescendants={includeDescendants}
        toggleStatus={toggleStatus}
        contactIds={contactIds}
        onContactsSelect={onContactsSelect}
        dateRange={dateRange}
        minDate={minDate}
        handleDateRangeChange={onDateRangeChange}
        resetFilters={resetFilters}
      />
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <ContentComponent
            entity="product"
            entityId={product_id}
            queries={productQueries[camelCase(interaction)]}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={territories}
            userIds={contactIds}
            includeDescendants={includeDescendants}
          />
        </div>
      </div>
    </div>
  );
});

export default ProductAnalyticsTab;
