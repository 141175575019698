import { useCallback } from 'react';
import { map } from 'lodash';

import { useStore } from 'store';

import { shouldDisplayRestore as shouldDisplay } from './actions-acl';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { pluralEntityInflect } from 'utils/general';
import { restoreProducts } from './api';

import { IProduct } from 'types';

export const useRestoreAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { toastStore } = useStore();

  const handler = useCallback(async () => {
    const { entity, entityWithCount } = pluralEntityInflect('related Product', items.length);
    try {
      await restoreProducts(map(items, 'id'));
      toastStore.success(`${entityWithCount} restored`);
      await options?.onSuccess?.(IProductActionName.RESTORE);
      return true;
    } catch (error) {
      toastStore.error(`${entity} restoring failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, toastStore, options]);

  return {
    name: IProductActionName.RESTORE,
    shouldDisplay,
    icon: 'automatic-updates',
    title: 'Restore',
    handler,
  };
};
