import React, { useCallback } from 'react';
import { upperFirst } from 'lodash';
import { IAnalyticsListProps, IGetAnalyticsTableDataParams } from 'types';

import { ListTable } from 'components/list-table';
import { exportAnalyticsTableData, getDetailedAnalyticsTableData } from 'utils/apis/analytics';

import * as listColumns from './columns';

import './style.scss';

const ProductViewsListTable: React.FC<IAnalyticsListProps> = ({
  title,
  entity,
  entityId,
  dateRange,
  queryName,
  userIds,
  geoScopeIds,
  productIds,
  includeDescendants,
  columns,
}) => {
  const columnsToApply = columns ?? listColumns[upperFirst(`${entity}Columns`)];

  const getTableData = useCallback((params: IGetAnalyticsTableDataParams) => {
    return getDetailedAnalyticsTableData(params, true);
  }, []);

  return (
    <ListTable
      title={title}
      entity={entity}
      entityId={entityId}
      from_date={dateRange[0]}
      to_date={dateRange[1]}
      query_name={queryName}
      geo_scope_ids={geoScopeIds}
      user_ids={userIds}
      product_ids={productIds}
      include_descendants={includeDescendants}
      columns={columnsToApply}
      getTableData={getTableData}
      exportTableData={exportAnalyticsTableData}
    />
  );
};

export default ProductViewsListTable;
