export const firstStepValidationKeys = [
  'first_name',
  'last_name',
  'organization_id',
  'function',
  'email',
  'country_id',
  'gender',
  'date_of_birth',
  'role_id',
];
