import React from 'react';
import { FormCheckbox, FormMultiSelect, FormSelect } from 'helpers/form';
import { IOrganizationFormPart } from 'pages/upsert-organization/types';

export const CustomField: React.FC<IOrganizationFormPart> = ({ formHandlers, formData }) => {
  return (
    <>
      <FormSelect name="classification" label="Type" large {...formHandlers} {...formData.classification} />
      <FormCheckbox
        name="is_producer"
        label="This is a Production Company (ITV Label)"
        {...formHandlers}
        {...formData.is_producer}
      />
      <FormMultiSelect
        name="producer_refs"
        label="Producer References"
        {...formHandlers}
        {...formData.producer_refs}
        options={formData.producer_refs?.value}
        large
        allowNewItems
      />
    </>
  );
};
