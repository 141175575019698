import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IBasketStandardized } from 'types';
import { IBasketActionName } from 'utils/actions/basket/types';
import { useShareBasketAction } from 'utils/actions/basket/share-basket-action';
import { useDeleteAction } from 'utils/actions/basket/delete-action';

export const useBasketActions = (
  entities: IBasketStandardized[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IBasketActionName> => {
  const shareBasketAction = useShareBasketAction(entities, options);
  const deleteBasketAction = useDeleteAction(entities, options);

  const actions = [shareBasketAction, deleteBasketAction];

  return useActions(actions, options, entities);
};
