import { ISimpleBasketItem } from 'components/baskets/basket-preview-tab/basket-preview-tab';
import { IBasketStandardized } from 'types';
import { loadListProducts } from 'utils/apis/lists';

export const getSimpleBasketItems = async (basket: IBasketStandardized): Promise<ISimpleBasketItem[]> => {
  switch (basket.entityType) {
    case 'products':
      const product = await loadListProducts({ basket_id: basket.id });
      return product.map((product) => ({ id: product.id, label: product.title }));
  }
  return [];
};
