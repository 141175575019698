import React, { useCallback } from 'react';
import { IconAlias } from 'icons';

import { IAsset, IMm3Asset } from 'types';
import { useStore } from 'store';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { CreateConferenceWizard } from 'components/conference/conference-form';
import { initialValues } from 'utils/conference';
import { IModalSize } from 'components/dialogs/types';
import { getValidIds } from 'utils/general';

import { shouldDisplaySetUpConference as shouldDisplay } from './actions-acl';

export const useSetUpConferenceAction = (items: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const { dialogStore } = useStore();
  const asset_ids = getValidIds(items);

  const handler = useCallback((): void => {
    dialogStore.openModal({
      title: 'Set Up Conference',
      size: IModalSize.XL,
      fitInView: true,
      body: () => <CreateConferenceWizard conference={{ ...initialValues, asset_ids }} onSuccess={dialogStore.close} />,
    });
  }, [asset_ids, dialogStore]);

  return {
    name: IAssetActionName.SET_UP_CONFERENCE,
    icon: IconAlias.CONFERENCE,
    title: 'Set Up Conference',
    shouldDisplay,
    handler,
  };
};
