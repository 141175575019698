import React, { useMemo } from 'react';
import { camelCase, upperCase } from 'lodash';

import { AnalyticsInteractionLogin as ContactLogins } from 'components/analytics-interaction-login';
import { AnalyticsInteractionsVideoScreenings as VideoScreenings } from 'components/analytics-interactions-video-screenings';
import { AnalyticsInteractionsProductViews as ProductViews } from 'components/analytics-interactions-product-views';
import { IAnalyticsPageValues } from 'types';
import { AnalyticsRecommendationOverview } from 'components/analytics-interactions-recommendation';

import * as queries from './queries';
import { IAnalyticsSectionTabProps } from './types';

enum entity {
  CONTACTS = 'user',
  PRODUCTS = 'product',
  ASSETS = 'asset',
  RECOMMENDATION = 'recommendation',
}

const AnalyticsOverviewTab: React.FC<IAnalyticsSectionTabProps> = ({
  section,
  dateRange,
  filtersComponent,
  geoScopeIds,
  userIds: contactIds,
  productIds,
  includeDescendants,
  recipientIds,
  senderIds,
}) => {
  const analyticsEntity = entity[upperCase(section.split('_')[1])];
  const analyticsQuery = queries[camelCase(section)];

  const ContentComponent = useMemo(() => {
    switch (section) {
      case IAnalyticsPageValues.ANALYTICS_PRODUCTS:
        return ProductViews;
      case IAnalyticsPageValues.ANALYTICS_ASSETS:
        return VideoScreenings;
      case IAnalyticsPageValues.ANALYTICS_RECOMMENDATION:
        return AnalyticsRecommendationOverview;
      default:
        return ContactLogins;
    }
  }, [section]);

  return (
    <div className="analytics-tab">
      {filtersComponent}
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <ContentComponent
            entity={analyticsEntity}
            queries={analyticsQuery}
            geoScopeIds={geoScopeIds}
            userIds={contactIds}
            productIds={productIds}
            includeDescendants={includeDescendants}
            dateRange={dateRange as [Date, Date]}
            recipientIds={recipientIds}
            senderIds={senderIds}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsOverviewTab;
