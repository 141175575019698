import React from 'react';
import { FormCheckbox, FormColorPicker, FormNumericInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { FormImage, FormInput } from 'helpers/form';
import { ICategory } from 'types';
import { FormSubmitSection } from 'components/form-submit-section';

export interface IGenreFormData extends Partial<ICategory> {
  previewImageUrl?: string;
  parentName?: string;
}

interface IGenreFormProps {
  genre?: ICategory;
  handleSubmit: (genre: IGenreFormData) => Promise<ICategory | void>;
  parentName?: string;
  parentId?: number;
}

const initialValues: IGenreFormData = {
  name: '',
  previewImageUrl: '',
  color: '',
  sequence_no: undefined,
  invisible: false,
  parentName: '',
  parent_id: undefined,
};

const GenreForm: React.FC<IGenreFormProps> = ({ genre, handleSubmit, parentName, parentId }) => {
  const initValues = genre
    ? { ...genre, previewImageUrl: genre.preview_image?.url }
    : parentName && parentId
    ? { ...initialValues, parentName: parentName, parent_id: parentId }
    : initialValues;

  const { handlers, formData, onSubmit, valid, values } = useForm<IGenreFormData>(
    initValues,
    Model.GENRES,
    handleSubmit,
  );
  const isEditMode = Boolean(genre?.id);

  return (
    <form className="d-flex flex-column justify-content-between h-100" onSubmit={onSubmit}>
      <FormInput name="name" label="Genre name" {...handlers} {...formData?.name} />
      {parentId && <FormInput name="parent_id" label="Parent" {...formData.parentName} {...handlers} disabled />}
      <FormNumericInput name="sequence_no" label="Sequence number" {...formData.sequence_no} {...handlers} />
      <FormColorPicker
        name="color"
        label="Сolor"
        {...formData.color}
        {...handlers}
        type="color"
        value={values?.color}
      />
      <FormCheckbox name="invisible" label="Invisible" {...formData.invisible} {...handlers} large className="mt-2" />
      <FormImage
        name="previewImageUrl"
        label="Preview Image"
        className="py-3"
        {...formData.previewImageUrl}
        {...handlers}
      />
      <FormSubmitSection labels={{ confirm: isEditMode ? 'Save' : 'Create' }} submitDisabled={!valid} />
    </form>
  );
};

export { GenreForm };
