import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { IBarChartData } from 'types';
import { ChartContainer } from 'components/chart-container';

interface IPieChartProps {
  data: IBarChartData[];
  aspectRatio: number;
  unit?: string;
}

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart: React.FC<IPieChartProps> = ({ data, aspectRatio, unit }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio,
    resizeDelay: 50,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label;
            const value = context.formattedValue;

            label = unit ? label + ': ' + (value + unit) : label + ': ' + value;

            return label;
          },
        },
      },
    },
  };

  const chartData = {
    labels: data.map(({ group_name }) => group_name ?? 'Others'),
    datasets: [
      {
        data: data.map(({ count }) => count),
        backgroundColor: [
          'rgba(2, 160, 237, 0.3)',
          'rgba(197, 207, 33, 0.3)',
          'rgba(192, 154, 255, 0.3)',
          'rgba(57, 188, 59, 0.3)',
          'rgba(202, 142, 0, 0.3)',
          'rgba(255, 55, 123, 0.3)',
          'rgba(2, 169, 142, 0.3)',
          'rgba(193, 70, 0, 0.3)',
          'rgba(215, 181, 132, 0.3)',
          'rgba(126, 66, 55, 0.3)',
        ],
        borderColor: [
          'rgba(2, 160, 237, 1)',
          'rgba(197, 207, 33, 1)',
          'rgba(192, 154, 255, 1)',
          'rgba(57, 188, 59, 1)',
          'rgba(202, 142, 0, 1)',
          'rgba(255, 55, 123, 1)',
          'rgba(2, 169, 142, 1)',
          'rgba(193, 70, 0, 1)',
          'rgba(215, 181, 132, 1)',
          'rgba(126, 66, 55, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ChartContainer aspectRatio={aspectRatio}>
      <Pie data={chartData} options={options} />
    </ChartContainer>
  );
};
export default PieChart;
