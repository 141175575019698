import React, { useCallback } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';

import { querySentRecommendationRecipients } from 'utils/apis/recommendation';

import {
  IRecommendationRecipientContact,
  recommendationRecipientcolumns,
  sentRecommendationRecipientcolumns,
} from './utils';

import './style.scss';
import { queryContacts } from 'utils/apis/contacts';
import { by } from 'utils/general';
import { useRemote } from 'utils/hooks';

interface IRecommendationRecipientsTabProps {
  recommendation?: McGenericRecommendationProduct;
}

const RecommendationRecipientsTab: React.FC<IRecommendationRecipientsTabProps> = ({ recommendation }) => {
  const { id, recipient_list, status } = recommendation || {};
  const recipientsFetcher = useCallback(async () => {
    if (status === 'sent') {
      if (!id) return [];
      const recipients = await querySentRecommendationRecipients({ ids: [id] });
      const formattedRecipients = recipients.reduce(
        (acc, recipient) => {
          return recipient.recipient_type === 'user'
            ? { ...acc, internalRecipients: [...acc.internalRecipients, recipient] }
            : { ...acc, emailRecipient: [...acc.emailRecipient, { ...recipient, recipient_id: recipient.email }] };
        },
        { internalRecipients: [], emailRecipient: [] },
      );
      const internalIds = formattedRecipients.internalRecipients.map((recipient) => recipient?.owner_id);
      const contactsDetails = internalIds?.length
        ? await queryContacts({ ids: internalIds, include_deleted: true })
        : [];

      const internalRecipientsById = by(formattedRecipients.internalRecipients, 'owner_id');
      const contacts = (contactsDetails || []).map((contact) => {
        const contactRecipient = internalRecipientsById?.[contact?.id];
        return { ...contactRecipient, ...contact };
      });
      return [...contacts, ...formattedRecipients.emailRecipient];
    } else {
      const formattedRecipients = (recipient_list || []).reduce(
        (acc, recipient) => {
          return recipient.recipient_type === 'user'
            ? { ...acc, internalIds: [...acc.internalIds, recipient.recipient_id] }
            : { ...acc, emailRecipient: [...acc.emailRecipient, recipient] };
        },
        { internalIds: [], emailRecipient: [] },
      );
      const internalIds = formattedRecipients.internalIds;
      const contacts = internalIds?.length ? await queryContacts({ ids: internalIds, include_deleted: true }) : [];
      return [...contacts, ...formattedRecipients.emailRecipient];
    }
  }, [id, recipient_list, status]);

  const [detailedRecipients, loading] = useRemote(recipientsFetcher);
  if (!loading && !detailedRecipients?.length) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable
        data={detailedRecipients as IRecommendationRecipientContact[]}
        columns={status === 'sent' ? sentRecommendationRecipientcolumns : recommendationRecipientcolumns}
        loading={loading}
        tableClassName="recommendation-recipients__table"
      />
    </div>
  );
};

export default RecommendationRecipientsTab;
