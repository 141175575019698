import React from 'react';
import { capitalize } from 'lodash';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { getContactName } from 'utils/ui';
import { ICalGenericMeeting } from 'types/calendar';

import { InviteesDisplay } from './ui';
import DateListElement from 'components/date-list-element';
import { changeTimeZone } from 'helpers/form/fields/helpers';
import { getTotalAttendeesCount, MeetingTypes } from './utils';

import './style.scss';

export const MeetingPreview: React.FC<ICalGenericMeeting & { padded?: boolean }> = ({ padded = false, ...meeting }) => {
  const {
    description,
    ends_at,
    host,
    mobile_sync_group,
    starts_at,
    time_zone,
    invites,
    location,
    title,
    additional_seats,
    basket,
    type,
    place,
    meta,
  } = meeting;
  {
    return (
      <InfoBoxV2 gridTemplateColumns="190px 1fr" padded={padded}>
        <Row key="title" label="Title" value={title} />
        <Row key="start" label="Start" value={<DateListElement date={changeTimeZone(starts_at, time_zone)} />} />
        <Row key="end" label="End" value={<DateListElement date={changeTimeZone(ends_at, time_zone)} />} />
        <Row key="timezone" label="Timezone" value={time_zone} />
        <Row key="type" label="Type" value={capitalize(type?.split('::')[1])} />
        <Row key="host" label="Host" value={getContactName(host)} />
        <Row rawValue key="Invitees" label="Invitees" value={<InviteesDisplay invites={invites} />} />
        <Row key="additional_seats" label="No. of additional attendees" value={additional_seats || 0} />
        <Row label="Total number of attendees" value={getTotalAttendeesCount(meeting)} />
        {type === MeetingTypes.PERSONAL ? (
          <Row key="place" label="Market Location" value={place} fallback="N/A" />
        ) : (
          <Row key="location" label="Market Location" value={location?.name} fallback="N/A" />
        )}
        <Row key="mobile_sync_group" label="Mobile Selection" value={mobile_sync_group?.name} fallback="N/A" />
        <Row key="basket" label="Basket" value={basket?.name} fallback="N/A" />
        <Row key="description" label="Description" value={description} valueClassName="meeting-preview__description" />
        {type === MeetingTypes.PERSONAL && <Row key="tags" label="Tags" value={meta?.tags} />}
      </InfoBoxV2>
    );
  }
};
