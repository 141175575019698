import React from 'react';
import { ChartContainer } from 'components/chart-container';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import { Wordcloud } from '@visx/wordcloud';
import { ScaleLogarithmic } from '@visx/vendor/d3-scale';

import './style.scss';

interface IWordCloudProps {
  data: { search_terms: string; term_count: number }[];
  aspectRatio?: number;
}

export interface IWordData {
  text: string;
  value: number;
}

const colors = ['#8ca6b7', '#2F6B9A', '#82a6c2'];

const DataWordCloudChart: React.FC<IWordCloudProps> = ({ data, aspectRatio = 1.2 }) => {
  const words = data.map((d) => ({ text: d.search_terms as string, value: d.term_count }));

  const fontScale: ScaleLogarithmic<number, number, never> = scaleLog<number>({
    domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
    range: [10, 100],
  });
  const fontSizeSetter = (datum: IWordData): number => fontScale(datum.value);

  return (
    <ChartContainer aspectRatio={aspectRatio}>
      <Wordcloud
        words={words}
        width={400}
        height={330}
        fontSize={fontSizeSetter}
        font="Impact"
        padding={2}
        spiral="archimedean"
        rotate={0}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </ChartContainer>
  );
};

export default DataWordCloudChart;
