import React from 'react';

import { FormSelect, FormInput, FormCountry, useFieldsContextInit } from 'helpers/form';
import { IJsonLDProperty, IJsonLDSpec } from '@mediafellows/chipmunk';
import { IFormHandlers, IFormData } from 'helpers/form/types';
import { IDeepPartial } from 'types';
import { Classes } from 'utils/ui';

export interface IFormAddressProps<T> {
  formData: IFormData<T>;
  label?: string;
  name: string;
  separator?: string;
  large?: boolean;
  disableAddressType?: boolean;
  handlers: IFormHandlers<T>;
}

const generateCountryContext = (): Partial<IJsonLDProperty> => {
  const result = {};
  for (let index = 0; index < 6; index++) {
    result[`addresses[${index}].country_id`] = { required: true };
  }

  return result;
};

export const customContext = {
  properties: generateCountryContext(),
} as IDeepPartial<IJsonLDSpec>;

export function FormAddress<T>(props: IFormAddressProps<T>): JSX.Element {
  const { large = false, disableAddressType, name = 'addresses', separator = '.', label, formData, handlers } = props;
  const preset = name + separator;

  useFieldsContextInit<T>(
    formData,
    handlers,
    ['label', 'street', 'supplement', 'zip_code', 'city', 'country_id'].map((key) => `${preset}${key}`),
  );

  return (
    <>
      {label ? <div className={`form-dynamic-fields__title ${Classes.TEXT_MUTED}`}>{label}</div> : null}
      <>
        {disableAddressType || (
          <FormSelect
            name={`${preset}label`}
            label="Type"
            large={large}
            {...formData[`${preset}label`]}
            {...handlers}
          />
        )}
        <FormInput
          name={`${preset}street`}
          label="Street"
          type="text"
          large={large}
          {...formData[`${preset}street`]}
          {...handlers}
        />
        <FormInput
          name={`${preset}supplement`}
          label="Supplement"
          type="text"
          large={large}
          {...formData[`${preset}supplement`]}
          {...handlers}
        />
        <FormInput
          name={`${preset}zip_code`}
          label="Zip Code"
          type="text"
          large={large}
          {...formData[`${preset}zip_code`]}
          {...handlers}
        />
        <FormInput
          name={`${preset}city`}
          label="City"
          type="text"
          large={large}
          {...formData[`${preset}city`]}
          {...handlers}
        />
        <FormCountry
          name={`${preset}country_id`}
          label="Country"
          large
          {...formData[`${preset}country_id`]}
          {...handlers}
        />
      </>
    </>
  );
}
