import React, { useCallback } from 'react';
import { AmGenericNews } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

import { INewsActionConfig, INewsActionName } from './types';
import { ToastError } from 'components/toast';
import { deleteNewsPreviewImage } from './apis';

export const useDeletePreviewAction = (
  items: AmGenericNews[],
  options: IUseActionsOptionParam<AmGenericNews>,
): INewsActionConfig => {
  const item = items[0];
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const updatedCollection = await deleteNewsPreviewImage(item);
      toastStore.success('Successfully deleted preview image');
      options?.onSuccess?.(INewsActionName.DELETE_PREVIEW, { ...item, ...updatedCollection });
      dialogStore.close();
    } catch (error) {
      options?.onFailure?.();
      toastStore.error(<ToastError error={error} placeholder={`Something went wrong when deleting preview image`} />);
    }
  }, [dialogStore, item, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: `Delete preview image`,
      body: 'Are you sure you want to delete this preview image?',
      onConfirm,
    });
  };

  return {
    name: INewsActionName.DELETE_PREVIEW,
    shouldDisplay: () => options.context === 'single' && item.preview_image_id !== null,
    icon: 'delete',
    title: 'Delete Preview',
    intent: Intent.DANGER,
    handler,
  };
};
