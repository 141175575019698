import { chipmunk, IResult } from 'utils/chipmunk';
import { logger } from 'utils/logger';
import { deliveryPackageSchema } from 'utils/schemas/delivery-package';
import { map, uniq, filter, isNull } from 'lodash';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { Model } from 'helpers/filters/types';
import { IThumbnailImage, ItemId } from 'types';
import type { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

import { queryProducts } from 'utils/apis/product';
import { getFutureDateTime } from 'components/delivery/utils';

export const sendDeliveryPackage = <T extends DeliveriesGenericDeliveryPackage>(
  delivery: Partial<T>,
): Promise<DeliveriesGenericDeliveryPackage> => {
  return chipmunk.run(async ({ action }) => {
    if (!delivery) {
      return [];
    }
    const { object } = await action(Model.DELIVERY_PACKAGE, 'deliver', {
      params: { delivery_packages_ids: [delivery.id] },
    });

    return object;
  });
};

export const createDeliveryDraft = (
  delivery: Partial<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): Promise<DeliveriesGenericDeliveryPackage> => {
  return chipmunk.run(async ({ action }) => {
    const scheduled_at = delivery.scheduled_at ?? getFutureDateTime(5);

    const { object } = await action(Model.DELIVERY_PACKAGE, 'create', {
      body: { ...delivery, scheduled_at },
    });

    return object;
  });
};

export const updateDeliveryDraft = (
  delivery: Partial<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): Promise<DeliveriesGenericDeliveryPackage> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.DELIVERY_PACKAGE, 'update', {
      params: { id: delivery.id },
      body: delivery,
    });

    return object;
  });
};

export const loadDeliveryThumbnails = async (
  delivery?: DeliveriesGenericDeliveryPackage,
): Promise<IThumbnailImage[]> => {
  if (!delivery?.id) {
    return [];
  }
  const { delivery_items } = delivery;
  const productIds = uniq(
    map(
      filter(delivery_items, (item) => !isNull(item.product_id)),
      'product_id',
    ),
  );

  if (!productIds?.length) {
    return [];
  }

  const ids = <number[]>productIds.slice(0, 3);
  const products = await queryProducts({ ids });

  return products.reduce((acc, product) => {
    const url = product?.inherited_preview_image?.url;
    return url ? [{ image: url }, ...acc] : acc;
  }, []);
};

export const loadDelivery = async (deliveryId?: ItemId): Promise<DeliveriesGenericDeliveryPackage> => {
  return chipmunk.run(async ({ action }) => {
    try {
      if (!deliveryId) {
        return;
      }
      const { object } = await action(Model.DELIVERY_PACKAGE, 'get', {
        params: {
          id: deliveryId,
        },
        schema: deliveryPackageSchema,
      });
      return object;
    } catch (e) {
      logger.error(e);
      return null;
    }
  });
};

export const deleteDelivery = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.DELIVERY_PACKAGE, 'delete', {
      params: { id: ids },
    });
  });
};

export const cancelDelivery = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.DELIVERY_PACKAGE, 'cancel', {
      params: { id: ids },
    });
  });
};

export const copyDeliveryPackage = ({
  name = '',
  ...delivery
}: DeliveriesGenericDeliveryPackage): Promise<DeliveriesGenericDeliveryPackage> => {
  return createDeliveryDraft({ ...delivery, name: `${name} (Copy)` });
};

export const loadDeliveries = async (filter: Array<string>): Promise<DeliveriesGenericDeliveryPackage[]> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { objects } = await action(Model.DELIVERY_PACKAGE, 'search', {
        body: {
          search: {
            filters: [filter],
          },
        },
        schema: deliveryPackageSchema,
      });
      return objects;
    } catch (e) {
      logger.error(e);
      return null;
    }
  });
};
