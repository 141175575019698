import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { IShouldDisplayScreeningRoomActionFunction } from './types';

export const shouldDisplayEmail: IShouldDisplayScreeningRoomActionFunction = (action, index, user, entities) => {
  return Boolean(entities?.length === 1 && areAllNotDeleted(entities));
};

export const shouldDisplayDeletePreviewAction: IShouldDisplayScreeningRoomActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities?.length > 0 &&
    options.context === 'single' &&
    entities[0]?.preview_image !== null &&
    areAllNotDeleted(entities)
  );
};
