export const initialValues = {
  id: '',
  name: '',
  access_level: '',
  created_at: '',
  updated_at: '',
  event_starts_at: null,
  event_ends_at: null,
  purpose: '',
  event_password: '',
  asset_ids: [],
  approved_emails: '',
  registered_emails: '',
  is_timed_event: true,
  show_event_countdown: false,
  use_zip_source: false,
  allow_registered_user_access: false,

  users_count: 0,
  pm_statistics_data: { count: 0 },
  am_statistics_data: { count: 0 },

  event_not_started_headline: '',
  event_started_loggedin_headline: '',
  event_started_not_loggedin_headline: '',
  event_finished_headline: '',
  event_not_started_introduction: '',
  event_started_not_loggedin_introduction: '',
  event_started_loggedin_introduction: '',
  event_finished_introduction: '',
  product_ids: [],
  group_preview_image_url: null,
  group_parent_group_id: null,
  subevent: false,

  text_color: '#ffffff',
  standalone: false,
  location: '',
  eventTiming: 'timed',
  eventType: 'mainevent',
};
