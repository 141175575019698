import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { ArrowLeft } from 'blueprint5-icons';

import cx from 'classnames';

import { MantineIcon } from 'utils/ui/icon';
import { Page } from 'components/layout';
import { useWizardSteps, Wizard, WizardStep } from 'components/wizard';
import { FormActionSection } from 'components/form-submit-section';

import { isValid } from 'helpers/form';
import { useOrganizationForm } from './form';
import { StepIds } from './utils';

import { FormKeyData, keyDataKeys } from 'pages/upsert-organization/form-key-data';
import { FormContacts, contactsDataKeys } from 'pages/upsert-organization/form-contacts';
import { FormAddresses, addressesDataKeys } from 'pages/upsert-organization/form-addresses';
import { FormFinancials, financialsDataKeys } from 'pages/upsert-organization/form-financials';
import { FormDefaults } from 'pages/upsert-organization/form-defaults';

import './style.scss';

export const UpsertOrganizationForm: React.FC = observer(() => {
  const { formData, formHandlers, formValues, onSubmit } = useOrganizationForm();

  const step = [
    {
      id: StepIds.Step1,
      disabled: !isValid(formData, keyDataKeys),
    },
    {
      id: StepIds.Step2,
      disabled: !isValid(formData, contactsDataKeys),
    },
    {
      id: StepIds.Step3,
      disabled: !isValid(formData, addressesDataKeys),
    },
    {
      id: StepIds.Step4,
      disabled: !isValid(formData, financialsDataKeys),
    },
    {
      id: StepIds.Step5,
      action: onSubmit,
      disabled: !isValid(formData),
      nextButtonTitle: 'Create',
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(step);

  return (
    <Page title="Create New Organization">
      <Wizard
        selectedStepId={currentStepId}
        onChange={setCurrentStepId}
        footerPadded={false}
        className="upsert-organization__content"
        fitInParent
        footer={
          <FormActionSection className="d-flex justify-content-between w-100">
            <Button
              className={cx('m-0', { invisible: backButton.hide })}
              onClick={backButton.action}
              disabled={backButton.hide}
              variant="subtle"
              leftSection={<MantineIcon icon={<ArrowLeft />} />}
            >
              {backButton.title}
            </Button>
            <Button className="m-0" variant="primary" onClick={nextButton.action} disabled={nextButton.disabled}>
              {nextButton.title}
            </Button>
          </FormActionSection>
        }
      >
        <WizardStep
          id={StepIds.Step1}
          title="Key data"
          icon="person"
          disabled={isStepDisabled(StepIds.Step1)}
          panel={<FormKeyData formData={formData} formHandlers={formHandlers} formValues={formValues} />}
        />
        <WizardStep
          id={StepIds.Step2}
          title="Contacts"
          icon="phone"
          disabled={isStepDisabled(StepIds.Step2)}
          panel={<FormContacts formData={formData} formHandlers={formHandlers} formValues={formValues} />}
        />
        <WizardStep
          id={StepIds.Step3}
          title="Addresses"
          disabled={isStepDisabled(StepIds.Step3)}
          icon="home"
          panel={<FormAddresses formData={formData} formHandlers={formHandlers} formValues={formValues} />}
        />
        <WizardStep
          id={StepIds.Step4}
          title="Financial"
          icon="euro"
          disabled={isStepDisabled(StepIds.Step4)}
          panel={<FormFinancials formData={formData} formHandlers={formHandlers} formValues={formValues} />}
        />
        <WizardStep
          id={StepIds.Step5}
          title="Defaults"
          icon="settings"
          disabled={isStepDisabled(StepIds.Step5)}
          panel={<FormDefaults formData={formData} formHandlers={formHandlers} formValues={formValues} />}
        />
      </Wizard>
    </Page>
  );
});
