import React from 'react';

import { IContact } from 'types';
import { CategoriesInfo } from 'utils/ui';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';

export const PreferencesSection: React.FC<IContact> = ({ genre_preferences_ids }) => {
  return (
    <InfoBoxV2 gridTemplateColumns="200px 1fr" wrapperClassName="py-3" title="Preferences" withBorder>
      <Row label="Genres" value={<CategoriesInfo category_ids={genre_preferences_ids} expanded />} />
    </InfoBoxV2>
  );
};
