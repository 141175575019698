import React from 'react';
import { Tooltip } from '@mantine/core';

import { IColumn } from 'components/list-table';

import { IAudioTrack } from 'types';
import { formatType } from 'utils/format-type';
import { Position } from 'utils/ui/position';
import { ClipboardView } from 'components/clipboard-view';

export const columns: IColumn<IAudioTrack>[] = [
  {
    title: 'File Name',
    renderer: ({ storage_url }) => {
      const fileName = storage_url?.split('/').pop();
      return (
        <Tooltip key={storage_url} label={fileName} position={Position.TOP}>
          <div className="text-truncate">{fileName}</div>
        </Tooltip>
      );
    },
    columnClassName: 'text-truncate',
  },
  {
    title: 'Status',
    renderer: ({ status }) => status,
    columnClassName: 'text-capitalize',
  },
  {
    title: 'Type',
    renderer: ({ ['@type']: type }) => formatType(type, '/'),
  },
  {
    title: 'Format',
    renderer: ({ storage_url }) => formatType(storage_url, '.'),
  },
  {
    title: 'Storage URL',
    renderer: ({ storage_url }) => <ClipboardView textClassName="text-truncate" value={storage_url} />,
  },
];
