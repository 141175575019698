import React from 'react';
import { IconName } from 'blueprint5-icons';

export interface IWizardStepProps {
  id: number;
  title: string;
  description?: string;
  icon: IconName;
  disabled?: boolean;
  panel: JSX.Element;
  loading?: boolean;
}

const WizardStep: React.FC<IWizardStepProps> = (props) => {
  const { panel } = props;
  return <div>{panel}</div>;
};

export default WizardStep;
