import React from 'react';
import { McGenericRecommendation } from '@mediafellows/mm3-types';
import { isBefore, addMonths, addHours, addYears } from 'date-fns';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions';
import { updateRecommendation } from 'utils/apis/recommendation';
import { IRecommendationActionName } from 'utils/actions/recommendation/types';
import { FormDate, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { FormSubmitSection } from 'components/form-submit-section';
import { ToastError } from 'components/toast';
import { ISODateFormat, formatDate, today } from 'utils/date';

const defaultExpires = formatDate(addMonths(today, 1), ISODateFormat);

export const RecommendationExpiryForm: React.FC<{
  recommendation: McGenericRecommendation;
  options?: IUseActionsOptionParam<McGenericRecommendation>;
}> = ({ recommendation, options }) => {
  const { dialogStore, toastStore } = useStore();
  const handleSubmit = async ({ id, expires_at }: McGenericRecommendation, valid: boolean): Promise<void> => {
    if (!valid) {
      return;
    }
    try {
      const recommendation = await updateRecommendation({ id, expires_at });
      toastStore.success('Recommendation expiry has been updated');
      dialogStore.close();
      await options?.onSuccess?.(IRecommendationActionName.EDIT_EXPIRY, recommendation);
    } catch (error) {
      await options?.onFailure?.();
      toastStore.error(<ToastError error={error} />);
    }
  };
  const initialDate =
    recommendation?.expires_at && isBefore(new Date(recommendation.expires_at), today)
      ? defaultExpires.toString()
      : recommendation.expires_at;

  const initialValues = { ...recommendation, expires_at: initialDate };
  const { formData, onSubmit, handlers, values } = useMm3Form(initialValues, Model.RECOMMENDATIONS, handleSubmit);

  return (
    <form onSubmit={onSubmit}>
      <FormDate
        label="Expires at"
        name="expires_at"
        withTime
        {...formData.expires_at}
        {...handlers}
        minDate={addHours(today, 1)}
        maxDate={addYears(today, 10)}
      />
      <FormSubmitSection submitDisabled={!values.expires_at} />
    </form>
  );
};
