import React from 'react';

import { isProduct } from 'utils/general';

import { ScheduledChangeIndicatorAsset, IAvailableType } from './scheduled-change-indicator-asset';
import { ScheduledChangeIndicatorProduct } from './scheduled-change-indicator-product';
import { ICommonEntity } from './types';

interface IScheduledChangeIndicator {
  entity?: ICommonEntity & {
    access_change_jobs_count?: number | null;
    scheduled_jobs_count?: number | null;
  };
  type?: IAvailableType;
  className?: string;
}

export const ScheduledChangeIndicator: React.FC<IScheduledChangeIndicator> = ({ entity, type, className }) => {
  return isProduct(entity) ? (
    <ScheduledChangeIndicatorProduct entity={entity} className={className} />
  ) : (
    <ScheduledChangeIndicatorAsset entity={entity} type={type} className={className} />
  );
};
