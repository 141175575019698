import React, { useCallback } from 'react';
import { map } from 'lodash';
import { IAssetToProduct, IProduct } from 'types';

import { AssetProductForm } from 'components/assign-asset-to-product-form';

import { useStore } from 'store';
import { assignAssetsToProducts } from 'utils/actions/asset';

import { shouldDisplayAssignToProduct as shouldDisplay } from './actions-acl';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { IUseActionsOptionParam } from '..';
import { pluralEntityInflect } from 'utils/general';
import { IModalSize } from 'components/dialogs/types';

export const useAssignAssetsToProductAction = (
  items: IProduct[],
  options: IUseActionsOptionParam,
): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const productIds = map(items, 'id');

  const handleSubmit = useCallback(
    async (values: IAssetToProduct) => {
      const { entity: assetPluralized, entityWithCount } = pluralEntityInflect('Asset', values.asset_ids.length);
      const { entity: productPluralized } = pluralEntityInflect('Product', productIds.length);

      try {
        await assignAssetsToProducts(values);
        toastStore.success(`${entityWithCount} will be assigned`);

        if (options?.onSuccess) {
          setTimeout(options.onSuccess, 1000, IProductActionName.ASSIGN_ASSET_TO_SELECTION, values);
        }

        return dialogStore.close();
      } catch (e) {
        toastStore.error(`Error happened while assigning ${assetPluralized} to ${productPluralized}`);
      }
    },
    [productIds.length, toastStore, options.onSuccess, dialogStore],
  );

  const body = useCallback(() => {
    return <AssetProductForm productIds={productIds} onSubmit={handleSubmit} />;
  }, [productIds, handleSubmit]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Assign asset to product',
      size: IModalSize.S,
      body,
    });
  };

  return {
    name: IProductActionName.ASSIGN_ASSET_TO_SELECTION,
    shouldDisplay,
    icon: 'group-objects',
    title: 'Assign assets',
    handler: openDialog,
  };
};
