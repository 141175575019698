import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { isValid } from 'date-fns';

import {
  RecommendationAnalyticsListTable,
  parseRecommendationDataForMultiBarChart as parseData,
  RecommendationAnalyticsTopUsers,
  DateCountMultiBarChart,
  CountGaugeChart,
  IChartContext,
  ContactInteractions,
} from 'components/analytics';
import { SectionHeader } from 'components/section-header';
import { recommendationReceivedColumns } from './recommendation-recieved-columns';

import { getHistogramData } from 'utils/apis/analytics';
import { IAnalyticsProps, IRecommendationHistogramData, IBarChartData, DetailsPageTabs } from 'types';
import { Routes } from 'utils/routes';

const AnalyticsInteractionsRecommendationsReceived: React.FC<IAnalyticsProps> = (props) => {
  const [countByUniqueRecommendationReceived, setCountByUniqueRecommendationReceived] = useState([] as IBarChartData[]);
  const [countByUniqueRecommendationViewed, setCountByUniqueRecommendationViewed] = useState([] as IBarChartData[]);
  const [engagementRate, setEngagementRate] = useState([] as number[]);
  const [countByEngagementRate, setCountByEngagementRate] = useState<IRecommendationHistogramData>();

  useEffect(() => {
    if (!props?.dateRange.every(isValid)) {
      return;
    }
    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationReceivedCount,
      ...props,
      entity: 'recipient',
    }).then(({ data }) => {
      const { viewedData, sentData } = parseData(data);
      setCountByUniqueRecommendationReceived(sentData);
      setCountByUniqueRecommendationViewed(viewedData);
    });

    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationReceivedEngagementRate,
      ...props,
      entity: 'recipient',
    }).then(({ data }) => {
      if (!data.length) {
        setEngagementRate([]);
      } else {
        const engagementRate = data?.[0].success_percentage || 0;
        const parsedData = [engagementRate, 100 - engagementRate];

        setEngagementRate(parsedData);
        setCountByEngagementRate(data?.[0]);
      }
    });
  }, [props]);

  const topSenders = useMemo(
    (): JSX.Element => (
      <RecommendationAnalyticsTopUsers
        queryName={props?.queries?.recommendationTopSenders}
        {...{ ...props, entity: 'recipient' }}
        entityName="sender"
        title="Top Senders"
        statisticName="sent"
        interaction={ContactInteractions.RECOMMENDATIONS_SENT}
        url={`${Routes.ANALYTICS_RECOMMENDATIONS}/${DetailsPageTabs.LIST}`}
      />
    ),
    [props],
  );

  const customTooltipFormatter = useCallback(
    (context: IChartContext): string => {
      const label = context.label;
      if (label === 'received') return `${countByEngagementRate?.sent_count} total received`;
      return `${countByEngagementRate?.viewed_count} viewed at least once`;
    },
    [countByEngagementRate],
  );
  return (
    <>
      <div className="analytics-tab__content">
        <div className="analytics-tab__card">
          <SectionHeader title="Recommendations" useBackground useBorder />
          <div className="analytics-tab__card-content">
            <DateCountMultiBarChart
              data1={countByUniqueRecommendationReceived}
              data2={countByUniqueRecommendationViewed}
              startDate={props.dateRange[0]}
              endDate={props.dateRange[1]}
              unit1="received"
              unit2="view(s)"
            />
          </div>
        </div>
        <div className="analytics-tab__card">
          <SectionHeader title="Engagement Rate" useBackground useBorder />
          <div className="analytics-tab__card-content">
            {countByEngagementRate?.success_percentage !== null && (
              <CountGaugeChart
                data={engagementRate}
                labels={['viewed', 'received']}
                titleText={`${Math.round(engagementRate?.[0] * 100) / 100} %`}
                showTitle
                showLegend={false}
                customTooltipFormatter={customTooltipFormatter}
              />
            )}
          </div>
        </div>
        <div className="analytics-tab__card">{topSenders}</div>
      </div>
      <div className="analytics-tab-list">
        <RecommendationAnalyticsListTable
          title="Detailed Recommendations Received"
          queryName={props?.queries?.recommendationReceivedList}
          {...props}
          entity="recipient"
          columns={recommendationReceivedColumns}
        />
      </div>
    </>
  );
};

export default AnalyticsInteractionsRecommendationsReceived;
