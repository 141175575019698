import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDataSectionStore } from 'store';
import { Row } from 'components/label-value-pair';
import { formatDate } from 'utils/date';

import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { formatConnectionType } from 'components/connection-list-item/utils';

export const ConnectionPreviewAttributes: React.FC = observer(() => {
  const { activeItem: connection } = useDataSectionStore<DeliveriesGenericConnection>();
  const { type, created_at, updated_at } = connection || {};

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 1fr" className="break-word p-3">
      <Row label="Type" value={formatConnectionType(type as string)} />

      <Row label="Created at" value={formatDate(created_at)} rawLabel />
      <Row label="Active since" value={formatDate(updated_at)} rawLabel />
      <Row label="Last login" value={formatDate(updated_at)} rawLabel />
      <Row label="Deleted at" value={''} rawLabel />
    </InfoBoxV2>
  );
});
