import { useCallback } from 'react';
import { getDivisions } from 'utils/apis/divisions';
import { useRemote } from '.';
import { IDivision } from 'types';
import { useBasicStore } from 'store/hooks';
import { byId, formatField, formatTreeEntities } from 'utils/general';

export const useDivisions = ({
  division_ids,
}: {
  division_ids?: number[] | null;
}): {
  divisions: IDivision[];
  divisionsNames: string;
} => {
  const fetchDivisions = useCallback(() => {
    if (division_ids?.length) {
      return getDivisions(division_ids);
    } else {
      return Promise.resolve([]);
    }
  }, [division_ids]);

  const [divisions = []] = useRemote(fetchDivisions);
  const divisionsNames = divisions?.map(({ name }) => name).join(', ') || '';

  return { divisions, divisionsNames };
};

export function useDivisionsInfo(ids?: number[] | null, full = false): string | undefined {
  const { divisions } = useBasicStore();
  if (!ids || !ids.length) {
    return;
  }
  if (full) {
    return formatTreeEntities(ids, divisions);
  }
  const divisionsById = byId(divisions);
  return formatField(ids.map((divisionId) => divisionsById[divisionId]?.name)) as string;
}
