import React, { useState, useRef } from 'react';
import { FloatingPosition, Menu, ActionIcon, ScrollArea } from '@mantine/core';

import { CheckPowers } from 'components/check-power';

import { IAction, IUseActionsOptionParam } from 'utils/actions';

import { IActionName, ITitleDividerActionName } from 'utils/actions/types';
import { MantineIcon } from 'utils/ui/icon';

interface IActionMenuItemProps {
  action: IAction<IActionName>;
}

export const ActionMenuItem: React.FC<IActionMenuItemProps> = (props) => {
  const { action } = props;

  if (action.name === 'divider') return <Menu.Divider />;

  if (action.isTitle || Object.values(ITitleDividerActionName).find((el) => el === action.name)) {
    return <Menu.Label>{action.title ?? action.name}</Menu.Label>;
  }

  return (
    <CheckPowers requiredPowers={action.requiredPowers}>
      <Menu.Item
        leftSection={<MantineIcon icon={action.icon || 'blank'} />}
        variant={action.intent}
        data-action={action.name}
        onClick={action.handler}
        disabled={Boolean(action.isDisabled)}
      >
        {action.title}
      </Menu.Item>
    </CheckPowers>
  );
};

export interface IActionsMenuProps {
  actions: IAction<IActionName>[];
  loading?: boolean;
  placement?: FloatingPosition;
  lazy?: boolean;
}

function DropDownList<T>({
  items,
  options,
  component: Component,
  isOpen,
  viewportRef,
}: {
  items: T[];
  options: IUseActionsOptionParam<T>;
  component: React.FC<{ items: T[]; options: IUseActionsOptionParam<T> }>;
  isOpen: boolean;
  viewportRef: React.ForwardedRef<HTMLDivElement>;
}): JSX.Element {
  if (!isOpen) {
    return <></>;
  }

  return (
    <ScrollArea.Autosize mah="85vh" scrollbars="y" viewportRef={viewportRef}>
      <Component items={items} options={options} />
    </ScrollArea.Autosize>
  );
}

export function ActionsMenu<T>(props: {
  loading?: boolean;
  placement?: FloatingPosition;
  items: T[];
  options: IUseActionsOptionParam<T>;
  component: React.FC<{ items: T[]; options: IUseActionsOptionParam<T> }>;
}): JSX.Element {
  const { items, placement = 'left', options, component } = props;
  const [isOpen, setIsOpen] = useState(false);
  const setToOpen = (): void => {
    setIsOpen(true);
  };
  const viewportRef = useRef<HTMLDivElement>(null);

  const validItems = items?.filter((e) => e);
  if (!validItems?.length) return <></>;

  return (
    <Menu onOpen={setToOpen} position={placement} middlewares={{ flip: { fallbackAxisSideDirection: 'end' } }}>
      <Menu.Target>
        <ActionIcon color="gray.5" radius="sm" variant="subtle" className="action-menu-button js_action-btn">
          <MantineIcon icon="more" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <DropDownList
          viewportRef={viewportRef}
          isOpen={isOpen}
          options={options}
          items={validItems}
          component={component}
        />
      </Menu.Dropdown>
    </Menu>
  );
}

export interface IItemActionsMenuProps<T> {
  items: T[];
  options: IUseActionsOptionParam<T>;
}
