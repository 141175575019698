import React, { useCallback, useState, useMemo } from 'react';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import { toJS } from 'mobx';
import { ActionIcon, Pill, Loader } from '@mantine/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import { ITreeNode, Popover, PopoverInteractionKind, Position, Tree } from '@blueprintjs/core';
import { MantineIcon } from 'utils/ui';
import { Classes } from 'utils/ui';
import { IAggregation, InFilter, NotInFilter } from 'helpers/filters/types';
import { ITreeDataProvider, TreeSelectedState } from 'helpers/data-provider/tree-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';

import './style.scss';

type ITreeFilterDefinition = InFilter | NotInFilter;

interface IFilterTreeProps {
  name: string;
  label: string;
  optionsProvider: ITreeDataProvider;
  large?: boolean;
  disabled?: boolean;
  aggregations: IAggregation[];
  filter: ITreeFilterDefinition;
  onChange?: (newValue: { [key: string]: ITreeFilterDefinition }) => void;
}

const getNodeAndChildrenIds = (node: ITreeNode): (number | string)[] => {
  const result = [node.id];
  if (node.childNodes) {
    node.childNodes.map((n) => result.push(...getNodeAndChildrenIds(n)));
  }
  return result;
};

const getNodesByIds = (nodes: ITreeNode[], ids: (string | number)[]): ITreeNode[] => {
  const result: ITreeNode[] = [];
  nodes.map((n) => {
    if (ids.includes(n.id)) {
      result.push(n);
    }
    if (n.childNodes) {
      result.push(...getNodesByIds(n.childNodes, ids));
    }
  });

  return result;
};

const setNodesAggregations = (nodes: ITreeNode[], aggregations: IAggregation[]): ITreeNode[] => {
  return nodes.map((node) => {
    const aggr = aggregations?.find((a) => a.value === node.id);
    const childNodes = node.childNodes && setNodesAggregations(node.childNodes, aggregations);
    const newNode = toJS(node);
    return {
      ...newNode,
      secondaryLabel: aggr && `${aggr.count}`,
      disabled: !aggr?.count && !node.childNodes?.length,
      childNodes,
    };
  });
};

const setNodesSelected = (nodes: ITreeNode[], ids: (string | number)[]): ITreeNode[] => {
  return nodes.map((node) => {
    const childNodes = node.childNodes && setNodesSelected(node.childNodes, ids);
    const newNode = toJS(node);
    return {
      ...newNode,
      icon: ids.includes(node.id) ? TreeSelectedState.SELECTED : TreeSelectedState.NOT_SELECTED,
      childNodes,
    };
  });
};

interface ILocalStore {
  optsWithAggregations: ITreeNode[];
  selectedItems: ITreeNode[];
}

interface ILocalStoreSource {
  value: (string | number)[];
  aggregations: IAggregation[];
}

const FilterTree: React.FC<IFilterTreeProps> = observer((props) => {
  const { name, label, filter, aggregations, onChange, optionsProvider, disabled, large } = props;
  const { value } = filter || {};
  const [isOpen, setIsOpen] = useState(false);

  const valueNormalized = useMemo(() => (isArray(value) ? value : [value]), [value]);
  const options = optionsProvider.data;
  const disableReset = valueNormalized.length === 0;

  const localStore = useLocalStore<ILocalStore, ILocalStoreSource>(
    (source) => ({
      get optsWithAggregations(): ITreeNode[] {
        const aggregated = setNodesAggregations(options, source.aggregations);
        const selected = setNodesSelected(aggregated, source.value);
        return selected;
      },
      get selectedItems(): ITreeNode[] {
        return getNodesByIds(options, source.value);
      },
    }),
    { value: valueNormalized, aggregations },
  );

  const handlePopoverIneraction = useCallback(
    (nextState) => {
      setIsOpen(nextState);
      optionsProvider.init();
    },
    [optionsProvider],
  );

  const handleNodeClick = useCallback(
    (node) => {
      const selected = valueNormalized.includes(node.id);
      const nodeIds = getNodeAndChildrenIds(node);
      if (selected) {
        const newValue = valueNormalized.filter((v) => !nodeIds.includes(v));
        onChange && onChange({ [name]: { ...filter, value: newValue } });
      } else {
        const values = [...valueNormalized, ...nodeIds].filter((v) => v !== '');
        const newValue = values.filter((v, idx) => values.indexOf(v) === idx); // get unique values
        onChange && onChange({ [name]: { ...filter, value: newValue } });
      }
    },
    [filter, name, onChange, valueNormalized],
  );

  const handleTagRemove = useCallback(
    (item: ITreeNode): void => {
      const nodeId = item.id;
      const newValue = valueNormalized.filter((v) => v !== nodeId);
      onChange && onChange({ [name]: { ...filter, value: newValue } });
    },
    [filter, name, onChange, valueNormalized],
  );

  const classes = classnames(Classes.INPUT, Classes.TAG_INPUT, {
    [Classes.ACTIVE]: true,
    [Classes.DISABLED]: disabled,
    [Classes.FILL]: true,
    [Classes.LARGE]: large,
  });

  const handleReset = (): void => {
    onChange?.({ [name]: { ...filter, value: [] } });
  };

  return (
    <div className="d-flex align-items-center">
      <div className="flex-fill">
        <FormGroup label={label} labelFor={name}>
          <Popover
            autoFocus={false}
            enforceFocus={false}
            isOpen={isOpen}
            fill={true}
            portalClassName={'filter-tree__popover'}
            onInteraction={handlePopoverIneraction}
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.BOTTOM_LEFT}
          >
            <div className={classes}>
              <div className={Classes.TAG_INPUT_VALUES}>
                {localStore.selectedItems.map((item) => (
                  <Pill
                    size="sm"
                    radius="sm"
                    key={item.id}
                    data-tag-id={item.id}
                    withRemoveButton
                    onRemove={() => handleTagRemove(item)}
                  >
                    {item.label}
                  </Pill>
                ))}
              </div>
            </div>
            {optionsProvider.loading ? (
              <Loader size={25} />
            ) : (
              <Tree
                contents={localStore.optsWithAggregations}
                onNodeExpand={(node, nodePath) => optionsProvider.expandNode(nodePath)}
                onNodeCollapse={(node, nodePath) => optionsProvider.collapseNode(nodePath)}
                onNodeClick={handleNodeClick}
              />
            )}
          </Popover>
        </FormGroup>
      </div>
      <div>
        <ActionIcon className="mt-2" variant="subtle" color="gray.5" disabled={disableReset} onClick={handleReset}>
          <MantineIcon icon="trash" />
        </ActionIcon>
      </div>
    </div>
  );
});

export default FilterTree;
