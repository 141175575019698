import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import Uppy, { UppyFile } from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { IAssetStatistics } from './asset';

const AWS_S3_MULTIPART_UPLOAD_LIMIT = 100;

export const getFileStatistics = (): Promise<IAssetStatistics> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.ASSET_STATISTICS_DATA, 'file.get', { params: { files_only: 1 } });

    return objects;
  });
};

export const uploadFile = async (jwtToken: string, file: File | Blob): Promise<{ url: string; file?: UppyFile }> =>
  new Promise<{ url: string; file?: UppyFile }>((resolve) => {
    let isResultReturned = false;
    const returnResult = (result: string, file?: UppyFile): void => {
      if (isResultReturned) return;
      isResultReturned = true;
      resolve({ url: result, file });
    };

    const companionUrl = chipmunk.currentConfig()?.endpoints?.companion;
    if (!companionUrl) return returnResult('');

    const uppy = new Uppy({
      autoProceed: true,
      onBeforeFileAdded: (currentFile: UppyFile): UppyFile => {
        return { ...currentFile, meta: { ...currentFile.meta, filename: currentFile.name } };
      },
    });

    uppy.use(AwsS3Multipart, {
      limit: AWS_S3_MULTIPART_UPLOAD_LIMIT,
      companionUrl,
      companionHeaders: {
        'uppy-auth-token': jwtToken,
      },
    });

    uppy.on('error', () => {
      returnResult('');
    });

    uppy.on('upload-error', () => {
      returnResult('');
    });

    uppy.on('upload-success', (file, response) => {
      returnResult(response?.uploadURL || '', file);
    });

    uppy.on('file-added', (file) => {
      const data = file.data; // is a Blob instance
      const url = URL.createObjectURL(data);
      const image = new Image();
      image.src = url;

      image.onload = () => {
        uppy.setFileMeta(file.id, { width: image.width, height: image.height });
        URL.revokeObjectURL(url);
      };
    });

    uppy.addFile({
      data: file,
      name: file instanceof File ? file.name : 'image.png',
      type: file.type,
    });

    uppy.upload();
  });
