import React from 'react';
import { logger } from 'utils/logger';

function isValidJSON(str?: string): boolean {
  try {
    JSON.parse(str as string);
    return true; // If parsing succeeds, it's valid JSON
  } catch (e) {
    return false; // If parsing fails, it's not valid JSON
  }
}

function isHTML(str: string): boolean {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str.trim();

  // Check if the temporary div has any child elements
  return tempDiv.childNodes.length > 0 && Array.from(tempDiv.childNodes).some((node) => node.nodeType === 1);
}

export const ToastError: React.FC<{
  error?: React.ReactNode & {
    message?: string;
    title?: string;
    description?: string;
    text?: string;
    status?: number;
    response?: { body?: { message?: string; description: string; error: { response: { text: string } } } };
  };
  placeholder?: string;
}> = ({ error, placeholder = 'No error provided' }) => {
  try {
    if (!error) {
      return <div>{placeholder}</div>;
    }

    if (error.status === 403) {
      return <div>You are not allowed to perform this action</div>;
    }

    if (error.title && error.description) {
      return (
        <div>
          <div>{error.title}</div>
          <div>{error.description}</div>
        </div>
      );
    }
    const errorDescriptionText = error.response?.body?.error?.response?.text || '';
    const isJsonObj = isValidJSON(errorDescriptionText);
    const parsedText = isJsonObj ? JSON.parse(errorDescriptionText) : '';
    if (error.response?.body?.message || error.response?.body?.description || errorDescriptionText) {
      return (
        <div>
          <div>{error.response?.body?.message || error.response?.body?.description}</div>
          {parsedText?.description && <div>{parsedText?.description}</div>}
        </div>
      );
    }

    if (isHTML(errorDescriptionText)) {
      return <div dangerouslySetInnerHTML={{ __html: errorDescriptionText }}></div>;
    }

    if (error.text || error.message) {
      return <div>{error.text || error.message}</div>;
    }

    return <div>{error}</div>;
  } catch (error) {
    logger.error(error);
    return <div>Sorry, something went wrong...</div>;
  }
};
