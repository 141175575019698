import { connect, getSocket, joinRoom } from '@mediafellows/pigeon2-client';

import { chipmunk } from 'utils/chipmunk';

export const pigeon = async (id: string): Promise<void> => {
  const socket = getSocket();

  if (!socket || !socket.connected) {
    await connect(chipmunk);
  }

  joinRoom(`delivery-package-${id}`);
};
