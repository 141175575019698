import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useStore } from 'store';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import { sortBy, groupBy, uniq, isEmpty } from 'lodash';
import { Loader, Accordion } from '@mantine/core';

import { formatTime, renderModalHeader } from './utils';

import { queryContacts } from 'utils/apis/contacts';
import { byId } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { dateWithoutHours, formatDate } from 'utils/date';
import { MeetingPreview } from './meeting-preview';
import { stringToColour } from 'components/avatar/avatar-utils';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

interface ICustomListViewProps {
  events: EventInput[];
  date: string | undefined;
  timeZone: string | undefined;
  calendar: React.RefObject<FullCalendar>;
}

export const CustomListView: React.FC<ICustomListViewProps> = ({ events, date, timeZone, calendar }) => {
  const { dialogStore } = useStore();
  const getHostsById = useCallback(async () => {
    const hosts = await queryContacts({ ids: uniq(events.map((meeting) => meeting.location.host_ids || []).flat()) });
    return byId(hosts);
  }, [events]);
  const [hostsById = []] = useRemote(getHostsById);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isEmpty(hostsById)) {
      setLoading(true);
    } else setLoading(false);
  }, [hostsById]);

  const meetings: EventInput[] = useMemo(() => {
    const sortedEvents = sortBy(events, 'start');
    const groupedEvents = groupBy(sortedEvents, 'location.name');
    return Object.entries(groupedEvents).map(([key, value]) => ({
      eventLocation: key,
      location: value[0].location,
      events: value,
    }));
  }, [events]);

  const sortedLocations = sortBy(meetings, 'eventLocation');

  const openMeetingPreviewDialog = useCallback(
    (event) => {
      dialogStore.openModal({
        header: () => renderModalHeader(event, calendar),
        className: 'meeting-preview-dialog',
        body: () => <MeetingPreview {...event} />,
      });
    },
    [dialogStore, calendar],
  );
  return (
    <div className="custom-list-view">
      <div className="d-flex align-items-center justify-content-between p-3">
        <div className="custom-list-view__date">{date}</div>
        <div className="custom-list-view__count custom-list-view__secondary-text">
          {events.length} Meeting{events.length > 1 && 's'}
        </div>
      </div>
      <Accordion>
        {sortedLocations.map((meeting, index) => {
          const isPersonal = meeting?.eventLocation === 'undefined';
          const locationHost = meeting?.location?.host_ids?.map((id) => ({
            hostName: hostsById[id] ? hostsById[id]?.first_name + ' ' + hostsById[id]?.last_name : '',
          }));

          return (
            <Accordion.Item
              key={index}
              value={String(index)}
              style={{ '--locationColour': stringToColour(`${meeting.eventLocation}`) } as React.CSSProperties}
              className="custom-list-view__btn"
            >
              <Accordion.Control className="py-1">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="event-location">
                    {!meeting.eventLocation || meeting.eventLocation === 'undefined'
                      ? 'Personal Meetings'
                      : meeting.eventLocation}
                  </span>
                  {!isPersonal ? (
                    <span className="custom-list-view__secondary-text px-3">
                      <span className="custom-list-view__location-dates">
                        <MantineIcon icon="time" className="mx-1" size={11} />
                        {formatDate(meeting.location?.starts_at, dateWithoutHours)}
                        {' - '}
                        {formatDate(meeting.location?.ends_at, dateWithoutHours)}
                      </span>
                      {loading && (
                        <>
                          {' | '} <Loader size={12} className="mx-2" />
                        </>
                      )}
                      {!loading && locationHost?.length > 0 && (
                        <>
                          {' | '}
                          <MantineIcon icon="app-header" className="mx-1" size={11} />
                          {locationHost.map((host) => host.hostName).join(', ')}
                        </>
                      )}
                      {' | '}
                      {meeting.events.length} Meeting{meeting.events.length > 1 && 's'}
                    </span>
                  ) : (
                    <span className="custom-list-view__secondary-text px-3">
                      {meeting.events.length} Meeting{meeting.events.length > 1 && 's'}
                    </span>
                  )}
                </div>
              </Accordion.Control>

              <Accordion.Panel>
                {meeting.events.map((event, index) => (
                  <div
                    key={index}
                    className="custom-list-view__group py-2"
                    onClick={() => openMeetingPreviewDialog(event)}
                  >
                    <div className="custom-list-view__group-item">
                      <div className="custom-list-view__secondary-text">
                        {formatTime(new Date(event.start), { timeZone })}
                        {' - '}
                        {formatTime(new Date(event.end), { timeZone })}
                      </div>
                      <div className="d-flex flex-column">
                        <div>{event.title}</div>
                        <div className="custom-list-view__secondary-text">
                          {event.location?.name && (
                            <span>
                              <MantineIcon icon="map-marker" className="me-2" />
                              {event.location.name} <span className="mx-2">|</span>
                            </span>
                          )}
                          {event.host && (
                            <span>
                              <MantineIcon icon="app-header" className="me-2" size={14} />
                              {event.host.first_name} {event.host.last_name} <span className="mx-2">|</span>
                            </span>
                          )}
                          <span>
                            <MantineIcon icon="person" className="me-2" size={14} />
                            {event.invites?.length || 0} Invitee{event.invites?.length > 1 && 's'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};
