import { action, computed, observable } from 'mobx';
import { ParametronStore } from './parametron-store';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { IPreset, IPresetParams, Model } from 'helpers/filters/types';
import { ISearchFilter, ItemId } from 'types';
import { logger } from 'utils/logger';
import { IFiltersDefinition } from 'helpers/filters';

const initialState = {
  searchStore: null,
  checked: [],
  active: null,
  activeTab: null,
  target: null,
  lastChecked: null,
  defaultFilters: null,
  defaultParams: null,
  filters: null,
  presets: [],
  selectedPreset: null,
  isAllItemsSelected: false,
  initFilters: {},
  resetAllListFiltersOnInit: false,
};

export interface IDataSectionStore<T = unknown> {
  searchStore: ParametronStore<T> | null;
  target: Model | null;
  checked: T[];
  active: ItemId | null;
  activeTab: DataSectionSidebarTabs;
  lastChecked: number | null;
  defaultFilters: IFiltersDefinition | null;
  initFilters: IFiltersDefinition | null;
  defaultParams: IPresetParams | null;
  filters: IFiltersDefinition | null;
  resetParams: () => void;
  reset: () => void;
  updateStore: (obj: Partial<IDataSectionStore<T>>) => void;
  presets: IPreset[];
  selectedPreset: null | IPreset;
  isAllItemsSelected: boolean;
  getIsActive: (id?: ItemId | null) => boolean;
  getIsChecked: (id?: ItemId | null) => boolean;
  setParams: (params: Record<string, unknown>) => IDataSectionStore;
  getParams: () => Record<string, unknown>;
  getFilters: () => ISearchFilter[];
  activeItem: T | undefined;
  oldFilters: Record<string, IFiltersDefinition>;
  updateOldFilters: (key: string) => void;
  clearOldFilters: (key: string) => void;
  resetAllListFilters: () => void;
  getOldFilters: <P extends IFiltersDefinition>(key: string) => P | undefined;
  resetAllListFiltersOnInit?: boolean;
}

export class DataSectionStore<T = unknown> implements IDataSectionStore<T> {
  @observable public searchStore;
  @observable public target;
  @observable public checked;
  @observable public active;
  @observable public activeTab;
  @observable public lastChecked;
  @observable public defaultFilters;
  @observable public initFilters = {};
  @observable public defaultParams;
  @observable public filters;
  @observable public presets;
  @observable public selectedPreset;
  @observable public isAllItemsSelected;
  @observable public oldFilters = {};
  @observable public resetAllListFiltersOnInit = false;

  resetAllListFilters(): void {
    // this will be set to the clear filters action on the filters section
    // check src/helpers/filters/use-filters.ts#L191, see #5039
    logger.error('Missing "Clear Filters" action!');
  }

  @action.bound
  updateStore(obj): void {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof this[key] === 'undefined') {
        logger.error(`key missing in store: ${key}`);
        continue;
      }

      this[key] = value;
    }
  }

  @action.bound
  clearOldFilters(key: string): void {
    if (!this.oldFilters?.[key]) {
      return;
    }

    this.oldFilters[key] = undefined;
    delete this.oldFilters[key];
  }

  @action.bound
  updateOldFilters(key: string): void {
    if (!this.oldFilters) {
      return;
    }

    this.oldFilters[key] = this.filters;
  }

  @action.bound
  getOldFilters<P extends IFiltersDefinition>(key: string): P | undefined {
    return this?.oldFilters?.[key];
  }

  @action.bound
  reset(): void {
    Object.keys(initialState).map((key) => {
      this[key] = initialState[key];
    });
  }

  @action.bound
  resetParams(params = null): void {
    if (!this.searchStore || !this.defaultParams) {
      return;
    }

    this.searchStore.parametron.api.setParams(params || { ...this.defaultParams });
  }

  /**
   * This might look strange, BUT
   * if you simply expose a function (the one that returned) then it will stay the same all the time
   * and if a component only uses this function and nothing else from other parts of the observable
   * then the component will never be rerendered (because the function will not be changed)
   *
   * however in case of computed property it will, because it depende on other observables
   *
   */
  @computed
  public get getIsChecked() {
    return (itemId: ItemId) => {
      return this.isAllItemsSelected || this.checked.some((checked) => checked.id === itemId);
    };
  }

  @computed
  public get getIsActive() {
    return (itemId: ItemId) => {
      if (Array.isArray(this.active)) {
        return this.active.some((active) => active === itemId);
      }
      return this.active === itemId;
    };
  }

  @computed
  public get activeItem(): T | undefined {
    return this.searchStore?.objects.find((element) => element?.id === this.active);
  }

  @action.bound
  public setParams(params: Record<string, unknown>): this {
    const newParams = { ...params };
    this.searchStore?.parametron.api.setParams(newParams);

    return this;
  }

  @action.bound
  public getParams(): Record<string, unknown> {
    const params = { ...this.searchStore?.parametron.data.params };
    return params;
  }

  @action.bound
  public getFilters(): ISearchFilter[] {
    return this.searchStore?.parametron.data.filters;
  }

  @action.bound
  public triggerSearch(): void {
    this.searchStore?.parametron.api.fire();
  }
}
