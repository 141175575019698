import React, { Dispatch, SetStateAction, useState } from 'react';
import { Tabs } from '@mantine/core';

import { IConference, IAsset, DetailsPageTabs } from 'types';
import { DataSectionControls } from 'components/data-section-controls';
import { SortButton } from 'components/sort-buttons';

import { ConferenceOverviewTab } from './conference-overview-tab';
import { AddAssetsToConferenceButton, AddContactsToConferenceButton } from './tab-buttons';
import { ConferenceAssetsTab } from './conference-assets-tab';
import { ConferenceContactsTab } from './conference-contacts-tab';
import { IUseFormReturn } from 'helpers/form';
import { ConferenceDetailTab, ConferenceTabPanel } from './utils';
import { TabButtonsWrapper } from 'components/tabs';

interface IConferenceDetailsTabsView {
  conference: IConference;
  conferenceForm: IUseFormReturn<IConference>;
  editModeEnabled: boolean;
  assets: IAsset[];
  currentTabId: DetailsPageTabs;
  onTabChangeHandle: (tab: DetailsPageTabs) => void;
  onSuccess: () => void;
  setConference: Dispatch<SetStateAction<IConference>>;
}

export const ConferenceDetailsTabsView: React.FC<IConferenceDetailsTabsView> = ({
  conference,
  conferenceForm,
  editModeEnabled,
  assets,
  currentTabId,
  onTabChangeHandle,
  onSuccess,
  setConference,
}) => {
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  return (
    <Tabs value={currentTabId} onChange={onTabChangeHandle} className="conference-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <ConferenceDetailTab value={DetailsPageTabs.OVERVIEW} activeTab={currentTabId} disabled={isSortModeEnabled} />
        <ConferenceDetailTab value={DetailsPageTabs.ASSETS} activeTab={currentTabId} disabled={editModeEnabled} />
        <ConferenceDetailTab value={DetailsPageTabs.CONTACTS} activeTab={currentTabId} disabled={editModeEnabled} />

        {currentTabId === DetailsPageTabs.ASSETS && (
          <TabButtonsWrapper>
            <SortButton isSortModeEnabled={isSortModeEnabled} setIsSortModeEnabled={setIsSortModeEnabled} />
            <AddAssetsToConferenceButton
              disabled={isSortModeEnabled}
              conference={conference}
              onSuccess={(action, updatedConf) => setConference(updatedConf)}
            />
            <DataSectionControls customRefresh={onSuccess} disabled={isSortModeEnabled} />
          </TabButtonsWrapper>
        )}

        {currentTabId === DetailsPageTabs.CONTACTS && (
          <TabButtonsWrapper>
            <AddContactsToConferenceButton
              disabled={isSortModeEnabled}
              conference={conference}
              onSuccess={(action, updatedConf) => setConference(updatedConf)}
            />
            <DataSectionControls customRefresh={onSuccess} disabled={isSortModeEnabled} />
          </TabButtonsWrapper>
        )}
      </Tabs.List>
      <ConferenceTabPanel
        value={DetailsPageTabs.OVERVIEW}
        content={
          <ConferenceOverviewTab
            editModeEnabled={editModeEnabled}
            assets={assets}
            conference={conference}
            conferenceForm={conferenceForm}
          />
        }
      />
      <ConferenceTabPanel
        value={DetailsPageTabs.ASSETS}
        content={
          <ConferenceAssetsTab
            setConference={setConference}
            isSortModeEnabled={isSortModeEnabled}
            conference={conference}
            assets={assets}
          />
        }
      />

      <ConferenceTabPanel
        value={DetailsPageTabs.CONTACTS}
        content={<ConferenceContactsTab setConference={setConference} conference={conference} />}
      />
    </Tabs>
  );
};
