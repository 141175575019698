import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Classes } from 'utils/ui';

import { IDataSectionLayout, IMarketingEvent } from 'types';

interface IEventAncestryBreadcrumbsProps {
  parentEvent?: IMarketingEvent;
  layout?: IDataSectionLayout | 'preview' | 'detail';
}

const EventAncestryBreadcrumbs: React.FC<IEventAncestryBreadcrumbsProps> = ({ parentEvent }) => {
  if (!parentEvent) {
    return null;
  }
  return (
    <div className="breadcrumbs__wrapper__list">
      {parentEvent && (
        <ul className="breadcrumbs__items__list breadcrumbs__items__preview">
          <Link
            className="breadcrumbs__ancestor__list breadcrumbs__ancestor__detail"
            key={parentEvent.id}
            title={parentEvent.name}
            to={`/events/${parentEvent.id}/overview`}
          >
            <span className={cx(`${Classes.TEXT_MUTED}`, 'd-block text-truncate breadcrumbs__list__text')}>
              {parentEvent.name}
            </span>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default EventAncestryBreadcrumbs;
