import React from 'react';

import { FormSectionWrapper } from 'components/form-section-wrapper';
import { FormProducts, FormAssets } from 'helpers/form';
import { ItemId } from 'types';
import { ICreateEventStep } from './types';

export const keysProductsAssets = ['asset_ids', 'products_ids'];

interface IStepProductsAssets extends ICreateEventStep {
  showProducts?: boolean;
  showAssets?: boolean;
  productIds?: ItemId[];
  assetIds?: ItemId[];
  hideProductsParams?: boolean;
}

export const StepProductsAssets: React.FC<IStepProductsAssets> = ({ form, showProducts = true, showAssets = true }) => {
  const settings =
    showProducts && showAssets
      ? { columns: '1fr 1fr', title: 'Products and assets' }
      : { columns: '1fr', title: showProducts ? 'Products' : 'Assets' };

  return (
    <FormSectionWrapper title={settings.title} gridTemplateColumns={settings.columns}>
      <>
        {showProducts && (
          <FormProducts
            label="Products"
            name="products_item_ids"
            {...form.formData.products_item_ids}
            {...form.handlers}
          />
        )}

        {showAssets && (
          <FormAssets label="Assets" name="assets_item_ids" {...form.formData.assets_item_ids} {...form.handlers} />
        )}
      </>
    </FormSectionWrapper>
  );
};
