import React from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import cx from 'classnames';

import { ToastError } from 'components/toast';
import { getRootStore } from 'store';
import { updateRecommendation } from 'utils/apis/recommendation';
import { DetailsPageTabs, IProduct } from 'types';
import { ITabValue, Tab, TabPanel } from 'components/tabs';
import { Text, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui';
import { flags } from 'utils/flags';

interface IUpdateRecommendationParams {
  id?: number | null;
  assetIds?: number[] | null;
  assetsToUpdate: { assetsToAdd?: string[]; assetsToDelete?: string[] };
}

export const updateRecommendationAssets = async ({
  id,
  assetIds,
  assetsToUpdate,
}: IUpdateRecommendationParams): Promise<McGenericRecommendationProduct | undefined> => {
  const { toastStore } = getRootStore();
  const { assetsToDelete, assetsToAdd } = assetsToUpdate || {};
  if (!id) return;

  try {
    let asset_ids = assetsToDelete?.length
      ? assetIds?.filter((assetId) => !assetsToDelete?.includes(String(assetId)))
      : assetIds;
    asset_ids = assetsToAdd?.length ? [...(asset_ids || []), ...assetsToAdd.map(Number)] : asset_ids;

    const updatedRecommendation = await updateRecommendation({
      id,
      asset_ids,
    });
    toastStore.clearAll();
    toastStore.success('Recommendation Assets updated');
    return updatedRecommendation as McGenericRecommendationProduct;
  } catch (error) {
    toastStore.error(<ToastError error={error} />);
  }
};
export const RecommendationDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  hidden?: boolean;
  disabled?: boolean;
  valueParser?: (value?: ITabValue) => string;
}> = ({ value, activeTab, hidden = false, disabled, valueParser }) => {
  return (
    <Tab
      value={value}
      className={cx(`recommendation-details__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={disabled}
      hidden={hidden}
      valueParser={valueParser}
    />
  );
};

export const RecommendationTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({
  value,
  content,
}) => {
  return <TabPanel value={value} className="recommendation-details__tab-content h-100" content={content} />;
};

const getProductTitles = (products): JSX.Element | null => {
  const comp = products?.map((obj: IProduct) => <div key={obj.id}>{obj.display_title}</div>);

  return (
    <>
      <Text>Future episodes included for: </Text>
      <>{comp}</>
    </>
  );
};

export const ProductsWithFutureEpisodes: React.FC<{ products: IProduct[] }> = ({ products }) => {
  const { showFutureEpisodesFeature } = flags;
  if (!showFutureEpisodesFeature || !products?.length) return null;

  return (
    <Tooltip w={220} multiline label={getProductTitles(products)}>
      <span className="px-2 text-muted">
        <MantineIcon icon="inheritance" className="text-mutted" />
      </span>
    </Tooltip>
  );
};
