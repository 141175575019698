import React, { useCallback, useState } from 'react';
import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { createAccessPrivilege } from 'utils/apis/access-privilege';
import { ItemId, IGroupAddContact } from 'types';
import { useStore } from 'store';
import { initialValues } from 'components/access-privilege-grant/initial-values';

import { AccessPrivilegeGrantWizard } from './access-privilege-grant-wizard';
import { IAccessPrivilege } from './types';

const customContext = {
  properties: {
    product_ids: { required: true },
    email_subject: { required: true, validations: [{ length: { maximum: 300 } }] },
    email_message: { required: true, validations: [{ length: { maximum: 2000 } }] },
  },
};

interface IAccessPrivilegeGrantWizardContainerProps {
  productIds?: ItemId[];
  organizationIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];

  contacts?: IGroupAddContact[];
  onSuccess?: () => void;
  fitInParent?: boolean;
}

const makePartialOptions = (ids, type: 'contact-selection' | 'contact'): IGroupAddContact[] =>
  ids.map((id) => ({ id, type }));

export const AccessPrivilegeGrantWizardContainer: React.FC<IAccessPrivilegeGrantWizardContainerProps> = ({
  onSuccess,
  productIds = [],
  organizationIds = [],
  userIds = [],
  contacts = [],
  userSelectionIds = [],
  fitInParent = false,
}) => {
  const { toastStore } = useStore();
  const [isCreating, setIsCreating] = useState(false);
  const createAction = useCallback(
    async (values) => {
      setIsCreating(true);
      toastStore.info('Creating');
      await createAccessPrivilege(values);
      toastStore.clearAll();
      toastStore.success('Access privilege was created successfully');
      setIsCreating(false);
      onSuccess?.();
    },
    [onSuccess, toastStore],
  );

  const form = useForm<IAccessPrivilege>(
    {
      ...initialValues,
      product_ids: productIds,
      organization_ids: organizationIds,
      contacts: [
        ...contacts,
        ...makePartialOptions(userIds, 'contact'),
        ...makePartialOptions(userSelectionIds, 'contact-selection'),
      ],
    },
    Model.ACCESS_PRIVILEGES,
    createAction,
    customContext,
  );

  return <AccessPrivilegeGrantWizard form={form} isCreating={isCreating} fitInParent={fitInParent} />;
};
