import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { useDataSectionStore } from 'store';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { getSimpleSelectionItems } from 'components/selection/selection-preview-tab/get-simple-selection-items';
import SimpleList from 'components/simple-list/simple-list';
import { AsyncSliderSelect } from 'components/slider-select/slider-select';

import { ACCESS_LEVELS_OPTIONS } from 'utils/constants';
import { formatDate } from 'utils/date';
import { LinkWithArrow } from 'utils/ui/ui';
import { changeGroupAccessImmediate } from 'utils/apis/groups';
import { SelectionSchema } from 'utils/schemas';
import { pluralWordInflect, getEntityLabel, getStatus } from 'utils/general';

import { useRemote } from 'utils/hooks';
import { IResult } from 'utils/chipmunk';
import useSelectedOption from 'utils/hooks/selected-option';
import { changeAccessLevelSlider } from 'utils/change-access';

import { IAsset, IProduct, IContact, ISelection, ISelectionStandardized } from 'types';
import { Loading } from 'components/loading';
import { AssetTypeAggregations } from 'components/asset/assets-aggregation';

export interface ISelectionPreviewTab {
  selectionMapper: (productSelection: ISelection) => ISelectionStandardized;
}

export type ISimpleSelectionItem = {
  id: string | number;
  label: string;
} & (IAsset | IProduct | IContact);

const objects: ISimpleSelectionItem[] = [];

const defaultValue: IResult<ISimpleSelectionItem> = { objects, object: objects[0] };

const SelectionPreviewTab: React.FC<ISelectionPreviewTab> = observer(({ selectionMapper }) => {
  const { activeItem: selection } = useDataSectionStore<ISelection>();

  const mappedSelection = useMemo(() => selection && selectionMapper(selection), [selection, selectionMapper]);
  const fetchAssets = useCallback(async (): Promise<IResult<ISimpleSelectionItem> | undefined> => {
    return mappedSelection?.id ? getSimpleSelectionItems(mappedSelection) : defaultValue;
  }, [mappedSelection]);
  const [result = defaultValue, loadingItems] = useRemote<IResult<ISimpleSelectionItem>>(fetchAssets, defaultValue);

  const { access_level, name, entityCount, owner, created_at, ownerDivisionIds, deleted_at } = mappedSelection || {};
  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeGroupAccessImmediate(data, SelectionSchema),
        divisionIds: ownerDivisionIds,
        newAccessLevel,
      });
    },
    [ownerDivisionIds],
  );

  const selectedOption = useSelectedOption({ value: selection?.access_level });

  if (!selection || !mappedSelection?.id) {
    return (
      <div className={cx('selection-preview')}>
        <span className="entity-preview-tab__no-preview">No preview</span>
      </div>
    );
  }

  const entityLabel = pluralWordInflect(
    getEntityLabel(mappedSelection.main_entity_type),
    mappedSelection.entityCount,
    false,
  );
  const detailPageLink = `${location.pathname}/${mappedSelection?.id}`;
  const isAssetsSelection = selection?.main_entity_type === 'assets';
  return (
    <div className={cx('entity-preview h-100', { 'entity-preview--deleted': Boolean(deleted_at) })}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="entity-preview__title">
          <h3
            className={cx({
              [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
            })}
          >
            {name}
          </h3>
        </div>
      </div>
      <div className="entity-preview__actions">
        <AsyncSliderSelect onChange={handleAccessLevelChange} value={access_level} options={ACCESS_LEVELS_OPTIONS} />
        {!deleted_at && <LinkWithArrow to={detailPageLink} text="View Details" />}
      </div>
      <InfoBoxV2 className="p-3" gridTemplateColumns="1fr 2fr">
        <Row
          label={entityLabel}
          loading={isAssetsSelection && loadingItems}
          value={
            isAssetsSelection ? (
              <AssetTypeAggregations aggregations={result.aggregations} baseUrl={detailPageLink} />
            ) : (
              entityCount
            )
          }
          size="m"
        />
        <Row label="Owner" value={owner} size="m" />
        <Row label="Access" value={access_level} size="m" />
        <Row label="Status" value={getStatus(deleted_at)} size="m" />

        <Row label="Created at" value={formatDate(created_at)} size="m" rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} size="m" rawLabel hideIfNoValue />
      </InfoBoxV2>
      <hr className="entity-preview__divider" />
      {loadingItems ? (
        <Loading className="loader-height" />
      ) : (
        <SimpleList
          items={result.objects}
          title="Items"
          footer={
            !deleted_at && (
              <LinkWithArrow className="mt-2 selection-preview__link" to={detailPageLink} text="View More" />
            )
          }
        />
      )}
    </div>
  );
});
export default SelectionPreviewTab;
