import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useBasicStore } from 'store/hooks';

import { FormTreeSelect, ITreeSelectOption, IFormTreeSelectProps } from 'helpers/form';
import { byId, formatTreeEntity } from 'utils/general';

import './style.scss';

export const FormDivisions: React.FC<Omit<IFormTreeSelectProps, 'options'>> = observer((props) => {
  const { divisions } = useBasicStore();

  const formatDivisionLabel = useCallback(
    ({ value }: ITreeSelectOption): string => {
      const divisionsById = byId(divisions);
      return formatTreeEntity(parseInt(value, 10), divisionsById);
    },
    [divisions],
  );
  const divisionOptions = divisions.map((division) => ({
    ...division,
    id: division?.id?.toString(),
    parent_id: division.parent_id?.toString(),
  }));

  return (
    <FormTreeSelect
      tagRenderer={formatDivisionLabel}
      {...props}
      options={divisionOptions}
      className="contact__division"
    />
  );
});

export default FormDivisions;
