import { IIdentifiable, ItemId } from 'types';

import { IconAlias } from 'icons';
import { useGrantAccessPrivilegeDialog } from 'components/access-privilege-grant';
import { IUseActionsOptionParam } from 'utils/actions';

import {
  IShouldDisplayAccessPrivilegeActionFunction,
  IAccessPrivilegeActionConfig,
  IAccessPrivilegeActionName,
} from './types';

interface IUseGrantAccessPrivilegeOptions extends IUseActionsOptionParam {
  shouldDisplay: IShouldDisplayAccessPrivilegeActionFunction;
}

interface IUseGrantAccessPrivilegeItems {
  users?: IIdentifiable[];
  products?: IIdentifiable[];
  organizations?: IIdentifiable[];
  userSelections?: IIdentifiable[];
}

const mapId = (items?: IIdentifiable[]): ItemId[] => (items || []).map(({ id }) => id);

export const useGrantAccessPrivilegeAction = (
  items: IUseGrantAccessPrivilegeItems,
  options?: IUseGrantAccessPrivilegeOptions,
): IAccessPrivilegeActionConfig => {
  const [openDialog] = useGrantAccessPrivilegeDialog({
    organizationIds: mapId(items.organizations),
    productIds: mapId(items.products),
    userIds: mapId(items.users),
    userSelectionIds: mapId(items.userSelections),
    onConfirm: () => options?.onSuccess?.(),
  });

  return {
    name: IAccessPrivilegeActionName.GRANT_ACCESS_PRIVILEGE,
    shouldDisplay: options?.shouldDisplay,
    icon: IconAlias.ACCESS_PRIVILEGE_GRANT,
    title: 'Grant Access Privilege',
    handler: openDialog,
  };
};
