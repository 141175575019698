import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterSelect, FilterText } from 'helpers/filters/fields';
import { IFiltersDefinition, InFilter, Model, QFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { useStore } from 'store';

import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';

export interface IConferenceFilters extends IFiltersDefinition {
  _: QFilter;
  owner_id: InFilter;
  status: InFilter;
}

const statusOptionsProvider = new ContextDataProvider(Model.CONFERENCE, 'statuses');

const handleSubmit = (action): void => submitFilters({ action });

export const ConferenceFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();

  const [filterValues, filterHandlers] = useFilters<IConferenceFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search Conferences"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Status"
            name="status"
            placeholder="Select Status"
            optionsProvider={statusOptionsProvider}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
          />
          <FilterSelect
            label="Moderator"
            name="owner_id"
            placeholder="Select Moderator"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});
