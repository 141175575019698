import React, { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { DateRange } from '@blueprintjs/datetime';
import { ActionIcon } from '@mantine/core';
import { emptyValue } from './utils';
import './style.scss';
import { MantineIcon } from 'utils/ui';

export interface IResetButtonProps {
  handleChange: (value: DateRange) => void;
  value?: DateRange;
  hide?: boolean;
}

export const ResetButton: React.FC<IResetButtonProps> = ({ handleChange, hide = false, value }) => {
  const handleReset = useCallback(() => {
    handleChange(emptyValue);
  }, [handleChange]);

  if (hide) {
    return <></>;
  }

  return (
    <ActionIcon
      variant="subtle"
      color="gray.5"
      onClick={handleReset}
      disabled={isEqual(value, emptyValue)}
      className="cancel-button"
    >
      <MantineIcon icon="cross" />
    </ActionIcon>
  );
};
