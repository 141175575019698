import React from 'react';

import { FormSelect, IFormSelectProps } from 'helpers/form';

import { countryOptions } from './utils';

export const FormCountry: React.FC<IFormSelectProps> = (props) => {
  const { required, large, placeholder = 'Select Country', label = 'Country', name, ...other } = props;

  return (
    <FormSelect
      name={name}
      label={label}
      placeholder={placeholder}
      large={large}
      required={required}
      {...other}
      options={countryOptions}
      withinPortal
    />
  );
};
