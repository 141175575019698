import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { SmGenericNotificationPreference } from '@mediafellows/mm3-types';

export const getNotificationPreferences = (): Promise<SmGenericNotificationPreference> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.NOTIFICATION_PREFERENCES, 'get', {
      schema: 'id, mobile_channel, email_channel, sms_channel',
    });
    return object;
  });
};

export const setNotificationPreferences = (
  data: SmGenericNotificationPreference,
): Promise<SmGenericNotificationPreference> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.NOTIFICATION_PREFERENCES, 'update', {
      body: data,
    });

    return object;
  });
};
