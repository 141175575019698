import { IProduct } from 'types';
import { map } from 'lodash';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam } from 'utils/actions';

import { shouldDisplayDelivery as shouldDisplay } from './actions-acl';
import { IProductActionName, IProductActionConfig } from './types';
import { useDeliveryDialog } from 'components/delivery';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

export const useDeliveryAction = (
  products: IProduct[],
  options: IUseActionsOptionParam<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): IProductActionConfig => {
  const [handler] = useDeliveryDialog({
    delivery: { product_ids: map(products, 'id') } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
    options,
    itemAction: true,
  });

  return {
    name: IProductActionName.DELIVER,
    icon: 'cloud-upload',
    title: 'Deliver',
    shouldDisplay,
    handler,
  };
};
