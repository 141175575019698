import React, { useCallback } from 'react';

import { ItemId } from 'types';
import { useStore } from 'store';

import { AccessPrivilegeGrantWizard } from './';
import { IModalSize } from 'components/dialogs/types';

interface IUseGrantAccessPrivilegeDialog {
  productIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];
  organizationIds?: ItemId[];
  onConfirm?: (params: {}) => void;
}

export const useGrantAccessPrivilegeDialog = ({
  productIds,
  userIds,
  userSelectionIds,
  organizationIds,
}: IUseGrantAccessPrivilegeDialog): [() => void] => {
  const { dialogStore } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <AccessPrivilegeGrantWizard
        onSuccess={() => dialogStore.close()}
        productIds={productIds}
        organizationIds={organizationIds}
        userSelectionIds={userSelectionIds}
        userIds={userIds}
      />
    );
  }, [dialogStore, productIds, organizationIds, userIds, userSelectionIds]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Grant access privilege',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
