import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { useDataSectionStore } from 'store';
import { handleItemClick } from 'components/data-section';

import { LocationsDataSectionItem } from './locations-data-section-item';
import { ItemId } from 'types/utility';

interface IConferenceDataSectionItemProps {
  className?: string;
  location: CalGenericLocation;
  contextMenu: React.ReactElement;
}

export const LocationDataSectionItemWrapper: React.FC<IConferenceDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, location } = props;

  const { id } = location;

  const { getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id as ItemId);
  const isActive = getIsActive(id as ItemId);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id as ItemId);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <LocationsDataSectionItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        location={location}
        isChecked={isChecked}
      />
    </div>
  );
});
