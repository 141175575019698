import React, { useCallback } from 'react';
import { useStore } from 'store';

import { UmGenericListItem } from '@mediafellows/mm3-types';

import { ToastError } from 'components/toast';

import { shouldDisplayRemoveFromList } from './acl';
import { IListItemActionConfig, IListItemActionName } from './types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { deleteListItems } from 'utils/apis/showcase-item';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export function useRemoveFromListAction(
  items: UmGenericListItem[],
  options: IUseActionsOptionParam<UmGenericListItem>,
): IListItemActionConfig<UmGenericListItem> {
  const { toastStore, dialogStore } = useStore();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Item', items.length);

  const handleRemove = useCallback(async () => {
    try {
      if (!items?.[0]?.id) {
        return;
      }

      const itemIds = items.map((e) => e?.id || 0);
      await deleteListItems(items[0].list_id, itemIds);
      await options?.onSuccess?.(IListItemActionName.DESTROY);

      toastStore.success(`${entityWithCount} successfully removed!`);
    } catch (error) {
      toastStore.error(
        <ToastError error={error} placeholder={`Sorry, something went wrong, could not remove ${entity}...`} />,
      );
      await options?.onFailure?.();
    }
  }, [items, options, toastStore, entityWithCount, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Remove From Collection',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from this Collection?`,
      onConfirm: handleRemove,
    });
  }, [dialogStore, entityWithDemonstrative, handleRemove]);

  return {
    name: IListItemActionName.DESTROY,
    icon: 'remove',
    title: 'Remove From Collection',
    intent: Intent.DANGER,
    shouldDisplay: shouldDisplayRemoveFromList,
    handler,
  };
}
