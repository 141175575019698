import { ISelectionStandardized } from 'types';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { useMemo } from 'react';
import { IUseActionsOptionParam } from 'utils/actions';

import { shouldDisplayDelivery as shouldDisplay } from './actions-acl';
import { ISelectionActionName, ISelectionActionConfig } from './types';
import { useDeliveryDialog } from 'components/delivery';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

export const useDeliveryAction = (
  selection: ISelectionStandardized[],
  options: IUseActionsOptionParam<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): ISelectionActionConfig => {
  const groupIds = useMemo(() => selection.map((group) => group.id), [selection]);
  const [group] = selection;
  const type = group.main_entity_type;
  const [handler] = useDeliveryDialog({
    options,
    excludeProducts: type === 'assets' ? true : false,
    groupIds,
    selectionType: type,
    itemAction: true,
  });

  return {
    name: ISelectionActionName.DELIVER,
    icon: 'cloud-upload',
    title: 'Deliver',
    shouldDisplay,
    handler,
  };
};
