export const conferenceListSchema = `
access_level,
affiliation_id,
asset_ids,
user_ids,
awaiting_approval_text,
awaiting_moderator_text,
background_media,
banner_media,
code,
created_at,
disable_waitingroom,
ends_at,
farewell_alt_text,
farewell_text,
id,
meeting_ended_alt_text,
meeting_ended_text,
mode,
owner,
owner_id,
starts_at,
status,
title,
updated_at,
welcome_text
`;
