import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { useLocation, useNavigate } from 'react-router';

import { Globals } from 'types';
import { useQuery } from 'utils/url';
import { Loading } from 'components/loading';
import { Footer } from 'components/footer';
import { Routes } from 'utils/routes';
import { ssoLogin } from 'store/session-store';
import './style.scss';

type IQueryFields = {
  sid: string;
  error: string;
  email: string;
  first_name: string;
  last_name: string;
};

const fields: (keyof IQueryFields)[] = ['sid', 'error', 'email', 'first_name', 'last_name'];

const errorCodeToText = {
  '4224': 'Login Failed',
  '4226': 'Registration needs to be approved in order to be able to sign in!',
  '4227': 'Single Sign on Failed!',
};

const Sso: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sid = '', error = '', email = '', first_name = '', last_name = '' } = useQuery<IQueryFields>(fields);

  const landingPage = state?.from?.pathname || '/';

  useEffect(() => {
    if (sid) {
      setTimeout(() =>
        ssoLogin(sid).then(() => {
          navigate(landingPage);
        }),
      );
    }
  }, [landingPage, navigate, sid]);

  useEffect(() => {
    if (!error) {
      return;
    }

    switch (error) {
      case '4225':
        setTimeout(() => {
          toastStore.error(
            "We couldn't find an account in our system matching your SSO login. Please ask your support contact to create one for you.",
          );
          navigate(
            `${Routes.REGISTRATION}/?user_email=${email}&user_first_name=${first_name}&user_last_name=${last_name}`,
          );
        });
        break;

      default:
        setTimeout(() => {
          toastStore.error(errorCodeToText[error] || 'Something went wrong!');
          navigate(`${Routes.LOGIN}`);
        });

        break;
    }
  }, [email, error, first_name, last_name, navigate, toastStore]);

  return (
    <div className={`page--login ${Globals.MODE_DARK_CLASS}`}>
      <Loading text="Loading..." />
      <Footer />
    </div>
  );
});

export default Sso;
