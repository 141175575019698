import { useProfileStore } from 'store/profile-store';
import { Globals } from 'types';

const body = document.body;

export const sidebarToggle = (): void => {
  const updateSettings = useProfileStore.getState().updateSettings;
  const isSidebarOpen = useProfileStore.getState().settings.isSidebarOpen;

  body.classList.toggle(Globals.SIDEBAR_COLLAPSED_CLASS);
  updateSettings('isSidebarOpen', !isSidebarOpen);

  if (localStorage.getItem(Globals.SIDEBAR_COLLAPSED_LS)) {
    localStorage.removeItem(Globals.SIDEBAR_COLLAPSED_LS);
    return;
  }

  localStorage.setItem(Globals.SIDEBAR_COLLAPSED_LS, '1');
};
