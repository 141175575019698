import React, { useCallback } from 'react';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IAsset, IProduct, ItemId } from 'types';

import { IUseActionsOptionParam } from 'utils/actions';
import { IRecommendationActionName } from 'utils/actions/recommendation';
import { IModalSize } from 'components/dialogs/types';
import { ICreateWorkFlowPayload } from 'utils/actions/types';
import { RecommendAssetsWizard } from 'components/recommendation/recommend-assets';
import { RecommendProductsWizard } from 'components/recommendation/recommend-products';
import { formatType } from 'utils/format-type';

interface IUseRecommendAssetsDialogProps {
  assets?: IAsset[];
  products?: IProduct[];
  groupId?: ItemId;
  recommendation?: Partial<ICreateWorkFlowPayload<McGenericRecommendationAsset | McGenericRecommendationProduct>>;
  tab?: number;
  options?: IUseActionsOptionParam<McGenericRecommendationAsset | McGenericRecommendationProduct>;
}

export const useEditRecommendationDialog = ({
  assets,
  products,
  recommendation,
  groupId,
  tab,
  options,
}: IUseRecommendAssetsDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();
  const { type } = recommendation || {};

  const body = useCallback((): JSX.Element => {
    if (type === 'Recommendation::Product') {
      return (
        <RecommendProductsWizard
          products={products}
          onFinish={(newValue: McGenericRecommendationProduct) => {
            close();
            options?.onSuccess?.(IRecommendationActionName.EDIT, newValue);
          }}
          groupId={groupId}
          recommendation={recommendation}
          tab={tab}
        />
      );
    }
    return (
      <RecommendAssetsWizard
        assets={assets}
        onFinish={(newValue: McGenericRecommendationAsset) => {
          close();
          options?.onSuccess?.(IRecommendationActionName.EDIT, newValue);
        }}
        groupId={groupId}
        recommendation={recommendation}
        tab={tab}
      />
    );
  }, [recommendation, type, assets, groupId, tab, products, close, options]);

  const openDialog = (): void => {
    openModal({
      title: `Recommend ${formatType(type)}`,
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
