import React from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { ICreateCastCrewFormData } from 'components/cast-crew/create-cast-crew/utils';

import { IFormHandlers } from 'helpers/form';
import { IFormData } from 'helpers/form/mm3';

interface ICustomDescriptionFieldProps {
  formData: IFormData<ICreateCastCrewFormData>;
  handlers: Omit<IFormHandlers<ICreateCastCrewFormData>, 'onSetFields'>;
  editModeEnabled?: boolean;
  castCrew: PmGenericCastCrew;
  inputStyle: React.CSSProperties;
}
export const CustomDescriptionField: React.FC<ICustomDescriptionFieldProps> = () => {
  return <></>;
};
export const customLabels = {
  description: 'Description',
};
