import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';

import { useSortDataSectionStore, useStore } from 'store';

import { assetItemRenderer } from 'components/group-items-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';
import { ToastError } from 'components/toast';

import { getAssets } from 'utils/apis/asset';
import { updateRecommendation } from 'utils/apis/recommendation';
import { byId } from 'utils/general';

import { IAsset } from 'types';

interface IRecommendationAssetsSortTabProps {
  setRecommendation: React.Dispatch<React.SetStateAction<McGenericRecommendationAsset>>;
  recommendation: McGenericRecommendationAsset;
}
interface ISortableAsset extends IAsset {
  sequence_number: number;
}

const saveOrder = (id: number, asset_ids: number[]): Promise<McGenericRecommendationAsset> => {
  return updateRecommendation<McGenericRecommendationAsset>({ id, asset_ids });
};

export const RecommendationAssetsSortTab: React.FC<IRecommendationAssetsSortTabProps> = observer(
  ({ recommendation, setRecommendation }) => {
    const { initStore } = useSortDataSectionStore();

    const fetcher = useCallback(async (): Promise<ISortableAsset[]> => {
      if (!recommendation?.asset_ids) {
        return [];
      }
      const assets = await getAssets(recommendation?.asset_ids);
      const assetsById = byId(assets);
      return recommendation?.asset_ids.map((id, index) => ({ ...assetsById[id], sequence_number: index }));
    }, [recommendation.asset_ids]);
    const { toastStore } = useStore();

    const onSave = useCallback(
      async (list) => {
        try {
          if (!recommendation?.id) {
            return;
          }
          const { asset_ids } = await saveOrder(
            recommendation.id,
            list.map((e) => e.id),
          );
          setRecommendation((p) => ({ ...p, asset_ids }));
        } catch (e) {
          toastStore.error(<ToastError error={e} />);
        }
      },
      [recommendation.id, setRecommendation, toastStore],
    );

    useEffect(() => {
      initStore({ fetcher, onSave });
    }, [fetcher, initStore, onSave]);

    return <SortDataSection itemRenderer={assetItemRenderer} />;
  },
);
