import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { map, compact } from 'lodash';

import { useStore } from 'store';

import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { updateGroup } from 'utils/apis/groups';
import { queryParentEvents } from 'utils/apis/events';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { shouldDisplayRemoveFromGroup } from './actions-acl';

import { IMarketingEventActionName } from './types';
import { IMarketingEvent } from 'types';

export const useRemoveFromGroupAction = (
  groups: IMarketingEvent[],
  options: IUseActionsOptionParam,
): IActionConfig<IMarketingEvent, IMarketingEventActionName> => {
  const { toastStore, dialogStore } = useStore();
  const { eventId } = useParams<{ eventId: string }>();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Sub-Event', groups.length);

  const onConfirm = useCallback(async () => {
    try {
      const itemIds = compact(map(groups, 'id'));
      if (!eventId || !itemIds.length) {
        return;
      }
      const [group] = await queryParentEvents({ ids: [eventId] });
      const result = await updateGroup<IMarketingEvent>(
        {
          id: eventId,
          subevent_group_ids: (group.subevent_group_ids || []).filter((e) => !itemIds.includes(e)),
        },
        'id, subevent_group_ids',
      );
      toastStore.success(`${entityWithCount} will be removed from Event`);
      await options?.onSuccess?.(IMarketingEventActionName.REMOVE_FROM_GROUP, result);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error?.text || error}`);
      await options?.onFailure?.();
    }
  }, [groups, eventId, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from the Event?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: IMarketingEventActionName.REMOVE_FROM_GROUP,
    shouldDisplay: shouldDisplayRemoveFromGroup,
    icon: 'remove',
    title: `Remove from Event`,
    intent: Intent.DANGER,
    handler,
  };
};
