import React from 'react';
import { ISidebarSection } from 'store/profile-store';

interface ICustomSidebarSectionProps {
  handleToggleSection: (e: React.SyntheticEvent<HTMLElement>) => void;
  activeSidebarSection: ISidebarSection | null;
}

export const CustomSidebarSection: React.FC<ICustomSidebarSectionProps> = () => {
  return <></>;
};
