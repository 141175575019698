import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Popover, ActionIcon } from '@mantine/core';
import { Search } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

import { FilterText } from 'helpers/filters';
import { useDataSectionStore } from 'store/hooks';
import { IProduct } from 'types';
import { QFilter } from 'helpers/filters/types';

import './style.scss';

const HeaderTextSearch: React.FC = observer(() => {
  const { filters, updateStore } = useDataSectionStore<IProduct>();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (newValues): void => {
      updateStore({ filters: { ...filters, ...newValues } });
    },
    [filters, updateStore],
  );

  const handleIconClick = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header-text-search d-flex align-items-center">
      {isOpen && (
        <FilterText
          label=""
          placeholder="Search"
          name="_"
          filter={filters?._ as QFilter}
          onChange={handleChange}
          className="align-items-center"
          isOpen={isOpen}
        />
      )}
      <Popover position="bottom">
        <Tooltip position="bottom" label="Search List with search operators">
          <ActionIcon size="lg" color="gray.5" radius="sm" variant="subtle" onClick={handleIconClick}>
            <MantineIcon icon={<Search />} />
          </ActionIcon>
        </Tooltip>
      </Popover>
    </div>
  );
});

export default HeaderTextSearch;
