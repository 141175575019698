import { useEffect, useState } from 'react';

import { chipmunk } from 'utils/chipmunk';
import { flags } from 'utils/flags';
import { Model } from 'helpers/filters/types';

export function useGetSsoLink(): { ssoLink: string | undefined } {
  const [ssoLink, setSsoLink] = useState();

  useEffect(() => {
    chipmunk.run(async ({ action }) => {
      const { object } = await action(Model.AFFILIATION, 'get');
      const sso_options = (object.sso_options || []).find(({ provider }) => provider === flags.ssoProvider);
      setSsoLink(sso_options?.sso_endpoint);
    });
  }, []);

  return { ssoLink };
}
