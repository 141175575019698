import React, { FC, useMemo } from 'react';
import { Chart as ChartJS, PointElement, LineElement, ChartOptions } from 'chart.js';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import LoadingBoundary from 'components/loading-boundary/loading-boundary';
import { ChartContainer } from 'components/chart-container';
import { LinkWithArrow } from 'utils/ui';
import { useRemote } from 'utils/hooks';
import { IUseActivityData, IAnalyticsActivityProps, IDataset } from 'types/analytics';

import './style.scss';

ChartJS.register(PointElement, LineElement);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  resizeDelay: 50,
  showScale: false,
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
  elements: {
    point: {
      hitRadius: 20,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 5,
      grid: {
        color: '#425e74',
      },
      ticks: {
        color: '#425e74',
        precision: 0,
      },
    },
    x: {
      grid: {
        color: '#425e74',
      },
      ticks: {
        color: '#425e74',
        precision: 0,
      },
    },
  },
};

interface IActivityChart {
  title?: string;
  subtitle?: string;
  url?: string;
  fetcher: (params?: IAnalyticsActivityProps) => Promise<IUseActivityData[]>;
  aspectRatio: number;
  parseDataset?: (data?: ({ date: string; [key: string]: number | string } | IUseActivityData)[]) => IDataset[];
}

const defaultDatasetParser = (data: IUseActivityData[]): IDataset[] => [
  {
    data: data?.map((item) => item.count),
    label: 'sent',
    borderColor: '#2b95d6',
    backgroundColor: '#1a6da0',
  },
];

export const ActivityChart: FC<IActivityChart> = ({
  title = 'Platform Activity',
  subtitle = 'logins per day',
  fetcher,
  url = '',
  aspectRatio,
  parseDataset = defaultDatasetParser,
}): JSX.Element => {
  const [data, isLoading] = useRemote<IUseActivityData[]>(fetcher);

  const chartData = {
    labels: data?.map((item) => moment(item.date).format('DD MMM')),
    datasets: parseDataset(data),
  };
  const chartOptions = useMemo<ChartOptions<'line'>>(
    () => ({
      ...options,
      aspectRatio,
    }),
    [aspectRatio],
  );

  return (
    <>
      <SectionHeader
        title={title}
        useBackground
        useBorder
        customRightRenderer={() => (url ? <LinkWithArrow to={url} text="View All" /> : <></>)}
        subtitle={`(${subtitle})`}
        horizontalTitle
      />
      <div className="activity-chart">
        <LoadingBoundary loading={isLoading} suspender={<Loading text={'Loading...'} />}>
          <ChartContainer aspectRatio={aspectRatio}>
            <Line options={chartOptions} data={chartData} />
          </ChartContainer>
        </LoadingBoundary>
      </div>
    </>
  );
};
