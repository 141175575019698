import React, { useCallback } from 'react';

import { useStore } from 'store';
import EditAudioDialogBody, { IEditAudioProps } from './edit-audio-body';

export const useEditAudioDialog = (props: IEditAudioProps): [() => void] => {
  const { onSubmit, audio } = props;
  const { dialogStore } = useStore();

  const body = useCallback(() => {
    return <EditAudioDialogBody onSubmit={onSubmit} audio={audio} />;
  }, [onSubmit, audio]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Edit Audio Track',
      body,
    });
  };

  return [openDialog];
};
