import React from 'react';
import { observer } from 'mobx-react-lite';

import { RecommendationDataSection } from 'components/recommendation';
import { Pages } from 'utils/actions';
import { DataSectionPage } from 'components/layout';

export const Recommendations: React.FC<{ page?: Pages }> = observer(({ page = Pages.LIST }) => {
  return (
    <DataSectionPage>
      <RecommendationDataSection persistFilters page={page} />
    </DataSectionPage>
  );
});
