import { useCallback } from 'react';

import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { deleteGroups } from 'utils/apis/groups';
import { formatGroupName } from 'utils/group';
import { pluralEntityInflect } from 'utils/general';
import { shouldDisplayDelete as shouldDisplay } from 'utils/actions/common';
import { Intent } from 'utils/ui';

import type { IIdentifiable, ItemId } from 'types';
import { IGroupConfig, IGroupActionName } from './types';

export const useDeleteGroupAction = (items: IIdentifiable[], options: IUseActionsOptionParam): IGroupConfig => {
  const { toastStore, dialogStore } = useStore();
  const groupLabel = formatGroupName(items[0]['@type']);
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect(groupLabel, items.length);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id).filter(Boolean) as ItemId[];
      await deleteGroups(itemIds);
      options?.onSuccess?.(IGroupActionName.DELETE);
      toastStore.success(`${entityWithCount} deleted`);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IGroupActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
