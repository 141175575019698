import React from 'react';
import { Link } from 'react-router-dom';

import DateElement from 'components/date-list-element/date-list-element';
import { yn } from 'components/label-value-pair';
import { IColumn } from 'components/list-table';
import { AnalyticsContact, UserTerritory } from 'utils/ui';

import { DetailsPageTabs } from 'types';
import { ContactInteractions } from 'components/analytics/utils';
import { IRecommendationSentTableItem } from 'components/analytics/contact-analytics/recommendation-analytics/recommendation-analytics-detailed-list-table/utils';

export const recommendationDetailsColumns: IColumn<IRecommendationSentTableItem>[] = [
  {
    title: 'Recipient',
    renderer: ({ recipient }) => (
      <AnalyticsContact user={recipient} interaction={ContactInteractions.RECOMMENDATIONS_RECEIVED} />
    ),
  },
  {
    title: 'Organization',
    renderer: ({ recipient_organization }) => (
      <div className="analytics-list-table__organization-name text-truncate">
        {recipient_organization?.id && recipient_organization?.id !== '0' ? (
          <Link to={`/organizations/${recipient_organization?.id}`}>{recipient_organization?.name || 'N/A'}</Link>
        ) : (
          <span>N/A</span>
        )}
      </div>
    ),
    visibleFromSize: 9,
  },

  {
    title: 'Country',
    renderer: ({ recipient }) => {
      return recipient.country_name ? (
        <span>{recipient.country_name}</span>
      ) : (
        <UserTerritory countryId={recipient.country_id} />
      );
    },
    visibleFromSize: 9,
  },
  {
    title: 'Sender',
    renderer: ({ sender }) => <AnalyticsContact user={sender} interaction={ContactInteractions.RECOMMENDATIONS_SENT} />,
  },
  {
    title: 'Subject',
    renderer: ({ recommendation_id, subject }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ANALYTICS}`}>{subject}</Link>
    ),
    visibleFromSize: 7,
  },
  {
    title: 'Products',
    renderer: ({ recommendation_id, products_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.PRODUCTS}`}>{products_count}</Link>
    ),
    visibleFromSize: 9,
  },
  {
    title: 'Assets',
    renderer: ({ recommendation_id, assets_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ASSETS}`}>{assets_count}</Link>
    ),
    visibleFromSize: 9,
  },
  { title: 'Login', renderer: ({ requires_login }) => yn(requires_login) || 'N/A', visibleFromSize: 9 },
  { title: 'Views', renderer: ({ views_count }) => views_count || 'N/A' },
  {
    title: 'Last viewed at',
    renderer: ({ last_viewed_at }) => <DateElement date={last_viewed_at} />,
    visibleFromSize: 8,
  },
  { title: 'Expires at', renderer: ({ expires_at }) => <DateElement date={expires_at} />, visibleFromSize: 8 },
  { title: 'Sent at', renderer: ({ sent_at }) => <DateElement date={sent_at} />, visibleFromSize: 9 },
];
