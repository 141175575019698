import React, { useState } from 'react';
import { Tabs } from '@mantine/core';

import { ACCESS_TABS, IAsset, IMm3Asset } from 'types';

import { AccessChangeImmediateForm } from './access-change-immediate-form';

import { IUseActionsOptionParam } from 'utils/actions';
import { MantineIcon } from 'utils/ui/icon';

import {
  AccessChangeScheduledAccessForm,
  AccessChangeScheduledStatusForm,
  AccessChangeScheduledProtectionForm,
  AccessChangeScheduledAccessDelegationForm,
} from './scheduled';

import './style.scss';

const ScheduledTab: React.FC<{ title: string; value: ACCESS_TABS }> = ({ title, value }) => (
  <Tabs.Tab
    value={value}
    key={value}
    leftSection={<MantineIcon icon="time" size={14} className="scheduled-tab-title_icon" />}
  >
    {title}
  </Tabs.Tab>
);
interface IAssetAccessChangeForm {
  items: (IAsset | IMm3Asset)[];
  options: IUseActionsOptionParam;
  readOnly?: boolean;
  tabs?: ACCESS_TABS[];
}

export const scheduledTabs = [
  ACCESS_TABS.SCHEDULED_ACCESS,
  ACCESS_TABS.SCHEDULED_STATUS,
  ACCESS_TABS.SCHEDULED_PROTECTION,
  ACCESS_TABS.SCHEDULED_DELEGATION,
];

export const defaultTabs = Object.values(ACCESS_TABS);

export const AssetAccessChangeForm: React.FC<IAssetAccessChangeForm> = ({
  items,
  options,
  readOnly = false,
  tabs = defaultTabs,
}) => {
  const panels = {
    [ACCESS_TABS.IMMEDIATE]: <AccessChangeImmediateForm items={items} options={options} />,
    [ACCESS_TABS.SCHEDULED_ACCESS]: <AccessChangeScheduledAccessForm items={items} readOnly={readOnly} />,
    [ACCESS_TABS.SCHEDULED_STATUS]: <AccessChangeScheduledStatusForm items={items} readOnly={readOnly} />,
    [ACCESS_TABS.SCHEDULED_PROTECTION]: <AccessChangeScheduledProtectionForm items={items} readOnly={readOnly} />,
    [ACCESS_TABS.SCHEDULED_DELEGATION]: <AccessChangeScheduledAccessDelegationForm items={items} readOnly={readOnly} />,
  };

  const availableTabs = [
    <Tabs.Tab value={ACCESS_TABS.IMMEDIATE} key={ACCESS_TABS.IMMEDIATE}>
      Immediate
    </Tabs.Tab>,
    <ScheduledTab key={ACCESS_TABS.SCHEDULED_ACCESS} value={ACCESS_TABS.SCHEDULED_ACCESS} title="Access" />,
    <ScheduledTab value={ACCESS_TABS.SCHEDULED_STATUS} key={ACCESS_TABS.SCHEDULED_STATUS} title="Status" />,
    <ScheduledTab value={ACCESS_TABS.SCHEDULED_PROTECTION} key={ACCESS_TABS.SCHEDULED_PROTECTION} title="Protection" />,
    <ScheduledTab value={ACCESS_TABS.SCHEDULED_DELEGATION} key={ACCESS_TABS.SCHEDULED_DELEGATION} title="Delegation" />,
  ];

  const tabsList = availableTabs.reduce(
    (acc, tab) => {
      if (!tab.key || !tabs.includes(tab.key as ACCESS_TABS)) {
        return acc;
      }
      const panel = <Tabs.Panel value={tab.key.toString()}>{panels[tab.key]}</Tabs.Panel>;
      acc = { ...acc, tabs: [...acc.tabs, tab], panels: [...acc.panels, panel] };
      return acc;
    },
    { tabs: [] as JSX.Element[], panels: [] as JSX.Element[] },
  );
  const currentTabs = tabsList.tabs;
  const [selectedTab, setTab] = useState<ACCESS_TABS | null>(
    (currentTabs[0]?.key as ACCESS_TABS) || ACCESS_TABS.IMMEDIATE,
  );

  if (currentTabs.length === 1) {
    const tabId = currentTabs[0]?.key;
    if (!tabId) {
      return null;
    }

    return panels[tabId];
  }
  const handleChange = (value: string | null): void => {
    if (!value) return;
    setTab(value as ACCESS_TABS);
  };

  return (
    <Tabs onChange={handleChange} value={selectedTab} className="access-change__tabs">
      <Tabs.List grow>{currentTabs}</Tabs.List>
      {tabsList.panels}
    </Tabs>
  );
};
