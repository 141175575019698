import React from 'react';
import cx from 'classnames';
import './style.scss';

interface IPaginationItemProps {
  label: string;
  page: number;
  tabIndex: number;
  goToPage: React.MouseEventHandler<HTMLAnchorElement>;
}

export const PaginationItem: React.FC<IPaginationItemProps> = ({ label, page, tabIndex, goToPage }) => {
  return (
    <li className={cx({ disabled: page === tabIndex })}>
      <a className="data-section__page-btn" tabIndex={tabIndex} onClick={goToPage} role="button" aria-label={label}>
        {label}
      </a>
    </li>
  );
};

export default React.memo(PaginationItem);
