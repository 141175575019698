import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { flags } from 'utils/flags';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import { AssetTypeAggregations } from 'components/asset/assets-aggregation';

import { dateWithoutHours, formatDate } from 'utils/date';
import { getProducts } from 'utils/apis/product';
import { useRemote } from 'utils/hooks';

import { DetailsPageTabs, IAssetAggregation, IProduct, ItemId } from 'types';
import { isAssetRecommendation } from 'utils/recommendation';

import { formatOwnership } from 'utils/general';
import { formatType } from 'utils/format-type';
import { ProductsWithFutureEpisodes } from './utils';

const { showFutureEpisodesFeature } = flags;

export const RecommendationDetailsOverview: React.FC<{
  recommendation: McGenericRecommendationProduct;
  assetAggregation?: IAssetAggregation;
}> = ({ recommendation, assetAggregation }) => {
  const {
    created_at,
    expires_at,
    sent_at,
    updated_at,
    requires_login,
    generate_links_only,
    message,
    product_ids,
    recipient_list,
    allowed_views,
    status,
    type,
    allows_asset_download,
    include_future_episodes_of_product_ids,
    visible,
  } = recommendation || {};
  const splitMsg = message?.split('\n');

  const fetcher = useCallback(() => {
    if (!showFutureEpisodesFeature) return Promise.resolve([]);

    return getProducts({ product_ids: include_future_episodes_of_product_ids as ItemId[] }, 'id, display_title');
  }, [include_future_episodes_of_product_ids]);
  const [objects] = useRemote(fetcher);

  return (
    <div className="recommendation-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground padded={false} wrapperClassName="p-3 m-3">
        <Row label="Type" value={formatType(type)} />
        <Row label="Status" value={status} size="m" />
        <Row label="Sent at" value={formatDate(sent_at)} rawLabel />
        <Row label="Expires at" value={formatDate(expires_at)} rawLabel />
        <Row label="Sender" value={formatOwnership(recommendation)} size="m" />
        <Row label="Created at" value={formatDate(created_at, dateWithoutHours)} size="m" rawLabel />
        <Row label="Updated at" value={formatDate(updated_at, dateWithoutHours)} size="m" rawLabel />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        <Row
          hidden={isAssetRecommendation({ type })}
          label="Products"
          value={
            <>
              <Link to={`../${DetailsPageTabs.PRODUCTS}`}>{product_ids?.length}</Link>
              <ProductsWithFutureEpisodes products={objects as IProduct[]} />
            </>
          }
          size="m"
        />
        <Row label="Assets" value={<AssetTypeAggregations aggregations={assetAggregation} disabled />} size="m" />
        <Row
          label="Recipients"
          value={<Link to={`../${DetailsPageTabs.RECIPIENTS}`}>{recipient_list?.length}</Link>}
          size="m"
        />
        <Row label="Requires Login" rawLabel value={yn(requires_login)} size="m" fallback="N/A" />
        <Row
          label="List on website"
          rawLabel
          value={yn(visible)}
          size="m"
          fallback="N/A"
          hidden={!flags.showListRecommendationOnWebsiteFeature}
        />
        <Row label="Generate links" rawLabel value={yn(generate_links_only)} size="m" fallback="N/A" />
        <Row
          label="Download"
          value={allows_asset_download ? 'Permitted' : 'Not permitted'}
          hidden={type === 'Recommendation::Product'}
        />
        <Row label="Allowed views" rawLabel value={allowed_views} size="m" fallback="Unlimited" />
      </InfoBoxV2>
      <div className="muted-text recommendation-details__overview-message-title">Message</div>
      <span className="recommendation-details__overview-message">
        {splitMsg?.map((line, i) => <p key={i}>{line}</p>) ?? '—'}
      </span>
    </div>
  );
};
