import { chipmunk } from 'utils/chipmunk';
import type { IUploadType } from 'types/upload';

export const getConfig = (type?: IUploadType): { companionUrl: string; power: string } => {
  const { endpoints = {}, headers = {} } = chipmunk.currentConfig();
  const companion = ['itvstudios'].includes(headers['Affiliation-Id'] || 'none') ? 'companion-eu' : 'companion';

  return type === 'attachment'
    ? {
        companionUrl: endpoints['attachments-companion'],
        power: 'am://attachments/create',
      }
    : { companionUrl: endpoints[companion], power: 'am://assets/create' };
};
