import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Routes } from 'utils/routes';
import { useProfileStore } from 'store/profile-store';

import './style.scss';
import { Divider } from '@mantine/core';

const today = new Date();

const Footer: React.FC<{}> = observer(() => {
  const isDarkMode = useProfileStore(({ settings }) => settings.isDarkMode);
  const theme = isDarkMode ? 'dark' : 'light';

  return (
    <div className="footer ps-4 pe-4">
      <div className="footer__left">
        {today.getFullYear()} &copy; <span>mediafellows GmbH</span>
      </div>
      <div className="footer__right">
        <a className="footer__link" href="mailto:support@mediafellows.com">
          Support
        </a>
        <Divider orientation="vertical" mx={10} />
        <Link className="footer__link" to={`${Routes.IMPRINT}`}>
          Imprint
        </Link>
        <Divider orientation="vertical" mx={10} />
        <Link className="footer__link" to={`${Routes.PRIVACY_POLICY}`}>
          Privacy Policy
        </Link>

        <iframe
          className="ps-8"
          src={`https://mediafellows.betteruptime.com/badge?theme=${theme}`}
          width="300"
          height="30"
        />
      </div>
    </div>
  );
});

export default Footer;
