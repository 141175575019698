import { IContact } from 'types';
import {
  dividerAction,
  IUseActionsOptionParam,
  IUseActionsResponse,
  useActions,
  useGrantAccessPrivilegeAction,
} from 'utils/actions';

import { shouldDisplayGrantAccessPrivilege } from 'utils/actions/common/actions-acl';

import { useAddContactsAction, contactsToItems } from 'utils/actions/access-privilege';

import { IContactActionName, IContactActionConfig } from './types';
import { useApproveContactAction } from './approve';
import { useContactGeneralActions } from './general-actions';
import { useInviteContactAction } from './invite';
import { useReInviteContactAction } from './re-invite';
import { useRejectContactAction } from './reject';
import { useCreateSelectionAction } from './create-selection';
import { useAddToSelectionAction } from './add-to-selection-action';
import { useDeleteAvatarImageAction } from './delete-avatar-image';
import { useUploadPreviewImageAction } from './upload-preview-image';
import { useRemoveFromGroupAction } from './remove-from-group';
import { useImpersonateAction } from './impersonate';
import { useUpdateResponsibleContactAction } from './update-responsible-contact';
import { useRemoveFromConferenceAction } from './remove-from-conference';

export { useUploadPreviewImageAction } from './upload-preview-image';

export * from './types';

export const useContactActions = (
  contacts: IContact[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IContactActionName> => {
  const generalActions = useContactGeneralActions(contacts, options);
  const inviteContactAction = useInviteContactAction(contacts, options);
  const reInviteContactAction = useReInviteContactAction(contacts, options);
  const approveContactAction = useApproveContactAction(contacts, options);
  const rejectContactAction = useRejectContactAction(contacts, options);
  const deleteAvatarAction = useDeleteAvatarImageAction(contacts, options);
  const uploadPreviewAction = useUploadPreviewImageAction(contacts, options);
  const createSelection = useCreateSelectionAction(contacts, options);
  const addToSelectionAction = useAddToSelectionAction(contacts);
  const updateResponsibleContacts = useUpdateResponsibleContactAction(contacts, options);
  const addToAccessPrivilegeAction = useAddContactsAction({
    options: {
      ...options,
      items: contactsToItems(contacts),
      shouldDisplay: shouldDisplayGrantAccessPrivilege,
    },
  });

  const removeFromGroupAction = useRemoveFromGroupAction(contacts, options);
  const impersonateAction = useImpersonateAction(contacts, options);
  const removeFromConferenceAction = useRemoveFromConferenceAction(contacts, options);

  const createAccessPrivilegeAction = useGrantAccessPrivilegeAction(
    { users: contacts },
    {
      ...options,
      shouldDisplay: shouldDisplayGrantAccessPrivilege,
    },
  );

  const actions = [
    impersonateAction,
    dividerAction,
    removeFromGroupAction,
    removeFromConferenceAction,
    dividerAction,
    uploadPreviewAction,
    deleteAvatarAction,
    dividerAction,
    createSelection,
    addToSelectionAction,
    addToAccessPrivilegeAction,
    createAccessPrivilegeAction,
    dividerAction,
    updateResponsibleContacts,
    approveContactAction,
    rejectContactAction,
    inviteContactAction,
    reInviteContactAction,
    ...generalActions,
  ];

  return useActions<IContact, IContactActionName>(actions as IContactActionConfig[], options, contacts);
};
