export interface IBlankValidationOpts {
  allow_blank?: boolean;
}

export const isBlank = (value: unknown): boolean => {
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (value === null || value === undefined) {
    return true;
  }

  if (('' + value).trim().length == 0) {
    return true;
  }

  return false;
};

export const isNil = (value: unknown): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  return false;
};

export const blankValidation = (opts: IBlankValidationOpts, value: unknown): [boolean, string?] => {
  const { allow_blank } = opts;

  if (allow_blank || allow_blank === undefined) {
    return [true];
  }

  if (isBlank(value)) {
    return [false, `This field is required`];
  }

  return [true];
};

export interface INilValidationOpts {
  allow_nil?: boolean;
}
export const nilValidation = (opts: INilValidationOpts, value: unknown): [boolean, string?] => {
  const { allow_nil } = opts;

  if (allow_nil || allow_nil === undefined) {
    return [true];
  }

  if (isNil(value)) {
    return [false, `This field is required`];
  }

  return [true];
};

/* empty validation
 *
 * examples:
 * validations: [ { length: { allow_blank: true } } ]
 * validations: [ { numericality: { allow_nil: true } } ]
 */

export interface IEmptyValidationOpts {
  allow_blank?: boolean;
  allow_nil?: boolean;
}

export const emptyValidation = (opts: IEmptyValidationOpts, value: unknown): [boolean, string?] => {
  const { allow_blank, allow_nil } = opts;

  const [blankResult, blankMessage] = blankValidation({ allow_blank }, value);

  if (!blankResult) {
    return [blankResult, blankMessage];
  }

  const [nilResult, nilMessage] = nilValidation({ allow_nil }, value);

  if (!nilResult) {
    return [nilResult, nilMessage];
  }

  return [true];
};
