import { useBasicStore } from 'store/hooks';
import { byId } from 'utils/general';
import { map, find } from 'lodash';
import { getRootStore } from 'store';

export function useTerritories(ids?: string[] | null): string {
  const { territories } = useBasicStore();

  if (!ids || !ids.length) {
    return '';
  }

  const territoriesById = byId(territories) as { [key: string | number]: { name: string } };

  return ids.map((territoryId) => territoriesById[territoryId]?.name).join(', ');
}

export const useTerritoriesCount = (ids?: string[] | null): number => {
  const { countries } = useBasicStore();

  if (!ids) {
    return 0;
  }

  return ids.reduce((acc, id) => {
    return acc + (countries.filter((country) => country.ancestry?.includes(id)).length || 1);
  }, 0);
};

export const territoriesToCountryIds = (ids?: string[]): Array<string> => {
  const {
    basicStore: { countries },
  } = getRootStore();
  return (ids || []).reduce((acc, id) => {
    const descCountries = countries.filter((country) => country.ancestry?.includes(id));
    const countryIds = descCountries?.length ? map(descCountries, 'id') : id;
    return acc.concat(countryIds);
  }, [] as Array<string>);
};

export function useTerritoryName(id: string): string {
  const { territories } = useBasicStore();

  if (!id) {
    return '';
  }

  return find(territories, { id: id })?.name as string;
}
