import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { useDataSectionStore } from 'store';

import { LabelValuePair as Row } from 'components/label-value-pair';
import { getSimpleBasketItems } from 'components/baskets/basket-preview-tab/get-simple-basket-items';
import SimpleList from 'components/simple-list/simple-list';
import { formatDate } from 'utils/date';
import { pluralWordInflect, getEntityLabel } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { LinkWithArrow } from 'utils/ui';
import { fetchThumbnailImages } from 'components/baskets/utils';

import { IBasket, IBasketStandardized } from 'types';

import './style.scss';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { PreviewInfo, PreviewTab } from 'components/preview-tab';

export interface IBasketPreviewTab {
  basketMapper: (productBasket: IBasket) => IBasketStandardized;
}

export interface ISimpleBasketItem {
  id: string | number;
  label: string;
}

const BasketPreviewTab: React.FC<IBasketPreviewTab> = observer(({ basketMapper }) => {
  const [simpleBasketItems, setSimpleBasketItems] = useState<ISimpleBasketItem[]>();
  const { activeItem: basket } = useDataSectionStore<IBasket>();

  const mappedBasket = useMemo(() => basket && basketMapper(basket), [basket, basketMapper]);

  useEffect(() => {
    let isCancelled = false;
    if (mappedBasket?.id)
      getSimpleBasketItems(mappedBasket).then((newSimpleBasketItems) => {
        if (!isCancelled) setSimpleBasketItems(newSimpleBasketItems.slice(0, 8));
      });

    return () => {
      setSimpleBasketItems(undefined);
      isCancelled = true;
    };
  }, [mappedBasket]);

  const { name, entityCount, owner, updatedAt, id } = mappedBasket || {};

  const fetcher = useCallback(() => fetchThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);

  if (!basket || !mappedBasket?.id) {
    return (
      <div className={cx('basket-preview')}>
        <span className="entity-preview-tab__no-preview">No preview</span>
      </div>
    );
  }

  const entityLabel = pluralWordInflect(getEntityLabel(mappedBasket.entityType), mappedBasket.entityCount, false);

  return (
    <PreviewTab
      title={name}
      detailsLink={`${location.pathname}/${basket?.id}`}
      customThumbnail={
        <MemoizedMultiThumbnail
          height={172}
          width={308}
          className="entity-preview__thumb"
          thumbnailImages={thumbnailImages || [{}]}
        />
      }
    >
      <PreviewInfo gridTemplateColumns="2fr minmax(0, 6fr)">
        <Row label={entityLabel} value={entityCount} size="m" />
        <Row label="Owner" value={owner} size="m" />
        <Row label="Update at" value={formatDate(updatedAt)} size="m" rawLabel />
      </PreviewInfo>
      <hr className="basket-preview__divider" />
      <SimpleList
        items={simpleBasketItems}
        title={'Items'}
        footer={
          <LinkWithArrow
            className="mt-2 basket-preview__link"
            to={`${location.pathname}/${basket?.id}`}
            text="View More"
          />
        }
      />
    </PreviewTab>
  );
});
export default BasketPreviewTab;
