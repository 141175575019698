import React, { useMemo, useCallback } from 'react';

import { ThumbnailImage } from 'components/thumbnail';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { EventTiming } from 'components/event-timing';
import { EventLink } from 'components/event-link';
import { ClipboardView } from 'components/clipboard-view';
import { queryParentEvents } from 'utils/apis/events';
import { ICreateEventStep } from './types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { useRemote } from 'utils/hooks';
import { getAttendeesCount } from 'utils/event-utils';

export const StepSummary: React.FC<ICreateEventStep> = ({ form }) => {
  const {
    assets_item_ids,
    event_password,
    name,
    products_item_ids,
    group_preview_image_url,
    allow_registered_user_access,
    group_parent_group_id,
    eventType,
    location,
  } = form.values;

  const approvedEmailCount = useMemo(() => {
    return getAttendeesCount(form.values);
  }, [form.values]);

  const getParentEvent = useCallback(async () => {
    if (!group_parent_group_id) {
      return null;
    }
    const events = await queryParentEvents({ ids: [group_parent_group_id] });

    return events?.[0]?.name;
  }, [group_parent_group_id]);

  const [parentEvent] = useRemote(getParentEvent, null);
  const isTimed = form.values.eventTiming === 'timed';
  return (
    <div className="event-detail__tab__wrapper">
      <div className="event-wizard__summary__content h-100">
        <InfoBoxV2 gridTemplateColumns="1fr 4fr" title="Summary">
          <Row label="Name" value={name} size="m" />
          <Row label="Main event" value={parentEvent} size="m" hideIfNoValue />
          <Row
            label="URL"
            rawValue
            value={<EventLink event={form.values} className="d-block" />}
            valueClassName="text-wrap text-break"
            size="m"
          />
          {eventType !== 'subevent' && (
            <Row
              label="Password"
              rawValue
              value={
                <ClipboardView textClassName="text-wrap text-break" className="d-block">
                  {event_password}
                </ClipboardView>
              }
              size="m"
            />
          )}
          <EventTiming event={{ ...form.values, is_timed_event: isTimed }} />
          <Row label="Location" rawLabel value={location} size="m" />
          <Row label="Products" value={products_item_ids?.length || 0} size="m" />
          <Row label="Assets" value={assets_item_ids?.length || 0} size="m" />
          {eventType !== 'subevent' && (
            <>
              <Row label="Approved emails" value={approvedEmailCount} size="m" />
              <Row label="Allow active contacts" value={yn(allow_registered_user_access)} size="m" />
            </>
          )}
        </InfoBoxV2>
        <ThumbnailImage className="event-wizard__summary__image" url={group_preview_image_url} />
      </div>
    </div>
  );
};
