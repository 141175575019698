import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { SectionHeader } from 'components/section-header';
import { ConferenceActionsList } from 'components/action';
import { ConferenceDetailsTabsView } from './conference-details-tabs-view';

import { useEditMode, useRefreshDataSection, useRemote, useTab } from 'utils/hooks';
import { byId } from 'utils/general';
import { Pages } from 'utils/actions';
import { IConferenceActionName } from 'utils/actions/conference/types';
import { deleteUnreferencedAttachments } from 'utils/apis/attachment';
import { Classes } from 'utils/ui';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { updateConference } from 'utils/apis/conference';
import { getAssets } from 'utils/apis/asset';
import { useStore } from 'store';
import { ToastError } from 'components/toast';

import { Routes } from 'utils/routes';
import { IConference, DetailsPageTabs, IAsset } from 'types';

import { getHeaderActions } from './utils';

import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const defaultTab = DetailsPageTabs.OVERVIEW;
const options = [defaultTab, DetailsPageTabs.ASSETS, DetailsPageTabs.CONTACTS];
const editableTabs = [defaultTab];

const customContext = {
  properties: {
    mode: { required: true },
    title: { required: true },
    owner_id: { required: true },
  },
};

export const ConferenceDetailsLoaded: React.FC<{
  conference: IConference;
  setConference: Dispatch<SetStateAction<IConference>>;
}> = observer(({ conference, setConference }) => {
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const { toastStore } = useStore();
  const { title, asset_ids, user_ids } = conference || {};
  const [currentTab, setCurrentTab] = useTab(defaultTab, options);
  const handleSubmit = useCallback(
    async (values: IConference) => {
      try {
        const result = await updateConference(values);
        await deleteUnreferencedAttachments(conference, result, ['background_media', 'banner_media']);

        setConference(result);
        setEditModeEnabled(false);
        toastStore.success('Conference has been updated successfully!');
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [conference, setConference, setEditModeEnabled, toastStore],
  );
  const conferenceForm = useForm(conference, Model.CONFERENCE, handleSubmit, customContext);

  const onSuccess = useRefreshDataSection();

  const navigate = useNavigate();

  const onActionSuccess = useCallback(
    async (action: IConferenceActionName, newValue: IConference) => {
      switch (action) {
        case IConferenceActionName.DELETE:
          navigate(`${Routes.CONFERENCES}`);
          break;
        case IConferenceActionName.RESET:
        case IConferenceActionName.ADD_ASSETS:
        case IConferenceActionName.ADD_CONTACTS:
          setConference(newValue);
          break;
      }
    },
    [navigate, setConference],
  );

  const setEditMode = useCallback(
    (isEditMode: boolean) => {
      if (isEditMode && !editableTabs.includes(currentTab)) {
        setCurrentTab(editableTabs[0], true);
        setEditModeEnabled(isEditMode, false);
        return;
      }
      setEditModeEnabled(isEditMode);
    },
    [setCurrentTab, setEditModeEnabled, currentTab],
  );

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  const dropdownMenu = useMemo((): JSX.Element => {
    if (!conference) {
      return <></>;
    }
    return (
      <ActionsMenu
        component={ConferenceActionsList}
        items={[conference]}
        options={{ context: 'single', page: Pages.DETAILS, onSuccess: onActionSuccess }}
      />
    );
  }, [conference, onActionSuccess]);
  const headerActions = getHeaderActions(editModeEnabled, setEditMode, conferenceForm);

  const customLeftRenderer = useCallback((): JSX.Element => {
    if (!title) {
      return <></>;
    }
    return (
      <div className="my-0 d-flex flex-column">
        <div className="mt-1 d-flex flex-column">
          <h3 className="section-header__title">{title}</h3>
          <div className={Classes.TEXT_MUTED}>{`${asset_ids?.length} Assets | ${user_ids.length} Contacts`}</div>
        </div>
      </div>
    );
  }, [asset_ids?.length, user_ids.length, title]);

  const fetchAssets = useCallback(async () => {
    if (!asset_ids?.length) {
      return [];
    }
    const assets = await getAssets(asset_ids);
    const assetsById = byId(assets);
    return asset_ids.reduce((acc, id) => (assetsById?.[id] ? [...acc, assetsById[id]] : acc), []);
  }, [asset_ids]);

  const [assets = []] = useRemote<IAsset[]>(fetchAssets, []);
  return (
    <>
      <SectionHeader
        transparent={Boolean(conference?.background_media)}
        customLeftRenderer={customLeftRenderer}
        useBackground
        actions={headerActions}
        dropdownMenu={dropdownMenu}
      />

      <div className="conference-details__content-wrapper flex-full">
        <div className="conference-details__tabs-wrapper h-100">
          <ConferenceDetailsTabsView
            conference={conference}
            editModeEnabled={editModeEnabled}
            assets={assets}
            conferenceForm={conferenceForm}
            currentTabId={currentTab}
            onTabChangeHandle={handleTabChange}
            onSuccess={onSuccess}
            setConference={setConference}
          />
        </div>
      </div>
    </>
  );
});
