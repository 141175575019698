import { set, isEmpty, chunk } from 'lodash';
import { ISearchFilter, ItemId } from 'types';
import { IFiltersDefinition, RangeFilter } from 'helpers/filters/types';

export const isObject = (obj: unknown): boolean => Object.prototype.toString.call(obj) === '[object Object]';

type IObject = Record<string, unknown>;

function parseObjectKeysToPaths(obj: IObject, prefix: string): IObject {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const path = `${prefix}.${key}`;

    if (isObject(value)) return { ...acc, ...parseObjectKeysToPaths(value as IObject, path) };
    if (Array.isArray(value) && value.length && isObject(value[0])) {
      return { ...acc, ...parseArrayToPaths(value, path) };
    }

    return { ...acc, [path]: value || '' };
  }, {}) as IObject;
}

function parseArrayToPaths(items: unknown[], prefix: string): IObject {
  return items.reduce((acc: IObject, item: unknown, index: number) => {
    const path = `${prefix}[${index}]`;

    if (isObject(item)) return { ...acc, ...parseObjectKeysToPaths(item as IObject, path) };
    if (Array.isArray(item)) return { ...acc, ...parseArrayToPaths(item, path) };
    return { ...acc, [path]: item };
  }, {}) as IObject;
}

// parse js objects to key path, e.g, { a: { b: { c: [1] }}} -> { 'a.b.c[0]': 1 }
export function parseObjectToKeyPath<T extends {}, P>(obj: T, exclude: string[] = []): P {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (exclude.includes(key)) {
      return { ...acc, [key]: value };
    }

    if (isObject(value)) return { ...acc, ...parseObjectKeysToPaths(value as T, key) };
    if (Array.isArray(value) && isObject(value[0])) return { ...acc, ...parseArrayToPaths(value, key) };

    return { ...acc, [key]: value };
  }, {} as P);
}

interface IPreparePayloadParams {
  nullifyEmptyStrings?: boolean;
}
// removes empty strings and sets defaults
export const preparePayload = <T extends {}, U>(payload: T, defaults?: U, options: IPreparePayloadParams = {}): T => {
  const obj: T = Object.assign(defaults || ({} as T), payload);

  const subjectValues = ['valid', 'errorMessage'];
  subjectValues.map((k) => delete obj[k]);

  const result = Object.keys(obj).reduce((acc, k) => {
    if (obj[k] === null || obj[k] === undefined) {
      return acc;
    }
    if (options?.nullifyEmptyStrings && obj[k] === '') {
      return { ...acc, [k]: null };
    }

    return { ...acc, [k]: obj[k] };
  }, {} as T);

  return result;
};

export const removeObjectsFromPayload = <T extends {}>(payload: T): T | {} => {
  return Object.entries(payload).reduce((acc, [key, value]) => {
    if (isObject(value)) return acc;
    return { ...acc, [key]: value };
  }, {});
};

const cloneFirstLevelProps = <T extends {}>(obj: T): Partial<T> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!key.includes('.')) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
};

// parse object keys to js objects, e.g, { 'a.b.c[0]': 1 } -> { a: { b: { c: [1] }}}
export function parseKeyPathToObject<T, P = Record<string | number, unknown>>(keyPathObj: T): P {
  if (!keyPathObj) {
    return {} as P;
  }

  // we need to clone first level for the case when we have
  // paths (keylevel1.keylevel2) and existing key in the given object (keylevel1)
  // otherwice some data will be missing.
  // more details in the test - deep parses payload's key path to a js object with existing key
  const res = Object.entries(keyPathObj).reduce((acc, [path, value]) => {
    return { ...set(acc, path, value) };
  }, cloneFirstLevelProps(keyPathObj));

  return Object.entries(res).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      return { ...acc, [key]: parseKeyPathToObject(value) };
    }

    return { ...acc, [key]: value };
  }, {} as P);
}

export function cleanUp<T extends {}, U>(payload: T, defaults?: U, maxCalls = 10): T {
  if (!isObject(payload) || maxCalls < 1) {
    return payload;
  }

  const result = preparePayload<T, U>(payload, defaults);
  return Object.entries(result).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: cleanUp(value as T, {}, maxCalls - 1),
    }),
    {} as T,
  );
}

export const mergeObjects = <T>(state: T, values: Partial<T>): T => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      return { ...acc, [key]: mergeObjects(state?.[key] || {}, value as Partial<T>) };
    }
    if (Array.isArray(value) && isObject(value[0])) {
      return { ...acc, [key]: value.map((v, index) => mergeObjects(state[key]?.[index] || {}, v)) };
    }

    return { ...acc, [key]: value };
  }, state);
};

export const getFiltersFromDefinition = (definition?: IFiltersDefinition): ISearchFilter[] => {
  return Object.values(definition || {}).reduce((acc: ISearchFilter[], item) => {
    if (!isEmpty(item['value'])) {
      acc.push([item.attribute, item.method, item['value']]);
      return acc;
    }

    if (!isEmpty(item['end']) && !isEmpty(item['start'])) {
      acc.push([item.attribute, item.method, item['start'], item['end']] as unknown as ISearchFilter);
      return acc;
    }

    return acc;
  }, []) as ISearchFilter[];
};

export const getQueryFromFilters = (filters: ISearchFilter[] = []): string | undefined => {
  if (!filters.length) {
    return;
  }

  const parts = filters.map((f) => {
    const operator = f[1];
    switch (operator) {
      case 'in':
        const v = (Array.isArray(f[2]) ? f[2] : [f[2]]).map((v) => `"${v}"`);
        return `${f[0]}:(${v.join(' OR ')})`;
      case 'range':
        const fr = f as unknown as RangeFilter;
        return `${fr[0]}:[${fr[2]} TO ${fr[3]}]`;
      case 'q':
        return `(${f[2]})`;

      default:
        return `${f[0]}:("${f[2]}")`;
    }
  });

  return parts.join(' AND ');
};

export const handleLongIdsParam = async <T, P = ItemId>(
  callback: (ids: P[]) => Promise<T[]>,
  value: P[],
  chunkSize = 100,
): Promise<T[]> => {
  const chunks = chunk(value, chunkSize);
  const promises = chunks.map((chunk) => {
    return callback(chunk);
  });
  const results = await Promise.all(promises);
  return results.flat();
};
