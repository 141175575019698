import { useCallback } from 'react';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayUImpersonateAction } from './actions-acl';
import { impersonate } from './api';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';

export const useImpersonateAction = (items: IContact[], options: IUseActionsOptionParam): IContactActionConfig => {
  const { toastStore, basicStore } = useStore();
  const name = basicStore?.buyerDomain?.name;

  const handler = useCallback(async () => {
    try {
      if (!name) {
        return;
      }
      const { login_domain: domain, id } = await impersonate(items[0].email);

      const uri = `${domain[0]}://${name}/session/${id}`;
      window.open(uri, '_blank');

      toastStore.success(`Contact impersonated successfully!`);
      await options?.onSuccess?.();
    } catch (error) {
      toastStore.error(`Contact impersonation Failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore, name]);

  return {
    name: IContactActionName.IMPERSONATE,
    shouldDisplay: shouldDisplayUImpersonateAction,
    icon: 'inherited-group',
    title: 'Impersonate',
    handler,
  };
};
