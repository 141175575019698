import React from 'react';
import { Link } from 'react-router-dom';

import DateElement from 'components/date-list-element/date-list-element';
import { yn } from 'components/label-value-pair';
import { IColumn } from 'components/list-table';
import { AnalyticsContact, UserTerritory } from 'utils/ui';

import { DetailsPageTabs } from 'types';
import { ContactInteractions } from 'components/analytics/utils';

export const recommendationSentColumns: IColumn[] = [
  {
    title: 'Recipient',
    renderer: ({ recipient }) => (
      <AnalyticsContact user={recipient} interaction={ContactInteractions.RECOMMENDATIONS_RECEIVED} />
    ),
  },
  {
    title: 'Organization',
    renderer: ({ recipient_organization }) => (
      <div className="analytics-list-table__organization-name text-truncate">
        {recipient_organization?.id && recipient_organization?.id !== '0' ? (
          <Link to={`/organizations/${recipient_organization?.id}`}>{recipient_organization?.name || 'N/A'}</Link>
        ) : (
          <span>N/A</span>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    renderer: ({ recipient }) => {
      return recipient.country_name ? (
        <span>{recipient.country_name}</span>
      ) : (
        <UserTerritory countryId={recipient.country_id} />
      );
    },
  },
  {
    title: 'Responsible contact',
    renderer: ({ recipient }) => {
      const { responsible_user } = recipient;
      return (
        <span>
          {responsible_user && responsible_user?.id !== '0' ? (
            <Link
              className="analytics-list-table__responsible-contact-name text-truncate"
              to={`/contacts/${responsible_user?.id}`}
            >
              {responsible_user?.full_name ||
                responsible_user.first_name + ' ' + responsible_user.last_name ||
                responsible_user?.email ||
                'N/A'}
            </Link>
          ) : (
            <span className="analytics-list-table__responsible-contact-name text-truncate">N/A</span>
          )}
        </span>
      );
    },
  },
  {
    title: 'Subject',
    renderer: ({ recommendation_id, subject }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ANALYTICS}`}>{subject}</Link>
    ),
  },
  {
    title: 'Products',
    renderer: ({ recommendation_id, products_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.PRODUCTS}`}>{products_count}</Link>
    ),
  },
  {
    title: 'Assets',
    renderer: ({ recommendation_id, assets_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ASSETS}`}>{assets_count}</Link>
    ),
  },
  { title: 'Login', renderer: ({ requires_login }) => yn(requires_login) || 'N/A' },
  // { title: 'Permissions', renderer: ({ access_level }) => access_level },
  { title: 'Views', renderer: ({ views_count }) => views_count || 'N/A' },
  { title: 'Last viewed at', renderer: ({ last_viewed_at }) => <DateElement date={last_viewed_at} /> },
  { title: 'Expires at', renderer: ({ expires_at }) => <DateElement date={expires_at} /> },
  { title: 'Sent at', renderer: ({ sent_at }) => <DateElement date={sent_at} /> },
];
