import React from 'react';

import DateElement from 'components/date-list-element/date-list-element';
import { UserAgent, AnalyticsContext } from 'utils/ui';

import { ILoginTableItem } from './types';
import { formatDuration } from 'utils/date';

export const contactDetailPageColumns: { title: string; renderer: (data: ILoginTableItem) => React.ReactNode }[] = [
  { title: 'Active Time', renderer: ({ total_sec_duration }) => formatDuration(total_sec_duration, 'seconds') },
  { title: 'Login context', renderer: ({ group }) => <AnalyticsContext group={group} /> },
  { title: 'Device info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  { title: 'Logged in at', renderer: ({ visit_start }) => <DateElement date={visit_start} /> },
  { title: 'Logged out at', renderer: ({ visit_end }) => <DateElement date={visit_end} /> },
];
