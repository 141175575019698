import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { IconAlias } from 'icons';
import { FormSubmitSection } from 'components/form-submit-section';

import { shouldDisplayAddEntity as shouldDisplay } from './actions-acl';
import { IConferenceActionConfig, IConferenceActionName } from 'utils/actions/conference/types';
import { pluralEntityInflect } from 'utils/general';

import { FormInput, FormMultiContacts, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { IContact, IConference, IQueryParams, ISearchFilter } from 'types';
import { updateConference } from 'utils/apis/conference';
import { queryContacts } from 'utils/apis/contacts';

export interface IAddUsersToConferenceFormProps {
  handleSubmit: (conference: IConference) => void;
  conference: IConference;
}

export const AddUsersToConferenceForm: React.FC<IAddUsersToConferenceFormProps> = ({ conference, handleSubmit }) => {
  const { code, title, user_ids } = conference || {};
  const { formData, values, handlers, onSubmit } = useForm<Partial<IConference>>(
    { code, user_ids: [] },
    Model.PRODUCTS,
    handleSubmit,
  );

  const fetchItems = useCallback(
    (params: IQueryParams): Promise<IContact[]> => {
      const filters: ISearchFilter[] = [['status', 'eq', 'active']];
      if (user_ids?.length) {
        filters.push(['id', 'not_in', user_ids]);
      }
      return queryContacts(params, filters);
    },
    [user_ids],
  );

  return (
    <form onSubmit={onSubmit}>
      <FormMultiContacts
        label="Contacts And Selections"
        name="user_ids"
        {...formData.user_ids}
        {...handlers}
        fetchValues={fetchItems}
      />
      <FormInput className="mb-3" name="title" label="Conference" value={title} size="sm" disabled />
      <FormSubmitSection submitDisabled={!values.user_ids?.length} labels={{ confirm: 'Save' }} />
    </form>
  );
};

export const useAddContactsAction = (
  items: IConference[],
  options: IUseActionsOptionParam,
): IConferenceActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async ({ code, user_ids = [] }: IConference): Promise<void> => {
      const { entity, entityWithCount } = pluralEntityInflect('Contact', user_ids.length);

      try {
        if (!items?.length) {
          return;
        }
        const result = await updateConference({ code, user_ids: user_ids.concat(items[0].user_ids) });
        toastStore.success(`${entityWithCount} will be added`);
        await options?.onSuccess?.(IConferenceActionName.ADD_CONTACTS, result);
        dialogStore.close();
      } catch (error) {
        toastStore.error(`Adding ${entity} to Conference failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [dialogStore, items, options, toastStore],
  );

  const handler = useCallback((): void => {
    dialogStore.openModal({
      title: 'Add Contact(s) to this Conference',
      body: () =>
        items?.length ? <AddUsersToConferenceForm conference={items[0]} handleSubmit={handleSubmit} /> : <></>,
    });
  }, [dialogStore, handleSubmit, items]);

  return {
    name: IConferenceActionName.ADD_CONTACTS,
    icon: IconAlias.ACTION_ADD_ASSETS,
    title: 'Add Contacts',
    handler,
    shouldDisplay,
  };
};
