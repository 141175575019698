import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { DataSection } from 'components/data-section';
import { CalGenericLocation } from '@mediafellows/mm3-types';
import { useControlDataSection, useRefreshDataSection } from 'utils/hooks';
import { InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { LocationDataSectionItemWrapper } from 'components/locations-data-section/location-data-section-item-wrapper';
import { LocationPreview } from 'components/locations/location-preview/location-preview';
import { useEditLocation, initialValues } from 'components/scheduler/location';
import { Button } from '@mantine/core';

import { LocationItem } from 'components/locations/location-item/location-item';
import { LocationsFilters } from 'components/locations-filters/locations-filters';
import { LocationActionsList } from 'components/action';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useDataSectionStore } from 'store/index';
import { LocationCustomSortMenu } from 'components/locations/locations-custom-sort-menu/locations-custom-sort-menu';
import { ActionsMenu } from 'components/action/utils';

import { locationSchema } from 'utils/schemas/location';
import { useManageMeetingsHosts } from 'utils/actions/meeting/manage-hosts';
import { MantineIcon } from 'utils/ui/icon';
import { Plus } from 'blueprint5-icons';
import { DataSectionPage } from 'components/layout';

const sidebarTabs = {
  preview: <LocationPreview />,
  filters: <LocationsFilters />,
  selectedItemRenderer(location: CalGenericLocation): React.ReactElement {
    return <LocationItem location={location} />;
  },
};

const defaultFilters = {
  _: new QFilter(''),
  host_ids: new InFilter('host_ids', []),
  location_type: new InFilter('meta.location_type', []),
  starts_at: new RangeFilter('starts_at', '', ''),
  ends_at: new RangeFilter('ends_at', '', ''),
};

const params = {
  persistFilters: true,
  model: Model.LOCATIONS,
  presetSuffix: 'location',
  defaultFilters,
  stats: `meta.location_type`,
  schema: locationSchema,
};

const LocationsPage: React.FC = observer(() => {
  useControlDataSection(params);
  const { checked } = useDataSectionStore<CalGenericLocation>();

  const onSuccess = useRefreshDataSection();
  const options = useMemo<IUseActionsOptionParam<CalGenericLocation>>(
    () => ({
      context: 'single',
      page: Pages.LOCATIONS,
      onSuccess,
    }),
    [onSuccess],
  );

  const itemRenderer = useCallback(
    (location: CalGenericLocation): React.ReactElement => {
      if (!location) {
        return <></>;
      }

      return (
        <LocationDataSectionItemWrapper
          key={location.id}
          location={location}
          contextMenu={<ActionsMenu component={LocationActionsList} items={[location]} options={options} />}
        />
      );
    },
    [options],
  );

  const openCreateListDialog = useEditLocation(initialValues as CalGenericLocation, options).handler;
  const manageHostsAction = useManageMeetingsHosts(options);
  return (
    <DataSectionPage>
      <DataSection
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        customSortMenu={LocationCustomSortMenu}
        contextMenu={
          <ActionsMenu
            component={LocationActionsList}
            items={checked}
            options={{
              context: 'multi',
              page: Pages.LOCATIONS,
              onSuccess,
            }}
          />
        }
        customOrderRightSection
        headerRightSection={
          <>
            <Button
              size="xs"
              leftSection={<MantineIcon icon={manageHostsAction.icon} />}
              onClick={manageHostsAction.handler}
              variant="primary"
              disabled={manageHostsAction.isDisabled}
              loading={manageHostsAction.isDisabled}
              className="me-2"
            >
              {manageHostsAction.title}
            </Button>
            <Button
              size="xs"
              leftSection={<MantineIcon icon={<Plus />} />}
              onClick={openCreateListDialog}
              variant="primary"
            >
              {'Create Location'}
            </Button>
          </>
        }
      />
    </DataSectionPage>
  );
});

export default LocationsPage;
