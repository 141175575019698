import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { DataSectionRefreshButton } from 'components/data-section-refresh-button';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { useRemote } from 'utils/hooks';
import { getList } from 'utils/apis/showcase';
import { searchListItems } from 'utils/apis/showcase-item';

import { CollectionDetailsLoaded } from './collection-details-loaded';

const CollectionDetails: React.FC<{}> = observer(() => {
  const { collectionId } = useParams<{ collectionId: string }>();

  const fetchCollection = useCallback(async (): Promise<UmGenericListCollection | undefined> => {
    return getList<UmGenericListCollection>(collectionId);
  }, [collectionId]);

  const [collection, loading, setCollection, refreshCollection] = useRemote(fetchCollection);

  const fetchProductsIds = useCallback(async (): Promise<number[]> => {
    const items = await searchListItems(collectionId, [['entity_type', 'eq', 'product']], 'id, entity_id');
    return items.map((e) => e.entity_id);
  }, [collectionId]);
  const [productIds, , , refreshProductIds] = useRemote(fetchProductsIds, []);

  const fetchSubCollectionIds = useCallback(async (): Promise<number[]> => {
    const items = await searchListItems(collectionId, [['entity_type', 'eq', 'list/collection']], 'id, entity_id');
    return items.map((e) => e.entity_id);
  }, [collectionId]);
  const [subCollectionIds, , , refreshSubCollectionsCount] = useRemote(fetchSubCollectionIds, []);

  const refreshPage = (): void => {
    refreshCollection();
    refreshSubCollectionsCount();
    refreshProductIds();
  };

  return (
    <div
      className="d-flex flex-column background-wrapper w-100 h-100"
      style={{ backgroundImage: `url(${collection?.preview_image?.url})` }}
    >
      <div className="background-wrapper__index w-100 h-100">
        <div className="d-flex align-items-center justify-content-between">
          <DashboardBreadcrumbs className="mb-3" />
          <DataSectionRefreshButton customRefresh={refreshPage} />
        </div>
        {loading && <Loading text="Loading collection Details..." />}
        {!loading && collection?.id && (
          <CollectionDetailsLoaded
            collection={collection}
            setCollection={setCollection}
            refreshSubCollectionsCount={refreshSubCollectionsCount}
            refreshProductIds={refreshProductIds}
            productIds={productIds}
            subCollectionIds={subCollectionIds}
          />
        )}
        {!loading && !collection?.id && (
          <SectionMessage intent={SectionMessageType.EMPTY} title="Uh-Oh!">
            It appears that this page is empty.
          </SectionMessage>
        )}
      </div>
    </div>
  );
});

export default CollectionDetails;
