import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon, Button } from '@mantine/core';
import { Intent, MantineIcon } from 'utils/ui';
import { FormInput, useForm } from 'helpers/form';
import { EyeOff, EyeOn } from 'blueprint5-icons';

export interface IPasswordFields {
  user_password: string;
  user_password_confirmation: string;
}

const initialValues: IPasswordFields = {
  user_password: '',
  user_password_confirmation: '',
};

export interface IStepPasswordProps {
  inProgress: boolean;
  onValidChange?: (valid: boolean) => void;
  onContinue: (formData: IPasswordFields) => void;
}

const StepPassword: React.FC<IStepPasswordProps> = observer((props) => {
  const { inProgress, onContinue, onValidChange } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleSubmit = useCallback(
    (data: IPasswordFields, valid: boolean) => {
      if (valid) {
        onContinue && onContinue(data);
      }
    },
    [onContinue],
  );

  const { formData, handlers, valid, onSubmit } = useForm<IPasswordFields>(
    initialValues,
    'um.confirmation',
    handleSubmit,
  );

  useEffect(() => {
    onValidChange && onValidChange(valid);
  }, [onValidChange, valid]);
  const togglePasswordVisibilityField = (): void => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <form className="py-4 px-1" onSubmit={onSubmit}>
        <FormInput
          name="user_password"
          type={showPassword ? 'text' : 'password'}
          rightElement={
            <ActionIcon variant="subtle" color="gray.5" onClick={togglePasswordVisibilityField}>
              <MantineIcon icon={showPassword ? <EyeOff /> : <EyeOn />} />
            </ActionIcon>
          }
          label="Password"
          showPositiveValidation={true}
          {...formData.user_password}
          {...handlers}
        />
        <FormInput
          name="user_password_confirmation"
          type={showPassword ? 'text' : 'password'}
          rightElement={
            <ActionIcon variant="subtle" color="gray.5" onClick={togglePasswordVisibilityField}>
              <MantineIcon icon={showPassword ? <EyeOff /> : <EyeOn />} />
            </ActionIcon>
          }
          label="Confirm Password"
          placeholder="Repeat Password"
          showPositiveValidation={true}
          {...formData.user_password_confirmation}
          {...handlers}
        />
      </form>
      <div className="registration-confirmation__btngroup">
        <Button disabled={!valid} loading={inProgress} variant={Intent.PRIMARY} type="submit" onClick={onSubmit}>
          Continue
        </Button>
      </div>
    </>
  );
});

export default StepPassword;
