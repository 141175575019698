import React from 'react';
import cx from 'classnames';
import './style.scss';
import { IStyled } from 'types';

export interface IInfoBoxV2Props extends IStyled {
  gridTemplateColumns?: string | null;
  title?: string;
  useBackground?: boolean;
  padded?: boolean;
  withBorder?: boolean;
  wrapperClassName?: string;
  newline?: boolean;
  hidden?: boolean;
}
/**
 * InfoBoxV2 will be replacing InfoBox and should be used in future modules/pages
 *
 */

const InfoBoxV2: React.FC<IInfoBoxV2Props> = ({
  children,
  className,
  wrapperClassName,
  gridTemplateColumns,
  title = '',
  useBackground = false,
  padded = true,
  withBorder = false,
  newline = false,
  hidden,
}) => {
  if (hidden) {
    return <></>;
  }

  return (
    <div
      className={cx(
        'infobox-wrapper',
        {
          'infobox--use-background': useBackground,
          'infobox--with-border': withBorder,
          'mb-3': newline,
        },
        wrapperClassName,
      )}
    >
      {title && <div className="infobox__title"> {title} </div>}
      <div
        style={{ gridTemplateColumns: gridTemplateColumns ?? undefined }}
        className={cx(
          'infobox__content',
          {
            'infobox__content--padded': padded,
          },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default InfoBoxV2;
