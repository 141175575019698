import React from 'react';
import { observer } from 'mobx-react-lite';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { Row, yn } from 'components/label-value-pair';
import { PreviewInfo } from 'components/preview-tab';

import { useCollectionCounts } from 'components/collection/collection-preview-tab/utils';

import { useDataSectionStore } from 'store';
import { formatDate } from 'utils/date';
import { formatOwnership } from 'utils/general';
import { useCategories } from 'utils/hooks';

export const CollectionPreviewAttributes: React.FC<{ overrideCollection?: UmGenericListCollection }> = observer(
  ({ overrideCollection }) => {
    const { activeItem } = useDataSectionStore<UmGenericListCollection>();
    const collection = overrideCollection || activeItem;

    const { created_at, purpose, updated_at, sublist, meta, allowed_item_types, id } = collection || {};
    const genres = useCategories(meta?.category_ids);

    const { productIds, loadingProducts, subCollectionIds, loadingSubCollections } = useCollectionCounts(id, sublist);

    return (
      <PreviewInfo gridTemplateColumns="120px 1fr">
        <Row label="Identifier" value={purpose} />
        <Row label="Genres" value={genres} size="m" />
        <Row label="is Sub-Collection" value={yn(sublist)} rawLabel />
        <Row label="Allowed Objects" value={allowed_item_types?.join(', ')} rawLabel />
        <Row label="Description" valueClassName="text-truncate" value={meta?.description} size="m" />
        <Row label="Ownership" value={formatOwnership(collection)} size="m" />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        {!sublist ? (
          <Row
            label="Sub-Collection count"
            rawLabel
            value={subCollectionIds?.length}
            loading={loadingSubCollections}
            valueClassName="d-block"
          />
        ) : (
          <></>
        )}
        <Row
          label="Products count"
          loading={loadingProducts}
          rawLabel
          value={productIds?.length}
          valueClassName="d-block"
        />
      </PreviewInfo>
    );
  },
);
