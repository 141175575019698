import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { logger } from 'utils/logger';
import { affiliationSchema } from 'utils/schemas/affiliation';
import { IAffiliation, IDomain } from 'types';

declare const AFFILIATION;

export const loadAffiliationId = async (): Promise<string> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { id } = (await action(Model.AFFILIATION, 'get')).object;

      return id;
    } catch (error) {
      logger.error(error);
      return AFFILIATION === 'generic' ? 'mfx' : AFFILIATION;
    }
  });
};

export const loadAffiliation = async (schema = affiliationSchema): Promise<IAffiliation> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { object } = await action(Model.AFFILIATION, 'get', { schema });
      return object;
    } catch (error) {
      logger.error(error);
    }
  });
};

export const updateAffiliation = async (affiliation?: Partial<IAffiliation>): Promise<IAffiliation> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { object } = await action(Model.AFFILIATION, 'update', {
        body: {
          ...affiliation,
        },
      });
      return object;
    } catch (error) {
      logger.error(error);
    }
  });
};

export const fetchDomains = async (model: Model.BUYER_DOMAINS | Model.SELLER_DOMAINS): Promise<IDomain[]> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { objects } = await action(model, 'get', { params: { per: 50 } });
      return objects;
    } catch (error) {
      logger.error(error);
    }
  });
};
