import { IAssetPresetFields } from 'types';

export const presetKeys: Array<keyof IAssetPresetFields> = [
  'products',
  'classification',
  'protection_levels',
  'watermark',
  'access_level',
  'permissions',
  'languages',
  'burned_in_subtitles_language_id',
  'dubbed_language_id',
  'status',
  'sharable',
  'product.marketing_use',
  'layer.notes',
];
