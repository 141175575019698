import React from 'react';
import { observer } from 'mobx-react-lite';

import { IAsset, IMm3Asset } from 'types';

import {
  AssetSettingsSection,
  AssetIdentifiersSection,
  AssetAboutSection,
  IAssetFormFields,
} from 'components/asset/asset-edit-form/sections';
import { IUseFormReturn } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export interface IAssetPresetFieldsProps
  extends Pick<IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>, 'formData' | 'handlers'> {
  formId: string;
  asset: IAsset | IMm3Asset;
}

export const AssetPresetFields: React.FC<IAssetPresetFieldsProps> = ({ formId, formData, handlers, asset }) => {
  return (
    <>
      <AssetSettingsSection formData={formData} formId={formId} handlers={handlers} />

      <AssetAboutSection
        formId={formId}
        formData={formData}
        handlers={handlers}
        assetType={asset?.main_classification || ''}
      />

      <AssetIdentifiersSection formId={formId} formData={formData} handlers={handlers} />
    </>
  );
};

export default React.memo(observer(AssetPresetFields));
