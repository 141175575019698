import React from 'react';
import { Row, yn } from 'components/label-value-pair';
import { IOrganization } from 'types';

export const CustomRow: React.FC<{ organization: IOrganization }> = ({ organization }) => {
  const { is_producer, producer_refs } = organization;
  return (
    <>
      <Row label="Type" value={organization?.classification} size="m" />
      <Row label="Production Company (ITV Label)" value={yn(is_producer)} size="m" rawLabel />
      <Row label="Producer References" value={producer_refs} size="m" />
    </>
  );
};
