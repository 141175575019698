import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { FloppyDisk } from 'blueprint5-icons';
import { observer } from 'mobx-react-lite';

import { IAsset } from 'types';
import { useAssetsEditStore, useDialogStore } from 'store/hooks';
import { editAssetPresetInitialData as initialValues } from 'components/asset/asset-edit-form/constants';
import { MantineIcon } from 'utils/ui/icon';
import { SaveAssetPresetForm } from './save-preset-form';
import { IModalSize } from 'components/dialogs/types';

export interface IAssetPresetControlsProps {
  values: IAsset;
}

export const AssetPresetControls: React.FC<IAssetPresetControlsProps> = ({ values }) => {
  const { savePreset } = useAssetsEditStore();
  const { openModal } = useDialogStore();
  const openSavePresetDialog = useCallback(() => {
    savePreset({ ...initialValues, ...values } as IAsset);
    openModal({
      title: 'Save Preset',
      size: IModalSize.S,
      body: () => <SaveAssetPresetForm />,
    });
  }, [openModal, savePreset, values]);

  return (
    <div className="asset-upload-components__single-asset-form-preset-control my-2">
      <Button leftSection={<MantineIcon icon={<FloppyDisk />} />} variant="subtle" onClick={openSavePresetDialog}>
        Save As Preset
      </Button>
    </div>
  );
};

export default React.memo(observer(AssetPresetControls));
