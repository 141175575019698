import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayRemoveFromRelated as shouldDisplay } from './actions-acl';

import { removeRelatedProducts } from './api';

import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { pluralEntityInflect } from 'utils/general';

export const useRemoveFromRelatedAction = (
  items: IProduct[],
  options: IUseActionsOptionParam,
): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { productId } = useParams<{ productId: string }>();

  const executeRemove = useCallback(async () => {
    const { entity, entityWithCount } = pluralEntityInflect('related Product', items.length);
    try {
      if (!productId) {
        throw new Error('Could not read product Id from URL!');
      }
      const dissociated = items.map(({ id }) => id);
      const newProduct = await removeRelatedProducts(dissociated, productId);
      toastStore.success(`${entityWithCount} removed`);

      await options?.onSuccess?.(IProductActionName.REMOVE_FROM_RELATED, newProduct);
      return dialogStore.close();
    } catch (error) {
      toastStore.error(`Removing ${entity} failed: ${error.text || error}`);
      await options?.onFailure?.();
    }
  }, [productId, items, toastStore, options, dialogStore]);

  const handler = useCallback((): void => {
    const { entityWithDemonstrative } = pluralEntityInflect('Product', items.length);

    dialogStore.openConfirm({
      title: 'Confirm remove from Related',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from related products?`,
      onConfirm: executeRemove,
    });
  }, [dialogStore, executeRemove, items.length]);

  return {
    name: IProductActionName.REMOVE_FROM_RELATED,
    shouldDisplay,
    icon: 'remove',
    title: 'Remove from related',
    handler,
  };
};
