import { emptyValidation, isBlank } from './empty';

/* inclusion validation
 *
 * examples:
 * validations: [ { inclusion: { in: ASPECT_RATIOS, allow_blank: true } } ]
 */

export interface IInclusionValidationOpts {
  in?: string[];
  within?: string[];
  allow_blank?: boolean;
}

export const inclusionValidation = (opts: IInclusionValidationOpts, value = ''): [boolean, string?] => {
  const { allow_blank } = opts;
  const whitelist = opts.in || opts.within;

  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank }, value);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  if (allow_blank && isBlank(value)) {
    return [true];
  }

  if (whitelist && whitelist.indexOf('' + value) < 0) {
    return [false, 'This field is required'];
  }

  return [true];
};
