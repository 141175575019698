import React from 'react';
import cx from 'classnames';

import { AssetFilters } from 'utils/asset';

import { IAsset, IMm3Asset, IStyled } from 'types';
import { selectAssetHelper } from 'utils/asset';
import { formatDuration } from 'utils/date';
import { getEntityType, formatAssetType, formatResolution, parseDataSize } from 'utils/general';
import { getVideoResolution } from 'utils/ui';

interface IAssetAttributesProps extends IStyled {
  asset: IAsset | IMm3Asset;
}

const getAvailableOldAssetAttributes = (asset: IAsset): string => {
  const { classification, file_size, width, height, duration } = asset;
  const hasResolution = height && width;
  const assetType = getEntityType(classification);
  let attributes = formatAssetType(classification);
  if (assetType === AssetFilters.IMAGE && hasResolution) {
    attributes = attributes + ` | ${formatResolution({ width, height })}`;
  }

  if (assetType === AssetFilters.VIDEO && hasResolution) {
    attributes = attributes + ` | ${getVideoResolution({ width, height })}`;
  }

  if ((assetType === AssetFilters.VIDEO || assetType === AssetFilters.AUDIO) && duration) {
    attributes = attributes + ` | ${formatDuration(duration, 'seconds')}`;
  }
  if (file_size) {
    attributes = attributes + ` | ${parseDataSize(file_size)}`;
  }
  return attributes;
};

const getAvailableMm3AssetAttributes = (asset: IMm3Asset): string => {
  const { classification, file_size } = asset;
  const { width, height, duration } = asset.meta || {};
  const hasResolution = height && width;
  const assetType = getEntityType(classification) as AssetFilters;
  const attributes = [formatAssetType(classification)];
  if (AssetFilters.IMAGE === assetType && hasResolution) {
    attributes.push(formatResolution({ width, height }));
  }

  if (assetType === AssetFilters.VIDEO && hasResolution) {
    attributes.push(getVideoResolution({ width, height }));
  }

  if ((assetType === AssetFilters.VIDEO || assetType === AssetFilters.AUDIO) && duration) {
    attributes.push(formatDuration(duration, 'seconds'));
  }
  if (file_size) {
    attributes.push(parseDataSize(file_size));
  }
  return attributes.join(' | ');
};

const getAvailableAssetAttributes = selectAssetHelper(getAvailableMm3AssetAttributes, getAvailableOldAssetAttributes);

export const AssetAttributes: React.FC<IAssetAttributesProps> = ({ asset, className }) => {
  return (
    <div className={cx('text-truncate', className)}>{getAvailableAssetAttributes(asset as IAsset & IMm3Asset)}</div>
  );
};
