import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { IProduct, IProductLayer, ViewMode } from 'types';
import { useMm3Form } from 'helpers/form';
import { useStore } from 'store';

import { productInitialValues, productLayerInitialValues } from 'components/product-edit-sections';
import { Model } from 'helpers/filters/types';
import { useFormsStore } from 'store/hooks';
import { cleanUp, mergeObjects } from 'utils/payload';

import { ProductMetadataTabEditFormView } from 'pages/product-details/product-metadata-tab/product-metadata-tab-edit-form-view';
import { ToastError } from 'components/toast';
import { updateProduct, updateProductLayer } from 'utils/apis/product';
import { productDetailsSchema } from 'utils/schemas/product';

interface IProductMetadataTabEditProps {
  product: IProduct;
  setProduct: (product: IProduct) => void;
  setMode: (view: ViewMode) => void;
}

export const updateProductAndLayer = async (product: IProduct, layer: IProductLayer): Promise<IProduct> => {
  const productPromise = updateProduct(product, productDetailsSchema);
  const layerPromise = updateProductLayer(layer);
  const prd = await productPromise;
  const default_layer = await layerPromise;

  return { ...prd, default_layer };
};
export const ProductMetadataTabEdit: React.FC<IProductMetadataTabEditProps> = observer(
  ({ product, setProduct, setMode }) => {
    const { addForm } = useFormsStore();
    const { toastStore } = useStore();
    const { default_layer, ...productValues } = product;
    const layerForm = useMm3Form(
      mergeObjects(productLayerInitialValues, cleanUp(default_layer)),
      Model.PRODUCT_STANDARD_LAYER,
    );

    const handleSubmit = useCallback(
      async (values: IProduct): Promise<void> => {
        try {
          const prod = await updateProductAndLayer(values, layerForm.values);
          setProduct(prod);
          setMode(ViewMode.PREVIEW);
          toastStore.success('Product updated');
        } catch (error) {
          toastStore.error(<ToastError error={error} placeholder="Product update error" />);
        }
      },
      [layerForm.values, setMode, setProduct, toastStore],
    );
    const productForm = useMm3Form(
      mergeObjects(productInitialValues, cleanUp(productValues)),
      Model.PRODUCTS,
      handleSubmit,
    );

    addForm('layerForm', layerForm);
    addForm('productForm', productForm);

    return <ProductMetadataTabEditFormView layerForm={layerForm} productForm={productForm} />;
  },
);

export default ProductMetadataTabEdit;
