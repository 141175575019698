import { useCallback } from 'react';
import { get } from 'lodash';

import { IGroupNameEditFormFields, editGroupInfo } from 'utils/apis/groups';
import { useStore } from 'store';
import { Model } from 'helpers/filters/types';
import { IUseFormReturn, useForm } from 'helpers/form';
import { IMobileAppSync } from 'types';

export const useEditMobileSync = ({
  mobileAppSync,
  setEditModeEnabled,
  refreshMobileAppSync,
}: {
  mobileAppSync?: IMobileAppSync;
  setEditModeEnabled: (editMode: boolean, updateParam?: boolean) => void;
  refreshMobileAppSync: () => Promise<void>;
}): IUseFormReturn<IGroupNameEditFormFields> => {
  const { toastStore } = useStore();
  const { name, id: mobileSyncId } = mobileAppSync || {};
  const handleSubmit = useCallback(
    async (values: IGroupNameEditFormFields): Promise<void> => {
      try {
        if (mobileSyncId) {
          toastStore.info('Saving');
          await editGroupInfo(mobileSyncId, values);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          setEditModeEnabled(false);
          refreshMobileAppSync();
        }
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(`Error while saving: ${get(e, 'object.description', 'Unknown error')}`);
      }
    },
    [mobileSyncId, refreshMobileAppSync, setEditModeEnabled, toastStore],
  );

  const editForm = useForm<IGroupNameEditFormFields>({ name: name || '' }, Model.GROUPS, handleSubmit);
  return editForm;
};
