export const keysGeneralInfo = [
  'name',
  'access_level',
  'event_password',
  'purpose',
  'event_starts_at',
  'event_ends_at',
  'is_timed_event',
  'show_event_countdown',
  'group_preview_image_url',
  'group_parent_group_id',
];
