import { useCallback } from 'react';
import { map } from 'lodash';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { deleteConnection } from 'utils/apis/delivery-connections';
import { pluralEntityInflect } from 'utils/general';
import { IConnectionActionName } from 'utils/actions/connection/types';
import { Intent } from 'utils/ui';
export const useDeleteConnectionAction: IUseAction<DeliveriesGenericConnection, IConnectionActionName> = (
  items,
  options,
) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Connection', items.length);

  const executeDelete = useCallback(async () => {
    try {
      await deleteConnection(map(items, 'id'));
      toastStore.success(`${entityWithCount} deleted`);
      return await options?.onSuccess?.(IConnectionActionName.DELETE);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative} ?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IConnectionActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
