import { IBuyerDomain, IConference } from 'types';

export const parseConferenceUrl = (buyerDomain: IBuyerDomain | undefined, code: string | undefined): string => {
  if (!buyerDomain || !code) {
    return '';
  }
  return `${buyerDomain.protocol}://${buyerDomain.name}/video-conference/${code}`;
};

export const initialValues = {
  asset_ids: [],
  awaiting_approval_text: 'Your request has been sent to the moderator. Please wait for approval.',
  awaiting_moderator_text: 'Please wait for the meeting to be started by the moderator.',
  background_media: '',
  banner_media: '',
  disable_waitingroom: false,
  ends_at: '',
  farewell_alt_text: `
    <p>Thank you for participating in the meeting!</p>
    <p>We hope you've enjoyed the conference call. Please <a href="/registration">register here</a> to explore our content library.</p>
  `,
  farewell_text: `
    <p>Thank you for participating in the meeting!</p>
    <p>We hope you've enjoyed the conference call.</p>
  `,
  meeting_ended_alt_text: 'This meeting has finished',
  meeting_ended_text: 'This meeting has finished',
  mode: 'conference',
  starts_at: '',
  title: '',
  welcome_text: 'Welcome to this meeting.',
  owner_id: undefined,
} as Partial<IConference>;
