import React, { useCallback } from 'react';
import { isArray } from 'lodash';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { ToastError } from 'components/toast';

import { ILocationsActionName, ILocationsActionConfig } from './types';
import { Intent } from 'utils/ui';
import { IUseActionsOptionParam } from '..';

export const useDeleteAction = (
  location: CalGenericLocation | CalGenericLocation[],
  options: IUseActionsOptionParam<CalGenericLocation[]>,
): ILocationsActionConfig => {
  const {
    toastStore,
    dialogStore,
    locationsStore: { deleteLocation },
  } = useStore();
  const isArrayLocation = isArray(location);
  const sortLocationsId = isArrayLocation
    ? location.map((location: CalGenericLocation) => location?.id || '')
    : location?.id || '';

  const executeDelete = useCallback(async () => {
    try {
      sortLocationsId && (await deleteLocation(sortLocationsId));
      toastStore.success(
        `${
          isArrayLocation ? location.map((location: CalGenericLocation) => location.name).join(', ') : location.name
        } was deleted`,
      );
      options.onSuccess?.(ILocationsActionName.DELETE);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      options.onFailure?.();
    }
  }, [deleteLocation, sortLocationsId, toastStore, isArrayLocation, location, options]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: isArrayLocation
        ? `Are you sure you want to delete this Locations?`
        : `Are you sure you want to delete this Location?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, executeDelete, isArrayLocation]);

  return {
    name: ILocationsActionName.DELETE,
    shouldDisplay: () => true,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
