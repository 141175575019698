import React from 'react';

import { FormAssets, IUseFormReturn } from 'helpers/form';
import { IConference, ISearchFilter } from 'types';

interface IFormAddToGroupItemsForm {
  conferenceForm: IUseFormReturn<Partial<IConference>>;
}
const filters: ISearchFilter[] = [['status', 'eq', 'available']];

export const ConferenceAssetsSection: React.FC<IFormAddToGroupItemsForm> = ({ conferenceForm }) => {
  const { formData, handlers } = conferenceForm;

  return (
    <FormAssets
      className="conference-assets-section__form-assets"
      fitInParent
      name="asset_ids"
      label="Assets"
      {...formData.asset_ids}
      {...handlers}
      filters={filters}
    />
  );
};
