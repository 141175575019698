import React, { useCallback, useMemo, useState } from 'react';
import { camelCase } from 'lodash';
import { observer } from 'mobx-react-lite';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { getMaxDate, getMinDate, today } from 'utils/date';
import { useAnalytics } from 'utils/hooks';

import { AnalyticsInteractionsAttendance as Attendance } from 'components/analytics-interactions-attendance';
import { AnalyticsInteractionsProductViews as ProductViews } from 'components/analytics-interactions-product-views';
import { AnalyticsInteractionsVideoScreenings as VideoScreenings } from 'components/analytics-interactions-video-screenings';
import { AnalyticsSectionFilters } from 'components/analytics';

import { DetailsPageTabs, IMarketingEvent } from 'types';
import { IAnalyticsEntityValues, IAnalyticsStateProps } from 'types/analytics';
import { EventInteractions, eventInteractionOptions } from 'components/analytics/utils';

import * as eventQueries from 'components/analytics/queries/event-queries';

const EventAnalyticsTab: React.FC<{ event: IMarketingEvent }> = observer(({ event }) => {
  const [interaction, setInteraction] = useState<EventInteractions>(EventInteractions.ATTENDANCE);
  const eventStartDate = useMemo(() => (event.event_starts_at ? new Date(event.event_starts_at) : null), [event]);
  const eventEndDate = useMemo(() => (event.event_ends_at ? new Date(event.event_ends_at) : null), [event]);
  const eventCreationDate = useMemo(() => startOfDay(new Date(event.created_at as string)), [event]);
  const sixMonthsAgo = useMemo(() => startOfDay(subMonths(today, 6)), []);
  const { state }: { state: IAnalyticsStateProps } = useLocation();
  const onInteractionChange = useCallback(({ interaction }: { interaction: EventInteractions }) => {
    setInteraction(interaction || EventInteractions.ATTENDANCE);
  }, []);

  const minDate = useMemo(
    () => getMinDate([eventStartDate, eventCreationDate, sixMonthsAgo]),
    [eventStartDate, sixMonthsAgo, eventCreationDate],
  );
  const initialStartDate = useMemo(
    () => eventStartDate || getMaxDate([eventCreationDate, sixMonthsAgo]),
    [eventCreationDate, eventStartDate, sixMonthsAgo],
  );

  const maxDate = useMemo(() => getMaxDate([eventEndDate, endOfDay(today)]), [eventEndDate]);
  const initialEndDate = useMemo(() => eventEndDate || endOfDay(today), [eventEndDate]);
  const getEventMinDate = useCallback(async () => minDate, [minDate]);
  const initialDateRange = useMemo(
    () => [initialStartDate, initialEndDate] as [Date, Date],
    [initialStartDate, initialEndDate],
  );
  const { dateRange, onDateRangeChange, resetFilters } = useAnalytics({
    getMinDate: getEventMinDate,
    initialDateRange,
    entity: IAnalyticsEntityValues.EVENT,
    tab: DetailsPageTabs.ANALYTICS,
    state,
    interaction,
  });

  const handleDateRangeChange = useCallback(
    ({ dateRange }) => {
      const newDateRange = [...dateRange] as [Date, Date];
      if (!dateRange[0] || dateRange[0] < minDate) {
        newDateRange[0] = initialStartDate;
      }
      if (!dateRange[1] || dateRange[1] > maxDate) {
        newDateRange[1] = initialEndDate;
      }
      if (newDateRange[0] > newDateRange[1]) {
        const swap = newDateRange[0];
        newDateRange[0] = newDateRange[1];
        newDateRange[1] = swap;
      }
      onDateRangeChange({ dateRange: newDateRange });
    },
    [initialEndDate, initialStartDate, maxDate, minDate, onDateRangeChange],
  );

  const ContentComponent = useMemo(() => {
    switch (interaction) {
      case EventInteractions.PRODUCT_VIEWS:
        return ProductViews;
      case EventInteractions.VIDEO_SCREENINGS:
        return VideoScreenings;
      default:
        return Attendance;
    }
  }, [interaction]);

  const id = event.id || 0;
  const eventIds = event.subevent ? id : [id, ...(event.group_ids || [])].filter(Boolean);

  return (
    <div className="analytics-tab">
      <AnalyticsSectionFilters
        interaction={interaction}
        onInteractionChange={onInteractionChange}
        interactionOptions={eventInteractionOptions}
        dateRange={dateRange}
        minDate={minDate}
        handleDateRangeChange={handleDateRangeChange}
        resetFilters={resetFilters}
        maxDate={maxDate}
      />
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <ContentComponent
            entity="event"
            queries={eventQueries[camelCase(interaction)]}
            dateRange={dateRange as [Date, Date]}
            entityId={eventIds}
          />
        </div>
      </div>
    </div>
  );
});

export default EventAnalyticsTab;
