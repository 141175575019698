import React, { useCallback } from 'react';
import { omit } from 'lodash';

import { ToastError } from 'components/toast';
import { useStore } from 'store';
import { IMarketingEvent } from 'types';
import { IModalSize } from 'components/dialogs/types';

import { IMarketingEventEditForm } from './steps';
import { saveGroup } from 'utils/apis/groups';
import { EventCreate } from './event-create';
import { useNavigate } from 'react-router';
import './style.scss';

interface IUseEventSaveParams {
  onSuccess?: (event?: IMarketingEvent) => void;
  onFailure?: () => void;
}

type IUseEventSave = (params?: IUseEventSaveParams) => (values: Partial<IMarketingEventEditForm>) => void;

export const useEventSave: IUseEventSave = (params) => {
  const { onSuccess, onFailure } = params || {};
  const { dialogStore, toastStore } = useStore();
  const navigate = useNavigate();

  const handleConfirm = useCallback(
    async (values: Partial<IMarketingEventEditForm>): Promise<void> => {
      try {
        toastStore.info('Saving');
        const event = {
          ...omit(values, ['eventTiming', 'eventType']),
          is_timed_event: values.eventTiming === 'timed',
          subevent: values.eventType === 'subevent',
        };
        const result = await saveGroup<IMarketingEvent>('event', event);

        toastStore.success('Success');

        if (onSuccess) {
          onSuccess(result?.object);
        } else {
          dialogStore.close();
          navigate(`/events/${result.object.id}`);
        }
      } catch (error) {
        if (error.text?.match(/purpose/i)) {
          error.text = error.text.replace(/purpose/i, 'Event identifier');
        }
        toastStore.error(<ToastError error={error} />);
        onFailure?.();
      }
    },
    [toastStore, dialogStore, navigate, onSuccess, onFailure],
  );

  return handleConfirm;
};

const useEventCreate = (): [() => void] => {
  const { dialogStore } = useStore();

  const submitCallback = useEventSave();

  const body = useCallback((): JSX.Element => {
    return <EventCreate saveEvent={submitCallback} />;
  }, [submitCallback]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Create Event',
      body,
      size: IModalSize.XL,
      className: 'create-event-dialog',
    });
  };

  return [openDialog];
};

export { useEventCreate };
