import React from 'react';
import { Menu } from '@mantine/core';

import { IMarketingEvent, ItemId } from 'types';

import { byId } from 'utils/general';
import { updateGroup, fetchGroups } from 'utils/apis/groups';
import { EventSchema } from 'utils/schemas';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { ICustomSortMenuProp } from 'components/data-section';

import cx from 'classnames';

export const fetchSubEvents = async (subevent_group_ids?: ItemId[]): Promise<IMarketingEvent[]> => {
  if (!subevent_group_ids?.length) {
    return [];
  }

  const groups = await fetchGroups<IMarketingEvent>(
    [
      ['id', 'in', subevent_group_ids],
      ['subevent', 'eq', true],
    ],
    EventSchema,
  );

  const groupsById = byId(groups);
  return subevent_group_ids.map((id) => groupsById[id]);
};

export const saveOrder = (id: ItemId, subevent_group_ids: ItemId[]): Promise<IMarketingEvent | undefined> => {
  return updateGroup<IMarketingEvent>({ id, subevent_group_ids }, 'id, subevent_group_ids');
};

export const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

export const customSortMenu: ICustomSortMenuProp = (sort, handleSort) => {
  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.NAME}
        className={cx({ active: sort === ParametronSort.NAME })}
      >
        Name
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
    </Menu>
  );
};
