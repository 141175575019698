import React, { useCallback } from 'react';
import ReactPaginate from 'react-paginate';

import './style.scss';

const CardPagination: React.FC<{
  page: number;
  onPageChange: (page: number) => void;
  totalCount: number;
  totalPages: number;
  pageRangeDisplayed?: number;
}> = ({ page, onPageChange, totalCount, totalPages, pageRangeDisplayed }) => {
  const handlePageClick = useCallback(
    ({ selected }): void => {
      onPageChange(selected + 1);
    },
    [onPageChange],
  );

  const showLastPages = totalCount < 9999;

  return (
    <ul className="data-section__pagination-container">
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'data-section__pagination__dots mx-1'}
        pageCount={totalPages}
        marginPagesDisplayed={showLastPages ? 2 : 0}
        pageRangeDisplayed={pageRangeDisplayed || 8}
        forcePage={page - 1}
        onPageChange={handlePageClick}
        containerClassName={'data-section__pagination'}
        activeLinkClassName={'data-section__page-btn--active'}
        pageLinkClassName={`data-section__page-btn`}
        previousLinkClassName={`data-section__page-btn`}
        nextLinkClassName={`data-section__page-btn`}
      />
    </ul>
  );
};

export default CardPagination;
