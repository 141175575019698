import React, { useCallback } from 'react';
import { toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';

import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { CustomRow } from 'components/mobile-app-sync-preview/custom-row';

import { Routes } from 'utils/routes';
import { IMobileAppSync, DetailsPageTabs } from 'types';

import { changeAccessLevelSlider } from 'utils/change-access';
import { formatDate } from 'utils/date';
import { formatFullName, getStatus, parseDataSize } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { groupListSchema } from 'utils/schemas';
import { fetchProductThumbnailImages, DetailCountLink } from 'utils/ui';
import { changeGroupAccessImmediate } from 'utils/apis/groups';

const MobileAppSyncPreview: React.FC = observer(() => {
  const { activeItem: mobileAppSync } = useDataSectionStore<IMobileAppSync>();
  const {
    name,
    access_level,
    id,
    owner,
    owner_organization,
    pm_statistics_data,
    am_statistics_data,
    created_at,
    updated_at,
    deleted_at,
  } = mobileAppSync || {};
  const fetcher = useCallback(() => fetchProductThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);

  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeGroupAccessImmediate(data, groupListSchema),
        divisionIds: owner?.division_ids,
        newAccessLevel,
      });
    },
    [owner],
  );
  if (!mobileAppSync) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const mobile_sync_size = toNumber(am_statistics_data?.mobile_sync_size);
  const ownerField = owner_organization?.name
    ? `${formatFullName(owner, false)}, ${owner_organization?.name}`
    : `${formatFullName(owner, false)}`;
  const detailsPageLink = `${Routes.MOBILE_APP_SYNC}/${id}`;
  return (
    <PreviewTab
      title={name}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : detailsPageLink}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={<MemoizedMultiThumbnail height={172} width={308} thumbnailImages={thumbnailImages || [{}]} />}
    >
      <PreviewInfo>
        <CustomRow mobileAppSync={mobileAppSync} />
        <Row label="Sync size" value={parseDataSize(mobile_sync_size)} />
        <DetailCountLink
          entityCount={pm_statistics_data?.count}
          link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
          label={DetailsPageTabs.PRODUCTS}
        />
        <DetailCountLink
          entityCount={am_statistics_data?.count}
          link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
          label={DetailsPageTabs.ASSETS}
        />
        <Row label="Owner" value={ownerField} />
        <Row label="Status" value={getStatus(deleted_at)} />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </PreviewInfo>
    </PreviewTab>
  );
});
export default MobileAppSyncPreview;
