import React from 'react';
import cx from 'classnames';

import useSelectedOption from 'utils/hooks/selected-option';
import { formatDate } from 'utils/date';
import { formatGroupName } from 'utils/group';
import { IGroup, IStyled } from 'types';
import { Thumbnail } from 'components/thumbnail';
import { UserSelectionAvatar } from 'components/multi-component';
import { Classes } from 'utils/ui';

interface IGroupSearchItem extends IStyled {
  group: IGroup;
  handleSelect?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const customTypeParser = (type?: string): string => {
  if (type === 'group/access_privilege') return 'access p.';

  return formatGroupName(type);
};

const GroupSearchItem: React.FC<IGroupSearchItem> = ({ group, handleSelect }) => {
  const selectedOption = useSelectedOption({ value: group?.access_level });

  return (
    <GroupSelectedItem
      group={group}
      handleSelect={handleSelect}
      className={cx({ [`access-level-${selectedOption?.color}`]: Boolean(selectedOption) })}
    />
  );
};

export const GroupSelectedItem: React.FC<IGroupSearchItem> = ({ className, group, handleSelect }) => {
  if (!group) {
    return <></>;
  }
  const { name, '@type': type, id, updated_at, main_entity_type } = group;
  const isUserSelection = main_entity_type === 'users';

  return (
    <div className={cx('entity-search-list-item', { className })} onClick={handleSelect}>
      <div
        className={cx(Classes.MENU_ITEM, {
          searchbox__group__selection: isUserSelection,
          searchbox__group: !isUserSelection,
        })}
        data-id={id}
        data-item-type={type}
        data-item-name={name}
        data-item-entity={main_entity_type}
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          {isUserSelection ? (
            <UserSelectionAvatar selection={group} height={40} width={40} offsetBetweenElements={3} />
          ) : (
            <Thumbnail image={group.preview_image?.url} showType customTag={customTypeParser(type)} />
          )}
        </div>
        <div>
          <div className="text-truncate">{name}</div>
          <div className="searchbox__info-text">{formatDate(updated_at)}</div>
        </div>
      </div>
    </div>
  );
};

export { GroupSearchItem };
