import { AmGenericNews } from '@mediafellows/mm3-types';
import { IActionConfig } from 'utils/actions/types';

export enum INewsActionName {
  DELETE = 'delete',
  EDIT = 'edit',
  UPLOAD_PREVIEW = 'upload-preview',
  DELETE_PREVIEW = 'delete-preview',
  MANAGE_SHOWCASE = 'manage-showcase',
}

export type INewsActionConfig = IActionConfig<AmGenericNews, INewsActionName>;
