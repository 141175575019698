import React from 'react';

import { useSubtitleActions } from 'utils/actions/subtitle';

import { ISubtitle } from 'types';
import { renderMenuActionItems } from 'utils/ui';

import { IItemActionsMenuProps } from './utils';

export const SubtitleActionsList: React.FC<IItemActionsMenuProps<ISubtitle>> = (props) => {
  const { items, options } = props;

  const actions = useSubtitleActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
