import React from 'react';

import { FormInput, IFormData, IFormHandlers } from 'helpers/form';

export interface IFormMultiInputField {
  key: string;
  label: string;
}

export interface IFormMultiInputProps<T> {
  form: { data: IFormData<T>; handlers: IFormHandlers<T> };
  large?: boolean;
  name?: string;
  placeholders?: string[];
  fields?: IFormMultiInputField[];
}

export function FormMultiInput<T>({
  form,
  large = false,
  name,
  placeholders = [],
  fields = [
    { key: 'label', label: 'Label' },
    { key: 'value', label: 'Value' },
  ],
}: IFormMultiInputProps<T>): JSX.Element {
  return (
    <>
      {fields.map((field, index) => (
        <FormInput
          key={`${field.key}_${index}`}
          name={`${name}.${field.key}`}
          label={field.label}
          type="text"
          placeholder={placeholders[index]}
          large={large}
          {...form.data[`${name}.${field.key}`]}
          {...form.handlers}
        />
      ))}
    </>
  );
}
