import React, { useState } from 'react';
import { FormInput, FormNumericInput, FormSelect } from 'helpers/form';
import { IConnectionForm } from 'components/delivery/connections/types';
import { ActionIcon, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui';
import { connectionRegions } from 'utils/actions/types';

export const CustomFormFields: React.FC<IConnectionForm> = ({ form }) => {
  const [fieldStates, setFieldStates] = useState<Record<string, boolean>>({});

  const handleFieldVisibilityChange = (name: string, visible: boolean): void => {
    setFieldStates((prevState) => ({
      ...prevState,
      [name]: visible,
    }));
  };

  const isFieldVisible = (fieldId: string): boolean => !!fieldStates[fieldId];

  // TODO extract into its own component
  const lockButton = (name: string): JSX.Element => {
    return (
      <Tooltip label={`${isFieldVisible(name) ? 'Hide' : 'Show'} Field`}>
        <ActionIcon name={name} variant="subtle" onClick={() => handleFieldVisibilityChange(name, !fieldStates[name])}>
          <MantineIcon icon={isFieldVisible(name) ? 'eye-off' : 'eye-open'} />
        </ActionIcon>
      </Tooltip>
    );
  };

  switch (form.values.type) {
    case 'Connection::AzureBlobStorage':
    case 'Connection::GoogleCloudStorage':
    case 'Connection::Aspera':
    case 'Connection::Sftp': {
      return (
        <>
          <FormInput
            rightElement={lockButton('username')}
            type={isFieldVisible('username') ? 'text' : 'password'}
            name="username"
            label="Username"
            {...form.formData.username}
            {...form.handlers}
            autocomplete="one-time-code"
            size="xs"
          />
          <FormInput
            rightElement={lockButton('password')}
            type={isFieldVisible('password') ? 'text' : 'password'}
            name="password"
            label="Password"
            size="xs"
            {...form.formData.password}
            {...form.handlers}
            autocomplete="one-time-code"
          />
          <FormInput name="host" label="Host" size="xs" {...form.formData.host} {...form.handlers} />
          <FormNumericInput
            name="port"
            label="Port"
            {...form.formData.port}
            {...form.handlers}
            size="xs"
            className="delivery-connection__port"
          />
        </>
      );
    }
    default: {
      return (
        <>
          <FormInput name="bucket" label="Bucket" size="xs" {...form.formData.bucket} {...form.handlers} />
          <FormSelect
            name="region"
            label="Region"
            size="xs"
            {...form.formData.region}
            {...form.handlers}
            options={connectionRegions}
          />
          <FormInput
            name="aws_access_key_id"
            label="Access Key Id"
            rightElement={lockButton('aws_access_key_id')}
            type={isFieldVisible('aws_access_key_id') ? 'text' : 'password'}
            size="xs"
            {...form.formData.aws_access_key_id}
            {...form.handlers}
            autocomplete="one-time-code"
          />
          <FormInput
            name="aws_secret_access_key"
            label="Secret Acces Key"
            rightElement={lockButton('aws_secret_access_key')}
            type={isFieldVisible('aws_secret_access_key') ? 'text' : 'password'}
            size="xs"
            {...form.formData.aws_access_key_id}
            {...form.formData.aws_secret_access_key}
            {...form.handlers}
            autocomplete="one-time-code"
          />
        </>
      );
    }
  }
};
