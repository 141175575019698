import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { handleItemClick } from 'components/data-section';
import { ISelectionStandardized } from 'types';
import { ISelectionDataSectionWrappedComponent } from './types';
import { SelectionDataSectionListItem } from './selection-data-section-list-item';

import './style.scss';

export interface ISelectionsListItemProps {
  className?: string;
  selection: ISelectionStandardized;
  contextMenu: React.ReactElement;
  component?: React.FC<ISelectionDataSectionWrappedComponent>;
}

const SelectionListItem: React.FC<ISelectionsListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();

  const { className, selection, contextMenu, component: Component = SelectionDataSectionListItem } = props;
  const { id: selectionId } = selection;

  const isChecked = checked.some((checked) => checked.id === selectionId);
  const isActive = active === selectionId;

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, selectionId);
    },
    [selectionId],
  );
  return (
    <div
      className={cx('default-selections-list-item', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <Component
        selection={selection}
        contextMenu={contextMenu}
        isChecked={isChecked || isAllItemsSelected}
        isAllItemsSelected={isAllItemsSelected}
        handleClick={handleClick}
      />
    </div>
  );
});

export default SelectionListItem;
