import React from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormImage, FormInput, FormHtmlInput, IUseFormReturn } from 'helpers/form';
import { IConference } from 'types';

interface IConferenceAttachmentsProps {
  conferenceForm: IUseFormReturn<Partial<IConference>>;
  className?: string;
  title?: string;
}

export const ConferenceAttachmentsSection: React.FC<IConferenceAttachmentsProps> = ({
  conferenceForm,
  title,
  className,
}) => {
  const { formData, handlers } = conferenceForm;

  return (
    <FormSectionWrapperV2 wrapperClassName={className} title={title} padded={false}>
      <FormImage
        label="Preview / Background media"
        name="background_media"
        large
        {...handlers}
        {...formData.background_media}
        type="attachment"
      />
      <FormImage
        label="Banner media"
        name="banner_media"
        large
        {...handlers}
        {...formData.banner_media}
        type="attachment"
      />

      <FormInput
        className="form-input-textarea"
        label="Awaiting approval text"
        name="awaiting_approval_text"
        textarea
        {...handlers}
        {...formData.awaiting_approval_text}
      />
      <FormInput
        className="form-input-textarea"
        label="Awaiting moderator text"
        name="awaiting_moderator_text"
        textarea
        {...handlers}
        {...formData.awaiting_moderator_text}
      />
      <FormHtmlInput label="Welcome text" name="welcome_text" large {...handlers} {...formData.welcome_text} />
      <FormHtmlInput label="Farewell text" name="farewell_text" large {...handlers} {...formData.farewell_text} />
      <FormHtmlInput
        label="Farewell text (public users)"
        name="farewell_alt_text"
        large
        {...handlers}
        {...formData.farewell_alt_text}
      />
      <FormHtmlInput
        label="Meeting ended text"
        name="meeting_ended_text"
        large
        {...handlers}
        {...formData.meeting_ended_text}
      />
      <FormHtmlInput
        label="Meeting ended text (public users)"
        name="meeting_ended_alt_text"
        large
        {...handlers}
        {...formData.meeting_ended_alt_text}
      />
    </FormSectionWrapperV2>
  );
};
