import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import pick from 'lodash/pick';
import { UppyFile } from '@uppy/core';
import { Button } from '@mantine/core';

import { SectionHeader } from 'components/section-header';
import { IAssetPresetFields } from 'types';
import { FormSelect, useForm } from 'helpers/form';
import { IFormHandlers, IFormData } from 'helpers/form/types';
import { formatFileFields } from 'utils/assets-upload';

import PresetForm from 'components/asset/asset-upload-components/preset-form/preset-form';
import { Model } from 'helpers/filters/types';
import { presetKeys } from 'components/asset/asset-upload-components/constants';

import './index.scss';
import { useDialogStore } from 'store/hooks';

export interface ISelectAssetPresetProps {
  shouldShowErrors: boolean;
  applyPreset: (preset: IAssetPresetFields) => void;
  presets: Record<string, IAssetPresetFields>;
  withParentField?: boolean;
}

type IPresetOption = {
  presetName: string;
};

const initializeValues = pick(formatFileFields({} as UppyFile), presetKeys);

interface IAssetPresetFormProps {
  formData: IFormData<IAssetPresetFields>;
  handlers: IFormHandlers<IAssetPresetFields>;
  values: IAssetPresetFields;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

export const AssetPresetForm: React.FC<IAssetPresetFormProps> = ({
  formData,
  handlers,
  shouldShowErrors,
  values,
  withParentField,
}) => {
  return (
    <PresetForm formId="select-preset-form" {...{ formData, handlers, values, shouldShowErrors, withParentField }} />
  );
};

interface IPresetSelectorProps extends IPresetOption {
  onPresetChange: (option: IPresetOption) => void;
  presetOptions: string[];
}

const PresetSelector: React.FC<IPresetSelectorProps> = ({ presetName, onPresetChange, presetOptions }) => (
  <div className="asset-upload-components__select-preset-modal-header">
    <FormSelect
      label=""
      validation={{ valid: true }}
      touched={false}
      required={false}
      name="presetName"
      placeholder="Select Preset"
      value={presetName}
      onChange={onPresetChange}
      options={presetOptions}
    />
  </div>
);
export const SelectAssetPreset: React.FC<ISelectAssetPresetProps> = observer((props) => {
  const { shouldShowErrors, applyPreset, presets, withParentField } = props;
  const [presetName, setPresetName] = useState<string>('');
  const { formData, handlers, values } = useForm<IAssetPresetFields>(initializeValues, Model.ASSET_DIGITAL_IMAGE);
  const { close } = useDialogStore();

  const onPresetChange = useCallback(
    ({ presetName }: IPresetOption) => {
      setPresetName(presetName);
      const preset = presetName ? presets[presetName] : initializeValues;
      handlers.onSetFields(preset);
    },
    [handlers, presets],
  );
  const handleApplyPreset = useCallback(() => {
    applyPreset(values);
    close();
  }, [applyPreset, close, values]);

  const presetOptions = Object.keys(presets);
  return (
    <>
      <SectionHeader
        customRightRenderer={() => <PresetSelector {...{ presetOptions, presetName, onPresetChange }} />}
      />
      <div className="asset-upload-components__select-preset-modal-container px-3">
        <AssetPresetForm {...{ formData, handlers, values, shouldShowErrors, withParentField }} />
      </div>
      <div className="asset-upload-components__select-preset-modal-footer  px-3">
        <Button onClick={handleApplyPreset} size="xs" variant="primary">
          Apply
        </Button>
        <Button onClick={close} variant="default" size="xs" className="me-4">
          Cancel
        </Button>
      </div>
    </>
  );
});

export default SelectAssetPreset;
