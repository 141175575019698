import React from 'react';

import ContactDataSection from 'components/contact-data-section';
import { EntityExportButton } from 'components/entity-export';
import { DataSectionPage } from 'components/layout';
import { Pages } from 'utils/actions';
import { exportContacts } from 'utils/apis/contacts';

const Contacts: React.FC = () => (
  <DataSectionPage>
    <ContactDataSection
      persistFilters
      page={Pages.LIST}
      headerRightSection={<EntityExportButton entity="contacts" apiCall={exportContacts} />}
    />
  </DataSectionPage>
);

export default Contacts;
