import React, { useCallback, useState, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { IAsset, IMm3Asset, IProductAsset } from 'types';
import { useStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { IAssetDataSectionWrappedComponent } from './types';
import { Pages } from 'utils/actions/types';

import './style.scss';

interface IAssetDataSectionItemWrapperProps {
  className?: string;
  asset: IAsset | IMm3Asset;
  contextMenu: React.ReactElement;
  component: React.FC<IAssetDataSectionWrappedComponent>;
  page?: Pages;
  disableMarketingStar?: boolean;
  setProductAssets?: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
}

export const AssetDataSectionItemWrapper: React.FC<IAssetDataSectionItemWrapperProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();
  const [itemChecked, setChecked] = useState(false);
  const { asset, contextMenu, component: Component, page, disableMarketingStar, setProductAssets } = props;
  const { id } = asset;

  const isActive = active === asset.id;

  useEffect(() => {
    const isChecked = checked.some((checked) => checked.id === id);

    setChecked(isChecked);
  }, [id, checked]);

  const handleClick = useCallback(
    (e): void => {
      if (!id) {
        return;
      }
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <Component
      asset={asset}
      page={page}
      isChecked={itemChecked || isAllItemsSelected}
      isAllItemsSelected={isAllItemsSelected}
      contextMenu={contextMenu}
      handleClick={handleClick}
      isActive={isActive}
      disableMarketingStar={disableMarketingStar}
      setProductAssets={setProductAssets}
    />
  );
});
