import React from 'react';
import { isUndefined, uniqBy } from 'lodash';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { getRootStore, useProfileStore } from 'store';
import { Globals } from 'types';
import { toggleProfileSettings } from 'utils/toggle-profile-settings';

export const handleListItemCheck = (e, id: number | string): void => {
  e.stopPropagation();
  e.preventDefault();

  const {
    dataSectionStore: { searchStore, checked, lastChecked, updateStore },
  } = getRootStore();
  const items = searchStore?.objects;
  let newChecked = [...checked];

  if (checked.some((contact) => contact.id === id)) {
    newChecked = newChecked.filter((item) => item.id !== id);
  } else {
    newChecked.push(items.find((contact) => contact.id === id));
  }

  if (!lastChecked) {
    updateStore({ lastChecked: id, activeTab: DataSectionSidebarTabs.SELECTED_ITEMS, checked: newChecked });
    return;
  }

  if (e.nativeEvent.shiftKey) {
    const start = items.findIndex((contact) => contact.id === id);
    const end = items.findIndex((contact) => contact.id === lastChecked);
    const range = items.slice(Math.min(start, end), Math.max(start, end) + 1);

    newChecked = uniqBy([...newChecked, ...range], 'id');
  }

  updateStore({ lastChecked: id, activeTab: DataSectionSidebarTabs.SELECTED_ITEMS, checked: newChecked });
};

export const handleItemClick = (e: React.MouseEvent<HTMLInputElement>, id: number | string): void => {
  e.preventDefault();
  e.stopPropagation();

  const {
    dataSectionStore: { updateStore, isAllItemsSelected },
  } = getRootStore();

  const isDataSectionSidebarOpen = useProfileStore.getState().settings.isDataSectionSidebarOpen;
  const target = e.currentTarget;

  if (target.classList.contains('js_action-btn') || target.closest('.js_action-btn')) {
    return;
  }

  // detect checkbox
  if (!isUndefined(target.checked)) {
    if (isAllItemsSelected) {
      return;
    }

    handleListItemCheck(e, id);
    return;
  }

  updateStore({ active: id, activeTab: DataSectionSidebarTabs.PREVIEW });

  if (isDataSectionSidebarOpen) return;

  toggleProfileSettings(
    'isDataSectionSidebarOpen',
    Globals.DATASECTION_SIDEBAR_COLLAPSED_LS,
    Globals.DATASECTION_SIDEBAR_COLLAPSED_CLASS,
  );
};
