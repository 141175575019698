import { useCallback } from 'react';
import { map } from 'lodash';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { useStore } from 'store';
import { IUseAction } from 'utils/actions';
import { deleteRecommendation } from 'utils/apis/recommendation';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { IRecommendationActionName } from './types';

export const useDeleteAction: IUseAction<
  McGenericRecommendationProduct | McGenericRecommendationAsset,
  IRecommendationActionName
> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Recommendation', items.length);
  const executeDelete = useCallback(async () => {
    try {
      await deleteRecommendation(map(items, 'id'));
      toastStore.success(`${entityWithCount} deleted`);
      return await options?.onSuccess?.(IRecommendationActionName.DELETE);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);
  return {
    name: IRecommendationActionName.DELETE,
    shouldDisplay: () => false,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
