import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';

import { getConference, updateConference } from 'utils/apis/conference';
import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IContact } from 'types';

import { shouldDisplayRemoveFromConference } from './actions-acl';
import { IContactActionName } from './types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export const useRemoveFromConferenceAction = (
  users: IContact[],
  options: IUseActionsOptionParam,
): IActionConfig<IContact, IContactActionName> => {
  const { toastStore, dialogStore } = useStore();
  const { code } = useParams<{ code: string }>();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Contact', users.length);

  const onConfirm = useCallback(async () => {
    try {
      const userIds = map(users, 'id');
      if (!code || !userIds.length) {
        return;
      }

      const conference = await getConference(code);
      const { user_ids } = conference || {};
      const updatedConference = await updateConference({
        code,
        user_ids: user_ids?.filter((id) => !userIds.includes(id as string)),
      });
      toastStore.success(`${entityWithCount} will be removed from this Conference`);

      await options?.onSuccess?.(IContactActionName.REMOVE_FROM_CONFERENCE, updatedConference);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [users, code, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from this Conference?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: IContactActionName.REMOVE_FROM_CONFERENCE,
    shouldDisplay: shouldDisplayRemoveFromConference,
    icon: 'remove',
    title: 'Remove from Conference',
    intent: Intent.DANGER,
    handler,
  };
};
