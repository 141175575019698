import { IJsonLDSpec, IJsonSchemaSpec } from '@mediafellows/chipmunk';

import { IFormFieldsSettings } from 'helpers/form/types';
import { IDeepPartial } from 'types';

export enum FormStateActionType {
  UpdateContext = 'update_context',
  UpdateSchema = 'update_schema',
  UpdateCustomContext = 'update_custom_context',
  UpdateValues = 'update_values',
  UpdateTouched = 'update_touched',
  SetFields = 'set_fields',
  ResetFields = 'reset_fields',
}

export interface IFormStateAction {
  readonly type: FormStateActionType;
}

export class UpdateContextAction implements IFormStateAction {
  readonly type = FormStateActionType.UpdateContext;
  constructor(public context: IJsonLDSpec) {}
}

export class UpdateSchemaAction implements IFormStateAction {
  readonly type = FormStateActionType.UpdateSchema;
  constructor(public schema: IJsonSchemaSpec) {}
}

export class UpdateCustomContextAction implements IFormStateAction {
  readonly type = FormStateActionType.UpdateCustomContext;
  constructor(public context?: IDeepPartial<IJsonLDSpec>) {}
}

export class UpdateFormValuesAction<T> implements IFormStateAction {
  readonly type = FormStateActionType.UpdateValues;
  constructor(public values: Partial<T>) {}
}

export class SetFormFieldsAction<T> implements IFormStateAction {
  readonly type = FormStateActionType.SetFields;
  constructor(public values: T) {}
}

export class ResetFormFieldsAction<T> implements IFormStateAction {
  readonly type = FormStateActionType.ResetFields;
  constructor(public values: T) {}
}

export class UpdateFormTouchedAction<T> implements IFormStateAction {
  readonly type = FormStateActionType.UpdateTouched;
  constructor(public fieldsTouched: Partial<IFormFieldsSettings<T, boolean>>) {}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type UpdateFormActions<T> =
  | UpdateContextAction
  | UpdateSchemaAction
  | UpdateCustomContextAction
  | SetFormFieldsAction<T>
  | ResetFormFieldsAction<T>
  | UpdateFormValuesAction<T>
  | UpdateFormTouchedAction<T>;
