import React, { useState, useCallback, useEffect } from 'react';

import { FormDynamicFields } from 'components/form-dynamic-fields';

import { FormAddress, IUseFormReturn } from 'helpers/form';
import { IOrganizationRegistrationFields } from 'pages/registration/step-organization';
import { IContact } from 'types';
import { IContactFields } from 'pages/upsert-contact';

interface IContactAddressSection extends IUseFormReturn<IContact | IOrganizationRegistrationFields | IContactFields> {
  component?: ({}) => JSX.Element;
  preset?: string;
  separator?: string;
  countryKey?: string;
}

const ContactAddressSection = (props: IContactAddressSection): JSX.Element => {
  const {
    formData,
    handlers,
    values,
    component = FormAddress,
    preset = 'address',
    separator = '.',
    countryKey,
  } = props;
  const fieldKey = `${preset}${separator}`;

  const [address, setAddress] = useState(() => {
    const hasAddressField = Object.entries(values).some(([key, value]) => key.startsWith(fieldKey) && value);
    return hasAddressField ? [0] : [];
  });

  const addressCountryKey = fieldKey + 'country_id';

  useEffect(() => {
    if (!values[addressCountryKey]) {
      setAddress([]);
    }
  }, [addressCountryKey, values]);

  const onAddAddress = useCallback(() => {
    const defaultKey = fieldKey === 'address.' ? 'country_id' : `${fieldKey}country_id`;
    const key = countryKey || defaultKey;

    // set the value in the country field to the value of the address country field
    handlers.onChange({ [addressCountryKey]: values[key] });
    setAddress([0]);
  }, [addressCountryKey, fieldKey, handlers, values, countryKey]);

  const onRemoveItem = useCallback(() => {
    setAddress([]);
    const result = Object.entries(values).reduce((acc, [key, value]) => {
      if (key.startsWith(preset)) {
        return acc;
      }
      return { ...acc, [key]: value };
    }, {});
    handlers.onSetFields(result);
  }, [handlers, values, preset]);

  return (
    <FormDynamicFields
      values={address}
      Item={component}
      itemProps={() => ({
        name: preset,
        disableAddressType: true,
        large: true,
        formData,
        handlers,
      })}
      onAddItem={onAddAddress}
      onRemoveItem={onRemoveItem}
      label="Address"
      limit={1}
    />
  );
};

export default ContactAddressSection;
