import { map, uniq } from 'lodash';
import { IParametronData } from '@mediafellows/parametron';

import { ParametronStore } from 'store/parametron-store';

import { Model } from 'helpers/filters/types';

import { IAsset } from 'types';
import { chipmunk } from 'utils/chipmunk';

export const handleUpdate = async (data: IParametronData, packageSearch: ParametronStore): Promise<void> => {
  packageSearch?.update(data);

  const assetIds = data?.objects?.reduce((acc, delivery) => {
    const deliveryAssetIds = delivery.delivery_items.map((item) => item.asset_id);
    return acc.concat(deliveryAssetIds);
  }, []);

  const deliveryWithAssets: Array<{ id: string; asset_ids: Array<string> }> = map(data.objects, (delivery) => {
    return {
      id: delivery.id,
      asset_ids: uniq(map(delivery.delivery_items, 'asset_id')),
    };
  });

  if (assetIds.length > 0) {
    const assetsPromise = chipmunk.unfurl<IAsset>(Model.ASSETS, 'search', {
      body: { ids: assetIds, stats: 'main_classification' },
      schema: 'id, type, classification',
    });

    const { objects } = await assetsPromise;

    (data?.objects || []).map((delivery) => {
      const assetIdsPerDelivery: string[] = deliveryWithAssets.reduce((acc: string[], del) => {
        if (del.id === delivery.id) {
          acc = acc.concat(del.asset_ids);
        }
        return acc;
      }, []);

      delivery.assets = objects.filter((obj) => assetIdsPerDelivery?.includes(`${obj.id}`));
    });

    packageSearch?.update(data);
  }
};
