import React from 'react';
import { isArray } from 'lodash';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { useDialogStore } from 'store/hooks';
import { ILocationsActionConfig, ILocationsActionName } from 'utils/actions/location/types';

import { LocationForm } from 'components/scheduler/location/location-form';
import { shouldDisplayEdit } from 'utils/actions/location/actions-acl';
import { IUseActionsOptionParam } from 'utils/actions';

export const useEditLocation = (
  location: CalGenericLocation | CalGenericLocation[],
  options: IUseActionsOptionParam<CalGenericLocation | CalGenericLocation[]>,
): ILocationsActionConfig => {
  const firstLocation = isArray(location) ? location[0] : location;
  const { openModal } = useDialogStore();

  const title = firstLocation?.id ? `Edit ${firstLocation.name}` : `Create location`;
  const name = location ? ILocationsActionName.EDIT : ILocationsActionName.CREATE;
  const onSuccess = (): void => {
    options?.onSuccess?.(name);
  };

  const handler = (): void =>
    openModal({
      title,
      body: () => <LocationForm location={firstLocation} onSuccess={onSuccess} />,
    });

  return {
    name,
    shouldDisplay: shouldDisplayEdit,
    icon: location ? 'edit' : 'add',
    title: location ? 'Edit' : 'Create',
    handler,
  };
};
