import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from 'utils/hooks';
import {
  AnalyticsSectionFilters,
  RecommendationDetailsInteractions,
  recommendationDetailsInteractionsOptions,
  RecommendationAnalyticsListTable,
  IAnalyticsInteractionOption,
} from 'components/analytics';
import { getRecommendationReceivedTableData } from 'components/recommendation/recommendation-received-list-table/api';
import { recommendationsReceived } from 'components/analytics/queries/contact-queries';
import { IAnalyticsEntityValues, IAnalyticsStateProps, DetailsPageTabs } from 'types';
import { IScreeningRowData, IRecipientsRowData, IRecipientsScreeningData } from './types';

import {
  InteractionPreviewRecipients,
  InteractionPreviewAssets,
  recommendationReceivedColumns,
  recommendationScreeningsColumns,
} from './interaction-screens';

import '../style.scss';

interface IRecommendationRecipientsTabProps {
  recommendation?: McGenericRecommendationProduct;
}

export const RecommendationAnalyticsTab: React.FC<IRecommendationRecipientsTabProps> = ({ recommendation }) => {
  const { sent_at, id } = recommendation || {};
  const getMinDate = useCallback(async (): Promise<Date> => {
    return new Date(sent_at || '');
  }, [sent_at]);

  const { state }: { state: IAnalyticsStateProps } = useLocation();
  const [interaction, setInteraction] = useState<IAnalyticsInteractionOption | undefined>(
    RecommendationDetailsInteractions.RECIPINETS,
  );
  const {
    minDate,
    dateRange,
    onDateRangeChange,
    onDescendantsChange,
    onNonScreeningsAssetsChange,
    onProductsSelect,
    productIds,
    includeDescendants,
    includeNonScreeningsAssets,
    resetFilters,
  } = useAnalytics({
    getMinDate,
    initialDateRange: state?.dateRange,
    state,
    interaction,
    entity: IAnalyticsEntityValues.RECOMMENDATION,
    tab: DetailsPageTabs.ANALYTICS,
  });
  const { recommendationReceivedList, recommendationScreeningsList, recommendationScreeningData } =
    recommendationsReceived;
  const [selectedRow, setSelectedRow] = useState<IScreeningRowData | IRecipientsRowData | null>();
  const [screeningData, setScreeningData] = useState<IRecipientsScreeningData[]>();

  const fetchScreeningAnalytics = useCallback(
    async (rowData) => {
      setScreeningData([]);
      // additional call to get the missing screening data
      const { data } = await getRecommendationReceivedTableData({
        entity: 'recommendation',
        entityId: recommendation?.id,
        include_all_users: true,
        asset_ids: [rowData.asset_id],
        page: 1,
        per: 25,
        from_date: dateRange[0],
        to_date: dateRange[1],
        user_ids: [rowData.recipient?.id],
        query_name: recommendationScreeningData,
      });

      setScreeningData(data as unknown as IRecipientsScreeningData[]);
    },
    [recommendation?.id, dateRange, recommendationScreeningData],
  );

  const onInteractionChange = useCallback(({ interaction }: { interaction: IAnalyticsInteractionOption }) => {
    setInteraction(interaction);
    setSelectedRow(null);
  }, []);

  const onRowSelect = useCallback(
    (e, data) => {
      e.preventDefault();
      const target = e.target;
      const parent = target.closest('tr');
      //removes className from all rows
      const rows = document.querySelectorAll('tbody tr');
      Array.from(rows).filter((el) => (el.className = ''));
      const isRecipient = !!data.recipient?.id;
      const rowCondition = isRecipient
        ? data.recipient?.id === (selectedRow as IRecipientsRowData)?.recipient?.id
        : data.asset_id === (selectedRow as IScreeningRowData)?.asset_id;

      if (selectedRow && rowCondition) {
        setSelectedRow(null);
        parent.className = '';
      } else {
        setSelectedRow(data);
        fetchScreeningAnalytics(data);
        parent.className = 'active';
      }
    },
    [fetchScreeningAnalytics, selectedRow],
  );

  const handleNonScreeningsAssetsChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRow(null);
    return onNonScreeningsAssetsChange(evt);
  };

  return (
    <div className="analytics-tab">
      <AnalyticsSectionFilters
        interaction={interaction}
        interactionOptions={recommendationDetailsInteractionsOptions}
        onInteractionChange={onInteractionChange}
        productIds={productIds}
        onProductsSelect={onProductsSelect}
        handleDescendantsChange={onDescendantsChange}
        includeDescendants={includeDescendants}
        dateRange={dateRange}
        minDate={minDate}
        handleDateRangeChange={onDateRangeChange}
        includeNonScreeningsAssets={includeNonScreeningsAssets}
        handleNonScreeningsAssetsChange={handleNonScreeningsAssetsChange}
        resetFilters={resetFilters}
      />

      <div className={`analytics-detail__wrapper h-100 ${isEmpty(selectedRow) ? 'full-view' : ''}`}>
        <div className="analytics-recommendation__tab-content">
          <RecommendationAnalyticsListTable
            title=""
            queryName={
              interaction === RecommendationDetailsInteractions.RECIPINETS
                ? recommendationReceivedList
                : recommendationScreeningsList
            }
            dateRange={dateRange as [Date, Date]}
            productIds={productIds}
            includeDescendants={includeDescendants}
            includeNonScreeningsAssets={
              interaction === RecommendationDetailsInteractions.PRODUCT_ASSET ? includeNonScreeningsAssets : true
            }
            entity="recommendation"
            entityId={id}
            tableClassName="recommendation-recipients__table"
            headerClassName="d-none"
            columns={
              interaction === RecommendationDetailsInteractions.RECIPINETS
                ? recommendationReceivedColumns()
                : recommendationScreeningsColumns()
            }
            onRowClick={(e, data) => onRowSelect(e, data)}
          />
        </div>

        {selectedRow &&
          (interaction === RecommendationDetailsInteractions.RECIPINETS ? (
            <InteractionPreviewRecipients rowData={selectedRow as IRecipientsRowData} screeningData={screeningData} />
          ) : (
            <InteractionPreviewAssets rowData={selectedRow as IScreeningRowData} screeningData={screeningData} />
          ))}
      </div>
    </div>
  );
};
