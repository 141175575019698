import { useCallback, useState } from 'react';
import { map } from 'lodash';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { cancelDelivery } from 'utils/apis/package-delivery';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { IDeliveryActionName } from './types';
import { shouldDisplayRevoke as shouldDisplay } from './actions-acl';

export const useCancelAction: IUseAction<DeliveriesGenericDeliveryPackage, IDeliveryActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const [loading, setLoading] = useState(false);
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Delivery', items.length);

  const executeCancel = useCallback(async () => {
    try {
      setLoading(true);
      toastStore.info('Cancelation of the delivery package has started!');

      await cancelDelivery(map(items, 'id'));
      toastStore.success(`${entityWithCount} canceled`);
      setLoading(false);

      return await options?.onSuccess?.(IDeliveryActionName.CANCEL);
    } catch (error) {
      toastStore.error(`${entity} cancel action failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Cancelation',
      body: `Are you sure you want to cancel ${entityWithDemonstrative}?`,
      onConfirm: executeCancel,
    });
  }, [dialogStore, entityWithDemonstrative, executeCancel]);
  return {
    name: IDeliveryActionName.CANCEL,
    shouldDisplay,
    isDisabled: loading,
    icon: 'undo',
    title: 'Cancel',
    intent: Intent.DANGER,
    handler,
  };
};
