import React, { useCallback } from 'react';
import get from 'lodash/get';

import { FormRemoteSelect, IFormData, IFormHandlers, IFormRemoteSelectProps } from 'helpers/form';
import { queryOrganizations } from 'utils/apis/organization';
import { IOrganization, ISearchFilter } from 'types';
import { IFormData as IMm3FormData } from 'helpers/form/mm3';

export interface IFormOrganizationSelectProps<T> extends Partial<IFormRemoteSelectProps> {
  name: string;
  label?: string;
  placeholder?: string;
  formData: IFormData<T> | IMm3FormData<T>;
  handlers: Omit<IFormHandlers<T>, 'onSetFields'>;
  additionalFilters?: ISearchFilter[];
}

export function FormOrganizationSelect<T>({
  name,
  label = 'Organization',
  placeholder = 'Select Organization',
  formData,
  handlers,
  additionalFilters = [],
  large = true,
  ...rest
}: IFormOrganizationSelectProps<T>): React.ReactElement {
  const fetchOrganizationsOptions = useCallback(
    async (q: string, id: string | number): Promise<IOrganization[]> => {
      const params = { q, ...(id ? { ids: [id] } : {}) };
      return queryOrganizations(params, additionalFilters);
    },
    [additionalFilters],
  );

  return (
    <FormRemoteSelect
      name={name}
      label={label}
      placeholder={placeholder}
      large={large}
      {...get(formData, name)}
      {...handlers}
      fetchOptions={fetchOrganizationsOptions}
      enableClearing
      {...rest}
    />
  );
}

export default FormOrganizationSelect;
