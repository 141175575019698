import { IResult } from 'utils/chipmunk';
import { ISimpleSelectionItem } from 'components/selection/selection-preview-tab/selection-preview-tab';
import { ISelectionStandardized, IAsset, IContact, IProduct } from 'types';
import { fetchGroupAssets } from 'utils/apis/asset';
import { queryContacts } from 'utils/apis/contacts';
import { fetchGroupProduct } from 'utils/apis/product';
import { flags } from 'utils/flags';

export const getSimpleSelectionItems = async (
  selection: ISelectionStandardized,
): Promise<IResult<(IAsset | IContact | IProduct) & ISimpleSelectionItem> | undefined> => {
  switch (selection.main_entity_type) {
    case 'users':
      const contacts = await queryContacts({ per: 8 }, [['group_ids', 'in', [selection.id]]]);
      const objects = contacts.map((contact) => ({ ...contact, label: `${contact.first_name} ${contact.last_name}` }));
      return { objects, object: objects?.[0] };

    case 'assets': {
      const result = await fetchGroupAssets<IAsset, true>(
        selection.id,
        { per: 8 },
        { stats: flags.isMm3Assets ? 'type' : 'main_classification', rawResult: true },
      );
      const objects = result.objects.map((asset) => ({ ...asset, label: asset.name || '' }));
      return { ...result, objects, object: objects?.[0] };
    }
    case 'products': {
      const result = await fetchGroupProduct(selection.id, { per: 8 }, { rawResult: true });
      const objects = result.objects.map((product) => ({ ...product, label: product.title }));
      return {
        ...result,
        objects,
        object: objects?.[0],
      };
    }
  }
};
