import {
  connect as pigeonConnect,
  disconnect,
  enableUserNotifications,
  addListener,
} from '@mediafellows/pigeon2-client';
import { observable } from 'mobx';
import { useSessionStore } from 'store/session-store';
import { chipmunk } from 'utils/chipmunk';
import { logger } from 'utils/logger';

export class NotificationsStore {
  @observable public initialized = false;

  public async connect(): Promise<void> {
    this.initialized = true;
    await pigeonConnect(chipmunk);
    const user = useSessionStore.getState().user;

    if (user?.id) enableUserNotifications();

    addListener('message', ({ message, type }) => {
      logger.info('pigeon message!', message, type);
    });
  }

  public reset(): void {
    disconnect();

    this.initialized = false;
  }
}
