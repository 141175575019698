import React, { useCallback } from 'react';

import { IAsset, IMm3Asset, ItemId } from 'types';

import { createWorkflow } from 'utils/apis/workflow';

import { FormAssets, FormSelect, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { useDialogStore } from 'store/hooks';
import { useStore } from 'store';

import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';
import { FormSubmitSection } from 'components/form-submit-section';

import { IAssetActionName, IAssetActionConfig } from './types';
import { shouldDisplayTranscode } from './actions-acl';
import { getValidIds } from 'utils/general';

const transcodingOptions: { value: string; label: string }[] = [
  {
    value: 'prores422.1',
    label: 'ProRes 422.1 (MOV, 720x480, 4:3, 29.97 FPS, PCM 48kHz)',
  },
  {
    value: 'h264',
    label: 'H264 (MP4, 720x480, 4:3, 29.97 FPS, AAC 44.1kHz)',
  },
];

const customContext = {
  properties: {
    encoding_profile: { required: true },
    asset_ids: { required: true, validation: { minimum: 1 } },
  },
};
const labels = { confirm: 'Submit' };

type IFormData = {
  encoding_profile: string;
  asset_ids: ItemId[];
  name: 'Workflow::Base::MultiTranscodeWorkflow';
};

export const TranscodeForm: React.FC<{ assets: (IMm3Asset | IAsset)[] }> = ({ assets }) => {
  const initialValues: IFormData = {
    encoding_profile: '',
    asset_ids: getValidIds(assets),
    name: 'Workflow::Base::MultiTranscodeWorkflow',
  };
  const { close } = useDialogStore();

  const { toastStore } = useStore();
  const handleSubmit = useCallback(
    async (values: IFormData, valid: boolean): Promise<void> => {
      try {
        if (!valid) {
          return;
        }

        await createWorkflow(values);

        toastStore.clearAll();
        toastStore.success('Transcoding was successfully requested!');
        close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, toastStore],
  );

  const { formData, onSubmit, handlers, valid } = useForm<IFormData>(
    initialValues,
    Model.ASSETS,
    handleSubmit,
    customContext,
  );

  return (
    <form onSubmit={onSubmit}>
      <FormSelect
        name="encoding_profile"
        label="Encoding Profile"
        {...formData.encoding_profile}
        {...handlers}
        options={transcodingOptions}
        noEmptyOption
      />
      <FormAssets name="asset_ids" {...formData.asset_ids} {...handlers} />
      <FormSubmitSection submitDisabled={!valid} labels={labels} />
    </form>
  );
};

export const useTranscodeAction = (assets: (IMm3Asset | IAsset)[]): IAssetActionConfig => {
  const { openModal } = useDialogStore();
  const handler = useCallback(
    () =>
      openModal({
        title: 'Asset Transcoding',
        size: IModalSize.M,
        body: () => <TranscodeForm assets={assets} />,
      }),
    [assets, openModal],
  );

  return {
    name: IAssetActionName.TRANSCODE,
    icon: 'array-numeric',
    title: 'Transcode',
    shouldDisplay: shouldDisplayTranscode,
    handler,
  };
};
