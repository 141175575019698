import { useCallback, useState } from 'react';
import { toNumber, omit } from 'lodash';

import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { IUseAction } from 'utils/actions';
import { useStore } from 'store';
import { useSessionStore } from 'store/session-store';

import { copyDeliveryPackage } from 'utils/apis/package-delivery';

import { shouldDisplayCopy } from './actions-acl';
import { IDeliveryActionName } from './types';

export const useCopyAction: IUseAction<DeliveriesGenericDeliveryPackage, IDeliveryActionName> = (items, options) => {
  const { toastStore } = useStore();
  const [loading, setLoading] = useState(false);
  const user = useSessionStore((state) => state.user);

  const handler = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }
      setLoading(true);
      toastStore.info('Copying of the delivery package has started!');

      await copyDeliveryPackage({
        ...omit(items[0], ['uuid', 'percent_transferred', 'delivered_at', 'delivery_duration_seconds']),
        owner_id: toNumber(user?.id),
        owner_organization_id: toNumber(user?.organization_id),
        status: 'draft',
      });

      toastStore.success('Delivery copied successfully!');
      setLoading(false);
      return options?.onSuccess?.(IDeliveryActionName.COPY);
    } catch (error) {
      toastStore.error(`Delivery copy failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [toastStore, items, options, user?.id, user?.organization_id]);

  return {
    name: IDeliveryActionName.COPY,
    shouldDisplay: shouldDisplayCopy,
    isDisabled: loading,
    icon: 'duplicate',
    title: 'Copy',
    handler,
  };
};
