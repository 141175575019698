import React from 'react';
import { IFilters } from 'components/product-filters/types';
import { FilterSelect, IFiltersHandlers } from 'helpers/filters';
import { InFilter } from 'helpers/filters/types';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { QueryDataProvider, queryDataExecutorCache } from 'helpers/data-provider/option-data-provider';
import { IResult } from 'utils/chipmunk';
import { ILanguage } from 'types';

import { queryLanguages } from 'utils/apis/language';

const languageKey = 'default_layer.meta.language_ids';
export const additionalFilters = {
  [languageKey]: new InFilter(languageKey, []),
};

interface IAdditionalFilters {
  [languageKey]: InFilter;
}

interface IAdditionalFiltersProps {
  filterValues: IAdditionalFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}

const languageOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.language.search-cache', queryLanguages),
  (data: IResult<ILanguage>) => data.objects.map((l) => ({ value: l.id, label: l.name })),
);

export const LanguageSelector: React.FC<IAdditionalFiltersProps> = observer(({ filterValues, filterHandlers }) => {
  const {
    dataSectionStore: { searchStore },
  } = useStore();

  return (
    <FilterSelect
      label="Language"
      name="default_layer.meta.language_ids"
      placeholder="Select Language"
      optionsProvider={languageOptionsProvider}
      filter={filterValues[languageKey]}
      onChange={filterHandlers.onChange}
      aggregations={searchStore.aggregationValues('language_ids')}
    />
  );
});
