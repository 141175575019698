import React, { useCallback } from 'react';
import { IAccessPrivilegeForm } from './types';
import { IWizardStepButton } from 'components/wizard/utils';
import { Button } from '@mantine/core';

interface ISkipButtonProps {
  form: IAccessPrivilegeForm;
  currentStepId: number;
  nextButton: IWizardStepButton;
}

export const enum StepIds {
  General = 0,
  Products = 1,
  Contacts = 2,
  Settings = 3,
  Email = 4,
  Summary = 5,
}

export const SkipButton: React.FC<ISkipButtonProps> = ({ form, currentStepId, nextButton }) => {
  const modifyEmailValidation = useCallback(() => {
    form.handlers.onChange({ email_subject: '', email_message: '' });
    nextButton.action();
  }, [form, nextButton]);

  return currentStepId === StepIds.Email ? (
    <Button variant="primary" onClick={modifyEmailValidation}>
      Skip
    </Button>
  ) : (
    <></>
  );
};

export const getEmailStepNextButton = (form: IAccessPrivilegeForm): { title: string; disabled: boolean } => {
  const {
    values: { email_subject, email_message },
    formData,
  } = form;

  if (!formData.email_subject?.validation?.valid || !formData.email_message?.validation?.valid) {
    return { title: 'Next', disabled: true };
  }

  const hasSubject = email_subject.trim().length > 0;
  const hasMessage = email_message.trim().length > 0;

  if (!hasMessage || !hasSubject) {
    return { title: 'Next', disabled: true };
  }

  return { title: 'Next', disabled: false };
};
