import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IconName } from 'blueprint5-icons';
import cx from 'classnames';
import { startCase, get } from 'lodash';

import { useStore } from 'store';
import { FormInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { LabelValuePair as Row } from 'components/label-value-pair';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { InfoBox } from 'components/info-box';
import { DataSectionControls } from 'components/data-section-controls';
import LoadingBoundary from 'components/loading-boundary/loading-boundary';

import { formatDate } from 'utils/date';
import { IGroupNameEditFormFields } from 'utils/apis/groups';
import { getEntityLabel } from 'utils/general';
import { useEditMode, useRefreshDataSection } from 'utils/hooks';
import { editBasket } from 'utils/apis/lists';

import { IBasketStandardized, IDataSectionLayout } from 'types';
import { useSessionStore } from 'store/session-store';
import '../style.scss';

export interface IBasketDetailsLayoutProps {
  refreshBasket: () => Promise<void>;
  basketLoading: boolean;
  basket: IBasketStandardized;
  headerDropdownMenu: React.ReactElement;
  layout?: IDataSectionLayout;
  className?: string;
  isSortModeEnabled?: boolean;
  setIsSortModeEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const inputStyle = { gridAutoColumns: '1fr' };

const BasketDetailsLayout: React.FC<IBasketDetailsLayoutProps> = observer(
  ({
    basket,
    basketLoading,
    refreshBasket,
    className,
    children,
    headerDropdownMenu,
    isSortModeEnabled,
    setIsSortModeEnabled,
  }) => {
    const { toastStore } = useStore();
    const user = useSessionStore((state) => state.user);
    const hideSortButton = !!user?.id && +user?.id !== +basket?.ownerId;

    const [editModeEnabled, setEditModeEnabled] = useEditMode();
    const refreshDataSection = useRefreshDataSection();
    const { id, name, owner, updatedAt, entityCount, entityType } = basket || {};

    const handleSubmit = useCallback(
      async (values: IGroupNameEditFormFields): Promise<void> => {
        try {
          toastStore.info('Saving');
          await editBasket(id, values);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          setEditModeEnabled(false);
          refreshBasket();
        } catch (e) {
          toastStore.clearAll();
          toastStore.error(`Error while saving: ${get(e, 'object.description', 'Unknown error')}`);
        }
      },
      [id, refreshBasket, setEditModeEnabled, toastStore],
    );

    const editForm = useForm<IGroupNameEditFormFields>({ name: name || '' }, Model.GROUPS, handleSubmit);
    const onChange = editForm.handlers.onChange;

    useEffect(() => {
      if (name) {
        onChange({ name });
      }
    }, [onChange, name]);

    const editModeActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => [
        {
          type: 'save',
          handler: editForm.onSubmit,
          disabled: !editForm.valid,
        },
        { type: 'cancel', handler: (): void => setEditModeEnabled(false) },
      ],
      [editForm, setEditModeEnabled],
    );

    const commonActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => [{ type: 'edit', handler: () => setEditModeEnabled(true) }],
      [setEditModeEnabled],
    );

    const headerActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => (editModeEnabled ? editModeActions : commonActions),
      [commonActions, editModeActions, editModeEnabled],
    );

    const refresh = useCallback(() => {
      refreshBasket();
      refreshDataSection();
    }, [refreshDataSection, refreshBasket]);

    return (
      <div className="d-flex flex-column h-100 w-100">
        <div className="d-flex align-items-center justify-content-between">
          <DashboardBreadcrumbs className="d-inline-block mb-3" />
          <div>
            <DataSectionControls customRefresh={refresh} />
          </div>
        </div>
        <SectionHeader
          title={name}
          subtitle={startCase(owner)}
          actions={headerActions}
          dropdownMenu={headerDropdownMenu}
          useBackground
          isSortModeEnabled={isSortModeEnabled}
          setIsSortModeEnabled={setIsSortModeEnabled}
          hideSortButton={hideSortButton}
        />
        <div className="basket-details__wrapper h-100">
          <div>
            <LoadingBoundary loading={basketLoading} suspender={<Loading text="Loading Basket" />}>
              <InfoBox gridTemplateColumns={'1fr 2fr'} useBackground={true}>
                <Row
                  label="Name"
                  value={
                    editModeEnabled ? (
                      <FormInput
                        name="name"
                        style={inputStyle}
                        {...editForm.formData.name}
                        {...editForm.handlers}
                        required={false}
                      />
                    ) : (
                      name
                    )
                  }
                />
                <Row label="Owner" value={owner} />
                <Row label="Updated at" rawLabel value={formatDate(updatedAt)} />
                <Row label="Content type" value={getEntityLabel(entityType)} />
                <Row label={entityType || 'Items'} value={entityCount} />
              </InfoBox>
            </LoadingBoundary>
          </div>
          <div className={cx('d-flex flex-column', className)}>{children}</div>
        </div>
      </div>
    );
  },
);

export default BasketDetailsLayout;
