import React, { useCallback } from 'react';
import { IAsset, IMm3Asset } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions';

import { AssetAccessChangeForm } from './asset-access-change-form';
import { IModalSize } from 'components/dialogs/types';

export const useAccessChangeDialog = (
  items: (IAsset | IMm3Asset)[] = [],
  options: IUseActionsOptionParam,
): [() => void] => {
  const { dialogStore } = useStore();

  const body = useCallback((): JSX.Element => {
    return <AssetAccessChangeForm items={items} options={options} />;
  }, [items, options]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Change asset access',
      body,
      size: IModalSize.S,
    });
  };

  return [openDialog];
};
