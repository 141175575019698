import React from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { formatDate } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ItemId } from 'types/utility';
import { formatCollectionValueToOption } from 'utils/list';
import { CustomFieldsPreview } from 'pages/collection-details/collection-overview-tab/custom-fields';
import { formatField, formatOwnership } from 'utils/general';
import { useCategories } from 'utils/hooks';

export interface ICollectionOverviewTabProps {
  collection: UmGenericListCollection;
  productIds?: number[];
  subCollectionIds?: number[];
  isEditMode: boolean;
  collectionForm: IUseMm3FormReturn<UmGenericListCollection>;
}
interface ICollectionOverviewProps {
  productIds?: ItemId[];
  subCollectionsCount?: number;
  loadingSubCollections: boolean;
}

export const CollectionOverviewPreview: React.FC<
  ICollectionOverviewProps & { collection: UmGenericListCollection }
> = ({ collection, productIds, subCollectionsCount, loadingSubCollections }) => {
  const { allowed_item_types, name, purpose, access_level, meta, sublist, created_at, updated_at } = collection;
  const categories = useCategories(meta?.category_ids);

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 4fr" wrapperClassName="py-3" className="collection-overview__attributes">
      <Row label="Name" value={name} className="text-wrap text-break" size="m" />
      <Row label="Identifier" rawValue value={purpose} className="text-wrap text-break" size="m" />
      <Row label="Genres" value={categories} />
      <Row label="Is Sub-Collection" rawLabel value={yn(sublist)} size="m" />
      <Row
        label="Allowed objects"
        value={formatField(allowed_item_types?.map(formatCollectionValueToOption).map((e) => e.label))}
      />
      <Row label="Access level" value={access_level} size="m" />
      <CustomFieldsPreview collection={collection} />
      <Row label="Description" value={meta?.description} size="m" />
      <Row label="Ownership" value={formatOwnership(collection)} size="m" />
      <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
      <Row label="Updated at" rawLabel value={formatDate(updated_at)} size="m" />
      {!sublist && (
        <Row
          label="Sub-Collection count"
          rawLabel
          value={subCollectionsCount}
          loading={loadingSubCollections}
          valueClassName="d-block"
        />
      )}
      <Row label="Products count" rawLabel value={productIds?.length} valueClassName="d-block" />
    </InfoBoxV2>
  );
};
