import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { IConference } from 'types';
import { useDataSectionStore } from 'store';
import { handleItemClick } from 'components/data-section';

import { ConferenceDataSectionListItem } from './conference-data-section-list-item';

interface IConferenceDataSectionItemProps {
  className?: string;
  conference: IConference;
  contextMenu?: React.ReactElement;
}

export const ConferenceDataSectionItem: React.FC<IConferenceDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, conference } = props;

  const { id } = conference;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <ConferenceDataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        conference={conference}
        isChecked={isChecked}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
