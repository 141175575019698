import React from 'react';

import { IMarketingEvent } from 'types';

import EventSubEventsSortTab from './event-sub-event-sort-tab';
import { EventSubEventsPreviewTab } from './event-sub-events';

export const EventSubEventsTab: React.FC<{
  setEvent: React.Dispatch<React.SetStateAction<IMarketingEvent>>;
  event: IMarketingEvent;
  isSortModeEnabled?: boolean;
}> = ({ event, isSortModeEnabled, setEvent }) => {
  if (isSortModeEnabled) {
    return <EventSubEventsSortTab event={event} setEvent={setEvent} />;
  }

  return <EventSubEventsPreviewTab event={event} setEvent={setEvent} />;
};

export default EventSubEventsPreviewTab;
