import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { Plus } from 'blueprint5-icons';

import { CollectionDataSection } from 'components/collection/collection-data-section';
import { DataSectionPage } from 'components/layout';
import { IWebsitePageValues } from 'types';
import { useCreateShowcase } from 'components/showcase-form/showcase-dialog';

import { persistentFilters } from './utils';
import { MantineIcon } from 'utils/ui/icon';
import CollectionsSortPage from './collections-sort';
import { EqFilter } from 'helpers/filters/types';

const initFilters = { sublist: new EqFilter('sublist', false) };

export const CollectionsPage: React.FC<{}> = observer(() => {
  const openCreateListDialog = useCreateShowcase({
    title: 'Create Collection',
    section: IWebsitePageValues.COLLECTIONS,
  });

  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const toggleSortMode = useCallback<VoidFunction>(() => {
    setIsSortModeEnabled((v) => !v);
  }, []);

  return (
    <DataSectionPage>
      {isSortModeEnabled ? (
        <CollectionsSortPage setIsSortModeEnabled={setIsSortModeEnabled} isSortModeEnabled={isSortModeEnabled} />
      ) : (
        <CollectionDataSection
          persistentFilters={persistentFilters}
          initFilters={initFilters}
          persistFilters
          headerRightSection={
            <>
              <Button size="xs" className="me-2" onClick={toggleSortMode} variant="success">
                Sort
              </Button>
              <Button
                size="xs"
                leftSection={<MantineIcon icon={<Plus />} />}
                onClick={openCreateListDialog}
                variant="primary"
              >
                Create Collection
              </Button>
            </>
          }
        />
      )}
    </DataSectionPage>
  );
});

export default CollectionsPage;
