import { action, observable, IObservableArray, computed } from 'mobx';
import { chipmunk, tuco } from 'utils/chipmunk';
import { fetchGeographyOptions } from 'utils/apis/geography';
import { getBuyerDomain } from 'utils/apis/conference';

import { getRootStore } from 'store';
import { useSessionStore } from 'store/session-store';

import type { IBuyerDomain, ICurrency, IDivision, ILanguage } from 'types';

import type { IFormMultiSelectOption, IFormSelectOption, IRawTreeOption } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IDataProviderItem } from 'helpers/data-provider/option-data-provider';

interface IAffiliation {
  primary_buyer_domain?: {
    protocol: string;
    name: string;
    id: number;
  };
}

export class BasicStore {
  @observable public isGlobalSearchOpen = false;
  @observable public categories = observable.array([]) as IObservableArray;
  @observable public divisions = observable.array([]) as IObservableArray<IDivision>;
  @observable public languages = observable.array([]) as IObservableArray;
  @observable public countries = observable.array([]) as IObservableArray;
  @observable public territories = [] as IRawTreeOption[];
  @observable public currencies = observable.array([]) as IObservableArray<ICurrency>;
  @observable public affiliation: IAffiliation = {};
  @observable public buyerDomain: IBuyerDomain;

  private basicsPromise: Promise<void>;

  @computed get countriesDataOptions(): IDataProviderItem[] {
    return this.countries.map((country) => ({ value: country.id, label: country.name }));
  }

  @computed get languagesDataOptions(): IFormMultiSelectOption[] {
    return this.languages.map((language) => ({ value: language.id, label: language.name }));
  }

  @computed get categoriesDataOptions(): IFormSelectOption[] {
    return this.categories.map((category) => ({ value: category.id, label: category.name }));
  }

  @computed get currencyOptions(): IFormSelectOption[] {
    return this.currencies.map((currency) => ({ value: currency.id, label: currency.name }));
  }

  @computed get languagesById(): Record<string, ILanguage> {
    return this.languages.reduce((acc, lang) => {
      acc[lang.id] = lang;
      return acc;
    }, {});
  }
  @computed get getLanguagesByIds() {
    return (languageIds: string[]): ILanguage[] => {
      return languageIds.map((id) => this.languagesById[id]);
    };
  }

  @computed get languagesNamesById() {
    return (languageIds: string[]) => {
      return this.getLanguagesByIds(languageIds).map((lang) => lang?.name);
    };
  }

  @action.bound
  public async loadBasics(): Promise<void> {
    const { toastStore } = getRootStore();
    const session = useSessionStore.getState().session;

    if (!session) {
      return;
    }

    if (this.basicsPromise) {
      return this.basicsPromise;
    }

    return (this.basicsPromise = chipmunk.run(
      async ({ action, performLater }) => {
        const values = await Promise.all([
          action('um.geography', 'countries'),
          tuco('gcuiBasics', { isMm3: true }),
          action('um.currency', 'get', { params: { currency_ids: [] } }),
          fetchGeographyOptions(),
          action(Model.AFFILIATION, 'get', { schema: 'primary_buyer_domain' }),
          action(Model.DIVISIONS, 'get', { params: { division_ids: [] } }),
        ]);

        this.countries.replace(values[0]?.objects);
        const { categories, languages } = values[1]?.object as { categories; languages };
        const sortedLanguages = [...languages].sort((a, b) => a.name.localeCompare(b.name));
        this.categories = observable.array(categories);
        this.languages = observable.array(sortedLanguages);
        this.currencies = observable.array(values[2]?.objects as ICurrency[]);
        this.territories = observable.array(values[3] || []);
        this.affiliation = (values[4]?.object || {}) as IAffiliation;
        this.divisions = observable.array(values[5]?.objects as IDivision[]);

        performLater(async () => {
          if (!this.affiliation?.primary_buyer_domain?.id) {
            return;
          }

          this.buyerDomain = await getBuyerDomain(this.affiliation.primary_buyer_domain.id);
        });
      },
      () => {
        toastStore.error('Failed to load basics');
      },
    ));
  }

  @action.bound
  updateBasicStore(key: string, value: unknown): void {
    this[key] = value;
  }
}
