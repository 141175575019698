import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';

export enum IDeliveryActionName {
  EDIT = 'edit',
  SEND = 'send',
  DELETE = 'delete',
  SCHEDULE = 'schedule',
  COPY = 'copy',
  CANCEL = 'cancel',
  RETRIGGER = 'retrigger',
}

export type IDeliveryActionConfig = IActionConfig<DeliveriesGenericDeliveryPackage, IDeliveryActionName>;
export type IShouldDisplayDeliveryActionFunction = IShouldDisplayActionFunction<
  DeliveriesGenericDeliveryPackage,
  IDeliveryActionName
>;
