import React, { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { Loading } from 'components/loading';

import { IAsyncSliderSelect, ISliderSelect } from 'components/slider-select/types';
import useSelectedOption from 'utils/hooks/selected-option';

import './style.scss';

const CONTAINER_WIDTH = 180;

export const SliderSelect: React.FC<ISliderSelect> = ({
  value,
  onMouseUp,
  onMouseDown,
  disabled,
  options,
  onChange,
  className,
}) => {
  const [label, setLabel] = useState<{ left: number; label: string; hoveredElementOrder: number } | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedOption = useSelectedOption({ value, options });

  const onElementChangeHandle = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newOption = options[event.currentTarget.value];
      if (value === newOption?.value || !onChange) {
        return;
      }
      onChange(newOption.value);
    },
    [onChange, options, value],
  );

  const onMouseMoveHandle = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
      const CONTAINER_PADDING = 14;
      const SLIDER_THUMB_WIDTH = 14;

      const distanceBetweenOptions =
        options.length > 1 ? (CONTAINER_WIDTH - CONTAINER_PADDING - SLIDER_THUMB_WIDTH) / (options.length - 1) : 0;

      const labelLeftOffset = (CONTAINER_PADDING + SLIDER_THUMB_WIDTH) / 2;

      const cursorOffsetX = event.nativeEvent.offsetX + 2.5;

      const hoveredElementOrder = Math.floor((cursorOffsetX + SLIDER_THUMB_WIDTH / 2) / distanceBetweenOptions);

      const newOption = options[hoveredElementOrder];
      if (newOption)
        setLabel({
          label: newOption.label,
          left: hoveredElementOrder * distanceBetweenOptions + labelLeftOffset,
          hoveredElementOrder,
        });
    },
    [options],
  );

  return (
    <div className={cx('slider-select__content', className)} style={{ width: CONTAINER_WIDTH }}>
      <div className={'slider-select__dots-wrapper'}>
        {options.map((option) => (
          <div key={`dot-${option.value}`} className={'slider-select__dot'} />
        ))}
      </div>
      <input
        ref={inputRef}
        disabled={disabled}
        onChange={onElementChangeHandle}
        onMouseMove={onMouseMoveHandle}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        value={selectedOption?.order || 0}
        className={cx(`slider-select__input slider-select__input-${label?.hoveredElementOrder}`, {
          [`slider-select__input-color-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
        type="range"
        min={0}
        max={options.length - 1}
      />
      {label && (
        <div className={'slider-select__tooltip'} style={{ left: label.left }}>
          {label.label}
        </div>
      )}
    </div>
  );
};

export const AsyncSliderSelect: React.FC<IAsyncSliderSelect> = ({
  value,
  onChange,
  className,
  spinner,
  disabled,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value || '');
  const [oldValue, setOldValue] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const onMouseDownHandle = useCallback(() => {
    if (!loading) {
      setOldValue(currentValue);
    }
  }, [currentValue, loading]);

  const onElementChangeHandle = useCallback(
    (newValue: string) => {
      if (oldValue) {
        setCurrentValue(newValue);
      }
    },
    [oldValue],
  );

  const onMouseUpHandle = useCallback(async () => {
    if (currentValue === oldValue || !oldValue) {
      return;
    }
    if (!onChange) {
      setCurrentValue(oldValue);
      return;
    }
    setLoading(true);
    try {
      const updateComplete = await onChange(currentValue);
      if (!updateComplete) {
        setCurrentValue(oldValue);
      }
    } catch (e) {
      setCurrentValue(oldValue);
      setOldValue(null);
      setLoading(false);
      throw e;
    }
    setOldValue(null);
    setLoading(false);
  }, [currentValue, oldValue, onChange]);

  return (
    <div className={cx('slider-select__wrapper', className)}>
      <SliderSelect
        {...props}
        value={currentValue}
        onChange={onElementChangeHandle}
        onMouseDown={onMouseDownHandle}
        onMouseUp={onMouseUpHandle}
        disabled={loading || disabled}
        className={cx(
          'slider-select__content',
          { ['slider-select__disabled']: disabled },
          { ['slider-select__loading']: loading },
        )}
      />
      {loading && spinner && <Loading className={'slider-select__loading'} />}
    </div>
  );
};
