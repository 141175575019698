import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useAssetsUploadStore, useAssetsUploadToSelectionStore } from 'store/hooks';
import AssetUploadShortcut from 'components/dialogs/upload-dialog/assets-upload-shortcut/assets-upload-shortcut';
import { useUploadAssetToSelectionDialog } from 'components/upload-asset-to-selection-dialog';
import { pick } from 'lodash';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';
import './style.scss';

export const UploadDialog: React.FC<{}> = () => {
  const assetUploadStore = useAssetsUploadStore();
  const assetUploadToSelectionStore = useAssetsUploadToSelectionStore();
  const [openUploadAssetToSelection] = useUploadAssetToSelectionDialog();
  const navigate = useNavigate();

  const openAssetUploadPage = useCallback(() => {
    navigate(`${Routes.CREATE_ASSET}`);
  }, [navigate]);

  const openAssetUploadToSelection = useCallback(() => {
    openUploadAssetToSelection();
  }, [openUploadAssetToSelection]);

  return (
    <div className={'upload-dialog__wrapper'}>
      <AssetUploadShortcut
        onClick={openAssetUploadPage}
        {...pick(assetUploadStore, ['files', 'progress', 'isUploadPending', 'uploadedFiles'])}
      />
      <AssetUploadShortcut
        onClick={openAssetUploadToSelection}
        {...pick(assetUploadToSelectionStore, ['files', 'progress', 'isUploadPending', 'uploadedFiles'])}
      />
    </div>
  );
};

export default observer(UploadDialog);
