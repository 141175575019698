import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterDateRange, FilterSelect, FilterSwitch, FilterText } from 'helpers/filters/fields';
import {
  EqFilter,
  FilterActions,
  IFiltersDefinition,
  InFilter,
  Model,
  QFilter,
  RangeFilter,
} from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { useStore } from 'store';

import { useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { AdditionalFiltersCmp } from 'components/collection/collection-data-section-filters/additional-filters';
import { getCustomOwnersOptionsProvider } from 'helpers/filters/option-providers';

export interface ICollectionDataSectionFilters extends IFiltersDefinition {
  _: QFilter;
  owner_id: InFilter;
  access_level: InFilter;
  updated_at: RangeFilter;
  created_at: RangeFilter;
  sublist: EqFilter;
}

const accessOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'access_level');

const handleSubmit = (action: FilterActions): void => submitFilters({ action });

export const CollectionFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore || {};
  const [filterValues, filterHandlers] = useFilters<ICollectionDataSectionFilters>(dataSectionStore, handleSubmit);

  const ownerAggregation = searchStore.aggregationValues?.('owner_id');

  const ownersOptionsProvider = useMemo(() => getCustomOwnersOptionsProvider(ownerAggregation), [ownerAggregation]);
  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search Collections"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <AdditionalFiltersCmp filterHandlers={filterHandlers} filterValues={filterValues} />
          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues?.('access_level')}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues?.('owner_id')}
          />

          <FilterSwitch
            label="Show Main Collection only"
            name="sublist"
            disabled={searchStore.running}
            filter={filterValues.sublist}
            onChange={filterHandlers.onChange}
            notCheckedValue={true}
            checkedValue={false}
          />
        </form>
      </div>
    </div>
  );
});
