import React, { useCallback, useMemo } from 'react';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';
import { IAsset, ISubtitle } from 'types';
import { ISubtitleActionName } from 'utils/actions/subtitle/types';

import { getSubtitlesTableColumns } from './utils';

import './style.scss';

export interface IAssetOldSubtitlesTabProps {
  asset: IAsset;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
}

export const AssetOldSubtitlesTab: React.FC<IAssetOldSubtitlesTabProps> = ({ asset, setAsset }) => {
  const { subtitles, id } = asset || {};

  const onSuccess = useCallback(
    (action, subtitle: ISubtitle) => {
      switch (action) {
        case ISubtitleActionName.DELETE:
          setAsset((asset) => ({ ...asset, subtitles: asset?.subtitles?.filter((sub) => sub.id !== subtitle?.id) }));
          break;
        case ISubtitleActionName.DOWNLOAD:
          window.open(subtitle as unknown as string, '_self');
          break;
        case ISubtitleActionName.EDIT:
          setAsset((asset) => {
            return {
              ...asset,
              subtitles: asset?.subtitles?.map((sub) => {
                return sub.id === subtitle?.id ? { ...sub, ...subtitle } : sub;
              }),
            };
          });
        case ISubtitleActionName.REPLACE:
          setAsset((asset) => {
            const assetSubs = [...(asset?.subtitles || [])];
            const updatedSubIndex = assetSubs.findIndex((sub) => sub.id === subtitle?.id);
            if (!updatedSubIndex) return asset;
            assetSubs[updatedSubIndex] = subtitle;
            return {
              ...asset,
              subtitles: assetSubs,
            };
          });
          break;
      }
    },
    [setAsset],
  );

  const columns = useMemo(() => getSubtitlesTableColumns(onSuccess, id), [onSuccess, id]);

  if (!subtitles) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable data={subtitles} columns={columns} tableClassName="asset-subtitles__table" />
    </div>
  );
};
