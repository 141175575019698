import React, { useState } from 'react';
import { filter } from 'lodash';
import { Cross, FloppyDisk, Trash } from 'blueprint5-icons';
import { ActionIcon, Button } from '@mantine/core';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { getRootStore, useStore } from 'store/index';
import { IMeetingPropsFilters } from './calendar';

import { ToastError } from 'components/toast';
import { chipmunk } from 'utils/chipmunk';
import { MantineIcon } from 'utils/ui/icon';
import { FormInput, FormSelect } from 'helpers/form';

interface IProps {
  filters: IMeetingPropsFilters;
  onChange: (filters: IMeetingPropsFilters) => void;
}

interface ISavedFilter {
  id: number;
  name: string;
  filters: IMeetingPropsFilters;
}

const filtersToString = (filters: IMeetingPropsFilters): string => {
  const keys = Object.keys(filters)
    .filter((key) => Boolean(filters[key]))
    .sort();

  if (!keys.length) return '';

  const resultSetArr: [string, IMeetingPropsFilters][] = [];
  for (const key of keys) resultSetArr.push([key, filters[key]]);
  return JSON.stringify(resultSetArr);
};

const objectToSavedFilter = (object?: IObject | undefined): ISavedFilter | undefined => {
  if (!(object instanceof Object)) return;
  let { description } = object;
  const { id, search_params } = object;
  if (!Number.isInteger(id) || id <= 0) return;
  if (typeof description !== 'string') return;
  if (!(description = description.trim()).length) return;
  if (!(search_params instanceof Object)) return;
  if (!Object.keys(search_params).length) return;
  return { id, name: description, filters: search_params };
};

const loadSchedulerFilters = async (): Promise<ISavedFilter[]> => {
  let objects: IObject[] | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      objects = (
        await chipmunk.action('um.saved_search', 'query', {
          params: {
            target: 'scheduler-filters',
          },
        })
      ).objects;
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }

    if (!(objects instanceof Array)) objects = [];

    const result: ISavedFilter[] = [];

    for (const object of objects) {
      const savedFilter = objectToSavedFilter(object);
      if (!savedFilter) continue;
      result.push(savedFilter);
    }

    return result;
  });
};

const saveSchedulerFilter = async (name: string, filters: IMeetingPropsFilters): Promise<ISavedFilter | undefined> => {
  let result: ISavedFilter | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result = objectToSavedFilter(
        (
          await chipmunk.action('um.saved_search', 'create', {
            body: {
              description: name,
              target: 'scheduler-filters',
              search_params: filters,
            },
          })
        )?.object,
      );
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }
    return result;
  });
};

const updateSchedulerFilter = async (
  filterItem: ISavedFilter,
  newFilters: IMeetingPropsFilters,
): Promise<ISavedFilter | undefined> => {
  let result: ISavedFilter | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result = objectToSavedFilter(
        (
          await chipmunk.action('um.saved_search', 'update', {
            body: {
              description: filterItem.name,
              target: 'scheduler-filters',
              search_params: newFilters,
            },
            params: { id: filterItem.id },
          })
        )?.object,
      );
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong" />);
    }
    return result;
  });
};

const removeSchedulerFilter = async (id: number): Promise<boolean> => {
  let result = false;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result =
        (
          await chipmunk.action('um.saved_search', 'delete', {
            body: { id },
          })
        )?.object?.id === id;
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }
    return result;
  });
};

const FilterPresetSelector: React.FC<IProps> = (props: IProps) => {
  const { filters, onChange } = props;

  const isEmptyFilter = !filtersToString(filters);

  const { toastStore } = useStore();
  const [busy, setBusy] = useState(true);
  const [presetName, setPresetName] = useState('');
  const [savedFilters, setSavedFilters] = useState<undefined | ISavedFilter[]>(undefined);

  const matchingFilter = ((): ISavedFilter | undefined => {
    if (!savedFilters) return undefined;

    const propsFiltersStr = filtersToString(filters);
    const foundFilter = savedFilters.find((savedFilter) => filtersToString(savedFilter.filters) === propsFiltersStr);

    return foundFilter;
  })();

  const matchingFilterName = (filterName: string): ISavedFilter | undefined =>
    savedFilters?.find((filter) => filter.name === filterName);

  React.useEffect(() => {
    loadSchedulerFilters().then((filters) => {
      setSavedFilters(filters);
      setPresetName('');
      setBusy(false);
    });
  }, []);

  const setFilterName = (id: string): void => {
    const filters = savedFilters?.find((savedFilter) => savedFilter.id === parseInt(id, 10));

    if (filters) onChange(filters.filters);
  };

  const removeFilter = async (): Promise<void> => {
    if (!matchingFilter) return;
    setBusy(true);
    if (await removeSchedulerFilter(matchingFilter.id)) {
      toastStore.success(`Preset "${matchingFilter.name}" removed`);
      setSavedFilters(savedFilters?.filter((filter) => filter.id !== matchingFilter.id));
      setBusy(false);
    } else {
      setBusy(false);
    }
  };

  const saveFilter = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (isEmptyFilter) return;
    if (matchingFilter) return;
    const cleanFilterName = presetName.trim();
    if (!cleanFilterName) return;
    const filterToUpdate = matchingFilterName(cleanFilterName);
    setBusy(true);
    const newItem = filterToUpdate
      ? await updateSchedulerFilter(filterToUpdate, filters)
      : await saveSchedulerFilter(cleanFilterName, filters);

    if (newItem) {
      toastStore.success(`New preset "${cleanFilterName}" saved`);
      setSavedFilters([...(filter(savedFilters, (filter) => filter.id !== newItem.id) || []), newItem]);
      setPresetName('');
      setBusy(false);
    } else {
      setBusy(false);
    }
  };

  return (
    <div className="filter-preset-selector-wrapper-outer">
      <Button
        className="filter-preset-selector__clear-btn"
        onClick={() => onChange({})}
        disabled={isEmptyFilter}
        leftSection={<MantineIcon icon={<Cross />} />}
        variant="subtle"
        size="xs"
      >
        Clear All
      </Button>
      <div className="filter-preset-selector-wrapper">
        {!matchingFilter && !isEmptyFilter ? (
          <>
            <form onSubmit={saveFilter}>
              <FormInput
                name="presetName"
                label="Filter Presets"
                placeholder="Type Preset Name"
                onChange={(newValue) => setPresetName(String(newValue['presetName']))}
                value={presetName}
                disabled={busy}
                size="xs"
              />
            </form>
            <ActionIcon
              onClick={saveFilter}
              disabled={!presetName.trim().length || busy}
              className="filter-preset-selector__save-btn"
              loading={busy}
              variant="subtle"
              color="gray.5"
            >
              <MantineIcon icon={<FloppyDisk />} />
            </ActionIcon>
          </>
        ) : (
          <div className="meetings-filter__preset">
            <FormSelect
              name="preset"
              label="Filter presets"
              placeholder="Select preset"
              value={matchingFilter?.id}
              options={savedFilters?.map((savedFilter) => ({ value: savedFilter.id, label: savedFilter.name }))}
              disabled={!savedFilters?.length || busy}
              onChange={(newValue) => setFilterName(String(newValue['preset']))}
              size="xs"
            />
            <ActionIcon
              type="submit"
              disabled={!matchingFilter || busy}
              className="filter-preset-selector__delete-btn"
              loading={busy}
              onClick={removeFilter}
              variant="subtle"
              color="gray.5"
            >
              <MantineIcon icon={<Trash />} />
            </ActionIcon>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterPresetSelector;
