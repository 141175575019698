export const productPageViews: Record<string, string> = {
  productCount: 'v3/events/products/count_by_uniq_views',
  topProducts: 'v3/events/products/top_products',
  topViewers: 'v3/events/products/top_viewers',
  productList: 'v3/events/products/views_list',
  productListExport: 'v3/events/products/views_list_export',
};

export const videoViews: Record<string, string> = {
  screeningCount: 'v3/events/screenings/count_by_uniq_screenings',
  screeningList: 'v3/events/screenings/screenings_list',
  screeningTopVideos: 'v3/events/screenings/top_videos',
  screeningTopViewers: 'v3/events/screenings/top_viewers',
  screeningListExport: 'v3/events/screenings/screenings_list_export',
};

export const attendance: Record<string, string> = {
  attendanceCount: 'v3/events/attendance/count_by_uniq_visitors',
  attendanceCountDuration: 'v3/events/attendance/count_by_duration',
  attendanceCountGroups: 'v3/events/attendance/count_by_groups',
  attendanceAvgDuration: 'v3/events/attendance/average_duration',
  attendanceVisitsList: 'v3/events/attendance/visits_list',
  attendanceVisitsListExport: 'v3/events/attendance/visits_list_export',
};
