import React, { useCallback, useState } from 'react';
import { omit } from 'lodash';

import { useStore } from 'store';
import { FormSubmitSection } from 'components/form-submit-section';
import { SubtitleFormCommonParts } from 'components/asset/subtitle-form';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAudioTrack, IFile } from 'types';

type IUploadAudioForm = Omit<IAudioTrack, 'language'>;

export interface IEditAudioProps {
  onSubmit: (audio: IUploadAudioForm, file?: IFile | null) => void;
  audio?: IAudioTrack;
}

const EditAudioDialogBody: React.FC<IEditAudioProps> = ({ audio, onSubmit }) => {
  const { dialogStore, toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = omit(audio, ['language']);
  const { formData, handlers, valid, values } = useForm<IUploadAudioForm>(initialValues, Model.AUDIO_TRACKS);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        e.preventDefault();
        await onSubmit(values);
        toastStore.success('Updates applied successfully!');
        return dialogStore.close();
      } catch (error) {
        toastStore.error(error.text);
        return dialogStore.close();
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, values, toastStore, dialogStore],
  );

  return (
    <form onSubmit={handleSubmit}>
      <SubtitleFormCommonParts formData={formData} handlers={handlers} />
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!valid} loading={isLoading} />
    </form>
  );
};

export default EditAudioDialogBody;
