import React from 'react';
import { IOrganization, UserStatus } from 'types';
import { Avatar, IAvatar } from 'components/avatar/avatar';

export interface IUserAvatarProps extends IAvatar {
  organization?: IOrganization;
  showTag?: boolean;
  offset?: number;
  indicatorSize?: number;
}

export const OrganizationLogo: React.FC<IUserAvatarProps> = (props) => {
  const { organization, offset, indicatorSize, showTag = false, ...rest } = props;

  if (!organization) return null;

  const { name, logo } = organization;
  const tag = rest.size === 'xs' || !showTag ? undefined : organization.classification;

  return (
    <Avatar
      tag={tag}
      {...rest}
      offset={offset}
      indicatorSize={indicatorSize}
      image={logo}
      text={name}
      status={organization?.status as UserStatus}
    />
  );
};
