import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Popover } from '@mantine/core';
import cx from 'classnames';

import { useChangeAssetStatusDialog } from './change-asset-status-modal';

import { isEntityDeleted } from 'utils/is-entity-deleted';
import { AssetStatuses, IMm3Asset, IAsset } from 'types';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { MfxIcon } from 'icons/index';

interface IAssetIndicatorProps {
  asset: IAsset | IMm3Asset;
  className?: string;
  padded?: boolean;
  setAsset?: React.Dispatch<React.SetStateAction<IAsset>>;
}

const PopoverContent: React.FC<{ isOffline: boolean; status?: string }> = ({ isOffline, status }) => {
  return isOffline ? (
    <div className="p-2 d-flex flex-column">
      <span className="fw-bold">{`Status: ${status}`}</span>
      <span>
        Asset is in long-term storage. It can still be previewed and streamed, <br /> but not downloaded unless
        unarchived.
      </span>
    </div>
  ) : (
    <div className="p-2 d-flex flex-column">
      <span className="fw-bold">{`Status: ${status}`}</span>
      <span>Asset is not displayed on the client website</span>
    </div>
  );
};

export const AssetStatusIndicator: React.FC<IAssetIndicatorProps> = ({ asset, setAsset, className, padded = true }) => {
  const { status } = asset || {};
  const [opened, { close, open }] = useDisclosure(false);
  const isOffline = status === AssetStatuses.OFFLINE;
  const showIndicator = status !== AssetStatuses.AVAILABLE || isOffline;
  const openDialog = useChangeAssetStatusDialog(asset, setAsset);
  const isNotDeleted = !isEntityDeleted(asset);

  return status && showIndicator ? (
    <Popover opened={opened}>
      <Popover.Target>
        {isOffline ? (
          <span onMouseEnter={open} onMouseLeave={close} className={cx({ 'px-1': padded }, className)}>
            <MfxIcon icon="snowflake" />
          </span>
        ) : (
          <span
            onMouseEnter={open}
            onMouseLeave={close}
            className={cx(
              Classes.TEXT_MUTED,
              { 'px-2': padded, 'pointer-action': isNotDeleted && !isOffline },
              className,
            )}
          >
            <MantineIcon icon="eye-off" onClick={isNotDeleted ? openDialog : undefined} />
          </span>
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <PopoverContent isOffline={isOffline} status={status} />
      </Popover.Dropdown>
    </Popover>
  ) : null;
};
