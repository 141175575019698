import React from 'react';
import { get } from 'lodash';
import { ScrollWrapper } from 'components/scroll-wrapper';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IFieldData, FieldListV2, FormSelect, FormInput, IUseFormReturn, FormFileInput } from 'helpers/form';
import { IOrganizationFields } from 'pages/upsert-organization/types';
import { extensionsByClassification } from 'utils/actions/asset/upload-asset-action';

import './style.scss';

export function FormDeliveryInformation(props): React.ReactElement {
  const { formData, name, handlers, large } = props;

  return (
    <>
      <FormSelect
        name={`${name}.label`}
        label="Label"
        {...(get(formData, `${name}.label`) as IFieldData<string>)}
        {...handlers}
        large={large}
        options={['Delivery Specifications', 'File Naming Information']}
        emptyValueIsUndefined
      />

      <FormInput
        className="spec_type"
        name={`${name}.spec_type`}
        key="spec_type"
        label="Spec Type"
        {...(get(formData, `${name}.spec_type`) as IFieldData<string>)}
        {...handlers}
      />

      <FormInput
        name={`${name}.page_url`}
        label="Page Url"
        {...(get(formData, `${name}.page_url`) as IFieldData<string>)}
        {...handlers}
        disabled={!!get(formData, `${name}.document`)?.value}
      />

      <FormFileInput
        name={`${name}.document`}
        label="Document"
        {...(get(formData, `${name}.document`) as IFieldData<string>)}
        {...handlers}
        disabled={!!get(formData, `${name}.page_url`)?.value}
        type="attachment"
        extensions={extensionsByClassification.document}
      />
    </>
  );
}

export const OrganizationDeliveryInformationEditTab: React.FC<IUseFormReturn<IOrganizationFields>> = ({
  formData,
  handlers,
  values,
}) => {
  return (
    <ScrollWrapper>
      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" withBorder padded={false}>
        <FieldListV2
          label="Delivery Information"
          prefix="delivery_informations"
          component={FormDeliveryInformation}
          fieldProps={() => ({ large: true, formData: formData, handlers: handlers })}
          formValues={values}
          formHandlers={handlers}
          limit={20}
          defaultOrder="desc"
        />
      </FormSectionWrapperV2>
    </ScrollWrapper>
  );
};

export default OrganizationDeliveryInformationEditTab;
