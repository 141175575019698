import React, { useMemo } from 'react';

import ContactDataSection from 'components/contact-data-section';

import { Pages, IUseActionsOptionParam } from 'utils/actions';
import { ItemId } from 'types';
import { IFilterOption } from 'utils/hooks';

const GroupDetailContactsTab: React.FC<{
  groupId: ItemId;
  page: Pages;
  options?: Partial<IUseActionsOptionParam>;
}> = ({ groupId, page, options }) => {
  const persistentFilters = useMemo<IFilterOption[]>(() => (groupId ? [['group_ids', 'eq', groupId]] : []), [groupId]);

  return <ContactDataSection page={page} actionsOptions={options} persistentFilters={persistentFilters} />;
};

export default GroupDetailContactsTab;
