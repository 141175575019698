import { chipmunk } from 'utils/chipmunk';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { ItemId, IQueryParams, IExtendedSearchFilter } from 'types';
import { Model } from 'helpers/filters/types';
import { castCrewSchema } from 'utils/schemas/cast-crew';
import { parseToSearchParams } from 'utils/general';

export const getCastCrew = (ids?: null | ItemId | ItemId[], schema = castCrewSchema): Promise<PmGenericCastCrew> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<PmGenericCastCrew>(Model.CAST_CREW, 'get', {
      params: {
        cast_crew_ids: ids,
      },
      schema,
    });
    return object;
  });
};

export const deleteCastCrew = (cast_crew_ids?: null | ItemId | ItemId[]): Promise<PmGenericCastCrew> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<PmGenericCastCrew>(Model.CAST_CREW, 'destroy', {
      params: { cast_crew_ids },
    });

    return objects;
  });
};

export const updateCastCrew = async (
  values?: Partial<PmGenericCastCrew> & Pick<PmGenericCastCrew, 'id'>,
  schema = castCrewSchema,
): Promise<PmGenericCastCrew | undefined> => {
  if (!values?.id) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action<PmGenericCastCrew>(Model.CAST_CREW, 'update', {
      params: {
        cast_crew_ids: values.id,
      },
      body: values,
      schema,
    });

    return object;
  });
};

export const createCastCrew = async (
  body: Partial<PmGenericCastCrew>,
  schema = castCrewSchema,
): Promise<PmGenericCastCrew> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<PmGenericCastCrew>(Model.CAST_CREW, 'create', {
      body,
      schema,
    });

    return object;
  });
};

export const fetchCastCrew = async (
  params: IQueryParams,
  additionalFilters?: IExtendedSearchFilter[],
  action: 'action' | 'unfurl' = 'action',
  schema = castCrewSchema,
): Promise<PmGenericCastCrew[]> => {
  return chipmunk.run(async (cp) => {
    const { objects } = await cp[action]<PmGenericCastCrew>(Model.CAST_CREW, 'search', {
      body: parseToSearchParams(params, additionalFilters),
      schema,
    });

    return objects;
  });
};
