import { Model } from 'helpers/filters/types';
import { IOrganization } from 'types';
import { chipmunk } from 'utils/chipmunk';

import { IOrganizationFields } from './types';
import { prepareOrganizationPayload } from './utils';

export function saveOrganization(fields: IOrganizationFields): Promise<IOrganization> {
  const payload = prepareOrganizationPayload(fields);

  return createOrganization(payload);
}

async function createOrganization(body: IOrganization): Promise<IOrganization> {
  return await chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.ORGANIZATIONS, 'create', {
      ROR: true,
      body,
    });

    return object;
  });
}
