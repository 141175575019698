import React from 'react';

import { useConferenceActions } from 'utils/actions';
import { IConference } from 'types';
import { renderMenuActionItems } from 'utils/ui';

import { IItemActionsMenuProps } from './utils';

export const ConferenceActionsList: React.FC<IItemActionsMenuProps<IConference>> = (props) => {
  const { items, options } = props;

  const actions = useConferenceActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
