export const WorkflowSchema = `
  id,
  full_id,
  status,
  progress,
  workflow,
  tags,
  result,
  owner,
  queue,
  next_run_at,
  created_at
`;
