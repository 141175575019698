import React from 'react';
import cx from 'classnames';

import { ISectionHeaderAction } from 'components/section-header';
import { Tab, TabPanel } from 'components/tabs';
import { IUseFormReturn } from 'helpers/form';
import { DetailsPageTabs, IGroup } from 'types';

export type IScreeningRoomEditForm = Pick<IGroup, 'name' | 'description'>;

export const getHeaderActions = (
  editModeEnabled: boolean,
  setEditModeEnabled: (edit: boolean) => void,
  form: IUseFormReturn<IScreeningRoomEditForm>,
): ISectionHeaderAction[] => {
  const headerActions: ISectionHeaderAction[] = [];
  if (editModeEnabled) {
    headerActions.push({
      type: 'save',
      handler: form.onSubmit,
      disabled: !form.valid,
    });
    headerActions.push({
      type: 'cancel',
      handler: () => {
        setEditModeEnabled(false);
        form.resetFields();
      },
    });
  } else {
    headerActions.push({
      type: 'edit',
      handler: () => {
        setEditModeEnabled(true);
      },
    });
  }
  return headerActions;
};

export const ScreeningDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  hidden?: boolean;
  disabled?: boolean;
}> = ({ value, activeTab, hidden = false, disabled }) => {
  return (
    <Tab
      value={value}
      className={cx(`entity-detail__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={disabled}
      hidden={hidden}
    />
  );
};

export const CollectionTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="entity-detail__tab-content h-100" content={content} />;
};
