import { chipmunk, IResult } from 'utils/chipmunk';
import type { IAccessPrivilege } from 'components/access-privilege-grant';
import {
  addProductToGroup,
  addParentProductsToGroup,
  addDifferentContactEntitiesToGroup,
  addOrganizationsToGroup,
  loadGroupsUsers,
  loadGroupsOrganizationsUsers,
} from 'utils/apis/groups';
import { ItemId, GroupTypes } from 'types';
import { Model } from 'helpers/filters/types';

export const createAccessPrivilege = async (values: IAccessPrivilege): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const {
      permit_download,
      delegates_access,
      include_descendants,
      include_future_descendants,
      organization_ids,
      product_ids,
      contacts,
      email_subject,
      email_message,
      ...formData
    } = values;
    const result = await action(Model.GROUPS, 'create', {
      body: {
        type: GroupTypes.ACCESS_PRIVILEGE,
        ...formData,
      },
    });
    const { object: group } = result;
    const group_id = group.id;

    const productsPromise = addProductToGroup({
      group_id,
      item_ids: product_ids,
      delegates_access,
      include_descendants,
      include_future_descendants,
      permissions: permit_download ? ['download'] : [],
    });

    const parentProducts = addParentProductsToGroup({ group_id, item_ids: product_ids });

    const contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });

    const organizationsPromise = addOrganizationsToGroup({ group_id, item_ids: organization_ids });

    await Promise.all([productsPromise, parentProducts, contactsPromise, organizationsPromise]);

    if (email_subject.length > 0 && email_message.length > 0) {
      const apUserIds = await loadAccessPrivilegeAllUserIds(group_id);
      await sendAccessPrivilegeEmails({
        group_id,
        user_ids: apUserIds,
        subject: email_subject,
        message: email_message,
      });
    }

    return group;
  });
};

interface ISendAccessPrivilegeEmails {
  group_id: ItemId;
  user_ids: ItemId[];
  subject?: string;
  message?: string;
}

export const loadAccessPrivilegeAllUserIds = async (group_id: ItemId): Promise<ItemId[]> => {
  const users = await loadGroupsUsers([group_id]);
  const organizations_users = await loadGroupsOrganizationsUsers([group_id]);

  return [...users.map(({ id }) => id), ...organizations_users.map(({ id }) => id)];
};

export const sendAccessPrivilegeEmails = async (props: ISendAccessPrivilegeEmails): Promise<void> => {
  const { user_ids, subject = '', message = '' } = props;

  if (!user_ids.length || !subject.length || !message.length) {
    return;
  }

  return chipmunk.run(({ action }) =>
    action('pm.group_products_list_notification', 'notify', {
      body: { group_id: props.group_id, recipients: user_ids, subject, message },
    }),
  );
};
