import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { Page } from 'components/layout';
import { RecommendProductsWizardContainer } from 'components/recommendation/recommend-products/recommend-products-wizard-container';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';
import { RecommendAssetsWizardContainer } from 'components/recommendation/recommend-assets/recommend-assets-wizard-container';

const RecommendationCreate: React.FC<{ type: 'asset' | 'product' }> = observer(({ type }) => {
  const navigate = useNavigate();
  const onFinish = useCallback(
    ({ id }: Pick<McGenericRecommendationProduct, 'id'>): void => {
      navigate(`${Routes.RECOMMENDATIONS}/${id}`);
    },
    [navigate],
  );
  const RecommendationWizard = type === 'asset' ? RecommendAssetsWizardContainer : RecommendProductsWizardContainer;

  return (
    <Page title="Create New Recommendation">
      <RecommendationWizard onFinish={onFinish} />
    </Page>
  );
});

export default RecommendationCreate;
