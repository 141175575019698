import React, { useCallback, useMemo } from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { IWebsitePageValues, IAccessLevels, IListItem } from 'types';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IUseActionsContext, Pages } from 'utils/actions';
import { updateList } from 'utils/apis/showcase';
import { useEditShowcaseAction } from 'utils/actions/showcase/edit';
import { Loading } from 'components/loading';
import { ShowcaseActionsList } from 'components/action';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { EmptySectionMessage } from 'components/section-message';

import ItemsPreviewSection from './items-preview-section';
import { IActionName } from 'utils/actions/types';
import { IExtendedGenericList, useItemToPreview } from './utils';

import './style.scss';
import { useDataSectionStore } from 'store/hooks';
import { ActionsMenu } from 'components/action/utils';

export const WebsitePreviewTab: React.FC<{
  section: IWebsitePageValues;
  isSortModeEnabled: boolean;
  onSuccess: (action?: IActionName, showcase?: IExtendedGenericList, items?: IListItem[]) => void;
}> = observer(({ section, isSortModeEnabled, onSuccess }) => {
  const { loading, showcase } = useItemToPreview(section);
  const { searchStore } = useDataSectionStore();
  const { running = true } = searchStore || {};
  const { id, name, access_level, owner, listItems, itemsCount } = showcase || {};

  const updateAccessLevel = useCallback(
    async (newAccessLevel: IAccessLevels): Promise<boolean> => {
      return changeAccessLevelSlider({
        apiCall: ({ access_level }) => updateList({ id, access_level } as UmGenericListShowcase),
        divisionIds: owner?.division_ids,
        newAccessLevel,
      });
    },
    [owner, id],
  );
  const options = useMemo(
    () => ({
      context: 'single' as IUseActionsContext,
      page: Pages.DETAILS,
      items: listItems,
      section,
      onSuccess,
    }),
    [listItems, onSuccess, section],
  );

  const actions: ISectionHeaderAction[] = [
    { type: 'edit', handler: useEditShowcaseAction(showcase ? [showcase] : [], options).handler, disabled: !id },
  ];

  const previewSection = useMemo(() => {
    if (loading || running) {
      return <Loading text="Loading Data" />;
    }
    if (itemsCount === 0 || !listItems?.length) return <EmptySectionMessage />;
    return <ItemsPreviewSection items={listItems} />;
  }, [itemsCount, listItems, loading, running]);

  return (
    <div className="website-data-section-preview">
      <SectionHeader
        useBorder
        title={name}
        onAccessLevelChange={updateAccessLevel}
        accessLevel={access_level}
        actions={actions}
        isSortModeEnabled={isSortModeEnabled}
        useBackground
        dropdownMenu={<ActionsMenu component={ShowcaseActionsList} items={[showcase]} options={{ ...options }} />}
      />
      {previewSection}
    </div>
  );
});

export default React.memo(WebsitePreviewTab);
