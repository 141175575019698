import { useCallback } from 'react';

import { IAsset, IMm3Asset } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayUnarchive as shouldDisplay } from './actions-acl';

import { unarchiveAssets } from './api';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { getValidIds, pluralEntityInflect } from 'utils/general';

export const useUnarchiveAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const { toastStore } = useStore();
  const { entity, entityWithCount } = pluralEntityInflect('Asset', items.length);

  const handler = useCallback(async () => {
    try {
      await unarchiveAssets(getValidIds(items));
      toastStore.success(`${entityWithCount} unarchived successfully`);
      await options?.onSuccess?.();
      return true;
    } catch (error) {
      toastStore.error(`${entity} unarchive failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  return {
    name: IAssetActionName.UNANRCHIVE,
    shouldDisplay,
    icon: 'unarchive',
    title: 'Unarchive',
    handler,
  };
};
