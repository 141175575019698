import React, { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';
import { Globals } from 'types';
import { toggleProfileSettings } from 'utils/toggle-profile-settings';
import { useProfileStore } from 'store';
import { MantineIcon } from 'utils/ui/icon';
import { PanelStats } from 'blueprint5-icons';

export interface IDataSectionToggleButton {
  disabled?: boolean;
}

const DataSectionToggleButton: React.FC<IDataSectionToggleButton> = ({ disabled }) => {
  const isDataSectionSidebarOpen = useProfileStore(({ settings }) => settings.isDataSectionSidebarOpen);

  const handleSideSectionToggle = useCallback((): void => {
    toggleProfileSettings(
      'isDataSectionSidebarOpen',
      Globals.DATASECTION_SIDEBAR_COLLAPSED_LS,
      Globals.DATASECTION_SIDEBAR_COLLAPSED_CLASS,
    );
  }, []);

  return (
    <ActionIcon
      data-disabled={disabled}
      disabled={disabled}
      variant={isDataSectionSidebarOpen ? 'filled' : 'subtle'}
      data-active={isDataSectionSidebarOpen}
      color="gray.7"
      radius="sm"
      onClick={handleSideSectionToggle}
    >
      <MantineIcon icon={<PanelStats />} />
    </ActionIcon>
  );
};

export default DataSectionToggleButton;
