import React, { useCallback } from 'react';
import { map } from 'lodash';

import { IOrganization, ItemId } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IOrganizationActionName, IOrganizationActionConfig } from 'utils/actions/organization/types';

import { shouldDisplayRestoreAction as shouldDisplay } from './actions-acl';
import { restoreOrganization } from './api';

export const useRestoreAction = (
  items: IOrganization[],
  options: IUseActionsOptionParam,
): IOrganizationActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const organizationIds = map(items, 'id');
      await restoreOrganization(organizationIds as Array<ItemId>);
      toastStore.success(`${organizationIds.length} Organization${organizationIds.length > 1 ? 's' : ''} restored`);
      options?.onSuccess?.();
    } catch (error) {
      toastStore.error(
        <div>
          <div>{error.title}</div>
          <div>{error.description}</div>
        </div>,
      );
      options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: 'Confirm Restore',
      body: `Are you sure you want to restore ${items.length > 1 ? 'these organizations' : 'this organization'}?`,
      onConfirm,
    });
  };

  return {
    name: IOrganizationActionName.DELETE,
    shouldDisplay,
    icon: 'automatic-updates',
    title: 'Restore',
    handler,
  };
};
