import { Product3Types } from 'types/product';
import { last, startCase } from 'lodash';

export const formatType = (type?: string, separator = '::'): string => {
  switch (type) {
    case Product3Types.SERIES:
      return 'Title';
    case Product3Types.SEASON:
      return 'Series';
    case Product3Types.PROGRAM:
      return 'Film';

    default:
      return startCase(last(type?.split(separator)));
  }
};
