import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export function useQuery<T extends Record<string, string | null> = {}>(fields: (keyof T & string)[]): T {
  const { search } = useLocation();
  const fieldsRef = useRef(fields);
  const [queryParams, setQueryParams] = useState<T>({} as T);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    setQueryParams((queryParams) => {
      const fields = fieldsRef.current;
      const noChange = fields.every((field) => (searchParams.get(field) || '') === (queryParams[field] || ''));
      if (noChange) {
        return queryParams;
      }

      return fields.reduce((acc, key) => ({ ...acc, [key]: searchParams.get(key) || '' }), {} as T);
    });
  }, [search]);

  return queryParams;
}

export function buildPath(path: string, key: string | number): string {
  if (!path || (!key && key !== 0)) return path || key?.toString() || '';

  const separator = path.endsWith('/') ? '' : '/';

  return [path, key].join(separator);
}
