import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { flags } from 'utils/flags';

import { useDataSectionStore } from 'store';
import { Routes } from 'utils/routes';

import { Row, yn } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';

import { formatDate } from 'utils/date';
import { getRecommendationStatus } from 'components/recommendation/utils';

import { DetailsPageTabs, IProduct, ItemId } from 'types';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { Thumbnail } from 'components/thumbnail';
import { isAssetRecommendation } from 'utils/recommendation';
import { loadRecommendationThumbnails } from 'utils/apis/recommendation';
import { formatOwnership } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { DetailCountLink } from 'utils/ui';
import { formatType } from 'utils/format-type';
import { ProductsWithFutureEpisodes } from 'pages/recommendation-details/utils';
import { getProducts } from 'utils/apis/product';

const { showFutureEpisodesFeature } = flags;

export const RecommendationPreview: React.FC<{}> = observer(() => {
  const { activeItem: recommendation } = useDataSectionStore<McGenericRecommendationProduct>();

  const {
    subject,
    id,
    created_at,
    expires_at,
    sent_at,
    updated_at,
    recipient_list,
    product_ids,
    asset_ids,
    requires_login,
    allowed_views,
    generate_links_only,
    type,
    allows_asset_download,
    include_future_episodes_of_product_ids,
    visible,
  } = recommendation || {};

  const fetchThumbnails = useCallback(() => {
    return loadRecommendationThumbnails(recommendation);
  }, [recommendation]);

  const [thumbnails = []] = useRemote(fetchThumbnails);

  const fetcher = useCallback(() => {
    if (!showFutureEpisodesFeature) return Promise.resolve([]);

    return getProducts({ product_ids: include_future_episodes_of_product_ids as ItemId[] }, 'id, display_title');
  }, [include_future_episodes_of_product_ids]);
  const [objects] = useRemote(fetcher);

  if (!recommendation) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }
  const detailsPageLink = `${Routes.RECOMMENDATIONS}/${id}`;
  return (
    <PreviewTab
      title={subject}
      detailsLink={`${detailsPageLink}/${DetailsPageTabs.RECIPIENTS}`}
      customThumbnail={
        thumbnails.length ? (
          <MemoizedMultiThumbnail
            height={172}
            width={308}
            className="entity-preview__thumb"
            thumbnailImages={thumbnails || [{}]}
            customTag={formatType(type)}
          />
        ) : (
          <Thumbnail assetType={type} showType image="" />
        )
      }
    >
      <PreviewInfo>
        <Row label="Status" value={getRecommendationStatus(recommendation)} size="m" />
        <Row label="Sent at" value={formatDate(sent_at)} rawLabel />
        <Row label="Expires at" value={formatDate(expires_at)} rawLabel />
        <Row label="Sender" value={formatOwnership(recommendation)} />
        <Row
          hidden={isAssetRecommendation(recommendation)}
          label="Products"
          value={
            <>
              <Link to={`../${DetailsPageTabs.PRODUCTS}`}>{product_ids?.length}</Link>
              <ProductsWithFutureEpisodes products={objects as IProduct[]} />
            </>
          }
          size="m"
        />
        <DetailCountLink
          entityCount={asset_ids?.length}
          link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
          label="Assets"
        />
        <DetailCountLink
          entityCount={recipient_list?.length}
          link={`${detailsPageLink}/${DetailsPageTabs.RECIPIENTS}`}
          label="Recipients"
        />
        <Row label="Requires login" rawLabel value={yn(requires_login)} />
        <Row
          label="List on website"
          value={yn(visible)}
          size="m"
          fallback="N/A"
          rawLabel
          hidden={!flags.showListRecommendationOnWebsiteFeature}
        />
        <Row label="Generate links" rawLabel value={yn(generate_links_only)} size="m" fallback="N/A" />
        <Row
          label="Download allowed"
          rawLabel
          value={yn(allows_asset_download)}
          hideIfNoValue
          hidden={type === 'Recommendation::Product'}
        />
        <Row label="Allowed views" rawLabel value={allowed_views} size="m" fallback="Unlimited" />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
      </PreviewInfo>
    </PreviewTab>
  );
});
