import React, { useState } from 'react';
import { VideoAsset, VideoPlayer } from '@mediafellows/videoplayer';

import { IAsset, IMm3Asset } from 'types';
import { getIngestStatus, getPreviewImageUrl, readyStatuses } from 'utils/asset';
import { ThumbnailFileStatus } from 'components/file-status';
import { ThumbnailEntity } from 'components/thumbnail';

import './style.scss';

interface IAssetVideoPlayer {
  video?: Pick<IAsset | IMm3Asset, 'classification' | 'preview_image' | 'file_status'>;
}

export const AssetVideoPlayer: React.FC<IAssetVideoPlayer> = (props) => {
  const { video } = props;
  const { classification, preview_image, file_status } = video || {};

  const isVideo = classification?.startsWith('video');
  const isFileReady = readyStatuses.has(file_status || '');

  const [showStatus, setShowStatus] = useState<boolean>(true);

  if (!isVideo) {
    return null;
  }

  if (!isFileReady) {
    return <ThumbnailEntity entity={video} />;
  }

  return (
    <div className="asset-video-player__wrapper">
      <div className="asset-video-player__status-wrapper">
        {showStatus && <ThumbnailFileStatus status={getIngestStatus(video as IAsset)} withBackground />}
      </div>

      <VideoPlayer
        className={'custom-carousel__video'}
        video={video as VideoAsset}
        autoplay={false}
        posterUrl={getPreviewImageUrl(preview_image)}
        onPlay={() => setShowStatus(false)}
      />
    </div>
  );
};
