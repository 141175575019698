import { each, get } from 'lodash';
import { observable, action, toJS, IObservableObject } from 'mobx';
import { IParametron, IParametronApi, IParametronData } from '@mediafellows/parametron';
import { IAggregation } from 'helpers/filters/types';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

interface IAggregations {
  [key: `count_by_${string}`]: IAggregation[];
}

export class ParametronStore<T = IObject> {
  @observable public page: number;
  @observable public sort: string;
  @observable public order: string;
  @observable public totalCount: number;
  @observable public totalPages: number;
  @observable public running = false;
  @observable public filters = [];
  @observable public persistentFilters = [];
  @observable public objects: T[] = [] as T[];
  @observable public aggregations: IAggregations;
  @observable public stats: string;
  @observable public reqId: number;
  @observable public paramsAndFiltersCount = 0;

  @observable public data: IObservableObject;

  public parametron: IParametron;

  constructor(parametron: IParametron) {
    this.parametron = parametron;
  }

  public get api(): IParametronApi {
    return this.parametron.api;
  }

  @action.bound
  public reset(): void {
    this.objects = [];
    this.aggregations = {};
  }

  @action.bound
  public update(data: Partial<IParametronData>, append = false): void {
    each(data, (value, key) => {
      if (append && key === 'objects') {
        this.objects = this.objects.concat(data.objects as T[]);
        return;
      }
      this[key] = value;
    });
  }

  public aggregationValues(attr: string): IAggregation[] {
    return get(toJS(this.aggregations), `count_by_${attr}`, [] as IAggregation[]);
  }

  @action.bound
  public updateParamsAndFiltersCount(paramsToCount: string[] = []): void {
    const { filters = [], params } = this.parametron.data;
    let counter = filters.length;

    paramsToCount.map((param) => {
      if (params[param]) {
        counter++;
      }
    });

    this.paramsAndFiltersCount = counter;
  }
}
