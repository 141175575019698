import React from 'react';
import {
  AmGenericNews,
  UmGenericListCollection,
  UmGenericListItem,
  UmGenericListShowcase,
} from '@mediafellows/mm3-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { isProduct } from 'utils/general';
import { deleteListItems, updateListItems } from 'utils/apis/showcase-item';
import { updateList } from 'utils/apis/showcase';
import { ProductSelectedTabItem } from 'components/product-selected-tab-item';
import { AssetSelectedTabItem } from 'components/asset/asset-selected-tab-item';
import { NewsSelectedItem } from 'components/news';
import { IAsset, ICategory, IContact, IGroup, IListItem, IOrganization, IProduct } from 'types';
import { CollectionSearchItem } from 'components/collection-search-item';
import { GroupSelectedItem } from 'components/group-search-item';
import { ContactSelectedTabItem } from 'components/contact-selected-tab-item';
import { CategorySelectedTabItem } from 'components/category-selected-tab-item';
import { OrganizationSelectedTabItem } from 'components/organization';
import { getPreviewImage, typeParser } from 'utils/list-item';
import { isSeparator } from 'utils/list';
import { IResult } from '@mediafellows/chipmunk';

export const itemRenderer = (item: IListItem): JSX.Element => {
  if (isSeparator(item)) {
    return <div>Separator: {item.meta?.title}</div>;
  }

  if (!item?.entity || item.isRemoved) {
    return <div>unavailable item {item?.id}</div>;
  }

  const { entity } = item;

  if (isProduct(entity as IProduct)) {
    return (
      <ProductSelectedTabItem
        product={{ ...entity, inherited_preview_image: getPreviewImage(item as IListItem, 'entity') } as IProduct}
      />
    );
  }

  const type = entity['@type'] || entity['$type'] || entity['type'] || '';
  if (type === 'am.news') {
    return <NewsSelectedItem news={entity as AmGenericNews} />;
  }
  if (type.toLowerCase().includes('asset')) {
    return <AssetSelectedTabItem asset={entity as IAsset} customParser={typeParser} />;
  }

  if ((entity as UmGenericListCollection).type === 'List::Collection') {
    return <CollectionSearchItem collection={entity as unknown as UmGenericListCollection} handleSelect={noop} />;
  }

  if (type.startsWith('group')) {
    return <GroupSelectedItem group={entity as IGroup} handleSelect={noop} key={item.id} />;
  }

  if (type.includes('user')) {
    return <ContactSelectedTabItem contact={entity as IContact} />;
  }

  if (type.includes('organization')) {
    return <OrganizationSelectedTabItem organization={entity as IOrganization} />;
  }
  if (type.includes('category')) {
    return <CategorySelectedTabItem category={entity as ICategory} />;
  }

  return <div>{item.id}</div>;
};

export const labels = {
  confirm: 'Save',
};

export const isShowcaseChanged = (
  { name, purpose, meta, allowed_item_types, access_level }: UmGenericListShowcase,
  showcase: UmGenericListShowcase,
): boolean => {
  if (!showcase?.meta) {
    return false;
  }

  return (
    name !== showcase.name ||
    purpose !== showcase.purpose ||
    meta?.page !== showcase.meta.page ||
    access_level !== showcase.access_level ||
    allowed_item_types !== showcase.allowed_item_types
  );
};

export function parseInitialValue({
  meta = {},
  preview_asset_id,
  id,
}: Partial<UmGenericListItem>): Partial<UmGenericListItem> {
  const parsedMeta = Object.entries(meta).reduce((acc, [key, value]) => ({ ...acc, [key]: value ?? undefined }), {});

  return { meta: parsedMeta, preview_asset_id, id } as Partial<UmGenericListItem>;
}

const removeEmptyMeta = ({ meta, ...rest }: Partial<UmGenericListItem>): Partial<UmGenericListItem> => {
  return isEmpty(meta) ? rest : { meta, ...rest };
};

export const saveShowcase = async (
  values: UmGenericListShowcase,
  list: IListItem[],
  showcase: UmGenericListShowcase,
  listWasReordered: boolean,
): Promise<unknown[]> => {
  if (!showcase?.id) {
    return [];
  }

  const promises: Promise<unknown>[] = [];
  if (isShowcaseChanged(values, showcase)) {
    promises.push(updateList(values));
  }

  const { listToUpdate, listToDelete } = list.reduce<{ listToDelete: number[]; listToUpdate: IListItem[] }>(
    (acc, { edited, isRemoved, sequence_number: _, ...e }) => {
      if ((edited || listWasReordered) && !isRemoved) {
        return { ...acc, listToUpdate: [...acc.listToUpdate, e] };
      }
      if (isRemoved && e.id) {
        return { ...acc, listToDelete: [...acc.listToDelete, e.id] };
      }
      return acc;
    },
    { listToDelete: [], listToUpdate: [] },
  );

  promises.push(
    updateListItems(
      [showcase.id],
      listToUpdate.map((item) => removeEmptyMeta(parseInitialValue(item as Partial<UmGenericListItem>))),
      listWasReordered,
    ),
  );

  promises.push(deleteListItems(showcase.id, listToDelete));

  return Promise.all(promises) as Promise<IResult[]>;
};
