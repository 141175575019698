import React from 'react';
import { observer } from 'mobx-react-lite';

import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { Model } from 'helpers/filters/types';

import { FilterButtonGroup } from 'helpers/filters/fields/filter-button-group';
import { withAssetVersion } from 'utils/asset';
import { FilterCheckbox, FilterSwitch, IFiltersHandlers } from 'helpers/filters';
import { useDataSectionStore } from 'store/hooks';
import { IAssetFilters } from './utils';

const mainClassificationProvider = new Mm3ContextDataProvider(Model.MM3_ASSETS, 'main_classification');
const typeProvider = new Mm3ContextDataProvider(Model.MM3_ASSETS, 'type');

interface IAssetFiltersProps {
  filterValues: IAssetFilters;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  withMainAssetsFilter?: boolean;
}

export const AssetOldTypeFilter: React.FC<IAssetFiltersProps> = observer(({ filterValues, filterHandlers }) => {
  const { searchStore } = useDataSectionStore();

  return (
    <FilterButtonGroup
      label="Type"
      name="main_classification"
      disabled={searchStore?.running}
      filter={filterValues.main_classification}
      optionsProvider={mainClassificationProvider}
      onChange={filterHandlers.onChange}
    />
  );
});

export const AssetMm3TypeFilter: React.FC<IAssetFiltersProps> = observer((props) => {
  const { filterValues, filterHandlers, withMainAssetsFilter } = props;
  const { searchStore } = useDataSectionStore();

  return (
    <>
      <FilterCheckbox
        label="Type"
        name="type"
        optionsProvider={typeProvider}
        filter={filterValues.type}
        onChange={filterHandlers.onChange}
        disabled={searchStore?.running}
        aggregations={searchStore?.aggregationValues('type')}
      />
      {withMainAssetsFilter && (
        <FilterSwitch
          label="Show main assets only"
          name="parent_id"
          filter={filterValues.parent_id}
          onChange={filterHandlers.onChange}
          checkedValue
          notCheckedValue={false}
        />
      )}
    </>
  );
});

export const AssetTypeFilter = withAssetVersion(AssetOldTypeFilter, AssetMm3TypeFilter);
