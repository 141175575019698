import React from 'react';
import { Button } from '@mantine/core';

import { IConference } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useAddAssetsAction } from 'utils/actions/conference/add-assets';
import { IActionName } from 'utils/actions/types';
import { MantineIcon } from 'utils/ui/icon';
import { useAddContactsAction } from 'utils/actions/conference/add-contacts';

interface IAddItemsToEventButtonProps {
  conference: IConference;
  onSuccess: (action: IActionName, conference: IConference) => void;
  disabled: boolean;
}

export const AddAssetsToConferenceButton: React.FC<IAddItemsToEventButtonProps> = ({
  conference,
  onSuccess,
  disabled,
}) => {
  const options: IUseActionsOptionParam = {
    onSuccess,
    context: 'single',
    page: Pages.DETAILS,
  };

  // replace this with action to add assets to conferences
  const { handler, icon, title } = useAddAssetsAction([conference], options);

  return (
    <Button size="xs" leftSection={<MantineIcon icon={icon} />} disabled={disabled} onClick={handler} variant="primary">
      {title}
    </Button>
  );
};

export const AddContactsToConferenceButton: React.FC<IAddItemsToEventButtonProps> = ({
  conference,
  onSuccess,
  disabled,
}) => {
  const options: IUseActionsOptionParam = {
    onSuccess,
    context: 'single',
    page: Pages.DETAILS,
  };

  const { handler, icon, title } = useAddContactsAction([conference], options);

  return (
    <Button size="xs" leftSection={<MantineIcon icon={icon} />} disabled={disabled} onClick={handler} variant="primary">
      {title}
    </Button>
  );
};
