import { useParams } from 'react-router-dom';
import { unassignProductAsset } from 'utils/apis/product-asset';
import { useCallback } from 'react';

import { IAsset, IMm3Asset, ItemId } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayUnassign as shouldDisplay } from './actions-acl';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export const useUnassignAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { productId } = useParams<{ productId: string }>();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Asset', items.length);

  const executeUnassign = useCallback(async () => {
    try {
      if (!productId) {
        throw new Error('Could not read product Id from URL!');
      }

      await unassignProductAsset(items.map(({ id }) => ({ asset_id: id as ItemId, product_id: productId })));
      if (options?.onSuccess) {
        setTimeout(options.onSuccess, 1000);
      }
      toastStore.success(`${entityWithCount} will be unassigned`);
    } catch (error) {
      toastStore.error(`${entity} Unassign failed: ${error.text || error}`);
      await options?.onFailure?.();
    }
  }, [productId, items, options, toastStore, entityWithCount, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Unassign',
      body: `Are you sure you want to unassign ${entityWithDemonstrative}?`,
      onConfirm: executeUnassign,
    });
  }, [dialogStore, entityWithDemonstrative, executeUnassign]);

  return {
    name: IAssetActionName.UNASSIGN,
    intent: Intent.DANGER,
    shouldDisplay,
    icon: 'cross',
    title: 'Unassign',
    handler,
  };
};
