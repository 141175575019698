import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { logger } from 'utils/logger';
import { getAttachmentsToDelete } from 'utils/attachment';

export const deleteAttachment = (s3Url: string): Promise<boolean> => {
  return chipmunk.run(async ({ action }) => {
    try {
      await action(Model.ATTACHMENT, 'delete', {
        params: {
          s3_url: s3Url,
        },
      });
      return true;
    } catch (error) {
      logger.error(error);
      return false;
    }
  });
};

export const deleteUnreferencedAttachments = async <T>(original: T, updated: T, keys: string[]): Promise<void> => {
  const attachmentsToDelete = getAttachmentsToDelete(original, updated, keys);
  const promises = attachmentsToDelete.map(deleteAttachment);
  await Promise.all(promises);
};
