import React from 'react';
import { map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';
import { Routes } from 'utils/routes';

import DateElement from 'components/date-list-element/date-list-element';
import { IAnalyticsTableItem } from 'types/analytics';
import { formatDuration } from 'utils/date';
import { formatAssetType } from 'utils/general';
import { AnalyticsContext, UserAgent } from 'utils/ui';

export const UserColumns: {
  title: string;
  renderer: (data: IAnalyticsTableItem) => React.ReactNode;
  visibleFromSize?: number;
}[] = [
  {
    title: 'Products',
    renderer: ({ product_ids_titles }) => {
      if (isEmpty(product_ids_titles)) return 'N/A';

      return map(product_ids_titles, (product, index) => {
        return (
          <Tooltip label={product[1]} position={'top'}>
            <div className="video-screenings-list-table__video-products text-truncate">
              <Link key={product[0] + index} to={`${Routes.PRODUCTS}/${product[0]}/overview`}>
                {product[1]}
              </Link>
            </div>
          </Tooltip>
        );
      });
    },
  },
  {
    title: 'Video title',
    renderer: ({ asset }) => (
      <Tooltip label={asset?.name} position={'top'}>
        <div className="video-screenings-list-table__video-title text-truncate">
          <Link to={`${Routes.ASSETS}/${asset?.id}`}>{asset?.name || 'N/A'}</Link>
        </div>
      </Tooltip>
    ),
  },
  { title: 'Video type', renderer: ({ asset }) => formatAssetType(asset?.classification) || 'N/A' },
  { title: 'Video duration', renderer: ({ asset }) => formatDuration(asset?.duration, 'seconds') },
  {
    title: 'Screening duration',
    renderer: ({ total_playback_duration }) => formatDuration(total_playback_duration, 'seconds'),
  },
  {
    title: 'Screening context',
    renderer: ({ group }) => <AnalyticsContext group={group} />,
  },
  { title: 'Device info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  {
    title: 'Screened at',
    renderer: ({ last_effective_at }) => <DateElement date={last_effective_at} />,
  },
];
