import React from 'react';

import { FormInput, FormSelect } from 'helpers/form';
import { IFormHandlers, IFormData } from 'helpers/form/types';

export interface IPhoneItemsProps<T> {
  index: number;
  formData: IFormData<T>;
  handlers: IFormHandlers<T>;
}

export function PhoneField<T>({ index, formData, handlers }: IPhoneItemsProps<T>): JSX.Element {
  return (
    <>
      <FormSelect
        name={`phones[${index}].label`}
        label="Label"
        large
        {...formData[`phones[${index}].label`]}
        {...handlers}
        withinPortal
      />
      <FormInput
        name={`phones[${index}].number`}
        label="Number"
        type="text"
        large
        {...formData[`phones[${index}].number`]}
        {...handlers}
        validation={{
          ...(formData[`phones[${index}].number`].validation || {}),
          errorMessage: 'Please enter a valid phone number',
        }}
      />
    </>
  );
}

// TODO: refactor others to make it consistent accross the app
// this is for consistency across other form fields which are prefixed with Form.
export { PhoneField as FormPhone };
