import React, { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';
import { ChevronDown, ChevronUp, Cross, Plus } from 'blueprint5-icons';

import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { IStyled } from 'types';

import cx from 'classnames';

import './style.scss';

interface IRemoveItemButtonProps {
  removeItem: (index: number) => void;
  index: number;
}

const RemoveItemButton: React.FC<IRemoveItemButtonProps> = ({ removeItem, index }) => {
  const handleRemoveItem = useCallback((): void => {
    removeItem(index);
  }, [removeItem, index]);

  return (
    <ActionIcon
      variant="subtle"
      color="gray.5"
      className="form-dynamic-fields__remove-button"
      onClick={handleRemoveItem}
    >
      <MantineIcon icon={<Cross />} />
    </ActionIcon>
  );
};

// const getNewItem = (items: number[]): number => (items.length ? last(items) : -1) + 1;

interface IFormDynamicFieldsProps extends IStyled {
  Item: ({}) => JSX.Element | null;
  values: number[];
  itemProps: (index: number) => Record<string, unknown>;
  onAddItem: () => void;
  onRemoveItem: (index: number) => void;
  label: string;
  limit?: number;
  moveItem?: (index: number, direction: 'up' | 'down') => void;
  className?: string;
}

export const FormDynamicFields: React.FC<IFormDynamicFieldsProps> = ({
  Item,
  itemProps,
  onAddItem,
  onRemoveItem,
  label,
  values,
  limit = 5,
  moveItem,
  className,
}) => {
  const removeItem = useCallback(
    (index: number): void => {
      onRemoveItem(index);
    },
    [onRemoveItem],
  );

  const handleItemSort = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      moveItem?.(parseInt(e.currentTarget.dataset.index, 10) || 0, e.currentTarget.dataset.direction);
    },
    [moveItem],
  );
  const withSortOption = Boolean(moveItem);

  return (
    <div className={cx('form-dynamic__wrapper', className)}>
      <div
        className={cx({
          'form-dynamic-fields__title': label,
          'd-none': !label,
        })}
      >
        <div className={`${Classes.TEXT_MUTED}`}>{label}</div>
        {limit > values?.length ? (
          <ActionIcon variant="subtle" color="gray.5" onClick={onAddItem}>
            <MantineIcon icon={<Plus />} />{' '}
          </ActionIcon>
        ) : null}
      </div>
      <div className="form-dynamic-fields__content">
        {values?.map((index: number) => {
          const ip = itemProps(index);

          if (ip.isRemoved) {
            return;
          }

          const formField = (
            <div className="form-dynamic-fields__item">
              <Item {...ip} />
            </div>
          );

          return (
            <React.Fragment key={index}>
              <RemoveItemButton index={index} removeItem={removeItem} />

              {withSortOption ? (
                <div key={index} className="form-sort-dynamic-fields__item">
                  <div className="d-flex flex-column">
                    <ActionIcon
                      disabled={index === 0}
                      data-index={index}
                      data-direction="up"
                      variant="subtle"
                      onClick={handleItemSort}
                    >
                      <MantineIcon icon={<ChevronUp />} />
                    </ActionIcon>
                    <ActionIcon
                      disabled={index === values?.length - 1}
                      data-index={index}
                      data-direction="down"
                      variant="subtle"
                      onClick={handleItemSort}
                    >
                      <MantineIcon icon={<ChevronDown />} />
                    </ActionIcon>
                  </div>
                  {formField}
                </div>
              ) : (
                formField
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(FormDynamicFields);
