import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IGroup } from 'types';

import { ShareBasketWrapper } from './share-basket-wrapper';
import { IUseActionsOptionParam } from 'utils/actions/types';

import type { IAddToGroupEntityType } from './types';
import { shareBasket } from 'utils/apis/lists';

interface IUseSelectionDialogProps {
  entityType: IAddToGroupEntityType;
  items?: Array<{ label: string; value: number | string }>;
  group?: IGroup;
  options?: IUseActionsOptionParam;
}

export const entityTypeToCallback: { [key in IAddToGroupEntityType] } = {
  users: shareBasket,
};

export const useShareBasketDialog = ({ entityType, items, group, options }: IUseSelectionDialogProps): [() => void] => {
  const { dialogStore, toastStore } = useStore();
  const groupTitle = 'Basket';

  const onConfirm = useCallback(
    async (values): Promise<void> => {
      try {
        const shareBasketWithContacts = entityTypeToCallback[entityType];
        await shareBasketWithContacts({
          ...values,
          basket_id: group?.id || values.group_id,
          recipient_id: values.item_ids,
        });

        toastStore.success(`${groupTitle} will be shared with ${values.item_ids.length} ${entityType}`);
        options?.onSuccess?.();
        dialogStore.close();
      } catch (error) {
        toastStore.error(
          <div>
            <div>{error.title}</div>
            <div>{error.description}</div>
          </div>,
        );
      }
    },
    [entityType, group?.id, toastStore, groupTitle, options, dialogStore],
  );

  const title = `Share ${groupTitle} with users`;

  const body = useCallback((): JSX.Element => {
    return (
      <ShareBasketWrapper
        items={items}
        group={group}
        onConfirm={onConfirm}
        entity={entityType}
        groupTitle={groupTitle}
      />
    );
  }, [items, group, onConfirm, entityType, groupTitle]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title,
      body,
    });
  };

  return [openDialog];
};
