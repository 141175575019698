import { IOrganization, IIdentifiable } from 'types';
import {
  IUseActionsOptionParam,
  IUseActionsResponse,
  useActions,
  dividerAction,
  useTitleAction,
  useGrantAccessPrivilegeAction,
} from 'utils/actions';

import { shouldDisplayGrantAccessPrivilege } from 'utils/actions/common/actions-acl';
import { useAddOrganizationsAction, organizationsToItems } from 'utils/actions/access-privilege';
import { shouldDisplayTitle } from 'utils/actions/organization/actions-acl';
import { IOrganizationActionName, IOrganizationActionConfig } from 'utils/actions/organization/types';
import { useDeleteAction } from 'utils/actions/organization/delete-action';
import { useRestoreAction } from 'utils/actions/organization/restore-action';
import { useEditAction } from 'utils/actions/organization/edit-action';
import { useRemoveFromGroupAction } from 'utils/actions/organization/remove-from-group-action';

export const useOrganizationActions = <T>(
  organizations: (IOrganization & IIdentifiable & T)[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IOrganizationActionName> => {
  const removeFromGroupAction = useRemoveFromGroupAction(organizations, options);
  const deleteAction = useDeleteAction(organizations, options);
  const restoreAction = useRestoreAction(organizations, options);
  const editAction = useEditAction(organizations[0]);

  const addToAccessPrivilegeAction = useAddOrganizationsAction({
    options: {
      ...options,
      items: organizationsToItems(organizations),
      shouldDisplay: shouldDisplayGrantAccessPrivilege,
    },
  });

  const createAccessPrivilegeAction = useGrantAccessPrivilegeAction(
    { organizations },
    {
      ...options,
      shouldDisplay: shouldDisplayGrantAccessPrivilege,
    },
  );

  const groupTitleAction = useTitleAction(organizations, {
    ...options,
    title: options.groupName,
    shouldDisplay: shouldDisplayTitle,
  });

  const contactTitleAction = useTitleAction(organizations, {
    ...options,
    title: 'Organization',
    shouldDisplay: shouldDisplayTitle,
  });

  const actions = [
    dividerAction,
    addToAccessPrivilegeAction,
    createAccessPrivilegeAction,
    groupTitleAction,
    dividerAction,
    removeFromGroupAction,
    contactTitleAction,
    dividerAction,
    editAction,
    deleteAction,
    restoreAction,
  ];

  return useActions<IOrganization, IOrganizationActionName>(
    actions as IOrganizationActionConfig[],
    options,
    organizations,
  );
};
