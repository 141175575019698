import React, { useCallback, useState } from 'react';
import { Button } from '@mantine/core';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

import { useSortDataSectionStore } from 'store/hooks';
import { CheckPowers } from 'components/check-power';

interface ISortButtonsProps {
  isSortModeEnabled: boolean;
  setIsSortModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  hidden?: boolean;
  requiredPowers?: string | string[];
}

export const SortButton: React.FC<ISortButtonsProps> = ({
  isSortModeEnabled,
  setIsSortModeEnabled,
  hidden,
  requiredPowers,
}) => {
  const { save } = useSortDataSectionStore();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const toggleSortMode = useCallback(() => {
    setIsSortModeEnabled((mode) => !mode);
  }, [setIsSortModeEnabled]);

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);
      await save();
      setIsSortModeEnabled(false);
      const searchData = queryString.parse(location.search.substring(1), { parseNumbers: true });
      const searchString = queryString.stringify({ ...searchData, page: 1 });
      navigate({ pathname: location.pathname, search: searchString });
    } finally {
      setIsLoading(false);
    }
  }, [navigate, save, setIsSortModeEnabled]);

  if (hidden) {
    return <></>;
  }

  if (isSortModeEnabled) {
    return (
      <CheckPowers requiredPowers={requiredPowers}>
        <div className="d-flex gap-2 align-items-center">
          <Button size="xs" variant="default" disabled={isLoading} onClick={toggleSortMode}>
            Cancel
          </Button>
          <Button size="xs" loading={isLoading} onClick={handleSave} variant="success">
            Save
          </Button>
        </div>
      </CheckPowers>
    );
  }

  return (
    <CheckPowers requiredPowers={requiredPowers}>
      <Button size="xs" onClick={toggleSortMode} variant="success">
        Sort
      </Button>
    </CheckPowers>
  );
};
