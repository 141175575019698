import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { ICastCrewActionName } from './types';
import { useDeletePreviewImageAction } from './delete-preview-image';
import { useUploadPreviewImageAction } from './upload-preview-image';
import { useDeleteAction } from './delete';
import { useAssignToProductsAction } from './assign-to-products';
import { useRemoveFromProductAction } from './remove-from-product';
import { useEditAssignmentToProductsAction } from './edit-assignment';
import { IExtendedProductCastCrew } from 'types/product';

export * from './types';

export const useCastCrewActions = (
  items: (PmGenericCastCrew | IExtendedProductCastCrew)[],
  options: IUseActionsOptionParam<PmGenericCastCrew>,
): IUseActionsResponse<ICastCrewActionName> => {
  const deleteAvatarAction = useDeletePreviewImageAction(items, options);
  const uploadPreviewAction = useUploadPreviewImageAction(items, options);
  const deleteAction = useDeleteAction(items, options);
  const assignCastCrewsAction = useAssignToProductsAction(items, options);
  const removeFromProductAction = useRemoveFromProductAction(items, options);
  const editAssignmentToProductsAction = useEditAssignmentToProductsAction(
    items as IExtendedProductCastCrew[],
    options,
  );

  const actions = [
    removeFromProductAction,
    editAssignmentToProductsAction,
    assignCastCrewsAction,
    uploadPreviewAction,
    deleteAvatarAction,
    deleteAction,
  ];

  return useActions<PmGenericCastCrew, ICastCrewActionName>(actions, options, items);
};
