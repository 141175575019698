import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import omit from 'lodash/omit';

import { ProductDataSection } from 'components/product-data-section';
import { GroupProductsSortDataSection } from 'components/group-items-sort-data-section';

import { IProduct, ISearchFilter, ItemId } from 'types';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { IResult, tuco } from 'utils/chipmunk';
import { ToastError } from 'components/toast';
import { useStore } from 'store';

import { customSortMenu } from './custom-sort-menu';

export interface IGroupDetailProductsTabProps {
  groupId: ItemId;
  page: Pages;
  isSortModeEnabled?: boolean;
  showParentsOnlyFilter?: boolean;
  parentFilterDefaultValue?: boolean;
  options?: Partial<IUseActionsOptionParam>;
  params?;
}

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

export const GroupDetailProductsTab: React.FC<IGroupDetailProductsTabProps> = observer(
  ({
    groupId,
    page,
    options,
    isSortModeEnabled,
    params,
    showParentsOnlyFilter = false,
    parentFilterDefaultValue = true,
  }) => {
    const { toastStore } = useStore();

    const executor = useCallback(
      async (options): Promise<IResult<IProduct>> => {
        try {
          const opts = omit(options, ['type']);

          return await tuco<IProduct>('getGroupProducts', opts);
        } catch (error) {
          toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
          const objects = [];
          return { objects: [], object: objects[0] };
        }
      },
      [toastStore],
    );

    const persistentFilters: ISearchFilter[] = useMemo(() => [['group_ids', 'eq', groupId]], [groupId]);
    if (!groupId) {
      return <></>;
    }

    if (isSortModeEnabled) {
      return <GroupProductsSortDataSection groupId={groupId as string} />;
    }

    return (
      <ProductDataSection
        persistentFilters={persistentFilters}
        showParentsOnlyFilter={showParentsOnlyFilter}
        parentFilterDefaultValue={parentFilterDefaultValue}
        actionOptions={options}
        page={page}
        params={params}
        executor={executor}
        getSortMenu={customSortMenu}
        defaultParams={defaultParams}
        customSubmitFilter={submitFiltersWithSeqNumber}
      />
    );
  },
);

export default GroupDetailProductsTab;
