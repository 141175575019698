import { ISelectionStandardized, IGroup, GroupTypes } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { ISelectionActionConfig, ISelectionActionName } from 'utils/actions/selection/types';
import { shouldDisplaySingleNotDeleted as shouldDisplay } from 'utils/actions/common';

import type { IAddToGroupEntityType } from 'components/add-to-group-dialog/types';

export interface IUseAddEntitiesToGroupActionOptions extends IUseActionsOptionParam {
  title?: string;
  groupLabel?: string;
  dialogTitle?: string;
  entityType?: IAddToGroupEntityType;
  passPropsFromExistingItems?: boolean;
  hideProductsParams?: boolean;
}

export const useAddEntitiesToGroupAction = (
  items: ISelectionStandardized[] | IGroup[],
  options?: IUseAddEntitiesToGroupActionOptions,
): ISelectionActionConfig => {
  const [openSelectionDialog] = useAddToGroupDialog({
    group: items[0] as unknown as IGroup,
    entityType:
      ((items[0] as ISelectionStandardized)?.main_entity_type as IAddToGroupEntityType) || options?.entityType,
    options,
    groupType: GroupTypes.SELECTION,
    title: options?.dialogTitle,
    groupLabel: options?.groupLabel,
    passPropsFromExistingItems: options?.passPropsFromExistingItems,
    hideProductsParams: options?.hideProductsParams,
  });

  return {
    name: ISelectionActionName.ADD_TO_THIS_SELECTION,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: options?.title ?? `Add to this Selection`,
    handler: openSelectionDialog,
  };
};
