import React from 'react';

import { Page } from 'components/layout';
import { CreateConferenceWizard } from 'components/conference/conference-form';

export const CreateConference: React.FC = () => {
  return (
    <Page title="Create Conference">
      <CreateConferenceWizard />
    </Page>
  );
};
