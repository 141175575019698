import React, { useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import { DateCountTotalChart } from 'components/date-count-total-bar-chart';
import { BasicCountBarChart } from 'components/basic-count-bar-chart';
import PieChart from 'components/pie-chart';
import { LoginListTable } from 'components/login-list-table';
import { ActivityChart } from 'components/activity-chart';
import { SectionHeader } from 'components/section-header';
import { getHistogramData, fetchActivityStatistics } from 'utils/apis/analytics';
import { IHistogramData, IAnalyticsProps } from 'types';
import './style.scss';

const AnalyticsInteractionLogin: React.FC<IAnalyticsProps> = (props) => {
  const [countByUserLogin, setCountByUserLogin] = useState([] as IHistogramData[]);
  const [countByDeviceLogin, setCountByDeviceLogin] = useState([] as IHistogramData[]);
  const [countByMostActiveDays, setCountByMostActiveDays] = useState<IHistogramData[]>([]);

  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData({
      queryName: props?.queries?.loginCount,
      ...props,
    }).then(({ data }) => {
      setCountByUserLogin(data);
    });

    getHistogramData({
      queryName: props?.queries?.loginDevices,
      ...props,
    }).then(({ data }) => {
      const formatedData = data.map((dx) => {
        return { ...dx, group_name: dx?.platform };
      });
      setCountByDeviceLogin(formatedData);
    });

    getHistogramData({
      queryName: props?.queries?.loginActiveDaysAvg,
      ...props,
    }).then(({ data }) => {
      const formattedData = data.map((item) => {
        return { ...item, count: Number(item?.count.toFixed(2)) };
      });
      setCountByMostActiveDays(formattedData);
    });
  }, [props]);

  return (
    <>
      <div className="analytics-tab-logins">
        <div className="analytics-tab-logins__card">
          <SectionHeader title="Logins" useBackground useBorder />
          <div className="analytics-tab-logins__card-content">
            <DateCountTotalChart
              data={countByUserLogin}
              unit="login"
              startDate={props.dateRange[0]}
              endDate={props.dateRange[1]}
            />
          </div>
        </div>
        <div className="analytics-tab-logins__card">
          <SectionHeader title="Favorite Devices" useBackground useBorder />
          <div className="analytics-tab-logins__card-content">
            <PieChart data={countByDeviceLogin} aspectRatio={1} />
          </div>
        </div>

        <div className="analytics-tab-logins__card">
          <SectionHeader title="Most Active Week Days" useBackground useBorder />
          <div className="analytics-tab-logins__card-content">
            <BasicCountBarChart
              data={countByMostActiveDays}
              unit="logins"
              xAxisTitle="Week days"
              yAxisTitle="Logins (average)"
              xAxisKey="weekday_num"
            />
          </div>
        </div>
      </div>

      <div className="analytics-tab-logins-list">
        {props.queries?.loginList && (
          <LoginListTable title="Detailed Logins" queryName={props.queries?.loginList} {...props} />
        )}

        {props.queries?.loginActivity && (
          <ActivityChart fetcher={() => fetchActivityStatistics(props)} url={''} aspectRatio={6} />
        )}
      </div>
    </>
  );
};

export default AnalyticsInteractionLogin;
