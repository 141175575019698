export const organizationDetailsSchema = `
  access_level,
  addresses,
  classification,
  created_at,
  deleted_at,
  id,
  legal_entity,
  name,
  phones,
  status,
  updated_at,
  email,
  social_media_profiles,
  urls,
  country,
  language,
  language_id,
  registration_number,
  responsible_user,
  responsible_user_id,
  tags,
  geo_scopes,
  courier_services,
  key_persons,
  vat_number,
  vat_rate,
  withholding_tax_rate,
  tax_number,
  currency_id,
  bank_accounts,
  description,
  producer_refs,
  is_producer,
  logo,
  banner,
  delivery_informations
`;

export const organizationListSchema = `
  addresses,
  access_level,
  classification,
  country_id,
  country,
  created_at,
  deleted_at,
  id,
  language,
  legal_entity,
  name,
  phones,
  status,
  updated_at,
  users_count,
  logo,
  is_producer,
  urls { url },
`;
