import React, { useCallback } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IBarChartData } from 'types';
import { ChartContainer } from 'components/chart-container';
import { pluralizeTooltipLabel } from 'components/analytics';

const AddTopRightText = {
  id: 'topRightText',
  beforeDraw: function ({ ctx, width }, args, { text }) {
    if (!text) {
      return;
    }
    ctx.restore();
    ctx.fillStyle = '#8ca6b7';
    ctx.textBaseline = 'middle';
    const textX = width - ctx.measureText(text).width - 15;
    const textY = 5;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, AddTopRightText);

ChartJS.defaults.color = '#8ca6b7';
ChartJS.defaults.font = { family: 'var(--font-family-sans-serif)', size: 14, weight: '500' };

interface IBarChartProps {
  data: IBarChartData[];
  aspectRatio?: number;
  unit?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  topRightText?: string;
  xAxisKey?: string;
}

const DurationCountBarChart: React.FC<IBarChartProps> = ({
  data,
  unit = 'attendee',
  aspectRatio = 1.2,
  xAxisTitle = 'Duration of Attendance (in minutes)',
  yAxisTitle = 'Attendees',
  xAxisKey = 'name',
  topRightText = '',
}) => {
  const formatLabel = useCallback(({ parsed: { y = 0 } }) => pluralizeTooltipLabel(y, unit), [unit]);
  const formatTitle = ([context]): string => `spent ${context.label} minutes`;

  const options = React.useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio,
      resizeDelay: 50,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: formatLabel,
            title: formatTitle,
          },
        },
        topRightText: {
          text: topRightText,
        },
      },
      parsing: { xAxisKey: xAxisKey, yAxisKey: 'count' },
      scales: {
        xAxes: {
          title: {
            display: true,
            text: xAxisTitle,
          },
          offset: true,
          grid: {
            display: false,
            offset: false,
            borderWidth: 2,
            borderColor: '#304658',
            color: '#304658',
          },
        },
        yAxes: {
          title: {
            display: true,
            text: yAxisTitle,
          },
          min: 0,
          beginAtZero: true,
          ticks: { precision: 0 },
          offset: false,
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#304658',
            color: '#304658',
          },
        },
      },
    }),
    [aspectRatio, formatLabel, topRightText, xAxisKey, xAxisTitle, yAxisTitle],
  );

  const chartData = {
    datasets: [
      {
        data,
        backgroundColor: '#2b95d6',
      },
    ],
  };
  return (
    <ChartContainer aspectRatio={aspectRatio}>
      <Bar options={options} data={chartData} />
    </ChartContainer>
  );
};

export default DurationCountBarChart;
