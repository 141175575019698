import React, { useCallback } from 'react';

import { FormMultiSelect, IFormMultiSelectProps } from 'helpers/form';
import { queryAssets } from 'utils/apis/asset';
import { IAsset, IQueryParams, ISearchFilter } from 'types';

const tagInputProps = { inputProps: { placeholder: 'Add more' } };

export const FormAssets: React.FC<IFormMultiSelectProps & { filters?: ISearchFilter[]; includeSubAssets?: boolean }> = (
  props,
) => {
  const { placeholder = 'Select assets', label = 'Assets', name, filters, includeSubAssets, ...other } = props;

  const fetchAssets = useCallback(
    ({ q, ids }: IQueryParams): Promise<IAsset[]> => {
      return queryAssets({ q, ids }, includeSubAssets ? filters : [...(filters || []), ['parent_id', 'not_exist']]);
    },
    [filters, includeSubAssets],
  );

  return (
    <FormMultiSelect
      name={name}
      label={label}
      omni
      fetchValues={fetchAssets}
      tagInputProps={tagInputProps}
      placeholder={placeholder}
      {...other}
    />
  );
};
