import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';

import { IAsset, IMm3Asset } from 'types';
import { AssetFilters, withAssetVersion } from 'utils/asset';

export const AssetOldMetadataDocumentDataSection: React.FC<{ asset?: IAsset }> = ({ asset }) => {
  const { author, classification, comments, keywords, number_of_pages, pdf_version, producer, subject, title } =
    asset || {};

  const isDocument = classification?.startsWith(AssetFilters.IMAGE);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Document Data" hidden={!isDocument}>
      <Row label="author" value={author} size="m" />
      <Row label="comments" value={comments} size="m" />
      <Row label="keywords" value={keywords} size="m" />
      <Row label="Number of Pages" value={number_of_pages} size="m" />
      <Row label="Pdf Version" value={pdf_version} size="m" />
      <Row label="producer" value={producer} size="m" />
      <Row label="subject" value={subject} size="m" />
      <Row label="title" value={title} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataDocumentDataSection: React.FC<{ asset?: IMm3Asset }> = ({ asset }) => {
  const { classification, meta } = asset || {};
  const { author, comments, keywords, number_of_pages, pdf_version, producer, subject, title } = meta || {};

  const isDocument = classification?.startsWith(AssetFilters.IMAGE);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Document Data" hidden={!isDocument}>
      <Row label="author" value={author} size="m" />
      <Row label="comments" value={comments} size="m" />
      <Row label="keywords" value={keywords} size="m" />
      <Row label="Number of Pages" value={number_of_pages} size="m" />
      <Row label="Pdf Version" value={pdf_version} size="m" />
      <Row label="producer" value={producer} size="m" />
      <Row label="subject" value={subject} size="m" />
      <Row label="title" value={title} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMetadataDocumentDataSection = withAssetVersion(
  AssetOldMetadataDocumentDataSection,
  AssetMm3MetadataDocumentDataSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
