import { IMobileAppSyncFormData } from 'components/mobile-app-sync-wizard';
import { IIdentifiable, IGroup } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';
import { IGroupActionName } from 'utils/actions/common';

export enum IMobileAppSyncActionName {
  CREATE_MOBILE_SYNC = 'create-mobile-sync',
  RECOMMEND = 'recommend',
}

export type IMobileSyncActionForm = IMobileAppSyncFormData & IIdentifiable;

export type IMobileAppSyncActionConfig = IActionConfig<IIdentifiable, IGroupActionName | IMobileAppSyncActionName>;

export type IShouldDisplayMobileAppSyncActionFunction = IShouldDisplayActionFunction<IGroup, IGroupActionName>;
