import React from 'react';
import { notifications } from '@mantine/notifications';
import { Tick, WarningSign } from 'blueprint5-icons';
import { MfxIcon } from 'icons/index';

// duplicate intent due to loading issues between mantineProvider and storePorvider
enum Intent {
  NONE = 'none',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

const DEFAULT_TIMEOUT = 3000;

export class ToastStore {
  showNotification(config: {
    message: React.ReactNode;
    icon?: React.ReactNode;
    intent?: string;
    timeout?: number | boolean;
  }): void {
    notifications.show({
      message: config.message,
      color:
        config.intent === Intent.PRIMARY
          ? 'var(--mfx-main)'
          : config.intent === Intent.SUCCESS
          ? 'var(--mfx-success)'
          : config.intent === Intent.WARNING
          ? 'var(--mfx-warning)'
          : config.intent === Intent.DANGER
          ? 'var(--mfx-error)'
          : undefined,
      icon:
        config.intent === Intent.PRIMARY ? (
          <MfxIcon icon="info" className="custom-icon" />
        ) : config.intent === Intent.SUCCESS ? (
          <Tick color="var(--mfx-gray-1)" />
        ) : config.intent === Intent.WARNING ? (
          <WarningSign color="var(--mfx-gray-1)" />
        ) : config.intent === Intent.DANGER ? (
          <MfxIcon icon="error" className="custom-icon" />
        ) : undefined,
      autoClose:
        !config.timeout && (config.intent === Intent.WARNING || config.intent === Intent.DANGER)
          ? false
          : DEFAULT_TIMEOUT,
    });
  }

  info(message: React.ReactNode, timeout: number = DEFAULT_TIMEOUT): void {
    this.showNotification({ message, timeout, intent: Intent.PRIMARY });
  }

  success(message: React.ReactNode, timeout: number = DEFAULT_TIMEOUT): void {
    this.showNotification({ message, timeout, intent: Intent.SUCCESS });
  }

  warning(message: React.ReactNode, timeout?: boolean): void {
    this.showNotification({ message, intent: Intent.WARNING, timeout });
  }

  error(message: React.ReactNode, timeout?: boolean): void {
    this.showNotification({ message, intent: Intent.DANGER, timeout });
  }

  clearAll(): void {
    notifications.clean();
  }
}
