import React, { useMemo, useCallback } from 'react';
import { map, uniq, remove } from 'lodash';
import { observer } from 'mobx-react-lite';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { Tabs } from '@mantine/core';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, ISearchFilter } from 'types';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { BodyWrapper } from 'components/layout';
import { DeliveryActionsList } from 'components/action';

import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useEditDeliveryAction, IDeliveryActionName } from 'utils/actions/delivery';
import { useTab } from 'utils/hooks';
import { IActionName, ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

import { DeliveryDetailsOverview } from './delivery-details-overview';
import { isDeliveryDraftAndSingleContext as shouldDisplayEditDelivery } from 'utils/actions/delivery/actions-acl';
import { useNavigate } from 'react-router';
import { AssetsDataSection } from 'components/asset/assets-data-section';
import { DeliveryDetailTab, DeliveryTabPanel } from './utils';
import { DeliveryRecipientsTab } from 'pages/delivery-details/delivery-recipients-tab';

import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const tabOptions = [DetailsPageTabs.ASSETS, DetailsPageTabs.RECIPIENTS];

export const DeliveryDetails: React.FC<{
  page?: Pages;
  delivery: DeliveriesGenericDeliveryPackage;
  setDelivery: React.Dispatch<React.SetStateAction<DeliveriesGenericDeliveryPackage>>;
}> = observer(({ page = Pages.DELIVERIES_DETAILS, delivery, setDelivery }) => {
  const { name, delivery_items, recipient_list } = delivery || {};
  const [currentTab, setCurrentTab] = useTab(DetailsPageTabs.ASSETS, tabOptions);
  const asset_ids = uniq(map(delivery_items, 'asset_id'));
  const product_ids = uniq(remove(map(delivery_items, 'product_id'), null)) as number[];
  const user_ids = map(delivery.recipient_list, 'recipient_id');
  const assetsCount = asset_ids?.length;

  const navigate = useNavigate();

  const onSuccess = useCallback(
    async (action?: IActionName, newValue?: DeliveriesGenericDeliveryPackage): Promise<void> => {
      switch (action) {
        case IDeliveryActionName.DELETE:
          navigate(`${Routes.DELIVERIES}`);
          break;
        default:
          setDelivery((oldValue) => ({
            ...oldValue,
            ...newValue,
          }));
      }
    },
    [navigate, setDelivery],
  );

  const options = useMemo<IUseActionsOptionParam<DeliveriesGenericDeliveryPackage>>(
    () => ({ context: 'single', page, onSuccess, tab: currentTab }),
    [currentTab, onSuccess, page],
  );

  const editAction = useEditDeliveryAction([{ ...delivery, asset_ids, product_ids, recipients: user_ids }], options);

  const actions = shouldDisplayEditDelivery(editAction, 0, null, [delivery], options)
    ? [editAction as ISectionHeaderAction]
    : undefined;

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  const assetsFilter = useMemo(() => [['id', 'in', asset_ids]] as ISearchFilter[], [asset_ids]);

  return (
    <BodyWrapper>
      <SectionHeader
        title={name}
        subtitle={
          <span className="muted-text">
            {[
              [`${product_ids?.length || 0} Products`],
              `${assetsCount || 0} Assets`,
              `${recipient_list?.length || 0} Recipients`,
            ].join(' | ')}
          </span>
        }
        actions={actions}
        useBackground
        dropdownMenu={
          <ActionsMenu
            component={DeliveryActionsList}
            items={[
              {
                ...delivery,
                asset_ids,
                product_ids,
                recipients: user_ids,
              } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
            ]}
            options={options}
          />
        }
      />
      <div className="delivery-details__content-wrapper">
        <DeliveryDetailsOverview delivery={delivery} assetsCount={assetsCount} />
        <div className="delivery-details__tabs-wrapper">
          <Tabs value={currentTab} onChange={handleTabChange} className={'delivery-details__tabs h-100'}>
            <Tabs.List className="d-flex flex-nowrap">
              <DeliveryDetailTab value={DetailsPageTabs.ASSETS} activeTab={currentTab} />

              <DeliveryDetailTab value={DetailsPageTabs.RECIPIENTS} activeTab={currentTab} />
            </Tabs.List>
            <DeliveryTabPanel
              value={DetailsPageTabs.ASSETS}
              content={<AssetsDataSection persistentFilters={assetsFilter} />}
            />

            <DeliveryTabPanel
              value={DetailsPageTabs.RECIPIENTS}
              content={<DeliveryRecipientsTab delivery={delivery} />}
            />
          </Tabs>
        </div>
      </div>
    </BodyWrapper>
  );
});

export default DeliveryDetails;
