import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import ceil from 'lodash/ceil';

import { IExtendedProductCastCrew, IProduct } from 'types';
import { CastCrewDataSection } from 'components/cast-crew';
import { byId, parseOptionsToSearch } from 'utils/general';
import { ExtendedCastCrewListItemAttributes } from 'components/cast-crew/data-section-item/extended-data-section-item';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { fetchCastCrew } from 'utils/apis/cast-crew';
import { customSortMenu, handleFilters, handlePagination, handleSort, isTextFreeFilter } from './utils';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { Pages } from 'utils/actions/types';
import { ICastCrewActionName } from 'utils/actions/cast-crew';
import { useRefreshDataSection } from 'utils/hooks';

const defaultParams = { sort: ParametronSort.SEQUENCE_NUMBER, order: ParametronOrder.ASCENDING };

const ProductCastCrewPreviewTab: React.FC<{ product: IProduct; setProduct: Dispatch<SetStateAction<IProduct>> }> =
  observer(({ product, setProduct }) => {
    const executor = useCallback(
      async (opts: IActionOpts): Promise<IResult> => {
        opts = parseOptionsToSearch(opts);
        const filter = opts?.body?.search?.filters[0];
        const textSearchFilter = filter && isTextFreeFilter(filter) ? [filter] : [];

        const ids = (product.default_layer.meta?.cast_crew || []).map((e) => e.cast_crew_id);
        const castCrewList = await fetchCastCrew({ ids }, textSearchFilter, 'unfurl');
        const castCrewById = byId(castCrewList);

        const indexList = (product.default_layer.meta?.cast_crew || [])
          .filter((e) => castCrewList.some((cast) => cast.id === e.cast_crew_id))
          .map((e) => {
            // pass index from product.default_layer.meta?.cast_crew since it can have more items than castCrewList
            const index = product.default_layer.meta?.cast_crew?.findIndex(
              (cast) => cast.cast_crew_id === e.cast_crew_id,
            );
            return { ...e, index: index };
          });
        let list = handleSort(indexList as unknown as IExtendedProductCastCrew[], opts.params);
        list = handleFilters(list, opts);
        const pageItems = handlePagination(list, opts.params);

        const objects = pageItems.map((e, index) => ({
          ...e,
          ...(castCrewById[e.cast_crew_id || 0] || {}),
          // use index as id to differentiate when same member is assigned multiple times to a product
          id: index + 1,
        }));
        const { per, page } = opts?.params || {};
        const castCount = list.filter(({ kind }) => kind === 'Cast').length;

        return {
          aggregations: {
            count_by_kind: [
              { value: 'Cast', count: castCount },
              { value: 'Crew', count: list.length - castCount },
            ],
          },
          objects,
          object: objects[0],
          pagination: {
            current_page: page || 1,
            total_count: list.length,
            total_pages: per ? ceil(list.length / per) : 1,
          },
        };
      },
      [product.default_layer.meta?.cast_crew],
    );

    const refreshDataSection = useRefreshDataSection();

    const onSuccess = useCallback(
      (action: ICastCrewActionName, _, layer) => {
        switch (action) {
          case ICastCrewActionName.REMOVE_FROM_PRODUCTS:
          case ICastCrewActionName.EDIT_ASSIGNMENT:
            setProduct((p) => ({ ...p, default_layer: { ...p.default_layer, ...layer } }));
            break;

          default:
            refreshDataSection();
            break;
        }
      },
      [refreshDataSection, setProduct],
    );

    return (
      <CastCrewDataSection
        defaultParams={defaultParams}
        executor={executor}
        AttributesCmp={ExtendedCastCrewListItemAttributes}
        customSortMenu={customSortMenu}
        page={Pages.PRODUCT_CAST_CREW_TAB}
        options={{ product, onSuccess }}
      />
    );
  });

export default ProductCastCrewPreviewTab;
