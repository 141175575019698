import React, { useCallback } from 'react';
import { Button } from '@mantine/core';

const ManageAssetsButtons: React.FC<{
  showButton: boolean;
  isAssetEditEnabled: boolean;
  setIsAssetEditEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaveClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsCancelClicked: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showButton, isAssetEditEnabled, setIsAssetEditEnabled, setIsSaveClicked, isLoading, setIsCancelClicked }) => {
  const handleCancelClick = useCallback((): void => {
    setIsCancelClicked(true);
    setIsAssetEditEnabled(false);
  }, [setIsAssetEditEnabled, setIsCancelClicked]);

  const toggleEditAssets = useCallback((): void => {
    setIsAssetEditEnabled((editMode) => !editMode);
  }, [setIsAssetEditEnabled]);

  const handleSaveClick = useCallback(() => {
    setIsSaveClicked(true);
  }, [setIsSaveClicked]);

  if (!showButton) return null;
  return isAssetEditEnabled ? (
    <>
      <Button variant="default" size="xs" onClick={handleCancelClick} disabled={isLoading}>
        Cancel
      </Button>
      <Button size="xs" loading={isLoading} onClick={handleSaveClick} variant="success">
        Save
      </Button>
    </>
  ) : (
    <Button size="xs" variant="primary" onClick={toggleEditAssets}>
      Manage Assets
    </Button>
  );
};
export default ManageAssetsButtons;
