import React from 'react';

import { IStyled } from 'types';
import { Classes } from 'utils/ui';
import { paths16, paths24 } from 'icons/generated/paths';
import { IMfxIconName } from './icon-names';

import './style.scss';

interface IMfxIconProps extends IStyled {
  iconSize?: number;
  title?: string;
  bp3IconClass?: boolean;
  gridSize?: MfxIconGrid;
  icon: IMfxIconName;
  onMouseEnter?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

export enum MfxIconGrid {
  LARGE = 24,
  STANDARD = 16,
}

export const MfxIcon: React.FC<IMfxIconProps> = (props) => {
  const { icon, style, onMouseEnter, onMouseLeave } = props;
  const { className, iconSize = MfxIconGrid.STANDARD, gridSize, title, bp3IconClass = true } = props;

  const getPath = (): Partial<Record<IMfxIconName, string[]>> => {
    if (gridSize === MfxIconGrid.LARGE || (iconSize >= MfxIconGrid.LARGE && paths24[icon])) {
      return paths24;
    }

    return paths16;
  };

  const getGridSize = (): MfxIconGrid => {
    if (gridSize) {
      return gridSize;
    }

    if (iconSize >= MfxIconGrid.LARGE && paths24[icon]) {
      return MfxIconGrid.LARGE;
    }

    return MfxIconGrid.STANDARD;
  };

  const renderSvgPaths = (grid): string => {
    const path = getPath();
    const pathStrings = path[icon];

    if (!pathStrings) {
      return `<rect width="${grid}" height="${grid}" />`;
    }
    return unescape(pathStrings[0]);
  };

  const grid = getGridSize();
  const __html = renderSvgPaths(grid);

  const classes = `${bp3IconClass ? Classes.ICON : ''} mfx-icon mfx-icon-${icon} ${className ? className : ''}`;
  const viewBox = `0 0 ${grid} ${grid}`;

  return React.createElement(
    'span',
    {
      style,
      className: classes,
      onMouseEnter,
      onMouseLeave,
    },
    <svg width={iconSize} height={iconSize} viewBox={viewBox}>
      {title && <desc>{title}</desc>}
      <g dangerouslySetInnerHTML={{ __html }} />
    </svg>,
  );
};

export default MfxIcon;
