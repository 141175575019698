import { useCallback } from 'react';
import { ISelectionStandardized } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ISelectionActionConfig, ISelectionActionName } from 'utils/actions/selection/types';
import { useUploadAssetToSelectionDialog } from 'components/upload-asset-to-selection-dialog';
import { shouldDisplayUploadAssetToSelection as shouldDisplay } from './actions-acl';

export const useUploadAssetToSelectionAction = (
  items: ISelectionStandardized[],
  options?: IUseActionsOptionParam,
): ISelectionActionConfig => {
  const [openDialog] = useUploadAssetToSelectionDialog(options);

  const openDialogHandler = useCallback(() => openDialog(items[0]), [items, openDialog]);

  return {
    name: ISelectionActionName.UPLOAD_ASSET_TO_SELECTION,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: `Upload Asset to Selection`,
    handler: openDialogHandler,
  };
};
