import { IActionConfig } from 'utils/actions/types';
import { ITitleDividerActionName } from 'utils/actions/types';

export const dividerAction: IActionConfig<never, never> = { name: 'divider', shouldDisplay: (): boolean => true };
export const selectionDividerAction: IActionConfig<never, never> = {
  name: ITitleDividerActionName.SELECTION,
  shouldDisplay: (): boolean => true,
};
export const assetDividerAction: IActionConfig<never, never> = {
  name: ITitleDividerActionName.ASSET,
  shouldDisplay: (): boolean => true,
};

export const productDividerAction: IActionConfig<never, never> = {
  name: ITitleDividerActionName.PRODUCT,
  shouldDisplay: (): boolean => true,
};

export const contactDividerAction: IActionConfig<never, never> = {
  name: ITitleDividerActionName.CONTACT,
  shouldDisplay: (): boolean => true,
};
