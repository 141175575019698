import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { SectionHeader } from 'components/section-header';
import { PreviewBarChart } from 'components/preview-bar-chart';
import { useDataSectionStore } from 'store';
import { ConnectionPreviewAttributes } from './connection-list-attributes';
import { ConnectionPreviewHeader } from './connection-preview-header';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';

import './style.scss';

const ConnectionPreviewTab: React.FC = observer(() => {
  const { activeItem: connection } = useDataSectionStore<DeliveriesGenericConnection>();

  return (
    <div className={cx('connection-preview', { 'd-flex align-items-center justify-content-center': !connection })}>
      {connection && (
        <>
          <ConnectionPreviewHeader connection={connection} />

          <div className="connection-preview__rows">
            <ConnectionPreviewAttributes />
            <div className="connection-preview__analytics">
              <SectionHeader useBackground title="Connection Activity" />
              <div className="connection-preview__analytics__graph">
                <PreviewBarChart
                  data={[]}
                  title="Connection Activity"
                  startDate={new Date()}
                  endDate={new Date()}
                  unit="login"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {!connection && <span className="entity-preview-tab__no-preview">No preview</span>}
    </div>
  );
});
export default ConnectionPreviewTab;
