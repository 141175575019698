import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { isEqual } from 'lodash';
import { ActionIcon, Button, TextInput } from '@mantine/core';

import { useStore } from 'store';
import { PresetSelect } from 'helpers/filters/preset-select';
import { IFiltersDefinition, IFiltersHandlers } from 'helpers/filters';
import { filtersToPreset } from 'helpers/filters/helpers';
import { ControlGroup } from 'helpers/form/fields/control-group/control-group';
import { IPreset } from 'helpers/filters/types';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { Cross, FloppyDisk, Tick } from 'blueprint5-icons';

import cx from 'classnames';
import './style.scss';

interface IDataSectionPresetProps {
  filterHandlers: IFiltersHandlers<IFiltersDefinition>;
}

const DataSectionPreset: React.FC<IDataSectionPresetProps> = observer((props) => {
  const { filterHandlers } = props;
  const {
    toastStore,
    dataSectionStore: {
      selectedPreset,
      presets,
      filters,
      searchStore: { paramsAndFiltersCount },
    },
  } = useStore();
  const [presetNameFieldOpen, setPresetNameFieldOpen] = useState(false);
  const [presetName, setPresetName] = useState(selectedPreset?.description || '');
  const [presetSaving, setPresetSaving] = useState(false);

  const matchingFilter = ((): IPreset | undefined => {
    if (!presets) return undefined;
    const propsFiltersStr = filtersToPreset(filters);

    return presets.find((savedFilter) => isEqual(savedFilter.filters, propsFiltersStr));
  })();

  useEffect(() => {
    setPresetName(selectedPreset?.description || '');
  }, [selectedPreset]);

  const handleOpenNewPresetName = (): void => {
    setPresetNameFieldOpen(true);
  };

  const handlePresetNameFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPresetName(e.currentTarget.value);
  };

  const handlePresetSave = (e): void => {
    e.preventDefault();
    setPresetSaving(true);
    filterHandlers.onSavePreset(presetName.trim()).then(([name, isUpdate]) => {
      setPresetSaving(false);
      setPresetNameFieldOpen(false);

      if (!isUpdate) {
        toastStore.success(`New preset "${name}" saved`);
      } else {
        toastStore.success(`Preset "${name}" updated`);
      }
    });
  };

  const handleClear = (): void => {
    filterHandlers.onClear();
    setPresetNameFieldOpen(false);
  };

  return (
    <div className="data-section-preset">
      <div className="data-section-preset--top d-flex align-items-center justify-content-between">
        <h4 className="mb-0">{paramsAndFiltersCount} active filters</h4>
        <ActionIcon variant="subtle" color="gray.5" radius="sm" onClick={handleClear}>
          <MantineIcon icon={<Cross />} />
        </ActionIcon>
      </div>
      <div className="data-section-preset--bottom">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Filter presets</h4>
          <ActionIcon variant="subtle" color="gray.5" radius="sm" onClick={handleOpenNewPresetName}>
            <MantineIcon icon={<FloppyDisk />} />
          </ActionIcon>
        </div>

        {presetNameFieldOpen && !matchingFilter && (
          <form onSubmit={handlePresetSave} className="mb-3">
            <ControlGroup gap={0} alignItems="flex-start">
              <TextInput
                size="xs"
                placeholder="Type Preset Name"
                onChange={handlePresetNameFieldChange}
                value={presetName}
                className="data-section-preset__input"
              />
              <Button
                size="xs"
                type="submit"
                variant="default"
                disabled={presetName.length < 1}
                className={cx(Classes.FIXED, 'save-button')}
                loading={presetSaving}
              >
                <MantineIcon icon={<Tick />} />
              </Button>
            </ControlGroup>
          </form>
        )}

        <PresetSelect
          onChange={filterHandlers.onLoadPreset}
          onDelete={filterHandlers.onDeletePreset}
          matchingFilter={matchingFilter}
        />
      </div>
    </div>
  );
});

export default DataSectionPreset;
