import React from 'react';
import { FormCheckbox, FormInput, FormSelect, IUseFormReturn } from 'helpers/form';
import { useBasicStore } from 'store/hooks';
import { IUploadSubtitleForm } from 'components/asset/upload-subtitle-dialog/upload-subtitle-dialog-body';

export const SubtitleFormCommonParts: React.FC<Pick<IUseFormReturn<IUploadSubtitleForm>, 'formData' | 'handlers'>> = ({
  formData,
  handlers,
}) => {
  const { languagesDataOptions } = useBasicStore();

  return (
    <>
      <FormInput name="label" label="Label" {...formData.label} {...handlers} />
      <FormSelect
        name="language_id"
        label="Language"
        large
        {...formData.language_id}
        {...handlers}
        options={languagesDataOptions}
      />

      {formData.purpose && <FormSelect name="purpose" label="Type" large {...formData.purpose} {...handlers} />}
      {formData.creation_type && (
        <FormSelect name="creation_type" label="Creation Type" large {...formData.creation_type} {...handlers} />
      )}

      <FormCheckbox
        name="show_in_player"
        label="Make available in video player"
        large
        {...formData.show_in_player}
        {...handlers}
      />
    </>
  );
};
