import React from 'react';
import { Button } from '@mantine/core';
import { Plus } from 'blueprint5-icons';

import { useCreateCastCrew } from './hook';
import { MantineIcon } from 'utils/ui/icon';

export const CreateCastCrewButton: React.FC = () => {
  const handleCreateCastCrew = useCreateCastCrew();

  return (
    <Button leftSection={<MantineIcon icon={<Plus />} />} size="xs" onClick={handleCreateCastCrew} variant="primary">
      Create Cast & Crew
    </Button>
  );
};
