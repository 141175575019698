import { useEffect } from 'react';
import { IUseFormReturn } from 'helpers/form';

export const useFutureDescendantsFlag = (
  form: IUseFormReturn<{ include_descendants?: boolean; include_future_descendants?: boolean }>,
): void => {
  const {
    handlers: { onChange },
    values: { include_descendants },
  } = form;

  useEffect(() => {
    if (include_descendants) {
      return;
    }

    onChange({ include_future_descendants: false });
  }, [include_descendants, onChange]);
};
