import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';
import { useRefreshDataSection } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';
import { Refresh } from 'blueprint5-icons';

export interface IDataSectionRefreshButton {
  className?: string;
  disabled?: boolean;
  customRefresh?: () => void;
}

const DataSectionRefreshButton: React.FC<IDataSectionRefreshButton> = observer(
  ({ className, customRefresh, disabled = false }) => {
    const defaultRefresh = useRefreshDataSection();
    const refresh = customRefresh || defaultRefresh;

    return (
      <ActionIcon
        variant="subtle"
        color="gray.5"
        radius="sm"
        className={className}
        data-disabled={disabled}
        disabled={disabled}
        onClick={refresh}
      >
        <MantineIcon icon={<Refresh />} />
      </ActionIcon>
    );
  },
);

export default DataSectionRefreshButton;
