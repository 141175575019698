import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IGroup, IIdentifiable } from 'types';
import { useDeleteAction } from './delete-action';
import { useRestoreAction } from './restore-action';
import { useChangeAccessAction } from './change-access-action';
import { useCopyAction } from 'utils/actions/common/copy-action';

import { IAccessGroupActionName } from './types';

import type { IShouldDisplayAccessGroupActionFunction, IAccessGroupActionConfig } from './types';
import {
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactsAction,
  useAddOrganizationsAction,
} from './add-entities-actions';

export { IAccessGroupActionName, IShouldDisplayAccessGroupActionFunction };

export {
  useAddContactsAction,
  useAddOrganizationsAction,
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactSelectionsAction,
  contactsToItems,
  productsToItems,
  organizationsToItems,
  contactSelectionsToItems,
} from './add-entities-actions';

export const useAccessGroupActions = (
  entities: (IGroup & IIdentifiable)[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IAccessGroupActionName> => {
  const addProductsAction = useAddProductsAction({
    entities,
    options: { ...options, addSelectedProductAncestors: true },
  });

  const addAssetsAction = useAddAssetsAction({ entities, options });
  const addContactsAction = useAddContactsAction({ entities, options });
  const addOrganizationsAction = useAddOrganizationsAction({
    entities,
    options,
  });
  const changeAccessAction = useChangeAccessAction(entities, options);

  const copyAccessGroupAction = useCopyAction(entities, {
    ...options,
    messageSuccess: 'Access group has been copied',
  });

  const deleteAccessGroupAction = useDeleteAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);

  const actions = [
    addProductsAction,
    addAssetsAction,
    addContactsAction,
    addOrganizationsAction,
    changeAccessAction,
    copyAccessGroupAction,
    restoreAction,
    deleteAccessGroupAction,
  ];

  return useActions(actions as IAccessGroupActionConfig[], options, entities);
};
