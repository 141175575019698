import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { DataSection } from 'components/data-section';
import { RecommendationDataSectionItem } from 'components/recommendation/recommendation-data-section-item';
import { RecommendationActions } from 'components/action';
import { RecommendationSelectedTabItem, IRecommendationFilters } from 'components/recommendation';
import { RecommendationPreview } from 'components/recommendation/recommendation-preview';
import { RecommendationFilters } from 'components/recommendation/recommendation-filters/recommendation-filters';
import { EqFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { IActionName, IUseActionsOptionParam, Pages } from 'utils/actions/types';
import { IRecommendationActionName } from 'utils/actions/recommendation/types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { useDataSectionStore } from 'store/hooks';
import { useRefreshDataSection } from 'utils/hooks';
import { RecommendationSchema } from 'utils/schemas/recommendation';
import { ActionsMenu } from 'components/action/utils';

const defaultPersistentFilters: IFilterOption[] = [];

const defaultFilters: IRecommendationFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  expires_at: new RangeFilter('expires_at', '', ''),
  sent_at: new RangeFilter('sent_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  status: new EqFilter('status', ''),
  type: new EqFilter('type', ''),
};
const sidebarTabs = {
  preview: <RecommendationPreview />,
  filters: <RecommendationFilters />,
  selectedItemRenderer(recommendation) {
    return <RecommendationSelectedTabItem recommendation={recommendation} />;
  },
};

interface IRecommendationDataSectionProps {
  persistentFilters?: IFilterOption[];
  page?: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  persistFilters?: boolean;
}

const RecommendationDataSection: React.FC<IRecommendationDataSectionProps> = observer(
  ({ persistentFilters, page = Pages.LIST, actionOptions, persistFilters }) => {
    const recommendationFilters = useMemo(
      () => [...defaultPersistentFilters, ...(persistentFilters || [])],
      [persistentFilters],
    );

    useControlDataSection({
      persistFilters,
      schema: RecommendationSchema,
      model: Model.RECOMMENDATIONS,
      filters: recommendationFilters,
      defaultFilters,
      stats: 'status,type,owner_id',
    });
    const refreshDataSection = useRefreshDataSection();

    const onSuccess = useCallback(
      async (action?: IActionName): Promise<void> => {
        if (action === IRecommendationActionName.DELETE) {
          setTimeout(refreshDataSection, 1000);
        } else {
          refreshDataSection();
        }
      },
      [refreshDataSection],
    );

    const itemRenderer = useCallback(
      (recommendation: McGenericRecommendationProduct): React.ReactElement => {
        return (
          <RecommendationDataSectionItem
            key={recommendation.id}
            recommendation={recommendation}
            contextMenu={
              <ActionsMenu
                component={RecommendationActions}
                items={[recommendation]}
                options={{ context: 'single', page, onSuccess }}
              />
            }
          />
        );
      },
      [onSuccess, page],
    );
    const { checked } = useDataSectionStore<McGenericRecommendationProduct>();

    return (
      <DataSection
        className="contacts__data-section"
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={RecommendationActions}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
          />
        }
      />
    );
  },
);

export default RecommendationDataSection;
