import React, { useCallback } from 'react';

import { IDeepPartial, IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayAssociate as shouldDisplay } from './actions-acl';

import { associateProduct } from './api';

import { FormSubmitSection } from 'components/form-submit-section';
import { FormProducts, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { fetchProductsWithAncestry } from 'utils/apis/product';
import { pluralEntityInflect } from 'utils/general';

export interface IAssociateProductForm {
  handleSubmit: (product: IProduct) => void;
  product: IProduct;
}

const AssociateProductsForm: React.FC<IAssociateProductForm> = ({ product, handleSubmit }) => {
  const { formData, values, handlers, onSubmit } = useForm<IDeepPartial<IProduct>>(
    product,
    Model.PRODUCTS,
    handleSubmit,
  );
  const { id, meta } = product;

  const fetchProductsOverride = useCallback(
    (params, filters) => {
      const defaultFilter = filters || [['parent_id', 'not_exist']];

      if (id) {
        defaultFilter.push(['id', 'ne', id]);
      }

      if (meta?.related_product_ids?.length) {
        defaultFilter.push(['id', 'not_in', [...meta?.related_product_ids]]);
      }

      return fetchProductsWithAncestry(params, defaultFilter);
    },
    [id, meta],
  );

  return (
    <form onSubmit={onSubmit}>
      <FormProducts
        name="meta.related_product_ids"
        label="Products"
        {...formData['meta.related_product_ids']}
        {...handlers}
        fetchValues={fetchProductsOverride}
      />
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!values['meta.related_product_ids']?.length} />
    </form>
  );
};

export const useAssociateAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IProduct) => {
      const newRelatedIds = values['meta.related_product_ids'];
      const { entity, entityWithCount } = pluralEntityInflect('related Product', newRelatedIds.length);

      try {
        const newProduct = await associateProduct(items[0], newRelatedIds);
        toastStore.success(`${entityWithCount} added`);
        await options?.onSuccess?.(IProductActionName.ASSOCIATE, newProduct);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(`Adding ${entity} failed: ${error.text || error}`);
        await options?.onFailure?.();
      }
    },
    [items, toastStore, options, dialogStore],
  );

  const body = useCallback(
    () => <AssociateProductsForm product={items[0]} handleSubmit={handleSubmit} />,
    [handleSubmit, items],
  );

  const openAssociateProductDialog = useCallback((): void => {
    dialogStore.openModal({
      title: 'Add related Product(s)',
      body,
    });
  }, [body, dialogStore]);

  return {
    name: IProductActionName.ASSOCIATE,
    shouldDisplay,
    icon: 'flows',
    title: 'Add related products',
    handler: openAssociateProductDialog,
  };
};
