export const enum StepIds {
  Settings = 0,
  Assets = 1,
  Users = 2,
  Layout = 3,
  Summary = 4,
}

export const customContext = {
  properties: {
    mode: { required: true },
    title: { required: true },
    owner_id: { required: true },
  },
};
