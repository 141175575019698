import { IShouldDisplayActionFunction } from 'utils/actions/types';
import { ISelectionStandardized } from 'types';
import type { IShouldDisplayAccessPrivilegeActionFunction } from 'utils/actions';
import { Pages } from 'utils/actions/types';
import { ISelectionActionName, IShouldDisplaySelectionActionFunction } from './types';
import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { flags } from 'utils/flags';

export const shouldDisplayRecommend: IShouldDisplayActionFunction<ISelectionStandardized, ISelectionActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  const showRecommendFlag =
    items[0].main_entity_type === 'assets' ? flags.showAssetRecommendationsFeature : flags.showRecommendationFeature;

  if (!areAllNotDeleted(items) || !showRecommendFlag) {
    return false;
  }

  if (items.length > 0) {
    return true;
  }

  const opItems = options?.['items'];

  if (opItems?.length > 0) {
    return true;
  }
  return false;
};

export const shouldDisplayUploadAssetToSelection: IShouldDisplaySelectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
): boolean => {
  if (!areAllNotDeleted(entities)) {
    return false;
  }

  return (
    entities.length > 0 &&
    entities.every((entity) => entity.main_entity_type === 'assets') &&
    options.page === Pages.SELECTION_DETAILS
  );
};

export const shouldDisplayDeleteSelection: IShouldDisplaySelectionActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.SELECTION_DETAILS, Pages.SELECTION_LIST].includes(options?.page)
  );
};
export const shouldDisplayAccessPrivilege:
  | IShouldDisplayAccessPrivilegeActionFunction
  | IShouldDisplayActionFunction<ISelectionStandardized, ISelectionActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  if (!flags.showAccessPrivilegeFeature) return false;

  if (!areAllNotDeleted(items)) {
    return false;
  }

  if (items.length > 0 && items.every((item) => item.main_entity_type === 'users')) {
    return true;
  }

  const opItems = options?.['items'];

  if (opItems?.length > 0) {
    return true;
  }

  return false;
};

export const shouldDisplayDelivery: IShouldDisplaySelectionActionFunction = (
  action,
  index,
  user,
  entities,
): boolean => {
  if (!areAllNotDeleted(entities)) {
    return false;
  }
  return (
    flags.showDeliveryFeature &&
    entities.every((entity) => entity.main_entity_type === 'assets' || entity.main_entity_type === 'products')
  );
};
