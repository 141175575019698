import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { IBarChartData } from 'types';
import { formatDuration } from 'utils/date';
import { fillMissingItems } from './utils';

ChartJS.register(...registerables);
ChartJS.defaults.elements.point.radius = 0;
ChartJS.defaults.elements.point.hoverBackgroundColor = 'rgb(53, 162, 235)';

ChartJS.defaults.color = '#8ca6b7';
ChartJS.defaults.font = { family: 'var(--font-family-sans-serif)', size: 14, weight: '500' };

interface IBarChartProps {
  data: IBarChartData[];
  aspectRatio?: number;
}

const ScreeningHeatMap: React.FC<IBarChartProps> = ({ data, aspectRatio = 1.2 }) => {
  const options = {
    aspectRatio,
    responsive: true,
    hover: { intersect: false },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
        caretPadding: 4,
        callbacks: {
          title: () => {
            return '';
          },
          label: (context) => {
            const { raw = 0, label = '0s' } = context;

            return ['timecode: ' + label, raw + ' playback(s)'];
          },
        },
      },
    },
    scales: { yAxis: { min: 0, beginAtZero: true, ticks: { precision: 0 } } },
  };

  const d = fillMissingItems(data);

  const labels = d.map((d) => formatDuration(d.position, 'seconds'));

  const mdata = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'playback(s)',
        data: d.map((d) => d.count),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return <Line options={options} data={mdata} />;
};

export default ScreeningHeatMap;
