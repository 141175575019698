import { IConference } from 'types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { IConferenceActionName } from './types';
import { useDeleteAction } from './delete';
import { useCopyAction } from './copy';
import { useStatusActions } from './status-actions';
import { useAddAssetsAction } from './add-assets';
import { useAddContactsAction } from './add-contacts';

export type { IConferenceActionName } from './types';

export const useConferenceActions = (
  items: IConference[],
  options: IUseActionsOptionParam<IConference>,
): IUseActionsResponse<IConferenceActionName> => {
  const addAssetsAction = useAddAssetsAction(items, options);
  const addContactsAction = useAddContactsAction(items, options);
  const statusActions = useStatusActions(items, options);
  const copyAction = useCopyAction(items, options);
  const deleteAction = useDeleteAction(items, options);

  const actions = [statusActions, addAssetsAction, addContactsAction, copyAction, deleteAction];

  return useActions<IConference, IConferenceActionName>(actions, options, items);
};
