import React from 'react';
import { Title } from '@mantine/core';
import cx from 'classnames';
import './style.scss';
import { IStyled } from 'types';

export enum SectionMessageType {
  EMPTY = 'empty',
  SUCCESS = 'success',
}

const DEFAULTS: { [key in SectionMessageType]: { title: React.ReactNode; message: React.ReactNode } } = {
  [SectionMessageType.EMPTY]: {
    title: 'Uh-Oh!',
    message: 'It appears that this page is empty.',
  },
  [SectionMessageType.SUCCESS]: {
    title: 'Operation was succesful!',
    message: 'We did what we can and we did it right.',
  },
};

export interface ISectionMessageProps extends IStyled {
  intent?: SectionMessageType;
  title?: React.ReactNode;
  modalView?: boolean;
}

const SectionMessage: React.FC<ISectionMessageProps> = ({ intent, title, children, modalView }) => {
  return (
    <div className="section-message">
      <div className="section-message__content py-4 px-6">
        <div
          className={cx('section-message__img-emoji', {
            'section-message__img-emoji--empty': intent === SectionMessageType.EMPTY,
            'section-message__img-emoji--success': intent === SectionMessageType.SUCCESS,
          })}
        />
        <Title order={3} className={cx({ 'side-line': modalView })}>
          {title || (intent && DEFAULTS[intent]?.title)}
        </Title>
        <div className="section-message__text">{children || (intent && DEFAULTS[intent]?.message)}</div>
      </div>
    </div>
  );
};

export const EmptySectionMessage: React.FC = () => {
  return <SectionMessage intent={SectionMessageType.EMPTY} />;
};

export default SectionMessage;
