import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';

import { getConference, updateConference } from 'utils/apis/conference';
import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IAsset, IMm3Asset } from 'types';

import { shouldDisplayRemoveFromConference } from './actions-acl';
import { IAssetActionName } from './types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export const useRemoveFromConferenceAction = (
  assets: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IActionConfig<IAsset, IAssetActionName> => {
  const { toastStore, dialogStore } = useStore();
  const { code } = useParams<{ code: string }>();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Asset', assets.length);

  const onConfirm = useCallback(async () => {
    try {
      const assetIds = map(assets, 'id');
      if (!code || !assetIds.length) {
        return;
      }

      const conference = await getConference(code);
      const { asset_ids } = conference || {};
      const updatedConference = await updateConference({
        code,
        asset_ids: asset_ids?.filter((id) => !assetIds.includes(id)),
      });

      toastStore.success(`${entityWithCount} will be removed from this Conference`);
      await options?.onSuccess?.(IAssetActionName.REMOVE_FROM_CONFERENCE, updatedConference);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [assets, code, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from this Conference?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: IAssetActionName.REMOVE_FROM_CONFERENCE,
    shouldDisplay: shouldDisplayRemoveFromConference,
    icon: 'remove',
    title: 'Remove from Conference',
    intent: Intent.DANGER,
    handler,
  };
};
