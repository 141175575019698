import React from 'react';
import { Menu } from '@mantine/core';

import { ParametronSort } from 'helpers/filters/types';
import { ParametronStore } from 'store/parametron-store';

import cx from 'classnames';

export interface IProductAssetSortMenuProps {
  sort: string | ParametronSort;
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  searchStore?: ParametronStore;
}

export const ProductAssetSortMenu: React.FC<IProductAssetSortMenuProps> = ({ sort, handleSort, searchStore }) => {
  const { paramsAndFiltersCount = 0, parametron = null } = searchStore || {};
  const params = parametron?.data?.params || {};
  const hasFiltersApplied = !!paramsAndFiltersCount;

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        disabled={hasFiltersApplied || params.include_descendants}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.NAME}
        className={cx({ active: sort === ParametronSort.NAME })}
      >
        Name
      </Menu.Item>
    </Menu>
  );
};
