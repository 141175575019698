export enum IconAlias {
  PRODUCT = 'film',
  ASSET = 'media',
  ACTION_ADD_ASSETS = 'add',
  ACCESS_PRIVILEGE = 'shared-filter',
  ACCESS_PRIVILEGE_GRANT = 'unlock',
  CONTACT = 'person',
  SETTINGS = 'contrast',
  ACTION_RESTORE = 'automatic-updates',
  FILES = 'document',
  COLLECTION = 'layers',
  VIDEO = 'mobile-video',
  AUDIO = 'music',
  CONFERENCE = 'headset',
  EVENT = 'timeline-events',
  DELETE = 'trash',
  REMOVE = 'delete',
  ORGANIZATION = 'office',
  MOBILE_APP_SYNC = 'mobile-phone',
  GENERAL_INFO = 'properties',
  MENU = 'menu',
  CAST_CREW = 'star',
  RECOMMENDATION = 'share',
  EDIT = 'edit',
  REPLACE = 'exchange',
  NEWS = 'clean',
  SCREENING_ROOM = 'desktop',
  PRODUCER_PORTAL = 'th',
  DELIVERY = 'compressed',
  CONNECTION = 'link',
  ACCESS_GROUPS = 'group-item',
}
