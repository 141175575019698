import React from 'react';
import cx from 'classnames';
import { Loader } from '@mantine/core';
import { Intent, LoaderSize } from 'utils/ui';

import './style.scss';

export const LoaderWithText: React.FC<{ text: string }> = ({ text }) => (
  <ThumbnailPlaceholderWrapper>
    <div className="thumbnail__processing thumbnail__processing-bg">
      <Loader size={LoaderSize.SMALL} variant={Intent.PRIMARY} />
      <div className="ms-2">{text}</div>
    </div>
  </ThumbnailPlaceholderWrapper>
);

export const SmallLoader: React.FC<{ size?: number }> = ({ size }) => (
  <ThumbnailPlaceholderWrapper>
    <div className="thumbnail__processing thumbnail__processing-bg">
      <Loader size={size || LoaderSize.SMALL} variant={Intent.PRIMARY} />
    </div>
  </ThumbnailPlaceholderWrapper>
);

export const ThumbnailPlaceholderWrapper: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={cx('thumbnail__processing  thumbnail__processing-bg d-flex justify-content-center', className)}>
    {children}
  </div>
);
