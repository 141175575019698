import { IMobileAppSync } from 'types';
import { useAddProductsAction } from './add-products-action';
import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IGroupActionName } from 'utils/actions/common';
import { IMobileAppSyncActionConfig, IMobileAppSyncActionName } from './types';
import { useDeleteGroupAction } from 'utils/actions/common/delete-action';
import { useRestoreGroupAction } from 'utils/actions/common/restore-action';
import { useCopyAction } from 'utils/actions/common/copy-action';
import { useRecommendAction } from './recommend';

export const useMobileAppSyncActions = (
  entities: IMobileAppSync[] = [],
  options: IUseActionsOptionParam<IMobileAppSync>,
): IUseActionsResponse<IGroupActionName | IMobileAppSyncActionName> => {
  const addProductsAction = useAddProductsAction({
    entities,
    options,
  });
  const copyAction = useCopyAction(entities, options);
  const deleteMobileSyncAction = useDeleteGroupAction(entities, options);
  const restoreMobileAppSync = useRestoreGroupAction(entities, options);
  const recommendAction = useRecommendAction(entities, options);

  const actions = [recommendAction, addProductsAction, copyAction, deleteMobileSyncAction, restoreMobileAppSync];

  return useActions(actions as IMobileAppSyncActionConfig[], options, entities);
};
export { useAddProductsAction } from './add-products-action';
export { IMobileAppSyncActionName } from './types';
export { useCreateMobileSyncAction } from './create-mobile-app-sync';
