import React from 'react';
import { Scheduler } from 'components/scheduler';
import './style.scss';

const SchedulerPage: React.FC = () => {
  return (
    <div className="layout-products d-flex flex-column h-100 w-100">
      <Scheduler />
    </div>
  );
};

export default SchedulerPage;
