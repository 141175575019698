import React, { useCallback } from 'react';
import { isEqual } from 'lodash';
import cx from 'classnames';

import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';
import { isAssetIngesting } from 'utils/asset';
import { refreshPreviewIngestingDelay } from 'utils/constants';
import { useInterval } from 'utils/hooks/use-interval';
import { ITabValue, Tab, TabPanel } from 'components/tabs';
import { getIsTabDisabled } from 'utils/general';

export const useRefreshIngestingAsset = (
  asset: IAsset | IMm3Asset | undefined,
  fetchAsset: () => Promise<IAsset | IMm3Asset>,
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>,
): void => {
  const refresh = useCallback(
    async (id: number): Promise<void> => {
      if (!asset) return;
      if (!isAssetIngesting(asset)) {
        return clearInterval(id);
      }
      const newValue = await fetchAsset();
      setAsset((prev) => {
        return isEqual(prev, newValue) ? prev : newValue;
      });
    },
    [fetchAsset, setAsset, asset],
  );

  useInterval(refresh, refreshPreviewIngestingDelay);
};

export const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.WORKFLOW,
  DetailsPageTabs.NEWS,
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.AUDIO_TRACKS,
];

export const editableTabs = [DetailsPageTabs.METADATA, DetailsPageTabs.NEWS];

export const AssetDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
  hidden?: boolean;
  valueParser?: (value: ITabValue) => string;
}> = ({ value, activeTab, editModeEnabled, hidden = false, valueParser }) => {
  return (
    <Tab
      value={value}
      className={cx(`asset-details__tab`, {
        'asset-details__tab--active': activeTab === value,
      })}
      disabled={getIsTabDisabled(value, editableTabs, editModeEnabled)}
      hidden={hidden}
      valueParser={valueParser}
    />
  );
};

export const AssetTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="asset-details__tab-content h-100" content={content} />;
};
