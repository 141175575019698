import React, { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';

import { parseDataSize } from 'utils/general';
import { IAssetFile } from 'types';

import { getAssetIconName } from 'components/asset/asset-upload-components/utils';
import { MantineIcon } from 'utils/ui/icon';

interface IAssetUploadItemProps {
  file: IAssetFile;
  removeFile: (file: IAssetFile) => void;
}

export const AssetUploadItem: React.FC<IAssetUploadItemProps> = ({ file, removeFile }) => {
  const onRemove = useCallback(() => {
    removeFile(file);
  }, [file, removeFile]);

  const scrollTo = useCallback(() => {
    const el = document.getElementById(file?.id);
    el?.scrollIntoView?.({ behavior: 'smooth' });
  }, [file]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div onClick={scrollTo} className="d-flex asset-upload-components__upload-dashboard-item-form-link">
        <MantineIcon icon={getAssetIconName(file)} className="me-2" />
        <div className="asset-upload-components__upload-dashboard-filename text-truncate">
          <span>{file?.name}</span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span>{parseDataSize(file?.fileData?.size)}</span>
        <ActionIcon variant="subtle" color="gray.5" onClick={onRemove} className="ms-2">
          <MantineIcon icon="cross" />
        </ActionIcon>
      </div>
    </div>
  );
};

export default React.memo(AssetUploadItem);
