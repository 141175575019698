import { Pages } from 'utils/actions/types';
import { IFeatureFlag, ISubFeatureFlag, UserRoles } from 'types';
import { Routes } from 'utils/routes';
import { ISidebarSection } from 'store/profile-store';

const featureFlags: IFeatureFlag = {
  isMm3Assets: false,
  showCastCrewFeature: true,
  showBasketsFeature: true,
  showCollectionsFeature: true,
  showScreeningRoomFeature: false,
  showEventFeature: true,
  showConferenceFeature: true,
  showRecommendationFeature: true,
  showAssetRecommendationsFeature: true,
  showAccessPrivilegeFeature: true,
  showMobileSelectionsFeature: true,
  showMeetingsFeature: true,
  showProducerPortalFeature: false,
  showDeliveryFeature: false,
  showMediaBenchFeature: false,
  showAccessGroupFeature: false,
};

const subFeatureFlags: ISubFeatureFlag = {
  showCreateScreeningRoom: false,
  showNewsManageShowcaseAction: true,
  showEventLayout: false,
  showAutonomousShowcaseButtons: false,
  showCollectionImagesTab: false,
  showFutureEpisodesFeature: true,
  showListRecommendationOnWebsiteFeature: true,
  showRelatedProductsFeature: true,
};

export const flags = {
  ssoProvider: 'okta',
  ...featureFlags,
  ...subFeatureFlags,
};

export const mbImportAllowedRoles = [];

export const allowedRolesByRoute = {
  [Routes.LOCATIONS]: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
};

export const allowedProductPermissionsPages = {
  [Pages.RECOMMENDATIONS_LIST]: true,
  [Pages.RECOMMENDATIONS_DETAILS]: true,
  [Pages.ACCESS_PRIVILEGES_LIST]: true,
  [Pages.ACCESS_PRIVILEGES_DETAILS]: true,
};

// useMatches hook can be used to check if the current page is one of the allowed pages
export const getShowPermissions = (page?: Pages): boolean => {
  return Boolean(page && allowedProductPermissionsPages[page]);
};

export const hideSideBarFlags = {
  [Routes.SCREENING_ROOMS]: !flags.showScreeningRoomFeature,
  [Routes.CREATE_SCREENING_ROOM]: !flags.showScreeningRoomFeature,
  [ISidebarSection.fulfillment]: !flags.showDeliveryFeature,
  [Routes.DELIVERIES]: !flags.showDeliveryFeature,
  [Routes.CREATE_DELIVERY_PACKAGE]: !flags.showDeliveryFeature,
  [ISidebarSection.producerPortal]: !flags.showProducerPortalFeature,
  [Routes.PRODUCER_PORTAL]: !flags.showProducerPortalFeature,
  [Routes.CREATE_ACCESS_GROUP]: !flags.showAccessGroupFeature,
  [Routes.ACCESS_GROUPS]: !flags.showAccessGroupFeature,
};
