import React from 'react';
import { AmGenericNews } from '@mediafellows/mm3-types';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { IconAlias } from 'icons/icon-aliases';
import { isProcessingPreview } from 'utils/general';
import { formatDate, dateWithoutHours } from 'utils/date';
import { Classes } from 'utils/ui';

import './style.scss';

interface INewsSelectedItem {
  news: AmGenericNews;
}

export const NewsSelectedItem: React.FC<INewsSelectedItem> = ({ news }) => {
  const { title, preview_image, date } = news || {};

  return (
    <li className="news-selected-item text-truncate">
      <Thumbnail
        className="selected-tab-item__thumb"
        showType
        customTag="news"
        image={preview_image?.url}
        fallbackIconName={IconAlias.NEWS}
        placeholder={isProcessingPreview(news) && <SmallLoader />}
      />
      <div className="selected-tab-item__content text-truncate">
        <div className="text-truncate">{title}</div>
        <div className={Classes.TEXT_MUTED}>{formatDate(date, dateWithoutHours)}</div>
      </div>
    </li>
  );
};

export default NewsSelectedItem;
