import React from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { useDialogStore } from 'store/hooks';
import ShowcaseEditForm from 'components/showcase-edit-form/showcase-edit-form';
import { formatWebsiteTypes } from 'components/showcase-form/utils';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IListItem } from 'types';
import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { refresh } from 'components/action';
import { shouldDisplayEdit } from './acl';
import { IModalSize } from 'components/dialogs/types';

interface IUseEditShowcaseOptions extends IUseActionsOptionParam<UmGenericListShowcase> {
  items?: IListItem[];
}
export const useEditShowcaseAction = (
  [showcase]: UmGenericListShowcase[],
  options: IUseEditShowcaseOptions,
): IShowcaseActionConfig => {
  const { openModal } = useDialogStore();
  const { onSuccess = refresh, items, section } = options;
  const title = `Edit ${formatWebsiteTypes(showcase?.type)}`;
  const handler = (): void =>
    openModal({
      title,
      className: 'showcase-edit-form__container',
      size: IModalSize.XL,
      body: () => <ShowcaseEditForm showcase={showcase} section={section} refresh={onSuccess} items={items} />,
    });

  return {
    name: IShowcaseActionName.EDIT,
    shouldDisplay: shouldDisplayEdit,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
