import { IUseFormReturn } from 'helpers/form';
import { IRegistrationData } from 'pages/registration';

export interface IOrganizationRegistrationFields {
  organization_name: string;
  organization_legal_entity: string;
  organization_classification: string;
  organization_email: string;
  organization_phone_0_number?: string;
}

export interface IAddressFields {
  organization_legal_address_street: string;
  organization_legal_address_zip_code: string;
  organization_legal_address_city: string;
  organization_legal_address_state: string;
  organization_legal_address_country_id: string;
}

export interface IStepOrganizationProps {
  isVisible: boolean;
  onContinue: (formData: IOrganizationRegistrationFields) => void;
  onBack: () => void;
  form: IUseFormReturn<Partial<IRegistrationData>>;
}

export const keysOrganization = ['organization_name', 'organization_legal_entity', 'organization_classification'];

export const keysAddress = [
  'organization_legal_address_street',
  'organization_legal_address_zip_code',
  'organization_legal_address_city',
  'organization_legal_address_state',
  'organization_legal_address_country_id',
];
