import React, { useMemo, useCallback, useState } from 'react';
import { Tabs, Tooltip } from '@mantine/core';
import cx from 'classnames';

import { Carousel } from 'components/carousel';
import { AssetSelectedTabItem } from 'components/asset/asset-selected-tab-item';

import { AssetFilters, filterPreviewAssets, getAvailableTabs } from 'utils/asset';
import { IAsset, IProductAsset } from 'types';

export const ProductAssetsPreview: React.FC<{ assets?: IProductAsset[] }> = ({ assets }) => {
  const [assetFilter, setAssetFilter] = useState<AssetFilters>(AssetFilters.ALL);

  const marketingAssets = useMemo(() => filterPreviewAssets(assets || [], assetFilter), [assets, assetFilter]);

  const handleAssetFilterChange = useCallback((filter): void => {
    setAssetFilter(filter);
  }, []);

  const availableTabs = useMemo(() => getAvailableTabs(assets), [assets]);
  return (
    <div className="product-general-tab__preview-area">
      <Tabs
        className="product-general-tab__tabs h-100"
        value={assetFilter}
        onChange={handleAssetFilterChange}
        defaultValue={AssetFilters.ALL}
      >
        <Tabs.List>
          {availableTabs.map(({ filter, title }) => (
            <Tabs.Tab
              id={AssetFilters[filter]}
              value={AssetFilters[filter]}
              key={`tab_${filter}`}
              className={cx(`product-general-tab__tab muted-text`, {
                'product-general-tab__tab--active': assetFilter === AssetFilters[filter],
              })}
            >
              {title}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        <Tabs.Panel value={assetFilter} className="h-100">
          {marketingAssets.length > 0 && (
            <Carousel
              data={marketingAssets}
              entity="asset"
              customThumbnail={(entity) => (
                <Tooltip
                  label={(entity as IAsset)?.name}
                  withArrow
                  withinPortal
                  className="product-asset-preview__tooltip"
                >
                  <div className="h-100">
                    <AssetSelectedTabItem asset={entity as IAsset} />
                  </div>
                </Tooltip>
              )}
            />
          )}
          {marketingAssets.length === 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="product-general-tab__loading"> No assets of this type</div>
            </div>
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
