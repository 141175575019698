import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ProductDataSection } from 'components/product-data-section';
import { ISearchFilter } from 'types';
import { Pages } from 'utils/actions/types';

const AssetProductsTab: React.FC = observer(() => {
  const { assetId = '' } = useParams<{ assetId: string }>();
  const persistentFilters: ISearchFilter[] = useMemo(() => [['asset_ids', 'eq', assetId]], [assetId]);

  return (
    <ProductDataSection
      persistentFilters={persistentFilters}
      showParentsOnlyFilter={false}
      page={Pages.ASSET_PRODUCTS_TAB}
    />
  );
});

export { AssetProductsTab };
