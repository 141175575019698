import { map } from 'lodash';
import { IProduct } from 'types';

import { useSelectionDialog } from 'components/form-selection-items';

import { shouldDisplayMultiNotDeleted as shouldDisplay } from 'utils/actions/common/actions-acl';

import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';

export const useCreateSelectionAction = (items: IProduct[]): IProductActionConfig => {
  const [openSelectionDialog] = useSelectionDialog({ entityType: 'products', itemIds: map(items, 'id') });

  return {
    name: IProductActionName.CREATE_SELECTION,
    shouldDisplay,
    icon: 'select',
    title: 'Create selection',
    handler: openSelectionDialog,
  };
};
