import React from 'react';
import { observer } from 'mobx-react-lite';

import { Thumbnail } from 'components/thumbnail';
import { PreviewTab } from 'components/preview-tab';

import { useDataSectionStore } from 'store';
import { IListItem, IProduct } from 'types';
import { ProductPreviewTab } from 'components/product-preview-tab';
import { CollectionPreviewTab } from 'components/collection/collection-preview-tab';
import { Row } from 'components/label-value-pair';
import { formatDate } from 'utils/date';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { ItemTypes } from 'helpers/form/fields/select-helpers';

import './style.scss';

export const ListItemPreviewTab: React.FC<{}> = observer(() => {
  const { activeItem } = useDataSectionStore<IListItem>();

  if (!activeItem?.id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  if (activeItem.entity_type === ItemTypes.PRODUCT) {
    return <ProductPreviewTab overrideProduct={activeItem.entity as IProduct} />;
  }
  if (activeItem.entity_type === ItemTypes.LIST_COLLECTION) {
    return <CollectionPreviewTab collection={activeItem.entity as UmGenericListCollection} />;
  }

  return (
    <PreviewTab
      title={activeItem.meta?.title || ''}
      accessLevel="public"
      customThumbnail={<Thumbnail fallbackIconName="vertical-distribution" showType customTag="Separator" />}
    >
      <div className="collection-items-preview-tab__attributes">
        <Row label="Title" value={activeItem.meta?.title} />
        <Row label="Created at" rawLabel value={formatDate(activeItem.created_at)} />
        <Row label="Updated at" rawLabel value={formatDate(activeItem.updated_at)} />
      </div>
    </PreviewTab>
  );
});
