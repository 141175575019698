export const productPageViews: Record<string, string> = {
  productCount: 'v3/product/views/count__product_views',
  topViewers: 'v3/product/views/top__product_viewers',
  topDevices: 'v3/product/views/pie_chart__product_views__ua_os',
  productList: 'v3/product/views/det_list__product_viewers',
  productListExport: 'v3/product/views/det_list__product_viewers_export',
};

export const videoViews: Record<string, string> = {
  screeningCount: 'v3/product/screenings/count__assets_screenings',
  screeningTopViewers: 'v3/product/screenings/top__assets_viewers',
  screeningTopVideos: 'v3/product/screenings/top__assets_screened',
  screeningList: 'v3/product/screenings/det_list__assets_screenings',
  screeningListExport: 'v3/product/screenings/det_list__assets_screenings_export',
};

export const searchTermQueries: Record<string, string> = {
  searchCounter: 'v3::product::search::count',
  searchTopProduct: 'v3::product::search::top_products',
  searchTopTerms: 'v3::product::search::top_terms',
  searchTermList: 'v3::product::search::detailed_list',
  searchTermListExport: 'v3::product::search::detailed_list/export',
};
