import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  FilterCheckbox,
  FilterDateRange,
  FilterSelect,
  FilterSlider,
  FilterSwitch,
  FilterText,
  FilterTree,
} from 'helpers/filters/fields';
import { Switch } from '@mantine/core';
import { Model, ParametronSort } from 'helpers/filters/types';
import { toggleParam, submitFilters, ISubmitFiltersParams } from 'helpers/filters/helpers';
import { dynamicDataExecutorCache, Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { IResult } from '@mediafellows/chipmunk';
import { useStore } from 'store';

import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { organizationOptionsProvider, ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { defaultSearchParams } from 'utils/default-search-param';

import { AdditionalFiltersCmp } from 'components/product-filters/additional-filters';
import { useLocation } from 'react-router-dom';
import { IFilters } from 'components/product-filters/types';
import { LanguageSelector } from 'components/product-filters/language-selector';
import { chipmunk } from 'utils/chipmunk';
import { parseQueryParams } from 'utils/general';

const typeOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'type');

const accessOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'access_level');

const genreTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('genres', () =>
    chipmunk.run(({ action }) => action('pm.layer/motion_picture/standard/category', 'graph')),
  ),
  (data: IResult) =>
    unflattenTree(data.objects[0]['@graph'].map((g) => ({ label: g.name, value: g.id, ancestry: g.ancestry }))),
);

const defaultParamComponentValues = (queryParams): typeof defaultSearchParams => ({
  include_deleted: queryParams['include_deleted'] === true,
  sort: ParametronSort.UPDATED_AT,
});

const ProductFilters: React.FC<{ customSubmitFilter?: (obj: ISubmitFiltersParams) => void }> = observer(
  ({ customSubmitFilter = submitFilters }) => {
    const { dataSectionStore } = useStore();
    const location = useLocation();
    const { searchStore } = dataSectionStore;
    const queryParams = parseQueryParams(location.search);
    const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

    const handleSubmit = useCallback(
      (action): void => {
        customSubmitFilter?.({
          action,
          setParamComponentValues,
          customParams: defaultSearchParams,
        });
      },
      [customSubmitFilter],
    );

    const [filterValues, filterHandlers] = useFilters<IFilters>(dataSectionStore, handleSubmit);

    const handleToggleParam = (event): void => {
      toggleParam({
        event,
        paramComponentValues,
        setParamComponentValues,
      });
    };
    const labelMarks = [
      {
        value: 1946,
        label: '1946',
      },
      {
        value: 1996,
        label: '1996',
      },
    ];

    return (
      <div className="entity-filters">
        <DataSectionPreset filterHandlers={filterHandlers} />

        <div className="entity-filters__new-filter">
          <div className="entity-filters__title mb-3">
            <h4 className="mb-0">New Filter</h4>
          </div>

          <form>
            <FilterText
              label="Search List"
              placeholder="Search"
              name="_"
              filter={filterValues._}
              onChange={filterHandlers.onChange}
            />

            <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

            <FilterDateRange
              label="Updated at"
              name="updated_at"
              filter={filterValues.updated_at}
              onChange={filterHandlers.onChange}
            />
            <FilterDateRange
              label="Created at"
              name="created_at"
              filter={filterValues.created_at}
              onChange={filterHandlers.onChange}
            />

            <FilterSelect
              label="Owner"
              name="owner_id"
              placeholder="Select Owner"
              optionsProvider={ownersOptionsProvider}
              filter={filterValues.owner_id}
              onChange={filterHandlers.onChange}
            />
            <FilterSelect
              label="Owner Organization"
              name="owner_organization_id"
              placeholder="Select Organization"
              optionsProvider={organizationOptionsProvider}
              filter={filterValues.owner_organization_id}
              onChange={filterHandlers.onChange}
            />

            <FilterCheckbox
              label="Access"
              name="access_level"
              optionsProvider={accessOptionsProvider}
              filter={filterValues.access_level}
              onChange={filterHandlers.onChange}
              disabled={searchStore.running}
              aggregations={searchStore.aggregationValues('access_level')}
            />

            <FilterCheckbox
              label="Type"
              name="type"
              simplifyCheckboxLabel={true}
              optionsProvider={typeOptionsProvider}
              disabled={searchStore.running}
              filter={filterValues.type}
              onChange={filterHandlers.onChange}
              aggregations={searchStore.aggregationValues('type')}
            />
            {filterValues.no_parent && (
              <FilterSwitch
                label="Show parent products only"
                name="no_parent"
                disabled={searchStore.running}
                filter={filterValues.no_parent}
                onChange={filterHandlers.onChange}
                checkedValue={true}
                notCheckedValue={false}
              />
            )}

            <LanguageSelector filterValues={filterValues} filterHandlers={filterHandlers} />

            <FilterSlider
              label="Year of Production"
              name="default_layer.meta.year_of_production"
              min={1896}
              max={2040}
              marks={labelMarks}
              filter={filterValues['default_layer.meta.year_of_production']}
              onChange={filterHandlers.onChange}
            />

            <FilterTree
              label="Genre"
              name="default_layer.meta.category_ids"
              optionsProvider={genreTreeProvider}
              filter={filterValues['default_layer.meta.category_ids']}
              onChange={filterHandlers.onChange}
              aggregations={searchStore.aggregationValues('category_ids')}
            />

            <Switch
              label="Include deleted"
              data-param={'include_deleted'}
              onChange={handleToggleParam}
              checked={paramComponentValues.include_deleted}
            />
          </form>
        </div>
      </div>
    );
  },
);

export default ProductFilters;
