import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';
import { handleItemClick } from 'components/data-section';

import { CollectionDataSectionListItem } from './collection-data-section-list-item';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { ItemId } from 'types';

interface ICollectionDataSectionItemProps {
  overrideId?: ItemId | null;
  className?: string;
  collection: UmGenericListCollection;
  contextMenu?: React.ReactElement;
}

export const CollectionDataSectionItem: React.FC<ICollectionDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, collection, overrideId } = props;

  const id = overrideId || collection?.id;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e) => {
      if (!id) {
        return;
      }
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <CollectionDataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        collection={collection}
        isChecked={isChecked}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
