import { emptyValidation } from './empty';

/* value_inclusion validation
 *
 * examples:
 * validations: [ { value_inclusion: { in: LANGUAGES } } ]
 * validations: [ { value_inclusion: { in: LANGUAGES, allow_blank: true } } ]
 * validations: [ { value_inclusion: { in: PERMISSIONS, allow_blank: true } } ]
 * validations: [ { value_inclusion: { in: PROTECTION_LEVELS, allow_blank: true } } ]
 */

export interface IValueInclusionValidationOpts {
  in?: string[];
  within?: string[];
  allow_blank?: boolean;
}

export const valueInclusionValidation = (
  opts: IValueInclusionValidationOpts,
  value: string[] = [],
): [boolean, string?] => {
  const { allow_blank } = opts;
  const whitelist = opts.in || opts.within;
  const safeValue = value || [];
  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank }, safeValue);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  if (whitelist && Array.isArray(safeValue)) {
    const notMatching = safeValue.find((v) => {
      return whitelist.indexOf(v) < 0;
    });
    if (notMatching) {
      return [false, `This field is required`];
    }
  }

  return [true];
};
