export interface IAcceptanceValidationOpts {
  accept?: boolean;
}
export const acceptanceValidation = (opts: IAcceptanceValidationOpts, value: boolean): [boolean, string?] => {
  const { accept } = opts;

  if (accept && !value) {
    return [false, `needs to be accepted`];
  }

  return [true];
};
