import React, { useMemo } from 'react';
import cx from 'classnames';
import { slice } from 'lodash';
import { Thumbnail } from 'components/thumbnail';
import { UserAvatar } from 'components/user-avatar';
import { IMultiAvatar, IMultiComponent, IMultiThumbnail } from 'components/multi-component/types';

import './style.scss';

const MultiThumbnail: React.FC<IMultiThumbnail> = ({
  thumbnailImages,
  width = 104,
  height = 60,
  customTag,
  ...props
}) => {
  const imagesComponents = useMemo(() => {
    return thumbnailImages.map((thumbnailImage) => (
      <Thumbnail
        key={thumbnailImage.image}
        background={thumbnailImages.length > 1}
        image={thumbnailImage.image}
        assetType={thumbnailImage.assetType}
        customTag={customTag}
        showType={Boolean(customTag)}
      />
    ));
  }, [thumbnailImages, customTag]);

  return <MultiComponent {...{ width, height, customTag, ...props }} components={imagesComponents} />;
};

const MultiAvatar: React.FC<IMultiAvatar> = ({
  contacts = [],
  width = 60,
  height = 60,
  maxImagesCount = 3,
  ...props
}) => {
  const avatarComponents = useMemo(() => {
    return contacts.map((contact, index) => <UserAvatar key={`${contact.id}-${index}`} user={contact} />);
  }, [contacts]);

  return (
    <MultiComponent
      {...{ width, height, maxImagesCount, ...props }}
      className="multi-avatar__wrapper"
      components={avatarComponents}
    />
  );
};

const MultiComponent: React.FC<IMultiComponent> = ({
  maxImagesCount = 4,
  width = 60,
  height = 60,
  offsetBetweenElements = 5,
  className,
  components,
}) => {
  const reducedComponents = useMemo(() => slice(components, 0, maxImagesCount), [components, maxImagesCount]);

  const imageSizeDecreaseValue = useMemo(
    () => (reducedComponents.length - 1) * offsetBetweenElements,
    [reducedComponents.length, offsetBetweenElements],
  );

  return (
    <div className={cx('multi-component__wrapper', className)} style={{ width, height }}>
      {reducedComponents.map((component, index) => {
        const offset = offsetBetweenElements * index;
        return (
          <div
            className={cx('multi-component__single-element', {
              ['multi-component__single-element-shadow']: reducedComponents.length > 1,
              ['multi-component__single-element-darken']: index + 1 !== reducedComponents.length,
            })}
            style={{
              width: width - imageSizeDecreaseValue,
              height: height - imageSizeDecreaseValue,
              right: offset,
              top: offset,
            }}
            key={index}
          >
            {component}
          </div>
        );
      })}
    </div>
  );
};

export const MemoizedMultiThumbnail = React.memo(MultiThumbnail);

export const MemoizedMultiAvatar = React.memo(MultiAvatar);
