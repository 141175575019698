import { useCallback } from 'react';
import { map } from 'lodash';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { validateConnection } from 'utils/apis/delivery-connections';
import { pluralEntityInflect } from 'utils/general';
import { IConnectionActionName } from 'utils/actions/connection/types';

export const useValidateConnectionAction: IUseAction<DeliveriesGenericConnection, IConnectionActionName> = (
  items,
  options,
) => {
  const { toastStore } = useStore();
  const { entity, entityWithCount } = pluralEntityInflect('Connection', items.length);

  const validate = useCallback(async () => {
    toastStore.info('Validating connection...');
    try {
      await validateConnection(map(items, 'id'));
      toastStore.clearAll();
      toastStore.success(`${entityWithCount} validated`);
      return await options?.onSuccess?.(IConnectionActionName.VALIDATE);
    } catch (error) {
      toastStore.clearAll();
      toastStore.error(`${entity} validation failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  return {
    name: IConnectionActionName.VALIDATE,
    icon: 'changes',
    title: 'Validate',
    handler: validate,
  };
};
