import React from 'react';
import { Menu } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { ParametronSort } from 'helpers/filters/types';
import { useDataSectionStore } from 'store/hooks';

import cx from 'classnames';

export interface IProductAssetSortMenuProps {
  sort: string | ParametronSort;
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ProductRelatedSortMenu: React.FC<IProductAssetSortMenuProps> = observer(({ sort, handleSort }) => {
  const { searchStore } = useDataSectionStore();
  const { paramsAndFiltersCount } = searchStore || {};

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        disabled={Boolean(paramsAndFiltersCount)}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.DISPLAY_TITLE}
        className={cx({ active: sort === ParametronSort.DISPLAY_TITLE })}
      >
        Title
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION}
        className={cx({ active: sort === ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION })}
      >
        Year of Production
      </Menu.Item>
    </Menu>
  );
});
