import React, { useCallback } from 'react';

import { useStore } from 'store';

import { MobileAppSyncWizardContainer } from 'components/mobile-app-sync-wizard';
import { IUseCreateMobileSyncOptions } from 'utils/actions/mobile-app-sync/create-mobile-app-sync';
import { ItemId } from 'types';
import { IModalSize } from 'components/dialogs/types';

interface IUseMobileAppSyncDialogParams {
  productIds: ItemId[];
  options?: IUseCreateMobileSyncOptions;
}

export const useMobileAppSyncDialog = ({ productIds, options }: IUseMobileAppSyncDialogParams): [() => void] => {
  const { dialogStore } = useStore();

  const onSuccess = useCallback(() => {
    options?.onSuccess?.();
    dialogStore.close();
  }, [dialogStore, options]);

  const body = useCallback((): JSX.Element => {
    return <MobileAppSyncWizardContainer onSuccess={onSuccess} productIds={productIds} fitInParent />;
  }, [onSuccess, productIds]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Create Mobile Selection',
      body,
      fitInView: true,
      size: IModalSize.S,
    });
  };

  return [openDialog];
};
