import React, { useEffect, useState, useCallback } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Routes } from 'utils/routes';
import { Thumbnail } from 'components/thumbnail';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';

import DateElement from 'components/date-list-element/date-list-element';

import { loadRecommendationThumbnails } from 'utils/apis/recommendation';
import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { ListItemCountLink } from 'utils/ui';
import { Classes } from 'utils/ui';
import { formatType } from 'utils/format-type';
import { getRecommendationStatus } from 'components/recommendation/utils';

import { DetailsPageTabs } from 'types';

import './style.scss';

interface IRecommendationDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  recommendation: McGenericRecommendationProduct;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick: (e) => void;
}

const fallbackValue = '—';

export const RecommendationDataSectionListItem: React.FC<IRecommendationDataSectionListItemProps> = ({
  recommendation,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
}) => {
  const {
    owner,
    created_at,
    expires_at,
    id,
    subject,
    product_ids,
    asset_ids,
    requires_login,
    recipient_list,
    sent_at,
    updated_at,
    type,
  } = recommendation;

  const fetchThumbnails = useCallback(() => {
    return loadRecommendationThumbnails(recommendation);
  }, [recommendation]);

  const [itemChecked, setChecked] = useState(false);

  const [thumbnails = []] = useRemote(fetchThumbnails);

  const detailsPageLink = `${Routes.RECOMMENDATIONS}/${id}`;

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <>
      <div className="entity-list-item-row__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
      </div>
      <div className="entity-list-item-row__thumb">
        {thumbnails?.length ? (
          <MemoizedMultiThumbnail thumbnailImages={thumbnails} customTag={formatType(type)} />
        ) : (
          <Thumbnail showType image="" assetType={formatType(type)} />
        )}
      </div>
      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={subject}>
          <Link to={detailsPageLink}>
            <h4 className="text-nowrap text-truncate">{subject}</h4>
          </Link>

          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className={cx(`recommendation-list-item__attributes ${Classes.TEXT_MUTED}`)}>
          <div title="Status" className="text-truncate recommendation-list-item__status">
            {getRecommendationStatus(recommendation)}
          </div>
          <div title="Sent at" className="text-truncate recommendation-list-item__sent">
            <DateElement date={sent_at} mfxIcon="sent-at" />
          </div>
          <div title="Expires at" className="text-truncate recommendation-list-item__expires">
            <DateElement date={expires_at} mfxIcon="expires-at" />
          </div>
          <div title="Owner" className="text-truncate recommendation-list-item__owner">
            {formatFullName(owner, false)}
          </div>

          <div className="text-truncate recommendation-list-item__recipients">
            <ListItemCountLink
              entityCount={recipient_list?.length}
              link={`${detailsPageLink}/${DetailsPageTabs.RECIPIENTS}`}
              linkText="Recipient"
            />
          </div>
          <div className="text-truncate recommendation-list-item__products">
            <ListItemCountLink
              entityCount={product_ids?.length}
              link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
              linkText="Product"
            />
          </div>
          <div className="text-truncate recommendation-list-item__videos">
            <ListItemCountLink
              entityCount={asset_ids?.length}
              link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
              linkText="Asset"
            />
          </div>
          <div className="text-truncate recommendation-list-item__login">
            {requires_login ? 'Requires login' : fallbackValue}
          </div>
          <div title="Created at" className="text-truncate recommendation-list-item__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
          <div title="Updated at" className="text-truncate recommendation-list-item__updated">
            <DateElement date={updated_at} mfxIcon="updated-at" />
          </div>
        </div>
      </div>
    </>
  );
};
