import React, { useCallback } from 'react';

import { StatisticsTopCard } from 'components/statistic-top-card';

import { fetchTopViewers } from 'utils/apis/analytics';

import { IAnalyticsProps, IUseTopVideoViewerData, IFetchTopUsersEntity } from 'types/analytics';

interface IRecommendationAnalyticsTopUsers extends IAnalyticsProps {
  entityName?: IFetchTopUsersEntity;
  title?: string;
  statisticName?: string;
  interaction?: string;
  url?: string;
}
export const RecommendationAnalyticsTopUsers: React.FC<IRecommendationAnalyticsTopUsers> = (props) => {
  const { entityName, title = 'Top Recipients', statisticName = 'views', ...rest } = props;
  const fetcher: () => Promise<IUseTopVideoViewerData> = useCallback(
    () => fetchTopViewers(rest, entityName || 'recipient'),
    [entityName, rest],
  );

  return <StatisticsTopCard title={title} statisticName={statisticName} fetcher={fetcher} {...props} />;
};
