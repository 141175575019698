import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Popover } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useDisclosure } from '@mantine/hooks';

import { CalGenericLocation } from '@mediafellows/mm3-types';

import DateListElement from 'components/date-list-element';

import { formatLocationType, formatDateToTimezone } from './utils';
import { getContactName, placeholder } from 'utils/ui';
import { Classes } from 'utils/ui';

import './style.scss';

interface ILocationDataSectionItemProps {
  location: CalGenericLocation;
  contextMenu: React.ReactElement;
  handleClick: (e) => void;
  isChecked: boolean;
}
const renderTimezoneDate = (
  date: string,
  time_zone: string,
  opened?: boolean,
  close?: () => void,
  open?: () => void,
): JSX.Element => {
  return (
    <Popover opened={opened} withArrow>
      <Popover.Target>
        <span onMouseEnter={open} onMouseLeave={close}>
          <DateListElement date={formatDateToTimezone(date, time_zone)} />
        </span>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="p-1">{`Timezone: ${time_zone}`}</div>
      </Popover.Dropdown>
    </Popover>
  );
};

export const LocationsDataSectionItem: React.FC<ILocationDataSectionItemProps> = observer(
  ({ location, contextMenu, handleClick, isChecked }) => {
    const { id, name, address, meta, capacity, starts_at, ends_at, time_zone, hosts } = location || {};
    const [itemChecked, setItemChecked] = useState(isChecked);

    useEffect(() => {
      setItemChecked(isChecked);
    }, [isChecked]);

    const [opened, { close, open }] = useDisclosure(false);

    return (
      <div className="location-list-item" data-id={id} onClick={handleClick}>
        <div className="entity-list-item-row__checkbox">
          <Checkbox onChange={handleClick} checked={itemChecked} />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title">
            <h4 className="entity-list-item__name">{placeholder(name)}</h4>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className={`location-list-item__attributes ${Classes.TEXT_MUTED}`}>
            <span className="selections-list-item__location text-nowrap">{placeholder(address)}</span>
            <span className="selections-list-item__type text-nowrap">
              {placeholder(formatLocationType(meta?.location_type as string))}
            </span>
            <span className="selections-list-item__host text-nowrap">{placeholder(hosts?.map(getContactName))}</span>
            <span className="selections-list-item__capacity text-nowrap">{placeholder(capacity)}</span>
            <span className="selections-list-item__date-start text-nowrap">
              {renderTimezoneDate(starts_at, time_zone, opened, close, open)}
            </span>
            <span className="selections-list-item__date-end text-nowrap">{renderTimezoneDate(ends_at, time_zone)}</span>
          </div>
        </div>
      </div>
    );
  },
);
