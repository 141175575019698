import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';

import { DataSection, ICustomSortMenuProp } from 'components/data-section';
import { ToastError } from 'components/toast';
import { useProfileStore, useStore } from 'store';
import { chipmunk } from 'utils/chipmunk';
import { useAssetActionsOptions } from 'utils/hooks/action-options';
import { useRefreshDataSection } from 'utils/hooks';
import { parseOptionsToSearch } from 'utils/general';
import { assetListSchema } from 'utils/schemas';

import { AssetDataSectionItemWrapper, getDataItemComponent } from 'components/asset/asset-data-section-item';
import { AssetPreviewTab } from 'components/asset/asset-preview-tab';
import { AssetFilters, defaultAssetFilters, assetStats, IAssetFilters } from 'components/asset/asset-filters';
import { AssetSelectedTabItem } from 'components/asset/asset-selected-tab-item';
import { AssetActionsList } from 'components/action';
import { Pages, IUseActionsOptionParam, IActionName } from 'utils/actions/types';
import { IAsset, IMm3Asset, IProductAsset, ISearchFilter } from 'types';
import { IProductActionName } from 'utils/actions/product/types';
import { useQuery } from 'utils/url';
import { getAssetModel } from 'utils/asset';
import useControlDataSection from 'utils/hooks/control-data-section';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';
import { ISubmitFiltersParams } from 'helpers/filters/helpers';
import { IFiltersDefinition } from 'helpers/filters';
import { ActionsMenu } from 'components/action/utils';

import { handleUpdate, initQueryParams, useRefreshIngestingAssets, parseQueryParamsToFilters } from './utils';

const sidebarTabs = (
  customSubmitFilter?: (opt: ISubmitFiltersParams) => void,
  withMainAssetsFilter?: boolean,
): Record<string, React.ReactNode> => {
  return {
    preview: <AssetPreviewTab />,
    filters: <AssetFilters customSubmitFilter={customSubmitFilter} withMainAssetsFilter={withMainAssetsFilter} />,
    selectedItemRenderer(asset) {
      return <AssetSelectedTabItem asset={asset} />;
    },
  };
};

interface IAssetsDataSectionProps {
  executor?: (opts: IActionOpts) => Promise<IResult>;
  persistentFilters?: ISearchFilter[];
  page?: Pages;
  actionsOptions?: Partial<IUseActionsOptionParam>;
  setProductAssets?: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
  headerRightSection?: React.ReactElement;
  customSortMenu?: ICustomSortMenuProp;
  defaultParams?: IDataSectionDefaultParams;
  customSubmitFilter?: (obj: ISubmitFiltersParams) => void;
  customOrderRightSection?: boolean;
  initFilters?: Partial<IAssetFilters>;
  persistFilters?: boolean;
}

export type IQueryFields = Pick<IMm3Asset, 'file_status' | 'created_at' | 'main_classification' | 'type'>;
const fields: (keyof IQueryFields)[] = ['created_at', 'main_classification', 'file_status', 'type'];

const defaultExecutor = (options: IActionOpts): Promise<IResult<IAsset>> =>
  chipmunk.run(({ action }) => action<IAsset>(getAssetModel(), 'search', options));

const AssetsDataSection: React.FC<IAssetsDataSectionProps> = observer((props) => {
  const {
    executor = defaultExecutor,
    page = Pages.LIST,
    persistentFilters,
    actionsOptions,
    setProductAssets,
    defaultParams,
    customSubmitFilter,
    customOrderRightSection = false,
    initFilters,
    persistFilters,
  } = props;
  const {
    dataSectionStore: { checked, searchStore },
    toastStore,
  } = useStore<IMm3Asset>();

  const layout = useProfileStore(({ computed }) => computed.layout);

  const queryParams = useQuery<IQueryFields>(fields);

  const exec = useCallback(
    async (options: IActionOpts) => {
      try {
        const result = await executor(parseOptionsToSearch(options, fields));

        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
        const objects: IAsset[] = [];
        return { objects, object: objects[0] };
      }
    },
    [executor, toastStore],
  );

  useControlDataSection({
    stats: assetStats,
    schema: assetListSchema,
    executor: exec,
    model: getAssetModel(),
    filters: persistentFilters,
    persistFilters,
    defaultFilters: defaultAssetFilters,
    initFilters: initFilters as IFiltersDefinition,
    defaultParams,
    queryParams: queryParams as Record<string, string>,
    handleUpdate,
    initQueryParams,
    parseQueryParamsToFilters,
  });

  useRefreshIngestingAssets(searchStore);

  const refreshDataSection = useRefreshDataSection();

  const onSuccess = useCallback(
    async (action?: IActionName): Promise<void> => {
      if (action === IProductActionName.REMOVE_FROM_GROUP) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection],
  );

  const AssetItemComponent = useMemo(() => getDataItemComponent(layout), [layout]);

  const customOptions = useAssetActionsOptions();
  const itemRenderer = useCallback(
    (asset) => {
      return (
        <AssetDataSectionItemWrapper
          key={asset.id}
          asset={asset}
          page={page}
          setProductAssets={setProductAssets}
          contextMenu={
            <ActionsMenu
              component={AssetActionsList}
              items={[asset]}
              options={{ onSuccess, page, ...customOptions, ...(actionsOptions || {}), context: 'single' }}
            />
          }
          component={AssetItemComponent}
        />
      );
    },
    [page, setProductAssets, onSuccess, customOptions, actionsOptions, AssetItemComponent],
  );

  return (
    <DataSection
      defaultParams={defaultParams}
      customSortMenu={props.customSortMenu}
      itemRenderer={itemRenderer}
      contextMenu={
        <ActionsMenu
          component={AssetActionsList}
          items={checked}
          options={{ onSuccess, page, ...customOptions, ...(actionsOptions || {}), context: 'multi' }}
        />
      }
      headerRightSection={props.headerRightSection}
      tabs={sidebarTabs(customSubmitFilter, Boolean(initFilters?.parent_id))}
      layout={layout}
      customOrderRightSection={customOrderRightSection}
    />
  );
});

export default AssetsDataSection;
