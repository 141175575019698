import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';
import { ActionIcon } from '@mantine/core';

import { DraggableType } from 'components/data-section-selected-tab';
import useSelectedOption from 'utils/hooks/selected-option';
import { IEntity, IProduct } from 'types';
import { ICard } from 'components/sort-data-section/sort-data-section';
import { MantineIcon } from 'utils/ui/icon';

export interface IDraggableProductProps {
  item: IItem;
  moveCard: (id: string, to: number) => void;
  moveByClick: (card: ICard, direction: 'up' | 'down') => void;
  findCard: (id: string) => { index: number };
  removeSelected: (card: ICard) => void;
  maxLength: number;
  selectedItemRenderer?: (item: IItem) => React.ReactElement;
}

type IItem = {
  type: string;
  id: string;
  originalIndex: string;
} & IEntity;

export const SelectedTabItem: React.FC<IDraggableProductProps> = (props) => {
  const { item, moveCard, findCard, moveByClick, maxLength, removeSelected, selectedItemRenderer } = props;

  const { id } = item;
  const [canDrag, setCanDrag] = useState(true);
  const card = findCard(id as string);

  const originalIndex = card?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DraggableType.PRODUCT, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: DraggableType.PRODUCT,
    canDrop: () => false,
    hover({ id: draggedId }: IItem) {
      if (draggedId === id) {
        return;
      }

      const { index: overIndex } = findCard(id);
      moveCard(draggedId, overIndex);
    },
  });

  const handleRef = (node): void => {
    drag(drop(node));
  };

  const handleClickUp = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    moveByClick(card, 'up');
  };

  const handleClickDown = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    moveByClick(card, 'down');
  };

  const toggleCanDrag = (): void => {
    setCanDrag(!canDrag);
  };

  const handleRemoveClick = (): void => {
    removeSelected(card);
  };
  const selectedOption = useSelectedOption({ value: (item as IProduct)?.access_level });
  const showAccessLevel = !['user', 'organization'].includes(item['@type']);
  return (
    <div
      className={cx(
        'selected-tab-item',
        { 'selected-tab-item--is-dragging': isDragging },
        {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption) && showAccessLevel,
        },
      )}
      ref={handleRef}
    >
      <div className="selected-tab-item__arrows" onMouseEnter={toggleCanDrag} onMouseLeave={toggleCanDrag}>
        <div
          className={cx('selected-tab-item__arrow-btn', {
            'selected-tab-item__arrow-btn--disabled': originalIndex === 0,
          })}
          onClick={handleClickUp}
        >
          <MantineIcon icon="chevron-up" />
        </div>

        <div
          className={cx('selected-tab-item__arrow-btn', {
            'selected-tab-item__arrow-btn--disabled': originalIndex === maxLength - 1,
          })}
          onClick={handleClickDown}
        >
          <MantineIcon icon="chevron-down" />
        </div>
      </div>

      {selectedItemRenderer && selectedItemRenderer(item)}

      <div className="selected-tab-item__delete">
        <ActionIcon
          onMouseEnter={toggleCanDrag}
          onMouseLeave={toggleCanDrag}
          onClick={handleRemoveClick}
          variant="subtle"
          color="gray.5"
        >
          <MantineIcon icon="cross" />
        </ActionIcon>
      </div>
    </div>
  );
};
