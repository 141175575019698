import { ISelectionStandardized } from 'types';

import { useAccessChangeDialog } from 'components/selection-access-change-form';
import { IUseActionsOptionParam } from 'utils/actions';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common/actions-acl';
import { ISelectionActionConfig, ISelectionActionName } from './types';

export const useChangeAccessAction = (
  items: ISelectionStandardized[],
  options: IUseActionsOptionParam,
): ISelectionActionConfig => {
  const [openDialog] = useAccessChangeDialog(items, options);

  return {
    name: ISelectionActionName.CHANGE_ACCESS,
    shouldDisplay: shouldDisplaySingleNotDeleted,
    icon: 'key',
    title: 'Change access',
    handler: openDialog,
  };
};
