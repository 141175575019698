import React from 'react';

import { FormInput, FormSelect } from 'helpers/form';
import { IAccessPrivilegeFormComponent } from './types';

export const keysGeneral = ['name', 'access_level'];

export const AccessPrivilegeGrantFormGeneral: React.FC<IAccessPrivilegeFormComponent> = ({ form }) => {
  return (
    <div className="d-flex flex-column">
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <FormSelect name="access_level" label="Access Level" large {...form.formData.access_level} {...form.handlers} />
    </div>
  );
};
