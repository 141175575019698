import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { DetailsPageTabs, IAsset, IAssetAggregation, IMm3Asset } from 'types';
import { formatDuration } from 'utils/date';
import { getEntityType, formatAssetType, formatOwnership } from 'utils/general';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { AssetTypeAggregations } from 'components/asset/assets-aggregation';
import { Routes } from 'utils/routes';

import { useDivisions } from 'utils/hooks/use-divisions';

interface IAssetGeneralTabAboutSectionProps {
  asset?: IAsset | IMm3Asset;
  subAssetsAggregations?: IAssetAggregation;
}

export const AssetOldGeneralTabAboutSection: React.FC<{ asset: IAsset }> = ({ asset }) => {
  const { classification, duration, name, division_ids, organization } = asset || {};
  const { divisionsNames } = useDivisions({ division_ids });
  return (
    <InfoBoxV2 gridTemplateColumns="minmax(135px, 1fr) 2fr" title="About" wrapperClassName="mt-3">
      <Row label="Name" value={name} size="m" />
      <Row label="File Type" value={getEntityType(classification)} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <Row label="Ownership" hideIfNoValue value={formatOwnership(asset)} size="m" />
      <Row label="organization" hideIfNoValue value={organization?.name} size="m" />
      <Row label="divisions" hideIfNoValue value={divisionsNames} size="m" />
      <Row label="duration" hideIfNoValue value={formatDuration(duration, 'seconds')} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMm3GeneralTabAboutSection: React.FC<{
  asset: IMm3Asset;
  subAssetsAggregations: IAssetAggregation;
}> = ({ asset, subAssetsAggregations }) => {
  const { classification, id, name, meta, owner_organization, main_classification, division_ids } = asset || {};
  const { duration } = meta || {};

  const { divisionsNames } = useDivisions({ division_ids });

  return (
    <InfoBoxV2 gridTemplateColumns="minmax(135px, 1fr) 2fr" title="About" wrapperClassName="mt-3">
      <Row label="Name" value={name} />
      <Row label="File Type" value={getEntityType(classification)} />
      <Row label="Asset Type" value={formatAssetType(classification)} />
      <Row
        label="Sub-Assets"
        value={
          <AssetTypeAggregations
            aggregations={subAssetsAggregations}
            baseUrl={`${Routes.ASSETS}/${id}/${DetailsPageTabs.SUB_ASSETS}`}
            muted
          />
        }
        hidden={main_classification !== AssetFilters.VIDEO}
      />
      <Row label="Ownership" hideIfNoValue value={formatOwnership(asset)} />
      <Row label="organization" hideIfNoValue value={owner_organization?.name} />
      <Row label="divisions" hideIfNoValue value={divisionsNames} />
      <Row label="duration" hidden={!duration} value={formatDuration(duration, 'seconds')} />
    </InfoBoxV2>
  );
};

export const AssetGeneralTabAboutSection = withAssetVersion(
  AssetOldGeneralTabAboutSection,
  AssetMm3GeneralTabAboutSection,
) as React.FC<IAssetGeneralTabAboutSectionProps>;
