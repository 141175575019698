import { useCallback } from 'react';

import { IConference } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

import { shouldDisplayDelete as shouldDisplay } from './actions-acl';

import { IConferenceActionConfig, IConferenceActionName } from 'utils/actions/conference/types';
import { deleteConference } from 'utils/apis/conference';

import { pluralEntityInflect } from 'utils/general';

export const useDeleteAction = (items: IConference[], options: IUseActionsOptionParam): IConferenceActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Conference', items.length);

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }
      await deleteConference(items.map(({ code }) => code));
      toastStore.success(`${entityWithCount} deleted`);
      await options?.onSuccess?.(IConferenceActionName.DELETE);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback((): void => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: IConferenceActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
