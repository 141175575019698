import React from 'react';

import { formatDate } from 'utils/date';
import { MantineIcon } from 'utils/ui';

import { CopyUsersList, RecipientList } from './utils';
import { flags } from 'utils/flags';

import { IRecommendationFormProps } from './types';

import './style.scss';

export const RecommendProductsStepSummary: React.FC<IRecommendationFormProps & { isEditingRecipients?: boolean }> = ({
  form: { values },
  isEditingRecipients,
  children,
}) => {
  const {
    allowed_views,
    expires_at,
    message,
    requires_login,
    subject,
    recipient_list,
    contact_selections,
    cc_contact_selections,
    cc_list,
    bcc_list,
    bcc_contact_selections,
    visible,
  } = values || {};

  return (
    <div className="product-recommendation_summary__wrapper">
      <div className="product-recommendation_summary">
        <section className="product-recommendation_summary--section">
          <p className="wizard_summary__label">Subject</p>
          <p className="text-truncate wizard_summary__value--border">{subject || '—'}</p>
          <p className="wizard_summary__label">Recipient(s)</p>
          <div className="wizard_summary__value--border">
            <RecipientList
              recipient_list={recipient_list}
              contact_selections={contact_selections}
              isEditingRecipients={isEditingRecipients}
            />
          </div>
          <p className="wizard_summary__label">CC List</p>
          <div className="wizard_summary__value--border">
            <CopyUsersList
              selectionList={cc_contact_selections}
              usersList={cc_list}
              isEditingRecipients={isEditingRecipients}
            />
          </div>
          <p className="wizard_summary__label">BCC List</p>
          <div className="wizard_summary__value--border">
            <CopyUsersList
              selectionList={bcc_contact_selections}
              usersList={bcc_list}
              isEditingRecipients={isEditingRecipients}
            />
          </div>
          <p className="wizard_summary__label">Message</p>
          <span className="wizard_summary__message">
            {message?.split('\n').map((line, i) => <p key={i}>{line}</p>) ?? '—'}
          </span>
        </section>
        <section className="product-recommendation_summary--section">
          <p className="wizard_summary__label">Expires at</p>
          <p className="wizard_summary__value--border">{formatDate(expires_at) || '—'}</p>

          <p className="wizard_summary__label">Allowed Views</p>
          <p className="wizard_summary__value--border">{allowed_views ?? 'Unlimited'}</p>

          <p className="wizard_summary__label">Require Login</p>
          <p className="wizard_summary__value--border">
            <MantineIcon icon={requires_login ? 'tick' : 'cross'} fill={requires_login ? 'green' : 'red'} />
          </p>
          {flags.showListRecommendationOnWebsiteFeature && (
            <>
              <p className="wizard_summary__label">List on website</p>
              <p className="wizard_summary__value--border">
                <MantineIcon icon={visible ? 'tick' : 'cross'} fill={visible ? 'green' : 'red'} />
              </p>
            </>
          )}
          {children}
        </section>
      </div>
    </div>
  );
};
