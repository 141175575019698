import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { IFiltersDefinition, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { useStore } from 'store';
import startCase from 'lodash/startCase';

import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';

export interface ILocationsFilters extends IFiltersDefinition {
  _: QFilter;
  host_ids: InFilter;
  location_type: InFilter;
  starts_at: RangeFilter;
  ends_at: RangeFilter;
}

const typeOptionsProvider = new Mm3ContextDataProvider(Model.LOCATIONS, 'meta.location_type', startCase);
const handleSubmit = (action): void => submitFilters({ action });

export const LocationsFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;

  const [filterValues, filterHandlers] = useFilters<ILocationsFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search Locations"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Host"
            name="host_ids"
            placeholder="Select host"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues?.host_ids}
            onChange={filterHandlers?.onChange}
          />

          <FilterSelect
            label="Location Type"
            name="location_type"
            placeholder="Select Location type"
            optionsProvider={typeOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.location_type}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('location_type')}
          />

          <FilterDateRange
            label="Start at"
            name="starts_at"
            filter={filterValues.starts_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Ends at"
            name="ends_at"
            filter={filterValues.ends_at}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});
