import { shouldDisplaySingleNotDeleted } from 'utils/actions/common/actions-acl';
import { flags } from 'utils/flags';
import { IShouldDisplayGroupActionFunction } from 'utils/actions/common/types';

export const shouldDisplayRecommendMobileSelection: IShouldDisplayGroupActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return shouldDisplaySingleNotDeleted(action, index, user, entities, options) && flags.showRecommendationFeature;
};
