import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { IAccessPrivilege } from 'types';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { AccessPrivilegeDataSectionListItem } from './access-privilege-data-section-list-item';

interface IAccessPrivilegeDataSectionItemProps {
  className?: string;
  accessPrivilege: IAccessPrivilege;
  contextMenu?: React.ReactElement;
}

const AccessPrivilegeDataSectionItem: React.FC<IAccessPrivilegeDataSectionItemProps> = observer((props) => {
  const { contextMenu, accessPrivilege } = props;
  const { id } = accessPrivilege;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <AccessPrivilegeDataSectionListItem
      contextMenu={contextMenu}
      handleClick={handleClick}
      accessPrivilege={accessPrivilege}
      isChecked={isChecked}
      isCheckedDisabled={isAllItemsSelected}
      isActive={isActive}
    />
  );
});

export default AccessPrivilegeDataSectionItem;
