import React from 'react';

import { ProductDataSection } from 'components/product-data-section';
import { DataSectionPage } from 'components/layout';

import { Pages } from 'utils/actions';
import { EntityExportButton } from 'components/entity-export';
import { exportProducts } from 'utils/apis/product';

const Products: React.FC = () => {
  return (
    <DataSectionPage>
      <ProductDataSection
        page={Pages.LIST}
        persistFilters
        headerRightSection={<EntityExportButton entity="products" apiCall={exportProducts} />}
      />
    </DataSectionPage>
  );
};

export default Products;
