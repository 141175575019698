import React from 'react';
import { Popover } from '@mantine/core';

import { DetailsPageTabs, IDataSectionLayout, IProduct, ItemId } from 'types';
import { Routes } from 'utils/routes';
import { ProductsLink } from 'components/product';

import { AssetDataSectionListItem } from './asset-data-section-list-item';
import { AssetDataSectionTilesItem } from './asset-data-section-tiles-item';
import { IAssetDataSectionWrappedComponent } from './types';
import { formatField } from 'utils/general';
import { useDisclosure } from '@mantine/hooks';

type IProtectionLevel = ('watermark' | '2fa' | 'drm')[] | string[] | null;

export const getDataItemComponent = (layout: IDataSectionLayout): React.FC<IAssetDataSectionWrappedComponent> => {
  return layout === 'tiles' ? AssetDataSectionTilesItem : AssetDataSectionListItem;
};

export const productAssetLink = (assetId?: ItemId | null, products?: IProduct[]): React.ReactNode => {
  if (!assetId) {
    return <>-</>;
  }
  return <ProductsLink url={`${Routes.ASSETS}/${assetId}/${DetailsPageTabs.PRODUCTS}`} products={products} />;
};

export const AssetProtectionlevels: React.FC<{ protectionLevels?: IProtectionLevel }> = ({ protectionLevels }) => {
  const [opened, { close, open }] = useDisclosure(false);

  const formattedProtections = formatField(protectionLevels, 'no protections');
  if (!protectionLevels?.length) {
    return (
      <div className="text-truncate asset-list-item-row__protection-levels text-nowrap fst-italic">
        {formattedProtections}
      </div>
    );
  }

  return (
    <Popover opened={opened}>
      <Popover.Target>
        <div
          onMouseEnter={open}
          onMouseLeave={close}
          className="text-truncate asset-list-item-row__protection-levels text-nowrap"
        >
          {formattedProtections}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="p-2">{formattedProtections}</div>
      </Popover.Dropdown>
    </Popover>
  );
};
