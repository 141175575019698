import React from 'react';

import { IDeliveryFormProps } from 'components/delivery/types';
import { FormAssets } from 'helpers/form';

export const DeliveryAssetsStep: React.FC<IDeliveryFormProps> = ({ deliveryForm: { formData, handlers } }) => {
  return (
    <div className="asset-recommendation-form h-100">
      <FormAssets
        name="asset_ids"
        label="Assets"
        fitInParent
        large
        omni
        {...formData.asset_ids}
        {...handlers}
        required
      />
    </div>
  );
};
