import React, { useCallback } from 'react';

import { useStore } from 'store';
import { ToastError } from 'components/toast';

import { restart } from 'utils/apis/workflow';
import { IWorkflowActionConfig, IWorkflowActionName } from 'utils/actions/workflow/types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IWorkflow } from 'types';

import { shouldDisplayRestart } from './acl';

export const useRestartAction = (
  items: IWorkflow[],
  options: IUseActionsOptionParam<IWorkflow>,
): IWorkflowActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const handleRestart = useCallback(async () => {
    try {
      const itemId = items?.[0]?.id;
      await restart(itemId);
      await options?.onSuccess?.(IWorkflowActionName.RESTART);

      toastStore.success('workflow is restarted!');
    } catch (error) {
      toastStore.error(
        <ToastError error={error} placeholder="Sorry, something went wrong, could not restart workflow..." />,
      );
      await options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Restart Workflow',
      body: `Are you sure you want to mark this workflow as resolved?`,
      onConfirm: handleRestart,
    });
  }, [dialogStore, handleRestart]);

  return {
    name: IWorkflowActionName.RESTART,
    icon: 'confirm',
    title: 'Mark as Resolved',
    shouldDisplay: shouldDisplayRestart,
    handler,
  };
};
