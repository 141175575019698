import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';

type IItemId = number | string;

export const removeElementFromSelection = async (
  group_ids: IItemId[] | IItemId,
  item_ids: IItemId[],
  target: Model,
): Promise<void> => {
  if (!item_ids?.length || !group_ids) {
    return;
  }

  return chipmunk.run(({ action }) => {
    return action(target, 'collection.delete', {
      params: { group_ids, item_ids },
    });
  });
};

export const removeContactsFromSelection = (group_id: IItemId, user_ids: IItemId[]): Promise<void> => {
  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'remove_users', {
      params: { group_id, user_ids },
    });
  });
};

export const restoreSelection = (group_ids: IItemId[] | IItemId): Promise<void> => {
  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'collection.restore', {
      params: { group_ids },
    });
  });
};
