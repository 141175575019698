import { IAsset, IMm3Asset } from 'types';

import { shouldDisplayDelivery as shouldDisplay } from './actions-acl';
import { IAssetActionName, IAssetActionConfig } from './types';
import { useAddToDeliveryDraftDialog } from 'components/add-to-delivery-draft';
import { getValidIds } from 'utils/general';

export const useAddToDraftAction = (assets: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const [handler] = useAddToDeliveryDraftDialog({
    asset_ids: getValidIds(assets),
  });

  return {
    name: IAssetActionName.ADD_TO_DELIVERY,
    icon: 'cube-add',
    title: 'Add to Delivery Package',
    shouldDisplay,
    handler,
  };
};
