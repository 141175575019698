import React from 'react';
import { pick, omit } from 'lodash';

import { useDialogStore, useStore } from 'store';

import { useGenreForm } from 'components/genres/genre-form/use-genre-form';
import { ToastError } from 'components/toast';

import { updateGenre } from 'utils/apis/genre';
import { IUseActionsOptionParam } from 'utils/actions';
import { delay } from 'utils/delay';
import { ingestPreviewImage } from 'utils/apis/preview-image';

import { IGenreActionConfig, IGenreActionName } from './types';
import { ICategory } from 'types';
import { IGenreFormData } from 'components/genres/genre-form/genre-form';

export const useEditGenreAction = (
  genres: ICategory[],
  options: IUseActionsOptionParam<ICategory>,
): IGenreActionConfig => {
  const { toastStore } = useStore();
  const { close } = useDialogStore();
  const handleSubmit = async (values: IGenreFormData): Promise<ICategory | void> => {
    try {
      let previewImage;
      const { previewImageUrl } = values || {};
      if (previewImageUrl && previewImageUrl !== genres[0]?.preview_image?.url) {
        previewImage = await ingestPreviewImage(values.previewImageUrl);
      }
      const omitValues = omit(values, ['previewImageUrl', 'preview_image']);
      const genre = await updateGenre({ ...omitValues, preview_image_id: previewImage?.id });
      toastStore.success('Genre has been edited successfully!');
      await delay();
      await options?.onSuccess?.(IGenreActionName.EDIT, genre);
      close();
      return genre;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  };

  const handler = useGenreForm({
    title: 'Edit Genre',
    genre: pick(genres[0], ['id', 'name', 'sequence_no', 'color', 'invisible', 'preview_image']) as ICategory,
    handleSubmit,
  });

  return {
    name: IGenreActionName.EDIT,
    shouldDisplay: () => true,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
