import { IConference } from 'types';
import { IShouldDisplayActionFunction } from 'utils/actions/types';
import { IConferenceActionName } from './types';

type IShouldDisplayProductActionFunction = IShouldDisplayActionFunction<IConference, IConferenceActionName>;

export const shouldDisplayCopy: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return options.context === 'single';
};

export const shouldDisplayDelete: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0;
};

export const shouldDisplayAddEntity: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return options?.context === 'single' && entities?.length > 0;
};

export const shouldDisplayConferenceActions: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options?.context === 'single' && entities?.length === 1 && parseInt(user?.id ?? '0', 10) === entities[0]?.owner_id
  );
};
