import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { FormInput, FormMultiSelect } from 'helpers/form';
import type {
  IAssetMm3MetadataEditTabProps,
  IAssetOldMetadataEditTabProps,
} from 'components/asset/edit-sections/types';
import { getMm3Tags, getTags } from './utils';
import { withAssetVersion } from 'utils/asset';

export const AssetOldMetadataEditIdentifiersSection: React.FC<IAssetOldMetadataEditTabProps> = ({ form }) => {
  const { formData, handlers } = form;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Identifiers">
      <Row
        label="Tags"
        value={
          <FormMultiSelect
            name="tags"
            placeholder="Add Tags"
            allowNewItems
            {...formData.tags}
            {...handlers}
            fetchValues={getTags}
            noResults={null}
            large
          />
        }
        size="m"
      />
      <Row
        label="External Reference"
        value={
          <FormInput
            name="external_reference"
            placeholder="Type External Reference"
            {...formData.external_reference}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Asset Identifier"
        value={
          <FormInput
            name="asset_identifier"
            placeholder="Type Asset Identifier"
            {...formData.asset_identifier}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Archive Number"
        value={
          <FormInput
            name="archive_number"
            placeholder="Type Archive Number"
            {...formData.archive_number}
            {...handlers}
          />
        }
        size="m"
      />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataEditIdentifiersSection: React.FC<IAssetMm3MetadataEditTabProps> = ({ form }) => {
  const { formData, handlers } = form;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Identifiers">
      <Row
        label="Tags"
        value={
          <FormMultiSelect
            name="tags"
            placeholder="Add Tags"
            allowNewItems
            {...formData.tags}
            {...handlers}
            fetchValues={getMm3Tags}
            noResults={null}
            large
          />
        }
        size="m"
      />
      <Row
        label="External Reference"
        value={
          <FormInput
            name="external_reference"
            placeholder="Type External Reference"
            {...formData.external_reference}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Asset Identifier"
        value={
          <FormInput
            name="meta.asset_identifier"
            placeholder="Type Asset Identifier"
            {...formData.meta?.asset_identifier}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Archive Number"
        value={
          <FormInput
            name="meta.archive_number"
            placeholder="Type Archive Number"
            {...formData.meta?.archive_number}
            {...handlers}
          />
        }
        size="m"
      />
    </InfoBoxV2>
  );
};

export const AssetMetadataEditIdentifiersSection = withAssetVersion(
  AssetOldMetadataEditIdentifiersSection,
  AssetMm3MetadataEditIdentifiersSection,
);
