import React, { useCallback } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';

import { EqFilter, Model, QFilter, RangeFilter, InFilter } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';

import { Pages } from 'utils/actions/types';
import { IUseActionsContext } from 'utils/actions';

import { organizationListSchema } from 'utils/schemas';
import { IUseActionsOptionParam } from 'utils/actions';

import { OrganizationPreviewTab } from 'components/organization/organization-preview-tab';
import { OrganizationActionsList } from 'components/action';
import { IOrganizationFilters, OrganizationFilters } from 'components/organization/organization-filters';
import { OrganizationListItem } from 'components/organization/organization-list-item';
import { OrganizationSelectedTabItem } from 'components/organization/organization-selected-tab-item';
import { IFilterOption, useControlDataSection, useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';

export const customOrganizationParam = {
  include_deleted: false,
};

const defaultFilters: IOrganizationFilters = {
  _: new QFilter(''),
  classification: new EqFilter('classification', ''),
  status: new EqFilter('status', ''),
  country_id: new EqFilter('country_id', ''),
  updated_at: new RangeFilter('updated_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
  users_count: new RangeFilter('users_count', 0, 100),
};

const sidebarTabs = {
  preview: <OrganizationPreviewTab />,
  filters: <OrganizationFilters />,
  selectedItemRenderer(organization) {
    return <OrganizationSelectedTabItem organization={organization} />;
  },
};

interface IOrganizationDataSectionProps {
  persistentFilters?: IFilterOption[];
  actionsOptions?: Partial<IUseActionsOptionParam>;
  persistFilters?: boolean;
}

export const OrganizationDataSection: React.FC<IOrganizationDataSectionProps> = observer((props) => {
  const { persistentFilters, actionsOptions, persistFilters } = props;

  const {
    dataSectionStore: { checked },
  } = useStore();

  useControlDataSection({
    persistFilters,
    schema: organizationListSchema,
    filters: persistentFilters,
    defaultFilters: defaultFilters,
    model: Model.ORGANIZATIONS,
    stats: 'classification,status,access_level',
    params: customOrganizationParam,
  });

  const refreshDataSection = useRefreshDataSection();

  const options = useCallback(
    (context: IUseActionsContext) => ({
      context,
      page: Pages.LIST,
      onSuccess: refreshDataSection,
      ...actionsOptions,
    }),
    [refreshDataSection, actionsOptions],
  );

  const itemRenderer = useCallback(
    (org): JSX.Element => {
      return (
        <OrganizationListItem
          key={org.id}
          organization={org}
          contextMenu={<ActionsMenu component={OrganizationActionsList} items={[org]} options={options('single')} />}
        />
      );
    },
    [options],
  );

  return (
    <DataSection
      showListTileViewToggleButton={false}
      tabs={sidebarTabs}
      itemRenderer={itemRenderer}
      contextMenu={<ActionsMenu component={OrganizationActionsList} items={checked} options={options('multi')} />}
    />
  );
});
