import React from 'react';
import { observer } from 'mobx-react-lite';

import { CollectionItemsDataSection } from 'components/collection-items';
import { IListItemActionName } from 'utils/actions/list-item/types';

import { CollectionItemSortTab } from './collection-items-tab-sort';

export interface ICollectionOverviewTabProps {
  collectionId: number;
  isSortModeEnabled: boolean;
  onSuccess: (action: IListItemActionName) => void;
}

export const CollectionItemTab: React.FC<ICollectionOverviewTabProps> = observer(
  ({ collectionId, isSortModeEnabled, onSuccess }) => {
    if (isSortModeEnabled) {
      return <CollectionItemSortTab collectionId={collectionId} />;
    }
    return <CollectionItemsDataSection listId={collectionId} onSuccess={onSuccess} />;
  },
);
