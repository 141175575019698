import React from 'react';

import { Carousel } from 'components/carousel';
import ScrollWrapper from 'components/scroll-wrapper';

import { typeParser } from 'utils/list-item';
import { IListItem } from 'types';

import './style.scss';

export const ItemsPreviewSection: React.FC<{ items: IListItem[] }> = ({ items }) => {
  return (
    <ScrollWrapper>
      <Carousel data={items} entity="entity" customTagParser={typeParser} />
    </ScrollWrapper>
  );
};

export default ItemsPreviewSection;
