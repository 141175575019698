import React from 'react';
import startCase from 'lodash/startCase';

import { checkIfDateWithoutHours, formatDate, formatStringDate } from 'utils/date';
import { IMfxIconName } from 'icons/components/icon-names';
import { MfxIcon } from 'icons/components/mfx-icon';
import { IStyled } from 'types';
import { MantineIcon } from 'utils/ui/icon';
import { IconName } from 'blueprint5-icons';

interface IDateElementProps extends IStyled {
  date: string | number | Date | undefined | null;
  icon?: IconName;
  mfxIcon?: IMfxIconName;
  timeZone?: string;
  withHoverLabel?: boolean;
  customHoverLabel?: string;
  format?: string;
}

export const DateListElement: React.FC<IDateElementProps> = ({
  date,
  icon,
  mfxIcon,
  className,
  timeZone,
  withHoverLabel = true,
  customHoverLabel,
  format,
}) => {
  const title = withHoverLabel ? customHoverLabel || startCase(mfxIcon) : undefined;

  const withoutHoursDate = checkIfDateWithoutHours(date);
  date = withoutHoursDate ? formatStringDate(date) : date;

  return (
    <>
      {date ? (
        <div title={title} className={className}>
          {icon && <MantineIcon className="me-1" icon={icon} />}
          {mfxIcon && <MfxIcon className="me-1" icon={mfxIcon} />}
          {formatDate(date, format)}
          {timeZone && <div>{timeZone}</div>}
        </div>
      ) : (
        <div className={className}> — </div>
      )}
    </>
  );
};

export default React.memo(DateListElement);
