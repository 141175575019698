import { map } from 'lodash';
import { ItemId } from 'types';
import { getProducts, queryProducts } from 'utils/apis/product';

const schema = 'id, type, meta { related_product_ids }';

export const fetchRelatedProducts = async (product_ids: ItemId[], type = 'all'): Promise<ItemId[]> => {
  if (!product_ids?.length) {
    return [];
  }
  const products = await getProducts({ product_ids: product_ids }, schema);
  const filteredByType = type === 'all' ? products : products.filter((product) => product.type === type);
  const relatedProductIds = map(filteredByType, 'meta.related_product_ids').flat() || [];

  // additional request to filter out the deleted products
  const filteredRelatedProducts = await queryProducts(
    { ids: relatedProductIds },
    undefined,
    undefined,
    undefined,
    'id',
  );

  return map(filteredRelatedProducts, 'id');
};
