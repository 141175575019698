import { IAsset, IMm3Asset } from 'types';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';

import { useRecommendAssetsDialog } from 'components/recommendation';
import { IUseActionsOptionParam } from 'utils/actions';

import { shouldDisplayRecommend } from './actions-acl';
import { IAssetActionName, IAssetActionConfig } from './types';

export const useRecommendAction = (
  assets: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam<McGenericRecommendationAsset>,
): IAssetActionConfig => {
  const [openDialog] = useRecommendAssetsDialog({
    assets,
    options,
  });

  return {
    name: IAssetActionName.RECOMMEND,
    icon: 'share',
    title: 'Recommend',
    shouldDisplay: shouldDisplayRecommend,
    handler: openDialog,
  };
};
