import { useState, useEffect } from 'react';

export const useIsResizing = (): boolean => {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let timeout;
    const handleResize = (): void => {
      // resizing started
      setIsResizing(true);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        // resizing finished
        setIsResizing(false);
      }, 250);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isResizing;
};
