import React from 'react';

import { IContact } from 'types';
import { dateWithoutHours, formatDate } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';

export const PersonalDataSection: React.FC<IContact> = (props) => {
  const { title, first_name, middle_name, last_name, gender, date_of_birth } = props;
  return (
    <InfoBoxV2 title="Personal Data" wrapperClassName="py-3" className="entity-detail__infobox-wrapper">
      <InfoBoxV2 gridTemplateColumns="200px 1fr" padded={false}>
        <Row label="Title" value={title} size="m" />
        <Row label="First Name" value={first_name} size="m" />
        <Row label="Middle Name" value={middle_name} size="m" />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="200px 1fr" padded={false}>
        <Row label="Last Name" value={last_name} size="m" />
        <Row label="Birthday" value={formatDate(date_of_birth, dateWithoutHours)} size="m" />
        <Row label="Gender" value={gender} size="m" />
      </InfoBoxV2>
    </InfoBoxV2>
  );
};
