import React from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { useDialogStore } from 'store/hooks';
import { IModalLabels } from 'components/dialogs';
import { Classes } from 'utils/ui';

export interface IFormSubmitSectionProps {
  labels?: IModalLabels;
  submitDisabled?: boolean;
  loading?: boolean;
  className?: string;
}

const FormSubmitSection: React.FC<IFormSubmitSectionProps> = ({
  labels,
  submitDisabled = false,
  loading = false,
  className,
}) => {
  const { close } = useDialogStore();

  return (
    <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, className, 'gap-2')}>
      <Button variant="default" size="xs" onClick={close}>
        {labels?.cancel || 'Cancel'}
      </Button>
      <Button size="xs" variant="primary" loading={loading} type="submit" disabled={submitDisabled}>
        {labels?.confirm || 'Confirm'}
      </Button>
    </div>
  );
};

export { FormSubmitSection };
