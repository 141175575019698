import { useCallback } from 'react';
import { map } from 'lodash';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { setConnectionStatus } from 'utils/apis/delivery-connections';
import { pluralEntityInflect } from 'utils/general';
import { IConnectionActionName } from 'utils/actions/connection/types';
import { shouldDisplayActivation } from 'utils/actions/connection/actions-acl';

export const useActivateConnectionAction: IUseAction<DeliveriesGenericConnection, IConnectionActionName> = (
  items,
  options,
) => {
  const { toastStore } = useStore();
  const { entity, entityWithCount } = pluralEntityInflect('Connection', items.length);

  const activate = useCallback(async () => {
    toastStore.info(`Activating Connection...`);
    try {
      await setConnectionStatus(map(items, 'id'), 'activate');
      toastStore.clearAll();
      toastStore.success(`${entityWithCount} activated`);
      return await options?.onSuccess?.(IConnectionActionName.ACTIVATE);
    } catch (error) {
      toastStore.clearAll();
      toastStore.error(`${entity} activation failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  return {
    name: IConnectionActionName.ACTIVATE,
    icon: 'power',
    title: 'Activate',
    shouldDisplay: shouldDisplayActivation,
    handler: activate,
  };
};
