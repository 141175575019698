import React from 'react';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { DataSectionControls } from 'components/data-section-controls';

export const DataSectionPage: React.FC<{
  children?: React.ReactNode;
  refresh?: VoidFunction;
  hideToggleButton?: boolean;
}> = (props) => {
  const { children, refresh, hideToggleButton } = props;

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="d-inline-block mb-3" />
        <div>
          <DataSectionControls customRefresh={refresh} hideToggleButton={hideToggleButton} />
        </div>
      </div>
      {children}
    </div>
  );
};
