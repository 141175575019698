import React, { useCallback } from 'react';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';

import { columns } from './utils';

import { IAsset } from 'types';
import { getAssetArtifacts } from 'utils/apis/audio-track';
import { useRemote } from 'utils/hooks';

import './style.scss';
import { Loading } from 'components/loading';

interface IAssetArtifactsTabProps {
  asset: IAsset;
}

export const AssetArtifactsTab: React.FC<IAssetArtifactsTabProps> = ({ asset }) => {
  const { id } = asset || {};

  const fetchAudioTracks = useCallback(() => getAssetArtifacts(id), [id]);

  const [audioTracks = [], loading] = useRemote(fetchAudioTracks);

  if (loading) {
    return <Loading text="Loading..." />;
  }

  if (!audioTracks.length) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable data={audioTracks} columns={columns} tableClassName="asset-audio__table" />
    </div>
  );
};
