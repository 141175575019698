import React, { useCallback } from 'react';

import { useRemote } from 'utils/hooks';
import { getMediaInfo, getMetadata } from 'utils/apis/asset';
import { withAssetVersion } from 'utils/asset';
import { IAsset, IMm3Asset } from 'types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Loading } from 'components/loading';

export const AssetOldMetadataMediaInfoSection: React.FC<{ asset?: IAsset }> = ({ asset }) => {
  const { id, ingest_error_message } = asset || {};

  const fetcher = useCallback(() => getMetadata(id), [id]);
  const [metadata] = useRemote(fetcher);
  const metadataSummaryLength = JSON.stringify(metadata, null, 2)?.split('\n').slice(0, 4).join('\n').length;

  return (
    <div>
      <InfoBoxV2 gridTemplateColumns="1fr" title="Ingest Error" hidden={!ingest_error_message?.length}>
        <pre>{ingest_error_message}</pre>
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="1fr" title="Media Info">
        {metadata ? (
          <details className="entity-detail-content--padded">
            <summary>
              <pre>{JSON.stringify(metadata, null, 2).slice(0, metadataSummaryLength)}</pre>
            </summary>
            <pre>{JSON.stringify(metadata, null, 2).slice(metadataSummaryLength)}</pre>
          </details>
        ) : (
          <pre>
            <code>JSON is not available</code>
          </pre>
        )}
      </InfoBoxV2>
    </div>
  );
};

export const AssetMm3MetadataMediaInfoSection: React.FC<{ asset?: IMm3Asset }> = ({ asset }) => {
  const { id } = asset || {};

  const fetcher = useCallback(() => getMediaInfo(id), [id]);
  const [mediaInfo, loading] = useRemote(fetcher);
  const mediaInfoSummaryLength = JSON.stringify(mediaInfo, null, 2)?.split('\n').slice(0, 4).join('\n').length;

  return (
    <InfoBoxV2 gridTemplateColumns="1fr" title="Media Info">
      {!loading && mediaInfo ? (
        <details className="entity-detail-content--padded entity-detail-content__code">
          <summary>
            <pre className="entity-detail-content__summary">
              {JSON.stringify(mediaInfo, null, 2).slice(0, mediaInfoSummaryLength)}
            </pre>
          </summary>
          <pre className="entity-detail-content__rest">
            {JSON.stringify(mediaInfo, null, 2).slice(mediaInfoSummaryLength)}
          </pre>
        </details>
      ) : loading ? (
        <Loading text="Loading..." />
      ) : (
        <pre>
          <code>JSON is not available</code>
        </pre>
      )}
    </InfoBoxV2>
  );
};

export const AssetMetadataMediaInfoSection = withAssetVersion(
  AssetOldMetadataMediaInfoSection,
  AssetMm3MetadataMediaInfoSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
