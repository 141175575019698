import React from 'react';
import { IMarketingEvent } from 'types';

import DateElement from 'components/date-list-element';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';

export const EventTiming: React.FC<{ event?: IMarketingEvent }> = ({ event }) => {
  if (!event) {
    return null;
  }
  const { is_timed_event, event_starts_at, event_ends_at, show_event_countdown } = event;

  if (is_timed_event) {
    return (
      <>
        <Row label="Start  at" rawLabel value={<DateElement date={event_starts_at} />} size="m" />
        <Row label="Ends at" rawLabel value={<DateElement date={event_ends_at} />} size="m" />
        <Row label="Display countdown" value={yn(show_event_countdown)} size="m" />
      </>
    );
  }

  return <Row label="Persistent event" value="Yes" size="m" />;
};
