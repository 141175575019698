import { IActionConfig } from 'utils/actions/types';
import { IContact } from 'types';

export enum IContactActionName {
  USER_NAME = 'user-name',
  UPLOAD_PREVIEW_IMAGE = 'upload-preview-image',
  UPDATE_RESPONSIBLE_CONTACT = 'update-responsible-contact',
  REJECT = 'reject',
  INVITE = 'invite',
  DELETE_PREVIEW = 'delete-preview',
  CREATE_SELECTION = 'create-selection',
  ADD_TO_SELECTION = 'add-to-selection',
  APPROVE = 'approve',
  REVIEW = 'review',
  UNBOUNCE = 'unbounce',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  DELETE = 'delete',
  RESTORE = 'restore',
  IMPERSONATE = 'impersonate',
  REMOVE_FROM_GROUP = 'remove-from-group',
  GRANT_ACCESS_PRIVILEGE = 'grant-access-privilege',
  REMOVE_FROM_CONFERENCE = 'remove-from-conference',
}

export interface ICreateSelectionFormValues {
  name: string;
  access_level: string;
  item_ids: (string | number)[];
}

export type IContactActionConfig = IActionConfig<IContact, IContactActionName>;
