import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { collectionSchema } from 'utils/schemas';
import {
  EqFilter,
  IFiltersDefinition,
  InFilter,
  Model,
  ParametronOrder,
  ParametronSort,
  QFilter,
  RangeFilter,
} from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { ShowcaseActionsList } from 'components/action';
import { useDataSectionStore } from 'store/hooks';

import { useControlDataSection, IFilterOption, useRefreshDataSection } from 'utils/hooks';
import { CollectionPreviewTab } from 'components/collection/collection-preview-tab';
import { CollectionFilters, additionalFilters } from 'components/collection/collection-data-section-filters';
import { CollectionSelectedItem } from 'components/collection/collection-selected-item';
import { CollectionDataSectionItem } from 'components/collection/collection-data-section-item';
import { IWebsitePageValues } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { customSortMenu } from './custom-sort-menu';
import { ActionsMenu } from 'components/action/utils';

const defaultFilters = {
  _: new QFilter(''),
  owner_id: new InFilter('owner_id', []),
  access_level: new InFilter('access_level', []),
  updated_at: new RangeFilter('updated_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  sublist: new EqFilter('sublist', true),
  ...additionalFilters,
};

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const params = {
  model: Model.COLLECTIONS,
  presetSuffix: 'collection',
  defaultFilters,
  schema: collectionSchema,
  stats: ['access_level', 'owner_id', ...Object.keys(additionalFilters)].join(','),
  params: defaultParams,
};

const sidebarTabs = {
  preview: <CollectionPreviewTab />,
  filters: <CollectionFilters />,
  selectedItemRenderer(collection: UmGenericListCollection): React.ReactElement {
    return <CollectionSelectedItem collection={collection} />;
  },
};

const multiOptions: IUseActionsOptionParam<UmGenericListCollection> = {
  context: 'single',
  section: IWebsitePageValues.COLLECTIONS,
  page: Pages.LIST,
};

interface ICollectionDataSectionProps {
  persistentFilters?: IFilterOption[];
  initFilters?: IFiltersDefinition;
  persistFilters?: boolean;
  headerRightSection?: React.ReactElement;
}

const options: IUseActionsOptionParam<UmGenericListCollection> = {
  context: 'multi',
  section: IWebsitePageValues.COLLECTIONS,
  page: Pages.LIST,
};

export const CollectionDataSection: React.FC<ICollectionDataSectionProps> = observer((props) => {
  const { headerRightSection, persistFilters, initFilters, persistentFilters } = props;
  useControlDataSection({ ...params, filters: persistentFilters, initFilters, persistFilters });
  const { checked } = useDataSectionStore<UmGenericListCollection>();
  const refreshDataSection = useRefreshDataSection();

  const itemRenderer = useCallback(
    (conference: UmGenericListCollection): React.ReactElement => {
      return (
        <CollectionDataSectionItem
          key={conference.id}
          collection={conference}
          contextMenu={
            <ActionsMenu
              component={ShowcaseActionsList}
              items={[conference]}
              options={{ ...multiOptions, onSuccess: refreshDataSection }}
            />
          }
        />
      );
    },
    [refreshDataSection],
  );

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={itemRenderer}
      customOrderRightSection
      headerRightSection={headerRightSection}
      showListTileViewToggleButton={false}
      customSortMenu={customSortMenu}
      defaultParams={defaultParams}
      contextMenu={
        <ActionsMenu
          component={ShowcaseActionsList}
          items={checked}
          options={{ ...options, onSuccess: refreshDataSection }}
        />
      }
    />
  );
});
