import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { IconAlias } from 'icons';
import { GroupTypes, IMarketingEvent } from 'types';
import { IUseAction } from 'utils/actions';
import { shouldDisplaySingleNotDeleted as shouldDisplay } from 'utils/actions/common';
import { IMarketingEventActionName } from './types';

export const useAddAssetsAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items, options) => {
  const [openSelectionDialog] = useAddToGroupDialog({
    group: items[0],
    entityType: 'assets',
    options,
    groupType: GroupTypes.EVENT,
  });

  return {
    name: IMarketingEventActionName.ADD_ASSETS,
    shouldDisplay,
    icon: IconAlias.ACTION_ADD_ASSETS,
    title: 'Add assets',
    handler: openSelectionDialog,
  };
};
