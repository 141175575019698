import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { Routes } from 'utils/routes';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { IconAlias } from 'icons';
import useSelectedOption from 'utils/hooks/selected-option';
import { CollectionAttributes } from 'components/collection/collection-data-section-item/collection-attributes';

import './style.scss';
import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';

interface ICollectionDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  collection: UmGenericListCollection;
  isChecked?: boolean;
  isCheckedDisabled?: boolean;
  handleClick?: (e) => void;
}

export const CollectionDataSectionListItem: React.FC<ICollectionDataSectionListItemProps> = observer(
  ({ collection, contextMenu, handleClick, isChecked, isCheckedDisabled }) => {
    const { access_level, name, id, preview_image, sublist } = collection;
    const selectedOption = useSelectedOption({ value: access_level });
    const [itemChecked, setItemChecked] = useState(isChecked);

    useEffect(() => {
      setItemChecked(isChecked);
    }, [isChecked]);

    return (
      <div className="entity-list-item-row" onClick={handleClick}>
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="entity-list-item-row__thumb">
          <Thumbnail
            fallbackIconName={IconAlias.COLLECTION}
            image={preview_image?.url}
            placeholder={isEntityIngesting(collection) && <SmallLoader size={30} />}
            customTag={sublist ? 'sub' : 'main'}
            showType
          />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={name}>
            <Link to={`${Routes.COLLECTIONS}/${id}`}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <CollectionAttributes collection={collection} />
        </div>
      </div>
    );
  },
);
