import { IJsonLDSpec, IResult, ISetOpts } from '@mediafellows/chipmunk';
import { action, IObservableArray, observable } from 'mobx';
import { chipmunk } from 'utils/chipmunk';
import { IDataProvider, IDataProviderItem } from './data-provider';
import { IDataProviderMapper } from './dynamic-data-provider';

export type IQueryDataProviderExecutor = (query: string) => Promise<IResult | IJsonLDSpec>;

export class QueryDataProvider implements IDataProvider {
  @observable loading = false;
  @observable public data = observable([]) as IObservableArray<IDataProviderItem>;
  @observable public cachedSelectedValue?: IDataProviderItem | undefined;

  constructor(protected executor: IQueryDataProviderExecutor, protected mapper: IDataProviderMapper) {}

  public cacheSelectedValue(value: IDataProviderItem): void {
    this.cachedSelectedValue = value;
  }

  @action async filter(query: string): Promise<void> {
    this.loading = true;

    const result = await this.executor(query);
    const data = this.mapper(result) || [];

    this.data.replace(data);
    this.loading = false;
  }

  async init(): Promise<void> {
    await this.filter('');
  }
}

export const queryDataExecutorCache = (
  cacheName: string,
  executor: IQueryDataProviderExecutor,
  opts?: ISetOpts,
): IQueryDataProviderExecutor => {
  return async (query) => {
    const cName = `query_data_provider_${cacheName}`;
    if (query.length === 0) {
      const cachedValue = chipmunk.cache.get(cName, opts);

      if (cachedValue) {
        return cachedValue;
      }
    }

    const value = await executor(query);
    if (query.length === 0) {
      chipmunk.cache.set(cName, value, opts);
    }

    return value;
  };
};
