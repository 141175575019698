export const enum Position {
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT = 'left',
  LEFT_BOTTOM = 'left-bottom',
  LEFT_TOP = 'left-top',
  RIGHT = 'right',
  RIGHT_BOTTOM = 'right-bottom',
  RIGHT_TOP = 'right-top',
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
}
export declare type IPosition = (typeof Position)[keyof typeof Position];
export const isPositionHorizontal = (position: IPosition): boolean => {
  return (
    position === Position.TOP ||
    position === Position.TOP_LEFT ||
    position === Position.TOP_RIGHT ||
    position === Position.BOTTOM ||
    position === Position.BOTTOM_LEFT ||
    position === Position.BOTTOM_RIGHT
  );
};

export const isPositionVertical = (position: IPosition): boolean => {
  return (
    position === Position.LEFT ||
    position === Position.LEFT_TOP ||
    position === Position.LEFT_BOTTOM ||
    position === Position.RIGHT ||
    position === Position.RIGHT_TOP ||
    position === Position.RIGHT_BOTTOM
  );
};

export const getPositionIgnoreAngles = (position: IPosition): 'left' | 'top' | 'bottom' | 'right' => {
  if (position === Position.TOP || position === Position.TOP_LEFT || position === Position.TOP_RIGHT) {
    return Position.TOP;
  } else if (position === Position.BOTTOM || position === Position.BOTTOM_LEFT || position === Position.BOTTOM_RIGHT) {
    return Position.BOTTOM;
  } else if (position === Position.LEFT || position === Position.LEFT_TOP || position === Position.LEFT_BOTTOM) {
    return Position.LEFT;
  } else {
    return Position.RIGHT;
  }
};
