import { IContact } from 'types';
import { isInMainPage } from 'utils/actions/utils';

import { IShouldDisplayActionFunction } from 'utils/actions/types';
import { Pages, IShouldDisplayCommonActionFunction } from 'utils/actions';
import { IContactActionName } from './types';
import { areAllDeleted, areAllNotDeleted } from 'utils/is-entity-deleted';

type IShouldDisplayContactActionFunction = IShouldDisplayActionFunction<IContact, IContactActionName>;

export const shouldDisplayGeneralAction: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  contacts,
  options,
) => {
  if (action.name === 'divider') {
    return index > 0;
  }

  switch (action.name) {
    case IContactActionName.BLOCK:
      return contacts.every((contact) => contact.status === 'active');
    case IContactActionName.UNBLOCK:
      return contacts.every((contact) => contact.status === 'blocked');
    case IContactActionName.UNBOUNCE:
      return contacts.every((contact) => contact.bouncing);
    case IContactActionName.RESTORE:
      return areAllDeleted(contacts);
    case IContactActionName.REVIEW:
      return contacts.every((contact) => contact.status === 'pending');
    case IContactActionName.DELETE:
      return isInMainPage(options) && areAllNotDeleted(contacts);
    default:
      return true;
  }
};

export const shouldDisplayInviteAction: IShouldDisplayContactActionFunction = (action, index, user, contacts) => {
  return contacts.every(({ status }) => ['inactive', 'rejected'].includes(status));
};

export const shouldDisplayReInviteAction: IShouldDisplayContactActionFunction = (action, index, user, contacts) => {
  return contacts.every(({ status }) => ['invited'].includes(status));
};

export const shouldDisplayApproveAction: IShouldDisplayContactActionFunction = (action, index, user, contacts) => {
  return contacts.length === 1 && ['pending', 'review'].includes(contacts[0].status);
};

export const shouldDisplayRejectAction: IShouldDisplayContactActionFunction = (action, index, user, contacts) => {
  return contacts.every(({ status }) => ['pending', 'review'].includes(status));
};

export const shouldDisplayUImpersonateAction: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return Boolean(options.context === 'single') && areAllNotDeleted(items);
};

export const shouldDisplayRemoveFromGroup: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    [
      Pages.SELECTION_DETAILS,
      Pages.SELECTION_ENTITY_TAB,
      Pages.ACCESS_PRIVILEGES_DETAILS,
      Pages.SCREENING_ROOM_DETAILS,
      Pages.ACCESS_GROUP_DETAILS,
    ].includes(options?.page as Pages) && areAllNotDeleted(items)
  );
};

export const shouldDisplayRemoveFromConference: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return Boolean(entities?.length && options.page === Pages.CONFERENCE && areAllNotDeleted(entities));
};

export const shouldDisplayDeletePreviewImageAction: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    items?.length === 1 && options.context === 'single' && areAllNotDeleted(items) && items[0].preview_image !== null
  );
};

export const shouldDisplayUploadPreviewImageAction: IShouldDisplayContactActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return items?.length === 1 && options.context === 'single' && areAllNotDeleted(items);
};

export const shouldDisplayAddToSelection: IShouldDisplayContactActionFunction = (action, index, user, items) => {
  return areAllNotDeleted(items);
};

export const shouldDisplayTitle: IShouldDisplayCommonActionFunction = (action, index, user, items, options) => {
  return areAllNotDeleted(items) && (Boolean(options?.groupId) || Boolean(options?.groupName));
};
