import React from 'react';
import { Popover } from '@blueprintjs/core';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

export interface IValidationMarkerProps {
  valid?: boolean;
  errorMessage?: string;
}

const ValidationMarker: React.FC<IValidationMarkerProps> = (props) => {
  const { valid, errorMessage } = props;

  return valid ? (
    <MantineIcon icon="tick-circle" variant="success" />
  ) : (
    <Popover
      content={<div className="validation-marker__error">{errorMessage}</div>}
      target={<MantineIcon icon="info-sign" variant="danger" className="validation-marker__error-icon" />}
      position="right"
      interactionKind="hover"
    />
  );
};

export default ValidationMarker;
