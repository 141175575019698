import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IUseAction } from 'utils/actions';
import { useEditRecommendationDialog } from 'components/recommendation';

import { IRecommendationActionName } from './types';
import { isRecommendationDraftAndSingleContext as shouldDisplay } from './actions-acl';
import { ICreateWorkFlowPayload } from 'utils/actions/types';

export const useEditAction: IUseAction<McGenericRecommendationProduct, IRecommendationActionName> = (
  items,
  options,
) => {
  const [recommendation] = items;

  const [handler] = useEditRecommendationDialog({
    recommendation: recommendation as ICreateWorkFlowPayload<McGenericRecommendationProduct>,
    tab: options?.tab as unknown as number,
    options,
  });

  return {
    name: IRecommendationActionName.EDIT,
    icon: 'edit',
    shouldDisplay,
    title: 'Edit',
    handler,
  };
};
