import React from 'react';
import { Group, InputWrapper, Text } from '@mantine/core';
import { Intent } from 'utils/ui';
import cx from 'classnames';

interface IFormGroupProps {
  label?: string | React.ReactNode;
  labelFor?: string;
  helperText?: string;
  inline?: boolean;
  children?: React.ReactNode;
  error?: string;
  required?: boolean;
  intent?: Intent;
  style?: React.CSSProperties;
  showError?: boolean;
  className?: string;
  errorMessage?: string;
  hasBottomMargin?: boolean;
  contentClassName?: string;
}
export const FormGroup: React.FC<IFormGroupProps> = ({
  label,
  labelFor,
  helperText,
  inline,
  children,
  error,
  required,
  style,
  showError,
  className,
  errorMessage,
  hasBottomMargin = true,
  contentClassName,
  ...props
}) => {
  const WrapperComponent = inline ? Group : 'div';

  return (
    <InputWrapper
      className={className}
      mb={hasBottomMargin ? 15 : 0}
      style={style}
      id={labelFor}
      label={label}
      required={required}
      error={errorMessage ?? null}
      {...props}
    >
      <WrapperComponent className={cx(`bp3-form-content ${contentClassName}`)}>{children}</WrapperComponent>
      {helperText && (
        <Text c="var(--mfx-error)" size="xs">
          {helperText}
        </Text>
      )}
    </InputWrapper>
  );
};
