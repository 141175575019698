import React, { useCallback } from 'react';
import { ProducerGenericReport, UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { useDisableWhileSubmitting } from 'utils/hooks/submit';
import { IActionConfig } from 'utils/actions';
import { useStore } from 'store';
import { FormSliderShowcase } from 'components/showcase';
import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';
import { createList } from 'utils/apis/showcase';
import { createListItems, deleteListItems } from 'utils/apis/showcase-item';
import { IFormMultiSelectOption } from 'helpers/form';
import { getAllListItems } from 'utils/apis/showcase-item';
import { getShowcaseByPurpose } from 'utils/apis/showcase';
import { useRefreshDataSection } from 'utils/hooks';

import { IProducerPortalActionName } from './types';

interface ISliderFormProps {
  sliderShowcase?: UmGenericListShowcase | null;
  onSubmit: (items: IFormMultiSelectOption[], purpose?: string) => Promise<void>;
}

const allowedItemTypes: ['user'] = ['user'];

const BiAccessForm: React.FC<ISliderFormProps> = observer(({ onSubmit, sliderShowcase }) => {
  const { toastStore, dialogStore } = useStore();
  const onSuccess = useRefreshDataSection();

  const handleSubmit = useCallback(
    async (items: IFormMultiSelectOption[], purpose: string): Promise<void> => {
      try {
        await onSubmit(items, purpose);
        onSuccess();
        toastStore.clearAll();
        toastStore.success(
          `Business Intelligence Access List ${sliderShowcase?.id ? 'updated' : 'created'} successfully!`,
        );
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [onSubmit, onSuccess, toastStore, sliderShowcase?.id, dialogStore],
  );

  return (
    <FormSliderShowcase
      allowed_item_types={allowedItemTypes}
      sliderId={sliderShowcase?.id}
      onSubmit={handleSubmit}
      label=""
      placeholder="Add Users..."
    />
  );
});

export const useManageBiAccess = ({
  title,
  purpose,
}): IActionConfig<ProducerGenericReport, IProducerPortalActionName> => {
  const { dialogStore } = useStore();

  const onClick = useCallback(async () => {
    const sliderShowcase = await getShowcaseByPurpose(purpose);

    const onCreateMeetingsHostsShowcase = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const sliderShowcase = await createList({
        meta: { page: 'configuration' },
        purpose,
        name: title,
        type: 'List::Showcase',
        access_level: 'public',
        allowed_item_types: allowedItemTypes,
      });
      await createListItems(items, sliderShowcase?.id);
    };

    const onUpdate = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const listItems = await getAllListItems(sliderShowcase?.id);

      const itemsIds = listItems.map((e) => e.id || 0);
      await deleteListItems(sliderShowcase?.id, itemsIds);
      await createListItems(items, sliderShowcase?.id);
    };

    dialogStore.openModal({
      title: 'Manage Business Intelligence Access',
      size: IModalSize.M,
      body: () => (
        <BiAccessForm
          onSubmit={sliderShowcase?.id ? onUpdate : onCreateMeetingsHostsShowcase}
          sliderShowcase={sliderShowcase}
        />
      ),
    });
  }, [dialogStore, title, purpose]);

  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: IProducerPortalActionName.MANAGE_BI_ACCESS,
    icon: 'people',
    title,
    handler,
    isDisabled: disabled,
  };
};
