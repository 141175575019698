import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { TagsList } from 'components/entity-list';
import { IAsset, IMm3Asset } from 'types';
import { withAssetVersion } from 'utils/asset';

export const AssetOldMetadataIdentifiersSection: React.FC<{ asset?: IAsset }> = ({ asset }) => {
  const { archive_number, asset_identifier, external_reference, tags } = asset || {};

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Identifiers">
      <TagsList tags={tags} className={'asset-metadata-tab__tag me-1'} />
      <Row label="External Reference" value={external_reference} size="m" />
      <Row label="Asset Identifier" value={asset_identifier} size="m" />
      <Row label="Archive Number" value={archive_number} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataIdentifiersSection: React.FC<{ asset?: IMm3Asset }> = ({ asset }) => {
  const { external_reference, tags, meta } = asset || {};
  const { archive_number, asset_identifier } = meta || {};

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Identifiers">
      <TagsList tags={tags} className={'asset-metadata-tab__tag me-1'} />
      <Row label="External Reference" value={external_reference} size="m" />
      <Row label="Asset Identifier" value={asset_identifier} size="m" />
      <Row label="Archive Number" value={archive_number} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMetadataIdentifiersSection = withAssetVersion(
  AssetOldMetadataIdentifiersSection,
  AssetMm3MetadataIdentifiersSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
