import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';

import { AnalyticsInteractionsVideoScreenings as VideoScreenings } from 'components/analytics-interactions-video-screenings';
import { AnalyticsSectionFilters, assetInteractionOptions } from 'components/analytics';

import { loadAsset } from 'utils/apis/asset';
import { useAnalytics } from 'utils/hooks';

import { DetailsPageTabs, IAnalyticsStateProps } from 'types';
import { IAnalyticsEntityValues } from 'types/analytics';
import { AssetInteractions } from 'components/analytics/utils';
import { videoQueries } from 'components/analytics/queries/asset-queries';

const AssetAnalyticsTab: React.FC<{}> = observer(() => {
  const { assetId: asset_id } = useParams<{ assetId: string }>();
  const { state }: { state: IAnalyticsStateProps } = useLocation();

  const getMinDate = useCallback(async () => {
    if (!asset_id) return;
    const { created_at } = await loadAsset(asset_id, 'id, created_at');
    return new Date(created_at as string);
  }, [asset_id]);
  const [interaction, setInteraction] = useState<AssetInteractions>(
    (capitalize(state?.interaction) as AssetInteractions) || AssetInteractions.SCREENINGS,
  );

  const {
    minDate,
    dateRange,
    onDateRangeChange,
    territories,
    onTerritoriesSelect,
    contactIds,
    onContactsSelect,
    resetFilters,
  } = useAnalytics({
    getMinDate,
    state,
    tab: DetailsPageTabs.ANALYTICS,
    interaction,
    entity: IAnalyticsEntityValues.ASSET,
  });

  const onInteractionChange = useCallback(({ interaction }: { interaction: AssetInteractions }) => {
    setInteraction(interaction || AssetInteractions.SCREENINGS);
  }, []);

  // TODO: Implement Downloads; issue #506
  const ContentComponent = useMemo(() => {
    switch (interaction) {
      case AssetInteractions.SCREENINGS:
        return VideoScreenings;
      default:
        return VideoScreenings;
    }
  }, [interaction]);

  return (
    <div className="analytics-tab">
      <AnalyticsSectionFilters
        interaction={interaction}
        onInteractionChange={onInteractionChange}
        interactionOptions={assetInteractionOptions}
        territories={territories}
        onTerritoriesSelect={onTerritoriesSelect}
        contactIds={contactIds}
        onContactsSelect={onContactsSelect}
        dateRange={dateRange}
        minDate={minDate}
        handleDateRangeChange={onDateRangeChange}
        resetFilters={resetFilters}
      />
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <ContentComponent
            entity="asset"
            queries={videoQueries}
            entityId={asset_id}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={territories}
            userIds={contactIds}
          />
        </div>
      </div>
    </div>
  );
});

export default AssetAnalyticsTab;
