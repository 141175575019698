import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { FormInput, FormSelect } from 'helpers/form';
import { ContactAddressSection } from 'components/contact-form';
import { RegistrationFormAddress } from 'pages/registration/step-organization/form-address';
import { IStepOrganizationProps } from 'pages/registration/step-organization/types';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';

const StepOrganization: React.FC<IStepOrganizationProps> = observer((props) => {
  const { onBack, form } = props;

  const { formData, handlers, valid, onSubmit } = form;

  return (
    <>
      <form className="py-4" onSubmit={onSubmit}>
        <div className="registration__name">
          <FormInput
            name="organization_name"
            type="text"
            label="Organization Name"
            {...formData.organization_name}
            {...handlers}
          />
          <FormSelect
            name="organization_legal_entity"
            label="Legal Entity"
            large={true}
            {...formData.organization_legal_entity}
            {...handlers}
          />
        </div>
        <div className="registration__org">
          <FormSelect
            name="organization_classification"
            label="Type"
            large={true}
            {...formData.organization_classification}
            {...handlers}
          />
          <FormInput
            name="organization_email"
            type="text"
            label="Email"
            placeholder="Type Company Email"
            {...formData.organization_email}
            {...handlers}
          />
          <FormInput
            name="organization_phone_0_number"
            type="phone"
            label="Phone Number"
            placeholder="Type Company Phone Number"
            {...formData.organization_phone_0_number}
            {...handlers}
          />
        </div>
        <ContactAddressSection
          {...form}
          countryKey="user_country_id"
          preset={'organization_legal_address'}
          separator="_"
          component={RegistrationFormAddress}
        />
      </form>
      <div className="registration__btngroup">
        <a onClick={onBack}>
          <MantineIcon className="me-1" icon="arrow-left" />
          Back
        </a>
        <Button disabled={!valid} variant={Intent.PRIMARY} type="submit" onClick={onSubmit}>
          Preview
        </Button>
      </div>
    </>
  );
});

export default StepOrganization;
