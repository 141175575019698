import { PmGenericCastCrew } from '@mediafellows/mm3-types';

export enum Product3Types {
  EPISODE = 'Product3::MotionPicture::Episode',
  EPISODE_VERSION = 'Product3::MotionPicture::EpisodeVersion',
  FILM_VERSION = 'Product3::MotionPicture::FilmVersion',
  PROGRAM = 'Product3::MotionPicture::Program',
  FORMAT = 'Product3::MotionPicture::Format',
  SEASON = 'Product3::MotionPicture::Season',
  SERIES = 'Product3::MotionPicture::Series',
  PROGRAMS = 'Product3::MotionPicture::Program',
}

export interface IExtendedProductCastCrew extends PmGenericCastCrew {
  cast_crew_id?: number;
  name: string;
  cast_character?: string;
  index: number;
  cast_role: string[];
  crew_position: string[];
  cast_crew: PmGenericCastCrew;
  kind: 'Cast' | 'Crew';
}

export interface IProductLayerMeta {
  broadcaster?: string | null;
  produced_by?: string | null;
  language_versions?: {
    language_id: string;
    language_type: 'Original' | 'Dubbed' | 'Subtitled' | 'Textual Content' | 'Voice Over' | 'Audio Description';
  }[];
  logline: string;
  synopsis: string;
  product_state: string;
  commissioning_broadcasters: string[];
  co_producers: string[];
  cast_crew: IExtendedProductCastCrew[];
  due_delivery_date: string;
  resolution: string;
  year_of_production: number;
  category_ids: number[];
  language_ids: string[];
  share_link: string;
  global: string;
  number_of_seasons_manual: number;
  territory_count: string;
  territories_sold_in: string[];
  production_end: string;
  production_start: string;
  producer_external_references: string[];
  producer_organization_ids: number[];
  duration?: number | null;
  duration_text?: string | null;
  tx_date: string;
  tx_date_text: string;
  produced_by_external?: string[];
  keywords?: string[];
}
