import React from 'react';

import { Thumbnail } from 'components/thumbnail';

import { isProcessingPreview } from 'utils/general';
import { useFetchPreviewWithRefreshForIngest } from 'utils/hooks';

import { IEntityWithPreview, IStyled } from 'types';
import { IThumbnailProps } from './thumbnail';

interface IThumbnailWithRefreshProps
  extends IStyled,
    Pick<IThumbnailProps, 'fallbackIconName' | 'customTag' | 'showType' | 'placeholder'> {
  entity: IEntityWithPreview;
  shouldRefresh?: boolean;
}

export const ThumbnailWithRefresh: React.FC<IThumbnailWithRefreshProps> = ({
  entity: { preview_image_id, preview_image },
  shouldRefresh = true,
  className,
  fallbackIconName,
  placeholder,
  customTag,
  showType,
}) => {
  const [previewImage] = useFetchPreviewWithRefreshForIngest({ preview_image_id, preview_image }, shouldRefresh);
  const isProcessing = isProcessingPreview({ preview_image: previewImage, preview_image_id });
  return (
    <Thumbnail
      fallbackIconName={fallbackIconName}
      image={previewImage?.url}
      className={className}
      placeholder={isProcessing && placeholder}
      customTag={customTag}
      showType={showType}
    />
  );
};
