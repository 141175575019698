import { IIdentifiable, IGroup } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IAccessGroupActionName {
  CHANGE_ACCESS = 'change-access',
  COPY = 'copy',
  EDIT = 'edit',
  DELETE = 'delete',
  RESTORE = 'restore',
}

export type IAccessGroupActionConfig = IActionConfig<IIdentifiable, IAccessGroupActionName>;

export type IShouldDisplayAccessGroupActionFunction = IShouldDisplayActionFunction<IGroup, IAccessGroupActionName>;
