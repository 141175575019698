import React, { useCallback, useEffect, useMemo } from 'react';
import { FormCheckbox, useForm } from 'helpers/form';
import { observer } from 'mobx-react-lite';
import { Button, Divider, Switch } from '@mantine/core';

import { Model } from 'helpers/filters/types';
import { SectionHeader } from 'components/section-header';
import { useAssetsUploadStore } from 'store/hooks';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IAssetsFormHeader {
  openPresetModal: () => void;
}
export const AssetsFormHeader: React.FC<IAssetsFormHeader> = observer(({ openPresetModal }) => {
  const { files, setFiles } = useAssetsUploadStore();

  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedFiles = useMemo(() => files.filter(({ selected }) => selected).length, [files]);

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedFiles > 0 });
  }, [onChange, numberOfSelectedFiles]);

  const onSelectedAssetsChange = useCallback(
    ({ selectedAssets }) => {
      onChange({ selectedAssets });
      setFiles(files.map((file) => (file.selected === selectedAssets ? file : { ...file, selected: selectedAssets })));
    },
    [onChange, setFiles, files],
  );

  const onSelectAllChange = useCallback(
    (evt) => {
      const selectAll = evt.target.checked;
      setFiles(files.map((file) => (file.selected === selectAll ? file : { ...file, selected: selectAll })));
    },
    [setFiles, files],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => (
      <Button variant="outline" color="white" className="asset-upload__form-header" onClick={openPresetModal}>
        Select Preset
      </Button>
    ),
    [openPresetModal],
  );

  const allSelected = useMemo(() => files.every(({ selected }) => selected), [files]);

  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <SectionHeader title="Asset Info" customRightRenderer={CustomRightRenderer} useBackground />

      <div className="asset-upload__form-select">
        <FormCheckbox
          label={`${numberOfSelectedFiles}/${files.length}`}
          name="selectedAssets"
          {...formData.selectedAssets}
          {...handlers}
          onChange={onSelectedAssetsChange}
          hasBottomMargin={false}
        />
        <Divider orientation="vertical" mx={10} />
        <Switch checked={allSelected} label="Select All" name="selectAll" onChange={onSelectAllChange} />
      </div>
    </>
  );
});

export default React.memo(AssetsFormHeader);
