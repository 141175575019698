import React from 'react';
import { IContactFilters } from 'components/contact-filters/contact-filters';
import { IFiltersHandlers } from 'helpers/filters';

export const additionalFilters = {};

interface IAdditionalFiltersProps {
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;
