import React, { useCallback } from 'react';

import { useStore } from 'store';

import UploadSubtitleDialogBody, { IUploadSubtitleProps } from './upload-subtitle-dialog-body';

export const useUploadSubtitleDialog = (props: IUploadSubtitleProps): [() => void] => {
  const { onSubmit, message, type } = props;
  const { dialogStore } = useStore();

  const body = useCallback(() => {
    return <UploadSubtitleDialogBody type={type} message={message} onSubmit={onSubmit} />;
  }, [message, onSubmit, type]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Upload subtitle',
      body,
    });
  };

  return [openDialog];
};
