import React from 'react';
import { observer } from 'mobx-react-lite';

import { FieldList, PhoneField, IUseFormReturn } from 'helpers/form';
import { IContactFields } from 'pages/upsert-contact/upsert-contact';
import { ContactAddressSection } from 'components/contact-form';

import { SocialMediaProfileItems } from 'helpers/form/custom-fields/social-media-profile-fields';

export interface IStepContactProps {
  form: IUseFormReturn<IContactFields>;
}

const phoneKeys = ['label', 'number'];
const socialMediaProfileKeys = ['label', 'link'];

const StepContact: React.FC<IStepContactProps> = observer((props) => {
  const { form } = props;
  const { formData, handlers, values } = form;

  return (
    <div>
      <div className="upsert-contact__section">
        <ContactAddressSection {...form} />
      </div>
      <div className="upsert-contact__section">
        <FieldList
          label="Phones"
          Fields={PhoneField}
          {...{ handlers, values, formData }}
          prefix="phones"
          keys={phoneKeys}
        />
      </div>
      <div className="upsert-contact__section">
        <FieldList
          label="Social media profiles"
          Fields={SocialMediaProfileItems}
          {...{ handlers, values, formData }}
          prefix="social_media_profiles"
          keys={socialMediaProfileKeys}
        />
      </div>
    </div>
  );
});

export default StepContact;
