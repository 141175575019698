import { IResult } from '@mediafellows/chipmunk';
import { map } from 'lodash';

import { Model } from 'helpers/filters/types';
import { queryProducts } from 'utils/apis/product';
import { ItemId, IBasket, IProduct } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { basketSchema } from 'utils/schemas/basket';

import { IBasketFormFields } from 'components/baskets/basket-details/types';

export interface IFetchListItemsProps {
  basket_id: number;
  target: Model;
  actionName?: string;
  per?: number;
  schema?: string;
}

export const loadBasket = async (basketId: string): Promise<IBasket> => {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.BASKETS, 'member.get', {
      params: { basket_id: basketId },
      schema: basketSchema,
    });
    return object;
  });
};

export const loadUserBaskets = async (ownerId: ItemId): Promise<IBasket[]> => {
  if (!ownerId) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.BASKETS, 'query', {
      params: { owner_id: ownerId },
      schema: basketSchema,
    });

    return objects;
  });
};

export const loadListItems = async <T>({
  basket_id,
  target,
  actionName = 'query_basket',
  schema,
}: IFetchListItemsProps): Promise<T[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(target, actionName, {
      params: { basket_id },
      schema,
    });
    return objects;
  });
};

export const loadListProducts = async ({
  basket_id,
}: Omit<IFetchListItemsProps, 'target' | 'actionName'>): Promise<IProduct[]> => {
  const productGroupItems = await loadListItems({
    basket_id,
    target: Model.PRODUCTS,
    schema: 'id',
  });

  if (!productGroupItems) return [];
  const productIds = map(productGroupItems, 'id');

  return queryProducts({ ids: productIds });
};

export const shareBasket = async ({ basket_id, recipient_id }): Promise<void> => {
  return chipmunk.run(async ({ action }) =>
    action(Model.BASKET_GROUP_ITEMS, 'share', {
      params: {
        basket_id: basket_id,
        recipient_id,
      },
    }),
  );
};

export const editBasket = async (basketId: number, newBasket?: IBasketFormFields): Promise<IResult> => {
  return chipmunk.action(Model.BASKETS, 'member.update', {
    params: { group_ids: basketId },
    body: {
      id: basketId,
      name: newBasket?.name,
    },
  });
};

export const createBasket = async (name: string): Promise<IResult> => {
  return chipmunk.action(Model.BASKETS, 'member.create', {
    body: { name },
  });
};

export const deleteBasket = async (basketId: number): Promise<IResult> => {
  return chipmunk.action(Model.BASKETS, 'member.delete', {
    params: { basket_id: basketId },
  });
};
