import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';

import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IContact } from 'types';
import { removeContactsFromGroup } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { shouldDisplayRemoveFromGroup } from './actions-acl';
import { IContactActionName } from './types';

export const useRemoveFromGroupAction = (
  contacts: IContact[],
  options: IUseActionsOptionParam,
): IActionConfig<IContact, IContactActionName> => {
  const { toastStore, dialogStore } = useStore();
  const params = useParams<{ groupId: string }>();
  const groupId = params[options.groupParamName ?? ''];
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Contact', contacts.length);

  const executeRemove = useCallback(async () => {
    try {
      const productIds = map(contacts, 'id');
      if (!groupId || !productIds.length) {
        return;
      }

      await removeContactsFromGroup(groupId, productIds);
      toastStore.success(`${entityWithCount} will be removed from ${options.groupName}`);
      await options?.onSuccess?.(IContactActionName.REMOVE_FROM_GROUP);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [contacts, groupId, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from the ${options.groupName}?`,
      onConfirm: executeRemove,
    });
  }, [dialogStore, executeRemove, options, entityWithDemonstrative]);

  return {
    name: IContactActionName.REMOVE_FROM_GROUP,
    shouldDisplay: shouldDisplayRemoveFromGroup,
    icon: 'remove',
    title: `Remove from ${options.groupName}`,
    intent: Intent.DANGER,
    handler,
  };
};
