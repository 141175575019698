import { ItemId, ICategory } from 'types';
import { categoryListSchema } from 'utils/schemas';
import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { IGenreFormData } from 'components/genres/genre-form/genre-form';
import { ISortableItem } from 'store/sort-data-store';

export const queryGenres = (
  params: { ids?: ItemId[] | null; q?: string },
  schema = categoryListSchema,
): Promise<ICategory[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.GENRES, 'query', {
      params,
      schema,
    });

    return objects;
  });
};

export const createGenre = (body: IGenreFormData): Promise<ICategory> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<ICategory>(Model.GENRES, 'create', {
      body,
      schema: categoryListSchema,
    });

    return object;
  });
};

export const deleteGenre = (category_ids: ItemId[]): Promise<ICategory> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<ICategory>(Model.GENRES, 'delete', {
      params: { category_ids },
    });

    return object;
  });
};

export const updateGenre = (genre: Partial<ICategory>): Promise<ICategory> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<ICategory>(Model.GENRES, 'member.update', {
      params: { category_id: genre.id },
      body: genre,
    });

    return object;
  });
};

export const sortGenres = (items: ISortableItem[]): Promise<ICategory[]> => {
  const category_ids = items.map(({ id }) => id);

  const result = chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.GENRES, 'update', {
      params: { category_ids },
      body: items.reduce((acc, genre, index) => {
        if (genre.id) acc[genre.id] = { id: genre.id, sequence_no: index };
        return acc;
      }, {}),
    });
    return objects;
  });
  return result;
};
