import { Model } from 'helpers/filters/types';
import { IQueryParams, IContact, ItemId, IGenericObject } from 'types';
import { parseTags } from 'utils/tags';
import { contactDetailsSchema } from 'utils/schemas';
import { IFormMultiSelectOption } from 'helpers/form';
import { fieldToOption, queryCustomFields } from 'utils/apis/custom-field';
import { chipmunk, IResult } from 'utils/chipmunk';
import { getContacts } from 'utils/apis/contacts';

export const saveContact = async (objectId: string, data: IContact, originalData: IContact): Promise<IResult> => {
  return await chipmunk.run(async ({ action }) => {
    const isAddressDeleted = originalData.address?.id && !data.address?.id;
    const address: { id?: ItemId; _destroy?: boolean } = isAddressDeleted
      ? { id: originalData.address?.id, _destroy: true }
      : {};

    const tags = parseTags(data?.tags || []);
    return action(Model.CONTACTS, 'member.update', {
      ROR: true,
      params: { user_id: objectId },
      body: { ...data, tags, ...(address.id ? { address } : {}) },
    });
  });
};

export const loadContact = async (contactId: string, schema = contactDetailsSchema): Promise<IContact> => {
  const [contact] = await getContacts({ contactIds: [contactId], schema });
  return contact;
};

export const loadContacts = async (contactIds: Array<number>): Promise<Array<IContact>> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.CONTACTS, 'get', {
      params: { user_ids: contactIds },
      schema: contactDetailsSchema,
    });

    return objects;
  });
};

export const getInterests = async (ids: number[]): Promise<IFormMultiSelectOption[]> => {
  if (!ids || ids.length === 0) {
    return [];
  }

  const objects = await queryCustomFields({ ids }, 'interest');

  return objects.map(fieldToOption);
};

export const queryInterests = async (params: IQueryParams): Promise<IFormMultiSelectOption[]> => {
  try {
    const objects = await queryCustomFields(params, 'interest');

    return objects.map(fieldToOption);
  } catch (error) {
    return [];
  }
};

export const getInterestLabels = async (interests: number[]): Promise<string[]> =>
  (await getInterests(interests)).map((i) => i.label);

export const getCompanyProfiles = async (ids: number[]): Promise<IFormMultiSelectOption[]> => {
  if (!ids || ids.length === 0) {
    return [];
  }

  const objects = await queryCustomFields({ ids }, 'company_profile');

  return objects.map(fieldToOption);
};

export const queryCompanyProfiles = async (params: IQueryParams): Promise<IFormMultiSelectOption[]> => {
  try {
    const objects = await queryCustomFields(params, 'company_profile');

    return objects.map(fieldToOption);
  } catch (error) {
    return [];
  }
};

export const getCompanyProfileLabels = async (profiles: number[]): Promise<string[]> =>
  (await getCompanyProfiles(profiles)).map((i) => i.label);

export const getProductsAncestry = (product_ids: Array<string>): Promise<Array<IGenericObject>> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action('pm.product/ancestry_info', 'get', {
      params: { product_ids },
      schema: `effective_series_count,
        effective_seasons_count,
        effective_episodes_count,
        effective_episode_versions_count,
        effective_programs_count,
        effective_film_versions_count`,
    });

    return objects;
  });
};
