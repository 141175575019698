import { IRawTreeOption } from 'helpers/form/fields/form-select';
import { chipmunk } from 'utils/chipmunk';

export const fetchGeographyOptions = (params: { ids: string[] } = { ids: [] }): Promise<IRawTreeOption[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action('um.geography', 'query', {
      params,
      schema: 'id, name, parent_id',
    });

    return objects;
  });
};
