import { useCallback } from 'react';

import { useStore } from 'store';

import { IUseAction } from 'utils/actions/types';
import { shouldDisplayRestore as shouldDisplay } from 'utils/actions/common';
import { restoreSelection } from 'utils/actions/selection/api';
import { IMarketingEventActionName } from 'utils/actions/event/types';
import { formatGroupName } from 'utils/group';
import { pluralEntityInflect } from 'utils/general';

import { ItemId, IMarketingEvent } from 'types';

export const useRestoreAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const groupLabel = formatGroupName(items[0]['@type']);
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect(groupLabel, items.length);

  const executeRestore = useCallback(async () => {
    try {
      toastStore.success(`${entityWithCount} successfully restored`);
      const itemIds = items.map((item) => item.id);
      await restoreSelection(itemIds as ItemId | Array<ItemId>);
      await options?.onSuccess?.();
    } catch (error) {
      toastStore.error(`${entity} restore failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Restore',
      body: `Are you sure you want to restore ${entityWithDemonstrative}?`,
      onConfirm: executeRestore,
    });
  }, [dialogStore, entityWithDemonstrative, executeRestore]);

  return {
    name: IMarketingEventActionName.RESTORE,
    shouldDisplay,
    icon: 'automatic-updates',
    title: 'Restore',
    handler,
  };
};
