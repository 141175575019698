import { IEntityWithPreview, IPreviewImage, ItemId } from 'types';

import { Dispatch, SetStateAction, useCallback } from 'react';
import { fetchPreviewImage, fetchPreviewImages } from 'utils/apis/preview-image';
import { useRemote } from '.';
import { useInterval } from './use-interval';
import { refreshPreviewIngestingDelay } from 'utils/constants';
import { ParametronStore } from 'store/parametron-store';
import { byId } from 'utils/general';

export const isEntityIngesting = (entity: IEntityWithPreview): boolean => {
  const { preview_image, preview_image_id } = entity || {};

  return Boolean(
    preview_image_id && (!preview_image?.id || (preview_image?.id && preview_image_id !== preview_image?.id)),
  );
};

export function useFetchPreviewWithRefreshForIngest(
  entity: IEntityWithPreview,
  shouldRefresh?: boolean,
  delay = refreshPreviewIngestingDelay,
): [IPreviewImage | undefined, boolean, Dispatch<SetStateAction<IPreviewImage | undefined>>, () => Promise<void>] {
  const { preview_image_id, preview_image } = entity || {};

  const fetchPreview = useCallback(async () => {
    if (preview_image && preview_image?.url) {
      return preview_image;
    }
    return fetchPreviewImage(preview_image_id);
  }, [preview_image_id, preview_image]);

  const [previewImage, isLoading, setPreviewImage, refresh] = useRemote(fetchPreview);

  const refreshPreview = useCallback(
    async (id: number): Promise<void> => {
      if (!shouldRefresh) return;
      if (isEntityIngesting(entity)) {
        const fetchedPreviewImage = await fetchPreviewImage(preview_image_id);
        fetchedPreviewImage && setPreviewImage(fetchedPreviewImage);
        if (fetchedPreviewImage?.id) {
          clearInterval(id);
        }
      }
    },
    [entity, preview_image_id, setPreviewImage, shouldRefresh],
  );

  useInterval(refreshPreview, delay);
  return [previewImage, isLoading, setPreviewImage, refresh];
}

export const refreshIngestingDataSection = async <T extends IEntityWithPreview>(
  searchStore: ParametronStore<T> | null,
  id?: number | undefined,
): Promise<void> => {
  if (!searchStore?.objects?.length) {
    return;
  }
  const { objects, update } = searchStore;

  const ingestingPreviewIds = objects.reduce((acc: ItemId[], entity: T): ItemId[] => {
    if (isEntityIngesting(entity) && entity?.preview_image_id) {
      acc.push(entity.preview_image_id);
    }

    return acc;
  }, []);
  if (!ingestingPreviewIds.length) {
    clearInterval(id);
    return;
  }

  const refreshedPreviewImages = await fetchPreviewImages(ingestingPreviewIds);
  const previewImageByIds = byId(refreshedPreviewImages);
  objects.map((object) => {
    const refreshedPreviewImage = previewImageByIds[object?.preview_image_id || 0];
    if (!refreshedPreviewImage?.id || !object?.preview_image_id) {
      return;
    }
    object.preview_image = refreshedPreviewImage;
  });
  update({ objects });
};
