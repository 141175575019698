import React, { useCallback, SetStateAction, Dispatch, useMemo } from 'react';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';

import { IAsset, IConference, ISearchFilter } from 'types';
import { AssetsDataSection } from 'components/asset/assets-data-section';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { customSortMenu } from 'components/marketing-entity-detail/group-detail-asset-data-section/custom-sort-menu';

import { useRefreshDataSection } from 'utils/hooks';
import { IAssetActionName } from 'utils/actions/asset';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';
import { customSortExecutor } from 'utils/apis/sort';
import { getAssetModel } from 'utils/asset';

interface IConferenceAssetsPreviewTabProps {
  conference: IConference;
  setConference: Dispatch<SetStateAction<IConference>>;
}

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const executor = (options: IActionOpts): Promise<IResult<IAsset>> =>
  customSortExecutor<IAsset>(options, getAssetModel());

const ConferenceAssetsPreviewTab: React.FC<IConferenceAssetsPreviewTabProps> = ({ conference, setConference }) => {
  const persistentFilters = useMemo(
    () => [['id', 'in', conference.asset_ids]] as ISearchFilter[],
    [conference.asset_ids],
  );

  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action: IAssetActionName, conference: IConference) => {
      switch (action) {
        case IAssetActionName.REMOVE_FROM_CONFERENCE:
          return setConference(conference);

        default:
          return refreshDataSection();
      }
    },
    [refreshDataSection, setConference],
  );
  const options = useMemo<Partial<IUseActionsOptionParam<IConference>>>(
    () => ({
      onSuccess,
    }),
    [onSuccess],
  );

  return (
    <AssetsDataSection
      persistentFilters={persistentFilters}
      actionsOptions={options}
      page={Pages.CONFERENCE}
      customSortMenu={customSortMenu}
      defaultParams={defaultParams}
      customSubmitFilter={submitFiltersWithSeqNumber}
      executor={executor}
    />
  );
};

export default ConferenceAssetsPreviewTab;
