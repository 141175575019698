import React, { useState, useCallback, useEffect, useRef } from 'react';
import { uniq, get } from 'lodash';
import { removeItemFromFieldList } from 'utils/form';
import { FormDynamicFields } from 'components/form-dynamic-fields';
import { IFieldData, IFormHandlers } from 'helpers/form/types';
import { IFormData } from 'helpers/form/mm3';

export interface IFieldListV2Props<T, C> extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  prefix: string;
  formHandlers: Omit<IFormHandlers<T>, 'onSetFields'>;
  formData?: IFormData<T>;
  formValues: T;
  component: React.FC<C & { index: number; name: string }>;
  /**
   * Props that will be passed to underlying component
   * this is useful for the case of component, but can also be used for render property
   */
  // { index: number; name: string; isRemoved: boolean }
  fieldProps?: ({ index, name, isRemoved }) => C;
  className?: string;
  /**
   * Minimal number of rows that should be opened even if openFields is empty
   */
  initialFieldsCount?: number;
  withSorting?: boolean;
  limit?: number;
  defaultOrder?: 'asc' | 'desc';
}

/**
 * The function return indexes in case of a list of values
 */
function getListFromValues<T>(values: T, prefix: string): number[] {
  const mm3 = get(values, prefix);

  if (Array.isArray(mm3)) {
    return new Array(mm3.length).fill(0).map((_, index) => index);
  }

  return uniq(
    Object.keys(values || {})
      // include only items for the specific prefix which are converted to keys like item[0], item[1]
      .filter((key) => key.startsWith(`${prefix}[`))
      // use only prefix+index for later filtering
      .map((key) => key.split('.')[0]),
  ).map((_, index) => index);
}

function getInitialValues<T>(formData: IFormData<T>, prefix: string): Record<string, '' | undefined> {
  if (!get(formData, prefix)?.[0]) {
    const key = get(formData, `${prefix}.anyOf`) ? 'type' : 'id';
    return { [key]: undefined };
  }

  return Object.entries<IFieldData<T>>(get(formData, prefix)?.[0]).reduce((acc, [key, val]) => {
    if (val.required) {
      return { ...acc, [key]: undefined };
    }
    return acc;
  }, {});
}

const addItem = ({ formData, formHandlers, formValues, prefix, setOpenFields, defaultOrder = 'asc' }): void => {
  const list = getListFromValues(formValues, prefix);

  const mm3 = get(formValues, `${prefix}`);
  if (Array.isArray(mm3)) {
    const requiredFields = getInitialValues(formData, prefix);

    mm3.push(requiredFields);
    formHandlers.onChange({ [prefix]: mm3 });
  } else {
    formHandlers.onChange({ [`${prefix}[${list.length}].id`]: '' });
  }

  setOpenFields(defaultOrder === 'asc' ? [...list, list.length] : [list.length, ...list]);
};

export const FieldListV2 = <T, C>({
  label,
  formValues,
  prefix,
  formHandlers,
  formData,
  component,
  fieldProps,
  initialFieldsCount = 0,
  withSorting = false,
  className,
  limit = 5,
  defaultOrder = 'asc',
}: IFieldListV2Props<T, C>): React.ReactElement => {
  const [openFields, setOpenFields] = useState(() => getListFromValues(formValues, prefix));

  const removeItem = useCallback(
    (indexToRemove) => {
      const parsedValues = removeItemFromFieldList<T>(prefix, indexToRemove, formValues);

      setOpenFields(getListFromValues(parsedValues, prefix));
      // FIXME either find another way to remove an item
      // or fix typing issue
      if (formHandlers['onSetFields']) {
        formHandlers['onSetFields']?.(parsedValues);
      } else {
        // mm3 case
        formHandlers['onChange']?.(parsedValues);
      }
    },
    [formValues, prefix, formHandlers],
  );

  const getItemsProps = useCallback(
    (index: number) => {
      const name = `${prefix}[${index}]`;
      const isRemoved = Boolean(get(formValues, `${name}._destroy`));

      return {
        name,
        ...fieldProps?.({ index, name, isRemoved }),
        index,
        isRemoved,
      };
    },
    [formValues, prefix, fieldProps],
  );

  const initialValues = useRef({
    formData,
    formHandlers,
    formValues,
    prefix,
    setOpenFields,
    openFields,
    initialFieldsCount,
    defaultOrder,
  });

  useEffect(() => {
    const { formData, formHandlers, formValues, prefix, setOpenFields, openFields, initialFieldsCount, defaultOrder } =
      initialValues.current;
    for (let i = openFields.length; i < initialFieldsCount; i++) {
      addItem({ formData, formHandlers, formValues, prefix, setOpenFields, defaultOrder });
    }
  }, []);

  const handleOnAddItem = (): void => {
    addItem({ formData, formHandlers, formValues, prefix, setOpenFields, defaultOrder });
  };

  const reorderItems = useCallback(
    (index: number, direction: 'up' | 'down'): void => {
      const list = get(formValues, prefix);
      const newList = [...list];
      const newIndex = index + (direction === 'up' ? -1 : 1);

      newList[index] = list[newIndex];
      newList[newIndex] = list[index];

      formHandlers.onChange({ [prefix]: newList } as Partial<T>);
    },
    [formHandlers, formValues, prefix],
  );

  return (
    <FormDynamicFields
      values={openFields}
      label={label}
      limit={limit}
      Item={component}
      itemProps={getItemsProps}
      onAddItem={handleOnAddItem}
      moveItem={withSorting ? reorderItems : undefined}
      onRemoveItem={removeItem}
      className={className}
    />
  );
};
