import { UmGenericListItem } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { useRemoveFromListAction } from './remove-from-list';
import { IListItemActionName } from './types';
import { useEditTitleAction } from './edit-title';

export { useRemoveFromListAction } from './remove-from-list';

export const useListItemActions = (
  entities: UmGenericListItem[] = [],
  options: IUseActionsOptionParam<UmGenericListItem>,
): IUseActionsResponse<IListItemActionName> => {
  const deleteItem = useRemoveFromListAction(entities, options);
  const editTitle = useEditTitleAction(entities, options);
  const actions = [deleteItem, editTitle];

  return useActions(actions, options, entities);
};
