import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Model } from 'helpers/filters/types';
import { FormInput, useForm } from 'helpers/form';
import { IAssetPresetFields } from 'types';

import { getStorageItem, setStorageItem, STORAGE_KEYS } from 'utils/storage';
import { useDialogStore } from 'store';
import { ActionIcon } from '@mantine/core';
import { MantineIcon } from 'utils/ui';

interface ISavePresetFormFields {
  name: string;
}

const initialValues: ISavePresetFormFields = { name: '' };

export interface ISaveAssetPresetFormProps {
  preset: IAssetPresetFields;
  setPresets: React.Dispatch<React.SetStateAction<object>>;
}

const SaveAssetPresetForm: React.FC<ISaveAssetPresetFormProps> = observer(({ preset, setPresets }) => {
  const { close } = useDialogStore();

  const handleSubmit = useCallback(
    ({ name }: ISavePresetFormFields): void => {
      const saved = getStorageItem(STORAGE_KEYS.ASSET_UPLOAD_PRESETS);
      setStorageItem<Record<string, IAssetPresetFields>>(STORAGE_KEYS.ASSET_UPLOAD_PRESETS, {
        ...saved,
        [name]: preset,
      });
      setPresets(getStorageItem(STORAGE_KEYS.ASSET_UPLOAD_PRESETS));
      close();
    },
    [close, preset, setPresets],
  );

  const { formData, handlers, onSubmit } = useForm(initialValues, Model.ASSETS, handleSubmit);

  return (
    <form onSubmit={onSubmit} style={{ display: 'grid', gridTemplateColumns: '1fr 20px' }}>
      <FormInput label="Add Preset Name" name="name" placeholder="Type Preset Name" {...formData.name} {...handlers} />
      <ActionIcon variant="subtle" color="gray.5" type="submit" mt="30px">
        <MantineIcon icon="floppy-disk" />
      </ActionIcon>
    </form>
  );
});

export default SaveAssetPresetForm;
