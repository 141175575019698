import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { Select } from '@blueprintjs/select';
import ReactPaginate from 'react-paginate';

import { useProfileStore, useStore } from 'store';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';

import PaginationItem from './pagination-item';
import { MenuItem } from '@blueprintjs/core';
import { Button } from '@mantine/core';
import { Globals } from 'types';
import './style.scss';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { MantineIcon } from 'utils/ui/icon';
import { DoubleCaretVertical } from 'blueprint5-icons';

const Pagination: React.FC = observer(() => {
  const {
    dataSectionStore: { searchStore, updateStore },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const updateSettings = useProfileStore(({ updateSettings }) => updateSettings);
  const itemsPerPage = useProfileStore(({ settings }) => settings.itemsPerPage);

  const handlePageClick = useCallback(
    ({ selected }): void => {
      const searchData = queryString.parse(location.search.substring(1), { parseNumbers: true });
      const searchString = queryString.stringify({ ...searchData, page: selected + 1 });

      // switch to filter tab on page change
      updateStore({ active: null, activeTab: DataSectionSidebarTabs.FILTERS });

      navigate({ pathname: location.pathname, search: searchString });
    },
    [location, updateStore, navigate],
  );

  const handlePerHandle = useCallback(
    (newItemsPerPage) => {
      searchStore.api.setParams({ per: newItemsPerPage });
      updateSettings('itemsPerPage', newItemsPerPage);
      localStorage.setItem(Globals.DATASECTION_ITEMS_PER_PAGE_LS, newItemsPerPage);
      handlePageClick({ selected: 0 });
    },
    [handlePageClick, searchStore.api, updateSettings],
  );

  const goToPage = useCallback(
    (e) => {
      handlePageClick({ selected: e.target.tabIndex - 1 });
    },
    [handlePageClick],
  );

  const showLastPages = searchStore.totalCount < 9999;

  if (searchStore?.totalPages === undefined || searchStore?.page === undefined || searchStore?.totalPages === 0) {
    return null;
  }

  return (
    <ul className="data-section__pagination-container">
      <PaginationItem page={searchStore.page} goToPage={goToPage} tabIndex={1} label="First" />
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'data-section__pagination__dots mx-1'}
        pageCount={Number(searchStore.totalPages)}
        marginPagesDisplayed={showLastPages ? 2 : 0}
        pageRangeDisplayed={5}
        forcePage={searchStore.page - 1}
        onPageChange={handlePageClick}
        containerClassName={'data-section__pagination'}
        activeLinkClassName={'data-section__page-btn--active'}
        pageLinkClassName={`data-section__page-btn`}
        previousLinkClassName={`data-section__page-btn`}
        nextLinkClassName={`data-section__page-btn`}
      />
      {showLastPages && (
        <PaginationItem page={searchStore.page} goToPage={goToPage} tabIndex={searchStore.totalPages} label="Last" />
      )}
      <Select
        popoverProps={{ minimal: true }}
        matchTargetWidth
        filterable={false}
        activeItem={itemsPerPage}
        items={[100, 50, 25]}
        onItemSelect={handlePerHandle}
        itemRenderer={(count, { handleClick, modifiers }) => (
          <MenuItem
            textClassName="items-per-page-select__item"
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={count}
            onClick={handleClick}
            text={count}
          />
        )}
      >
        <Button size="xs" variant="default" rightSection={<MantineIcon icon={<DoubleCaretVertical />} />}>
          {itemsPerPage}
        </Button>
      </Select>
    </ul>
  );
});

export default Pagination;
