import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { IFiltersDefinition, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { useFilters } from 'helpers/filters';
import { getCustomOwnersOptionsProvider } from 'helpers/filters/option-providers';

import { useStore } from 'store';

import { DataSectionPreset } from 'components/data-section-preset';

export interface INewsFilters extends IFiltersDefinition {
  _: QFilter;
  owner_id: InFilter;
  access_level: InFilter;
  date: RangeFilter;
  created_at: RangeFilter;
  updated_at: RangeFilter;
}

export const defaultFilters = {
  _: new QFilter(''),
  date: new RangeFilter('date', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new InFilter('owner_id', []),
  access_level: new InFilter('access_level', []),
};

const accessOptionsProvider = new Mm3ContextDataProvider(Model.NEWS, 'access_level');

const handleSubmit = (action): void => submitFilters({ action });

export const NewsFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore || {};

  const [filterValues, filterHandlers] = useFilters<INewsFilters>(dataSectionStore, handleSubmit);

  const ownerAggregation = searchStore.aggregationValues?.('owner_id');

  const ownersOptionsProvider = useMemo(() => getCustomOwnersOptionsProvider(ownerAggregation), [ownerAggregation]);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search News"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />
          <FilterDateRange
            label="News Date"
            name="date"
            filter={filterValues.date}
            onChange={filterHandlers.onChange}
          />
          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues?.('access_level')}
          />
          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues?.('owner_id')}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />
          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});
