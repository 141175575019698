import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import cx from 'classnames';
import _ from 'lodash';

import { Checkbox } from '@mantine/core';
import { ActionIcon, Tooltip, Popover, Menu, MenuItem } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { Sort, SortDesc } from 'blueprint5-icons';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Pagination } from 'components/data-section/pagination';
import { DataSectionSidebarTabs, IDataSectionSidebarTabs } from 'components/data-section-sidebar';
import { DataSectionSidebar } from 'components/data-section-sidebar';
import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { ListTileViewToggleButton } from 'components/list-tile-view-toggle-button';
import { HeaderTextSearch } from 'components/header-text-search';
import { DataSectionContentWrapper } from 'components/data-section-content-wrapper/data-section-content-wrapper';

import { ParametronOrder, ParametronSort, Model } from 'helpers/filters/types';
import { IDataSectionLayout, IBasket, IEntity } from 'types';
import { parseQueryParams } from 'utils/general';
import { MantineIcon } from 'utils/ui/icon';
import { getAssetModel } from 'utils/asset';
import { useStore } from 'store';
import { ParametronStore } from 'store/parametron-store';

import './style.scss';

const getSortTypeMenu = (
  target: string,
  sort: string,
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): React.ReactElement => {
  const isProduct = target === Model.PRODUCTS;
  const isAsset = target === getAssetModel();
  const isContact = target === Model.CONTACTS;

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
      {isProduct && (
        <>
          <Menu.Item
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.DISPLAY_TITLE}
            className={cx({ active: sort === ParametronSort.DISPLAY_TITLE })}
          >
            Title
          </Menu.Item>
          <Menu.Item
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION}
            className={cx({ active: sort === ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION })}
          >
            Year of Production
          </Menu.Item>
        </>
      )}
      {isContact && (
        <>
          <Menu.Item
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.FIRST_NAME}
            className={cx({ active: sort === ParametronSort.FIRST_NAME })}
          >
            First Name
          </Menu.Item>
          <Menu.Item
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.LAST_NAME}
            className={cx({ active: sort === ParametronSort.LAST_NAME })}
          >
            Last Name
          </Menu.Item>
          <Menu.Item
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.LAST_LOGIN}
            className={cx({ active: sort === ParametronSort.LAST_LOGIN })}
          >
            Last Login
          </Menu.Item>
        </>
      )}
      {isAsset && (
        <>
          <MenuItem
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.PUBLISHED_AT}
            className={cx({ active: sort === ParametronSort.PUBLISHED_AT })}
          >
            Date Published
          </MenuItem>
          <MenuItem
            onClick={handleSort}
            data-param="sort"
            data-value={ParametronSort.NAME}
            className={cx({ active: sort === ParametronSort.NAME })}
          >
            Name
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

const getSortOrderMenu = (
  order: string,
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): React.ReactElement => {
  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="order"
        data-value={ParametronOrder.DESCENDING}
        className={cx({ active: order === ParametronOrder.DESCENDING })}
      >
        Descending
      </Menu.Item>
      <MenuItem
        onClick={handleSort}
        data-param="order"
        data-value={ParametronOrder.ASCENDING}
        className={cx({ active: order === ParametronOrder.ASCENDING })}
      >
        Ascending
      </MenuItem>
    </Menu>
  );
};

const DataSectionHotkeyTarget: React.FC<{ target: string }> = ({ children }) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const focusList = (): void => {
    if (sectionRef.current) {
      sectionRef.current.focus();
    }
  };

  useHotkeys([['shift+a', focusList]]);

  return (
    <div className="data-section__content-wrapper" ref={sectionRef}>
      {children}
    </div>
  );
};

export type ICustomSortMenuProp = (
  sort: ParametronSort,
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  searchStore: ParametronStore,
) => React.ReactElement;

export interface IDataSectionDefaultParams {
  sort?: ParametronSort;
  order?: ParametronOrder;
}

export interface IDataSectionProps {
  className?: string;
  itemRenderer: (item: React.ReactElement | IEntity | IBasket) => React.ReactElement;
  contextMenu?: React.ReactElement;
  tabs?: IDataSectionSidebarTabs;
  layout?: IDataSectionLayout;
  showListTileViewToggleButton?: boolean;
  headerRightSection?: React.ReactElement;
  customSortMenu?: ICustomSortMenuProp;
  defaultParams?: IDataSectionDefaultParams;
  hideSortButtons?: boolean;
  customOrderRightSection?: boolean;
  hideSearchButton?: boolean;
}

const defaultSortParams = { sort: ParametronSort.UPDATED_AT, order: ParametronOrder.DESCENDING };

export const DataSection: React.FC<IDataSectionProps> = observer((props) => {
  const {
    dataSectionStore: { searchStore, target, checked, updateStore, reset, isAllItemsSelected, setParams },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [allowRender, setAllowRender] = useState(false);

  const {
    className,
    defaultParams = defaultSortParams,
    itemRenderer,
    contextMenu,
    tabs,
    layout = 'list',
    showListTileViewToggleButton = true,
    customSortMenu,
    hideSortButtons,
    hideSearchButton = false,
  } = props;
  const { totalCount, running } = searchStore || {};
  const search = location.search;
  const sectionItems = searchStore?.objects || [];
  const sort = searchStore?.parametron?.data?.params.sort as ParametronSort;
  const order = searchStore?.parametron?.data?.params.order as ParametronOrder;

  // on mount reset store, then render
  useEffect(() => {
    reset();
    setAllowRender(true);
  }, [reset]);

  // parametron fires here after navigate()
  useEffect(() => {
    if (!searchStore?.parametron || !allowRender) {
      return;
    }

    const searchData = parseQueryParams(search);
    setParams(searchData).triggerSearch();
  }, [location, searchStore, search, allowRender, setParams]);

  const handleSelectAllLoaded = (e): void => {
    if (isAllItemsSelected) {
      return;
    }

    const isChecked = e.target.checked;

    if (isChecked) {
      updateStore({
        checked: _.uniqBy([...checked, ...sectionItems], 'id'),
        activeTab: DataSectionSidebarTabs.SELECTED_ITEMS,
      });
      return;
    }

    const productIds = sectionItems.map((product) => product.id);
    const newChecked = checked.filter((item) => !productIds.includes(item.id));

    updateStore({ checked: _.uniqBy([...newChecked], 'id') });
  };

  const handleSort = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      const value = e.currentTarget.getAttribute('data-value');
      const param = e.currentTarget.getAttribute('data-param');
      const queryParams = parseQueryParams(location.search);

      const query = { ...queryParams, ...(param ? { [param]: value } : {}) };

      const searchString = queryString.stringify({ ...query, page: 1 });
      navigate({ pathname: location.pathname, search: searchString });
    },
    [location, navigate],
  );

  const sortMenuCmp = useMemo(
    () => customSortMenu?.(sort, handleSort, searchStore) || getSortTypeMenu(target, sort, handleSort),
    [customSortMenu, handleSort, sort, target, searchStore],
  );
  const sortOrderMenu = useMemo(() => getSortOrderMenu(order, handleSort), [handleSort, order]);
  const CustomRightRenderer = (): React.ReactElement => {
    const [sortOpened, setSortOpened] = useState(false);
    const [orderOpened, setOrderOpened] = useState(false);
    return (
      <>
        {!props.customOrderRightSection && !hideSearchButton && <HeaderTextSearch />}
        {props.headerRightSection}
        {props.customOrderRightSection && !hideSearchButton && <HeaderTextSearch />}
        {showListTileViewToggleButton && <ListTileViewToggleButton />}
        {hideSortButtons ? (
          <></>
        ) : (
          <>
            <Popover position="bottom" withArrow shadow="md" opened={sortOpened} onChange={setSortOpened}>
              <Popover.Target>
                <Tooltip label="Sort" position="bottom">
                  <ActionIcon
                    data-active={sort !== (defaultParams?.sort || ParametronSort.UPDATED_AT) ? 'active' : null}
                    variant="subtle"
                    color="gray.5"
                    radius="sm"
                    disabled={running}
                    onClick={() => setSortOpened((o) => !o)}
                  >
                    <MantineIcon icon={<Sort />} />
                  </ActionIcon>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown onClick={() => setSortOpened(false)}>{sortMenuCmp}</Popover.Dropdown>
            </Popover>

            <Popover position="bottom" withArrow withinPortal opened={orderOpened} onChange={setOrderOpened}>
              <Popover.Target>
                <Tooltip label="Order" position="bottom">
                  <ActionIcon
                    className="mx-1"
                    data-active={order !== (defaultParams?.order || ParametronOrder.DESCENDING) ? 'active' : null}
                    variant="subtle"
                    color="gray.5"
                    radius="sm"
                    disabled={running}
                    onClick={() => setOrderOpened((o) => !o)}
                  >
                    <MantineIcon icon={<SortDesc />} />
                  </ActionIcon>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown onClick={() => setOrderOpened(false)}>{sortOrderMenu}</Popover.Dropdown>
            </Popover>
          </>
        )}
      </>
    );
  };

  const customLeftRenderer = (): React.ReactElement => (
    <div className="d-flex align-items-center">
      <Checkbox
        disabled={running || !sectionItems.length || isAllItemsSelected}
        checked={allProductsChecked || isAllItemsSelected}
        onChange={handleSelectAllLoaded}
        className="data-section__checkbox mb-0"
      />
      {sectionItems.length > 0 && (
        <>
          <span className="data-section__item-count px-2">
            {isAllItemsSelected ? totalCount : checkedSectionItemsCount} / {totalCount}
          </span>
        </>
      )}
    </div>
  );

  if (!searchStore || !allowRender) {
    return null;
  }

  const checkedIds = checked.map((item) => item.id);
  const allProductsChecked =
    Boolean(sectionItems?.length) && sectionItems.every((product) => checkedIds.includes(product.id));
  const checkedSectionItemsCount = sectionItems.filter((product) => checkedIds.includes(product.id))?.length;

  return (
    <div
      className={cx('data-section-layout h-100', {
        'data-section-layout--sidebar': true,
      })}
    >
      <div className={cx('data-section', className)}>
        <SectionHeader
          useBackground
          customLeftRenderer={customLeftRenderer}
          customRightRenderer={CustomRightRenderer}
        />
        {running && <Loading text="Loading Data" />}

        {!running &&
          (sectionItems.length ? (
            <DataSectionHotkeyTarget target={target}>
              <DataSectionContentWrapper contextMenu={contextMenu} layout={layout}>
                {sectionItems.map(itemRenderer)}
              </DataSectionContentWrapper>
            </DataSectionHotkeyTarget>
          ) : (
            <SectionMessage intent={SectionMessageType.EMPTY} />
          ))}
        <Pagination />
      </div>

      {tabs && <DataSectionSidebar className="data-section__sidebar" contextMenu={contextMenu} tabs={tabs} />}
    </div>
  );
});
