import React, { useCallback } from 'react';
import { ModalWrapper } from 'components/dialogs';
import { IInfoDialogProps } from 'components/dialogs/types';

const InfoDialog: React.FC<IInfoDialogProps> = ({ icon, title, body, canOutsideClickClose = true }) => {
  const Body = useCallback(() => {
    return <div>{body}</div>;
  }, [body]);

  return (
    <ModalWrapper
      opened
      title={title}
      icon={icon}
      body={Body}
      canOutsideClickClose={canOutsideClickClose}
      onClose={() => null}
    />
  );
};

export default React.memo(InfoDialog);
