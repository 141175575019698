import { useEffect, useState } from 'react';

import { IBarChartData, ILatestActivitiesData, IUseAnalyticsParams, IUseAnalyticsResponse } from 'types';
import { queryProducts } from 'utils/apis/product';
import { useAnalyticsStore } from 'store/analytics-store';
import { dateFormat, formatDate } from 'utils/date';

export function useAnalytics({
  getHistogram,
  getLatestActivities,
  getMinDate,
  initialDateRange,
  state,
  tab,
  section,
  entity,
  interaction,
}: IUseAnalyticsParams): IUseAnalyticsResponse {
  const {
    onDateRangeChange,
    onProductsSelect,
    onContactsSelect,
    onRecipientsSelect,
    onSendersSelect,
    onDescendantsChange,
    onTerritoriesSelect,
    onAssetsSelect,
    onNonScreeningsAssetsChange,
    getAnalyticsFilters,
    reset: resetFilters,
    init,
    minDate,
  } = useAnalyticsStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    init({ state, getMinDate, initialDateRange });
  }, [state, init, getMinDate, initialDateRange]);

  const {
    dateRange,
    productIds,
    geoScopeIds,
    includeDescendants,
    userIds,
    assetIds,
    recipientIds,
    senderIds,
    includeNonScreeningsAssets,
    loading,
  } = getAnalyticsFilters({
    tab,
    section,
    entity,
    interaction,
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const [latestActivities, setLatestActivitiesData] = useState<ILatestActivitiesData[]>([]);
  const [histogram, setHistogramData] = useState<IBarChartData[]>([]);

  useEffect(() => {
    const [from_date, to_date] = dateRange;
    if (getLatestActivities && from_date && to_date) {
      const params = {
        from_date: formatDate(from_date, dateFormat),
        to_date: formatDate(to_date, dateFormat),
      };

      getLatestActivities(params).then(setLatestActivitiesData);
    }
  }, [dateRange, getLatestActivities]);

  useEffect(() => {
    const [from_date, to_date] = dateRange;
    if (getHistogram && from_date && to_date) {
      const params = {
        from_date: formatDate(from_date, dateFormat),
        to_date: formatDate(to_date, dateFormat),
      };
      getHistogram(params).then(setHistogramData);
    }
  }, [dateRange, getHistogram]);

  useEffect(() => {
    const stateAssetIds = state?.entity === 'asset' && state?.entityId ? [state?.entityId as string] : undefined;

    if (stateAssetIds) {
      queryProducts({ search: { filters: [['asset_ids', 'in', stateAssetIds]] } }).then((products) => {
        const ids = products.map(({ id }) => String(id));
        useAnalyticsStore.setState({ productIds: ids });
      });
    }
  }, [state?.entity, state?.entityId]);

  return {
    data: { histogram, latestActivities },
    onDateRangeChange,
    onProductsSelect,
    onContactsSelect,
    onDescendantsChange,
    onNonScreeningsAssetsChange,
    onTerritoriesSelect,
    onAssetsSelect,
    resetFilters: () => resetFilters(getMinDate),
    minDate,
    productIds,
    territories: geoScopeIds,
    includeDescendants,
    contactIds: userIds,
    assetIds,
    dateRange,
    loading: isLoading,
    onRecipientsSelect,
    onSendersSelect,
    recipientIds,
    senderIds,
    includeNonScreeningsAssets,
  };
}
