import React from 'react';
import './style.scss';

/**
 * Wraps any passed children inside a div with the given aspect ratio
 * @param children - elements to be rendered inside the aspect ratio wrapper
 * @param ratio - for 16:9 ratio={16/9}, for 1:1 ratio={1} for 4:3 ratio={4/3}
 */
const AspectRatio: React.FC<{ ratio: number }> = ({ children, ratio = 1 }) => (
  <div className="aspect-ratio-container" style={{ paddingTop: `${100 / ratio}%` }}>
    <div className="aspect-ratio-content">{children}</div>
  </div>
);

export default AspectRatio;
