import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';

import { Page } from 'components/layout';
import { DeliveryWizardContainer } from 'components/delivery/delivery-wizard-container';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

const DeliveryCreate: React.FC = observer(() => {
  const navigate = useNavigate();
  const onFinish = useCallback(
    (delivery: DeliveriesGenericDeliveryPackage): void => {
      navigate(`${Routes.ORGANIZATIONS}/${delivery.organization_id}/deliveries/${delivery.id}`);
    },
    [navigate],
  );

  return (
    <Page title="Create New Delivery Package">
      <DeliveryWizardContainer onFinish={onFinish} />
    </Page>
  );
});

export default DeliveryCreate;
