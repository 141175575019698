import { useCallback } from 'react';

import { ISubtitle } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

import { pluralEntityInflect } from 'utils/general';
import { ISubtitleActionConfig, ISubtitleActionName } from './types';
import { deleteSubtitle } from 'utils/apis/subtitle';

export const useDeleteAction = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): ISubtitleActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Subtitle', items.length);

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }
      const deletedSubtitle = await deleteSubtitle(items[0]);
      toastStore.success(`${entityWithCount} deleted`);
      await options?.onSuccess?.(ISubtitleActionName.DELETE, deletedSubtitle);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback((): void => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: ISubtitleActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
