import { useCallback } from 'react';

import type { ItemId } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { deleteGroups } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { shouldDisplayAccessPrivilege as shouldDisplay } from './actions-acl';

import { IAccessPrivilegeActionConfig, IAccessPrivilegeActionName } from './types';

export const useDeleteAction = (
  items: { deleted_at?: string; id?: ItemId }[],
  options: IUseActionsOptionParam,
): IAccessPrivilegeActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Access Privilege', items.length);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id).filter(Boolean) as ItemId[];
      await deleteGroups(itemIds);

      options?.onSuccess?.(IAccessPrivilegeActionName.DELETE);

      toastStore.success(`${entityWithCount} were deleted`);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IAccessPrivilegeActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
