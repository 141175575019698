import React from 'react';

import { Thumbnail } from 'components/thumbnail';

import { IConference } from 'types';
import { getContactName } from 'utils/ui';
import { Classes } from 'utils/ui';

import './style.scss';

interface IConferenceItem {
  conference: IConference;
}

export const ConferenceItem: React.FC<IConferenceItem> = ({ conference }) => {
  const { title, id, mode, banner_media, owner } = conference;

  return (
    <li key={`conference-${id}`} className="conference-selected-tab-item">
      <div className="conference-selected-tab-item__thumb px-2">
        <Thumbnail image={banner_media} customTag={mode} showType />
      </div>
      <div className="conference-selected-tab-item__content">
        <div className={`asset-selected-tab-item__title ${Classes.TEXT_MUTED}`}>{title}</div>
        <div className="text-truncate">{getContactName(owner)}</div>
      </div>
    </li>
  );
};

export default ConferenceItem;
