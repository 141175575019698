import { ItemId } from 'types';
import { useBasicStore } from 'store';
import { countries } from '@mediafellows/tuco/dist/lib/localization';
import { getOptionLabels } from 'utils/general';

/**
 * Get country ids and return country names
 */
export const useCountryNames = (ids: (ItemId | undefined)[] | null = []): string[] => {
  const { countriesDataOptions } = useBasicStore();

  return getOptionLabels(ids, countriesDataOptions);
};

/**
 * Also get country ids and return country names, but use countries list from tuco
 */
export const formatCountryNames = (ids: (ItemId | undefined)[] = []): string[] => {
  const countriesDataOptions = countries.map((country) => ({ value: country.id.toLowerCase(), label: country.name }));

  return getOptionLabels(ids, countriesDataOptions);
};
