import React from 'react';

import { formatDuration } from 'utils/date';
import { AnalyticsContact, AnalyticsOrganization, UserTerritory, UserAgent } from 'utils/ui';
import { ItemId } from 'types';
import DateElement from 'components/date-list-element/date-list-element';
import { ListTable } from 'components/list-table';
import { IColumn } from 'components/list-table';

import { exportAttendanceTableData, getAttendanceTableData, IAttendanceTableItem } from './api';

import './style.scss';

const defaultColumns: { title: string; renderer: (data: IAttendanceTableItem) => React.ReactNode }[] = [
  {
    title: 'Contact',
    renderer: ({ user }) => <AnalyticsContact user={user} />,
  },
  {
    title: 'Organization',
    renderer: ({ user, organization }) => <AnalyticsOrganization user={user} organization={organization} />,
  },
  {
    title: 'Country (IP Country)',
    renderer: ({ user, country }) => {
      return <UserTerritory countryId={user.country_id} geoCountry={country as string} />;
    },
  },
  { title: 'Active Time', renderer: ({ duration }) => formatDuration(duration, 'seconds') },
  { title: 'Device Info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  { title: 'Attended at', renderer: ({ last_occured_at }) => <DateElement date={last_occured_at} /> },
  {
    title: 'Responsible Contact',
    renderer: (responsible_user) => <AnalyticsContact user={responsible_user.user} />,
  },
];

const AttendanceListTable: React.FC<{
  title: string;
  entity?: string;
  entityId?: ItemId | ItemId[] | null;
  dateRange: [Date, Date];
  queryName?: string;
  columns?: IColumn[];
}> = ({ title, entity, entityId, dateRange, queryName, columns }) => {
  return (
    <ListTable
      title={title}
      entity={entity}
      entityId={entityId}
      from_date={dateRange[0]}
      to_date={dateRange[1]}
      query_name={queryName}
      columns={columns || defaultColumns}
      getTableData={getAttendanceTableData}
      exportTableData={exportAttendanceTableData}
    />
  );
};

export default AttendanceListTable;
