import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { IMobileAppSync } from 'types';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { MobileAppSyncDataSectionListItem } from './mobile-sync-data-section-list-item';

interface IMobileAppSyncDataSectionItemProps {
  className?: string;
  mobileAppSync: IMobileAppSync;
  contextMenu?: React.ReactElement;
}

const MobileAppSyncDataSectionItem: React.FC<IMobileAppSyncDataSectionItemProps> = observer((props) => {
  const { contextMenu, mobileAppSync } = props;
  const { id } = mobileAppSync;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <MobileAppSyncDataSectionListItem
      contextMenu={contextMenu}
      handleClick={handleClick}
      mobileAppSync={mobileAppSync}
      isChecked={isChecked}
      isCheckedDisabled={isAllItemsSelected}
      isActive={isActive}
    />
  );
});

export default MobileAppSyncDataSectionItem;
