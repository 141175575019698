import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { isValid } from 'date-fns';

import {
  parseRecommendationDataForMultiBarChart as parseData,
  RecommendationAnalyticsListTable,
  RecommendationAnalyticsTopUsers,
  DateCountMultiBarChart,
  CountGaugeChart,
  IChartContext,
  ContactInteractions,
} from 'components/analytics';
import { SectionHeader } from 'components/section-header';
import { recommendationSentColumns } from './recommendation-sent-columns';

import { getHistogramData } from 'utils/apis/analytics';
import { IAnalyticsProps, IRecommendationHistogramData, IBarChartData, DetailsPageTabs } from 'types';
import { Routes } from 'utils/routes';

const AnalyticsInteractionsRecommendationsSent: React.FC<IAnalyticsProps> = (props) => {
  const [countByUniqueRecommendationSent, setCountByUniqueRecommendationSent] = useState([] as IBarChartData[]);
  const [countByUniqueRecommendationViewed, setCountByUniqueRecommendationViewed] = useState([] as IBarChartData[]);
  const [successPercentage, setSuccessPercentage] = useState([] as number[]);
  const [countBySuccessPercentage, setCountBySuccessPercentage] = useState<IRecommendationHistogramData>();
  useEffect(() => {
    if (!props.dateRange.every(isValid) || !props) {
      return;
    }
    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationSentCount,
      ...props,
      entity: 'sender',
    }).then(({ data }) => {
      const { viewedData, sentData } = parseData(data);
      setCountByUniqueRecommendationSent(sentData);
      setCountByUniqueRecommendationViewed(viewedData);
    });

    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationSentSuccessRate,
      ...props,
      entity: 'sender',
    }).then(({ data }) => {
      if (!data.length) {
        setSuccessPercentage([]);
      } else {
        const successRate = data?.[0].success_percentage || 0;
        const parsedData = [successRate, 100 - successRate];

        setSuccessPercentage(parsedData);
        setCountBySuccessPercentage(data?.[0]);
      }
    });
  }, [props]);

  const topRecipients = useMemo(
    (): JSX.Element => (
      <RecommendationAnalyticsTopUsers
        queryName={props?.queries?.recommendationTopRecipients}
        {...{ ...props, entity: 'sender' }}
        entityName="recipient"
        url={`${Routes.ANALYTICS_RECOMMENDATIONS}/${DetailsPageTabs.LIST}`}
        interaction={ContactInteractions.RECOMMENDATIONS_RECEIVED}
      />
    ),
    [props],
  );

  const customTooltipFormatter = useCallback(
    (context: IChartContext): string => {
      const label = context.label;
      if (label === 'sent') return `${countBySuccessPercentage?.sent_count} total sent`;

      return `${countBySuccessPercentage?.viewed_count} unique view(s) per recommendation`;
    },
    [countBySuccessPercentage],
  );
  return (
    <>
      <div className="analytics-tab__content">
        <div className="analytics-tab__card">
          <SectionHeader title="Recommendations Sent" useBackground useBorder />
          <div className="analytics-tab__card-content">
            <DateCountMultiBarChart
              data1={countByUniqueRecommendationSent}
              data2={countByUniqueRecommendationViewed}
              startDate={props.dateRange[0]}
              endDate={props.dateRange[1]}
              unit1="sent"
              unit2="view(s)"
            />
          </div>
        </div>
        <div className="analytics-tab__card">
          <SectionHeader title="Success Rate" useBackground useBorder />
          <div className="analytics-tab__card-content">
            {countBySuccessPercentage?.success_percentage !== null && (
              <CountGaugeChart
                data={successPercentage}
                labels={['viewed', 'sent']}
                titleText={`${Math.round(successPercentage?.[0] * 100) / 100} %`}
                showTitle
                showLegend={false}
                customTooltipFormatter={customTooltipFormatter}
              />
            )}
          </div>
        </div>
        <div className="analytics-tab__card">{topRecipients}</div>
      </div>
      <div className="analytics-tab-list">
        <RecommendationAnalyticsListTable
          title="Detailed Recommendations Sent"
          queryName={props?.queries?.recommendationSentList}
          {...props}
          entity="sender"
          columns={recommendationSentColumns}
        />
      </div>
    </>
  );
};

export default AnalyticsInteractionsRecommendationsSent;
