import { merge } from 'lodash';
import { IDeliveryConnection } from './types';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';

export type IConnectionType = 'Connection::S3' | 'Connection::Aspera' | 'Connection::Sftp';
export const initialValues: IDeliveryConnection = {
  type: ' ' as IConnectionType,
  name: 'New Connection',
  username: '',
  password: '',
  host: '',
  port: null as unknown as number,
  description: '',
  organization_id: null as unknown as number,
  bucket: '',
  region: 'us-east-1',
  aws_access_key_id: '',
  aws_secret_access_key: '',
};

// TODO should be done by BE
export function customFormValidator(
  connection: IDeliveryConnection,
  validations: IValidationResult<IDeliveryConnection>,
): IValidationResult<IDeliveryConnection> {
  if (!connection) return validations;
  const s3Validation = connection.type === 'Connection::S3';

  return merge({}, validations, {
    name: {
      required: true,
      ...validations.name,
      ...(connection.name ? {} : getRequiredError()),
    },
    description: {
      required: true,
      ...validations.description,
      ...(connection.description ? {} : getRequiredError()),
    },
    username: {
      required: !s3Validation,
      ...validations.username,
      ...(connection.username || (s3Validation && !connection.username) ? {} : getRequiredError()),
    },
    password: {
      required: !s3Validation,
      ...validations.username,
      ...(connection.password || (s3Validation && !connection.password) ? {} : getRequiredError()),
    },
    host: {
      required: !s3Validation,
      ...validations.host,
      ...(connection.host || (s3Validation && !connection.host) ? {} : getRequiredError()),
    },
    port: {
      required: !s3Validation,
      ...validations.port,
      ...(connection.port || (s3Validation && !connection.host) ? {} : getRequiredError()),
    },
    bucket: {
      required: s3Validation,
      ...validations.bucket,
      ...(connection.bucket || (!s3Validation && !connection.bucket) ? {} : getRequiredError()),
    },
    region: {
      required: s3Validation,
      ...validations.region,
      ...(connection.region || (!s3Validation && !connection.region) ? {} : getRequiredError()),
    },
    aws_access_key_id: {
      required: s3Validation,
      ...validations.aws_access_key_id,
      ...(connection.aws_access_key_id || (!s3Validation && !connection.aws_access_key_id) ? {} : getRequiredError()),
    },
    aws_secret_access_key: {
      required: s3Validation,
      ...validations.aws_secret_access_key,
      ...(connection.aws_secret_access_key || (!s3Validation && !connection.aws_secret_access_key)
        ? {}
        : getRequiredError()),
    },
  });
}
