import { ISearchFilter, Product3Types } from 'types';

export const showcaseProductFilter: ISearchFilter = [
  'type',
  'not_in',
  [Product3Types.EPISODE_VERSION, Product3Types.EPISODE, Product3Types.FILM_VERSION],
];

export const showcaseFilters = {
  ['product']: [showcaseProductFilter],
  ['list/collection']: [['sublist', 'eq', false]] as ISearchFilter[],
};

export const collectionFilters = {
  ...showcaseFilters,
  ['list/collection']: [['sublist', 'eq', true]] as ISearchFilter[],
};
