import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { IOrganization, IQueryParams, ISearchFilter, IContact, ItemId } from 'types';
import { Model } from 'helpers/filters/types';
import { organizationListSchema } from 'utils/schemas';

export const queryOrganizations = (
  params: IQueryParams,
  filters?: ISearchFilter[],
  schema: string = organizationListSchema,
): Promise<IOrganization[]> =>
  chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.ORGANIZATIONS, 'search', {
      body: parseToSearchParams(params, filters),
      schema,
    });

    return objects;
  });

export const fetchProducerCompanies = ({ q, ids }: IQueryParams = {}): Promise<IOrganization[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.ORGANIZATIONS, 'search', {
      body: parseToSearchParams({ q, ids }, [['is_producer', 'eq', 'true']]),
    });

    return objects;
  });
};

export const loadOrganizationsAllUsers = ({
  organization_ids,
}: {
  organization_ids: ItemId[];
}): Promise<IContact[]> => {
  return chipmunk.run(
    async ({ unfurl }) =>
      (
        await unfurl('um.user', 'search', {
          body: { search: { filters: [['organization_id', 'in', organization_ids]] } },
        })
      ).objects,
  );
};

export const getOrganizationGroupIds = async (organization_ids?: ItemId): Promise<number[]> => {
  if (!organization_ids) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.ORGANIZATIONS, 'group_ids', {
      params: {
        organization_ids,
      },
    });

    return object.group_ids;
  });
};
