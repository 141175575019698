import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { Button } from '@mantine/core';
import { Import, NewPerson, Person } from 'blueprint5-icons';
import { IUseFormReturn } from 'helpers/form';
import { IContactFields } from 'pages/upsert-contact';
import { StepIds } from 'pages/upsert-contact/utils';
import { IContact } from 'types';
import { Routes } from 'utils/routes';
import { useInviteContactAction } from 'utils/actions/contact/invite';
import { useNavigate } from 'react-router';
import { useRefreshDataSection } from 'utils/hooks';
import { useStore } from 'store/index';
import { MantineIcon } from 'utils/ui/icon';

export interface IStepDoneProps {
  setCurrentStepId: (step: number) => void;
  form: IUseFormReturn<IContactFields>;
  newContact: IContact[];
  modalView?: boolean;
}

const StepDone: React.FC<IStepDoneProps> = observer((props) => {
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const refreshDataSection = useRefreshDataSection();
  const {
    dialogStore: { close },
  } = useStore();
  const { form, newContact, setCurrentStepId, modalView } = props;
  const { resetFields } = form;
  const navigate = useNavigate();

  const onSuccess = useCallback(async () => {
    setIsInvited(true);
  }, []);

  const createAnotherContact = useCallback(() => {
    resetFields();
    setIsInvited(false);
    setCurrentStepId(StepIds.Step1);
  }, [resetFields, setCurrentStepId]);

  const seeAllContacts = useCallback(() => {
    if (modalView) {
      close();
      refreshDataSection();
    } else {
      navigate(Routes.CONTACTS);
    }
  }, [navigate, modalView, refreshDataSection, close]);

  const inviteContact = useInviteContactAction(newContact, { onSuccess, context: 'single' });

  return (
    <div className="upsert-contact__done">
      <SectionMessage
        intent={SectionMessageType.SUCCESS}
        title={isInvited ? 'Contact invited successfully!' : 'Contact created successfully!'}
        modalView={modalView}
      >
        <Button
          variant="default"
          className="d-inline-flex align-items-center ms-2 mt-5"
          leftSection={<MantineIcon icon={<Import />} />}
          onClick={inviteContact?.handler}
          disabled={isInvited}
        >
          Invite Contact
        </Button>
        <Button
          variant="default"
          className="d-inline-flex align-items-center ms-2 mt-5"
          leftSection={<MantineIcon icon={<NewPerson />} />}
          onClick={createAnotherContact}
        >
          Create another Contact
        </Button>
        <Button
          variant="default"
          className="d-inline-flex align-items-center ms-2 mt-5"
          leftSection={<MantineIcon icon={<Person />} />}
          onClick={seeAllContacts}
        >
          See all Contacts
        </Button>
      </SectionMessage>
    </div>
  );
});

export default StepDone;
