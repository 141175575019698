import React, { useCallback } from 'react';
import { map } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { BasketActionsList } from 'components/action';
import { DataSection } from 'components/data-section';
import { BasketsListItem } from 'components/baskets/default-basket-list-item';
import { BasketDataSectionItem } from 'components/baskets/basket-data-section-item/basket-data-section-item';
import { BasketsPreviewTab } from 'components/baskets/basket-preview-tab';
import { DataSectionControls } from 'components/data-section-controls';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { defaultBasketFilters, BasketFilters } from 'components/baskets/basket-filters';

import mapList from 'pages/contact-details/contact-baskets-tab/map-list';

import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { basketSchema } from 'utils/schemas/basket';
import { Pages } from 'utils/actions/types';

import { Model } from 'helpers/filters/types';
import { IBasket } from 'types';
import { ActionsMenu } from 'components/action/utils';

import './style.scss';

const sidebarTabs = {
  preview: <BasketsPreviewTab basketMapper={mapList} />,
  filters: <BasketFilters />,
};

const productsDataFilters: IFilterOption[] = [];

const page = Pages.BASKETS;

const Baskets: React.FC = observer(() => {
  const {
    dataSectionStore: { updateStore, checked },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  useControlDataSection({
    persistFilters: true,
    schema: basketSchema,
    model: Model.BASKETS,
    actionName: 'get',
    defaultFilters: defaultBasketFilters,
    filters: productsDataFilters,
  });

  const onSuccess = useCallback(async (): Promise<void> => {
    updateStore({ activeTab: DataSectionSidebarTabs.FILTERS, checked: [] });
    navigate({ pathname: location.pathname, search: location.search });
  }, [updateStore, navigate, location]);

  const mapListsArray = useCallback((lists) => map(lists, (l) => mapList(l)), []);

  const handleItemRenderer = useCallback(
    (productBasketData: IBasket): JSX.Element => {
      const basket = mapList(productBasketData);

      return (
        <BasketsListItem
          key={basket.id}
          basket={basket}
          contextMenu={
            <ActionsMenu
              component={BasketActionsList}
              items={[basket]}
              options={{ context: 'single', onSuccess, page }}
            />
          }
          component={BasketDataSectionItem}
        />
      );
    },
    [onSuccess],
  );

  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="d-inline-block mb-3" />
        <div>
          <DataSectionControls customRefresh={onSuccess} />
        </div>
      </div>
      <DataSection
        itemRenderer={handleItemRenderer}
        contextMenu={
          <ActionsMenu
            component={BasketActionsList}
            items={mapListsArray(checked)}
            options={{ context: 'multi', onSuccess, page }}
          />
        }
        tabs={sidebarTabs}
        showListTileViewToggleButton={false}
      />
    </div>
  );
});

export default Baskets;
