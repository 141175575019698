import { isString } from 'lodash';
import { emptyValidation, isBlank } from './empty';

export interface IFormatValidationOpts {
  with?: string;
  without?: string;
  allow_blank?: boolean;
  message?: string;
}

const decodeExpression = (value): RegExp => {
  const arr = value.split('/');
  arr.shift();

  let flags = arr.pop();

  if (!flags.includes('m')) {
    flags += 'm';
  }

  return new RegExp(arr.join('/'), flags);
};

export const formatValidation = (opts: IFormatValidationOpts, value = ''): [boolean, string?] => {
  const { with: withString, without, allow_blank, message } = opts;
  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank }, value);
  const [emptyWithStringResult] = emptyValidation({ allow_blank: false, allow_nil: false }, withString);
  const [emptyWithoutResult] = emptyValidation({ allow_blank: false, allow_nil: false }, without);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  if (allow_blank && isBlank(value)) {
    return [true];
  }

  if (!emptyWithoutResult && !emptyWithStringResult) {
    return [false, '"with" and "without" are both empty'];
  }

  const msg = message || 'is invalid';
  const withRegex = isString(withString) && decodeExpression(withString);
  const withoutRegex = isString(without) && decodeExpression(without);
  const withOnly = !withoutRegex && withRegex && withRegex.test(value);
  const withoutOnly = !withRegex && withoutRegex && !withoutRegex.test(value);
  const withAndWithout = withRegex && withoutRegex && withRegex.test(value) && !withoutRegex.test(value);

  if (withOnly || withoutOnly || withAndWithout) {
    return [true];
  }

  return [false, msg];
};
