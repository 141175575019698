import React, { useCallback, useLayoutEffect, useMemo, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { map, uniqBy } from 'lodash';

import { Loading } from 'components/loading';
import { EmptySectionMessage } from 'components/section-message/section-message';
import { TreeWrapper } from 'components/marketing-entity-detail';
import ScrollWrapper from 'components/scroll-wrapper';

import { createDataTree, IOmniTreeNode, IOmniTreeSelectOption } from 'helpers/form/fields/form-select';
import { FormCheckbox } from 'helpers/form';

import { IAsset, IGroupAssetItem, IGroupEntityIdName, IProduct, ISearchFilter, ItemId } from 'types';
import { IOnCheckNode } from 'components/checkbox-tree/types';
import { getGroupItemsIds, getProductsWithAssets, getFlatOptionsTree, checkUpdatedAssets } from './utils';
import { getEntityIdFromParams } from 'utils/general';
import { useRemote } from 'utils/hooks';

export interface IProductWithAssets {
  product: IProduct;
  assets: IAsset[];
}

interface IGroupAssetTreeProps {
  setAssetsCount?: React.Dispatch<React.SetStateAction<number>>;
  setSyncSize?: React.Dispatch<React.SetStateAction<number>>;
  entityIdName?: IGroupEntityIdName;
  propGroupId?: ItemId;
  productIds?: number[] | null | undefined;
  assetIds?: number[] | null | undefined;
  disabled?: boolean;
  groupAssets?: IGroupAssetItem[];
  onAssetUpdate: (
    assetsToUpdate?: {
      assetsToAdd?: string[] | undefined;
      assetsToDelete?: string[] | undefined;
    },
    assetIdsToGroupItemIds?: Record<string, string>,
  ) => Promise<void>;
  isSaveClicked: boolean;
  isCancelClicked: boolean;
  setIsCancelClicked: React.Dispatch<React.SetStateAction<boolean>>;
  assetFilters?: ISearchFilter[];
  productWithAssetsProp?: IProductWithAssets[];
}

const GroupAssetsTreeTab: React.FC<IGroupAssetTreeProps> = observer(
  ({
    setAssetsCount,
    setSyncSize,
    entityIdName,
    propGroupId,
    disabled = true,
    productIds,
    assetIds,
    onAssetUpdate,
    isSaveClicked,
    groupAssets,
    isCancelClicked,
    setIsCancelClicked,
    assetFilters,
    productWithAssetsProp,
  }) => {
    const params = useParams<{ entityIdName: IGroupEntityIdName }>();
    const groupId = entityIdName ? getEntityIdFromParams(params, entityIdName) : String(propGroupId);

    const [checked, setChecked] = useState<string[][]>([]);
    const checkedRef = useRef<string[][]>();
    const [checkedVideos, setCheckedVideos] = useState<IOmniTreeSelectOption[]>([]);
    const [currentFilters, setCurrentFilters] = useState<Record<string, boolean>>({ select_all: false });

    const [updatedAssets, setUpdatedAssets] = useState<Record<string | number, boolean> | undefined>({});

    const fetchProductsWithAssets = useCallback(
      async (): Promise<IProductWithAssets[]> =>
        productWithAssetsProp ? productWithAssetsProp : getProductsWithAssets(groupId, productIds, assetFilters),
      [groupId, productIds, assetFilters, productWithAssetsProp],
    );

    const [productWithAssets, loading] = useRemote(fetchProductsWithAssets);
    const assetIdsToGroupItemIds = useMemo(() => {
      return groupAssets?.reduce((obj, item) => ({ ...obj, [item.asset_id]: item.id }), {});
    }, [groupAssets]);

    useLayoutEffect(() => {
      const initialChecked = map(productWithAssets, ({ product, assets }) => {
        const assetsGroup = getGroupItemsIds(assets, groupAssets);
        return [String(product.id), ...assetsGroup];
      });
      setChecked(initialChecked);
      checkedRef.current = initialChecked;
    }, [productWithAssets, groupAssets]);

    useEffect(() => {
      if (isCancelClicked) {
        checkedRef.current && setChecked(checkedRef.current);
        setIsCancelClicked(false);
        setUpdatedAssets(undefined);
      }
    }, [isCancelClicked, setIsCancelClicked]);

    useEffect(() => {
      const uniqAssets = uniqBy(checkedVideos, 'value');
      setAssetsCount?.(uniqAssets.length);
      setSyncSize?.(uniqAssets.reduce((acc, current) => acc + (current?.file_size || 0), 0));
    }, [setAssetsCount, setSyncSize, checkedVideos]);

    useEffect(() => {
      async function updateAssets(): Promise<void> {
        const assetsToAdd: string[] = [];
        const assetsToDelete: string[] = [];
        if (updatedAssets && Object.keys(updatedAssets).length) {
          const updatedAssetsEntries = Object.entries(updatedAssets);
          updatedAssetsEntries.forEach(([id, value]) => {
            if (value && assetIdsToGroupItemIds && !(id in assetIdsToGroupItemIds)) {
              assetsToAdd.push(id);
            }
            if (!value && assetIdsToGroupItemIds && id in assetIdsToGroupItemIds) assetsToDelete.push(id);
          });
        }

        await onAssetUpdate({ assetsToAdd, assetsToDelete }, assetIdsToGroupItemIds);
        setUpdatedAssets(undefined);
      }

      isSaveClicked && updateAssets();
    }, [assetIdsToGroupItemIds, isSaveClicked, onAssetUpdate, updatedAssets]);

    const [flatOptionsTree, classificationsCount, checkedClassifications] = useMemo(
      () =>
        getFlatOptionsTree({
          setCheckedVideos,
          productWithAssets,
          assetIdsToGroupItemIds,
          assetIds,
          propGroupId,
          disabled,
          checked,
          setCurrentFilters,
        }),
      [assetIds, assetIdsToGroupItemIds, checked, disabled, productWithAssets, propGroupId],
    );

    const optionsTree = useMemo<IOmniTreeNode[][]>(() => map(flatOptionsTree, createDataTree), [flatOptionsTree]);

    const handleCheck = useCallback(
      async (currentBranch: string[], node: IOnCheckNode) => {
        const { checked, value, isParent } = node;

        if (isParent) {
          const childrenIds = (node.children || []).map((child) => child.value);

          setChecked(([...tree]) => {
            flatOptionsTree.map((option, index) => {
              const result = option.filter((treeItem) => childrenIds.includes(treeItem.value)).map((e) => e.value);
              if (!result.length) return;
              setUpdatedAssets((prev) => {
                const updatedIds = result.reduce((obj, cur) => {
                  return { ...obj, [cur]: checked };
                }, {});
                return { ...prev, ...updatedIds };
              });
              if (checked) {
                tree[index] = [...tree[index], ...result];
              } else {
                tree[index] = tree[index].filter((id) => !result.includes(id));
              }
            });
            return [...tree];
          });
        } else {
          setChecked(([...tree]) => {
            if (checked) {
              flatOptionsTree.map((option, index) => {
                const result = option.find((treeItem) => treeItem.value === value);
                if (!result) return;
                tree[index] = [...tree[index], value];
              });
            } else {
              tree = tree.map((branch) => branch.filter((id) => id !== value));
            }

            return [...tree];
          });

          setUpdatedAssets((prev) => {
            return { ...prev, [value]: checked };
          });
        }
      },
      [flatOptionsTree],
    );

    const handleFilterChange = useCallback(
      async (value: Record<string, boolean>): Promise<void> => {
        const [filterName, isFilterChecked] = Object.entries(value)[0];
        const isSelectAll = filterName === 'select_all';

        checkUpdatedAssets({
          flatOptionsTree,
          filterName,
          isFilterChecked,
          isSelectAll,
          setChecked,
          setUpdatedAssets,
        });

        setCurrentFilters((currentFilters) => {
          const newFilters: Record<string, boolean> = { ...currentFilters, [filterName]: isFilterChecked };
          if (isSelectAll) {
            return {
              ...Object.keys(currentFilters).reduce((acc, key) => ({ ...acc, [key]: isFilterChecked }), {}),
              select_all: isFilterChecked,
            };
          }
          if (filterName.startsWith('select_')) {
            const mainCategory = filterName === 'select_videos' ? 'video' : 'document';
            return Object.keys(newFilters).reduce(
              (acc, key) => (key.startsWith(mainCategory) ? { ...acc, [key]: isFilterChecked } : acc),
              { ...newFilters, select_all: currentFilters.select_all && isFilterChecked },
            );
          }
          const { select_all, ...rest } = newFilters;
          const allFiltersChecked = Object.values(rest).every((filter) => filter);

          if (allFiltersChecked) return { ...newFilters, select_all: allFiltersChecked };
          if (!isFilterChecked && currentFilters.select_all) return { ...newFilters, select_all: false };

          return newFilters;
        });
      },
      [flatOptionsTree],
    );

    if (loading) {
      return <Loading text="Loading Assets" />;
    }

    if (!optionsTree.length && !loading) {
      return <EmptySectionMessage />;
    }

    const formatFilterLabel = (classification: string): string =>
      `${classification.split('_').pop()} ${checkedClassifications[classification] || 0} / ${
        classificationsCount[classification]
      }`;
    const formatCount = (maiClassification: string, classificationsCount: Record<string, number>): number =>
      Object.entries(classificationsCount).reduce(
        (acc, [key, cur]) => (key.startsWith(maiClassification) ? acc + cur : acc),
        0,
      );
    const allVideosCount = formatCount('video', classificationsCount);
    const allCheckedVideosCount = formatCount('video', checkedClassifications);
    const allDocumentsCount = formatCount('document', classificationsCount);
    const allCheckedDocumentsCount = formatCount('document', checkedClassifications);

    return (
      <div className="d-flex flex-column h-100">
        {!disabled ? (
          <>
            <div className="form-omni-checkbox__actions">
              {Object.keys(currentFilters || {}).reduce(
                (acc, classification) =>
                  classification.startsWith('video')
                    ? [
                        ...acc,
                        <FormCheckbox
                          groupClassName="m-0"
                          key={classification}
                          name={classification}
                          label={formatFilterLabel(classification)}
                          value={Boolean(currentFilters[classification])}
                          indeterminate={Boolean(
                            checkedClassifications[classification] &&
                              checkedClassifications[classification] !== classificationsCount[classification],
                          )}
                          onChange={handleFilterChange}
                        />,
                      ]
                    : acc,
                [
                  <FormCheckbox
                    name="select_videos"
                    key="all_videos"
                    label={`All Videos (${allCheckedVideosCount} / ${allVideosCount})`}
                    indeterminate={Boolean(allCheckedVideosCount && allVideosCount !== allCheckedVideosCount)}
                    value={Boolean(currentFilters?.select_videos)}
                    groupClassName="m-0"
                    onChange={handleFilterChange}
                  />,
                ],
              )}
            </div>
            <div className="form-omni-checkbox__actions pt-0">
              {Object.keys(currentFilters || {}).reduce(
                (acc, classification) =>
                  classification.startsWith('document')
                    ? [
                        ...acc,
                        <FormCheckbox
                          groupClassName="m-0"
                          key={classification}
                          name={classification}
                          label={formatFilterLabel(classification)}
                          value={Boolean(currentFilters[classification])}
                          indeterminate={Boolean(
                            checkedClassifications[classification] &&
                              checkedClassifications[classification] !== classificationsCount[classification],
                          )}
                          onChange={handleFilterChange}
                        />,
                      ]
                    : acc,
                [
                  <FormCheckbox
                    name="select_documents"
                    key="all_documents"
                    label={`All Documents (${allCheckedDocumentsCount} / ${allDocumentsCount})`}
                    indeterminate={Boolean(allCheckedDocumentsCount && allDocumentsCount !== allCheckedDocumentsCount)}
                    value={Boolean(currentFilters?.select_documents)}
                    groupClassName="m-0"
                    onChange={handleFilterChange}
                  />,
                ],
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <ScrollWrapper>
          <TreeWrapper onCheck={handleCheck} optionsTree={optionsTree} checked={checked} disabled={disabled} />
        </ScrollWrapper>
      </div>
    );
  },
);

export default GroupAssetsTreeTab;
