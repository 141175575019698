import React, { useCallback } from 'react';

import { IContact } from 'types';
import { AccessTab } from 'components/access-tab';
import { getContactGroupIds } from 'utils/apis/contacts';

export const ContactAccessTab: React.FC<{ contact: IContact }> = ({ contact }) => {
  const fetchContactGroupIds = useCallback(() => getContactGroupIds(contact.id), [contact.id]);

  return <AccessTab getGroupIds={fetchContactGroupIds} />;
};
