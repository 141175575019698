import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { Plus } from 'blueprint5-icons';

import { useDataSectionStore, useStore } from 'store';

import { ToastError } from 'components/toast';
import { MantineIcon } from 'utils/ui';

import { ingestPreviewImage } from 'utils/apis/preview-image';
import { useGenreForm } from './use-genre-form';
import { createGenre } from 'utils/apis/genre';
import { delay } from 'utils/delay';

import { IGenreFormData } from './genre-form';
import { ICategory } from 'types';

export const CreateGenreButton = observer((): JSX.Element => {
  const {
    toastStore,
    dialogStore: { close },
  } = useStore();
  const { searchStore } = useDataSectionStore<ICategory>();
  const handleSubmit = useCallback(
    async ({ previewImageUrl, ...values }: IGenreFormData): Promise<ICategory | void> => {
      try {
        let previewImage;
        if (previewImageUrl) {
          previewImage = await ingestPreviewImage(previewImageUrl);
        }

        const result = await createGenre({ ...values, preview_image_id: previewImage?.id });
        await delay();
        close();
        searchStore?.update({
          objects: [...(searchStore?.objects || []), result],
        });
        toastStore.clearAll();
        toastStore.success('Genre created successfully!');
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, searchStore, toastStore],
  );

  const onClick = useGenreForm({ title: 'Create Genre', handleSubmit });

  return (
    <Button size="xs" leftSection={<MantineIcon icon={<Plus />} />} variant="primary" onClick={onClick}>
      Create Genre
    </Button>
  );
});
