import { IFetchThumbnailImages } from 'types';
import { loadListProducts } from 'utils/apis/lists';

export const fetchThumbnailImages = async (id): IFetchThumbnailImages => {
  if (!id) {
    return [];
  }
  const productThumbnails = await loadListProducts({
    schema: 'id, inherited_preview_image, type',
    basket_id: id,
    per: 4,
  });
  if (!productThumbnails?.length) {
    return [{}];
  }
  return productThumbnails.map((product) => ({
    image: product.inherited_preview_image?.url,
    assetType: product.type,
  }));
};
