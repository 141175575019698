import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IProduct, ItemId } from 'types';

import { RecommendProductsWizard } from 'components/recommendation/recommend-products';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IUseActionsOptionParam } from 'utils/actions';
import { IRecommendationActionName } from 'utils/actions/recommendation';
import { IModalSize } from 'components/dialogs/types';
import { ICreateWorkFlowPayload } from 'utils/actions/types';

interface IUseRecommendProductsDialogProps {
  products?: IProduct[];
  groupId?: ItemId;
  recommendation?: Partial<ICreateWorkFlowPayload<McGenericRecommendationProduct | McGenericRecommendationAsset>>;
  tab?: number;
  options?: IUseActionsOptionParam<McGenericRecommendationProduct | McGenericRecommendationAsset>;
}

export const useRecommendProductsDialog = ({
  products,
  recommendation,
  groupId,
  tab,
  options,
}: IUseRecommendProductsDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <RecommendProductsWizard
        products={products}
        onFinish={(newValue) => {
          close();
          options?.onSuccess?.(IRecommendationActionName.EDIT, newValue);
        }}
        groupId={groupId}
        recommendation={recommendation}
        tab={tab}
      />
    );
  }, [products, groupId, recommendation, tab, close, options]);

  const openDialog = (): void => {
    openModal({
      title: 'Recommend Products',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
