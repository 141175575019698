import React from 'react';
import cx from 'classnames';

import { ITabValue, Tab, TabPanel } from 'components/tabs';

import { DetailsPageTabs } from 'types';

export const AnalyticsDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;

  valueParser?: (value?: ITabValue) => string;
  hidden?: boolean;
}> = ({ value, activeTab, valueParser, hidden = false }) => {
  if (hidden) return <></>;

  return (
    <Tab
      value={value}
      className={cx(`analytics-details__tab`, {
        'analytics-details__tab--active': activeTab === value,
      })}
      valueParser={valueParser}
    />
  );
};

export const AnalyticsTabPanel: React.FC<{ value: Partial<DetailsPageTabs>; content: JSX.Element }> = ({
  value,
  content,
}) => {
  return <TabPanel value={value} className="analytics-details__tab-content h-100" content={content} />;
};
