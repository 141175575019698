import { IColumn } from 'components/list-table';
import { IAnalyticsInteractionOption } from 'components/analytics';
import {
  DetailsPageTabs,
  IAnalyticsPageValues,
  IAsset,
  IContact,
  IPreviewImage,
  IProduct,
  ItemId,
  IUserAgent,
  UserStatus,
} from 'types';

export interface IAnalyticsDateParams {
  from_date: Date | string;
  to_date: Date | string;
}

export interface IGenre {
  name: string;
}

export interface IDivision {
  name: string;
}

export interface ILatestActivitiesData {
  classification: string;
  title: string;
  genre: IGenre[];
  duration: number | string;
  thumbnail_image: string;
  last_view: string;
  count: number;
}

export type IBarChartData = {
  [key: string]: Date | string | number;
};

export type IHistogramData = {
  date?: string;
  count: number;
  device?: string; // TODO: consolidate IHistogramData
  platform?: string;
};

export type IWordCountData = {
  search_terms: string;
  term_count: number;
};

export interface IRecommendationHistogramData extends IHistogramData {
  sent_count?: number;
  viewed_count?: number;
  success_percentage?: number;
}

export type IGenericObject = {
  [key: string]: Date | [Date, Date] | string | number | boolean | Array<string>;
};

export interface IAnalyticsFilters {
  dateRange: [Date, Date];
  geoScopeIds?: Array<string>;
  userIds?: Array<string>;
  productIds?: Array<string>;
  assetIds?: Array<string>;
  senderIds?: string[];
  recipientIds?: string[];
  includeDescendants?: boolean;
  includeNonScreeningsAssets?: boolean;
}

export interface IAnalyticsProps extends IAnalyticsFilters {
  entity?: string;
  entityId?: ItemId | ItemId[] | null;
  queryName?: string;
  queries?: { [key: string]: string };
  per?: number;
  page?: number;
  limit?: number;
}

export interface IAnalyticsSearchProps extends IAnalyticsProps {
  title: string;
  unit: string;
  exportAction?: boolean;
}

export interface IAnalyticsListProps extends IAnalyticsProps {
  title: string;
  columns?: IColumn[];
}

export interface IAnalyticsStateProps extends IAnalyticsProps {
  interaction?: string;
}

export type IFetchTopUsersEntity = 'user' | 'recipient' | 'sender';
export interface IHistogramQueryParams {
  [x: string]: string | number | boolean | Date | (string | number)[] | undefined;
  query_name: string | undefined;
  from_date: Date | string;
  to_date: Date | string;
  geo_scope_ids: string[] | undefined;
  include_descendants: boolean | undefined;
  limit: number | undefined;
}

export interface IGetAnalyticsTableDataParams extends IAnalyticsDateParams {
  page: number;
  per: number;
  entityId?: ItemId | ItemId[] | null;
  entity?: string;
  query_name?: string;
  geo_scope_ids?: Array<string>;
  user_ids?: Array<string> | string;
  product_ids?: Array<string>;
  recipientIds?: string[];
  senderIds?: string[];
  asset_ids?: Array<string>;
  include_descendants?: boolean;
  only_screenings?: boolean;
}

export interface IExportAnalyticsTableDataParams extends IAnalyticsDateParams {
  entityId?: ItemId | ItemId[] | null;
  entity?: string;
  query_name?: string;
  geo_scope_ids?: Array<string>;
  recipientIds?: string[];
  senderIds?: string[];
  user_ids?: Array<string> | string;
  product_ids?: Array<string> | string;
  asset_ids?: Array<string> | string;
  include_descendants?: boolean;
  only_screenings?: boolean;
}

export interface IAnalyticsTableItem {
  user: IContact;
  organization: {
    id: string;
    name: string;
  };
  country?: string;
  asset: IAsset & { type: string };
  last_occured_at: string;
  last_effective_at: string;
  total_duration: number;
  total_playback_duration: number;
  asset_products: string;
  user_agent: IUserAgent;
  geoip_country?: string;
  responsible_user: {
    id: string;
    uuid: string;
    email: string;
    full_name: string;
  };
  group?: {
    id: string;
    type: string;
    name: string;
  };
  product_ids_titles?: Array<string>;
}

export interface IAnalyticsTableData<T> {
  data: T[];
  page: number;
  per: number;
  total_count: number;
  total_pages: number;
}

export type IUseItemData = IUseContactData | IUseProductData | IUseAssetData | IUseFileData;

type IStatsItemValue = [number, number, number];
export interface IUseContactItems {
  active: IStatsItemValue;
  inactive: IStatsItemValue;
  invited: IStatsItemValue;
  pending: IStatsItemValue;
  review: IStatsItemValue;
}

export interface IUseContactData {
  items: IUseContactItems;
}

export interface IUseProductItems {
  episode: IStatsItemValue;
  format: IStatsItemValue;
  programs: IStatsItemValue;
  season: IStatsItemValue;
  series: IStatsItemValue;
}

export interface IUseProductData {
  items: IUseProductItems;
}

export interface IUseAssetItems {
  audios: IStatsItemValue;
  documents: IStatsItemValue;
  images: IStatsItemValue;
  other: IStatsItemValue;
  videos: IStatsItemValue;
}

export interface IUseAssetData {
  items: IUseAssetItems;
}

export interface IUseFileItems {
  ready: IStatsItemValue;
  pending: IStatsItemValue;
  ingesting: IStatsItemValue;
  reingesting: IStatsItemValue;
  invalid: IStatsItemValue;
}

export interface IUseFileData {
  items: IUseFileItems;
}

export interface IUseActivityData {
  count: number;
  date?: string;
}

export type IUseTopItemData = IUseTopVideoData | IUseTopVideoViewerData | IUseTopProductData;
export interface IUseTopItemDataGeneric<T> {
  data: Array<T>;
  pagination?: {
    total_count: number;
  };
}

export type IUseTopVideoData = IUseTopItemDataGeneric<{
  id: number;
  title: string;
  count: number;
  lastViewed?: string;
  thumbnail: string | undefined;
  url: string;
  products?: { id: number; title: string }[];
}>;

export type IUseTopVideoViewerData = IUseTopItemDataGeneric<{
  id: number;
  title: string;
  count: string | number;
  lastViewed?: string;
  contact?: {
    first_name: string;
    last_name: string;
    preview_image?: IPreviewImage;
    status?: UserStatus;
    function?: string;
  };
  url: string;
}>;

export type IUseTopProductData = IUseTopItemDataGeneric<{
  id: number;
  title: string;
  count: number;
  lastViewed?: string;
  thumbnail: string | undefined;
  url: string;
}>;

export type ITopProductsData = IUseTopItemDataGeneric<{
  count: number;
  last_view: string;
  product?: IProduct;
}>;

export type ITopVideoViewersData = IUseTopItemDataGeneric<{
  duration: number;
  total_duration: number;
  last_occured_at: string;
  user: IContact;
}>;

export type ITopVideosData = IUseTopItemDataGeneric<{
  asset?: IAsset;
  count: number;
  last_occured_at: string;
  products?: (string | number)[][];
}>;

export interface ITopViewersProps {
  entity?: string;
  entityId?: ItemId | null;
  queryName?: string;
  dateRange: [Date, Date];
  interaction?: 'products' | 'screenings';
  geo_scope_ids?: Array<string>;
  user_ids?: Array<string>;
  product_ids?: Array<string>;
  include_descendants?: boolean;
  limit?: number;
  page?: number;
  per?: number;
}

export type ITopUsers = IEventTopViewersHistogramData | IRecommendationTopRecipientsHistogramData;
export type IEventTopViewersHistogramData = IUseTopItemDataGeneric<{
  user: {
    id: string;
    uuid: string;
    email: string;
    full_name: string;
    preview_image_id: string | null;
  };
  count: number;
  total_duration: number;
  last_occured_at: string;
  last_view?: string;
}>;

export type IRecommendationTopRecipientsHistogramData = IUseTopItemDataGeneric<{
  recipient: {
    id: string;
    uuid: string;
    email: string;
    full_name: string;
    preview_image_id: string | null;
    organization_name: string;
  };
  count: number;
  total_duration: number;
  last_occured_at: string;
  last_view?: string;
}>;

export type IEventTopScreeningsHistogramData = IUseTopItemDataGeneric<{
  asset: {
    id: string;
    type: string;
    name: string;
    file_name: string;
    classification: string;
    duration: number | null | undefined;
  };
  count: number;
  last_occured_at?: string;
  last_download?: string;
  last_effective_at?: string;
  total_playback_duration?: number;
  products?: (string | number)[][];
}>;

export type IEventTopProductsHistogramData = IUseTopItemDataGeneric<{
  product: {
    id: string;
    type: string;
    title: string;
    extended_title: string;
  };
  count: number;
  last_occured_at?: string;
  last_view?: string;
}>;

export interface IGetHistogramAnalyticsData extends IAnalyticsDateParams {
  entity?: string;
  query_name?: string;
  ids?: ItemId | ItemId[] | null;
  limit?: number;
  page?: number;
  per?: number;
  geo_scope_ids?: Array<string>;
  user_ids?: Array<string>;
  product_ids?: Array<string>;
  include_descendants?: boolean;
}

export interface IUseAnalyticsResponse {
  dateRange: [Date, Date];
  data: { histogram: IBarChartData[]; latestActivities: ILatestActivitiesData[] };
  minDate: Date;
  onProductsSelect: ({ productIds }: { productIds: Array<string> }) => void;
  onAssetsSelect: ({ assetIds }: { assetIds: Array<string> }) => void;
  onDescendantsChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onContactsSelect: ({ contactIds }: { contactIds: Array<string> }) => void;
  onRecipientsSelect: ({ recipientIds }: { recipientIds: string[] }) => void;
  onSendersSelect: ({ senderIds }: { senderIds: string[] }) => void;
  onTerritoriesSelect: ({ territories }: { territories: Array<string> }) => void;
  onDateRangeChange: ({ dateRange }: { dateRange: [Date, Date] }) => void;
  onNonScreeningsAssetsChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  recipientIds?: string[];
  senderIds?: string[];
  territories?: string[];
  contactIds?: string[];
  productIds?: string[];
  assetIds?: string[];
  includeDescendants?: boolean;
  includeNonScreeningsAssets?: boolean;
  resetFilters: () => void;
  loading: boolean;
}

export type IAnalyticsEntity = 'contact' | 'product' | 'asset' | 'event';
export enum IAnalyticsEntityValues {
  CONTACT = 'user',
  PRODUCT = 'product',
  ASSET = 'asset',
  EVENT = 'event',
  RECOMMENDATION = 'recommendation',
}
export interface IUseAnalyticsParams {
  getHistogram?: (params: IAnalyticsDateParams) => Promise<IBarChartData[]>;
  getLatestActivities?: (params: IAnalyticsDateParams) => Promise<ILatestActivitiesData[]>;
  getMinDate: () => Promise<Date | undefined>;
  initialDateRange?: [Date, Date];
  state: IAnalyticsStateProps;
  tab: DetailsPageTabs;
  section?: IAnalyticsPageValues;
  interaction?: IAnalyticsInteractionOption;
  entity?: IAnalyticsEntityValues;
}

export interface IAnalyticsProps {
  entity?: string;
  entityId?: ItemId | ItemId[] | null;
  dateRange: [Date, Date];
  queryName?: string;
}

export interface IAnalyticsTopProps extends IAnalyticsProps {
  productIds?: Array<string>;
  contactIds?: Array<string>;
  territories?: Array<string>;
  includeDescendants?: boolean;
}

export interface IAnalyticsProductViews extends Omit<IAnalyticsProps, 'queryName'> {
  queries: Record<string, string>;
  product_ids?: Array<string>;
  geo_scope_ids?: Array<string>;
  user_ids?: Array<string>;
  include_descendants?: boolean;
}

export interface IAnalyticsEventProps extends IAnalyticsProps {
  territories?: Array<string>;
  contactIds?: Array<string>;
  user_ids?: Array<string>;
  productIds?: Array<string>;
  includeDescendants?: boolean;
  limit?: number;
  page?: number;
  per?: number;
}

export interface IAnalyticsScreeningsProps extends Omit<IAnalyticsProps, 'queryName'> {
  queries: Record<string, string>;
  territories?: Array<string>;
  contactIds?: Array<string>;
  productIds?: Array<string>;
  includeDescendants?: boolean;
}

export interface IAnalyticsActivityProps {
  queryName?: string;
  dateRange?: [Date, Date];
  userIds?: Array<string>;
  geoScopeIds?: Array<string>;
  recipientIds?: string[];
  senderIds?: string[];
}

export interface IDataset {
  data: number[];
  label: string;
  backgroundColor: string;
  borderColor: string;
}

export type IUseTopSearchTermsData = IUseTopItemDataGeneric<{
  product: {
    id: number;
    type: string;
    title: string;
    extended_title: string;
  };
  hit_count: number;
  product_id: number;
}>;
