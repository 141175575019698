import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useAssetsUploadToSelectionStore } from 'store/hooks';
import { AssetsFormHeader } from 'components/upload-asset-to-selection-dialog/components/upload-assets-form-header';
import { AssetsFormFooter } from 'components/upload-asset-to-selection-dialog/components/upload-assets-form-footer';
import { AssetsUploadDashboard } from 'components/asset/asset-upload-components/upload-dashboard/upload-dashboard';
import { AssetForm } from 'components/asset/asset-upload-components/single-asset-form/single-asset-form';
import SaveAssetPresetForm from 'components/asset/asset-upload-components/save-preset-modal/save-preset-modal';
import { SelectAssetPreset } from 'components/asset/asset-upload-components/select-preset-modal/select-preset-modal';
import { UPLOAD_ASSETS_TO_SELECTION_DROP_AREA_ID } from 'components/upload-asset-to-selection-dialog/constants';
import { IAssetFile, IAssetPresetFields, IUploadedFile } from 'types';
import { useStore } from 'store';
import { getStorageItem, STORAGE_KEYS } from 'utils/storage';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '../index.scss';

interface IUploadAssetsFormCallback {
  isValid: boolean;
  files: IAssetFile[];
  filesRemote: IUploadedFile[];
  cleanUp: () => void;
  showErrors: () => void;
}

export interface IUploadAssetsForm {
  onSubmit: (props: IUploadAssetsFormCallback) => Promise<void>;
}

export const UploadAssetsForm: React.FC<IUploadAssetsForm> = observer(({ onSubmit }) => {
  const {
    files,
    initializeUppy,
    isValid,
    filesRemote,
    cleanUp,
    showErrors,
    uppy,
    removeFile,
    savePreset,
    progress,
    applyPreset,
    setIsValidForm,
    onDataChange,
    shouldShowErrors,
  } = useAssetsUploadToSelectionStore();
  const { dialogStore } = useStore();
  const [presets, setPresets] = useState<Record<string, IAssetPresetFields>>(
    getStorageItem(STORAGE_KEYS.ASSET_UPLOAD_PRESETS) as Record<string, IAssetPresetFields>,
  );

  const openSavePresetModal = useCallback(
    (preset: IAssetPresetFields) => {
      savePreset(preset);
      dialogStore.openModal({
        title: `Save as preset`,
        body: () => <SaveAssetPresetForm preset={preset} setPresets={setPresets} />,
      });
    },
    [dialogStore, savePreset],
  );

  const openPresetSelector = useCallback(() => {
    dialogStore.openModal({
      title: 'Select Preset',
      body: () => <SelectAssetPreset {...{ presets, shouldShowErrors, applyPreset }} />,
      className: 'asset-upload-components__modal-wrapper',
    });
  }, [applyPreset, dialogStore, presets, shouldShowErrors]);

  const handleFormSubmit = useCallback(() => {
    onSubmit({ files, isValid, filesRemote, cleanUp, showErrors });
  }, [cleanUp, files, filesRemote, isValid, onSubmit, showErrors]);

  const handleCancel = useCallback(() => {
    cleanUp();
    dialogStore.close();
  }, [cleanUp, dialogStore]);

  useEffect(() => {
    return initializeUppy();
  }, [initializeUppy]);

  return (
    <div className="form-upload-asset-to-selection__wrapper position-relative">
      <div className="upload-asset-to-selection-dialog__dashboard d-flex flex-column">
        <AssetsUploadDashboard
          {...{ progress, removeFile, files, uppy }}
          dropAreaId={UPLOAD_ASSETS_TO_SELECTION_DROP_AREA_ID}
        />
      </div>
      <div className="form-upload-asset-to-selection__form-wrapper d-flex flex-column">
        <AssetsFormHeader openPresetModal={openPresetSelector} />
        <div className="form-upload-asset-to-selection__form">
          {files.map((file, index) => (
            <AssetForm
              key={file.id}
              initialValues={file}
              {...{
                index,
                savePreset: openSavePresetModal,
                applyPreset,
                setIsValidForm,
                removeFile,
                onDataChange,
                shouldShowErrors,
              }}
            />
          ))}
        </div>
        <AssetsFormFooter onSubmit={handleFormSubmit} onCancel={handleCancel} />
      </div>
    </div>
  );
});
