import { useMarkResolveAction } from './mark-resolved';
import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IWorkflow } from 'types';
import { useRestartAction } from './restart';
import { IWorkflowActionName } from './types';

export const useWorkflowActions = (
  entities: IWorkflow[] = [],
  options: IUseActionsOptionParam<IWorkflow>,
): IUseActionsResponse<IWorkflowActionName> => {
  const restartAction = useRestartAction(entities, options);
  const markResolved = useMarkResolveAction(entities, options);
  const actions = [restartAction, markResolved];

  return useActions(actions, options, entities);
};
