import React, { useCallback, useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Checkbox, Loader } from '@mantine/core';

import { EqFilter, IAggregation, InFilter, isEqFilter, NotInFilter } from 'helpers/filters/types';
import { IDataProvider, IDataProviderItem } from 'helpers/data-provider/option-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';
import { formatType } from 'utils/general';

import './style.scss';

type ICheckboxGroupFilterDefinition = InFilter | NotInFilter | EqFilter;

interface IFilterCheckboxProps {
  name: string;
  label: React.ReactNode;
  className?: string;
  optionsProvider: IDataProvider;
  large?: boolean;
  disabled?: boolean;
  inline?: boolean;
  simplifyCheckboxLabel?: boolean;

  aggregations?: IAggregation[];
  filter: ICheckboxGroupFilterDefinition;
  onChange?: (newValue: { [key: string]: ICheckboxGroupFilterDefinition }) => void;
}

const FilterCheckbox: React.FC<IFilterCheckboxProps> = observer((props) => {
  const {
    name,
    label,
    filter,
    aggregations,
    onChange,
    disabled,
    className,
    optionsProvider,
    inline,
    simplifyCheckboxLabel,
  } = props;
  const [localAggregations, setLocalAggregations] = useState<{ count: number; value: unknown }[]>([]);
  const { value } = filter || {};

  useEffect(() => {
    if (typeof aggregations !== 'undefined') {
      setLocalAggregations([...aggregations]);
    }
  }, [aggregations]);

  useEffect(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  const valueNormalized = useMemo(() => (Array.isArray(value) ? value : [value]), [value]);
  const options = optionsProvider.data;

  const handleOtherFilters = useCallback(
    (option: IDataProviderItem): void => {
      const { value } = option;
      const index = valueNormalized.indexOf(value);
      let newValue;

      if (!valueNormalized.includes(value)) {
        newValue = [...valueNormalized, value];
      } else {
        newValue = valueNormalized.filter((value) => value !== valueNormalized[index]);
      }

      onChange && onChange({ [name]: { ...filter, value: newValue } });
    },
    [filter, name, onChange, valueNormalized],
  );

  const handleEqFilter = (option, e): void => {
    const { value } = option;
    const isSelected = e.currentTarget.checked;
    onChange?.({ [name]: { ...filter, value: isSelected ? value : '' } });
  };

  const handleClick = (option: IDataProviderItem, e): void => {
    if (isEqFilter(filter)) {
      handleEqFilter(option, e);
      return;
    }
    handleOtherFilters(option);
  };

  const getFormattedLabel = (label: string): string => {
    let formattedLabel = label || '';

    if (simplifyCheckboxLabel) {
      formattedLabel = label.split('/').pop() || '';
    }

    if (formattedLabel.includes('::')) return formatType(formattedLabel);

    return formattedLabel.replace(/_/g, ' ');
  };

  if (optionsProvider.loading) {
    return <Loader size={25} />;
  }

  return (
    <FormGroup
      label={label}
      labelFor={name}
      contentClassName={cx(`filter-checkbox ${className}`, { 'filter-checkbox--two-columns': !inline })}
    >
      {options.map((option) => {
        const isSelected = valueNormalized.includes(option.value);
        const aggregation = localAggregations?.find((a) => a?.value === option.value);
        const label = `${getFormattedLabel(option.label)}${aggregation ? ` (${aggregation.count})` : ''}`;
        const isDisabled = disabled || (!aggregation?.count && !isSelected);

        return (
          <Checkbox
            key={option.value}
            label={label}
            checked={isSelected}
            disabled={isDisabled}
            mb={10}
            onChange={(e) => handleClick(option, e)}
          />
        );
      })}
    </FormGroup>
  );
});

export default FilterCheckbox;
