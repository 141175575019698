import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { useParams } from 'react-router-dom';
import { DataSectionPage } from 'components/layout';

import { useRefreshDataSection, useRemote } from 'utils/hooks';

import { getCastCrew } from 'utils/apis/cast-crew';
import './style.scss';
import CastCrewDetailsLoadedPage from './cast-crew-details-loaded';
import { Loading } from 'components/loading';
import { EmptySectionMessage } from 'components/section-message';

const CastCrewDetailsPage: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const refreshDataSection = useRefreshDataSection();

  const loadCastCrew = useCallback((): Promise<PmGenericCastCrew> => getCastCrew(id), [id]);
  const [castCrew, isLoading, setCastCrew, refreshCastCrew] = useRemote(loadCastCrew);

  const refresh = useCallback(() => {
    refreshCastCrew();
    refreshDataSection();
  }, [refreshDataSection, refreshCastCrew]);

  return (
    <DataSectionPage refresh={refresh}>
      {isLoading && <Loading text="Loading..." />}
      {!isLoading && castCrew?.id && <CastCrewDetailsLoadedPage castCrew={castCrew} setCastCrew={setCastCrew} />}
      {!isLoading && !castCrew?.id && <EmptySectionMessage />}
    </DataSectionPage>
  );
});

export default CastCrewDetailsPage;
