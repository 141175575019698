import React from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { IconAlias } from 'icons/icon-aliases';
import { isProcessingPreview } from 'utils/general';

import './style.scss';

interface ICollectionSelectedItem {
  collection: UmGenericListCollection;
}

export const CollectionSelectedItem: React.FC<ICollectionSelectedItem> = ({ collection }) => {
  const { name, preview_image, purpose } = collection || {};

  return (
    <li className="collection-selected-item text-truncate">
      <Thumbnail
        className="selected-tab-item__thumb"
        image={preview_image?.url}
        fallbackIconName={IconAlias.COLLECTION}
        placeholder={isProcessingPreview(collection) && <SmallLoader />}
      />
      <div className="selected-tab-item__content text-truncate">
        <div className="text-truncate">{name}</div>
        <div className="text-truncate">{purpose}</div>
      </div>
    </li>
  );
};

export default CollectionSelectedItem;
