import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';

import { useStore } from 'store';

import { EmptySectionMessage } from 'components/section-message/section-message';
import { ScreeningRoomActionsList } from 'components/action/screening-room';
import { BodyWrapper } from 'components/layout';
import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { ScreeningRoomDetailsTabsView } from './screening-room-tabs-view';
import ScreeningRoomDetailsOverview from './screening-room-details-overview';

import { IContactActionName, Pages } from 'utils/actions';
import { Routes } from 'utils/routes';
import { IGroupActionName } from 'utils/actions/common';
import { IScreeningRoomEditForm, getHeaderActions } from './utils';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IGroup, DetailsPageTabs } from 'types';
import { IAssetActionName } from 'utils/actions/asset';
import { ToastError } from 'components/toast';
import { useEditMode, useRefreshDataSection, useRemote, useTab } from 'utils/hooks';
import { loadGroup, IGroupNameEditFormFields, editGroupInfo } from 'utils/apis/groups';
import { groupListSchema } from 'utils/schemas';
import { ActionsMenu } from 'components/action/utils';

const tabOptions = [DetailsPageTabs.ASSETS, DetailsPageTabs.CONTACTS];
const DEFAULT_TAB = DetailsPageTabs.ASSETS;

export const ScreeningRoomDetails: React.FC = observer(() => {
  const { screeningRoomId = '' } = useParams<{ screeningRoomId: string }>();
  const { toastStore } = useStore();

  const [editModeEnabled, setEditModeEnabled] = useEditMode();

  const [currentTab, setCurrentTab] = useTab(DEFAULT_TAB, tabOptions);
  const navigate = useNavigate();

  const fetchScreeningRoom = useCallback(async () => {
    if (!screeningRoomId) {
      return;
    }
    try {
      return loadGroup(screeningRoomId);
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }
  }, [screeningRoomId, toastStore]);

  const [screeningRoom, loading, setScreeningRoom, refreshScreeningRoom] = useRemote<IGroup>(fetchScreeningRoom);

  const { name, description } = screeningRoom || {};
  const refreshDataSection = useRefreshDataSection();

  const onActionSuccess = useCallback(
    async (action?: string, updatedScreeningRoom?: IGroup): Promise<void> => {
      switch (action) {
        case IGroupActionName.DELETE:
          navigate(`${Routes.SCREENING_ROOMS}`);
          break;
        case IGroupActionName.ADD_ENTITIES:
        case IAssetActionName.REMOVE_FROM_GROUP:
        case IContactActionName.REMOVE_FROM_GROUP:
          setTimeout(refreshScreeningRoom, 1000);
          break;
        case IGroupActionName.UPLOAD_PREVIEW:
          updatedScreeningRoom &&
            setScreeningRoom((prev) => ({
              ...prev,
              preview_image: updatedScreeningRoom?.preview_image,
              preview_image_id: updatedScreeningRoom?.preview_image_id,
            }));
        default:
          refreshDataSection();
          return;
      }
    },
    [navigate, refreshScreeningRoom, setScreeningRoom, refreshDataSection],
  );

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  const handleSubmit = useCallback(
    async (values: IGroupNameEditFormFields & { description: string }): Promise<void> => {
      try {
        if (screeningRoomId) {
          toastStore.info('Saving');
          const updatedGroup = await editGroupInfo(screeningRoomId, values, groupListSchema);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          setEditModeEnabled(false);
          setScreeningRoom((sr) => ({ ...sr, ...updatedGroup.object }));
        }
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(`Error while saving: ${get(e, 'object.description', 'Unknown error')}`);
      }
    },
    [screeningRoomId, setScreeningRoom, setEditModeEnabled, toastStore],
  );

  const initialValues = { name, description };
  const editForm = useForm<IScreeningRoomEditForm>(initialValues, Model.GROUPS, handleSubmit);
  const headerActions = getHeaderActions(editModeEnabled, setEditModeEnabled, editForm);

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Screening Rooms details" />
      </BodyWrapper>
    );
  }

  if (!screeningRoom && !loading) {
    return <EmptySectionMessage />;
  }
  return (
    <BodyWrapper>
      <SectionHeader
        title={name}
        useBackground
        dropdownMenu={
          <ActionsMenu
            component={ScreeningRoomActionsList}
            items={[screeningRoom]}
            options={{ context: 'single', page: Pages.DETAILS, onSuccess: onActionSuccess }}
          />
        }
        actions={headerActions}
      />

      <div className="marketing-entity-details__content-wrapper">
        <ScreeningRoomDetailsOverview screeningRoom={screeningRoom} form={editForm} editModeEnabled={editModeEnabled} />

        <div className="marketing-entity-details__tabs-wrapper">
          <ScreeningRoomDetailsTabsView
            editModeEnabled={editModeEnabled}
            screeningRoom={screeningRoom}
            currentTab={currentTab}
            onTabChangeHandle={handleTabChange}
            onSuccess={onActionSuccess}
          />
        </div>
      </div>
    </BodyWrapper>
  );
});
