import { keysAbout } from 'pages/registration/step-about/step-about';
import { keysPreview } from 'pages/registration/step-preview/step-preview';
import { IAboutFields } from './step-about';
import {
  IOrganizationRegistrationFields,
  IAddressFields,
  keysOrganization,
} from 'pages/registration/step-organization';
import { IPreviewFields } from './step-preview';

export interface IRegistrationHiddenFields {
  organization_legal_address_label: string;
  user_address_label: string;
  user_phone_0_label?: string;
  organization_phone_0_label?: string;
  user_address_street?: string;
  user_address_zip_code?: string;
  user_address_city?: string;
  user_address_country_id?: string;
}

export const defaults: IRegistrationHiddenFields = {
  organization_legal_address_label: 'Legal',
  user_address_label: 'Legal',
};

export interface IRegistrationData
  extends IAboutFields,
    IOrganizationRegistrationFields,
    IAddressFields,
    IPreviewFields,
    IRegistrationHiddenFields {}

export const enum StepIds {
  Step1,
  Step2,
  Step3,
  Step4,
}

export const steps = [
  { id: StepIds.Step1, keys: keysAbout },
  { id: StepIds.Step2, keys: keysOrganization },
  { id: StepIds.Step3, keys: keysPreview },
  { id: StepIds.Step4, keys: null },
];

export const initialValues: Partial<IRegistrationData> = {
  user_first_name: '',
  user_last_name: '',
  user_gender: '',
  user_date_of_birth: '',
  user_email: '',
  user_phone_0_number: '',
  user_country_id: '',
  organization_name: '',
  organization_legal_entity: '',
  organization_classification: '',
  organization_email: '',
  organization_phone_0_number: '',
  user_terms_accepted: false,
};

export const customContext = {
  properties: {
    user_country_id: { required: true },
    organization_legal_address_country_id: { required: true },
    organization_legal_address_street: { required: true },
    organization_legal_address_zip_code: { required: true },
    organization_legal_address_city: { required: true },
  },
};
