import React from 'react';
import { observer } from 'mobx-react-lite';

import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { PreviewInfo, PreviewTab } from 'components/preview-tab';
import { Row } from 'components/label-value-pair';
import { GroupAssetTypeAggregations } from 'components/asset';
import { ScreeningRoomLink } from 'components/screening-room-link/screening-room-link';

import { useDataSectionStore } from 'store/hooks';
import { IconAlias } from 'icons';
import { Routes } from 'utils/routes';
import { formatDate } from 'utils/date';
import { DetailsPageTabs, IGroup } from 'types';
import { formatOwnership } from 'utils/general';
import { DetailCountLink } from 'utils/ui';

export const ScreeningRoomPreviewTab: React.FC = observer(() => {
  const { activeItem } = useDataSectionStore<IGroup>();
  const { created_at, updated_at, id, deleted_at, preview_image, name, users_count, preview_image_id } =
    activeItem || {};
  const detailsPageLink = `${Routes.SCREENING_ROOMS}/${id}`;

  if (!id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }
  return (
    <PreviewTab
      title={name}
      detailsLink={deleted_at ? '' : detailsPageLink}
      customThumbnail={
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.SCREENING_ROOM}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
        />
      }
    >
      <PreviewInfo>
        <Row label="Owner" value={formatOwnership(activeItem)} />
        <Row
          label="Assets"
          value={<GroupAssetTypeAggregations groupId={id} baseUrl={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`} />}
        />
        <DetailCountLink
          entityCount={users_count}
          link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
          label={DetailsPageTabs.CONTACTS}
        />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row
          label="URL"
          rawValue
          value={<ScreeningRoomLink screeningRoom={activeItem} className="d-block" />}
          className="text-wrap text-break"
          size="m"
        />
      </PreviewInfo>
    </PreviewTab>
  );
});
