import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import BasketDetailsLayout from 'components/baskets/basket-details/basket-details-layout/basket-details-layout';
import { BasketActionsList } from 'components/action';
import useBasket from 'utils/hooks/basket';
import mapList from 'pages/contact-details/contact-baskets-tab/map-list';
import { Pages } from 'utils/actions/types';
import { IBasketStandardized } from 'types';

import ProductsDataSection from 'components/product-data-section/product-data-section';
import { BasketsProductsSortDataSection } from 'components/group-items-sort-data-section';
import { getSortMenu } from './sort-menu';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { ActionsMenu } from 'components/action/utils';

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const BasketDetails: React.FC<{ page?: Pages }> = observer(({ page = Pages.BASKET_DETAILS }) => {
  const { basketId = '' } = useParams<{ basketId: string }>();
  const [basket, basketLoading, refreshBasket] = useBasket(basketId, mapList);
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  const onSuccess = useCallback(async () => {
    await refreshBasket();
  }, [refreshBasket]);

  const params = useMemo(() => ({ basket_id: basketId }), [basketId]);

  return (
    <BasketDetailsLayout
      basket={basket as IBasketStandardized}
      basketLoading={basketLoading}
      refreshBasket={refreshBasket}
      headerDropdownMenu={
        <ActionsMenu
          component={BasketActionsList}
          items={[basket as IBasketStandardized]}
          options={{ context: 'single', page, onSuccess }}
        />
      }
      isSortModeEnabled={isSortModeEnabled}
      setIsSortModeEnabled={setIsSortModeEnabled}
    >
      {isSortModeEnabled ? (
        <BasketsProductsSortDataSection basketId={basketId} />
      ) : (
        <ProductsDataSection
          getSortMenu={getSortMenu}
          defaultParams={defaultParams}
          actionName="search_basket"
          params={params}
          showParentsOnlyFilter={false}
          page={page}
        />
      )}
    </BasketDetailsLayout>
  );
});

export default BasketDetails;
