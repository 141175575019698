import { IShouldDisplayDeliveryActionFunction } from './types';

export const isDeliveryDraftAndSingleContext: IShouldDisplayDeliveryActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities[0]?.status === 'draft' && options.context === 'single';
};

export const shouldDisplayCopy: IShouldDisplayDeliveryActionFunction = (action, index, user, entities, options) => {
  return options.context === 'single';
};

export const shouldDisplayEdit: IShouldDisplayDeliveryActionFunction = (action, index, user, entities, options) => {
  return (
    options.context === 'single' &&
    (entities[0]?.status === 'draft' || entities[0]?.status === 'scheduled' || entities[0]?.status === 'failed')
  );
};

export const shouldDisplaySend: IShouldDisplayDeliveryActionFunction = (action, index, user, entities, options) => {
  return options.context === 'single' && (entities[0]?.status === 'draft' || entities[0]?.status === 'scheduled');
};

export const shouldDisplayRevoke: IShouldDisplayDeliveryActionFunction = (action, index, user, entities, options) => {
  return options.context === 'single' && entities[0]?.status === 'scheduled';
};

export const shouldDisplayRetrigger: IShouldDisplayDeliveryActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.context === 'single' && entities[0]?.status === 'failed';
};
