import React from 'react';

import { FormCategories, IUseFormReturn } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IContact } from 'types';

const ContactPreferencesSection: React.FC<IUseFormReturn<IContact>> = ({ formData, handlers }) => {
  return (
    <FormSectionWrapperV2
      title="Preferences"
      className="entity-detail__infobox-wrapper"
      wrapperClassName="py-3"
      withBorder
    >
      <FormCategories
        large
        key="genre_preferences_ids"
        name="genre_preferences_ids"
        label="Genres"
        {...formData.genre_preferences_ids}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
};

export default ContactPreferencesSection;
