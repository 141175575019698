import { IBasketStandardized, IBasketMapper } from 'types';
import { useCallback } from 'react';
import { loadBasket } from 'utils/apis/lists';
import { useRemote } from 'utils/hooks';

export type ILoading = boolean;
export type IRefreshBasket = () => Promise<void>;

export type IUseBasketData = [IBasketStandardized | undefined, ILoading, IRefreshBasket];

const useBasket = (listId: string, listMapper: IBasketMapper): IUseBasketData => {
  const refreshBasket = useCallback(async (): Promise<IBasketStandardized> => {
    const data = await loadBasket(listId);
    return listMapper(data);
  }, [listId, listMapper]);

  const [basket, loading, , refresh] = useRemote<IBasketStandardized>(refreshBasket);

  return [basket, loading, refresh];
};

export default useBasket;
