/* confirmation validation
 *
 * examples:
 * validations: [ { confirmation: { refValue: "my password" } } ]
 */

export interface IConfirmationValidationOpts {
  refValue: string;
}

export const confirmationValidation = (opts: IConfirmationValidationOpts, value: string): [boolean, string?] => {
  const { refValue } = opts;

  if (refValue !== value) {
    return [false, `Passwords do not match`];
  }

  return [true];
};
