import React from 'react';

import { Classes, placeholder } from 'utils/ui';
import { formatLayerDuration } from 'utils/date';

import { IProduct, IProductLayer } from 'types';

export const ProductSearchItemInfo: React.FC<{ product: IProduct; isAncestryMode }> = ({ product, isAncestryMode }) => {
  const layer = (product?.default_layer || {}) as IProductLayer;
  const { year_of_production } = layer.meta || {};
  return (
    <>
      <span className={Classes.TEXT_MUTED}> {placeholder(year_of_production)}</span>
      {!isAncestryMode && (
        <span className="searchbox__info-text text-truncate">{placeholder(formatLayerDuration(layer))}</span>
      )}
    </>
  );
};
