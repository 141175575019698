import { isInteger, isNumber } from 'lodash';
import { emptyValidation } from './empty';

/* numericality validation
 *
 * examples:
 * validations: [ { numericality: { allow_blank: true, greater_than: -2 } } ]
 * validations: [ { numericality: { allow_blank: true, greater_than: MIN_DURATION } } ]
 * validations: [ { numericality: { allow_blank: true, greater_than_or_equal_to: 1896, less_than_or_equal_to: 2100 } } ]
 * validations: [ { numericality: { only_integer: true, allow_blank: true } } ]
 * validations: [ { numericality: { only_integer: true, allow_nil: true, greater_than: 0 } } ]
 * validations: [ { numericality: { only_integer: true, allow_nil: true, greater_than_or_equal_to: 0, less_than_or_equal_to: 99999 } } ]
 */

export interface INumericalityValidationOpts {
  greater_than?: number;
  less_than?: number;
  greater_than_or_equal_to?: number;
  less_than_or_equal_to?: number;
  only_integer?: boolean;
  allow_nil?: boolean;
  allow_blank?: boolean;
}

export const numericalityValidation = (
  opts: INumericalityValidationOpts,
  value?: number | string | null,
): [boolean, string?] => {
  const {
    greater_than,
    greater_than_or_equal_to,
    less_than,
    less_than_or_equal_to,
    only_integer,
    allow_nil = true,
    allow_blank = true,
  } = opts;

  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank, allow_nil }, value);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  // because we already passed empty check we are expecting to have number comparison later
  // therefore if we have something that is not a number, we can return right here
  if (!isNumber(value)) {
    return [true];
  }

  if (isNumber(greater_than) && value <= greater_than) {
    return [false, `should be greater than ${greater_than}`];
  }

  if (isNumber(less_than) && value >= less_than) {
    return [false, `should be less than ${less_than}`];
  }

  if (isNumber(greater_than_or_equal_to) && value < greater_than_or_equal_to) {
    return [false, `should be greater than or equal to ${greater_than_or_equal_to}`];
  }

  if (isNumber(less_than_or_equal_to) && value > less_than_or_equal_to) {
    return [false, `should be less than or equal to ${less_than_or_equal_to}`];
  }

  if (only_integer && !isInteger(value)) {
    return [false, `should be integer`];
  }

  return [true];
};
