import React, { useCallback } from 'react';

import { IProduct } from 'types';

import { AccessTab } from 'components/access-tab';
import { getProductGroupIds } from 'utils/apis/product';

export const ProductAccessTab: React.FC<{
  product: IProduct;
}> = ({ product }) => {
  const getGroupIds = useCallback(() => getProductGroupIds(product.id), [product.id]);

  return <AccessTab getGroupIds={getGroupIds} />;
};
