import React, { useCallback } from 'react';
import { useStore } from 'store';
import { IModalSize } from 'components/dialogs/types';
import ProductCreate from 'pages/product-create/product-create';
import { Product3Types, IProduct } from 'types';

export const useCreateNewProduct = (
  productType: Product3Types,
  parent: IProduct,
): React.MouseEventHandler<HTMLButtonElement> => {
  const { dialogStore } = useStore();
  const openCreateNewProductDialog = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      return dialogStore.openModal({
        title: 'Create New Product',
        fitInView: true,
        size: IModalSize.XL,
        body: () => <ProductCreate productType={productType} parent={parent} onSuccess={dialogStore.close} />,
      });
    },
    [dialogStore, parent, productType],
  );

  return openCreateNewProductDialog;
};
