import React, { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cx from 'classnames';

import { DraggableType } from 'components/data-section-selected-tab';
import { DataSectionContentWrapper } from 'components/data-section-content-wrapper';
import { Loading } from 'components/loading';
import { ISortableItem } from 'store/sort-data-store';
import { IListItem } from 'types';
import { useSortDataSectionStore } from 'store';
import { IFormMultiSelectOption } from 'helpers/form';

import { SortDataSectionItem } from './sort-data-section-item';
import './style.scss';
import { EmptySectionMessage } from 'components/section-message';

export interface ICard {
  card?: ISortableItem;
  index: number;
}

interface ISortDataSectionAreaProps {
  itemRenderer: (item: ISortableItem | IListItem | IFormMultiSelectOption) => JSX.Element;
  className?: string;
  withCustomSorting?: boolean;
  onItemRemove?: (id: string | number) => void;
  hideEmptyMsg?: boolean;
}

export const SortDataSectionArea: React.FC<ISortDataSectionAreaProps> = observer((props) => {
  const { itemRenderer, withCustomSorting = false, onItemRemove, className, hideEmptyMsg } = props;
  const { list, updateStore, isLoading, updateActive, active } = useSortDataSectionStore();

  const [cards, setCards] = useState<ISortableItem[]>(list);

  useEffect(() => {
    if (list) setCards(list);
  }, [list]);

  const findCard = useCallback(
    (id: string): ICard => {
      const card = cards.filter((c) => c.id === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards],
  );

  const moveCard = useCallback(
    (id: string, atIndex: number, options: { wasCardDropped: boolean } = { wasCardDropped: false }): void => {
      const { card, index } = findCard(id);
      if (!card) {
        return;
      }
      if (options.wasCardDropped && withCustomSorting) {
        updateStore(cards, { withCustomSorter: true, from: atIndex, to: index });
        return;
      }

      const reorderedChecked = [...cards];

      reorderedChecked.splice(index, 1);
      reorderedChecked.splice(atIndex, 0, card);

      updateStore(reorderedChecked);
    },
    [cards, findCard, updateStore, withCustomSorting],
  );

  const onClick = useCallback(
    (card: ISortableItem): void => {
      updateActive(card);
    },
    [updateActive],
  );

  const handleRemoveItem = useCallback(
    (id: string | number): void => {
      if (!id) {
        return;
      }
      if (id === active?.id) {
        updateActive(null);
      }
      onItemRemove?.(id);
    },
    [active, onItemRemove, updateActive],
  );

  const handleMoveByClick = useCallback(
    (cardObj: ICard, direction: 'down' | 'up'): void => {
      const { card, index } = cardObj;
      if (!card) {
        return;
      }

      const reorderedChecked = [...cards];
      const atIndex = direction === 'down' ? index + 1 : index - 1;

      reorderedChecked.splice(index, 1);
      reorderedChecked.splice(atIndex, 0, card);

      const options = withCustomSorting ? { withCustomSorter: true, from: index, to: atIndex } : undefined;
      updateStore(reorderedChecked, options);
      updateActive(card);
    },
    [cards, withCustomSorting, updateStore, updateActive],
  );

  const unselectItem = useCallback(() => {
    updateActive(null);
  }, [updateActive]);
  const [, drop] = useDrop({ accept: DraggableType.GROUP_ITEM });
  if (isLoading) {
    return (
      <div className={cx('data-section-layout', className)}>
        <Loading text="Loading Data" />
      </div>
    );
  }

  if (!hideEmptyMsg && !list?.length) {
    return (
      <div className={cx('data-section-layout', className)}>
        <EmptySectionMessage />
      </div>
    );
  }

  return (
    <div className={cx('data-section-layout', className)}>
      <div className="data-section">
        <div className="data-section__content-wrapper">
          <div ref={drop} className="data-section-layout" onClick={unselectItem}>
            <DataSectionContentWrapper layout="list">
              {cards
                ?.filter((item) => item && !item.isRemoved)
                .map((item) => (
                  <SortDataSectionItem
                    key={item.id}
                    item={item}
                    withCustomSorting={withCustomSorting}
                    isActive={item.id === active?.id}
                    moveCard={moveCard}
                    moveByClick={handleMoveByClick}
                    onClick={onClick}
                    findCard={findCard}
                    maxLength={cards.length}
                    itemRenderer={itemRenderer}
                    onRemove={Boolean(onItemRemove) ? handleRemoveItem : undefined}
                  />
                ))}
            </DataSectionContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
});

export const SortDataSection: React.FC<ISortDataSectionAreaProps> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <SortDataSectionArea {...props} />
    </DndProvider>
  );
};

export default SortDataSection;
