import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import ScrollWrapper from 'components/scroll-wrapper';

import { IAffiliation } from 'types';

const AffiliationEmailSettingsTab: React.FC<{ affiliation?: IAffiliation }> = ({ affiliation }) => {
  const { email_cc, email_bcc, email_reply_to, contact_email, registration_notification_emails } = affiliation || {};
  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" wrapperClassName="py-3" className="text-no-transform ">
        <Row label="Email Cc" value={email_cc} />
        <Row label="Email Bcc" value={email_bcc} />
        <Row label="Email Reply To" value={email_reply_to} />
        <Row label="Contact Email" value={contact_email} />
        <Row label="Registration Notification Emails" value={registration_notification_emails} />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationEmailSettingsTab;
