import { map } from 'lodash';

import {
  GroupTypes,
  IAsset,
  IAssetGroupItem,
  IContact,
  IGroup,
  IIdentifiable,
  IProduct,
  IProductGroupItem,
  IQueryParams,
  ISearchFilter,
  ISelection,
  ISelectionItem,
  ISelectionStandardized,
  ItemId,
} from 'types';
import { by, parseToSearchParams } from 'utils/general';
import { chipmunk } from 'utils/chipmunk';
import { contactListSchema } from 'utils/schemas/contact';
import { SelectionSchema } from 'utils/schemas/selection';
import { Model } from 'helpers/filters/types';

import { queryAssets } from './asset';
import { queryProducts } from './product';

export const loadSelection = async (selectionId: string): Promise<ISelection> => {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.CONTACTS_GROUPS, 'get', {
      params: { group_ids: selectionId },
      schema: SelectionSchema,
    });
    return object;
  });
};

export interface ILoadSelectionItemsProps {
  selections?: ISelectionStandardized[];
  ids?: ItemId[];
  target: Model;
  actionName?: string;
  per?: number;
  schema?: string;
}

export const loadSelectionItems = async <T>({
  selections,
  ids,
  target,
  actionName = 'get',
  per = 25,
  schema,
}: ILoadSelectionItemsProps): Promise<T[]> => {
  const selectionIds = ids || map(selections, 'id');
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(target, actionName, {
      params: { per, group_ids: selectionIds },
      schema,
    });
    return objects;
  });
};

export const loadAllContactSelectionItems = async (
  selections: Pick<ISelectionStandardized, 'id'>[],
): Promise<IContact[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.unfurl(Model.CONTACT_GROUP_SET, 'query_users', {
      params: { group_ids: map(selections, 'id') },
      schema: contactListSchema,
    });
    return objects;
  });
};

export const loadAssetSelectionItems = async (
  assetSelections?: ISelectionStandardized[],
  ids?: ItemId[],
  additionalFilters?: ISearchFilter[],
): Promise<(IAsset & ISelectionItem)[]> => {
  const selectionItems = await loadSelectionItems<IAssetGroupItem>({
    selections: assetSelections,
    ids,
    target: Model.ASSET_GROUP_ITEMS,
    schema: `id, asset_id`,
  });

  const assetIds = selectionItems.map(({ asset_id }) => asset_id);
  const assets = await queryAssets({ ids: assetIds, include_deleted: false }, additionalFilters);
  const selectionItemsByAssetId = by(selectionItems, 'asset_id');

  return assets.map((asset) => ({
    ...asset,
    selection_item_id: asset?.id && selectionItemsByAssetId[asset.id]?.id,
  }));
};

export const loadProductSelectionItems = async (
  productSelections?: ISelectionStandardized[],
  ids?: ItemId[],
): Promise<(IProduct & ISelectionItem)[]> => {
  const productSelectionItems = await loadSelectionItems<IProductGroupItem>({
    selections: productSelections,
    ids,
    target: Model.PRODUCT_GROUP_ITEMS,
    schema: `id, product_id`,
  });
  const selectionItemByProductId = by(productSelectionItems, 'product_id');
  const products = await queryProducts({ ids: productSelectionItems.map(({ product_id }) => product_id) });

  return products.map((product) => ({
    ...product,
    selection_item_id: product?.id && selectionItemByProductId[product.id]?.id,
  }));
};

type IQueryAssetSelections = (
  params: IQueryParams,
  filters?: ISearchFilter[],
) => Promise<((IAsset | IGroup) & IIdentifiable)[]>;
export const queryAssetSelections: IQueryAssetSelections = (params, filters = []) => {
  return chipmunk.run(async ({ action }) => {
    const actionFilters: ISearchFilter[] = [
      ...filters,
      ['type', 'eq', GroupTypes.SELECTION],
      ['main_entity_type', 'eq', 'assets'],
    ];

    const { objects } = await action(Model.CONTACT_GROUP_SET, 'search', {
      body: parseToSearchParams(params, actionFilters),
      schema: `name, id, updated_at, access_level, main_entity_type, am_statistics_data`,
    });

    return objects;
  });
};
