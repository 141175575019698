import React from 'react';
import { RingProgress } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Classes } from 'utils/ui';

export interface IAssetsUploadProgressIndicator {
  progress: number;
}

export const AssetsUploadProgressIndicator: React.FC<IAssetsUploadProgressIndicator> = observer(({ progress }) => {
  return (
    <div className="asset-upload-components__upload-dashboard-progress-indicator my-2">
      <RingProgress
        thickness={5}
        roundCaps
        variant="primary"
        sections={[{ value: progress, color: 'var(--mfx-primary)' }]}
        size={140}
        label={
          <div className="asset-upload-components__upload-dashboard-progress-text">
            <div className="asset-upload-components__upload-dashboard-progress-percent">{progress}%</div>
            <div className={cx(`asset-upload-components__upload-dashboard-progress-subtext ${Classes.TEXT_MUTED}`)}>
              Uploaded
            </div>
          </div>
        }
      />
    </div>
  );
});

export default React.memo(AssetsUploadProgressIndicator);
