import { isInMainPage } from 'utils/actions/utils';
import { IShouldDisplayGroupActionFunction } from './types';
import { areAllDeleted, areAllNotDeleted } from 'utils/is-entity-deleted';
import { IGroup, IItem } from 'types';
import { flags } from 'utils/flags';

export const shouldDisplayDelete: IShouldDisplayGroupActionFunction = (action, index, user, items, options) => {
  return areAllNotDeleted(items as IGroup[]) && isInMainPage(options);
};

export const shouldDisplayRestore: IShouldDisplayGroupActionFunction = (action, index, user, items) => {
  return areAllDeleted(items as IGroup[]);
};

export const shouldDisplaySingleNotDeleted: IShouldDisplayGroupActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options?.context === 'single' && entities?.length > 0 && areAllNotDeleted(entities as IGroup[]);
};

export const shouldDisplayNotDeleted: IShouldDisplayGroupActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities as IGroup[]);
};

export const shouldDisplayMultiNotDeleted = (action, index, user, entities: IItem[]): boolean => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};
export const shouldDisplayGrantAccessPrivilege: IShouldDisplayGroupActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities as IGroup[]) && flags.showAccessPrivilegeFeature;
};
export const shouldDisplayCreateScreeningRoom: IShouldDisplayGroupActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && flags.showCreateScreeningRoom && areAllNotDeleted(entities as IGroup[]);
};
