import React from 'react';
import { Tabs } from '@mantine/core';
import cx from 'classnames';

import { DetailsPageTabs, ProductAccessTabSubTabs } from 'types';

const TabPanel: React.FC<{
  value: DetailsPageTabs | ProductAccessTabSubTabs;
  content: JSX.Element;
  className?: string;
}> = ({ value, content, className }) => {
  return (
    <Tabs.Panel value={value} className={cx(className)}>
      {content}
    </Tabs.Panel>
  );
};
export default TabPanel;
