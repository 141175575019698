import { withAssetVersion } from 'utils/asset';

import { AssetMm3SubtitlesTab } from './asset-mm3-subtitles-tab';
import { AssetOldSubtitlesTab } from './asset-old-subtitles-tab';
import { IAsset, IMm3Asset } from 'types';

export const AssetSubtitlesTab = withAssetVersion(AssetOldSubtitlesTab, AssetMm3SubtitlesTab) as React.FC<{
  asset: IMm3Asset | IAsset;
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>;
}>;
