import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Row } from 'components/label-value-pair';

import { DetailsPageTabs, IProductAncestryInfo, ItemId, Product3Types } from 'types';
import { Routes } from 'utils/routes';
import { pluralizeProductType } from 'utils/product';

export interface IProductOverviewAboutProps {
  id?: ItemId | null;
  ancestry?: IProductAncestryInfo;
}

export const ProductDescendantsCount: React.FC<IProductOverviewAboutProps> = observer(({ id, ancestry }) => {
  const {
    effective_series_count,
    effective_seasons_count,
    effective_episodes_count,
    effective_episode_versions_count,
    effective_programs_count,
    effective_film_versions_count,
  } = ancestry || {};

  return (
    <>
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.SERIES)}`}
        value={
          effective_series_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.SERIES}`}>{effective_series_count}</Link>
          )
        }
        size="m"
      />
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.SEASON)}`}
        value={
          effective_seasons_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.SEASONS}`}>{effective_seasons_count}</Link>
          )
        }
        size="m"
      />
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.EPISODE)}`}
        value={
          effective_episodes_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.EPISODES}`}>{effective_episodes_count}</Link>
          )
        }
        size="m"
      />
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.EPISODE_VERSION)}`}
        value={
          effective_episode_versions_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.EPISODE_VERSION}`}>
              {effective_episode_versions_count}
            </Link>
          )
        }
        size="m"
      />
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.PROGRAM)}`}
        value={
          effective_programs_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.PROGRAMS}`}>{effective_programs_count}</Link>
          )
        }
      />
      <Row
        hideIfNoValue
        rawLabel
        label={`Number of ${pluralizeProductType(Product3Types.FILM_VERSION)}`}
        value={
          effective_film_versions_count && (
            <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.FILM_VERSION}`}>{effective_film_versions_count}</Link>
          )
        }
        size="m"
      />
    </>
  );
});
