import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pages } from 'utils/actions/types';
import { DeliveryDetails } from 'pages/delivery-details';

const OrganizationDeliveryDetails: React.FC = observer(() => {
  return <DeliveryDetails page={Pages.ORGANIZATION_DELIVERY_DETAILS} />;
});

export default OrganizationDeliveryDetails;
