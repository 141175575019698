import React, { useCallback, useEffect } from 'react';
import { Button } from '@mantine/core';

import { ItemId } from 'types';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { AccessPrivilegeProductSettingsChangeForm } from './access-privilege-product-settings-change-form';
import type { IAccessPrivilegeProductSettingsForm } from './types';
import { useRemote } from 'utils/hooks';
import { updateGroupProducts, loadPropsFromExistingProductItems } from 'utils/apis/groups';
import { logger } from 'utils/logger';

interface IAccessPrivilegeProductSettingsChange {
  productIds: ItemId[];
  groupIds: ItemId[];
  onSubmit?: () => void;
}

const contextExtension = {
  properties: {
    group_id: {
      required: true,
    },
    item_ids: {
      required: true,
    },
    delegates_access: {
      required: true,
    },
    permit_download: {
      required: true,
    },
  },
};

export const AccessPrivilegeProductSettingsChange: React.FC<IAccessPrivilegeProductSettingsChange> = ({
  productIds,
  groupIds,
  onSubmit,
}) => {
  const { toastStore } = useStore();

  const submit = useCallback(
    async (values: IAccessPrivilegeProductSettingsForm) => {
      try {
        toastStore.info('Updating');

        await updateGroupProducts(values);

        toastStore.clearAll();
        toastStore.success('Access privilege products updated successfully');

        onSubmit?.();
      } catch (e) {
        logger.error(e);
      }
    },
    [onSubmit, toastStore],
  );

  const loadProductsProps = useCallback(() => {
    return loadPropsFromExistingProductItems(groupIds[0], productIds);
  }, [groupIds, productIds]);

  const [existingProps] = useRemote(loadProductsProps);

  const form = useForm<IAccessPrivilegeProductSettingsForm>(
    {
      delegates_access: true,
      permit_download: true,
      product_ids: productIds,
      group_ids: groupIds,
    },
    Model.ACCESS_PRIVILEGES,
    submit,
    contextExtension,
  );

  const {
    handlers: { onChange },
  } = form;

  useEffect(() => {
    const newValues = {
      delegates_access: existingProps?.delegates_access ?? true,
      permit_download: (existingProps?.permissions || []).includes('download'),
    };
    onChange(newValues);
  }, [existingProps, onChange]);

  return (
    <FormSectionWrapperV2 gridTemplateColumns={'1fr'} padded={false}>
      <AccessPrivilegeProductSettingsChangeForm form={form} />
      <Button variant="primary" size="md" onClick={form.onSubmit} disabled={!form.valid}>
        Change
      </Button>
    </FormSectionWrapperV2>
  );
};
