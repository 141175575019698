import React from 'react';
import cx from 'classnames';

import { IStyled } from 'types';

import './style.scss';
export interface IThumbnailProps extends IStyled {
  url?: string;
  showType?: true;
  alt?: string;
  width?: string | number;
  height?: string | number;
}

const ThumbnailImage: React.FC<IThumbnailProps> = ({ url, className, alt, width, height }) => {
  return (
    <>
      {url && <img src={url} alt={alt} width={width} className={className} height={height} />}

      {!url && (
        <div className={cx(['thumbnail__content thumbnail__content--fallback', className])}>No Preview Image</div>
      )}
    </>
  );
};

export default ThumbnailImage;
