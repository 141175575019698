import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';

export enum IRecommendationActionName {
  ADD_TO_SELECTION = 'add-to-selection',
  EDIT = 'edit',
  EDIT_EXPIRY = 'edit-expiry',
  DELETE = 'delete',
  SEND = 'send',
  REVOKE = 'revoke',
}

export type IRecommendationActionConfig = IActionConfig<McGenericRecommendationProduct, IRecommendationActionName>;
export type IShouldDisplayRecommendationActionFunction = IShouldDisplayActionFunction<
  McGenericRecommendationProduct,
  IRecommendationActionName
>;
