import { IActionConfig, IUseActionsOptionParam } from 'utils/actions';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';

import { useDisableWhileSubmitting } from 'utils/hooks/submit';
import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IMarketingEvent, ISearchFilter } from 'types';
import { FormSliderShowcase } from 'components/showcase';
import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';
import { createList } from 'utils/apis/showcase';
import { IEntitiesToQuery, createListItems, deleteListItems } from 'utils/apis/showcase-item';
import { IFormMultiSelectOption } from 'helpers/form';

import { IMarketingEventActionName } from 'utils/actions/event/types';
import { getAllListItems } from 'utils/apis/showcase-item';
import { getShowcaseByPurpose } from 'utils/apis/showcase';

interface ISliderFormProps {
  sliderShowcase?: UmGenericListShowcase | null;
  onSubmit: (items: IFormMultiSelectOption[]) => Promise<void>;
  options: IUseActionsOptionParam<UmGenericListShowcase>;
  additionalFilters?: { [key in IEntitiesToQuery]?: ISearchFilter[] };
}
const allowedItemTypes: ['user'] = ['user'];
const purpose = 'meetings_hosts';

const MeetingsHostsForm: React.FC<ISliderFormProps> = observer(
  ({ onSubmit, sliderShowcase, options, additionalFilters }) => {
    const { toastStore, dialogStore } = useStore();
    const handleSubmit = useCallback(
      async (items: IFormMultiSelectOption[]): Promise<void> => {
        try {
          await onSubmit(items);
          options?.onSuccess?.(IMarketingEventActionName.MANAGE_SLIDER);
          toastStore.success(
            `Meetings hosts List has been ${sliderShowcase?.id ? 'updated' : 'created'} successfully!`,
          );
          dialogStore.close();
        } catch (error) {
          options?.onFailure?.();
          toastStore.error(<ToastError error={error} />);
        }
      },
      [onSubmit, options, toastStore, sliderShowcase?.id, dialogStore],
    );

    return (
      <FormSliderShowcase
        allowed_item_types={allowedItemTypes}
        additionalFilters={additionalFilters}
        sliderId={sliderShowcase?.id}
        onSubmit={handleSubmit}
      />
    );
  },
);

const onCreateMeetingsHostsShowcase = async (items: IFormMultiSelectOption[]): Promise<void> => {
  const sliderShowcase = await createList({
    meta: { page: 'configuration' },
    purpose,
    name: purpose,
    type: 'List::Showcase',
    access_level: 'viewable',
    allowed_item_types: allowedItemTypes,
  });
  await createListItems(items, sliderShowcase?.id);
};

export const useManageMeetingsHosts = (
  options: IUseActionsOptionParam,
): IActionConfig<IMarketingEvent, IMarketingEventActionName> => {
  const { dialogStore } = useStore();

  const onClick = useCallback(async () => {
    const sliderShowcase = await getShowcaseByPurpose(purpose);
    const onUpdate = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const listItems = await getAllListItems(sliderShowcase?.id);
      const itemsIds = listItems.map((e) => e.id || 0);
      await deleteListItems(sliderShowcase?.id, itemsIds);
      await createListItems(items, sliderShowcase?.id);
    };

    dialogStore.openModal({
      title: 'Manage Hosts',
      size: IModalSize.M,
      body: () => (
        <MeetingsHostsForm
          onSubmit={sliderShowcase?.id ? onUpdate : onCreateMeetingsHostsShowcase}
          sliderShowcase={sliderShowcase}
          options={options}
        />
      ),
    });
  }, [dialogStore, options]);

  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: IMarketingEventActionName.MANAGE_HOSTS,
    shouldDisplay: shouldDisplaySingleNotDeleted,
    icon: 'people',
    title: 'Manage Hosts',
    handler,
    isDisabled: disabled,
  };
};
