import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import ScrollWrapper from 'components/scroll-wrapper';
import { WorkflowActionsList } from 'components/action';

import { Routes } from 'utils/routes';
import { IAsset, SharedConstants } from 'types';
import { useRemote } from 'utils/hooks';
import { formatDate } from 'utils/date';
import { getContactName } from 'utils/ui';
import { getWorkflows } from 'utils/apis/workflow';
import { Pages } from 'utils/actions';
import { Loading } from 'components/loading';

import './style.scss';

const AssetWorkflowTab: React.FC<{ asset: IAsset }> = ({ asset }) => {
  const assetId = asset?.id;
  const fetcher = useCallback(() => getWorkflows(assetId), [assetId]);
  const [workflows = [], loading] = useRemote(fetcher, []);
  const hasNextRunAt = workflows.some((w) => w.next_run_at);
  const hasQueue = workflows.some((w) => w.tags?.am_task_id);
  const hasProgress = workflows.some((w) => w.progress);

  if (loading) {
    return <Loading />;
  }

  return (
    <ScrollWrapper className="w-100">
      <table className="w-100">
        <thead key="header" className="asset-details__workflow--table-header">
          <tr>
            <th>Full Id</th>
            <th>Status</th>
            {hasProgress ? <th>Progress</th> : <></>}
            {hasQueue ? <th>Queue</th> : <></>}
            <th>Workflow</th>
            <th>Owner</th>
            {hasNextRunAt ? <th>Next Run At</th> : <></>}
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>

        <tbody className="w-100">
          {workflows.map((workflow) => (
            <tr key={workflow.id} className="asset-details__workflow--table-line">
              <td>{workflow.full_id}</td>
              <td>{workflow.status}</td>
              {hasProgress ? <td>{workflow.progress ?? SharedConstants.NOT_ADDED}</td> : <></>}
              {hasQueue ? <td>{workflow.tags.am_task_id}</td> : <></>}
              <td>{(workflow.workflow || '').replace('Workflow::Base::', '')}</td>
              <td>
                <Link to={`${Routes.CONTACTS}/${workflow.owner?.id}`}>{getContactName(workflow.owner)}</Link>
              </td>
              {hasNextRunAt ? <td>{formatDate(workflow.next_run_at) || SharedConstants.NOT_ADDED}</td> : <></>}
              <td>{formatDate(workflow.created_at) || workflow.created_at}</td>
              <td>
                <WorkflowActionsList items={[workflow]} options={{ page: Pages.LIST, context: 'single' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </ScrollWrapper>
  );
};

export { AssetWorkflowTab };
