import React from 'react';
import { AmGenericNews, UmGenericListCollection } from '@mediafellows/mm3-types';
import { Menu, MenuItemProps } from '@mantine/core';

import { noop } from 'lodash';

import { by } from 'utils/general';
import { autosuggestValues } from 'utils/autosuggest-values';
import { IAsset, ICategory, IProduct, IContact, IOrganization, IQueryParams, IGroup } from 'types';
import { ProductSearchItem } from 'components/product-search-item';

import { AssetSearchItem } from 'components/asset/asset-search-item';

import { UserSearchItem } from 'components/user-search-item';
import { OrganizationSearchItem } from 'components/organization';

import { CollectionSearchItem } from 'components/collection-search-item';
import { CategorySelectedTabItem } from 'components/category-selected-tab-item';
import { GroupSelectedItem } from 'components/group-search-item';
import { NewsSearchItem } from 'components/news';

import { parseItem, itemRenderer, ItemTypes } from 'helpers/form/fields/select-helpers';
import type { IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import { IFieldOptions } from 'helpers/form/types';
import { Model } from 'helpers/filters/types';

import type { IOptionId, IRawOption } from './types';
import { MantineIcon } from 'utils/ui';
import { Add } from 'blueprint5-icons';

export { parseItem, itemRenderer };

interface IExtendedMenuItemProps extends MenuItemProps {
  text: string;
  onClick?: () => void;
}
export function tagRenderer(omni: boolean) {
  return function render(item: IFormMultiSelectOption): React.ReactNode {
    const label = item?.label;

    if (!omni) {
      if (item.itemType === ItemTypes.PRODUCT) {
        return (item as IProduct).full_title || label;
      }
      return label;
    }

    if (item.itemType === ItemTypes.USER) {
      return <UserSearchItem key={`user-${(item as IContact).id}`} user={item as IContact} handleSelect={noop} />;
    }
    if (item.itemType === ItemTypes.NEWS) {
      return <NewsSearchItem key={`news-${item.value}`} news={item as AmGenericNews} handleSelect={noop} />;
    }
    if (item.itemType === ItemTypes.ORGANIZATION) {
      return (
        <OrganizationSearchItem
          key={`category-${(item as IOrganization).id}`}
          organization={item as IOrganization}
          handleSelect={noop}
        />
      );
    }

    if (item.itemType === ItemTypes.ASSET) {
      return <AssetSearchItem key={`asset-${(item as IAsset).id}`} asset={item as IAsset} handleSelect={noop} />;
    }

    if (item.itemType === ItemTypes.PRODUCT) {
      return (
        <ProductSearchItem key={`product-${(item as IProduct).id}`} product={item as IProduct} handleSelect={noop} />
      );
    }

    if (item.itemType === ItemTypes.LIST_COLLECTION) {
      return (
        <CollectionSearchItem
          key={`collection-${(item as UmGenericListCollection).id}`}
          handleSelect={noop}
          collection={item as UmGenericListCollection}
        />
      );
    }

    if (item.itemType === ItemTypes.CATEGORY) {
      return (
        <CategorySelectedTabItem
          key={`category-${(item as ICategory).id}`}
          category={item as ICategory}
          handleSelect={noop}
        />
      );
    }
    if (item['@type']?.includes('group')) {
      return <GroupSelectedItem key={`group-${(item as IGroup).id}`} handleSelect={noop} group={item as IGroup} />;
    }
    return label;
  };
}

export const parseOption = (op: IRawOption): IFormMultiSelectOption => {
  return typeof op === 'object' ? op : { label: `${op}`, value: op };
};

export const preparePropOptions = (options?: IRawOption[]): IFormMultiSelectOption[] => {
  return options?.map(parseOption) || [];
};

/**
 * Because parse might return void this is a workaround
 */
export const parseAndFilterItems = (items: unknown[]): IFormMultiSelectOption[] => {
  return items.map(parseItem).filter(Boolean) as IFormMultiSelectOption[];
};

export const prepareInitialValues = (
  value: IOptionId[],
  options?: IFormMultiSelectOption[],
): IFormMultiSelectOption[] => {
  const optionsByValue = by(options || [], 'value');
  return value.reduce((acc, e) => (optionsByValue[e] ? [optionsByValue[e], ...acc] : acc), []);
};

export const isValueEqualSelectedItems = (
  value?: IOptionId[] | null,
  selectedItems: IFormMultiSelectOption[] = [],
): boolean => {
  return (
    selectedItems.length === value?.length &&
    selectedItems.every((item) => value.toString().includes(item.value.toString()))
  );
};

const allowedSelectableStatus = ['created', 'review', 'available', 'archived'];

export const filterAssetStatusOptions = (options?: IFieldOptions): IFieldOptions | undefined =>
  options?.filter((option) => allowedSelectableStatus.includes(option));

export const getSuggestions = async ({ ids, q }: IQueryParams, property: string): Promise<string[]> => {
  if (ids) {
    return ids as string[];
  }
  return autosuggestValues(Model.PRODUCTS, property, q as string);
};

export const DefaultCreateTagElement: React.FC<IExtendedMenuItemProps> = ({ text, ...props }) => {
  return (
    <Menu>
      <Menu.Item leftSection={<MantineIcon icon={<Add />} />} {...props}>
        {`create: ${text}`}
      </Menu.Item>
    </Menu>
  );
};

export const loadMultiselectRemoteOptions = async (params): Promise<IFormMultiSelectOption[]> => {
  const { fetchValues, options, additionalFilters } = params;
  const fetchedItems = (await fetchValues?.(options, additionalFilters)) || [];
  const parsedItems = parseAndFilterItems(fetchedItems);
  return Array.isArray(parsedItems) ? parsedItems : [];
};

export const GroupTagElement: React.FC<IExtendedMenuItemProps> = ({ text, ...props }) => {
  return (
    <Menu>
      <Menu.Item leftSection={<MantineIcon icon={<Add />} />} {...props}>
        {`Add: ${text}`}
      </Menu.Item>
    </Menu>
  );
};
