import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Button, ActionIcon, TextInput } from '@mantine/core';
import cx from 'classnames';

import { useDebounce, usePrevious } from 'utils/hooks';
import { MantineIcon } from 'utils/ui';
import { QFilter } from 'helpers/filters/types';
import { FormGroup } from 'helpers/form/fields/form-group';

import './style.scss';

type ITextFilterDefinition = QFilter;

interface IFilterTextProps {
  name: string;
  label?: string;
  placeholder?: string;
  large?: boolean;
  disabled?: boolean;
  className?: string;
  isOpen?: boolean;

  filter: ITextFilterDefinition;
  onChange?: (newValue: { [key: string]: ITextFilterDefinition }) => void;
}

const FilterText: React.FC<IFilterTextProps> = observer((props) => {
  const searchOperators = [
    { id: 'OR', label: 'OR: search either word' },
    { id: 'AND', label: 'AND: search all words' },
  ];
  const { name, label, filter, onChange, ...restProps } = props;
  const { value, searchOperator } = filter;
  const [internalValue, setInternalValue] = useState(value);
  const [searchParam, setSearchParam] = useState(searchOperator);

  const [lastFilterUpdate, setLastFilterUpdate] = useState<string | number | boolean | undefined>(undefined);
  const debouncedValue = useDebounce(internalValue, 250);
  const debouncedSearchOpValue = useDebounce(searchParam, 250);
  const prevDebounced = usePrevious(debouncedValue);
  const prevDebouncedSearchOpValue = usePrevious(debouncedSearchOpValue);
  const disableReset = debouncedValue === '';

  useEffect(() => {
    // detect filter update from parent
    if (typeof lastFilterUpdate !== 'undefined' && lastFilterUpdate !== filter.value) {
      setInternalValue(filter.value);
      setLastFilterUpdate(filter.value);
      onChange && onChange({ [name]: { ...filter, value: `${filter.value}`, searchOperator: filter.searchOperator } });
      return;
    }

    // filter already applied
    if (
      (filter.value === debouncedValue && filter.searchOperator === debouncedSearchOpValue) ||
      (prevDebounced === debouncedValue && prevDebouncedSearchOpValue === debouncedSearchOpValue)
    ) {
      return;
    }

    setLastFilterUpdate(debouncedValue);
    onChange?.({ [name]: { ...filter, value: `${debouncedValue}`, searchOperator: debouncedSearchOpValue } });
  }, [
    debouncedValue,
    filter,
    name,
    onChange,
    lastFilterUpdate,
    prevDebounced,
    debouncedSearchOpValue,
    prevDebouncedSearchOpValue,
  ]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    setSearchParam(searchOperator);
  }, [searchOperator]);

  const valueNormalized = `${internalValue}`;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((ev) => {
    const newValue = ev.currentTarget.value;
    setInternalValue(newValue);
  }, []);

  const handleReset = (): void => {
    setInternalValue('');
    setSearchParam('OR');
    onChange?.({ [name]: { ...filter, value: '', searchOperator: 'OR' } });
  };

  const handleOperatorChange = useCallback((operator): void => {
    setSearchParam(operator.id);
  }, []);

  const searchMenu = (
    <Menu>
      <Menu.Target>
        <Button
          security="md"
          variant="subtle"
          rightSection={<MantineIcon icon="caret-down" />}
          disabled={Boolean((internalValue as string).split(' ').length < 2)}
          style={{ transform: 'none' }}
        >
          {searchParam}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {searchOperators.map((operator) => (
          <Menu.Item key={operator.id} onClick={() => handleOperatorChange(operator)}>
            {operator.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <div className={cx(`filter-text d-flex align-items-end ${props?.className}`)}>
      <div className="flex-fill">
        <FormGroup label={label} labelFor={name}>
          <TextInput
            autoFocus={!!props?.isOpen}
            value={valueNormalized}
            {...restProps}
            onChange={handleChange}
            rightSection={searchMenu}
            rightSectionWidth="85px"
          />
        </FormGroup>
      </div>
      <div>
        <ActionIcon
          size="lg"
          variant="subtle"
          className={cx(`${props?.className ? '' : 'mb-3'}`)}
          disabled={disableReset}
          onClick={handleReset}
        >
          <MantineIcon icon="cross" />
        </ActionIcon>
      </div>
    </div>
  );
});

export default FilterText;
