import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { UmGenericListItem } from '@mediafellows/mm3-types';

import { Thumbnail } from 'components/thumbnail';
import useSelectedOption from 'utils/hooks/selected-option';
import { formatField } from 'utils/general';

export const SeparatorListItem: React.FC<{
  contextMenu?: JSX.Element;
  separator: UmGenericListItem;
  handleClick?: (e) => void;
  isActive?: boolean;
  isChecked?: boolean;
}> = ({ contextMenu, isActive, isChecked, separator, handleClick }) => {
  const selectedOption = useSelectedOption({ value: 'public' });
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div
      className={cx('entity-list-item', {
        'entity-list-item--active entity-list-item-row--active': isActive,
      })}
      onClick={handleClick}
    >
      <div className="entity-list-item-row">
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={itemChecked} />
        </div>

        <Thumbnail fallbackIconName="vertical-distribution" showType customTag="Separator" />

        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title">
            <h4>{formatField(separator?.meta?.title)}</h4>
            {Boolean(contextMenu) && contextMenu}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeparatorListItem;
