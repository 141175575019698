import React from 'react';
import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { FormInput, IFormMultiSelectOption } from 'helpers/form';
import { FormMultiEntitiesSelect } from 'helpers/form/custom-fields/form-multi-entities-select';
import { formatWebsiteTypes, showcaseRenderItemParser } from 'components/showcase-form/utils';
import { includesSeparator } from 'utils/list';
import { collectionFilters, showcaseFilters } from 'components/showcase-form/constants';

interface IFormAddToGroupItemsForm {
  showcase?: UmGenericListShowcase | UmGenericListCollection;
  onSelectedItemsChange: (items: IFormMultiSelectOption[]) => void;
}

const selectDefaultProps = {
  validation: { valid: true },
  required: true,
  disabled: true,
};

export const FormAddItemsToShowcaseForm: React.FC<IFormAddToGroupItemsForm> = ({ showcase, onSelectedItemsChange }) => {
  if (!showcase?.id) {
    return <></>;
  }
  const type = formatWebsiteTypes(showcase.type);
  const isCollection = showcase.type === 'List::Collection';

  return (
    <>
      <FormInput name="showcase_id" label={type} {...selectDefaultProps} value={showcase.name} />
      <FormMultiEntitiesSelect
        allowedItemTypes={showcase?.allowed_item_types}
        withSeparator={includesSeparator(showcase?.allowed_item_types)}
        onSelectedItemsChange={onSelectedItemsChange}
        additionalFilters={isCollection ? collectionFilters : showcaseFilters}
        customRenderItemParser={showcaseRenderItemParser}
        isAncestryMode
      />
    </>
  );
};
