import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHotkeys } from '@mantine/hooks';
import { useBasicStore } from 'store/hooks';
import { DashboardHotkeys } from 'types';
import { colorModeToggle } from 'components/top-header/top-header';

interface IHotkeysProps {
  toggle: () => void;
}

const HotkeyManager: React.FC<IHotkeysProps> = observer(({ toggle }) => {
  const { isGlobalSearchOpen, updateBasicStore } = useBasicStore();

  const handleAction = (combo: string): void => {
    switch (combo) {
      case DashboardHotkeys.TOGGLE_SEARCH:
        updateBasicStore('isGlobalSearchOpen', !isGlobalSearchOpen);
        break;
      case DashboardHotkeys.TOGGLE_DARK_LIGHT:
        colorModeToggle();
        break;
      case DashboardHotkeys.TOGGLE_SIDEBAR:
        toggle();
        break;
    }
  };

  useHotkeys([
    [DashboardHotkeys.TOGGLE_SEARCH, () => handleAction(DashboardHotkeys.TOGGLE_SEARCH)],
    [DashboardHotkeys.TOGGLE_DARK_LIGHT, () => handleAction(DashboardHotkeys.TOGGLE_DARK_LIGHT)],
    [DashboardHotkeys.TOGGLE_SIDEBAR, () => handleAction(DashboardHotkeys.TOGGLE_SIDEBAR)],
  ]);

  return null;
});

export default HotkeyManager;
