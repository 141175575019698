import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useDeleteAction } from './delete';
import { useEditGenreAction } from './edit';
import { useAddSubGenreAction } from './add-sub-genre';

import { IGenreActionConfig, IGenreActionName } from './types';
import { IGenreTreeItem } from 'types';
import { useSortSubGenreAction } from './sort-sub-genres';

export const useGenreActions = (
  entities: IGenreTreeItem[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IGenreActionName> => {
  const editAction = useEditGenreAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);
  const addSubGenre = useAddSubGenreAction(entities, options);
  const sortSubGenres = useSortSubGenreAction(entities, options);
  const actions = [editAction, addSubGenre, sortSubGenres, deleteAction];
  return useActions(actions as IGenreActionConfig[], options, entities);
};
