import React from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';

import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { Row, yn } from 'components/label-value-pair';
import ProductAssets from 'components/asset/assets-aggregation/product-assets';
import { getIncludedAttribute, useGroupItemInfo } from 'components/product-preview-tab/utils';
import { InfoBoxV2 } from 'components/info-box-v2';

import { useDataSectionStore } from 'store';
import { DetailsPageTabs, IProductAsset, Product3Types } from 'types';
import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';
import { formatLayerDuration, formatDate } from 'utils/date';
import { formatField } from 'utils/general';
import { useLanguages } from 'utils/hooks';
import { CategoriesInfo } from 'utils/ui';
import { formatType } from 'utils/format-type';

const ProductPreviewAttributes: React.FC = observer(() => {
  const { activeItem: product } = useDataSectionStore<PmItvstudiosProduct3 & { assets: IProductAsset[] }>();

  const {
    access_level,
    created_at,
    default_layer,
    deleted_at,
    id,
    published_at,
    status,
    type: productType,
    updated_at,
  } = product || {};
  const groupItem = useGroupItemInfo(id);
  const layer = (default_layer || {}) as PmItvstudiosLayer3MotionPictureStandard;
  const { year_of_production, category_ids, language_ids, resolutions, product_state, bw_content, bbfc } =
    layer.meta || {};

  const languageInfo = useLanguages(language_ids);

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 1fr" className="product-preview__infobox px-3">
      <Row
        label="Assets"
        valueClassName="overflow-inherit"
        value={
          <ProductAssets
            assets={product?.assets}
            baseUrl={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.ASSETS}`}
            muted={false}
          />
        }
      />

      <Row hideIfNoValue label="Unpublished Assets" value={getIncludedAttribute(groupItem?.delegates_access)} />

      <Row hideIfNoValue label="Descendants" value={getIncludedAttribute(groupItem?.include_descendants)} />

      <Row
        hideIfNoValue
        label="Future descendants"
        value={getIncludedAttribute(groupItem?.include_future_descendants)}
      />
      <Row
        hideIfNoValue
        label="Download permission:"
        value={groupItem?.id && yn(groupItem.permissions?.includes('download'))}
      />

      <Row
        hideIfNoValue
        label="Access"
        value={
          <>
            {access_level} <ScheduledChangeIndicator entity={product} />
          </>
        }
      />

      <Row hideIfNoValue label="Status" value={status} />
      {productType === Product3Types.PROGRAM && (
        <Row hideIfNoValue label="Black & White" rawLabel value={yn(bw_content)} />
      )}

      <Row hideIfNoValue rawLabel label="Created at" value={formatDate(created_at)} />
      <Row hideIfNoValue rawLabel label="Updated at" value={formatDate(updated_at)} />
      <Row hideIfNoValue rawLabel label="Published at" value={formatDate(published_at)} />
      <Row hideIfNoValue rawLabel label="Deleted at" value={formatDate(deleted_at)} />
      <Row hideIfNoValue label="Type" value={formatType(productType)} />
      <Row hideIfNoValue rawLabel label="Year of production" value={year_of_production} />
      <Row
        hideIfNoValue
        className="text-lowercase"
        label="Duration"
        value={formatLayerDuration(product?.default_layer)}
      />
      <Row hideIfNoValue rawLabel label="BBFC" value={formatField(bbfc)} />
      <Row hideIfNoValue label="Resolutions" value={formatField(resolutions)} />
      <Row hideIfNoValue rawLabel label="Language" value={languageInfo} />
      <Row hideIfNoValue rawLabel label="Genres" value={<CategoriesInfo category_ids={category_ids} />} />
      <Row hideIfNoValue rawLabel label="Product status" value={product_state} />
    </InfoBoxV2>
  );
});

export default ProductPreviewAttributes;
