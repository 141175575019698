import React from 'react';
import { observer } from 'mobx-react-lite';
import { sortBy } from 'lodash';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { LabelLinkPair } from 'components/entity-list';
import { ScrollWrapper } from 'components/scroll-wrapper';
import { IOrganizationDeliveryInformation } from 'types';

import './style.scss';

const OrganizationDeliveryInformationTab: React.FC<{ deliveryInformations: IOrganizationDeliveryInformation[] }> =
  observer((props) => {
    const { deliveryInformations } = props;
    const sortedDeliveryInformations = sortBy(deliveryInformations, [
      function (info) {
        return -info.updated_at;
      },
    ]);

    return (
      <ScrollWrapper>
        <InfoBoxV2 title="Delivery Specifications" wrapperClassName="py-3" gridTemplateColumns="1fr">
          {sortedDeliveryInformations?.map((data: IOrganizationDeliveryInformation, index: number) => {
            return (
              <InfoBoxV2
                key={data.page_url + index}
                className="delivery-specification-data"
                padded={false}
                gridTemplateColumns="1fr"
              >
                <Row label="label" value={data.label} size="m" />
                <Row label="Spec Type" value={data.spec_type} size="m" />
                {!data.document && <LabelLinkPair label="Page Url" link={data.page_url} />}
                {!data.page_url && <LabelLinkPair label="Document" link={data.document} />}
              </InfoBoxV2>
            );
          })}
        </InfoBoxV2>
      </ScrollWrapper>
    );
  });

export default OrganizationDeliveryInformationTab;
