export const newsListSchema = `
$type,
affiliation_id,
owner_id,
owner_organization_id,
created_at,
updated_at,
id,
title,
date,
preview_image_id,
asset_ids,
meta,
access_level,
owner,
owner_organization,
preview_image,
assets
`;

export const newsDetailsSchema = newsListSchema;
