import React from 'react';
import { IFilters } from 'components/product-filters/types';
import { FilterSwitch, IFiltersHandlers } from 'helpers/filters';
import { NotExistFilter } from 'helpers/filters/types';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';

export const additionalFilters = {
  'meta.ccid.fts': new NotExistFilter('meta.ccid.fts', false),
};

interface IAdditionalFilters {
  'meta.ccid.fts': NotExistFilter;
}

interface IAdditionalFiltersProps {
  filterValues: IAdditionalFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = observer(({ filterValues, filterHandlers }) => {
  const {
    dataSectionStore: { searchStore },
  } = useStore();

  return (
    <FilterSwitch
      label="Show products without CCID only"
      name="meta.ccid.fts"
      disabled={searchStore.running}
      filter={filterValues['meta.ccid.fts']}
      onChange={filterHandlers.onChange}
      checkedValue={true}
      notCheckedValue={false}
    />
  );
});
