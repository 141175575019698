import { IMarketingEvent } from 'types';
import { IShouldDisplayActionFunction, Pages } from 'utils/actions/types';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';
import { IMarketingEventActionName } from './types';
import { isEntityDeleted } from 'utils/is-entity-deleted';

type IShouldDisplayMarketingEventActionFunction = IShouldDisplayActionFunction<
  IMarketingEvent,
  IMarketingEventActionName
>;

export const shouldDisplayRemoveFromGroup: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.page === Pages.EVENT_SUB_EVENTS_TAB;
};

export const shouldDisplayManageAttendees: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return shouldDisplaySingleNotDeleted(action, index, user, entities, options) && !entities[0]?.subevent;
};

export const shouldDisplayEdit: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return shouldDisplaySingleNotDeleted(action, index, user, entities, options) && options.page !== Pages.DETAILS;
};

export const shouldDisplayAddEvents: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options.context === 'single' && entities?.every((event) => event?.subevent === false && !isEntityDeleted(event))
  );
};
