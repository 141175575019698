const getPathAfterContentDeletion = (oldPath: string, contentId: string | number): string => {
  if (oldPath[oldPath.length - 1] === '/') {
    oldPath = oldPath.slice(0, -1);
  }
  const paths = oldPath.split('/');
  if (paths.length > 1 && paths[paths.length - 1] === String(contentId)) {
    paths.pop();
  }
  return paths.join('/');
};

export default getPathAfterContentDeletion;
