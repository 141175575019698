import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useSortDataSectionStore, useStore } from 'store';
import { productItemRenderer } from 'components/group-items-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';
import { ToastError } from 'components/toast';

import { fetchRelatedProducts, saveOrder } from './utils';

interface IRecommendationProductsSortTabProps {
  setRecommendation: React.Dispatch<React.SetStateAction<McGenericRecommendationProduct>>;
  recommendation: McGenericRecommendationProduct;
}

export const RecommendationProductsSortTab: React.FC<IRecommendationProductsSortTabProps> = observer(
  ({ recommendation, setRecommendation }) => {
    const { initStore } = useSortDataSectionStore();
    const fetcher = useCallback(() => fetchRelatedProducts(recommendation.product_ids), [recommendation.product_ids]);
    const { toastStore } = useStore();

    const onSave = useCallback(
      async (list) => {
        try {
          if (!recommendation?.id) {
            return;
          }
          const { product_ids } = await saveOrder(
            recommendation.id,
            list.map((e) => e.id),
          );
          setRecommendation((p) => ({ ...p, product_ids }));
        } catch (e) {
          toastStore.error(<ToastError error={e} />);
        }
      },
      [recommendation.id, setRecommendation, toastStore],
    );

    useEffect(() => {
      initStore({ fetcher, onSave });
    }, [fetcher, initStore, onSave]);

    return <SortDataSection itemRenderer={productItemRenderer} />;
  },
);
