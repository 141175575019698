import { Model } from 'helpers/filters/types';
import { IPreviewImage, ItemId, IFileMeta } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { logger } from 'utils/logger';

export const createPreviewImage = async (
  asset_id?: ItemId | null,
  preview_image_id?: ItemId | null,
  storage_url?: string,
  file_size?: number,
  meta?: IFileMeta | null,
): Promise<IPreviewImage> => {
  return chipmunk.run(async ({ action }) => {
    if (!storage_url) {
      logger.error('No file or file.url provided to uploadPreviewImage');
      return;
    }

    preview_image_id && deletePreviewImage(asset_id);

    const { object } = await action<IPreviewImage>(Model.MM3_PREVIEW_IMAGE, 'create', {
      body: { storage_url, file_size, meta, ...(asset_id ? { asset_id } : {}) },
    });

    return object;
  });
};

export const fetchPreviewImages = async (ids?: ItemId | null): Promise<IPreviewImage | undefined> => {
  if (!ids) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.MM3_PREVIEW_IMAGE, 'search', {
      params: { asset_ids: ids },
    });
    return objects;
  });
};

export const deletePreviewImage = async (ids?: ItemId | ItemId[] | null): Promise<IPreviewImage | undefined> => {
  if (!ids || (Array.isArray(ids) && !ids.length)) {
    return;
  }

  return chipmunk.run(async (chipmunk) => {
    return chipmunk.action(Model.MM3_PREVIEW_IMAGE, 'delete', {
      params: {
        asset_id: ids,
      },
    });
  });
};
