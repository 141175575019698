import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { InfoBoxV2 } from 'components/info-box-v2';
import { IStyled } from 'types';

import './style.scss';
export interface IFormSectionWrapperV2 extends IStyled {
  title?: string;
  children: JSX.Element | JSX.Element[];
  gridTemplateColumns?: string | null;
  padded?: boolean;
  withBorder?: boolean;
  wrapperClassName?: string;
}
/**
 * FormSectionWrapperV2 will be replacing FormSectionWrapper and should be used in future modules/pages
 *
 */
export const FormSectionWrapperV2: React.FC<IFormSectionWrapperV2> = observer(
  ({ children, title, gridTemplateColumns, className, wrapperClassName, padded, withBorder }) => {
    return (
      <InfoBoxV2
        className={cx(`form-section-wrapper`, className)}
        title={title}
        gridTemplateColumns={gridTemplateColumns}
        padded={padded}
        withBorder={withBorder}
        wrapperClassName={wrapperClassName}
      >
        {children}
      </InfoBoxV2>
    );
  },
);

export default React.memo(FormSectionWrapperV2);
