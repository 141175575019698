import React, { useCallback } from 'react';
import { Select } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { Button } from '@mantine/core';
import ReactPaginate from 'react-paginate';

import { PaginationItem } from 'components/data-section/pagination/pagination-item';
import { ITablePaginationProps } from './types';
import { MantineIcon } from 'utils/ui';

const TablePagination: React.FC<ITablePaginationProps> = ({
  page,
  onPageChange,
  per,
  onPerChange,
  totalCount,
  totalPages,
}) => {
  const handlePageClick = useCallback(
    ({ selected }): void => {
      onPageChange(selected + 1);
    },
    [onPageChange],
  );

  const handlePerChange = useCallback(
    (newItemsPerPage) => {
      onPerChange(newItemsPerPage);
      handlePageClick({ selected: 0 });
    },
    [handlePageClick, onPerChange],
  );

  const goToPage = useCallback(
    (e) => {
      handlePageClick({ selected: e.target.tabIndex - 1 });
    },
    [handlePageClick],
  );
  const showLastPages = totalCount < 9999;

  return (
    <ul className="data-section__pagination-container">
      <PaginationItem page={page} goToPage={goToPage} tabIndex={1} label="First" />
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'data-section__pagination__dots mx-1'}
        pageCount={totalPages}
        marginPagesDisplayed={showLastPages ? 2 : 0}
        pageRangeDisplayed={5}
        forcePage={page - 1}
        onPageChange={handlePageClick}
        containerClassName={'data-section__pagination'}
        activeLinkClassName={'data-section__page-btn--active'}
        pageLinkClassName={`data-section__page-btn`}
        previousLinkClassName={`data-section__page-btn`}
        nextLinkClassName={`data-section__page-btn`}
      />
      {showLastPages && <PaginationItem page={page} goToPage={goToPage} tabIndex={totalPages} label="Last" />}
      <Select
        popoverProps={{ minimal: true }}
        matchTargetWidth
        filterable={false}
        activeItem={per}
        items={[100, 50, 25]}
        onItemSelect={handlePerChange}
        itemRenderer={(count, { handleClick, modifiers }) => (
          <MenuItem
            textClassName="items-per-page-select__item"
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={count}
            onClick={handleClick}
            text={count}
          />
        )}
      >
        <Button rightSection={<MantineIcon icon={'double-caret-vertical'} />}>{per}</Button>
      </Select>
    </ul>
  );
};

export default TablePagination;
