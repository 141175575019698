import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tree } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { useStore } from 'store';

import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import { DataSectionContentWrapper } from 'components/data-section-content-wrapper';
import { SortButton } from 'components/sort-buttons';
import GenreSortDataSection from './genre-sort-data-section';
import { CreateGenreButton } from 'components/genres/genre-form/create-genre-button';

import { byId, parseQueryParams } from 'utils/general';

import { ISortableItem } from 'store/sort-data-store';
import { ICategory, IGenreTreeItem } from 'types';

export const GenreDataSectionItems: React.FC<{
  className: string;
  isSortModeEnabled: boolean;
  setIsSortModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  itemRenderer: (item) => React.ReactNode | JSX.Element;
  title: string;
  genreTree: IGenreTreeItem[];
}> = observer(({ className, itemRenderer, isSortModeEnabled, setIsSortModeEnabled, title, genreTree }) => {
  const {
    dataSectionStore: { searchStore, setParams, reset },
  } = useStore();

  const [allowRender, setAllowRender] = useState(false);
  const location = useLocation();
  const { running, objects: genres = [] } = searchStore || {};
  const itemsCount = genres.length;
  useEffect(() => {
    reset();
    setAllowRender(true);
  }, [reset]);

  useEffect(() => {
    if (!searchStore?.parametron || !allowRender) {
      return;
    }
    const searchData = parseQueryParams(location.search);
    setParams(searchData).triggerSearch();
  }, [location, searchStore, allowRender, setParams]);

  const onSortSuccess = useCallback(
    (updatedObjects: ISortableItem[]) => {
      if (updatedObjects?.length) {
        const genresById = byId(updatedObjects);
        const updatedGenres = searchStore.objects
          .map((object: ICategory) => {
            if (!object?.id) return object;
            const newGenre = genresById[object.id];
            return { ...object, sequence_number: newGenre?.sequence_number, sequence_no: newGenre?.sequence_number };
          })
          .sort((a, b) => a.sequence_no - b.sequence_no);
        searchStore.update({
          objects: updatedGenres,
        });
      }
    },
    [searchStore],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => (
      <div className="d-flex  gap-1">
        {Boolean(itemsCount) && (
          <SortButton isSortModeEnabled={isSortModeEnabled} setIsSortModeEnabled={setIsSortModeEnabled} />
        )}
        {!isSortModeEnabled && <CreateGenreButton />}
      </div>
    ),
    [isSortModeEnabled, itemsCount, setIsSortModeEnabled],
  );

  return (
    <div className="data-section-layout">
      <div className={className}>
        <SectionHeader
          useBorder
          className="website-data-section__section-header"
          title={title}
          useBackground
          customRightRenderer={CustomRightRenderer}
        />
        {running && <Loading text="Loading Data" />}
        {!(running || genreTree.length) && <SectionMessage intent={SectionMessageType.EMPTY} />}
        {!running && Boolean(genreTree.length) && !isSortModeEnabled && (
          <div className="data-section__content-wrapper website-data-section__items-list h-100">
            <DataSectionContentWrapper className="h-100" layout="list">
              <Tree selectOnClick clearSelectionOnOutsideClick data={genreTree} renderNode={itemRenderer} />
            </DataSectionContentWrapper>
          </div>
        )}
        {isSortModeEnabled && <GenreSortDataSection genres={genreTree} onSuccess={onSortSuccess} />}
      </div>
    </div>
  );
});

export default GenreDataSectionItems;
