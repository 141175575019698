import { observable, action } from 'mobx';
import { AssetsUploaderStore } from 'store/assets-upload-store';
import { UPLOAD_ASSETS_TO_SELECTION_DROP_AREA_ID } from 'components/upload-asset-to-selection-dialog/constants';
import { ISelectionStandardized } from 'types';

export class AssetsUploadToSelectionStore extends AssetsUploaderStore {
  @observable selection: ISelectionStandardized | null = null;

  constructor() {
    super(`#${UPLOAD_ASSETS_TO_SELECTION_DROP_AREA_ID}`);
  }

  @action.bound
  setSelection(selection: ISelectionStandardized | null): void {
    this.selection = selection;
    super.cleanUp();
  }

  @action.bound
  cleanUp(): void {
    this.selection = null;
    super.cleanUp();
  }

  // this is needed in case we need to use selection isnide a for which is inside a dialog.
  // kinda hack
  @action.bound
  getCurrentSelection(): ISelectionStandardized | null {
    return this.selection;
  }
}
