import React, { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { useStore } from 'store';

import { IScrollableTab, ScrollableTabs } from 'components/scrollable-tabs/scrollable-tabs';

import { DetailsPageTabs, IProduct, IProductAncestryInfo, Product3Types, ViewMode } from 'types';

import { useEditMode, useTab, useRemote } from 'utils/hooks';
import { getProductAssets } from 'utils/apis/product-asset';

import ProductDetailsHeader from './product-details-header';
import { ProductChildrenTab } from './product-children-tab';

import { tabOptions, editableTabs, useRedirectFromEmptyChildTab, getTabsList } from './utils';
import { TabButtons } from './tab-buttons';

import './style.scss';

const productAssetSchema =
  'id, marketing_use, asset { stream_url, format, classification, file_status, id, preview_image, type, name }';

export const ProductDetailsLoaded: React.FC<{
  product: IProduct;
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  ancestry: IProductAncestryInfo;
  refreshAncestry: () => Promise<void>;
  refreshProduct: () => Promise<void>;
}> = observer(({ product, setProduct, ancestry, refreshAncestry, refreshProduct }) => {
  const { productId = '' } = useParams<{ productId: string }>();
  const { toastStore } = useStore();

  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const mode = editModeEnabled ? ViewMode.EDIT : ViewMode.PREVIEW;

  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);

  const [parentId, setParentId] = useState<number>(0);

  const getIsDisabled = useCallback(
    (tab: DetailsPageTabs): boolean => {
      if (isSortModeEnabled) return true;
      if (!editModeEnabled) return false;
      return !editableTabs.includes(tab);
    },
    [editModeEnabled, isSortModeEnabled],
  );

  useRedirectFromEmptyChildTab(activeTab, ancestry);

  const fetchProductAssets = useCallback(() => getProductAssets(productId, productAssetSchema), [productId]);
  const [productAssets, , setProductAssets] = useRemote(fetchProductAssets);

  const setMode = useCallback(
    (mode: ViewMode) => {
      if (!product?.default_layer && mode === ViewMode.EDIT) {
        return toastStore.error("This product has no layer and can't be edited.");
      }

      const isEditEnabled = mode === ViewMode.EDIT;

      if (!editableTabs.includes(activeTab)) {
        setActiveTab(editableTabs[0], true);
        setEditModeEnabled(isEditEnabled, false);
        return;
      }

      setEditModeEnabled(isEditEnabled);
    },
    [setEditModeEnabled, toastStore, product, activeTab, setActiveTab],
  );

  const changeTab = useCallback(
    (tab: DetailsPageTabs): void => {
      setParentId(0);
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  const getChildTab = useCallback(
    (type: Product3Types): JSX.Element => {
      return <ProductChildrenTab {...{ isSortModeEnabled, type, parentId, product, refreshAncestry }} />;
    },
    [isSortModeEnabled, parentId, product, refreshAncestry],
  );

  const tabsList: IScrollableTab[] = useMemo(() => {
    return getTabsList({
      ancestry,
      getChildTab,
      getIsDisabled,
      isSortModeEnabled,
      mode,
      product,
      productAssets,
      setMode,
      setProduct,
      setProductAssets,
    });
  }, [
    ancestry,
    getChildTab,
    getIsDisabled,
    isSortModeEnabled,
    mode,
    product,
    productAssets,
    setMode,
    setProduct,
    setProductAssets,
  ]);

  return (
    <>
      <ProductDetailsHeader
        product={product}
        setProduct={setProduct}
        setActiveTab={changeTab}
        ancestry={ancestry}
        mode={mode}
        setMode={setMode}
        refreshAncestry={refreshAncestry}
        refreshProduct={refreshProduct}
      />

      <div className="product-details__content-wrapper flex-full">
        <div
          className={cx(`product-details__content h-100 d-flex flex-column`, {
            'product-details__content--product-with-image': product.inherited_preview_image?.url,
          })}
        >
          <ScrollableTabs
            tabs={tabsList}
            buttons={
              <TabButtons
                isSortModeEnabled={isSortModeEnabled}
                setIsSortModeEnabled={setIsSortModeEnabled}
                parentId={parentId}
                setParentId={setParentId}
                activeTab={activeTab}
                setProduct={setProduct}
                product={product}
              />
            }
            activeTab={activeTab}
            setActiveTab={changeTab}
          />
        </div>
      </div>
    </>
  );
});
