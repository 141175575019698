import React, { useMemo } from 'react';

import { IMm3Asset, ISearchFilter } from 'types';

import { AssetsDataSection } from 'components/asset/assets-data-section';
import { Pages } from 'utils/actions';

export interface IAssetMm3SubtitlesTabProps {
  asset: IMm3Asset;
  setAsset: React.Dispatch<React.SetStateAction<IMm3Asset>>;
}

export const AssetMm3SubtitlesTab: React.FC<IAssetMm3SubtitlesTabProps> = ({ asset }) => {
  const { id } = asset || {};
  const subtitlesFilters = useMemo<ISearchFilter[]>(
    () => [
      ['parent_id', 'eq', id || 0],
      ['type', 'eq', 'Asset3::Digital::Subtitle'],
    ],
    [id],
  );

  return <AssetsDataSection persistentFilters={subtitlesFilters} page={Pages.ASSET_SUBTITLES_TAB} />;
};
