import { IShouldDisplayRecommendationActionFunction } from './types';

export const isRecommendationDraftAndSingleContext: IShouldDisplayRecommendationActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities[0]?.status === 'draft' && options.context === 'single';
};

export const isRecommendationSentAndSingleContext: IShouldDisplayRecommendationActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities[0]?.status === 'sent' && options.context === 'single';
};
