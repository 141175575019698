import { CalGenericLocation } from '@mediafellows/mm3-types';
import { IActionConfig } from 'utils/actions/types';

export enum ILocationsActionName {
  CREATE = 'create',
  DELETE = 'delete',
  EDIT = 'edit',
}

export type ILocationsActionConfig = IActionConfig<CalGenericLocation, ILocationsActionName>;
