import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

import { Routes } from 'utils/routes';
import { UserAgent, AnalyticsContact, AnalyticsOrganization, AnalyticsContext, UserTerritory } from 'utils/ui';

import DateElement from 'components/date-list-element/date-list-element';
import { IAnalyticsTableItem } from 'types/analytics';
import { formatDuration } from 'utils/date';
import { formatAssetType } from 'utils/general';

export const EventColumns: {
  title: string;
  renderer: (data: IAnalyticsTableItem) => React.ReactNode;
  visibleFromSize?: number;
}[] = [
  {
    title: 'Contact',
    renderer: ({ user }) => <AnalyticsContact user={user} />,
  },
  {
    title: 'Organization',
    renderer: ({ user, organization }) => <AnalyticsOrganization user={user} organization={organization} />,
  },
  {
    title: 'Country (IP Country)',
    renderer: ({ user, country }) => {
      return <UserTerritory countryId={user.country_id} geoCountry={country as string} />;
    },
  },
  {
    title: 'Video title',
    renderer: ({ asset }) => (
      <Tooltip label={asset?.name} position={'top'}>
        <div className="video-screenings-list-table__video-title text-truncate">
          <Link to={`${Routes.ASSETS}/${asset?.id}`}>{asset?.name || 'N/A'}</Link>
        </div>
      </Tooltip>
    ),
  },
  { title: 'Video type', renderer: ({ asset }) => formatAssetType(asset?.classification) || 'N/A' },
  { title: 'Video duration', renderer: ({ asset }) => formatDuration(asset?.duration, 'seconds') },
  { title: 'Screening duration', renderer: ({ total_duration }) => formatDuration(total_duration, 'seconds') },
  {
    title: 'Screening context',
    renderer: ({ group }) => <AnalyticsContext group={group} />,
  },
  { title: 'Device info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  {
    title: 'Screened at',
    renderer: ({ last_occured_at }) => <DateElement date={last_occured_at} />,
  },
  {
    title: 'Responsible Contact',
    renderer: ({ responsible_user: { id, full_name, email } }) => (
      <span className="video-screenings-list-table__responsible-contact">
        {id !== '0' ? (
          <Tooltip label={full_name || email} position={'top'}>
            <Link
              className="video-screenings-list-table__responsible-contact-name text-truncate"
              to={`${Routes.CONTACTS}/${id}`}
            >
              {full_name || email}
            </Link>
          </Tooltip>
        ) : (
          <span className="video-screenings-list-table__responsible-contact-name text-truncate">
            {email || 'Not assigned'}
          </span>
        )}
      </span>
    ),
    visibleFromSize: 7,
  },
];
