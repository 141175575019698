import { useCallback } from 'react';

import { useStore } from 'store';

import { IconAlias } from 'icons';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { restoreGroups } from 'utils/apis/groups';
import { shouldDisplayRestore as shouldDisplay } from 'utils/actions/common';
import { formatGroupName } from 'utils/group';
import { pluralEntityInflect } from 'utils/general';

import type { IIdentifiable } from 'types';
import { IGroupConfig, IGroupActionName } from './types';

export const useRestoreGroupAction = (
  items: IIdentifiable[],
  options: IUseActionsOptionParam,
  groupName?: string,
): IGroupConfig => {
  const { toastStore, dialogStore } = useStore();
  const groupLabel = groupName || formatGroupName(items[0]['@type']);
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect(groupLabel, items.length);

  const executeRestore = useCallback(async () => {
    try {
      await restoreGroups(items);
      options?.onSuccess?.();
      toastStore.success(`${entityWithCount} restored`);
    } catch (error) {
      toastStore.error(`${entity} restore failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm restore',
      body: `Are you sure you want to restore ${entityWithDemonstrative}?`,
      onConfirm: executeRestore,
    });
  }, [dialogStore, entityWithDemonstrative, executeRestore]);

  return {
    name: IGroupActionName.RESTORE,
    shouldDisplay,
    icon: IconAlias.ACTION_RESTORE,
    title: 'Restore',
    handler,
  };
};
