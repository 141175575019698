import React from 'react';
import classNames from 'classnames';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Menu, ActionIcon } from '@mantine/core';
import { Button } from '@blueprintjs/core';
import { ItemPredicate, IItemRendererProps, Select } from '@blueprintjs/select';

import { IPreset } from 'helpers/filters/types';
import { highlightText } from 'helpers/form/fields/helpers';
import { filtersToPreset } from 'helpers/filters/helpers';
import { useStore } from 'store';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

import './style.scss';

interface IPresetSelectProps {
  onChange: (preset: IPreset) => void;
  onDelete: (preset: IPreset) => void;
  matchingFilter?: IPreset;
}

export type ICustomItemRenderer<T> = (
  item: T,
  itemProps: IItemRendererProps,
  handleDelete: (event: React.SyntheticEvent<HTMLElement>) => void,
) => JSX.Element | null;

const getCurrentPreset = (filters, matchingPreset, selectedPreset): IPreset | null => {
  if (selectedPreset && !isEqual(selectedPreset?.filters, filtersToPreset(filters))) return null;
  else if (matchingPreset?.id) return matchingPreset;
  else return selectedPreset;
};

const itemRenderer: ICustomItemRenderer<IPreset> = (item, { handleClick, modifiers, query }, handleDelete) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <div className={`preset-select__item-renderer`} key={item.id}>
      <Menu>
        <Menu.Item onClick={handleClick} className={cx({ active: modifiers.active })} disabled={modifiers.disabled}>
          {highlightText(item.description, query)}
        </Menu.Item>
      </Menu>
      <ActionIcon
        className={`${Classes.POPOVER_DISMISS} preset-select__delete-btn`}
        data-id={item.id}
        variant="subtle"
        color="gray.5"
        onClick={handleDelete}
      >
        <MantineIcon icon={'trash'} />
      </ActionIcon>
    </div>
  );
};

const itemFilter: ItemPredicate<IPreset> = (query, item, _index, exactMatch) => {
  const normalizedLabel = item?.description?.toLowerCase() || '';
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedLabel === normalizedQuery;
  } else {
    return normalizedLabel.indexOf(normalizedQuery) >= 0;
  }
};

const FilterSelect = Select.ofType<IPreset>();

const PresetSelect: React.FC<IPresetSelectProps> = observer((props) => {
  const { dataSectionStore } = useStore();
  const { presets, filters, selectedPreset } = dataSectionStore;
  const { onChange, onDelete, matchingFilter } = props;

  const items = [...presets];

  const handleChange = (preset: IPreset): void => {
    onChange && onChange(preset);
  };

  const handleDelete = (e): void => {
    const presetId = e.currentTarget.getAttribute('data-id');
    const preset = presets.find(({ id }) => id === ~~presetId);
    onDelete(preset);
  };

  const currentPreset = getCurrentPreset(filters, matchingFilter, selectedPreset);

  return (
    <FilterSelect
      className="preset-select"
      activeItem={currentPreset}
      onItemSelect={handleChange}
      items={items}
      itemRenderer={(item, itemProps) => itemRenderer(item, itemProps, handleDelete)}
      itemPredicate={itemFilter}
      noResults={
        <Menu>
          <Menu.Item className="preset-select__no-results" disabled={true}>
            Nothing found.
          </Menu.Item>
        </Menu>
      }
      popoverProps={{ minimal: true, fill: true, popoverClassName: 'preset-select__popover' }}
    >
      <Button
        className={classNames('filter-select__btn', { empty: !currentPreset })}
        fill
        text={currentPreset?.description || 'Select Filter Preset'}
        rightIcon={<MantineIcon icon="caret-down" />}
      />
    </FilterSelect>
  );
});

export default PresetSelect;
