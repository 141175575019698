import { useMemo } from 'react';
import { fromPairs, keys } from 'lodash';

import type { ILanguageVersion, ItemId } from 'types';
import { useBasicStore } from 'store';
import { byId } from 'utils/general';

export function useLanguages(ids?: (null | undefined | ItemId)[] | null): string | undefined {
  const { languagesById } = useBasicStore();
  const validIds = ids?.filter((a): a is ItemId => Boolean(a)) || [];
  if (!validIds.length) {
    return;
  }

  // to support both languages ids formats e.g: "en-CA" and "en-ca"
  // we add small-case mappings too
  const smallCaseIds = fromPairs(
    keys(languagesById).map((languageId) => [languageId.toLowerCase(), languagesById[languageId]]),
  );
  Object.assign(languagesById, smallCaseIds);

  return validIds
    .reduce((acc, categoryId) => {
      const name = languagesById[categoryId]?.name;
      return name ? [...acc, name] : acc;
    }, [])
    .join(', ');
}

export const useLanguageVersionInfo = (versions: ILanguageVersion[] = []): string | undefined => {
  const { languages } = useBasicStore();

  return useMemo(() => {
    if (!versions?.length || !languages?.length) return;

    const languagesById = byId(languages);

    return versions
      .map((version) => {
        const { language_type, comment, language_id } = version;
        const { display_title = 'No language specified' } = languagesById[language_id] ?? {};

        return `${display_title} (${language_type.replace('_', ' ')}) ${comment || ''}`.trim();
      })
      .join(', ');
  }, [versions, languages]);
};
