import { useCallback } from 'react';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayRejectAction as shouldDisplay } from './actions-acl';
import { rejectContacts } from './api';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';
import { pluralEntityInflect } from 'utils/general';

export const useRejectContactAction = (items: IContact[], options: IUseActionsOptionParam): IContactActionConfig => {
  const { toastStore } = useStore();

  const handler = useCallback(async () => {
    const { entityWithCount, entity } = pluralEntityInflect('Contact', items.length);
    try {
      const contactIds = items.map(({ id }) => id);
      await rejectContacts(contactIds);
      toastStore.success(`${entityWithCount} rejected`);
      await options?.onSuccess?.();
    } catch (error) {
      toastStore.error(`${entity} invitation failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  return {
    name: IContactActionName.REJECT,
    shouldDisplay,
    icon: 'cross',
    title: 'Reject',
    handler,
  };
};
