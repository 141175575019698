import { IChipmunk } from '@mediafellows/chipmunk';

import { chipmunk } from 'utils/chipmunk';
import { IProduct } from 'types';
import { Model } from 'helpers/filters/types';

export function getProduct(productId: number | string): Promise<IProduct> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { object } = await action(Model.PRODUCTS, 'get', {
      params: { product_ids: [productId] },
      schema: 'id, created_at',
    });

    return object;
  });
}
