import React from 'react';

import { InfoBox } from 'components/info-box';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { IAttendees } from './types';
import { IStyled } from 'types';
import { splitStringItems } from 'utils/general';
import { EmailsBlock } from 'utils/ui';

export interface IMarketingContactListItemProps extends IStyled {
  attendees: IAttendees;
  isActiveUsersAttendanceAllowed: boolean;
}

export const EventAttendeeListItem: React.FC<IMarketingContactListItemProps> = ({
  attendees,
  isActiveUsersAttendanceAllowed,
}) => {
  const { approved_emails } = attendees;
  const emails = splitStringItems(approved_emails);
  return (
    <div className="event-detail__tab__wrapper">
      <div className="event-detail__tab__content">
        <EmailsBlock emails={emails} title={`Approved emails (${emails.length})`} />
        <InfoBox title gridTemplateColumns={'200px 1fr'}>
          <div>Settings</div>
          <Row label="Allow active contacts" value={isActiveUsersAttendanceAllowed ? 'Yes' : 'No'} />
        </InfoBox>
      </div>
    </div>
  );
};
