import { formatType } from 'utils/format-type';
import { ReportTypes } from 'types';

export const reportTypes: ReportTypes[] = [
  ReportTypes.Top10,
  ReportTypes.Top30,
  ReportTypes.Top50,
  ReportTypes.Producer,
  ReportTypes.MarketTrends,
  ReportTypes.MarketTrendsFr,
];

export const formatProducerType = (type: ReportTypes): string => {
  switch (type) {
    case ReportTypes.Producer:
      return 'Producer Report';
    default:
      return formatType(type);
  }
};
