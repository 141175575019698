import { chipmunk, IResult } from 'utils/chipmunk';
import { queryContacts } from 'utils/apis/contacts';
import { GroupTypes, IContact } from 'types';

import {
  ICreateSelectionParams,
  IAddItemsToSelectionParams,
  IAddProductsToSelectionParams,
  IProductParams,
  IItemParams,
} from './types';
import { Model } from 'helpers/filters/types';

export const createSelection = async (params: ICreateSelectionParams): Promise<IResult> => {
  return await chipmunk.run(async ({ action }) => {
    const { main_entity_type, access_level, name } = params;
    return action(Model.GROUPS, 'create', {
      body: {
        type: GroupTypes.SELECTION,
        main_entity_type,
        access_level,
        name,
      },
    });
  });
};

export const addAssetsToSelection = ({ group_id, item_ids }: IAddItemsToSelectionParams): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const items = item_ids.map((asset_id) => ({
      asset_id,
      access_level: 'viewable',
      permissions: ['download'],
    }));

    return action('am.group/item/asset', 'create', {
      params: { group_id },
      body: { items },
    });
  });
};

export const addProductsToSelection = (params: IAddProductsToSelectionParams): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const { group_id, item_ids, include_descendants } = params;

    const items = item_ids.map((product_id) => ({
      product_id,
      include_descendants,
      access_level: 'viewable',
      permissions: ['download'],
      type: 'group/item/product',
    }));

    return action('pm.group/item', 'create', {
      params: { group_id },
      body: { items },
    });
  });
};

export const addContactsToSelection = ({ group_id, item_ids }: IAddItemsToSelectionParams): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    return action(Model.GROUPS, 'add_users', {
      params: { group_id, user_ids: item_ids },
    });
  });
};

export const createAssetSelectionWithItems = async (
  createSelectionParams: ICreateSelectionParams,
  { item_ids }: IItemParams,
): Promise<IResult> => {
  const { object: group } = await createSelection(createSelectionParams);
  return addAssetsToSelection({ group_id: group.id, item_ids });
};

export const createProductSelectionWithItems = async (
  createSelectionParams: ICreateSelectionParams,
  { item_ids, include_descendants }: IProductParams,
): Promise<IResult> => {
  const { object: group } = await createSelection(createSelectionParams);

  return addProductsToSelection({
    group_id: group.id,
    item_ids,
    include_descendants,
  });
};

export const createContactSelectionWithItems = async (
  params: ICreateSelectionParams,
  { item_ids }: IItemParams,
): Promise<IResult> => {
  const { object: group } = await createSelection(params);
  return addContactsToSelection({ group_id: group.id, item_ids });
};

export const onQueryContacts = async (params: { ids?: string[]; q?: string }): Promise<IContact[]> => {
  return queryContacts(params);
};
