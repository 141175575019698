import { AmGenericNews } from '@mediafellows/mm3-types';

import { useUploadImageDialog } from 'components/upload-preview-image';

import { INewsActionConfig, INewsActionName } from './types';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { uploadPreviewImage } from './apis';
import { delay } from 'utils/delay';

export const useUploadPreviewAction = (items: AmGenericNews[], options: IUseActionsOptionParam): INewsActionConfig => {
  const item = items?.[0];
  const [openUploadImageDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      if (file && item?.id) {
        const news = await uploadPreviewImage(file, item.id);
        await delay();
        options?.onSuccess?.(INewsActionName.UPLOAD_PREVIEW, news);
      }
    },
    customMessage: 'Select a preview image to upload',
  });

  return {
    name: INewsActionName.UPLOAD_PREVIEW,
    shouldDisplay: () => options.context === 'single',
    icon: 'upload',
    title: 'Upload preview',
    handler: openUploadImageDialog,
  };
};
