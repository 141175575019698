import React from 'react';
import { Group } from '@mantine/core';

export interface IControlGroupProps {
  fill?: boolean;
  vertical?: boolean;
  className?: string;
  gap?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: string;
}

export const ControlGroup: React.FC<IControlGroupProps> = ({
  children,
  className = '',
  fill = false,
  vertical = false,
  alignItems = 'center',
  gap = 'md',
}) => {
  return (
    <Group
      align={alignItems}
      gap={gap}
      grow={fill}
      className={className}
      style={{
        flexDirection: vertical ? 'column' : 'row',
      }}
    >
      {children}
    </Group>
  );
};
