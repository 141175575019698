import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from 'store';
import { Tabs } from '@mantine/core';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import { IContact, DetailsPageTabs } from 'types';
import { useEditMode, useTab } from 'utils/hooks';
import { IUseFormReturn } from 'helpers/form';
import { DataSectionControls } from 'components/data-section-controls';
import { ContactAnalyticsTab } from './contact-analytics-tab';
import { ContactTimelineTab } from './contact-timeline-tab';
import { ContactBasketsTab } from './contact-baskets-tab';
import { ContactAccessTab } from './contact-access-tab';
import { ContactActivityTab } from './contact-activity-tab';
import ContactDetailsHeader from './contact-details-header';
import { ContactOverviewTab, ContactOverviewEditTab } from './contact-overview-tab';
import {
  ContactDetailTab,
  ContactTabPanel,
  editableTabs,
  initialContactForm,
  tabOptions,
  useRefreshContact,
  useRefreshPreviewForImageIngest,
} from './utils';
import { flags } from 'utils/flags';

import './style.scss';

const ContactDetails: React.FC = observer(() => {
  const { contactId = '' } = useParams<{ contactId: string }>();
  const { toastStore } = useStore();
  const [loading, refresh, contact, setContact] = useRefreshContact(contactId, toastStore);

  useRefreshPreviewForImageIngest(contactId, setContact, contact);

  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);
  const [contactForm, setContactForm] = useState<IUseFormReturn<IContact>>(initialContactForm);

  const enableEditMode = (isEnabled: boolean): void => {
    if (!editableTabs.includes(activeTab)) {
      setActiveTab(editableTabs[0], true);
      setEditModeEnabled(isEnabled, false);
      return;
    }
    setEditModeEnabled(isEnabled);
  };

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleTabChange = useCallback(
    (tab): void => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  const overviewTab = useMemo(() => {
    if (!contact) {
      return <></>;
    }
    return editModeEnabled ? (
      <ContactOverviewEditTab
        contact={contact}
        onDataChange={setContactForm}
        refresh={refresh}
        setEditModeEnabled={setEditModeEnabled}
      />
    ) : (
      <ContactOverviewTab contact={contact} refresh={refresh} />
    );
  }, [contact, editModeEnabled, refresh, setEditModeEnabled]);

  return (
    <div className="d-flex flex-column h-100 w-100">
      <DashboardBreadcrumbs className="mb-3" />
      {loading && <Loading text="Loading Contact" />}
      {!loading && contact?.id && (
        <>
          <ContactDetailsHeader
            contact={contact}
            refresh={refresh}
            contactForm={contactForm}
            editModeEnabled={editModeEnabled}
            setEditModeEnabled={enableEditMode}
          />
          <div className="contact-detail">
            <div className="contact-detail__content-wrapper flex-fill">
              <Tabs className="contact-detail__tabs h-100" value={activeTab} onChange={handleTabChange}>
                <Tabs.List className="d-flex flex-nowrap">
                  <ContactDetailTab
                    value={DetailsPageTabs.OVERVIEW}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                  />
                  <ContactDetailTab
                    value={DetailsPageTabs.ANALYTICS}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                  />
                  <ContactDetailTab
                    value={DetailsPageTabs.LISTS}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                    hidden={!flags.showBasketsFeature}
                  />
                  <ContactDetailTab
                    value={DetailsPageTabs.TIMELINE}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                    hidden={!flags.showBasketsFeature}
                  />

                  <ContactDetailTab
                    value={DetailsPageTabs.ACTIVITY}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                    hidden={!flags.showBasketsFeature}
                  />
                  <ContactDetailTab
                    value={DetailsPageTabs.ACCESS}
                    activeTab={activeTab}
                    editModeEnabled={editModeEnabled}
                    hidden={!flags.showBasketsFeature}
                  />

                  <DataSectionControls
                    className="ms-auto me-4 align-self-center"
                    hidden={activeTab !== DetailsPageTabs.ACCESS}
                  />
                </Tabs.List>
                <ContactTabPanel value={DetailsPageTabs.OVERVIEW} content={overviewTab} />
                <ContactTabPanel
                  value={DetailsPageTabs.ANALYTICS}
                  content={<ContactAnalyticsTab contact={contact} />}
                />
                <ContactTabPanel value={DetailsPageTabs.LISTS} content={<ContactBasketsTab />} />
                <ContactTabPanel value={DetailsPageTabs.TIMELINE} content={<ContactTimelineTab />} />
                <ContactTabPanel value={DetailsPageTabs.ACTIVITY} content={<ContactActivityTab />} />
                <ContactTabPanel value={DetailsPageTabs.ACCESS} content={<ContactAccessTab contact={contact} />} />
              </Tabs>
            </div>
          </div>
        </>
      )}
      {!contact && !loading && <SectionMessage intent={SectionMessageType.EMPTY} />}
    </div>
  );
});

export default ContactDetails;
