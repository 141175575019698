import React, { useCallback } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { IMarketingEvent } from 'types';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { EventDataSectionListItem } from './event-data-section-list-item';
import { isEntityDeleted } from 'utils/is-entity-deleted';

interface IEventDataSectionItemProps {
  className?: string;
  event: IMarketingEvent;
  contextMenu?: React.ReactElement;
}

export const customEventParam = {
  include_deleted: false,
};

export const EventDataSectionItem: React.FC<IEventDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, event } = props;
  const { id } = event;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(event),
      })}
      onClick={handleClick}
    >
      <EventDataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        event={event}
        isChecked={isChecked}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
