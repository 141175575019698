import React, { useState } from 'react';
import { Tabs } from '@mantine/core';
import { Time } from 'blueprint5-icons';

import { ACCESS_TABS, IProduct } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';

import { AccessChangeImmediateForm } from './access-change-immediate-form';

import { AccessChangeScheduledAccessForm } from './scheduled';
import { getShowPermissions } from 'utils/flags';
import { MantineIcon } from 'utils/ui/icon';

export const ProductAccessChangeForm: React.FC<{ items: IProduct[]; options: IUseActionsOptionParam }> = ({
  items,
  options,
}) => {
  const [selectedTab, setTab] = useState<ACCESS_TABS>(ACCESS_TABS.IMMEDIATE);
  const showPermissions = getShowPermissions(options?.page);

  const handleChange = (value: string | null): void => {
    if (!value) return;
    setTab(value as ACCESS_TABS);
  };
  return (
    <Tabs onChange={handleChange} value={selectedTab} className="access-change__tabs">
      <Tabs.List grow>
        <Tabs.Tab id={ACCESS_TABS.IMMEDIATE} value={ACCESS_TABS.IMMEDIATE}>
          Immediate
        </Tabs.Tab>
        <Tabs.Tab
          id={ACCESS_TABS.SCHEDULED_ACCESS}
          value={ACCESS_TABS.SCHEDULED_ACCESS}
          leftSection={<MantineIcon icon={<Time />} size={14} className="scheduled-tab-title_icon" />}
        >
          Future
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={ACCESS_TABS.IMMEDIATE}>
        <AccessChangeImmediateForm items={items} options={options} showPermissions={showPermissions} />
      </Tabs.Panel>
      <Tabs.Panel value={ACCESS_TABS.SCHEDULED_ACCESS}>
        <AccessChangeScheduledAccessForm items={items} showPermissions={showPermissions} />
      </Tabs.Panel>
    </Tabs>
  );
};
