import { localTimezone } from 'components/scheduler/utils';
import React from 'react';

export const formatFormLabel = (label = '', required?: boolean): string => {
  if (!label) {
    return '';
  }
  return `${label}${required ? '*' : ''}`;
};

function escapeRegExpChars(text: string): string {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export function highlightText(text: string, query: string): React.ReactNode {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}
export const formatTimeZone = (timezone?: string | null): string | null | undefined => {
  return timezone === 'local' ? localTimezone : timezone;
};

export function changeTimeZone(date: Date | string, timezone?: string | null): Date {
  if (!timezone) {
    return new Date(date);
  }
  const timeZone = timezone === 'local' ? localTimezone : timezone;

  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
}

export const getFieldPlaceholder = ({
  placeholder,
  disabled,
  defaultPlaceholder,
}: {
  placeholder?: string;
  disabled?: boolean;
  defaultPlaceholder: string;
}): string => {
  if (disabled) return '—';
  return placeholder || defaultPlaceholder;
};
