import React from 'react';

import { EventDataSection } from 'components/event-data-section';
import { DataSectionPage } from 'components/layout';
import { EqFilter } from 'helpers/filters/types';
import { Pages } from 'utils/actions/types';

const initFilters = { subevent: new EqFilter('subevent', false) };

export const Events: React.FC = () => {
  return (
    <DataSectionPage>
      <EventDataSection persistFilters initFilters={initFilters} page={Pages.LIST} />
    </DataSectionPage>
  );
};
