import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';
import { ILanguage, ISubtitle } from 'types';
import { getLanguageById } from 'utils/general';

export const parseSubtitles = (type: string, languages: ILanguage[], subtitles?: ISubtitle[]): string => {
  return (
    subtitles
      ?.filter(({ purpose }) => purpose === type)
      .map(({ language_id }) => getLanguageById(languages, language_id))
      .join(', ') || ''
  );
};

export const parseMm3Subtitles = (
  type: string,
  languages: ILanguage[],
  subtitles?: AmGenericAsset3DigitalSubtitle[],
): string => {
  return (
    subtitles
      ?.reduce((acc: string[], { meta }) => {
        if (meta?.purpose === type && meta?.language_id) {
          acc.push(getLanguageById(languages, meta.language_id));
        }
        return acc;
      }, [])
      .join(', ') || ''
  );
};
