import React from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormInput, FormSelect } from 'helpers/form';
import { IMobileAppSyncFormComponent } from './types';

export const keysGeneral = ['name', 'access_level'];

export const MobileAppSyncFormGeneral: React.FC<IMobileAppSyncFormComponent> = ({ form }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <FormSelect name="access_level" label="Access Level" large {...form.formData.access_level} {...form.handlers} />
    </FormSectionWrapperV2>
  );
};
