import { defaultVariantColorsResolver, VariantColorsResolver, rem, darken, lighten } from '@mantine/core';

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  // Completely override variant
  if (input.variant === 'outline') {
    return {
      background: 'transparent',
      hover: 'rgba(138,155,168,0.15)',
      border: `${rem(1)} solid ${darken(input.theme.white, 0.4)}`,
      color: input.theme.white,
    };
  }

  if (input.variant === 'default') {
    return {
      background: 'var(--mfx-gray-5)',
      hover: 'color-mix(in srgb, var(--mfx-gray-5), var(--mfx-gray-7) 50%)',
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  if (input.variant === 'subtle') {
    return {
      background: 'none',
      hover: 'var(--mfx-gray-5)',
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  // Add new variants support
  if (input.variant === 'primary') {
    return {
      background: 'var(--mfx-main)',
      hover: `${lighten('var(--mfx-main)', 0.2)}`,
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  if (input.variant === 'success') {
    return {
      background: '#3dcc91',
      hover: `${lighten('#3dcc91', 0.2)}`,
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  if (input.variant === 'danger') {
    return {
      background: '#f55656',
      hover: `${lighten('#f55656', 0.2)}`,
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  return defaultResolvedColors;
};
