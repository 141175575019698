import React from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@mantine/core';
import cx from 'classnames';

import { LoaderSize } from 'utils/ui/loader-size';

import './style.scss';

export interface ILoadingProps {
  className?: string;
  text?: string;
  isLoadMore?: true;
}

const Loading: React.FC<ILoadingProps> = observer(({ text, isLoadMore, className }) => {
  return (
    <div className={cx('loading h-100 w-100', className)}>
      <div
        className={cx('loading__content', {
          'loading__content--load-more': isLoadMore,
        })}
      >
        <Loader size={LoaderSize.SMALL} className="me-2" />
        <div className="loading__text">{text}</div>
      </div>
    </div>
  );
});

export default Loading;
