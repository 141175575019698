import React, { useCallback } from 'react';
import { ColorInput, ColorInputProps } from '@mantine/core';

import cx from 'classnames';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { getFieldPlaceholder } from 'helpers/form/fields/helpers';

export interface IFormColorPicker
  extends Omit<ColorInputProps, 'value' | 'onChange' | 'onBlur'>,
    IFieldData<string | null | undefined>,
    IFieldHandlers<string | null | undefined> {
  name: string;
  type?: string;
  label?: string;
  showPositiveValidation?: boolean;
  formId?: string;
  inline?: boolean;
}

const FormColorPicker: React.FC<IFormColorPicker> = (props) => {
  const {
    formId = 'id',
    showPositiveValidation,
    label,
    name,
    disabled,
    placeholder = 'EMPTY',
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    inline = false,
    className,
    size = 'md',
    format = 'rgba',
  } = props;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = Boolean(showPositiveValidation && touched && validation?.valid);

  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Pick ${label}` });

  const handleChange = useCallback(
    (color: string) => {
      onChange?.({ [name]: color });
    },
    [name, onChange],
  );

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const safeValue = value || undefined;
  const errorMsg = showValid || showError ? validation?.errorMessage : undefined;
  return (
    <ColorInput
      id={`${formId}-${name}`}
      name={name}
      label={label}
      withAsterisk={required}
      placeholder={formattedPlaceholder}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      value={safeValue}
      className={cx({ 'form-input-inline__container': inline }, className)}
      error={errorMsg}
      size={size}
      fixOnBlur={false}
      format={format}
    />
  );
};

export default FormColorPicker;
