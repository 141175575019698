import React from 'react';
import cx from 'classnames';

import { ITabValue, Tab, TabPanel } from 'components/tabs';

import { DetailsPageTabs } from 'types';

export const AffiliationDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  valueParser?: (value?: ITabValue) => string;
  disabled?: boolean;
}> = ({ value, activeTab, valueParser, disabled = false }) => {
  return (
    <Tab
      value={value}
      className={cx(`affiliation-details__tab `, {
        'affiliation-details__tab--active': activeTab === value,
      })}
      valueParser={valueParser}
      disabled={disabled}
    />
  );
};

export const AffiliationTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="affiliation-details__tab-content h-100" content={content} />;
};
