import pick from 'lodash/pick';

import { IConference, IBuyerDomain } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { conferenceListSchema } from 'utils/schemas/conference';
import { Model } from 'helpers/filters/types';
import { initialValues } from 'utils/conference';

export const getBuyerDomain = (domain_id: number): Promise<IBuyerDomain> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.BUYER_DOMAINS, 'member.get', {
      params: {
        domain_id,
      },
    });

    return object;
  });
};

export const deleteConference = (codes: string | string[]): Promise<IConference> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'delete', {
      params: {
        codes,
      },
    });

    return object;
  });
};

export const createConference = (body: Partial<IConference>): Promise<IConference> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'create', {
      body,
    });

    return object;
  });
};

export const getConference = async (code: string): Promise<IConference | undefined> => {
  if (!code) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'get', {
      params: { code },
      schema: conferenceListSchema,
    });

    return object;
  });
};

export const resetConference = async (code: string): Promise<IConference | undefined> => {
  if (!code) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'member.reset', {
      params: { code },
    });

    return object;
  });
};

export const copyConference = ({ title = '', ...conference }: IConference): Promise<IConference> => {
  const keys = Object.keys(initialValues);
  const body = pick(conference, keys);

  return createConference({ ...body, title: `${title} (Copy)` });
};

export const updateConference = (conference: Partial<IConference>): Promise<IConference> => {
  const { code } = conference;
  const { created_at, updated_at, ...body } = conference;

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'member.update', {
      params: { code },
      body,
      schema: conferenceListSchema,
    });

    return object;
  });
};

export const startConference = (code: string, owner_id: number): Promise<IConference> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONFERENCE, 'start', {
      params: { code },
      body: {
        external_user_id: owner_id.toString(),
      },
    });

    return object;
  });
};
