import React from 'react';
import { IFiltersHandlers } from 'helpers/filters';
import { ICollectionDataSectionFilters } from 'components/collection/collection-data-section-filters';

export const additionalFilters = {};

interface IAdditionalFiltersProps {
  filterValues: ICollectionDataSectionFilters;
  filterHandlers: IFiltersHandlers<ICollectionDataSectionFilters>;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;
