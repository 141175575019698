import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useRemote } from 'utils/hooks';
import { IConference } from 'types';
import { getConference } from 'utils/apis/conference';
import { EmptySectionMessage } from 'components/section-message/section-message';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';

import { ConferenceDetailsLoaded } from './conference-details-loaded';

import './style.scss';

const ConferenceDetails: React.FC<{}> = observer(() => {
  const { code } = useParams<{ code: string }>();

  const fetchConference = useCallback(async () => (code ? getConference(code) : undefined), [code]);
  const [conference, loading, setConference] = useRemote<IConference>(fetchConference);
  return (
    <div
      className="d-flex flex-column background-wrapper w-100 h-100"
      style={{ backgroundImage: `url(${conference?.background_media})` }}
    >
      <div className="background-wrapper__index w-100 h-100">
        <DashboardBreadcrumbs className="mb-3" />
        {loading && <Loading text="Loading Conference Details" />}
        {!loading && conference && <ConferenceDetailsLoaded setConference={setConference} conference={conference} />}
        {!loading && !conference && <EmptySectionMessage />}
      </div>
    </div>
  );
});

export default ConferenceDetails;
