import React, { useCallback } from 'react';

import { useAssetsUploadToSelectionStore, useStore } from 'store';
import { ISelectionStandardized, IUploadedFile } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { UploadAssetsForm } from 'components/upload-asset-to-selection-dialog/components/upload-assets-form';
import { ToastError } from 'components/toast';
import { byId, pluralEntityInflect } from 'utils/general';
import { createAssets } from 'pages/assets-upload/api';
import { addAssetToGroup } from 'utils/apis/groups';
import { map } from 'lodash';
import { ISelectionActionName } from 'utils/actions/selection/types';

export type IUseUploadAssetToSelectionDialogCallback = (newSelection?: ISelectionStandardized) => void;

export const useUploadAssetToSelectionDialog = (
  options?: IUseActionsOptionParam,
): [IUseUploadAssetToSelectionDialogCallback] => {
  const { dialogStore, toastStore } = useStore();
  const { getCurrentSelection, selection, isUploadPending, setSelection, isSending, setIsSending } =
    useAssetsUploadToSelectionStore();

  const onSubmit = useCallback(
    async ({ isValid, files, filesRemote, cleanUp, showErrors }) => {
      const selection = getCurrentSelection();
      const { entityWithCount } = pluralEntityInflect('asset', files.length);

      try {
        if (!selection) {
          toastStore.error(
            <ToastError error={{ title: 'Upload assets to selection error', description: 'Selection is not given' }} />,
          );
        }
        if (isSending) {
          return;
        }
        if (!isValid) {
          return showErrors();
        }
        setIsSending(true);
        const filesRemoteById = byId<IUploadedFile>(filesRemote);
        const assets = await createAssets(files, filesRemoteById);
        await addAssetToGroup({ group_id: selection?.id, item_ids: map(assets, 'id') });
        toastStore.success(`${entityWithCount} uploaded to the selection`);
        cleanUp();
        options?.onSuccess?.(ISelectionActionName.UPLOAD_ASSET_TO_SELECTION);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      } finally {
        setIsSending(false);
      }
    },

    [getCurrentSelection, isSending, setIsSending, toastStore, options, dialogStore],
  );

  const openDialog = useCallback(() => {
    dialogStore.openModal({
      title: `Upload Asset(s) to Selection`,
      className: 'upload-asset-to-selection-dialog__dialog',
      body: () => <UploadAssetsForm onSubmit={onSubmit} />,
    });
  }, [dialogStore, onSubmit]);

  const shouldOpenUploadToSelectionDialog = useCallback(
    (newSelection?: ISelectionStandardized) => {
      const shouldOpenNewDialog = Boolean(!isUploadPending && newSelection);
      const shouldOpenNewDialogAfterConfirmation = newSelection && isUploadPending && newSelection.id !== selection?.id;
      if (shouldOpenNewDialog) {
        setSelection(newSelection || null);
        openDialog();
      } else if (shouldOpenNewDialogAfterConfirmation) {
        return dialogStore.openConfirm({
          title: `Upload Asset(s) to Selection`,
          body: `Previous upload to selection isn't finished. Are you sure to open new one anyway and cancel the previous?`,
          onConfirm: () => {
            setSelection(newSelection);
            openDialog();
          },
        });
      } else if (isUploadPending) {
        openDialog();
      } else {
        toastStore.error(
          <ToastError
            error={{ title: 'Upload assets to selection error', description: 'Cannot open asset upload dialog' }}
          />,
        );
      }
    },
    [dialogStore, isUploadPending, openDialog, selection, setSelection, toastStore],
  );

  return [shouldOpenUploadToSelectionDialog];
};
