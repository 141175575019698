import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  FormCheckbox,
  FormInput,
  FormSelect,
  IUseFormReturn,
  FormDateRange,
  useDateRangeSplit,
  FormRemoteSelect,
} from 'helpers/form';
import { IConference, IContact, ISearchFilter, ItemId } from 'types';
import { LabelValuePair } from 'components/label-value-pair';
import { ClipboardView } from 'components/clipboard-view';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { useBasicStore } from 'store/hooks';
import { parseConferenceUrl } from 'utils/conference';
import { formatDate } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { queryContacts } from 'utils/apis/contacts';
import { useSessionStore } from 'store/session-store';

const codeOptions = [
  { value: 'conference', label: 'Conference' },
  { value: 'panel', label: 'Panel' },
  { value: 'webinar', label: 'Webinar' },
];

interface IConferenceSettingsSectionProps {
  conferenceForm: IUseFormReturn<Partial<IConference>>;
  className?: string;
  title?: string;
  wrapperClassName?: string;
  padded?: boolean;
}

const fetchModerators = async (query: string, id: ItemId): Promise<IContact[]> => {
  const params: { q?: string; ids?: ItemId[] } = { q: query };
  if (id) {
    params.ids = [id];
  }
  const userOrgId = useSessionStore.getState().user?.organization_id;
  const additionalParams: ISearchFilter[] = userOrgId
    ? [
        ['status', 'eq', 'active'],
        ['organization_id', 'eq', userOrgId],
      ]
    : [['status', 'eq', 'active']];
  return queryContacts(params, additionalParams);
};

export const ConferenceSettingsSection: React.FC<IConferenceSettingsSectionProps> = observer(
  ({ conferenceForm, className = 'scroll-wrapper--height', title, wrapperClassName, padded }) => {
    const { buyerDomain } = useBasicStore();
    const { handlers, formData, values } = conferenceForm;
    const { code, id } = values;
    const [conferenceTimeData, conferenceTimeHandlers] = useDateRangeSplit(
      'conference_time',
      'starts_at',
      'ends_at',
      conferenceForm,
    );

    const url = parseConferenceUrl(buyerDomain, code);

    return (
      <FormSectionWrapperV2 className={className} wrapperClassName={wrapperClassName} title={title} padded={padded}>
        <FormSelect label="Conference type" name="mode" large {...handlers} {...formData.mode} options={codeOptions} />
        <FormInput label="Title" name="title" {...handlers} {...formData.title} />
        <FormRemoteSelect
          label="Moderator"
          name="owner_id"
          fetchOptions={fetchModerators}
          {...formData.owner_id}
          {...handlers}
          placeholder="Select Moderator"
          large
          required
        />
        <FormDateRange
          label="Conference Time"
          name="conference_time"
          large
          wide
          showShortcuts={false}
          withTime
          {...conferenceTimeData}
          {...conferenceTimeHandlers}
        />
        <FormCheckbox
          label="Disable waiting room?"
          name="disable_waitingroom"
          large
          {...handlers}
          {...formData.disable_waitingroom}
        />
        <>
          {Boolean(id) && (
            <InfoBoxV2 padded={false} gridTemplateColumns="1fr 3fr">
              <LabelValuePair label="Created at" value={formatDate(values.created_at)} rawLabel />
              <LabelValuePair label="Updated at" value={formatDate(values.updated_at)} rawLabel />
              <LabelValuePair label="Status" value={values.status} />
              <LabelValuePair label="URL" rawValue value={<ClipboardView value={url} />} />
            </InfoBoxV2>
          )}
        </>
      </FormSectionWrapperV2>
    );
  },
);
