import { IMarketingEvent } from 'types';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions';

import { useEventShowcase } from 'components/showcase';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';

import { IMarketingEventActionName } from './types';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';

export const useManageHostsShowcase = (
  entities: IMarketingEvent[],
  options: IUseActionsOptionParam,
): IActionConfig<IMarketingEvent, IMarketingEventActionName> => {
  const onClick = useEventShowcase(entities, options, `event_${entities[0].id}_team`, ['user']);
  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: IMarketingEventActionName.MANAGE_HOSTS,
    shouldDisplay: shouldDisplaySingleNotDeleted,
    icon: 'people',
    title: 'Manage Hosts',
    handler,
    isDisabled: disabled,
  };
};
