import React from 'react';
import cx from 'classnames';
import { Flex } from '@mantine/core';

import { DataSectionRefreshButton } from 'components/data-section-refresh-button';
import { DataSectionToggleButton } from 'components/data-section-toggle-button';

interface IDataSectionsControlsProps {
  customRefresh?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  hideToggleButton?: boolean;
  className?: string;
}
const DataSectionControls: React.FC<IDataSectionsControlsProps> = ({
  customRefresh,
  disabled = false,
  hidden,
  hideToggleButton,
  className,
}) =>
  hidden ? (
    <></>
  ) : (
    <Flex gap="xs" className={cx(className)}>
      <DataSectionRefreshButton customRefresh={customRefresh} disabled={disabled} />
      {!hideToggleButton && <DataSectionToggleButton disabled={disabled} />}
    </Flex>
  );

export default DataSectionControls;
