import { ISubtitle } from 'types';
import { IActionConfig } from 'utils/actions/types';

export enum ISubtitleActionName {
  DELETE = 'delete',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  REPLACE = 'replace',
}

export type ISubtitleActionConfig = IActionConfig<ISubtitle, ISubtitleActionName>;
