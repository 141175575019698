import { useCallback } from 'react';
import { DateRange } from '@blueprintjs/datetime';

import { IUseFormReturn, IFieldData, IFormHandlers } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

interface IUseDateRangeSplitParams {
  startKeyContext?: boolean;
}

export const useDateRangeSplit = <T extends object>(
  name: string,
  startKey: keyof T,
  endKey: keyof T,
  form: Pick<IUseFormReturn<T> | IUseMm3FormReturn<T>, 'values' | 'formData' | 'handlers'>,
  params?: IUseDateRangeSplitParams,
): [IFieldData<DateRange>, Omit<IFormHandlers<T & { [key: string]: DateRange }>, 'onSetFields'>] => {
  const { startKeyContext = true } = params || {};

  const start = form.values[startKey] as unknown as null | string;
  const end = form.values[endKey] as unknown as null | string;

  const range: DateRange = [start ? new Date(start) : null, end ? new Date(end) : null];

  const fieldData = {
    ...form.formData[startKeyContext ? startKey : endKey],
    value: range,
  };

  const onChange = useCallback(
    (newData) => {
      const values: Partial<T> = {};
      const start = newData[name][0];
      const end = newData[name][1];

      values[startKey] = start?.toISOString() || null;
      values[endKey] = end?.toISOString() || null;

      form.handlers.onChange(values);
    },
    [name, startKey, endKey, form.handlers],
  );

  return [fieldData, { ...form.handlers, onChange }];
};
