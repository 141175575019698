import { IConference } from 'types';
import { IActionConfig } from 'utils/actions/types';

export enum IConferenceActionName {
  COPY = 'copy',
  DELETE = 'delete',
  START = 'start',
  RESET = 'reset',
  ADD_ASSETS = 'add-assets',
  ADD_CONTACTS = 'add-contacts',
}

export type IConferenceActionConfig = IActionConfig<IConference, IConferenceActionName>;
