import React, { useCallback } from 'react';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';
import { addMonths } from 'date-fns';

import { IAsset, IMm3Asset, ItemId } from 'types';
import { useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { formatDate, ISODateFormat, today } from 'utils/date';
import { ToastError } from 'components/toast';
import type { ICreateWorkFlowPayload } from 'utils/actions/types';

import { initialValues } from 'components/recommendation/recommend-assets/initial-values';
import { RecommendAssetsWizard } from './recommendation-wizzard';
import { customValidator } from './utils';
import { createGenericRecommendationDraft } from 'components/recommendation/recommendation-shared/ui';

const defaultExpires = addMonths(today, 1);

interface IRecommendProductsWizardContainer {
  assets?: (IAsset | IMm3Asset)[];
  onFinish: (recommendation: McGenericRecommendationAsset) => void;
  groupId?: ItemId;
  recommendation?: Partial<ICreateWorkFlowPayload<McGenericRecommendationAsset>>;
  tab?: number;
}

export const RecommendAssetsWizardContainer: React.FC<IRecommendProductsWizardContainer> = ({
  assets,
  onFinish,
  tab,
  recommendation,
}) => {
  const { toastStore } = useStore();

  const handleConfirm = useCallback(
    async (values: ICreateWorkFlowPayload<McGenericRecommendationAsset>): Promise<void> => {
      try {
        if (!values) {
          return;
        }
        const recommendation = await createGenericRecommendationDraft<McGenericRecommendationAsset>(values);
        toastStore.success('Recommendation draft was successfully saved!');
        onFinish(recommendation);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore, onFinish],
  );

  const form = useMm3Form<ICreateWorkFlowPayload<McGenericRecommendationAsset>>(
    {
      ...initialValues,
      type: 'Recommendation::Asset',
      ...recommendation,
      cc_recipients: recommendation?.cc_list || [],
      bcc_recipients: recommendation?.bcc_list || [],
      expires_at: formatDate(recommendation?.expires_at || defaultExpires, ISODateFormat),
      message: recommendation?.message || '',
      allows_asset_download: Boolean(recommendation?.allows_asset_download),
      requires_login: recommendation ? Boolean(recommendation?.requires_login) : initialValues.requires_login,
      asset_ids: recommendation?.asset_ids || (assets || []).map((e) => e.id),
      recipients: recommendation?.recipient_list?.map((e) => e.recipient_id) || recommendation?.recipients || [],
      assetList: recommendation?.assetList || recommendation?.asset_ids || (assets || []).map((e) => e.id),
      visible: recommendation ? Boolean(recommendation?.visible) : initialValues.visible,
    } as ICreateWorkFlowPayload<McGenericRecommendationAsset>,
    Model.RECOMMENDATIONS_ASSET,
    handleConfirm,
    customValidator,
  );

  return <RecommendAssetsWizard form={form} tab={tab} onFinish={onFinish} />;
};
