import { IResult } from '@mediafellows/chipmunk';
import { IMobileAppSyncFormData } from 'components/mobile-app-sync-wizard/types';
import { Model } from 'helpers/filters/types';
import { GroupTypes } from 'types';
import { IMobileSyncActionForm } from 'utils/actions/mobile-app-sync/types';
import { chipmunk } from 'utils/chipmunk';
import { addAssetToGroup, addProductToGroup } from './groups';

export const addItemsToMobileAppSync = async (values: IMobileSyncActionForm): Promise<void> => {
  const { id, product_ids, include_descendants, asset_ids } = values;
  await addProductToGroup({
    group_id: id,
    item_ids: product_ids,
    include_descendants,
    delegates_access: false,
    permissions: ['download'],
  });

  await addAssetToGroup({
    group_id: id,
    item_ids: asset_ids,
  });
};

export const createMobileAppSync = async (values: IMobileAppSyncFormData): Promise<IResult> => {
  const { name, access_level, app_market_allocation_type } = values;
  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.MOBILE_APP_SYNC, 'create', {
      body: {
        type: GroupTypes.MOBILE_APP_SYNC,
        name,
        access_level,
        app_market_allocation_type,
      },
    });
    const { object: group } = result;
    await addItemsToMobileAppSync({ ...values, id: group.id });
    return group;
  });
};
