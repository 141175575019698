import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { IProduct } from 'types';
import { getProducts } from 'utils/apis/product';
import { byId } from 'utils/general';
import { updateRecommendation } from 'utils/apis/recommendation';

export const fetchRelatedProducts = async (related_product_ids: number[]): Promise<IProduct[]> => {
  if (related_product_ids?.length < 1) {
    return [];
  }
  const products = await getProducts({ product_ids: related_product_ids });
  const productsById = byId(products);
  return related_product_ids.map((id) => productsById[id]);
};

export const saveOrder = (id: number, product_ids: number[]): Promise<McGenericRecommendationProduct> => {
  return updateRecommendation<McGenericRecommendationProduct>({ id, product_ids });
};
