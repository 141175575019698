import React from 'react';
import { IAssetFilters } from 'components/asset/asset-filters';
import { IFiltersHandlers } from 'helpers/filters';

export const additionalFilters = {};

interface IAdditionalFiltersProps {
  filterValues: IAssetFilters;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;
