import React, { useCallback } from 'react';

import {
  FormRemoteSelect,
  IRemoteSelectorFetcher,
  FormSegment,
  FormHtmlInput,
  FormImage,
  FormCheckbox,
  IUseFormReturn,
  FormColorPicker,
} from 'helpers/form';
import { FormSectionWrapper } from 'components/form-section-wrapper';
import { queryParentEvents } from 'utils/apis/events';
import { IStepGeneralInfo } from 'components/event-create/steps/step-general-info/types';

import { IMarketingEventEditForm } from 'components/event-create/steps';

const typeSegments = [
  { value: 'mainevent', label: 'Main Event' },
  { value: 'subevent', label: 'Sub-Event' },
];

interface IStepGeneralInfoSecondColumnProps extends IStepGeneralInfo {
  form: IUseFormReturn<IMarketingEventEditForm & { text_color?: string | null; standalone?: boolean }>;
}

const StepGeneralInfoSecondColumn: React.FC<IStepGeneralInfoSecondColumnProps> = (props) => {
  const { form, editMode = false } = props;

  const eventFetcher: IRemoteSelectorFetcher = useCallback(async (q, id) => {
    return queryParentEvents({ q, ...(id ? { ids: [id] } : {}) });
  }, []);

  const { onChange } = form.handlers;

  const onSubEventChange = useCallback(
    ({ ...values }) => {
      if (!values.eventType) {
        values.group_parent_group_id = null;
      } else {
        values.event_password = '';
      }

      onChange(values);
    },
    [onChange],
  );
  const textColor = form.values.text_color ?? '#ffffff';

  const handleStandaloneChange = useCallback(
    ({ standalone }) => {
      form.handlers.onChange({ standalone });
      if (!standalone) {
        form.handlers.onChange({ text_color: null });
      }
    },
    [form.handlers],
  );

  return (
    <FormSectionWrapper className="create-wizard__inner-section" gridTemplateColumns="1fr">
      <FormSegment
        name="eventType"
        label="Type"
        large
        segments={typeSegments}
        {...form.formData.eventType}
        {...form.handlers}
        onChange={onSubEventChange}
        disabled={editMode}
      />

      <FormRemoteSelect
        name="group_parent_group_id"
        label="Main Event"
        large
        enableClearing
        fetchOptions={eventFetcher}
        {...form.formData.group_parent_group_id}
        {...form.handlers}
        disabled={form.values.eventType === 'mainevent' || !form.values.eventType || editMode}
      />

      <FormCheckbox
        name="standalone"
        label="Standalone page"
        {...form.formData.standalone}
        {...form.handlers}
        onChange={handleStandaloneChange}
      />
      <FormColorPicker
        name="text_color"
        label="Standalone Text Сolor"
        {...form.formData.text_color}
        {...form.handlers}
        value={textColor}
        disabled={!form.values.standalone}
      />
      <FormImage
        name="group_preview_image_url"
        label="Preview Image"
        {...form.formData.group_preview_image_url}
        {...form.handlers}
      />
      <FormHtmlInput name="description" label="Description" large {...form.formData.description} {...form.handlers} />
    </FormSectionWrapper>
  );
};

export { StepGeneralInfoSecondColumn };
