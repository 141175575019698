import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { ScrollWrapper } from 'components/scroll-wrapper';

import { IOrganization } from 'types';

interface IOrganizationFinanceTabProps {
  organization: IOrganization;
}

const OrganizationFinanceTab: React.FC<IOrganizationFinanceTabProps> = observer(({ organization }) => {
  const { vat_number, vat_rate, withholding_tax_rate, tax_number, bank_accounts } = organization || {};

  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="150px 1fr" title="Business" wrapperClassName="py-3" withBorder>
        <Row label="tax number" value={tax_number || `—`} size="m" />
        <Row label="VAT Number" value={vat_number || `—`} size="m" />
        <Row label="VAT rate" value={vat_rate || `—`} size="m" />
        <Row label="Withholding Tax Rate" value={withholding_tax_rate || `—`} size="m" />
      </InfoBoxV2>
      <InfoBoxV2 title="Account" wrapperClassName="py-3" className="entity-detail__infobox-wrapper">
        {bank_accounts?.map((bank_account) => (
          <InfoBoxV2 key={bank_account.iban} gridTemplateColumns="150px 1fr" padded={false}>
            <Row label="Name" value={bank_account.name || `—`} size="m" />
            <Row label="IBAN" value={bank_account.iban || `—`} size="m" />
            <Row label="SWIFT-BIC" value={bank_account.swift_bic || `—`} size="m" />
            <Row label="Account Number" value={bank_account.account_number || `—`} size="m" />
            <Row label="Sort Code" value={bank_account.sort_code || `—`} size="m" />
            <Row label="Description" value={bank_account.description || `—`} size="m" />
          </InfoBoxV2>
        ))}
      </InfoBoxV2>
    </ScrollWrapper>
  );
});

export default OrganizationFinanceTab;
