import React, { useCallback } from 'react';
import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { ToastError } from 'components/toast';
import { AddItemsToShowcase } from 'components/add-to-showcase';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { createListItems } from 'utils/apis/showcase-item';
import { formatWebsiteTypes } from 'components/showcase-form/utils';
import { IFormMultiSelectOption } from 'helpers/form';
import { fetchListItems } from 'utils/apis/showcase';
import { delay } from 'utils/delay';

import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { shouldDisplayForOneItem } from './acl';
import { IModalSize } from 'components/dialogs/types';

export function useAddItemsToShowcaseAction<
  T extends UmGenericListShowcase | UmGenericListCollection = UmGenericListShowcase,
>(showcases: T[], options: IUseActionsOptionParam<T>): IShowcaseActionConfig {
  const { toastStore, dialogStore } = useStore();
  const [showcase] = showcases;
  const type = formatWebsiteTypes(showcase.type);

  const onSubmit = useCallback(
    async (items: IFormMultiSelectOption[]): Promise<void> => {
      try {
        if (!showcase?.id) {
          return;
        }

        await createListItems(items, showcase.id);
        await delay(1000);
        const { objects } = await fetchListItems({
          listIds: [showcase.id],
        });

        options?.onSuccess?.(IShowcaseActionName.ADD_ITEMS, showcase, objects || []);

        toastStore.success(`Items will be added to ${type}`);
        dialogStore.close();
      } catch (error) {
        toastStore.error(
          <ToastError error={error} placeholder={`Something went wrong when adding items to the ${type}`} />,
        );
        await options?.onFailure?.();
      }
    },
    [options, toastStore, type, dialogStore, showcase],
  );
  const handler = useCallback(() => {
    dialogStore.openModal({
      title: `Add items to ${type}`,
      size: IModalSize.S,
      body: () => <AddItemsToShowcase showcase={showcase} onSubmit={onSubmit} />,
    });
  }, [dialogStore, onSubmit, showcase, type]);

  return {
    name: IShowcaseActionName.ADD_ITEMS,
    shouldDisplay: shouldDisplayForOneItem,
    icon: 'add-to-artifact',
    title: 'Add items',
    handler,
  };
}
