import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';
import cx from 'classnames';

import { IAsset, IProduct, IGroupEntityIdName, ItemId } from 'types';

import { Loading } from 'components/loading';
import { createDataTree, IOmniTreeNode, IOmniTreeSelectOption } from 'helpers/form/fields/form-select';
import { ProductListItem, AssetListItem } from './marketing-entity-detail-product-list-item';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import CheckboxTree from 'components/checkbox-tree/checkbox-tree';

import { getEntityIdFromParams, getValidIds } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { loadProductGroupItems } from 'utils/apis/groups';
import { queryClassificationAssetsOfProducts } from 'utils/apis/product-asset';

import './style.scss';

interface IProductWithAssets {
  product: IProduct;
  assets: IAsset[];
}

export interface IMarketingEntityProductsTab {
  assets?: Pick<IAsset, 'id'>[];
  entityIdName: IGroupEntityIdName;
}

const MarketingEntityProductsTab: React.FC<IMarketingEntityProductsTab> = observer(({ entityIdName, assets }) => {
  const params = useParams<{ entityIdName: string }>();

  const groupId = getEntityIdFromParams(params, entityIdName);
  const [checked, setChecked] = useState<string[][]>([]);

  const fetchProductsWithAssets = useCallback(async (): Promise<IProductWithAssets[]> => {
    const products = await loadProductGroupItems({ group_ids: groupId, withAncestryInfo: true });

    let assets: (IAsset & { product_id?: number })[] = [];
    if (products?.length) {
      assets = await queryClassificationAssetsOfProducts(map(products, 'id'), true);
    }

    return products?.map((product) => ({
      product,
      assets: assets.filter(({ product_id }) => product_id === product.id),
    }));
  }, [groupId]);
  const [productWithAssets, loading] = useRemote(fetchProductsWithAssets);

  useLayoutEffect(() => {
    if (productWithAssets) {
      const assetIds = getValidIds<ItemId, string>(assets, (id: ItemId): string => `${id}`);
      setChecked(map(productWithAssets, ({ product }) => [String(product.id), ...assetIds]));
    }
  }, [assets, productWithAssets]);

  const flatOptionsTree = useMemo((): IOmniTreeSelectOption[][] | [] => {
    if (!productWithAssets) return [];
    return productWithAssets.map(({ product, assets }, index) => {
      const [videos, images] = assets.reduce(
        (acc: [IOmniTreeSelectOption[], IOmniTreeSelectOption[]], asset) => {
          const assetElement = {
            label: <AssetListItem asset={asset} />,
            value: `${asset.id}`,
            className: 'tree-item',
          };

          if (asset.classification === 'video') {
            return [[...acc[0], { ...assetElement, parentId: 'videos' }], acc[1]];
          }
          if (asset.classification === 'image') {
            return [acc[0], [...acc[1], { ...assetElement, parentId: 'images' }]];
          }
          return [acc[0], acc[1]];
        },
        [[], []],
      );

      const productElement: IOmniTreeSelectOption = {
        className: `tree-item ${!videos.length && !images.length && 'empty'}`,
        label: <ProductListItem product={product as IProduct & { assets: IAsset[] }} />,
        value: String(product.id),
      };

      if (videos.length) {
        const checkedCount = videos.filter((video) => checked[index]?.includes(String(video.value))).length;
        videos.push({
          className: 'tree-item tree-item-assets',
          label: `Videos ${checkedCount} / ${videos.length}`,
          value: 'videos',
          parentId: String(product.id),
        });
      }

      if (images.length) {
        const checkedCount = images.filter((image) => checked[index]?.includes(String(image.value))).length;
        images.push({
          className: 'tree-item tree-item-assets',
          label: `Images ${checkedCount} / ${images.length}`,
          value: 'images',
          parentId: String(product.id),
        });
      }

      return [productElement, ...videos, ...images];
    });
  }, [checked, productWithAssets]);

  const optionsTree = useMemo<IOmniTreeNode[][]>(() => map(flatOptionsTree, createDataTree), [flatOptionsTree]);

  return (
    <div className="marketing-entity-products__wrapper">
      {loading && <Loading className="marketing-entity-products-tab__loading" text="Loading Products" />}
      {optionsTree?.map((nodes, index) => {
        const expanded = map(nodes, 'value');
        const checkedForCurrent = checked[index] || [];
        return (
          <div
            key={index}
            className={cx('marketing-entity-products__content', {
              ['marketing-entity-products__content--active']: checkedForCurrent.length,
            })}
          >
            <CheckboxTree disabled checked={checkedForCurrent} nodes={nodes} withExpanded expanded={expanded} />
          </div>
        );
      })}
      {!loading && !optionsTree.length && <SectionMessage intent={SectionMessageType.EMPTY} />}
    </div>
  );
});

export default MarketingEntityProductsTab;
