import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IconName } from 'blueprint5-icons';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { AccessGroupActions } from 'components/action';
import { AccessGroupDetailsTabsView } from './access-group-tabs-view';
import AccessGroupDetailsOverview from './access-group-details-overview';
import { BodyWrapper } from 'components/layout';

import { useEditMode, useOrganizationCountInGroup, useRemote, useTab } from 'utils/hooks';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IAccessGroupActionName } from 'utils/actions/access-group';
import { Classes } from 'utils/ui';
import { Pages } from 'utils/actions/types';

import { Model } from 'helpers/filters/types';
import { IAccessGroup, IGroupItem, DetailsPageTabs } from 'types';
import { Routes } from 'utils/routes';
import { IProductActionName } from 'utils/actions/product/types';
import { useForm } from 'helpers/form';
import { ToastError } from 'components/toast';
import { useNavigate } from 'react-router';
import {
  loadGroup,
  loadGroupItems,
  changeGroupAccessImmediate,
  editGroupInfo,
  IGroupNameEditFormFields,
} from 'utils/apis/groups';
import { groupDetailsSchema } from 'utils/schemas';
import { ActionsMenu } from 'components/action/utils';

const tabOptions = [
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.CONTACTS,
  DetailsPageTabs.ORGANIZATIONS,
];
const defaultTab = DetailsPageTabs.PRODUCTS;

const EmptySection: React.FC = () => {
  return (
    <BodyWrapper>
      <SectionMessage intent={SectionMessageType.EMPTY}>No Access Group</SectionMessage>
    </BodyWrapper>
  );
};

const AccessGroupDetails: React.FC = observer(() => {
  const { toastStore } = useStore();
  const navigate = useNavigate();
  const { accessGroupId } = useParams<{ accessGroupId: string }>();
  const [currentTab, setCurrentTab] = useTab(defaultTab, tabOptions);
  const [editModeEnabled, setEditModeEnabled] = useEditMode();

  const downloadPermission = useRef(false);

  const fetchAccessGroup = useCallback(async () => {
    if (accessGroupId) return loadGroup<IAccessGroup>(accessGroupId);
  }, [accessGroupId]);

  const [accessGroup, loading, setAccessGroup, refreshAccessGroup] = useRemote<IAccessGroup | undefined>(
    fetchAccessGroup,
  );

  const { id, name = '', access_level, owner, pm_statistics_data, users_count } = accessGroup || {};
  const [organization_count, refreshOrgCount] = useOrganizationCountInGroup(id);

  useEffect(() => {
    async function fetchProductGroupItems(): Promise<void> {
      if (!accessGroupId) {
        return;
      }

      const productGroupItems = await loadGroupItems<IGroupItem>({
        group_ids: accessGroupId,
        target: Model.PRODUCT_GROUP_ITEMS,
        per: 1,
      });
      downloadPermission.current = productGroupItems?.[0]?.permissions?.includes('download');
    }
    fetchProductGroupItems();
  }, [accessGroupId]);

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string) => {
      return await changeAccessLevelSlider<IAccessGroup>({
        apiCall: (data) =>
          changeGroupAccessImmediate({ ...data, item_ids: accessGroupId ? [accessGroupId] : [] }, groupDetailsSchema),
        divisionIds: owner?.division_ids,
        newAccessLevel,
        entitySetter: setAccessGroup,
      });
    },
    [accessGroupId, owner?.division_ids, setAccessGroup],
  );

  const handleSubmit = useCallback(
    async (values): Promise<void> => {
      try {
        if (!accessGroupId) {
          return;
        }

        toastStore.info('Saving');
        await editGroupInfo(accessGroupId, values);
        toastStore.clearAll();
        toastStore.success('Saved', 1000);
        setEditModeEnabled(false);
        refreshAccessGroup();
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(<ToastError error={e} placeholder="Error while saving: Unknown error" />);
      }
    },
    [accessGroupId, refreshAccessGroup, setEditModeEnabled, toastStore],
  );

  const editForm = useForm<IGroupNameEditFormFields>({ name }, Model.GROUPS, handleSubmit);
  const { onChange } = editForm.handlers;

  useEffect(() => {
    if (!name) {
      return;
    }
    onChange({ name });
  }, [name, onChange]);

  const editModeActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => [
      {
        type: 'save',
        handler: editForm.onSubmit,
        disabled: !editForm.valid,
      },
      {
        type: 'cancel',
        handler: (): void => {
          setEditModeEnabled(false);
          editForm.handlers.onChange({ name });
        },
      },
    ],
    [editForm.handlers, editForm.onSubmit, editForm.valid, name, setEditModeEnabled],
  );

  const commonActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => [{ type: 'edit', handler: () => setEditModeEnabled(true) }],
    [setEditModeEnabled],
  );
  const headerActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => (editModeEnabled ? editModeActions : commonActions),
    [commonActions, editModeActions, editModeEnabled],
  );

  const onActionSuccess = useCallback(
    async (action?: string): Promise<void> => {
      switch (action) {
        case IAccessGroupActionName.DELETE:
          navigate(`${Routes.ACCESS_GROUPS}`);
          return;
        case IAccessGroupActionName.EDIT:
        case IProductActionName.REMOVE_FROM_GROUP:
          setTimeout(() => {
            refreshAccessGroup();
            refreshOrgCount();
          }, 1000);
          return;
      }
      refreshAccessGroup();
    },
    [refreshAccessGroup, navigate, refreshOrgCount],
  );

  const dropdownMenu = useMemo((): JSX.Element => {
    if (!accessGroup) {
      return <></>;
    }
    return (
      <ActionsMenu
        component={AccessGroupActions}
        items={[accessGroup]}
        options={{ context: 'single', page: Pages.ACCESS_GROUP_DETAILS, onSuccess: onActionSuccess }}
      />
    );
  }, [accessGroup, onActionSuccess]);

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Access Group details" />
      </BodyWrapper>
    );
  }

  if (!accessGroup && !loading) {
    return <EmptySection />;
  }

  return (
    <BodyWrapper>
      <SectionHeader
        title={name}
        subtitle={
          <span className={Classes.TEXT_MUTED}>
            {`${pm_statistics_data?.count} Products | ${users_count} Contacts | ${organization_count} Organizations`}
          </span>
        }
        dropdownMenu={dropdownMenu}
        useBackground
        accessLevel={access_level}
        onAccessLevelChange={handleAccessLevelChange}
        actions={headerActions}
      />
      <div className="recommendation-details__content-wrapper">
        <AccessGroupDetailsOverview group={accessGroup} form={editForm} editModeEnabled={editModeEnabled} />

        <div className="recommendation-details__tabs-wrapper">
          <AccessGroupDetailsTabsView
            group={accessGroup}
            currentTab={currentTab}
            onTabChangeHandle={handleTabChange}
            onSuccess={onActionSuccess}
            editModeEnabled={editModeEnabled}
          />
        </div>
      </div>
    </BodyWrapper>
  );
});

export default AccessGroupDetails;
