import { getRootStore } from 'store';
import { ICategory } from 'types';

import { byId, formatTreeEntity } from './general';

export const formatCategoryLabel = (id: number): string => {
  const {
    basicStore: { categories },
  } = getRootStore();

  const categoriesById = byId<ICategory>(categories);
  return formatTreeEntity(id, categoriesById);
};
