import React from 'react';
import { addHours } from 'date-fns';
import { Tooltip, ActionIcon, Switch } from '@mantine/core';

import { FormCheckbox, FormDate, FormSelect } from 'helpers/form';
import { maxAccessDate } from 'utils/constants';

import { IRecommendationFormProps } from './types';
import { today } from 'utils/date';
import { MantineIcon } from 'utils/ui';
import { flags } from 'utils/flags';

const allowedViewsOptions = [1, 2, 3, 4, 5, 10, 20, 50];

export const RecommendProductsStepSettings: React.FC<IRecommendationFormProps> = ({
  form: { formData, handlers, values },
  children,
}) => {
  const handleToggle = (field: 'visible') => () => {
    handlers.onChange({ [field]: !values[field] });
  };

  return (
    <div className="product-recommendation-form">
      <FormDate
        name="expires_at"
        label="Expires at"
        placeholder="Select date"
        minDate={addHours(today, 1)}
        maxDate={maxAccessDate}
        large
        withTime
        {...formData.expires_at}
        {...handlers}
        required
      />
      <FormSelect
        name="allowed_views"
        label="Allowed views"
        placeholder="Unlimited"
        {...formData.allowed_views}
        options={allowedViewsOptions}
        emptyValue={null}
        {...handlers}
      />
      {flags.showListRecommendationOnWebsiteFeature && (
        <div className="d-flex align-items-center mt-2">
          <Switch
            label="List on the website"
            data-param="visible"
            onChange={handleToggle('visible')}
            checked={Boolean(values.visible)}
            size="md"
          />
          <Tooltip
            label="Includes the recommendation into recipient's received recommendations on the client-facing website"
            position="bottom"
            events={{ hover: true, focus: true, touch: true }}
          >
            <ActionIcon className="ms-1" variant="subtle" color="grey.5">
              <MantineIcon icon="help" />
            </ActionIcon>
          </Tooltip>
        </div>
      )}
      <FormCheckbox
        className="product-recommendation-form__generate-links mb-0 d-flex"
        name="generate_links_only"
        labelElement={
          <div className="d-flex align-items-center">
            <span>Generate links only</span>
            <Tooltip
              label="Recommendation links will be generated and sent to you via email"
              position="bottom"
              events={{ hover: true, focus: true, touch: true }}
            >
              <ActionIcon variant="subtle" color="grey.5">
                <MantineIcon icon="help" />
              </ActionIcon>
            </Tooltip>
          </div>
        }
        {...formData.generate_links_only}
        {...handlers}
      />
      {children}
    </div>
  );
};
