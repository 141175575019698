import { CalGenericLocation } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useEditLocation } from 'components/scheduler';
import { useDeleteAction } from './delete';

import { ILocationsActionName } from './types';

export const useLocationActions = (
  entities: CalGenericLocation | CalGenericLocation[],
  options: IUseActionsOptionParam<CalGenericLocation | CalGenericLocation[]>,
): IUseActionsResponse<ILocationsActionName> => {
  const deleteAction = useDeleteAction(entities, options);
  const editAction = useEditLocation(entities, options);
  const actions = [editAction, deleteAction];

  return useActions(actions, options, [entities]);
};
