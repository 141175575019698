import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { FilterCheckbox, FilterDateRange, FilterSelect, FilterSwitch, FilterText } from 'helpers/filters/fields';
import { submitFilters, ISubmitFiltersParams, toggleParam } from 'helpers/filters/helpers';
import { parseQueryParams } from 'utils/general';
import { useStore } from 'store';
import { ownersOptionsProvider, organizationOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { AdditionalFiltersCmp } from 'components/asset/asset-filters/additional-filters';
import { AssetTypeFilter } from 'components/asset/asset-filters/type-filters';

import {
  accessOptionsProvider,
  classificationsOptionsProvider,
  protectionLevelsOptionsProvider,
  defaultParamComponentValues,
  fileStatusOptionsProvider,
  IAssetFilters,
  statusOptionsProvider,
} from './utils';

export const AssetFilters: React.FC<{
  customSubmitFilter?: (obj: ISubmitFiltersParams) => void;
  withMainAssetsFilter?: boolean;
}> = observer(({ customSubmitFilter = submitFilters, withMainAssetsFilter }) => {
  const location = useLocation();

  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;
  const queryParams = parseQueryParams(location.search);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

  const handleSubmit = useCallback(
    (action): void => {
      customSubmitFilter({
        action,
        setParamComponentValues,
        customParams: {
          include_deleted: false,
        },
      });
    },
    [customSubmitFilter],
  );

  const [filterValues, filterHandlers] = useFilters<IAssetFilters>(dataSectionStore, handleSubmit);
  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

          <AssetTypeFilter
            filterHandlers={filterHandlers}
            filterValues={filterValues}
            withMainAssetsFilter={withMainAssetsFilter}
          />

          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues('access_level')}
          />

          <FilterCheckbox
            label="Status"
            name="status"
            optionsProvider={statusOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('status')}
          />

          <FilterSelect
            label="File Status"
            name="file_status"
            placeholder="Select File Status"
            optionsProvider={fileStatusOptionsProvider}
            filter={filterValues.file_status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('file_status')}
          />

          <FilterSelect
            label="Classification"
            name="classification"
            placeholder="Select Classification"
            optionsProvider={classificationsOptionsProvider}
            filter={filterValues.classification}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('classification')}
          />

          <FilterSelect
            label="Protection Levels"
            name="protection_levels"
            placeholder="Select Protection Level"
            optionsProvider={protectionLevelsOptionsProvider}
            filter={filterValues.protection_levels}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('protection_levels')}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />
          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />
          <FilterSelect
            label="Owner Organization"
            name="owner_organization_id"
            placeholder="Select Organization"
            optionsProvider={organizationOptionsProvider}
            filter={filterValues.owner_organization_id}
            onChange={filterHandlers.onChange}
          />

          <FilterSwitch
            label="Unassigned assets only"
            name="products_count"
            filter={filterValues.products_count}
            onChange={filterHandlers.onChange}
            checkedValue={0}
            notCheckedValue={false}
          />

          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});
