import React from 'react';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { FormDate, FormHtmlInput, FormImage, FormInput, FormSelect } from 'helpers/form';
import { initialValues } from 'components/news/news-form/utils';
import { INewsFormData } from 'types';
import { FormSubmitSection } from 'components/form-submit-section';
import { endOfYear, addYears } from 'date-fns';
import {
  CustomTargetNewsField,
  CustomTitleNewsField,
  CustomContentNewsField,
} from 'components/news/news-form/custom-field';

interface INewsFormProps {
  news?: INewsFormData;
  handleSubmit: (values: INewsFormData) => Promise<AmGenericNews | void>;
}

export const maxDate = endOfYear(addYears(new Date(), 10));

const NewsForm: React.FC<INewsFormProps> = ({ news = initialValues, handleSubmit }) => {
  const { handlers, formData, onSubmit, valid } = useMm3Form<INewsFormData>(news, Model.NEWS, handleSubmit);
  const isEditMode = Boolean(news?.id);

  return (
    <form className="d-flex flex-column justify-content-between h-100" onSubmit={onSubmit}>
      <FormInput name="title" label="Title" {...handlers} {...formData?.title} />

      <CustomTitleNewsField handlers={handlers} formData={formData} />

      <FormSelect
        emptyValueIsUndefined
        large
        name="access_level"
        label="Access Level"
        {...handlers}
        {...formData?.access_level}
      />
      <FormDate large name="date" maxDate={maxDate} label="News Date" {...handlers} {...formData?.date} />
      <FormInput name="meta.location" label="Location" {...handlers} {...formData?.meta?.location} />

      <CustomTargetNewsField handlers={handlers} formData={formData} />

      <FormHtmlInput large name="meta.content" label="Text" {...handlers} {...formData?.meta?.content} />
      <CustomContentNewsField handlers={handlers} formData={formData} />

      {!isEditMode && (
        <FormImage
          name="previewImageUrl"
          label="Preview Image"
          className="py-3"
          {...formData.previewImageUrl}
          {...handlers}
        />
      )}
      <FormSubmitSection labels={{ confirm: isEditMode ? 'Save' : 'Create' }} submitDisabled={!valid} />
    </form>
  );
};

export { NewsForm };
