import { UmGenericListShowcase, UmGenericListCollection } from '@mediafellows/mm3-types';

import { useUploadImageDialog } from 'components/upload-preview-image';

import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { uploadPreviewImage } from 'utils/apis/showcase';

export const useUploadPreviewAction = (
  items: (UmGenericListShowcase | UmGenericListCollection)[],
  options: IUseActionsOptionParam,
): IShowcaseActionConfig => {
  const item = items?.[0];
  const [openUploadImageDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      if (file && item) {
        const updatedList = await uploadPreviewImage(file, item);
        Boolean(updatedList) &&
          options?.onSuccess?.(IShowcaseActionName.UPLOAD_PREVIEW, { ...item, ...updatedList?.object });
      }
    },
    customMessage: 'Select a preview image to upload',
  });

  return {
    name: IShowcaseActionName.UPLOAD_PREVIEW,
    shouldDisplay: () => options.context === 'single' && item.type === 'List::Collection',
    icon: 'upload',
    title: 'Upload preview',
    handler: openUploadImageDialog,
  };
};
