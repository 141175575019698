import React from 'react';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { IGroup, ItemId } from 'types';
import { IPartialRecommendation } from './apis';
import { Loading } from 'components/loading';
import { RecommendProductsWizard } from 'components/recommendation/recommend-products';
import { RecommendAssetsWizardContainer } from 'components/recommendation/recommend-assets/recommend-assets-wizard-container';

import './style.scss';

interface IRecommendProductsWizardContainer {
  onFinish: (recommendation: IGroup | McGenericRecommendationProduct | McGenericRecommendationAsset) => void;
  recommendation?: IPartialRecommendation;
  loading: boolean;
  isAssetRecommendation: boolean;
  groupId?: ItemId;
}

export const GroupToRecommendationMapper: React.FC<IRecommendProductsWizardContainer> = ({
  onFinish,
  recommendation,
  loading,
  isAssetRecommendation,
  groupId,
}) => {
  if (loading) {
    return (
      <div className="recommendation-wizard__loading-container">
        <Loading text="initiating Recommendation..." />
      </div>
    );
  }
  if (isAssetRecommendation)
    return (
      <RecommendAssetsWizardContainer groupId={groupId} assets={recommendation?.otherAssets} onFinish={onFinish} />
    );
  return <RecommendProductsWizard groupId={groupId} recommendation={recommendation || {}} onFinish={onFinish} />;
};
