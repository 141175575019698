import { useEffect, useRef, useMemo } from 'react';
import { isEqual } from 'lodash';
import { IMarketingEvent } from 'types';

import { Model } from 'helpers/filters/types';
import { useForm, IUseFormReturn } from 'helpers/form';
import { initialValues as emptyEventValues } from 'components/event-create/utils';

import { IMarketingEventEditForm } from './steps/types';

const createEventCustomContext = {
  properties: {
    name: { required: true },
    purpose: {
      required: true,
      validations: [
        {
          format: {
            message: 'is invalid',
            without: '/[&$+,/:;=?@#\\s<>{}|\\\\^%\\[\\]]/',
          },
        },
      ],
    },
  },
};

interface IUseEventCreateForm {
  saveEvent: (values: Partial<IMarketingEvent>) => void;
  values?: Partial<IMarketingEventEditForm>;
}

export const useEventCreateForm = ({
  saveEvent,
  values: event,
}: IUseEventCreateForm): IUseFormReturn<IMarketingEventEditForm> => {
  const latestEventFields = useRef(event);

  const initialValues: IMarketingEventEditForm = useMemo(
    () =>
      ({
        ...emptyEventValues,
        group_preview_image_url: event?.preview_image?.url,
        ...(event || {}),
      } as IMarketingEventEditForm),
    [event],
  );

  const form = useForm<IMarketingEventEditForm>(initialValues, Model.EVENTS, saveEvent, createEventCustomContext);

  const {
    handlers: { onChange },
    values,
  } = form;
  const latestValues = useRef(values);

  useEffect(() => {
    if (!event || isEqual(initialValues, latestEventFields.current)) {
      return;
    }
    latestEventFields.current = initialValues;
    onChange(initialValues);
  }, [event, initialValues, onChange]);

  useEffect(() => {
    if (!event || isEqual(values, latestValues.current)) {
      return;
    }

    latestValues.current = values;
  }, [values, event]);

  return form;
};
