import React from 'react';

import { Row } from 'components/label-value-pair';

import { RecipientList } from 'components/recommendation/recommendation-shared/utils';

import { IScreeningRoomStepProps } from './utils';

import './style.scss';

export const ScreeningRoomStepSummary: React.FC<
  IScreeningRoomStepProps & {
    assetsCount: number;
    contactsCount: number;
    loading: boolean;
    isEditingRecipients: boolean;
  }
> = ({ form: { values }, assetsCount, loading, isEditingRecipients }) => {
  return (
    <div className="d-flex flex-column gap-2 h-100">
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Name"
        value={values.name}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border summery-description"
        label="Description"
        value={values.description}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Assets"
        value={assetsCount}
        loading={loading}
      />
      <Row
        labelClassName="wizard_summary__label"
        valueClassName="wizard_summary__value--border"
        label="Recipients"
        loading={loading}
        value={
          <RecipientList
            isEditingRecipients={isEditingRecipients}
            numberOfShownUsers={5}
            recipient_list={values?.contacts?.reduce(
              (acc, e) =>
                e?.id && e.itemType === 'user' ? [...acc, { recipient_id: e.id || 0, recipient_type: 'user' }] : acc,
              [],
            )}
            contact_selections={values?.contacts?.reduce(
              (acc: { id: number }[], e) => (e?.id && e.itemType === 'group' ? [...acc, { id: e.id }] : acc),
              [],
            )}
          />
        }
      />
    </div>
  );
};
