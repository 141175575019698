export const mobileAppSyncSchema = `
  id,
  access_level,
  am_statistics_data,
  pm_statistics_data,
  created_at,
  updated_at,
  expires_at,
  deleted_at,
  scheduled_at,
  name,
  owner_organization,
  preview_image {
    url
  },
  preview_image_id,
  users_count,
  owner,
  settings {
    protection_levels
  },
  description,
`;
export const mobileAppSyncDetailsSchema = mobileAppSyncSchema;
