import { emptyValidation } from './empty';
import { presenceValidation } from './presence';
import { lengthValidation, ILengthValidationOpts } from './length';
import { emailValidation, IEmailValidationOpts } from './email';
import { numericalityValidation, INumericalityValidationOpts } from './numericality';
import { inclusionValidation, IInclusionValidationOpts } from './inclusion';
import { valueInclusionValidation, IValueInclusionValidationOpts } from './value-inclusion';
import { formatValidation, IFormatValidationOpts } from './format';
import { acceptanceValidation, IAcceptanceValidationOpts } from './acceptance';
import { IConfirmationValidationOpts, confirmationValidation } from './confirmation';
import { extensionsValidation, IFileExtensionValidationOpts } from './extensions';

enum ValidatorType {
  presence = 'presence',
  length = 'length',
  email = 'email',
  numericality = 'numericality',
  inclusion = 'inclusion',
  value_inclusion = 'value_inclusion',
  format = 'format',
  acceptance = 'acceptance',
  confirmation = 'confirmation',
  uniqueness = 'uniqueness',
  list_item_uniqueness = 'list_item_uniqueness',
  size = 'file_size',
  extensions = 'file_extensions',
}

export interface IValidationOpts {
  [ValidatorType.presence]?: boolean;
  [ValidatorType.length]?: ILengthValidationOpts;
  [ValidatorType.email]?: boolean;
  [ValidatorType.numericality]?: INumericalityValidationOpts;
  [ValidatorType.inclusion]?: IInclusionValidationOpts;
  [ValidatorType.value_inclusion]?: IValueInclusionValidationOpts;
  [ValidatorType.format]?: IFormatValidationOpts;
  [ValidatorType.acceptance]?: IAcceptanceValidationOpts;
  [ValidatorType.confirmation]?: IConfirmationValidationOpts;
}

const processValidator = (
  validatorType: ValidatorType,
  opts:
    | boolean
    | ILengthValidationOpts
    | INumericalityValidationOpts
    | IInclusionValidationOpts
    | IValueInclusionValidationOpts
    | IFormatValidationOpts
    | IAcceptanceValidationOpts
    | IFileExtensionValidationOpts
    | IConfirmationValidationOpts,
  value: unknown,
): [boolean, string?] => {
  switch (validatorType) {
    case ValidatorType.presence:
      return presenceValidation(value);
    case ValidatorType.length:
      return lengthValidation(opts as ILengthValidationOpts, value as string);
    case ValidatorType.email:
      return emailValidation(opts as IEmailValidationOpts, value as string);
    case ValidatorType.uniqueness:
    case ValidatorType.list_item_uniqueness:
      return [true];
    case ValidatorType.numericality:
      return numericalityValidation(opts as INumericalityValidationOpts, value as string | number);
    case ValidatorType.inclusion:
      return inclusionValidation(opts as IInclusionValidationOpts, value as string);
    case ValidatorType.value_inclusion:
      return valueInclusionValidation(opts as IValueInclusionValidationOpts, value as string[]);
    case ValidatorType.format:
      return formatValidation(opts as IFormatValidationOpts, value as string);
    case ValidatorType.acceptance:
      return acceptanceValidation(opts as IAcceptanceValidationOpts, value as boolean);
    case ValidatorType.confirmation:
      return confirmationValidation(opts as IConfirmationValidationOpts, value as string);
    case ValidatorType.size:
      // skip validation for file size
      return [true];
    case ValidatorType.extensions:
      return extensionsValidation(opts as IFileExtensionValidationOpts, value as string);
    default:
      return [false, 'validator not handled'];
  }
};

export const fieldValidation = <T>(opts: IValidationOpts[] = [], value: T): [boolean, string?] => {
  for (let i = 0; i < opts.length; i++) {
    const validators: ValidatorType[] = Object.keys(opts[i]) as ValidatorType[];

    for (let j = 0; j < validators.length; j++) {
      const validatorType = validators[j];
      const opt = opts[i][validatorType];
      const [valid, message] = processValidator(validatorType as ValidatorType, opt, value);
      if (!valid) {
        return [valid, message];
      }
    }
  }

  return [true];
};

export {
  emptyValidation,
  presenceValidation,
  lengthValidation,
  emailValidation,
  numericalityValidation,
  inclusionValidation,
  valueInclusionValidation,
  formatValidation,
};
