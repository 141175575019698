import React from 'react';
import { ActionIcon, Button } from '@mantine/core';
import { IconName } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

import { CheckPowers } from 'components/check-power';

export interface IPopoverMenuProps {
  text?: string;
  iconName?: IconName;
  requiredPowers?: string | string[];
}

const PopoverMenuItem = ({
  iconName,
  text,
  requiredPowers,
}: React.PropsWithChildren<IPopoverMenuProps>): JSX.Element => {
  return (
    <CheckPowers requiredPowers={requiredPowers}>
      <div className="sidebar__mode--default">
        <Button
          variant="primary"
          size="md"
          leftSection={<MantineIcon icon={iconName} />}
          rightSection={<MantineIcon icon="caret-down" />}
        >
          <span className="sidebar__btn-text">{text}</span>
        </Button>
      </div>
      <div className="sidebar__mode--icons">
        <ActionIcon variant="primary" aria-label="menu" size="40px" className="create-new-icon">
          <MantineIcon icon={iconName} />
        </ActionIcon>
      </div>
    </CheckPowers>
  );
};

export default PopoverMenuItem;
