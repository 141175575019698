import React, { useCallback, useState } from 'react';
import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { createAccessGroup } from 'utils/apis/access-group';
import { useStore } from 'store';
import { initialValues } from 'components/access-groups/initial-values';

import { AccessGroupWizard } from './access-group-wizard';
import { IAccessGroup } from './types';
import { IGroupAddContact } from 'types/group';
import { ItemId } from 'types/utility';

interface IAccessGroupWizardContainerProps {
  productIds?: ItemId[];
  assetIds?: ItemId[];
  organizationIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];

  contacts?: IGroupAddContact[];
  onSuccess?: () => void;
  fitInParent?: boolean;
}

const makePartialOptions = (ids, type: 'contact-selection' | 'contact'): IGroupAddContact[] => {
  if (!ids) return [];
  return ids.map((id) => ({ id, type }));
};

export const AccessGroupWizardContainer: React.FC<IAccessGroupWizardContainerProps> = ({
  onSuccess,
  productIds = [],
  assetIds = [],
  organizationIds = [],
  userIds = [],
  contacts = [],
  userSelectionIds = [],
  fitInParent = false,
}) => {
  const { toastStore } = useStore();
  const [isCreating, setIsCreating] = useState(false);

  const createAction = useCallback(
    async (values) => {
      setIsCreating(true);
      toastStore.info('Creating');
      await createAccessGroup(values);
      toastStore.clearAll();
      toastStore.success('Access Group was created successfully');
      setIsCreating(false);
      onSuccess?.();
    },
    [onSuccess, toastStore],
  );

  const form = useForm<IAccessGroup>(
    {
      ...initialValues,
      product_ids: productIds,
      asset_ids: assetIds,
      organization_ids: organizationIds,
      contacts: [
        ...contacts,
        ...makePartialOptions(userIds, 'contact'),
        ...makePartialOptions(userSelectionIds, 'contact-selection'),
      ],
    },
    Model.ACCESS_GROUPS,
    createAction,
  );

  return <AccessGroupWizard form={form} isCreating={isCreating} fitInParent={fitInParent} />;
};
