import React from 'react';
import { observer } from 'mobx-react-lite';
import AssetProgressItem from './asset-upload-item';
import UploadProgressIndicator from './progress-indicator';
import Uppy from '@uppy/core';
import { IAssetFile } from 'types';

import './index.scss';

export interface IAssetsUploadDashboard {
  dropAreaId: string;
  uppy: Uppy | null;
  files: IAssetFile[];
  removeFile: (file: IAssetFile) => void;
  progress: number;
}

export const AssetsUploadDashboard: React.FC<IAssetsUploadDashboard> = ({
  dropAreaId,
  uppy,
  files,
  removeFile,
  progress,
}) => {
  return (
    <div className="h-100 d-flex flex-column asset-upload-components__upload-dashboard-progress-items-wrapper">
      <div className="asset-upload-components__upload-dashboard-progress-items-content">
        <div>
          <div id={dropAreaId} className="asset-upload-components__upload-dashboard-dnd-area" />
        </div>
        <UploadProgressIndicator progress={progress} />
        <div className="asset-upload-components__upload-dashboard-progress pb-2">
          {files?.map((file) => (
            <AssetProgressItem key={file?.id} {...{ uppy, file }} removeFile={removeFile} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(observer(AssetsUploadDashboard));
