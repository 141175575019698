import React, { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ToastError } from 'components/toast';
import { AssignCastCrewToProductForm } from 'components/cast-crew';
import type { IAssignCastCrewToProductFormData } from 'components/cast-crew';
import { updateProductLayer } from 'utils/apis/product';
import { cleanUp } from 'utils/payload';

import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { shouldDisplayOnEntityPage } from './acl';
import { IModalSize } from 'components/dialogs/types';

export const useAssignToProductsAction = (
  items: PmGenericCastCrew[],
  options: IUseActionsOptionParam,
): ICastCrewActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IAssignCastCrewToProductFormData): Promise<void> => {
      const { cast_crew, products, cast_character, kind, cast_role, crew_position } = values;
      try {
        if (products.some((e) => !e?.default_layer?.id)) {
          const ids = products.reduce((acc, p) => (p?.default_layer?.id ? acc : [...acc, p.id]), []);
          throw new Error(
            `Could not perform this action, because Product(s) with id(s) ${ids.join(
              ', ',
            )} do not have a default layer`,
          );
        }
        const promises = products.map((e) => {
          const data = cleanUp({
            id: e.default_layer_id,
            product_id: e.id,
            meta: {
              ...e.default_layer.meta,
              cast_crew: [
                ...(e.default_layer?.meta?.cast_crew || []),
                ...cast_crew.map(({ id, name }) => ({
                  cast_crew_id: id || 0,
                  kind,
                  name,
                  ...(kind === 'Cast' ? { cast_character, cast_role } : { crew_position }),
                })),
              ],
            },
          });
          return updateProductLayer(data);
        });
        await Promise.all(promises);
        toastStore.success('Success');
        options?.onSuccess?.();
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        options?.onFailure?.();
      }
    },
    [dialogStore, options, toastStore],
  );

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Assign to Products',
      size: IModalSize.S,
      body: () => {
        const cast_crew = items.filter((e) => e.id);
        const cast_crew_ids = cast_crew.map((e) => e.id || 0);

        return <AssignCastCrewToProductForm data={{ cast_crew_ids, cast_crew }} handleSubmit={handleSubmit} />;
      },
    });
  };

  return {
    name: ICastCrewActionName.ASSIGN_TO_PRODUCTS,
    icon: 'link',
    title: 'Assign To Products',
    shouldDisplay: shouldDisplayOnEntityPage,
    handler,
  };
};
