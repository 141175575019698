import React, { useEffect, useCallback } from 'react';

import { IContact, IStyled, ItemId } from 'types';
import { FormRemoteSelect, IFieldData, IFieldHandlers } from 'helpers/form';
import { queryContacts } from 'utils/apis/contacts';
import { fetchListItems, getShowcaseByPurpose } from 'utils/apis/showcase';
import { meetingHostPurpose } from './utils';

export interface IFormParticipants
  extends IFieldData<string | number | null>,
    IFieldHandlers<string | number>,
    IStyled {
  name: string;
  label: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  enableClearing?: boolean;

  placeholder?: string;
  showPositiveValidation?: boolean;
  ids?: ItemId[] | null;
}

export const FormParticipants: React.FC<IFormParticipants> = ({ name, label, ids, ...rest }) => {
  const [sliderId, setSliderId] = React.useState<number | null>(null);

  useEffect(() => {
    getShowcaseByPurpose(meetingHostPurpose).then((sliderShowcase) => {
      setSliderId(sliderShowcase?.id || null);
    });
  }, []);

  const fetchParticipants = useCallback<(query: string, id?: ItemId) => Promise<IContact[]>>(
    async (query, id) => {
      const params: { q?: string; ids?: ItemId[] } = { q: query };

      const { objects } = await fetchListItems({ listIds: [sliderId as number] });
      const hostIds = objects.map((item) => item.entity.id);

      if (hostIds?.length) params.ids = hostIds as ItemId[];

      if (ids?.length) {
        params.ids = [...hostIds, ...ids] as ItemId[];
      }

      if (id) {
        params.ids = [id];
      }

      if (!params.ids) return [];

      return queryContacts(params);
    },
    [ids, sliderId],
  );

  return <FormRemoteSelect name={name} label={label} fetchOptions={fetchParticipants} {...rest} />;
};
