import React, { useCallback } from 'react';
import { map } from 'lodash';

import { IOrganization, ItemId } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';
import { IOrganizationActionName, IOrganizationActionConfig } from 'utils/actions/organization/types';

import { shouldDisplayDeleteAction as shouldDisplay } from './actions-acl';
import { deleteOrganization } from './api';

export const useDeleteAction = (items: IOrganization[], options: IUseActionsOptionParam): IOrganizationActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const organizationIds = map(items, 'id');
      await deleteOrganization(organizationIds as Array<ItemId>);
      toastStore.success(`${organizationIds.length} Organization${organizationIds.length > 1 ? 's' : ''} deleted`);
      options?.onSuccess?.();
    } catch (error) {
      toastStore.error(
        <div>
          <div>{error.title}</div>
          <div>{error.description}</div>
        </div>,
      );
      options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: (
        <div>
          <div>{`Are you sure you want to delete ${
            items.length > 1 ? 'these Organizations' : 'this Organization'
          }?`}</div>
          <div>All associated Contacts will also be deleted and will no longer be able to log in.</div>
        </div>
      ),
      onConfirm,
    });
  };

  return {
    name: IOrganizationActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
