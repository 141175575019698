import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from '@mantine/core';

import { CheckPowers } from 'components/check-power';
import { Routes } from 'utils/routes';
import { hideSideBarFlags } from 'utils/flags';
import { powersByRoute } from 'components/sidebar/utils';
import { useDataSectionStore } from 'store/hooks';

export interface ICollapseMenuItemProps {
  route: Routes;
  text: string;
  hidden?: boolean;
}

const CollapsibleMenuSubitem = ({ route, text }: ICollapseMenuItemProps): JSX.Element => {
  const { resetAllListFilters, clearOldFilters } = useDataSectionStore();

  const onClick = useCallback(() => {
    if (route === location.pathname) {
      resetAllListFilters();
    } else {
      clearOldFilters(route);
    }
  }, [resetAllListFilters, clearOldFilters, route]);

  return (
    <CheckPowers requiredPowers={powersByRoute[route]} hideAnyway={hideSideBarFlags[route]}>
      <div className="sidebar__mode--default">
        <NavLink state={{ from: 'newPage' }} onClick={onClick} to={route} className="sidebar__subitem">
          {text}
        </NavLink>
      </div>
      <div className="sidebar__mode--icons">
        <NavLink
          onClick={onClick}
          to={`${route}`}
          className={({ isActive }) => (isActive ? `sidebar__icons-subitem--active` : '')}
          state={{ from: 'newPage' }}
        >
          <Menu.Item component="li" className="sidebar__item ps-3 mb-0 pt-1 pb-1">
            {text}
          </Menu.Item>
        </NavLink>
      </div>
    </CheckPowers>
  );
};

export default CollapsibleMenuSubitem;
