import React, { useCallback, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Button, MenuItem, Menu, IPopoverProps } from '@blueprintjs/core';
import { Loader } from '@mantine/core';
import { ItemPredicate, Select } from '@blueprintjs/select';

import { IStyled, IItem, ICustomItemRendererProps } from 'types';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { ValidationMarker } from 'helpers/form/fields/validation-marker';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { itemRenderer, parseItem } from 'helpers/form/fields/select-helpers';
import { FormGroup } from 'helpers/form/fields/form-group';

import { useRemoteSelectSetup, usePreventBackspaceClick, useShouldInitiateOptions } from './hooks';
import { DefaultCreateTagElement } from './utils';
import { Classes } from 'utils/ui';

import { AncestryDropdown, IBreadcrumbProps } from './ancestry-dropdown/ancestry-dropdown';
import { IFilterOption } from 'utils/hooks';
import { IFormRemoteSelectOption, IRemoteSelectorFetcher } from './types';

import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';
import './style.scss';

const MfxSelect = Select.ofType<IFormRemoteSelectOption>();

const itemFilter: ItemPredicate<IFormRemoteSelectOption> = (query, item, _index, exactMatch) => {
  const normalizedLabel = item.label.toLowerCase();
  const normalizedQuery = query.toLowerCase();
  if (normalizedQuery !== '' && !Boolean(item.value)) return false; // ignore EmptyOption when user types smth
  if (normalizedQuery.indexOf('id:') >= 0) return true;
  if (exactMatch) {
    return normalizedLabel === normalizedQuery;
  } else {
    return normalizedLabel.indexOf(normalizedQuery) >= 0;
  }
};

export interface IFormRemoteSelectProps
  extends IFieldData<string | number | undefined | null, (number | string | IFormRemoteSelectOption)[]>,
    IFieldHandlers<string | number | null | undefined>,
    IStyled {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  fetchOptions: IRemoteSelectorFetcher;
  placeholder?: string;
  showPositiveValidation?: boolean;
  enableClearing?: boolean;
  allowNewItems?: boolean;
  validateNewItem?: (query: string) => boolean;
  createTagElement?: React.FC<{ text: string | number; onClick: VoidFunction }>;
  isAncestryMode?: boolean;
  emptyValue?: null | '' | 0;
  parseItemRenderProps?: (
    item: IFormRemoteSelectOption,
    itemProps: ICustomItemRendererProps,
  ) => ICustomItemRendererProps;
}

const preparePropOptions = (
  options: (IItem | string | number)[],
  placeholder = 'Nothing selected',
  noEmptyOption = false,
  emptyValue: null | undefined | '' | 0,
): IFormRemoteSelectOption[] => {
  const emptyOption: IFormRemoteSelectOption = { label: placeholder, value: emptyValue } as IFormRemoteSelectOption;
  const props: IFormRemoteSelectOption[] = (options || []).map((op): IFormRemoteSelectOption => {
    return typeof op === 'object' ? parseItem(op as IItem) : ({ label: '' + op, value: op } as IFormRemoteSelectOption);
  });

  return noEmptyOption ? props : [emptyOption, ...props];
};

const popoverProps: IPopoverProps = {
  boundary: 'viewport',
  minimal: true,
  usePortal: true,
  fill: true,
  portalClassName: 'form-select-popover-portal',
};
const defaultBreadcrumbs: IBreadcrumbProps[] = [{ href: 'root', icon: 'double-chevron-left', text: 'Top' }];

export const FormRemoteSelect: React.FC<IFormRemoteSelectProps> = (props) => {
  const {
    showPositiveValidation,
    name,
    label,
    large,
    inline,
    fetchOptions,
    disabled,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    style,
    enableClearing = false,
    className,
    allowNewItems = false,
    isAncestryMode = false,
    validateNewItem = (s: string): boolean => s?.trim().length > 0,
    createTagElement,
    parseItemRenderProps,
    emptyValue,
  } = props;
  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled,
    defaultPlaceholder: allowNewItems ? `Add ${label}` : `Select ${label}`,
  });
  const CreateTagElement = createTagElement || DefaultCreateTagElement;
  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = showPositiveValidation && touched && validation?.valid;
  const intent = showError ? Intent.DANGER : undefined;

  const [activeItem, setActiveItem] = useState<IFormRemoteSelectOption | null>(null);
  const [options, setOptions] = useState<IFormRemoteSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitiating, setIsInitiating] = useState(Boolean(value));
  const [shouldInitiateOptions, handleClick] = useShouldInitiateOptions();
  const latestRequestRef = useRef(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbProps[]>(defaultBreadcrumbs);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean | undefined>(undefined);

  const filters = useRef<IFilterOption[] | undefined>([]);

  usePreventBackspaceClick(inputRef);

  const onQueryChange = useMemo(() => {
    const debouncedQuery = debounce(async (query: string) => {
      setInputValue(query);
      latestRequestRef.current = latestRequestRef.current + 1;
      const requestNumber = latestRequestRef.current;
      try {
        const options = await fetchOptions(query, undefined, filters?.current);
        if (requestNumber !== latestRequestRef.current) {
          return;
        }
        setOptions(preparePropOptions(options, formattedPlaceholder, required, emptyValue));
      } finally {
        setIsLoading(false);
      }
    }, 400);

    return (query: string): void => {
      // show loading indicator
      setOptions([]);
      setIsLoading(true);
      debouncedQuery(query);
    };
  }, [fetchOptions, formattedPlaceholder, required, emptyValue]);

  useRemoteSelectSetup({
    value,
    shouldInitiateOptions,
    onQueryChange,
    setIsInitiating,
    fetchOptions,
    activeItem,
    setActiveItem,
    enableClearing,
    filters: filters.current,
    isPopoverOpen,
    setPopoverOpen,
    isAncestryMode,
  });

  const handleChange = useCallback(
    (item: IFormRemoteSelectOption, e?) => {
      e?.preventDefault();
      e?.stopPropagation();
      const targetEl = e?.target;
      const isAncestryArrowBtn =
        targetEl?.classList.contains('js-product-search-item__ancestry') ||
        targetEl?.closest('.js-product-search-item__ancestry');
      if (isAncestryArrowBtn && e?.currentTarget?.dataset) {
        const parentId = e.currentTarget.dataset.parentId;
        const text = e.currentTarget.dataset.text;
        filters.current = [['parent_id', 'eq', parentId]];
        setIsLoading(true);
        setBreadcrumbs((breadcrumbs) => [...breadcrumbs, { text, href: parentId }]);
        onQueryChange('');
        setInputValue('');
        return;
      } else {
        onChange?.({ [name]: item.value });
        setActiveItem(item);
        onBlur?.(name);
        isAncestryMode && setPopoverOpen(false);
      }
    },
    [onQueryChange, onChange, name, onBlur, isAncestryMode],
  );

  const createNewItemFromQuery = useMemo(() => {
    if (!allowNewItems) {
      return;
    }
    return (query: string) => {
      const value = query.trim();
      return { label: value, value };
    };
  }, [allowNewItems]);

  const handleAdd = useCallback((): void => {
    const trimmedValue = inputValue.trim();
    if (!validateNewItem(trimmedValue) || !trimmedValue || activeItem?.value === trimmedValue) {
      return;
    }
    const newItem = createNewItemFromQuery?.(trimmedValue);
    if (!newItem) {
      return;
    }
    handleChange(newItem as IFormRemoteSelectOption);
  }, [activeItem?.value, createNewItemFromQuery, handleChange, inputValue, validateNewItem]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleAdd();
      }
    },
    [handleAdd],
  );

  const handleBreadcrumbClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const menuItem = target.closest(`.${Classes.MENU_ITEM}`);
    const isLink = target.classList.contains(Classes.BREADCRUMB);
    let parentId;

    if (isLink) {
      parentId = target.getAttribute('href');
    }

    if (!isLink && menuItem) {
      parentId = menuItem.getAttribute('href');
    }

    if (!parentId) return;

    filters.current = parentId === 'root' ? undefined : [['parent_id', 'eq', parentId]];

    const breadcrumbIndex = breadcrumbs.findIndex((item) => item.href === parentId);
    const updated = breadcrumbs.slice(0, breadcrumbIndex + 1);

    setIsLoading(true);
    setBreadcrumbs(updated);
    setInputValue('');
    onQueryChange('');
  };

  const handleItemRenderer = (item, itemProps: ICustomItemRendererProps): JSX.Element | null => {
    itemProps = parseItemRenderProps ? parseItemRenderProps(item, itemProps) : itemProps;
    return itemRenderer(item, itemProps, { isAncestryMode });
  };

  const handleItemListRender = ({ filteredItems, renderItem }): React.ReactElement => {
    const items = filteredItems.filter((item) => item !== null);
    const hasItems = items.length;

    if (isAncestryMode) {
      return (
        <AncestryDropdown
          isLoading={isLoading}
          items={items}
          renderItem={renderItem}
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      );
    }

    if (isLoading) {
      return (
        <Menu>
          <MenuItem disabled icon={<Loader size={16} />} text="Loading..." />
        </Menu>
      );
    }

    const trimmedValue = inputValue.trim();
    const exactEmailMatch = items.some((item) => item.itemType === 'user' && item.email === trimmedValue);
    const showCreateTag = allowNewItems && validateNewItem(trimmedValue) && !exactEmailMatch;

    if (hasItems) {
      return (
        <Menu>
          {showCreateTag && <CreateTagElement text={inputValue} onClick={handleAdd} />}
          {items.map(renderItem)}
        </Menu>
      );
    }

    if (showCreateTag) {
      return (
        <Menu>
          <CreateTagElement text={inputValue} onClick={handleAdd} />
        </Menu>
      );
    }

    return (
      <Menu>
        <MenuItem disabled text="Nothing found." />
      </Menu>
    );
  };

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation?.errorMessage : ''}
      inline={inline}
      className={classNames(className, { 'form-select-inline__container': inline })}
      style={style}
    >
      <MfxSelect
        className="form-select"
        activeItem={activeItem}
        disabled={disabled}
        onItemSelect={handleChange}
        items={options}
        itemRenderer={handleItemRenderer}
        popoverProps={isAncestryMode ? { ...popoverProps, isOpen: isPopoverOpen } : popoverProps}
        onQueryChange={onQueryChange}
        itemPredicate={itemFilter}
        resetOnClose
        resetOnSelect
        inputProps={{
          onKeyUp: handleEnter,
          onClick: handleClick,
          value: inputValue,
        }}
        itemListRenderer={handleItemListRender}
      >
        <Button
          className={classNames('form-select__button', { empty: !value })}
          rightIcon={
            <>
              {(showValid || showError) && <ValidationMarker {...validation} />}
              <MantineIcon icon="caret-down" />
            </>
          }
          fill
          onClick={handleClick}
          loading={isInitiating}
          text={activeItem?.label || formattedPlaceholder}
          {...{ intent, disabled, name, large }}
        />
      </MfxSelect>
    </FormGroup>
  );
};

export default FormRemoteSelect;
