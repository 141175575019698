import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { flattenDeep } from 'lodash';

import { useStore } from 'store';

import { DataSectionPreset } from 'components/data-section-preset';

import { FilterCheckbox, FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { EqFilter, IFiltersDefinition, QFilter, RangeFilter, Model } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { useFilters } from 'helpers/filters';
import {
  getCustomOwnersOptionsProvider,
  getCustomOrganizationsOptionsProvider,
} from 'helpers/filters/option-providers';

export interface IDeliveryFilters extends IFiltersDefinition {
  _: QFilter;
  status: EqFilter;
  deliverd_at: RangeFilter;
  owner_id: EqFilter;
  connection_type: EqFilter;
  organization_id: EqFilter;
}

const typeOptionsProvider = new Mm3ContextDataProvider(Model.CONNECTION, 'type');
const statusOptionsProvider = new Mm3ContextDataProvider(Model.DELIVERY_PACKAGE, 'status');

export const DeliveryFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;
  const isOrganizationPersistantFilter = flattenDeep(searchStore.persistentFilters).find(
    (value: string) => value === 'organization_id',
  );

  const ownerAggregation = searchStore.aggregationValues?.('owner_id');
  const ownersOptionsProvider = useMemo(() => getCustomOwnersOptionsProvider(ownerAggregation), [ownerAggregation]);

  const organizationAggregation = searchStore.aggregationValues?.('organization_id');
  const organizationsOptionsProvider = useMemo(
    () => getCustomOrganizationsOptionsProvider(organizationAggregation),
    [organizationAggregation],
  );

  const handleSubmit = useCallback((action): void => {
    submitFilters({
      action,
    });
  }, []);

  const [filterValues, filterHandlers] = useFilters<IDeliveryFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Status"
            name="status"
            optionsProvider={statusOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('status')}
          />

          <FilterDateRange
            label="Sent at"
            name="deliverd_at"
            filter={filterValues.deliverd_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />

          {!isOrganizationPersistantFilter && (
            <FilterSelect
              label="Organization"
              name="organization_id"
              placeholder="Select Organization"
              optionsProvider={organizationsOptionsProvider}
              filter={filterValues.organization_id}
              onChange={filterHandlers.onChange}
            />
          )}

          <FilterCheckbox
            label="Connection Type"
            name="connection_type"
            optionsProvider={typeOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.connection_type}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('connection_type')}
          />
        </form>
      </div>
    </div>
  );
});
