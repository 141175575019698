import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import { DetailsPageTabs, IProduct, Product3Types } from 'types';
import { SortDataSection } from 'components/sort-data-section';
import { useSortDataSectionStore } from 'store/hooks';
import { getProductsAncestryInfo, queryProducts, updateProducts } from 'utils/apis/product';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';
import { childrenUpdateParser } from 'utils/children-sort';
import { isDirectDescendantsTab } from 'pages/product-details/utils';
import { FormNumericInput } from 'helpers/form';
import { by } from 'utils/general';

import './style.scss';
import { ParametronSort } from 'helpers/filters/types';

interface IChildrenTabPreviewProps {
  product: IProduct;
  type: Product3Types;
  parentId: number;
}

const ChildProductSortItem: React.FC<{ product: IProduct }> = ({ product }): JSX.Element => {
  const { list, updateStore } = useSortDataSectionStore();
  const onValueChange = useCallback(
    ({ sequence_number }) => {
      updateStore(list.map((item) => (item.id === product.id ? { ...item, sequence_number } : item)));
    },
    [list, product.id, updateStore],
  );

  return (
    <ProductDataSectionListItem
      product={product as IProduct}
      isAllItemsSelected={false}
      active={NaN}
      id={product.id}
      handleClick={noop}
      isChecked={false}
      contextMenu={<></>}
      rightSection={
        <FormNumericInput
          name="sequence_number"
          label="sequence no."
          value={product.sequence_number || 1}
          onChange={onValueChange}
          className="product-children-sort__number"
          size="sm"
        />
      }
    />
  );
};

const itemRenderer = (product: IProduct): JSX.Element => {
  return <ChildProductSortItem product={product} />;
};

const onSave = async (list: IProduct[]): Promise<void> => {
  await updateProducts(list.map(({ id, sequence_number }) => ({ id, sequence_number })));
};

export const ProductChildrenSortTab: React.FC<IChildrenTabPreviewProps> = observer(({ type, parentId, product }) => {
  const { initStore, clear: clearSortStore } = useSortDataSectionStore();
  const { tabLevel1 } = useParams<{ tabLevel1: DetailsPageTabs }>();

  const tabParentId = isDirectDescendantsTab(product.type, tabLevel1) ? product.id : parentId ?? product.id;

  const fetcher = useCallback(async (): Promise<IProduct[]> => {
    if (!tabParentId || !type) {
      return [];
    }

    const products = await queryProducts({ per: 250, sort: ParametronSort.FLAT_SEQUENCE_NUMBER }, [
      ['type', 'eq', type],
      ['ancestor_ids', 'eq', tabParentId],
    ]);
    const ancestryInfos = await getProductsAncestryInfo(products.map((e) => e.id));
    const ancestryByProductId = by(ancestryInfos, 'product_id');

    // make product sequence number start from 1 instead of 0, see #3035
    const shouldIncrementSeqNumbers = products[0]?.sequence_number === 0;

    products.map((product) => {
      if (shouldIncrementSeqNumbers) {
        product.sequence_number = (product.sequence_number || 0) + 1;
      }

      product.ancestry_info = ancestryByProductId[product.id];
    });

    return products;
  }, [type, tabParentId]);

  useEffect(() => {
    initStore({ fetcher, onSave, updateParser: childrenUpdateParser });

    return () => {
      clearSortStore();
    };
  }, [initStore, fetcher, clearSortStore]);

  return <SortDataSection withCustomSorting itemRenderer={itemRenderer} />;
});
