import { IShouldDisplayConnectionActionFunction } from './types';

export const shouldDisplayActivation: IShouldDisplayConnectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.context === 'single' && entities[0]?.status === 'inactive';
};

export const shouldDisplayDeactivation: IShouldDisplayConnectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.context === 'single' && entities[0]?.status === 'active';
};
