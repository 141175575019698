import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from '@mantine/core';
import { Footer } from 'components/footer';
import { useNavigate } from 'react-router';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

const Imprint: React.FC<{}> = observer(() => {
  const navigate = useNavigate();

  const handleBackClick = (e): void => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="imprint">
      <div className="imprint__head pb-5 d-flex justify-content-center">
        <Title order={3}>Imprint</Title>
      </div>
      <div className="imprint__line" />
      <div className="imprint__content pt-4 pb-4">
        <a href="#" className="imprint__back" onClick={handleBackClick}>
          <MantineIcon className="me-1" icon="arrow-left" />
          Back
        </a>
        <section className="mt-4 mb-4">
          <Title order={4} className="mt-4 mb-2">
            Disclaimer and Legal Notice
          </Title>
          <div className="running-text">
            Responsible for this web site is mediafellows GmbH, represented by its senior management. mediafellows GmbH
            is a limited company under German law, with headquarters in Berlin. It is registered in the commercial
            register of Berlin-Charlottenburg under HRB 215464 B. <br />
            mediafellows regularly checks and updates the information appearing on its web site. In spite of our best
            efforts, data may change before such modification is reflected on our web pages. Therefore, we can neither
            assume liability, nor offer any warranty for the timeliness, accuracy or completeness of the information
            provided. mediafellows also reserves the right to make changes or additions to the information it makes
            available at any time. <br />
            You should assume everything you see or read on this site is copyrighted unless otherwise noted. <br />
            mediafellows might provide hyperlinks throughout this site to other web sites for your convenience. These
            web sites are independent from mediafellows and any reference in this web site to any products, services,
            processes, hypertext links to third parties or other information by trade name, trade-mark, manufacturer,
            supplier or otherwise does not necessarily constitute or imply its endorsement, sponsorship or
            recommendation by mediafellows. Product and service information is the sole responsibility of each
            individual operator. It is your responsibility to verify any information contained within linked sites
            before relying on it. Also, the information contained in the links may be changed or updated at any time
            without notice. Linked sites may have their own legal information that you should locate and review.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title order={4} className="mt-4 mb-2">
            Contact Information
          </Title>
          <div className="imprint__contact running-text">
            <div>
              <Title className="title-icon" order={6}>
                <MantineIcon style={{ color: 'var(--mfx-gray-2--dark)' }} className="me-1" icon="map" /> Address
              </Title>
              mediafellows GmbH <br />
              Greifswalder Str. 226, 10405 Berlin, Germany
            </div>
            <div>
              <Title className="title-icon" order={6}>
                <MantineIcon style={{ color: 'var(--mfx-gray-2--dark)' }} className="me-1" icon="mobile-phone" /> Phone
              </Title>
              +49 30 52001286 <br />
            </div>
            <div>
              <Title className="title-icon" order={6}>
                <MantineIcon style={{ color: 'var(--mfx-gray-2--dark)' }} className="me-1" icon="envelope" /> Email
              </Title>
              <a href="mailto:info@mediafellows.com">info@mediafellows.com</a>
            </div>
            <div>
              <Title className="title-icon" order={6}>
                <MantineIcon style={{ color: 'var(--mfx-gray-2--dark)' }} className="me-1" icon="office" /> Registration
              </Title>
              <span>Court: </span> Amtsgericht Berlin-Charlottenburg <br />
              <span>Number: </span>HRB 215464 B <br />
              <span>VAT: </span>DE328786245
            </div>
            <div>
              <Title className="title-icon" order={6}>
                <MantineIcon style={{ color: 'var(--mfx-gray-2--dark)' }} className="me-1" icon="mugshot" /> Managing
                Director
              </Title>
              Torsten Graf-Oettel
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
});

export default Imprint;
