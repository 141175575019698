import React, { useMemo } from 'react';

import { IAsset, IMm3Asset, ISearchFilter } from 'types';

import { AssetsDataSection } from 'components/asset/assets-data-section';
import { IUseActionsOptionParam } from 'utils/actions';

interface IAssetSubAssetsTabProps {
  asset?: IAsset | IMm3Asset;
}

export const AssetSubAssetsTab: React.FC<IAssetSubAssetsTabProps> = ({ asset }) => {
  const { id } = asset || {};
  const [persistentFilters, actionsOptions] = useMemo<[ISearchFilter[], Partial<IUseActionsOptionParam<IMm3Asset>>]>(
    () => [[['parent_id', 'eq', id || 0]], { parentId: id || 0 }],
    [id],
  );

  return <AssetsDataSection persistentFilters={persistentFilters} actionsOptions={actionsOptions} />;
};
