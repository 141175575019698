import React from 'react';
import { observer } from 'mobx-react-lite';

import { OrganizationLogo } from 'components/organization/organization-logo';

import { IOrganization } from 'types';

import { pluralEntityInflect } from 'utils/general';
import { Classes } from 'utils/ui';

import './style.scss';

const OrganizationSelectedTabItem: React.FC<{ organization: IOrganization }> = observer(({ organization }) => {
  const { users_count, name, classification } = organization;
  const { entityWithCount } = pluralEntityInflect('Contact', users_count || 0);
  const orgAttributes = [entityWithCount, ...(classification ? [classification] : [])].join(' | ');

  return (
    <div className="organization-selected-tab-item">
      <div className="organization-selected-tab-item__avatar px-2">
        <OrganizationLogo organization={organization} size="xs" />
      </div>
      <div className="organization-selected-tab-item__content">
        <div className="text-truncate mb-1">{name}</div>
        <div className={`text-truncate ${Classes.TEXT_MUTED}`}>{orgAttributes}</div>
      </div>
    </div>
  );
});

export default OrganizationSelectedTabItem;
