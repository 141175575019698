import { IWorkflowActionName } from 'utils/actions/workflow/types';
import { IWorkflow } from 'types';
import { IShouldDisplayActionFunction, Pages } from 'utils/actions/types';
type IShouldDisplayMarketingEventActionFunction = IShouldDisplayActionFunction<IWorkflow, IWorkflowActionName>;

export const shouldDisplayRestart: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options.context === 'single' &&
    options.page === Pages.LIST &&
    entities?.length === 1 &&
    !entities[0]?.restarted_job_id &&
    ['failed', 'timeout'].includes(entities[0]?.status)
  );
};

export const shouldDisplayMarkAsResolved: IShouldDisplayMarketingEventActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options.context === 'single' &&
    options.page === Pages.LIST &&
    entities.length === 1 &&
    ['failed', 'timeout'].includes(entities[0]?.status)
  );
};
