import { GroupTypes, IProduct } from 'types';
import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { shouldDisplayAddToSelection as shouldDisplay } from './actions-acl';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';

export const useAddToSelectionAction = (items: IProduct[]): IProductActionConfig => {
  const [openSelectionDialog] = useAddToGroupDialog({
    entityType: 'products',
    items: items.map(({ id, title }) => ({ label: title, value: id })),
    groupType: GroupTypes.SELECTION,
  });

  return {
    name: IProductActionName.ADD_TO_SELECTION,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: `Add to Selection`,
    handler: openSelectionDialog,
  };
};
