import { ToastError } from 'components/toast';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';

export const useDisableWhileSubmitting = (
  handleSubmit: (event?) => Promise<void> | void,
): {
  disabled: boolean;
  onSubmit: (event?) => Promise<void> | void;
} => {
  const { toastStore } = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
      try {
        if (isSubmitting) {
          return;
        }

        setIsSubmitting(true);
        await handleSubmit(e);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
      } finally {
        setIsSubmitting(false);
      }
    },
    [handleSubmit, isSubmitting, toastStore],
  );

  return { onSubmit, disabled: isSubmitting };
};
