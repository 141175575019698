import React, { useCallback } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';

import { contactListSchema } from 'utils/schemas';
import { Model } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { ContactListItem } from 'components/contact-list-item';
import { ContactSelectedTabItem } from 'components/contact-selected-tab-item';
import { ContactPreviewTab } from 'components/contact-preview-tab';
import { defaultContactFilters, contactStats, ContactFilters } from 'components/contact-filters';
import { ContactActionsList } from 'components/action';
import { Pages } from 'utils/actions/types';
import { IContactActionName, IUseActionsOptionParam } from 'utils/actions';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';

import { useRefreshDataSection } from 'utils/hooks';
import { useQuery } from 'utils/url';
import { IContact } from 'types/contact';
import { ActionsMenu } from 'components/action/utils';

import './style.scss';

export const customContactParam = {
  include_deleted: false,
  include_internal_accounts: false,
};

const sidebarTabs = {
  preview: <ContactPreviewTab />,
  filters: <ContactFilters />,
  selectedItemRenderer(contact) {
    return <ContactSelectedTabItem contact={contact} />;
  },
};

interface IContactDataSectionProps {
  page: Pages;
  actionsOptions?: Partial<IUseActionsOptionParam>;
  persistentFilters?: IFilterOption[];
  headerRightSection?: React.ReactElement;
  persistFilters?: boolean;
}

export const ContactDataSection: React.FC<IContactDataSectionProps> = observer((props) => {
  const { page = Pages.LIST, actionsOptions, persistentFilters, headerRightSection, persistFilters } = props;
  const {
    dataSectionStore: { checked },
  } = useStore();

  const refreshDataSection = useRefreshDataSection();
  const queryParams = useQuery<Pick<IContact, 'status' | 'created_at'>>(['status', 'created_at']);

  useControlDataSection({
    persistFilters,
    schema: contactListSchema,
    filters: persistentFilters,
    defaultFilters: defaultContactFilters,
    model: Model.CONTACTS,
    stats: contactStats,
    params: customContactParam,
    queryParams,
  });

  const onSuccess = useCallback(
    async (action: IContactActionName, entity?: IContact): Promise<void> => {
      actionsOptions?.onSuccess?.(action, entity);
      if (action === IContactActionName.REMOVE_FROM_GROUP) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection, actionsOptions],
  );

  const handleItemRenderer = (contact: IContact): React.ReactElement => {
    return (
      <ContactListItem
        key={contact.id}
        contact={contact}
        contextMenu={
          <ActionsMenu
            component={ContactActionsList}
            items={[contact]}
            options={{ ...(actionsOptions || {}), context: 'single', page, onSuccess }}
          />
        }
      />
    );
  };

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={handleItemRenderer}
      headerRightSection={headerRightSection}
      showListTileViewToggleButton={false}
      contextMenu={
        <ActionsMenu
          component={ContactActionsList}
          items={checked}
          options={{ ...(actionsOptions || {}), context: 'multi', page, onSuccess }}
        />
      }
    />
  );
});

export default ContactDataSection;
