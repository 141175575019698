export const affiliationSchema = `
  id,
  brand_header,
  public_access,
  password_expiration_interval,
  primary_seller_domain_id,
  primary_seller_domain,
  primary_buyer_domain_id,
  primary_buyer_domain,
  logo_id,
  logo,
  email_cc,
  email_bcc,
  email_reply_to,
  contact_email,
  registration_notification_emails,
  uses_conferencing,
  uses_meeting_calendars,
  uses_product_baskets,
  uses_mm3_products,
  uses_mm3_assets,
  mobile_app_background_color,
  mobile_app_signature_color,
  splash_screen_id,
  splash_screen, 
  mobile_app_splash_screen,
`;
