import React from 'react';
interface IVolumeInputProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}

const VolumeInput: React.FC<IVolumeInputProps> = ({ volume, onVolumeChange }) => {
  return (
    <input
      aria-label="volume"
      name="volume"
      type="range"
      min={0}
      step={0.05}
      max={1}
      value={volume}
      className="audio-player__volume-input"
      onChange={(e) => {
        onVolumeChange(e.currentTarget.valueAsNumber);
      }}
    />
  );
};
export default VolumeInput;
