export const logins: Record<string, string> = {
  loginCount: 'v3/interaction/logins/count__user_logins',
  loginDevices: 'v3/interaction/logins/pie_chart__contact_platforms',
  loginActiveDaysAvg: 'v3/interaction/logins/count__logins_by_weekdays_avg',
  loginList: 'v3/interaction/logins/det_list__contact_logins',
  loginListExport: 'v3/interaction/logins/det_list__contact_logins_export',
};

export const productPageViews: Record<string, string> = {
  productCount: 'v3/interaction/product_views/count__contact_product_views',
  topProducts: 'v3/interaction/product_views/top__contact_products',
  topGenres: 'v3/interaction/product_views/pie_chart__contact_top_genres',
  productList: 'v3/interaction/product_views/det_list__contact_product_views',
  productListExport: 'v3/interaction/product_views/det_list__contact_product_views_export',
};

export const videoViews: Record<string, string> = {
  screeningCount: 'v3/interaction/screenings/count__contact_screenings',
  screeningTopRuntime: 'v3/interaction/screenings/list__contact_screening_top_duration',
  screeningTopVideos: 'v3/interaction/screenings/list__contact_top_screenings',
  screeningList: 'v3/interaction/screenings/det_list__contact_screenings',
  screeningListExport: 'v3/interaction/screenings/det_list__contact_screenings_export',
};

export const assetDownloads: Record<string, string> = {};

export const recommendationsSent: Record<string, string> = {
  recommendationSentCount: 'v3/recommendations/sent/count',
  recommendationSentList: 'v3/recommendations/sent/list',
  recommendationSentSuccessRate: 'v3/recommendations/sent/success_rate',
  recommendationTopRecipients: 'v3/recommendations/sent/top_recommendees',
};

export const recommendationsReceived: Record<string, string> = {
  recommendationReceivedCount: 'v3/recommendations/received/count',
  recommendationReceivedList: 'v3/recommendations/received/list',
  recommendationReceivedEngagementRate: 'v3/recommendations/received/engagement',
  recommendationTopSenders: 'v3/recommendations/received/top_recommenders',
  recommendationScreeningsList: 'v3/recommendations/screenings/list',
  recommendationScreeningData: 'v3/asset/screening/summary',
};
