import merge from 'lodash/merge';

import { getRequiredError, IValidationResult } from 'helpers/form/mm3';
import { Product3Types, IProduct, IProductLayer } from 'types';
import { cleanUp } from 'utils/payload';
import { slugify } from 'utils/slug';

export interface ICreateFormData {
  product: IProduct;
  layer: IProductLayer;
}

export function customValidator(
  { type, parent_id }: IProduct,
  validations: IValidationResult<IProduct>,
): IValidationResult<IProduct> {
  if ([Product3Types.FORMAT, Product3Types.PROGRAM, Product3Types.SERIES].includes(type)) return validations;

  return merge({}, validations, {
    parent_id: {
      required: true,
      ...(parent_id ? {} : getRequiredError()),
    },
  }) as IValidationResult<IProduct>;
}

export const prepareProductPayload = ({ product, layer }: ICreateFormData): ICreateFormData => {
  const slug = slugify(layer.title);
  return cleanUp({ product: { ...product, meta: { ...product.meta, slug } }, layer });
};
export const customLabel = {
  title: 'Title',
};
