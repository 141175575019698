import React from 'react';
import { Button } from '@mantine/core';

import { DetailsPageTabs, IIdentifiable, IAccessPrivilege } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useAddProductsAction, useAddContactsAction, useAddOrganizationsAction } from 'utils/actions/access-privilege';
import { useRefreshDataSection } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';

interface IAddItemsToAccessPrivilegeButtonProps {
  currentTab: DetailsPageTabs;
  accessPrivilege: IAccessPrivilege & IIdentifiable;
  disabled: boolean;
  onSuccess?: () => void;
  hidden: boolean;
}

export const AddItemsToAccessPrivilegeButton: React.FC<IAddItemsToAccessPrivilegeButtonProps> = ({
  currentTab,
  accessPrivilege,
  disabled,
  onSuccess,
  hidden,
}) => {
  const refreshDataSection = useRefreshDataSection();

  const options: IUseActionsOptionParam = {
    onSuccess: onSuccess || refreshDataSection,
    context: 'single',
    page: Pages.DETAILS,
  };
  const entities = [accessPrivilege];
  const addProducts = useAddProductsAction({ entities, options: { ...options, addSelectedProductAncestors: true } });
  const addContacts = useAddContactsAction({ entities, options });
  const addOrganizations = useAddOrganizationsAction({ entities, options });

  let action;

  switch (currentTab) {
    case DetailsPageTabs.PRODUCTS:
      action = addProducts;
      break;

    case DetailsPageTabs.CONTACTS:
      action = addContacts;
      break;

    case DetailsPageTabs.ORGANIZATIONS:
      action = addOrganizations;
      break;

    default:
      return <></>;
  }
  const { handler, icon, title } = action;

  return (
    <Button
      size="xs"
      leftSection={<MantineIcon icon={icon} />}
      disabled={disabled}
      onClick={handler}
      variant="primary"
      hidden={hidden}
    >
      {title}
    </Button>
  );
};
