import React from 'react';

import { IConference } from 'types';

import ScrollWrapper from 'components/scroll-wrapper';
import { IUseFormReturn } from 'helpers/form';
import { ConferenceAttachmentsSection, ConferenceSettingsSection } from 'components/conference/conference-form';
import './style.scss';

export interface IConferenceOverviewTabProps {
  conferenceForm: IUseFormReturn<IConference>;
}

export const ConferenceOverviewEditTab: React.FC<IConferenceOverviewTabProps> = ({ conferenceForm }) => {
  return (
    <ScrollWrapper className="conference-details-overview-edit-tab">
      <ConferenceSettingsSection
        className="conference-details-overview-edit__settings"
        wrapperClassName="mt-3"
        conferenceForm={conferenceForm}
        title="settings"
      />
      <ConferenceAttachmentsSection
        className="conference-details-overview-edit__attachements mt-3"
        conferenceForm={conferenceForm}
        title="attachments"
      />
    </ScrollWrapper>
  );
};
