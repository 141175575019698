import React, { useCallback } from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { IAllowedListItemType, IMarketingEvent, ISearchFilter } from 'types';
import { FormSliderShowcase } from 'components/showcase';
import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';
import { IUseActionsOptionParam } from 'utils/actions';
import { createList } from 'utils/apis/showcase';
import { IEntitiesToQuery, createListItems, deleteListItems } from 'utils/apis/showcase-item';
import { IFormMultiSelectOption } from 'helpers/form';

import { IMarketingEventActionName } from 'utils/actions/event/types';
import { getAllListItems } from 'utils/apis/showcase-item';
import { getShowcaseByPurpose } from 'utils/apis/showcase';

interface ISliderFormProps {
  event: IMarketingEvent;
  sliderShowcase?: UmGenericListShowcase | null;
  onSubmit: (items: IFormMultiSelectOption[]) => Promise<void>;
  options: IUseActionsOptionParam<UmGenericListShowcase>;
  allowedItemTypes: IAllowedListItemType[];
  additionalFilters?: { [key in IEntitiesToQuery]?: ISearchFilter[] };
}

const SliderForm: React.FC<ISliderFormProps> = observer(
  ({ onSubmit, sliderShowcase, event, options, allowedItemTypes, additionalFilters }) => {
    const { toastStore, dialogStore } = useStore();
    const handleSubmit = useCallback(
      async (items: IFormMultiSelectOption[]): Promise<void> => {
        try {
          await onSubmit(items);
          options?.onSuccess?.(IMarketingEventActionName.MANAGE_SLIDER);
          toastStore.success(
            `Slider for "${event.name}" has been ${sliderShowcase?.id ? 'updated' : 'created'} successfully!`,
          );
          dialogStore.close();
        } catch (error) {
          options?.onFailure?.();
          toastStore.error(<ToastError error={error} />);
        }
      },
      [onSubmit, options, toastStore, event?.name, sliderShowcase?.id, dialogStore],
    );

    return (
      <FormSliderShowcase
        allowed_item_types={allowedItemTypes}
        additionalFilters={additionalFilters}
        sliderId={sliderShowcase?.id}
        onSubmit={handleSubmit}
      />
    );
  },
);

export const useEventShowcase = (
  entities: IMarketingEvent[],
  options: IUseActionsOptionParam,
  purpose: string,
  allowedItemTypes: IAllowedListItemType[],
  additionalFilters?: { [key in IEntitiesToQuery]?: ISearchFilter[] },
): (() => Promise<void>) => {
  const { dialogStore } = useStore();
  const [event] = entities;

  const onCreate = useCallback(
    async (items: IFormMultiSelectOption[]): Promise<void> => {
      const sliderShowcase = await createList({
        meta: { page: 'configuration' },
        purpose,
        name: purpose,
        type: 'List::Showcase',
        access_level: 'public',
        allowed_item_types: allowedItemTypes,
      });
      await createListItems(items, sliderShowcase?.id);
    },
    [allowedItemTypes, purpose],
  );

  const handler = useCallback(async () => {
    if (!event?.id) {
      return;
    }

    const sliderShowcase = await getShowcaseByPurpose(purpose);
    const onUpdate = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const listItems = await getAllListItems(sliderShowcase?.id);
      const itemsIds = listItems.map((e) => e.id || 0);
      await deleteListItems(sliderShowcase?.id, itemsIds);
      await createListItems(items, sliderShowcase?.id);
    };

    dialogStore.openModal({
      title: `Manage ${purpose?.endsWith('slider') ? 'Showcase' : 'Hosts'}`,
      size: IModalSize.M,
      body: () => (
        <SliderForm
          event={event}
          onSubmit={sliderShowcase?.id ? onUpdate : onCreate}
          sliderShowcase={sliderShowcase}
          options={options}
          allowedItemTypes={allowedItemTypes}
          additionalFilters={additionalFilters}
        />
      ),
    });
  }, [event, dialogStore, purpose, onCreate, options, allowedItemTypes, additionalFilters]);

  return handler;
};
