import React from 'react';
import { useSessionStore } from 'store/session-store';
import { UserRoles } from 'types';

export const RoleAcl: React.FC<{
  allowedRoles?: UserRoles[];
  prohibitedRoles?: string[];
  children: React.ReactNode;
}> = ({ children, allowedRoles = [], prohibitedRoles = [] }) => {
  const session = useSessionStore((state) => state.session);
  const { role } = session || {};
  if (!role?.name) {
    return <></>;
  }

  if (
    (allowedRoles?.length && !allowedRoles.includes(role.name as UserRoles)) ||
    (prohibitedRoles?.length && prohibitedRoles?.includes(role.name))
  ) {
    return <></>;
  }

  return <>{children}</>;
};
