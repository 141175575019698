import { useState } from 'react';
import { IWizardStepProps } from './wizard-step';

export enum TransitionDirections {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export interface IWizardStep {
  id: number;
  nextButtonTitle?: string;
  isLoading?: boolean;
  action?: (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
}

export interface IWizardStepButton {
  action: (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
  hide?: boolean;
  disabled?: boolean;
}
interface IUseWizardStepsParams {
  defaultStepId?: number;
}

type IUseWizardSteps = (
  steps: IWizardStep[],
  params?: IUseWizardStepsParams,
) => {
  nextButton: IWizardStepButton;
  backButton: IWizardStepButton;
  currentStepId: number;
  setCurrentStepId: (arg: number) => void;
  isStepDisabled: (arg: number) => boolean;
};

export interface IWizardProps {
  selectedStepId: number;
  defaultSelectedStepId?: number;
  className?: string;
  onChange?: (newStepId: number) => void;
  footer?: JSX.Element;
  footerPadded?: boolean;
  isLoading?: boolean;
  loadingMessage?: React.ReactNode;
  showStepTitleIndex?: boolean;
  fitInParent?: boolean;
  children: IWizardStepElement[];
}

export const isStepDisabled = (stepId: number, steps: IWizardStep[]): boolean => {
  const stepIndex = steps.findIndex(({ id }) => id === stepId);

  if (stepIndex === -1) {
    return true;
  }

  const step = steps[stepIndex];
  const isDisabled = step?.disabled ?? false;

  if (stepIndex === 0) {
    return isDisabled;
  }

  return steps.slice(0, stepIndex).some((step) => step?.disabled);
};

export const useWizardSteps: IUseWizardSteps = (steps, params) => {
  const [currentStepId, setCurrentStepId] = useState<number>(params?.defaultStepId ?? steps[0]?.id);

  const currentStepIndex = steps.findIndex((s) => s.id === currentStepId);
  const currentStep = steps[currentStepIndex];

  const nextStep = steps[currentStepIndex + 1];
  const prevStep = steps[currentStepIndex - 1];

  const goNextStep = (): void => {
    return setCurrentStepId(nextStep?.id);
  };

  const goPrevStep = (): void => {
    return setCurrentStepId(prevStep.id);
  };

  const nextButton: IWizardStepButton = {
    disabled: currentStep?.disabled,
    action: currentStep.action ?? goNextStep,
    title: currentStep.nextButtonTitle ?? 'Next',
  };

  const backButton: IWizardStepButton = {
    action: goPrevStep,
    title: 'Back',
    hide: currentStepIndex === 0,
  };

  const isStepDisabledWrapped = (stepId): boolean => isStepDisabled(stepId, steps);

  return { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled: isStepDisabledWrapped };
};

export const detectDefaultSelectedStep = (props: React.PropsWithChildren<IWizardProps>): number => {
  const { children, defaultSelectedStepId } = props;
  if (defaultSelectedStepId) {
    return defaultSelectedStepId;
  }

  if (Array.isArray(children)) {
    return (children?.[0] as React.ReactElement).props.id;
  }

  return (children as React.ReactNode)?.['props']?.['id'];
};

export type IWizardStepElement = React.ReactElement<IWizardStepProps & { children: React.ReactNode }>;

export const filterExistingChildren = (children: React.ReactNode): IWizardStepElement[] => {
  if (!children) {
    return [];
  }

  const existing = Array.isArray(children) ? children : [children];

  return existing?.filter(Boolean) as IWizardStepElement[];
};
