import React from 'react';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { FormMultiSelect } from 'helpers/form';
import { getKeywords } from 'components/product-edit-sections/utils';
import { PmItvstudiosLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

interface IProductEditSectionProps {
  layerForm: IUseMm3FormReturn<PmItvstudiosLayer3MotionPictureStandard>;
}

export const ProductEditSearchSection: React.FC<IProductEditSectionProps> = observer(({ layerForm }) => {
  const { formData: layerData, handlers: layerHandlers } = layerForm;
  return (
    <InfoBoxV2 title="Search" gridTemplateColumns="1fr" wrapperClassName="pb-3">
      <FormMultiSelect
        name="meta.keywords"
        label="Keywords"
        placeholder="Add keywords"
        {...layerData.meta?.keywords}
        {...layerHandlers}
        fetchValues={getKeywords}
        rawLabel
        allowNewItems
        large
      />
    </InfoBoxV2>
  );
});
