import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { DateRange, DateRangeInput, TimePickerProps } from '@blueprintjs/datetime';
import cx from 'classnames';
import { IDatePickerBaseProps } from '@blueprintjs/datetime/lib/esm/datePickerCore';
import { isValid } from 'date-fns';
import { HTMLInputProps, InputGroupProps2 } from '@blueprintjs/core';

import { formatFormLabel } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { RangeFilter } from 'helpers/filters/types';
import { FormGroup } from 'helpers/form/fields/form-group';
import { formatDate, parseDate, defaultDateFormat, dateWithoutHours } from 'utils/date';
import { Intent } from 'utils/ui';
import { IStyled } from 'types';

import { emptyValue, getShortcuts } from './utils';
import { ResetButton } from './reset-button';

import './style.scss';

export type IDateRangeFilterDefinition = RangeFilter;

export interface IFormDateRangeProps
  extends Partial<IDatePickerBaseProps>,
    IStyled,
    Partial<IFieldData<DateRange>>,
    IFieldHandlers<DateRange> {
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  name: string;
  withTime?: boolean;
  label?: string;
  large?: boolean;
  inputProps?: HTMLInputProps & InputGroupProps2;
  onChange: (newValue: { [key: string]: DateRange }) => void;
  wide?: boolean;
  showShortcuts?: boolean;
  hideResetButton?: boolean;
}

const timePickerProps: TimePickerProps = { precision: 'minute', showArrowButtons: true };

/**
 * If you need to use this component with date range structure of properties, not an array
 * { start_date: ..., end_date: ... }
 * then you can utilize `useDateRangeSplit`
 *
 * E.g.:
 *
 * `const [eventTimeData, eventTimeHandlers] = useDateRangeSplit('event_time', 'event_starts_at', 'event_ends_at', form);`
 *
 */
export const FormDateRange: React.FC<IFormDateRangeProps> = (props) => {
  const {
    label,
    required = false,
    touched = false,
    validation,
    className,
    value,
    minDate,
    maxDate,
    onChange,
    onBlur,
    name,
    withTime = false,
    inputProps,
    large = false,
    wide = false,
    showShortcuts = true,
    hideResetButton = false,
    ...restProps
  } = props;

  const shortcuts = useMemo(
    () => (showShortcuts ? getShortcuts(minDate, maxDate) : false),
    [minDate, maxDate, showShortcuts],
  );

  const handleDateParse = useCallback(
    (str: string) => {
      if (!str) return null;
      const date = new Date(str);
      if (!isValid(date)) return false;
      return parseDate(new Date(str), minDate, maxDate);
    },
    [maxDate, minDate],
  );

  const handleChange = useCallback(
    (selectedRange: DateRange): void => {
      onChange?.({ [name]: selectedRange });
      onBlur?.(name);
    },
    [onChange, onBlur, name],
  );

  const handleFormatDate = useCallback(
    (date: Date): string => {
      const format = withTime ? defaultDateFormat : dateWithoutHours;
      return formatDate(date, format);
    },
    [withTime],
  );

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const intent = showError ? Intent.DANGER : Intent.NONE;

  // only pass values to reset the fields, when value is present
  // the Time picker for active input is out of sync, see #807
  const valueProp = useMemo(() => {
    if (isEqual(value, emptyValue)) return { value: emptyValue };
    if (value && !withTime) return { value };
    return {};
  }, [value, withTime]);

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation?.errorMessage : ''}
      className={cx({ 'mfx-date-range': wide })}
      contentClassName="d-flex align-items-center"
    >
      <DateRangeInput
        className={cx('flex-fill', className)}
        startInputProps={{ ...inputProps, large }}
        endInputProps={{ ...inputProps, large }}
        defaultValue={value}
        {...valueProp}
        minDate={minDate}
        maxDate={maxDate}
        formatDate={handleFormatDate}
        timePickerProps={withTime ? timePickerProps : undefined}
        parseDate={handleDateParse}
        shortcuts={shortcuts}
        contiguousCalendarMonths={false}
        allowSingleDayRange
        onChange={handleChange}
        closeOnSelection={!withTime}
        {...restProps}
      />
      <ResetButton handleChange={handleChange} hide={hideResetButton} value={value} />
    </FormGroup>
  );
};
