import { ISubtitle } from 'types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useDeleteAction } from './delete';
import { useDownloadAction } from './download';
import { useEditSubtitleAction } from './edit';
import { ISubtitleActionName } from './types';
import { useReplaceSubtitleAction } from './replace';

export const useSubtitleActions = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): IUseActionsResponse<ISubtitleActionName> => {
  const deleteAction = useDeleteAction(items, options);
  const downloadAction = useDownloadAction(items, options);
  const editAction = useEditSubtitleAction(items, options);
  const replaceAction = useReplaceSubtitleAction(items, options);
  const actions = [editAction, downloadAction, replaceAction, deleteAction];

  return useActions<ISubtitle, ISubtitleActionName>(actions, options, items);
};
