import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

import { Routes } from 'utils/routes';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { InfoBox } from 'components/info-box';
import { ProductDescendantsCount } from 'components/product/overview/descendants-count';

import { formatDate, formatLayerDuration } from 'utils/date';
import { IProduct } from 'types';
import ProductAssets from 'components/asset/assets-aggregation';
import { IProductAncestryInfo, IProductAsset, DetailsPageTabs } from 'types';
import { formatField, formatOwnership, formatType } from 'utils/general';
import { useLanguages } from 'utils/hooks';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { CategoriesInfo } from 'utils/ui';
import { ProductAssetsPreview } from 'pages/product-details/product-overview-tab/product-overview-assets-preview';

import 'pages/product-details/product-overview-tab/style.scss';

export interface IProductOverviewTabProps {
  product?: PmItvstudiosProduct3;
  assets?: IProductAsset[];
  ancestry: IProductAncestryInfo;
}

const ProductOverviewTab: React.FC<IProductOverviewTabProps> = observer(({ product, assets, ancestry }) => {
  const { created_at, type, access_level, status, updated_at, published_at, id, sequence_number, parent_id } =
    product || {};
  const default_layer = product?.default_layer;

  const { category_ids, resolutions, language_ids, product_state, year_of_production, logline } =
    default_layer?.meta || {};

  const languageInfo = useLanguages(language_ids);

  return (
    <div className="product-general-tab h-100">
      <div className="product-general-tab__wrapper h-100">
        <div className="product-general-tab__info">
          {logline && (
            <div className="product-general-tab__synopsis">
              <div className="product-general-tab__synopsis-text mb-1" dangerouslySetInnerHTML={{ __html: logline }} />
              <Link to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.METADATA}`}>Read all</Link>
            </div>
          )}

          <div className="product-general-tab__account-info">
            <InfoBox gridTemplateColumns="1fr 1fr" title>
              <div>SETTINGS</div>
              <div>Assets</div>
              <div>
                <ProductAssets
                  assets={assets}
                  baseUrl={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.ASSETS}`}
                  muted={false}
                />
              </div>
              <Row
                label="Access Level"
                value={
                  <>
                    {access_level} <ScheduledChangeIndicator entity={product as unknown as IProduct} />
                  </>
                }
              />
              <Row label="Status" value={status} />
              {/* <Row label="Permissions" value={formatField(permissions)} /> */}
              <Row label="Ownership" value={formatOwnership(product)} />
            </InfoBox>
            <InfoBox gridTemplateColumns="1fr 1fr" title>
              <div>Timestamps</div>
              <Row label="Created at" rawLabel value={formatDate(created_at)} />
              <Row label="Updated at" rawLabel value={formatDate(updated_at)} />
              <Row label="Published at" rawLabel value={formatDate(published_at)} />
            </InfoBox>
            <InfoBox gridTemplateColumns="1fr 1fr" title>
              <div>ABOUT</div>
              <Row hideIfNoValue label="Type" value={formatType(type)} />
              {parent_id ? <Row label="Sequence number" value={sequence_number} /> : <></>}
              <ProductDescendantsCount id={product?.id} ancestry={ancestry} />
              <Row hideIfNoValue label="Production Status" value={product_state} />
              <Row hideIfNoValue label="Year of Production" value={year_of_production} />
              <Row rawValue label="Duration" value={formatLayerDuration(default_layer)} />
              <Row hideIfNoValue label="Resolutions" value={formatField(resolutions)} />
              <Row hideIfNoValue label="Languages" value={languageInfo} />
              <Row hideIfNoValue label="Genres" value={<CategoriesInfo category_ids={category_ids} />} />
            </InfoBox>
          </div>
        </div>

        <ProductAssetsPreview assets={assets} />
      </div>
    </div>
  );
});

export default ProductOverviewTab;
