import React from 'react';

import { FormSectionWrapper } from 'components/form-section-wrapper';
import { FormInput, FormCheckbox } from 'helpers/form';
import { countStringItems } from 'utils/general';
import { ICreateEventStep } from './types';

const StepUsers: React.FC<ICreateEventStep> = ({ form }) => {
  return (
    <div className="event-detail__tab__wrapper">
      <div className="event-detail__tab__content">
        <FormSectionWrapper title="Attendees" gridTemplateColumns={'1fr'}>
          <FormInput
            textarea
            name="approved_emails"
            label={`Approved emails (${countStringItems(form.values.approved_emails)})`}
            rows={9}
            placeholder="one@example.com two@example.com"
            {...form.formData.approved_emails}
            {...form.handlers}
          />
        </FormSectionWrapper>
        <FormSectionWrapper title="Settings" gridTemplateColumns={'1fr'}>
          <FormCheckbox
            name="allow_registered_user_access"
            label="Allow active contacts to attend the event"
            {...form.formData.allow_registered_user_access}
            {...form.handlers}
          />
        </FormSectionWrapper>
      </div>
    </div>
  );
};

export { StepUsers };
