import React, { useMemo } from 'react';
import LoadingBoundary from 'components/loading-boundary/loading-boundary';
import { Loading } from 'components/loading';

import './style.scss';

export interface ISimpleListItem {
  id: string | number;
  label: string;
}

export interface ISimpleList {
  items?: ISimpleListItem[];
  title?: string;
  footer?: React.ReactNode;
}

const SimpleList: React.FC<ISimpleList> = ({ items, title, footer }) => {
  const emptyList = useMemo((): boolean => !items?.length, [items]);

  return (
    <div className="simple-list__container">
      <div className="simple-list__title">{title}</div>
      <LoadingBoundary loading={!items} suspender={<Loading text={'Loading elements...'} />}>
        {!emptyList && (
          <div className="simple-list__items-wrapper">
            {items?.map(({ id, label }) => (
              <div className="simple-list__item" key={id}>
                {label}
              </div>
            ))}
          </div>
        )}
        {emptyList ? <span className="simple-list__empty-message">Empty list</span> : footer}
      </LoadingBoundary>
    </div>
  );
};

export default React.memo(SimpleList);
