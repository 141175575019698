import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { useBasicStore } from 'store/hooks';
import { formatDuration } from 'utils/date';
import { getEntityType, formatAssetType, joinLanguages } from 'utils/general';
import { IAsset, IMm3Asset } from 'types';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { CustomAssetMetadataRow } from 'pages/asset-details/asset-metadata-tab/custom-row';
import { useDivisions } from 'utils/hooks/use-divisions';
import { DivisionsInfo } from 'utils/ui';

export const AssetOldMetadataAboutSection: React.FC<{ asset?: IAsset }> = observer(({ asset }) => {
  const { languages } = useBasicStore();
  const {
    burned_in_subtitles_language_id,
    classification,
    default_layer,
    duration,
    name,
    owner,
    division_ids,
    owner_organization: organization,
  } = asset || {};

  const { first_name, last_name } = owner || {};
  const { notes } = default_layer || {};
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row label="Name" value={name} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <CustomAssetMetadataRow asset={asset as IAsset} />
      <Row label="owner" value={`${first_name} ${last_name}`} size="m" />
      <Row label="organization" value={organization?.name} size="m" />
      <Row label="Divisions" value={<DivisionsInfo division_ids={division_ids} />} size="m" />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row label="Notes" value={notes} size="m" html />
      <Row
        rawLabel
        label="Burned-in Subtitles"
        value={joinLanguages(languages, [burned_in_subtitles_language_id as string])}
        size="m"
        hidden={!isVideo}
      />
    </InfoBoxV2>
  );
});

export const AssetMm3MetadataAboutSection: React.FC<{ asset?: IMm3Asset }> = observer(({ asset }) => {
  const { classification, name, owner, owner_organization: organization, division_ids } = asset || {};
  const { duration, notes } = asset?.meta || {};

  const { first_name, last_name } = owner || {};
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;

  const { divisionsNames } = useDivisions({ division_ids });

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row label="Name" value={name} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <CustomAssetMetadataRow asset={asset as IMm3Asset} />
      <Row label="owner" value={`${first_name} ${last_name}`} size="m" />
      <Row label="organization" value={organization?.name} size="m" />
      <Row label="divisions" value={divisionsNames} size="m" />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row label="Notes" value={notes} size="m" html />
      {/* <Row
        rawLabel
        label="Burned-in Subtitles"
        value={joinLanguages(languages, [burned_in_subtitles_language_id as string])}
        size="m"
        hidden={!isVideo}
      /> */}
    </InfoBoxV2>
  );
});

export const AssetMetadataAboutSection = withAssetVersion(
  AssetOldMetadataAboutSection,
  AssetMm3MetadataAboutSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
