import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { FormTreeSelect, IFormTreeSelectProps } from 'helpers/form';
import { useBasicStore } from 'store/hooks';

export const FormGeoSelect: React.FC<Omit<IFormTreeSelectProps, 'options'>> = observer((props) => {
  const { required, large, placeholder, label, name, className, ...other } = props;
  const { territories } = useBasicStore();

  return (
    <FormTreeSelect
      name={name}
      label={label}
      placeholder={placeholder}
      large={large}
      required={required}
      {...other}
      className={cx('form-control-wrap', className)}
      options={territories}
    />
  );
});
