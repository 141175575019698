import { useCallback } from 'react';
import { IProduct, ItemId } from 'types';
import { shouldDisplayPreviewAsClient as shouldDisplay } from './actions-acl';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { chipmunk } from 'utils/chipmunk';
import { useStore } from 'store';

const fetchImpersonate = async (): Promise<{ login_domain: string; id: string }> => {
  return chipmunk.run(async ({ action }) => {
    return (await action('um.session', 'impersonate_as_preview_user')).object;
  });
};

const getImpersonateLink = async (target?: ItemId): Promise<string | null> => {
  const impersonate = await fetchImpersonate();

  if (!impersonate) {
    return null;
  }

  const domain = impersonate.login_domain || [];

  if (domain.length < 2) {
    return null;
  }

  const id = impersonate.id;
  const ref = document.location.toString();

  const uri = target
    ? `${domain[0]}://${domain[1]}/session/${id}/${target}?ref=${ref}`
    : `${domain[0]}://${domain[1]}/session/${id}?ref=${ref}`;

  return uri;
};

export const usePreviewAsClientAction = (items: IProduct[]): IProductActionConfig => {
  const id = items?.[0]?.id;

  const { toastStore } = useStore();

  const handler = useCallback(async () => {
    toastStore.info('Preparing preview as a client');
    const impersonateLink = await getImpersonateLink(id);

    if (!impersonateLink) {
      toastStore.error('Preview as a client failed');
      return null;
    }

    toastStore.clearAll();

    window.open(impersonateLink, '_blank');
  }, [id, toastStore]);

  return {
    name: IProductActionName.PREVIEW_AS_CLIENT,
    shouldDisplay,
    icon: 'eye-open',
    title: `Preview as client`,
    handler,
  };
};
