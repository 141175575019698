import { format } from 'date-fns';
import { IRecommendationHistogramData } from 'types/analytics';

export const parseTooltipDateTitle = ([context]): string => format(context.parsed.x, 'dd MMMM yyyy');
export const pluralizeTooltipLabel = (count: number, unit: string): string =>
  `${count} ${unit}${count === 1 ? '' : 's'}`;

type IMultiBarChartData = { count: number; date?: string };

export const parseRecommendationDataForMultiBarChart = (
  data: IRecommendationHistogramData[],
): { viewedData: IMultiBarChartData[]; sentData: IMultiBarChartData[] } => {
  const viewedData: IMultiBarChartData[] = [];
  const sentData: IMultiBarChartData[] = [];

  data.forEach((entry) => {
    const { sent_count = 0, viewed_count = 0, date } = entry;
    const lastViewedEntry = viewedData.at(-1);
    const lastSentEntry = sentData.at(-1);

    if (lastViewedEntry && lastViewedEntry.date === entry.date) {
      lastViewedEntry.count = (lastViewedEntry.count || 0) + viewed_count;
    } else {
      viewedData.push({ date, count: viewed_count });
    }
    if (lastSentEntry && lastSentEntry?.date === date) {
      lastSentEntry.count += sent_count;
    } else {
      sentData.push({ date, count: sent_count });
    }
  });
  return { viewedData, sentData };
};
