import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { IAccessGroup } from 'types';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { AccessGroupDataSectionListItem } from './access-group-data-section-list-item';

interface IAccessGroupDataSectionItemProps {
  className?: string;
  accessGroup: IAccessGroup;
  contextMenu?: React.ReactElement;
}

const AccessGroupDataSectionItem: React.FC<IAccessGroupDataSectionItemProps> = observer((props) => {
  const { contextMenu, accessGroup } = props;
  const { id } = accessGroup;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <AccessGroupDataSectionListItem
      contextMenu={contextMenu}
      handleClick={handleClick}
      accessGroup={accessGroup}
      isChecked={isChecked}
      isCheckedDisabled={isAllItemsSelected}
      isActive={isActive}
    />
  );
});

export default AccessGroupDataSectionItem;
