import React, { useCallback } from 'react';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayApproveAction as shouldDisplay } from './actions-acl';
import { approveContacts } from './api';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';
import { ApproveContact } from 'components/contact-form/approve-contact';
import { pluralEntityInflect } from 'utils/general';

export const useApproveContactAction = (items: IContact[], options: IUseActionsOptionParam): IContactActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async () => {
    const { entityWithCount, entity } = pluralEntityInflect('Contact', items.length);

    try {
      const contactIds = items.map(({ id }) => id);
      await approveContacts(contactIds);
      toastStore.success(`${entityWithCount} approved`);
      await options?.onSuccess?.();
      return true;
    } catch (error) {
      toastStore.error(`${entity} approval failed: ${error.text}`);
      await options?.onFailure?.();
      return false;
    }
  }, [items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openModal({
      title: 'Approve Contact',
      body: () => <ApproveContact onConfirm={onConfirm} items={items} />,
    });
  }, [onConfirm, items, dialogStore]);

  return {
    name: IContactActionName.APPROVE,
    shouldDisplay,
    icon: 'tick',
    title: 'Approve',
    handler,
  };
};
