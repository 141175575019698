import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { ContextMenu } from '@blueprintjs/core';
import { useStore } from 'store';

import { IDataSectionLayout, IDataSectionItemStepSize } from 'types';
import { useThrottledResizeObserver } from 'utils/hooks';

export interface IDataSectionContentWrapperProps {
  className?: string;
  contextMenu?: React.ReactElement;
  layout?: IDataSectionLayout;
}

export const DataSectionContentWrapper: React.FC<IDataSectionContentWrapperProps> = observer((props) => {
  const {
    dataSectionStore: { checked },
  } = useStore();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

  const { children, contextMenu, layout = 'list', className } = props;

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!checked.length || !contextMenu) {
      return;
    }

    ContextMenu.show(contextMenu, { left: e.clientX, top: e.clientY }, () => {
      setIsContextMenuOpen(false);
    });

    setIsContextMenuOpen(true);
  };

  const { ref, width } = useThrottledResizeObserver(500);

  return (
    <div
      ref={ref}
      className={cx(
        `data-section__content data-section__content--${layout} h-100`,
        {
          'context-menu--open': isContextMenuOpen,
          'size-tiny': width < IDataSectionItemStepSize.STEP1,
          'size-1': width >= IDataSectionItemStepSize.STEP1 && width < IDataSectionItemStepSize.STEP2,
          'size-2': width >= IDataSectionItemStepSize.STEP2 && width < IDataSectionItemStepSize.STEP3,
          'size-3': width >= IDataSectionItemStepSize.STEP3 && width < IDataSectionItemStepSize.STEP4,
          'size-4': width >= IDataSectionItemStepSize.STEP4 && width < IDataSectionItemStepSize.STEP5,
          'size-5': width >= IDataSectionItemStepSize.STEP5 && width < IDataSectionItemStepSize.STEP6,
          'size-6': width >= IDataSectionItemStepSize.STEP6 && width < IDataSectionItemStepSize.STEP7,
          'size-7': width >= IDataSectionItemStepSize.STEP7 && width < IDataSectionItemStepSize.STEP8,
          'size-8': width >= IDataSectionItemStepSize.STEP8 && width < IDataSectionItemStepSize.STEP9,
          'size-xxl': width >= IDataSectionItemStepSize.STEP9,
        },
        className,
      )}
      onContextMenu={handleContextMenu}
    >
      {children}
    </div>
  );
});

export default DataSectionContentWrapper;
