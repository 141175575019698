import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDialogStore } from 'store';
import { DialogTypes } from 'types';
import ModalDialog from 'components/dialogs/modal-dialog/modal-dialog';
import ConfirmDialog from 'components/dialogs/confirm-dialog/confirm-dialog';
import InfoDialog from 'components/dialogs/info-dialog/info-dialog';

const ModalRenderer = observer(() => {
  const { activeDialogs, close } = useDialogStore();

  return (
    <>
      {activeDialogs.map(({ id, ...dialog }) => {
        switch (dialog.type) {
          case DialogTypes.MODAL:
            return <ModalDialog key={id} {...dialog.props} />;
          case DialogTypes.CONFIRM:
            return <ConfirmDialog key={id} {...dialog.props} close={close} />;
          case DialogTypes.INFO:
            return <InfoDialog key={id} {...dialog.props} />;
          default:
            return <InfoDialog key={id} title={`Missing implementation of dialog`} body={() => <></>} />;
        }
      })}
    </>
  );
});

export default ModalRenderer;
