import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import React from 'react';

export const BodyWrapper: React.FC = ({ children }) => {
  return (
    <div className="d-flex flex-column h-100 w-100">
      <DashboardBreadcrumbs className="mb-3" />
      {children}
    </div>
  );
};
