import { IAssetFile } from 'types';
import { useEffect, useRef } from 'react';

interface IUserInitiateProductParams {
  files: IAssetFile[];
  onDataChange: (data: Partial<IAssetFile>, index: number) => void;
  productId?: string;
}
export function useInitiateProduct({ files, onDataChange, productId }: IUserInitiateProductParams): void {
  const isProductInitialized = useRef(false);
  useEffect(() => {
    if (!productId || !files?.length || isProductInitialized.current) {
      return;
    }
    isProductInitialized.current = true;

    files.map((file, i) =>
      onDataChange(
        {
          products: [productId],
        },
        i,
      ),
    );
  }, [files, onDataChange, productId]);
}
