import { Model } from 'helpers/filters/types';
import { IFormMultiSelectOption } from 'helpers/form';

import { IQueryParams, ICustomField } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';

export const fieldToOption = (field): IFormMultiSelectOption => ({
  value: field.id,
  label: field.value,
});

export const queryCustomFields = (params: IQueryParams, purpose: string): Promise<ICustomField[]> =>
  chipmunk.run(async ({ action }) => {
    try {
      const { objects } = await action(Model.CUSTOM_FIELD, 'search', {
        body: parseToSearchParams(params, [['purpose', 'eq', purpose]]),
        schema: 'id, purpose, value',
      });

      return objects;
    } catch (error) {
      return [];
    }
  });
