import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { GeoScopesRow, TagsList } from 'components/entity-list';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { CustomContactRow } from 'pages/contact-details/contact-overview-tab/custom-contact-row';

import { DivisionsInfo, ResponsibleUser } from 'utils/ui';
import { useCountryNames, useRemote } from 'utils/hooks';
import { getInterestLabels, getCompanyProfileLabels } from 'pages/contact-details/api';
import { ContactAssistants } from 'pages/contact-details/utils';

import { IContact } from 'types';

export const BusinessSection: React.FC<IContact> = (contact) => {
  const {
    organization,
    country_id,
    department,
    function: func,
    assistant_ids,
    responsible_user,
    geo_scopes,
    tags,
    responsibility,
    interests = [],
    customer_segment,
    company_profile,
    territory_rep,
    division_ids,
  } = contact || {};

  const [country] = useCountryNames([country_id]);

  const fetchInterestLabels = useCallback(async () => {
    const labels = await getInterestLabels(interests);
    return labels.join(', ');
  }, [interests]);

  const [interestLabels] = useRemote<string>(fetchInterestLabels, '');

  const fetchCompanyProfiles = useCallback(async () => {
    const labels = await getCompanyProfileLabels(company_profile);
    return labels.join(', ');
  }, [company_profile]);

  const [companyProfiles] = useRemote<string>(fetchCompanyProfiles, '');

  return (
    <InfoBoxV2
      wrapperClassName="py-3"
      gridTemplateColumns="200px 1fr 200px 1fr"
      title="Business"
      withBorder
      className="entity-detail__infobox-wrapper--business"
    >
      <Row
        label="Organization"
        value={<Link to={`/organizations/${organization?.id}`}>{organization?.name || `—`}</Link> || `—`}
        size="m"
      />
      <Row label="Divisions" value={<DivisionsInfo division_ids={division_ids} />} size="m" />
      <Row
        label="Assistants"
        value={!!assistant_ids?.length && <ContactAssistants assistantIds={assistant_ids} />}
        size="m"
      />
      <Row label="Country" value={country} size="m" />
      <Row
        label="Responsible Contact"
        value={
          responsible_user?.first_name && responsible_user?.last_name ? (
            <ResponsibleUser contact={contact} />
          ) : undefined
        }
        size="m"
      />
      <Row label="Department" value={department} size="m" />
      <Row label="Sales contact" value={territory_rep} rawLabel />
      <Row label="Function" value={func} size="m" />
      <Row label="Company Profile" value={companyProfiles} size="m" />
      <CustomContactRow contact={contact} />
      <Row label="Customer Segment" value={customer_segment} size="m" />
      <Row label="Responsibility" value={responsibility} size="m" />
      <GeoScopesRow geo_scopes={geo_scopes} />
      <TagsList tags={tags} className={'contact-general-tab__tag me-1'} />
      <Row label="Interests" value={interestLabels} size="m" />
    </InfoBoxV2>
  );
};
