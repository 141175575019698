import { action, observable, IObservableArray } from 'mobx';
import { filter } from 'lodash';
import { ItemId } from 'types';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { getLocations, createLocation, updateLocation, deleteLocation } from 'utils/apis/location';

export class LocationsStore {
  @observable public locations = observable.array([]) as IObservableArray;
  @observable public active: ItemId;

  private locationsPromise: Promise<void>;

  @action.bound
  public async loadLocations(): Promise<void> {
    if (this.locationsPromise) {
      return this.locationsPromise;
    }

    return (this.locationsPromise = new Promise(async () => {
      const locations = await getLocations();
      this.locations.replace(locations);
      return locations;
    }));
  }

  @action.bound
  public async createLocation(location: CalGenericLocation): Promise<void> {
    try {
      const newLocation = await createLocation(location);
      this.locations.push(newLocation);
    } catch (error) {
      throw error;
    }
  }

  @action.bound
  public async updateLocation(location: CalGenericLocation): Promise<void> {
    try {
      const updatedLocation = await updateLocation(location);
      const updatedLocations = filter(this.locations, (loc) => loc.id !== location.id);
      this.locations.replace([...updatedLocations, updatedLocation]);
    } catch (error) {
      throw error;
    }
  }

  @action.bound
  public setActive(active: ItemId): void {
    this.active = active;
  }

  @action.bound
  public async deleteLocation(id: ItemId | ItemId[]): Promise<void> {
    try {
      await deleteLocation(id);
      this.locations.replace(filter(this.locations, (loc) => loc.id !== id));
    } catch (error) {
      throw error;
    }
  }
}
