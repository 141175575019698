import React from 'react';
import cx from 'classnames';
import { Menu } from '@mantine/core';

import { ParametronSort } from 'helpers/filters/types';

export const customSortMenu = (
  sort: string,
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): React.ReactElement => {
  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
    </Menu>
  );
};
