import React, { useCallback, useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import './style.scss';

import { SectionHeader } from 'components/section-header';
import { DateCountBarChart } from 'components/date-count-bar-chart';
import { StatisticsTopCard } from 'components/statistic-top-card';
import { VideoScreeningsListTable } from 'components/video-screenings-list-table';
import { ScreeningHeatMap } from 'components/screening-heat-map';
import { ActivityChart } from 'components/activity-chart';
import { fetchTopScreenings, fetchTopViewers, fetchActivityStatistics, getHistogramData } from 'utils/apis/analytics';
import { IHistogramData, IAnalyticsProps, DetailsPageTabs } from 'types';
import { IUseTopProductData, IUseTopVideoViewerData } from 'types/analytics';
import { Routes } from 'utils/routes';
import { AssetInteractions } from 'components/analytics/utils';

const AnalyticsEventScreeningPlaybacks: React.FC<IAnalyticsProps> = (props) => {
  const [countByUniqueScreeningsData, setCountByUniqueScreeningsData] = useState([] as IHistogramData[]);
  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData(props).then(({ data }) => {
      setCountByUniqueScreeningsData(data);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-video-screenings__card">
      <SectionHeader title="Screenings" useBackground useBorder />
      <div className="event-analytics-tab-video-screenings__card-content">
        <DateCountBarChart
          unit="playback"
          data={countByUniqueScreeningsData}
          startDate={props.dateRange[0]}
          endDate={props.dateRange[1]}
        />
      </div>
    </div>
  );
};

const AnalyticsTopScreenings: React.FC<IAnalyticsProps> = (props) => {
  const fetcher: () => Promise<IUseTopProductData> = useCallback(() => fetchTopScreenings(props), [props]);

  return (
    <StatisticsTopCard
      title={props.entity === 'user' ? 'Videos Screened Most' : 'Top Videos Screened'}
      statisticName={'views'}
      fetcher={fetcher}
      interaction={AssetInteractions.SCREENINGS}
      url={{
        pathname: `${Routes.ANALYTICS_ASSETS}/${DetailsPageTabs.LIST}`,
        state: props,
      }}
    />
  );
};

const AnalyticsTopScreeningRuntime: React.FC<IAnalyticsProps> = (props) => {
  const fetcher: () => Promise<IUseTopProductData> = useCallback(() => fetchTopScreenings(props), [props]);
  return (
    <StatisticsTopCard
      title={'Videos Screened Longest'}
      statisticName={'min'}
      fetcher={fetcher}
      interaction={AssetInteractions.SCREENINGS}
      url={{
        pathname: `${Routes.ANALYTICS_ASSETS}/${DetailsPageTabs.LIST}`,
        state: props,
      }}
    />
  );
};

const AnalyticsTopViewers: React.FC<IAnalyticsProps> = (props) => {
  const fetcher: () => Promise<IUseTopVideoViewerData> = useCallback(() => fetchTopViewers(props), [props]);

  return (
    <StatisticsTopCard
      title="Top Viewers"
      statisticName={props.entity === 'product' ? 'times' : 'min'}
      fetcher={fetcher}
      interaction={AssetInteractions.SCREENINGS}
      url={{
        pathname: `${Routes.ANALYTICS_CONTACTS}/${DetailsPageTabs.LIST}`,
        state: props,
      }}
    />
  );
};

const AnalyticsVideoScreeningRuntime: React.FC<IAnalyticsProps> = (props) => {
  const [screeningRuntime, setScreeningRuntime] = useState([] as IHistogramData[]);

  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }
    getHistogramData(props).then(({ data }) => {
      setScreeningRuntime(data);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-video-screenings__card">
      <SectionHeader title="Screening Trend" useBackground useBorder />
      <div className="event-analytics-tab-video-screenings__card-content">
        <ScreeningHeatMap data={screeningRuntime} />
      </div>
    </div>
  );
};

const InteractionsVideoScreenings: React.FC<IAnalyticsProps> = (props) => (
  <>
    <div className="event-analytics-tab-video-screenings">
      {props?.queries?.screeningCount && (
        <AnalyticsEventScreeningPlaybacks queryName={props?.queries?.screeningCount} {...props} />
      )}

      {props?.queries?.screeningTopVideos && (
        <AnalyticsTopScreenings queryName={props?.queries?.screeningTopVideos} {...props} />
      )}

      {props?.queries?.screeningTopRuntime && (
        <AnalyticsTopScreeningRuntime queryName={props?.queries?.screeningTopRuntime} {...props} />
      )}

      {props?.queries?.screeningTopViewers && (
        <AnalyticsTopViewers queryName={props?.queries?.screeningTopViewers} {...props} />
      )}

      {props?.queries?.screeningRuntime && (
        <AnalyticsVideoScreeningRuntime queryName={props?.queries?.screeningRuntime} {...props} />
      )}
    </div>

    <div className="event-analytics-tab-video-screenings-list">
      {props?.queries?.screeningList && (
        <VideoScreeningsListTable
          title="Detailed Video Screenings"
          queryName={props?.queries?.screeningList}
          {...props}
        />
      )}

      {props.queries?.screeningActivity && (
        <ActivityChart
          title="Screening Activity"
          subtitle="video playbacks per day"
          fetcher={() =>
            fetchActivityStatistics({
              queryName: props?.queries?.screeningActivity,
              ...props,
            })
          }
          url={''}
          aspectRatio={6}
        />
      )}
    </div>
  </>
);

export default InteractionsVideoScreenings;
