import { useEffect, useRef } from 'react';
import { cloneDeep, get, isEqual, isEmpty } from 'lodash';

import { IJsonLDProperty, IJsonLDSpec } from '@mediafellows/chipmunk';
import { IValidationOpts, fieldValidation, presenceValidation } from 'utils/validations';

import {
  IFieldOptions,
  IFormValidationResults,
  IFormFieldsSettings,
  IFormData,
  IFormHandlers,
  IFieldData,
  IFileExtensions,
} from './types';
import { IFormData as IMM3FormData } from './mm3/types';

import { IDeepPartial } from 'types';
import { IFormState, UpdateFormValuesAction } from './state';

const prepareContextSpecialCases = <T extends {}>(
  values: T,
  context: IDeepPartial<IJsonLDSpec> | null,
): IDeepPartial<IJsonLDSpec> => {
  const newContext = cloneDeep(context || {});
  Object.keys(values).map((key) => {
    const validators = newContext?.properties?.[key]?.validations;
    if (validators) {
      validators.map((v, i) => {
        // if has confirmation validator then we need to remove that validator
        // and add it to the corresponding _confirmation field

        if (v?.confirmation && !key.endsWith('_confirmation') && newContext && newContext.properties) {
          newContext?.properties?.[key]?.validations?.splice(i, 1);
          const confirmationFieldKey = `${key}_confirmation`;
          const obj = newContext.properties[confirmationFieldKey];

          if (obj) {
            if (!obj?.validations) {
              obj.validations = [];
            }

            obj.validations?.push({
              confirmation: { refValue: values[key] },
            });
          }
        }

        return v;
      });
    }
  });

  return newContext;
};

/**
 * Validates all the passed values based on context
 */
export const validate = <T extends {}>(
  values: T,
  context: IDeepPartial<IJsonLDSpec> | null,
): IFormValidationResults<T> => {
  const specialCasesContext = prepareContextSpecialCases(values, context);

  const result = Object.entries<T>(values).reduce((acc, [key, value]) => {
    const fieldContext = specialCasesContext?.properties?.[key];
    let [valid, errorMessage] = fieldValidation<T>(fieldContext?.validations as IValidationOpts[], value as T);

    // check if field is required via the required attribute
    // and not the presence validation
    if (fieldContext?.required) {
      const [isPresent, presenceError] = presenceValidation(value);
      if (!isPresent) {
        [valid, errorMessage] = [isPresent, presenceError];
      }
    }

    return { ...acc, [key]: { valid, errorMessage } };
  }, {});

  return result as IFormValidationResults<T>;
};

/**
 * Checks if field is required based on context data
 */
export const isFieldRequired = (contextProp?: Partial<IJsonLDProperty>): boolean => Boolean(contextProp?.required);

/**
 * Checks if field is writable based on context data
 */
// this is placeholder until wee implement the proper logic for disabling fields
export const isFieldDisable = (contextProp?: Partial<IJsonLDProperty>): boolean =>
  Boolean(contextProp?.writable && false);

/**
 * Calculates if all passed fields are required based on context
 */
export const calculateRequired = <T extends {}>(
  values: T,
  context?: IDeepPartial<IJsonLDSpec>,
): IFormFieldsSettings<T, boolean> => {
  const result: Partial<IFormFieldsSettings<T, boolean>> = {};
  Object.keys(values).map((key) => {
    result[key] = isFieldRequired(context?.properties?.[key]);
  });
  return result as IFormFieldsSettings<T, boolean>;
};

/**
 * Calculates if all passed fields are disabled based on context
 */
export const calculateDisabled = <T extends {}>(
  values: T,
  context?: IDeepPartial<IJsonLDSpec>,
): IFormFieldsSettings<T, boolean> => {
  const result: Partial<IFormFieldsSettings<T, boolean>> = {};
  Object.keys(values).map((key) => {
    result[key] = isFieldDisable(context?.properties?.[key]);
  });
  return result as IFormFieldsSettings<T, boolean>;
};

/**
 * Gets field options (for select fields) based on inclusion validator
 */
export const getFieldOptions = (contextProp?: Partial<IJsonLDProperty>): IFieldOptions => {
  const validator = (contextProp?.validations || []).find((v) => v.inclusion?.in || v.value_inclusion?.in);
  return validator?.inclusion?.in || validator?.value_inclusion?.in || null;
};

/**
 * Gets whether a field is a number or not
 */
export const getIsNumberField = (contextProp?: Partial<IJsonLDProperty>): boolean => {
  return contextProp?.type === 'number';
};

/**
 * Calculates if all passed fields are disabled based on context
 */
export const calculateIsNumber = <T extends {}>(
  values: T,
  context?: IDeepPartial<IJsonLDSpec>,
): IFormFieldsSettings<T, boolean> => {
  const result: Partial<IFormFieldsSettings<T, boolean>> = {};
  Object.keys(values).map((key) => {
    result[key] = getIsNumberField(context?.properties?.[key]);
  });
  return result as IFormFieldsSettings<T, boolean>;
};

/**
 * Calculates options for selects based on validators (for all passed fields)
 */
export const calculateOptions = <T extends {}>(
  values: T,
  context: IDeepPartial<IJsonLDSpec>,
): IFormFieldsSettings<T, IFieldOptions> => {
  const result: Partial<IFormFieldsSettings<T, IFieldOptions>> = {};
  Object.keys(values).map((key) => {
    result[key] = getFieldOptions(context?.properties?.[key]);
  });
  return result as IFormFieldsSettings<T, IFieldOptions>;
};

const getAllowedFileExtensions = (contextProp?: Partial<IJsonLDProperty>): IFieldOptions => {
  const validator = contextProp?.validations?.find((v) => v?.file_extensions?.in);
  return validator?.file_extensions?.in?.map((e) => '.' + e);
};
/**
 * Calculates file extensions for selects based on validators (for all passed fields)
 */
export const calculateExtensions = <T extends {}>(
  values: T,
  context: IDeepPartial<IJsonLDSpec>,
): IFormFieldsSettings<T, IFieldOptions> => {
  const result: Partial<IFormFieldsSettings<T, IFieldOptions>> = {};
  Object.keys(values).map((key) => {
    result[key] = getAllowedFileExtensions(context?.properties?.[key]);
  });
  return result as IFormFieldsSettings<T, IFieldOptions>;
};

/**
 * Fills object with value (for instance used to initialize valid map with `true` values)
 */
export const fillFields = <T extends {}, U>(values: T, initValue: U): IFormFieldsSettings<T, U> => {
  const result: Partial<IFormFieldsSettings<T, U>> = {};
  Object.keys(values).map((key) => {
    result[key] = initValue;
  });
  return result as IFormFieldsSettings<T, U>;
};

/**
 * Function that converts state values, validations etc into IFieldData
 * so converts:
 * {
 *   values: {user_first_name: "First name"},
 *   validations: {user_first_name: {valid: true} }
 * }
 *
 * into:
 * {
 *   user_first_name: {
 *     value: "First name",
 *     validations: {valid: true}
 *   }
 * }
 *
 * so we can easily pass this data to form fields
 */
export const combineData = <T extends {}>(
  values: T,
  validations: IFormValidationResults<T>,
  touched: IFormFieldsSettings<T, boolean>,
  required: IFormFieldsSettings<T, boolean>,
  options: IFormFieldsSettings<T, IFieldOptions | null>,
  extensions: IFormFieldsSettings<T, IFileExtensions>,
  disabled: IFormFieldsSettings<T, boolean>,
  isValueNumber: IFormFieldsSettings<T, boolean | null>,
): IFormData<T> => {
  const result: Partial<IFormData<T>> = {};
  Object.keys(values || {}).map((key) => {
    const fieldData: IFieldData<T> = {
      value: values[key],
      validation: validations[key],
      // on edit mode (the entity has an id) we want to mark all fields
      // as touched so error messages would be displayed by default
      touched: Boolean(values?.['id']) || touched[key],
      required: required[key],
      options: options[key],
      extensions: extensions[key],
      disabled: disabled[key],
      isValueNumber: isValueNumber[key],
    };
    result[key] = fieldData;
  });

  return result as IFormData<T>;
};

/**
 * This is a workaround for fields that depend on a context, like providing some options
 * if it's not set, then until any change options will not be visible.
 *
 * E.g. for the address.label field which is selector with predefined options from the form data
 *
 */
export function useFieldsContextInit<T>(
  formData: IFormData<T>,
  formHandlers: IFormHandlers<T>,
  fields: string[],
): void {
  useEffect(() => {
    const initialized = {};

    for (const field of fields) {
      if (!formData[field]) {
        initialized[field] = '';
      }
    }

    if (Object.keys(initialized).length > 0) {
      formHandlers.onChange(initialized);
    }
  }, [formData, formHandlers, fields]);
}

export const isValidMM3 = <T extends {}>(formData: IMM3FormData<T>, optionalKeys: string[] = []): boolean => {
  const keys = (optionalKeys || []).length === 0 ? Object.keys(formData) : optionalKeys;

  for (const key of keys) {
    const field = get(formData, key);

    if (field?.validation?.valid === false) {
      return false;
    }
  }

  return true;
};

/**
 * Helper to check if form data is valid
 * The aim is to check specific keys, and not entire form data
 */
export const isValid = <T extends {}>(formData: IFormData<T>, optionalKeys: string[] = []): boolean => {
  const keys = (optionalKeys || []).length === 0 ? Object.keys(formData) : optionalKeys;

  const re = new RegExp(`^(${keys.join('|')})`);
  for (const key of Object.keys(formData)) {
    if (!re.test(key)) {
      continue;
    }

    if (formData[key]?.validation?.valid === false) {
      return false;
    }
  }

  return true;
};

export function updateTouchedFieldsFromValues<T extends {}>(
  fieldsTouched: IFormFieldsSettings<T, boolean>,
  values: T,
): IFormFieldsSettings<T, boolean> {
  return Object.keys(values || {}).reduce((acc, key) => {
    if (key in fieldsTouched) {
      return { ...acc, [key]: Boolean(fieldsTouched[key]) };
    }
    return acc;
  }, {} as IFormFieldsSettings<T, boolean>);
}

export const isSameValues = <T extends {}>(state: IFormState<T>, action: UpdateFormValuesAction<T>): boolean => {
  const changedValues = Object.entries(action.values).reduce((acc, [key, value]) => {
    const currentValue = get(state.values, key);
    if (isEqual(currentValue, value)) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  return isEmpty(changedValues);
};

export const useFieldsTouchedRef = <T extends {}>(fieldsTouched: T): React.MutableRefObject<T> => {
  // this is a workaround to make sure handlers reference do not change
  // as that would trigger rerun of use effect and cause various issues, see #2929
  const fieldsTouchedRef = useRef(fieldsTouched);
  useEffect(() => {
    fieldsTouchedRef.current = fieldsTouched;
  }, [fieldsTouched]);

  return fieldsTouchedRef;
};
