import React from 'react';
import { Thumbnail } from 'components/thumbnail';

import { IconAlias } from 'icons/icon-aliases';
import { IExtendedProductCastCrew } from 'types/product';

import './style.scss';

interface ICollectionSelectedItem {
  cast: IExtendedProductCastCrew;
}

export const CastCrewSelectedItem: React.FC<ICollectionSelectedItem> = ({ cast }) => {
  const { name, preview_image, meta, kind } = cast || {};

  return (
    <li className="cast-crew-selected-item text-truncate">
      <Thumbnail
        className="selected-tab-item__thumb"
        image={preview_image?.url}
        fallbackIconName={IconAlias.CAST_CREW}
        showType={Boolean(kind)}
        customTag={kind}
      />
      <div className="selected-tab-item__content text-truncate">
        <div className="text-truncate">{name}</div>
        <div className="text-truncate">{meta?.description}</div>
      </div>
    </li>
  );
};

export default CastCrewSelectedItem;
