import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import DateElement from 'components/date-list-element/date-list-element';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { useCategories } from 'utils/hooks/categories';
import { formatFullName } from 'utils/general';
import { Classes } from 'utils/ui';

interface ICollectionAttributesProps {
  collection: UmGenericListCollection;
}

export const CollectionAttributes: React.FC<ICollectionAttributesProps> = observer(({ collection }) => {
  const { owner, created_at, updated_at, purpose, meta } = collection;
  const categories = useCategories(meta?.category_ids);

  return (
    <div className={cx('collection-list-item-row__attributes', Classes.TEXT_MUTED)}>
      <div className="text-truncate collection-list-item-row__purpose">{purpose}</div>
      <div className="text-truncate collection-list-item-row__owner">{formatFullName(owner, false)}</div>
      <div className="text-truncate collection-list-item-row__genres">{categories}</div>
      <DateElement date={created_at} className="text-truncate collection-list-item-row__created" mfxIcon="created-at" />
      <DateElement className="text-truncate collection-list-item-row__updated" date={updated_at} mfxIcon="updated-at" />
    </div>
  );
});
