import React, { useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useSortDataSectionStore } from 'store';

import { SortDataSection } from 'components/sort-data-section';

import { IExtendedProductCastCrew, IIdentifiable, IProduct } from 'types';
import { ExtendedCastCrewListItemAttributes } from 'components/cast-crew/data-section-item/extended-data-section-item';
import { updateProductLayer } from 'utils/apis/product';
import { CastCrewDataSectionListItem } from 'components/cast-crew/data-section-item/data-section-list-item';
import { fetchCastCrew } from 'utils/apis/cast-crew';
import { byId } from 'utils/general';

const assetListItemRenderer = (cast): JSX.Element => {
  return (
    <CastCrewDataSectionListItem
      key={cast.id}
      cast={cast as IExtendedProductCastCrew}
      AttributesCmp={ExtendedCastCrewListItemAttributes}
    />
  );
};

const ProductCastCrewSortTab: React.FC<{
  product: IProduct;
  setProduct: Dispatch<SetStateAction<IProduct>>;
}> = observer(({ product, setProduct }) => {
  const { initStore } = useSortDataSectionStore<IExtendedProductCastCrew & IIdentifiable>();
  const fetcher = useCallback(async (): Promise<(IExtendedProductCastCrew & IIdentifiable)[]> => {
    const ids = product.default_layer.meta?.cast_crew?.map((e) => e.cast_crew_id || 0);
    if (!ids?.length) {
      return [];
    }

    const castCrewList = await fetchCastCrew({ ids }, [], 'unfurl');
    const castCrewById = byId(castCrewList);
    return product.default_layer.meta?.cast_crew?.map((e, index) => ({
      ...e,
      ...(castCrewById[e.cast_crew_id || 0] || {}),
      cast_crew: castCrewById[e.cast_crew_id || 0],
      id: index + 1,
      index,
    })) as unknown as (IExtendedProductCastCrew & IIdentifiable)[];
  }, [product.default_layer.meta?.cast_crew]);

  const onSave = useCallback(
    async (list: (IExtendedProductCastCrew & IIdentifiable)[]): Promise<void> => {
      if (!product.default_layer?.meta?.cast_crew?.length) {
        return;
      }

      const initialList = product.default_layer.meta.cast_crew;
      const result = await updateProductLayer({
        id: product.default_layer.id,
        product_id: product.id,
        meta: { ...product.default_layer.meta, cast_crew: list.map((e) => initialList[e.index || 0]) },
      });
      setProduct((p) => ({ ...p, default_layer: result }));
    },
    [product, setProduct],
  );

  useEffect(() => {
    initStore({ fetcher, onSave });
  }, [fetcher, initStore, onSave]);

  return <SortDataSection itemRenderer={assetListItemRenderer} />;
});

export default ProductCastCrewSortTab;
