import { IAsset, IMm3Asset } from 'types';

import { useSelectionDialog } from 'components/form-selection-items';
import { shouldDisplayMultiNotDeletedMainAssets as shouldDisplay } from './actions-acl';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { getValidIds } from 'utils/general';

export const useCreateSelectionAction = (items: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const [openSelectionDialog] = useSelectionDialog({ entityType: 'assets', itemIds: getValidIds(items) });

  return {
    name: IAssetActionName.CREATE_SELECTION,
    shouldDisplay,
    icon: 'select',
    title: 'Create selection',
    handler: openSelectionDialog,
  };
};
