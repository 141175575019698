import { subWeeks, startOfWeek } from 'date-fns';
import { Routes } from 'utils/routes';
import { compact, has, isUndefined } from 'lodash';
import { IChipmunk, IResult } from '@mediafellows/chipmunk';
import { DateRange } from '@blueprintjs/datetime';

import { Model } from 'helpers/filters/types';
import { chipmunk, tuco } from 'utils/chipmunk';
import { DetailsPageTabs, IAsset, IContact, IHistogramData } from 'types';
import { queryAssets, getAssets } from 'utils/apis/asset';
import { queryContacts } from 'utils/apis/contacts';
import { queryProducts } from 'utils/apis/product';
import { byId } from 'utils/general';
import { dateFormat, formatDate, today } from 'utils/date';
import {
  IEventTopProductsHistogramData,
  IEventTopScreeningsHistogramData,
  IEventTopViewersHistogramData,
  ITopProductsData,
  ITopVideosData,
  ITopVideoViewersData,
  IUseActivityData,
  IUseTopProductData,
  IUseTopVideoData,
  IUseTopVideoViewerData,
  IAnalyticsActivityProps,
  IRecommendationTopRecipientsHistogramData,
  IAnalyticsTableData,
  IAnalyticsTableItem,
  IExportAnalyticsTableDataParams,
  IFetchTopUsersEntity,
  IGetAnalyticsTableDataParams,
  IHistogramQueryParams,
  IAnalyticsProps,
  IUseItemData,
  IUseTopSearchTermsData,
} from 'types/analytics';
import { territoriesToCountryIds } from 'utils/hooks/territory';
import { omitNilValues } from 'utils/ui';
import { analyticsContactSchema } from 'utils/schemas/contact';
import { flags } from 'utils/flags';

const initialMinDate = subWeeks(today, 2);

const parseProductsForAssetAnalytics = (
  products?: (string | number)[][] | null,
): {
  id: number;
  title: string;
}[] => {
  return products?.map((product) => ({ id: Number(product?.[0]), title: product?.[1]?.toString() })) || [];
};

const formatAnalyticsDate = (date?: Date | number | string | null): string => formatDate(date, dateFormat);

const prepareHistogramParams = (params: IAnalyticsProps): IHistogramQueryParams => {
  const geo_scope_ids = territoriesToCountryIds(params.geoScopeIds);
  const {
    entity,
    queryName: query_name,
    entityId: ids,
    dateRange: dateRange,
    userIds: user_ids,
    senderIds: sender_ids,
    recipientIds: recipient_ids,
    productIds: product_ids,
    includeDescendants: include_descendants,
    limit,
    page,
    per,
  } = params;
  const entityIds = Array.isArray(ids) ? ids : [ids];
  const [from_date, to_date] = dateRange;

  let queryParams: IHistogramQueryParams = {
    query_name,
    from_date: formatAnalyticsDate(from_date),
    to_date: formatAnalyticsDate(to_date),
    geo_scope_ids,
    include_descendants,
    sender_ids,
    recipient_ids,
    limit,
    page,
    per,
  };

  if (Boolean(ids)) {
    queryParams = { ...queryParams, [`${entity}_ids`]: compact(entityIds) };
    // clash of properties with same name
    if (entity && `${entity}_ids` !== 'user_ids' && user_ids) {
      queryParams.user_ids = user_ids;
    }
    if (entity && `${entity}_ids` !== 'product_ids' && product_ids) {
      queryParams.product_ids = product_ids;
    }
  } else {
    queryParams = { ...queryParams, user_ids, product_ids };
  }
  return omitNilValues(queryParams) as IHistogramQueryParams;
};

export function getHistogramData<T = IHistogramData>(
  params: IAnalyticsProps,
): Promise<{
  data: T[];
  pagination?: {
    total_count: number;
  };
}> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const {
      object: { data },
      pagination,
    } = await action(Model.ANALYTICS, 'show', {
      params: prepareHistogramParams(params),
      schema: 'data',
    });
    return {
      data,
      pagination,
    };
  });
}

export interface IAnalyticsDashboardData {
  user: IUseItemData;
  product: IUseItemData;
  file: IUseItemData;
  asset: IUseItemData;
}

export const fetchDashboardAnalytics = async (): Promise<IResult<IAnalyticsDashboardData>> =>
  tuco<IAnalyticsDashboardData>('getDashboardStats', {
    isMm3Asset: flags.isMm3Assets,
    today,
    startOfThisWeek: startOfWeek(today),
    startOfLastWeek: subWeeks(startOfWeek(today), 1),
  });

export const fetchActivityStatistics = async (params: IAnalyticsActivityProps = {}): Promise<IUseActivityData[]> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/interaction/logins/count__user_logins',
    dateRange: params?.dateRange || [initialMinDate, today],
  };

  const { data } = await getHistogramData(queryParams);
  return data;
};

export const fetchTopVideoData = async (params): Promise<IUseTopVideoData> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/assets/top_videos_screened',
    dateRange: params?.dateRange || [initialMinDate, today],
    entity: params.entity || 'asset',
    limit: params.limit || 5,
  };

  const { data: statistics, pagination } = (await getHistogramData(
    omitNilValues(queryParams) as unknown as IAnalyticsProps,
  )) as unknown as ITopVideosData;

  const videos = await queryAssets({
    ids: statistics.reduce((acc, { asset }) => (asset?.id ? [...acc, asset.id] : acc), []),
  });

  const videoData = statistics.map(({ asset, count, last_occured_at, products }) => {
    const fullVideo = videos.find(({ id }) => Number(id) === Number(asset?.id));
    const thumbnail = fullVideo?.preview_image?.url;
    const { id = '0', name = '' } = asset || {};

    return {
      id: Number(id),
      title: name,
      count,
      lastViewed: last_occured_at,
      thumbnail,
      url: `${Routes.ASSETS}/${id}/${DetailsPageTabs.OVERVIEW}`,
      products: parseProductsForAssetAnalytics(products),
    };
  });

  return {
    data: videoData,
    pagination,
  };
};

export const fetchTopVideoViewers = async (params): Promise<IUseTopVideoViewerData> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/asset/screening/top_viewers',
    dateRange: params?.dateRange || [initialMinDate, today],
    entity: params.entity || 'user',
    limit: params.limit || 5,
  };

  const { data: statistics, pagination } = (await getHistogramData(queryParams)) as unknown as ITopVideoViewersData;

  const contactsWithIds = statistics?.reduce((acc, { user }) => (user.id !== '0' ? [...acc, user.id] : acc), []);
  const idToUserMap = await fetchExtraUserInfo(contactsWithIds);

  const videoViewerStatistics = statistics.map(({ user, total_duration, last_occured_at }) => {
    const contact = user || {
      first_name: 'Unknown',
      last_name: 'Visitor',
    };

    const id = parseInt(user.id, 10);

    return {
      id,
      title: user.full_name || '',
      count: Math.round(total_duration / 60),
      lastViewed: last_occured_at,
      contact: { ...contact, ...idToUserMap[id] },
      url: id !== 0 ? `/contacts/${user.id}/overview` : '',
    };
  });

  return {
    data: videoViewerStatistics,
    pagination,
  };
};

export const fetchTopProductData = async (params): Promise<IUseTopProductData> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/products/top_products',
    dateRange: params?.dateRange || [initialMinDate, today],
    limit: params.limit || 5,
  };

  const { data: statistics, pagination } = (await getHistogramData(queryParams)) as unknown as ITopProductsData;

  const products = await queryProducts({
    ids: statistics.reduce((acc, { product }) => (product?.id ? [...acc, product.id] : acc), []),
  });

  const productStatistics = statistics.map(({ product, count, last_view }) => {
    const { id = 0, title = '' } = product || {};
    const fullProduct = products.find(({ id: pid }) => `${pid}` === `${id}`);

    const thumbnail = fullProduct?.preview_image?.url || fullProduct?.inherited_preview_image?.url;

    return {
      id,
      title,
      count,
      lastViewed: last_view,
      thumbnail,
      url: `${Routes.PRODUCTS}/${id}/${DetailsPageTabs.OVERVIEW}`,
    };
  });

  return {
    data: productStatistics,
    pagination,
  };
};

export const fetchEventTopProducts = async (params): Promise<IUseTopProductData> => {
  if (!params?.dateRange.every(Boolean)) {
    return { data: [] };
  }

  const { data: topProductsData, pagination } = (await getHistogramData({
    ...params,
    limit: 5,
  })) as unknown as IEventTopProductsHistogramData;
  const products = await queryProducts({
    ids: topProductsData.reduce((acc, { product }) => (product?.id ? [...acc, product.id] : acc), []),
  });

  const eventProductData = topProductsData.map(({ product: { id, title }, count, last_occured_at, last_view }) => {
    const fullProduct = products.find(({ id: pid }) => `${pid}` === `${id}`);
    const thumbnail = fullProduct?.preview_image?.url || fullProduct?.inherited_preview_image?.url;

    return {
      id: Number(id),
      title,
      count,
      lastViewed: last_occured_at || last_view,
      thumbnail,
      url: `${Routes.PRODUCTS}/${id}/${DetailsPageTabs.OVERVIEW}`,
    };
  });

  return {
    data: eventProductData,
    pagination,
  };
};

const screeningSchema = `
  id,
  preview_image,
`;

async function fetchExtraScreeningInfo(screeningIds: string[]): Promise<{ [id: string]: IAsset }> {
  if (!screeningIds?.length) {
    return {};
  }
  const screenings = await getAssets(screeningIds, screeningSchema);
  if (!screenings) {
    return {};
  }
  return byId(screenings);
}

export const fetchTopScreenings = async (params: IAnalyticsProps): Promise<IUseTopVideoData> => {
  if (!params?.dateRange.every(Boolean)) {
    return { data: [] };
  }

  const queryParams = {
    ...params,
    entity: params.entity || 'asset',
    queryName: params.queryName || 'v3/interaction/screenings/list__contact_screening_top_duration',
    limit: params.limit || 5,
  };

  const { data: topScreeningsData, pagination } = (await getHistogramData(
    omitNilValues(queryParams) as unknown as IAnalyticsProps,
  )) as unknown as IEventTopScreeningsHistogramData;
  const screeningIds = topScreeningsData.map(({ asset: { id } }) => id);
  const idToScreeningMap = await fetchExtraScreeningInfo(screeningIds);

  const screeningData = topScreeningsData.map(
    ({
      asset: { id, name },
      count,
      total_playback_duration,
      last_effective_at,
      last_download,
      last_occured_at,
      products,
    }) => ({
      id: Number(id),
      title: name,
      count: count || Number(Math.round((total_playback_duration as number) / 60)),
      lastViewed: last_occured_at || last_download || last_effective_at,
      thumbnail: idToScreeningMap[id]?.preview_image?.url,
      url: `${Routes.ASSETS}/${id}/${DetailsPageTabs.OVERVIEW}`,
      products: parseProductsForAssetAnalytics(products),
    }),
  );

  return {
    data: screeningData,
    pagination,
  };
};

export async function fetchExtraUserInfo(contactsWithIds: string[]): Promise<{ [id: string]: IContact }> {
  if (!contactsWithIds?.length) {
    return {};
  }
  const contacts = await queryContacts({ ids: contactsWithIds }, undefined, analyticsContactSchema);
  if (!contacts) {
    return {};
  }
  return byId(contacts);
}

export const fetchTopUsers = async (
  params: IAnalyticsProps,
): Promise<{
  contactsWithIds: string[];
  histogramData: IEventTopViewersHistogramData;
}> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/product/views/top__product_viewers',
    entity: params.entity || 'asset',
    limit: params.limit || 5,
  };
  const histogramData = (await getHistogramData(queryParams)) as unknown as IEventTopViewersHistogramData;

  const { data: topUsersData } = histogramData;
  const contactsWithIds = topUsersData?.reduce((acc, { user }) => (user.id !== '0' ? [...acc, user.id] : acc), []);
  return { contactsWithIds, histogramData };
};

export const fetchTopRecommendationUsers = async (
  params: IAnalyticsProps,
  entityName?: IFetchTopUsersEntity,
): Promise<{
  contactsWithIds: string[];
  histogramData: IRecommendationTopRecipientsHistogramData;
}> => {
  const queryParams = {
    ...params,
    queryName: params.queryName || 'v3/recommendations/sent/top_recommendees',
    entity: params.entity || 'user',
    limit: params.limit || 5,
  };
  const histogramData = (await getHistogramData(queryParams)) as unknown as IRecommendationTopRecipientsHistogramData;

  const { data: topUsersData } = histogramData;

  const resultEntityName = entityName || 'sender';
  const contactsWithIds = topUsersData?.reduce(
    (acc, entity) => (entity[resultEntityName].id !== '0' ? [...acc, entity[resultEntityName].id] : acc),
    [],
  );
  return { contactsWithIds, histogramData };
};

export const fetchTopViewers = async (
  params: IAnalyticsProps,
  entityName: IFetchTopUsersEntity = 'user',
): Promise<IUseTopVideoViewerData> => {
  if (!params?.dateRange.every(Boolean)) {
    return { data: [] };
  }
  const fetcher = entityName === 'user' ? fetchTopUsers : fetchTopRecommendationUsers;
  const {
    contactsWithIds,
    histogramData: { data: topViewersData, pagination },
  } = await fetcher(params, entityName);

  const idToUserMap = await fetchExtraUserInfo(contactsWithIds);
  const viewersData = topViewersData.map((viewerData) => {
    const {
      [entityName]: { id, full_name, email, organization_name },
      last_occured_at,
      last_view,
    } = viewerData;
    const first_name = full_name?.trim().split(' ')[0];
    const last_name = full_name?.substring(first_name.length).trim();

    return {
      id: Number(id),
      title: id !== '0' ? full_name : email,
      count: has(viewerData, 'count') ? viewerData['count'] : Number(Math.round(viewerData['total_duration'] / 60)), //min to seconds
      lastViewed: last_occured_at || last_view,
      contact: {
        first_name,
        last_name,
        full_name: id !== '0' ? full_name : email,
        organization_name,
        ...Object.assign({}, id !== '0' && idToUserMap[id] ? idToUserMap[id] : {}),
      },
      url: id !== '0' ? `${Routes.CONTACTS}/${id}/${DetailsPageTabs.OVERVIEW}` : '',
    };
  });

  return {
    data: viewersData,
    pagination,
  };
};

interface IExportAnalyticsParams {
  exportQuery: string;
  dateRange: DateRange;
  geoScopeIds: string[];
  contactIds: string[];
  productIds: string[];
  includeDescendants: boolean;
  recipientIds?: string[];
  senderIds?: string[];
}

export function exportAnalytics(params: IExportAnalyticsParams): Promise<string[]> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { exportQuery, dateRange, geoScopeIds, contactIds, senderIds, recipientIds, productIds, includeDescendants } =
      params;
    const geo_scope_ids = territoriesToCountryIds(geoScopeIds);
    const exportParams = {
      query_name: exportQuery,
      from_date: formatAnalyticsDate(dateRange[0]),
      to_date: formatAnalyticsDate(dateRange[1]),
      user_ids: contactIds,
      geo_scope_ids,
      product_ids: productIds,
      include_descendants: includeDescendants,
      sender_ids: senderIds,
      recipient_ids: recipientIds,
    };

    const {
      object: { data },
    } = await action(Model.ANALYTICS, 'collection.export', {
      body: omitNilValues(exportParams),
    });

    return data;
  });
}

const prepareQueryParams = (
  params: IGetAnalyticsTableDataParams | IExportAnalyticsTableDataParams,
  exportQuery = false,
): Partial<IGetAnalyticsTableDataParams> => {
  const {
    entity,
    entityId,
    query_name,
    from_date,
    to_date,
    geo_scope_ids,
    user_ids,
    product_ids,
    asset_ids,
    include_descendants,
    recipientIds,
    senderIds,
    only_screenings,
  } = params;

  const entity_ids = Array.isArray(entityId) ? entityId : [entityId];
  const includeEntityIds = !isUndefined(entity) && !isUndefined(entityId);
  const queryParams = {
    query_name: exportQuery ? `${query_name}_export` : query_name,
    from_date: formatAnalyticsDate(from_date),
    to_date: formatAnalyticsDate(to_date),
    geo_scope_ids: territoriesToCountryIds(geo_scope_ids),
    include_descendants,
    user_ids,
    product_ids,
    asset_ids,
    recipient_ids: recipientIds,
    sender_ids: senderIds,
    only_screenings,
    ...(includeEntityIds && { [`${entity}_ids`]: entity_ids }),
  };

  return omitNilValues(queryParams);
};

const fetchContactsInfo = async (data): Promise<IAnalyticsTableItem[]> => {
  const contactsWithIds = data?.reduce((acc, { user }) => (user.id !== '0' ? [...acc, user.id] : acc), []);
  const idToUserMap = await fetchExtraUserInfo(contactsWithIds);
  return data.map((item) => {
    if (item.id !== '0' && idToUserMap[item.id]) {
      return Object.assign({}, item, { user: { ...item.user, ...idToUserMap[item.id] } });
    } else {
      const first_name = item.user.full_name.trim().split(' ')[0];
      const last_name = item.user.full_name.substring(first_name.length).trim();
      return Object.assign({}, item, { user: { ...item.user, first_name, last_name } });
    }
  });
};

type IGetDetailedAnalyticsCustomParser<T = IAnalyticsTableItem[]> = (data: unknown) => Promise<T[]>;

export function getDetailedAnalyticsTableData<T>(
  params: IGetAnalyticsTableDataParams,
  fetchContactInfo = false,
  customParser?: IGetDetailedAnalyticsCustomParser<T>,
): Promise<IAnalyticsTableData<IAnalyticsTableItem>> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { page, per } = params;
    const queryParams = prepareQueryParams(params);

    const {
      object: { data },
      pagination,
    } = await action(Model.ANALYTICS, 'member.show', {
      params: { ...queryParams, page, per },
      schema: 'data',
    });
    const { total_count = 0 } = pagination || {};

    if (!data?.length) {
      return {
        data: [],
        page,
        per,
        total_count: 0,
        total_pages: 0,
      };
    }
    const parser = customParser ?? fetchContactsInfo;
    const parsedData = fetchContactInfo ? await parser(data) : data;
    return {
      data: parsedData,
      page,
      per,
      total_count,
      total_pages: Math.ceil(total_count / per),
    };
  });
}

export function exportAnalyticsTableData(params: IExportAnalyticsTableDataParams): Promise<string[]> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const body = prepareQueryParams(params, true);
    const {
      object: { data },
    } = await action(Model.ANALYTICS, 'collection.export', {
      body,
    });

    return data;
  });
}

export const fetchSearchTopProducts = async (params): Promise<IUseTopProductData> => {
  if (!params?.dateRange.every(Boolean)) {
    return { data: [] };
  }

  const { data, pagination } = (await getHistogramData({
    ...params,
    per: params.per || 5,
    limit: 5,
  })) as unknown as IUseTopSearchTermsData;

  const products = await queryProducts({
    ids: data.map(({ product_id }) => product_id),
  });

  const searchTermProductData = data.map(({ product: { id, title }, hit_count }) => {
    const fullProduct = products.find(({ id: pid }) => `${pid}` === `${id}`);
    const thumbnail = fullProduct?.preview_image?.url || fullProduct?.inherited_preview_image?.url;

    return {
      id: Number(id),
      title,
      count: hit_count,
      thumbnail,
      url: `${Routes.PRODUCTS}/${id}/${DetailsPageTabs.OVERVIEW}`,
    };
  });

  return {
    data: searchTermProductData,
    pagination,
  };
};
