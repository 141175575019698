import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import merge from 'lodash/merge';

import { UpdateFormTouchedAction, UpdateFormValuesAction } from 'helpers/form/state';
import { IFieldValidationResults } from 'helpers/form/types';
import { ItemId } from 'types';

import {
  IFieldsRequiredAndOptionsSettings,
  IFieldsSettings,
  IFieldsValues,
  IFormData,
  IFormState,
  ITouched,
  ITouchedFields,
} from './types';
import { isObject } from 'utils/payload';

/**
 * Function that Recursively read the values object and converts it to fromData format
 * so converts:
 * { layer: { name: 'first name' }}
 *
 * into:
 * { layer: { name: { value: 'first name' }}}
 *
 * so we can easily pass this data to forData
 */
export function formatValuesToFormData<T>(values: T): IFieldsValues<T> {
  return Object.entries<T[keyof T]>(values || {}).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      return { ...acc, [key]: formatValuesToFormData<T[keyof T]>(value) };
    }
    if (Array.isArray(value) && isObject(value[0])) {
      return { ...acc, [key]: value.map(formatValuesToFormData) };
    }

    return { ...acc, [key]: { value, validation: { valid: true } } };
  }, {} as IFieldsValues<T>);
}

/**
 * Recursively read the form data object and return true if touched is true for one of the fields
 */
export const hasFormBeenTouched = <T>(values: ITouchedFields<T>): boolean => {
  if (!isObject(values)) return false;

  return Object.values<ITouched | ITouchedFields<T[keyof T]>>(values).reduce((acc, value) => {
    if (acc) return true;

    if (value.hasOwnProperty('touched')) return (value as ITouched).touched;

    if (isObject(value)) return hasFormBeenTouched(value as ITouchedFields<T[keyof T]>);

    if (Array.isArray(value) && isObject(value[0])) return value.some(hasFormBeenTouched);

    return false;
  }, false);
};

/**
 * Recursively fills object with value (for instance used to initialize valid map with `true` values)
 */
export const deepFillFields = <T, U>(values: T, initValue: U): IFieldsSettings<T, U> => {
  return Object.entries<T[keyof T]>(values || {}).reduce((acc, [key, value]) => {
    if (isObject(value)) return { ...acc, [key]: deepFillFields(value || {}, initValue) };

    if (Array.isArray(value) && isObject(value[0]))
      return { ...acc, [key]: value.map((v) => deepFillFields(v, initValue)) };

    return { ...acc, [key]: initValue };
  }, {} as IFieldsSettings<T, U>);
};

export function touchedFieldsReducer<T>(state: IFormState<T>, action: UpdateFormTouchedAction<T>): IFormState<T> {
  const fieldsTouched = cloneDeep(state.fieldsTouched);

  const a = Object.keys(action.fieldsTouched).reduce((acc, path) => {
    // extra steps to set the value here because lodash's set has side effects if a portion of path doesn't exist
    // see https://lodash.com/docs/4.17.15#set
    const a = set({}, path, { touched: true });
    return merge({}, acc, a);
  }, fieldsTouched);

  return { ...state, fieldsTouched: a };
}

export function valuesReducer<T>(state: IFormState<T>, action: UpdateFormValuesAction<T>): IFormState<T> {
  const values = { ...state.values, ...(state.values?.['meta'] ? { meta: { ...state.values['meta'] } } : {}) };

  Object.entries(action.values).forEach(([path, value]) => {
    set<T>(values, path, value);
  });

  return { ...state, values };
}

export function mergeFormData<T>(
  values: IFieldsSettings<T, { value?: ItemId | ItemId[] }>,
  validations: IFieldsSettings<T, { validation: IFieldValidationResults }>,
  fieldsTouched: IFieldsSettings<T, ITouched>,
  optionsAndRequiredSettings?: IFieldsRequiredAndOptionsSettings<T>,
): IFormData<T> {
  if (!isObject(values)) {
    return { value: values, ...validations, ...fieldsTouched, ...optionsAndRequiredSettings } as IFormData<T>;
  }

  return Object.entries(values).reduce((acc, [key, value]: [string, object & { value?: string | object }]) => {
    if (optionsAndRequiredSettings?.[key]?.anyOf && Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map((v, index) => {
          const typeValue = v.type?.value;
          const list = optionsAndRequiredSettings[key].anyOf;
          const options = list.find((e) => e.type.constantValue === typeValue) || list[0];
          const typeOptions = list.map((e) => e.type.constantValue);
          options.type.options = typeOptions;

          return mergeFormData(
            {
              ...Object.keys(options).reduce((acc, cur) => ({ ...acc, [cur]: undefined }), {}),
              type: { value: options.type.constantValue },
              ...(v || {}),
            },
            validations?.[key]?.[index],
            fieldsTouched?.[key]?.[index],
            options,
          );
        }),
      };
    }

    if (
      Array.isArray(optionsAndRequiredSettings?.[key]) &&
      Array.isArray(value) &&
      isObject(optionsAndRequiredSettings?.[key][0])
    ) {
      const keys = Object.keys(optionsAndRequiredSettings?.[key][0] || {}).reduce(
        (acc, key) => ({ ...acc, [key]: null }),
        {},
      );
      return {
        ...acc,
        [key]: value.map((v, index) =>
          mergeFormData(
            { ...keys, ...(v || {}) },
            validations?.[key]?.[index],
            fieldsTouched?.[key]?.[index],
            optionsAndRequiredSettings?.[key]?.[0],
          ),
        ),
      };
    }

    if (isObject(value) && !('value' in value)) {
      return {
        ...acc,
        [key]: mergeFormData(
          value as object,
          validations?.[key],
          fieldsTouched?.[key],
          optionsAndRequiredSettings?.[key],
        ),
      };
    }

    return {
      ...acc,
      [key]: {
        ...value,
        ...optionsAndRequiredSettings?.[key],
        ...validations?.[key],
        ...fieldsTouched?.[key],
      },
    };
  }, {} as IFormData<T>);
}
