import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Timeline } from 'components/timeline';
import Loading from 'components/loading/loading';
import { useFetchPaginatedData } from 'utils/hooks';
import { IContactActivityData } from 'types';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { fetchContactActivityData } from './api';
import './style.scss';

const ContactActivityTab: React.FC = observer(() => {
  const { contactId = '' } = useParams<{ contactId: string }>();

  const fetchData = useCallback(
    async (page) => {
      return await fetchContactActivityData({ page, contactId });
    },
    [contactId],
  );

  const {
    handleLoadMore,
    loading,
    data: activities,
    currentPage,
  } = useFetchPaginatedData<IContactActivityData>(fetchData);

  if (loading && currentPage === 1) {
    return <Loading text="Loading Activities" />;
  }
  if (!activities) return null;

  return (
    <div className="contact-activity-tab h-100">
      <div className="d-flex justify-content-center h-100">
        <div className="contact-activity-tab__content">
          {!!activities?.length && <Timeline data={activities} handleLoadMore={handleLoadMore} />}
          {!activities?.length && <SectionMessage intent={SectionMessageType.EMPTY} />}
        </div>
        {loading && <Loading text="Loading More" isLoadMore={true} />}
      </div>
    </div>
  );
});

export default ContactActivityTab;
