import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';

import { IAsset } from 'types';
import { SortDataSection } from 'components/sort-data-section';
import { AssetDataSectionListItem } from 'components/asset/asset-data-section-item';
import { useSortDataSectionStore } from 'store/hooks';
import { Model } from 'helpers/filters/types';
import { loadAllGroupItems, updateGroupItemsSequenceNumber } from 'utils/apis/groups';
import { ISortableItem } from 'store/sort-data-store';
import { delay } from 'utils/delay';

export function assetItemRenderer(asset: ISortableItem, disableMarketingStar?: boolean): JSX.Element {
  return (
    <AssetDataSectionListItem
      asset={asset as IAsset}
      isAllItemsSelected={false}
      isActive={false}
      handleClick={noop}
      isChecked={false}
      disableMarketingStar={disableMarketingStar}
      contextMenu={<></>}
    />
  );
}

export const GroupAssetsSortDataSection: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const { initStore } = useSortDataSectionStore();
  const fetcher = useCallback(() => loadAllGroupItems(groupId, 'asset_id'), [groupId]);

  const onSave = useCallback(
    async (list) => {
      await updateGroupItemsSequenceNumber(Model.ASSET_GROUP_ITEMS, groupId, list);
      await delay();
    },
    [groupId],
  );

  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={assetItemRenderer} />;
});

export default GroupAssetsSortDataSection;
