import { isNumber } from 'lodash';
import { emptyValidation, isBlank } from './empty';

/* length validation
 *
 * examples:
 * validations: [ { length: { in: 2..128, allow_blank: true } } ]
 * validations: [ { length: { in: 2..300, allow_blank: true } } ]
 * validations: [ { length: { maximum: 100 } } ]
 * validations: [ { length: { minimum: 2, allow_blank: true } } ]
 * validations: [ { length: { minimum: MIN_STRING_LENGTH, maximum: MAX_STRING_LENGTH } } ]
 */

export interface ILengthValidationOpts {
  in?: string;
  within?: string;
  maximum?: number;
  minimum?: number;
  allow_blank?: boolean;
}

export const lengthValidation = (opts: ILengthValidationOpts, value = ''): [boolean, string?] => {
  const length = Array.isArray(value) ? value.length : ('' + value).trim().length || 0;

  const { allow_blank } = opts;
  let { minimum = 0, maximum = Number.MAX_VALUE } = opts;

  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank }, value);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  if (allow_blank && isBlank(value)) {
    return [true];
  }

  const lengthIn = opts.in || opts.within;
  if (lengthIn) {
    const within = lengthIn.split('..');
    if (within.length === 2) {
      const withinMinimum = parseInt(within[0]);
      const withinMaximum = parseInt(within[1]);
      if (isNumber(withinMinimum) && isNumber(withinMaximum)) {
        minimum = withinMinimum;
        maximum = withinMaximum;
      }
    }
  }

  if (isFinite(minimum) && length < minimum) {
    return [false, `Please enter at least ${minimum} characters`];
  }

  if (isFinite(maximum) && length > maximum) {
    return [false, `Please enter a maximum of ${maximum} ${Array.isArray(value) ? 'items' : 'characters'}`];
  }

  return [true];
};
