import React from 'react';
import { observer } from 'mobx-react-lite';

import ShowcaseSortDataSection from 'components/website-data-section/website-sort-data-section';
import { useRemote } from 'utils/hooks';
import { fetchAllLists } from 'utils/apis/showcase';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { parseToSearchParams } from 'utils/general';
import { Loading } from 'components/loading';
import { IListItem } from 'types';
import { SectionHeader } from 'components/section-header';
import { persistentFilters } from './utils';

import './style.scss';

const getCollections = async (): Promise<IListItem[]> => {
  return (await fetchAllLists(
    parseToSearchParams({ sort: ParametronSort.SEQUENCE_NUMBER, order: ParametronOrder.ASCENDING }, [
      ...persistentFilters,
      ['sublist', 'eq', false],
    ]),
  )) as unknown[] as IListItem[];
};

interface ICollectionsSortPageProps {
  setIsSortModeEnabled: (value: boolean) => void;
  isSortModeEnabled: boolean;
}

export const CollectionsSortPage: React.FC<ICollectionsSortPageProps> = observer(
  ({ setIsSortModeEnabled, isSortModeEnabled }) => {
    const [items, isLoading] = useRemote(getCollections);

    if (isLoading) {
      return (
        <div className="data-section-layout">
          <Loading text="Loading Data" />
        </div>
      );
    }

    return (
      <div className="data-section">
        <SectionHeader
          useBackground
          title="Sort Collections"
          className="collections-sort__header"
          setIsSortModeEnabled={setIsSortModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <ShowcaseSortDataSection items={items} />
      </div>
    );
  },
);

export default CollectionsSortPage;
