import { IGenreTreeItem } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IGenreActionName {
  ADD_SUB_GENRE = 'add-sub-genre',
  EDIT = 'edit',
  DELETE = 'delete',
  SORT_SUB_GENRE = 'sort-sub-genre',
}

export type IGenreActionConfig = IActionConfig<IGenreTreeItem, IGenreActionName>;

export type IShouldDisplayGenreActionFunction = IShouldDisplayActionFunction<IGenreTreeItem, IGenreActionName>;
