export const RecommendationSchema = `
  id,
  name,
  owner,
  access_level,
  created_at,
  deleted_at,
  expires_at,
  updated_at,
  sent_at,
  preview_image {
    url
  },
  requires_login,
  product_ids,
  asset_ids,
  recipient_list,
  generate_links_only,
  cc_list,
  bcc_list,
  subject,
  recommendation_group_id,
  allows_asset_download,
  status,
  allowed_views,
  message,
  type,
  include_future_episodes_of_product_ids,
  visible,
`;
