import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { useDataSectionStore } from 'store';
import { handleItemClick } from 'components/data-section';
import { IExtendedProductCastCrew, IProduct } from 'types';

import { CastCrewDataSectionListItem } from './data-section-list-item';
import { IExtendedCastCrewAttributesProps } from './extended-data-section-item';
import { ICastCrewListItemAttributesProps } from './cast-crew-item-attributes';

interface ICastCrewDataSectionItemProps {
  className?: string;
  cast: IExtendedProductCastCrew & { products?: IProduct[] };
  contextMenu?: React.ReactElement;
  AttributesCmp?: React.FC<IExtendedCastCrewAttributesProps | ICastCrewListItemAttributesProps>;
}

export const CastCrewDataSectionItem: React.FC<ICastCrewDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, cast, AttributesCmp } = props;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore<PmGenericCastCrew>();

  const { id } = cast;
  const handleClick = useCallback(
    (e): void => {
      if (!id) {
        return;
      }

      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': getIsActive(id),
      })}
      onClick={handleClick}
    >
      <CastCrewDataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        cast={cast}
        AttributesCmp={AttributesCmp}
        isChecked={getIsChecked(id)}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
