import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterText } from 'helpers/filters/fields';
import { IFiltersDefinition, InFilter, QFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { useStore } from 'store';

import { useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { StaticDataProvider } from 'helpers/data-provider/option-data-provider';

export interface ICastCrewFilters extends IFiltersDefinition {
  _: QFilter;
  kind: InFilter;
}

const handleSubmit = (action): void => submitFilters({ action });

const typeOptionsProvider = new StaticDataProvider([
  { label: 'Cast', value: 'Cast' },
  { label: 'Crew', value: 'Crew' },
]);

export const ProductCastCrewFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore || {};
  const [filterValues, filterHandlers] = useFilters<ICastCrewFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search Cast & Crews"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Type"
            name="kind"
            optionsProvider={typeOptionsProvider}
            filter={filterValues.kind}
            onChange={filterHandlers.onChange}
            aggregations={searchStore?.aggregationValues('kind')}
          />
        </form>
      </div>
    </div>
  );
});
