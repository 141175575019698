import { IAsset, IMm3Asset } from 'types';

import { useUploadSubtitleDialog } from 'components/asset/upload-subtitle-dialog';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { flags } from 'utils/flags';

import { uploadSubtitle } from './api';
import { shouldDisplayUploadSubtitle as shouldDisplay } from './actions-acl';

export const useUploadSubtitleAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const [openDialog] = useUploadSubtitleDialog({
    onSubmit: async (subtitle, file) => {
      const itemId = items?.[0]?.id;
      if (file && itemId) {
        const result = await uploadSubtitle(itemId, file, subtitle);
        // TODO: link subtitles to mm3 assets
        if (flags.isMm3Assets) {
          throw new Error('Upload subtitles is not supported yet');
        }
        const asset = items[0] as IAsset;
        options.onSuccess?.(IAssetActionName.UPLOAD_SUBTITLE, {
          ...asset,
          subtitles: result?.object ? [...(asset?.subtitles || []), result?.object] : asset?.subtitles,
        });
      }
    },
    message: 'Select a subtitle to upload',
    type: 'attachment',
  });

  return {
    name: IAssetActionName.UPLOAD_SUBTITLE,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload Subtitle',
    handler: openDialog,
  };
};
