import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { Loading } from 'components/loading';
import { BodyWrapper } from 'components/layout';

import { useRemote } from 'utils/hooks';
import { loadDelivery } from 'utils/apis/package-delivery';
import { Pages } from 'utils/actions/types';

import { DeliveryDetails } from './delivery-details';

import './style.scss';

export const DeliveryDetailsContainer: React.FC<{ page?: Pages }> = observer(({ page = Pages.DELIVERIES_DETAILS }) => {
  const { deliveryId } = useParams<{ deliveryId: string }>();
  const fetchDelivery = useCallback(async () => loadDelivery(deliveryId), [deliveryId]);
  const [delivery, loading, setDelivery] = useRemote(fetchDelivery);

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Delivery" />
      </BodyWrapper>
    );
  }

  if (!delivery) {
    return (
      <BodyWrapper>
        <SectionMessage intent={SectionMessageType.EMPTY}>No delivery found</SectionMessage>
      </BodyWrapper>
    );
  }

  return <DeliveryDetails delivery={delivery} setDelivery={setDelivery} page={page} />;
});
