import React from 'react';
import cx from 'classnames';

import { InfoBoxV2 } from 'components/info-box-v2';
import { AddressList, PhonesList, SocialMediaProfilesList } from 'components/entity-list';

import { IContact } from 'types';

export const ContactInformationSection: React.FC<IContact> = (contact) => {
  const { email, address, phones, social_media_profiles } = contact;
  return (
    <InfoBoxV2
      wrapperClassName="py-3"
      className="entity-detail__infobox-wrapper"
      title="Contact Information"
      withBorder
    >
      <InfoBoxV2 gridTemplateColumns="1fr" padded={false}>
        <div className={cx(`contact-general-tab__subtitle mb-2 muted-text`)}>Email</div>
        <a className="contact-general-tab__email text-lowercase" href={`mailto:${email}`}>
          {email}
        </a>
        <AddressList address={address} />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="1fr" padded={false}>
        <PhonesList phones={phones} />
        <SocialMediaProfilesList social_media_profiles={social_media_profiles} />
      </InfoBoxV2>
    </InfoBoxV2>
  );
};
