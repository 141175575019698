import { useCallback } from 'react';

import { IProductAncestryInfo, IProduct, ItemId } from 'types';
import {
  fetchProductsAncestry,
  getProducts,
  fetchPotentialParentsByType,
  getProductSequenceNumber,
} from 'utils/apis/product';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IUseRemoteReturn, useRemote } from 'utils/hooks';
import { IFilterOption } from './control-data-section';

export function useProductAncestry(productId?: number | string): IUseRemoteReturn<IProductAncestryInfo> {
  const refresh = useCallback(async (): Promise<IProductAncestryInfo | undefined> => {
    if (!productId) {
      return;
    }

    const [ancestry] = await fetchProductsAncestry(productId);
    return ancestry;
  }, [productId]);

  return useRemote<IProductAncestryInfo>(refresh);
}

interface IUseSearchForPotentialParents {
  fetchParents: (q: string, id: ItemId, additionalFilters?: IFilterOption[]) => Promise<IProduct[]>;
  onParentChange: ({ parent_id }: Pick<IProduct, 'parent_id'>) => void;
}

export function useSearchForPotentialParents(
  productForm: Pick<
    IUseMm3FormReturn<Pick<IProduct, 'id' | 'type' | 'parent_id' | 'sequence_number'>>,
    'values' | 'handlers'
  >,
): IUseSearchForPotentialParents {
  const { values, handlers } = productForm;
  const type = Array.isArray(values) ? values[0]?.type : values.type;

  const fetchParents = useCallback(
    async (q: string, id: ItemId, additionalFilters?: IFilterOption[]): Promise<IProduct[]> => {
      if (id) {
        return getProducts({ product_ids: id });
      }
      return fetchPotentialParentsByType(q, type, additionalFilters);
    },
    [type],
  );

  const onParentChange = useCallback(
    ({ parent_id }: Pick<IProduct, 'parent_id'>): void => {
      handlers.onChange({ parent_id });
      if (!parent_id) {
        handlers.onChange({ sequence_number: undefined });
        return;
      }

      getProductSequenceNumber([
        ['type', 'eq', type],
        ['parent_id', 'eq', parent_id],
      ]).then((sequenceNumber) => {
        handlers.onChange({ sequence_number: sequenceNumber });
      });
    },
    [handlers, type],
  );

  return { fetchParents, onParentChange };
}
