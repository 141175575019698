import React from 'react';
import cx from 'classnames';

import { StepGeneralInfoFirstColumn } from 'components/event-create/steps/step-general-info/first-column';
import { StepGeneralInfoSecondColumn } from 'components/event-create/steps/step-general-info/second-column';
export { keysGeneralInfo } from 'components/event-create/steps/step-general-info/keys';

import { IStepGeneralInfo } from './types';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { useThrottledResizeObserver } from 'utils/hooks';

const StepGeneralInfo: React.FC<IStepGeneralInfo> = (props) => {
  const { form, editMode = false, containerClassname, showCountdownLabel } = props;

  return (
    <div className="event-detail__tab__wrapper">
      <div className="event-detail__tab__content">
        <FormSectionWrapperV2 title="Overview" className={cx(containerClassname)} wrapperClassName="pt-4">
          <StepGeneralInfoFirstColumn form={form} showCountdownLabel={showCountdownLabel} />
          <StepGeneralInfoSecondColumn form={form} editMode={editMode} />
        </FormSectionWrapperV2>
      </div>
    </div>
  );
};

const StepGeneralInfoV2: React.FC<IStepGeneralInfo> = (props) => {
  const { ref, width } = useThrottledResizeObserver(500);
  const { form, editMode = false, showCountdownLabel } = props;

  return (
    <div ref={ref}>
      <FormSectionWrapperV2 title="Overview" gridTemplateColumns={width < 800 ? '1fr' : '1fr 1fr'}>
        <StepGeneralInfoFirstColumn form={form} showCountdownLabel={showCountdownLabel} />
        <StepGeneralInfoSecondColumn form={form} editMode={editMode} />
      </FormSectionWrapperV2>
    </div>
  );
};

export { StepGeneralInfo, StepGeneralInfoV2 };
