import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { Routes } from 'utils/routes';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { PreviewTab } from 'components/preview-tab';
import { useDataSectionStore } from 'store';
import { IconAlias } from 'icons';
import { CollectionPreviewAttributes } from 'components/collection/collection-preview-tab/collection-preview-attributes';
import { changeAccessLevelSlider } from 'utils/change-access';
import { updateList } from 'utils/apis/showcase';

export const CollectionPreviewTab: React.FC<{ collection?: UmGenericListCollection }> = observer(
  ({ collection: c }) => {
    const { activeItem } = useDataSectionStore<UmGenericListCollection>();
    const collection = c || activeItem;

    const id = collection?.id;

    const handleAccessLevelChange = useCallback(
      (newAccessLevel: string) => {
        return changeAccessLevelSlider({
          apiCall: async ({ access_level }) =>
            updateList({ id, access_level } as Pick<UmGenericListCollection, 'id' | 'access_level'>),
          newAccessLevel,
        });
      },
      [id],
    );

    if (!id) {
      return <span className="entity-preview-tab__no-preview">No preview</span>;
    }

    const { access_level, preview_image, name, preview_image_id } = collection;

    return (
      <PreviewTab
        title={name}
        accessLevel={access_level}
        handleAccessLevelChange={handleAccessLevelChange}
        detailsLink={`${Routes.COLLECTIONS}/${id}`}
        customThumbnail={
          <ThumbnailWithRefresh
            fallbackIconName={IconAlias.COLLECTION}
            entity={{ preview_image_id, preview_image }}
            placeholder={<LoaderWithText text="processing" />}
          />
        }
      >
        <CollectionPreviewAttributes overrideCollection={collection} />
      </PreviewTab>
    );
  },
);
