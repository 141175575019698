import { useCallback } from 'react';

import { useStore } from 'store';

import { deleteGenre } from 'utils/apis/genre';
import { Intent } from 'utils/ui';

import type { ICategory, ItemId } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IGenreActionConfig, IGenreActionName } from './types';

export const useDeleteAction = (items: ICategory[], options: IUseActionsOptionParam): IGenreActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id).filter(Boolean) as ItemId[];
      await deleteGenre(itemIds);
      options?.onSuccess?.(IGenreActionName.DELETE, items);
      toastStore.success('Genre was deleted successfully!');
    } catch (error) {
      toastStore.error(`Genre deletion failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: 'Are you sure you want to delete this genre and all its children?',
      onConfirm: executeDelete,
    });
  }, [dialogStore, executeDelete]);

  return {
    name: IGenreActionName.DELETE,
    shouldDisplay: () => true,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
