import React from 'react';

import { FormDateRange, IFormDateRangeProps } from './form-date-range';

import { DateRange } from '@blueprintjs/datetime';

interface IAnalyticsFormDateRangeFilterProps extends IFormDateRangeProps {
  dateRange: DateRange;
}
export const AnalyticsFormDateRangeFilter: React.FC<IAnalyticsFormDateRangeFilterProps> = (props) => {
  const { minDate, maxDate, onChange, dateRange, name } = props;
  return (
    <FormDateRange
      className="event-analytics-tab_date-range align-self-lg-end"
      validation={{
        valid: Boolean(dateRange[0] && dateRange[1]),
        errorMessage: 'Please pick a complete date range',
      }}
      touched
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      hideResetButton
      onChange={onChange}
      value={dateRange}
    />
  );
};
