import { IProduct } from 'types';

import { useRecommendProductsDialog } from 'components/recommendation';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { shouldDisplayRecommend as shouldDisplay } from './actions-acl';

export const useRecommendAction = (items: IProduct[]): IProductActionConfig => {
  const [openDialog] = useRecommendProductsDialog({
    products: items,
  });

  return {
    name: IProductActionName.RECOMMEND,
    shouldDisplay,
    icon: 'share',
    title: 'Recommend',
    handler: openDialog,
  };
};
