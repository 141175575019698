const index = {
  siteTitle: 'ITV Studios',
  header: {
    logo: {
      alt: 'ITV Studios Logo',
      text: 'ITV Studios MediaStore',
    },
  },
};

export default index;
