import React from 'react';
import isString from 'lodash/isString';

import { hasPower } from 'utils/powers';

interface ICheckPowersProps {
  anyOf?: boolean;
  requiredPowers?: string | string[];
  children?: React.ReactNode;
  hideAnyway?: boolean;
}

export const CheckPowers: React.FC<ICheckPowersProps> = ({ anyOf, requiredPowers, children, hideAnyway }) => {
  const powers = isString(requiredPowers) ? [requiredPowers] : requiredPowers;

  if (!hideAnyway && (!powers || powers[anyOf ? 'some' : 'every'](hasPower))) {
    return <>{children}</>;
  }

  return <></>;
};
