import React, { useCallback } from 'react';

import { ToastError } from 'components/toast';
import { IGroup } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

import { shouldDisplayDeletePreviewAction as shouldDisplay } from './actions-acl';
import { removeGroupPreviewImage } from 'utils/apis/groups';
import { IScreeningRoomActionConfig, IScreeningRoomActionName } from './types';

export const useDeletePreviewAction = (
  items: IGroup[],
  options: IUseActionsOptionParam,
): IScreeningRoomActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async (): Promise<boolean> => {
    try {
      await removeGroupPreviewImage(items[0]);
      toastStore.success('Success');
      options?.onSuccess?.();
      return true;
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder="Preview image deletion failed" />);
      options?.onFailure?.();

      return false;
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: `Delete Preview image`,
      body: 'Are you sure you want to delete this Preview Image?',
      onConfirm,
    });
  };

  return {
    name: IScreeningRoomActionName.DELETE_PREVIEW,
    shouldDisplay,
    icon: 'delete',
    title: 'Delete Preview',
    intent: Intent.DANGER,
    handler,
  };
};
