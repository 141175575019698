import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ValidateFunction } from 'ajv';
import { IJsonSchemaSpec } from '@mediafellows/chipmunk';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { ajv, chipmunk } from 'utils/chipmunk';
import { logger } from 'utils/logger';

import { IFormFieldsSettings, ISetFormFields, IFormMm3Handlers } from './types';
import {
  SetFormFieldsAction,
  UpdateFormActions,
  UpdateFormTouchedAction,
  UpdateFormValuesAction,
  UpdateSchemaAction,
} from './state';
import {
  calculateValidation,
  extractFieldsSettingsFromSchema,
  formatValuesToFormData,
  formReducer,
  formStateInit,
  hasFormBeenTouched,
  IFieldsRequiredAndOptionsSettings,
  IFormData,
  IFormState,
  IValidationResult,
  isFormDataValid,
  ITouched,
  mergeFormData,
} from './mm3';
import { useFieldsTouchedRef } from './helpers';

/**
 * Type of Form Submit handler passed to `useForm` - passes values and validated states to field
 * to form parent
 */
export type IFormSubmitHandler<T> = (values: T, valid: boolean, validations: IValidationResult<T>) => void;

/**
 * data returned from `useMm3Form` hook:
 * - formData: IFormData<T> - field data for every field in form
 * - handlers: IFormHandlers<T> - handler functions like onChange, onBlur that are passed to form inputs
 * - onSubmit: React.FormEventHandler - submit handler that executes the provided onSubmit param and prevents default
 * - valid: boolean - valid state (if whole form is valid)
 * - touched: boolean - touched state (if any field in form was touched)
 * - values: object<T> - form values
 */
export type IUseMm3FormReturn<T> = {
  formData: IFormData<T>;
  handlers: IFormMm3Handlers<T>;
  onSubmit: React.FormEventHandler<HTMLFormElement> & React.MouseEventHandler<HTMLElement>;
  onSetFields: ISetFormFields<T>;
  valid: boolean;
  touched: boolean;
  values: T;
  isSending: boolean;
  resetFields: VoidFunction;
};

export const useMm3Form = <T>(
  initialValues: T,
  appModel: string,
  handleSubmit?: IFormSubmitHandler<T>,
  customValidator?: (values: T, validations: IValidationResult<T>) => IValidationResult<T>,
): IUseMm3FormReturn<T> => {
  const [formState, dispatchFormDataAction] = useReducer<React.Reducer<IFormState<T>, UpdateFormActions<T>>, T>(
    formReducer,
    cloneDeep(initialValues),
    formStateInit,
  );
  const { values, fieldsTouched } = formState;
  const [optionsAndRequiredSettings, setOptionsAndRequiredSettings] = useState<IFieldsRequiredAndOptionsSettings<T>>();
  const [validate, setValidate] = useState<ValidateFunction<IJsonSchemaSpec>>();

  useEffect(() => {
    (async () => {
      try {
        const schema: IJsonSchemaSpec = await chipmunk.spec(appModel);
        if (ajv.getSchema(schema['$id'])) {
          ajv.removeSchema(schema);
        }
        const validate: ValidateFunction<IJsonSchemaSpec> = await ajv.compileAsync<IJsonSchemaSpec>(schema);
        setValidate(() => validate);

        // extract required and options information from schema
        const fieldsOptionsAndRequiredSettings = await extractFieldsSettingsFromSchema<T>(schema);
        setOptionsAndRequiredSettings(fieldsOptionsAndRequiredSettings);

        dispatchFormDataAction(new UpdateSchemaAction(schema));
      } catch (e) {
        // cookie.remove('sessionId');
        logger.info(e);
      }
    })();
  }, [appModel]);

  const onChange = useCallback((newValues: Partial<T>) => {
    dispatchFormDataAction(new UpdateFormValuesAction(newValues));
  }, []);

  const onSetFields = useCallback((fields: T) => {
    dispatchFormDataAction(new SetFormFieldsAction(fields));
  }, []);

  const fieldsTouchedRef = useFieldsTouchedRef(fieldsTouched);

  const onBlur = useCallback(
    (name: keyof T) => {
      const current = get(fieldsTouchedRef.current, name) as unknown;
      const isAlreadyTouched = (current as ITouched)?.touched;

      if (isAlreadyTouched) {
        return;
      }

      dispatchFormDataAction(
        new UpdateFormTouchedAction<IFormFieldsSettings<T, boolean>>({ [name]: true } as IFormFieldsSettings<
          T,
          boolean
        >),
      );
    },
    [fieldsTouchedRef],
  );

  const validations = useMemo(() => {
    validate?.(values);
    const ajvValidations = calculateValidation(values, validate);
    return customValidator?.(values, ajvValidations) || ajvValidations;
  }, [customValidator, validate, values]);

  const valid = useMemo(() => isFormDataValid(validations, values), [validations, values]);

  const [isSending, setIsSending] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (e) => {
      if (isSending) {
        return;
      }

      try {
        setIsSending(true);
        e?.preventDefault();
        await handleSubmit?.(values, valid, validations);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsSending(false);
      }
    },
    [handleSubmit, isSending, valid, validations, values],
  );

  const handlers = useMemo(() => ({ onChange, onBlur }), [onBlur, onChange]);

  const formData = useMemo(() => {
    return mergeFormData<T>(formatValuesToFormData(values), validations, fieldsTouched, optionsAndRequiredSettings);
  }, [fieldsTouched, values, optionsAndRequiredSettings, validations]);

  const touched = useMemo(() => hasFormBeenTouched(fieldsTouched), [fieldsTouched]);
  const resetFields = useCallback(() => {
    onSetFields(initialValues);
  }, [initialValues, onSetFields]);

  return {
    formData,
    handlers,
    onSubmit,
    onSetFields,
    touched,
    valid: valid && !isSending,
    values,
    isSending,
    resetFields,
  };
};
