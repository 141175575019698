import { UmGenericListItem } from '@mediafellows/mm3-types';
import { IUseActionsOptionParam, Pages } from 'utils/actions/types';

export function shouldDisplayRemoveFromList(
  action,
  index,
  user,
  entities: UmGenericListItem[],
  options: IUseActionsOptionParam<UmGenericListItem>,
): boolean {
  return options.page === Pages.COLLECTION_ITEMS && entities?.length > 0;
}

export function shouldDisplayEditTitle(
  action,
  index,
  user,
  entities: UmGenericListItem[],
  options: IUseActionsOptionParam<UmGenericListItem>,
): boolean {
  return options.page === Pages.COLLECTION_ITEMS && entities?.length > 0 && options.context === 'single';
}
