import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ProductFormAdditionalFields } from 'components/product/product-create/product-create-additional-fields';

import { FormInput, FormSelect, FormNumericInput, IFormRemoteSelectOption } from 'helpers/form';
import { ICustomItemRendererProps, IProduct, IProductLayer, ItemId, Product3Types } from 'types';
import { customLabel } from 'pages/product-create/utils';
import { FormRemoteSelect } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { useSearchForPotentialParents } from 'utils/hooks/product';
import { IFilterOption } from 'utils/hooks';
import { getProductSearchModifiers } from 'utils/product';
import { formatType } from 'utils/format-type';

interface IProductFormProps {
  productForm: IUseMm3FormReturn<IProduct>;
  layerForm: IUseMm3FormReturn<IProductLayer>;
  additionalParentFilters: IFilterOption[];
}

export const ProductForm: React.FC<IProductFormProps> = observer(
  ({ productForm, layerForm, additionalParentFilters }) => {
    const { formData, handlers, values } = productForm;
    const { type, parent_id } = values;

    const onTypeChange = useCallback(
      (values: Partial<IProduct>) => {
        handlers.onChange({ ...values, parent_id: undefined });
      },
      [handlers],
    );
    const { fetchParents, onParentChange } = useSearchForPotentialParents(productForm);
    const productType = Array.isArray(values) ? values[0]?.type : values.type;

    const fetchProducts = useCallback(
      (q: string, id: ItemId, filters: IFilterOption[] = []): Promise<IProduct[]> => {
        const shouldAddCurrentProductFilters = (filters || []).every(([key]) => key !== 'parent_id');
        const filtersToApply = shouldAddCurrentProductFilters ? additionalParentFilters.concat(filters) : filters;

        return fetchParents(q, id, filtersToApply);
      },
      [additionalParentFilters, fetchParents],
    );

    const parseItemRenderProps = useCallback(
      (item: IFormRemoteSelectOption, itemProps: ICustomItemRendererProps): ICustomItemRendererProps => {
        if (productType && item.value) {
          const { isDisabled, isNavigationEnabled } = getProductSearchModifiers(productType, item as IProduct);
          itemProps = {
            ...itemProps,
            modifiers: { ...itemProps.modifiers, disabled: isDisabled, isNavigationEnabled },
          };
        }

        return itemProps;
      },
      [productType],
    );

    return (
      <>
        <FormSectionWrapperV2 className="create_product__form__product" title="Product">
          <FormInput
            className="create-product-layer__title-field"
            name="title"
            key="title"
            label={customLabel.title}
            {...layerForm.formData.title}
            {...layerForm.handlers}
          />

          <FormSelect
            name="access_level"
            key="access_level"
            label="Access Level"
            {...formData.access_level}
            {...handlers}
            large
          />

          <FormSelect
            name="type"
            key="type"
            label="Type"
            {...formData.type}
            {...handlers}
            formatLabel={formatType}
            onChange={onTypeChange}
            large
          />

          <>
            {Boolean(type && type !== Product3Types.FORMAT) && (
              <>
                <FormRemoteSelect
                  name="parent_id"
                  key="parent_id"
                  label="Parent"
                  {...formData.parent_id}
                  {...handlers}
                  fetchOptions={fetchProducts}
                  onChange={onParentChange}
                  enableClearing
                  large
                  isAncestryMode
                  parseItemRenderProps={parseItemRenderProps}
                />
                {Boolean(parent_id) && (
                  <FormNumericInput
                    name="sequence_number"
                    key="sequence_number"
                    label="Sequence Number"
                    {...formData.sequence_number}
                    {...handlers}
                  />
                )}
              </>
            )}
            <ProductFormAdditionalFields productForm={productForm} />
          </>
        </FormSectionWrapperV2>
      </>
    );
  },
);

export default ProductForm;
