import React from 'react';

import { IProduct } from 'types';
import ProductCastCrewSortTab from './cast-crew-sort-tab';
import ProductCastCrewPreviewTab from './cast-crew-preview-tab';

interface IProductCastCrewTabProps {
  product: IProduct;
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  isSortModeEnabled: boolean;
}

const ProductCastCrewTab: React.FC<IProductCastCrewTabProps> = ({ product, setProduct, isSortModeEnabled }) => {
  if (isSortModeEnabled) {
    return <ProductCastCrewSortTab product={product} setProduct={setProduct} />;
  }

  return <ProductCastCrewPreviewTab product={product} setProduct={setProduct} />;
};

export default ProductCastCrewTab;
