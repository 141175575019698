import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mantine/core';
import { uniq, groupBy, omit } from 'lodash';

import { IAssetTreeOption, IOmniTreeNode } from 'helpers/form/fields/form-select';

import { getAllChildrenNodes } from 'helpers/form/fields/form-select/tree-selector-utils';
import { formatAssetFilterLabel, getAssetsCount } from './utils';

import { IFieldData } from 'helpers/form/types';

import { ItemId } from 'types/utility';

import './style.scss';

import { IAssetMainClassification } from 'types/asset';

interface IFiltersProps extends IFieldData<ItemId[], IAssetTreeOption[]> {
  handleChange: (value: ItemId[]) => void;
  optionsTree: IOmniTreeNode[];
  value?: ItemId[];
}

const assetClassifications = ['video', 'image', 'document', 'audio'];

export const AssetMainClassificationFilters: React.FC<IFiltersProps> = ({ handleChange, optionsTree, value }) => {
  const assetsOptions = useMemo(() => getAllChildrenNodes(optionsTree), [optionsTree]);

  const filters = useMemo<Record<string, { count: number; checkedCount: number }>>(() => {
    const assetsByClassification = groupBy(assetsOptions, ({ classification }) => {
      if (classification) {
        return classification.split('/')[0];
      }
      return 'nonAssets';
    });

    return Object.keys(omit(assetsByClassification, 'nonAssets')).reduce((acc, classification) => {
      acc[classification] = getAssetsCount(assetsByClassification[classification], value);
      return acc;
    }, {});
  }, [value, assetsOptions]);

  const handleMainClassificationFilter = useCallback(
    (e) => {
      const [filterName, isChecked] = [e.target?.name, e.target?.checked];
      const safeValue = value || [];
      const allAssetNodes = getAllChildrenNodes(optionsTree);

      let newValue: ItemId[] = [];
      if (isChecked) {
        newValue = allAssetNodes.reduce((acc: string[], e) => {
          if (e?.id && e.classification?.startsWith(filterName)) {
            acc.push(e.id);
          }
          return acc;
        }, safeValue);
      } else {
        const classificationValues = new Set();
        allAssetNodes.reduce((acc: string[], e) => {
          if (e?.id && e.classification?.startsWith(filterName)) {
            classificationValues.add(e.id);
          }
          return acc;
        }, []);
        newValue = safeValue.filter((id) => !classificationValues.has(id));
      }
      return handleChange(uniq(newValue));
    },
    [handleChange, optionsTree, value],
  );

  const elements = assetClassifications.reduce((acc, classification: IAssetMainClassification) => {
    if (filters[classification]?.count) {
      const { checkedCount, count } = filters[classification];

      acc[classification] = [
        <Checkbox
          name={classification}
          label={formatAssetFilterLabel(classification, filters[classification])}
          key={classification}
          checked={checkedCount === count}
          indeterminate={checkedCount !== count && checkedCount > 0}
          onChange={handleMainClassificationFilter}
        />,
      ];
    }
    return acc;
  }, {} as Record<IAssetMainClassification, React.ReactNode[]>);

  return <div className="form-omni-checkbox__actions">{Object.values(elements).flatMap((elements) => elements)}</div>;
};
