import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { FormImage, IUseFormReturn } from 'helpers/form';
import type { IStyled } from 'types';
import type { IOrganizationFields } from 'pages/upsert-organization/types';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { Thumbnail } from 'components/thumbnail';

export const ImagesOrganizationEdit: React.FC<IStyled & IUseFormReturn<IOrganizationFields>> = ({
  className,
  formData,
  handlers,
}) => {
  return (
    <InfoBoxV2 className="py-3 gap-2" gridTemplateColumns="0.5fr 1fr 1fr">
      <Row
        className="py-2"
        label="Logo"
        value={<FormImage type="attachment" large name="logo" label="" {...formData.logo} {...handlers} />}
        size="m"
      />
      <Thumbnail image={formData.logo.value} className={className} />

      <Row
        className="py-2"
        label="Banner"
        value={<FormImage type="attachment" large name="banner" label="" {...formData.banner} {...handlers} />}
        size="m"
      />
      <Thumbnail image={formData.banner.value} className={className} />
    </InfoBoxV2>
  );
};
