import { action, IObservableArray, observable, computed } from 'mobx';
import Uppy, { Uppy as IUppy, UppyFile } from '@uppy/core';
import DropZone from '@uppy/drag-drop';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

import { IAssetPresetFields, IAssetFile, IUploadedFile, IFile } from 'types';
import { getConfig } from 'utils/companion';
import { applyPrefixToAsset, parseFileName } from 'utils/assets-upload';
import { chipmunk } from 'utils/chipmunk';
import { formatFileFields } from 'utils/assets-upload-format-file-fields';

interface IUploadFile extends UppyFile {
  meta: {
    filename: string;
    name: string;
    type?: string;
  };
}

const AWS_S3_MULTIPART_UPLOAD_LIMIT = 100;

function getImageOrientation(url: string): Promise<{ orientation: string }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;

    img.onload = function () {
      const { height, width } = img;

      let orientation = 'landscape';
      if (width < height) {
        orientation = 'portrait';
      } else if (height === width) {
        orientation = 'square';
      }

      resolve({ orientation });
    };
  });
}

export class AssetsUploaderStore {
  dropFileAreaSelector: string;
  constructor(dropFileAreaSelector: string) {
    this.dropFileAreaSelector = dropFileAreaSelector;
  }

  @observable uppy: IUppy | null;

  @observable dragZonePlugin;
  @observable idToName = {};

  @action.bound
  initializeUppy(): () => void {
    this.isUploadPending = false;

    if (this.isAssetsUploaderStoreInitialized) {
      this.dragZonePlugin?.mount(this.dragZonePlugin.opts.target, this.dragZonePlugin);
    } else {
      const { companionUrl, power } = getConfig('asset');

      chipmunk.run(async (chipmunk) => {
        const result = await chipmunk.action('um.session', 'token', { params: { power } });
        const jwtToken = result?.object?.token;

        this.uppy = new Uppy({
          id: `assets-upload${this.dropFileAreaSelector}`,
          autoProceed: true,
          allowMultipleUploadBatches: true,
          onBeforeFileAdded: (currentFile: UppyFile): IUploadFile => {
            const filename = parseFileName(currentFile.name);
            this.idToName[currentFile.id] = currentFile.name;
            return {
              ...currentFile,
              name: filename,
              meta: { ...currentFile.meta, filename, name: filename },
            };
          },
        });

        this.uppy.use(DropZone, {
          target: this.dropFileAreaSelector,
          width: '100%',
        });

        this.uppy.use(ThumbnailGenerator, {
          thumbnailHeight: 200,
          waitForThumbnailsBeforeUpload: false,
        });

        this.uppy.use(AwsS3Multipart, {
          limit: AWS_S3_MULTIPART_UPLOAD_LIMIT,
          companionUrl,
          companionHeaders: {
            'uppy-auth-token': jwtToken,
          },
        });

        this.uppy.on('files-added', (addedFiles: UppyFile[] = []) => {
          this.files = this.files.concat(
            addedFiles.map((file) => ({ ...file, name: this.idToName[file.id] })).map(formatFileFields),
          );

          this.progress = Math.max(this.progress, 1);
        });

        this.uppy.on('file-removed', this.removeFile);

        this.uppy.on('thumbnail:generated', (file: UppyFile) => {
          this.files = this.files.map((f) => (f.id === file.id ? { ...f, preview: file.preview } : f));
        });

        this.uppy.on('progress', (progress) => {
          if ((this.files.length && progress < 1) || progress === 100) {
            return;
          }
          this.progress = progress;
        });

        this.uppy.on('upload-success', (file, response) => {
          if (!file?.id) {
            return;
          }
          const newFile: IFile = {
            id: file.id,
            url: response['uploadURL'],
            name: this.idToName[file.id],
            size: file.size,
          };

          const index = this.files.findIndex((e) => e.id === file.id);
          if (index > -1 && this.files[index]) {
            this.files[index] = { ...this.files[index], uploadURL: newFile.url };
          }

          this.appendUploadedFiles(newFile);
        });

        this.uppy.on('complete', async (result) => {
          const filesRemote = [...this.filesRemote, ...result.successful];
          const promises = filesRemote.map(async (file) => {
            if (!file.preview) {
              return file;
            }
            const { type = '' } = file;
            if (type.startsWith('image')) {
              const { orientation } = await getImageOrientation(file.preview);
              return { ...file, orientation };
            }
            if (type.startsWith('video')) {
              return { ...file, orientation: 'landscape' };
            }
            return file;
          });

          const filesWithOrientation = await Promise.all(promises);
          this.filesRemote = observable(filesWithOrientation);
          this.progress = 100;
        });

        this.dragZonePlugin = this.uppy.getPlugin('DragDrop');
      });
    }

    return (): void => {
      this.isUploadPending = Boolean(this.files.length);
    };
  }

  @observable filesRemote: IUploadedFile[] = [];

  @observable files: IAssetFile[] = [];

  @action.bound
  setFiles(files: IAssetFile[]): void {
    this.files = files;
  }

  @observable formsValidity = {};
  @action.bound
  setIsValidForm(fileId: string, valid: boolean): void {
    this.formsValidity[fileId] = valid;
  }

  @action.bound
  cleanUp(): void {
    this.uppy?.close();
    this.uppy = null;
    this.dragZonePlugin = null;
    this.uploadedFiles.clear();
    this.filesRemote = [];
    this.files = [];
    this.isUploadPending = false;
    this.shouldShowErrors = false;
  }

  @observable uploadedFiles: IObservableArray<IFile> = observable.array<IFile>();
  @action.bound
  appendUploadedFiles(file: IFile): void {
    this.uploadedFiles = observable([...this.uploadedFiles, file]);
  }

  @observable isUploadPending = false;

  @observable progress = 0;

  @computed get isAssetsUploaderStoreInitialized(): boolean {
    return Boolean(this.uppy);
  }

  @observable isSending = false;
  @action.bound
  setIsSending = (isSending: boolean): void => {
    this.isSending = isSending;
  };

  @action.bound
  removeFile = (file: { id: string }): void => {
    this.files = this.files.filter(({ id }) => id !== file.id);
    this.uploadedFiles = observable(this.uploadedFiles.filter(({ id }) => id !== file.id));
    this.filesRemote = this.filesRemote.filter(({ id }) => id !== file.id);
    this.uppy?.removeFile(file.id);
    if (this.files.length > 0) this.progress = 100;
    delete this.formsValidity[file.id];
  };

  @action.bound
  onDataChange(data: Partial<IAssetFile>, index: number): void {
    this.files = this.files.map((file, i) => (i === index ? { ...file, ...data } : file));
  }

  @observable preset: IAssetPresetFields;

  @action.bound
  savePreset(preset: IAssetPresetFields): void {
    this.preset = preset;
  }

  @action.bound
  applyPreset(preset: IAssetPresetFields): void {
    this.files = applyPrefixToAsset(preset, this.files);
  }

  @action.bound
  applyChanges(preset: Partial<IAssetPresetFields>): void {
    this.files = (this.files || []).map((file) => ({ ...file, ...preset }));
  }

  @computed get isValid(): boolean {
    return Object.values(this.formsValidity).every((valid) => valid);
  }

  @observable shouldShowErrors = false;
  @action.bound
  showErrors(): void {
    this.shouldShowErrors = true;
  }

  @computed get disableSubmit(): boolean {
    return this.isSending || this.progress < 100 || !this.isValid;
  }
}
