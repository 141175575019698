import React from 'react';

import { DataSectionPage } from 'components/layout';
import { OrganizationDataSection } from 'components/organization';

const Organizations: React.FC = () => {
  return (
    <DataSectionPage>
      <OrganizationDataSection persistFilters />
    </DataSectionPage>
  );
};

export default Organizations;
