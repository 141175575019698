import React from 'react';
import cx from 'classnames';

import { Tab, TabPanel } from 'components/tabs';

import { DetailsPageTabs } from 'types';

export const sortableTabs = [DetailsPageTabs.ASSETS, DetailsPageTabs.PRODUCTS];
export const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.ATTENDEES, DetailsPageTabs.LAYOUT];

export const showDataSectionButtons = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.ASSETS, DetailsPageTabs.PRODUCTS, DetailsPageTabs.SUB_EVENTS].includes(tab);

export const isEditableTab = (tab: DetailsPageTabs): boolean => editableTabs.includes(tab);

export const isSortableTab = (tab: DetailsPageTabs): boolean => sortableTabs.includes(tab);

export const canAddItemsToTab = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.PRODUCTS, DetailsPageTabs.ASSETS, DetailsPageTabs.SUB_EVENTS].includes(tab);

export const isTabDisabled = (
  tab: DetailsPageTabs,
  isSortModeEnabled: boolean,
  editableTabs: DetailsPageTabs[],
  editModeEnabled: boolean,
  activeTab: DetailsPageTabs,
): boolean => {
  if (isSortModeEnabled) return !(tab === activeTab && isSortableTab(tab));
  if (editModeEnabled) return !editableTabs.includes(tab);
  return false;
};

export const EventDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
  isSortModeEnabled: boolean;
  hidden?: boolean;
  disabled?: boolean;
}> = ({ value, activeTab, editModeEnabled, hidden = false, disabled, isSortModeEnabled }) => {
  return (
    <Tab
      value={value}
      className={cx(`event-details__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={isTabDisabled(value, isSortModeEnabled, editableTabs, editModeEnabled, activeTab) || disabled}
      hidden={hidden}
    />
  );
};

export const EventTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="event-details__tab-content h-100" content={content} />;
};
