import React from 'react';

import { useWorkflowActions } from 'utils/actions';
import { IWorkflow } from 'types';
import { IItemActionsMenuProps } from './utils';
import { renderMenuActionItems } from 'utils/ui';
import { ActionsMenu } from 'components/action/utils';

const WorkflowActions: React.FC<IItemActionsMenuProps<IWorkflow>> = (props) => {
  const { items, options } = props;
  const actions = useWorkflowActions(items, options);
  return <>{renderMenuActionItems(actions)}</>;
};

export const WorkflowActionsList: React.FC<IItemActionsMenuProps<IWorkflow>> = (props) => {
  const { items, options } = props;
  return <ActionsMenu component={WorkflowActions} items={items} options={options} />;
};
