import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';

import { handleItemClick } from 'components/data-section';

import { IProduct, ItemId } from 'types';
import { useStore } from 'store';

import { IProductDataSectionWrappedComponent } from './types';
import './style.scss';

interface IProductListItemProps {
  overrideId?: ItemId | null;
  product: IProduct;
  contextMenu: React.ReactElement;
  component: React.FC<IProductDataSectionWrappedComponent>;
}

export const ProductDataSectionItemWrapper: React.FC<IProductListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();

  const { product, contextMenu, component: Component, overrideId } = props;
  const id = overrideId || product?.id;

  const isChecked = checked?.some((checked) => checked.id === id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <Component
      product={product}
      id={id}
      isChecked={isChecked}
      isAllItemsSelected={isAllItemsSelected}
      contextMenu={contextMenu}
      handleClick={handleClick}
      active={active}
    />
  );
});
