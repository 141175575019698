import { Model } from 'helpers/filters/types';
import { ILanguage } from 'types';
import { IResult, chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';

export const queryLanguages = (query: string): Promise<IResult<ILanguage>> =>
  chipmunk.run(async (chipmunk) => {
    return await chipmunk.action<ILanguage>(Model.LANGUAGES, 'search', {
      body: parseToSearchParams({ q: query }),
    });
  });
