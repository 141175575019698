import React from 'react';

import { FormInput } from 'helpers/form';
import { IAssetOldAboutSectionProps, IAssetMm3AboutSectionProps } from './types';

export const ImageSpecificFields: React.FC<IAssetOldAboutSectionProps> = ({ assetType, formData, handlers }) => {
  if (!assetType?.includes('image')) return null;

  return (
    <>
      <FormInput
        name="default_layer.caption"
        label="Caption"
        type="textarea"
        {...formData['default_layer.caption']}
        {...handlers}
      />
      <FormInput
        name="default_layer.talent_in_image"
        label="Talent in Image"
        type="textarea"
        {...formData['default_layer.talent_in_image']}
        {...handlers}
        className="talent-in__image-form"
      />
      <FormInput
        name="default_layer.photographer"
        label="Photographer"
        type="textarea"
        {...formData['default_layer.photographer']}
        {...handlers}
      />
    </>
  );
};

export const ImageMm3SpecificFields: React.FC<IAssetMm3AboutSectionProps> = ({ assetType, formData, handlers }) => {
  if (!assetType?.includes('image')) return null;

  return (
    <>
      <FormInput name="meta.caption" label="Caption" type="textarea" {...formData.meta?.caption} {...handlers} />
      <FormInput
        name="meta.talent_in_image"
        label="Talent in Image"
        type="textarea"
        {...formData.meta?.talent_in_image}
        {...handlers}
        className="talent-in__image-form"
      />
      <FormInput
        name="meta.photographer"
        label="Photographer"
        type="textarea"
        {...formData.meta?.photographer}
        {...handlers}
      />
    </>
  );
};
