import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import intersection from 'lodash/intersection';
import { queryProducts } from 'utils/apis/product';
import { IProduct } from 'types';

export const mapProductsToCastCrew = async (
  castCrewList: PmGenericCastCrew[],
): Promise<(PmGenericCastCrew & { products: IProduct[] })[]> => {
  const ids = castCrewList.map((e) => e.id || 0);
  const products = await queryProducts(
    {},
    [['default_layer.meta.cast_crew.cast_crew_id', 'in', ids]],
    undefined,
    'unfurl',
  );

  const productsByCastCrewId = ids.reduce<{ [key: string]: IProduct[] }>(
    (acc, id) => (id ? { ...acc, [id]: [] } : acc),
    {},
  );
  products.map((product) => {
    const list = product.default_layer.meta?.cast_crew?.map((e) => e.cast_crew_id);
    intersection(ids, list).map((id) => {
      if (!id) {
        return;
      }

      productsByCastCrewId[id].push(product);
    });
  });

  return castCrewList.map((castCrew) => ({ ...castCrew, products: productsByCastCrewId[castCrew.id || 0] }));
};
