import React from 'react';
import { Progress } from '@mantine/core';

export const SubtitleFileUpload: React.FC<{ dropAreaId: string; progress: number; message: string }> = ({
  dropAreaId,
  progress,
  message,
}) => {
  return (
    <div className="preview-image-uploader-container">
      <div id={dropAreaId} className="preview-image-uploader" />
      <div className="image-uploader-progress-container">
        <div className="progress-text-container">
          {progress >= 100 ? 'Upload completed!' : progress > 0 ? `Uploading ${progress}%...` : message}
        </div>
        <Progress value={progress} variant="primary" />
      </div>
    </div>
  );
};
