import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { UmGenericListCollection, UmGenericListItem } from '@mediafellows/mm3-types';

import { collectionSchema, productListSchema } from 'utils/schemas';
import { tuco } from 'utils/chipmunk';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useControlDataSection, IFilterOption, useRefreshDataSection } from 'utils/hooks';

import {
  EqFilter,
  IFiltersDefinition,
  Model,
  ParametronOrder,
  ParametronSort,
  RangeFilter,
} from 'helpers/filters/types';
import { useDataSectionStore } from 'store/hooks';

import { DataSection } from 'components/data-section';
import { ListItemActionsList, ProductActionsList, ShowcaseActionsList } from 'components/action';
import { CollectionSelectedItem } from 'components/collection/collection-selected-item';
import { CollectionDataSectionItem } from 'components/collection/collection-data-section-item';
import { IListItem, IProduct, IWebsitePageValues, ItemId } from 'types';
import { customSortMenu } from 'components/collection/collection-data-section';
import { ProductSelectedTabItem } from 'components/product-selected-tab-item';
import { SeparatorItem } from 'components/collection-items/data-section-item';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';
import { ListItemPreviewTab } from 'components/collection-items/preview-tab';
import { ProductDataSectionItemWrapper } from 'components/product-data-section-item';
import { ICollectionItemsFilters, CollectionItemsFilters } from 'components/collection-items/filters-tab';
import { IDataSectionSidebarTabs } from 'components/data-section-sidebar';
import { IListItemActionName } from 'utils/actions/list-item/types';
import { ItemTypes } from 'helpers/form/fields/select-helpers';
import { ActionsMenu } from 'components/action/utils';

const defaultFilters: ICollectionItemsFilters = {
  entity_type: new EqFilter('entity_type', ''),
  updated_at: new RangeFilter('updated_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
};

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const sidebarTabs: IDataSectionSidebarTabs = {
  preview: <ListItemPreviewTab />,
  filters: <CollectionItemsFilters />,
  selectedItemRenderer(item: IListItem): JSX.Element {
    if (!item?.id) {
      return <div key={Math.random()}>test</div>;
    }
    if (item.entity_type === ItemTypes.PRODUCT) {
      return <ProductSelectedTabItem key={item?.id} product={item.entity as IProduct} />;
    }
    if (item.entity_type === ItemTypes.SEPARATOR) {
      return <div key={item?.id}>Separator: {item.meta?.title}</div>;
    }
    if (item.entity_type === ItemTypes.LIST_COLLECTION) {
      return <CollectionSelectedItem key={item?.id} collection={item.entity as UmGenericListCollection} />;
    }
    return <div key={item.id}>Item was Deleted</div>;
  },
};

const multiOptions: IUseActionsOptionParam<IListItem> = {
  context: 'multi',
  page: Pages.COLLECTION_ITEMS,
};

const options: IUseActionsOptionParam<IListItem> = {
  context: 'single',
  page: Pages.COLLECTION_ITEMS,
};

interface ICollectionDataSectionProps {
  persistentFilters?: IFilterOption[];
  initFilters?: IFiltersDefinition;
  headerRightSection?: React.ReactElement;
  listId: ItemId;
  onSuccess: (action: IListItemActionName) => void;
}

export const CollectionItemsDataSection: React.FC<ICollectionDataSectionProps> = observer((props) => {
  const { onSuccess, headerRightSection, listId } = props;

  const params = useMemo(
    () => ({
      model: Model.COLLECTIONS,
      presetSuffix: 'collection',
      stats: 'entity_type',
      defaultFilters,
      filters: [['list_id', 'eq', listId] as IFilterOption],
      schema: collectionSchema,
      params: defaultParams,
      executor: (opts) =>
        tuco('searchListItems', {
          productSchema: productListSchema,
          collectionSchema,
          ...opts,
          params: { ...opts.params, lists_ids: [listId] },
        }),
    }),
    [listId],
  );

  useControlDataSection(params);
  const { checked } = useDataSectionStore<IListItem>();
  const refreshDataSection = useRefreshDataSection();

  const itemRenderer = useCallback(
    (item: IListItem): React.ReactElement => {
      if (item.entity_type === 'product' && item.entity?.id) {
        const product = item.entity as IProduct;
        return (
          <ProductDataSectionItemWrapper
            key={item.id}
            overrideId={item.id}
            component={ProductDataSectionListItem}
            product={product}
            contextMenu={
              <ActionsMenu
                component={ProductActionsList}
                items={[{ ...product, listItem: item } as IProduct & { listItem: IListItem }]}
                options={{ ...options, onSuccess: onSuccess || refreshDataSection } as IUseActionsOptionParam<IProduct>}
              />
            }
          />
        );
      }
      if (item.entity_type === 'separator' && item?.id) {
        return (
          <SeparatorItem
            separator={item as UmGenericListItem}
            key={item.id}
            contextMenu={
              <ActionsMenu
                component={ListItemActionsList}
                items={[item as UmGenericListItem]}
                options={{
                  onSuccess: onSuccess || refreshDataSection,
                  ...options,
                }}
              />
            }
          />
        );
      }
      if (item?.entity_type === ItemTypes.LIST_COLLECTION && item.entity?.id) {
        const collection = item.entity as UmGenericListCollection;
        return (
          <CollectionDataSectionItem
            key={item.id}
            collection={collection}
            overrideId={item.id}
            contextMenu={
              <ActionsMenu
                component={ShowcaseActionsList}
                items={[{ ...collection, listItem: item }]}
                options={{
                  ...options,
                  onSuccess: onSuccess || refreshDataSection,
                  section: IWebsitePageValues.COLLECTIONS,
                }}
              />
            }
          />
        );
      }

      return (
        <div key={item?.id}>
          deleted entity of type {item?.entity_type} with id {item?.entity_id}
        </div>
      );
    },
    [onSuccess, refreshDataSection],
  );

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={itemRenderer}
      headerRightSection={headerRightSection}
      showListTileViewToggleButton={false}
      customSortMenu={customSortMenu}
      defaultParams={defaultParams}
      contextMenu={
        <ActionsMenu component={ListItemActionsList} items={checked} options={{ ...multiOptions, onSuccess }} />
      }
    />
  );
});
