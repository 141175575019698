import { addYears, endOfYear } from 'date-fns';

import { IProduct, IProductLayer, IQueryParams } from 'types';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { getSuggestions } from 'helpers/form';

export const minDate = new Date(1896, 0, 1);
export const maxDate = endOfYear(addYears(new Date(), 10));

export interface IProductEditFormProps {
  layerForm: IUseMm3FormReturn<IProductLayer>;
  productForm: IUseMm3FormReturn<IProduct>;
  inEditMode?: boolean;
}

export const getKeywords = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.keywords');

export const getTags = (params: IQueryParams): Promise<string[]> => getSuggestions(params, 'default_layer.meta.tags');
