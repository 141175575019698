import React, { useCallback } from 'react';

import { useStore } from 'store';
import { Routes } from 'utils/routes';

import { ProductEditParentForm } from 'components/product';
import { ToastError } from 'components/toast';

import { shouldDisplayReassign } from './actions-acl';
import { pluralEntityInflect } from 'utils/general';
import { useRefreshDataSection } from 'utils/hooks';
import { typeToTab } from 'utils/product';
import { productDetailsSchema } from 'utils/schemas/product';
import { updateProducts } from 'utils/apis/product';

import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IProduct } from 'types';

import './style.scss';
import { useNavigate } from 'react-router';

export const useReassignAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const navigate = useNavigate();
  const refreshDataSection = useRefreshDataSection();

  const handleSubmit = useCallback(
    async ({
      id,
      parent_id = null,
      sequence_number,
    }: Pick<IProduct, 'id' | 'parent_id' | 'sequence_number'>): Promise<void> => {
      const { entity } = pluralEntityInflect('Product', items.length);
      try {
        if (!id) {
          return;
        }
        const productsToUpdate =
          items.length > 1
            ? items.map((product) => ({ ...product, parent_id, sequence_number: 0 }))
            : [{ id, parent_id, sequence_number }];
        const updatedProducts = await updateProducts(productsToUpdate, productDetailsSchema);
        toastStore.success(`${entity} were successfully reassigned to the selected parent!`);

        setTimeout(async () => {
          await options?.onSuccess?.(IProductActionName.REASSIGN_PARENT, updatedProducts);
        }, 1000);

        const tab = typeToTab[updatedProducts[0].type];

        setTimeout(async () => {
          if (parent_id) {
            navigate(`${Routes.PRODUCTS}/${parent_id}/${tab}`);
          } else {
            refreshDataSection();
          }
          return dialogStore.close();
        }, 1000);
      } catch (error) {
        toastStore.error(
          <ToastError error={error} placeholder={`Sorry, ${entity} reassignment to the selected parent failed!`} />,
        );
        await options?.onFailure?.();
      }
    },
    [dialogStore, items, options, refreshDataSection, navigate, toastStore],
  );

  const openReassignDialog = useCallback((): void => {
    dialogStore.openModal({
      title: 'Reassign',
      body: () => <ProductEditParentForm handleSubmit={handleSubmit} products={items} />,
    });
  }, [dialogStore, handleSubmit, items]);

  return {
    name: IProductActionName.REASSIGN_PARENT,
    shouldDisplay: shouldDisplayReassign,
    icon: 'diagram-tree',
    title: 'Reassign',
    handler: openReassignDialog,
  };
};
