import { IAsset, IMm3Asset } from 'types';
import { flags } from 'utils/flags';

export const editAssetOldPresetInitialData = {
  selected: true,
  ['@type']: '',
  access_level: '',
  archive_number: '',
  asset_type: '',
  asset_identifier: '',
  classification: null,
  created_at: '',
  default_layer: {
    copyright_notice: '',
    description: '',
    caption: '',
    talent_in_image: '',
    photographer: '',
    notes: '',
  },
  effective_permissions: [],
  external_reference: '',
  file_name: '',
  format: '',
  geographies: [],
  height: '',
  id: undefined,
  md5: '',
  name: '',
  preview_image: null,
  preview_image_id: '',
  permissions: [],
  language_ids: [],
  protection_levels: [],
  status: '',
  updated_at: '',
  user_md5: '',
  width: '',
} as unknown as IAsset;

export const editAssetMm3PresetInitialData = {
  selected: true,
  classification: null,
  geography_ids: [],
  status: 'created',
  access_level: 'company',
  permissions: [],
  protection_levels: [],
  tags: [],
  external_reference: '',
  ingest_notes: '',
  name: '',
  file_status: null,
  sharable: false,
  meta: {
    asset_identifier: null,
    description: null,
    notes: null,
    copyright_notice: null,
    language_id: null,
    user_md5: null,
  },
} as unknown as IMm3Asset;

export const editAssetPresetInitialData = flags.isMm3Assets
  ? editAssetMm3PresetInitialData
  : editAssetOldPresetInitialData;

export const assetFormCustomContext = {
  properties: {
    'default_layer.name': { required: true, validations: [{ presence: true }] },
    access_level: { required: true, validations: [{ presence: true }] },
    status: { required: true, validations: [{ presence: true }] },
    classification: { required: true, validations: [{ presence: true }] },
  },
};
