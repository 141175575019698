import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

import { Routes } from 'utils/routes';

import DateElement from 'components/date-list-element/date-list-element';
import { IProductViewsTableItem } from 'components/product-views-list-table/api';
import { AnalyticsContext, UserAgent } from 'utils/ui';
import { formatType } from 'utils/format-type';

export const UserColumns: { title: string; renderer: (data: IProductViewsTableItem) => React.ReactNode }[] = [
  {
    title: 'Product',
    renderer: ({ product }) => (
      <Tooltip label={product?.full_title} position={'top'}>
        <div className="product-views-list-table__product-title text-truncate">
          <Link to={`${Routes.PRODUCTS}/${product?.id}`}>{product?.full_title || 'N/A'}</Link>
        </div>
      </Tooltip>
    ),
  },
  { title: 'Product Type', renderer: ({ product }) => formatType(product?.type) || 'N/A' },
  {
    title: 'Production Year',
    renderer: ({ product }) =>
      !product?.year_of_production || product?.year_of_production === '0' ? 'N/A' : product?.year_of_production,
  },
  {
    title: 'Genre',
    renderer: ({ product }) => (
      <div className="product-views-list-table__genre">{product?.genre || product?.categories || 'N/A'}</div>
    ),
  },
  { title: 'Produced By', renderer: ({ product }) => product?.produced_by || 'N/A' },
  { title: 'View context', renderer: ({ group }) => <AnalyticsContext group={group} /> },
  { title: 'Device Info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  { title: 'Viewed at', renderer: ({ last_effective_at }) => <DateElement date={last_effective_at} /> },
];
