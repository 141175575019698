import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';

import { DataSection } from 'components/data-section';
import { MobileAppSyncDataSectionItem } from 'components/mobile-sync-data-section-item';

import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { MobileAppSyncFilters } from 'components/mobile-sync-app-filters';
import { MobileAppSyncPreview } from 'components/mobile-app-sync-preview';
import { MobileAppSyncActions } from 'components/action';

import { GroupTypes, IMobileAppSync } from 'types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';

import { Pages } from 'utils/actions';
import { mobileAppSyncDetailsSchema } from 'utils/schemas/mobile-app-sync';
import { useRefreshDataSection } from 'utils/hooks';
import { DataSectionPage } from 'components/layout';
import { ActionsMenu } from 'components/action/utils';

export const customMobileAppSyncAppParam = {
  include_deleted: false,
};

const persistentFilters: IFilterOption[] = [['type', 'eq', GroupTypes.MOBILE_APP_SYNC]];
const defaultFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};
const sidebarTabs = {
  preview: <MobileAppSyncPreview />,
  filters: <MobileAppSyncFilters />,
  selectedItemRenderer(mobileAppSync: IMobileAppSync) {
    return <div>{mobileAppSync.name}</div>;
  },
};

const MobileAppSync: React.FC<{}> = observer(() => {
  useControlDataSection({
    persistFilters: true,
    schema: mobileAppSyncDetailsSchema,
    model: Model.MOBILE_APP_SYNC,
    presetSuffix: 'mobile-app-sync',
    filters: persistentFilters,
    defaultFilters,
    stats: 'access_level',
  });

  const { checked } = useDataSectionStore();

  const refreshDataSection = useRefreshDataSection();

  const itemRenderer = useCallback(
    (mobileAppSync): React.ReactElement => {
      return (
        <MobileAppSyncDataSectionItem
          key={mobileAppSync.id}
          mobileAppSync={mobileAppSync}
          contextMenu={
            <ActionsMenu
              component={MobileAppSyncActions}
              items={[mobileAppSync]}
              options={{ context: 'single', page: Pages.LIST, onSuccess: refreshDataSection }}
            />
          }
        />
      );
    },
    [refreshDataSection],
  );

  return (
    <DataSectionPage>
      <DataSection
        showListTileViewToggleButton={false}
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={MobileAppSyncActions}
            items={checked as IMobileAppSync[]}
            options={{ context: 'multi', page: Pages.LIST, onSuccess: refreshDataSection }}
          />
        }
      />
    </DataSectionPage>
  );
});

export default MobileAppSync;
