import { ItemId, ISearchFilter, IUserRole } from 'types';
import { parseToSearchParams } from 'utils/general';
import { chipmunk } from 'utils/chipmunk';
export const excludeCommonPlatformRolesFilter: ISearchFilter = [
  'name',
  'not_in',
  ['Public Visitor', 'Recommendation Recipient', 'Client'],
];

const internalRoles = [['name', 'not_in', ['Public Visitor', 'Recommendation Recipient']]] as ISearchFilter[];

export function fetchRoles(query = '', id?: ItemId, filters: ISearchFilter[] = internalRoles): Promise<IUserRole[]> {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action('um.role', 'search', {
      // schema: `id,name,internal_account`,
      body: parseToSearchParams({ ids: id ? [id] : undefined, q: query }, [
        ['internal_account', 'ne', true],
        ...filters,
      ]),
    });

    return objects;
  });
}
