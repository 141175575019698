import Cookies from 'cookies-js';
import merge from 'lodash/merge';
import { logout } from 'store/session-store';

declare const AFFILIATION;

const broadcastChannel = new BroadcastChannel(AFFILIATION);

interface ICookieOpts {
  path?: string;
  domain?: string;
  expires?: number;
  secure?: boolean;
}

declare let ENVIRONMENT;

const defaultOpts: ICookieOpts = {
  expires: 30 * 24 * 60 * 60, // 1 month
  secure: ENVIRONMENT !== 'development',
};

export const cookie = {
  set: (name: string, value: string, opts: ICookieOpts = {}): CookiesStatic => {
    opts = merge({}, defaultOpts, opts);
    return Cookies.set(`${AFFILIATION}-${name}`, value, opts);
  },

  get: (name: string): string => {
    return Cookies.get(`${AFFILIATION}-${name}`);
  },

  remove: (...names: string[]): void => {
    names.forEach((name) => Cookies.expire(`${AFFILIATION}-${name}`));
  },
};

export const triggerSignOutFromAllOpenTabs = (): void => {
  broadcastChannel.postMessage('logout');
};

broadcastChannel.onmessage = (event: MessageEvent): void => {
  const { data } = event;
  if (data === 'logout') {
    logout();
  }
};
