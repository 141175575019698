import { IFile, ISubtitle } from 'types';

import { updateSubtitle } from 'utils/apis/subtitle';
import { IconAlias } from 'icons';
import { useReplaceSubtitleDialog } from 'components/asset/replace-subtitle-dialog';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { ISubtitleActionConfig, ISubtitleActionName } from './types';

export const useReplaceSubtitleAction = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): ISubtitleActionConfig => {
  const subtitle = items[0];
  const [openDialog] = useReplaceSubtitleDialog({
    onSubmit: async (file?: IFile | null) => {
      const updatedSubtitle = await updateSubtitle({ id: subtitle?.id, file_attachment: file?.url });
      options.onSuccess?.(ISubtitleActionName.REPLACE, updatedSubtitle);
    },
    type: 'attachment',
  });

  return {
    name: ISubtitleActionName.REPLACE,
    icon: IconAlias.REPLACE,
    title: 'Replace',
    handler: openDialog,
  };
};
