import React, { useEffect, useState } from 'react';
import { UmGenericList, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { useDataSectionStore } from 'store/hooks';
import { IListItem, ItemId, IWebsitePageValues } from 'types';
import { fetchListItems } from 'utils/apis/showcase';
import { IFilterOption } from 'utils/hooks';
import { Model } from 'helpers/filters/types';
import { collectionSchema, showcaseSchema } from 'utils/schemas';
import { ParametronStore } from 'store/parametron-store';
import { getRootStore, useStore } from 'store';
import { toJS } from 'mobx';
import { ToastError } from 'components/toast';

export interface IExtendedGenericList extends UmGenericListShowcase {
  listItems: IListItem[];
  itemsCount: number;
}

export const getParams = (section: IWebsitePageValues): { model: Model; schema?: string; filters: IFilterOption[] } => {
  switch (section) {
    case IWebsitePageValues.COLLECTIONS:
      return {
        model: Model.COLLECTIONS,
        schema: collectionSchema,
        filters: [['type', 'eq', 'List::Collection']],
      };
    default:
      return {
        model: Model.LISTS,
        schema: showcaseSchema,
        filters: [['meta.page', 'eq', section]],
      };
  }
};

export const isList = (list?: UmGenericList): boolean => {
  return Boolean(list?.type?.startsWith('List::'));
};

const fetchItems = async (
  list?: IExtendedGenericList,
  per?: number,
): Promise<{ listItems: IListItem[]; itemsCount: number }> => {
  if (!list?.id) {
    return { listItems: [], itemsCount: 0 };
  }
  const isCollection = list.type === 'List::Collection';
  const params = per
    ? {
        per,
        page: 1,
      }
    : {};
  const { objects = [], pagination } = await fetchListItems({
    listIds: [list.id],
    loadProductAncestry: isCollection,
    ...params,
  });
  return { listItems: objects as IListItem[], itemsCount: pagination?.total_count || 0 };
};

export const useItemToPreview = (
  section: IWebsitePageValues,
): { showcase?: IExtendedGenericList; loading: boolean } => {
  const [loading, setLoading] = useState<boolean>(true);
  const { active, searchStore, updateStore, activeItem } = useDataSectionStore<IExtendedGenericList>();
  const { toastStore } = useStore();
  const { objects: obj } = searchStore || {};
  const objects = toJS(obj);

  // check if first item is not of this section (for exp am.news)
  const firstItem = (active && activeItem) || (objects?.[0] as unknown as IExtendedGenericList);
  const item = firstItem?.meta?.page === section ? firstItem : undefined;

  useEffect(() => {
    if (!item?.id) {
      setLoading(false);
      return;
    }
    updateStore({ active: item.id });

    async function updateShowcasesStore(): Promise<void> {
      try {
        setLoading(true);
        await updateExtendedList(searchStore, item?.id);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      } finally {
        setLoading(false);
      }
    }
    updateShowcasesStore();
  }, [searchStore, item?.id, updateStore, toastStore]);
  return { loading, showcase: item };
};

export const extendStoreObjects = async (): Promise<void> => {
  const {
    dataSectionStore: { searchStore },
    toastStore,
  } = getRootStore();
  searchStore?.objects?.map(async (list) => {
    try {
      const { listItems, itemsCount } = await fetchItems(list, 4);
      if (list?.listItems?.length) return list;
      if (!list) {
        return { listItems, itemsCount };
      }
      list.listItems = listItems;
      list.itemsCount = itemsCount;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    }
  });
};

export const updateExtendedList = async (
  searchStore: ParametronStore | null,
  id?: ItemId | null,
): Promise<IExtendedGenericList | undefined> => {
  if (!searchStore?.objects?.length || !id) return;
  const storeObjects = searchStore?.objects as IExtendedGenericList[];
  const list = storeObjects?.find((list) => list.id === Number(id));
  if (!list?.listItems || list?.itemsCount !== list?.listItems?.length) {
    const { listItems, itemsCount } = await fetchItems(list);
    if (!list) {
      return { listItems, itemsCount } as IExtendedGenericList;
    }

    list.listItems = listItems;
    list.itemsCount = itemsCount;
  }
  return list;
};
