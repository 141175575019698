import React, { useCallback } from 'react';
import { useDialogStore, useStore } from 'store';
import { createNewProject } from 'utils/apis/mediabench';
import { ToastError } from 'components/toast';
import { FormInput, FormSelect, useForm } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { Model } from 'helpers/filters/types';
import { ICreateFolderError, ICreateFolderSuccess } from 'types/mediabench';

type ICreateNewFolderProps = {
  projectsList: { label: string; value: number }[];
  refreshProjectsList: () => void;
  setSelectedProject: (project_id: number) => void;
};

type ICreateNewFolderType = {
  name: string;
  parent_id: null | number;
};

const customContext = { properties: { name: { required: true } } };

export const FormCreateNewFolder: React.FC<ICreateNewFolderProps> = ({
  projectsList,
  refreshProjectsList,
  setSelectedProject,
}) => {
  const { toastStore } = useStore();
  const { close } = useDialogStore();

  const onCreateSubmit = useCallback(
    async (data: ICreateNewFolderType) => {
      const response = await createNewProject({
        name: data.name,
        parent_id: data.parent_id,
      });

      if (response.status === 'error') {
        const { error } = response as ICreateFolderError;
        toastStore.error(<ToastError error={error} />);
        return;
      }

      const { result } = response as ICreateFolderSuccess;
      await refreshProjectsList();
      const project_id = result.project.id;
      setSelectedProject(project_id);
      close();
    },
    [close, refreshProjectsList, setSelectedProject, toastStore],
  );
  const { formData, handlers, valid, onSubmit } = useForm<ICreateNewFolderType>(
    { name: '', parent_id: null },
    Model.ASSETS,
    onCreateSubmit,
    customContext,
  );

  const handleParentChange = (values): void => {
    const { parent_id } = values;
    if (!parent_id) {
      handlers.onChange({ parent_id: null });
    } else {
      handlers.onChange({ parent_id: Number(parent_id) });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormSelect
        name="parent_id"
        label="Parent Folder"
        placeholder="Select MediaBench Project"
        large
        required
        {...formData.parent_id}
        {...handlers}
        options={projectsList}
        onChange={handleParentChange}
      />
      <FormInput name="name" label="New Folder" {...formData.name} {...handlers} />
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Create' }} />
    </form>
  );
};
