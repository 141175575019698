import { isString } from 'lodash';
import { emptyValidation, isBlank } from './empty';

export interface IEmailValidationOpts {
  allow_blank?: boolean;
}

export const validEmailRegex = /^\s*([a-z0-9\-_\.\+]{1,128})@((?:[-a-z0-9]+\.)+[a-z]{2,})\s*$/i;
export const emailValidation = (opts: IEmailValidationOpts, value = ''): [boolean, string?] => {
  const { allow_blank } = opts;
  const [emptyResult, emptyMessage] = emptyValidation({ allow_blank }, value);

  if (!emptyResult) {
    return [emptyResult, emptyMessage];
  }

  if (allow_blank && isBlank(value)) {
    return [true];
  }

  if (!isString(value)) {
    return [false, `Please enter a valid email address`];
  }

  return validEmailRegex.test(value) ? [true] : [false, `Please enter a valid email address`];
};
