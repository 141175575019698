import React from 'react';

import { searchTermQueries } from 'components/analytics/queries/product-queries';
import {
  AnalyticsTopProductSearchTerms,
  AnalyticsTopSearchProducts,
  AnalyticsTopSearchTerms,
} from 'components/analytics-interactions-product-views/analytics-interactions-product-views';
import { ProductViewsListTable } from 'components/product-views-list-table';
import { IAnalyticsSectionTabProps } from './types';

const AnalyticsProductSearchStatsTab: React.FC<IAnalyticsSectionTabProps> = ({
  dateRange,
  userIds,
  geoScopeIds,
  filtersComponent,
}) => {
  return (
    <div className="analytics-tab">
      {filtersComponent}
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <div className="event-analytics-tab-product-views">
            <AnalyticsTopProductSearchTerms
              queryName={searchTermQueries.searchCounter}
              dateRange={dateRange as [Date, Date]}
              geoScopeIds={geoScopeIds}
              userIds={userIds}
            />

            <AnalyticsTopSearchTerms
              queryName={searchTermQueries.searchTopTerms}
              dateRange={dateRange as [Date, Date]}
              geoScopeIds={geoScopeIds}
              userIds={userIds}
            />

            <AnalyticsTopSearchProducts
              title="Top Products Found"
              unit="times"
              queryName={searchTermQueries.searchTopProduct}
              dateRange={dateRange as [Date, Date]}
              geoScopeIds={geoScopeIds}
              userIds={userIds}
              exportAction={false}
            />
          </div>

          <div className="event-analytics-tab-product-views-list">
            <ProductViewsListTable
              title="Detailed Search Terms"
              queryName={searchTermQueries.searchTermList}
              dateRange={dateRange as [Date, Date]}
              geoScopeIds={geoScopeIds}
              userIds={userIds}
              entity="searchTerm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsProductSearchStatsTab;
