import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { action, observable } from 'mobx';

export class FormsStore {
  @observable forms: { [key: string]: IUseMm3FormReturn<unknown> } = {};

  @observable onSuccess: () => void;

  @action.bound
  addForm<T>(key: string, form: IUseMm3FormReturn<T>): void {
    this.forms[key] = form;
  }

  @action.bound
  getForm<T>(key: string): IUseMm3FormReturn<T> {
    return this.forms[key] as IUseMm3FormReturn<T>;
  }
}
