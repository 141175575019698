import { getParentType } from 'pages/product-details/utils';
import { useState, useEffect } from 'react';
import { Product3Types } from 'types/product';
import { IFilterOption } from 'utils/hooks';
import { useSearchForPotentialParents } from 'utils/hooks/product';

export function useParentFilters(parent, productType, productForm): [IFilterOption[]] {
  const [additionalProductFilters, setAdditionalProductFilters] = useState<IFilterOption[]>([
    ['parent_id', 'not_exist'],
  ]);

  const { onParentChange } = useSearchForPotentialParents(productForm);

  useEffect(() => {
    async function assignParent(): Promise<void> {
      if (parent?.id && productType) {
        const parentType = getParentType(productType);
        if (parent.id && parent.type === parentType) {
          onParentChange({ parent_id: parent.id });
        } else {
          const field = parent.type === Product3Types.SERIES ? 'id' : 'parent_id';
          setAdditionalProductFilters([[field, 'eq', parent.id]]);
        }
      }
    }

    assignParent();
  }, [onParentChange, parent, productType]);

  return [additionalProductFilters];
}
