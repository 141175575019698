import React, { useCallback, useState } from 'react';
import { omit } from 'lodash';

import { useStore } from 'store';
import { FormSubmitSection } from 'components/form-submit-section';
import { SubtitleFormCommonParts } from 'components/asset/subtitle-form';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IFile, ISubtitle } from 'types';

type IUploadSubtitleForm = Omit<ISubtitle, 'asset_id' | 'language'>;

export interface IEditSubtitleProps {
  onSubmit: (subtitle: IUploadSubtitleForm, file?: IFile | null) => void;
  subtitle?: ISubtitle;
}

const EditSubtitleDialogBody: React.FC<IEditSubtitleProps> = ({ subtitle, onSubmit }) => {
  const { dialogStore, toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = omit(subtitle, ['language', 'asset_id', 'file_attachment']);
  const { formData, handlers, valid, values } = useForm<IUploadSubtitleForm>(initialValues, Model.SUBTITLES);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        e.preventDefault();
        await onSubmit({ ...values, file_attachment: subtitle?.file_attachment });
        toastStore.success('Updates applied successfully!');
        return dialogStore.close();
      } catch (error) {
        toastStore.error(error.text);
        return dialogStore.close();
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, values, subtitle?.file_attachment, toastStore, dialogStore],
  );

  return (
    <form onSubmit={handleSubmit}>
      <SubtitleFormCommonParts formData={formData} handlers={handlers} />
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!valid} loading={isLoading} />
    </form>
  );
};

export default EditSubtitleDialogBody;
