import React from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Routes } from 'utils/routes';
import { IProduct } from 'types';

import './style.scss';

export const ProductsLink: React.FC<{ url: string; products?: IProduct[] }> = ({ url, products }) => {
  const [opened, { close, open }] = useDisclosure(false);

  if (!products?.length) return <>{'0 Products'}</>;
  switch (products?.length) {
    case 1: {
      return (
        <Popover withArrow opened={opened}>
          <Popover.Target>
            <Link
              onMouseEnter={open}
              onMouseLeave={close}
              className="text-truncate bp3-text-muted asset-list-item-row__products_title d-block"
              to={`${Routes.PRODUCTS}/${products[0].id}`}
            >
              {products[0].full_title}
            </Link>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="p-2 overflow-y-hidden">{products[0].full_title}</div>
          </Popover.Dropdown>
        </Popover>
      );
    }
    default:
      return (
        <Popover opened={opened} withArrow>
          <Popover.Dropdown>
            <div className="products-link__popover-content overflow-y-hidden">
              {products.map(({ id, full_title }) => (
                <div key={id}>{full_title}</div>
              ))}
            </div>
          </Popover.Dropdown>
          <Popover.Target>
            <Link
              onMouseEnter={open}
              onMouseLeave={close}
              className="bp3-text-muted"
              to={url}
            >{`${products.length} Products`}</Link>
          </Popover.Target>
        </Popover>
      );
  }
};
