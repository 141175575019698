import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { SectionHeader } from 'components/section-header';

import { useHeaderActions } from './utils';
import { IOrganization, IIdentifiable } from 'types';
import { IUseFormReturn } from 'helpers/form';
import { IOrganizationFields } from 'pages/upsert-organization/types';

interface IOrganizationDetailsHeaderProps {
  organization: IOrganization & IIdentifiable;
  refresh: () => Promise<void>;
  organizationForm: IUseFormReturn<IOrganizationFields>;
  editModeEnabled: boolean;
  setEditModeEnabled: (enabled: boolean) => void;
}

export const OrganizationDetailsHeader: React.FC<IOrganizationDetailsHeaderProps> = observer(
  ({ organization, refresh, organizationForm, editModeEnabled, setEditModeEnabled }) => {
    const { name } = organization || {};

    const { dialogStore, toastStore } = useStore();

    const { headerActions } = useHeaderActions({
      organization,
      refresh,
      organizationForm,
      toastStore,
      dialogStore,
      editModeEnabled,
      setEditModeEnabled,
    });
    const subtitle = Array.isArray(organization.classification)
      ? organization.classification.join(', ')
      : organization.classification;
    return <SectionHeader title={name} subtitle={subtitle} useBackground actions={headerActions} />;
  },
);

export default OrganizationDetailsHeader;
