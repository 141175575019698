import React from 'react';
import cx from 'classnames';
import { Avatar as MantineAvatar, Indicator } from '@mantine/core';

import { getStatusColor } from 'components/user-avatar/user-avatar';

import { stringToColour, getTitle } from './avatar-utils';
import { UserStatus } from 'types';

import './style.scss';

export interface IAvatar {
  text?: string;
  image?: string;
  size?: 'small' | 'medium' | 'large' | 'xs';
  onClick?: () => void;
  tag?: string;
  status?: UserStatus;
  disabled?: boolean;
  offset?: number;
  indicatorSize?: number;
}

export const Avatar: React.FC<IAvatar> = (props) => {
  const {
    text,
    image,
    tag,
    onClick,
    size = 'medium',
    status,
    disabled = false,
    offset = 7,
    indicatorSize = 16,
  } = props;

  const sizeClass = `avatar__content-size-${size}`;
  const title = getTitle(text);

  const fallbackStyles = !image
    ? {
        background: stringToColour(`${text}`),
      }
    : {};

  return (
    <div className={cx('avatar', { 'avatar--clickable': !!onClick })} onClick={onClick}>
      <Indicator
        disabled={disabled}
        inline
        size={indicatorSize}
        offset={offset}
        position="bottom-end"
        color={getStatusColor(status)}
        withBorder
        className="avatar__content"
      >
        <MantineAvatar
          src={image}
          className={`${sizeClass} avatar__content avatar__content--image`}
          style={fallbackStyles}
        >
          {!image && title}
        </MantineAvatar>
      </Indicator>
      {tag && <div className="avatar__tag text-uppercase p-1 ms-0">{tag}</div>}
    </div>
  );
};
