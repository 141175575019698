import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { useDataSectionStore } from 'store';
import { getHistogramData } from 'utils/apis/analytics';
import { PreviewBarChart } from 'components/preview-bar-chart';
import { SectionHeader } from 'components/section-header';
import { ContactPreviewHeader } from 'components/contact-preview-tab/contact-preview-header';
import { ContactPreviewAttributes } from 'components/contact-preview-tab/contact-list-attributes';

import { IContact, DetailsPageTabs, IHistogramData } from 'types';

import { useRemote } from 'utils/hooks';
import { LinkWithArrow } from 'utils/ui';
import './style.scss';

const ContactPreviewTab: React.FC = observer(() => {
  const { activeItem: contact, active } = useDataSectionStore<IContact>();

  const end = React.useMemo(() => endOfDay(new Date()), []);
  const start = React.useMemo(() => subDays(startOfDay(end), 13), [end]);

  const fetchContactAnalytics = useCallback(async (): Promise<IHistogramData[]> => {
    const { data } = await getHistogramData({
      entity: 'user',
      queryName: 'v3/interaction/logins/count__user_logins',
      entityId: active,
      dateRange: [start, end],
    });
    return data;
  }, [active, end, start]);

  const [histogram] = useRemote(fetchContactAnalytics);

  const { id: contactId, deleted_at } = contact || {};

  const CustomRightRenderer = useCallback(
    (): JSX.Element => <LinkWithArrow to={`/contacts/${contactId}/${DetailsPageTabs.ANALYTICS}`} text="View All" />,
    [contactId],
  );
  return (
    <div className={cx('contact-preview', { 'd-flex align-items-center justify-content-center': !contact })}>
      {contact && (
        <>
          <ContactPreviewHeader contact={contact} />
          <div className="contact-preview__actions py-3">
            {!deleted_at && (
              <LinkWithArrow to={`/contacts/${contactId}/${DetailsPageTabs.OVERVIEW}`} text="View Details" />
            )}
          </div>

          <div className="contact-preview__rows">
            <ContactPreviewAttributes />
            <div className="contact-preview__analytics">
              <SectionHeader useBackground title="Login Activity" {...(!deleted_at && { CustomRightRenderer })} />
              <div className="contact-preview__analytics__graph">
                <PreviewBarChart data={histogram} title="Login Activity" startDate={start} endDate={end} unit="login" />
              </div>
            </div>
          </div>
        </>
      )}
      {!contact && <span className="entity-preview-tab__no-preview">No preview</span>}
    </div>
  );
});
export default ContactPreviewTab;
