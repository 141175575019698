import React from 'react';

import { FormInput, FormCountry, useFieldsContextInit, IFormAddressProps } from 'helpers/form';

const formFieldsNames = ['street', 'zip_code', 'city', 'state', 'country_id'];

export function RegistrationFormAddress<T extends {}>(props: IFormAddressProps<T>): JSX.Element {
  const { large = true, name, separator = '_', formData, handlers } = props;
  const preset = name + separator;
  useFieldsContextInit<T>(
    formData,
    handlers,
    formFieldsNames.map((key) => `${preset}${key}`),
  );

  return (
    <>
      <FormInput name={`${preset}street`} label="Street" type="text" {...formData[`${preset}street`]} {...handlers} />
      <FormInput
        name={`${preset}zip_code`}
        label="ZIP code"
        type="text"
        large={large}
        {...formData[`${preset}zip_code`]}
        {...handlers}
      />
      <FormInput name={`${preset}city`} label="City" type="text" {...formData[`${preset}city`]} {...handlers} />
      <FormInput name={`${preset}state`} label="State" type="text" {...formData[`${preset}state`]} {...handlers} />
      <FormCountry
        name={`${preset}country_id`}
        label="Country"
        large
        {...formData[`${preset}country_id`]}
        {...handlers}
      />
    </>
  );
}
