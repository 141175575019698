import { useCallback } from 'react';

import { IAsset, IMm3Asset } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayDownload as shouldDisplay } from 'utils/actions/asset/action-download-acl';

import { downloadAsset, requestAssetsDownloadLink } from './api';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { getValidIds, pluralEntityInflect } from 'utils/general';

export const useDownloadAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const { toastStore } = useStore();
  const { entity } = pluralEntityInflect('Asset', items.length);

  const handler = useCallback(async () => {
    try {
      const itemIds = getValidIds(items);
      if (items.length === 1) {
        await downloadAsset(itemIds[0]);
      } else {
        await requestAssetsDownloadLink(itemIds);
        toastStore.success('Download requested. We will notify you when the download is ready');
      }
      await options?.onSuccess?.(IAssetActionName.DOWNLOAD);
      return true;
    } catch (error) {
      toastStore.error(`${entity} download failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, items, options, toastStore]);

  return {
    name: IAssetActionName.DOWNLOAD,
    shouldDisplay,
    icon: 'download',
    title: 'Download',
    handler,
  };
};
