import React from 'react';

import { useScreeningRoomActions } from 'utils/actions/screening-room';
import { IGroup } from 'types';
import { renderMenuActionItems } from 'utils/ui';

import { IItemActionsMenuProps } from './utils';

export const ScreeningRoomActionsList: React.FC<IItemActionsMenuProps<IGroup>> = (props) => {
  const { items, options } = props;

  const actions = useScreeningRoomActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
