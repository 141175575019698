import React, { useMemo } from 'react';
import { Button } from '@mantine/core';
import { Intent } from 'utils/ui';
import { ICalGenericMeeting } from 'types/calendar';
import { CalendarApi } from '@fullcalendar/react';
import { isEmpty } from 'lodash';
import { formatDate } from 'utils/date';

import { FormChecklist, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { ICalendarInvite } from 'types/calendar';
import { formatInviteeName } from './utils';

type IAttendeesData = Pick<ICalGenericMeeting, 'id' | 'invites'>;

export interface IAttendeesFormData {
  id?: number | null;
  attendees: string[];
}

const getEmail = (e: ICalendarInvite): string => e.email;
const getAttendeesEmail = (invites: ICalendarInvite[]): string[] => {
  return invites.reduce((acc, e) => (e.attended ? [...acc, getEmail(e)] : acc), []);
};

export const AttendanceForm: React.FC<
  IAttendeesData & { calendar?: CalendarApi; submit: (values: IAttendeesFormData) => void }
> = (props) => {
  const { id, invites, submit } = props;

  const initialValues = { id, attendees: getAttendeesEmail(invites || []) };
  const { valid, formData, handlers, onSubmit } = useMm3Form<IAttendeesFormData>(initialValues, Model.MEETINGS, submit);

  const checkedInOptions = useMemo(() => {
    return (invites || [])
      .filter((e) => e.attended)
      .map((e) => ({
        name: getEmail(e),
        label: formatInviteeName(e),
        description: formatDate(e.checked_in_at as string),
      }));
  }, [invites]);

  const nonCheckedInOptions = useMemo(
    () => (invites || []).filter((e) => !e.attended).map((e) => ({ name: getEmail(e), label: formatInviteeName(e) })),
    [invites],
  );

  const isEmptyOptions = isEmpty([...nonCheckedInOptions, ...checkedInOptions]);

  return (
    <>
      {!isEmptyOptions ? (
        <>
          {!!nonCheckedInOptions.length && (
            <FormChecklist
              label="Check in invitees"
              name="attendees"
              {...handlers}
              {...formData.attendees}
              options={nonCheckedInOptions}
            />
          )}

          {!!checkedInOptions.length && (
            <FormChecklist
              label="Previously checked in"
              name="attendees"
              {...handlers}
              {...formData.attendees}
              options={checkedInOptions}
            />
          )}
        </>
      ) : (
        <h4>You have not added any named invitees to check-in.</h4>
      )}
      <Button
        variant={Intent.PRIMARY}
        disabled={!valid || isEmptyOptions}
        onClick={onSubmit}
        className="attendance-form__submit-button"
      >
        Confirm
      </Button>
    </>
  );
};
