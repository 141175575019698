import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { Switch } from '@mantine/core';
import { IFiltersDefinition, InFilter, QFilter, RangeFilter, Model, EqFilter } from 'helpers/filters/types';
import { toggleParam, submitFilters } from 'helpers/filters/helpers';
import { ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { parseQueryParams } from 'utils/general';
import { useStore } from 'store';
import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { useLocation } from 'react-router-dom';
import './style.scss';

export interface ISelectionsFilters extends IFiltersDefinition {
  _: QFilter;
  created_at: RangeFilter;
  access_level: InFilter;
  owner_id: EqFilter;
}

export const defaultSelectionFilters = {
  _: new QFilter(''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};

const accessOptionsProvider = new ContextDataProvider(Model.GROUPS, 'access_levels');

const defaultParamComponentValues = (queryParams): { include_deleted: boolean } => ({
  include_deleted: !!queryParams['include_deleted'] && queryParams['include_deleted'].toString() === 'true',
});

const SelectionsFilters: React.FC = observer(() => {
  const location = useLocation();

  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;
  const queryParams = parseQueryParams(location.search);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

  const handleSubmit = useCallback((action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: {
        include_deleted: false,
      },
    });
  }, []);

  const [filterValues, filterHandlers] = useFilters<ISelectionsFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="d-flex justify-content-center flex-column">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="selections-filter__wrapper">
        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues('access_level')}
          />

          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});

export default SelectionsFilters;
