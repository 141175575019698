import { useCallback, useEffect, useRef, useState } from 'react';

import { IProductAncestryInfo } from 'types';
import { toggleParam } from 'helpers/filters/helpers';
import { useDataSectionSort } from 'utils/hooks/control-data-section';
import { ParametronSort } from 'helpers/filters/types';
import { useDataSectionStore } from 'store/hooks';
import { useLocation } from 'react-router-dom';

export const hasDescendants = (ancestry?: IProductAncestryInfo): boolean => {
  return Boolean(
    ancestry &&
      (ancestry.effective_series_count ||
        ancestry.effective_seasons_count ||
        ancestry.effective_programs_count ||
        ancestry.effective_products_count ||
        ancestry.effective_film_versions_count ||
        ancestry.effective_episodes_count ||
        ancestry.effective_episode_versions_count),
  );
};

type IFilter = {
  include_descendants: boolean;
};

export const useIncludeDescendantsProducts = (
  ancestry: IProductAncestryInfo,
): {
  paramComponentValues: IFilter;
  handleToggleParam: React.FormEventHandler<HTMLInputElement>;
  disableIncludeDescendantsAssetsSwitch: boolean;
} => {
  const disableIncludeDescendantsAssetsSwitch = !hasDescendants(ancestry);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isIncludeDescendantsSetInURl = Boolean(params.get('include_descendants'));
  const handleSorting = useDataSectionSort();
  const initValues = useRef({ isIncludeDescendantsSetInURl, handleSorting });

  const [paramComponentValues, setParamComponentValues] = useState<IFilter>({
    include_descendants: isIncludeDescendantsSetInURl,
  });

  //force UPDATED_AT sort on init if url has include_descendants=true
  useEffect(() => {
    const { isIncludeDescendantsSetInURl, handleSorting } = initValues.current;
    if (isIncludeDescendantsSetInURl) {
      handleSorting(ParametronSort.UPDATED_AT);
    }
  }, []);

  const { getParams, getFilters } = useDataSectionStore();

  const handleToggleParam = useCallback<React.FormEventHandler<HTMLInputElement>>(
    (event) => {
      const { sort } = getParams();
      const filters = getFilters();
      const hasFilters = filters.length;
      const isChecked = !paramComponentValues.include_descendants;

      if (!isChecked && sort !== ParametronSort.SEQUENCE_NUMBER && !hasFilters) {
        handleSorting(ParametronSort.SEQUENCE_NUMBER);
      }

      if (isChecked && sort === ParametronSort.SEQUENCE_NUMBER) {
        handleSorting(ParametronSort.UPDATED_AT);
      }

      toggleParam({
        event,
        paramComponentValues,
        setParamComponentValues,
      });
    },
    [getFilters, getParams, handleSorting, paramComponentValues],
  );

  return {
    paramComponentValues,
    handleToggleParam,
    disableIncludeDescendantsAssetsSwitch,
  };
};
