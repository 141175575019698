import React from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronRight } from 'blueprint5-icons';
import cx from 'classnames';

import { Thumbnail } from 'components/thumbnail/thumbnail';
import ProductAssets from 'components/asset/assets-aggregation';
import { ProductSearchTitle } from 'components/product/product-search-title/product-search-title';
import { ProductSearchItemInfo } from 'components/product-search-item/product-search-item-info';

import { hasDescendants } from 'pages/product-details/product-assets-tab/utils';
import { IProduct, Product3Types } from 'types';

import { pluralizeProductType, formatMm3TypeTag } from 'utils/product';
import useSelectedOption from 'utils/hooks/selected-option';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

interface IProductSearchItem {
  product: IProduct;
  className?: string;
  isAncestryMode?: boolean;
  isDisabled?: boolean;
  isNavigationEnabled?: boolean;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onLoadAncestry?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const plural = (pType: string, count: number): string =>
  pluralizeProductType(formatMm3TypeTag(pType), count > 1 ? 's' : '');

const formatAncestryData = (product): string[] => {
  const { type, ancestry_info } = product;
  const {
    effective_episode_versions_count,
    effective_episodes_count,
    effective_film_versions_count,
    effective_programs_count,
    effective_seasons_count,
    effective_series_count,
  } = ancestry_info || {};

  const output: string[] = [];

  switch (type) {
    case Product3Types.FORMAT:
      if (effective_series_count) {
        output.push(`${effective_series_count} ${plural(Product3Types.SERIES, effective_series_count)}`);
      }
      if (effective_programs_count) {
        output.push(`${effective_programs_count} ${plural(Product3Types.PROGRAM, effective_programs_count)}`);
      }
      break;
    case Product3Types.PROGRAM:
      if (effective_film_versions_count) {
        output.push(
          `${effective_film_versions_count} ${plural(Product3Types.FILM_VERSION, effective_film_versions_count)}`,
        );
      }
      break;
    case Product3Types.EPISODE:
      if (effective_episode_versions_count) {
        output.push(
          `${effective_episode_versions_count} ${plural(
            Product3Types.EPISODE_VERSION,
            effective_episode_versions_count,
          )}`,
        );
      }
      break;
    case Product3Types.SEASON:
      if (effective_episodes_count) {
        output.push(`${effective_episodes_count} ${plural(Product3Types.EPISODE, effective_episodes_count)}`);
      }
      break;
    case Product3Types.SERIES:
      if (effective_seasons_count) {
        output.push(`${effective_seasons_count} ${plural(Product3Types.SEASON, effective_seasons_count)}`);
      }
      if (effective_episodes_count) {
        output.push(`${effective_episodes_count} ${plural(Product3Types.EPISODE, effective_episodes_count)}`);
      }
      break;
  }

  return output;
};

const ProductMenuItem: React.FC<{
  product: IProduct;
  handleSelect?: (e) => void;
  isAncestryMode?: boolean;
}> = ({ product, handleSelect, isAncestryMode }) => {
  const { title, id, type, preview_image, inherited_preview_image } = product;
  const ancestryData = formatAncestryData(product);

  return (
    <div
      className={`searchbox__product ${Classes.MENU_ITEM}`}
      data-id={id}
      data-item-type={type}
      data-item-name={title}
      onClick={handleSelect ? handleSelect : undefined}
    >
      <div className="searchbox__thumb">
        <Thumbnail image={preview_image?.url || inherited_preview_image?.url} showType assetType={type} />
      </div>
      <div>
        <ProductSearchTitle product={product} isAncestryMode={isAncestryMode} />
        <div className="searchbox__info-text__wrapper">
          <ProductSearchItemInfo product={product} isAncestryMode={isAncestryMode} />
          {isAncestryMode && (
            <span className="searchbox__info-text text-truncate text-capitalize">
              {Boolean(ancestryData.length) ? (
                ancestryData.join(', ')
              ) : (
                <ProductAssets assets={product?.assets} fallback="—" muted disabled />
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const ProductSearchItem: React.FC<IProductSearchItem> = observer((props) => {
  const { product, handleSelect, className, isAncestryMode, isDisabled = false, isNavigationEnabled = true } = props;
  const { access_level, id, ancestry_info } = product;
  const selectedOption = useSelectedOption({ value: access_level });
  const hasAncestry = hasDescendants(ancestry_info);
  if (isDisabled && !hasAncestry) return null;

  return (
    <li
      key={`${id}-product`}
      className={cx('entity-search-list-item', className, {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      {isAncestryMode ? (
        <div
          className={cx('d-flex align-items-center', { 'searchbox__entity--disabled': isDisabled })}
          onClick={handleSelect}
          data-parent-id={ancestry_info?.product_id}
          data-text={product.display_title}
        >
          <div className="flex-fill">
            <ProductMenuItem product={product} isAncestryMode={isAncestryMode} />
          </div>
          {hasAncestry && isNavigationEnabled && (
            <div className="product-search-item__ancestry-wrap">
              <div className={`p-3 ${Classes.MENU_ITEM} js-product-search-item__ancestry`} role="button">
                <MantineIcon icon={<ChevronRight />} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <ProductMenuItem product={product} handleSelect={handleSelect} isAncestryMode={isAncestryMode} />
      )}
    </li>
  );
});

export { ProductSearchItem };
