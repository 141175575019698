import React, { useCallback, useMemo } from 'react';

import { IOrganization } from 'types';
import { IFilterOption, useRemote } from 'utils/hooks';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import { AccessPrivilegesDataSection } from 'components/access-privileges-data-section';
import { getOrganizationGroupIds } from 'utils/apis/organization';

export const OrganizationAccessTab: React.FC<{ organization: IOrganization }> = ({ organization }) => {
  const getOrganizationGroups = useCallback(() => getOrganizationGroupIds(organization.id), [organization.id]);
  const [group_ids = [], loading] = useRemote<number[]>(getOrganizationGroups, []);

  const groupIdsFilter: IFilterOption[] = useMemo(() => [['id', 'in', group_ids]], [group_ids]);

  if (loading) {
    return <Loading text="Loading Organization's Access Privileges" />;
  }

  if (!group_ids?.length && !loading) {
    return <SectionMessage intent={SectionMessageType.EMPTY} />;
  }

  return <AccessPrivilegesDataSection persistentFilters={groupIdsFilter} />;
};

export default OrganizationAccessTab;
