import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IJsonLDSpec } from '@mediafellows/chipmunk';
import { Envelope } from 'blueprint5-icons';

import { Globals, IDeepPartial } from 'types';
import { Footer } from 'components/footer';
import { Intent } from 'utils/ui/intent';
import { chipmunk } from 'utils/chipmunk';
import { useStore } from 'store';
import { FormInput, useForm } from 'helpers/form';

import { ToastError } from 'components/toast';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

export interface IResetPasswordFields {
  email: string;
}

const initialValues: IResetPasswordFields = {
  email: '',
};

const contextExtension: IDeepPartial<IJsonLDSpec> = {
  properties: {
    email: {
      required: true,
      validations: [{ email: true }],
    },
  },
};

const ResetPassword: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (data: IResetPasswordFields, valid: boolean) => {
      if (!valid) {
        return;
      }

      setInProgress(true);

      const { email } = data;
      chipmunk.run(async (chipmunk) => {
        try {
          await chipmunk.action('um.password', 'create', {
            params: { email },
            headers: { 'Session-Id': null },
          });

          toastStore.success('If this account exists, we have sent you a password reset link. Please check your email');
          navigate(Routes.LOGIN);
        } catch (err) {
          toastStore.error(<ToastError error={err} placeholder="Sorry, something went wrong. Please try again." />);
        }
        setInProgress(false);
      });
    },
    [navigate, toastStore],
  );

  const { formData, handlers, valid, onSubmit } = useForm<IResetPasswordFields>(
    initialValues,
    'um.password',
    handleSubmit,
    contextExtension,
  );
  return (
    <div className={`page--reset-password ${Globals.MODE_DARK_CLASS}`}>
      <div className={`reset-password`}>
        <div className="reset-password__head">
          <Title order={3}>Reset password</Title>
        </div>

        <div className="reset-password__line" />

        <div className="reset-password__content">
          <div className="reset-password__text mb-3">Enter your e-mail address below to reset your password.</div>
          <form onSubmit={onSubmit}>
            <FormInput
              leftIcon={<MantineIcon icon={<Envelope />} />}
              name="email"
              type="email"
              label="Email"
              {...formData.email}
              {...handlers}
              mb={20}
            />
            <div className="reset-password__btngroup">
              <Link to="/login">
                <MantineIcon className="me-1" icon="arrow-left" />
                Back
              </Link>
              <Button disabled={!valid} loading={inProgress} variant={Intent.PRIMARY} type="submit">
                Reset
              </Button>
            </div>
          </form>
        </div>

        <div className="reset-password__note">
          <div>You didn&apos;t receive a reset email?</div>
          <a href="mailto:support@mediafellows.com" className="d-inline-flex align-items-center ms-2">
            <MantineIcon className="me-1" icon="envelope" />
            <span>Contact Us</span>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default ResetPassword;
