import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterDateRange, FilterSelect, FilterSlider, FilterText } from 'helpers/filters/fields';
import { Switch } from '@mantine/core';
import { IFiltersDefinition, InFilter, QFilter, RangeFilter, EqFilter, Model } from 'helpers/filters/types';
import { toggleParam, submitFilters } from 'helpers/filters/helpers';
import { ContextDataProvider, StaticDataProvider } from 'helpers/data-provider/option-data-provider';
import { parseQueryParams } from 'utils/general';
import { useStore } from 'store';

import { useFilters } from 'helpers/filters';
import { customOrganizationParam } from 'components/organization';
import { DataSectionPreset } from 'components/data-section-preset';
import { useLocation } from 'react-router-dom';

export interface IOrganizationFilters extends IFiltersDefinition {
  _: QFilter;
  classification: EqFilter;
  status: EqFilter;
  updated_at: RangeFilter;
  created_at: RangeFilter;
  access_level: InFilter;
  country_id: EqFilter;
  users_count: RangeFilter;
}

const classificationsOptionsProvider = new ContextDataProvider(Model.ORGANIZATIONS, 'classifications');
const statusOptionsProvider = new ContextDataProvider(Model.ORGANIZATIONS, 'status');
const accessOptionsProvider = new ContextDataProvider(Model.ORGANIZATIONS, 'access_levels');

const defaultParamComponentValues = (queryParams): typeof customOrganizationParam => ({
  include_deleted: !!queryParams['include_deleted'] && queryParams['include_deleted'].toString() === 'true',
});

const OrganizationFilters: React.FC = observer(() => {
  const {
    basicStore: { countriesDataOptions },
  } = useStore();
  const location = useLocation();

  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;
  const queryParams = parseQueryParams(location.search);
  const countryOptionsProvider = useMemo(() => new StaticDataProvider(countriesDataOptions), [countriesDataOptions]);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

  const handleSubmit = (action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: customOrganizationParam,
    });
  };

  const [filterValues, filterHandlers] = useFilters<IOrganizationFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };
  const labelMarks = [
    {
      value: 50,
      label: '50',
    },
  ];
  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="entity-filters__new-filter">
        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Status"
            name="status"
            optionsProvider={statusOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('status')}
          />

          <FilterSelect
            label="Type"
            name="classification"
            placeholder="Select Type"
            optionsProvider={classificationsOptionsProvider}
            filter={filterValues.classification}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('classification')}
          />

          <FilterSelect
            label="Country"
            name="country_id"
            placeholder="Select Country"
            optionsProvider={countryOptionsProvider}
            filter={filterValues.country_id}
            onChange={filterHandlers.onChange}
          />

          <FilterSlider
            label="Users count"
            name="users_count"
            min={0}
            max={100}
            marks={labelMarks}
            filter={filterValues.users_count}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues('access_level')}
          />

          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});

export default OrganizationFilters;
