import React from 'react';
import { Link, useMatches } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Breadcrumbs } from '@mantine/core';
import { ChevronRight } from 'blueprint5-icons';

import './style.scss';

interface IDashboardBreadcrumbsProps {
  className?: string;
}

const DashboardBreadcrumbs: React.FC<IDashboardBreadcrumbsProps> = observer((props) => {
  const { className } = props;
  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => {
      const handle = match?.handle as Record<string, unknown>;
      return handle?.breadcrumb;
    })
    .map(({ handle, pathname }) => ({ href: pathname, text: (handle as Record<string, unknown>).breadcrumb }));

  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1].text;

  return (
    <div className={cx('breadcrumbs', className)}>
      <Breadcrumbs separator={<ChevronRight color="var(--mfx-gray-4)" />} separatorMargin={'xs'}>
        {breadcrumbs.map((item, index) => (
          <Link className={cx('breadcrumb', { active: item.text === currentBreadcrumb })} to={item.href} key={index}>
            {item.text}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
});

export default DashboardBreadcrumbs;
