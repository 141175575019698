import React, { useRef, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import { ActionIcon, Menu } from '@mantine/core';

import { chipmunk } from 'utils/chipmunk';
import { MantineIcon } from 'utils/ui/icon';
import { isProduct, parseToSearchParams, getRouteFromEntity } from 'utils/general';
import { useStore } from 'store';
import { IProduct, IItem } from 'types';
import { useBasicStore } from 'store/hooks';
import { Spotlight, spotlight } from '@mantine/spotlight';
import { useClickOutside } from '@mantine/hooks';
import { ToastError } from 'components/toast';
import { Routes } from 'utils/routes';
import { Model } from 'helpers/filters/types';
import { assetListSchema, contactListSchema, groupListSchema, productListSchema } from 'utils/schemas';
import { useSearchItemsRenderer } from 'utils/hooks/search-items-renderer';
import { getAssetModel } from 'utils/asset';
import { getHiddenGroupTypes } from 'utils/group';
import { loadRecommendationByGroupId } from 'utils/apis/recommendation';

import './style.scss';

const Searchbox: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const { isGlobalSearchOpen, updateBasicStore } = useBasicStore();
  const [items, setItems] = useState<IItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(''); // State for search query text to render dynamic heights
  const ref = useClickOutside(() => updateBasicStore('isGlobalSearchOpen', false));

  const navigate = useNavigate();
  const latestRequestRef = useRef(0);

  useEffect(() => {
    isGlobalSearchOpen ? spotlight.open() : spotlight.close();
  }, [isGlobalSearchOpen]);

  const getRecommendationId = useCallback(async (entityId) => {
    const id = await loadRecommendationByGroupId(entityId);
    return id;
  }, []);

  const handleItemSelect: React.ReactEventHandler = async (e) => {
    const el = e.currentTarget;
    const id = parseInt(el.getAttribute('data-id') || '', 10);
    const type = el.getAttribute('data-item-type') || '';
    const entity = el.getAttribute('data-item-entity') || '';

    if (type.includes('asset') || type.includes('Asset')) {
      navigate(`${Routes.ASSETS}/${id}`);
    } else if (type.includes('user')) {
      navigate(`${Routes.CONTACTS}/${id}`);
    } else if (type.includes('organization')) {
      navigate(`${Routes.ORGANIZATIONS}/${id}`);
    } else if (type.includes('recommendation')) {
      const recoId = await getRecommendationId(id);
      recoId && navigate(`${getRouteFromEntity(type, entity)}/${recoId}`);
    } else if (type.includes('group')) {
      navigate(`${getRouteFromEntity(type, entity)}/${id}?globalSearch=true`);
    } else if (isProduct({ type } as IProduct)) {
      navigate(`${Routes.PRODUCTS}/${id}`);
    }

    updateBasicStore('isGlobalSearchOpen', false);
    spotlight.close();
  };

  const { renderAssets, renderProducts, renderUsers, renderGroups, renderOrgs } = useSearchItemsRenderer(
    items,
    handleItemSelect,
  );
  const handleItemListRender = (): JSX.Element => {
    const users = renderUsers();
    const products = renderProducts();
    const assets = renderAssets();
    const groups = renderGroups();
    const orgs = renderOrgs();

    return (
      <Menu>
        {[
          { items: users, nextExists: products || assets || groups || orgs },
          { items: products, nextExists: assets || groups || orgs },
          { items: assets, nextExists: groups || orgs },
          { items: groups, nextExists: orgs },
          { items: orgs, nextExists: false },
        ].map(({ items, nextExists }, index) =>
          items ? (
            <React.Fragment key={index}>
              {items}
              {nextExists && <Menu.Divider color="var(--mfx-gray-1)" />}
            </React.Fragment>
          ) : null,
        )}
      </Menu>
    );
  };

  const debouncedQueryChange = debounce((q) => {
    setIsLoading(true);
    setItems([]);
    setQuery(q);
    if (q.length < 2) {
      setIsLoading(false);
      return;
    }

    chipmunk.run(async ({ action }) => {
      try {
        const params = { q, per: 5, page: 1 };
        const body = parseToSearchParams(params);

        latestRequestRef.current = latestRequestRef.current + 1;
        const requestNumber = latestRequestRef.current;
        const usersPromise = action(Model.CONTACTS, 'search', {
          body,
          schema: contactListSchema,
        });

        const productsPromise = action(Model.PRODUCTS, 'search', {
          schema: productListSchema,
          body: {
            ...body,
            search: {
              ...body.search,
              filters: [...body.search.filters, ['parent_id', 'not_exist']],
            },
          },
        });

        const assetsPromise = action(getAssetModel(), 'search', {
          body,
          schema: assetListSchema,
        });

        const hiddenGroupTypes = getHiddenGroupTypes();
        const groupSearchBody = hiddenGroupTypes.length
          ? {
              ...body,
              search: {
                ...body.search,
                filters: [...body.search.filters, ['type', 'not_in', hiddenGroupTypes]],
              },
            }
          : body;
        const groupsPromise = action(Model.GROUPS, 'search', {
          body: groupSearchBody,
          schema: `${groupListSchema},main_entity_type`,
        });

        const orgsPromise = action(Model.ORGANIZATIONS, 'search', {
          body,
          schema: `name, status, id, phones, users_count`,
        });

        const result = await Promise.all([usersPromise, assetsPromise, groupsPromise, productsPromise, orgsPromise]);
        if (latestRequestRef.current !== requestNumber) {
          return;
        }

        const items = result.flatMap((item) => item.objects) as IItem[];
        setItems(items);
      } catch (err) {
        setItems([]);

        if (err.text.includes('query_parsing_exception')) {
          toastStore.error('Invalid characters used in search');
          return;
        }

        toastStore.error(<ToastError error={err} placeholder="Search failed" />);
      } finally {
        setIsLoading(false);
      }
    });
  }, 250);

  const toggleSearch = (): void => {
    updateBasicStore('isGlobalSearchOpen', !isGlobalSearchOpen);
    spotlight.toggle();
  };

  const maxHeight = query.length < 2 ? '50px' : '650px';

  return (
    <div className="searchbox">
      <ActionIcon className="searchbox--btn" onClick={toggleSearch} variant="subtle" color="gray.5">
        <MantineIcon icon="search" />
      </ActionIcon>

      <Spotlight.Root ref={ref} size="500" onQueryChange={debouncedQueryChange} maxHeight={maxHeight} scrollable>
        <Spotlight.Search className="text" placeholder="Search..." leftSection={<MantineIcon icon="search" />} />
        <Spotlight.ActionsList>
          {isLoading && !items.length ? <Spotlight.Empty>Searching...</Spotlight.Empty> : null}
          {!isLoading && !items.length ? <Spotlight.Empty>Nothing found...</Spotlight.Empty> : null}
          {items.length > 0 ? handleItemListRender() : null}
        </Spotlight.ActionsList>
      </Spotlight.Root>
    </div>
  );
});

export default Searchbox;
