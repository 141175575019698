import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { FormMultiEntitiesSelect } from 'helpers/form/custom-fields/form-multi-entities-select';
import { ToastError } from 'components/toast';
import { createListItems } from 'utils/apis/showcase-item';
import { createList, uploadPreviewImage } from 'utils/apis/showcase';
import { includesSeparator } from 'utils/list';
import { IFormMultiSelectOption } from 'helpers/form';
import { useDataSectionStore, useStore } from 'store';
import { IGenericListCollectionForm, isCollectionPage, showcaseRenderItemParser } from 'components/showcase-form/utils';
import { showcaseFilters, collectionFilters } from 'components/showcase-form/constants';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

interface IShowcaseFormItemsSelectorProps {
  showcaseForm: IUseMm3FormReturn<UmGenericListShowcase | IGenericListCollectionForm>;
  goBack: () => void;
  entityType?: string;
}

const ShowcaseFormItemsSelector: React.FC<IShowcaseFormItemsSelectorProps> = observer(
  ({ showcaseForm, goBack, entityType = 'Showcase' }) => {
    const [selectedItems, setSelectedItems] = useState<IFormMultiSelectOption[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { dialogStore, toastStore } = useStore();
    const { values } = showcaseForm;

    const { searchStore, updateStore } = useDataSectionStore();
    const refresh = useCallback(
      (showcase: UmGenericListShowcase): void => {
        if (!searchStore) {
          return;
        }

        searchStore.update({
          objects: [showcase, ...(searchStore.objects || [])],
        });
        updateStore({ active: showcase.id });
      },
      [searchStore, updateStore],
    );
    const onSubmit = useCallback(async (): Promise<void> => {
      try {
        setIsSubmitting(true);
        const showcase = await createList(values);
        if (values.type === 'List::Collection') {
          const url = (values as IGenericListCollectionForm)?.previewImageUrl;
          if (url) {
            await uploadPreviewImage({ url }, showcase);
          }
        }
        await createListItems(selectedItems, showcase?.id).catch((error) => {
          toastStore.error(
            <ToastError
              error={error}
              placeholder={`Sorry, something went wrong when adding items to this ${entityType}...`}
            />,
          );
        });
        refresh(showcase);
        toastStore.success(`${entityType} was created successfully!`);
        dialogStore.close();
      } catch (error) {
        if (error?.text === 'Purpose has already been taken') {
          toastStore.error(<ToastError placeholder={'Identifier has already been taken'} />);
        } else {
          if (error.response.body.description?.match(/purpose/i)) {
            error.response.body.description = error.response.body.description.replace(/purpose/i, 'Identifier');
          }

          toastStore.error(
            <ToastError error={error} placeholder={`Sorry, something went wrong when creating the ${entityType}...`} />,
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    }, [values, selectedItems, refresh, toastStore, entityType, dialogStore]);

    return (
      <div className="d-flex flex-column justify-content-between h-100">
        <form>
          <FormMultiEntitiesSelect
            onSelectedItemsChange={setSelectedItems}
            allowedItemTypes={values?.allowed_item_types}
            withSeparator={includesSeparator(values?.allowed_item_types)}
            additionalFilters={isCollectionPage(values) ? collectionFilters : showcaseFilters}
            customRenderItemParser={showcaseRenderItemParser}
            isAncestryMode={values?.allowed_item_types?.includes('product')}
          />
        </form>
        <div className="d-flex justify-content-between">
          <Button size="xs" variant="subtle" onClick={goBack} leftSection={<MantineIcon icon={<ArrowLeft />} />}>
            Back
          </Button>
          <Button size="xs" variant="primary" onClick={onSubmit} loading={isSubmitting}>
            Create
          </Button>
        </div>
      </div>
    );
  },
);

export default ShowcaseFormItemsSelector;
