import React from 'react';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { ThumbnailImage } from 'components/thumbnail';
import { InfoBox } from 'components/info-box';
import { Border } from 'components/border';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { IMarketingEvent } from 'types';
import { flags } from 'utils/flags';
import { Classes } from 'utils/ui';

import './style.scss';

const EventLayoutImage: React.FC<{ imageSource: string | undefined; title: string }> = ({ imageSource, title }) => (
  <div className="event-detail__image-wrapper">
    <div>{title}</div>
    <ThumbnailImage url={imageSource} className="event__detail__layout__thumb" alt="Featured Image" />
  </div>
);

const EventFeaturedImage: React.FC<{ imageSource: string | undefined }> = ({ imageSource }) => (
  <EventLayoutImage imageSource={imageSource} title="Featured Image" />
);

const EventBackgroundImage: React.FC<{ imageSource: string | undefined }> = ({ imageSource }) => (
  <EventLayoutImage imageSource={imageSource} title="Background Image" />
);

export const EventLayout: React.FC<{ event?: IMarketingEvent }> = ({ event }) => {
  if (!event) {
    return (
      <SectionMessage intent={SectionMessageType.EMPTY} title="Uh-Oh!">
        It appears that this page is empty.
      </SectionMessage>
    );
  }

  const {
    event_not_started_headline,
    event_started_not_loggedin_headline,
    event_started_loggedin_headline,
    event_finished_headline,
    event_not_started_introduction,
    event_started_not_loggedin_introduction,
    event_started_loggedin_introduction,
    event_finished_introduction,

    event_not_started_background_image_url,
    event_not_started_featured_image_url,

    event_started_not_loggedin_background_image_url,
    event_started_not_loggedin_featured_image_url,

    event_started_loggedin_background_image_url,
    event_started_loggedin_featured_image_url,

    event_finished_background_image_url,
    event_finished_featured_image_url,
    is_timed_event,
  } = event;
  const gridTemplateColumns = '150px minmax(300px, 2fr) 0.5fr';
  return (
    <div className="event-detail__tab__wrapper event-detail__layout-tab__wrapper">
      <div className="event-detail__tab__content event-detail__layout-tab__content">
        {is_timed_event && flags.showEventLayout && (
          <>
            <InfoBox gridTemplateColumns={gridTemplateColumns} title>
              <div>Upcoming</div>
              <Row
                label="Headline"
                value={event_not_started_headline}
                size="m"
                labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
                valueClassName="event-detail__layout-tab__value"
              />
              <EventFeaturedImage imageSource={event_not_started_featured_image_url} />
              <Row
                html
                label="Introduction"
                value={event_not_started_introduction}
                size="m"
                labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
                valueClassName="event-detail__layout-tab__value"
              />
              <EventBackgroundImage imageSource={event_not_started_background_image_url} />
            </InfoBox>
            <Border className="border-element__grid" />
          </>
        )}

        <InfoBox gridTemplateColumns={gridTemplateColumns} title>
          {flags.showEventLayout && <div>Ongoing</div>}
          <p className="layout-block__subtitle">Not logged in</p>

          <Row
            label="Headline"
            value={event_started_not_loggedin_headline}
            size="m"
            labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
            valueClassName="event-detail__layout-tab__value"
          />
          <EventFeaturedImage imageSource={event_started_not_loggedin_featured_image_url} />
          <Row
            html
            label="Introduction"
            value={event_started_not_loggedin_introduction}
            size="m"
            labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
            valueClassName="event-detail__layout-tab__value"
          />
          <EventBackgroundImage imageSource={event_started_not_loggedin_background_image_url} />

          <Border className="border-element__grid" />
          <p className="layout-block__subtitle">Logged in</p>
          <Row
            label="Headline"
            value={event_started_loggedin_headline}
            size="m"
            labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
            valueClassName="event-detail__layout-tab__value"
          />
          <EventFeaturedImage imageSource={event_started_loggedin_featured_image_url} />
          <Row
            html
            label="Introduction"
            value={event_started_loggedin_introduction}
            size="m"
            labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
            valueClassName="event-detail__layout-tab__value"
          />
          <EventBackgroundImage imageSource={event_started_loggedin_background_image_url} />
        </InfoBox>

        {is_timed_event && flags.showEventLayout && (
          <>
            <Border />
            <InfoBox gridTemplateColumns={gridTemplateColumns} title>
              <div>Finished</div>
              <Row
                label="Headline"
                value={event_finished_headline}
                size="m"
                labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
                valueClassName="event-detail__layout-tab__value"
              />
              <EventFeaturedImage imageSource={event_finished_featured_image_url} />
              <Row
                html
                label="Introduction"
                value={event_finished_introduction}
                labelClassName={`event-detail__layout-tab__label ${Classes.TEXT_MUTED}`}
                valueClassName="event-detail__layout-tab__value"
              />
              <EventBackgroundImage imageSource={event_finished_background_image_url} />
            </InfoBox>
          </>
        )}
      </div>
    </div>
  );
};
