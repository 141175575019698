import React, { useMemo, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stepper } from '@mantine/core';

import { useMm3Form } from 'helpers/form';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { Model } from 'helpers/filters/types';
import { useDialogStore } from 'store/hooks';
import { IWebsitePageValues } from 'types';

import ShowcaseFormFirstStep from 'components/showcase-form/showcase-form';
import CollectionFormFirstStep from 'components/showcase-form/collection-form';
import {
  IGenericListCollectionForm,
  customShowcaseFormValidator,
  customCollectionFormValidator,
  getShowcaseInitialValues,
  getCollectionInitialValues,
} from 'components/showcase-form/utils';
import ShowcaseFormItemsSelector from './showcase-items-form';
import { IModalSize } from 'components/dialogs/types';

enum Steps {
  Step1,
  Step2,
}

const CreateShowcase: React.FC<{ section: IWebsitePageValues }> = observer(({ section }) => {
  const [currentStep, setCurrentStep] = useState(Steps.Step1);
  const handleNext = useCallback(() => {
    setCurrentStep(Steps.Step2);
  }, []);

  const goBack = useCallback(() => {
    setCurrentStep(Steps.Step1);
  }, []);

  const isCollection = section === IWebsitePageValues.COLLECTIONS;

  const entityType = isCollection ? 'Collection' : 'Showcase';

  const showcaseForm = useMm3Form<UmGenericListShowcase>(
    getShowcaseInitialValues(section),
    Model.SHOWCASES,
    handleNext,
    customShowcaseFormValidator,
  );
  const collectionForm = useMm3Form<IGenericListCollectionForm>(
    getCollectionInitialValues(),
    Model.COLLECTIONS,
    handleNext,
    customCollectionFormValidator,
  );
  const form = isCollection ? collectionForm : showcaseForm;

  const firstStepPanel = useMemo(
    () =>
      isCollection ? (
        <CollectionFormFirstStep {...collectionForm} section={section} />
      ) : (
        <ShowcaseFormFirstStep {...showcaseForm} section={section} />
      ),
    [collectionForm, isCollection, section, showcaseForm],
  );
  return (
    <Stepper active={currentStep} onStepClick={setCurrentStep}>
      <Stepper.Step key={Steps.Step1} label={entityType}>
        {firstStepPanel}
      </Stepper.Step>
      <Stepper.Step key={Steps.Step2} label="Items" allowStepSelect={Boolean(form.valid)}>
        {
          <ShowcaseFormItemsSelector
            showcaseForm={isCollection ? collectionForm : showcaseForm}
            goBack={goBack}
            entityType={entityType}
          />
        }
      </Stepper.Step>
    </Stepper>
  );
});

export const useCreateShowcase = ({ title, section }: { title: string; section: IWebsitePageValues }): (() => void) => {
  const { openModal } = useDialogStore();

  return () => openModal({ title, size: IModalSize.S, body: () => <CreateShowcase section={section} /> });
};

export default CreateShowcase;
