import React from 'react';
import { Loader } from '@mantine/core';
import cx from 'classnames';

import { ThumbnailPlaceholderWrapper } from 'components/thumbnail';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';
import { readyStatuses } from 'utils/asset';

import './style.scss';

const notReadyStatuses = ['ingesting', 'available', 'pending', 'reingesting', 'created'];

const showLoadingIcon = (status: string): boolean => {
  return notReadyStatuses.some((s) => status.includes(s));
};

interface IFileStatusProps {
  status?: string | null;
  small?: boolean;
  size?: number;
  withBackground?: boolean;
  type?: string;
}

const previewPrefix = 'preview_';
const fileStatusToLabel = {
  invalid: 'File ingest failed',
  ingesting: 'Ingesting',
  available: 'Generating preview',
  pending: 'Pending',
  reingesting: 'Reingesting',
  created: 'Created',

  [`${previewPrefix}invalid`]: 'Preview ingest failed',
  [`${previewPrefix}ingesting`]: 'Preview ingesting',
  [`${previewPrefix}available`]: 'Generating preview',
  [`${previewPrefix}pending`]: 'Preview generation pending',
  [`${previewPrefix}reingesting`]: 'Preview reingesting',
  [`${previewPrefix}created`]: 'Preview ingesting',
};

export const FileStatus: React.FC<IFileStatusProps> = ({
  status,
  small = false,
  withBackground = false,
  size = 15,
  type = '',
}) => {
  if (!status || readyStatuses.has(status || '')) {
    return <></>;
  }

  const fileStatus = !type || status.startsWith('preview_') ? status : `${type}_${status}`;
  return (
    <div
      className={cx('d-flex align-items-center justify-content-center', {
        'file-status--with-bg': withBackground,
        'file-status': !withBackground,
      })}
    >
      {showLoadingIcon(status) && <Loader size={size} className="file-status_spinner" variant={Intent.PRIMARY} />}
      {status === 'invalid' && <MantineIcon size={size} icon="issue" />}

      {!small && <div className="file-status__text mx-2">{fileStatusToLabel[fileStatus]}</div>}
    </div>
  );
};

export const ThumbnailFileStatus: React.FC<IFileStatusProps> = (props) => {
  if (readyStatuses.has(props.status || '')) {
    return null;
  }

  return (
    <ThumbnailPlaceholderWrapper className="align-items-center">
      <FileStatus {...props} />
    </ThumbnailPlaceholderWrapper>
  );
};
export default FileStatus;
