import { IAsset, IMm3Asset } from 'types';

import { useAccessChangeDialog } from 'components/asset/asset-access-change-form';

import { shouldDisplayChangeAccess as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { IUseActionsOptionParam } from 'utils/actions';

export const useChangeAccessAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const [openDialog] = useAccessChangeDialog(items, options);

  return {
    name: IAssetActionName.CHANGE_ACCESS,
    shouldDisplay,
    icon: 'key',
    title: 'Change access',
    handler: openDialog,
  };
};
