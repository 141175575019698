import React from 'react';
import cx from 'classnames';
import { Menu } from '@mantine/core';

import { ParametronOrder, ParametronSort } from 'helpers/filters/types';

export const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

export const customSortMenu = (
  sort: ParametronSort,
  handleSort: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) & React.MouseEventHandler<HTMLAnchorElement>,
  hideSequenceNumberSort = false,
): JSX.Element => {
  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
        hidden={hideSequenceNumberSort}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.NAME}
        className={cx({ active: sort === ParametronSort.NAME })}
      >
        Name
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
    </Menu>
  );
};
