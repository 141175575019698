import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterSelect, FilterText } from 'helpers/filters/fields';
import { IFiltersDefinition, InFilter, QFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { useStore } from 'store';

import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { organizationOptionsProvider } from 'helpers/filters/option-providers';

export interface ICastCrewFilters extends IFiltersDefinition {
  _: QFilter;
  owner_id: InFilter;
  owner_organization_id: InFilter;
}

const handleSubmit = (action): void => submitFilters({ action });

export const CastCrewFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();

  const [filterValues, filterHandlers] = useFilters<ICastCrewFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search Cast & Crews"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />
          <FilterSelect
            label="Organization"
            name="owner_organization_id"
            placeholder="Select Organization"
            optionsProvider={organizationOptionsProvider}
            filter={filterValues.owner_organization_id}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});
