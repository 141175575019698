import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';

import { useSortDataSectionStore } from 'store';

import { SortDataSection } from 'components/sort-data-section';
import { GenreDataSectionSortItem } from 'components/genres/genre-data-section-sort-item/genre-data-section-sort-item';

import { sortGenres } from 'utils/apis/genre';

import { IGenreTreeItem } from 'types';
import { ISortableItem } from 'store/sort-data-store';

export const genreSortItemRenderer = (genre: IGenreTreeItem): React.ReactElement => {
  return <GenreDataSectionSortItem genre={genre} handleSelect={noop} />;
};

const GenreSortDataSection: React.FC<{
  genres: IGenreTreeItem[];
  onSuccess?: (lists: ISortableItem[]) => void;
}> = observer(({ genres, onSuccess }) => {
  const { initStore } = useSortDataSectionStore<IGenreTreeItem>();

  const fetcher = useCallback(async (): Promise<IGenreTreeItem[]> => genres, [genres]);

  const onSave = useCallback(
    async (sortedGenres) => {
      const updatedGenres = await sortGenres(sortedGenres);
      onSuccess?.(updatedGenres as ISortableItem[]);
    },
    [onSuccess],
  );
  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={genreSortItemRenderer} />;
});

export default GenreSortDataSection;
