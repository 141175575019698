import React from 'react';

import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { Thumbnail } from 'components/thumbnail';
import { typeParser } from 'utils/list-item';
import { Classes } from 'utils/ui';

interface ICollectionSearchItem {
  collection: UmGenericListCollection;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CollectionSearchItem: React.FC<ICollectionSearchItem> = ({ collection, handleSelect }) => {
  const { name, id, meta, purpose, preview_image, type } = collection;
  const description = meta?.description ? ` | ${meta.description}` : '';
  const collectionInfo = `${purpose} ${description}`;
  const parsedType = typeParser(collection);

  return (
    <li key={`${id}-collection`} className="entity-search-list-item">
      <div
        className={`searchbox__showcase ${Classes.MENU_ITEM}`}
        data-id={id}
        data-item-type={parsedType}
        data-item-name={name}
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          <Thumbnail image={preview_image?.url} showType customTag={parsedType} assetType={type} />
        </div>
        <div>
          <div className="d-flex">
            <span className="text-truncate">{name}</span>
          </div>
          <div className="d-flex text-truncate">
            <span className={Classes.TEXT_MUTED}>{collectionInfo}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

export { CollectionSearchItem };
