import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store/hooks';

import { WebsiteDataSectionItem } from 'components/website-data-section-item';

import useControlDataSection from 'utils/hooks/control-data-section';
import { IUseActionsContext, IUseActionsOptionParam, Pages } from 'utils/actions';
import WebsiteDataSectionItems from './website-data-section-items-list';
import WebsitePreviewTab from './website-data-section-preview';

import { extendStoreObjects, getParams, IExtendedGenericList } from './utils';
import { IActionName } from 'utils/actions/types';
import { IShowcaseActionName } from 'utils/actions/showcase/types';
import { fetchListItems } from 'utils/apis/showcase';

import { IListItem, IWebsitePageValues } from 'types';

import './style.scss';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

const WebsiteDataSection: React.FC<{ section: IWebsitePageValues; title: string }> = observer(({ section, title }) => {
  const params = useMemo(
    () => ({
      ...getParams(section),
      actionName: 'search',
      stats: '',
      defaultFilters: {},
      params: { sort: 'sequence_number', order: 'asc', per: 100 },
      handleUpdate: extendStoreObjects,
    }),
    [section],
  );

  useControlDataSection(params);

  const { updateStore, searchStore } = useDataSectionStore<IExtendedGenericList>();

  const handleItemSelect = useCallback(
    async ({ currentTarget }) => {
      const selectedId = currentTarget.dataset.id;
      updateStore({ active: parseInt(selectedId) });
    },
    [updateStore],
  );
  const [isSortModeEnabled, setIsSortModeEnabled] = useState(false);

  const onSuccess = useCallback(
    async (action?: IActionName, showcase?: IExtendedGenericList, items?: IListItem[]): Promise<void> => {
      if (!searchStore) {
        return;
      }
      const storeObjects = searchStore?.objects as IExtendedGenericList[];
      const index = storeObjects.findIndex((list) => list.id === showcase?.id);

      switch (action) {
        case IShowcaseActionName.ADD_ITEMS:
          if (items) {
            storeObjects[index].listItems = items;
            storeObjects[index].itemsCount = items?.length;
          }
          break;
        case IShowcaseActionName.EDIT:
          if (showcase) storeObjects[index] = showcase;
          if (items && showcase?.id) {
            const { objects } = await fetchListItems({ listIds: [showcase.id] });
            storeObjects[index].listItems = objects;
          }
          break;
        default:
          if (!searchStore || !showcase?.id) {
            return;
          }
          storeObjects[index] = showcase;
          updateStore({ active: showcase.id });
          break;
      }
    },
    [searchStore, updateStore],
  );

  const handleItemRenderer = useCallback(
    (showcase: IExtendedGenericList): React.ReactElement => {
      const options: IUseActionsOptionParam<UmGenericListShowcase> = {
        context: 'single' as IUseActionsContext,
        page: Pages.DETAILS,
        section,
        onSuccess,
      };
      return (
        <WebsiteDataSectionItem
          key={showcase.id}
          showcase={showcase}
          handleClick={handleItemSelect}
          options={options}
        />
      );
    },
    [handleItemSelect, onSuccess, section],
  );

  return (
    <div className="data-section website-data-section__container">
      <WebsiteDataSectionItems
        section={section}
        title={title}
        className="website__data-section"
        itemRenderer={handleItemRenderer}
        isSortModeEnabled={isSortModeEnabled}
        setIsSortModeEnabled={setIsSortModeEnabled}
      />
      <WebsitePreviewTab section={section} isSortModeEnabled={isSortModeEnabled} onSuccess={onSuccess} />
    </div>
  );
});

export default WebsiteDataSection;
