import { omit } from 'lodash';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { contactListSchema } from 'utils/schemas/contact';
import { GroupTypes, ISearchFilter, IIdentifiable, IContact, ItemId, IGroup, IOrganization } from 'types';
import { Model } from 'helpers/filters/types';

interface IQueryRecipientsParams {
  ids?: ItemId[] | null;
  q?: string;
  include_deleted?: boolean;
}

type IQueryItems<T> = (params: IQueryRecipientsParams, filters?: ISearchFilter[]) => Promise<(T & IIdentifiable)[]>;

type IQueryRecipients = IQueryItems<IContact | IGroup | IOrganization>;

export const queryRecipientContacts: IQueryItems<IContact> = (params, filters = []) => {
  const body = parseToSearchParams(params, filters);
  return chipmunk.run(async ({ action }) => {
    const extraParams = omit(params, 'ids', 'q');
    const { objects } = await action(Model.CONTACTS, 'search', {
      body,
      params: extraParams,
      schema: contactListSchema,
    });
    return objects;
  });
};

export const queryContactSelections: IQueryRecipients = (params, filters = []) => {
  return chipmunk.run(async ({ action }) => {
    const body = parseToSearchParams(params, [
      ['type', 'eq', GroupTypes.SELECTION],
      ['main_entity_type', 'eq', 'users'],
      ...filters,
    ]);
    const { objects } = await action(Model.CONTACT_GROUP_SET, 'search', {
      body,
      schema: `name, id, updated_at, access_level, main_entity_type, users_count`,
    });

    return objects;
  });
};

const queryOrganizations: IQueryRecipients = (params, filters = []) => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.ORGANIZATIONS, 'search', {
      body: parseToSearchParams(params, filters),
      schema: `name, status, id, phones, users_count`,
    });
    return objects;
  });
};

export const queryQueue = <T>(queries: IQueryItems<T>[]) => {
  return async (params, filters?: ISearchFilter[]) => {
    const promises = queries.map((query) => query(params, filters));

    return Promise.all(promises).then((result) => result.flatMap((item) => item));
  };
};

export const queryUsersAndSelections: IQueryRecipients = (params, filters) => {
  return queryQueue([queryRecipientContacts, queryContactSelections])(params, filters);
};

export const queryRecipients: IQueryRecipients = (params, filters) => {
  return queryQueue([queryRecipientContacts, queryContactSelections, queryOrganizations])(params, filters);
};
