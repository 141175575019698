import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@mantine/core';
import { merge, get } from 'lodash';

import { useStore } from 'store';

import { ToastError } from 'components/toast';
import { IAssetFormFields, useAssetEditForm } from 'components/asset/asset-edit-form';

import { useTab } from 'utils/hooks';
import { parseKeyPathToObject } from 'utils/payload';
import { saveAsset } from 'utils/apis/asset';
import { flags } from 'utils/flags';
import { useEditMode } from 'utils/hooks';
import { AssetFilters } from 'utils/asset';

import { DetailsPageTabs, IAsset, IAssetAggregation, IMm3Asset } from 'types';

import { AssetMetadataTab, AssetMetadataEditTab } from 'pages/asset-details/asset-metadata-tab';
import { AssetGeneralTab } from './asset-general-tab';
import { AssetAnalyticsTab } from './asset-analytics-tab';
import { AssetProductsTab } from './asset-products-tab';
import { AssetTimelineTab } from './asset-timeline-tab';
import { AssetWorkflowTab } from './asset-workflow-tab';
import { AssetSubtitlesTab } from './asset-subtitles-tab';
import { AssetAudiosTab } from './asset-audios-tab';

import { AssetDetailsButtons } from './asset-details-buttons';
import { AssetDetailsHeader } from './asset-details-header';

import { AssetDetailTab, AssetTabPanel, useRefreshIngestingAsset } from './utils';
import { AssetArtifactsTab } from './asset-artifacts-tab';
import { AssetSubAssetsTab } from './asset-sub-assets';

import './style.scss';

const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.SUB_ASSETS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.WORKFLOW,
  DetailsPageTabs.NEWS,
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.ARTIFACTS,
  DetailsPageTabs.AUDIO_TRACKS,
];

export const AssetDetailsLoaded: React.FC<{
  asset: IAsset;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  refreshAsset: () => Promise<void>;
  fetchAsset: () => Promise<IAsset>;
  subAssetsAggregations?: IAssetAggregation;
}> = observer(({ asset, setAsset, refreshAsset, fetchAsset, subAssetsAggregations }) => {
  const { toastStore } = useStore();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);

  const handleTabChange = useCallback((tab: string): void => setActiveTab(tab as DetailsPageTabs), [setActiveTab]);

  useRefreshIngestingAsset(asset, fetchAsset, setAsset);

  const handleSubmit = useCallback(
    async (values: IAssetFormFields | IMm3Asset): Promise<void> => {
      try {
        toastStore.info('Saving');
        const asset1 = flags.isMm3Assets
          ? values
          : merge(parseKeyPathToObject<IAssetFormFields, IAsset>(values as IAssetFormFields), {
              default_layer: { id: get(asset, 'default_layer.id') },
            });

        await saveAsset(asset1.id as string, asset1 as IAsset);
        toastStore.clearAll();
        toastStore.success('Saved', 1000);
        setEditModeEnabled(false);
        refreshAsset();
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(
          <ToastError error={e} placeholder={`Error while saving: ${get(e, 'object.description', 'Unknown error')}`} />,
        );
      }
    },
    [asset, refreshAsset, setEditModeEnabled, toastStore],
  );

  const editForm = useAssetEditForm({ asset, handleSubmit });

  const isVideo = asset.main_classification === AssetFilters.VIDEO;

  return (
    <>
      <AssetDetailsHeader
        asset={asset}
        setAsset={setAsset}
        refreshAsset={refreshAsset}
        editForm={editForm}
        setEditModeEnabled={setEditModeEnabled}
        editModeEnabled={editModeEnabled}
      />

      <div className="asset-details">
        <div className="asset-detail__content-wrapper flex-fill">
          <Tabs className="asset-details__tabs h-100" value={activeTab} onChange={handleTabChange}>
            <Tabs.List className="d-flex flex-nowrap">
              <AssetDetailTab
                value={DetailsPageTabs.OVERVIEW}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
              />
              <AssetDetailTab
                value={DetailsPageTabs.ANALYTICS}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
                hidden={!isVideo}
              />
              <AssetDetailTab
                value={DetailsPageTabs.METADATA}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
              />
              <AssetDetailTab
                value={DetailsPageTabs.SUBTITLES}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
                hidden={!isVideo || !asset.subtitles?.length}
              />
              <AssetDetailTab
                value={DetailsPageTabs.ARTIFACTS}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
                hidden={!isVideo}
              />
              <AssetDetailTab
                value={DetailsPageTabs.SUB_ASSETS}
                activeTab={activeTab}
                hidden={!isVideo || !subAssetsAggregations?.count_by_type?.length}
                editModeEnabled={editModeEnabled}
                valueParser={() => 'Sub-Assets'}
              />
              <AssetDetailTab
                value={DetailsPageTabs.AUDIO_TRACKS}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
                hidden={!isVideo}
                valueParser={() => 'Audios'}
              />
              <AssetDetailTab
                value={DetailsPageTabs.PRODUCTS}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
              />
              <AssetDetailTab
                value={DetailsPageTabs.TIMELINE}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
              />
              <AssetDetailTab
                value={DetailsPageTabs.WORKFLOW}
                activeTab={activeTab}
                editModeEnabled={editModeEnabled}
              />
              <AssetDetailsButtons
                asset={asset}
                refreshAsset={refreshAsset}
                setAsset={setAsset}
                editModeEnabled={editModeEnabled}
                activeTab={activeTab}
              />
            </Tabs.List>
            <AssetTabPanel
              value={DetailsPageTabs.OVERVIEW}
              content={
                <AssetGeneralTab
                  asset={asset}
                  subAssetsAggregations={subAssetsAggregations}
                  setActiveTab={handleTabChange}
                  setAsset={setAsset}
                />
              }
            />
            <AssetTabPanel value={DetailsPageTabs.ANALYTICS} content={<AssetAnalyticsTab />} />
            <AssetTabPanel
              value={DetailsPageTabs.METADATA}
              content={
                editModeEnabled ? (
                  <AssetMetadataEditTab asset={asset} form={editForm} />
                ) : (
                  <AssetMetadataTab asset={asset} />
                )
              }
            />
            <AssetTabPanel
              value={DetailsPageTabs.SUBTITLES}
              content={<AssetSubtitlesTab asset={asset} setAsset={setAsset} />}
            />
            <AssetTabPanel value={DetailsPageTabs.ARTIFACTS} content={<AssetArtifactsTab asset={asset} />} />
            <AssetTabPanel value={DetailsPageTabs.SUB_ASSETS} content={<AssetSubAssetsTab asset={asset} />} />
            <AssetTabPanel value={DetailsPageTabs.AUDIO_TRACKS} content={<AssetAudiosTab asset={asset} />} />
            <AssetTabPanel value={DetailsPageTabs.PRODUCTS} content={<AssetProductsTab />} />
            <AssetTabPanel value={DetailsPageTabs.TIMELINE} content={<AssetTimelineTab />} />
            <AssetTabPanel value={DetailsPageTabs.WORKFLOW} content={<AssetWorkflowTab asset={asset} />} />
          </Tabs>
        </div>
      </div>
    </>
  );
});
