import { IJsonSchemaSpec, IJsonLDSpec } from '@mediafellows/chipmunk';
import { IObservableArray, observable } from 'mobx';
import { chipmunk } from 'utils/chipmunk';
import { IDataProvider, IDataProviderItem } from './data-provider';
import { DynamicDataProvider } from './dynamic-data-provider';
import { getMm3Options } from './utils';

export class ContextDataProvider extends DynamicDataProvider implements IDataProvider {
  @observable loading = false;
  @observable public data = observable([]) as IObservableArray<IDataProviderItem>;

  constructor(model: string, constantName: string, forceLowerCase = false, formatLabel?: (label: string) => string) {
    super(
      () => chipmunk.context(model) as Promise<IJsonLDSpec>,
      (data: IJsonLDSpec | IJsonSchemaSpec): IDataProviderItem[] => {
        if (model.startsWith('mm3')) {
          const options = getMm3Options(data, constantName);

          return options.map((label) => ({
            label: formatLabel ? formatLabel(label) : label,
            value: forceLowerCase ? label.toLowerCase() : label,
          }));
        }
        return (data.constants[constantName] as string[]).map((c) => ({
          label: c,
          value: forceLowerCase ? c.toLowerCase() : c,
        }));
      },
    );
  }
}

export class Mm3ContextDataProvider extends DynamicDataProvider implements IDataProvider {
  @observable loading = false;
  @observable public data = observable([]) as IObservableArray<IDataProviderItem>;

  constructor(model: string, constantName: string, formatLabel?: (label: string) => string) {
    super(
      () => chipmunk.spec(model) as Promise<IJsonSchemaSpec>,
      (data: IJsonSchemaSpec): IDataProviderItem[] => {
        const options = getMm3Options(data, constantName);

        return options.map((label) => ({
          label: formatLabel ? formatLabel(label) : label,
          value: label,
        }));
      },
    );
  }
}
