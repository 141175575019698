import { useCallback } from 'react';
import { map } from 'lodash';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { revokeRecommendation } from 'utils/apis/recommendation';
import { Intent } from 'utils/ui';
import { IRecommendationActionName } from './types';
import { isRecommendationSentAndSingleContext as shouldDisplay } from './actions-acl';

export const useRevokeAction: IUseAction<McGenericRecommendationProduct, IRecommendationActionName> = (
  items,
  options,
) => {
  const { toastStore, dialogStore } = useStore();

  const executeRevoke = useCallback(async () => {
    try {
      await revokeRecommendation(map(items, 'id'));
      toastStore.success('Recommendation revoked successfully');
      return await options?.onSuccess?.(IRecommendationActionName.REVOKE);
    } catch (error) {
      toastStore.error(`Recommendation revoke failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore]);
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Revoke Access',
      body: 'Revoke can not be undone. Are you sure you want to revoke access?',
      onConfirm: executeRevoke,
    });
  }, [dialogStore, executeRevoke]);
  return {
    name: IRecommendationActionName.REVOKE,
    shouldDisplay,
    icon: 'undo',
    title: 'Revoke',
    intent: Intent.DANGER,
    handler,
  };
};
