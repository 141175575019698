import { IFetchTimelineResponse } from 'types';
import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { byId } from 'utils/general';

interface IFetchTimelineParams {
  page: number;
  productId: string;
}

export function fetchTimelineData({ productId, page }: IFetchTimelineParams): Promise<IFetchTimelineResponse> {
  return chipmunk.run(async (chipmunk) => {
    const { objects: products, pagination } = await chipmunk.action('ac.event/product', 'query_timeline', {
      schema:
        'effective_at, description, user_title, user_id, client_ip, entity_title, other_entity_title, other_entity_type, other_entity_id, diff',
      params: { product_id: productId, page },
    });

    const userIds = [...new Set(products.map(({ user_id }) => user_id))];

    const { objects: users } = await chipmunk.action(Model.CONTACTS, 'search', {
      schema: 'first_name, last_name, preview_image, id',
      params: { ids: userIds },
    });
    const usersById = byId(users);

    const data = products.map(({ user_id, ...product }) => ({
      ...product,
      user: usersById[user_id],
    }));

    return { data, totalPages: pagination?.total_pages };
  });
}
