import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { IconName } from 'blueprint5-icons';
import { useNavigate } from 'react-router';

import { Routes } from 'utils/routes';

import { SectionHeader, ISectionHeaderAction } from 'components/section-header';
import { AssetActionsList } from 'components/action';

import { useAssetActionsOptions, useRefreshDataSection, useTab } from 'utils/hooks';
import { changeAssetAccessImmediate } from 'utils/apis/asset';
import { assetDetailsSchema } from 'utils/schemas/asset';

import { IAssetActionName } from 'utils/actions/asset';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IAssetFormFields } from 'components/asset/asset-edit-form';
import { getIngestStatus } from 'utils/asset';

import { Pages } from 'utils/actions/types';
import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IUseFormReturn } from 'helpers/form';
import { ParentBreadcrumbs } from 'components/parent-breadcrumb';
import { Classes } from 'utils/ui';
import { ActionsMenu } from 'components/action/utils';

const page = Pages.DETAILS;

const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.WORKFLOW,
  DetailsPageTabs.NEWS,
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.AUDIO_TRACKS,
];

const editableTabs = [DetailsPageTabs.METADATA, DetailsPageTabs.NEWS];

export const AssetDetailsHeader: React.FC<{
  asset: IAsset | IMm3Asset;
  setAsset: Dispatch<SetStateAction<IAsset | IMm3Asset>>;
  refreshAsset: VoidFunction;
  editForm: IUseMm3FormReturn<IMm3Asset> | IUseFormReturn<IAssetFormFields>;
  editModeEnabled: boolean;
  setEditModeEnabled: (editMode: boolean, updateParam?: boolean | undefined) => void;
}> = observer(({ asset, setAsset, refreshAsset, editForm, editModeEnabled, setEditModeEnabled }) => {
  const { assetId = '' } = useParams<{ assetId: string }>();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);

  const { name, classification, access_level } = asset || {};

  const navigate = useNavigate();
  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action?: string, asset?: IAsset) => {
      switch (action) {
        case IAssetActionName.ASSIGN_TO_PRODUCT:
          setTimeout(refreshDataSection, 1000);
          break;
        case IAssetActionName.DELETE:
          navigate(Routes.ASSETS);
          break;
        case IAssetActionName.CHANGE_ACCESS:
          if (asset) {
            setAsset(asset);
          }
          break;
        case IAssetActionName.UPLOAD_ASSET:
          if (asset) {
            setAsset({ ...asset, file_status: 'ingesting' });
          }
          break;
        case IAssetActionName.UPLOAD_PREVIEW:
        case IAssetActionName.EXTRACT_PREVIEW:
          if (asset) {
            setAsset({ ...asset, preview_image: { ...asset?.preview_image, status: 'ingesting' } });
          }
          break;
        case IAssetActionName.DOWNLOAD:
          break;
        case IAssetActionName.UPLOAD_SUBTITLE:
          asset && setAsset(asset);
          break;
        default:
          await refreshAsset();
      }
    },
    [refreshDataSection, navigate, setAsset, refreshAsset],
  );

  const customOptions = useAssetActionsOptions();
  const headerMenu = useCallback(
    (): JSX.Element => (
      <ActionsMenu
        component={AssetActionsList}
        items={[asset]}
        options={{ ...customOptions, context: 'single', onSuccess, page }}
      />
    ),
    [asset, customOptions, onSuccess],
  );

  const setEdit = (isEnabled: boolean): void => {
    if (!editableTabs.includes(activeTab)) {
      setActiveTab(editableTabs[0], true);
      setEditModeEnabled(isEnabled, false);
      return;
    }

    setEditModeEnabled(isEnabled);
  };

  const headerActions: (ISectionHeaderAction | IconName)[] = editModeEnabled
    ? [
        {
          type: 'save',
          disabled: !editForm.valid,
          handler: editForm.onSubmit,
        },
        {
          type: 'cancel',
          handler: (): void => {
            editForm.resetFields();
            setEditModeEnabled(false);
          },
        },
      ]
    : [{ type: 'edit', handler: (): void => setEdit(true) }];

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string) => {
      return await changeAccessLevelSlider<IAsset>({
        apiCall: (data) => changeAssetAccessImmediate({ ...data, item_ids: [assetId] }, assetDetailsSchema),
        divisionIds: asset?.division_ids,
        newAccessLevel,
        entitySetter: setAsset,
      });
    },
    [assetId, asset?.division_ids, setAsset],
  );

  const { parent } = asset as IMm3Asset;
  const customLeftRenderer = useCallback(() => {
    if (!parent?.id) {
      return (
        <div className="d-flex flex-column">
          <h3 className="section-header__title">{name}</h3>
          <span className={`${Classes.TEXT_MUTED} section-header__subtitle`}>{classification?.replace(/_/g, ' ')}</span>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column">
        <ParentBreadcrumbs id={parent.id} title={parent.name} to={`${Routes.ASSETS}/${parent.id}`} layout="detail" />
        <h3 className="section-header__title">{name}</h3>
        <span className={`${Classes.TEXT_MUTED} section-header__subtitle`}>{classification?.replace(/_/g, ' ')}</span>
      </div>
    );
  }, [parent, classification, name]);

  return (
    <SectionHeader
      customLeftRenderer={customLeftRenderer}
      fileStatus={getIngestStatus(asset)}
      onAccessLevelChange={handleAccessLevelChange}
      accessLevel={access_level}
      useBackground
      actions={headerActions}
      dropdownMenu={headerMenu()}
    />
  );
});
