import { Button } from '@mantine/core';
import { Plus } from 'blueprint5-icons';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { createNews } from 'utils/apis/news';
import { useNewsForm } from 'components/news/news-form/use-news';
import { ingestPreviewImage } from 'utils/apis/preview-image';
import { INewsFormData } from 'types/news';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { useDialogStore } from 'store/hooks';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { useRefreshDataSection } from 'utils/hooks';
import { delay } from 'utils/delay';
import { MantineIcon } from 'utils/ui/icon';

export const CreateNewsButton = observer((): JSX.Element => {
  const { close } = useDialogStore();
  const { toastStore } = useStore();
  const onSuccess = useRefreshDataSection();
  const handleSubmit = useCallback(
    async ({ previewImageUrl, ...values }: INewsFormData): Promise<AmGenericNews | void> => {
      try {
        let previewImage;
        if (previewImageUrl) {
          previewImage = await ingestPreviewImage(previewImageUrl);
        }

        const result = await createNews({ ...values, preview_image_id: previewImage?.id });
        await delay();
        close();
        onSuccess();
        toastStore.clearAll();
        toastStore.success('News created successfully!');
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, onSuccess, toastStore],
  );

  const onClick = useNewsForm({ title: 'Create News', handleSubmit });

  return (
    <Button size="xs" leftSection={<MantineIcon icon={<Plus />} />} variant="primary" onClick={onClick}>
      Create News
    </Button>
  );
});
