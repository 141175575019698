import { useCallback } from 'react';
import { searchListItems } from 'utils/apis/showcase-item';
import { useRemote } from 'utils/hooks';

export const useCollectionCounts = (
  collectionId?: number | null,
  sublist?: boolean | null,
): { productIds: number[]; loadingProducts: boolean; subCollectionIds: number[]; loadingSubCollections: boolean } => {
  const fetchProductsIds = useCallback(async (): Promise<number[]> => {
    if (!collectionId) return [];
    const items = await searchListItems(collectionId, [['entity_type', 'eq', 'product']], 'id, entity_id');
    return items.map((e) => e.entity_id);
  }, [collectionId]);
  const [productIds, loadingProducts] = useRemote(fetchProductsIds, []);

  const fetchSubCollectionIds = useCallback(async (): Promise<number[]> => {
    if (sublist || !collectionId) return [];
    const items = await searchListItems(collectionId, [['entity_type', 'eq', 'list/collection']], 'id, entity_id');
    return items.map((e) => e.entity_id);
  }, [sublist, collectionId]);

  const [subCollectionIds, loadingSubCollections] = useRemote(fetchSubCollectionIds, []);

  return {
    productIds: productIds || [],
    loadingProducts,
    subCollectionIds: subCollectionIds || [],
    loadingSubCollections,
  };
};
