import React, { useState, useCallback } from 'react';
import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { IFormMultiSelectOption } from 'helpers/form';

import { FormAddItemsToShowcaseForm } from './add-items-to-showcase-form';
import { FormSubmitSection } from 'components/form-submit-section';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';

interface IFormAddToGroupItemsForm {
  showcase?: UmGenericListShowcase | UmGenericListCollection;
  onSubmit: (items: IFormMultiSelectOption[]) => void;
}

export const AddItemsToShowcase: React.FC<IFormAddToGroupItemsForm> = ({ showcase, onSubmit }) => {
  const [selectedItems, setSelectedItems] = useState<IFormMultiSelectOption[]>([]);

  const handleSubmit = useCallback<React.ReactEventHandler>(
    (evt) => {
      evt.preventDefault();
      return onSubmit(selectedItems);
    },
    [onSubmit, selectedItems],
  );

  const { onSubmit: onClick, disabled } = useDisableWhileSubmitting(handleSubmit);

  return (
    <form onSubmit={onClick}>
      <FormAddItemsToShowcaseForm showcase={showcase} onSelectedItemsChange={setSelectedItems} />
      <FormSubmitSection submitDisabled={disabled || !selectedItems.length} />
    </form>
  );
};
