import React from 'react';
import cx from 'classnames';

import { IStyled } from 'types';

import './style.scss';
export interface IScrollWrapper extends IStyled {
  children: JSX.Element | JSX.Element[];
}

export const ScrollWrapper: React.FC<IScrollWrapper> = ({ children, className }) => {
  return (
    <div className={cx(`scroll-wrapper h-100 ${className}`)}>
      <div className="scroll-wrapper__content">{children}</div>
    </div>
  );
};

export default ScrollWrapper;
