import React from 'react';
import { observer } from 'mobx-react-lite';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';

import { formatFullName } from 'utils/general';
import { useDataSectionStore } from 'store';
import DateListElement from 'components/date-list-element';
import { CalGenericLocation } from '@mediafellows/mm3-types';
import { formatDateToTimezone } from 'components/locations-data-section/utils';

export const LocationPreview: React.FC<{}> = observer(() => {
  const { activeItem: location } = useDataSectionStore<CalGenericLocation>();

  const { name, meta, hosts, starts_at, ends_at, capacity, address, time_zone, created_at, updated_at } =
    location || {};

  if (!location?.id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  return (
    <PreviewTab hideThumbnail customThumbnail={<></>} title={name}>
      <PreviewInfo gridTemplateColumns="120px 1fr">
        <Row label="Name" hideIfNoValue value={name} />
        <Row label="Type" value={meta?.location_type as string} size="m" />
        <Row label="Hosts" value={hosts?.map((host) => formatFullName(host, false))} size="m" />
        <Row label="Capacity" value={capacity} size="m" />
        <Row label="Timezone" value={time_zone} />
        <Row
          label="Availability Start"
          value={<DateListElement date={formatDateToTimezone(starts_at, time_zone)} timeZone={time_zone} />}
          size="m"
        />
        <Row
          label="Availability End"
          value={<DateListElement date={formatDateToTimezone(ends_at, time_zone)} timeZone={time_zone} />}
          size="m"
        />
        <Row label="Address" value={address} size="m" />
        <Row label="Create at" rawLabel value={<DateListElement date={created_at} />} size="m" />
        <Row label="Updated at" rawLabel value={<DateListElement date={updated_at} />} size="m" />
      </PreviewInfo>
    </PreviewTab>
  );
});
