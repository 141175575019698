import React, { useCallback } from 'react';
import { UmGenericListItem } from '@mediafellows/mm3-types';

import { handleItemClick } from 'components/data-section';
import { useDataSectionStore } from 'store/hooks';
import { SeparatorListItem } from './data-section-list-item';

export const SeparatorItem: React.FC<{
  rightSection?: JSX.Element;
  contextMenu: JSX.Element;
  separator: UmGenericListItem;
}> = ({ contextMenu, separator }) => {
  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();
  const { id } = separator;

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e) => {
      if (!id) {
        return;
      }
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <SeparatorListItem
      separator={separator}
      handleClick={handleClick}
      contextMenu={contextMenu}
      isChecked={isChecked || isAllItemsSelected}
      isActive={isActive}
    />
  );
};
