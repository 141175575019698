import { IActionOpts } from '@mediafellows/chipmunk';
import { Model, ParametronOrder } from 'helpers/filters/types';
import { chunk, sortBy, indexOf } from 'lodash';
import { ItemId } from 'types/utility';
import { chipmunk, IResult } from 'utils/chipmunk';

export const customSortExecutor = async <T>(opts: IActionOpts, model: Model): Promise<IResult<T>> => {
  return chipmunk.run(async ({ action }) => {
    const filters = opts.body?.search.filters || [];
    const [, , entitiesIds = []] = filters.find(([field]) => field === 'id') || [];
    let options = opts;
    let cleanIds = [...new Set(entitiesIds)].filter(Boolean) as ItemId[];

    if (opts.params?.sort === 'sequence_number') {
      const { per = 25, page = 1 } = opts.params || {};
      if (opts.params.order === ParametronOrder.DESCENDING) {
        cleanIds = cleanIds.reverse();
      }
      const ids = chunk(cleanIds, per)[page - 1] || [];
      options = {
        ...opts,
        body: {
          ...opts.body,
          search: {
            ...(opts.body?.search || {}),
            filters: [['id', 'in', ids], ...filters.filter(([field]) => field !== 'id')],
          },
          page: 1,
        },
      };
      const paginationOptions = {
        ...options,
        body: {
          ...options.body,
          search: {
            ...(options.body?.search || {}),
            filters: [['id', 'in', cleanIds], ...filters.filter(([field]) => field !== 'id')],
          },
          per: 1,
        },
      };
      const result = await action(model, 'search', options);
      const paginationResult = await action(model, 'search', paginationOptions);

      const { total_count, current_page } = paginationResult?.pagination || {};
      const correctPagination = total_count
        ? { total_pages: Math.ceil(total_count / per), total_count, current_page }
        : undefined;

      const objects = sortBy(result.objects, ({ id }) => indexOf(cleanIds, id));

      return { ...result, objects, pagination: correctPagination || result.pagination };
    }

    return await action(model, 'search', options);
  });
};
