import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { IconAlias } from 'icons';
import { FormSubmitSection } from 'components/form-submit-section';

import { shouldDisplayAddEntity as shouldDisplay } from './actions-acl';
import { IConferenceActionConfig, IConferenceActionName } from 'utils/actions/conference/types';
import { pluralEntityInflect } from 'utils/general';

import { FormAssets, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { IAsset, IConference, IQueryParams, ISearchFilter } from 'types';
import { queryAssets } from 'utils/apis/asset';
import { updateConference } from 'utils/apis/conference';

export interface IAddAssetsToConferenceFormProps {
  handleSubmit: (conference: IConference) => void;
  conference: IConference;
}

export const AddAssetsToConferenceForm: React.FC<IAddAssetsToConferenceFormProps> = ({ conference, handleSubmit }) => {
  const { code, asset_ids } = conference || {};
  const { formData, values, handlers, onSubmit } = useForm<Partial<IConference>>(
    { code, asset_ids: [] },
    Model.PRODUCTS,
    handleSubmit,
  );

  const fetchItems = useCallback(
    (params: IQueryParams): Promise<IAsset[]> => {
      const filters: ISearchFilter[] = [['status', 'eq', 'available']];
      if (asset_ids?.length) {
        filters.push(['id', 'not_in', asset_ids]);
      }
      return queryAssets(params, filters);
    },
    [asset_ids],
  );

  return (
    <form onSubmit={onSubmit}>
      <FormAssets label="Assets" name="asset_ids" {...formData.asset_ids} {...handlers} fetchValues={fetchItems} />
      <FormSubmitSection submitDisabled={!values.asset_ids?.length} labels={{ confirm: 'Save' }} />
    </form>
  );
};

export const useAddAssetsAction = (items: IConference[], options: IUseActionsOptionParam): IConferenceActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async ({ code, asset_ids = [] }: IConference): Promise<void> => {
      const { entity, entityWithCount } = pluralEntityInflect('Asset', asset_ids.length);

      try {
        if (!items?.length) {
          return;
        }
        const result = await updateConference({ code, asset_ids: asset_ids.concat(items[0].asset_ids) });
        toastStore.success(`${entityWithCount} will be added`);
        await options?.onSuccess?.(IConferenceActionName.ADD_ASSETS, result);
        dialogStore.close();
      } catch (error) {
        toastStore.error(`Adding ${entity} to Conference failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [dialogStore, items, options, toastStore],
  );

  const handler = useCallback((): void => {
    dialogStore.openModal({
      title: 'Add Asset(s) to Conference',
      body: () =>
        items?.length ? <AddAssetsToConferenceForm conference={items[0]} handleSubmit={handleSubmit} /> : <></>,
    });
  }, [dialogStore, handleSubmit, items]);

  return {
    name: IConferenceActionName.ADD_ASSETS,
    icon: IconAlias.ACTION_ADD_ASSETS,
    title: 'Add Assets',
    handler,
    shouldDisplay,
  };
};
