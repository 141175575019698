import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import SelectionDetailsLayout from 'components/selection-details/selection-details-layout/selection-details-layout';
import { IContactActionName } from 'utils/actions';
import { ContactSelectionActionsList } from 'components/action';
import { useNavigate, useParams } from 'react-router';
import { ISelection, ISelectionStandardized } from 'types';
import useSelection from 'utils/hooks/selection';
import { IActionName, ITitleDividerActionName, Pages } from 'utils/actions/types';
import mapSelection from 'components/selection/map-selection';
import ContactDataSection from 'components/contact-data-section';
import { IFilterOption } from 'utils/hooks/control-data-section';
import { ISelectionActionName } from 'utils/actions/selection/types';
import { IGroupActionName } from 'utils/actions/common/types';
import { useRefreshDataSection } from 'utils/hooks';
import { Routes } from 'utils/routes';
import { ActionsMenu } from 'components/action/utils';

const options = {
  groupName: ITitleDividerActionName.SELECTION,
  groupParamName: 'selectionId',
};

export const mapContactSelection = (contactSelection: ISelection): ISelectionStandardized =>
  mapSelection(contactSelection, contactSelection.users_count);

const page = Pages.SELECTION_DETAILS;

const ContactSelectionDetails: React.FC = observer(() => {
  const { selectionId = '' } = useParams<{ selectionId: string }>();
  const [selection, selectionLoading, refreshSelection, setSelection] = useSelection(selectionId, mapContactSelection);

  const persistentFilters: IFilterOption[] = useMemo(
    () => (selectionId ? [['group_ids', 'eq', selectionId]] : []),
    [selectionId],
  );

  if (!selectionId) {
    return null;
  }

  const refreshDataSection = useRefreshDataSection();
  const navigate = useNavigate();
  const onSuccess = useCallback(
    async (action?: string, selection?) => {
      switch (action) {
        case IGroupActionName.ADD_ENTITIES:
          setTimeout(() => {
            refreshDataSection();
            refreshSelection();
          }, 1000);
          break;

        case ISelectionActionName.DELETE:
          navigate(`${Routes.CONTACT_SELECTIONS}`);
          break;
        case ISelectionActionName.CHANGE_ACCESS:
          setSelection(mapContactSelection(selection));
          break;
        default:
          await refreshSelection();
      }
    },
    [refreshSelection, navigate, setSelection, refreshDataSection],
  );

  const dataSectionOnSuccess = useCallback(
    (action?: IActionName | undefined): void => {
      if (action === IContactActionName.REMOVE_FROM_GROUP) {
        setTimeout(refreshSelection, 1000);
      }
    },
    [refreshSelection],
  );

  return (
    <SelectionDetailsLayout
      selection={selection as ISelectionStandardized}
      selectionLoading={selectionLoading}
      refreshSelection={refreshSelection}
      headerDropdownMenu={
        <ActionsMenu
          component={ContactSelectionActionsList}
          items={[selection as ISelectionStandardized]}
          options={{ ...options, context: 'single', page, onSuccess }}
        />
      }
    >
      <ContactDataSection
        page={Pages.SELECTION_ENTITY_TAB}
        actionsOptions={{ ...options, onSuccess: dataSectionOnSuccess, page: Pages.SELECTION_ENTITY_TAB }}
        persistentFilters={persistentFilters}
      />
    </SelectionDetailsLayout>
  );
});

export default ContactSelectionDetails;
