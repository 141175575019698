import { GroupTypes, IMarketingEvent, IQueryParams, ISearchFilter } from 'types';
import { parseToSearchParams } from 'utils/general';
import { searchGroups } from './groups';
import { EventSchema } from 'utils/schemas';

export const queryEvents = async (params: IQueryParams, filters: ISearchFilter[] = []): Promise<IMarketingEvent[]> => {
  const searchParams = parseToSearchParams(
    params as IQueryParams,
    (
      [
        ['type', 'eq', GroupTypes.EVENT],
        ['subevent', 'eq', true],
      ] as ISearchFilter[]
    ).concat(filters),
  );

  return searchGroups(searchParams, EventSchema) as Promise<IMarketingEvent[]>;
};

export const queryParentEvents = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<IMarketingEvent[]> => {
  const searchParams = parseToSearchParams(
    params as IQueryParams,
    (
      [
        ['type', 'eq', GroupTypes.EVENT],
        ['subevent', 'eq', false],
      ] as ISearchFilter[]
    ).concat(filters),
  );

  return searchGroups(searchParams, EventSchema) as Promise<IMarketingEvent[]>;
};
