import { IQueryParams } from 'types';
import { Model } from 'helpers/filters/types';
import { autosuggestValues } from 'utils/autosuggest-values';

export const getMm3Suggestions = async ({ ids, q }: IQueryParams, property: string): Promise<string[]> => {
  if (ids) {
    return ids as string[];
  }
  return autosuggestValues(Model.MM3_ASSETS, property, q as string);
};

export const getSuggestions = async ({ ids, q }: IQueryParams, model: Model, property: string): Promise<string[]> => {
  if (ids) {
    return ids as string[];
  }
  return autosuggestValues(model, property, q as string);
};

export const getMm3Tags = async (params: IQueryParams): Promise<string[]> => {
  return getSuggestions(params, Model.MM3_ASSETS, 'tags');
};

export const getTags = async (params: IQueryParams): Promise<string[]> => {
  return getSuggestions(params, Model.ASSETS, 'default_layer.tags');
};
