import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { AmGenericNews } from '@mediafellows/mm3-types';

import { Model } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { newsListSchema } from 'utils/schemas/news';
import { useControlDataSection, useRefreshDataSection } from 'utils/hooks';

import { NewsFilters, defaultFilters } from './filters-tab';
import { NewsPreviewTab } from './preview-tab';
import { NewsDataSectionItem } from './data-section-item';
import { defaultParams, getSortMenu, useRefreshIngestingPreviewImages } from './utils';
import { NewsSelectedItem } from './selected-item';
import { CreateNewsButton } from 'components/news/create-news-button';
import { NewsActionsList } from 'components/action';
import { useDataSectionStore } from 'store/hooks';
import { Pages } from 'utils/actions/types';
import { ActionsMenu } from 'components/action/utils';

const params = {
  model: Model.NEWS,
  presetSuffix: 'news',
  defaultFilters,
  schema: newsListSchema,
  stats: 'access_level,owner_id',
};

const sidebarTabs = {
  preview: <NewsPreviewTab />,
  filters: <NewsFilters />,
  selectedItemRenderer(news: AmGenericNews): React.ReactElement {
    return <NewsSelectedItem news={news} />;
  },
};

export const NewsDataSection: React.FC<{ persistFilters?: boolean }> = observer(({ persistFilters }) => {
  useControlDataSection({ params: defaultParams, ...params, persistFilters });
  const { checked } = useDataSectionStore<AmGenericNews>();
  useRefreshIngestingPreviewImages();

  const refreshDataSection = useRefreshDataSection();

  const itemRenderer = useCallback(
    (news: AmGenericNews): React.ReactElement => {
      if (!news?.id) {
        return <></>;
      }

      return (
        <NewsDataSectionItem
          key={news.id}
          news={news}
          contextMenu={
            <ActionsMenu
              component={NewsActionsList}
              items={[news]}
              options={{ context: 'single', page: Pages.LIST, onSuccess: refreshDataSection }}
            />
          }
        />
      );
    },
    [refreshDataSection],
  );

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={itemRenderer}
      contextMenu={
        <ActionsMenu
          component={NewsActionsList}
          items={checked}
          options={{ context: 'multi', page: Pages.LIST, onSuccess: refreshDataSection }}
        />
      }
      headerRightSection={<CreateNewsButton />}
      defaultParams={defaultParams}
      customSortMenu={getSortMenu}
      customOrderRightSection
    />
  );
});
