import { AmGenericNews } from '@mediafellows/mm3-types';
import { IFile } from 'types';
import { updateNews } from 'utils/apis/news';
import { deletePreviewImage, ingestPreviewImage } from 'utils/apis/preview-image';
import { logger } from 'utils/logger';

export const uploadPreviewImage = async (file: Pick<IFile, 'url'>, id: number): Promise<AmGenericNews | void> => {
  if (!file?.url) {
    logger.error('No file or file.url provided to uploadPreviewImage');
    return;
  }
  const previewImage = await ingestPreviewImage(file.url);

  return updateNews({ id, preview_image_id: previewImage.id });
};

export const deleteNewsPreviewImage = async (news: AmGenericNews): Promise<AmGenericNews> => {
  const [, updatedNews] = await Promise.all([
    deletePreviewImage(news.preview_image_id),
    updateNews({ id: news.id, preview_image_id: null }),
  ]);
  return updatedNews;
};
