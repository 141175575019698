import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { useBasicStore } from 'store/hooks';
import { useDataSectionStore } from 'store';

import { Row } from 'components/label-value-pair';
import { OrganizationLogo } from 'components/organization/organization-logo';
import { getWebsiteLinks } from 'components/entity-list';
import { InfoBox } from 'components/info-box';

import { DetailsPageTabs, IOrganization } from 'types';
import { Routes } from 'utils/routes';
import { InfoBoxV2 } from 'components/info-box-v2';
import { formatDate } from 'utils/date';
import { formatAddress } from 'utils/general';
import { LinkWithArrow, DetailCountLink } from 'utils/ui';

const OrganizationPreviewTab: React.FC = observer(() => {
  const { countries } = useBasicStore();
  const { activeItem: organization } = useDataSectionStore<IOrganization>();

  const {
    id: orgId,
    status,
    name,
    classification,
    legal_entity,
    addresses,
    phones,
    country,
    language,
    deleted_at,
    created_at,
    updated_at,
    default_layer,
    users_count,
  } = organization || {};
  return (
    <div className={cx('entity-preview', { 'd-flex align-items-center justify-content-center': !organization })}>
      {organization && (
        <>
          <div className="d-flex flex-column align-items-center">
            <div className="entity-preview__title">
              <h3>{name}</h3>
            </div>
            <div className="entity-preview__subtitle">
              <h4>{getWebsiteLinks(organization?.urls)}</h4>
            </div>
            <div className="contact-preview__thumb">
              <OrganizationLogo organization={organization} />
            </div>
            <div className="entity-preview__actions w-100 py-3">
              {!deleted_at && <LinkWithArrow to={`/organizations/${orgId}`} text="View Details" />}
            </div>
          </div>
          <div className="my-3">
            {default_layer?.subtitle && (
              <>
                <InfoBox gridTemplateColumns="1fr" title={true}>
                  <div>Description</div>
                  <div className="entity-preview__description">{default_layer?.subtitle}</div>
                </InfoBox>
              </>
            )}

            <InfoBoxV2 gridTemplateColumns="1fr 2fr">
              <Row label="Type" value={classification} size="m" />
              <DetailCountLink
                entityCount={users_count}
                link={`${Routes.ORGANIZATIONS}/${orgId}/${DetailsPageTabs.CONTACTS}`}
                label="Contacts"
              />
              <Row label="Status" value={status} size="m" />
              <Row label="Legal entity" value={legal_entity} size="m" rawLabel />
              <Row label="Address" value={formatAddress(addresses?.[0], countries)} size="m" />
              <Row label="Country" value={country?.name} size="m" />
              <Row label="Created at" value={formatDate(created_at)} hideIfNoValue rawLabel />
              <Row label="Updated at" value={formatDate(updated_at)} hideIfNoValue rawLabel />
              <Row label="Deleted at" value={formatDate(deleted_at)} hideIfNoValue rawLabel />
              <Row label="Phone" value={phones && phones[0]?.number} size="m" />
              <Row label="Language" value={language?.name} size="m" />
            </InfoBoxV2>
          </div>
        </>
      )}
      {!organization && <span className="entity-preview-tab__no-preview">No preview</span>}
    </div>
  );
});
export default OrganizationPreviewTab;
