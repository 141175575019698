import React from 'react';

import { IContact, IStyled } from 'types';
import { FormRemoteSelect, IFieldData, IFieldHandlers, IFormRemoteSelectOption } from 'helpers/form';
import { contactListSchema } from 'utils/schemas';
import { getContacts, fetchResponsibleContacts } from 'utils/apis/contacts';

export interface IFormResponsibleContacts
  extends IFieldData<string | number, (number | string | IFormRemoteSelectOption)[]>,
    IFieldHandlers<string | number>,
    IStyled {
  name?: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;

  placeholder?: string;
  showPositiveValidation?: boolean;
}

const fetchContact = async (q: string, id: number): Promise<IContact[]> => {
  if (id) {
    return getContacts({ contactIds: id, schema: contactListSchema });
  }

  return fetchResponsibleContacts(q);
};

export const FormResponsibleContacts: React.FC<IFormResponsibleContacts> = (props) => {
  return (
    <FormRemoteSelect
      name="responsible_user_id"
      label="Responsible Contact"
      large
      fetchOptions={fetchContact}
      {...props}
      enableClearing
    />
  );
};

export default FormResponsibleContacts;
