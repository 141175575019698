import Uppy, { Uppy as IUppy, UppyFile } from '@uppy/core';
import DropZone from '@uppy/drag-drop';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import type { IUploadType } from './types';
import { chipmunk } from 'utils/chipmunk';
import { getConfig } from 'utils/companion';
import { parseFileName } from 'utils/assets-upload';

interface IInitializeUppyParams {
  target?: string;
  type?: IUploadType;
  extensions?: string[] | null;
  autoProceed?: boolean;
}

export function initializeUppy(params: IInitializeUppyParams): Promise<IUppy> {
  const { target = 'upload-preview-image', type, extensions, autoProceed = true } = params;

  return chipmunk.run(async ({ action }) => {
    const { companionUrl, power } = getConfig(type);

    const result = await action('um.session', 'token', { params: { power } });
    const jwtToken = result?.object?.token;

    const uppy = new Uppy({
      autoProceed,
      restrictions: { allowedFileTypes: extensions },
      onBeforeFileAdded: (currentFile: UppyFile) => {
        uppy.cancelAll();
        const filename = parseFileName(currentFile.name);
        return { ...currentFile, meta: { ...currentFile.meta, filename, name: filename } };
      },
    }).use(AwsS3Multipart, {
      limit: 1,
      companionUrl,
      companionHeaders: {
        'uppy-auth-token': jwtToken,
      },
    });

    if (target) {
      uppy.use(DropZone, {
        target: `#${target}`,
        width: '100%',
        locale: { strings: { dropHereOr: 'Drop a file here or %{browse}', browse: 'browse' } },
      });
    }

    return uppy;
  });
}
