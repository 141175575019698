import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { IContact } from 'types';

export const NotesSection: React.FC<IContact> = (contact) => {
  const { notes } = contact;
  return (
    <InfoBoxV2 gridTemplateColumns="1fr" wrapperClassName="py-3" title="Notes" withBorder>
      {notes && <div>{notes}</div>}
    </InfoBoxV2>
  );
};
