import React, { useState, useMemo } from 'react';
import { Flex, Tabs } from '@mantine/core';

import { EmptySectionMessage } from 'components/section-message/section-message';

import { ITitleDividerActionName, Pages } from 'utils/actions';
import { DetailsPageTabs, IGroup } from 'types';

import { SortButton } from 'components/sort-buttons';
import { DataSectionControls } from 'components/data-section-controls';
import { GroupDetailContactsTab, GroupDetailAssetsTab } from 'components/marketing-entity-detail';
import { CollectionTabPanel, ScreeningDetailTab } from './utils';
import { AddItemsToScreeningRoomButton } from './tab-buttons';
import { IActionName } from 'utils/actions/types';

const options = {
  page: Pages.SCREENING_ROOM_DETAILS,
  groupName: ITitleDividerActionName.SCREENING_ROOM,
  groupParamName: 'screeningRoomId',
};

interface IScreeningRoomDetailsTabsView {
  screeningRoom?: IGroup;
  editModeEnabled?: boolean;
  currentTab: DetailsPageTabs;
  onTabChangeHandle: (tab: DetailsPageTabs) => void;
  onSuccess?: (action?: IActionName) => void;
}

export const ScreeningRoomDetailsTabsView: React.FC<IScreeningRoomDetailsTabsView> = ({
  screeningRoom,
  currentTab,
  onTabChangeHandle,
  onSuccess,
  editModeEnabled,
}) => {
  const { id } = screeningRoom || {};
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  const actionsOptions = useMemo(
    () => ({
      ...options,
      groupId: id,
      onSuccess,
      groupName: ITitleDividerActionName.SCREENING_ROOM,
      groupParamName: 'screeningRoomId',
      page: Pages.SCREENING_ROOM_DETAILS,
    }),
    [id, onSuccess],
  );

  if (!id || !screeningRoom) {
    return <EmptySectionMessage />;
  }

  return (
    <Tabs value={currentTab} onChange={onTabChangeHandle} className="marketing-entity-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <ScreeningDetailTab value={DetailsPageTabs.ASSETS} activeTab={currentTab} disabled={editModeEnabled} />

        <ScreeningDetailTab
          value={DetailsPageTabs.CONTACTS}
          activeTab={currentTab}
          disabled={isSortModeEnabled || editModeEnabled}
        />

        <Flex gap="xs" className="ms-auto me-4 align-self-center">
          <SortButton
            isSortModeEnabled={isSortModeEnabled}
            setIsSortModeEnabled={setIsSortModeEnabled}
            hidden={currentTab !== DetailsPageTabs.ASSETS}
          />
          <AddItemsToScreeningRoomButton
            disabled={isSortModeEnabled}
            screeningRoom={screeningRoom}
            refreshScreeningRoom={onSuccess}
            currentTab={currentTab}
          />
          <DataSectionControls hidden={currentTab !== DetailsPageTabs.ASSETS} disabled={isSortModeEnabled} />
        </Flex>
      </Tabs.List>
      <CollectionTabPanel
        value={DetailsPageTabs.ASSETS}
        content={
          <GroupDetailAssetsTab isSortModeEnabled={isSortModeEnabled} groupId={id} actionOptions={actionsOptions} />
        }
      />
      <CollectionTabPanel
        value={DetailsPageTabs.CONTACTS}
        content={<GroupDetailContactsTab groupId={id} options={actionsOptions} page={Pages.SCREENING_ROOM_DETAILS} />}
      />
    </Tabs>
  );
};
