import React from 'react';
import { IconName } from 'blueprint5-icons';
import { NavLink } from 'react-router-dom';
import { Menu } from '@mantine/core';

import { CheckPowers } from 'components/check-power';
import { hideSideBarFlags } from 'utils/flags';
import { Routes } from 'utils/routes';
import { MantineIcon } from 'utils/ui/icon';
import { powersByRoute } from 'components/sidebar/utils';

interface ISidebarCreateItemProps {
  link: Routes;
  icon: IconName;
  text: string;
}

export const SidebarCreateItem: React.FC<ISidebarCreateItemProps> = ({ link, icon, text }) => {
  return (
    <CheckPowers requiredPowers={powersByRoute[link]} hideAnyway={hideSideBarFlags[link]}>
      <NavLink to={link} className="sidebar__navlink">
        <Menu.Item
          px={15}
          leftSection={<MantineIcon className="sidebar__icon" icon={icon} />}
          className="sidebar__create-item"
        >
          {text}
        </Menu.Item>
      </NavLink>
    </CheckPowers>
  );
};
