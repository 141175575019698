import React from 'react';
import cx from 'classnames';
import { ThemeIcon, ThemeIconProps } from '@mantine/core';
import { getIconPaths } from 'blueprint5-icons';
import { BlueprintIcons_16Id } from 'blueprint5-icons/lib/esm/generated/16px/blueprint-icons-16';
import { IconSize } from './icon-size';

interface IIconProps extends ThemeIconProps {
  icon?: string | JSX.Element;
  size?: string | number;
  className?: string;
  style?: React.CSSProperties;
  variant?: string;
  color?: string;
  fill?: string;
  onClick?: () => void;
}

export const MantineIcon = ({
  icon,
  size,
  className,
  style,
  color,
  variant,
  fill,
  ...props
}: IIconProps): JSX.Element | null => {
  if (!icon) return null;

  if (typeof icon === 'string') {
    const pathStrings = getIconPaths(icon as BlueprintIcons_16Id, IconSize.STANDARD);
    const iconSize = IconSize.STANDARD;
    const viewBox = `0 0 ${iconSize} ${iconSize}`;
    size = size || 16;

    return (
      <svg
        fill={fill || color || 'currentColor'}
        data-icon={icon}
        width={size}
        height={size}
        viewBox={viewBox}
        className={cx(`mantine-icon ${className}`)}
        style={{ ...style }}
      >
        {icon && <desc>{icon}</desc>}
        {pathStrings?.map((d, i) => (
          <path key={i} d={d} fillRule="evenodd" />
        ))}
      </svg>
    );
  }

  return (
    <ThemeIcon className={className} variant={variant} size={size || 'l'} color={color} {...props}>
      {icon}
    </ThemeIcon>
  );
};
