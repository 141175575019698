import React, { useCallback } from 'react';
import { IGroup, IIdentifiable } from 'types';
import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions';

import { AccessPrivilegeEmailSetup } from 'components/access-privilege';

import { IAccessPrivilegeActionConfig, IAccessPrivilegeActionName } from './types';
import { shouldDisplayEmail as shouldDisplay } from './actions-acl';

export const useEmailAction = (
  items: (IGroup & IIdentifiable)[],
  options: IUseActionsOptionParam,
): IAccessPrivilegeActionConfig => {
  const {
    dialogStore: { openModal, close: closeModal },
  } = useStore();

  const itemIds = items.map(({ id }) => id);

  const body = useCallback(() => {
    const onSuccess = (): void => {
      options?.onSuccess?.();
      closeModal();
    };

    const onFailure = (): void => {
      options?.onFailure?.();
      closeModal();
    };
    return <AccessPrivilegeEmailSetup itemIds={itemIds} onSuccess={onSuccess} onFailure={onFailure} />;
  }, [itemIds, closeModal, options]);

  const openDialog = (): void => {
    openModal({
      title: 'Send emails to access privilege contacts',
      className: 'screening-rooms-email-modal',
      body,
    });
  };

  return {
    name: IAccessPrivilegeActionName.EMAIL,
    shouldDisplay,
    icon: 'envelope',
    title: 'Email',
    handler: openDialog,
  };
};
