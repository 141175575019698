import React, { useCallback } from 'react';
import { FormSubmitSection } from 'components/form-submit-section';
import { ModalWrapper } from 'components/dialogs';
import { IConfirmDialogProps } from 'components/dialogs/types';

const ConfirmDialog: React.FC<IConfirmDialogProps & { close: () => void }> = ({
  icon,
  title,
  body,
  onConfirm,
  close,
  canOutsideClickClose = false,
}) => {
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      close();
      onConfirm && onConfirm();
    },
    [close, onConfirm],
  );

  const Body = useCallback(() => {
    return (
      <form onSubmit={handleSubmit}>
        <p>{body}</p>
        <FormSubmitSection />
      </form>
    );
  }, [body, handleSubmit]);

  return (
    <ModalWrapper
      opened
      title={title}
      icon={icon}
      body={Body}
      canOutsideClickClose={canOutsideClickClose}
      onClose={() => null}
    />
  );
};

export default React.memo(ConfirmDialog);
