import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { IFetchContactActivityResponse } from 'types';

interface IFetchActivityParams {
  page: number;
  contactId: string;
}

export function fetchContactActivityData({
  page,
  contactId,
}: IFetchActivityParams): Promise<IFetchContactActivityResponse> {
  return chipmunk.run(async (chipmunk) => {
    const data = await chipmunk.action('ac.event/user', 'query_activities', {
      schema:
        'effective_at, description, user_title, user_id, client_ip, entity_title, other_entity_title, other_entity_type, other_entity_id, diff',
      params: { user_id: contactId, page },
    });

    const user = (
      await chipmunk.action(Model.CONTACTS, 'search', {
        schema: 'first_name, last_name, preview_image, id',
        params: { ids: contactId },
      })
    ).objects[0];

    const newContactActivityData = data.objects.map((activity) => {
      const {
        user_title,
        description,
        client_ip,
        entity_title,
        other_entity_title,
        effective_at,
        other_entity_type,
        other_entity_id,
        diff,
      } = activity;

      return {
        user_title,
        effective_at,
        description,
        client_ip,
        entity_title,
        other_entity_title,
        other_entity_type,
        other_entity_id,
        diff,
        user,
      };
    });

    return { data: newContactActivityData, totalPages: data.pagination?.total_pages };
  });
}
