import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ToastError } from 'components/toast';
import { AssignCastCrewToProductForm } from 'components/cast-crew';
import type { IAssignCastCrewToProductFormData } from 'components/cast-crew';
import { updateProductLayer } from 'utils/apis/product';
import { cleanUp } from 'utils/payload';

import { IProduct } from 'types';
import { IProductActionConfig, IProductActionName } from './types';
import { shouldDisplayAddCastCrew } from './actions-acl';

const hiddenFields: ['product_ids'] = ['product_ids'];

export const useAddCastCrewToProductsAction = (
  items: IProduct[],
  options: IUseActionsOptionParam,
): IProductActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IAssignCastCrewToProductFormData): Promise<void> => {
      const { cast_crew, products, cast_character, kind, cast_role, crew_position } = values;
      const { id, default_layer, default_layer_id } = items[0];
      try {
        const data = cleanUp({
          id: default_layer_id,
          product_id: id,
          meta: {
            ...default_layer.meta,
            cast_crew: [
              ...(default_layer.meta?.cast_crew || []),
              ...cast_crew.map(({ id, name }) => ({
                cast_crew_id: id || 0,
                kind,
                name,
                ...(kind === 'Cast' ? { cast_character, cast_role } : { crew_position }),
              })),
            ],
          },
        });
        const result = await updateProductLayer(data);

        toastStore.success('Success');
        options?.onSuccess?.(IProductActionName.ADD_CAST_CREW, products[0], result);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        options?.onFailure?.();
      }
    },
    [dialogStore, items, options, toastStore],
  );

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Add Cast & Crew',
      body: () => {
        return (
          <AssignCastCrewToProductForm
            data={{ product_ids: items.map((e) => e.id), products: items }}
            hiddenFields={hiddenFields}
            handleSubmit={handleSubmit}
          />
        );
      },
    });
  };

  return {
    name: IProductActionName.ADD_CAST_CREW,
    icon: 'plus',
    title: 'Add Cast & Crew',
    shouldDisplay: shouldDisplayAddCastCrew,
    handler,
  };
};
