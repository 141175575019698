import React, { useCallback, useEffect, useMemo } from 'react';
import { FormCheckbox, useForm } from 'helpers/form';
import { observer } from 'mobx-react-lite';
import { Button, Divider } from '@mantine/core';

import { Model } from 'helpers/filters/types';
import { useAssetsUploadToSelectionStore } from 'store/hooks';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IAssetsFormHeader {
  openPresetModal: () => void;
}
export const AssetsFormHeader: React.FC<IAssetsFormHeader> = observer(({ openPresetModal }) => {
  const { files, setFiles } = useAssetsUploadToSelectionStore();

  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedFiles = useMemo(() => files.filter(({ selected }) => selected).length, [files]);

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedFiles === files.length });
  }, [onChange, numberOfSelectedFiles, files]);

  const onSelectAllAssetsChange = useCallback(
    ({ selectedAssets }) => {
      setFiles(files.map((file) => ({ ...file, selected: selectedAssets })));
    },
    [setFiles, files],
  );

  if (files.length === 0) {
    return null;
  }

  return (
    <div className="upload-assets-form-header__form-select">
      <FormCheckbox
        label={`${numberOfSelectedFiles}/${files.length}`}
        name="selectedAssets"
        {...formData.selectedAssets}
        {...handlers}
        onChange={onSelectAllAssetsChange}
      />
      <Divider orientation="vertical" mx={10} />
      <div />
      <Button variant="subtle" className="upload-assets-form-header__select-button" onClick={openPresetModal}>
        Select Preset
      </Button>
    </div>
  );
});

export default React.memo(AssetsFormHeader);
