import React from 'react';

import { IFormData, IFormHandlers } from 'helpers/form';
import { IContactFields } from 'pages/upsert-contact';

interface ICustomFieldsProps {
  handlers: IFormHandlers<IContactFields>;
  formData: IFormData<IContactFields>;
}

export const CustomFields: React.FC<ICustomFieldsProps> = () => {
  return <></>;
};
