import { IconName } from 'blueprint5-icons';
import { IIdentifiable } from 'types';
import { IShouldDisplayCommonActionFunction, IUseActionsOptionParam, ITitleDividerActionName } from 'utils/actions';
import { ICommonActionConfig } from './types';

interface IUseTitleActionOptions extends IUseActionsOptionParam {
  shouldDisplay?: IShouldDisplayCommonActionFunction;
  title?: string;
  icon?: IconName;
  name?: ITitleDividerActionName;
}

export const useTitleAction = (items: IIdentifiable[], options?: IUseTitleActionOptions): ICommonActionConfig => {
  return {
    name: options?.name ?? ITitleDividerActionName.UNDEFINED,
    isTitle: true,
    title: options?.title,
    icon: options?.icon,
    shouldDisplay: options?.shouldDisplay,
  };
};
