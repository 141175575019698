import { UmGenericListShowcase, UmGenericListCollection } from '@mediafellows/mm3-types';
import { IActionConfig } from 'utils/actions/types';

export enum IShowcaseActionName {
  DELETE = 'delete',
  ADD_ITEMS = 'add-items',
  EDIT = 'edit',
  UPLOAD_PREVIEW = 'upload-preview',
  DELETE_PREVIEW = 'delete-preview',
  GENERATE_SLIDER = 'generate-slider',
}

export type IShowcaseActionConfig = IActionConfig<UmGenericListShowcase | UmGenericListCollection, IShowcaseActionName>;
