import React, { useState, useEffect } from 'react';
import logoURL from 'assets/img/mediafellows_logo.png';
import { Image, ImageProps } from '@mantine/core';

import { STORAGE_KEYS, getStorageItem } from 'utils/storage';

import { Globals } from 'types';

import './style.scss';

export const AffiliationLogo: React.FC<ImageProps> = ({ ...props }) => {
  const affiliationLogo = getStorageItem<string | null>(STORAGE_KEYS.WEBSITE_LOGO);
  const brandHeader = getStorageItem<string | null>(STORAGE_KEYS.WEBSITE_BRAND_HEADER);

  const [logo, setLogo] = useState<string | null>(affiliationLogo);
  const [header, setHeader] = useState<string | null>(brandHeader);

  useEffect(() => {
    window.addEventListener(Globals.AFFILIATION_LOGO_UPDATE, () => {
      const newLogo = getStorageItem<string | object>(STORAGE_KEYS.WEBSITE_LOGO);
      const newHeader = getStorageItem<string | null>(STORAGE_KEYS.WEBSITE_BRAND_HEADER);

      if (newLogo !== affiliationLogo) {
        if (typeof newLogo === 'object' && Object.keys(newLogo).length === 0) setLogo('');
        else if (typeof newLogo === 'string') setLogo(newLogo);
      }
      if (newHeader !== brandHeader) {
        setHeader(newHeader);
      }
    });
  }, [affiliationLogo, brandHeader]);

  if (logo && logo !== '') {
    return <Image className="affiliation-logo" src={logo as string} alt="Affiliation Logo" {...props} />;
  }
  if (header && header !== '') {
    return <div className="brand-header">{header}</div>;
  }
  return <Image src={logoURL} alt="Mediafellows logo" {...props} />;
};
