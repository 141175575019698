import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterSelect } from 'helpers/filters/fields';
import { IFiltersDefinition, EqFilter } from 'helpers/filters/types';
import { searchByParam } from 'helpers/filters/helpers';
import { useStore } from 'store';
import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';

export interface IBasketsFilters extends IFiltersDefinition {
  owner_id: EqFilter;
}

export const defaultBasketFilters = {
  owner_id: new EqFilter('owner_id', ''),
};

const BasketFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();

  const handleSubmit = (action): void => {
    const customParamVal = filterValues['owner_id'].value ? filterValues['owner_id'].value : null;
    searchByParam({
      action,
      customParams: {
        owner_id: customParamVal,
      },
    });
  };

  const [filterValues, filterHandlers] = useFilters<IBasketsFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="entity-filters__new-filter">
        <form>
          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});

export default BasketFilters;
