import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import DateElement from 'components/date-list-element/date-list-element';
import { IBasketDataSectionWrappedComponent } from 'components/baskets/default-basket-list-item/types';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';

import { getEntityLabel, pluralWordInflect } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { fetchThumbnailImages } from 'components/baskets/utils';

import '../style.scss';

export const BasketDataSectionItem: React.FC<IBasketDataSectionWrappedComponent> = ({
  basket,
  contextMenu,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const { pathname } = useLocation();
  const { id, name, entityType, entityCount, owner, updatedAt } = basket;

  const fetcher = useCallback(() => fetchThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="baskets-list-item product-baskets" onClick={handleClick}>
      <div className="baskets-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="baskets-list-item__thumb">
        <MemoizedMultiThumbnail thumbnailImages={thumbnailImages || [{}]} />
      </div>
      <div className="baskets-list-item__content">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row align-items-center text-truncate baskets-list-item__title">
            <Link to={`${pathname}/${id}`}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
          </div>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className="baskets-list-item__row--products bp3-text-muted">
          <div className="text-nowrap text-truncate text-capitalize product-baskets__entity">
            {pluralWordInflect(getEntityLabel(entityType), entityCount)}
          </div>
          <div className="text-nowrap text-truncate product-baskets__owner">{owner}</div>
          <div className="text-nowrap text-truncate product-baskets__created">
            <DateElement date={updatedAt} mfxIcon="updated-at" />
          </div>
        </div>
      </div>
    </div>
  );
};
