import React, { useCallback } from 'react';
import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayDeletePreviewImageAction as shouldDisplay } from './actions-acl';
import { removeUserPreviewImage } from 'utils/actions/contact/api';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';
import { Intent } from 'utils/ui';

export const useDeleteAvatarImageAction = (
  items: IContact[],
  options: IUseActionsOptionParam,
): IContactActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const onConfirm = useCallback(async (): Promise<boolean> => {
    try {
      const user = items[0];
      await removeUserPreviewImage(user);
      toastStore.success('Success');
      options?.onSuccess?.();
      return true;
    } catch (error) {
      toastStore.error(
        <div>
          <div>{error.title}</div>
          <div>{error.description}</div>
        </div>,
      );
      options?.onFailure?.();

      return false;
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: `Delete Avatar`,
      body: 'Are you sure you want to delete this image?',
      onConfirm,
    });
  };

  return {
    name: IContactActionName.DELETE_PREVIEW,
    shouldDisplay,
    icon: 'delete',
    title: 'Delete avatar',
    intent: Intent.DANGER,
    handler,
  };
};
