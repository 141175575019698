import { CalGenericLocation } from '@mediafellows/mm3-types';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';

interface ILocation extends Omit<CalGenericLocation, 'time_zone' | 'name' | 'starts_at' | 'ends_at' | 'meta'> {
  time_zone?: string | undefined;
  name?: string | undefined;
  starts_at?: string | undefined;
  ends_at?: string | undefined;
  meta?: {
    location_type?: 'event_space' | 'internal' | 'other' | 'pod' | 'room' | 'table';
  };
}

export const initialValues: ILocation = {
  name: undefined,
  address: undefined,
  capacity: 0,
  starts_at: undefined,
  ends_at: undefined,
  host_ids: [],
  allow_overlapping_meetings: false,
  time_zone: undefined,
  meta: { location_type: undefined },
};

export const customValidator = (
  values: CalGenericLocation,
  validation: IValidationResult<CalGenericLocation>,
): IValidationResult<CalGenericLocation> => {
  if (!validation?.meta?.location_type || values?.meta?.location_type) {
    return validation;
  }

  return { ...validation, meta: { ...validation.meta, location_type: getRequiredError() } };
};
