import React from 'react';
import { Spotlight } from '@mantine/spotlight';

import { AssetSearchItem } from 'components/asset/asset-search-item';
import { GroupSearchItem } from 'components/group-search-item';
import { OrganizationSearchItem } from 'components/organization';
import { ProductSearchItem } from 'components/product-search-item';
import { UserSearchItem } from 'components/user-search-item';
import { isAsset, isProduct } from 'utils/general';
import { IAsset, IContact, IItem, IGroup, IOrganization, IProduct } from 'types';

interface IItemsRenderers {
  renderAssets: () => React.ReactNode;
  renderProducts: () => React.ReactNode;
  renderUsers: () => React.ReactNode;
  renderOrgs: () => React.ReactNode;
  renderGroups: () => React.ReactNode;
}

export function useSearchItemsRenderer(items: IItem[], handleSelect): IItemsRenderers {
  const getDivider = (title: string, children): React.ReactElement => (
    <>
      <Spotlight.ActionsGroup className="searchbox__divider" key={`${title}-divider`} label={title}>
        {children}
      </Spotlight.ActionsGroup>
    </>
  );
  const renderProducts = (): React.ReactNode => {
    const products = items.filter(isProduct) as IProduct[];

    if (!products.length) return null;

    const components = products.map((product: IProduct) => (
      <ProductSearchItem key={`product-${product.id}`} product={product} handleSelect={handleSelect} />
    ));

    return [getDivider('Products', components)];
  };

  const renderAssets = (): React.ReactNode => {
    const assets = items.filter(isAsset) as IAsset[];

    if (!assets.length) return null;

    const components = assets.map((asset: IAsset) => (
      <AssetSearchItem key={`asset-${asset.id}`} asset={asset} handleSelect={handleSelect} />
    ));
    return [getDivider('Assets', components)];
  };

  const renderUsers = (): React.ReactNode => {
    const users = items.filter((item) => item['@type'] === 'user') as IContact[];

    if (!users.length) return null;

    const components = users.map((user: IContact) => (
      <UserSearchItem key={`user-${user.id}`} user={user} handleSelect={handleSelect} />
    ));

    return [getDivider('Contacts', components)];
  };

  const renderGroups = (): React.ReactNode => {
    const groups = items.filter((item) => item['@type']?.includes('group')) as IGroup[];

    if (!groups.length) return null;

    const components = groups.map((group: IGroup) => (
      <GroupSearchItem key={`group-${group.id}`} group={group} handleSelect={handleSelect} />
    ));

    return [getDivider('Groups', components)];
  };

  const renderOrgs = (): React.ReactNode => {
    const orgs = items.filter((item) => item['@type'] === 'organization') as IOrganization[];

    if (!orgs.length) return null;

    const components = orgs.map((organization: IOrganization) => (
      <OrganizationSearchItem
        key={`organization-${organization.id}`}
        organization={organization}
        handleSelect={handleSelect}
      />
    ));

    return [getDivider('Organizations', components)];
  };

  return { renderAssets, renderProducts, renderUsers, renderOrgs, renderGroups };
}
