import { ProducerGenericReport } from '@mediafellows/mm3-types';

import { IMobileAppSyncActionName } from 'utils/actions/mobile-app-sync';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';
import { IIdentifiable, IGroupVariety, IAsset } from 'types';
import { IMarketingEventActionName } from 'utils/actions/event';
import { ISelectionActionName } from 'utils/actions/selection/types';
import { IAccessPrivilegeActionName } from 'utils/actions/access-privilege/types';
import { IScreeningRoomActionName } from 'utils/actions/screening-room/types';
import { IProducerPortalActionName } from 'utils/actions/producer-portal/types';
import { IAssetActionName } from 'utils/actions/asset';
import { IAccessGroupActionName } from 'utils/actions/access-group';

export type ICommonActionName = 'title';

export enum IGroupActionName {
  ADD_ENTITIES = 'add-entities',
  DELETE = 'delete',
  RESTORE = 'restore',
  UPLOAD_PREVIEW = 'upload-preview',
}

export type ICommonActionConfig = IActionConfig<ICommonActionEntity, ICommonActionName>;

export type IGroupConfig = IActionConfig<IGroupVariety, IGroupActionName>;

interface ICommonActionEntity extends IIdentifiable {
  deleted_at?: string;
  status?: string;
}

export type IShouldDisplayGroupActionFunction = IShouldDisplayActionFunction<
  IGroupVariety | ProducerGenericReport | IAsset,
  | IGroupActionName
  | IAssetActionName
  | IMarketingEventActionName
  | ISelectionActionName
  | IAccessPrivilegeActionName
  | IMobileAppSyncActionName
  | IScreeningRoomActionName
  | IProducerPortalActionName
  | IAccessGroupActionName
>;

export type IShouldDisplayCommonActionFunction = IShouldDisplayActionFunction<ICommonActionEntity, ICommonActionName>;
