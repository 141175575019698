import React from 'react';
import get from 'lodash/get';

import { InfoBoxV2 } from 'components/info-box-v2';
import { FormImage } from 'helpers/form';
import type { IStyled } from 'types';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { Thumbnail } from 'components/thumbnail';
import { IFormData } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

export function CollectionImagesForm({
  className,
  formData,
  values,
  handlers,
  images,
}: IStyled & IUseMm3FormReturn<UmGenericListCollection> & { images: { name: string; label: string }[] }): JSX.Element {
  return (
    <InfoBoxV2 className="py-3 gap-2" gridTemplateColumns="0.5fr 1fr 1fr">
      {images?.map(({ name, label }) => (
        <React.Fragment key={name}>
          <Row
            className="py-2"
            label={label}
            value={
              <FormImage
                type="attachment"
                large
                name={name}
                label={label}
                {...(get(formData, name) as IFormData<UmGenericListCollection['meta']>)}
                onBlur={handlers.onBlur}
                onChange={handlers.onChange}
              />
            }
            size="m"
          />
          <Thumbnail image={get(values, name) || ''} className={className} />
        </React.Fragment>
      ))}
    </InfoBoxV2>
  );
}
