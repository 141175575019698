import { IOrganization } from 'types';
import { parseKeyPathToObject } from 'utils/payload';
import { IFormData } from 'helpers/form';
import { IOrganizationFields } from './types';

export enum StepIds {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
}

export const prepareOrganizationPayload = (fields: IOrganizationFields): IOrganization => {
  return parseKeyPathToObject<IOrganizationFields>(fields) as unknown as IOrganization;
};

/**
 * This is utility function to return keys of invalid fields, useful to show specific errors for form in steps
 */
export const getInvalidFields = <T>(formData: IFormData<T>, fields: (keyof T)[]): (keyof T)[] => {
  // TODO: should also check nested data like
  // if we have `addresses` key then it shouls check:
  // All keys with `addresses.*` name
  return fields.filter((key) => !formData[key].validation?.valid);
};
