import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import React, { ReactElement } from 'react';

interface ICustomDescriptionPreviewProps {
  activeItem: PmGenericCastCrew;
}
export const CustomDescriptionPreview: React.FC<ICustomDescriptionPreviewProps> = (): ReactElement => <></>;

export const customLabel = {
  description: 'Description',
};
