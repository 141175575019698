import React, { useCallback } from 'react';

import { IAsset, IMm3Asset } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { getValidIds, pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { shouldDisplayDelete as shouldDisplay } from './actions-acl';

import { deleteAssets } from './api';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { ToastError } from 'components/toast';

export const useDeleteAction = (items: (IAsset | IMm3Asset)[], options: IUseActionsOptionParam): IAssetActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entityWithCount } = pluralEntityInflect('Asset', items.length);

  const executeDelete = useCallback(async () => {
    try {
      await deleteAssets(getValidIds(items));
      toastStore.success(`${entityWithCount} were deleted`);
      await options?.onSuccess?.(IAssetActionName.DELETE);
      return true;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore, entityWithCount]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, executeDelete, entityWithDemonstrative]);

  return {
    name: IAssetActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
