import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';

import { IBasketDataSectionWrappedComponent } from './types';

import './style.scss';
import { getEntityLabel } from 'utils/general';
import startCase from 'lodash/startCase';

export const BasketDataSectionTilesItem: React.FC<IBasketDataSectionWrappedComponent> = ({
  basket,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const [itemChecked, setChecked] = useState(false);
  const { name, entityType, entityCount } = basket;

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="default-baskets-list-item-tile">
      <div className="default-baskets-list-item-tile__actions mb-2">
        <div>
          <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
        </div>
      </div>
      <div className="default-baskets-list-item__title">
        <h4 className="text-nowrap text-truncate">{name}</h4>
      </div>
      <div>
        {entityCount} {startCase(getEntityLabel(entityType))}
      </div>
    </div>
  );
};
