import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';
import React, { useMemo } from 'react';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';

import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';

import { RecommendationAssetsSortTab } from './recommendation-asset-sort-tab';
import { AssetsDataSection } from 'components/asset/assets-data-section';
import { customSortMenu } from 'components/marketing-entity-detail/group-detail-asset-data-section/custom-sort-menu';

import { Pages } from 'utils/actions';
import { customSortExecutor } from 'utils/apis/sort';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { IAsset, ISearchFilter } from 'types';

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const executor = (options: IActionOpts): Promise<IResult<IAsset>> => customSortExecutor<IAsset>(options, Model.ASSETS);

type IRecommendationAssetsProps = {
  recommendation: McGenericRecommendationAsset;
};

export const RecommendationAssetsPreviewTab: React.FC<IRecommendationAssetsProps> = ({ recommendation }) => {
  const { asset_ids } = recommendation;
  const persistentFilters = useMemo<ISearchFilter[]>(
    () => [['id', 'in', asset_ids?.length ? asset_ids : []], ...getRecommendationAssetsFilters()],
    [asset_ids],
  );

  return (
    <AssetsDataSection
      persistentFilters={persistentFilters}
      page={Pages.RECOMMENDATIONS_DETAILS}
      executor={executor}
      defaultParams={defaultParams}
      customSortMenu={customSortMenu}
      customSubmitFilter={submitFiltersWithSeqNumber}
    />
  );
};

interface IRecommendationAssetsTabProps {
  setRecommendation: React.Dispatch<React.SetStateAction<McGenericRecommendationAsset>>;
  recommendation: McGenericRecommendationAsset;
  isSortModeEnabled: boolean;
}

export const RecommendationAssetsTab: React.FC<IRecommendationAssetsTabProps> = ({
  recommendation,
  isSortModeEnabled,
  setRecommendation,
}) => {
  if (isSortModeEnabled) {
    return <RecommendationAssetsSortTab recommendation={recommendation} setRecommendation={setRecommendation} />;
  }
  return <RecommendationAssetsPreviewTab recommendation={recommendation} />;
};
