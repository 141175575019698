import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RenderNodePayload } from '@mantine/core/lib/components/Tree/Tree';

import { useDataSectionStore } from 'store/hooks';

import { GenrePreviewTab } from './genre-data-section-preview';
import GenreDataSectionItems from './genre-data-section-items-list';
import { GenreDataSectionItem } from 'components/genres/genres-data-section-item/genres-data-section-item';

import { categoryListSchema } from 'utils/schemas';
import useControlDataSection from 'utils/hooks/control-data-section';
import { IUseActionsContext, IUseActionsOptionParam, Pages } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';

import { Model } from 'helpers/filters/types';
import { ICategory, IGenreTreeItem } from 'types';
import { IActionName } from 'utils/actions/types';
import { IGenreActionName } from 'utils/actions/genre/types';
import { getGenreTree } from './utils';

import './style.scss';

const GenresDataSection: React.FC = observer(() => {
  const refresh = useRefreshDataSection();

  const params = useMemo(
    () => ({
      model: Model.GENRES,
      schema: categoryListSchema,
      actionName: 'query',
      stats: '',
      defaultFilters: {},
    }),
    [],
  );

  useControlDataSection(params);

  const { updateStore, searchStore, active } = useDataSectionStore<ICategory>();
  const { objects: storeObjects = [], running = true } = searchStore || {};

  useEffect(() => {
    if (active || running) return;
    const firstItem = storeObjects?.[0];
    firstItem && updateStore({ active: firstItem.id });
  }, [active, running, storeObjects, updateStore]);

  const handleItemSelect = useCallback(
    async ({ currentTarget }) => {
      const selectedId = currentTarget.dataset.id;
      updateStore({ active: parseInt(selectedId) });
    },
    [updateStore],
  );
  const [isSortModeEnabled, setIsSortModeEnabled] = useState(false);

  const onSuccess = useCallback(
    async (action?: IActionName, updatedGenre?: ICategory | ICategory[]): Promise<void> => {
      if (!searchStore) {
        return;
      }
      switch (action) {
        case IGenreActionName.EDIT:
          const newGenre = updatedGenre as ICategory;
          const updated = storeObjects.map((object) => {
            if (object.id === newGenre?.id) {
              const previewImage =
                newGenre.preview_image_id === object?.preview_image?.id
                  ? object.preview_image
                  : newGenre?.preview_image;

              return { ...object, ...updatedGenre, preview_image: previewImage };
            }
            return object;
          });
          searchStore?.update({ objects: updated });
          break;
        case IGenreActionName.DELETE:
          const updatedObjects = storeObjects.filter((object) => object.id !== (updatedGenre?.[0] as ICategory)?.id);
          searchStore?.update({ objects: updatedObjects });
          break;
        default:
          refresh();
          break;
      }
    },
    [refresh, searchStore, storeObjects],
  );

  const handleItemRenderer = useCallback(
    ({ node, expanded, hasChildren, elementProps }: RenderNodePayload): React.ReactElement => {
      const options: IUseActionsOptionParam<ICategory> = {
        context: 'single' as IUseActionsContext,
        page: Pages.DETAILS,
        onSuccess,
        parentId: (node as unknown as ICategory).id,
      };
      return (
        <GenreDataSectionItem
          key={node.value}
          genre={node as unknown as IGenreTreeItem}
          handleClick={handleItemSelect}
          options={options}
          expanded={expanded}
          hasChildren={hasChildren}
          elementProps={elementProps}
        />
      );
    },
    [handleItemSelect, onSuccess],
  );
  const genreTree = useMemo(() => getGenreTree(storeObjects), [storeObjects]);

  return (
    <div className="data-section genre-data-section__container">
      <GenreDataSectionItems
        title="Genres"
        className="genre__data-section"
        itemRenderer={handleItemRenderer}
        isSortModeEnabled={isSortModeEnabled}
        setIsSortModeEnabled={setIsSortModeEnabled}
        genreTree={genreTree}
      />
      <GenrePreviewTab isSortModeEnabled={isSortModeEnabled} onSuccess={onSuccess} genreTree={genreTree} />
    </div>
  );
});

export default GenresDataSection;
