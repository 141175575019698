import { IAsset, IAssetPresetFields } from 'types';
import { parseObjectToKeyPath } from 'utils/payload';
import { IAssetFormFields } from './sections';

export const getAssetFormFields = (fields: IAsset | IAssetFormFields | IAssetPresetFields): IAssetFormFields =>
  parseObjectToKeyPath(fields, [
    'permissions',
    'protection_levels',
    'tags',
    'language_ids',
    'geography_ids',
  ]) as IAssetFormFields;
