import React, { useCallback, useMemo } from 'react';

import { IFilterOption } from 'utils/hooks/control-data-section';
import { IMarketingEventActionName, Pages } from 'utils/actions';
import { IMarketingEvent } from 'types';
import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';
import { EventDataSection } from 'components/event-data-section';

import { customSortMenu, defaultParams } from './utils';
import { useRefreshDataSection } from 'utils/hooks';
import { IActionName } from 'utils/actions/types';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { customSortExecutor } from 'utils/apis/sort';
import { Model } from 'helpers/filters/types';

const executor = (options: IActionOpts): Promise<IResult<IMarketingEvent>> =>
  customSortExecutor<IMarketingEvent>(options, Model.EVENTS);

export const EventSubEventsPreviewTab: React.FC<{
  event: IMarketingEvent;
  setEvent: React.Dispatch<React.SetStateAction<IMarketingEvent>>;
}> = ({ event, setEvent }) => {
  const persistentFilters: IFilterOption[] = useMemo(
    () => [
      ['id', 'in', event.subevent_group_ids || []],
      ['subevent', 'eq', true],
    ],
    [event.subevent_group_ids],
  );

  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action?: IActionName, event?: IMarketingEvent): Promise<void> => {
      if (action === IMarketingEventActionName.REMOVE_FROM_GROUP) {
        setEvent((e) => ({ ...e, ...(event || {}) }));
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection, setEvent],
  );

  const actionOptions = useMemo(() => ({ onSuccess }), [onSuccess]);

  if (!event?.id) {
    return <></>;
  }

  return (
    <EventDataSection
      executor={executor}
      persistentFilters={persistentFilters}
      page={Pages.EVENT_SUB_EVENTS_TAB}
      defaultParams={defaultParams}
      customSortMenu={customSortMenu}
      actionOptions={actionOptions}
      customSubmitFilter={submitFiltersWithSeqNumber}
    />
  );
};

export default EventSubEventsPreviewTab;
