import { IContact, IGroup, IItem } from 'types';
import { ItemTypes, parseItem } from 'helpers/form/fields/select-helpers';

const deletedStatuses = ['deleted', 'deleting'];

export const isEntityDeleted = (entity: IItem): boolean => {
  const { itemType } = parseItem(entity);

  if (!entity) return false;

  switch (itemType) {
    case ItemTypes.USER:
    case ItemTypes.ORGANIZATION:
    case ItemTypes.ASSET:
    case ItemTypes.PRODUCT:
      return deletedStatuses.includes((entity as IContact)?.status);
    default:
      return Boolean((entity as IGroup)?.deleted_at);
  }
};

export const areAllNotDeleted = (entities: IItem[]): boolean => entities.every((entity) => !isEntityDeleted(entity));
export const areAllDeleted = (entities: IItem[]): boolean => entities.every((entity) => isEntityDeleted(entity));
