import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { DetailsPageTabs, IConference } from 'types';
import { Routes } from 'utils/routes';
import { useBasicStore } from 'store/hooks';
import { parseConferenceUrl } from 'utils/conference';
import { ClipboardView } from 'components/clipboard-view';
import { Thumbnail } from 'components/thumbnail';
import DateElement from 'components/date-list-element/date-list-element';
import { IconAlias } from 'icons';

import { formatFullName } from 'utils/general';
import { ListItemCountLink } from 'utils/ui';
import { Classes } from 'utils/ui';
import './style.scss';

interface IConferenceDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  conference: IConference;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick: (e) => void;
}

export const ConferenceDataSectionListItem: React.FC<IConferenceDataSectionListItemProps> = observer(
  ({ conference, contextMenu, handleClick, isChecked, isCheckedDisabled }) => {
    const {
      title,
      owner,
      background_media,
      mode,
      status,
      starts_at,
      ends_at,
      asset_ids,
      user_ids,
      code,
      created_at,
      updated_at,
    } = conference;
    const { buyerDomain } = useBasicStore();
    const conferenceUrl = parseConferenceUrl(buyerDomain, code);
    const detailsPageLink = `${Routes.CONFERENCES}/${code}`;
    const [itemChecked, setItemChecked] = useState(isChecked);

    useEffect(() => {
      setItemChecked(isChecked);
    }, [isChecked]);

    return (
      <div className="entity-list-item-row">
        <div className="entity-list-item-row__checkbox">
          <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="entity-list-item-row__thumb">
          <Thumbnail customTag={mode} showType image={background_media} fallbackIconName={IconAlias.CONFERENCE} />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={title}>
            <Link to={detailsPageLink}>
              <h4 className="text-nowrap text-truncate">{title}</h4>
            </Link>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className={cx('conference-list-item-row__attributes', Classes.TEXT_MUTED)}>
            <ClipboardView
              value={conferenceUrl}
              textClassName="text-truncate"
              className="conference-list-item-row__code"
              showEmptyContainer
            >
              <a href={conferenceUrl} target="_blank" rel="noreferrer noopener" onClick={(e) => e.stopPropagation()}>
                {code}
              </a>
            </ClipboardView>
            <div className="text-truncate conference-list-item-row__start">
              <DateElement date={starts_at} mfxIcon="starts-at" />
            </div>
            <div className="text-truncate conference-list-item-row__end">
              <DateElement date={ends_at} mfxIcon="expires-at" customHoverLabel="Ends At" />
            </div>
            <div className="text-truncate conference-list-item-row__status">{status}</div>
            <div className="text-truncate conference-list-item-row__waitingroom">
              Waiting room {`${conference?.disable_waitingroom ? 'disabled' : 'enabled'}`}
            </div>
            <div className="text-truncate conference-list-item-row__assets">
              <ListItemCountLink
                entityCount={asset_ids.length}
                link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
                linkText="Asset"
              />
            </div>
            <div className="text-truncate conference-list-item-row__users">
              <ListItemCountLink
                entityCount={user_ids.length || 0}
                link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
                linkText="Contacts"
              />
            </div>
            <div className="text-truncate conference-list-item-row__owner">{formatFullName(owner, false)}</div>
            <div className="text-truncate conference-list-item-row__created">
              <DateElement date={created_at} mfxIcon="created-at" />
            </div>
            <div className="text-truncate conference-list-item-row__updated">
              <DateElement date={updated_at} mfxIcon="updated-at" />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
