import React, { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { basketSchema } from 'utils/schemas/basket';
import { Pages } from 'utils/actions/types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { IBasket } from 'types';
import { Model } from 'helpers/filters/types';

import { BasketActionsList } from 'components/action';
import { DataSection } from 'components/data-section';
import { BasketsPreviewTab } from 'components/baskets/basket-preview-tab';
import { BasketsListItem } from 'components/baskets/default-basket-list-item';
import { BasketDataSectionItem } from 'components/baskets/basket-data-section-item/basket-data-section-item';
import { defaultBasketFilters } from 'components/baskets/basket-filters';
import mapList from './map-list';

import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const page = Pages.CONTACT_BASKETS;

const sidebarTabs = {
  preview: <BasketsPreviewTab basketMapper={mapList} />,
};

const productsDataFilters: IFilterOption[] = [];

const ContactBasketsTab: React.FC = observer(() => {
  const { contactId } = useParams<{ contactId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const controlDataSectionProps = useMemo(
    () => ({
      schema: basketSchema,
      model: Model.BASKETS,
      actionName: 'get',
      defaultFilters: defaultBasketFilters,
      filters: productsDataFilters,
      params: { owner_id: contactId },
    }),
    [contactId],
  );

  useControlDataSection(controlDataSectionProps);

  const onSuccess = useCallback(async (): Promise<void> => {
    navigate({ pathname: location.pathname, search: location.search });
  }, [navigate, location]);

  const handleItemRenderer = useCallback(
    (basketData: IBasket): JSX.Element => {
      const list = mapList(basketData);

      return (
        <BasketsListItem
          key={list.id}
          basket={list}
          contextMenu={
            <ActionsMenu
              component={BasketActionsList}
              items={[list]}
              options={{ context: 'single', onSuccess, page }}
            />
          }
          component={BasketDataSectionItem}
        />
      );
    },
    [onSuccess],
  );

  return (
    <div className="contact-lists-tab h-100">
      <DataSection
        itemRenderer={handleItemRenderer}
        tabs={sidebarTabs}
        showListTileViewToggleButton={false}
        hideSearchButton
      />
    </div>
  );
});

export default ContactBasketsTab;
