import React from 'react';
import { ItemId } from 'types';
import { useContactsForSelectionPreview } from 'utils/apis/contacts';

import { MemoizedMultiAvatar } from './multi-component';

export const UserSelectionAvatar: React.FC<{
  selection: { id?: ItemId };
  height?: number;
  width?: number;
  offsetBetweenElements?: number;
}> = ({ selection, height, width, offsetBetweenElements }) => {
  const [contacts] = useContactsForSelectionPreview({ selection });

  return (
    <MemoizedMultiAvatar
      contacts={contacts}
      height={height}
      width={width}
      offsetBetweenElements={offsetBetweenElements}
    />
  );
};
