import React from 'react';

import { IAsset, IMm3Asset } from 'types';
import { ThumbnailFileStatus } from 'components/file-status';

import { Thumbnail } from './thumbnail';
import { IImgResizerSizes } from 'utils/img-resizer';

interface IThumbnailEntityProps {
  entity?: Pick<IAsset | IMm3Asset, 'preview_image' | 'file_status'>;
  size?: IImgResizerSizes;
}

const statusWeight = {
  ingesting: 0,
  ready: 1,
};

const getWorstStatus = (statuses: (string | undefined | null)[]): string | undefined | null => {
  const sorted = statuses.sort((a, b) => {
    const aValue = statusWeight[a || ''] || 10;
    const bValue = statusWeight[b || ''] || 10;

    return bValue - aValue;
  });

  return sorted[0];
};

export const ThumbnailEntity: React.FC<IThumbnailEntityProps> = ({ entity, size }) => {
  const { preview_image, file_status } = entity || {};
  const worstStatus = getWorstStatus([file_status, preview_image?.status]);

  const placeholder = <ThumbnailFileStatus size={35} status={worstStatus} withBackground />;

  if (worstStatus === 'ingesting') {
    return <Thumbnail image={undefined} placeholder={placeholder} />;
  }

  return <Thumbnail size={size} image={preview_image?.url} placeholder={placeholder} />;
};
