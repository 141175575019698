import { useCallback } from 'react';

import { ItemId } from 'types';
import { useRemote } from 'utils/hooks';
import { loadOrganizationCountInGroup } from 'utils/apis/groups';

export const useOrganizationCountInGroup = (group_id?: ItemId): [number, () => Promise<void>] => {
  const loadOrganizationCount = useCallback(() => loadOrganizationCountInGroup(group_id), [group_id]);

  const [organization_count = 0, , , refresh] = useRemote(loadOrganizationCount, 0);

  return [organization_count, refresh];
};
