import React, { useCallback } from 'react';
import { FormInput, FormSelect, useForm } from 'helpers/form';

import { FormSubmitSection } from 'components/form-submit-section';
import { ISelectionEntityType } from 'utils/actions/types';
import entityTypeToForm from './entity-forms';
import { IItemId, IFormSelectionItemsForm } from './types';
import { makeInitialFormValues } from './utils';
import { Model } from 'helpers/filters/types';

import './style.scss';

export interface IFormSelectionItems {
  entityType: ISelectionEntityType;
  itemIds: IItemId[];
  onConfirm: (values: IFormSelectionItemsForm) => Promise<void>;
}

export const FormSelectionItems: React.FC<IFormSelectionItems> = ({ itemIds, entityType, onConfirm }) => {
  const { formData, handlers, valid, values } = useForm<IFormSelectionItemsForm>(
    makeInitialFormValues(itemIds),
    Model.GROUPS,
  );

  const EntityForm = entityTypeToForm[entityType];

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await onConfirm?.(values);
    },
    [onConfirm, values],
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormInput label="Name" name="name" {...formData.name} {...handlers} />
      <FormSelect name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />

      <hr className="dark mb-3 mt-3 form-selection__separator" />
      <EntityForm formData={formData} handlers={handlers} />
      <FormSubmitSection submitDisabled={!valid} />
    </form>
  );
};
