import React from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from '@mantine/core';

import { Footer } from 'components/footer';
import { useNavigate } from 'react-router';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

const PrivacyPolicy: React.FC<{}> = observer(() => {
  const navigate = useNavigate();
  const handleBackClick = (e): void => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="privacy">
      <div className="privacy__head pb-5 d-flex justify-content-center">
        <Title order={3}>Privacy Policy</Title>
      </div>
      <div className="privacy__line" />
      <div className="privacy__content pt-4 pb-4">
        <a href="#" className="privacy__back" onClick={handleBackClick}>
          <MantineIcon className="me-1" icon="arrow-left" />
          Back
        </a>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            General
          </Title>
          <div className="running-text">
            We at mediafellows recognize that privacy is very important. The protection of personal data is a priority
            for us. All personal data is handled in accordance with the General Data Protection Regulation (GDPR) of the
            European Union. In general, mediafellows needs personal data for the operation of MediaStore and to provide
            safe, smooth, efficient and customized services. By you registering to our website you consent to the
            collection, storage, use and disclosure of your personal data as described in this Privacy Policy.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Collection of Data
          </Title>
          <div className="running-text">
            As a rule, you may visit the mediafellows website without providing personal data. <br /> Once you decide to
            use MediaStore and register, you provide us with personal data, including your name, job title, e-mail
            address, physical contact information, bank account numbers, and other information that may be requested by
            mediafellows. <br />
            To the extent that you use MediaStore, mediafellows needs to gather data about your actions on the platform.
            Such information is required in order to assess whether a consideration is due, for calculating the
            consideration and for billing purposes. <br />
            In addition, your computer automatically sends information such as the IP address, browser version and
            access time to mediafellows. This information is stored, partly in an anonymous form, and helps us to better
            understand the needs of the buyers and sellers. Furthermore, this information is used to enforce our
            contractual rights and prevent fraud.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Use of Personal Data
          </Title>
          <div className="running-text">
            mediafellows uses personal data to allow you to safely and effectively use MediaStore. The use of your
            personal information allows you to
            <ul className="m-2">
              <li>
                Conclude contracts with your contract partner, to receive the contact details of your contract partner
                and to provide your contract partner with your contact details;
              </li>
              <li>
                Obtain information about your past activities on MediaStore, and to receive interesting offers on our
                platform;
              </li>
              <li>
                Enforce the contractual obligations of mediafellows and all participants; in the event that a registered
                Buyer or Seller abuses MediaStore, we use the personal information to protect the Users from further
                harm and provide them to third persons only as described in section IV;
              </li>
              <li>Balance accounts for billing purposes.</li>
            </ul>
            We additionally use your personal data to provide you with information requested by you.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Transfer of Personal Data to Third Parties
          </Title>
          <div className="running-text">
            Under certain circumstances, mediafellows is legally obligated to forward your personal data to third
            parties.
            <br />
            Upon request and to the degree mediafellows is legally obligated to do so, personal data will be forwarded
            to law enforcement agencies. Also, if mediafellows has well founded and documented indications for the abuse
            of MediaStore or for breaches of duties, personal data will be forwarded to aggrieved parties or for the
            general enforcement of claims. This also applies after the user agreement has been terminated.
            <br /> Only subject to your explicit consent mediafellows may furthermore forward your personal data to
            other third parties.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Transfer of Personal Data to Participants of MediaStore
          </Title>
          <div className="running-text">
            mediafellows might provide you with contact details of other participants, such as e-mail address and
            telephone numbers. By agreeing to the privacy policy, you agree to use the personal data of other
            participants provided to you solely for the following purposes:
            <ul className="m-2">
              <li>
                Any MediaStore related communication necessary with other participants excluding any unsolicited
                advertising;
              </li>
              <li>Subject to section IV for making complaints in the event of fraud;</li>
              <li>Purposes to which the other participant has expressly consented.</li>
            </ul>
            You may not use an e-mail address communicated to you via MediaStore to send unsolicited e-mails (SPAM).
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Cookies
          </Title>
          <div className="running-text">
            mediafellows uses cookies to make our service more user friendly, more effective and safer. Cookies are
            information units stored on your computer and used by your browser. Most cookies are session cookies which
            are automatically deleted from your hard disk at the end of your visit to MediaStore. Other cookies remain
            on your computer and enable mediafellows to recognize your computer on your next visit.
            <br />
            mediafellows uses cookies to accelerate the navigation on the website, adapt MediaStore to your needs and
            interests and to prevent fraud. As an example, the use of cookies avoids the repetitive entry of your
            password during one session.
            <br />
            You may adjust the settings of your browser in such a way that your browser informs you before accepting a
            cookie or rejects the acceptance of cookies. Please note, however, that the use of MediaStore may be
            negatively affected upon the rejection of cookies. Some functions require the acceptance of cookies.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Deletion of Personal Data
          </Title>
          <div className="running-text">
            Personal data is deleted upon the expiration of the legal, contractual or statutory record retention
            periods. Data not covered by these rules will be deleted as soon as it is no longer needed for the purposes
            set forth above.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Transmission of Data Outside the EU
          </Title>
          <div className="running-text">
            You agree that personal data is transferred outside the EU in accordance with the rules of this Privacy
            Policy to the extent that you or other participants are located outside of the EU.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Request of Information
          </Title>
          <div className="running-text">
            At any time you have the right to request from mediafellows information as to your personal data stored by
            mediafellows, their origin, recipients and categories of recipients and the purpose of storage.
          </div>
        </section>
        <section className="mt-4 mb-4">
          <Title className="mt-4 mb-2" order={4}>
            Cancellation of the Consent
          </Title>
          <div className="running-text">
            You can cancel your consent with the terms of this Privacy Policy at any time with effect for the future in
            writing to mediafellows GmbH (Torellstr. 1, 10243 Berlin, Germany) or per e-mail message to
            <a href="mailto:support@mediafellows.com"> support@mediafellows.com</a>. However, in such case mediafellows
            is entitled to terminate the agreement between mediafellows and you or your company with immediate effect.
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
});

export default PrivacyPolicy;
