import React from 'react';
import { Menu } from '@mantine/core';

import { ParametronSort } from 'helpers/filters/types';
import { ICustomSortMenuProp } from 'components/data-section';

import cx from 'classnames';

export const getSortMenu: ICustomSortMenuProp = (sort, handleSort) => (
  <Menu>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.FLAT_SEQUENCE_NUMBER}
      className={cx({ active: sort === ParametronSort.FLAT_SEQUENCE_NUMBER })}
    >
      Display Order
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.CREATED_AT}
      className={cx({ active: sort === ParametronSort.CREATED_AT })}
    >
      Date Created
    </Menu.Item>

    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.UPDATED_AT}
      className={cx({ active: sort === ParametronSort.UPDATED_AT })}
    >
      Date Updated
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.DISPLAY_TITLE}
      className={cx({ active: sort === ParametronSort.DISPLAY_TITLE })}
    >
      Title
    </Menu.Item>
    <Menu.Item
      onClick={handleSort}
      data-param="sort"
      data-value={ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION}
      className={cx({ active: sort === ParametronSort.PRODUCTS_YEAR_OF_PRODUCTION })}
    >
      Year of Production
    </Menu.Item>
  </Menu>
);
