import React, { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';

import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { updateProductLayer } from 'utils/apis/product';
import { Intent } from 'utils/ui';
import { IExtendedProductCastCrew } from 'types/product';
import { ToastError } from 'components/toast';
import { shouldDisplayOnProductPageSingleItem } from './acl';

export const useRemoveFromProductAction = (
  items: PmGenericCastCrew[],
  options: IUseActionsOptionParam<PmGenericCastCrew>,
): ICastCrewActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entityWithCount } = pluralEntityInflect('Member', items.length);

  const onConfirm = useCallback(async () => {
    try {
      if (!options?.product?.default_layer?.meta?.cast_crew?.length) {
        return;
      }
      const {
        id,
        default_layer_id,
        default_layer: { meta },
      } = options.product;
      const indexesToRemove = (items as IExtendedProductCastCrew[]).map((e) => e.index);
      const layer = await updateProductLayer({
        product_id: id,
        id: default_layer_id,
        meta: {
          ...meta,
          cast_crew: meta.cast_crew?.filter((e, index) => !indexesToRemove.includes(index)),
        },
      });
      toastStore.success(`${entityWithCount} were Removed from this Product`);
      await options?.onSuccess?.(ICastCrewActionName.REMOVE_FROM_PRODUCTS, items[0], layer);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  }, [items, toastStore, entityWithCount, options]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove from Product',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from this Product?`,
      onConfirm,
    });
  }, [dialogStore, onConfirm, entityWithDemonstrative]);

  return {
    name: ICastCrewActionName.REMOVE_FROM_PRODUCTS,
    shouldDisplay: shouldDisplayOnProductPageSingleItem,
    icon: 'remove',
    title: 'Remove from Product',
    intent: Intent.DANGER,
    handler,
  };
};
