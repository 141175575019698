import { IAsset, IMm3Asset } from 'types';

import { useScreeningRoomDialog } from 'components/screening-room/screening-room-wizard/hooks';
import { IconAlias } from 'icons/icon-aliases';
import { useStore } from 'store';
import { shouldDisplayMultiNotDeletedMainAssets } from './actions-acl';

import { IAssetActionName, IAssetActionConfig } from './types';

export const useCreateScreeningRoomAction = (assets: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const { toastStore } = useStore();
  const [openDialog] = useScreeningRoomDialog({
    assets,
    onFinish() {
      toastStore.success('Screening Room was created successfully!');
    },
  });

  return {
    name: IAssetActionName.CREATE_SCREENING_ROOM,
    icon: IconAlias.SCREENING_ROOM,
    title: 'Create Screening Room',
    shouldDisplay: shouldDisplayMultiNotDeletedMainAssets,
    handler: openDialog,
  };
};
