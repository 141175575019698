import React, { useCallback, SetStateAction, Dispatch, useMemo } from 'react';

import { IConference } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';

import { IFilterOption, useRefreshDataSection } from 'utils/hooks';
import { IContactActionName } from 'utils/actions/contact';
import { ContactDataSection } from 'components/contact-data-section';

interface IConferenceContactsPreviewTabProps {
  conference: IConference;
  setConference: Dispatch<SetStateAction<IConference>>;
}

const ConferenceContactsPreviewTab: React.FC<IConferenceContactsPreviewTabProps> = ({ conference, setConference }) => {
  const persistentFilters = useMemo(
    () => [['id', 'in', conference.user_ids]] as IFilterOption[],
    [conference.user_ids],
  );
  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action: IContactActionName, conference: IConference) => {
      switch (action) {
        case IContactActionName.REMOVE_FROM_CONFERENCE:
          return setConference(conference);

        default:
          return refreshDataSection();
      }
    },
    [refreshDataSection, setConference],
  );
  const options = useMemo<Partial<IUseActionsOptionParam<IConference>>>(
    () => ({
      onSuccess,
    }),
    [onSuccess],
  );

  return <ContactDataSection persistentFilters={persistentFilters} actionsOptions={options} page={Pages.CONFERENCE} />;
};

export default ConferenceContactsPreviewTab;
