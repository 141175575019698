import React from 'react';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { DataSectionRefreshButton } from 'components/data-section-refresh-button';
import { WebsiteDataSection } from 'components/website-data-section';
import { IWebsitePageValues } from 'types';

import './style.scss';

const Website: React.FC<{ section: IWebsitePageValues; title: string }> = ({ section, title }) => {
  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="d-inline-block mb-3" />
        <DataSectionRefreshButton />
      </div>
      <WebsiteDataSection section={section} title={title} />
    </div>
  );
};

export default Website;
