import React from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { IFormHandlers } from 'helpers/form';
import { IFormData } from 'helpers/form/mm3';

export interface ICreateCastCrewFormData extends PmGenericCastCrew {
  imageUrl?: string;
}

interface ICustomProps {
  formData: IFormData<ICreateCastCrewFormData>;
  handlers: Omit<IFormHandlers<ICreateCastCrewFormData>, 'onSetFields'>;
}

export const CustomInput: React.FC<Partial<ICustomProps>> = () => <></>;

export const customLabels = {
  description: 'Description',
};
