import React, { useCallback } from 'react';

import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { deleteMeeting } from 'utils/apis/meeting';
import { Intent } from 'utils/ui';
import { IMeetingActionName } from './types';
import { ToastError } from 'components/toast';
import { CalGenericMeetingRegular } from '@mediafellows/mm3-types';

export const useDeleteAction: IUseAction<CalGenericMeetingRegular, IMeetingActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const calendar = options?.calendar?.current?.getApi();
      if (!items?.[0]?.id || !calendar) {
        return;
      }
      const { id } = items[0];

      await deleteMeeting(id);
      const event = calendar.getEventById(id.toString());
      event?.remove();

      toastStore.success('Meeting has been deleted!');
      calendar.refetchEvents();
      dialogStore.close();
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    }
  }, [items, options?.calendar, toastStore, dialogStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: 'Are you sure you want to delete this Meeting?',
      onConfirm,
    });
  }, [dialogStore, onConfirm]);

  return {
    name: IMeetingActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
