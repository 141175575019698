import React, { SetStateAction, Dispatch } from 'react';

import { IAsset, IConference } from 'types';
import ConferenceAssetsSortTab from './conference-assets-sort-tab';
import ConferenceAssetsPreviewTab from './conference-assets-tab-preview';

interface IConferenceAssetsTabProps {
  isSortModeEnabled: boolean;
  conference: IConference;
  assets: IAsset[];
  setConference: Dispatch<SetStateAction<IConference>>;
}

const ConferenceAssetsTab: React.FC<IConferenceAssetsTabProps> = ({
  isSortModeEnabled,
  conference,
  setConference,
  assets,
}) => {
  if (isSortModeEnabled) {
    return <ConferenceAssetsSortTab conference={conference} setConference={setConference} assets={assets} />;
  }

  return <ConferenceAssetsPreviewTab conference={conference} setConference={setConference} />;
};

export default ConferenceAssetsTab;
