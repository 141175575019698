import { IMarketingEvent } from 'types';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions';

import { IMarketingEventActionName } from './types';
import { useEventShowcase } from 'components/showcase';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';
const allowedItemTypes: ['product', 'asset'] = ['product', 'asset'];

export const useManageSliderShowcase = (
  entities: IMarketingEvent[],
  options: IUseActionsOptionParam,
): IActionConfig<IMarketingEvent, IMarketingEventActionName> => {
  const additionalFilters = allowedItemTypes.reduce(
    (acc, key) => ({
      ...acc,
      [key]: [['group_ids', 'eq', entities.map((e) => e.id)]],
    }),
    {},
  );
  const onClick = useEventShowcase(
    entities,
    options,
    `event_${entities[0].id}_slider`,
    allowedItemTypes,
    additionalFilters,
  );

  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: IMarketingEventActionName.MANAGE_SLIDER,
    shouldDisplay: shouldDisplaySingleNotDeleted,
    icon: 'layout-skew-grid',
    title: 'Manage Showcase',
    handler,
    isDisabled: disabled,
  };
};
