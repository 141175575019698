import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';
import { Add, Plus } from 'blueprint5-icons';

import { DataSectionControls } from 'components/data-section-controls';
import { useUploadAssetDialog } from 'components/upload-asset-product-dialog';
import { SortButton } from 'components/sort-buttons';
import { CreateNewProductButton } from 'components/create-new-product-dialog';

import { useAssignAssetsToProductAction } from 'utils/actions/product/assign-asset-to-product-action';
import { useAddCastCrewToProductsAction } from 'utils/actions/product/add-cast-crew';
import { useAssociateAction } from 'utils/actions/product/associate';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { IActionName } from 'utils/actions/types';
import { DetailsPageTabs, IProduct, IProductLayer } from 'types';

import { showDataSectionToggleButton, isDirectDescendantsTab, sortableTabs, childrenTabs } from './utils';
import ProductDescendantsParentSelector from './product-descendants-selector';
import { useRefreshDataSection } from 'utils/hooks';
import { tabToType } from 'utils/product';
import { formatType } from 'utils/format-type';

export const TabButtons: React.FC<{
  isSortModeEnabled: boolean;
  setIsSortModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: number;
  setParentId: React.Dispatch<React.SetStateAction<number>>;
  activeTab: DetailsPageTabs;
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  product: IProduct;
}> = ({ isSortModeEnabled, setIsSortModeEnabled, parentId, setParentId, activeTab, setProduct, product }) => {
  const { productId } = useParams();
  const refreshDataSection = useRefreshDataSection();
  const options: IUseActionsOptionParam = {
    onSuccess: refreshDataSection,
    context: 'single',
    page: Pages.DETAILS,
    productId,
  };
  const showParentProductSelector = useMemo(() => {
    return isSortModeEnabled && childrenTabs.includes(activeTab) && !isDirectDescendantsTab(product?.type, activeTab);
  }, [activeTab, isSortModeEnabled, product?.type]);

  const handleSuccess = useCallback(
    async (_: IActionName, product: IProduct): Promise<void> => {
      setProduct(product);
    },
    [setProduct],
  );
  const items = product ? [product] : [];

  const addRelated = useAssociateAction(items, { ...options, onSuccess: handleSuccess });
  const addCastCrew = useAddCastCrewToProductsAction(product ? [product] : [], {
    ...options,
    onSuccess: (action, product, layer: IProductLayer) => {
      setProduct((p) => ({ ...p, default_layer: layer }));
    },
    product,
  });
  const [openDialog] = useUploadAssetDialog({
    ...options,
    onSuccess: () => {
      setTimeout(refreshDataSection, 1000);
    },
  });
  const addAssets = useAssignAssetsToProductAction(items, options);
  const showSortButton = useMemo(() => sortableTabs.includes(activeTab), [activeTab]);

  return (
    <>
      <ProductDescendantsParentSelector
        key="select-parent-product"
        value={parentId}
        setParent={setParentId}
        activeTab={activeTab}
        hidden={!showParentProductSelector}
      />
      <SortButton
        isSortModeEnabled={isSortModeEnabled}
        setIsSortModeEnabled={setIsSortModeEnabled}
        hidden={!showSortButton}
        key="sort-btn"
      />
      <Button
        key="assign-assets-btn"
        onClick={addAssets.handler}
        disabled={isSortModeEnabled}
        variant="primary"
        size="xs"
        hidden={activeTab !== DetailsPageTabs.ASSETS}
        leftSection={<MantineIcon icon={<Add />} />}
      >
        Assign
      </Button>
      <Button
        key="upload-assets-btn"
        onClick={openDialog}
        disabled={isSortModeEnabled}
        variant={'primary'}
        size="xs"
        hidden={activeTab !== DetailsPageTabs.ASSETS}
        leftSection={<MantineIcon icon={<Plus />} />}
      >
        Upload
      </Button>
      <Button
        key="add-related-btn"
        onClick={addRelated.handler}
        disabled={isSortModeEnabled}
        variant={'primary'}
        size="xs"
        hidden={activeTab !== DetailsPageTabs.RELATED}
        leftSection={<MantineIcon icon={<Add />} />}
      >
        Add Related
      </Button>
      <CreateNewProductButton
        key="create-new-product-btn"
        title={formatType(tabToType[activeTab])}
        productType={tabToType[activeTab]}
        parent={product}
        hidden={!childrenTabs.includes(activeTab)}
      />
      <Button
        key="add-cast-crew-btn"
        onClick={addCastCrew.handler}
        disabled={isSortModeEnabled}
        variant="primary"
        size="xs"
        hidden={activeTab !== DetailsPageTabs.CAST_CREW}
        leftSection={<MantineIcon icon={<Add />} />}
      >
        Add Cast & Crew
      </Button>
      <DataSectionControls
        key="data-section-toggle-btn"
        disabled={isSortModeEnabled}
        hidden={!showDataSectionToggleButton(activeTab)}
      />
    </>
  );
};
