import React, { useCallback, useState, useMemo } from 'react';
import { Add, LayoutSkewGrid } from 'blueprint5-icons';
import { Button, Flex, Tabs } from '@mantine/core';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { omit } from 'lodash';

import { DetailsPageTabs, IWebsitePageValues } from 'types';
import { useEditMode, useRefreshDataSection, useTab } from 'utils/hooks';
import { useAddItemsToShowcaseAction } from 'utils/actions/showcase/add-items';
import { IListItemActionName } from 'utils/actions/list-item/types';
import { updateCollection } from 'utils/apis/showcase';
import { flags } from 'utils/flags';
import { useStore } from 'store';

import { useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { ToastError } from 'components/toast';
import { DataSectionControls } from 'components/data-section-controls';

import CollectionDetailsHeader from './collection-details-header';
import { CollectionOverviewTab } from './collection-overview-tab';
import { SortButton } from 'components/sort-buttons';
import { CollectionItemTab } from './collection-items-tab/collection-items-tab';
import { useManageSliderShowcase } from 'utils/actions/showcase/manage-slider-showcase';
import { CollectionImagesForm } from 'components/images-list/images-form';
import { ImagesView } from 'components/images-list';
import { MantineIcon } from 'utils/ui/icon';
import { CollectionDetailTab, CollectionTabPanel } from './utils';

import './style.scss';
interface ICollectionDetailsLoaded {
  collection: UmGenericListCollection;
  setCollection: React.Dispatch<React.SetStateAction<void | UmGenericListCollection>>;
  refreshSubCollectionsCount: VoidFunction;
  refreshProductIds: VoidFunction;
  productIds?: number[];
  subCollectionIds?: number[];
}

const tabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.IMAGES, DetailsPageTabs.ITEMS];

const { showAutonomousShowcaseButtons, showCollectionImagesTab } = flags;

const keysToOmit = ['preview_image_id', 'preview_image'];
type ICollectionForm = Omit<UmGenericListCollection, 'preview_image_id' | 'preview_image'>;
export const CollectionDetailsLoaded: React.FC<ICollectionDetailsLoaded> = ({
  collection,
  productIds,
  setCollection,
  refreshSubCollectionsCount,
  refreshProductIds,
  subCollectionIds,
}) => {
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabs);
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const { toastStore } = useStore();
  const saveCollection = useCallback(
    async (collection: ICollectionForm): Promise<void> => {
      try {
        const result = await updateCollection(omit(collection, keysToOmit));
        if (!result) {
          return;
        }
        setEditModeEnabled(false);
        setCollection((oldValues) => ({ ...oldValues, ...result.object }));
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [setCollection, setEditModeEnabled, toastStore],
  );

  const refreshDataSection = useRefreshDataSection();

  const onSuccess = useCallback(
    (action: IListItemActionName): void => {
      switch (action) {
        case IListItemActionName.DESTROY:
          refreshSubCollectionsCount();
          refreshProductIds();

        default:
          refreshDataSection();
      }
    },
    [refreshDataSection, refreshProductIds, refreshSubCollectionsCount],
  );

  const addItems = useAddItemsToShowcaseAction([collection], {
    section: IWebsitePageValues.COLLECTIONS,
    onSuccess,
    context: 'single',
  });
  const manageSliderShowcase = useManageSliderShowcase([collection], {
    section: IWebsitePageValues.COLLECTIONS,
    onSuccess,
    context: 'single',
  });

  const initialValues = omit(collection, keysToOmit) as ICollectionForm;
  const collectionForm = useMm3Form<ICollectionForm>(initialValues, Model.COLLECTIONS, saveCollection);
  const handleTabChange = useCallback(
    (tab): void => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  const images = useMemo(() => {
    return [
      { label: 'Main Banner', image: collection.meta?.main_banner || '', name: 'meta.main_banner' },
      { label: 'Secondary Banner', image: collection.meta?.secondary_banner || '', name: 'meta.secondary_banner' },
    ];
  }, [collection.meta?.main_banner, collection.meta?.secondary_banner]);

  return (
    <>
      <CollectionDetailsHeader
        collection={collection}
        setCollection={setCollection}
        isEditMode={editModeEnabled}
        setIsEditMode={setEditModeEnabled}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        collectionForm={collectionForm}
        refreshSubCollectionsCount={refreshSubCollectionsCount}
        refreshProductIds={refreshProductIds}
      />
      <div className="collection-details__tabs-wrapper h-100">
        <Tabs value={activeTab} onChange={handleTabChange} className="collection-details__tabs h-100">
          <Tabs.List className="d-flex flex-nowrap">
            <CollectionDetailTab
              value={DetailsPageTabs.OVERVIEW}
              activeTab={activeTab}
              editModeEnabled={editModeEnabled}
              disabled={isSortModeEnabled}
            />
            <CollectionDetailTab
              value={DetailsPageTabs.IMAGES}
              activeTab={activeTab}
              editModeEnabled={editModeEnabled}
              hidden={!showCollectionImagesTab}
            />
            <CollectionDetailTab
              value={DetailsPageTabs.ITEMS}
              activeTab={activeTab}
              editModeEnabled={editModeEnabled}
            />
            <Flex gap="xs" className="ms-auto me-4 align-self-center">
              <Button
                size="xs"
                leftSection={<MantineIcon icon={<LayoutSkewGrid />} />}
                onClick={manageSliderShowcase.handler}
                disabled={manageSliderShowcase.isDisabled}
                loading={manageSliderShowcase.isDisabled}
                variant="primary"
                hidden={!showAutonomousShowcaseButtons}
              >
                {manageSliderShowcase.title}
              </Button>

              <Button
                size="xs"
                leftSection={<MantineIcon icon={<Add />} />}
                onClick={addItems.handler}
                variant="primary"
                hidden={isSortModeEnabled || activeTab !== DetailsPageTabs.ITEMS}
              >
                {addItems.title}
              </Button>
              <SortButton
                setIsSortModeEnabled={setIsSortModeEnabled}
                isSortModeEnabled={isSortModeEnabled}
                hidden={activeTab !== DetailsPageTabs.ITEMS}
              />
              <DataSectionControls hidden={activeTab !== DetailsPageTabs.ITEMS} disabled={isSortModeEnabled} />
            </Flex>
          </Tabs.List>
          <CollectionTabPanel
            value={DetailsPageTabs.OVERVIEW}
            content={
              <CollectionOverviewTab
                isEditMode={editModeEnabled}
                collection={collection}
                subCollectionIds={subCollectionIds}
                productIds={productIds}
                collectionForm={collectionForm}
              />
            }
          />
          <CollectionTabPanel
            value={DetailsPageTabs.IMAGES}
            content={
              editModeEnabled ? (
                <CollectionImagesForm
                  {...collectionForm}
                  images={images}
                  className="thumbnail--large thumbnail--positioned-right"
                />
              ) : (
                <ImagesView images={images} className="thumbnail--large thumbnail--positioned-right" />
              )
            }
          />

          <CollectionTabPanel
            value={DetailsPageTabs.ITEMS}
            content={
              <CollectionItemTab
                collectionId={collection.id || 0}
                isSortModeEnabled={isSortModeEnabled}
                onSuccess={onSuccess}
              />
            }
          />
        </Tabs>
      </div>
    </>
  );
};
