import React from 'react';
import { IProduct, Product3Types } from 'types';
import { ProductChildrenSortTab } from './product-children-sort';
import ProductChildrenTabPreview from './product-children-preview';

interface IChildrenTabPreviewProps {
  product: IProduct;
  type: Product3Types;
  parentId: number;
  refreshAncestry: () => Promise<void>;
}

export const ProductChildrenTab: React.FC<IChildrenTabPreviewProps & { isSortModeEnabled?: boolean }> = (props) => {
  if (props.isSortModeEnabled) {
    return <ProductChildrenSortTab {...props} />;
  }

  return <ProductChildrenTabPreview {...props} />;
};

export default ProductChildrenTab;
