import React, { useCallback } from 'react';
import { IFormMultiSelectOption } from 'helpers/form';

import { IDeliveryFormProps } from 'components/delivery/types';
import { FormProducts } from 'helpers/form';

import { parseProductAncestors } from 'components/delivery/utils';

export const DeliveryProductsStep: React.FC<IDeliveryFormProps> = ({ deliveryForm: { formData, handlers } }) => {
  const { onChange } = handlers;
  const setProductAncestry = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const productAncestry = parseProductAncestors(options);
      onChange({ product_ancestry: productAncestry });
    },
    [onChange],
  );

  return (
    <div className="product-recommendation-form h-100">
      <FormProducts
        name="product_ids"
        label="Products"
        fitInParent
        large
        omni
        onSelectedItemsChange={setProductAncestry}
        {...formData.product_ids}
        {...handlers}
        required
      />
    </div>
  );
};
