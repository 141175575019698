import React from 'react';
import { ToastError } from 'components/toast';
import { useCallback } from 'react';

import { useStore } from 'store';

import { markResolved } from 'utils/apis/workflow';
import { IWorkflowActionConfig, IWorkflowActionName } from 'utils/actions/workflow/types';

import { IWorkflow } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { shouldDisplayRestart } from './acl';

export const useMarkResolveAction = (
  items: IWorkflow[],
  options: IUseActionsOptionParam<IWorkflow>,
): IWorkflowActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const handleRestart = useCallback(async () => {
    try {
      const itemId = items?.[0]?.id;
      await markResolved(itemId);
      await options?.onSuccess?.(IWorkflowActionName.MARK_RESOLVED);

      toastStore.success('workflow has been marked as resolved!');
    } catch (error) {
      toastStore.error(
        <ToastError
          error={error}
          placeholder="Sorry, something went wrong, could not mark this workflow as resolved..."
        />,
      );
      await options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Restart Workflow',
      body: `Are you sure you want to restart this workflow?`,
      onConfirm: handleRestart,
    });
  }, [dialogStore, handleRestart]);

  return {
    name: IWorkflowActionName.RESTART,
    icon: 'reset',
    title: 'Restart',
    shouldDisplay: shouldDisplayRestart,
    handler,
  };
};
