import React, { useCallback } from 'react';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { ToastError } from 'components/toast';

import { initialValues } from 'components/delivery/initial-values';
import { DeliveryWizard } from './delivery-wizzard';
import { buildDeliveryContent, removeInternalAttributes } from './utils';

import { useFormsStore } from 'store/hooks';

import { useConnectionCreateForm } from 'components/delivery/connections/use-connection-create-form';
import { initialValues as emptyConnectionValues } from 'components/delivery/connections/initial-values';

import type { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { IDeliveryConnection } from './connections/types';
import { updateConnection, createConnection } from 'utils/apis/delivery-connections';
import { updateDeliveryDraft, createDeliveryDraft } from 'utils/apis/package-delivery';

interface IDeliveryWizardContainer {
  onFinish: (delivery: DeliveriesGenericDeliveryPackage) => void;
  delivery?: ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>;
  tab?: number;
  excludeProducts?: boolean;
}

export const DeliveryWizardContainer: React.FC<IDeliveryWizardContainer> = ({
  onFinish,
  tab,
  excludeProducts = false,
  delivery,
}) => {
  const { toastStore } = useStore();
  const { addForm } = useFormsStore();

  const handleConnectionConfirm = useCallback(
    async (values: IDeliveryConnection): Promise<void> => {
      try {
        if (!values) {
          return;
        }

        const action = values?.id ? updateConnection : createConnection;
        await action(values);

        toastStore.success(`Connection was successfully ${values?.id ? 'updated' : 'created'}!`);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore],
  );

  const handleConfirm = useCallback(
    async (values: ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>): Promise<void> => {
      try {
        if (!values) {
          return;
        }
        const deliveryContent = buildDeliveryContent(values) as DeliveriesGenericDeliveryPackage['delivery_items'];
        const deliveryObj = {
          ...values,
          ...removeInternalAttributes(values),
          delivery_items: deliveryContent,
        };

        const delivery = values.id ? await updateDeliveryDraft(deliveryObj) : await createDeliveryDraft(deliveryObj);
        toastStore.success(`Package Delivery draft was successfully saved!`);
        onFinish(delivery);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore, onFinish],
  );

  const deliveryForm = useMm3Form<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>(
    {
      ...initialValues,
      ...delivery,
    } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
    Model.DELIVERY_PACKAGE,
    handleConfirm,
  );

  const connectionForm = useConnectionCreateForm({
    values: emptyConnectionValues,
    saveConnection: handleConnectionConfirm,
  });

  addForm('deliveryForm', deliveryForm);
  addForm('connectionForm', connectionForm);

  return (
    <DeliveryWizard
      deliveryForm={deliveryForm}
      connectionForm={connectionForm}
      tab={tab}
      excludeProducts={excludeProducts}
      onFinish={onFinish}
    />
  );
};
