import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { useDataSectionStore } from 'store';
import { DataSection } from 'components/data-section';
import { EventDataSectionItem } from 'components/event-data-section-item';
import { EventPreview } from 'components/event-preview';
import { PreviewSelected } from 'components/preview-selected';
import { EventFilters } from 'components/event-filters';
import { EventActionsList } from 'components/action';
import { IActionName, Pages } from 'utils/actions/types';
import { IFiltersDefinition } from 'helpers/filters';
import { IEventFilters } from 'components/event-filters/event-filters';
import { GroupTypes, IMarketingEvent } from 'types';
import { ICustomSortMenuProp, IDataSectionDefaultParams } from 'components/data-section/data-section';
import { ISubmitFiltersParams } from 'helpers/filters/helpers';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { customSortMenu } from './utils';
import { IMarketingEventActionName, IUseActionsOptionParam } from 'utils/actions';
import { EventSchema } from 'utils/schemas/event';
import { useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';

const eventPersistentFilters: IFilterOption[] = [['type', 'eq', GroupTypes.EVENT]];
const defaultFilters: IEventFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
  subevent: new EqFilter('subevent', ''),
};

interface IEventDataSectionProps {
  persistentFilters?: IFilterOption[];
  initFilters?: IFiltersDefinition;
  page?: Pages;
  defaultParams?: IDataSectionDefaultParams;
  customSubmitFilter?: (filters: ISubmitFiltersParams) => void;
  executor?: (opts: IActionOpts) => Promise<IResult<IMarketingEvent>>;
  customSortMenu?: ICustomSortMenuProp;
  actionOptions?: Partial<IUseActionsOptionParam<IMarketingEvent>>;
  persistFilters?: boolean;
}

export const EventDataSection: React.FC<IEventDataSectionProps> = observer(
  ({
    persistentFilters,
    page = Pages.LIST,
    initFilters,
    defaultParams,
    customSubmitFilter,
    executor,
    persistFilters,
    actionOptions,
  }) => {
    const filters = useMemo(() => [...eventPersistentFilters, ...(persistentFilters || [])], [persistentFilters]);
    useControlDataSection({
      persistFilters,
      schema: EventSchema,
      executor,
      defaultFilters,
      defaultParams,
      model: Model.EVENTS,
      presetSuffix: 'events',
      filters,
      stats: 'access_level',
      initFilters,
    });

    const { checked } = useDataSectionStore<IMarketingEvent>();
    const refreshDataSection = useRefreshDataSection();

    const onSuccess = useCallback(
      async (action?: IActionName): Promise<void> => {
        if (action === IMarketingEventActionName.REMOVE_FROM_GROUP) {
          setTimeout(refreshDataSection, 1000);
        } else {
          refreshDataSection();
        }
      },
      [refreshDataSection],
    );

    const itemRenderer = useCallback(
      (item: IMarketingEvent): React.ReactElement => {
        return (
          <EventDataSectionItem
            key={item.id}
            event={item}
            contextMenu={
              <ActionsMenu
                component={EventActionsList}
                items={[item]}
                options={{ context: 'single', page, onSuccess, ...(actionOptions || {}) }}
              />
            }
          />
        );
      },
      [onSuccess, page, actionOptions],
    );

    const sidebarTabs = useMemo(() => {
      const shouldDisplaySubeventsSwitch = (persistentFilters || []).filter((f) => f[0] === 'subevent').length === 0;

      return {
        preview: <EventPreview />,
        filters: (
          <EventFilters displaySubeventsSwitch={shouldDisplaySubeventsSwitch} customSubmitFilter={customSubmitFilter} />
        ),
        selectedItemRenderer(event: IMarketingEvent) {
          return (
            <PreviewSelected image={event?.preview_image?.url} assetType="image">
              {event?.name}
            </PreviewSelected>
          );
        },
      };
    }, [customSubmitFilter, persistentFilters]);

    const customSortMenuHandler = useCallback(
      (sort, handleSort) => {
        return customSortMenu(sort, handleSort, !customSubmitFilter);
      },
      [customSubmitFilter],
    );

    return (
      <DataSection
        className="contacts__data-section"
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        defaultParams={defaultParams}
        customSortMenu={customSortMenuHandler}
        contextMenu={
          <ActionsMenu
            component={EventActionsList}
            items={checked}
            options={{ context: 'multi', page, onSuccess, ...(actionOptions || {}) }}
          />
        }
      />
    );
  },
);

export default EventDataSection;
