import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';

import { useStore } from 'store';
import { IDataSectionSidebarTabs } from 'components/data-section-sidebar';
import { IEntity } from 'types';
import { MantineIcon } from 'utils/ui';
import { Classes } from 'utils/ui';
import { SelectedTabItem } from './selected-tab-item';

import './style.scss';

export const DraggableType = {
  PRODUCT: 'draggable-item',
  GROUP_ITEM: 'group-item',
};

export const DataSectionSelectedTab: React.FC<{
  contextMenu?: React.ReactElement;
  tabs: IDataSectionSidebarTabs;
}> = observer(({ contextMenu, tabs: { selectedItemRenderer } }) => {
  const {
    dataSectionStore: { checked, updateStore, isAllItemsSelected, searchStore },
  } = useStore();
  const { totalCount } = searchStore;
  const [cards, setCards] = useState(checked);

  useEffect(() => {
    setCards(checked);
  }, [checked]);

  const moveCard = (id: string, atIndex: number): void => {
    const { card, index } = findCard(id);
    const reorderedChecked = [...cards];

    reorderedChecked.splice(index, 1);
    reorderedChecked.splice(atIndex, 0, card);

    updateStore({ checked: reorderedChecked });
  };

  const handleMoveByClick = (cardObj, direction): void => {
    const { card, index } = cardObj;
    const reorderedChecked = [...cards];
    const atIndex = direction === 'down' ? index + 1 : index - 1;

    reorderedChecked.splice(index, 1);
    reorderedChecked.splice(atIndex, 0, card);

    updateStore({ checked: reorderedChecked });
  };

  const handleRemoveSelected = (card): void => {
    const { index } = card;
    const reorderedChecked = [...cards];

    reorderedChecked.splice(index, 1);

    updateStore({ checked: reorderedChecked });
  };

  const findCard = (id: string): { card: IEntity; index: number } => {
    const card = cards.filter((c) => c.id === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  const [, drop] = useDrop({ accept: DraggableType.PRODUCT });

  const handleResetSelected = (): void => {
    updateStore({ checked: [], isAllItemsSelected: false });
  };

  return (
    <div ref={drop} className="data-section-selected-tab">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="data-section-selected-tab__head flex-fill">
          <span>{isAllItemsSelected ? totalCount : checked.length}</span> Selected Items
        </div>

        {Boolean(checked.length) && Boolean(contextMenu) && contextMenu}

        <ActionIcon variant="subtle" color="gray.5" onClick={handleResetSelected} className="ms-2">
          <MantineIcon icon="cross" />
        </ActionIcon>
      </div>

      {isAllItemsSelected && (
        <div
          style={{ height: 300 }}
          className="d-flex text-center flex-column align-items-center justify-content-center"
        >
          <h2 className={'mb-3'}>All {totalCount} items selected</h2>
          <p className={Classes.TEXT_MUTED}>{`Turn off "Select all" mode to use manual selection again.`}</p>
        </div>
      )}

      {!isAllItemsSelected && !cards.length && (
        <div
          style={{ height: 300 }}
          className="data-section-selected-tab__no-selection d-flex text-center flex-column align-items-center justify-content-center"
        >
          Nothing selected
        </div>
      )}

      {!isAllItemsSelected &&
        !!cards.length &&
        cards.map((item) => (
          <SelectedTabItem
            key={item.id}
            item={item}
            moveCard={moveCard}
            moveByClick={handleMoveByClick}
            removeSelected={handleRemoveSelected}
            findCard={findCard}
            maxLength={cards.length}
            selectedItemRenderer={selectedItemRenderer}
          />
        ))}
    </div>
  );
});

export default DataSectionSelectedTab;
