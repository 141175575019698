import { NewsPage } from 'pages/news';
import { Routes } from 'utils/routes';
import { IWebsitePageValues } from 'types/list';

export const SIDE_BAR_WEBSITE_SECTION = [
  {
    route: Routes.WEBSITES_HOME_PAGE,
    text: 'Home Page',
    section: IWebsitePageValues.HOME,
  },
  {
    route: Routes.COLLECTIONS,
    text: 'Collections',
    section: IWebsitePageValues.COLLECTIONS,
  },
  {
    route: Routes.WEBSITES_GENRES,
    text: 'Genre Pages',
    section: IWebsitePageValues.GENRES,
  },
  {
    route: Routes.NEWS,
    text: 'News',
    component: NewsPage,
  },
  {
    route: Routes.WEBSITES_NEWS,
    text: 'News Page',
    section: IWebsitePageValues.NEWS,
  },
  {
    route: Routes.WEBSITES_PRODUCTION,
    text: 'Production Page',
    section: IWebsitePageValues.PRODUCTION,
  },
  {
    route: Routes.WEBSITES_EVENTS,
    text: 'Events Page',
    section: IWebsitePageValues.EVENTS,
  },
  {
    route: Routes.WEBSITES_LOCATIONS,
    text: 'Locations Page',
    section: IWebsitePageValues.LOCATIONS,
  },
  {
    route: Routes.WEBSITES_CONTACT,
    text: 'Contact Page',
    section: IWebsitePageValues.CONTACT,
  },
  {
    route: Routes.WEBSITES_BRAND_LICENSING,
    text: 'Brand Licensing Page',
    section: IWebsitePageValues.BRAND_LICENSING,
  },
  {
    route: Routes.WEBSITES_ABOUT,
    text: 'About Page',
    section: IWebsitePageValues.ABOUT,
  },
];
