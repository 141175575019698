import React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mantine/core';

import { useBasicStore } from 'store/hooks';

import { IColumn } from 'components/list-table';
import { AudioActionsList } from 'components/action/audio';
import { yn } from 'components/label-value-pair';

import { IActionName } from 'utils/actions/types';
import { ILanguage, IAudioTrack, ItemId } from 'types';
import { ActionsMenu } from 'components/action/utils';

const AudioLanguage: React.FC<{ languageId?: string; language?: ILanguage }> = observer(({ language, languageId }) => {
  const { languagesNamesById } = useBasicStore();
  const languageName = language?.name || (languageId && languagesNamesById([languageId])?.[0]);

  return (
    <Tooltip key={language?.id} label={languageName} position={'top-end'}>
      <div className="text-truncate">{languageName}</div>
    </Tooltip>
  );
});

export const getAudiosTableColumns = (
  onSuccess: (action?: IActionName, entity?: IAudioTrack) => void | Promise<void>,
  assetId: ItemId,
): IColumn<IAudioTrack>[] => {
  const columns: IColumn<IAudioTrack>[] = [
    {
      title: 'Language',
      renderer: ({ language, language_id }) => <AudioLanguage language={language} languageId={language_id} />,
      columnClassName: 'asset-audio__langauge',
    },
    {
      title: 'File Name',
      renderer: ({ storage_url }) => {
        const fileName = storage_url?.split('/').pop();
        return (
          <Tooltip key={storage_url} label={fileName} position={'top'}>
            <div className="text-truncate">{fileName}</div>
          </Tooltip>
        );
      },
      columnClassName: 'asset-audio__filename',
    },
    {
      title: 'Format',
      renderer: ({ storage_url }) => storage_url?.split('.').pop(),
      columnClassName: 'asset-audio__format',
    },
    {
      title: 'Label',
      renderer: ({ label }) => (
        <Tooltip key={label} label={label} position={'top'}>
          <div className="text-truncate">{label || '—'}</div>
        </Tooltip>
      ),
      columnClassName: 'asset-audio__label',
    },
    {
      title: 'Available in player',
      renderer: ({ show_in_player }) => yn(show_in_player) || '—',
      columnClassName: 'asset-audio__player',
    },
    {
      title: '',
      renderer: (audio: IAudioTrack) => (
        <ActionsMenu component={AudioActionsList} items={[audio]} options={{ onSuccess, context: 'single', assetId }} />
      ),
      columnClassName: 'asset-audio__actions',
    },
  ];
  return columns;
};
