import React from 'react';
import cx from 'classnames';

import { Tab, TabPanel } from 'components/tabs';
import { DetailsPageTabs } from 'types';
import { getIsTabDisabled } from 'utils/general';

const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.IMAGES];

export const CollectionDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
  hidden?: boolean;
  disabled?: boolean;
}> = ({ value, activeTab, editModeEnabled, hidden = false, disabled }) => {
  return (
    <Tab
      value={value}
      className={cx(`entity-detail__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={getIsTabDisabled(value, editableTabs, editModeEnabled) || disabled}
      hidden={hidden}
    />
  );
};

export const CollectionTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="entity-detail__tab-content h-100" content={content} />;
};
