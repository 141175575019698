import { action, IObservableArray, observable } from 'mobx';
import { IDataProvider, IDataProviderItem } from './data-provider';

export class StaticDataProvider implements IDataProvider {
  @observable loading = false;
  @observable public data = observable([]) as IObservableArray<IDataProviderItem>;
  @observable public cachedSelectedValue: IDataProviderItem;

  constructor(protected staticData: IDataProviderItem[]) {}

  @action filter(query: string): void {
    let filteredData = this.staticData;

    if (query) {
      filteredData = this.staticData.filter(({ label }) =>
        label.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      );
    }

    this.data.replace(filteredData);
  }

  public cacheSelectedValue(item: IDataProviderItem): void {
    this.cachedSelectedValue = item;
  }

  init(): void {
    if (!this.data?.length && this.staticData?.length) {
      this.data.replace(this.staticData);
    }
  }
}
