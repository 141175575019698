import { useUploadImageDialog } from 'components/upload-preview-image';
import { setGroupPreviewImage } from 'utils/apis/groups';
import { IGroupConfig, IGroupActionName } from './types';
import { shouldDisplaySingleNotDeleted as shouldDisplay } from './actions-acl';

import { IGroup, IIdentifiable } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';

export const useUploadGroupPreviewAction = (
  items: (IGroup & IIdentifiable)[],
  options: IUseActionsOptionParam,
): IGroupConfig => {
  const [openUploadImageDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      const group = items?.[0];
      const itemId = group?.id;
      if (file && itemId) {
        const { object } = await setGroupPreviewImage(itemId, file, group?.preview_image?.id);
        options?.onSuccess?.(IGroupActionName.UPLOAD_PREVIEW, object as IGroup);
      }
      return true;
    },
  });

  return {
    name: IGroupActionName.UPLOAD_PREVIEW,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload preview',
    handler: openUploadImageDialog,
  };
};
