import React from 'react';
import { useIsResizing } from 'utils/hooks/window-resize';
import { AspectRatio } from 'components/aspect-ratio';

const ChartContainer: React.FC<{
  children: JSX.Element;
  aspectRatio: number;
}> = ({ children, aspectRatio }): JSX.Element => {
  const isResizing = useIsResizing();
  return (
    <AspectRatio ratio={aspectRatio}>
      {/* display: none during resize to save a lot on performance and get rid of lag in UI responsiveness */}
      {/* width: 99% to have responsiveness on resize - https://stackoverflow.com/questions/57385938/how-to-get-react-chartjs-to-resize-back-down-with-window */}
      <div style={{ width: '99%', display: isResizing ? 'none' : 'inherit' }}>{children}</div>
    </AspectRatio>
  );
};

export default ChartContainer;
