import React from 'react';
import cx from 'classnames';

import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useProfileStore } from 'store/profile-store';
import { Sidebar } from 'components/sidebar';
import { TopHeader } from 'components/top-header';
import { Footer } from 'components/footer';
import { HotkeyManager } from 'components/hotkey-manager';
import { sidebarToggle } from './utils';

import './style.scss';

interface ILayoutProps {
  className?: string;
}

const Layout: React.FC<ILayoutProps> = (props) => {
  const { className, children } = props;
  const isSidebarOpen = useProfileStore.getState().settings.isSidebarOpen;

  const [opened, handlers] = useDisclosure(isSidebarOpen, {
    onOpen: () => sidebarToggle(),
    onClose: () => sidebarToggle(),
  });

  return (
    <>
      <AppShell
        header={{ height: 70 }}
        navbar={{
          width: { base: opened ? 230 : 75 },
          breakpoint: 'xs',
          collapsed: { mobile: !opened, desktop: !opened },
        }}
        footer={{ height: 40 }}
        padding="sm"
        className="layout"
      >
        <AppShell.Navbar p="md" className="sidebar">
          <Sidebar opened={opened} toggle={handlers.toggle} />
        </AppShell.Navbar>

        <AppShell.Header className="top-header">
          <TopHeader toggle={handlers.toggle} />
        </AppShell.Header>

        <AppShell.Main className={cx('layout__main', className)}>{children}</AppShell.Main>

        <AppShell.Footer>
          <Footer />
        </AppShell.Footer>
      </AppShell>
      <HotkeyManager toggle={handlers.toggle} />
    </>
  );
};

export default Layout;
