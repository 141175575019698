import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { useBasicStore } from 'store/hooks';
import { IAsset, IMm3Asset } from 'types';

import { AssetFilters, withAssetVersion } from 'utils/asset';
import { getEntityType, formatResolution, joinLanguages, parseDataSize } from 'utils/general';
import { useLanguages } from 'utils/hooks';
import { formatType } from 'utils/format-type';

export const AssetOldMetadataSpecificationsSection: React.FC<{ asset?: IAsset }> = observer(({ asset }) => {
  const { languages } = useBasicStore();
  const {
    ['@type']: type,
    classification,
    file_name,
    file_size,
    format,
    height,
    ingest_notes,
    language_ids,
    md5,
    user_md5,
    width,
  } = asset || {};

  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isImage: boolean = assetItemType === AssetFilters.IMAGE;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Specification">
      <Row label="File Name" value={file_name} size="m" />
      <Row label="File Type" value={type} size="m" />
      <Row label="MD5 Ingest" value={md5} size="m" />
      <Row label="MD5 User provided" value={user_md5} size="m" />
      <Row label="Width" value={width && `${width}px`} size="m" hidden={isImage || isVideo} />
      <Row label="Height" value={height && `${height}px`} size="m" hidden={isImage || isVideo} />
      <Row label="Resolution" value={`${formatResolution({ width, height })}`} size="m" hidden={isImage || isVideo} />
      <Row label="Languages" value={joinLanguages(languages, language_ids)} size="m" />
      <Row label="File Size" value={parseDataSize(file_size)} size="m" />
      <Row label="Format" value={format} size="m" hideIfNoValue />
      <Row label="Ingest notes" value={ingest_notes} size="m" hideIfNoValue />
    </InfoBoxV2>
  );
});

export const AssetMm3MetadataSpecificationsSection: React.FC<{ asset: IMm3Asset }> = observer(({ asset }) => {
  const { classification, file_size, ingest_notes, type } = asset || {};
  const { file_name, format, height, language_id, md5, user_md5, width } = asset?.meta || {};
  const language = useLanguages([language_id]);
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isImage: boolean = assetItemType === AssetFilters.IMAGE;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Specification">
      <Row label="File Name" value={file_name} size="m" />
      <Row label="File Type" value={formatType(type)} size="m" />
      <Row label="MD5 Ingest" value={md5} size="m" />
      <Row label="MD5 User provided" value={user_md5} size="m" />
      <Row label="Width" value={width && `${width}px`} size="m" hidden={isImage || isVideo} />
      <Row label="Height" value={height && `${height}px`} size="m" hidden={isImage || isVideo} />
      <Row label="Resolution" value={`${formatResolution({ width, height })}`} size="m" hidden={isImage || isVideo} />
      <Row label="Language" value={language} size="m" />
      <Row label="File Size" value={parseDataSize(file_size)} size="m" />
      <Row label="Format" value={format} size="m" hideIfNoValue />
      <Row label="Ingest notes" value={ingest_notes} size="m" hideIfNoValue />
    </InfoBoxV2>
  );
});

export const AssetMetadataSpecificationsSection = withAssetVersion(
  AssetOldMetadataSpecificationsSection,
  AssetMm3MetadataSpecificationsSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
