import React from 'react';
import { observer } from 'mobx-react-lite';

import DeliveryDataSection from 'components/delivery-data-section';
import { Pages } from 'utils/actions/types';
import { DataSectionPage } from 'components/layout';

const page = Pages.DELIVERIES;

const Deliveries: React.FC = observer(() => {
  return (
    <DataSectionPage>
      <DeliveryDataSection persistFilters page={page} />
    </DataSectionPage>
  );
});

export default Deliveries;
