import React, { useCallback } from 'react';
import { useRemote } from 'utils/hooks';

import { Row } from 'components/label-value-pair';
import { getNotificationPreferences } from 'utils/apis/notification-preferences';

export const MeetingNotificationsSection: React.FC = () => {
  const fetchPreferences = useCallback(() => {
    return getNotificationPreferences();
  }, []);

  const [data = {}] = useRemote(fetchPreferences);

  const preferences = Object.entries(data)
    .filter(([, value]) => value === true)
    .map(([key]) => key.split('_')[0])
    .join(', ');

  return <Row label="Meeting Notifications Preferences" value={preferences || '-'} size="m" />;
};
