import React from 'react';
import cx from 'classnames';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { IProduct } from 'types';
import { Routes } from 'utils/routes';
import DateElement from 'components/date-list-element/date-list-element';
import { ProductsLink } from 'components/product';
import { Classes } from 'utils/ui';

import './style.scss';

export interface ICastCrewListItemAttributesProps {
  cast: PmGenericCastCrew & { products?: IProduct[] };
}

export const CastCrewListItemAttributes: React.FC<ICastCrewListItemAttributesProps> = ({ cast }) => {
  const { id, created_at, updated_at, meta, products } = cast;

  return (
    <div className={cx('cast-crew-list-item-row__attributes', Classes.TEXT_MUTED)}>
      <div className="text-truncate cast-crew-list-item-row__description">{meta?.description}</div>
      <div className="cast-crew-list-item-row__products text-muted">
        <ProductsLink url={`${Routes.CAST_CREW}/${id}`} products={products} />
      </div>
      <DateElement className="text-truncate cast-crew-list-item-row__created" date={created_at} mfxIcon="created-at" />
      <DateElement className="text-truncate cast-crew-list-item-row__updated" date={updated_at} mfxIcon="updated-at" />
    </div>
  );
};
