import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { IOrganization, IIdentifiable, DetailsPageTabs } from 'types';
import { useRemote, useTab } from 'utils/hooks';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { loadOrganization } from './api';
import OrganizationDetailsLoaded from './organization-details-loaded';
const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.FINANCIAL,
  DetailsPageTabs.CONTACTS,
  DetailsPageTabs.IMAGES,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.ACCESS,
  DetailsPageTabs.CONNECTIONS,
  DetailsPageTabs.DELIVERIES,
  DetailsPageTabs.DELIVERY_INFORMATION,
];

const OrganizationDetails: React.FC = observer(() => {
  const { organizationId = '' } = useParams<{ organizationId: string }>();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);
  const fetchOrganization = useCallback(async (): Promise<(IOrganization & IIdentifiable) | undefined> => {
    return organizationId ? loadOrganization(organizationId) : undefined;
  }, [organizationId]);

  const [organization, loading, , refresh] = useRemote<IOrganization & IIdentifiable>(fetchOrganization);

  return (
    <div
      className="d-flex flex-column background-wrapper w-100 h-100"
      style={{ backgroundImage: `url(${organization?.banner})` }}
    >
      <div className="background-wrapper__index w-100 h-100">
        <DashboardBreadcrumbs className="mb-3" />
        {loading && <Loading text="Loading Organization" />}
        {organization && !loading && (
          <OrganizationDetailsLoaded
            organization={organization}
            refresh={refresh}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {!organization && !loading && <SectionMessage intent={SectionMessageType.EMPTY} />}
      </div>
    </div>
  );
});

export default OrganizationDetails;
