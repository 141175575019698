import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { Loading } from 'components/loading';
import { BodyWrapper } from 'components/layout';

import { useRemote } from 'utils/hooks';
import { loadRecommendation } from 'utils/apis/recommendation';

import { RecommendationDetails } from './recommendation-details';

import './style.scss';

export const RecommendationDetailsContainer: React.FC = observer(() => {
  const { recommendationId } = useParams<{ recommendationId: string }>();

  const fetchRecommendation = useCallback(async () => {
    return loadRecommendation(recommendationId);
  }, [recommendationId]);

  const [recommendation, loading, setRecommendation] = useRemote(fetchRecommendation);

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Recommendation" />
      </BodyWrapper>
    );
  }

  if (!recommendation?.id) {
    return (
      <BodyWrapper>
        <SectionMessage intent={SectionMessageType.EMPTY}>No recommendation found</SectionMessage>
      </BodyWrapper>
    );
  }

  return <RecommendationDetails recommendation={recommendation} setRecommendation={setRecommendation} />;
});
