import React from 'react';
import { Classes } from 'utils/ui';
import { Intent } from 'utils/ui';

interface IText {
  intent?: Intent;
  lines?: number;
}

export const Text: React.FC<IText> = ({ children, lines = 0, intent }) => {
  const intentClass = intent ? Classes[`INTENT_${intent.toUpperCase()}`] : '';

  return (
    <div className={`bp3-form-group ${intentClass}`}>
      <div className="bp3-form-helper-text">
        {children}
        {lines > 0 && <>&nbsp;</>}
      </div>
    </div>
  );
};
