import { subDays, startOfDay, startOfYear, endOfDay, subYears, endOfYear } from 'date-fns';
import { DateRange, DateRangeShortcut } from '@blueprintjs/datetime';

import { parseDate, defaultMinDate, defaultMaxDate, today } from 'utils/date';

export const emptyValue: DateRange = [null, null];

export const getShortcuts = (minDate?: Date, maxDate?: Date): DateRangeShortcut[] => {
  const minD = minDate || defaultMinDate;
  const maxD = maxDate || defaultMaxDate;

  const sevenDaysAgo = parseDate(subDays(startOfDay(today), 7), minD, maxD);
  const fourteenDaysAgo = parseDate(subDays(startOfDay(today), 14), minD, maxD);
  const thirtyDaysAgo = parseDate(subDays(startOfDay(today), 30), minD, maxD);
  const ninetyDaysAgo = parseDate(subDays(startOfDay(today), 90), minD, maxD);
  const thisYearStart = parseDate(startOfYear(today), minD, maxD);

  const shortcuts: DateRangeShortcut[] = [
    { label: 'Today', dateRange: [startOfDay(today), endOfDay(today)], includeTime: true },
    { label: 'Past 7 days', dateRange: [sevenDaysAgo, endOfDay(today)], includeTime: true },
    { label: 'Past 14 days', dateRange: [fourteenDaysAgo, endOfDay(today)], includeTime: true },
    { label: 'Past 30 days', dateRange: [thirtyDaysAgo, endOfDay(today)], includeTime: true },
    { label: 'Past 90 days', dateRange: [ninetyDaysAgo, endOfDay(today)], includeTime: true },
    { label: 'This year', dateRange: [thisYearStart, endOfDay(today)], includeTime: true },
    {
      label: 'Last year',
      dateRange: [
        startOfYear(subYears(new Date(today.getFullYear(), 1, 1), 1)),
        endOfYear(subYears(new Date(today.getFullYear(), 11, 31), 1)),
      ],
      includeTime: true,
    },
    { label: 'All time', dateRange: [minD, endOfDay(today)], includeTime: true },
  ];
  return shortcuts;
};
