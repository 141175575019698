import { Model } from 'helpers/filters/types';
import { DetailsPageTabs, IProduct, IProductAncestryInfo, Product3Types } from 'types';
import { by, formatType } from 'utils/general';

export const pluralizeProductType = (productType?: string | null, extension = 's'): string => {
  if (!productType) {
    return '';
  }

  const label = formatType(productType);
  const labelLastChar = label.at(label.length - 1);
  const isLastCharNotAlpha = labelLastChar?.match(/[^a-z]/);

  return label.endsWith('s') || isLastCharNotAlpha ? label : label + extension;
};

export const mapProductToAncestryInfo = (products: IProduct[], ancestryInfo: IProductAncestryInfo[]): IProduct[] => {
  const ancestryByProductId = by(ancestryInfo, 'product_id');

  return products.map((product) => {
    return { ...product, ancestry_info: ancestryByProductId[product.id] };
  });
};

export function getProductSearchModifiers(
  childType: Product3Types,
  parent: IProduct,
): { isDisabled: boolean; isNavigationEnabled: boolean } {
  const { type: parentType, ancestry_info } = parent;
  const isSeries = parentType === Product3Types.SERIES;

  switch (childType) {
    case Product3Types.EPISODE:
      const isSeriesAndHasSeasons =
        parentType === Product3Types.SERIES && Boolean(ancestry_info?.effective_seasons_count);
      const isSeason = parentType === Product3Types.SEASON;
      return {
        isDisabled: !isSeries && !isSeason,
        isNavigationEnabled: isSeriesAndHasSeasons || !isSeason,
      };

    case Product3Types.EPISODE_VERSION:
      const isNotEpisode = parentType !== Product3Types.EPISODE;
      return {
        isDisabled: isNotEpisode,
        isNavigationEnabled: isNotEpisode,
      };

    case Product3Types.FILM_VERSION:
      const isNotProgram = parentType !== Product3Types.PROGRAM;
      return {
        isDisabled: isNotProgram,
        isNavigationEnabled: isNotProgram,
      };
    case Product3Types.SEASON:
      return {
        isDisabled: !isSeries,
        isNavigationEnabled: !isSeries,
      };
    case Product3Types.PROGRAM:
    case Product3Types.SERIES:
      const isFormat = parentType === Product3Types.FORMAT;
      return {
        isDisabled: !isFormat,
        isNavigationEnabled: !isFormat,
      };
    default:
      return {
        isDisabled: false,
        isNavigationEnabled: true,
      };
  }
}

export function getAllowedParentSearchTypes(type: Product3Types): Product3Types[] {
  switch (type) {
    case Product3Types.EPISODE:
      return [Product3Types.FORMAT, Product3Types.SERIES, Product3Types.SEASON];

    case Product3Types.EPISODE_VERSION:
      return [Product3Types.FORMAT, Product3Types.SERIES, Product3Types.SEASON, Product3Types.EPISODE];

    case Product3Types.FILM_VERSION:
      return [Product3Types.PROGRAM, Product3Types.FORMAT];

    case Product3Types.SEASON:
      return [Product3Types.SERIES, Product3Types.FORMAT];

    case Product3Types.PROGRAM:
    case Product3Types.SERIES:
      return [Product3Types.FORMAT];

    default:
      return [Product3Types.FORMAT];
  }
}

export const tabToType = {
  [DetailsPageTabs.SERIES]: Product3Types.SERIES,
  [DetailsPageTabs.EPISODES]: Product3Types.EPISODE,
  [DetailsPageTabs.EPISODE_VERSION]: Product3Types.EPISODE_VERSION,
  [DetailsPageTabs.PROGRAMS]: Product3Types.PROGRAMS,
  [DetailsPageTabs.FILM_VERSION]: Product3Types.FILM_VERSION,
  [DetailsPageTabs.SEASONS]: Product3Types.SEASON,
};

export const typeToTab = Object.entries(tabToType).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

export const formatProductTabName = (tab: DetailsPageTabs): string => {
  const label = formatType(tabToType[tab]);
  return pluralizeProductType(label);
};

export const formatMm3TypeTag = (type?: string): string | null => {
  if (!type?.includes('::')) {
    return null;
  }
  return formatType(type).replace('Episode Version', 'Ep. Version');
};

export const getProductModel = (type?: string): string => {
  switch (type) {
    case Product3Types.EPISODE:
      return Model.EPISODE;
    case Product3Types.EPISODE_VERSION:
      return Model.EPISODE_VERSION;
    case Product3Types.FILM_VERSION:
      return Model.FILM_VERSION;
    case Product3Types.PROGRAM:
      return Model.PROGRAM;
    case Product3Types.FORMAT:
      return Model.FORMAT;
    case Product3Types.SEASON:
      return Model.SEASON;
    case Product3Types.SERIES:
      return Model.SERIES;
    default:
      return Model.PRODUCTS;
  }
};
