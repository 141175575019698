import React, { useCallback } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { RecommendationDataSectionListItem } from 'components/recommendation/recommendation-data-section-item/recommendation-data-section-list-item';

interface IRecommendationDataSectionItemProps {
  className?: string;
  recommendation: McGenericRecommendationProduct;
  contextMenu?: React.ReactElement;
}

export const RecommendationDataSectionItem: React.FC<IRecommendationDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, recommendation } = props;
  const { id } = recommendation;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = Boolean(id && getIsChecked(id));
  const isActive = Boolean(id && getIsActive(id));

  const handleClick = useCallback(
    (e): void => {
      if (!id) {
        return;
      }
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item-row', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <RecommendationDataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        recommendation={recommendation}
        isChecked={isChecked}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
