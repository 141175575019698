import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IGroup, IIdentifiable } from 'types';
import { useDeleteAction } from './delete-action';
import { useRestoreAction } from './restore-action';
// import { useGroupExpiryAction } from './use-group-expiry-action';
import { useAddContactsAction, useAddOrganizationsAction, useAddProductsAction } from './add-entities-actions';
import { useChangeAccessAction } from './change-access-action';
import { useEmailAction } from './email-action';

import { useCopyAction } from 'utils/actions/common/copy-action';

import { IAccessPrivilegeActionName } from './types';

import type { IShouldDisplayAccessPrivilegeActionFunction, IAccessPrivilegeActionConfig } from './types';

export { useGrantAccessPrivilegeAction } from './grant-access-privilege-action';
export { IAccessPrivilegeActionName, IShouldDisplayAccessPrivilegeActionFunction };
export {
  useAddContactsAction,
  useAddOrganizationsAction,
  useAddProductsAction,
  useAddContactSelectionsAction,
  contactsToItems,
  productsToItems,
  organizationsToItems,
  contactSelectionsToItems,
} from './add-entities-actions';

export const useAccessPrivilegeActions = (
  entities: (IGroup & IIdentifiable)[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IAccessPrivilegeActionName> => {
  const addProductsAction = useAddProductsAction({
    entities,
    options: { ...options, addSelectedProductAncestors: true },
  });
  const addContactsAction = useAddContactsAction({ entities, options });
  const addOrganizationsAction = useAddOrganizationsAction({
    entities,
    options,
  });

  const copyAccessPrivilegeAction = useCopyAction(entities, {
    ...options,
    messageSuccess: 'Access privilege has been copied',
  });
  const changeAccessAction = useChangeAccessAction(entities, options);

  const deleteAccessPrivilegeAction = useDeleteAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);
  const emailAction = useEmailAction(entities, options);

  // const expiryAction = useGroupExpiryAction(entities, options);

  const actions = [
    copyAccessPrivilegeAction,
    addProductsAction,
    addContactsAction,
    addOrganizationsAction,
    changeAccessAction,
    emailAction,
    deleteAccessPrivilegeAction,
    restoreAction,
  ];

  return useActions(actions as IAccessPrivilegeActionConfig[], options, entities);
};
