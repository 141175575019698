import React, { useCallback, useMemo } from 'react';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';

import { getAudiosTableColumns } from './utils';

import { IAsset } from 'types';
import { getAssetAudioArtifacts } from 'utils/apis/audio-track';
import { useRemote } from 'utils/hooks';

import './style.scss';

const AssetAudiosTab: React.FC<{ asset: IAsset }> = ({ asset }) => {
  const { id } = asset || {};

  const fetchAudioTracks = useCallback(async () => {
    return await getAssetAudioArtifacts(id);
  }, [id]);

  const [audioTracks = [], loading, , refresh] = useRemote(fetchAudioTracks);

  const columns = useMemo(() => getAudiosTableColumns(refresh, id), [refresh, id]);

  if (!audioTracks.length && !loading) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable data={audioTracks} columns={columns} tableClassName="asset-audio__table" />
    </div>
  );
};

export default AssetAudiosTab;
