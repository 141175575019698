import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { Page } from 'components/layout';

import { Routes } from 'utils/routes';

import { IGroup } from 'types';
import { ScreeningRoomWizard } from 'components/screening-room';

const ScreeningRoomCreate: React.FC<{}> = observer(() => {
  const navigate = useNavigate();
  const onFinish = useCallback(
    (screeningRoom: IGroup): void => {
      navigate(`${Routes.SCREENING_ROOMS}/${screeningRoom.id}`);
    },
    [navigate],
  );

  return (
    <Page title="Create New Screening Room">
      <ScreeningRoomWizard onFinish={onFinish} />
    </Page>
  );
});

export default ScreeningRoomCreate;
