import React from 'react';

import { IAccessPrivilegeGrantFormEmailForm } from 'components/access-privilege-grant/access-privilege-grant-form-email';
import {
  IAddToGroupEntityType,
  IAddToGroupForm,
  IAddToGroupFormReturn,
  IAddToGroupFull,
  IUseSelectionDialogProps,
} from 'components/add-to-group-dialog//types';
import { createInitialAddToGroupValues, processAddToGroupData } from 'components/add-to-group-dialog/utils';
import { ToastError } from 'components/toast';
import { DialogStore } from 'store/dialog-store';
import { ToastStore } from 'store/toast-store';
import { ItemId } from 'types';
import { IGroupActionName } from 'utils/actions/common';
import { pluralEntityInflect } from 'utils/general';
import { loadAccessPrivilegeAllUserIds, sendAccessPrivilegeEmails } from 'utils/apis/access-privilege';
import { parseDifferentContacts, loadGroupsUsers } from 'utils/apis/groups';
import { loadOrganizationsAllUsers } from 'utils/apis/organization';

export const createInitialAddToAccessPrivilegeValues = (
  itemIds: Array<string | number>,
  groupIds: string | number | undefined,
): IAddToGroupForm => ({
  ...createInitialAddToGroupValues(itemIds, groupIds),
  permit_download: false,
});

export enum Steps {
  Step1 = 0,
  Step2 = 1,
}

export type IGetDialogParams = (params: {
  form: IAddToGroupFormReturn;
  emailForm: IAccessPrivilegeGrantFormEmailForm;
  currentStep: Steps;
  onConfirm: (values: IAddToGroupFull | IAddToGroupForm) => void;
  setCurrentStep: (step: Steps) => void;
}) => {
  title: string;
  onClick: () => void;
  disabled: boolean;
};

export const getDialogParams: IGetDialogParams = ({ form, emailForm, currentStep, onConfirm, setCurrentStep }) => {
  const shouldNotify = form.values.notify_per_email;

  if (currentStep === Steps.Step2) {
    return {
      title: 'Confirm',
      onClick: () => onConfirm({ ...form.values, ...emailForm.values }),
      disabled: !emailForm.valid || !form.valid,
    };
  }
  if (shouldNotify) {
    return {
      title: ' Next ',
      onClick: () => setCurrentStep(Steps.Step2),
      disabled: !form.valid,
    };
  } else
    return {
      title: 'Confirm',
      onClick: () => onConfirm(form.values),
      disabled: !form.valid,
    };
};

const loadUserIdsForNotifications: Record<
  IAddToGroupEntityType,
  ((group_id: ItemId, values: IAddToGroupForm) => Promise<ItemId[]>) | null
> = {
  events: null,
  assets_and_selections: null,
  // notify existing users that are assigned to AP (users and users of organizations)
  products: (group_id) => loadAccessPrivilegeAllUserIds(group_id),
  assets: (group_id) => loadAccessPrivilegeAllUserIds(group_id),

  // notify only new added users, like users and users from selections or users from an organization
  users: async (group_id, values) => values.contacts?.map(({ id }) => id) || [],
  organizations: async (group_id, values) => {
    const users = await loadOrganizationsAllUsers({ organization_ids: values.contacts?.map(({ id }) => id) || [] });

    return users.map(({ id }) => id);
  },
  contacts_and_selections: async (group_id, values) => {
    const entities = parseDifferentContacts(values.contacts);
    const usersOfGroups = await loadGroupsUsers(entities.selections);

    return ([] as ItemId[]).concat(entities.users).concat(usersOfGroups.map(({ id }) => id));
  },
  contact_selections: async (group_id, values) => {
    return (await loadGroupsUsers(values.contacts?.map(({ id }) => id))).map(({ id }) => id);
  },
};

export const addToAccessPrivilegeOnConfirm = async (
  values: IAddToGroupForm,
  dialogStore: DialogStore,
  toastStore: ToastStore,
  entityLabel: string,
  groupTitle: string,
  props: Partial<IUseSelectionDialogProps>,
  entityType: IAddToGroupEntityType,
  withEmailNotification = false,
): Promise<void> => {
  const { group, options } = props;
  const group_id = group?.id || values.group_id;

  try {
    const { itemsLength, entityTypeLabel } = await processAddToGroupData(values, entityLabel, props, entityType);

    const { entityWithCount } = pluralEntityInflect(entityTypeLabel, itemsLength);
    if (withEmailNotification && values.notify_per_email && group_id) {
      // process email notifications for Access privilege
      const user_ids = await loadUserIdsForNotifications[entityType]?.(group_id, values);
      if (user_ids) {
        await sendAccessPrivilegeEmails({
          group_id,
          user_ids,
          subject: values.email_subject,
          message: values.email_message,
        });
      }
    }
    toastStore.success(`${entityWithCount} will be added to the ${groupTitle}`);
    options?.onSuccess?.(IGroupActionName.ADD_ENTITIES);
    dialogStore.close();
  } catch (error) {
    toastStore.error(<ToastError error={error} />);
  }
};
