import React, { useCallback } from 'react';

import { ToastError } from 'components/toast';
import { IAsset, IMm3Asset, ItemId } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

import { shouldDisplayDeletePreviewAction as shouldDisplay } from './actions-acl';
import { removeAssetPreviewImage } from 'utils/actions/asset/api';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';

export const useDeletePreviewAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const asset = items[0];
      await removeAssetPreviewImage(asset.id as ItemId);
      toastStore.success('Success');
      options?.onSuccess?.();
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder="Preview image deletion failed" />);
      options?.onFailure?.();
    }
  }, [items, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: `Delete Preview image`,
      body: 'Are you sure you want to delete this Preview Image?',
      onConfirm,
    });
  };

  return {
    name: IAssetActionName.DELETE_PREVIEW,
    shouldDisplay,
    icon: 'delete',
    title: 'Delete Preview',
    intent: Intent.DANGER,
    handler,
  };
};
