import React, { useCallback } from 'react';
import { AmGenericNews, UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { IAllowedListItemType, ISearchFilter } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';
import { IEntitiesToQuery, createListItems, deleteListItems, getAllListItems } from 'utils/apis/showcase-item';
import { createList, getShowcaseByPurpose } from 'utils/apis/showcase';
import { IModalSize } from 'components/dialogs/types';
import { ToastError } from 'components/toast';
import { FormSliderShowcase } from 'components/showcase';
import { IFormMultiSelectOption } from 'helpers/form';
import { useStore } from 'store';

import { INewsActionConfig, INewsActionName } from './types';
import { shouldDisplayManageShowcase } from './action-acl';

const allowedItemTypes: IAllowedListItemType[] = ['user', 'product', 'asset', 'list/collection', 'group/event'];

interface ISliderFormProps {
  news: AmGenericNews;
  sliderShowcase?: UmGenericListShowcase | null;
  onSubmit: (items: IFormMultiSelectOption[]) => Promise<void>;
  options: IUseActionsOptionParam<UmGenericListShowcase>;
  additionalFilters?: { [key in IEntitiesToQuery]?: ISearchFilter[] };
}

const SliderForm: React.FC<ISliderFormProps> = observer(
  ({ onSubmit, sliderShowcase, news, options, additionalFilters }) => {
    const { toastStore, dialogStore } = useStore();
    const handleSubmit = useCallback(
      async (items: IFormMultiSelectOption[]): Promise<void> => {
        try {
          await onSubmit(items);
          options?.onSuccess?.(INewsActionName.MANAGE_SHOWCASE);
          toastStore.success(
            `Showcase for news item "${news.title}" has been ${
              sliderShowcase?.id ? 'updated' : 'created'
            } successfully!`,
          );
          dialogStore.close();
        } catch (error) {
          options?.onFailure?.();
          toastStore.error(<ToastError error={error} />);
        }
      },
      [onSubmit, options, toastStore, news.title, sliderShowcase?.id, dialogStore],
    );

    return (
      <FormSliderShowcase
        allowed_item_types={allowedItemTypes}
        additionalFilters={additionalFilters}
        sliderId={sliderShowcase?.id}
        placeholder="Add items to Showcase"
        onSubmit={handleSubmit}
      />
    );
  },
);

export const useManageSliderShowcase = (
  entities: AmGenericNews[],
  options: IUseActionsOptionParam,
): INewsActionConfig => {
  const { dialogStore } = useStore();
  const [news] = entities;
  const { id } = news || {};
  const onCreate = useCallback(
    async (items: IFormMultiSelectOption[]): Promise<void> => {
      const sliderShowcase = await createList({
        meta: { page: 'configuration' },
        purpose: `news_${id}_slider`,
        name: `Slider for news item with id ${id}`,
        type: 'List::Showcase',
        access_level: 'public',
        allowed_item_types: allowedItemTypes,
      });
      await createListItems(items, sliderShowcase?.id);
    },
    [id],
  );

  const onClick = useCallback(async () => {
    if (!news?.id) {
      return;
    }
    const sliderShowcase = await getShowcaseByPurpose(`news_${news.id}_slider`);
    const onUpdate = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const listItems = await getAllListItems(sliderShowcase?.id);
      const itemsIds = listItems.reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
      await deleteListItems(sliderShowcase?.id, itemsIds);
      await createListItems(items, sliderShowcase?.id);
    };

    dialogStore.openModal({
      title: 'Manage Showcase',
      size: IModalSize.M,
      body: () => (
        <SliderForm
          news={news}
          onSubmit={sliderShowcase?.id ? onUpdate : onCreate}
          sliderShowcase={sliderShowcase}
          options={options}
        />
      ),
    });
  }, [dialogStore, news, onCreate, options]);

  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: INewsActionName.MANAGE_SHOWCASE,
    icon: 'layout-skew-grid',
    title: 'Manage Showcase',
    shouldDisplay: shouldDisplayManageShowcase,
    handler,
    isDisabled: disabled,
  };
};
