import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';

export enum IConnectionActionName {
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
  VALIDATE = 'validate',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}

export type IConnectionActionConfig = IActionConfig<DeliveriesGenericConnection, IConnectionActionName>;

export type IShouldDisplayConnectionActionFunction = IShouldDisplayActionFunction<
  DeliveriesGenericConnection,
  IConnectionActionName
>;
