import { IAnalyticsDateParams, IContact, ItemId } from 'types';
import { IChipmunk } from '@mediafellows/chipmunk';
import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { byId } from 'utils/general';
import { getContacts } from 'utils/apis/contacts';
import { omitNilValues } from 'utils/ui';
import { analyticsContactSchema } from 'utils/schemas/contact';

export interface IGetAttendanceTableDataParams extends IAnalyticsDateParams {
  page: number;
  per: number;
  entityId?: ItemId | null;
  entity?: string;
  query_name?: string;
}

export interface IExportAttendanceTableDataParams extends IAnalyticsDateParams {
  entityId?: ItemId | null;
  entity?: string;
  query_name?: string;
}

export type IUserAgent = {
  os: string;
  os_version: string;
  browser: string;
  browser_version: string;
  device: string;
};

export interface IAttendanceTableItem {
  user: IContact;
  organization: {
    id: string;
    name: string;
  };
  country?: string;
  duration: number; // in seconds
  last_occured_at: string;
  user_agent: IUserAgent;
  responsible_user: {
    id: string;
    uuid: string;
    email: string;
    full_name: string;
  };
}

export interface IAttendanceTableData {
  data: IAttendanceTableItem[];
  page: number;
  per: number;
  total_count: number;
  total_pages: number;
}

async function fetchExtraUserInfo(contactsWithIds: string[]): Promise<{ [id: string]: IContact }> {
  if (!contactsWithIds?.length) {
    return {};
  }
  // @todo replace with a search query to not fail when a contact is deleted / or doesn't exist anymore in the system
  const contacts = await getContacts({ contactIds: contactsWithIds, schema: analyticsContactSchema });
  if (!contacts) {
    return {};
  }
  return byId(contacts);
}

export function getAttendanceTableData(params: IGetAttendanceTableDataParams): Promise<IAttendanceTableData> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { entity, entityId, from_date, to_date, page, per, query_name } = params;

    const queryParams = {
      [`${entity}_ids`]: Array.isArray(entityId) ? entityId : [entityId],
      query_name,
      from_date,
      to_date,
      page,
      per,
    };

    const {
      object: { data },
      pagination,
    } = await action(Model.ANALYTICS, 'member.show', {
      params: omitNilValues(queryParams),
      schema: 'data',
    });
    const { total_count = 0 } = pagination || {};

    const contactsWithIds = data?.reduce((acc, { user }) => (user.id !== '0' ? [...acc, user.id] : acc), []);
    const idToUserMap = fetchExtraUserInfo(contactsWithIds);

    return {
      data: data?.length
        ? data.map((item) => {
            if (item.id !== '0' && idToUserMap[item.id]) {
              return Object.assign({}, item, { user: { ...item.user, ...idToUserMap[item.id] } });
            } else {
              const first_name = item.user.full_name.trim().split(' ')[0];
              const last_name = item.user.full_name.substring(first_name.length).trim();
              return Object.assign({}, item, {
                user: { ...item.user, first_name, last_name, role: item.user.role_name },
              });
            }
          })
        : [],
      page,
      per,
      total_count,
      total_pages: Math.ceil(total_count / per),
    };
  });
}

export function exportAttendanceTableData(params: IExportAttendanceTableDataParams): Promise<string[]> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { entity, entityId, from_date, to_date, query_name } = params;
    const queryParams = {
      [`${entity}_ids`]: entityId,
      query_name: `${query_name}_export`,
      from_date,
      to_date,
    };

    const {
      object: { data },
    } = await action(Model.ANALYTICS, 'export', {
      body: omitNilValues(queryParams),
    });

    return data;
  });
}
