import { IChipmunk } from '@mediafellows/chipmunk';

import { Model } from 'helpers/filters/types';
import { ItemId, IWorkflow } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { WorkflowSchema } from 'utils/schemas/workflow';

export const restart = (id: ItemId): Promise<IWorkflow> => {
  return chipmunk.run(({ action }) => {
    return action(Model.WORKFLOW, 'member.restart', {
      params: {
        id,
      },
    });
  });
};

export const createWorkflow = (body: Record<string, unknown>): Promise<IWorkflow> => {
  return chipmunk.run(({ action }) => {
    return action(Model.WORKFLOW, 'create', {
      body,
    });
  });
};

export const markResolved = (id: ItemId): Promise<IWorkflow> => {
  return chipmunk.run(({ action }) => {
    return action(Model.WORKFLOW, 'member.mark_as_resolved', {
      params: {
        id,
      },
    });
  });
};

export async function getWorkflows(asset_id?: ItemId): Promise<IWorkflow[]> {
  if (!asset_id) {
    return [];
  }

  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { objects } = await action<IWorkflow>(Model.WORKFLOW, 'search', {
      body: {
        filters: { asset_id },
      },
      schema: WorkflowSchema,
    });

    return objects;
  });
}
