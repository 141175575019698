import { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { deleteCastCrew } from 'utils/apis/cast-crew';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';
import { Pages } from 'utils/actions/types';
import { Routes } from 'utils/routes';
import { Intent } from 'utils/ui';

import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { shouldDisplayOnEntityPage } from './acl';
import { useNavigate } from 'react-router';

export const useDeleteAction = (
  items: PmGenericCastCrew[],
  options: IUseActionsOptionParam<PmGenericCastCrew>,
): ICastCrewActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const navigate = useNavigate();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Member', items.length);

  const onConfirm = useCallback(async () => {
    try {
      await deleteCastCrew(items?.reduce((acc, e) => (e.id ? [...acc, e.id] : acc), []));
      toastStore.success(`${entityWithCount} were deleted`);
      await options?.onSuccess?.(ICastCrewActionName.DELETE);
      if (options.page === Pages.DETAILS) {
        navigate(Routes.CAST_CREW);
      }
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, toastStore, entityWithCount, options, navigate, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm,
    });
  }, [dialogStore, onConfirm, entityWithDemonstrative]);

  return {
    name: ICastCrewActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    shouldDisplay: shouldDisplayOnEntityPage,
    handler,
  };
};
