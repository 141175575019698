import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Popover } from '@mantine/core';

import { DivisionsInfo, placeholder } from 'utils/ui';

export const ContactDivisions: React.FC<{ division_ids?: number[] | null }> = ({ division_ids }) => {
  const [opened, { close, open }] = useDisclosure(false);

  if (!Boolean(division_ids?.length)) return placeholder(division_ids);
  return (
    <Popover withArrow opened={opened}>
      <Popover.Target>
        <div onMouseEnter={open} onMouseLeave={close} className="text-truncate">
          <DivisionsInfo division_ids={division_ids} expanded={false} />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="p-2 overflow-y-hidden">
          <DivisionsInfo division_ids={division_ids} />
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
