import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Plus } from 'blueprint5-icons';
import { Button, Tabs } from '@mantine/core';

import { ImagesView, ImagesOrganizationEdit } from 'components/images-list';
import { flags } from 'utils/flags';
import { DetailsPageTabs, IOrganization, IIdentifiable } from 'types';
import { WithOverview } from 'components/layout';
import { DataSectionControls } from 'components/data-section-controls';
import { formatDetailPageTabName } from 'utils/general';

import {
  emptyOrganizationValues,
  additionalContextConfig,
  omitAddressesCountry,
  editableTabs,
  OrganizationDetailTab,
  OrganizationTabPanel,
} from './utils';
import OrganizationDetailsHeader from './organization-details-header';
import { OrganizationTimelineTab } from './organization-timeline-tab';
import { OrganizationOverviewTab, OrganizationEditTab } from './organization-overview-tab';
import { OrganizationFinanceTab } from './organization-finance-tab';
import { OrganizationDetailsOverviewSidebar } from './organization-overview-tab/organization-overview-sidebar';
import OrganizationContactsTab from './organization-contacts-tab/organization-contacts-tab';
import OrganizationAccessPrivilegeTab from './organization-access-tab/organization-access-tab';
import { OrganizationConnectionsTab } from './organzation-connections-tab/organization-contacts-tab';
import OrganizationDeliveryTab from './organization-delivery-tab/organization-delivery-tab';
import OrganizationDeliveryInformationEditTab from './organization-delivery-information-tab/organization-delivery-information-edit-tab';
import OrganizationDeliveryInformationTab from './organization-delivery-information-tab/organization-delivery-information-tab';
import { Model } from 'helpers/filters/types';
import { customContext } from 'helpers/form/fields/form-address';
import { useForm } from 'helpers/form';
import { IOrganizationFields } from 'pages/upsert-organization/types';
import { saveOrganization } from './api';
import { useStore } from 'store';
import { UpsertContactForm } from 'pages/upsert-contact';
import { IModalSize } from 'components/dialogs/types';
import { deleteUnreferencedAttachments } from 'utils/apis/attachment';
import { MantineIcon } from 'utils/ui/icon';
import { useEditMode } from 'utils/hooks';
import { parseObjectToKeyPath, parseKeyPathToObject } from 'utils/payload';

const thumbnailClassname = 'thumbnail--large thumbnail--positioned-right';

const OrganizationDetailsLoaded: React.FC<{
  organization: IOrganization & IIdentifiable;
  refresh: () => Promise<void>;
  activeTab: DetailsPageTabs;
  setActiveTab: (tab: DetailsPageTabs, addEditParam?: boolean | undefined) => void;
}> = observer(({ organization, refresh, activeTab, setActiveTab }) => {
  const {
    dialogStore: { openModal },
  } = useStore();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const initialValues = parseObjectToKeyPath<IOrganization, IOrganizationFields>(
    {
      ...emptyOrganizationValues,
      // remove organization.addresses[N].country fields as it's marked as required
      // in the context, but we handle the country_id field instead of country
      ...omitAddressesCountry(organization),
    },
    ['country'],
  );
  const { toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IOrganizationFields): Promise<void> => {
      if (!values?.id) {
        return;
      }

      try {
        toastStore.info('Saving');
        const payload = parseKeyPathToObject<IOrganizationFields, IOrganization>(values);
        const { object } = await saveOrganization(values.id, payload);
        await deleteUnreferencedAttachments(organization, object, ['logo', 'banner']);

        toastStore.clearAll();
        toastStore.success('Saved', 1000);
        await refresh();
        toastStore.success('Changes applied successfully!');
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(`Error while saving: ${e?.object?.description || 'Unknown error'}`);
      } finally {
        setEditModeEnabled(false);
      }
    },
    [organization, refresh, setEditModeEnabled, toastStore],
  );

  const orgForm = useForm<IOrganizationFields>(
    initialValues,
    Model.ORGANIZATIONS,
    handleSubmit,
    customContext,
    additionalContextConfig,
  );

  const enableEditMode = (isEnabled: boolean): void => {
    if (!editableTabs.includes(activeTab)) {
      setActiveTab(editableTabs[0], true);
      setEditModeEnabled(isEnabled, false);
      return;
    }
    setEditModeEnabled(isEnabled);
  };

  const overviewTab = useMemo(() => {
    if (editModeEnabled) {
      return <OrganizationEditTab {...orgForm} />;
    }
    return <OrganizationOverviewTab organization={organization} />;
  }, [editModeEnabled, organization, orgForm]);

  const imagesTab = useMemo(() => {
    if (!organization) {
      return <></>;
    }
    const images = [
      { label: 'Logo', image: organization.logo },
      { label: 'Banner', image: organization.banner },
    ];

    if (!editModeEnabled) {
      return <ImagesView images={images} className={thumbnailClassname} />;
    }

    return <ImagesOrganizationEdit {...orgForm} className={thumbnailClassname} />;
  }, [editModeEnabled, orgForm, organization]);

  const delieryInformationTab = useMemo(() => {
    if (!organization) {
      return <></>;
    }

    if (!editModeEnabled) {
      return <OrganizationDeliveryInformationTab deliveryInformations={organization?.delivery_informations || []} />;
    }

    return <OrganizationDeliveryInformationEditTab {...orgForm} />;
  }, [editModeEnabled, orgForm, organization]);

  const handleTabChange = useCallback(
    (tab): void => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );
  const openCreateContactDialog = useCallback(() => {
    return openModal({
      size: IModalSize.XL,
      title: 'Create New Contact',
      fitInView: true,
      body: () => <UpsertContactForm organizationId={organization.id} modalView={true} />,
    });
  }, [organization.id, openModal]);

  return (
    <div className="entity-detail">
      <OrganizationDetailsHeader
        organization={organization}
        refresh={refresh}
        organizationForm={orgForm}
        editModeEnabled={editModeEnabled}
        setEditModeEnabled={enableEditMode}
      />
      <div className="entity-detail">
        <div className="entity-detail__content-wrapper flex-fill">
          <div className="entity-detail__content h-100">
            <Tabs className="entity-detail__tabs h-100" value={activeTab} onChange={handleTabChange}>
              <Tabs.List className="d-flex flex-nowrap">
                <OrganizationDetailTab
                  value={DetailsPageTabs.OVERVIEW}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.FINANCIAL}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.CONTACTS}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.IMAGES}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.TIMELINE}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.ACCESS}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.CONNECTIONS}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                  hidden={!flags.showDeliveryFeature}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.DELIVERIES}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                  hidden={!flags.showDeliveryFeature}
                />
                <OrganizationDetailTab
                  value={DetailsPageTabs.DELIVERY_INFORMATION}
                  activeTab={activeTab}
                  editModeEnabled={editModeEnabled}
                  hidden={!flags.showDeliveryFeature}
                  valueParser={formatDetailPageTabName}
                />

                <Button
                  size="xs"
                  hidden={activeTab !== DetailsPageTabs.CONTACTS}
                  leftSection={<MantineIcon icon={<Plus />} />}
                  variant="primary"
                  onClick={openCreateContactDialog}
                  className="ms-auto me-4 align-self-center"
                >
                  Contact
                </Button>

                <DataSectionControls
                  className="ms-auto me-4 align-self-center"
                  hidden={activeTab !== DetailsPageTabs.ACCESS}
                />
              </Tabs.List>
              <OrganizationTabPanel
                value={DetailsPageTabs.OVERVIEW}
                content={
                  <WithOverview overview={<OrganizationDetailsOverviewSidebar organization={organization} />}>
                    {overviewTab}
                  </WithOverview>
                }
              />
              <OrganizationTabPanel
                value={DetailsPageTabs.FINANCIAL}
                content={<OrganizationFinanceTab organization={organization} />}
              />
              <OrganizationTabPanel value={DetailsPageTabs.CONTACTS} content={<OrganizationContactsTab />} />
              <OrganizationTabPanel value={DetailsPageTabs.IMAGES} content={imagesTab} />
              <OrganizationTabPanel value={DetailsPageTabs.TIMELINE} content={<OrganizationTimelineTab />} />
              <OrganizationTabPanel
                value={DetailsPageTabs.ACCESS}
                content={<OrganizationAccessPrivilegeTab organization={organization} />}
              />
              <OrganizationTabPanel value={DetailsPageTabs.CONNECTIONS} content={<OrganizationConnectionsTab />} />
              <OrganizationTabPanel value={DetailsPageTabs.DELIVERIES} content={<OrganizationDeliveryTab />} />
              <OrganizationTabPanel value={DetailsPageTabs.DELIVERY_INFORMATION} content={delieryInformationTab} />
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OrganizationDetailsLoaded;
