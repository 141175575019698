import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import ScrollWrapper from 'components/scroll-wrapper';
import { LoaderWithText, Thumbnail } from 'components/thumbnail';

import { isProcessingPreview } from 'utils/general';
import { IAffiliation } from 'types';

import './style.scss';

const AffiliationGeneralTab: React.FC<{ affiliation?: IAffiliation }> = ({ affiliation }) => {
  const {
    id,
    brand_header,
    public_access,
    primary_seller_domain,
    primary_buyer_domain,
    password_expiration_interval,
    logo,
    logo_id,
  } = affiliation || {};
  return (
    <ScrollWrapper>
      <InfoBoxV2 wrapperClassName="py-3" gridTemplateColumns="1fr 1fr" padded={false}>
        <InfoBoxV2 gridTemplateColumns="2fr 3fr" wrapperClassName="pb-3" className="text-no-transform ">
          <Row label="Id" value={id} />
          <Row label="Public Access" value={yn(public_access)} />
          <Row label="Primary Seller Domain" value={primary_seller_domain?.name} />
          <Row
            className="affiliation-image__row"
            label="Logo"
            value={
              <Thumbnail
                image={logo?.url}
                placeholder={
                  isProcessingPreview({ preview_image: logo, preview_image_id: logo_id }) && (
                    <LoaderWithText text="processing" />
                  )
                }
              />
            }
          />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="2fr 3fr" wrapperClassName="pb-3" className="text-no-transform ">
          <Row label="Brand header" value={brand_header} />
          <Row label="Password Expiration (Days)" rawLabel value={password_expiration_interval} />
          <Row label="Primary Buyer Domain" value={primary_buyer_domain?.name} />
        </InfoBoxV2>
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationGeneralTab;
