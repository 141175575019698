import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useSortDataSectionStore } from 'store';
import { SortDataSection } from 'components/sort-data-section';
import { fetchSubEvents, saveOrder } from './utils';
import { IMarketingEvent } from 'types';
import { EventDataSectionListItem } from 'components/event-data-section-item/event-data-section-list-item';
import { ISortableItem } from 'store/sort-data-store';

export function productItemRenderer(event: ISortableItem): JSX.Element {
  if (!event?.id) {
    return <></>;
  }

  return <EventDataSectionListItem isCheckedDisabled event={event as IMarketingEvent} isChecked={false} />;
}

export const EventSubEventsSortTab: React.FC<{
  setEvent: React.Dispatch<React.SetStateAction<IMarketingEvent>>;
  event: IMarketingEvent;
}> = observer(({ event, setEvent }) => {
  const { initStore } = useSortDataSectionStore<IMarketingEvent>();
  const fetcher = useCallback(() => fetchSubEvents(event.subevent_group_ids), [event.subevent_group_ids]);

  const onSave = useCallback(
    async (list) => {
      if (!event?.id) {
        return;
      }
      const updatedEvent = await saveOrder(
        event.id,
        list.map((e) => e.id),
      );
      setEvent((e: IMarketingEvent): IMarketingEvent => ({ ...e, ...(updatedEvent || {}) }));
    },
    [event.id, setEvent],
  );

  useEffect(() => {
    initStore({ fetcher, onSave });
  }, [fetcher, initStore, onSave]);

  return <SortDataSection itemRenderer={productItemRenderer} />;
});

export default EventSubEventsSortTab;
