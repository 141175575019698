import { Model } from 'helpers/filters/types';
import { IOrganization, IIdentifiable, ItemId } from 'types';
import { chipmunk, IResult } from 'utils/chipmunk';
import { organizationDetailsSchema } from 'utils/schemas';
import { parseTags } from 'utils/tags';

export const saveOrganization = async (
  organizationId: ItemId,
  organization: IOrganization,
): Promise<IResult<IOrganization>> => {
  return await chipmunk.run(async ({ action }) => {
    const tags = parseTags(organization.tags);

    return action(Model.ORGANIZATIONS, 'member.update', {
      ROR: true,
      params: { organization_ids: organizationId },
      body: { ...organization, tags },
    });
  });
};

export const loadOrganization = async (
  organizationId: string,
): Promise<(IOrganization & IIdentifiable) | undefined> => {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.ORGANIZATIONS, 'get', {
      params: {
        organization_ids: organizationId,
      },
      schema: organizationDetailsSchema,
    });

    return object;
  });
};

export const loadOrganizationDeliveryInfos = async (
  organizationId: string,
): Promise<(IOrganization & IIdentifiable) | undefined> => {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.ORGANIZATIONS, 'get', {
      params: {
        organization_ids: organizationId,
      },
      schema: `id, delivery_informations`,
    });

    return object;
  });
};
