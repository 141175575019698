import React, { useMemo } from 'react';

import { EmptySectionMessage } from 'components/section-message';
import { ProductViewsListTable } from 'components/product-views-list-table';
import { VideoScreeningsListTable } from 'components/video-screenings-list-table';
import { ContactLoginColumns, LoginListTable } from 'components/login-list-table';
import {
  AssetInteractions,
  ContactInteractions,
  ProductInteractions,
  RecommendationAnalyticsListTable,
  sectionToEntity,
} from 'components/analytics';
import * as viewsColumns from './product-views-columns';
import * as screeningsColumns from './asset-screenings-columns';

import { productPageViews } from 'components/analytics/queries/product-queries';
import { videoQueries } from 'components/analytics/queries/asset-queries';
import { logins as contactLogins, recommendationsReceived } from 'components/analytics/queries/contact-queries';

import { IAnalyticsSectionTabProps } from './types';
import { RecommendationInteractions } from 'components/analytics/utils';
import { recommendationDetailsColumns } from 'components/analytics/contact-analytics/recommendation-analytics/analytics-interaction-recommendations-received/recommendation-details-columns';

interface IAnalyticsDetailedStatsProps extends IAnalyticsSectionTabProps {
  interaction?: string;
}

const AnalyticsDetailedStatsTab: React.FC<IAnalyticsDetailedStatsProps> = ({
  section,
  dateRange,
  productIds,
  userIds,
  recipientIds,
  senderIds,
  geoScopeIds,
  includeDescendants,
  interaction,
  assetIds,
  filtersComponent,
}) => {
  const entityId = useMemo(
    () => ({
      product: productIds,
      user: userIds,
      asset: assetIds,
    }),
    [assetIds, productIds, userIds],
  );
  const entity = sectionToEntity[section];

  const Component = useMemo((): JSX.Element => {
    switch (interaction) {
      case ContactInteractions.VIEWS:
      case ProductInteractions.VIEWS:
        return (
          <ProductViewsListTable
            title="Detailed Product Views"
            queryName={productPageViews.productList}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={geoScopeIds}
            userIds={userIds}
            includeDescendants={includeDescendants}
            entity={entity}
            entityId={entityId[entity]}
            productIds={productIds}
            columns={viewsColumns[`${entity}ViewsColumns`]}
          />
        );
      case ContactInteractions.SCREENINGS:
      case AssetInteractions.SCREENINGS:
      case ProductInteractions.SCREENINGS:
        return (
          <VideoScreeningsListTable
            title="Detailed Video Screenings"
            queryName={videoQueries.screeningList}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={geoScopeIds}
            userIds={userIds}
            assetIds={assetIds}
            includeDescendants={includeDescendants}
            entity={entity}
            entityId={entityId[entity]}
            columns={screeningsColumns[`${entity}ScreeningsColumns`]}
          />
        );
      case ContactInteractions.LOGINS:
        return (
          <LoginListTable
            title="Detailed Logins"
            queryName={contactLogins.loginList}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={geoScopeIds}
            productIds={productIds}
            includeDescendants={includeDescendants}
            entity={entity}
            entityId={userIds}
            columns={ContactLoginColumns}
          />
        );
      case RecommendationInteractions.RECOMMENDATIONS_DETAILS:
      case ContactInteractions.RECOMMENDATIONS_DETAILS:
        return (
          <RecommendationAnalyticsListTable
            title={RecommendationInteractions.RECOMMENDATIONS_DETAILS}
            queryName={recommendationsReceived.recommendationReceivedList}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={geoScopeIds}
            productIds={productIds}
            includeDescendants={includeDescendants}
            recipientIds={recipientIds}
            senderIds={senderIds}
            columns={recommendationDetailsColumns}
          />
        );
      default:
        return <EmptySectionMessage />;
    }
  }, [
    assetIds,
    dateRange,
    entity,
    entityId,
    geoScopeIds,
    includeDescendants,
    recipientIds,
    senderIds,
    interaction,
    productIds,
    userIds,
  ]);
  if (!section) return <EmptySectionMessage />;
  return (
    <div className="analytics-tab detailed-analytics-tab">
      {filtersComponent}
      {Component}
    </div>
  );
};

export default AnalyticsDetailedStatsTab;
