import React, { useCallback } from 'react';

import { IContact, IStyled, ItemId } from 'types';
import { FormRemoteSelect, IFormRemoteSelectProps } from 'helpers/form';
import { queryContacts } from 'utils/apis/contacts';

export interface IFormContacts
  extends Partial<
      Exclude<
        IFormRemoteSelectProps,
        'name' | 'label' | 'large' | 'inline' | 'disabled' | 'placeholder' | 'showPositiveValidation' | 'ids'
      >
    >,
    IStyled {
  name: string;
  label: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPositiveValidation?: boolean;
  ids?: ItemId[] | null;
}

export const FormContacts: React.FC<IFormContacts> = ({ name, label, ids, ...rest }) => {
  const fetchContacts = useCallback<(query: string, id?: ItemId) => Promise<IContact[]>>(
    async (query, id) => {
      const params: { q?: string; ids?: ItemId[] } = { q: query };
      if (ids?.length) {
        params.ids = ids;
      }
      if (id) {
        params.ids = [id];
      }
      return queryContacts(params);
    },
    [ids],
  );

  return <FormRemoteSelect name={name} label={label} fetchOptions={fetchContacts} {...rest} />;
};
