import React from 'react';
import { Button } from '@mantine/core';
import { Intent } from 'utils/ui';
import cx from 'classnames';

import { ItemId, IScheduledJob } from 'types';
import { formatDate } from 'utils/date';

import './style.scss';

export const ScheduledJobList = ({
  jobs,
  onRemove,
  renderDetails,
  readOnly = false,
}: {
  readOnly?: boolean;
  jobs: IScheduledJob[];
  onRemove: (id: ItemId) => void;
  renderDetails: (job: IScheduledJob) => JSX.Element | string;
}): JSX.Element => {
  return (
    <>
      {jobs.map((job) => (
        <div key={job.id} className={cx('scheduled-job-container', { 'scheduled-job-container__read-only': readOnly })}>
          <div>{formatDate(job.scheduled_for)}</div>
          <div>{renderDetails(job)}</div>
          {!readOnly && (
            <Button size={'xs'} variant={Intent.DANGER} onClick={() => onRemove(job.id)}>
              Delete
            </Button>
          )}
        </div>
      ))}
    </>
  );
};
