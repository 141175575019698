import React from 'react';
import { IAnalyticsListProps } from 'types';

import { ListTable } from 'components/list-table';
import { getDetailedAnalyticsTableData, exportAnalyticsTableData } from 'utils/apis/analytics';
import { contactDetailPageColumns } from './columns';

const LoginListTable: React.FC<IAnalyticsListProps> = ({ title, entity, entityId, dateRange, queryName, columns }) => {
  const columnsToApply = columns ?? contactDetailPageColumns;

  return (
    <ListTable
      title={title}
      entity={entity}
      entityId={entityId}
      from_date={dateRange[0]}
      to_date={dateRange[1]}
      query_name={queryName}
      columns={columnsToApply}
      getTableData={getDetailedAnalyticsTableData}
      exportTableData={exportAnalyticsTableData}
    />
  );
};

export default LoginListTable;
