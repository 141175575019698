import { merge } from 'lodash';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';

import { IFormMultiSelectOption } from 'helpers/form';
import { IValidationResult, getRequiredError } from 'helpers/form/mm3';

import type { ICreateWorkFlowPayload } from 'utils/actions/types';
import { ISelection } from 'types';

interface IParcedAssets {
  assetList: string[];
  asset_selections: ISelection[];
  asset_ids: number[];
}

export const parseAssets = (options: IFormMultiSelectOption[]): IParcedAssets => {
  return options.reduce(
    (acc: IParcedAssets, item) => {
      const { value } = item;
      if (value && item.itemType === 'group') {
        return {
          ...acc,
          assetList: [value.toString(), ...acc.assetList],
          asset_selections: [item as ISelection, ...acc.asset_selections],
        };
      }
      return {
        ...acc,
        assetList: [value.toString(), ...acc.assetList],
        asset_ids: [Number(value), ...acc.asset_ids],
      };
    },
    { assetList: [], asset_selections: [], asset_ids: [] },
  );
};

export const customValidator = (
  values: ICreateWorkFlowPayload<McGenericRecommendationAsset>,
  validation: IValidationResult<ICreateWorkFlowPayload<McGenericRecommendationAsset>>,
): IValidationResult<ICreateWorkFlowPayload<McGenericRecommendationAsset>> => {
  const recipientsValidation = !values?.recipients.length
    ? {
        recipients: { required: true, ...getRequiredError() },
      }
    : { recipients: { required: true } };

  const assetsValidation = !values?.assetList.length
    ? {
        assetList: { required: true, ...getRequiredError() },
      }
    : { assetList: { required: true } };
  if (!values?.recipients.length) {
    return merge({}, validation, recipientsValidation, assetsValidation) as IValidationResult<
      ICreateWorkFlowPayload<McGenericRecommendationAsset>
    >;
  }

  return validation;
};
