import React, { useMemo } from 'react';
import { Tabs } from '@mantine/core';

import { AccessPrivilegesDataSection } from 'components/access-privileges-data-section';
import { RecommendationDataSection } from 'components/recommendation';
import { EmptySectionMessage } from 'components/section-message';
import { Loading } from 'components/loading';
import { Tab, TabPanel } from 'components/tabs';

import { formatDetailPageTabName } from 'utils/general';
import { IFilterOption, useRemote } from 'utils/hooks';

import { ItemId, ProductAccessTabSubTabs } from 'types';

import './style.scss';

export const AccessTab: React.FC<{
  getGroupIds: () => Promise<ItemId[]>;
}> = ({ getGroupIds }) => {
  const [groupIds = [], loading] = useRemote(getGroupIds, []);

  const accessPrivilegeIdsFilter = useMemo<IFilterOption[]>(() => [['id', 'in', groupIds]], [groupIds]);

  const recommendationIdsFilter = useMemo<IFilterOption[]>(
    () => [['recommendation_group_id', 'in', groupIds]],
    [groupIds],
  );

  if (loading) {
    return <Loading text="Loading Access groups..." />;
  }

  if (!groupIds?.length) {
    return <EmptySectionMessage />;
  }

  return (
    <Tabs className="access__tabs h-100 w-100" defaultValue={ProductAccessTabSubTabs.ACCESS_PRIVILEGE}>
      <Tabs.List>
        <Tab
          value={ProductAccessTabSubTabs.ACCESS_PRIVILEGE}
          className="access__tab h-100"
          valueParser={formatDetailPageTabName}
        />
        <Tab
          value={ProductAccessTabSubTabs.RECOMMENDATION}
          className="access__tab h-100"
          valueParser={formatDetailPageTabName}
        />
      </Tabs.List>

      <TabPanel
        value={ProductAccessTabSubTabs.ACCESS_PRIVILEGE}
        className="access__tab-content h-100"
        content={<AccessPrivilegesDataSection persistentFilters={accessPrivilegeIdsFilter} />}
      />

      <TabPanel
        value={ProductAccessTabSubTabs.RECOMMENDATION}
        className="access__tab-content h-100"
        content={<RecommendationDataSection persistentFilters={recommendationIdsFilter} />}
      />
    </Tabs>
  );
};
