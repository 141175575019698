import React, { useCallback } from 'react';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseAction } from 'utils/actions';
import { RecommendationExpiryForm } from 'components/recommendation';

import { IRecommendationActionName } from './types';
import { isRecommendationDraftAndSingleContext as shouldDisplay } from './actions-acl';

export const useEditExpiryAction: IUseAction<
  McGenericRecommendationProduct | McGenericRecommendationAsset,
  IRecommendationActionName
> = (items, options) => {
  const { dialogStore } = useStore();

  const handler = useCallback(() => {
    dialogStore.openModal({
      title: 'Edit Expiry',
      body: () => <RecommendationExpiryForm recommendation={items[0]} options={options} />,
    });
  }, [dialogStore, items, options]);

  return {
    name: IRecommendationActionName.EDIT_EXPIRY,
    shouldDisplay,
    icon: 'timeline-events',
    title: 'Edit Expiry',
    handler,
  };
};
