import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormCategories, FormDate, FormHtmlInput, FormInput, FormNumericInput } from 'helpers/form';
import { Product3Types } from 'types';
import { IProductEditFormProps, maxDate, minDate } from './utils';

const seqNumberProducts = new Set<string>([Product3Types.SERIES, Product3Types.FORMAT]);

export const ProductEditAboutSection: React.FC<IProductEditFormProps> = observer(({ layerForm, productForm }) => {
  const { formData, handlers } = layerForm;

  return (
    <FormSectionWrapperV2 className="product-create_html-editors-container" title="About">
      <>
        {Boolean(productForm.values.id) && (
          <FormInput className="title" name="title" key="title" label="Title" {...formData.title} {...handlers} />
        )}
      </>

      <FormCategories
        name="meta.category_ids"
        key="category_ids"
        label="Genres"
        {...formData.meta?.category_ids}
        {...handlers}
        className="categories"
        large
      />

      <>
        {seqNumberProducts.has(productForm?.values?.type) && (
          <FormNumericInput
            name="meta.number_of_seasons_manual"
            key="number_of_seasons_manual"
            label="Number of Seasons (Manual)"
            {...formData.meta?.number_of_seasons_manual}
            {...handlers}
            className="number-of-seasons"
          />
        )}
      </>

      <FormInput
        className="logline"
        name="meta.logline"
        key="logline"
        label="Logline"
        type="textarea"
        {...formData.meta?.logline}
        {...handlers}
      />

      <FormHtmlInput
        className="synopsis"
        name="meta.synopsis"
        key="synopsis"
        label="Synopsis"
        {...formData.meta?.synopsis}
        {...handlers}
        large
      />

      <>
        {Boolean(productForm.values.id) && (
          <FormInput
            className="slug"
            name="meta.slug"
            key="slug"
            label="Slug"
            {...productForm.formData.meta?.slug}
            {...productForm.handlers}
          />
        )}
      </>

      <FormNumericInput
        name="meta.duration"
        key="duration"
        label="Duration (Minutes)"
        max={60 * 24 * 365}
        {...formData.meta?.duration}
        {...handlers}
      />

      <FormInput
        name="meta.duration_text"
        key="duration_text"
        label="Duration (Text)"
        {...formData.meta?.duration_text}
        {...handlers}
      />
      <FormDate
        name="meta.original_release_date"
        key="original_release_date"
        label="Original Release Date"
        maxDate={maxDate}
        minDate={minDate}
        {...formData.meta?.original_release_date}
        {...handlers}
        large
        emptyValue={null}
      />
      <FormDate
        name="meta.tx_date"
        key="tx_date"
        label="TX Date"
        maxDate={maxDate}
        minDate={minDate}
        {...formData.meta?.tx_date}
        {...handlers}
        large
        emptyValue={null}
      />

      <FormInput
        name="meta.tx_date_text"
        key="tx_date_text"
        label="TX Date (Text)"
        {...formData.meta?.tx_date_text}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
});

export default ProductEditAboutSection;
