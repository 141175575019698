import React from 'react';
import { RouteProps, Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { useSessionStore } from 'store/session-store';
import { Layout } from 'components/layout';
import { persistRedirectUrl } from 'utils/storage';
import { hideSideBarFlags } from 'utils/flags';
import { powersByRoute } from 'components/sidebar/utils';
import { hasPower } from 'utils/powers';

export const PrivateRoutes: React.FC<RouteProps> = () => {
  const session = useSessionStore((state) => state.session);
  const is2FARedirect = session?.mfa_check_required;
  const location = useLocation();

  const pathParts = location.pathname.split('/');
  const mainPath = `/${pathParts[1]}`;
  // apply parent powers to children routes
  const allowedPowersForRoute = pathParts.length > 2 ? powersByRoute[mainPath] : powersByRoute[location.pathname];
  const userHasPowers = allowedPowersForRoute?.every(hasPower);

  // by feature flags or powers
  const hideRoute = hideSideBarFlags[location.pathname] || (allowedPowersForRoute && !userHasPowers);

  if (is2FARedirect) {
    persistRedirectUrl();
    return <Navigate to={Routes.TWO_FACTOR_AUTH} state={{ from: location }} />;
  }

  if (!session && location.pathname !== Routes.LOGIN) {
    persistRedirectUrl();
    return <Navigate to={Routes.LOGIN} state={{ from: location }} />;
  }
  if (hideRoute) return <Navigate to={Routes.DASHBOARD} state={{ from: location }} />;
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
