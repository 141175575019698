import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from 'utils/routes';
import { Menu, Tooltip } from '@mantine/core';
import { CheckPowers } from 'components/check-power';
import { hideSideBarFlags } from 'utils/flags';
import { MantineIcon } from 'utils/ui/icon';
import { IconName } from 'blueprint5-icons';

export interface IMenuItemProps {
  route: Routes;
  text: string;
  iconName: IconName;
  requiredPowers?: string | string[];
}

const SidebarMenuItem = ({ route, text, iconName, requiredPowers }: IMenuItemProps): JSX.Element => {
  return (
    <CheckPowers requiredPowers={requiredPowers} hideAnyway={hideSideBarFlags[route]}>
      <div className="sidebar__mode--default">
        <NavLink to={route} className="sidebar__navlink">
          <Menu.Item leftSection={<MantineIcon icon={iconName} />} className="sidebar__item">
            {text}
          </Menu.Item>
        </NavLink>
      </div>
      <div className="sidebar__mode--icons">
        <Tooltip className="me-2" label={text} position="right" withinPortal>
          <NavLink to={route} className="sidebar__item">
            <MantineIcon className="sidebar__icon" icon={iconName} />
          </NavLink>
        </Tooltip>
      </div>
    </CheckPowers>
  );
};

export default SidebarMenuItem;
