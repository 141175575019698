import React, { useCallback, useState } from 'react';

import { useStore } from 'store';
import { FormSubmitSection } from 'components/form-submit-section';
import { SubtitleFileUpload, allowedSubtitlesExtensions } from 'components/asset/subtitle-form';

import { IFile } from 'types';
import { useUppyUpload } from 'utils/hooks';
import { IUploadType } from 'components/upload-preview-image';

export interface IReplaceSubtitleProps {
  onSubmit: (file?: IFile | null) => void;
  type?: IUploadType;
  message?: string;
}

const ReplaceSubtitleDialogBody: React.FC<IReplaceSubtitleProps> = ({
  onSubmit,
  type,
  message = 'Select a file to upload',
}) => {
  const { dialogStore, toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const { progress, uploadFinished, dropAreaId, file } = useUppyUpload(type, allowedSubtitlesExtensions);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        e.preventDefault();
        await onSubmit(file.current);
        toastStore.success('Subtitle file replaced!');
        return dialogStore.close();
      } catch (error) {
        toastStore.error(error.text);
        return dialogStore.close();
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, file, toastStore, dialogStore],
  );

  return (
    <form onSubmit={handleSubmit}>
      <SubtitleFileUpload dropAreaId={dropAreaId} progress={progress} message={message} />
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!uploadFinished} loading={isLoading} />
    </form>
  );
};

export default ReplaceSubtitleDialogBody;
