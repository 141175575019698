import React, { useCallback, useState, useMemo } from 'react';
import { uniq, map } from 'lodash';

import CheckboxTree from 'components/checkbox-tree/checkbox-tree';

import { formatFormLabel } from 'helpers/form/fields/helpers';
import { IAssetTreeOption, IOmniTreeNode } from 'helpers/form/fields/form-select';
import { createDataTree, getAllChildrenNodes } from 'helpers/form/fields/form-select/tree-selector-utils';
import { FormGroup } from 'helpers/form/fields/form-group';

import { IOnCheckNode } from 'components/checkbox-tree/types';
import { EmptySectionMessage } from 'components/section-message';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { byId } from 'utils/general';
import { ItemId } from 'types/utility';

import { AssetClassificationFilters } from './asset-classification-filters';
import { parseMainClassificationCount, prepareOmniOptions } from './utils';

import './style.scss';

interface IFormOmniCheckbox extends IFieldData<ItemId[], IAssetTreeOption[]>, IFieldHandlers<ItemId[]> {
  label?: string;
  inline?: boolean;
  name: string;
  className?: string;
  showMetadataHover?: boolean;
}

const FormAssetOmniCheckboxWithFilters: React.FC<IFormOmniCheckbox> = ({
  onBlur,
  onChange,
  label,
  inline = false,
  name,
  options,
  required,
  value,
  className,
  showMetadataHover,
}) => {
  const optionsTree = useMemo<IOmniTreeNode[]>(
    () => parseMainClassificationCount(createDataTree(prepareOmniOptions(options, showMetadataHover)), value),
    [options, value, showMetadataHover],
  );

  const [expanded, setExpanded] = useState<string[]>(map(optionsTree, 'value'));

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (value: ItemId[]): void => {
      onChange?.({ [name]: value });
      handleBlur();
    },
    [name, handleBlur, onChange],
  );

  const handleCheck = useCallback(
    (selectedValues: string[], node?: IOnCheckNode): void => {
      if (!node) {
        return;
      }

      const safeValue = value || [];
      let newValue: ItemId[] = [];

      if (node.isLeaf) {
        const id = node.value.split('-')[2];
        newValue = node.checked ? uniq(safeValue.concat(id)) : safeValue.filter((e) => e !== id);
      } else if (node.isChild) {
        const childrenIds = (node.children || []).reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
        const childrenByIds = byId(node.children || []);
        newValue = node.checked ? uniq(safeValue.concat(childrenIds)) : safeValue.filter((id) => !childrenByIds[id]);
      } else {
        const children = getAllChildrenNodes([node]);
        const childrenIds = children.reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
        const childrenByIds = byId(children);
        newValue = node.checked ? uniq(safeValue.concat(childrenIds)) : safeValue.filter((id) => !childrenByIds[id]);
      }

      handleChange(newValue);
    },
    [handleChange, value],
  );

  const checked = useMemo(() => {
    const valueSet = new Set((value || []).map(String));

    return getAllChildrenNodes(optionsTree).reduce((acc: string[], node: IOmniTreeNode): string[] => {
      if (node.id && valueSet.has(node.id)) {
        acc.push(node.value);
      }
      return acc;
    }, []);
  }, [optionsTree, value]);

  if (!options?.length) return <EmptySectionMessage />;

  return (
    <div className={className}>
      <AssetClassificationFilters {...{ optionsTree, value, handleChange }} />
      <FormGroup
        label={formatFormLabel(label, required)}
        labelFor={name}
        inline={inline}
        className="form-omni-checkbox--container"
      >
        <CheckboxTree
          nodes={optionsTree}
          checked={checked}
          expanded={expanded}
          setExpanded={setExpanded}
          onCheck={handleCheck}
        />
      </FormGroup>
    </div>
  );
};
export { FormAssetOmniCheckboxWithFilters };
