import React from 'react';

import { AnalyticsDataSection } from 'components/analytics-data-section';

import { IAnalyticsSectionTabProps } from './types';

const AnalyticsListTab: React.FC<IAnalyticsSectionTabProps> = ({
  section,
  dateRange,
  filtersComponent,
  geoScopeIds,
  userIds,
  productIds,
  senderIds,
  recipientIds,
  includeDescendants,
}) => {
  return (
    <div className="analytics-tab">
      {filtersComponent}
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <AnalyticsDataSection
            section={section}
            dateRange={dateRange as [Date, Date]}
            geoScopeIds={geoScopeIds}
            userIds={userIds}
            productIds={productIds}
            senderIds={senderIds}
            recipientIds={recipientIds}
            includeDescendants={includeDescendants}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsListTab;
