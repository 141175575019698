import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { Button, HTMLInputProps, InputGroupProps2 } from '@blueprintjs/core';
import { DateInput, TimePickerProps } from '@blueprintjs/datetime';
import { isEmpty } from 'lodash';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers, IFieldValidationResults } from 'helpers/form/types';
import { IDatePickerBaseProps } from '@blueprintjs/datetime/lib/esm/datePickerCore';
import { dateFormat, defaultDateFormat, formatDate, parseDate, parseDate15Minutes } from 'utils/date';
import { isValid, addMinutes } from 'date-fns';
import cx from 'classnames';
import { changeTimeZone } from 'helpers/form/fields/helpers';
import { FormGroup } from 'helpers/form/fields/form-group';
import { Intent } from 'utils/ui';

import './style.scss';

type IFormDateValue = string | null;

export interface IFormDateProps
  extends IDatePickerBaseProps,
    IFieldData<IFormDateValue>,
    IFieldHandlers<IFormDateValue> {
  name: string;
  label: string;
  disabled?: boolean;
  quarters?: boolean;
  placeholder?: string;
  inputProps?: HTMLInputProps & InputGroupProps2;
  className?: string;
  formId?: string;
  large?: boolean;
  withTime?: boolean;
  showActionsBar?: boolean;
  formatOutput?: string;
  emptyValue?: IFormDateValue;
  canClearSelection?: boolean;
  hideTodayButton?: boolean;
  timeZone?: string | null;
}

const getId = ({ formId = 'id', name }: IFormDateProps): string => `${formId}-${name}`;
const parseMaxMin = (date?: Date, timeZone?: string | null): Date | undefined =>
  date && timeZone ? changeTimeZone(date, timeZone) : date;

export const FormDate: React.FC<IFormDateProps> = (props) => {
  const {
    canClearSelection = false,
    className,
    name,
    label,
    quarters,
    disabled,
    placeholder,
    inputProps,
    onChange,
    onBlur,
    validation = {} as IFieldValidationResults,
    touched,
    value,
    required,
    minDate,
    maxDate,
    large = false,
    withTime = false,
    showActionsBar = true,
    hideTodayButton = false,
    emptyValue = '',
    timeZone = '',
  } = props;
  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled,
    defaultPlaceholder: `Select ${label}`,
  });
  const inputEl = useRef<DateInput>(null);
  const formatOutput = withTime ? undefined : dateFormat;
  const showError = touched && !validation?.valid && (!isEmpty(value) || required);
  const intent = showError ? Intent.DANGER : Intent.NONE;
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const min = useMemo(() => parseMaxMin(minDate, timeZone), [minDate, timeZone]);
  const max = useMemo(() => parseMaxMin(maxDate, timeZone), [maxDate, timeZone]);

  const changeHandler = useCallback(
    (selectedDate: Date): void => {
      const selectedDateOrNull = quarters ? parseDate15Minutes(selectedDate, min, max) : selectedDate;

      if (!selectedDateOrNull) {
        setSelectedDate(null);
        onChange?.({ [name]: emptyValue });
        return;
      }

      const formatValue = formatOutput
        ? formatDate(selectedDateOrNull, formatOutput)
        : selectedDateOrNull.toISOString();
      onChange?.({ [name]: formatValue });
    },
    [name, onChange, formatOutput, emptyValue, min, max, quarters],
  );

  const isInitializedRef = useRef<string>('');
  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      return;
    }

    let date = new Date(value);

    if (timeZone && isInitializedRef.current !== timeZone) {
      isInitializedRef.current = timeZone;
      date = changeTimeZone(value, timeZone);
      changeHandler(date);
    }

    const dateUTC = addMinutes(date, date.getTimezoneOffset());
    const newDate = formatOutput ? dateUTC : date;

    if (isValid(newDate)) {
      setSelectedDate(quarters ? parseDate15Minutes(newDate, min, max) : parseDate(newDate, min, max));
    }
  }, [value, timeZone, min, max, formatOutput, quarters, changeHandler]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleDateParse = (str: string): Date | null | false => {
    if (!str) return null;
    const date = new Date(str);
    if (!isValid(date)) return false;
    return quarters ? parseDate15Minutes(new Date(str), min, max) : parseDate(new Date(str), min, max);
  };

  const rightElement = useMemo(
    () => (
      <Button
        disabled={disabled}
        icon="calendar"
        intent={intent}
        minimal
        onClick={() => inputEl.current?.inputElement?.focus()}
      />
    ),
    [intent, disabled],
  );

  const timePickerProps: TimePickerProps | undefined = useMemo(() => {
    if (!withTime) {
      return undefined;
    }

    return { precision: 'minute', showArrowButtons: true };
  }, [withTime]);

  const handleFormatDate = (date): string => {
    const format = withTime ? defaultDateFormat : 'dd MMM yyyy';
    return formatDate(date, format);
  };

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation.errorMessage : ''}
    >
      <DateInput
        className={cx('date-input-wrapper', className, { 'no-today-button': hideTodayButton })}
        fill={true}
        ref={inputEl}
        inputProps={{ ...inputProps, id: getId(props), name, onBlur: handleBlur, large }}
        placeholder={formattedPlaceholder}
        rightElement={rightElement}
        disabled={disabled}
        onChange={changeHandler}
        formatDate={handleFormatDate}
        parseDate={handleDateParse}
        value={selectedDate}
        showActionsBar={showActionsBar}
        minDate={minDate}
        maxDate={maxDate}
        timePickerProps={timePickerProps}
        closeOnSelection={!withTime}
        canClearSelection={canClearSelection}
      />
    </FormGroup>
  );
};

export default FormDate;
