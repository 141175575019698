import React from 'react';

import { FormInput } from 'helpers/form';

import { IRecommendationFormProps } from './types';

export const RecommendProductsStepMessage: React.FC<IRecommendationFormProps> = ({ form: { formData, handlers } }) => {
  return (
    <div className="product-recommendation-form">
      <FormInput name="subject" label="Subject" {...formData.subject} {...handlers} />
      <FormInput
        name="message"
        label="Message"
        className="recommendation-wizard__message-input"
        textarea
        rows={10}
        {...formData.message}
        {...handlers}
      />
    </div>
  );
};
