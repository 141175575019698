import React from 'react';
import { Button } from '@mantine/core';

import { FormDate } from 'helpers/form';
import { FormActionSection } from 'components/form-submit-section';

import { AccessPrivilegeGrantFormSettings } from 'components/access-privilege-grant';
import { IGroupAccessChangeForm } from './types';

interface IGroupAccessChangeFormComponent {
  form: IGroupAccessChangeForm;
  showScheduledFor?: boolean;
}

export const GroupAccessChangeForm: React.FC<IGroupAccessChangeFormComponent> = ({
  form,
  showScheduledFor = false,
}) => {
  return (
    <>
      {showScheduledFor && (
        <>
          <FormDate
            name="scheduled_for"
            label="Scheduled for"
            large
            {...form.formData.scheduled_for}
            {...form.handlers}
          />
          <hr />
        </>
      )}
      <AccessPrivilegeGrantFormSettings form={form} />
      <FormActionSection>
        <Button size="xs" variant="primary" onClick={form.onSubmit}>
          Save
        </Button>
      </FormActionSection>
    </>
  );
};
