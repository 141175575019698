import { IAsset, IMm3Asset } from 'types';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam } from 'utils/actions';

import { useDeliveryDialog } from 'components/delivery';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { getValidIds } from 'utils/general';

import { shouldDisplayDelivery as shouldDisplay } from './actions-acl';
import { IAssetActionName, IAssetActionConfig } from './types';

export const useDeliveryAction = (
  assets: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>,
): IAssetActionConfig => {
  const [handler] = useDeliveryDialog({
    delivery: { asset_ids: getValidIds(assets) } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>,
    options,
    excludeProducts: true,
    itemAction: true,
  });

  return {
    name: IAssetActionName.DELIVER,
    icon: 'cloud-upload',
    title: 'Deliver',
    shouldDisplay,
    handler,
  };
};
