import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Loading } from 'components/loading';
import { Timeline } from 'components/timeline';
import { IGroupEntityIdName, ItemId, ITimelineData } from 'types';

import { fetchTimelineData } from './api';
import { EmptySectionMessage } from 'components/section-message/section-message';
import { getEntityIdFromParams } from 'utils/general';
import { useFetchPaginatedData } from 'utils/hooks';

const GroupTimelineTab: React.FC<{ entityIdName?: IGroupEntityIdName; groupId?: ItemId | null }> = observer(
  ({ entityIdName, groupId: propGroupId }) => {
    if (!entityIdName && !propGroupId) return <EmptySectionMessage />;
    const params = useParams<{ entityIdName: IGroupEntityIdName }>();
    const groupId = entityIdName ? getEntityIdFromParams(params, entityIdName) : String(propGroupId);

    const fetchData = useCallback(
      async (page) => {
        return await fetchTimelineData({ page, groupId });
      },
      [groupId],
    );

    const { handleLoadMore, loading, data: timeline, currentPage } = useFetchPaginatedData<ITimelineData>(fetchData);

    if (loading && currentPage === 1) {
      return <Loading text="Loading Timeline" />;
    }

    if (!timeline) return <EmptySectionMessage />;

    return (
      <div className="entity-timeline-tab h-100">
        <div className="d-flex justify-content-center h-100">
          <div className="entity-timeline-tab__content">
            {!!timeline?.length && <Timeline data={timeline} handleLoadMore={handleLoadMore} />}
          </div>
          {loading && <Loading text="Loading More" isLoadMore={true} />}
        </div>
      </div>
    );
  },
);

export default GroupTimelineTab;
