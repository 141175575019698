import { flatten, map, filter, startsWith } from 'lodash';
import { chipmunk } from './chipmunk';

// example usage:
// suggestedValues = await autosuggestValues('mm3:pm.product3, 'default_layer.meta.full_territories_sold_in', 'VC')

export const autosuggestValues = async (model: string, property: string, query: string): Promise<string[]> => {
  return chipmunk.run(async (ch) => {
    const result = await ch.action(model, 'search', {
      body: {
        per: 1,
        stats: [property],
        search: {
          filters: [[`${property}.fts`, 'q', `${query}*`]],
        },
      },
    });

    const values = map(flatten(map(result.aggregations)), 'value');

    return filter(
      values,
      (value) =>
        typeof value === 'string' &&
        startsWith(value.toLowerCase(), query.toLowerCase()) &&
        value.toLowerCase() !== 'other',
    );
  });
};
