export interface IFileExtensionValidationOpts {
  in?: string[];
}
export const extensionsValidation = (opts: IFileExtensionValidationOpts, value?: string | null): [boolean, string?] => {
  if (!value || !opts?.in?.length) {
    return [true];
  }

  const valid = opts.in.some((extension) => value.endsWith(`.${extension}`));
  if (valid) {
    return [true];
  }

  const [first, ...extensions] = opts.in;
  return [false, `file extension must be ${extensions.length ? `${extensions.join(', ')} or ` : ''}${first}`];
};
