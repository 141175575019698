import { IJsonLDSpec, IJsonSchemaSpec, IResult, ISetOpts } from '@mediafellows/chipmunk';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { action, IObservableArray, observable } from 'mobx';
import { chipmunk } from 'utils/chipmunk';
import { IDataProvider, IDataProviderItem } from './data-provider';

export type IDataProviderExecutor<T = IObject> = () => Promise<IResult<T> | IJsonLDSpec | IJsonSchemaSpec>;
export type IDataProviderMapper<T = IObject> = (
  data: IResult<T> | IJsonLDSpec | IJsonSchemaSpec,
) => IDataProviderItem[];

export class DynamicDataProvider<T = IObject> implements IDataProvider {
  @observable loading = false;
  @observable public data = observable([]) as IObservableArray<IDataProviderItem>;

  protected dataLoaded = false;
  protected allData: IDataProviderItem[];
  public cachedSelectedValue?: IDataProviderItem;

  constructor(protected executor: IDataProviderExecutor<T>, protected mapper: IDataProviderMapper<T>) {}

  @action async filter(query: string, exactMatch?: string): Promise<void> {
    if (!this.dataLoaded) {
      await this.loadData();
    }

    const filteredData = this.allData.filter((item) => {
      const normalizedLabel = item.label.toLowerCase();
      const normalizedQuery = query.toLowerCase();

      if (exactMatch) {
        return normalizedLabel === normalizedQuery;
      } else {
        return normalizedLabel.indexOf(normalizedQuery) >= 0;
      }
    });

    this.data.replace(filteredData);
  }

  async init(): Promise<void> {
    if (!this.dataLoaded) {
      await this.filter('');
    }
  }

  cacheSelectedValue(value: IDataProviderItem): void {
    this.cachedSelectedValue = value;
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const result = await this.executor();
    this.allData = this.mapper(result) || [];

    this.dataLoaded = true;
    this.loading = false;
  }
}

export const dynamicDataExecutorCache = (
  cacheName: string,
  executor: IDataProviderExecutor<IObject>,
  opts?: ISetOpts,
): IDataProviderExecutor<IObject> => {
  return async () => {
    const cName = `dynamic_data_provider_${cacheName}`;
    const cachedValue = chipmunk.cache.get(cName, opts);
    if (cachedValue) {
      return cachedValue;
    }

    const value = await executor();
    chipmunk.cache.set(cName, value, opts);

    return value;
  };
};
