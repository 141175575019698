import React from 'react';
import { observer } from 'mobx-react-lite';

import { DataSectionPage } from 'components/layout';
import { CastCrewDataSection, CreateCastCrewButton } from 'components/cast-crew';
import { mapProductsToCastCrew } from './utils';
import { IParametronData } from '@mediafellows/parametron';
import { ParametronStore } from 'store/parametron-store';

const handleUpdate = async (data: IParametronData, searchStore: ParametronStore | null): Promise<void> => {
  if (!data.objects?.length || !searchStore?.objects?.length) {
    return;
  }

  const objects = await mapProductsToCastCrew(data.objects);

  searchStore.update({ objects });
};

export const CastCrew: React.FC<{}> = observer(() => {
  return (
    <DataSectionPage>
      <CastCrewDataSection persistFilters headerRightSection={<CreateCastCrewButton />} handleUpdate={handleUpdate} />
    </DataSectionPage>
  );
});
