import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useStore } from 'store';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { useRemote, useProductAncestry } from 'utils/hooks';
import { productDetailsSchema } from 'utils/schemas/product';
import { getProduct } from 'utils/apis/product';
import { IProductAncestryInfo } from 'types';

import { ProductDetailsLoaded } from './product-details-loaded';
import './style.scss';

const emptyAncestry = {} as IProductAncestryInfo;

const ProductDetails: React.FC = observer(() => {
  const { productId = '' } = useParams<{ productId: string }>();
  const { toastStore } = useStore();

  const fetchProduct = useCallback(async () => {
    try {
      return getProduct(productId, productDetailsSchema);
    } catch (error) {
      toastStore.error(`Failed to load product: ${error.text || error}`);
    }
  }, [productId, toastStore]);

  const [product, loading, setProduct, refreshProduct] = useRemote(fetchProduct);

  const [ancestry = emptyAncestry, loadingAncestry, , refreshAncestry] = useProductAncestry(productId);
  const isLoading = loadingAncestry || loading;

  return (
    <div
      className="d-flex flex-column background-wrapper w-100 h-100"
      style={{ backgroundImage: `url(${product?.inherited_preview_image?.url})` }}
    >
      <div className="background-wrapper__index w-100 h-100">
        <DashboardBreadcrumbs className="mb-3" />
        {loading && <Loading text="Loading Product" />}
        {!isLoading && product?.id && (
          <ProductDetailsLoaded
            product={product}
            setProduct={setProduct}
            ancestry={ancestry}
            refreshAncestry={refreshAncestry}
            refreshProduct={refreshProduct}
          />
        )}
        {!product && !loading && <SectionMessage intent={SectionMessageType.EMPTY} />}
      </div>
    </div>
  );
});

export default ProductDetails;
