import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { useAssetsUploadStore } from 'store/hooks';
import { byId, pluralEntityInflect } from 'utils/general';
import { IUploadedFile } from 'types';
import { useStore } from 'store';
import { SectionHeader } from 'components/section-header';
import { ToastError } from 'components/toast';
import { createAssets } from 'pages/assets-upload/api';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';

export const SubmitButton: React.FC<{}> = observer(() => {
  const { isValid, disableSubmit, files, filesRemote, cleanUp, showErrors, isSending, setIsSending } =
    useAssetsUploadStore();
  const { toastStore } = useStore();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      if (isSending) {
        return;
      }
      if (!isValid) {
        return showErrors();
      }
      setIsSending(true);
      const filesRemoteById = byId<IUploadedFile>(filesRemote);

      await createAssets(files, filesRemoteById);
      const assetsCount = files.length;
      const { entity } = pluralEntityInflect('Asset', assetsCount);
      toastStore.success(`${entity} ${assetsCount > 1 ? 'were' : 'was'} created successfully`);
      cleanUp();
      navigate(`${Routes.ASSETS}`);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    } finally {
      setIsSending(false);
    }
  }, [cleanUp, files, filesRemote, isSending, isValid, navigate, setIsSending, showErrors, toastStore]);

  const cancel = useCallback(() => {
    cleanUp();
    navigate(`${Routes.ASSETS}`);
  }, [cleanUp, navigate]);

  return (
    <>
      <Button size="xs" variant="default" onClick={cancel} className="me-4">
        Cancel
      </Button>
      <Button size="xs" onClick={handleSubmit} variant="primary" disabled={disableSubmit}>
        Finish
      </Button>
    </>
  );
});

export const FormSubmitButton: React.FC<{}> = observer(() => {
  const CustomRightRenderer = useCallback(() => {
    return <SubmitButton />;
  }, []);

  return <SectionHeader customRightRenderer={CustomRightRenderer} useBackground />;
});

export default React.memo(FormSubmitButton);
