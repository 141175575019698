import { uniq } from 'lodash';

import { IFormMultiSelectOption, IUseFormReturn } from 'helpers/form';
import { IGroup, IGroupEditForm, IIdentifiable, IAsset, IContact, GroupTypes, ItemId } from 'types';
import { loadGroupContacts } from 'utils/apis/groups';
import { queryAllMainAssets } from 'utils/apis/asset';
import { getValidIds } from 'utils/general';

export const enum StepIds {
  General,
  Assets,
  Contacts,
  Summary,
}

export type IGroupAddAsset = IAsset | (IGroup & IIdentifiable);
export type IGroupAddContact = IContact | (IGroup & IIdentifiable);

export interface IScreeningRoomForm extends IGroupEditForm {
  contacts?: IFormMultiSelectOption[];
  assets?: IGroupAddAsset[];
}

export interface IScreeningRoomStepProps {
  form: IUseFormReturn<IScreeningRoomForm>;
}

export const getContactsAndAssetsIds = async (
  values: IScreeningRoomForm,
): Promise<{
  assets_item_ids: ItemId[];
  contacts_item_ids: ItemId[];
}> => {
  const assetEntityIds = values?.assets?.reduce(
    (acc, entity) => {
      if (entity['@type'] === GroupTypes.SELECTION) {
        return { ...acc, selectionIds: [...acc.selectionIds, entity.id] };
      }
      return { ...acc, assetIds: [...acc.assetIds, entity.id] };
    },
    { selectionIds: [], assetIds: [] },
  );
  const selectionAssets = assetEntityIds?.selectionIds?.length
    ? await queryAllMainAssets({}, [['group_ids', 'in', assetEntityIds.selectionIds]], 'id')
    : [];
  const selectionAssetIds = getValidIds(selectionAssets);
  const assets_item_ids = uniq([...(assetEntityIds?.assetIds || []), ...(selectionAssetIds || [])]);

  const contactEntityIds = values?.contacts?.reduce(
    (acc, entity) => {
      if (entity['@type'] === GroupTypes.SELECTION) {
        return { ...acc, selectionIds: [...acc.selectionIds, entity.id || 0] };
      }
      return { ...acc, contactIds: [...acc.contactIds, entity.id || 0] };
    },
    { selectionIds: [], contactIds: [] },
  );

  const selectionContactIds = contactEntityIds?.selectionIds?.length
    ? await loadGroupContacts(contactEntityIds?.selectionIds)
    : [];
  const contacts_item_ids = uniq([
    ...(contactEntityIds?.contactIds || []),
    ...(selectionContactIds.map(({ id }) => id || 0) || []),
  ]);
  return { assets_item_ids, contacts_item_ids };
};
