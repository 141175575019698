import { chipmunk, IResult } from 'utils/chipmunk';
import type { IAccessGroup } from 'components/access-groups';
import {
  addProductToGroup,
  addParentProductsToGroup,
  addDifferentContactEntitiesToGroup,
  addOrganizationsToGroup,
  loadGroupsUsers,
  loadGroupsOrganizationsUsers,
} from 'utils/apis/groups';
import { ItemId, GroupTypes } from 'types';
import { Model } from 'helpers/filters/types';

export const createAccessGroup = async (values: IAccessGroup): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const {
      // permit_download,
      // delegates_access,
      include_descendants,
      include_future_descendants,
      organization_ids,
      product_ids,
      contacts,
      ...formData
    } = values;
    const result = await action(Model.GROUPS, 'create', {
      body: {
        type: GroupTypes.ACCESS_GROUPS,
        ...formData,
      },
    });
    const { object: group } = result;
    const group_id = group.id;

    const productsPromise = addProductToGroup({
      group_id,
      item_ids: product_ids,
      // delegates_access,
      include_descendants,
      include_future_descendants,
      // permissions: permit_download ? ['download'] : [],
    });

    const parentProducts = addParentProductsToGroup({ group_id, item_ids: product_ids });

    const contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });

    const organizationsPromise = addOrganizationsToGroup({ group_id, item_ids: organization_ids });

    await Promise.all([productsPromise, parentProducts, contactsPromise, organizationsPromise]);

    return group;
  });
};

export const loadAccessPrivilegeAllUserIds = async (group_id: ItemId): Promise<ItemId[]> => {
  const users = await loadGroupsUsers([group_id]);
  const organizations_users = await loadGroupsOrganizationsUsers([group_id]);

  return [...users.map(({ id }) => id), ...organizations_users.map(({ id }) => id)];
};
