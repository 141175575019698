import { chipmunk } from 'utils/chipmunk';
import { ItemId } from 'types';
import { Model } from 'helpers/filters/types';

interface ISendScreeningEmails {
  user_ids: ItemId[];
  subject: string;
  message?: string;
  to_recipients_attributes?: { recipient_id: ItemId; recipient_type: string }[];
}

export const sendScreeningsEmails = async (screeningEmail: ISendScreeningEmails): Promise<void> => {
  const { user_ids } = screeningEmail;
  const to_recipients_attributes = user_ids.map((user_id) => ({
    recipient_id: user_id,
    recipient_type: 'user',
  }));

  return chipmunk.run(({ action }) =>
    action(Model.WORKFLOW, 'create', {
      body: { ...screeningEmail, name: 'campaign_send', to_recipients_attributes },
    }),
  );
};
