import React, { useCallback } from 'react';

import { Page } from 'components/layout';
import { AccessPrivilegeGrantWizard } from 'components/access-privilege-grant';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';

export const UpsertAccessPrivilege: React.FC = () => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(`${Routes.ACCESS_PRIVILEGES}`);
  }, [navigate]);

  return (
    <Page title="Create Access Privilege">
      <AccessPrivilegeGrantWizard onSuccess={onSuccess} fitInParent />
    </Page>
  );
};
