import React, { useCallback } from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormMultiSelect } from 'helpers/form';

import { IAccessPrivilegeFormComponent, IAccessPrivilegeForm } from './types';
import { isValid } from 'helpers/form';
import { IQueryParams, IItem } from 'types';
import { queryContacts } from 'utils/apis/contacts';
import { queryContactSelections } from 'utils/apis/recipients';
import { queryOrganizations } from 'utils/apis/organization';

export const keysContacts = ['contacts', 'organization_ids'];
export const isValidContactsStep = (form: IAccessPrivilegeForm): boolean => {
  if (!isValid(form.formData, keysContacts)) {
    return false;
  }
  return form.values.organization_ids.length > 0 || form.values.contacts.length > 0;
};

const fetchValues = async ({ q, ids }: IQueryParams): Promise<IItem[]> => {
  const contacts = await queryContacts({ q, ids });
  const selections = await queryContactSelections({ q, ids });
  return [...contacts, ...selections];
};

export const AccessPrivilegeGrantFormContacts: React.FC<IAccessPrivilegeFormComponent> = ({ form }) => {
  const onContactChange = useCallback(
    (contacts) => {
      form.handlers.onChange({ contacts, contact_ids: contacts?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <FormSectionWrapperV2 gridTemplateColumns={'1fr 1fr'} className="h-100" padded={false}>
      <FormMultiSelect
        name="contact_ids"
        label="Contacts"
        large
        omni
        fetchValues={fetchValues}
        {...form.formData.contact_ids}
        onSelectedItemsChange={onContactChange}
        onBlur={form.handlers.onBlur}
        required={form.values.organization_ids.length < 1}
      />
      <FormMultiSelect
        omni
        name="organization_ids"
        label="Organizations"
        large
        fetchValues={queryOrganizations}
        {...form.formData.organization_ids}
        {...form.handlers}
        required={form.values.contacts.length < 1}
      />
    </FormSectionWrapperV2>
  );
};
