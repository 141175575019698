import React from 'react';

import { FormInput, FormImage, FormHtmlInput } from 'helpers/form';
import { InfoBoxV2 } from 'components/info-box-v2';
import { ICreateEventStep } from './types';
import { flags } from 'utils/flags';

import './style.scss';

const StepLayout: React.FC<ICreateEventStep> = ({ form }) => {
  return (
    <div className="event-detail__tab__wrapper event-detail__layout-tab__wrapper">
      <div className="event-detail__tab__content event-detail__layout-tab__content">
        {form.values.is_timed_event && flags.showEventLayout && (
          <>
            <InfoBoxV2 title="Upcoming" gridTemplateColumns="2fr 0.5fr">
              <FormInput
                name="event_not_started_headline"
                type="text"
                label="Headline"
                className="event-layout__edit__label"
                {...form.formData.event_not_started_headline}
                {...form.handlers}
              />
              <div className="event-layout-images">
                <FormImage
                  type="attachment"
                  name="event_not_started_featured_image_url"
                  label="Featured image"
                  large
                  {...form.formData.event_not_started_featured_image_url}
                  {...form.handlers}
                />
              </div>
              <FormHtmlInput
                name="event_not_started_introduction"
                label="Introduction"
                large
                className="event-layout__edit__label event-layout__edit__html-editor"
                {...form.formData.event_not_started_introduction}
                {...form.handlers}
              />
              <div className="event-layout-images">
                <FormImage
                  type="attachment"
                  name="event_not_started_background_image_url"
                  label="Background image"
                  large
                  {...form.formData.event_not_started_background_image_url}
                  {...form.handlers}
                />
              </div>
            </InfoBoxV2>
          </>
        )}

        <InfoBoxV2 title={`${flags.showEventLayout === false ? '' : 'ONGOING'}`} gridTemplateColumns="2fr 0.5fr">
          <div className="layout-block__subtitle">Not logged in</div>
          <FormInput
            name="event_started_not_loggedin_headline"
            type="text"
            label="Headline"
            className="event-layout__edit__label"
            {...form.formData.event_started_not_loggedin_headline}
            {...form.handlers}
          />
          <div className="event-layout-images">
            <FormImage
              type="attachment"
              name="event_started_not_loggedin_featured_image_url"
              label="Featured image"
              large
              {...form.formData.event_started_not_loggedin_featured_image_url}
              {...form.handlers}
            />
          </div>
          <FormHtmlInput
            name="event_started_not_loggedin_introduction"
            label="Introduction"
            className="event-layout__edit__label event-layout__edit__html-editor"
            large
            {...form.formData.event_started_not_loggedin_introduction}
            {...form.handlers}
          />
          <div className="event-layout-images">
            <FormImage
              type="attachment"
              name="event_started_not_loggedin_background_image_url"
              label="Background image"
              large
              {...form.formData.event_started_not_loggedin_background_image_url}
              {...form.handlers}
            />
          </div>
          <div className="layout-block__subtitle">Logged in</div>
          <FormInput
            name="event_started_loggedin_headline"
            type="text"
            label="Headline"
            className="event-layout__edit__label"
            {...form.formData.event_started_loggedin_headline}
            {...form.handlers}
          />
          <div className="event-layout-images">
            <FormImage
              type="attachment"
              name="event_started_loggedin_featured_image_url"
              label="Featured image"
              large
              {...form.formData.event_started_loggedin_featured_image_url}
              {...form.handlers}
            />
          </div>
          <FormHtmlInput
            name="event_started_loggedin_introduction"
            label="Introduction"
            large
            className="event-layout__edit__label event-layout__edit__html-editor"
            {...form.formData.event_started_loggedin_introduction}
            {...form.handlers}
          />
          <div className="event-layout-images">
            <FormImage
              type="attachment"
              name="event_started_loggedin_background_image_url"
              label="Background image"
              large
              {...form.formData.event_started_loggedin_background_image_url}
              {...form.handlers}
            />
          </div>
        </InfoBoxV2>

        {form.values.is_timed_event && flags.showEventLayout && (
          <InfoBoxV2 title="Finished" gridTemplateColumns="2fr 0.5fr">
            <FormInput
              name="event_finished_headline"
              type="text"
              label="Headline"
              className="event-layout__edit__label"
              {...form.formData.event_finished_headline}
              {...form.handlers}
            />
            <div className="event-layout-images">
              <FormImage
                type="attachment"
                name="event_finished_featured_image_url"
                label="Featured image"
                large
                {...form.formData.event_finished_featured_image_url}
                {...form.handlers}
              />
            </div>
            <FormHtmlInput
              name="event_finished_introduction"
              label="Introduction"
              large
              className="event-layout__edit__label event-layout__edit__html-editor"
              {...form.formData.event_finished_introduction}
              {...form.handlers}
            />
            <div className="event-layout-images">
              <FormImage
                type="attachment"
                name="event_finished_background_image_url"
                label="Background image"
                large
                {...form.formData.event_finished_background_image_url}
                {...form.handlers}
              />
            </div>
          </InfoBoxV2>
        )}
      </div>
    </div>
  );
};

export { StepLayout };
