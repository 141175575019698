import { IBasketStandardized, IGroup } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { useShareBasketDialog } from 'components/baskets/share-basket-dialog';
import { IBasketActionConfig, IBasketActionName } from 'utils/actions/basket/types';

export const useShareBasketAction = (
  items: IBasketStandardized[],
  options?: IUseActionsOptionParam,
): IBasketActionConfig => {
  const [openBasketDialog] = useShareBasketDialog({
    group: items[0] as unknown as IGroup,
    entityType: 'users',
    options,
  });

  return {
    name: IBasketActionName.SHARE_BASKET,
    icon: 'share',
    title: `Share Basket`,
    handler: openBasketDialog,
  };
};
