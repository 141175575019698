import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { Button } from '@mantine/core';
import { ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { ActionIcon } from '@mantine/core';
import cx from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import { noop, isEqual, isNumber } from 'lodash';

import { by } from 'utils/general';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';

import { formatFormLabel, getFieldPlaceholder, highlightText } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { FormGroup } from 'helpers/form/fields/form-group';

import {
  createDataTree,
  preparePropOptions,
  uncheckNode,
  getParentCheckedNodes,
  tagRenderer as defaultTagRenderer,
  getAllCheckedNodes,
  getAllAncestorNodes,
} from './tree-selector-utils';

import { IOptionId, IRawTreeOption, ITreeSelectOption, ITreeNode } from './types';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './style.scss';

const MfxMultiSelect = MultiSelect.ofType<ITreeSelectOption>();

export interface IFormTreeSelectProps
  extends IFieldData<IOptionId[] | null, IRawTreeOption[] | null>,
    IFieldHandlers<IOptionId[]> {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  tagRenderer?: (option: ITreeSelectOption) => React.ReactNode;
  hideClearAllButton?: boolean;
}

const icons = {
  expandClose: <MantineIcon icon="chevron-right" />,
  expandOpen: <MantineIcon icon="chevron-down" />,
  parentOpen: null,
  check: null,
  uncheck: null,
  halfCheck: null,
  expandAll: null,
  collapseAll: null,
  parentClose: null,
  leaf: null,
};

interface ICheckboxTreeSelectorProps {
  items: ITreeNode[];
  onCheck: (checked: string[]) => void;
  checked: string[];
}

const CheckboxTreeSelector: React.FC<ICheckboxTreeSelectorProps> = ({ items, onCheck, checked }) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  return (
    <CheckboxTree
      nodes={items}
      checked={checked}
      expanded={expanded}
      onCheck={onCheck}
      checkModel="all"
      onExpand={setExpanded}
      nativeCheckboxes
      icons={icons}
    />
  );
};

const itemRenderer: ItemRenderer<ITreeNode> = (item, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.value}
      onClick={handleClick}
      text={highlightText(item.label, query)}
    />
  );
};

export const FormTreeSelect: React.FC<IFormTreeSelectProps> = (props) => {
  const {
    name,
    label,
    className,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    large,
    tagRenderer = defaultTagRenderer,
    required,
    options,
    inline = false,
    placeholder,
    disabled = false,
    hideClearAllButton = false,
  } = props;
  const showError = touched && !validation?.valid;
  const intent = showError ? Intent.DANGER : undefined;
  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Select ${label}` });

  const flatOptionsList = useMemo<ITreeSelectOption[]>(() => preparePropOptions(options), [options]);
  const optionsTree = useMemo<ITreeNode[]>(() => createDataTree(flatOptionsList), [flatOptionsList]);
  const [selectedItems, setSelectedItems] = useState<ITreeSelectOption[]>([]);
  const selectedItemsRef = useRef<ITreeSelectOption[]>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (selected) => {
      let selectedValues = selected;
      if (isNumber(flatOptionsList?.[0]?.value)) {
        // selected is always an array of strings,
        // in case the id is of type number we need parse it here to avoid failing checks
        selectedValues = selected.map((v) => parseInt(v, 10));
      }

      setChecked(selectedValues);

      // when a parent node is selected, all its children are also selected
      // we only add the parent node to the selected items
      const checkedNodes = getParentCheckedNodes(optionsTree, selectedValues);

      // `selectedValues` already includes all the values with their descendants
      // to have all relevant values in the DB (for searching), we still need to add
      // all ancestors of the selected values..
      const ancestorNodes = getAllAncestorNodes(flatOptionsList, checkedNodes);
      const fullNodes = [...selectedValues, ...ancestorNodes];
      const nameParts = name.split('.');
      const nameTail = nameParts.pop();
      const fullName = [...nameParts, `full_${nameTail}`].join('.');

      onChange?.({ [name]: checkedNodes, [fullName]: fullNodes });

      const optionsByValue = by(flatOptionsList, 'value');
      const itemsSelected = checkedNodes.map((value) => optionsByValue[value]);
      setSelectedItems(itemsSelected);
      selectedItemsRef.current = itemsSelected;
      handleBlur?.();
    },
    [optionsTree, flatOptionsList, onChange, name, handleBlur],
  );

  useEffect(() => {
    const oldValue = selectedItemsRef.current.map(({ value }) => value).sort();
    const newValue = (value || []).sort();
    if (!optionsTree?.length || isEqual(newValue, oldValue)) {
      return;
    }

    handleChange(getAllCheckedNodes(optionsTree, value));
  }, [handleChange, optionsTree, value]);

  const tagInputProps = useMemo(() => {
    const onRemove = (_, index: number): void => {
      // if a node is unchecked we need to also get its children values and remove them from the checked list
      const uncheckedNodes = uncheckNode(optionsTree, selectedItems[index].value);
      handleChange(checked.filter((value) => !uncheckedNodes.includes(value)));
    };

    return { onRemove, inputProps: { readOnly: true } };
  }, [handleChange, checked, optionsTree, selectedItems]);

  const groupClass = cx(className, {
    'form-tree-inline__container': inline,
  });

  const itemsListRenderer = useCallback(() => {
    return <CheckboxTreeSelector checked={checked} items={optionsTree} onCheck={handleChange} />;
  }, [checked, optionsTree, handleChange]);

  const isEmptyMultiSelect = !selectedItems.length;

  const handleClearAll = useCallback(
    (name: string): void => {
      setSelectedItems([]);
      onChange?.({ [name]: [] });
    },
    [setSelectedItems, onChange],
  );
  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation?.errorMessage : ''}
      inline={inline}
      className={groupClass}
    >
      <MfxMultiSelect
        className="form-tree-select"
        items={flatOptionsList}
        itemListRenderer={itemsListRenderer}
        onItemSelect={noop}
        tagRenderer={tagRenderer}
        itemRenderer={itemRenderer}
        selectedItems={selectedItems}
        tagInputProps={{
          rightElement: !hideClearAllButton ? (
            <ActionIcon
              className="clear-all__button"
              disabled={isEmptyMultiSelect || disabled}
              onClick={() => handleClearAll(name)}
              variant="subtle"
              color="gray.5"
            >
              <MantineIcon icon="cross" />
            </ActionIcon>
          ) : undefined,
          large,
          ...({ ...tagInputProps, disabled } || {}),
        }}
        placeholder={formattedPlaceholder}
      >
        <Button variant={intent} name={name} size={large === true ? 'md' : 'sm'} />
      </MfxMultiSelect>
    </FormGroup>
  );
};

export default FormTreeSelect;
