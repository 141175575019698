import React, { useCallback } from 'react';

import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { cloneGroup } from 'utils/apis/groups';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';
import { ISelectionActionConfig, ISelectionActionName } from 'utils/actions/selection/types';

import { IGroup, IIdentifiable, ItemId } from 'types';

import { ToastError } from 'components/toast';
import { FormSubmitSection } from 'components/form-submit-section';

import { FormContacts, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { useSessionStore } from 'store/session-store';

interface ICopyFormProps {
  id: ItemId;
  ownerId: ItemId;
  handleSubmit: (group: IGroup) => Promise<void>;
}

export const CopyGroupForm: React.FC<ICopyFormProps> = ({ id, handleSubmit, ownerId }) => {
  const { formData, handlers, onSubmit } = useForm<IGroup>({ id, owner_id: ownerId }, Model.GROUPS, handleSubmit);
  return (
    <form onSubmit={onSubmit}>
      <FormContacts label="Owner" name="owner_id" {...formData.owner_id} {...handlers} />
      <FormSubmitSection labels={{ confirm: 'Copy' }} />
    </form>
  );
};

export function useCopyAction(
  items: IIdentifiable[],
  options: IUseActionsOptionParam<IIdentifiable>,
): ISelectionActionConfig {
  const { toastStore, dialogStore } = useStore();
  const user = useSessionStore((state) => state.user);

  const handleSubmit = useCallback(
    async ({ id, owner_id }: IGroup): Promise<void> => {
      try {
        await cloneGroup({ id, owner_id });
        await options?.onSuccess?.();
        toastStore.success(options?.messageSuccess || 'Selection has been copied');
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong" />);
        await options?.onFailure?.();
      }
    },
    [dialogStore, options, toastStore],
  );

  const groupId = items?.[0]?.id;
  const currentUserId = user?.id;

  const handler = useCallback(() => {
    return dialogStore.openModal({
      title: 'Copy and assign to selected owner',
      body: () => {
        if (!groupId || !currentUserId) {
          return <></>;
        }
        return <CopyGroupForm id={groupId} ownerId={currentUserId} handleSubmit={handleSubmit} />;
      },
    });
  }, [dialogStore, handleSubmit, groupId, currentUserId]);

  return {
    name: ISelectionActionName.COPY,
    shouldDisplay: shouldDisplaySingleNotDeleted,
    icon: 'duplicate',
    title: 'Copy',
    handler,
  };
}
