import React from 'react';
import { formatDuration } from 'utils/date';
import { Classes } from 'utils/ui';

import { InfoBoxV2 } from 'components/info-box-v2';
import DateElement from 'components/date-list-element/date-list-element';
import {
  IRecipientsRowData,
  IRecipientsScreeningData,
} from 'pages/recommendation-details/recommendation-analytics-tab/types';

interface IRecommendationRecipientsTabProps {
  rowData: IRecipientsRowData;
  screeningData?: IRecipientsScreeningData[];
}

export const InteractionPreviewRecipients: React.FC<IRecommendationRecipientsTabProps> = ({
  rowData,
  screeningData,
}) => {
  return (
    <div className="recommendation-analytics-sidebar__content">
      <InfoBoxV2 title="Screening Details" />
      <div className="entity-preview h-100">
        <div className="d-flex flex-column align-items-center justify-content-center preview-info-box">
          <div className="entity-preview__title">
            <h3 className="access-level-green">{rowData?.recipient?.full_name}</h3>
          </div>
          <div className={`entity-preview__subtitle ${Classes.TEXT_MUTED}`}>
            <h4>
              {rowData?.recipient?.role_name
                ? `${rowData?.recipient?.role_name} at ${rowData?.recipient?.organization_name}`
                : rowData?.recipient?.organization_name}
            </h4>
          </div>
        </div>

        {screeningData?.map((item, index) => {
          return (
            <InfoBoxV2 key={index} title={item?.asset.name} gridTemplateColumns="1fr 1fr">
              <div className="text-muted">Times screened</div>
              <div>{item?.times_screened}</div>

              <div className="text-muted">Screening Duration</div>
              <div>{formatDuration(item?.total_duration, 'seconds')}</div>

              <div className="text-muted">Last screened</div>
              <div>
                <DateElement date={item?.last_occured_at} />
              </div>
            </InfoBoxV2>
          );
        })}
      </div>
    </div>
  );
};
