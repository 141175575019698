import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { Link, NavLink } from 'react-router-dom';

import { getRootStore, useProfileStore } from 'store';
import { Globals } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { Searchbox } from 'components/searchbox';
import { UserAvatar } from 'components/user-avatar';
import { AffiliationLogo } from 'components/affiliation-logo/affiliation-logo';

import { Routes } from 'utils/routes';
import { logout, useSessionStore } from 'store/session-store';
import { MediaBenchLoginButton } from 'components/top-header/mediabench-login-button';
import { AdditionalButtons } from 'components/top-header/additional-buttons';
import { MantineIcon } from 'utils/ui/icon';
import { Position } from 'utils/ui/position';
import { Help, LogOut, Menu, SwapVertical } from 'blueprint5-icons';

import './style.scss';

interface ITopHeader {
  toggle: () => void;
}

const body = document.body;

export const colorModeToggle = (): void => {
  const updateSettings = useProfileStore.getState().updateSettings;
  const isDarkMode = useProfileStore.getState().settings.isDarkMode;
  body.classList.toggle(Globals.MODE_DARK_CLASS);
  updateSettings('isDarkMode', !isDarkMode);

  if (localStorage.getItem(Globals.THEME_LIGHT_LS)) {
    localStorage.removeItem(Globals.THEME_LIGHT_LS);
    return;
  }

  localStorage.setItem(Globals.THEME_LIGHT_LS, '1');
};

export const switchToClientSite = (): void => {
  const { toastStore } = getRootStore();
  chipmunk.run(
    async (chipmunk) => {
      const result = await chipmunk.action('um.session', 'impersonate_as_preview_user');

      const domain = result.object.login_domain;
      const id = result.object.id;
      const ref = document.location.toString();

      const uri = `${domain[0]}://${domain[1]}/session/${id}?ref=${ref}`;
      window.open(uri, '_blank');
    },
    () => {
      toastStore.error('Sorry, switching to the client site has failed.');
    },
  );
};

const TopHeader: React.FC<ITopHeader> = observer(({ toggle }) => {
  const user = useSessionStore((state) => state.user);

  const handleLogout = useCallback(async (): Promise<void> => {
    return logout({ allTabs: true, remote: true });
  }, []);

  return (
    <>
      <Group justify="flex-start" gap={0}>
        <ActionIcon color="gray.5" variant="subtle" className="top-header__left--collapse ms-2 me-4" onClick={toggle}>
          <MantineIcon icon={<Menu />} />
        </ActionIcon>

        <Link to={Routes.DASHBOARD}>
          <AffiliationLogo maw={175} />
        </Link>
      </Group>

      <Group justify="flex-end" gap={0}>
        <MediaBenchLoginButton />
        <AdditionalButtons />

        <Tooltip label="Search" position={Position.BOTTOM}>
          <div className="me-2">
            <Searchbox />
          </div>
        </Tooltip>

        <Tooltip label="Switch To Client Site" position={Position.BOTTOM}>
          <ActionIcon color="gray.5" variant="subtle" onClick={switchToClientSite} className="top-header__switch me-2 ">
            <MantineIcon icon={<SwapVertical />} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Press Shift + ? For Hotkeys Legend" position={Position.BOTTOM}>
          <ActionIcon color="gray.5" variant="subtle" className="top-header__hotkeys me-3">
            <MantineIcon icon={<Help />} />
          </ActionIcon>
        </Tooltip>

        <NavLink to={`/contacts/${user?.id}`} className="top-header__user me-4">
          <div className="top-header__user">
            <div className="top-header__avatar">
              <UserAvatar user={user} disabled />
            </div>
            <span className="top-header__username">{user?.first_name}</span>
          </div>
        </NavLink>

        <Tooltip className="top-header__sign-tooltip" label="Sign Out" position={Position.BOTTOM}>
          <div className="top-header__signout">
            <ActionIcon variant="subtle" color="gray.5" className="top-header__signout" onClick={handleLogout}>
              <MantineIcon icon={<LogOut />} />
            </ActionIcon>
          </div>
        </Tooltip>
      </Group>
    </>
  );
});

export default TopHeader;
