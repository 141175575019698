import { useCallback } from 'react';

import { ISelectionStandardized } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { shouldDisplayRestore as shouldDisplay } from 'utils/actions/common';
import { restoreSelection } from 'utils/actions/selection/api';
import { ISelectionActionConfig, ISelectionActionName } from 'utils/actions/selection/types';
import { pluralEntityInflect } from 'utils/general';

export const useRestoreAction = (
  items: ISelectionStandardized[],
  options: IUseActionsOptionParam,
): ISelectionActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Selection', items.length);

  const executeRestore = useCallback(async () => {
    try {
      toastStore.success(`${entityWithCount} was restored`);
      const itemIds = items.map((item) => item.id);
      await restoreSelection(itemIds);
      await options?.onSuccess?.();
    } catch (error) {
      toastStore.error(`${entity} restore failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Restore',
      body: `Are you sure you want to restore ${entityWithDemonstrative}?`,
      onConfirm: executeRestore,
    });
  }, [dialogStore, entityWithDemonstrative, executeRestore]);

  return {
    name: ISelectionActionName.RESTORE,
    shouldDisplay,
    icon: 'automatic-updates',
    title: 'Restore',
    handler,
  };
};
