import { useEffect } from 'react';
import { omit } from 'lodash';

import { loadAffiliation } from 'utils/apis/affiliation';
import { deletePreviewImage, ingestPreviewImage } from 'utils/apis/preview-image';

import { Globals, IAffiliation, IPreviewImage } from 'types';
import { IAffiliationForm } from './types';
import { STORAGE_KEYS, deleteStorageItem, setStorageItem } from 'utils/storage';

export const useRefreshPreviewForImageIngest = (
  setAffiliation: React.Dispatch<React.SetStateAction<IAffiliation>>,
  affiliation?: IAffiliation | null,
): void => {
  const { logo_id, logo } = affiliation || {};

  useEffect(() => {
    if ((logo_id && logo?.id) || (!logo_id && !logo?.id)) return;
    const id = setInterval(async () => {
      if (logo_id && !logo?.id) {
        const newAffiliation = await loadAffiliation();
        const { logo: newlogo } = newAffiliation;
        if (newlogo?.url) {
          setStorageItem(STORAGE_KEYS.WEBSITE_LOGO, newlogo.url);
          window.dispatchEvent(new Event(Globals.AFFILIATION_LOGO_UPDATE));
          setAffiliation(newAffiliation);
          clearInterval(id);
        }
      }
    }, 10 * 1000);

    return () => clearInterval(id);
  }, [logo_id, logo, setAffiliation]);
};

const processAffiliationLogoUpdates = async (
  previewUrl: string,
  preview?: IPreviewImage | null,
  isLogo = false,
): Promise<string | undefined> => {
  if (previewUrl === '' && preview?.id) {
    deletePreviewImage(preview.id);
    if (isLogo) {
      deleteStorageItem(STORAGE_KEYS.WEBSITE_LOGO);
      window.dispatchEvent(new Event(Globals.AFFILIATION_LOGO_UPDATE));
    }
    return previewUrl;
  }
  const previewImage = previewUrl === preview?.url ? preview : await ingestPreviewImage(previewUrl);
  return previewImage?.id?.toString();
};

export const prepareAffiliationPayload = async (values: IAffiliationForm): Promise<Omit<IAffiliation, 'id'>> => {
  const {
    logoUrl,
    logo,
    splashScreenUrl,
    splash_screen,
    splashScreenVideoUrl,
    primarySellerDomain,
    primaryBuyerDomain,
    password_expiration_interval,
  } = values;
  const logoPreviewId = await processAffiliationLogoUpdates(logoUrl, logo, true);
  const splashScreenId = await processAffiliationLogoUpdates(splashScreenUrl, splash_screen);

  const affiliationForm: Omit<IAffiliation, 'id'> = {
    ...omit(values, [
      'logoUrl',
      'splashScreenUrl',
      'splashScreenVideoUrl',
      'primarySellerDomain',
      'primaryBuyerDomain',
    ]),
    logo_id: logoPreviewId,
    splash_screen_id: splashScreenId,
    mobile_app_splash_screen: splashScreenVideoUrl,
    primary_seller_domain_id: primarySellerDomain,
    primary_buyer_domain_id: primaryBuyerDomain,
    password_expiration_interval: password_expiration_interval === undefined ? null : password_expiration_interval,
  };
  return affiliationForm;
};
