import React from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { EmailsBlock } from 'utils/ui';

import './style.scss';

export const RecommendationCCListTab: React.FC<Pick<McGenericRecommendationProduct, 'cc_list' | 'bcc_list'>> = ({
  cc_list,
  bcc_list,
}) => {
  return (
    <div className="asset-metadata-tab h-100">
      <EmailsBlock emails={cc_list} title="CC List" />
      <EmailsBlock emails={bcc_list} title="BCC List" />
    </div>
  );
};

export default RecommendationCCListTab;
