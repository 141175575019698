import { useProfileStore } from 'store';
const body = document.body;

export const toggleProfileSettings = (key: string, localStorageKey: string, className?: string): void => {
  const updateSettings = useProfileStore.getState().updateSettings;
  const settings = useProfileStore.getState().settings;

  if (className) {
    body.classList.toggle(className);
  }
  updateSettings(key, !settings[key]);

  if (localStorage.getItem(localStorageKey)) {
    localStorage.removeItem(localStorageKey);
    return;
  }

  localStorage.setItem(localStorageKey, '1');
};
