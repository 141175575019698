import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ICategory, ILanguageVersion, IQueryParams } from 'types';
import { addYears, endOfYear } from 'date-fns';
import { getSuggestions } from 'helpers/form';
import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

export const minDate = new Date(1896, 0, 1);
export const maxDate = endOfYear(addYears(new Date(), 10));

export interface IProductLayer extends PmItvstudiosLayer3MotionPictureStandard {
  access_level: string;
  acclaim: string;
  aka: string;
  availability: string;
  brand_name: string;
  categories: ICategory[];
  copyright: string;
  copyright_notice: string;
  countries: { name: string }[];
  created_at?: string;
  crews?: { name: string; role: string }[];
  display_definition?: string;
  duration?: number;
  duration_text?: string;
  episode_production_no?: string;
  format?: { name?: string };
  gross_box_office?: string;
  highlights?: string;
  id: number;
  identifier_accounting_reference?: string;
  identifier_archive_number: string;
  identifier_crid: string;
  identifier_guid: string;
  identifier_imdb: string;
  identifier_isan: string;
  identifier_isbn: string;
  identifier_iswc: string;
  identifier_umid: string;
  identifier_upid: string;
  identifier_uri: string;
  identifier_v_isan: string;
  intended_audiences: { name: string }[];
  intended_distribution: { name: string };
  internal_rating: string;
  language_versions: ILanguageVersion[];
  library: string;
  logline: string;
  long_synopsis: string;
  original_broadcaster: string;
  original_release_date: string;
  product_id: number;
  product_type: string;
  rating: string;
  resolution: string;
  rights_holder: string;
  synopsis: string;
  title: string;
  topics: { name: string }[];
  trademark: string;
  updated_at: string;
  web_page: string;
}

export interface IProductEditFormProps {
  layerForm: IUseMm3FormReturn<PmItvstudiosLayer3MotionPictureStandard>;
  productForm: IUseMm3FormReturn<PmItvstudiosProduct3>;
  inEditMode?: boolean;
}

export const getKeywords = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.keywords');
