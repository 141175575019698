import React from 'react';
import { AmGenericAsset3, AmGenericNews, UmGenericListCollection } from '@mediafellows/mm3-types';
import { Menu } from '@mantine/core';

import {
  IAsset,
  IGroup,
  IOrganization,
  IProduct,
  IContact,
  ICategory,
  ISeparator,
  ICustomItemRenderer,
  IItem,
  ItemId,
} from 'types';
import { isProduct } from 'utils/general';
import { ProductSearchItem } from 'components/product-search-item';

import { NewsSearchItem } from 'components/news';
import { AssetSearchItem } from 'components/asset/asset-search-item';
import { GroupSearchItem } from 'components/group-search-item';
import { UserSearchItem } from 'components/user-search-item';
import { OrganizationSearchItem } from 'components/organization';
import { CollectionSearchItem } from 'components/collection-search-item';
import { CategorySelectedTabItem } from 'components/category-selected-tab-item';
import { highlightText } from './helpers';

import cx from 'classnames';

export enum ItemTypes {
  USER = 'user',
  ASSET = 'asset',
  GROUP = 'group',
  NEWS = 'news',
  PRODUCT = 'product',
  ORGANIZATION = 'organization',
  CATEGORY = 'category',
  LIST_COLLECTION = 'list/collection',
  SEPARATOR = 'separator',
  UNKNOWN = 'unknown',
}

export type IItemType =
  | ItemTypes.USER
  | ItemTypes.ASSET
  | ItemTypes.GROUP
  | ItemTypes.PRODUCT
  | ItemTypes.ORGANIZATION
  | ItemTypes.CATEGORY
  | ItemTypes.NEWS
  | ItemTypes.LIST_COLLECTION
  | ItemTypes.SEPARATOR
  | ItemTypes.UNKNOWN;

export type IFormMultiSelectOption<T = {} | IItem> = {
  value: string | number;
  label: string;
  itemType?: ItemTypes;
  type?: string;
  id?: ItemId;
} & T;

export const parseItem = (item: IItem | IFormMultiSelectOption | string): IFormMultiSelectOption => {
  if (!item) {
    return { value: '', label: '' };
  }

  if (typeof item === 'string') {
    return {
      label: item,
      value: item,
    };
  }

  if (item['@type'] === ItemTypes.USER) {
    const contact = item as IContact;
    return {
      ...contact,
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id,
      itemType: ItemTypes.USER,
      type: 'user',
    };
  }

  if (item['$type'] === 'am.news') {
    const news = item as AmGenericNews;
    return {
      ...news,
      label: news.title,
      value: news.id || 0,
      id: news.id || 0,
      itemType: ItemTypes.NEWS,
      type: 'news',
    };
  }

  if (isProduct(item as IProduct)) {
    return {
      ...(item as IProduct),
      label: (item as IProduct).full_title || '',
      value: item.id || 0,
      itemType: ItemTypes.PRODUCT,
    };
  }

  let itemType: ItemTypes = ItemTypes.UNKNOWN;

  if ((item as UmGenericListCollection).type) {
    itemType = ItemTypes.LIST_COLLECTION;
  }

  if ((item as AmGenericAsset3).type?.startsWith('Asset3')) {
    return {
      ...item,
      label: (item as AmGenericAsset3).name || '',
      value: item.id || 0,
      itemType: ItemTypes.ASSET,
    } as IFormMultiSelectOption;
  }
  if (item['@type']?.includes('asset')) {
    itemType = ItemTypes.ASSET;
  }
  if (item['@type']?.includes('group')) {
    itemType = ItemTypes.GROUP;
  }
  if (item['@type'] === 'organization') {
    itemType = ItemTypes.ORGANIZATION;
  }
  if (item['@type']?.includes('category')) {
    itemType = ItemTypes.CATEGORY;
  }
  if ((item as ISeparator).type === 'separator') {
    itemType = ItemTypes.SEPARATOR;
  }

  return {
    ...item,
    label: (item as IFormMultiSelectOption).label || (item as IGroup).name || '',
    value: item.id || (item as IFormMultiSelectOption).value || 0,
    itemType,
    type: itemType,
  } as IFormMultiSelectOption;
};

export const itemRenderer: ICustomItemRenderer<IFormMultiSelectOption> = (
  item,
  { handleClick, modifiers, query },
  options,
) => {
  const { isAncestryMode } = options || {};

  if (!modifiers.matchesPredicate) {
    return null;
  }

  switch (item.itemType) {
    case ItemTypes.USER:
      return <UserSearchItem key={`user-${item.value}`} user={item as IContact} handleSelect={handleClick} />;
    case ItemTypes.ASSET:
      return <AssetSearchItem key={`asset-${item.value}`} asset={item as IAsset} handleSelect={handleClick} />;
    case ItemTypes.NEWS:
      return <NewsSearchItem key={`news-${item.value}`} news={item as AmGenericNews} handleSelect={handleClick} />;
    case ItemTypes.PRODUCT:
      return (
        <ProductSearchItem
          key={`product-${item.value}`}
          product={item as IProduct}
          handleSelect={handleClick}
          isAncestryMode={isAncestryMode}
          isDisabled={modifiers.disabled}
          isNavigationEnabled={modifiers?.isNavigationEnabled}
        />
      );
    case ItemTypes.GROUP:
      return <GroupSearchItem key={`group-${item.value}`} group={item as IGroup} handleSelect={handleClick} />;
    case ItemTypes.ORGANIZATION:
      return (
        <OrganizationSearchItem
          key={`organization-${item.value}`}
          organization={item as IOrganization}
          handleSelect={handleClick}
        />
      );

    case ItemTypes.LIST_COLLECTION:
      return (
        <CollectionSearchItem
          key={`collection-${item.value}`}
          handleSelect={handleClick}
          collection={item as UmGenericListCollection}
        />
      );
    case ItemTypes.CATEGORY:
      return (
        <CategorySelectedTabItem
          key={`category-${item.value}`}
          category={item as ICategory}
          handleSelect={handleClick}
        />
      );

    default:
      return (
        <Menu>
          <Menu.Item
            className={cx({ active: modifiers.active })}
            disabled={modifiers.disabled}
            key={item.value || item.label}
            onClick={handleClick}
          >
            {highlightText(item.label, query)}
          </Menu.Item>
        </Menu>
      );
  }
};
