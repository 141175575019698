import React from 'react';

import { FormDate, FormInput, FormSelect, IUseFormReturn } from 'helpers/form';
import { minBirthDate, maxBirthDate } from 'utils/constants';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { InfoBoxV2 } from 'components/info-box-v2';
import { IContact } from 'types';

const ContactPersonalDataSection: React.FC<IUseFormReturn<IContact>> = ({ formData, handlers }) => {
  return (
    <FormSectionWrapperV2 title="PERSONAL DATA" gridTemplateColumns="1fr" wrapperClassName="py-3" withBorder>
      <InfoBoxV2 className="entity-detail__infobox-wrapper" padded={false}>
        <FormSelect large name="title" label="Title" {...handlers} {...formData.title} />
        <FormInput name="last_name" label="Last Name" {...handlers} {...formData.last_name} />
        <FormInput name="first_name" label="First Name" {...handlers} {...formData.first_name} />
        <FormDate
          key="date_of_birth"
          name="date_of_birth"
          label="Birthday"
          large
          minDate={minBirthDate}
          maxDate={maxBirthDate}
          {...formData.date_of_birth}
          {...handlers}
          hideTodayButton
        />
        <FormInput name="middle_name" label="Middle Name" {...handlers} {...formData.middle_name} />
        <FormSelect large name="gender" label="Gender" {...handlers} {...formData.gender} />
      </InfoBoxV2>
    </FormSectionWrapperV2>
  );
};

export default ContactPersonalDataSection;
