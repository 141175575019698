import { IAction, IActionConfig, IActionName, IUseActionsOptionParam } from './types';
import { cleanUpDividerActions } from './utils';
import { useSessionStore } from 'store/session-store';

export const useActions = <T, D extends IActionName>(
  actions: IActionConfig<T, D>[],
  options: IUseActionsOptionParam,
  items: (T | undefined | null)[] = [],
): IAction<D>[] => {
  const user = useSessionStore((state) => state.user);

  const meaningfulItems = items.filter((item): item is T => Boolean(item));
  if (!meaningfulItems.length) {
    return [];
  }

  const actionsList = actions.reduce((acc: IAction<D>[], { shouldDisplay = () => true, ...action }, index) => {
    const shouldDisplayAction = shouldDisplay(action, index, user, meaningfulItems, options);
    if (shouldDisplayAction) {
      return [...acc, action];
    }

    return acc;
  }, []);

  return cleanUpDividerActions(actionsList);
};
