import { useEffect, useState } from 'react';

import { IStorage } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';
import { getStorage } from 'utils/apis/asset';

const getOptionsFromStorages = (storages: IStorage[]): Partial<IUseActionsOptionParam> => {
  const hasRestorableArchive = storages.some(({ is_restorable }) => is_restorable);
  const hasArchive = storages.some((storage) => storage.is_archive && storage['@type'] != 'storage/external_archive');
  return { hasArchive, hasRestorableArchive };
};

export const useAssetActionsOptions = (): Partial<IUseActionsOptionParam> => {
  const [options, setOptions] = useState<Partial<IUseActionsOptionParam>>({});

  useEffect(() => {
    getStorage().then(getOptionsFromStorages).then(setOptions);
  }, []);

  return options;
};
