import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import DateElement from 'components/date-list-element/date-list-element';

import useSelectedOption from 'utils/hooks/selected-option';

import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { fetchProductThumbnailImages, ListItemCountLink } from 'utils/ui';
import { placeholder as ph } from 'utils/ui';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, IAccessPrivilege } from 'types';

import './style.scss';

interface IAccessPrivilegeDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  accessPrivilege: IAccessPrivilege;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick: (e) => void;
  isActive: boolean;
}

export const AccessPrivilegeDataSectionListItem: React.FC<IAccessPrivilegeDataSectionListItemProps> = ({
  accessPrivilege,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
  isActive,
}) => {
  const { id, name, created_at, updated_at, expires_at, pm_statistics_data, owner, access_level } = accessPrivilege;

  const fetcher = useCallback(() => fetchProductThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);

  const selectedOption = useSelectedOption({ value: access_level });
  const detailsPageLink = `${Routes.ACCESS_PRIVILEGES}/${id}`;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div
      className={cx('entity-list-item', {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(accessPrivilege),
      })}
      onClick={handleClick}
    >
      <div className="entity-list-item-row">
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="selections-list-item__thumb">
          <MemoizedMultiThumbnail thumbnailImages={thumbnailImages || [{}]} />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={name}>
            <Link to={detailsPageLink}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className="access-privilege-list-item__attributes muted-text">
            <div className="text-truncate access-privilege-list-item__products">
              <ListItemCountLink
                entityCount={pm_statistics_data?.count}
                link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
                linkText="Product"
              />
            </div>
            <div className="text-truncate access-privilege-list-item__owner">{ph(formatFullName(owner, false))}</div>

            <div className="text-truncate access-privilege-list-item__created">
              <DateElement date={created_at} mfxIcon="created-at" />
            </div>
            <div className="text-truncate access-privilege-list-item__updated">
              <DateElement date={updated_at} mfxIcon="updated-at" />
            </div>
            <div className="text-truncate access-privilege-list-item__expires">
              <DateElement date={expires_at} mfxIcon="expires-at" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
