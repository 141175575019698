import React, { useCallback, useState } from 'react';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { fetchRoles } from 'utils/apis/role';
import { FormSubmitSection } from 'components/form-submit-section';

import { shouldDisplayReInviteAction as shouldDisplay } from './actions-acl';
import { inviteContacts } from './api';
import { FormSelect, useFetchFieldOptions } from 'helpers/form';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';
import { pluralEntityInflect } from 'utils/general';

const validation = { valid: true };

const Body: React.FC<{ onConfirm: (values: { roleId: number }) => void }> = ({ onConfirm }) => {
  const roleOptions = useFetchFieldOptions(fetchRoles);
  const [roleId, setLocalRoleId] = useState<number>(0);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onConfirm({ roleId });
    },
    [onConfirm, roleId],
  );

  const handleChange = useCallback(({ role_id }: { role_id: number }): void => {
    setLocalRoleId(role_id);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormSelect
        label="Platform Role"
        name="role_id"
        options={roleOptions}
        onChange={handleChange}
        value={roleId}
        required
        touched={false}
        validation={validation}
        withinPortal
      />
      <FormSubmitSection
        labels={{
          confirm: 'Invite',
        }}
        submitDisabled={!roleId}
      />
    </form>
  );
};

export const useReInviteContactAction = (items: IContact[], options: IUseActionsOptionParam): IContactActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const haveRoleId = items.every(({ role_id }) => Boolean(role_id));

  const onConfirm = useCallback(
    async ({ roleId }): Promise<void> => {
      const { entityWithCount, entity } = pluralEntityInflect('Contact', items.length);

      try {
        const contactIds = items.map(({ id }) => id);
        await inviteContacts(contactIds, roleId);
        toastStore.success(`${entityWithCount} invited`);
        await options?.onSuccess?.(IContactActionName.INVITE);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(`${entity} invitation failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [items, toastStore, options, dialogStore],
  );

  const body = useCallback((): JSX.Element => {
    return <Body onConfirm={onConfirm} />;
  }, [onConfirm]);

  const handler = useCallback(() => {
    dialogStore.openModal({
      title: `Re-Invite Contact`,
      body,
    });
  }, [body, dialogStore]);

  return {
    name: IContactActionName.INVITE,
    shouldDisplay,
    icon: 'import',
    title: 'Re-Invite',
    handler: haveRoleId ? onConfirm : handler,
  };
};
