import { AmGenericNews } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useDeleteAction } from './delete';
import { useEditAction } from './edit';
import { INewsActionName } from './types';
import { useUploadPreviewAction } from './upload-preview';
import { useDeletePreviewAction } from './delete-preview';
import { useManageSliderShowcase } from './manage-showcase';

export const useNewsActions = (
  items: AmGenericNews[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<INewsActionName> => {
  const editAction = useEditAction(items, options);
  const uploadPreview = useUploadPreviewAction(items, options);
  const deletePreview = useDeletePreviewAction(items, options);
  const deleteAction = useDeleteAction(items, options);
  const manageSlider = useManageSliderShowcase(items, options);

  const actions = [editAction, manageSlider, uploadPreview, deletePreview, deleteAction];

  return useActions<AmGenericNews, INewsActionName>(actions, options, items);
};
