import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Thumbnail } from 'components/thumbnail';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { AssetVideoPlayer } from 'components/common';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { ThumbnailFileStatus } from 'components/file-status';
import { AssetStatusIndicator } from 'components/asset';

import { formatDate } from 'utils/date';
import { IAsset, IMm3Asset, DetailsPageTabs, IAssetAggregation } from 'types';
import { AssetFilters, getIngestStatus, getPreviewImageUrl } from 'utils/asset';
import { AssetGeneralTabAboutSection } from 'pages/asset-details/asset-general-tab/asset-about-section';
import AudioPlayer from 'components/audio-player/custom-audio-player';
import { getProductsByAssetId } from 'utils/apis/product';
import { formatField } from 'utils/general';
import { useRemote } from 'utils/hooks';

import './style.scss';

interface IAssetGeneralTabProps {
  asset?: IAsset | IMm3Asset;
  setActiveTab: (tab: DetailsPageTabs) => void;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  subAssetsAggregations?: IAssetAggregation;
}

const showThumbnailTypes = new Set(['image', 'document']);

const AssetGeneralTab: React.FC<IAssetGeneralTabProps> = ({ asset, setActiveTab, setAsset, subAssetsAggregations }) => {
  const {
    id,
    access_level,
    created_at,
    updated_at,
    main_classification,
    status,
    permissions,
    protection_levels,
    published_at,
    preview_image,
    sharable,
  } = asset || {};

  const fetcher = useCallback(() => getProductsByAssetId(id), [id]);
  const [products] = useRemote(fetcher, []);
  const products_count = products?.length || 0;

  const productsSectionMessage = useMemo((): string | JSX.Element => {
    const message = products_count > 1 ? `${products_count - 1} +More` : `1 Product`;
    return products_count ? (
      <>
        <Thumbnail image={products?.[0]?.preview_image?.url} />
        <Link
          to={`${Routes.ASSETS}/${id}/${DetailsPageTabs.PRODUCTS}`}
          onClick={() => setActiveTab(DetailsPageTabs.PRODUCTS)}
        >
          {message}
        </Link>
      </>
    ) : (
      '0 Products'
    );
  }, [id, products, products_count, setActiveTab]);

  const isVideo = main_classification === AssetFilters.VIDEO;
  const isAudio = main_classification === AssetFilters.AUDIO;
  const showThumbnail = showThumbnailTypes.has(main_classification || '');

  return (
    <div className="asset-general-tab h-100">
      <div className="asset-general-tab__wrapper h-100">
        <div className="asset-general-tab__info">
          <AssetGeneralTabAboutSection asset={asset} subAssetsAggregations={subAssetsAggregations} />
          <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Settings" wrapperClassName="mt-3">
            <Row
              label="Status"
              value={
                <>
                  {status} <ScheduledChangeIndicator entity={asset} type="status" />
                  {asset && <AssetStatusIndicator asset={asset} setAsset={setAsset} />}
                </>
              }
              size="m"
            />
            <Row
              label="Access Level"
              value={
                <>
                  {access_level} <ScheduledChangeIndicator entity={asset} type="access" />
                </>
              }
              size="m"
            />
            <Row label="Sharable" value={yn(sharable)} size="m" />
            <Row
              label="Permissions"
              value={
                <>
                  {formatField(permissions)} <ScheduledChangeIndicator entity={asset} type="permissions" />
                </>
              }
              size="m"
            />
            <Row
              label="Protection Levels"
              value={
                <>
                  {formatField(protection_levels)} <ScheduledChangeIndicator entity={asset} type="protection" />
                </>
              }
              size="m"
            />
          </InfoBoxV2>
          <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Timestamps" wrapperClassName="mt-3">
            <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
            <Row label="Last Updated" value={formatDate(updated_at)} size="m" />
            <Row label="Published at" rawLabel value={formatDate(published_at)} size="m" />
          </InfoBoxV2>

          <div className="asset-general-tab__product mt-3">
            <div className="asset-general-tab__product-title">Product(s)</div>
            <div className="asset-general-tab__product-thumb">{productsSectionMessage}</div>
          </div>
        </div>

        <div className="asset-general-tab__preview-area">
          {showThumbnail && (
            <Thumbnail
              size="hd"
              image={getPreviewImageUrl(preview_image)}
              placeholder={<ThumbnailFileStatus status={getIngestStatus(asset)} size={35} withBackground />}
            />
          )}

          {isVideo ? <AssetVideoPlayer video={asset} /> : <></>}
          {isAudio ? <AudioPlayer asset={asset} key={asset?.id} /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AssetGeneralTab;
