import React from 'react';
import { isUndefined } from 'lodash';
import { IAnalyticsListProps, IAnalyticsTableData, IAnalyticsTableItem, IGetAnalyticsTableDataParams } from 'types';

import { IColumn, ListTable } from 'components/list-table';
import { recommendationsReceived } from 'components/analytics/queries/contact-queries';

import { fetchRecommendationContactsInfo } from './utils';

import { exportAnalyticsTableData, getDetailedAnalyticsTableData } from 'utils/apis/analytics';

interface IRecommendationAnalyticsListTableProps extends IAnalyticsListProps {
  recipientIds?: string[];
  senderIds?: string[];
  columns: IColumn[];
  headerClassName?: string;
  tableClassName?: string;
  onRowClick?: (e, rowData) => void;
}
const getTableData = (params: IGetAnalyticsTableDataParams): Promise<IAnalyticsTableData<IAnalyticsTableItem>> => {
  return params.query_name === recommendationsReceived.recommendationReceivedList
    ? getDetailedAnalyticsTableData(params, true, fetchRecommendationContactsInfo)
    : getDetailedAnalyticsTableData(params, false);
};

const RecommendationAnalyticsListTable: React.FC<IRecommendationAnalyticsListTableProps> = ({
  title,
  entity,
  queryName,
  entityId,
  dateRange,
  userIds,
  geoScopeIds,
  headerClassName,
  tableClassName,
  columns,
  recipientIds,
  senderIds,
  includeNonScreeningsAssets,
  onRowClick,
}) => {
  const onlyScreenings = !isUndefined(includeNonScreeningsAssets) && !includeNonScreeningsAssets;

  return (
    <ListTable
      entity={entity}
      title={title}
      entityId={entityId}
      query_name={queryName}
      from_date={dateRange[0]}
      to_date={dateRange[1]}
      user_ids={userIds}
      geo_scope_ids={geoScopeIds}
      columns={columns}
      recipientIds={recipientIds}
      senderIds={senderIds}
      only_screenings={onlyScreenings}
      getTableData={getTableData}
      exportTableData={exportAnalyticsTableData}
      className="analytics-list-table__recommendation-sent-list"
      headerClassName={headerClassName}
      tableClassName={tableClassName}
      onRowClick={onRowClick}
    />
  );
};

export default RecommendationAnalyticsListTable;
