import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { ISelectionDataSectionWrappedComponent } from './types';

import './style.scss';
import { getEntityLabel } from 'utils/general';
import startCase from 'lodash/startCase';

export const SelectionDataSectionTilesItem: React.FC<ISelectionDataSectionWrappedComponent> = ({
  selection,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const { name, main_entity_type, entityCount } = selection;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div className="default-selections-list-item-tile">
      <div className="default-selections-list-item-tile__actions mb-2">
        <div>
          <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
        </div>
      </div>
      <div className="default-selections-list-item__title">
        <h4 className="text-nowrap text-truncate">{name}</h4>
      </div>
      <div>
        {entityCount} {startCase(getEntityLabel(main_entity_type))}
      </div>
    </div>
  );
};
