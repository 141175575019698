import { IOption, IOptionColor } from 'types';
import { IActiveOption } from 'components/slider-select/types';
import { useMemo } from 'react';
import { ACCESS_LEVELS_OPTIONS } from 'utils/constants';

export interface IUseSelectedOption {
  options?: IOption[];
  value?: string;
}

const getDefaultColor = (index: number): IOptionColor => {
  const colors = Object.values(IOptionColor);
  return colors[index % colors.length];
};

const useSelectedOption = ({ value, options = ACCESS_LEVELS_OPTIONS }: IUseSelectedOption): IActiveOption | undefined =>
  useMemo(() => {
    if (!value) {
      return;
    }
    const order = options.findIndex((option) => option.value === value);
    if (order === -1) {
      return;
    }
    const option = options[order];
    return {
      ...option,
      color: option.color || getDefaultColor(order),
      order,
    };
  }, [options, value]);

export default useSelectedOption;
