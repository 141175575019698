import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import ContactDataSection from 'components/contact-data-section';

import { Pages } from 'utils/actions/types';
import { IFilterOption } from 'utils/hooks/control-data-section';

const page = Pages.ORGANIZATION_CONTACTS_TAB;

const OrganizationContactsTab: React.FC = observer(() => {
  const { organizationId = '' } = useParams<{ organizationId: string }>();

  const persistentFilters: IFilterOption[] = useMemo(
    () => [['organization_id', 'eq', organizationId]],
    [organizationId],
  );
  if (!organizationId) {
    return <></>;
  }

  return <ContactDataSection page={page} persistentFilters={persistentFilters} />;
});

export default OrganizationContactsTab;
