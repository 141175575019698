import React from 'react';
import { Classes } from 'utils/ui';
import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

import { Routes } from 'utils/routes';
import DateElement from 'components/date-list-element';

import { DetailsPageTabs, IProductAsset } from 'types';
import ProductAssets from 'components/asset/assets-aggregation/product-assets';
import { formatLayerDuration } from 'utils/date';
import { formatField } from 'utils/general';
import { useLanguages } from 'utils/hooks';
import { placeholder, CategoriesInfo } from 'utils/ui';

type IProduct = PmItvstudiosProduct3 & { assets: IProductAsset[] };

export const ProductListItemAttributes: React.FC<{ product: IProduct }> = ({ product }) => {
  const { default_layer, published_at, updated_at } = product;
  const { meta } = (default_layer || {}) as PmItvstudiosLayer3MotionPictureStandard;
  const { category_ids, product_state, resolutions, year_of_production, language_ids } = meta || {};

  const languagesInfo = useLanguages(language_ids);

  return (
    <div className={`product-list-item-row__attributes ${Classes.TEXT_MUTED}`}>
      <div className="product-list-item-row__production text-nowrap text-truncate">
        {formatField(year_of_production)}
      </div>
      <div className="text-truncate product-list-item-row__duration">
        {placeholder(formatLayerDuration(default_layer))}
      </div>
      <ProductAssets
        baseUrl={`${Routes.PRODUCTS}/${product?.id}/${DetailsPageTabs.ASSETS}`}
        assets={product?.assets}
        fallback="—"
        className="text-truncate product-list-item-row__shortcut d-block"
      />

      <div className="text-truncate product-list-item-row__resolutions">{formatField(resolutions)}</div>
      <div className="product-list-item-row__genre text-truncate text-nowrap">
        <CategoriesInfo category_ids={category_ids} />
      </div>
      <div className="text-truncate product-list-item-row__languages">{placeholder(languagesInfo)}</div>
      <div className="text-truncate product-list-item-row__state">{formatField(product_state)}</div>

      <DateElement className="text-truncate product-list-item-row__updated" date={updated_at} mfxIcon="updated-at" />

      <DateElement
        date={published_at}
        mfxIcon="published-at"
        className="text-truncate product-list-item-row__published"
      />
    </div>
  );
};
