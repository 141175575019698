import React from 'react';
import { AmGenericNews } from '@mediafellows/mm3-types';
import pick from 'lodash/pick';

import { IUseActionsOptionParam } from 'utils/actions';
import { useNewsForm } from 'components/news/news-form/use-news';
import { updateNews } from 'utils/apis/news';
import { useDialogStore, useStore } from 'store';
import { INewsActionConfig, INewsActionName } from './types';
import { ToastError } from 'components/toast';
import { shouldDisplayEdit } from './action-acl';
import { delay } from 'utils/delay';

export const useEditAction = (
  newsList: AmGenericNews[],
  options: IUseActionsOptionParam<AmGenericNews>,
): INewsActionConfig => {
  const { toastStore } = useStore();
  const { close } = useDialogStore();
  const handleSubmit = async (values: AmGenericNews): Promise<AmGenericNews | void> => {
    try {
      const news = await updateNews(values);
      toastStore.success(`News item has been edited successfully!`);
      await delay();
      await options?.onSuccess?.(INewsActionName.EDIT, news);
      close();
      return news;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  };

  const handler = useNewsForm({
    title: 'Edit News item',
    news: pick(newsList[0], ['id', 'title', 'access_level', 'date', 'meta']) as AmGenericNews,
    handleSubmit,
  });

  return {
    name: INewsActionName.EDIT,
    shouldDisplay: shouldDisplayEdit,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
