import React from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { Classes } from 'utils/ui';

import './style.scss';

const RecommendationSelectedTabItem: React.FC<{ recommendation: McGenericRecommendationProduct }> = observer(
  ({ recommendation }) => {
    const { subject, status } = recommendation;
    return (
      <div className="recommendation-selected-tab px-2">
        <div className="text-truncate mb-1">{subject}</div>
        <div className="d-flex">
          <span className={Classes.TEXT_MUTED}>{status}</span>
        </div>
      </div>
    );
  },
);

export default RecommendationSelectedTabItem;
