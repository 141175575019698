import React from 'react';

import { IItemActionsMenuProps } from './utils';
import { useCastCrewActions } from 'utils/actions/cast-crew';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { renderMenuActionItems } from 'utils/ui';

export const CastCrewActionsList: React.FC<IItemActionsMenuProps<PmGenericCastCrew>> = (props) => {
  const { items, options } = props;

  const actions = useCastCrewActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
