import { IAssetPresetFields, IAssetFile } from 'types';

export { formatFileFields } from 'utils/assets-upload-format-file-fields';

export function applyPrefixToAsset(preset: Partial<IAssetPresetFields>, files: IAssetFile[]): IAssetFile[] {
  return files.map((file) => (file.selected ? { ...file, ...preset } : file));
}

export const parseFileName = (originalName: string): string => {
  return (originalName || '').replaceAll(/[^a-zA-Z0-9._]/g, '-');
};
