export const contextExtension = {
  properties: {
    scheduled_for: {
      required: true,
      validations: [],
    },
  },
};

export const accessContextExtension = {
  properties: {
    ...contextExtension.properties,
    access_level: {
      required: true,
    },
  },
};

export const statusContextExtension = {
  properties: {
    ...contextExtension.properties,
    status: {
      required: true,
    },
  },
};

export const protectionContextExtension = {
  properties: {
    ...contextExtension.properties,
    protection_levels: {
      required: true,
    },
  },
};
