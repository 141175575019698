import { useCallback } from 'react';

import { useStore } from 'store';

import { IUseActionsOptionParam, Pages } from 'utils/actions/types';
import { deleteGroups } from 'utils/apis/groups';
import getPathAfterContentDeletion from 'utils/get-path-after-content-deletion';
import { ISelectionActionConfig, ISelectionActionName } from 'utils/actions/selection/types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { shouldDisplayDeleteSelection as shouldDisplay } from './actions-acl';

import { ISelectionStandardized } from 'types';
import { IShouldDisplaySelectionActionFunction } from './types';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export interface IUseDeleteActionOptions extends IUseActionsOptionParam {
  confirmationMessage?: string;
  shouldDisplay?: IShouldDisplaySelectionActionFunction;
}

export const useDeleteAction = (
  items: ISelectionStandardized[],
  options: IUseDeleteActionOptions,
): ISelectionActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Selection', items.length);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id);
      await deleteGroups(itemIds);
      if (options.page === Pages.DETAILS) {
        navigate({
          pathname: getPathAfterContentDeletion(location.pathname, itemIds[0]),
          search: location.search,
        });
      } else {
        options?.onSuccess?.(ISelectionActionName.DELETE);
      }

      toastStore.success(`${entityWithCount} deleted`);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore, location, navigate]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: options?.confirmationMessage ?? `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete, options?.confirmationMessage]);

  return {
    name: ISelectionActionName.DELETE,
    shouldDisplay: options?.shouldDisplay ?? shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
