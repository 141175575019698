import { IPassOnDataFields } from 'utils/actions/types';
import { IChecklistField } from 'helpers/form/types';

export const initialValues: IPassOnDataFields = {
  fields: [],
};

export const fieldsInfo: IChecklistField[] = [
  { label: 'Acclaim', name: 'acclaim' },
  { label: 'Additional Information', name: 'additional_information' },
  { label: 'Aka', name: 'aka' },
  { label: 'Availability', name: 'availability' },
  { label: 'Awards Text', name: 'awards_text' },
  { label: 'Brand Name', name: 'brand_name' },
  { label: 'Copyright', name: 'copyright' },
  { label: 'Copyright Notice', name: 'copyright_notice' },
  { label: 'Countries Of Origin', name: 'country_ids' },
  { label: 'Display Definition', name: 'display_definition' },
  { label: 'Duration', name: 'duration' },
  { label: 'Duration Text', name: 'duration_text' },
  { label: 'Episode Production Number', name: 'episode_production_no' },
  { label: 'Format', name: 'format_id' },
  { label: 'Genres', name: 'category_ids' },
  { label: 'Gross Box Office', name: 'gross_box_office' },
  { label: 'Highlights', name: 'highlights' },
  { label: 'Identifier Accounting Reference', name: 'identifier_accounting_reference' },
  { label: 'Identifier Archive Number', name: 'identifier_archive_number' },
  { label: 'Identifier CRID', name: 'identifier_crid' },
  { label: 'Identifier GUID', name: 'identifier_guid' },
  { label: 'Identifier IMDb URL', name: 'identifier_imdb' },
  { label: 'Identifier ISAN', name: 'identifier_isan' },
  { label: 'Identifier ISBN', name: 'identifier_isbn' },
  { label: 'Identifier ISWC', name: 'identifier_iswc' },
  { label: 'Identifier UMID', name: 'identifier_umid' },
  { label: 'Identifier UPID', name: 'identifier_upid' },
  { label: 'Identifier URI/URL', name: 'identifier_uri' },
  { label: 'Identifier V-ISAN', name: 'identifier_v_isan' },
  { label: 'Intended Audiences', name: 'intended_audience_ids' },
  { label: 'Intended Distribution', name: 'intended_distribution_id' },
  { label: 'Internal Rating', name: 'internal_rating' },
  { label: 'Library', name: 'library' },
  { label: 'Logline', name: 'logline' },
  { label: 'Long Synopsis', name: 'long_synopsis' },
  { label: 'Network', name: 'network' },
  { label: 'Original Broadcaster', name: 'original_broadcaster' },
  { label: 'Original Release Date', name: 'original_release_date' },
  { label: 'Produced By', name: 'produced_by' },
  { label: 'Product State', name: 'product_state' },
  { label: 'Product Type', name: 'product_type' },
  { label: 'Rating', name: 'rating' },
  { label: 'Resolution', name: 'resolution' },
  { label: 'Rights Holder', name: 'rights_holder' },
  { label: 'Subtitle', name: 'subtitle' },
  { label: 'Synopsis', name: 'synopsis' },
  { label: 'Title', name: 'title' },
  { label: 'Topics', name: 'topic_ids' },
  { label: 'Trademark', name: 'trademark' },
  { label: 'Web Page', name: 'web_page' },
  { label: 'Year of Production', name: 'year_of_production' },
];

export const fields: string[] = fieldsInfo.map((e) => e.name);
