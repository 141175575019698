import React from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { Globals } from 'types';
import { toggleProfileSettings } from 'utils/toggle-profile-settings';
import { MfxIcon } from 'icons';
import { IMfxIconName } from 'icons/components/icon-names';
import { useDataSectionStore, useProfileStore } from 'store';

const ListTileViewToggleButton: React.FC<{}> = () => {
  const isListViewActive = useProfileStore(({ settings }) => settings.isListViewActive);
  const { searchStore } = useDataSectionStore();
  const { running } = searchStore || {};

  const handleTileViewToggle = (): void => {
    toggleProfileSettings('isListViewActive', Globals.DATASECTION_TILE_VIEW_LS);
  };

  return (
    <Tooltip label={`Switch to ${isListViewActive ? 'Tile View' : 'List View'}`} position="bottom">
      <ActionIcon
        className="list-tile-view-toggle-button me-1"
        color="gray.5"
        radius="sm"
        variant="subtle"
        disabled={running}
        onClick={handleTileViewToggle}
      >
        <MfxIcon icon={`${isListViewActive ? 'tile-view' : 'list-view'}` as IMfxIconName} />
      </ActionIcon>
    </Tooltip>
  );
};

export default ListTileViewToggleButton;
