import { IBarChartData } from 'types/analytics';
import { by } from 'utils/general';

export function getSmallestStep(positions: number[]): number {
  if (positions?.length < 3) {
    return 10;
  }

  let smallestStep = positions[1] - positions[0];
  for (let index = 1; index < positions.length - 2; index++) {
    const step = positions[index + 1] - positions[index];
    if (step < smallestStep && step > 1) {
      smallestStep = step;
    }
  }

  return smallestStep;
}

interface IData {
  count: number;
  position: number;
  max_duration: number;
}
export function fillMissingItems(data: IBarChartData[]): IData[] {
  const positions = data.map((e) => parseInt(`${e.position}`)).sort((a, b) => a - b);

  const max_duration = Number(data?.[0]?.max_duration) || 0;
  const dataByPosition = by(data, 'position');

  const result: IData[] = [];
  const smallestStep = getSmallestStep(positions);
  for (let index = 0; index < max_duration; index += smallestStep) {
    const { count = 0 } = dataByPosition[index] || {};
    result.push({ position: index, count: Number(count), max_duration });
  }

  return result;
}
