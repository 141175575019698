import React, { useCallback } from 'react';

import { IConference } from 'types';
import { IUseFormReturn } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { useRemote } from 'utils/hooks';

import { formatDate } from 'utils/date';
import { loadContact } from 'pages/contact-details/api';
import { getContactName } from 'utils/ui';

const contactSchema = 'id, first_name, last_name';

export const ConferenceCreateSummary: React.FC<{ form: IUseFormReturn<Partial<IConference>> }> = ({
  form: { values },
}) => {
  const fetchContact = useCallback(() => {
    return loadContact(values?.owner_id?.toString() || '', contactSchema);
  }, [values.owner_id]);

  const [contact] = useRemote(fetchContact);

  return (
    <FormSectionWrapperV2 padded={false}>
      <Row label="Title" value={values.title} />
      <Row label="Type" value={values.mode} />
      <Row label="Moderator" value={getContactName(contact)} />

      <Row label="Start time" value={formatDate(values.starts_at)} hideIfNoValue />
      <Row label="End time" value={formatDate(values.ends_at)} hideIfNoValue />
      <Row label="Disable waiting room" value={yn(values.disable_waitingroom)} />
      <Row label="Assets" value={values.asset_ids?.length || 0} />
      <Row label="Contacts" value={values.user_ids?.length || 0} />
    </FormSectionWrapperV2>
  );
};
