import React, { useCallback, useState } from 'react';
import { Button, Popover, NavLink } from '@mantine/core';
import { filter } from 'lodash';
import cx from 'classnames';

import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { ToastError } from 'components/toast';
import { IWizardStepButton } from 'components/wizard/utils';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { createDeliveryDraft, updateDeliveryDraft, sendDeliveryPackage } from 'utils/apis/package-delivery';
import { updateConnection, createConnection } from 'utils/apis/delivery-connections';
import { loadOrganizationDeliveryInfos } from 'pages/organization-details/api';
import { Intent } from 'utils/ui';
import { useRemote } from 'utils/hooks';
import { useStore } from 'store';

import { StepIds, buildDeliveryContent, getFutureDateTime, removeInternalAttributes } from './utils';
import { IDeliveryConnection } from './connections/types';
import { IIdentifiable, IOrganization } from 'types';

import './style.scss';
import { ArrowLeft } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

type IWizardProps<T extends DeliveriesGenericDeliveryPackage> = {
  nextButton: IWizardStepButton;
  backButton: IWizardStepButton;
  currentStepId: number;
  onFinish: (delivery: T) => void;
  form: IUseMm3FormReturn<ICreateWorkFlowDeliveryPayload<T>>;
  connectionForm: IUseMm3FormReturn<IDeliveryConnection>;
  onConnectionValidating: (value: boolean) => void;
};

export function WizardButtons<T extends DeliveriesGenericDeliveryPackage>({
  backButton,
  nextButton,
  currentStepId,
  form,
  connectionForm,
  onFinish,
  onConnectionValidating,
}: IWizardProps<T>): React.ReactElement {
  const { toastStore, dialogStore } = useStore();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [validating, setIsValidating] = useState<boolean>(false);
  const organizationId = form.values.organization_id;

  const saveConnection = useCallback(async () => {
    const connection = connectionForm.values;
    try {
      setIsValidating(true);
      onConnectionValidating(true);
      const action = connection?.id ? updateConnection : createConnection;
      toastStore.info('Validating connection...');
      const newConnection = await action(connection);
      form.handlers.onChange({ connection_id: newConnection.id } as Partial<ICreateWorkFlowDeliveryPayload<T>>);

      toastStore.success('Connection validated!');
      return nextButton.action();
    } catch (error) {
      toastStore.clearAll();
      toastStore.error(<ToastError error={error} placeholder="Something went wrong!" />);
    } finally {
      setIsValidating(false);
      onConnectionValidating(false);
    }
  }, [connectionForm.values, form.handlers, onConnectionValidating, nextButton, toastStore]);

  const deliverPackage = useCallback(
    async (e) => {
      try {
        setIsSending(true);
        e.preventDefault();
        const deliveryContent = buildDeliveryContent(form.values) as DeliveriesGenericDeliveryPackage['delivery_items'];

        const scheduled_at = form.values.scheduled_at ?? getFutureDateTime(5);
        let draft: Partial<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>> = {
          ...removeInternalAttributes<T>(form.values),
          delivery_items: deliveryContent,
          scheduled_at,
        };

        if (!draft.id) {
          draft = await createDeliveryDraft(draft);
        }

        form.values.id && (await updateDeliveryDraft(draft));

        const deliveryPackage = await sendDeliveryPackage(draft).catch((err) => {
          throw err;
        });

        onFinish?.(deliveryPackage as T);
        dialogStore.close();
        const toasterSucessMsg = 'Package scheduled for delivery';
        toastStore.success(toasterSucessMsg);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong!" />);
      } finally {
        setIsSending(false);
      }
    },
    [dialogStore, form.values, onFinish, toastStore],
  );

  const fetchOrganizationDeliveryInfos = useCallback(async (): Promise<(IOrganization & IIdentifiable) | undefined> => {
    return organizationId ? await loadOrganizationDeliveryInfos(organizationId.toString()) : undefined;
  }, [organizationId]);

  const [organization] = useRemote<IOrganization & IIdentifiable>(fetchOrganizationDeliveryInfos);

  const deliveryInformations = filter(organization?.delivery_informations, { label: 'Delivery Specifications' }) || [];

  return (
    <div className="d-flex w-100 gap-2">
      <Button
        className={cx('me-auto', { invisible: backButton.hide })}
        onClick={backButton.action}
        disabled={backButton.disabled}
        variant="subtle"
        leftSection={<MantineIcon icon={<ArrowLeft />} />}
      >
        {backButton.title}
      </Button>

      {currentStepId === StepIds.Assets && !!deliveryInformations.length && (
        <div className="delivery-menu-options">
          <Popover position="top" withArrow shadow="md">
            <Popover.Target>
              <Button variant={Intent.PRIMARY}>Check Specifications</Button>
            </Popover.Target>
            <Popover.Dropdown>
              {deliveryInformations?.map((deliveryInfo) => (
                <NavLink
                  key={deliveryInfo.id}
                  label={deliveryInfo.spec_type}
                  href={deliveryInfo.page_url || deliveryInfo.document}
                  target="_blank"
                />
              ))}
            </Popover.Dropdown>
          </Popover>
        </div>
      )}

      {currentStepId !== StepIds.Recipients && (
        <Button
          variant="primary"
          onClick={nextButton.action}
          disabled={nextButton.disabled || form.isSending || isSending}
        >
          {nextButton.title}
        </Button>
      )}

      {currentStepId === StepIds.Recipients && (
        <Button
          disabled={nextButton.disabled || form.isSending || isSending}
          variant="primary"
          loading={validating}
          onClick={saveConnection}
        >
          Next
        </Button>
      )}

      {currentStepId === StepIds.Summary && (
        <Button
          disabled={nextButton.disabled || form.isSending || isSending}
          variant="primary"
          onClick={deliverPackage}
        >
          Deliver
        </Button>
      )}
    </div>
  );
}
