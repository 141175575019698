import React, { useState } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IconAlias } from 'icons';

import { IItemId } from 'components/form-selection-items';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';

import {
  assetsKeys,
  messageKeys,
  productKeys,
  settingsKeys,
  RecommendProductsStepMessage,
  RecommendProductsStepRecipients,
  RecommendProductsStepSettings,
  RecommendProductsStepSummary,
  StepIds,
  WizardButtons,
  getTabId,
} from 'components/recommendation/recommendation-shared';

import { FormAssetTreeSelector, isValidMM3 } from 'helpers/form';

import { IRecommendProductsStep } from './types';
import { RecommendProductsStepProducts } from './recommend-products-step-products';

export const RecommendProductsWizard: React.FC<
  IRecommendProductsStep & {
    allAssetsSelected?: boolean;
    onFinish: (recommendation: McGenericRecommendationProduct) => void;
  }
> = ({ form, tab, onFinish, allAssetsSelected }) => {
  const { recipients } = form.values || {};
  const invalidRecipients = recipients.length < 1;
  const [loadingAssets, setLoadingAssets] = useState(true);

  const steps = [
    { id: StepIds.Products, disabled: !isValidMM3(form.formData, productKeys) },
    { id: StepIds.Assets, disabled: !isValidMM3(form.formData, assetsKeys) || loadingAssets },
    { id: StepIds.Message, disabled: !isValidMM3(form.formData, messageKeys) },

    {
      id: StepIds.Recipients,
      disabled: invalidRecipients,
    },
    { id: StepIds.Settings, disabled: !isValidMM3(form.formData, settingsKeys) },

    {
      id: StepIds.Summary,
      nextButtonTitle: 'Save draft',
      action: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        form.onSubmit(evt);
      },
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps, {
    defaultStepId: getTabId(tab as IItemId),
  });

  return (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      showStepTitleIndex
      fitInParent
      footerPadded={false}
      footer={<WizardButtons {...{ form, onFinish, nextButton, backButton, currentStepId }} />}
    >
      <WizardStep
        id={StepIds.Products}
        title="List Items"
        icon="media"
        disabled={isStepDisabled(steps[0].id)}
        panel={<RecommendProductsStepProducts form={form} />}
      />

      <WizardStep
        id={StepIds.Assets}
        title="Assets"
        icon={IconAlias.ASSET}
        disabled={isStepDisabled(steps[1].id)}
        panel={
          <FormAssetTreeSelector
            allSelected={allAssetsSelected}
            form={form}
            isEditingProducts={currentStepId === StepIds.Products}
            assetType="recommendation"
            showMetadataHover
            setLoadingAssets={setLoadingAssets}
          />
        }
      />

      <WizardStep
        id={StepIds.Message}
        title="Message"
        icon="chat"
        disabled={isStepDisabled(steps[2].id)}
        panel={<RecommendProductsStepMessage form={form} />}
      />

      <WizardStep
        id={StepIds.Recipients}
        title="Recipients"
        icon={IconAlias.CONTACT}
        disabled={isStepDisabled(steps[3].id)}
        panel={<RecommendProductsStepRecipients form={form} />}
      />

      <WizardStep
        id={StepIds.Settings}
        title="Settings"
        icon={IconAlias.SETTINGS}
        disabled={isStepDisabled(steps[4].id)}
        panel={<RecommendProductsStepSettings form={form} />}
      />

      <WizardStep
        id={StepIds.Summary}
        title="Summary"
        icon="menu"
        disabled={isStepDisabled(steps[5].id)}
        panel={<RecommendProductsStepSummary form={form} isEditingRecipients={currentStepId === StepIds.Recipients} />}
      />
    </Wizard>
  );
};
