import { IActionConfig } from 'utils/actions/types';
import { IProduct, ItemId, IScheduledJob } from 'types';

export enum IProductActionName {
  ASSIGN_ASSET_TO_SELECTION = 'assign-asset-to-selection',
  ADD_CAST_CREW = 'add-cast-crew',
  CREATE_SELECTION = 'create-selection',
  CLONE = 'clone',
  RESTORE = 'restore',
  UPDATE = 'update',
  REASSIGN_PARENT = 'reassign',
  DELETE = 'delete',
  ADD_TO_SELECTION = 'add-to-selection',
  PASS_ON_DATA = 'pass-on-data',
  RECOMMEND = 'recommend',
  GRANT_ACCESS_PRIVILEGE = 'grant-access-privilege',
  CHANGE_ACCESS_PRIVILEGE_ACCESS = 'change-access-privilege-access',
  CHANGE_ACCESS = 'change-access',
  ASSOCIATE = 'associate',
  UNASSIGN = 'unassign',
  REMOVE_FROM_GROUP = 'remove-from-group',
  PRODUCT = 'products',
  GROUP = 'group',
  PREVIEW_AS_CLIENT = 'preview-as-client',
  REMOVE_FROM_RELATED = 'remove-from-related',
  REMOVE_FROM_MOBILE_SYNC = 'remove-from-mobile-sync',
  DELIVER = 'deliver',
  REMOVE_FROM_ACCESS_GROUP = 'remove-from-access-group',
}

export type IProductActionConfig = IActionConfig<IProduct, IProductActionName>;

export type IProductScheduledJobName = 'change_access_level_on_product';

export interface IAccessChangeScheduledForm {
  item_ids: ItemId[];
  scheduled_for: string;
}

export interface IAccessChangeScheduledFormAccess extends IAccessChangeScheduledForm {
  access_level: string;
  permissions?: string[] | null;
}

export interface IProductScheduledJob extends IScheduledJob {
  product_id: ItemId;
  name: IProductScheduledJobName;
}

export interface IProductScheduledJobAccess extends IProductScheduledJob {
  access_level: string;
  permissions: string[];
}
