import React, { useCallback } from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { IScreeningRoomStepProps } from './utils';
import { FormMultiContacts } from 'helpers/form';

export const ScreeningRoomStepContacts: React.FC<IScreeningRoomStepProps> = ({ form }) => {
  const onContactChange = useCallback(
    (contacts) => {
      form.handlers.onChange({ contacts, contacts_item_ids: contacts?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
      <FormMultiContacts
        name="contacts_item_ids"
        label="Contacts"
        omni
        onSelectedItemsChange={onContactChange}
        required
        {...form.formData.contacts_item_ids}
        onBlur={form.handlers.onBlur}
      />
    </FormSectionWrapperV2>
  );
};
