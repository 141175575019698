import React from 'react';
import { observer } from 'mobx-react-lite';
import { MantineIcon } from 'utils/ui/icon';

const StepDone: React.FC<{}> = observer(() => {
  return (
    <>
      <div className="registration__done m-6">
        <p>
          Your account was created and is pending approval. <br />
          We sent you an email for further instructions.
        </p>
        <span>You didn&apos;t receive an email?</span>
        <a href="mailto:support@mediafellows.com" className="d-inline-flex align-items-center ms-2">
          <MantineIcon className="me-1" icon="envelope" />
          <span>Contact Us</span>
        </a>
      </div>
    </>
  );
});

export default StepDone;
