import React, { useEffect } from 'react';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { ScreeningRoomLink } from 'components/screening-room-link/screening-room-link';

import { Routes } from 'utils/routes';

import { formatDate } from 'utils/date';
import { FormInput, IUseFormReturn } from 'helpers/form';
import { IScreeningRoomEditForm } from './utils';
import { IconAlias } from 'icons/icon-aliases';

import { DetailsPageTabs, IGroup } from 'types';
import './style.scss';
import { formatFullName } from 'utils/general';
import { DetailCountLink } from 'utils/ui';

const inputStyle = { gridTemplateColumns: '3fr' };

const ScreeningRoomDetailsOverview: React.FC<{
  screeningRoom?: IGroup;
  form: IUseFormReturn<IScreeningRoomEditForm>;
  editModeEnabled: boolean;
}> = ({ screeningRoom, editModeEnabled, form }) => {
  const {
    id,
    deleted_at,
    created_at,
    updated_at,
    am_statistics_data,
    owner,
    owner_organization,
    name,
    description,
    users_count,
    preview_image,
    preview_image_id,
  } = screeningRoom || {};

  const ownerField = `${formatFullName(owner, false)}${
    owner_organization?.name ? `, ${owner_organization?.name}` : ''
  }`;

  const splitDescription = description?.split('\n');
  const { onChange } = form.handlers;

  useEffect(() => {
    if (!name) {
      return;
    }
    onChange({ name, description });
  }, [name, description, onChange]);

  return (
    <div className="recommendation-details__overview-wrapper">
      <div className="screening-room-preview-wrapper">
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.SCREENING_ROOM}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
        />
      </div>

      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground={true} padded={false} wrapperClassName="p-3 m-3">
        <Row
          label="Name"
          value={
            editModeEnabled ? (
              <FormInput name="name" {...form.formData.name} {...form.handlers} required style={inputStyle} />
            ) : (
              name
            )
          }
        />
        <Row label="Owner" value={ownerField} />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
        <Row
          label="URL"
          rawValue
          value={<ScreeningRoomLink screeningRoom={screeningRoom} className="d-block" />}
          className="text-wrap text-break"
          size="m"
        />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        <DetailCountLink
          entityCount={users_count}
          link={`${Routes.SCREENING_ROOMS}/${id}/${DetailsPageTabs.CONTACTS}`}
          label={DetailsPageTabs.CONTACTS}
        />
        <DetailCountLink
          entityCount={am_statistics_data?.count}
          link={`${Routes.SCREENING_ROOMS}/${id}/${DetailsPageTabs.ASSETS}`}
          label={DetailsPageTabs.ASSETS}
        />
      </InfoBoxV2>
      <div className="muted-text recommendation-details__overview-message-title">Description</div>
      {editModeEnabled ? (
        <FormInput
          name="description"
          {...form.formData.description}
          {...form.handlers}
          required={false}
          textarea
          className="py-1 px-3"
        />
      ) : (
        <span className="recommendation-details__overview-message">
          {splitDescription?.map((line, i) => <p key={i}>{line}</p>) ?? <i>No description provided</i>}
        </span>
      )}
    </div>
  );
};

export default ScreeningRoomDetailsOverview;
