import React from 'react';

import { FormMultiSelect, IUseFormReturn } from 'helpers/form';
import { IConference } from 'types';
import { queryContacts } from 'utils/apis/contacts';

interface IFormAddToGroupItemsForm {
  conferenceForm: IUseFormReturn<Partial<IConference>>;
}

export const ConferenceUsersSection: React.FC<IFormAddToGroupItemsForm> = ({ conferenceForm }) => {
  const { formData, handlers } = conferenceForm;

  return (
    <FormMultiSelect
      fetchValues={queryContacts}
      name="user_ids"
      label="Contacts"
      rawLabel
      omni
      {...formData.user_ids}
      {...handlers}
    />
  );
};
