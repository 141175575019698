import React from 'react';

import { ModalWrapper } from 'components/dialogs';
import { IModalDialogProps } from 'components/dialogs/types';

const ModalDialog: React.FC<IModalDialogProps> = (modalProps) => {
  return <ModalWrapper {...modalProps} opened onClose={() => null} />;
};

export default React.memo(ModalDialog);
