import React from 'react';

import { useDialogStore } from 'store/hooks';
import { NewsForm } from './news-form';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { INewsFormData } from 'types';
import { IModalSize } from 'components/dialogs/types';

interface IUseNewsForm {
  title: string;
  news?: AmGenericNews;
  handleSubmit: (news: INewsFormData) => Promise<AmGenericNews | void>;
}

export const useNewsForm = ({ title, news, handleSubmit }: IUseNewsForm): (() => void) => {
  const { openModal } = useDialogStore();

  return () =>
    openModal({ title, size: IModalSize.S, body: () => <NewsForm news={news} handleSubmit={handleSubmit} /> });
};
