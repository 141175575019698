import React, { useCallback, useEffect, useState } from 'react';
import { ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Export } from 'blueprint5-icons';

import { useStore } from 'store';

import { SectionHeader } from 'components/section-header';
import { DataTable } from './data-table';
import TablePagination from './table-pagination';

import { useResetPagination } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';

import { IListTableProps } from './types';

import './style.scss';

const ListTable: React.FC<IListTableProps> = ({
  title,
  entity,
  entityId,
  query_name,
  from_date,
  to_date,
  geo_scope_ids,
  user_ids,
  product_ids,
  asset_ids,
  include_descendants,
  only_screenings,
  columns,
  getTableData,
  exportTableData,
  className,
  headerClassName,
  tableClassName,
  recipientIds,
  senderIds,
  onRowClick,
}) => {
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(50);
  const [tableData, setTableData] = useState({
    data: [],
    total_count: 0,
    total_pages: 0,
  });

  const [loading, setLoading] = useState(false);
  const { toastStore } = useStore();

  useResetPagination(setPage);

  const onPageChange = (newPage: number): void => {
    setPage(newPage);
  };
  const onPerChange = (newPer: number): void => {
    setPer(newPer);
    setPage(1);
  };
  const updateTableData = useCallback(
    async (page: number, per: number): Promise<void> => {
      try {
        if (!from_date || !to_date) {
          return;
        }

        setLoading(true);
        const tableData = await getTableData({
          page,
          per,
          from_date,
          to_date,
          entityId,
          entity,
          query_name,
          geo_scope_ids,
          user_ids,
          product_ids,
          asset_ids,
          include_descendants,
          only_screenings,
          recipientIds,
          senderIds,
        });
        const { data, total_count, total_pages } = tableData;
        setTableData({ data, total_count, total_pages });
      } catch (e) {
        toastStore.error(e.text);
      } finally {
        setLoading(false);
      }
    },
    [
      from_date,
      to_date,
      getTableData,
      entityId,
      entity,
      query_name,
      geo_scope_ids,
      user_ids,
      product_ids,
      asset_ids,
      recipientIds,
      senderIds,
      include_descendants,
      only_screenings,
      toastStore,
    ],
  );

  const exportAllTableData = useCallback(async () => {
    try {
      await exportTableData({
        from_date,
        to_date,
        entityId,
        entity,
        query_name,
        user_ids,
        geo_scope_ids,
        product_ids,
        asset_ids,
        recipientIds,
        senderIds,
        include_descendants,
        only_screenings,
      });
      toastStore.success('Export requested and sent via email.');
    } catch (e) {
      toastStore.error('Failed to export detailed list.');
    }
  }, [
    exportTableData,
    from_date,
    to_date,
    entityId,
    recipientIds,
    senderIds,
    entity,
    query_name,
    user_ids,
    geo_scope_ids,
    product_ids,
    asset_ids,
    include_descendants,
    only_screenings,
    toastStore,
  ]);

  const CustomRightRenderer = (): JSX.Element => (
    <>
      {!loading && data.length ? (
        <div className="list-table__actions">
          <ActionIcon title="Export Detailed List data" variant="subtle" color="gray.5" onClick={exportAllTableData}>
            <MantineIcon icon={<Export />} />
          </ActionIcon>
        </div>
      ) : null}
    </>
  );

  const { data, total_count, total_pages } = tableData;

  useEffect(() => {
    updateTableData(page, per);
  }, [page, per, updateTableData]);

  return (
    <>
      <SectionHeader
        title={title}
        useBackground
        useBorder
        customRightRenderer={CustomRightRenderer}
        className={headerClassName}
      />
      <DataTable
        data={data}
        columns={columns}
        loading={loading}
        className={className}
        tableClassName={tableClassName}
        onRowClick={onRowClick}
      />
      {(data.length && total_pages > 1) || data.length > 50 ? (
        <div className="list-table__pagination">
          <TablePagination
            page={page}
            onPageChange={onPageChange}
            per={per}
            onPerChange={onPerChange}
            totalCount={total_count}
            totalPages={total_pages}
          />
        </div>
      ) : null}
    </>
  );
};

export default observer(ListTable);
