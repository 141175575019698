import React from 'react';
import FullCalendar, { EventApi, EventClickArg, EventInput } from '@fullcalendar/react';
import { ResourceApi, ResourceLabelContentArg } from '@fullcalendar/resource-common';
import { ActionIcon, Button, Menu } from '@mantine/core';
import cx from 'classnames';

import { useMeetingActions } from 'utils/actions/meeting';
import { IUseActionsOptionParam } from 'utils/actions';
import { ICalendarInvite, ICalGenericMeeting } from 'types/calendar';
import { changeTimeZone } from 'helpers/form/fields/helpers';

import { formatDate } from 'utils/date';
import { formatInviteeName, getValidNewItem } from './utils';
import { validEmailRegex } from 'utils/validations/email';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

import './style.scss';

const InviteeRow: React.FC<ICalendarInvite> = ({ name, email, attended }) => {
  return (
    <div className="d-flex align-items-center gap-1 w-max-context">
      <MantineIcon icon="symbol-circle" color={attended ? 'green' : 'grey'} />
      <div className="text-truncate"> {formatInviteeName({ name, email })}</div>
    </div>
  );
};

export const InviteesDisplay: React.FC<Pick<ICalGenericMeeting, 'invites'>> = ({ invites }) => {
  if (!invites?.length) {
    return <i>No Invitees</i>;
  }

  return (
    <>
      {invites.map((invitee, i) => (
        <InviteeRow key={invitee.email + i} {...invitee} />
      ))}
    </>
  );
};

export function MeetingActions({
  info,
  calendar,
}: {
  info: EventClickArg | EventInput;
  calendar?: React.RefObject<FullCalendar>;
}): JSX.Element {
  let eventClickArg: EventApi | null = null;
  let eventInput: EventInput | null = null;

  if (info.event) {
    eventClickArg = info.event;
  } else {
    eventInput = info;
  }

  const meeting = {
    ...(eventInput || {}),
    ...((eventClickArg || eventInput)?.extendedProps || {}),
    title: (eventClickArg || eventInput)?.title || '',
    id: parseInt((eventClickArg || eventInput)?.id || '', 10) || null,
  } as ICalGenericMeeting;

  const actions = useMeetingActions([meeting], { calendar, closePrevDialog: true } as IUseActionsOptionParam);
  const checkInAction = actions.find((action) => action.name === 'manage-attendees');
  const restOfActions = actions.filter((action) => action.name !== 'manage-attendees');
  return (
    <div className="more-button-header">
      <Button key={checkInAction?.name} variant="primary" size="xs" onClick={checkInAction?.handler}>
        Check In
      </Button>
      {restOfActions.map((action) => (
        <ActionIcon key={action.name} radius="sm" variant="subtle" onClick={action.handler}>
          <MantineIcon icon={action.icon} />
        </ActionIcon>
      ))}
    </div>
  );
}

const formatLocationTime = (date: string, timeZone: string): string => {
  return formatDate(changeTimeZone(date, timeZone), 'dd MMM HH:mm');
};

const hostLabelContent = (resource: ResourceApi): JSX.Element => {
  const { first_name, last_name, function: role, organisation } = resource.extendedProps;
  return (
    <div className="resource-view__wrapper">
      <div className="text-truncate">
        {first_name} {last_name}
      </div>
      <div className="text-truncate d-flex gap-1 resource-view__location-time-container">
        <span>{role}</span>
        <span>{organisation?.name}</span>
      </div>
    </div>
  );
};

const locationLabelContent = (resource: ResourceApi): JSX.Element => {
  const { capacity, starts_at, ends_at, time_zone } = resource.extendedProps;

  return (
    <div className="resource-view__wrapper">
      <div className="text-truncate">
        {resource.title} {capacity ? `(${capacity} pax)` : ''}
      </div>
      <div className="text-truncate d-flex gap-1 resource-view__location-time-container">
        <span>{formatLocationTime(starts_at, time_zone)}</span>
        <span>-</span>
        <span>{formatLocationTime(ends_at, time_zone)}</span>
        <span>{time_zone}</span>
      </div>
    </div>
  );
};

export const resourceLabelContent = ({ resource }: ResourceLabelContentArg): JSX.Element => {
  const { type } = resource.extendedProps;

  if (type === 'hosts') {
    return hostLabelContent(resource);
  } else {
    return locationLabelContent(resource);
  }
};

const ExternalInviteeElement: React.FC<{ onClick: VoidFunction; validInvitee?: string }> = ({
  onClick,
  validInvitee,
}) => {
  return (
    <Menu>
      <Menu.Item onClick={onClick} disabled={!validInvitee}>
        <>
          <span className={cx(Classes.TEXT_MUTED, 'pe-2')}>
            {`For external Invitees use the format: Joe Doe joe@email.com ${validInvitee ? ':' : ''}`}
          </span>
          <div>{validInvitee}</div>
        </>
      </Menu.Item>
    </Menu>
  );
};
export const CreateInviteeElement: React.FC<{ onClick: VoidFunction; text: string }> = ({ onClick, text }) => {
  const validInvitee = getValidNewItem(text);
  return <ExternalInviteeElement validInvitee={validInvitee} onClick={onClick} />;
};

export const SearchExternalInvitee: React.FC<{ onClick: VoidFunction; text: string }> = ({ onClick, text }) => {
  const validEmail = text.match(validEmailRegex)?.[0];
  return <ExternalInviteeElement validInvitee={validEmail} onClick={onClick} />;
};

export enum meetingTags {
  breakfast = 'U+1F96A',
  lunch = 'U+1F354',
  coffee = 'U+2615',
  dinner = 'U+1F35D',
  reception = 'U+1F378',
  cocktail = 'U+1F378',
  party = 'U+1F378',
  'check-in' = 'U+1F3E8',
  checkin = 'U+1F3E8',
  'check-out' = 'U+1F3E8',
  checkout = 'U+1F3E8',
  hotel = 'U+1F3E8',
  apartment = 'U+1F3E8',
  'pick-up' = 'U+1F695',
  pickup = 'U+1F695',
  transfer = 'U+1F68C',
  transport = 'U+1F68C',
  car = 'U+1F695',
  taxi = 'U+1F695',
  flight = 'U+2708',
  plane = 'U+2708',
  airport = 'U+2708',
  train = 'U+1F683',
  tram = 'U+1F683',
  bus = 'U+1F68C',
}
