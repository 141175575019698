import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { IColumn } from 'components/list-table';
import { UserAvatar } from 'components/user-avatar';
import { ClipboardView } from 'components/clipboard-view';

import { Routes } from 'utils/routes';

import { IContact, IRecommendationSentRecipient } from 'types';
import { formatFullName } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { Classes } from 'utils/ui';

export interface IRecommendationRecipientContact extends IRecommendationSentRecipient, IContact {
  recipient_id: string;
}

const fallback = '—';

const RecipientRow: React.FC<{
  recipient: IRecommendationRecipientContact;
  value?: string;
  className?: string;
}> = ({ recipient, value, className }) => {
  return recipient['@type'] === 'user' ? (
    <div className={cx(className, { 'entity-list-item--deleted': isEntityDeleted(recipient) })}>
      {value || fallback}
    </div>
  ) : (
    <div className={cx(className, { 'entity-list-item--deleted': isEntityDeleted(recipient) })}>N/A</div>
  );
};

export const RecommendationRecipientAvatar: React.FC<{
  recipient: IRecommendationRecipientContact;
  className?: string;
}> = ({ recipient, className }) => {
  return (
    <div className={cx('recommendation-list-table__contact', className)}>
      <UserAvatar
        className="recommendation-list-table__contact-avatar"
        user={{ ...recipient, first_name: recipient['first_name'] || recipient['recipient_id'] }}
      />
      {recipient['@type'] === 'user' ? (
        <Link
          className="recommendation-list-table__contact-name text-truncate"
          to={`${Routes.CONTACTS}/${recipient?.['id']}`}
        >
          {formatFullName(recipient, false)}
        </Link>
      ) : (
        <span className="recommendation-list-table__contact-name text-truncate">{recipient['recipient_id']}</span>
      )}
    </div>
  );
};

export const recommendationRecipientcolumns: IColumn<IRecommendationRecipientContact>[] = [
  {
    title: 'Contact',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <RecommendationRecipientAvatar
        className={isEntityDeleted(recipient) ? 'entity-list-item--deleted' : undefined}
        recipient={recipient}
      />
    ),
    columnClassName: 'recommendation-recipient__avatar',
  },
  {
    title: 'Function',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient['function']} className="text-truncate" />
    ),
    columnClassName: 'recommendation-recipient__function',
  },
  {
    title: 'Organization',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient?.['organization']?.['name']} className="text-truncate" />
    ),
    columnClassName: 'recommendation-recipient__organization',
  },
  {
    title: 'Country',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient?.['country']?.['name']} className="text-truncate" />
    ),
    columnClassName: 'recommendation-recipient__country',
  },
];

export const sentRecommendationRecipientcolumns: IColumn<IRecommendationRecipientContact>[] = [
  ...recommendationRecipientcolumns,
  {
    title: 'Remaining views',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <div className={cx({ 'entity-list-item--deleted': isEntityDeleted(recipient) })}>
        {recipient?.remaining_views || fallback}
      </div>
    ),
    columnClassName: 'recommendation-recipient__views--sent',
  },
  {
    title: 'URL',
    renderer: (recipient: IRecommendationRecipientContact) => (
      <ClipboardView
        textClassName="text-truncate"
        className={cx(`recommendation-list-table__recipient-url ${Classes.TEXT_MUTED}`, {
          'entity-list-item--deleted': isEntityDeleted(recipient),
        })}
        value={recipient?.recommendation_url}
      >
        <a className="text-truncate" href={recipient?.recommendation_url} target="_blank" rel="noreferrer noopener">
          {recipient?.recommendation_url}
        </a>
      </ClipboardView>
    ),
    columnClassName: 'recommendation-recipient__url--sent',
  },
];
