import React from 'react';
import { omit } from 'lodash';

import { useDialogStore, useStore } from 'store';

import { useGenreForm } from 'components/genres/genre-form/use-genre-form';
import { ToastError } from 'components/toast';

import { createGenre } from 'utils/apis/genre';
import { IUseActionsOptionParam } from 'utils/actions';
import { delay } from 'utils/delay';
import { ingestPreviewImage } from 'utils/apis/preview-image';

import { IGenreActionConfig, IGenreActionName } from './types';
import { ICategory } from 'types';
import { IGenreFormData } from 'components/genres/genre-form/genre-form';

export const useAddSubGenreAction = (
  genres: ICategory[],
  options: IUseActionsOptionParam<ICategory>,
): IGenreActionConfig => {
  const { toastStore } = useStore();
  const { close } = useDialogStore();
  const handleSubmit = async (values: IGenreFormData): Promise<ICategory | void> => {
    try {
      let previewImage;
      if (values.previewImageUrl) {
        previewImage = await ingestPreviewImage(values.previewImageUrl);
      }
      const valuesToSubmit = omit(values, ['previewImageUrl', 'preview_image', 'parentName']);
      const genre = await createGenre({ ...valuesToSubmit, preview_image_id: previewImage?.id });
      toastStore.success('Sub-genre has been added successfully!');
      await delay();
      await options?.onSuccess?.(IGenreActionName.ADD_SUB_GENRE, genre);
      close();
      return genre;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  };

  const handler = useGenreForm({
    title: 'Add sub-genre',
    handleSubmit,
    parentName: genres[0].name,
    parentId: options?.parentId as number,
  });

  return {
    name: IGenreActionName.ADD_SUB_GENRE,
    shouldDisplay: () => Boolean(options?.parentId),
    icon: 'add',
    title: 'Add sub-genre',
    handler,
  };
};
