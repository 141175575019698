import { deliveryPackageConnectionSchema } from 'utils/schemas/delivery-package';
import { chipmunk, IResult } from 'utils/chipmunk';
import { filter } from 'lodash';

import { Model } from 'helpers/filters/types';
import { ItemId } from 'types';
import { IItemId } from 'components/form-selection-items';
import { IDeliveryConnection } from 'components/delivery/connections/types';

interface IExtendedDeliveryConnection extends IDeliveryConnection {
  id: number;
}

export const fetchConnections = (organizationId?: IItemId, status?: string): Promise<IExtendedDeliveryConnection[]> => {
  return chipmunk.run(async ({ action }) => {
    const orgFilter = organizationId ? [['organization_id', 'eq', organizationId]] : [];

    const { objects } = await action(Model.CONNECTION, 'search', {
      body: {
        search: {
          filters: orgFilter,
        },
      },
      schema: deliveryPackageConnectionSchema,
    });

    if (status) return filter(objects, { status });

    return objects;
  });
};

export const createConnection = (connection): Promise<IExtendedDeliveryConnection> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONNECTION, 'create', {
      body: { ...connection },
    });
    return object;
  });
};

export const updateConnection = (connection): Promise<IExtendedDeliveryConnection> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONNECTION, 'update', {
      params: { id: connection.id },
      body: { ...connection },
    });
    return object;
  });
};

export const fetchConnectionById = (id: ItemId | null): Promise<IExtendedDeliveryConnection> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.CONNECTION, 'get', {
      params: { id },
    });
    return object;
  });
};

export const deleteConnection = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.CONNECTION, 'delete', {
      params: { id: ids },
    });
  });
};

export const validateConnection = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.CONNECTION, 'validate', {
      params: { id: ids },
    });
  });
};

export const setConnectionStatus = async (
  ids?: (number | null | undefined)[],
  actionName = 'activate',
): Promise<IResult | void> => {
  if (!ids) {
    return;
  }
  return chipmunk.run(({ action }) => {
    return action(Model.CONNECTION, actionName, {
      params: { id: ids },
    });
  });
};
