import React, { useCallback, useState } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { ProductContentCatalogue } from 'components/product-content-catalogue';
import { MantineIcon } from 'utils/ui/icon';
import { Box } from 'blueprint5-icons';

export const AdditionalButtons: React.FC<{}> = () => {
  const [isOpen, openCatalogueWindow] = useState(false);

  const newWindowUnloaded = useCallback((): void => {
    openCatalogueWindow(false);
  }, []);
  return (
    <>
      <Tooltip label="Open Content Catalogue" position="bottom">
        <ActionIcon
          variant="subtle"
          color="gray.5"
          onClick={() => openCatalogueWindow(!isOpen)}
          className="top-header__switch me-2"
        >
          <MantineIcon icon={<Box />} />
        </ActionIcon>
      </Tooltip>

      {isOpen && <ProductContentCatalogue newWindowUnloaded={newWindowUnloaded} />}
    </>
  );
};
