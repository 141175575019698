import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import SelectionDetailsLayout from 'components/selection-details/selection-details-layout/selection-details-layout';
import { ProductSelectionActionsList } from 'components/action';
import { GroupDetailProductsTab } from 'components/marketing-entity-detail';
import mapSelection from 'components/selection/map-selection';

import { IActionName, ITitleDividerActionName, Pages } from 'utils/actions/types';
import { ISelectionActionName } from 'utils/actions/selection/types';
import useSelection from 'utils/hooks/selection';

import { ISelection, ISelectionStandardized } from 'types';
import { IGroupActionName } from 'utils/actions/common/types';
import { useRefreshDataSection } from 'utils/hooks';
import { IProductActionName } from 'utils/actions/product/types';
import { Routes } from 'utils/routes';
import { ActionsMenu } from 'components/action/utils';

const page = Pages.SELECTION_DETAILS;
const options = {
  groupName: ITitleDividerActionName.SELECTION,
  groupParamName: 'selectionId',
};

const mapProductSelection = (productSelection: ISelection): ISelectionStandardized =>
  mapSelection(productSelection, productSelection?.pm_statistics_data?.count);

const ProductSelectionDetails: React.FC = observer(() => {
  const { selectionId = '' } = useParams<{ selectionId: string }>();
  const [selection, selectionLoading, refreshSelection, setSelection] = useSelection(selectionId, mapProductSelection);
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  const refreshDataSection = useRefreshDataSection();

  const navigate = useNavigate();
  const onSuccess = useCallback(
    async (action?: string, selection?) => {
      switch (action) {
        case IGroupActionName.ADD_ENTITIES:
          setTimeout(() => {
            refreshDataSection();
            refreshSelection();
          }, 1000);
          break;

        case ISelectionActionName.DELETE:
          navigate(`${Routes.PRODUCT_SELECTIONS}`);
          break;
        case ISelectionActionName.CHANGE_ACCESS:
          setSelection(mapProductSelection(selection));
          break;
        default:
          await refreshSelection();
      }
    },
    [refreshSelection, navigate, setSelection, refreshDataSection],
  );

  const dataSectionOnSuccess = useCallback(
    (action?: IActionName | undefined): void => {
      if (action === IProductActionName.REMOVE_FROM_GROUP) {
        setTimeout(() => {
          refreshDataSection();
          refreshSelection();
        }, 1000);
      }
    },
    [refreshDataSection, refreshSelection],
  );

  return (
    <SelectionDetailsLayout
      selection={selection as ISelectionStandardized}
      selectionLoading={selectionLoading}
      refreshSelection={refreshSelection}
      headerDropdownMenu={
        <ActionsMenu
          component={ProductSelectionActionsList}
          items={[selection as ISelectionStandardized]}
          options={{ ...options, context: 'single', page, onSuccess }}
        />
      }
      isSortModeEnabled={isSortModeEnabled}
      setIsSortModeEnabled={setIsSortModeEnabled}
    >
      <GroupDetailProductsTab
        groupId={selectionId}
        isSortModeEnabled={isSortModeEnabled}
        page={Pages.SELECTION_ENTITY_TAB}
        options={{ ...options, onSuccess: dataSectionOnSuccess, page: Pages.SELECTION_ENTITY_TAB }}
      />
    </SelectionDetailsLayout>
  );
});

export default ProductSelectionDetails;
