import createChipmunk, { IConfig, IResult } from '@mediafellows/chipmunk';
import { cleanConfig } from '@mediafellows/chipmunk';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import Ajv, { _, KeywordCxt } from 'ajv';
import addErrors from 'ajv-errors';
import addKeywords from 'ajv-keywords';
import addFormats from 'ajv-formats';
export { IResult } from '@mediafellows/chipmunk';

import { cookie } from 'utils/cookie';
import { flags } from 'utils/flags';
import { getRootStore } from 'store';
import { logout } from 'store/session-store';

declare const ENDPOINTS;

const sessionId = cookie.get('sessionId');

const config: Partial<IConfig> = {
  endpoints: ENDPOINTS,
  headers: {
    'Session-Id': sessionId,
  },
  cache: {
    enabled: true,
    default: 'storage',
  },

  errorInterceptor: (err) => {
    const { toastStore } = getRootStore();

    if (err.status === 419) {
      logout({ allTabs: true, saveRedirectUrl: true });
      return true;
    } else if (err.status === 418 || err.status === 503) {
      //window.location.href = '/maintenance';
      toastStore.error('System Is Under Maintenance');
      return true;
    } else if (err.status === 420) {
      //twoFactor.nextPurpose = get(err, 'object.error.object.payload.purpose') || get(err, 'object.payload.purpose'); // tuco error response
      //const payload = { contentId: 'two-factor-modal' };
      toastStore.error('Two Factor Authentication is Required');
      return true;
    }

    return false;
  },
};

export const tuco = <T = IObject>(task, opts = {}): Promise<IResult<T>> => {
  return chipmunk.run(({ action }) =>
    action(`tuco.request`, `task`, {
      raw: true,
      body: {
        task,
        opts: { ...opts, isMm3Assets: flags.isMm3Assets },
        config: cleanConfig(chipmunk.currentConfig()),
      },
    }),
  );
};

const chipmunk = createChipmunk(config);
window['chipmunk'] = chipmunk;

const ajv = new Ajv({
  strict: 'log',
  allErrors: true,
  addUsedSchema: true,
  inlineRefs: 20,
  keywords: [
    'readonly',
    'writable',
    'readable',
    'skipHtmlCheck',
    '$jsonld_context',
    'legacy_ref',
    'defs',
    'action',
    'actions',
    'url',
    'associations',
    'source',
    'links',
    'uiType',
    'x-error',
    'constants',
  ],
  loadSchema: async (url) => {
    const schema = await chipmunk.spec(url);
    schema['$id'] = url;

    return schema;
  },
});

addErrors(ajv, { keepErrors: false });
addKeywords(ajv);
addFormats(ajv);

// exp of adding special keyword
ajv.addKeyword({
  keyword: 'notEmpty',
  type: ['string', 'number', 'null'],
  schemaType: 'boolean',
  code(cxt: KeywordCxt) {
    const { data } = cxt;
    cxt.fail(_`!${data}`);
  },
});

const mediaBench = chipmunk.currentConfig().endpoints?.mediacentral || chipmunk.currentConfig().endpoints?.mediabench;

export { chipmunk, ajv, mediaBench };
