import initial from 'lodash/initial';
import { IJsonLDSpec, IJsonLDProperty } from '@mediafellows/chipmunk';

import { IDeepPartial } from 'types';

export interface IAdditionalContextConfig {
  model: string;
  prefix: string;
}

const parseToPrefix = (key: string): string => {
  // removes last field in the key e.g, 'a.b.c[0].d' -> 'a.b.c[0]'
  return initial(key.split('.')).join('.');
};

function getContextPrefixes<T extends {}>(config: IAdditionalContextConfig, values: T): string[] {
  // looks into the form keys to return the prefixes to add to property keys of a given context
  const { prefix: ConfigPrefix = '' } = config || {};
  const prefixes = Object.keys(values).reduce((acc, key) => {
    return key.startsWith(ConfigPrefix) ? [...acc, parseToPrefix(key)] : acc;
  }, []);

  if (prefixes.length) return prefixes;
  if (ConfigPrefix) return [ConfigPrefix];

  return ['non-specified-prefix'];
}

function applyPrefixesToProperties<T extends {}>(properties: T, prefixes: string[]): IDeepPartial<IJsonLDSpec> {
  // applies the given prefixes
  return Object.entries<IJsonLDProperty>(properties).reduce(
    (acc, property) => ({
      ...applyPrefixesToSingleProperty(prefixes, property),
      ...acc,
    }),
    {},
  );
}

const applyPrefixesToSingleProperty = (
  prefixes: string[],
  [key, value]: [string, IJsonLDProperty],
): IDeepPartial<IJsonLDSpec> => {
  return prefixes.reduce((acc, prefix) => ({ [`${prefix}.${key}`]: value, ...acc }), {});
};

export function buildCustomContext<T extends {}>(
  contexts: IJsonLDSpec[],
  config: IAdditionalContextConfig[],
  values: T,
): IDeepPartial<IJsonLDSpec> {
  const properties = contexts.reduce((acc, { properties }, index) => {
    const prefixes = getContextPrefixes<T>(config[index], values);

    return { ...acc, ...applyPrefixesToProperties(properties, prefixes) };
  }, {});

  return { properties };
}
