import { useCallback } from 'react';
import { ParametronStore } from 'store/parametron-store';
import { getAssets } from 'utils/apis/asset';
import { isAssetIngesting, refreshIngestingAssetsDelay } from 'utils/asset';
import { byId } from 'utils/general';
import { useInterval } from 'utils/hooks/use-interval';
import { EqFilter, IFilter, NotExistFilter, RangeFilter } from 'helpers/filters/types';
import { createdAtRange } from 'utils/date';
import { queryAllAssets } from 'utils/apis/asset';
import { searchProducts } from 'utils/apis/product';
import { IParametronData, Parametron } from '@mediafellows/parametron';
import groupBy from 'lodash/groupBy';
import { IMm3Asset, ISearchFilter } from 'types';

export const refreshIngestingAssets = async (searchStore: ParametronStore | null): Promise<void> => {
  if (!searchStore?.objects?.length) {
    return;
  }
  const { objects, update } = searchStore;
  const assetsInIngest = objects.filter(isAssetIngesting);
  if (!assetsInIngest.length) {
    return;
  }
  const refreshedAssets = await getAssets(assetsInIngest.map((e) => e.id));
  const assetsById = byId(refreshedAssets);

  update({ objects: objects.map((e) => assetsById[e.id] || e) });
};

export const useRefreshIngestingAssets = (assetSearch: ParametronStore | null): void => {
  const refresh = useCallback(() => {
    refreshIngestingAssets(assetSearch);
  }, [assetSearch]);

  useInterval(refresh, refreshIngestingAssetsDelay);
};

export const parseQueryParamsToFilters = ({
  file_status,
  main_classification,
  created_at,
  type,
}: Record<string, string>): Record<string, IFilter> => {
  return {
    ...(created_at && createdAtRange[created_at]
      ? { created_at: new RangeFilter('created_at', ...createdAtRange[created_at]) }
      : {}),
    ...(file_status ? { file_status: new EqFilter('file_status', file_status) } : {}),
    ...(type ? { type: new EqFilter('type', type), parent_id: new NotExistFilter('parent_id', false) } : {}),
    ...(main_classification
      ? {
          main_classification: new EqFilter('main_classification', main_classification),
        }
      : {}),
  };
};

export const initQueryParams = (api: Parametron, queryParams: Record<string, string>): void => {
  if (queryParams.main_classification) {
    api.setFilter('main_classification', 'eq', queryParams.main_classification);
  }
  if (queryParams.file_status) {
    api.setFilter('file_status', 'eq', queryParams.file_status);
  }
  if (queryParams.type) {
    api.setFilter('type', 'eq', queryParams.type);
  }
  if (queryParams.created_at && createdAtRange[queryParams.created_at]) {
    api.setFilter('created_at', 'range', ...createdAtRange[queryParams.created_at]);
  }
};

export const handleUpdate = async (
  data: IParametronData,
  assetSearch: ParametronStore<IMm3Asset> | null,
): Promise<void> => {
  if (!assetSearch) {
    return;
  }
  assetSearch.updateParamsAndFiltersCount(['include_deleted']);
  if (!data.objects?.length) {
    assetSearch.update(data);
    return;
  }
  const assetIds = data.objects.map((e) => e.id);
  const filters: ISearchFilter[] = [['asset_ids', 'in', assetIds]];
  const productsPromise = searchProducts({ search: { filters } }, '', 'unfurl');
  const childAssetsPromise = queryAllAssets({}, [['parent_id', 'in', assetIds]], 'id, type, parent_id');
  const [products, children] = await Promise.all([productsPromise, childAssetsPromise]);
  const productsById = byId(products);
  const childrenByParentId = groupBy(children, 'parent_id');

  data.objects.map((asset) => {
    asset.products = (asset.products || []).map((p) => productsById[p.product_id]).filter(Boolean);
    asset.children = childrenByParentId[asset.id];
  });
  assetSearch.update(data);
};
