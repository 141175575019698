import React from 'react';
import { Button } from '@mantine/core';
import { IProduct, Product3Types } from 'types';
import { Intent, MantineIcon } from 'utils/ui';

import { useCreateNewProduct } from './hook';

interface INewProductButtonProps {
  title: string;
  productType: Product3Types;
  parent: IProduct;
  hidden?: boolean;
}

export const CreateNewProductButton: React.FC<INewProductButtonProps> = ({ title, productType, parent, hidden }) => {
  const handleCreateNewProduct = useCreateNewProduct(productType, parent);
  return (
    <Button
      onClick={handleCreateNewProduct}
      variant={Intent.PRIMARY}
      hidden={hidden}
      leftSection={<MantineIcon icon="plus" />}
      size="xs"
    >
      {title}
    </Button>
  );
};
