import React from 'react';
import { observer } from 'mobx-react-lite';

import { StatisticsTopCard } from 'components/statistic-top-card';
import {
  AssetInteractions,
  ContactInteractions,
  IAnalyticsInteractionOption,
  ProductInteractions,
} from 'components/analytics';
import { RecommendationInteractions } from 'components/analytics/utils';

import {
  fetchTopProductData,
  fetchTopVideoData,
  fetchTopVideoViewers,
  fetchTopScreenings,
  fetchTopViewers,
} from 'utils/apis/analytics';

import { IAnalyticsPageValues, IAnalyticsProps } from 'types';

import './style.scss';
import { recommendationsReceived, recommendationsSent } from 'components/analytics/queries/contact-queries';

enum AnalyticsUnits {
  TIMES = 'times',
  MINUTES = 'minutes',
  VIEWS = 'views',
  SENT = 'sent',
}

const getParams = (
  section: IAnalyticsPageValues,
): Array<{
  action;
  title: string;
  unit: AnalyticsUnits;
  interaction?: IAnalyticsInteractionOption;
  query?: string;
}> => {
  switch (section) {
    case IAnalyticsPageValues.ANALYTICS_ASSETS:
      return [
        {
          action: fetchTopVideoData,
          title: 'Top Videos Screened',
          unit: AnalyticsUnits.VIEWS,
          interaction: AssetInteractions.SCREENINGS,
          query: 'v3/assets/top_videos_screened',
        },
        {
          action: fetchTopScreenings,
          title: 'Videos Screened Longest',
          unit: AnalyticsUnits.MINUTES,
          interaction: AssetInteractions.SCREENINGS,
          query: 'v3/interaction/screenings/list__contact_screening_top_duration',
        },
      ];
    case IAnalyticsPageValues.ANALYTICS_PRODUCTS:
      return [
        {
          action: fetchTopProductData,
          title: 'Top Products',
          unit: AnalyticsUnits.TIMES,
          interaction: ProductInteractions.VIEWS,
          query: 'v3/products/top_products',
        },
        {
          action: fetchTopViewers,
          title: 'Top Product Viewers',
          unit: AnalyticsUnits.TIMES,
          interaction: ContactInteractions.VIEWS,
          query: 'v3/product/views/top__product_viewers',
        },
      ];
    case IAnalyticsPageValues.ANALYTICS_RECOMMENDATION:
      return [
        {
          action: (params: IAnalyticsProps) =>
            fetchTopViewers({ ...params, queryName: recommendationsSent.recommendationTopRecipients }, 'recipient'),
          title: RecommendationInteractions.TOP_RECIPIENTS,
          unit: AnalyticsUnits.VIEWS,
          interaction: ContactInteractions.RECOMMENDATIONS_RECEIVED,
          query: recommendationsSent.recommendationTopRecipients,
        },
        {
          action: (params: IAnalyticsProps) => {
            return fetchTopViewers(
              { ...params, queryName: recommendationsReceived.recommendationTopSenders },
              'sender',
            );
          },
          title: RecommendationInteractions.TOP_SENDERS,
          unit: AnalyticsUnits.SENT,
          interaction: ContactInteractions.RECOMMENDATIONS_SENT,
          query: recommendationsReceived.recommendationTopSenders,
        },
      ];
    case IAnalyticsPageValues.ANALYTICS_CONTACTS:
    default:
      return [
        {
          action: fetchTopVideoViewers,
          title: 'Top Video Viewers',
          unit: AnalyticsUnits.MINUTES,
          interaction: ContactInteractions.SCREENINGS,
          query: 'v3/asset/screening/top_viewers',
        },
        {
          action: fetchTopViewers,
          title: 'Top Product Viewers',
          unit: AnalyticsUnits.TIMES,
          interaction: ContactInteractions.VIEWS,
          query: 'v3/product/views/top__product_viewers',
        },
      ];
  }
};

const AnalyticsDataSection: React.FC<{
  section: IAnalyticsPageValues;
  dateRange: [Date, Date];
  geoScopeIds?: Array<string>;
  userIds?: Array<string>;
  productIds?: Array<string>;
  senderIds?: string[];
  recipientIds?: string[];
  includeDescendants?: boolean;
}> = observer(
  ({ section, dateRange, geoScopeIds, userIds, productIds, senderIds, recipientIds, includeDescendants }) => {
    const statisticsParams = getParams(section);
    return (
      <div className="data-section analytics-data-section__container">
        {statisticsParams.map(({ action, title, unit, interaction, query }, index) => (
          <StatisticsTopCard
            key={index}
            title={title}
            statisticName={unit}
            fetcher={action}
            dateRange={dateRange}
            userIds={userIds}
            geoScopeIds={geoScopeIds}
            productIds={productIds}
            interaction={interaction}
            includeDescendants={includeDescendants}
            query={query}
            url=""
            senderIds={senderIds}
            recipientIds={recipientIds}
            showPagination
          />
        ))}
      </div>
    );
  },
);

export default AnalyticsDataSection;
