import React from 'react';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { FormProducts, FormCheckbox } from 'helpers/form';
import { Text } from 'components/text';
import { IAccessPrivilegeFormComponent } from './types';

export const keysProducts = ['product_ids', 'include_descendants', 'delegates_access', 'permit_download'];

export const AccessPrivilegeGrantFormProducts: React.FC<IAccessPrivilegeFormComponent> = ({ form }) => {
  useFutureDescendantsFlag(form);
  return (
    <div className="d-flex flex-column">
      <div className="access-privilege-grant-form-products">
        <FormProducts name="product_ids" label="Products" large {...form.formData.product_ids} {...form.handlers} />
      </div>
      <Text>
        All products will be added with their full ancestry (necessary for the correct display of privileged access on
        the client-facing site).
      </Text>
      <FormCheckbox
        name="include_descendants"
        label="Add product(s) including descendants"
        {...form.formData.include_descendants}
        {...form.handlers}
        className="mb-0"
        large
      />
      <FormCheckbox
        label="Automatically include all future descendants"
        name="include_future_descendants"
        large
        className="ms-3 mb-0"
        {...form.formData.include_future_descendants}
        {...form.handlers}
        disabled={!form.values.include_descendants}
      />

      <FormCheckbox
        label="Include the Products’ unpublished Assets"
        name="delegates_access"
        large
        className="mb-0"
        {...form.formData.delegates_access}
        {...form.handlers}
      />
      <FormCheckbox
        label="Permit asset download"
        name="permit_download"
        large
        className="mb-0"
        {...form.formData.permit_download}
        {...form.handlers}
      />
    </div>
  );
};
