import React from 'react';
import { Tooltip } from '@mantine/core';

import { IContact } from 'types';
import { UserAvatar } from 'components/user-avatar';
import { Classes } from 'utils/ui';

export const ContactPreviewHeader: React.FC<{ contact: IContact }> = ({ contact }) => {
  const { first_name, last_name, status, organization, ['function']: contact_function } = contact || {};
  const organizationName = organization?.name;
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="entity-preview__title">
          <h3>
            {first_name} {last_name}
          </h3>
        </div>

        <div className={`entity-preview__subtitle ${Classes.TEXT_MUTED}`}>
          <h4>{contact_function ? `${contact_function} at ${organizationName}` : organizationName}</h4>
        </div>
      </div>
      <Tooltip
        offset={{ mainAxis: 8, crossAxis: 27 }}
        arrowOffset={40}
        arrowPosition="side"
        label={status}
        position={'bottom-end'}
      >
        <div className="contact-preview__thumb">
          <UserAvatar offset={13} size={23} user={contact} shouldRefresh />
        </div>
      </Tooltip>
    </div>
  );
};
