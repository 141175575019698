import { IContact } from 'types';
import { fetchExtraUserInfo } from 'utils/apis/analytics';

export interface IAnalyticsContact extends IContact {
  country_id: string;
  country_name: string;
  email: string;
  external_reference: string;
  full_name: string;
  id: string;
  organization_id: string;
  organization_name: string;
  role_id: string;
  role_name: string;
}

export interface IRecommendationSentTableItem {
  recommendation_id: string;
  subject: string;
  views_count: number;
  requires_login: boolean;
  allows_asset_download: boolean;
  recipient: IAnalyticsContact;
  sender: IAnalyticsContact;
  recipient_organization: {
    id: string;
    name: string;
  };
  assets_count: number;
  products_count: number;
  created_at: string;
  expires_at: string;
  sent_at: string;
  last_viewed_at: string | number | Date;
  allowed_views: number;
}

export interface IRecommendationSentTableData {
  data: IRecommendationSentTableItem[];
  page: number;
  per: number;
  total_count: number;
  total_pages: number;
}

export const fetchRecommendationContactsInfo = async (data): Promise<IRecommendationSentTableItem[]> => {
  const contactsWithIds = data?.reduce((acc, { recipient: { id } }) => (id && id !== '0' ? [...acc, id] : acc), []);
  const idToUserMap = await fetchExtraUserInfo(contactsWithIds);
  return data.map((item) => {
    const { recipient } = item;
    if (recipient.id !== '0' && idToUserMap[recipient.id]) {
      return Object.assign({}, item, { recipient: { ...recipient, ...idToUserMap[recipient.id] } });
    } else {
      const first_name = recipient.full_name.trim().split(' ')[0];
      const last_name = recipient.full_name.substring(first_name.length).trim();
      return Object.assign({}, item, { recipient: { ...recipient, first_name, last_name } });
    }
  });
};
