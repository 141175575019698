import { IAccessGroup } from './types';

export const initialValues: IAccessGroup = {
  name: '',
  access_level: 'company',
  product_ids: [],
  asset_ids: [],
  assetList: [],
  contacts: [],
  contact_ids: [],
  organization_ids: [],
  expires_at: '',

  permit_download: false,
  delegates_access: true,
  include_descendants: true,
  include_future_descendants: true,
};
