import React from 'react';
import { observer } from 'mobx-react-lite';
import { Badge } from '@mantine/core';

import DateElement from 'components/date-list-element/date-list-element';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { formatConnectionType, ConnectionStatus } from 'components/connection-list-item/utils';
import { Classes } from 'utils/ui';

import './style.scss';

interface IConnectionAttributeListProps {
  connection: DeliveriesGenericConnection;
}

export const ConnectionAttributeList: React.FC<IConnectionAttributeListProps> = observer(({ connection }) => {
  const { created_at, updated_at, type, status } = connection;

  return (
    <div className={`connection-list-item__attributes ${Classes.TEXT_MUTED}`}>
      <div className="text-truncate">{formatConnectionType(type)}</div>
      <div className="text-truncate">
        <Badge variant={ConnectionStatus[status as string]}>{status?.toUpperCase()}</Badge>
      </div>
      <div className="text-truncate">
        <DateElement date={created_at} mfxIcon="created-at" />
      </div>
      <div className="text-truncate">
        <DateElement date={updated_at} mfxIcon="updated-at" />
      </div>
    </div>
  );
});
