import React, { useCallback } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { ChartContainer } from 'components/chart-container';

type IPosition = 'bottom' | 'left' | 'right' | 'top' | undefined;

export interface IChartContext {
  label: string;
  formattedValue: string;
}
interface IGaugeChartProps {
  data: number[];
  labels: string[];
  aspectRatio?: number;
  unit?: string;
  backgroundColor?: string[];
  borderColor?: string[];
  showLegend?: boolean;
  showTitle?: boolean;
  titleText?: string;
  customTooltipFormatter?: (context: IChartContext) => string;
}

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font = { family: 'Arial', size: 20, weight: '500' };
ChartJS.defaults.color = '#8ca6b7';

const CountGaugeChart: React.FC<IGaugeChartProps> = ({
  data,
  labels,
  aspectRatio = 1,
  unit,
  backgroundColor = ['#3dcc91', '#bfccd6'],
  borderColor = ['#3dcc91', '#bfccd6'],
  showLegend = true,
  showTitle = false,
  titleText,
  customTooltipFormatter,
}) => {
  const formatTooltipLabel = useCallback(
    (context: IChartContext): string => {
      let label = context.label;
      const value = context.formattedValue;

      label = unit ? label + ': ' + (value + unit) : label + ': ' + value + '%';

      return label;
    },
    [unit],
  );

  const options = {
    type: 'doughnut',
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio,
    resizeDelay: 50,
    rotation: 270, // start angle in degrees
    circumference: 180, // sweep angle in degrees

    plugins: {
      tooltip: {
        callbacks: {
          label: customTooltipFormatter || formatTooltipLabel,
        },
      },
      legend: {
        display: showLegend,
      },
      title: {
        display: showTitle,
        text: titleText,
        color: '#3dcc91',
        position: 'bottom' as IPosition,
        font: {
          size: 20,
          family: 'Arial',
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        borderColor,
        hoverOffset: 4,
      },
    ],
    borderWidth: 1,
  };

  const showChart = chartData.datasets?.[0].data?.length;
  return showChart ? (
    <ChartContainer aspectRatio={aspectRatio}>
      <Doughnut data={chartData} options={options} />
    </ChartContainer>
  ) : (
    <></>
  );
};
export default CountGaugeChart;
