import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pages } from 'utils/actions/types';
import { BasketDetails } from 'pages/basket-details';

const ContactBasketDetails: React.FC = observer(() => {
  return <BasketDetails page={Pages.CONTACT_BASKET_DETAILS} />;
});

export default ContactBasketDetails;
