import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';

import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IAsset, IMm3Asset, ItemId } from 'types';
import { removeElementFromSelection } from 'utils/actions/selection/api';
import { Model } from 'helpers/filters/types';
import { queryAssetGroupItems } from 'utils/apis/groups';

import { shouldDisplayRemoveFromGroup } from './actions-acl';
import { IAssetActionName } from './types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export const useRemoveFromGroupAction = (
  assets: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IActionConfig<IAsset, IAssetActionName> => {
  const { toastStore, dialogStore } = useStore();
  const params = useParams<{ groupId: string }>();
  const groupId = params[options.groupParamName || ''];
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Asset', assets.length);

  const onConfirm = useCallback(async () => {
    try {
      const assetIds = assets.reduce((acc: ItemId[], asset) => (asset?.id ? acc.concat(asset.id) : acc), []);
      if (!groupId || !assetIds.length) {
        return;
      }

      const { objects: groupItems } = await queryAssetGroupItems(groupId, assetIds);
      const itemIds = map(groupItems, 'id');
      await removeElementFromSelection(groupId, itemIds, Model.ASSET_GROUP_ITEMS);
      toastStore.success(`${entityWithCount} will be removed from ${options.groupName}`);
      await options?.onSuccess?.(IAssetActionName.REMOVE_FROM_GROUP);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [assets, groupId, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from the ${options.groupName}?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm, options.groupName]);

  return {
    name: IAssetActionName.REMOVE_FROM_GROUP,
    shouldDisplay: shouldDisplayRemoveFromGroup,
    icon: 'remove',
    title: `Remove from ${options.groupName}`,
    intent: Intent.DANGER,
    handler,
  };
};
