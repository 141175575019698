import { IProduct } from 'types';

import { useAccessChangeDialog } from 'components/product-access-change';
import { IUseActionsOptionParam } from 'utils/actions';

import { shouldDisplayChangeAccess as shouldDisplay } from './actions-acl';
import { IProductActionConfig, IProductActionName } from './types';

export const useChangeAccessAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const [openDialog] = useAccessChangeDialog(items, options);

  return {
    name: IProductActionName.CHANGE_ACCESS,
    shouldDisplay,
    icon: 'key',
    title: 'Change access',
    handler: openDialog,
  };
};
