import React from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import cx from 'classnames';

import { pluralizeProductType, formatMm3TypeTag } from 'utils/product';
import { Routes } from 'utils/routes';
import { Classes } from 'utils/ui';
import { IDataSectionLayout, IProductAncestryInfo, IAncestor, DetailsPageTabs, ItemId, Product3Types } from 'types';

import './style.scss';

interface IProductAncestryBreadcrumbsProps {
  ancestryInfo?: IProductAncestryInfo;
  layout: IDataSectionLayout | 'preview' | 'detail';
  productId: ItemId;
  hasAncestry?: boolean;
}

interface IProductAncestor {
  title: string;
  tab: string;
}
const pluralize = (pType: string): string => pluralizeProductType(formatMm3TypeTag(pType), '(s)');

const getDescendants = (ancestryInfo: IProductAncestryInfo | undefined, tilesView: boolean): IProductAncestor[] => {
  if (!ancestryInfo) return [];
  const {
    effective_seasons_count,
    effective_series_count,
    effective_programs_count,
    effective_film_versions_count,
    effective_products_count,
    effective_episodes_count,
    effective_episode_versions_count,
  } = ancestryInfo;

  const descendants = [
    ...(effective_series_count
      ? [
          {
            title: `${effective_series_count} ${pluralize(Product3Types.SERIES)}`,
            tab: DetailsPageTabs.SERIES,
          },
        ]
      : []),
    ...(effective_seasons_count
      ? [
          {
            title: `${effective_seasons_count} ${pluralize(Product3Types.SEASON)}`,
            tab: DetailsPageTabs.SEASONS,
          },
        ]
      : []),
    ...(effective_episodes_count
      ? [
          {
            title: `${effective_episodes_count} ${pluralize(Product3Types.EPISODE)}`,
            tab: DetailsPageTabs.EPISODES,
          },
        ]
      : []),
    ...(effective_episode_versions_count
      ? [
          {
            title: `${effective_episode_versions_count} ${pluralize(Product3Types.EPISODE_VERSION)}`,
            tab: DetailsPageTabs.EPISODE_VERSION,
          },
        ]
      : []),
    ...(effective_programs_count
      ? [
          {
            title: `${effective_programs_count} ${pluralize(Product3Types.PROGRAM)}`,
            tab: DetailsPageTabs.PROGRAMS,
          },
        ]
      : []),
    ...(effective_film_versions_count
      ? [
          {
            title: `${effective_film_versions_count} ${pluralize(Product3Types.FILM_VERSION)}`,
            tab: DetailsPageTabs.FILM_VERSION,
          },
        ]
      : []),
    ...(effective_products_count ? [{ title: `${effective_products_count} product(s)`, tab: '' }] : []),
  ];

  if (tilesView) {
    return descendants[0] ? [descendants[0]] : [];
  }
  return descendants;
};

const getAncestors = (tilesView: boolean, ancestors: IAncestor[]): IAncestor[] => {
  const a = toJS(ancestors);
  if (tilesView) {
    return a?.[0] ? [a[0]] : [];
  }
  return a || [];
};

const ProductAncestryBreadcrumbs: React.FC<IProductAncestryBreadcrumbsProps> = ({
  ancestryInfo,
  layout,
  productId,
  hasAncestry,
}) => {
  const tilesView = layout === 'tiles';
  const descendantsHierarchy = getDescendants(ancestryInfo, tilesView);
  const ancestorsHierarchy = getAncestors(tilesView, ancestryInfo?.ancestors || []);

  if (hasAncestry === false || (!ancestorsHierarchy?.length && !descendantsHierarchy?.length)) {
    return (
      <div
        className={cx({
          breadcrumbs__items__tiles: tilesView,
          breadcrumbs__items__list: !tilesView,
        })}
      >
        &nbsp;
      </div>
    );
  }
  return (
    <div
      className={cx({
        breadcrumbs__wrapper__list: !tilesView,
        breadcrumbs__wrapper__tiles: tilesView,
        breadcrumbs__wrapper__preview: layout === 'preview',
        'breadcrumbs__wrapper--no-gap': !ancestorsHierarchy?.length || !descendantsHierarchy?.length,
      })}
    >
      {!!ancestorsHierarchy?.length && (
        <ul
          className={cx({
            breadcrumbs__items__list: !tilesView,
            breadcrumbs__items__tiles: tilesView,
            'breadcrumbs__items__preview breadcrumbs__items__preview--ancestors': layout === 'preview',
          })}
        >
          {ancestorsHierarchy?.map(({ display_title, product_id }) => (
            <Link
              className={cx({
                breadcrumbs__ancestor__tiles: tilesView,
                breadcrumbs__ancestor__list: !tilesView,
                breadcrumbs__ancestor__preview: layout === 'preview',
                breadcrumbs__ancestor__detail: layout === 'detail',
              })}
              key={product_id}
              title={display_title}
              to={`${Routes.PRODUCTS}/${product_id}`}
            >
              <span
                className={cx(`${Classes.TEXT_MUTED}`, 'd-block', 'text-truncate', {
                  breadcrumbs__tiles__text: tilesView,
                  breadcrumbs__list__text: !tilesView,
                })}
              >
                {display_title}
              </span>
            </Link>
          ))}
        </ul>
      )}

      {!!descendantsHierarchy?.length && (
        <ul
          className={cx({
            breadcrumbs__items__list: !tilesView,
            breadcrumbs__items__tiles: tilesView,
            breadcrumbs__items__preview: layout === 'preview',
          })}
        >
          {descendantsHierarchy?.map(({ title, tab }) => (
            <Link
              key={tab}
              to={`${Routes.PRODUCTS}/${productId}/${tab}`}
              title={title}
              className={cx({
                breadcrumbs__child__tiles: tilesView,
                breadcrumbs__child__list: !tilesView,
                breadcrumbs__child__preview: layout === 'preview',
                breadcrumbs__child__detail: layout === 'detail',
              })}
            >
              <span
                className={cx(`${Classes.TEXT_MUTED}`, 'd-block', 'text-truncate', {
                  breadcrumbs__tiles__text: tilesView,
                  breadcrumbs__list__text: !tilesView,
                })}
              >
                {title}
              </span>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductAncestryBreadcrumbs;
