import { ISelectionStandardized, ISelectionMapper } from 'types';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { loadSelection } from 'utils/apis/selection';

export type ILoading = boolean;
export type IRefreshSelection = () => Promise<void>;

export type IUseSelectionData = [
  ISelectionStandardized | undefined,
  ILoading,
  IRefreshSelection,
  React.Dispatch<React.SetStateAction<ISelectionStandardized>>,
];

const useSelection = (selectionId: string, selectionMapper: ISelectionMapper): IUseSelectionData => {
  const [selection, setSelection] = useState<ISelectionStandardized | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const { toastStore } = useStore();

  const refreshSelection = useCallback(async (): Promise<void> => {
    try {
      if (!selectionId) {
        return;
      }
      setLoading(true);
      const data = await loadSelection(selectionId);
      const mappedSelection = selectionMapper(data);
      setSelection(mappedSelection);
    } catch {
      toastStore.error('Failed to load selection');
    } finally {
      setLoading(false);
    }
  }, [selectionId, selectionMapper, toastStore]);

  useEffect(() => {
    refreshSelection();
  }, [refreshSelection]);

  return [selection, loading, refreshSelection, setSelection];
};

export default useSelection;
