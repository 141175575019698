import React from 'react';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { Thumbnail } from 'components/thumbnail';
interface IImageView {
  images?: { label: string; image?: string }[];
  className?: string;
}

export const ImagesView: React.FC<IImageView> = ({ images = [], className }) => {
  return (
    <InfoBoxV2 className="py-3">
      {images.map((data, index) => (
        <Row
          className="py-2"
          label={data.label}
          value={<Thumbnail image={data.image} className={className} />}
          key={`${data.image}-${index}`}
        />
      ))}
    </InfoBoxV2>
  );
};
