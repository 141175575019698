import React from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@mantine/core';

import { AffiliationLogo } from 'components/affiliation-logo/affiliation-logo';
import { Intent } from 'utils/ui';

import './style.scss';

const PageSpinner: React.FC<{}> = observer(() => {
  return (
    <div className="page-spinner">
      <AffiliationLogo />
      <Loader variant={Intent.PRIMARY} />
      <p className="page-spinner__text">Loading Page</p>
    </div>
  );
});

export default PageSpinner;
