import React, { useCallback } from 'react';
import { isEqual } from 'lodash';

import { IProduct } from 'types';
import { useRemote } from 'utils/hooks';
import { loadAllScheduledJobs } from 'utils/actions/product/api';
import { AccessChangeScheduledAccessForm } from 'components/product-access-change/scheduled';

import { ScheduledChangeIndicatorWrapper } from './scheduled-change-indicator-wrapper';

import { ICommonEntity } from './types';
import './style.scss';

interface IScheduledChangeIndicator {
  entity?: ICommonEntity & {
    scheduled_jobs_count?: number | null;
  };
  className?: string;
}

export const ScheduledChangeIndicatorProduct: React.FC<IScheduledChangeIndicator> = ({ entity, className }) => {
  const refresh = useCallback(async () => {
    if (!entity?.scheduled_jobs_count || !entity?.id) {
      return [];
    }
    return loadAllScheduledJobs([entity.id]);
  }, [entity]);

  const [jobs = [], isLoading] = useRemote(refresh, []);

  if (isLoading || !entity || !entity.id) {
    return null;
  }

  if (jobs.length === 0 || entity.scheduled_jobs_count === 0) {
    return null;
  }

  const hasJobType = jobs.some((j) => {
    return j.name === 'change_access_level_on_product' && !isEqual(j['access_level'], entity.access_level);
  });

  if (!hasJobType) {
    return null;
  }

  return (
    <ScheduledChangeIndicatorWrapper className={className}>
      <AccessChangeScheduledAccessForm items={[entity as IProduct]} readOnly />
    </ScheduledChangeIndicatorWrapper>
  );
};
