import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { SelectionsListItem } from 'components/selection/default-selection-list-item';
import { SelectionListItemActionsList } from 'components/action';
import { DataSection } from 'components/data-section';
import { SelectionsPreviewTab } from 'components/selection/selection-preview-tab';
import { SelectionSchema } from 'utils/schemas/selection';
import { useStore } from 'store';
import { SelectionsSelectedTabItem } from 'components/selection/selection-selected-tab-item';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { Model } from 'helpers/filters/types';
import mapSelection from 'components/selection/map-selection';
import { ISelectionStandardized, ISelection, GroupTypes } from 'types';
import { map } from 'lodash';
import { Pages } from 'utils/actions/types';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { ContactSelectionDataSectionListItem } from 'components/selection/contact-selection-data-section-list-item';
import { defaultSelectionFilters, SelectionsFilters } from 'components/selections-filters';
import { DataSectionControls } from 'components/data-section-controls';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ActionsMenu } from 'components/action/utils';

const mapContactSelection = (contactSelection: ISelection): ISelectionStandardized =>
  mapSelection(contactSelection, contactSelection?.users_count);

const page = Pages.SELECTION_LIST;

const sidebarTabs = {
  preview: <SelectionsPreviewTab selectionMapper={mapContactSelection} />,
  filters: <SelectionsFilters />,
  selectedItemRenderer(assetSelection: ISelection) {
    return <SelectionsSelectedTabItem selection={mapContactSelection(assetSelection)} />;
  },
};

const assetsDataFilters: IFilterOption[] = [
  ['type', 'eq', GroupTypes.SELECTION],
  ['main_entity_type', 'eq', 'users'],
];

const ContactSelections: React.FC<{}> = observer(() => {
  useControlDataSection({
    schema: SelectionSchema,
    model: Model.CONTACTS_GROUPS,
    defaultFilters: defaultSelectionFilters,
    filters: assetsDataFilters,
    stats: 'access_level',
    presetSuffix: 'contact-selections',
    persistFilters: true,
  });

  const {
    dataSectionStore: { updateStore, checked },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const mapSelectionsArray = useCallback((selections) => map(selections, (s) => mapContactSelection(s)), []);

  const onSuccess = useCallback(async (): Promise<void> => {
    updateStore({ activeTab: DataSectionSidebarTabs.FILTERS, checked: [] });
    navigate({ pathname: location.pathname, search: location.search });
  }, [updateStore, navigate, location]);

  const handleItemRenderer = useCallback(
    (contactSelection: ISelection): JSX.Element => {
      const mappedContactSelection = mapContactSelection(contactSelection);
      return (
        <SelectionsListItem
          key={mappedContactSelection.id}
          selection={mappedContactSelection}
          contextMenu={
            <ActionsMenu
              component={SelectionListItemActionsList}
              items={[mappedContactSelection]}
              options={{ context: 'single', page, onSuccess }}
            />
          }
          component={ContactSelectionDataSectionListItem}
        />
      );
    },
    [onSuccess],
  );

  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="d-inline-block mb-3" />
        <div>
          <DataSectionControls />
        </div>
      </div>
      <DataSection
        itemRenderer={handleItemRenderer}
        contextMenu={
          <ActionsMenu
            component={SelectionListItemActionsList}
            items={mapSelectionsArray(checked)}
            options={{ context: 'multi', page, onSuccess }}
          />
        }
        tabs={sidebarTabs}
        showListTileViewToggleButton={false}
      />
    </div>
  );
});

export default ContactSelections;
