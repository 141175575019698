import { isInMainPage } from 'utils/actions/utils';
import { IShouldDisplayCommonActionFunction } from 'utils/actions';

import type { IShouldDisplayOrganizationActionFunction } from './types';

const isNotDeleted = (entities: { deleted_at?: string }[]): boolean => entities.every((org) => !org.deleted_at);

export const shouldDisplayDeleteAction: IShouldDisplayOrganizationActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return isNotDeleted(entities) && isInMainPage(options);
};

export const shouldDisplayRestoreAction: IShouldDisplayOrganizationActionFunction = (action, index, user, entities) => {
  return entities.every((org) => Boolean(org.deleted_at));
};

export const shouldDisplayEditAction: IShouldDisplayOrganizationActionFunction = (action, index, user, entities) => {
  return entities.length === 1 && isNotDeleted(entities);
};

export const shouldDisplayRemoveFromGroup: IShouldDisplayOrganizationActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities.length > 0 && Boolean(options.groupId);
};

export const shouldDisplayTitle: IShouldDisplayCommonActionFunction = (action, index, user, items, options) => {
  return isNotDeleted(items) && Boolean(options?.groupId || options?.groupName);
};
