import React from 'react';
import ProductCreate from 'pages/product-create/product-create';
import { Page } from 'components/layout';

export const ProductCreatePage: React.FC = () => {
  return (
    <Page title="Create New Product">
      <ProductCreate />
    </Page>
  );
};
export default ProductCreatePage;
