import { MantineThemeOverride } from '@mantine/core';

export const defaultTheme: MantineThemeOverride = {
  defaultRadius: 'sm',
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '87.5em',
  },
  components: {
    Checkbox: {
      styles: {
        input: { cursor: 'pointer' },
        label: { cursor: 'pointer' },
      },
    },
    Radio: {
      styles: {
        radio: { cursor: 'pointer' },
        label: { cursor: 'pointer' },
      },
    },
  },
};
