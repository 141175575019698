import { ICategory, IGenreTreeItem } from 'types';

export const getGenreTree = (genres: ICategory[]): IGenreTreeItem[] => {
  if (!genres?.length) return [];
  const map: Record<number, IGenreTreeItem> = {};
  genres.forEach((item) => {
    map[item.id] = {
      ...item,
      label: item.name,
      value: item.id.toString(),
      sequence_number: item.sequence_no,
      children: [],
    };
  });

  const tree: IGenreTreeItem[] = [];

  genres.forEach((item) => {
    if (item.parent_id === null) {
      tree.push(map[item.id]);
    } else {
      if (item.parent_id && map[item.parent_id]) {
        map[item.parent_id].children?.push(map[item.id]);
      }
    }
  });

  return tree;
};

export const findNodeById = (tree: IGenreTreeItem[], id?: number): IGenreTreeItem | null => {
  if (!id || !tree?.length) return null;
  for (const node of tree) {
    if (node.id === id) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const result = findNodeById(node.children, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
