import React from 'react';

import { useAudioActions } from 'utils/actions/audio';
import { IUseActionsOptionParam } from 'utils/actions';

import { IAudioTrack } from 'types';
import { renderMenuActionItems } from 'utils/ui';

import { IItemActionsMenuProps } from './utils';

export interface IAudioActionsListProps extends IItemActionsMenuProps<IAudioTrack> {
  options: IUseActionsOptionParam<IAudioTrack>;
}

export const AudioActionsList: React.FC<IAudioActionsListProps> = (props) => {
  const { items, options } = props;

  const actions = useAudioActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
