import { UmGenericListItem } from '@mediafellows/mm3-types';
import last from 'lodash/last';

import {
  IAsset,
  IProductAsset,
  IListItem,
  IProduct,
  IPreviewImage,
  IEntity,
  IOrganization,
  IContact,
  IMm3Asset,
  Globals,
} from 'types';
import { formatAssetType, isProduct } from 'utils/general';
import { formatMm3TypeTag } from 'utils/product';

export const getPreviewImage = (item: IListItem | IProductAsset, entityName = 'entity'): IPreviewImage | null => {
  const entity = item?.[entityName] || {};
  return (
    (item as UmGenericListItem).preview_image ||
    entity.preview_image ||
    (entity as IProduct).inherited_preview_image ||
    (entity.logo && { url: entity.logo })
  );
};
export const typeParser = (entity?: IEntity): string => {
  if (!entity?.id) {
    return '';
  }

  const type = getEntityType({ entity } as IListItem, 'entity');

  if (type.endsWith('category')) {
    return 'genre';
  }

  if (type === 'am.news') {
    return 'news';
  }

  if (type === 'organization') {
    return (entity as IOrganization).classification || '';
  }

  if (type.endsWith('user')) {
    return (entity as IContact).role?.name;
  }

  if (entity?.['@type']?.includes('asset')) {
    return formatAssetType((entity as IAsset).classification);
  }

  const separator = type.includes('::') ? '::' : type.includes('/') ? '/' : '';
  if (!separator) {
    return type;
  }
  if (isProduct({ type })) return formatMm3TypeTag(type) || '';

  return last(type.split(separator)) || '';
};

export const getEntityType = (
  item: UmGenericListItem | IProductAsset | { entity: IAsset | IMm3Asset },
  entity: string,
): string => {
  if (!item?.[entity]) {
    return '';
  }

  return (item[entity] as IProduct).type || item[entity]['@type'] || item[entity]['$type'] || '';
};

export const getThumbnailType = (item: IListItem | IProductAsset, entityName = 'entity'): string => {
  const entity = item?.[entityName] || {};
  if (entity['@type'] === 'organization') {
    return entity['@type'];
  }

  if (entity['@type']?.includes('asset') || entity['type']?.includes('Asset::')) {
    return entity.classification;
  }

  if (entity['$type'] === 'am.news') {
    return 'news';
  }

  return getEntityType(item as IListItem, entityName);
};

export const getItemsPerPage = (): number | string => localStorage.getItem(Globals.DATASECTION_ITEMS_PER_PAGE_LS) || 25;
