import React, { useCallback, useEffect, useState } from 'react';
import { map } from 'lodash';

import { IProduct } from 'types';
import { useStore } from 'store';
import { IPassOnDataFields, IUseActionsOptionParam } from 'utils/actions/types';
import { fields, fieldsInfo, initialValues } from './constants';
import { shouldDisplayPassOnData as shouldDisplay } from './actions-acl';

import { passOnDataToProducts } from './api';

import { FormSubmitSection } from 'components/form-submit-section';
import { FormCheckbox, FormChecklist, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';

import './style.scss';

export interface IPassOnDataFormProps {
  onSubmit: (values: IPassOnDataFields) => void;
}

const customContext = {
  properties: {
    fields: {
      validations: [{ value_inclusion: { in: fields } }, { length: { allow_blank: false } }],
    },
  },
};

const PassOnDataForm: React.FC<IPassOnDataFormProps> = ({ onSubmit }) => {
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const buttonLabel: {
    confirm: string;
  } = { confirm: 'Pass on Data' };

  const {
    formData,
    handlers,
    valid,
    values,
    onSubmit: handlersSubmit,
  } = useForm<IPassOnDataFields>(initialValues, Model.PRODUCTS, onSubmit, customContext);
  const selectAll = useCallback(
    ({ all }: { all: boolean }): void => {
      setAllChecked(all);
      handlers.onChange({ fields: all ? fields : [] });
    },
    [handlers],
  );

  useEffect(() => {
    setAllChecked(values.fields.length === fields.length);
  }, [values]);

  return (
    <form onSubmit={handlersSubmit}>
      <span>Please select values you want to be copied to all descendants</span>
      <FormCheckbox
        name="all"
        label="All"
        value={allChecked}
        touched={false}
        required={false}
        onChange={selectAll}
        className={'pass-data__checkboxes--all my-2'}
      />
      <div className="pass-data__form mt-2">
        <FormChecklist name="fields" formId="pass-on-data" {...formData.fields} options={fieldsInfo} {...handlers} />
      </div>
      <FormSubmitSection labels={buttonLabel} submitDisabled={!valid} />
    </form>
  );
};

export const usePassOnDataAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IPassOnDataFields) => {
      try {
        await passOnDataToProducts(map(items, 'id'), values);
        toastStore.success(`Passing data to descendants succeeded`);
        await options?.onSuccess?.();
        return dialogStore.close();
      } catch (error) {
        toastStore.error(`Passing data to descendants failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [toastStore, dialogStore, items, options],
  );

  const openPassDataDialog = useCallback((): void => {
    dialogStore.openModal({
      title: 'Pass on data to descendants',
      body: () => <PassOnDataForm onSubmit={handleSubmit} />,
    });
  }, [dialogStore, handleSubmit]);
  return {
    name: IProductActionName.PASS_ON_DATA,
    shouldDisplay,
    icon: 'inheritance',
    title: 'Pass on Data',
    handler: openPassDataDialog,
  };
};
