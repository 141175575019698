import React from 'react';
import { IOrganization } from 'types';
import { formatDate } from 'utils/date';

import { LabelValuePair as Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { OrganizationLogo } from 'components/organization';
interface IOrganizationDetailsOverviewSidebar {
  organization: IOrganization;
}

export const OrganizationDetailsOverviewSidebar: React.FC<IOrganizationDetailsOverviewSidebar> = ({ organization }) => {
  const { classification, legal_entity, country, created_at, updated_at, language } = organization || {};
  const mobile = organization?.phones?.find((phone) => phone.label.toLocaleLowerCase() === 'mobile');

  return (
    <div className="contact-general-tab__account h-100">
      <div className="contact-general-tab__avatar-wrapper pt-3">
        <OrganizationLogo offset={13} indicatorSize={23} organization={organization} />
      </div>
      <InfoBoxV2 gridTemplateColumns="1fr 2fr" useBackground wrapperClassName="p-3 m-3">
        <Row label="Type" value={classification} size="m" />
        <Row label="Legal Entity" value={legal_entity} size="m" />
        <Row label="Country" value={country?.name} size="m" />
        <Row label="Mobile" value={mobile?.number} size="m" />
        <Row label="Language" value={language?.name} size="m" />
        <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
        <Row label="Updated at" rawLabel value={formatDate(updated_at)} size="m" />
      </InfoBoxV2>
    </div>
  );
};
