import { IContact } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';

import { IContactFields } from './upsert-contact';
import { prepareUserData } from './utils';

export async function createUser(contact: IContactFields): Promise<IContact> {
  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action(Model.CONTACTS, 'create', {
      ROR: true,
      body: prepareUserData(contact),
    });

    return object;
  });
}
