import React, { useState } from 'react';
import { Tooltip, TextInput } from '@mantine/core';

import { IAssetArtifact } from 'utils/actions/types';
import { parseDataSize } from 'utils/general';
import { ISummaryListItemProps } from 'components/delivery/types';
import { FormCheckbox } from 'helpers/form';
import { FormGroup } from 'helpers/form/fields/form-group';

export const ArtifactItem: React.FC<ISummaryListItemProps> = ({ form, artefact }) => {
  const fileName = artefact.storage_url?.split('/').pop();
  const type = artefact['@type']?.split('/').pop();
  const [name, setName] = useState(artefact.overwritten_artefact_name);
  const [checked, setChecked] = useState(artefact.checked);

  const onArtifactChecked = (value, id): void => {
    setChecked(value.checked);

    const artefacts = [...(form?.values.artefacts as IAssetArtifact[])];
    const updatedArtifacts = artefacts.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          checked: value.checked,
        };

        return updatedItem;
      }

      return item;
    });

    form?.handlers.onChange({ artefacts: updatedArtifacts });
  };

  const onFileNameChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setName(evt.target.value);

    const artefacts = [...(form?.values.artefacts as IAssetArtifact[])];
    const updatedArtifacts = artefacts.map((item) => {
      if (item.id === artefact.id) {
        const updatedItem = {
          ...item,
          overwritten_artefact_name: evt.target.value,
        };

        return updatedItem;
      }

      return item;
    });

    form?.handlers.onChange({ artefacts: updatedArtifacts });
  };

  return (
    <div key={artefact.id} className="entity-list-item-row">
      <FormCheckbox
        name="checked"
        className="m-auto"
        value={checked}
        onChange={(value) => onArtifactChecked(value, artefact.id)}
      />
      <div className="entity-list-item-row__type text-nowrap text-truncate">
        <Tooltip label={<div className="top-tooltip">Filename</div>} position={'top'}>
          <span className="entity-list-item-row__inner-content text-nowrap text-truncate">{fileName}</span>
        </Tooltip>
      </div>

      <div className="entity-list-item-row__type text-nowrap text-truncate">
        <Tooltip label={<div className="top-tooltip">File Type</div>} position={'top'}>
          <span className="entity-list-item-row__inner-content text-nowrap text-truncate">{type}</span>
        </Tooltip>
      </div>
      <div className="entity-list-item-row__size text-nowrap text-truncate">
        <Tooltip label={<div className="top-tooltip">File Size</div>} position={'top'}>
          <span className="entity-list-item-row__inner-content text-nowrap text-truncate">
            {parseDataSize(artefact.file_size)}
          </span>
        </Tooltip>
      </div>
      <div className="entity-list-item-row__field">
        <FormGroup>
          <TextInput
            type="text"
            name="name"
            value={name as string}
            onChange={onFileNameChange}
            placeholder="Set new File Name"
          />
        </FormGroup>
      </div>
    </div>
  );
};
