import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { FormInput, FormDate, FormSelect, IUseFormReturn } from 'helpers/form';
import { minBirthDate, maxBirthDate } from 'utils/constants';
import { countries } from '@mediafellows/tuco/dist/lib/localization';
import { IRegistrationData } from 'pages/registration';
import { useQuery } from 'utils/url';
import { Intent } from 'utils/ui';

export interface IAboutFields {
  user_first_name: string;
  user_last_name: string;
  user_gender: string;
  user_date_of_birth: string;
  user_email: string;
  user_phone_0_number?: string;
  user_country_id: string;
}

export interface IStepAboutProps {
  isVisible: boolean;
  onContinue: (formData: IAboutFields) => void;
  form: IUseFormReturn<Partial<IRegistrationData>>;
}

type IQueryFields = Pick<IAboutFields, 'user_email' | 'user_first_name' | 'user_last_name'>;
const fields: (keyof IQueryFields)[] = ['user_email', 'user_first_name', 'user_last_name'];

export const keysAbout = ['user_first_name', 'user_last_name', 'user_email', 'user_country_id'];

const StepAbout: React.FC<IStepAboutProps> = observer((props) => {
  const { form } = props;
  const ctries = useMemo(() => countries.map(({ id, name }) => ({ value: id, label: name })), []);
  const { user_email, user_first_name, user_last_name } = useQuery<IQueryFields>(fields);

  const { formData, handlers, valid, onSubmit } = form;

  useEffect(() => {
    if (user_first_name || user_last_name || user_email) {
      handlers.onChange({ user_first_name, user_last_name, user_email });
    }
  }, [user_email, user_first_name, handlers, user_last_name]);

  return (
    <>
      <form className="registration__form py-4" onSubmit={onSubmit}>
        <FormInput name="user_first_name" type="text" label="First Name" {...formData.user_first_name} {...handlers} />
        <FormInput name="user_last_name" type="text" label="Last Name" {...formData.user_last_name} {...handlers} />
        <FormSelect name="user_gender" label="Gender" large={true} {...formData.user_gender} {...handlers} />
        <FormDate
          className="registration__date"
          large
          name="user_date_of_birth"
          label="Birthday"
          minDate={minBirthDate}
          maxDate={maxBirthDate}
          {...formData.user_date_of_birth}
          {...handlers}
          hideTodayButton
        />
        <FormInput name="user_email" type="email" label="Email" {...formData.user_email} {...handlers} />
        <FormInput
          name="user_phone_0_number"
          type="phone"
          label="Phone Number"
          {...formData.user_phone_0_number}
          {...handlers}
        />
        <FormSelect
          name="user_country_id"
          label="Country"
          large={true}
          {...formData.user_country_id}
          {...handlers}
          options={ctries}
        />
      </form>
      <div className="registration__btn">
        <Button disabled={!valid} variant={Intent.PRIMARY} type="submit" onClick={onSubmit}>
          Continue
        </Button>
      </div>
    </>
  );
});

export default StepAbout;
