import React from 'react';

import { AssetsDataSection } from 'components/asset/assets-data-section';
import { EntityExportButton } from 'components/entity-export';
import { DataSectionPage } from 'components/layout';
import { exportAssets } from 'utils/apis/asset';
import { assetInitFilters } from 'components/asset/asset-filters';

const Assets: React.FC = () => {
  return (
    <DataSectionPage>
      <AssetsDataSection
        initFilters={assetInitFilters}
        persistFilters
        headerRightSection={<EntityExportButton entity="assets" apiCall={exportAssets} />}
      />
    </DataSectionPage>
  );
};

export default Assets;
