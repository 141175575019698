import React from 'react';
import cx from 'classnames';

import { IOrganization, IPhone, IIdentifiable, DetailsPageTabs } from 'types';
import { DialogStore } from 'store/dialog-store';
import { ToastStore } from 'store/toast-store';
import { useDeleteAction } from 'utils/actions/organization/delete-action';
import { useOrganizationActions } from 'utils/actions';
import { IUseActionsOptionParam } from 'utils/actions';
import { ActionMenuItem } from 'components/action';
import { IOrganizationFields } from 'pages/upsert-organization/types';
import { IUseFormReturn } from 'helpers/form';
import { ISectionHeaderAction } from 'components/section-header';
import { Pages } from 'utils/actions/types';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';
import { ITabValue, Tab, TabPanel } from 'components/tabs';
import { getIsTabDisabled } from 'utils/general';

interface IRenderPhonesMenuResult {
  type: 'phone';
  handler?: () => void;
  submenu?: {
    type: 'phone';
    title: string;
    handler: () => void;
  }[];
}

export const renderPhonesMenu = (phones: IPhone[]): IRenderPhonesMenuResult | undefined => {
  if (!phones) {
    return;
  }

  if (phones.length === 1) {
    return {
      type: 'phone',
      handler: () => {
        document.location.href = `tel:${phones[0].number}`;
      },
    };
  }

  if (phones.length > 1) {
    return {
      type: 'phone',
      submenu: phones.map((phone) => ({
        type: 'phone',
        title: `${phone.label}: ${phone.number}`,
        handler: () => (document.location.href = `tel:${phone.number}`),
      })),
    };
  }
};

interface IUseHeaderActionParams {
  organization: IOrganization & IIdentifiable;
  refresh: () => Promise<void>;
  organizationForm: IUseFormReturn<IOrganizationFields>;
  toastStore: ToastStore;
  dialogStore: DialogStore;
  editModeEnabled: boolean;
  setEditModeEnabled: (enabled: boolean) => void;
}

export const useHeaderActions = ({
  organization,
  organizationForm,
  editModeEnabled,
  setEditModeEnabled,
  refresh,
}: IUseHeaderActionParams): { headerActions: ISectionHeaderAction[] } => {
  const headerActions: ISectionHeaderAction[] = [];

  const { email, phones = [] } = organization || {};
  const navigate = useNavigate();

  if (email) {
    headerActions.push({
      type: 'email',
      handler: () => {
        window.location.href = `mailto:${email}`;
      },
    });
  }

  const phonesElement = renderPhonesMenu(phones);
  if (phonesElement) {
    headerActions.push(phonesElement);
  }
  headerActions.push({ divider: true });

  if (editModeEnabled) {
    headerActions.push({
      type: 'save',
      disabled: !organizationForm.valid,
      handler: organizationForm.onSubmit,
    });
    headerActions.push({
      type: 'cancel',
      handler: () => {
        organizationForm.resetFields();
        setEditModeEnabled(false);
      },
    });
  } else {
    headerActions.push({
      type: 'edit',
      handler: () => {
        setEditModeEnabled(true);
      },
    });
  }

  const { handler } = useDeleteAction([organization], {
    onSuccess: async () => {
      navigate(`${Routes.ORGANIZATIONS}`);
    },
  } as IUseActionsOptionParam);

  headerActions.push({
    type: 'delete',
    handler,
  });

  const singleActions = useOrganizationActions([organization], {
    onSuccess: refresh,
    page: Pages.DETAILS,
    context: 'single',
  });

  headerActions.push({
    type: 'more',
    submenu: singleActions.map((action, index) => <ActionMenuItem action={action} key={`${action.name}_${index}`} />),
  });
  return { headerActions };
};

export const additionalContextConfig = [
  { prefix: 'phones', model: 'um.organization/phone' },
  { prefix: 'addresses', model: 'um.organization/address' },
  { prefix: 'social_media_profiles', model: 'um.organization/social_media_profile' },
];

export const emptyOrganizationValues: IOrganizationFields = {
  id: '',
  name: '',
  access_level: '',
  classification: '',
  legal_entity: '',
  language_id: '',
  currency_id: '',
  registration_number: '',
  responsible_user_id: '',
  geo_scope_ids: [],
  key_persons: [],
  tax_number: '',
  withholding_tax_rate: '',
  vat_number: '',
  vat_rate: '',
  bank_accounts: [],
  email: '',
  phones: [],
  social_media_profiles: [],
  urls: [],
  courier_services: [],
  addresses: [],
  tags: [],
  logo: '',
  banner: '',
};

export const omitAddressesCountry = ({ addresses, ...organization }: IOrganization): IOrganization => {
  const parsedAddresses = (addresses || []).map(({ country: _, ...rest }) => rest);
  return { ...organization, addresses: parsedAddresses };
};
export const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.IMAGES, DetailsPageTabs.DELIVERY_INFORMATION];

export const OrganizationDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
  hidden?: boolean;
  valueParser?: (value: ITabValue) => string;
}> = ({ value, activeTab, editModeEnabled, hidden = false, valueParser }) => {
  return (
    <Tab
      value={value}
      className={cx(`entity-detail__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={getIsTabDisabled(value, editableTabs, editModeEnabled)}
      hidden={hidden}
      valueParser={valueParser}
    />
  );
};

export const OrganizationTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({
  value,
  content,
}) => {
  return <TabPanel value={value} className="entity-detail__tab-content h-100" content={content} />;
};
