import { initializeUppy } from 'components/upload-preview-image/api';
import { Model } from 'helpers/filters/types';
import { IPreviewImage, ItemId } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { generatePDFPreview } from 'utils/generate-pdf-preview';
import { logger } from 'utils/logger';

export const ingestPreviewImage = async (
  src_url?: string,
  currentPreviewImageId?: ItemId | null,
  asset_id?: ItemId | null,
): Promise<IPreviewImage> => {
  return chipmunk.run(async ({ action }) => {
    if (!src_url) {
      logger.error('No file or file.url provided to uploadPreviewImage');
      return;
    }
    currentPreviewImageId && deletePreviewImage(currentPreviewImageId);
    const { object } = await action<IPreviewImage>(Model.PREVIEW_IMAGE, 'ingest', {
      body: { src_url, ...(asset_id ? { asset_id } : {}) },
    });

    return object;
  });
};

export const fetchPreviewImage = async (id?: ItemId | null): Promise<IPreviewImage | undefined> => {
  if (!id) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.PREVIEW_IMAGE, 'get', {
      params: { id },
    });
    return object;
  });
};

export const fetchPreviewImages = async (ids?: ItemId[]): Promise<IPreviewImage[]> => {
  if (!ids || (Array.isArray(ids) && !ids.filter(Boolean).length)) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.PREVIEW_IMAGE, 'get', {
      params: { preview_image_ids: ids },
    });

    return objects;
  });
};

export const deletePreviewImage = async (ids?: ItemId | ItemId[] | null): Promise<IPreviewImage | undefined> => {
  if (!ids || (Array.isArray(ids) && !ids.length)) {
    return;
  }

  return chipmunk.run(async (chipmunk) => {
    const { object } = await chipmunk.action<IPreviewImage>(Model.PREVIEW_IMAGE, 'delete', {
      params: { preview_image_ids: ids },
    });

    return object;
  });
};

export const createPdfPreviewImage = async (url?: string): Promise<IPreviewImage | undefined> => {
  if (!url?.endsWith('pdf')) {
    return;
  }

  const previewBlob = await generatePDFPreview(url);
  if (!previewBlob) {
    return;
  }

  const uppy = await initializeUppy({ type: 'preview', autoProceed: false, target: '' });
  uppy.addFile({
    data: previewBlob,
    name: previewBlob.name,
    type: previewBlob.type,
    id: 'preview-' + previewBlob.name,
  });
  const uploadResult = await uppy.upload();
  const previewUrl = uploadResult.successful[0].uploadURL;
  const previewImage = await ingestPreviewImage(previewUrl);

  return previewImage;
};
