import React, { useCallback, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { Loader, Menu } from '@mantine/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { EqFilter, IAggregation, InFilter, isInFilter, NeFilter } from 'helpers/filters/types';
import { highlightText } from 'helpers/form/fields/helpers';
import { IDataProvider, IDataProviderItem } from 'helpers/data-provider/option-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';
import { useDebounce } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';

import cx from 'classnames';

import './style.scss';

type ISelectFilterDefinition = EqFilter | NeFilter | InFilter;

interface IFilterSelectProps {
  name: string;
  label: string;
  placeholder?: string;
  optionsProvider: IDataProvider;
  large?: boolean;
  disabled?: boolean;
  className?: string;

  aggregations?: IAggregation[];
  filter: ISelectFilterDefinition;
  onChange?: (newValue: { [key: string]: ISelectFilterDefinition }) => void;
}

const FilterSelectComponent = Select.ofType<IDataProviderItem>();

const FilterSelect: React.FC<IFilterSelectProps> = observer((props) => {
  const { name, placeholder, label, filter, aggregations, onChange, optionsProvider, className, ...restProps } = props;
  const { value } = filter || {};

  const [selectedItem, setSelectedItem] = useState<{ value: string | number; label: string } | null>(null);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const options = useMemo<IDataProviderItem[]>(
    () => (optionsProvider.loading ? [] : optionsProvider.data),
    [optionsProvider.loading, optionsProvider.data],
  );
  const hasAggregations = useMemo(() => typeof aggregations !== 'undefined', [aggregations]);
  const isDisabled = hasAggregations && !aggregations?.length;
  const isEmpty = !value || (Array.isArray(value) && !value?.length);

  useEffect(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  useEffect(() => {
    if (isEmpty) {
      setSelectedItem(null);
      return;
    }
    const formattedValue = Array.isArray(value) ? value[0] : value;
    const selected = options.find((o) => o.value === formattedValue);
    if (selected) {
      setSelectedItem(selected);
    } else {
      setSelectedItem(optionsProvider.cachedSelectedValue || null);
    }
  }, [isEmpty, options, optionsProvider, value]);

  useEffect(() => {
    optionsProvider.filter(debouncedQuery);
  }, [debouncedQuery, optionsProvider]);

  const itemRenderer: ItemRenderer<IDataProviderItem> = useCallback(
    (item, { handleClick, modifiers: { matchesPredicate, disabled, active }, query }) => {
      if (!matchesPredicate) {
        return null;
      }

      const aggr = aggregations?.find((a) => a.value === item.value);
      const allowItem = !disabled && (!hasAggregations || aggr?.count);

      if (!allowItem) {
        return null;
      }

      return (
        <Menu withinPortal>
          <Menu.Item
            rightSection={aggr && `${aggr.count}`}
            className={cx({ active: active }, 'filter-select__menu-item')}
            key={item.value}
            onClick={handleClick}
          >
            {highlightText(item.label, query)}
          </Menu.Item>
        </Menu>
      );
    },
    [aggregations, hasAggregations],
  );

  const handleChange = useCallback(
    (item: IDataProviderItem): void => {
      setSelectedItem({ ...item });
      optionsProvider?.cacheSelectedValue({ ...item });
      if (onChange) {
        isInFilter(filter)
          ? onChange({ [name]: { ...filter, value: [item.value] } })
          : onChange({ [name]: { ...filter, value: item.value } });
      }
    },
    [optionsProvider, onChange, filter, name],
  );

  const handleQueryChange = useCallback((query: string) => {
    setQuery(query);
  }, []);

  const handlePopoverOpen = useCallback(() => {
    optionsProvider.filter(debouncedQuery);
  }, [optionsProvider, debouncedQuery]);

  const handleReset = useCallback((): void => {
    setSelectedItem(null);
    if (onChange) {
      isInFilter(filter)
        ? onChange({ [name]: { ...filter, value: [] } })
        : onChange({ [name]: { ...filter, value: '' } });
    }
  }, [filter, name, onChange]);

  return (
    <div className={classNames(className, 'd-flex align-items-end')}>
      <div className="flex-fill filter-select__wrap">
        <FormGroup label={label} labelFor={name}>
          <FilterSelectComponent
            className="filter-select"
            onItemSelect={handleChange}
            activeItem={selectedItem}
            items={options}
            itemRenderer={itemRenderer}
            query={debouncedQuery}
            disabled={isDisabled}
            resetOnClose={true}
            resetOnSelect={true}
            onQueryChange={handleQueryChange}
            noResults={
              <Menu>
                <Menu.Item disabled={true}>
                  {optionsProvider.loading ? <Loader size={20} /> : 'Nothing found.'}
                </Menu.Item>
              </Menu>
            }
            popoverProps={{
              minimal: true,
              fill: true,
              onOpening: handlePopoverOpen,
              popoverClassName: 'filter-select__popover',
            }}
            {...restProps}
          >
            <Button
              className={classNames('filter-select__button', {
                empty: isEmpty,
              })}
              fill={true}
              name={name}
              disabled={isDisabled}
              text={selectedItem?.label || placeholder}
              rightIcon={
                <>
                  <MantineIcon icon="caret-down" />
                </>
              }
              {...restProps}
            />
          </FilterSelectComponent>
        </FormGroup>
      </div>
      <div>
        <Button minimal={true} className="mb-3" icon="cross" disabled={isEmpty} onClick={handleReset} />
      </div>
    </div>
  );
});

export default FilterSelect;
