import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';

import { useSortDataSectionStore } from 'store';

import { assetItemRenderer } from 'components/group-items-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';

import { fetchProductAssets, saveOrder } from 'utils/apis/product-asset';

import { IProductAsset, IAsset } from 'types';
import { ISortableItem } from 'store/sort-data-store';

const assetListItemRenderer = (asset: ISortableItem): JSX.Element => assetItemRenderer(asset, true);

const ProductAssetsSortTab: React.FC<{
  productId: string;
  setProductAssets: Dispatch<SetStateAction<IProductAsset[]>>;
}> = observer(({ productId, setProductAssets }) => {
  const { initStore } = useSortDataSectionStore<IAsset>();
  const fetcher = useCallback((): Promise<IAsset[]> => fetchProductAssets(productId), [productId]);

  const onSave = useCallback(
    async (list: IAsset[]): Promise<void> => {
      const productAssets = await saveOrder(list);
      setProductAssets(productAssets as IProductAsset[]);
    },
    [setProductAssets],
  );

  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={assetListItemRenderer} />;
});

export default ProductAssetsSortTab;
