import React, { useCallback } from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { FormAddress, FieldListV2 } from 'helpers/form';

import type { IOrganizationFormPart } from './types';

export const addressesDataKeys = ['addresses'];

export const FormAddresses: React.FC<IOrganizationFormPart> = ({ formData, formHandlers, formValues }) => {
  const fieldProps = useCallback(
    () => ({
      formData,
      handlers: formHandlers,
      large: true,
    }),
    [formData, formHandlers],
  );

  return (
    <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
      <FieldListV2
        prefix="addresses"
        label="Addresses"
        component={FormAddress}
        fieldProps={fieldProps}
        formValues={formValues}
        formHandlers={formHandlers}
      />
    </FormSectionWrapperV2>
  );
};
