import { ISelectionStandardized } from 'types';
import { useRecommendMobileSelectionDialog } from 'components/recommendation';
import { IUseActionsOptionParam } from 'utils/actions';

import { ISelectionActionConfig, ISelectionActionName } from './types';
import { shouldDisplayRecommend } from './actions-acl';

export const useRecommendAction = (
  items: ISelectionStandardized[],
  options: IUseActionsOptionParam,
): ISelectionActionConfig => {
  const [handler] = useRecommendMobileSelectionDialog({ group: items?.[0], options });

  return {
    name: ISelectionActionName.RECOMMEND,
    shouldDisplay: shouldDisplayRecommend,
    icon: 'share',
    title: 'Recommend',
    handler,
  };
};
