import { useCallback } from 'react';
import { map } from 'lodash';

import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { shouldDisplayDelete as shouldDisplay } from 'utils/actions/common';
import { deleteGroups } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { IMarketingEvent, ItemId } from 'types';
import { IMarketingEventActionName } from './types';

export const useDeleteAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Event', items.length);

  const executeDelete = useCallback(async () => {
    try {
      await deleteGroups(map(items, 'id') as Array<ItemId>);
      toastStore.success(`${entityWithCount} deleted`);
      await options?.onSuccess?.(IMarketingEventActionName.DELETE);
      return true;
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IMarketingEventActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
