import React, { useCallback, useRef, useState } from 'react';
import omit from 'lodash/omit';

import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormSubmitSection } from 'components/form-submit-section';
import { FormNumericInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { isAssetIngesting } from 'utils/asset';

import { shouldDisplayExtractPreviewImage as shouldDisplay } from './actions-acl';
import { ToastError } from 'components/toast';
import { Player, VideoAsset, VideoPlayer } from '@mediafellows/videoplayer';
import { createPreviewImage } from 'utils/apis/preview-image-mm3';
import { uploadFile } from 'utils/apis/files';
import { getToken } from 'utils/apis/session';
import { UppyFile } from '@uppy/core';
import { LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Loading } from 'components/loading';
import { getConfig } from 'utils/companion';

interface IExtractPreviewFormFields {
  positionOffset: number;
}

const initialValues: IExtractPreviewFormFields = { positionOffset: 0 };

export interface IExtractPreviewImageProps {
  title?: string;
  onSubmit: (positionOffset?: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export interface IExtractPreviewImageForm {
  onSubmit: (values: IExtractPreviewFormFields) => void;
  video: IMm3Asset;
}

const customContext = {
  properties: {
    positionOffset: {
      validations: [
        {
          numericality: {
            greater_than_or_equal_to: 0,
          },
        },
      ],
    },
  },
};

const handleFileUpload = async (previewBlob: Blob): Promise<{ url: string; file?: UppyFile }> => {
  const { power } = getConfig('asset');
  const jwtToken = await getToken(power);

  return await uploadFile(jwtToken, previewBlob);
};

const extractPreviewImage = async (): Promise<{ url: string; file?: UppyFile }> => {
  const video = document.querySelector(
    '.video-player-image__extraction .theo-player-wrapper video',
  ) as HTMLVideoElement;
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext('2d');

  context?.drawImage(video, 0, 0);

  const previewBlob: Blob = await new Promise((resolve) => canvas.toBlob(resolve as BlobCallback));

  try {
    const data = await handleFileUpload(previewBlob);
    return data;
  } catch (error) {
    throw error;
  }
};

const ExtractPreviewImageFormMm3: React.FC<IExtractPreviewImageForm> = ({ onSubmit, video }) => {
  const {
    formData,
    handlers,
    valid,
    onSubmit: onFormSubmit,
  } = useForm<IExtractPreviewFormFields>(initialValues, Model.ASSETS, onSubmit, customContext);
  const playersRef = useRef<Record<number, Player>>({});
  const [loading, { toggle }] = useDisclosure(false);
  const [trackingTimeMs, setTreckingTimeMs] = useState(initialValues.positionOffset);
  const [startTime, setStartTime] = useState(0);

  const handleSubmit = async (data): Promise<void> => {
    toggle();
    return onFormSubmit(data);
  };

  return (
    <>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        loaderProps={{ children: <Loading text="Extracting Preview Image..." /> }}
        overlayProps={{ radius: 'sm', backgroundOpacity: 0.75 }}
      />
      <form onSubmit={handleSubmit}>
        <VideoPlayer
          onPlayer={(player: Player) => {
            playersRef.current[0] = player;
          }}
          onReady={() => {
            const durationMs = video.meta?.duration as number;
            const treckingTime = Math.round((durationMs / 100) * 10);
            setStartTime(treckingTime);
            setTreckingTimeMs(treckingTime);
          }}
          onSeeked={() => {
            playersRef.current[0].pause();
            const currentTime = playersRef.current[0].currentTime;
            setTreckingTimeMs(currentTime);
          }}
          video={video as unknown as VideoAsset}
          muted={true}
          autoplay={true}
          playOnClick={false}
          startTime={startTime}
          className="mb-3 video-player-image__extraction"
        />

        <FormNumericInput
          formId="extract-preview-image"
          name="positionOffset"
          label="Position Offset (seconds)"
          {...formData.positionOffset}
          {...omit(handlers, 'onSetFields')}
          value={Math.round(trackingTimeMs)}
          onChange={({ positionOffset }) => {
            playersRef.current[0].currentTime = positionOffset as number;
          }}
        />
        <FormSubmitSection labels={{ confirm: 'Submit' }} submitDisabled={!valid} />
      </form>
    </>
  );
};

export const useExtractPreviewActionMm3 = (items: (IAsset | IMm3Asset)[], options): IAssetActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(async () => {
    try {
      const { url, file } = await extractPreviewImage();

      const {
        preview_image: { asset_id, id },
      } = items[0];

      await createPreviewImage(asset_id, id, url, file?.size, {
        width: file?.meta.width as number,
        height: file?.meta.height as number,
        orientation: 'landscape',
      });

      toastStore.success('You have successfully uploaded the extracted image from this video.');
      options?.onSuccess(IAssetActionName.EXTRACT_PREVIEW, items[0]);
      return dialogStore.close();
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    }
  }, [items, toastStore, options, dialogStore]);

  const body = useCallback(
    () => <ExtractPreviewImageFormMm3 onSubmit={handleSubmit} video={items[0] as unknown as IMm3Asset} />,
    [handleSubmit, items],
  );

  const openExtractPreviewDialog = useCallback((): void => {
    dialogStore.openModal({
      title: `Extract Preview Image from Video: ${items[0]?.name}`,
      body,
    });
  }, [body, dialogStore, items]);

  return {
    name: IAssetActionName.EXTRACT_PREVIEW,
    shouldDisplay,
    icon: 'unarchive',
    title: 'Extract preview image',
    isDisabled: !items?.[0]?.id || isAssetIngesting(items[0]),
    handler: openExtractPreviewDialog,
  };
};
