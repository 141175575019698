import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { FormInput } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { PmItvstudiosLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

interface IProductEditOthersSectionProps {
  layerForm: IUseMm3FormReturn<PmItvstudiosLayer3MotionPictureStandard>;
}

export const ProductEditOthersSection: React.FC<IProductEditOthersSectionProps> = observer(({ layerForm }) => {
  const { formData, handlers } = layerForm;

  return (
    <FormSectionWrapperV2 className="create_product__form" title="OTHERS">
      <FormInput
        name="meta.share_link"
        key="share_link"
        label="Share Link"
        {...formData.meta?.share_link}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
});
