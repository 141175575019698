import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { IShouldDisplayActionFunction, Pages } from 'utils/actions/types';
import { IShowcaseActionName } from './types';
import { IWebsitePageValues } from 'types/list';
export const shouldDisplayForOneItem: IShouldDisplayActionFunction<UmGenericListShowcase, IShowcaseActionName> = (
  action,
  index,
  user,
  items,
) => {
  return items.length === 1;
};

export const shouldDisplayForCollectionOneItem: IShouldDisplayActionFunction<
  UmGenericListShowcase,
  IShowcaseActionName
> = (action, index, user, items, options) => {
  return options.section === IWebsitePageValues.COLLECTIONS && options.context === 'single';
};

export const shouldDisplayEdit: IShouldDisplayActionFunction<UmGenericListShowcase, IShowcaseActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  return items.length === 1 && options.section !== IWebsitePageValues.COLLECTIONS;
};

export const shouldDisplayDelete: IShouldDisplayActionFunction<UmGenericListShowcase, IShowcaseActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    options.section !== IWebsitePageValues.COLLECTIONS ||
    Boolean(options.page && [Pages.LIST, Pages.DETAILS].includes(options.page))
  );
};
