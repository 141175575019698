import React from 'react';
import { observer } from 'mobx-react-lite';
import { ColorSwatch, Popover } from '@mantine/core';
import { RenderNodePayload } from '@mantine/core/lib/components/Tree/Tree';
import { useDisclosure } from '@mantine/hooks';
import cx from 'classnames';

import { Thumbnail } from 'components/thumbnail';

import { Classes, MantineIcon } from 'utils/ui';

import { IGenreTreeItem } from 'types';

interface IGenreDataSectionItemProps extends Partial<RenderNodePayload> {
  genre: IGenreTreeItem;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const GenreDataSectionSortItem: React.FC<IGenreDataSectionItemProps> = observer(({ genre, handleSelect }) => {
  const { id, name, preview_image, invisible } = genre;
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <li className="genre-search-item">
      <div className="searchbox__genre" data-id={id} data-item-type="genre" onClick={handleSelect}>
        <div className="py-1">
          <Thumbnail image={preview_image?.url} fallbackIconName="diagram-tree" />
        </div>

        <div className="text-truncate text-nowrap">
          <div className={cx('d-flex justify-content-between', { 'pe-2': invisible })}>
            <div className="text-nowrap text-truncate">{name}</div>
            {invisible && (
              <Popover opened={opened}>
                <Popover.Target>
                  <span onMouseEnter={open} onMouseLeave={close}>
                    <MantineIcon icon="eye-off" />
                  </span>
                </Popover.Target>
                <Popover.Dropdown>
                  <div className="p-2 d-flex flex-column">
                    <span>Genre is invisible</span>
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
          </div>
          <div className={`d-flex gap-5 ${Classes.TEXT_MUTED}`}>
            <span>{genre?.children?.length || 0} children</span>
            {genre?.color && <ColorSwatch color={genre?.color} />}
          </div>
        </div>
      </div>
    </li>
  );
});
