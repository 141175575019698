import React from 'react';
import { Tabs } from '@mantine/core';
import cx from 'classnames';

import { DetailsPageTabs, ProductAccessTabSubTabs } from 'types';

export type ITabValue = DetailsPageTabs | ProductAccessTabSubTabs | string;

const DetailPageTab: React.FC<{
  value: ITabValue;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  valueParser?: (value: ITabValue) => string;
}> = ({ value, className, hidden = false, disabled = false, valueParser }) => {
  const title = valueParser ? valueParser(value) : value;
  return (
    <Tabs.Tab value={value} id={value} className={cx(className)} disabled={disabled} hidden={hidden}>
      {title}
    </Tabs.Tab>
  );
};
export default DetailPageTab;
