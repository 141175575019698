import React, { useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { endOfDay, startOfDay } from 'date-fns';

import { IBarChartData } from 'types';
import { ChartContainer } from 'components/chart-container';
import { dateFormat } from 'utils/date';
import { parseTooltipDateTitle } from 'components/analytics';

import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

ChartJS.defaults.color = '#8ca6b7';
ChartJS.defaults.font = { family: 'var(--font-family-sans-serif)', size: 14, weight: '500' };

interface IBarChartProps {
  data1: IBarChartData[];
  data2: IBarChartData[];
  startDate: Date;
  endDate: Date;
  aspectRatio?: number;
  unit1?: string;
  unit2?: string;

  showLegend?: boolean;
}

const DateCountMultiBarChart: React.FC<IBarChartProps> = ({
  data1,
  data2,
  startDate,
  endDate,
  unit1 = 'view',
  unit2 = 'sent',
  aspectRatio = 1.2,
  showLegend = false,
}) => {
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio,
      resizeDelay: 50,
      plugins: {
        legend: {
          display: showLegend,
        },
        tooltip: {
          callbacks: {
            title: parseTooltipDateTitle,
          },
        },
      },
      parsing: { xAxisKey: 'date', yAxisKey: 'count' },
      scales: {
        xAxes: {
          type: 'time' as const,
          time: { unit: 'day' as const, displayFormats: { day: dateFormat } },
          min: startOfDay(startDate).getTime(),
          max: endOfDay(endDate).getTime(),
          offset: true,
          grid: {
            display: false,
            offset: false,
            borderWidth: 1,
            borderColor: '#304658',
            color: '#304658',
          },
          afterTickToLabelConversion: (scaleInstance) => {
            scaleInstance.ticks = [scaleInstance.ticks[0], scaleInstance.ticks[scaleInstance.ticks.length - 1]];
          },
        },
        yAxes: {
          min: 0,
          beginAtZero: true,
          ticks: { precision: 0 },
          offset: false,
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#304658',
            color: '#304658',
          },
        },
      },
    }),
    [aspectRatio, showLegend, startDate, endDate],
  );

  const chartData = {
    datasets: [
      {
        type: 'bar' as const,
        label: `Total ${unit1}`,
        data: React.useMemo(() => data1.map((pt) => ({ date: pt.date, count: pt.count })), [data1]),
        fill: false,
        borderColor: '#2b95d6',
        backgroundColor: '#2b95d6',
        order: 1,
        options: {
          tooltips: {
            mode: 'label',
            intersect: true,
            label: unit1,
          },
        },
      },
      {
        type: 'bar' as const,
        label: `Total ${unit2}`,
        data: React.useMemo(() => data2.map((pt) => ({ date: pt.date, count: pt.count })), [data2]),
        fill: false,
        borderColor: '#3dcc91',
        backgroundColor: '#3dcc91',
        order: 2,
        options: {
          tooltips: {
            mode: 'label',
            intersect: true,
            label: unit2,
          },
        },
      },
    ],
  };
  return (
    <ChartContainer aspectRatio={aspectRatio}>
      <Chart type="bar" options={options} data={chartData} />
    </ChartContainer>
  );
};

export default DateCountMultiBarChart;
