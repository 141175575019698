import { chipmunk, mediaBench } from 'utils/chipmunk';
import {
  IMBError,
  IMBSuccess,
  IMBExportData,
  ICreateNewProject,
  ICreateFolderSuccess,
  ICreateFolderError,
} from 'types/mediabench';
import { IHeaderSettings } from '@mediafellows/chipmunk/dist/src/config';

// initial request retrieves a list of MediaBench projects, while the second request is intended to send asset IDs.
export async function exportAssets(data: IMBExportData): Promise<IMBSuccess | IMBError> {
  const path = `${mediaBench}/api/v1/mediastore/imports`;
  const { 'Session-Id': session_id, 'Affiliation-Id': affiliation_id } = chipmunk.currentConfig()
    .headers as IHeaderSettings;
  if (!session_id || !affiliation_id) {
    throw new Error();
  }
  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id, affiliation_id, import: { ...data } }),
  });
  return await response.json();
}

export async function createNewProject(data: ICreateNewProject): Promise<ICreateFolderSuccess | ICreateFolderError> {
  const path = `${mediaBench}/api/v1/mediastore/projects`;
  const { 'Session-Id': session_id, 'Affiliation-Id': affiliation_id } = chipmunk.currentConfig()
    .headers as IHeaderSettings;
  if (!session_id || !affiliation_id) {
    throw new Error();
  }
  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id, affiliation_id, project: { ...data } }),
  });
  return await response.json();
}

export const getLoginToMBLink = (): string => {
  const { 'Session-Id': session_id, 'Affiliation-Id': affiliation_id } = chipmunk.currentConfig()
    .headers as IHeaderSettings;
  if (!session_id || !affiliation_id) {
    throw new Error();
  }
  const uri = new URL(`${mediaBench}`);
  uri.pathname = '/mediastore/signin';
  Object.entries({ session_id, affiliation_id }).forEach(([key, value]) => {
    uri.searchParams.set(key, value.toString());
  });
  return uri.toString();
};
