import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useBasicStore } from 'store';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { FormSelect, FieldListV2, FormMultiInput } from 'helpers/form';

import type { IOrganizationFormPart } from './types';

export const defaultsDataKeys = ['language_id', 'currency_id', 'courier_services'];

export const FormDefaults: React.FC<IOrganizationFormPart> = observer(({ formHandlers, formData, formValues }) => {
  const { languagesDataOptions, currencyOptions } = useBasicStore();

  const courierField = useCallback(
    () => ({
      form: { data: formData, handlers: formHandlers },
      fields: [
        { key: 'company', label: 'Company' },
        { key: 'account', label: 'Account' },
      ],

      large: true,
    }),
    [formData, formHandlers],
  );

  return (
    <>
      <FormSectionWrapperV2
        title="Defaults"
        className="entity-detail__infobox-wrapper"
        wrapperClassName="py-3"
        withBorder
      >
        <FormSelect
          name="language_id"
          label="Language"
          large
          {...formHandlers}
          {...formData.language_id}
          options={languagesDataOptions}
        />
        <FormSelect
          name="currency_id"
          label="Currency"
          large
          {...formHandlers}
          {...formData.currency_id}
          options={currencyOptions}
        />
      </FormSectionWrapperV2>
      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" padded={false} withBorder>
        <FieldListV2
          label="Courier"
          prefix="courier_services"
          component={FormMultiInput}
          fieldProps={courierField}
          formValues={formValues}
          formHandlers={formHandlers}
        />
      </FormSectionWrapperV2>
    </>
  );
});
