import React from 'react';
import { observer } from 'mobx-react-lite';

import './style.scss';
import { UserAvatar } from 'components/user-avatar';
import { getContactName } from 'utils/ui';
import { Classes } from 'utils/ui';
import { IContact } from 'types';

const ContactSelectedTabItem: React.FC<{ contact: IContact }> = observer(({ contact }) => {
  const { organization } = contact;

  return (
    <div className={'contact-selected-tab-item'}>
      <div className="contact-selected-tab-item__avatar px-2">
        <UserAvatar user={contact} shouldRefresh />
      </div>
      <div className="contact-selected-tab-item__content">
        <div className="contact-selected-tab-item__title text-truncate mb-1">{getContactName(contact)}</div>
        <div className={`text-truncate ${Classes.TEXT_MUTED}`}>{organization?.name || '\u00a0'}</div>
      </div>
    </div>
  );
});

export default ContactSelectedTabItem;
