import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { InfoBox } from 'components/info-box';
import { IStyled } from 'types';
import { Classes } from 'utils/ui';

import './style.scss';

export interface IFormSectionWrapper extends IStyled {
  title?: string;
  children: JSX.Element | JSX.Element[];
  gridTemplateColumns?: string | null;
}
/** @deprecated use FormSectionWrapperV2 instead*/

export const FormSectionWrapper: React.FC<IFormSectionWrapper> = observer(
  ({ children, title, gridTemplateColumns, className }) => {
    return (
      <InfoBox
        className={cx(`form-section-wrapper__container ${className}`)}
        title={Boolean(title)}
        gridTemplateColumns={gridTemplateColumns}
      >
        {title && <div className={cx(`form-section-wrapper__title ${Classes.TEXT_MUTED}`)}>{title}</div>}
        {children}
      </InfoBox>
    );
  },
);

export default React.memo(FormSectionWrapper);
