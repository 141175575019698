import { IAudioTrack } from 'types';

import { updateAudioTrack } from 'utils/apis/audio-track';
import { IAudioActionConfig, IAudioActionName } from './types';
import { IconAlias } from 'icons';
import { useEditAudioDialog } from 'components/asset/edit-audio-dialog';
import { IUseActionsOptionParam } from '..';

export const useEditAudioAction = (
  items: IAudioTrack[],
  options: IUseActionsOptionParam<IAudioTrack>,
): IAudioActionConfig => {
  const audio = items[0];
  const [openDialog] = useEditAudioDialog({
    onSubmit: async (audio: IAudioTrack) => {
      await updateAudioTrack(audio);
      options.onSuccess?.(IAudioActionName.EDIT, audio);
    },
    audio,
  });

  return {
    name: IAudioActionName.EDIT,
    icon: IconAlias.EDIT,
    title: 'Edit',
    handler: openDialog,
  };
};
