import React from 'react';
import cx from 'classnames';

import { ITabValue, Tab, TabPanel } from 'components/tabs';

import { DetailsPageTabs } from 'types';

export const AccessGroupDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  hidden?: boolean;
  disabled?: boolean;
  isSortModeEnabled?: boolean;
  editModeEnabled?: boolean;
  valueParser?: (value?: ITabValue) => string;
}> = ({ value, activeTab, hidden = false, disabled, valueParser, isSortModeEnabled, editModeEnabled }) => {
  return (
    <Tab
      value={value}
      className={cx(`entity-detail__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      disabled={disabled || isSortModeEnabled || editModeEnabled}
      hidden={hidden}
      valueParser={valueParser}
    />
  );
};

export const AccessGroupTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="entity-detail__tab-content h-100" content={content} />;
};
