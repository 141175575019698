export enum IMarketingEventActionName {
  EDIT = 'edit',
  MANAGE_ATTENDEES = 'manage-attendees',
  ADD_PRODUCTS = 'add-products',
  ADD_ASSETS = 'add-assets',
  ADD_EVENTS = 'add-events',
  DELETE = 'delete',
  RESTORE = 'restore',
  REMOVE_FROM_GROUP = 'remove-from-group',
  MANAGE_SLIDER = 'manage-slider',
  MANAGE_HOSTS = 'manage-hosts',
}
