export const SelectionSchema = `
  id,
  name,
  am_statistics_data,
  pm_statistics_data,
  users_count,
  main_entity_type,
  owner_id,
  owner,
  access_level,
  created_at,
  deleted_at
`;
