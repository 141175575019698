import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';

import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IProduct } from 'types';
import { removeElementFromSelection } from 'utils/actions/selection/api';
import { Model } from 'helpers/filters/types';
import { queryProductGroupItems } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { shouldDisplayRemoveFromGroup } from './actions-acl';
import { IProductActionName } from './types';

export const useRemoveFromGroupAction = (
  products: IProduct[],
  options: IUseActionsOptionParam,
): IActionConfig<IProduct, IProductActionName> => {
  const { toastStore, dialogStore } = useStore();
  const params = useParams<{ groupId: string }>();
  const groupId = params[options.groupParamName ?? ''];
  const { entity, entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Product', products.length);

  const executeRemove = useCallback(async () => {
    try {
      const productIds = map(products, 'id');
      if (!groupId || !productIds.length) {
        return;
      }

      const { objects: groupItems } = await queryProductGroupItems(groupId, productIds);
      const itemIds = map(groupItems, 'id');
      await removeElementFromSelection(groupId, itemIds, Model.PRODUCT_GROUP_ITEMS);
      toastStore.success(`${entityWithCount} will be removed from ${options.groupName}`);
      await options?.onSuccess?.(IProductActionName.REMOVE_FROM_GROUP);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, groupId, options, products, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from the ${options.groupName}?`,
      onConfirm: executeRemove,
    });
  }, [dialogStore, entityWithDemonstrative, executeRemove, options.groupName]);

  return {
    name: IProductActionName.REMOVE_FROM_GROUP,
    shouldDisplay: shouldDisplayRemoveFromGroup,
    icon: 'remove',
    title: `Remove from ${options.groupName}`,
    intent: Intent.DANGER,
    handler,
  };
};
