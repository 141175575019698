import React, { useCallback, useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import { SectionHeader } from 'components/section-header';
import { ActivityChart } from 'components/activity-chart';
import { fetchActivityStatistics, getHistogramData } from 'utils/apis/analytics';
import { DetailsPageTabs, IAnalyticsProps, IBarChartData, IDataset, IRecommendationHistogramData } from 'types';
import { Routes } from 'utils/routes';

import {
  CountGaugeChart,
  DateCountMultiBarChart,
  IChartContext,
  parseRecommendationDataForMultiBarChart,
} from 'components/analytics/charts';

import './style.scss';
import { RecommendationAnalyticsTopUsers } from 'components/analytics/contact-analytics';
import { ContactInteractions } from 'components/analytics/utils';

const AnalyticsDoubleBarChart: React.FC<IAnalyticsProps> = (props) => {
  const [countByUniqueRecommendationReceived, setCountByUniqueRecommendationReceived] = useState([] as IBarChartData[]);
  const [countByUniqueRecommendationViewed, setCountByUniqueRecommendationViewed] = useState([] as IBarChartData[]);
  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationSentCount,
      ...props,
      entity: 'recipient',
    }).then(({ data }) => {
      const { viewedData, sentData } = parseRecommendationDataForMultiBarChart(data);
      setCountByUniqueRecommendationReceived(sentData);
      setCountByUniqueRecommendationViewed(viewedData);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-video-screenings__card">
      <SectionHeader title="Recommendations Sent" useBackground useBorder />
      <div className="event-analytics-tab-video-screenings__card-content">
        <DateCountMultiBarChart
          data1={countByUniqueRecommendationReceived}
          data2={countByUniqueRecommendationViewed}
          startDate={props.dateRange[0]}
          endDate={props.dateRange[1]}
          unit1="sent"
          unit2="view(s)"
        />
      </div>
    </div>
  );
};

const labels = ['viewed', 'sent'];
const AnalyticsSuccessRate: React.FC<IAnalyticsProps> = (props) => {
  const [engagementRate, setEngagementRate] = useState([] as number[]);
  const [countByEngagementRate, setCountByEngagementRate] = useState<IRecommendationHistogramData>();

  useEffect(() => {
    if (!props?.dateRange.every(isValid)) {
      return;
    }

    getHistogramData<IRecommendationHistogramData>({
      queryName: props?.queries?.recommendationReceivedEngagementRate,
      ...props,
      entity: 'recipient',
    }).then(({ data }) => {
      if (!data.length) {
        setEngagementRate([]);
      } else {
        const engagementRate = data?.[0].success_percentage || 0;
        const parsedData = [engagementRate, 100 - engagementRate];

        setEngagementRate(parsedData);
        setCountByEngagementRate(data?.[0]);
      }
    });
  }, [props]);

  const customTooltipFormatter = useCallback(
    (context: IChartContext): string => {
      const label = context.label;
      if (label === 'sent') return `${countByEngagementRate?.sent_count} total sent`;
      return `${countByEngagementRate?.viewed_count} viewed at least once`;
    },
    [countByEngagementRate],
  );

  return (
    <div className="event-analytics-tab-video-screenings__card">
      <SectionHeader title="Success Rate" useBackground useBorder />
      <div className="event-analytics-tab-video-screenings__card-content">
        <CountGaugeChart
          data={engagementRate}
          labels={labels}
          titleText={`${Math.round(engagementRate?.[0] * 100) / 100} %`}
          showTitle
          showLegend={false}
          customTooltipFormatter={customTooltipFormatter}
        />
      </div>
    </div>
  );
};

const AnalyticsTopViewers: React.FC<IAnalyticsProps> = (props) => {
  return (
    <RecommendationAnalyticsTopUsers
      queryName={props?.queries?.recommendationTopSenders}
      {...props}
      entity="sender"
      entityName="sender"
      title="Top Senders"
      statisticName="sent"
      interaction={ContactInteractions.RECOMMENDATIONS_SENT}
      url={`${Routes.ANALYTICS_RECOMMENDATIONS}/${DetailsPageTabs.LIST}`}
    />
  );
};

const parseDataset = (data: { sent_count: number; viewed_count: number; date: string }[]): IDataset[] => [
  {
    data: data?.map((item) => item.sent_count),
    label: 'sent',
    borderColor: '#2b95d6',
    backgroundColor: '#1a6da0',
  },
  {
    data: data?.map((item) => item.viewed_count),
    label: 'viewed',
    borderColor: '#3dcc91',
    backgroundColor: '#3dcc91',
  },
];

const RecommendationActivityChart: React.FC<IAnalyticsProps> = (props) => {
  const fetcher = useCallback(() => {
    return fetchActivityStatistics({
      queryName: props?.queries?.recommendationSentCount,
      ...props,
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-video-screenings-list">
      <ActivityChart
        title="Recommendation Activity"
        subtitle="recommendations sent and viewed per day"
        parseDataset={parseDataset}
        fetcher={fetcher}
        aspectRatio={6}
      />
    </div>
  );
};

const InteractionsVideoScreenings: React.FC<IAnalyticsProps> = (props) => {
  return (
    <>
      <div className="event-analytics-tab-video-screenings">
        <AnalyticsDoubleBarChart {...props} />

        <AnalyticsSuccessRate {...props} />

        <AnalyticsTopViewers {...props} />
      </div>

      <RecommendationActivityChart {...props} />
    </>
  );
};

export default InteractionsVideoScreenings;
