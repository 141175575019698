import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { ISortableItem } from 'store/sort-data-store';

import { IWebsitePageValues } from 'types';
import { byId, parseQueryParams } from 'utils/general';
import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { useCreateShowcase } from 'components/showcase-form/showcase-dialog';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import { DataSectionContentWrapper } from 'components/data-section-content-wrapper';
import { SortButton } from 'components/sort-buttons';

import ShowcaseSortDataSection from './website-sort-data-section';
import { isList } from './utils';

import './style.scss';
import { Plus } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

export const WebsiteDataSectionItems: React.FC<{
  className: string;
  section: IWebsitePageValues;
  isSortModeEnabled: boolean;
  setIsSortModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  itemRenderer: (item) => React.ReactNode | JSX.Element;
  title: string;
}> = observer(({ className, itemRenderer, section, isSortModeEnabled, setIsSortModeEnabled, title }) => {
  const {
    dataSectionStore: { searchStore, setParams, reset },
  } = useStore();
  const openCreateListDialog = useCreateShowcase({ title: 'Create Showcase', section });

  const [allowRender, setAllowRender] = useState(false);
  const location = useLocation();

  const { running, objects: sectionItems = [] } = searchStore || {};
  const itemsCount = sectionItems.length;

  useEffect(() => {
    reset();
    setAllowRender(true);
  }, [reset]);

  useEffect(() => {
    if (!searchStore?.parametron || !allowRender) {
      return;
    }

    const searchData = parseQueryParams(location.search);
    setParams(searchData).triggerSearch();
  }, [location, searchStore, allowRender, setParams]);

  const onSortSuccess = useCallback(
    (updatedObjects: ISortableItem[]) => {
      if (updatedObjects?.length) {
        const listsById = byId(updatedObjects);
        const updatedLists = searchStore.objects
          .map((object: UmGenericListShowcase | UmGenericListCollection) => {
            if (!object?.id) return object;
            const newList = listsById[object.id];
            return { ...object, sequence_number: newList.sequence_number };
          })
          .sort((a, b) => a.sequence_number - b.sequence_number);
        searchStore.update({
          objects: updatedLists,
        });
      }
    },
    [searchStore],
  );
  const CustomRightRenderer = useCallback(
    (): JSX.Element => (
      <div className="d-flex  gap-1">
        {Boolean(itemsCount) && (
          <SortButton isSortModeEnabled={isSortModeEnabled} setIsSortModeEnabled={setIsSortModeEnabled} />
        )}
        {!isSortModeEnabled && (
          <Button
            size={'xs'}
            leftSection={<MantineIcon icon={<Plus />} />}
            onClick={openCreateListDialog}
            variant={'primary'}
          >
            Create Showcase
          </Button>
        )}
      </div>
    ),
    [isSortModeEnabled, itemsCount, openCreateListDialog, setIsSortModeEnabled],
  );

  return (
    <div className="data-section-layout">
      <div className={className}>
        <SectionHeader
          useBorder
          className="website-data-section__section-header"
          title={title}
          useBackground
          customRightRenderer={CustomRightRenderer}
        />
        {running && <Loading text="Loading Data" />}

        {!(running || sectionItems.length) && <SectionMessage intent={SectionMessageType.EMPTY} />}

        {!running && Boolean(sectionItems.length) && isList(sectionItems[0]) && !isSortModeEnabled && (
          <div className="data-section__content-wrapper website-data-section__items-list h-100">
            <DataSectionContentWrapper className="h-100" layout="list">
              {sectionItems.map(itemRenderer)}
            </DataSectionContentWrapper>
          </div>
        )}
        {isSortModeEnabled && <ShowcaseSortDataSection items={sectionItems} onSuccess={onSortSuccess} />}
      </div>
    </div>
  );
});

export default WebsiteDataSectionItems;
