import React from 'react';
import { uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

import { DetailsPageTabs } from 'types';
import DateElement from 'components/date-list-element';
import { IColumn } from 'components/list-table';
import { ContactInteractions } from 'components/analytics';
import { formatDuration } from 'utils/date';
import { formatAssetType } from 'utils/general';
import { AnalyticsContact, AnalyticsOrganization, UserTerritory } from 'utils/ui';
import { Routes } from 'utils/routes';

export const recommendationReceivedColumns = (): IColumn[] => {
  return [
    {
      title: 'Contact',
      renderer: ({ recipient }) => (
        <AnalyticsContact user={recipient} interaction={ContactInteractions.RECOMMENDATIONS_RECEIVED} />
      ),
    },
    {
      title: 'Organization',
      renderer: ({ recipient, recipient_organization }) => (
        <AnalyticsOrganization user={recipient} organization={recipient_organization} />
      ),
    },
    {
      title: 'Country',
      renderer: ({ recipient }) => {
        return recipient?.country_name ? (
          <span>{recipient?.country_name}</span>
        ) : (
          <UserTerritory countryId={recipient?.country_id} />
        );
      },
    },
    {
      title: 'Times viewed',
      renderer: ({ views_count }) => <span>{views_count || 'N/A'}</span>,
    },
    {
      title: 'Screening duration',
      renderer: ({ screening_duration }) => <div>{formatDuration(screening_duration, 'seconds')}</div>,
    },
    {
      title: 'Last Screened',
      renderer: ({ last_screened_at }) =>
        last_screened_at === '1970-01-01T00:00:00.000+00:00' ? 'N/A' : <DateElement date={last_screened_at} />,
    },
    {
      title: 'Assets Screened',
      renderer: ({ assets_screened }) => {
        if (!assets_screened?.length) return 'N/A';
        const assetNames = uniqBy(assets_screened, 0).map((asset) => asset[1]);
        const assetContent = assetNames.map((name, index) => <div key={index}>{name}</div>);

        return (
          <Tooltip label={<div>{assetContent}</div>} position={'top'}>
            <div className="video-screenings-list-table__video-products text-truncate">
              <div>{`${assetNames.length} Assets`}</div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Remaining views',
      renderer: ({ allowed_views, views_count }) => {
        const allowedViews = allowed_views ?? <span>Unlimited</span>;
        return isFinite(allowedViews) ? allowedViews - (views_count || 0) : allowedViews;
      },
    },
    {
      title: 'Last viewed',
      renderer: ({ last_viewed_at }) => <DateElement date={last_viewed_at} />,
    },
  ];
};
export const recommendationScreeningsColumns = (): IColumn[] => {
  return [
    {
      title: 'Products',
      renderer: ({ recommendation_id, product, product_id }) =>
        product_id ? (
          <Tooltip label={product?.full_title} position={'top'}>
            <div className="video-screenings-list-table__video-title text-truncate">
              <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.PRODUCTS}`}>
                {product?.full_title}
              </Link>
            </div>
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Asset title',
      renderer: ({ asset }) => (
        <Tooltip label={asset?.name || 'N/A'} position={'top'}>
          <div className="video-screenings-list-table__video-title text-truncate">
            <Link to={`${Routes.ASSETS}/${asset?.id}`}>{asset?.name || 'N/A'}</Link>
          </div>
        </Tooltip>
      ),
    },
    { title: 'Asset type', renderer: ({ asset }) => formatAssetType(asset?.classification) || 'N/A' },
    { title: 'Video duration', renderer: ({ asset }) => formatDuration(asset?.duration, 'seconds') || 'N/A' },
    { title: 'Times screened', renderer: ({ screenings_count }) => screenings_count || 'N/A' },
    {
      title: 'Screening duration',
      renderer: ({ screening_duration }) => formatDuration(screening_duration, 'seconds') || 'N/A',
    },
    {
      title: 'Last Screened',
      renderer: ({ last_screened_at }) =>
        last_screened_at === '1970-01-01T00:00:00.000+00:00' ? 'N/A' : <DateElement date={last_screened_at} />,
    },
    {
      title: 'Screened by',
      renderer: ({ watchers }) => {
        if (!watchers?.length) return 'N/A';

        return watchers?.map((watcher) => (
          <Tooltip key={watcher.id} label={watcher?.full_title} position={'top'}>
            <div className="video-screenings-list-table__video-title text-truncate">
              <Link
                className="analytics-list-table__contact-name text-truncate"
                to={`/contacts/${watcher?.id}/${DetailsPageTabs.ANALYTICS}`}
                state={{ interaction: ContactInteractions.RECOMMENDATIONS_RECEIVED }}
              >
                {watcher.full_name}
              </Link>
            </div>
          </Tooltip>
        ));
      },
    },
  ];
};
