import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';

import type { IDeliveryActionConfig } from './types';
import { IDeliveryActionName } from './types';
import { useEditAction } from './edit';
import { useSendAction } from './send-action';
import { useDeleteAction } from './delete-action';
import { useCopyAction } from './copy-action';
import { useCancelAction } from './cancel-action';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

export type { IDeliveryActionConfig };
export { useEditAction as useEditDeliveryAction, IDeliveryActionName };

export const useDeliveryActions = (
  entities: ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IDeliveryActionName> => {
  const editAction = useEditAction(entities, options);
  const sendAction = useSendAction(entities, options);
  const cancelAction = useCancelAction(entities, options);
  const copyAction = useCopyAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);

  const actions = [editAction, sendAction, cancelAction, copyAction, deleteAction];

  return useActions(actions as IDeliveryActionConfig[], options, entities as DeliveriesGenericDeliveryPackage[]);
};
