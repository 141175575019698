import { IGroup, IIdentifiable } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IScreeningRoomActionName {
  DELETE_PREVIEW = 'delete-preview',
  ADD_PRODUCTS = 'add-products',
  ADD_ASSETS = 'add-assets',
  EMAIL = 'email',
  DELETE = 'delete',
}

export type IScreeningRoomActionConfig = IActionConfig<IIdentifiable, IScreeningRoomActionName>;

export type IShouldDisplayScreeningRoomActionFunction = IShouldDisplayActionFunction<IGroup, IScreeningRoomActionName>;
