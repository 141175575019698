import React, { useCallback, useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import './style.scss';

import { SectionHeader } from 'components/section-header';
import { DateCountBarChart } from 'components/date-count-bar-chart';
import { StatisticsTopCard } from 'components/statistic-top-card';
import { ProductViewsListTable } from 'components/product-views-list-table';
import { ActivityChart } from 'components/activity-chart';
import PieChart from 'components/pie-chart';
import {
  fetchEventTopProducts,
  fetchTopViewers,
  fetchActivityStatistics,
  getHistogramData,
  fetchSearchTopProducts,
} from 'utils/apis/analytics';
import { IHistogramData, IBarChartData, IAnalyticsProps, DetailsPageTabs } from 'types';
import { IAnalyticsSearchProps, IUseTopProductData, IUseTopVideoViewerData, IWordCountData } from 'types/analytics';
import { Routes } from 'utils/routes';
import { ProductInteractions } from 'components/analytics/utils';
import { DataWordCloudChart } from 'components/data-word-cloud-chart';

const AnalyticsProductViews: React.FC<IAnalyticsProps> = (props) => {
  const [countByUniqueViewsData, setCountByUniqueViewsData] = useState([] as IHistogramData[]);
  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData(props).then(({ data }) => {
      setCountByUniqueViewsData(data);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-product-views__card">
      <SectionHeader title="Product Views" useBackground useBorder />
      <div className="event-analytics-tab-product-views__card-content">
        <DateCountBarChart data={countByUniqueViewsData} startDate={props.dateRange[0]} endDate={props.dateRange[1]} />
      </div>
    </div>
  );
};

const AnalyticsTopProducts: React.FC<IAnalyticsProps> = (props) => {
  const fetcher: () => Promise<IUseTopProductData> = useCallback(() => fetchEventTopProducts(props), [props]);

  return (
    <StatisticsTopCard
      title="Top Products"
      statisticName="views"
      fetcher={fetcher}
      url={{
        pathname: `${Routes.ANALYTICS_PRODUCTS}/${DetailsPageTabs.LIST}`,
        state: props,
      }}
    />
  );
};

const AnalyticsTopViewers: React.FC<IAnalyticsProps> = (props) => {
  const fetcher: () => Promise<IUseTopVideoViewerData> = useCallback(() => fetchTopViewers(props), [props]);

  return (
    <StatisticsTopCard
      title="Top Viewers"
      statisticName="views"
      fetcher={fetcher}
      interaction={ProductInteractions.VIEWS}
      url={{
        pathname: `${Routes.ANALYTICS_CONTACTS}/${DetailsPageTabs.LIST}`,
        state: props,
      }}
    />
  );
};
type IAnalyticsTopState = { count: IBarChartData[string]; group_name: IBarChartData[string] }[];
const AnalyticsTopGenres: React.FC<IAnalyticsProps> = (params) => {
  const [topGenres, setTopGenres] = useState<IAnalyticsTopState>([]);

  useEffect(() => {
    if (!params.dateRange.every(Boolean)) {
      return;
    }
    getHistogramData<IBarChartData>(params).then(({ data }) => {
      const formattedData = data.map((item) => ({
        count: item.percents,
        group_name: item.genre_name || 'Unknown',
      }));
      setTopGenres(formattedData);
    });
  }, [params]);

  return (
    <div className="statistic-top-card-wrapper">
      <SectionHeader title="Top Genres" useBackground useBorder />
      <div className="event-analytics-tab-product-views__card-content">
        <PieChart data={topGenres} aspectRatio={1} unit="%" />
      </div>
    </div>
  );
};

const AnalyticsTopDevices: React.FC<IAnalyticsProps> = (props) => {
  const [topDevices, setTopDevices] = useState<IAnalyticsTopState>([]);

  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }
    getHistogramData<IBarChartData>(props).then(({ data }) => {
      const formattedData = data.map((item) => ({
        count: item.percents,
        group_name: item.os || 'Unknown',
      }));
      setTopDevices(formattedData);
    });
  }, [props]);

  return (
    <div className="statistic-top-card-wrapper">
      <SectionHeader title="Top Devices & Platforms" useBackground useBorder />
      <div className="event-analytics-tab-product-views__card-content">
        <PieChart data={topDevices} aspectRatio={1} unit="%" />
      </div>
    </div>
  );
};

const InteractionsProductViews: React.FC<IAnalyticsProps> = (props) => (
  <>
    <div className="event-analytics-tab-product-views">
      <AnalyticsProductViews queryName={props?.queries?.productCount} {...props} />

      {props?.queries?.topProducts && <AnalyticsTopProducts queryName={props?.queries?.topProducts} {...props} />}

      {props?.queries?.topViewers && <AnalyticsTopViewers queryName={props?.queries?.topViewers} {...props} />}

      {props?.queries?.topGenres && <AnalyticsTopGenres queryName={props?.queries?.topGenres} {...props} />}

      {props?.queries?.topDevices && <AnalyticsTopDevices queryName={props?.queries?.topDevices} {...props} />}
    </div>

    <div className="event-analytics-tab-product-views-list">
      {props?.queries?.productList && (
        <ProductViewsListTable title="Detailed Product Views" queryName={props?.queries?.productList} {...props} />
      )}

      {props.queries?.productActivity && (
        <ActivityChart
          title="Viewing Activity"
          subtitle="product views per day"
          fetcher={() =>
            fetchActivityStatistics({
              queryName: props?.queries?.productActivity,
              ...props,
            })
          }
          url={''}
          aspectRatio={6}
        />
      )}
    </div>
  </>
);

export default InteractionsProductViews;

export const AnalyticsTopProductSearchTerms: React.FC<IAnalyticsProps> = (props) => {
  const [searchCount, setSearchCount] = useState([] as IHistogramData[]);
  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData(props).then(({ data }) => {
      setSearchCount(data);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-product-views__card">
      <SectionHeader title="Search Count" useBackground useBorder />
      <div className="event-analytics-tab-product-views__card-content">
        <DateCountBarChart
          data={searchCount}
          startDate={props.dateRange[0]}
          endDate={props.dateRange[1]}
          unit="searche"
        />
      </div>
    </div>
  );
};

export const AnalyticsTopSearchProducts: React.FC<IAnalyticsSearchProps> = (props) => {
  const { title, unit, exportAction, ...rest } = props;
  const fetcher: () => Promise<IUseTopProductData> = useCallback(() => fetchSearchTopProducts(rest), [rest]);

  return <StatisticsTopCard title={title} statisticName={unit} exportAction={exportAction} fetcher={fetcher} />;
};

export const AnalyticsTopSearchTerms: React.FC<IAnalyticsProps> = (props) => {
  const [searchCount, setSearchCount] = useState<IWordCountData[]>([]);
  useEffect(() => {
    if (!props.dateRange.every(isValid)) {
      return;
    }

    getHistogramData(props).then(({ data }) => {
      setSearchCount(data as unknown as IWordCountData[]);
    });
  }, [props]);

  return (
    <div className="event-analytics-tab-product-views__card">
      <SectionHeader title="Search Terms" useBackground useBorder />
      <div className="event-analytics-tab-product-views__card-content">
        <DataWordCloudChart data={searchCount} />
      </div>
    </div>
  );
};
