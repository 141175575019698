import { isString, isNumber, isArray, isEmpty, isBoolean } from 'lodash';

export const presenceValidation = (value: unknown): [boolean, string?] => {
  if (isArray(value) && !isEmpty(value)) return [true];
  else if (isString(value) && !isEmpty(value)) return [true];
  else if (isNumber(value)) return [true];
  else if (isBoolean(value)) return [true];

  return [false, `This field is required`];
};
