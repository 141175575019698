import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IconName } from 'blueprint5-icons';
import cx from 'classnames';
import { get } from 'lodash';

import { useStore } from 'store';
import { Model } from 'helpers/filters/types';
import { FormInput, useForm } from 'helpers/form';

import { LabelValuePair as Row } from 'components/label-value-pair';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { DataSectionControls } from 'components/data-section-controls';
import { LoadingBoundary } from 'components/loading-boundary';

import { ISelectionStandardized, IDataSectionLayout } from 'types';

import '../style.scss';
import { InfoBoxV2 } from 'components/info-box-v2';
import { formatDate } from 'utils/date';
import { getEntityLabel } from 'utils/general';
import { useEditMode, useRefreshDataSection } from 'utils/hooks';
import { SelectionSchema } from 'utils/schemas';
import { IGroupNameEditFormFields, editGroupInfo, changeGroupAccessImmediate } from 'utils/apis/groups';

export interface ISelectionDetailsLayoutProps {
  refreshSelection: () => Promise<void>;
  selectionLoading: boolean;
  selection: ISelectionStandardized;
  headerDropdownMenu: React.ReactElement;
  layout?: IDataSectionLayout;
  className?: string;
  isSortModeEnabled?: boolean;
  setIsSortModeEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
}
const inputStyle = { gridAutoColumns: '1fr' };

const SelectionDetailsLayout: React.FC<ISelectionDetailsLayoutProps> = observer(
  ({
    selection,
    selectionLoading,
    refreshSelection,
    className,
    children,
    headerDropdownMenu,
    isSortModeEnabled,
    setIsSortModeEnabled,
  }) => {
    const { toastStore } = useStore();

    const [editModeEnabled, setEditModeEnabled] = useEditMode();
    const refreshDataSection = useRefreshDataSection();
    const { id, name, owner, access_level, created_at, entityCount, main_entity_type, ownerDivisionIds } =
      selection || {};

    const handleSubmit = useCallback(
      async (values: IGroupNameEditFormFields): Promise<void> => {
        try {
          toastStore.info('Saving');
          await editGroupInfo(id, values);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          setEditModeEnabled(false);
          refreshSelection();
        } catch (e) {
          toastStore.clearAll();
          toastStore.error(`Error while saving: ${get(e, 'object.description', 'Unknown error')}`);
        }
      },
      [id, refreshSelection, setEditModeEnabled, toastStore],
    );
    const editForm = useForm<IGroupNameEditFormFields>({ name: name || '' }, Model.ASSETS, handleSubmit);
    const onChange = editForm.handlers.onChange;

    useEffect(() => {
      if (name) {
        onChange({ name });
      }
    }, [onChange, name]);

    const editModeActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => [
        {
          type: 'save',
          handler: editForm.onSubmit,
          disabled: !editForm.valid,
        },
        { type: 'cancel', handler: (): void => setEditModeEnabled(false) },
      ],
      [editForm.onSubmit, editForm.valid, setEditModeEnabled],
    );

    const commonActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => [{ type: 'edit', handler: () => setEditModeEnabled(true) }],
      [setEditModeEnabled],
    );

    const headerActions = useMemo(
      (): (ISectionHeaderAction | IconName)[] => (editModeEnabled ? editModeActions : commonActions),
      [commonActions, editModeActions, editModeEnabled],
    );

    const refresh = useCallback(() => {
      refreshSelection();
      refreshDataSection();
    }, [refreshDataSection, refreshSelection]);

    const handleAccessLevelChange = useCallback(
      async (newSelectionAccessLevel: string): Promise<boolean> => {
        if (!id) {
          toastStore.error('Missing selection');
          return false;
        }
        if (!ownerDivisionIds?.length && newSelectionAccessLevel === 'division') {
          toastStore.error('Owner of this selection does not belong to any division');
          return false;
        }
        const data = {
          item_ids: [id],
          access_level: newSelectionAccessLevel,
        };
        const accessChange = await changeGroupAccessImmediate(data, SelectionSchema);
        const newSelection = accessChange.object;

        if (!newSelection || access_level === newSelection.access_level) {
          return false;
        }
        refreshSelection();
        return true;
      },
      [access_level, id, ownerDivisionIds, refreshSelection, toastStore],
    );

    return (
      <div className="selection-details__layout d-flex flex-column w-100 h-100">
        <div className="d-flex align-items-center justify-content-between">
          <DashboardBreadcrumbs className="d-inline-block mb-3" />
          <div>
            <DataSectionControls customRefresh={refresh} />
          </div>
        </div>

        <LoadingBoundary loading={selectionLoading} suspender={<Loading text="Loading Selection" />}>
          {!selection && <SectionMessage intent={SectionMessageType.EMPTY} />}
          {selection && (
            <>
              <SectionHeader
                title={name}
                subtitle={owner}
                actions={headerActions}
                dropdownMenu={headerDropdownMenu}
                useBackground
                accessLevel={access_level}
                onAccessLevelChange={handleAccessLevelChange}
                isSortModeEnabled={isSortModeEnabled}
                setIsSortModeEnabled={setIsSortModeEnabled}
              />
              <div className="selection-details__wrapper h-100">
                <div>
                  <InfoBoxV2 gridTemplateColumns="1fr 2fr" wrapperClassName="mt-3">
                    <Row
                      label="Name"
                      value={
                        editModeEnabled ? (
                          <FormInput
                            name="name"
                            style={inputStyle}
                            {...editForm.formData.name}
                            {...editForm.handlers}
                          />
                        ) : (
                          name
                        )
                      }
                    />

                    <Row label="Owner" value={owner} />
                    <Row label="Access" value={access_level} />
                    <Row label="Created at" rawLabel value={formatDate(created_at)} />
                    <Row label="Content type" value={getEntityLabel(main_entity_type)} />
                    <Row label={main_entity_type || 'Items'} value={entityCount} />
                  </InfoBoxV2>
                </div>
                <div className={cx('d-flex flex-column', className)}>{children}</div>
              </div>
            </>
          )}
        </LoadingBoundary>
      </div>
    );
  },
);

export default SelectionDetailsLayout;
