import React from 'react';

import { Thumbnail } from 'components/thumbnail';
import { ICategory } from 'types';
import { formatCategoryLabel } from 'utils/categories';
import { Classes } from 'utils/ui';

import './style.scss';

interface ICategorySelectedTabItem {
  category: ICategory;
  handleSelect?: React.ReactEventHandler;
}

const CategorySelectedTabItem: React.FC<ICategorySelectedTabItem> = ({ category, handleSelect }) => {
  if (!category) {
    return <></>;
  }
  const categories = formatCategoryLabel(category.id).split(/( > )/g);
  return (
    <div className="bp3-menu-item category-selected-tab-item" onClick={handleSelect}>
      <div className="category-selected-tab-item__thumb px-2">
        <Thumbnail image={category.preview_image?.url} showType customTag="genre" />
      </div>
      <div className="category-selected-tab-item__content">
        {categories.map((category, index) => (
          <span key={index}>{category}</span>
        ))}
        <div className={Classes.TEXT_MUTED}>{category.color}</div>
      </div>
    </div>
  );
};

export default CategorySelectedTabItem;
