import React from 'react';
import { chunk, sortBy } from 'lodash';
import { Menu } from '@mantine/core';

import { IExtendedProductCastCrew } from 'types';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { ParametronStore } from 'store/parametron-store';
import { ICustomSortMenuProp } from 'components/data-section';
import { IActionOpts } from '@mediafellows/chipmunk';

import cx from 'classnames';

export const handlePagination = (
  list: IExtendedProductCastCrew[],
  { per, page }: { per?: number; page?: number } = {},
): IExtendedProductCastCrew[] => {
  if (!list?.length || !per || !page) {
    return list || [];
  }

  const chunks = chunk(list, per);
  return chunks[page - 1] || [];
};
export const handleSort = (
  list: IExtendedProductCastCrew[],
  { sort, order }: { sort?: ParametronSort; order?: ParametronOrder } = {},
): IExtendedProductCastCrew[] => {
  if (
    !list?.length ||
    !sort ||
    !order ||
    (sort === ParametronSort.SEQUENCE_NUMBER && order === ParametronOrder.ASCENDING)
  ) {
    return list || [];
  }
  let result = list;

  if (sort === ParametronSort.NAME) {
    result = sortBy(list, ParametronSort.NAME);
  }
  if (order === ParametronOrder.ASCENDING) {
    return result;
  }

  return result.reverse();
};

export const handleFilters = (list: IExtendedProductCastCrew[], opts: IActionOpts = {}): IExtendedProductCastCrew[] => {
  if (!opts?.body?.search?.filters?.length) {
    return list || [];
  }

  const filters = opts.body.search.filters;
  let result = list;
  filters.map((filter) => {
    const [field, , value] = filter;
    if (field === '_' && value) {
      result = result.filter(({ name, cast_character }) => {
        const v = value.toLowerCase();
        return name?.toLowerCase().includes(v) || cast_character?.toLowerCase().includes(v);
      });
    } else if (field === 'kind' && value) {
      result = result.filter(({ kind }) => {
        return value?.includes(kind);
      });
    }
  });
  return result;
};

export interface IProductAssetSortMenuProps {
  sort: string | ParametronSort;
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  searchStore?: ParametronStore;
}

export const ProductCastCrewSortMenu: React.FC<IProductAssetSortMenuProps> = ({ sort, handleSort, searchStore }) => {
  const { paramsAndFiltersCount = 0, parametron = null } = searchStore || {};
  const params = parametron?.data?.params || {};
  const hasFiltersApplied = !!paramsAndFiltersCount;

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        disabled={hasFiltersApplied || params.include_descendants}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.NAME}
        className={cx({ active: sort === ParametronSort.NAME })}
      >
        Name
      </Menu.Item>
    </Menu>
  );
};

export const customSortMenu: ICustomSortMenuProp = (sort, handleSort, searchStore) => (
  <ProductCastCrewSortMenu sort={sort} handleSort={handleSort} searchStore={searchStore} />
);

export const isTextFreeFilter = (filter): boolean => filter[0] === '+main';
