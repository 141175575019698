import React from 'react';
import cx from 'classnames';

import { ITabValue, Tab, TabPanel } from 'components/tabs';

import { IAssetArtifact } from 'utils/actions/types';
import { DetailsPageTabs } from 'types';

export const getTotalSize = (artifacts: IAssetArtifact[]): number => {
  if (!artifacts.length) return 0;

  return artifacts?.reduce((acc: number, artifact: IAssetArtifact) => {
    return acc + (artifact.file_size as number);
  }, 0);
};

export const DeliveryDetailTab: React.FC<{
  value: DetailsPageTabs;
  activeTab: DetailsPageTabs;
  valueParser?: (value?: ITabValue) => string;
}> = ({ value, activeTab, valueParser }) => {
  return (
    <Tab
      value={value}
      className={cx(`delivery-details__tab`, {
        'entity-detail__tab--active': activeTab === value,
      })}
      valueParser={valueParser}
    />
  );
};

export const DeliveryTabPanel: React.FC<{ value: DetailsPageTabs; content: JSX.Element }> = ({ value, content }) => {
  return <TabPanel value={value} className="delivery-details__tab-content h-100" content={content} />;
};
