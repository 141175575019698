import React from 'react';
import { IProduct, ViewMode } from 'types';

import { default as ProductMetadataTabView } from 'pages/product-details/product-metadata-tab/product-metadata-tab';
import { ProductMetadataTabEdit } from 'pages/product-details/product-metadata-tab/product-metadata-tab-edit';

import { ScrollWrapper } from 'components/scroll-wrapper';

interface IProductMetadataTabModeProps {
  mode: ViewMode;
  product: IProduct;
  setProduct: (product: IProduct) => void;
  setMode: (view: ViewMode) => void;
}

export const ProductMetadataTabMode: React.FC<IProductMetadataTabModeProps> = ({ mode, ...props }) => {
  const Component = mode === ViewMode.EDIT ? ProductMetadataTabEdit : ProductMetadataTabView;

  return (
    <ScrollWrapper>
      <Component {...props} />
    </ScrollWrapper>
  );
};
