import React from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { InfoBoxV2 } from 'components/info-box-v2';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import ScrollWrapper from 'components/scroll-wrapper';
import { IconAlias } from 'icons/icon-aliases';
import { CollectionOverviewForm } from './collection-overview-tab-edit';
import { CollectionOverviewPreview } from './collection-overview-tab-preview';

export interface ICollectionOverviewTabProps {
  collection: UmGenericListCollection;
  productIds?: number[];
  subCollectionIds?: number[];
  isEditMode: boolean;
  collectionForm: IUseMm3FormReturn<UmGenericListCollection>;
}

export const CollectionOverviewTab: React.FC<ICollectionOverviewTabProps> = ({
  collection,
  subCollectionIds,
  productIds,
  isEditMode,
  collectionForm,
}) => {
  const { preview_image_id, preview_image } = collection;
  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="2fr minmax(0, 1fr)" padded={false}>
        {isEditMode ? (
          <CollectionOverviewForm
            {...{
              collectionForm,
              productIds,
              subCollectionsCount: subCollectionIds?.length,
              loadingSubCollections: false,
            }}
          />
        ) : (
          <CollectionOverviewPreview
            {...{
              productIds,
              subCollectionsCount: subCollectionIds?.length,
              loadingSubCollections: false,
              collection,
            }}
          />
        )}
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.COLLECTION}
          entity={{ preview_image_id, preview_image }}
          className="collection-overview__preview-image"
          placeholder={<LoaderWithText text="processing" />}
        />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};
