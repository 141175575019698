import React from 'react';
import { Popover } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';

import { Classes } from 'utils/ui';
import cx from 'classnames';

export const ScheduledChangeIndicatorWrapper: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Popover>
      <Popover.Target>
        <span>
          <MantineIcon icon="time" size={15} className={cx(Classes.TEXT_MUTED, 'pointer-action', className)} />
        </span>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="py-1 px-2 scheduled-change-popup-content">{children}</div>
      </Popover.Dropdown>
    </Popover>
  );
};
