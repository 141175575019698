import React from 'react';
import { IInputGroupProps } from '@blueprintjs/core';
import { Button } from '@mantine/core';
import { Trash } from 'blueprint5-icons';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { formatFormLabel } from 'helpers/form/fields/helpers';
import { useUploadImageDialog, IUploadType } from 'components/upload-preview-image';
import { MantineIcon } from 'utils/ui/icon';
import { FormGroup } from 'helpers/form/fields/form-group';
import { Intent } from 'utils/ui';

import './style.scss';

export interface IFormImageProps
  extends Omit<Omit<IInputGroupProps, 'value'>, 'onChange'>,
    IFieldData<string | null>,
    IFieldHandlers<string | null> {
  name: string;
  label?: string;
  type?: IUploadType;
  extensions?: string[] | null;
  fileLabel?: string;
  customMessage?: string;
}

export const FormImage: React.FC<IFormImageProps> = (props) => {
  const {
    onChange,
    name,
    value,
    label,
    required,
    type,
    touched,
    validation,
    extensions,
    fileLabel = 'image',
    customMessage = 'Select an image to upload',
  } = props;
  const showError = Boolean(touched && !validation?.valid);
  const intent = showError ? Intent.DANGER : Intent.NONE;

  const [openDialog] = useUploadImageDialog({
    type,
    extensions,
    customMessage,
    onSubmit: (file) => {
      if (file && file.url) {
        onChange?.({ [name]: file.url });
      }
    },
  });

  const onRemove = (): void => {
    onChange?.({ [name]: '' });
  };

  const title = (value ? `Change ` : 'Upload ') + fileLabel;

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      helperText={showError ? validation?.errorMessage : ''}
      intent={intent}
    >
      <div className="form-image__container">
        <Button size="xs" variant="default" onClick={openDialog}>
          {title} {value && <img className="form-image-preview" src={value} />}
        </Button>
        {value && (
          <Button variant="default" size="xs" onClick={onRemove} className="ms-1">
            <MantineIcon icon={<Trash />} />
          </Button>
        )}
      </div>
    </FormGroup>
  );
};
