import { Alignment, IAlignment } from './alignment';
import { Elevation, IElevation } from './elevation';
import { IIntent, Intent } from './intent';
import { Position, IPosition } from './position';

const NS = 'bp3';

export const ACTIVE: string = ''.concat(NS, '-active');
export const ALIGN_LEFT = ''.concat(NS, '-align-left');
export const ALIGN_RIGHT = ''.concat(NS, '-align-right');
export const DARK = ''.concat(NS, '-dark');
export const DISABLED = ''.concat(NS, '-disabled');
export const FILL = ''.concat(NS, '-fill');
export const FIXED = ''.concat(NS, '-fixed');
export const FIXED_TOP = ''.concat(NS, '-fixed-top');
export const INLINE = ''.concat(NS, '-inline');
export const INTERACTIVE = ''.concat(NS, '-interactive');
export const LARGE = ''.concat(NS, '-large');
export const LOADING = ''.concat(NS, '-loading');
export const MINIMAL = ''.concat(NS, '-minimal');
export const OUTLINED = ''.concat(NS, '-outlined');
export const MULTILINE = ''.concat(NS, '-multiline');
export const ROUND = ''.concat(NS, '-round');
export const SMALL = ''.concat(NS, '-small');
export const VERTICAL = ''.concat(NS, '-vertical');
export const POSITION_TOP = positionClass(Position.TOP);
export const POSITION_BOTTOM = positionClass(Position.BOTTOM);
export const POSITION_LEFT = positionClass(Position.LEFT);
export const POSITION_RIGHT = positionClass(Position.RIGHT);
export const ELEVATION_0 = elevationClass(Elevation.ZERO);
export const ELEVATION_1 = elevationClass(Elevation.ONE);
export const ELEVATION_2 = elevationClass(Elevation.TWO);
export const ELEVATION_3 = elevationClass(Elevation.THREE);
export const ELEVATION_4 = elevationClass(Elevation.FOUR);
export const INTENT_PRIMARY = intentClass(Intent.PRIMARY);
export const INTENT_SUCCESS = intentClass(Intent.SUCCESS);
export const INTENT_WARNING = intentClass(Intent.WARNING);
export const INTENT_DANGER = intentClass(Intent.DANGER);
export const FOCUS_DISABLED = ''.concat(NS, '-focus-disabled');

// text utilities
export const UI_TEXT: string = ''.concat(NS, '-ui-text');
export const RUNNING_TEXT: string = ''.concat(NS, '-running-text');
export const MONOSPACE_TEXT: string = ''.concat(NS, '-monospace-text');
export const TEXT_LARGE: string = ''.concat(NS, '-text-large');
export const TEXT_SMALL: string = ''.concat(NS, '-text-small');
export const TEXT_MUTED: string = ''.concat(NS, '-text-muted');
export const TEXT_DISABLED: string = ''.concat(NS, '-text-disabled');
export const TEXT_OVERFLOW_ELLIPSIS: string = ''.concat(NS, '-text-overflow-ellipsis');

// textual elements
export const BLOCKQUOTE: string = ''.concat(NS, '-blockquote');
export const CODE: string = ''.concat(NS, '-code');
export const CODE_BLOCK: string = ''.concat(NS, '-code-block');
export const HEADING: string = ''.concat(NS, '-heading');
export const LIST: string = ''.concat(NS, '-list');
export const LIST_UNSTYLED: string = ''.concat(NS, '-list-unstyled');
export const RTL: string = ''.concat(NS, '-rtl');

// layout utilities
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block
 * @deprecated this is no longer needed for ContextMenu2, will be removed in v4.0
 */
export const FIXED_POSITIONING_CONTAINING_BLOCK = ''.concat(NS, '-fixed-positioning-containing-block');
// components
export const ALERT: string = ''.concat(NS, '-alert');
export const ALERT_BODY: string = ''.concat(ALERT, '-body');
export const ALERT_CONTENTS: string = ''.concat(ALERT, '-contents');
export const ALERT_FOOTER: string = ''.concat(ALERT, '-footer');
export const BREADCRUMB: string = ''.concat(NS, '-breadcrumb');
export const BREADCRUMB_CURRENT: string = ''.concat(BREADCRUMB, '-current');
export const BREADCRUMBS: string = ''.concat(BREADCRUMB, 's');
export const BREADCRUMBS_COLLAPSED: string = ''.concat(BREADCRUMB, 's-collapsed');
export const BUTTON: string = ''.concat(NS, '-button');
export const BUTTON_GROUP: string = ''.concat(BUTTON, '-group');
export const BUTTON_SPINNER: string = ''.concat(BUTTON, '-spinner');
export const BUTTON_TEXT: string = ''.concat(BUTTON, '-text');
export const CALLOUT: string = ''.concat(NS, '-callout');
export const CALLOUT_ICON: string = ''.concat(CALLOUT, '-icon');
export const CARD: string = ''.concat(NS, '-card');
export const COLLAPSE: string = ''.concat(NS, '-collapse');
export const COLLAPSE_BODY: string = ''.concat(COLLAPSE, '-body');
export const COLLAPSIBLE_LIST: string = ''.concat(NS, '-collapse-list');
export const CONTEXT_MENU: string = ''.concat(NS, '-context-menu');
export const CONTEXT_MENU_POPOVER_TARGET: string = ''.concat(CONTEXT_MENU, '-popover-target');
export const CONTROL_GROUP: string = ''.concat(NS, '-control-group');
export const DIALOG: string = ''.concat(NS, '-dialog');
export const DIALOG_CONTAINER: string = ''.concat(DIALOG, '-container');
export const DIALOG_BODY: string = ''.concat(DIALOG, '-body');
export const DIALOG_CLOSE_BUTTON: string = ''.concat(DIALOG, '-close-button');
export const DIALOG_FOOTER: string = ''.concat(DIALOG, '-footer');
export const DIALOG_FOOTER_ACTIONS: string = ''.concat(DIALOG, '-footer-actions');
export const DIALOG_HEADER: string = ''.concat(DIALOG, '-header');
export const DIALOG_STEP: string = ''.concat(NS, '-dialog-step');
export const DIALOG_STEP_CONTAINER: string = ''.concat(DIALOG_STEP, '-container');
export const DIALOG_STEP_TITLE: string = ''.concat(DIALOG_STEP, '-title');
export const DIALOG_STEP_ICON: string = ''.concat(DIALOG_STEP, '-icon');
export const DIALOG_STEP_VIEWED: string = ''.concat(DIALOG_STEP, '-viewed');
export const DIVIDER: string = ''.concat(NS, '-divider');
export const DRAWER: string = ''.concat(NS, '-drawer');
export const DRAWER_BODY: string = ''.concat(DRAWER, '-body');
export const DRAWER_FOOTER: string = ''.concat(DRAWER, '-footer');
export const DRAWER_HEADER: string = ''.concat(DRAWER, '-header');
export const EDITABLE_TEXT: string = ''.concat(NS, '-editable-text');
export const EDITABLE_TEXT_CONTENT: string = ''.concat(EDITABLE_TEXT, '-content');
export const EDITABLE_TEXT_EDITING: string = ''.concat(EDITABLE_TEXT, '-editing');
export const EDITABLE_TEXT_INPUT: string = ''.concat(EDITABLE_TEXT, '-input');
export const EDITABLE_TEXT_PLACEHOLDER: string = ''.concat(EDITABLE_TEXT, '-placeholder');
export const FLEX_EXPANDER: string = ''.concat(NS, '-flex-expander');
export const HTML_SELECT: string = ''.concat(NS, '-html-select');
/** @deprecated prefer `<HTMLSelect>` component */

export const SELECT: string = ''.concat(NS, '-select');
export const HTML_TABLE: string = ''.concat(NS, '-html-table');
export const HTML_TABLE_BORDERED: string = ''.concat(HTML_TABLE, '-bordered');
export const HTML_TABLE_CONDENSED: string = ''.concat(HTML_TABLE, '-condensed');
export const HTML_TABLE_STRIPED: string = ''.concat(HTML_TABLE, '-striped');
export const INPUT: string = ''.concat(NS, '-input');
export const INPUT_GHOST: string = ''.concat(INPUT, '-ghost');
export const INPUT_GROUP: string = ''.concat(INPUT, '-group');
export const INPUT_LEFT_CONTAINER: string = ''.concat(INPUT, '-left-container');
export const INPUT_ACTION: string = ''.concat(INPUT, '-action');
export const CONTROL: string = ''.concat(NS, '-control');
export const CONTROL_INDICATOR: string = ''.concat(CONTROL, '-indicator');
export const CONTROL_INDICATOR_CHILD: string = ''.concat(CONTROL_INDICATOR, '-child');
export const CHECKBOX: string = ''.concat(NS, '-checkbox');
export const RADIO: string = ''.concat(NS, '-radio');
export const SWITCH: string = ''.concat(NS, '-switch');
export const SWITCH_INNER_TEXT: string = ''.concat(SWITCH, '-inner-text');
export const FILE_INPUT: string = ''.concat(NS, '-file-input');
export const FILE_INPUT_HAS_SELECTION: string = ''.concat(NS, '-file-input-has-selection');
export const FILE_UPLOAD_INPUT: string = ''.concat(NS, '-file-upload-input');
export const FILE_UPLOAD_INPUT_CUSTOM_TEXT: string = ''.concat(NS, '-file-upload-input-custom-text');
export const KEY: string = ''.concat(NS, '-key');
export const KEY_COMBO: string = ''.concat(KEY, '-combo');
export const MODIFIER_KEY: string = ''.concat(NS, '-modifier-key');
export const HOTKEY: string = ''.concat(NS, '-hotkey');
export const HOTKEY_LABEL: string = ''.concat(HOTKEY, '-label');
export const HOTKEY_COLUMN: string = ''.concat(HOTKEY, '-column');
export const HOTKEY_DIALOG: string = ''.concat(HOTKEY, '-dialog');
export const LABEL: string = ''.concat(NS, '-label');
export const FORM_GROUP: string = ''.concat(NS, '-form-group');
export const FORM_CONTENT: string = ''.concat(NS, '-form-content');
export const FORM_HELPER_TEXT: string = ''.concat(NS, '-form-helper-text');
export const FORM_GROUP_SUB_LABEL: string = ''.concat(NS, '-form-group-sub-label');
export const MENU: string = ''.concat(NS, '-menu');
export const MENU_ITEM: string = ''.concat(MENU, '-item');
export const MENU_ITEM_LABEL: string = ''.concat(MENU_ITEM, '-label');
export const MENU_SUBMENU: string = ''.concat(NS, '-submenu');
export const MENU_DIVIDER: string = ''.concat(MENU, '-divider');
export const MENU_HEADER: string = ''.concat(MENU, '-header');
export const MULTISTEP_DIALOG: string = ''.concat(NS, '-multistep-dialog');
export const MULTISTEP_DIALOG_PANELS: string = ''.concat(MULTISTEP_DIALOG, '-panels');
export const MULTISTEP_DIALOG_LEFT_PANEL: string = ''.concat(MULTISTEP_DIALOG, '-left-panel');
export const MULTISTEP_DIALOG_RIGHT_PANEL: string = ''.concat(MULTISTEP_DIALOG, '-right-panel');
export const MULTISTEP_DIALOG_FOOTER: string = ''.concat(MULTISTEP_DIALOG, '-footer');
export const NAVBAR: string = ''.concat(NS, '-navbar');
export const NAVBAR_GROUP: string = ''.concat(NAVBAR, '-group');
export const NAVBAR_HEADING: string = ''.concat(NAVBAR, '-heading');
export const NAVBAR_DIVIDER: string = ''.concat(NAVBAR, '-divider');
export const NON_IDEAL_STATE: string = ''.concat(NS, '-non-ideal-state');
export const NON_IDEAL_STATE_VISUAL: string = ''.concat(NON_IDEAL_STATE, '-visual');
export const NUMERIC_INPUT: string = ''.concat(NS, '-numeric-input');
export const OVERFLOW_LIST: string = ''.concat(NS, '-overflow-list');
export const OVERFLOW_LIST_SPACER: string = ''.concat(OVERFLOW_LIST, '-spacer');
export const OVERLAY: string = ''.concat(NS, '-overlay');
export const OVERLAY_BACKDROP: string = ''.concat(OVERLAY, '-backdrop');
export const OVERLAY_CONTAINER: string = ''.concat(OVERLAY, '-container');
export const OVERLAY_CONTENT: string = ''.concat(OVERLAY, '-content');
export const OVERLAY_INLINE: string = ''.concat(OVERLAY, '-inline');
export const OVERLAY_OPEN: string = ''.concat(OVERLAY, '-open');
export const OVERLAY_SCROLL_CONTAINER: string = ''.concat(OVERLAY, '-scroll-container');
export const OVERLAY_START_FOCUS_TRAP: string = ''.concat(OVERLAY, '-start-focus-trap');
export const OVERLAY_END_FOCUS_TRAP: string = ''.concat(OVERLAY, '-end-focus-trap');
export const PANEL_STACK: string = ''.concat(NS, '-panel-stack');
export const PANEL_STACK_HEADER: string = ''.concat(PANEL_STACK, '-header');
export const PANEL_STACK_HEADER_BACK: string = ''.concat(PANEL_STACK, '-header-back');
export const PANEL_STACK_VIEW: string = ''.concat(PANEL_STACK, '-view');
export const PANEL_STACK2: string = ''.concat(NS, '-panel-stack2');
export const PANEL_STACK2_HEADER: string = ''.concat(PANEL_STACK, '-header');
export const PANEL_STACK2_HEADER_BACK: string = ''.concat(PANEL_STACK, '-header-back');
export const PANEL_STACK2_VIEW: string = ''.concat(PANEL_STACK, '-view');
export const POPOVER: string = ''.concat(NS, '-popover');
export const POPOVER_ARROW: string = ''.concat(POPOVER, '-arrow');
export const POPOVER_BACKDROP: string = ''.concat(POPOVER, '-backdrop');
export const POPOVER_CAPTURING_DISMISS: string = ''.concat(POPOVER, '-capturing-dismiss');
export const POPOVER_CONTENT: string = ''.concat(POPOVER, '-content');
export const POPOVER_CONTENT_SIZING: string = ''.concat(POPOVER_CONTENT, '-sizing');
export const POPOVER_DISMISS: string = ''.concat(POPOVER, '-dismiss');
export const POPOVER_DISMISS_OVERRIDE: string = ''.concat(POPOVER_DISMISS, '-override');
export const POPOVER_OPEN: string = ''.concat(POPOVER, '-open');
export const POPOVER_OUT_OF_BOUNDARIES: string = ''.concat(POPOVER, '-out-of-boundaries');
export const POPOVER_TARGET: string = ''.concat(POPOVER, '-target');
export const POPOVER_WRAPPER: string = ''.concat(POPOVER, '-wrapper');
export const TRANSITION_CONTAINER: string = ''.concat(NS, '-transition-container');
export const PROGRESS_BAR: string = ''.concat(NS, '-progress-bar');
export const PROGRESS_METER: string = ''.concat(NS, '-progress-meter');
export const PROGRESS_NO_STRIPES: string = ''.concat(NS, '-no-stripes');
export const PROGRESS_NO_ANIMATION: string = ''.concat(NS, '-no-animation');
export const PORTAL: string = ''.concat(NS, '-portal');
export const SKELETON: string = ''.concat(NS, '-skeleton');
export const SLIDER: string = ''.concat(NS, '-slider');
export const SLIDER_AXIS: string = ''.concat(SLIDER, '-axis');
export const SLIDER_HANDLE: string = ''.concat(SLIDER, '-handle');
export const SLIDER_LABEL: string = ''.concat(SLIDER, '-label');
export const SLIDER_TRACK: string = ''.concat(SLIDER, '-track');
export const SLIDER_PROGRESS: string = ''.concat(SLIDER, '-progress');
export const START: string = ''.concat(NS, '-start');
export const END: string = ''.concat(NS, '-end');
export const SPINNER: string = ''.concat(NS, '-spinner');
export const SPINNER_ANIMATION: string = ''.concat(SPINNER, '-animation');
export const SPINNER_HEAD: string = ''.concat(SPINNER, '-head');
export const SPINNER_NO_SPIN: string = ''.concat(NS, '-no-spin');
export const SPINNER_TRACK: string = ''.concat(SPINNER, '-track');
export const TAB: string = ''.concat(NS, '-tab');
export const TAB_INDICATOR: string = ''.concat(TAB, '-indicator');
export const TAB_INDICATOR_WRAPPER: string = ''.concat(TAB_INDICATOR, '-wrapper');
export const TAB_LIST: string = ''.concat(TAB, '-list');
export const TAB_PANEL: string = ''.concat(TAB, '-panel');
export const TABS: string = ''.concat(TAB, 's');
export const TAG: string = ''.concat(NS, '-tag');
export const TAG_REMOVE: string = ''.concat(TAG, '-remove');
export const TAG_INPUT: string = ''.concat(NS, '-tag-input');
export const TAG_INPUT_ICON: string = ''.concat(TAG_INPUT, '-icon');
export const TAG_INPUT_VALUES: string = ''.concat(TAG_INPUT, '-values');
export const TOAST: string = ''.concat(NS, '-toast');
export const TOAST_CONTAINER: string = ''.concat(TOAST, '-container');
export const TOAST_MESSAGE: string = ''.concat(TOAST, '-message');
export const TOOLTIP: string = ''.concat(NS, '-tooltip');
export const TOOLTIP_INDICATOR: string = ''.concat(TOOLTIP, '-indicator');
export const TREE: string = ''.concat(NS, '-tree');
export const TREE_NODE: string = ''.concat(NS, '-tree-node');
export const TREE_NODE_CARET: string = ''.concat(TREE_NODE, '-caret');
export const TREE_NODE_CARET_CLOSED: string = ''.concat(TREE_NODE_CARET, '-closed');
export const TREE_NODE_CARET_NONE: string = ''.concat(TREE_NODE_CARET, '-none');
export const TREE_NODE_CARET_OPEN: string = ''.concat(TREE_NODE_CARET, '-open');
export const TREE_NODE_CONTENT: string = ''.concat(TREE_NODE, '-content');
export const TREE_NODE_EXPANDED: string = ''.concat(TREE_NODE, '-expanded');
export const TREE_NODE_ICON: string = ''.concat(TREE_NODE, '-icon');
export const TREE_NODE_LABEL: string = ''.concat(TREE_NODE, '-label');
export const TREE_NODE_LIST: string = ''.concat(TREE_NODE, '-list');
export const TREE_NODE_SECONDARY_LABEL: string = ''.concat(TREE_NODE, '-secondary-label');
export const TREE_NODE_SELECTED: string = ''.concat(TREE_NODE, '-selected');
export const TREE_ROOT: string = ''.concat(NS, '-tree-root');
export const ICON: string = ''.concat(NS, '-icon');
/** @deprecated use <Icon> components and iconName prop APIs instead */
export const ICON_STANDARD: string = ''.concat(ICON, '-standard');
/** @deprecated use <Icon> components and iconName prop APIs instead */
export const ICON_LARGE: string = ''.concat(ICON, '-large');
/**
 * Returns the namespace prefix for all Blueprint CSS classes.
 * Customize this namespace at build time with the `process.env.BLUEPRINT_NAMESPACE` environment variable.
 */
export function getClassNamespace(): string {
  return NS;
}
/** Return CSS class for alignment. */
export function alignmentClass(alignment: IAlignment | undefined): string | undefined {
  switch (alignment) {
    case Alignment.LEFT:
      return ALIGN_LEFT;
    case Alignment.RIGHT:
      return ALIGN_RIGHT;
    default:
      return undefined;
  }
}
export function elevationClass(elevation: IElevation | undefined): string | undefined {
  if (elevation === undefined) {
    return undefined;
  }
  return ''.concat(NS, '-elevation-').concat(elevation as unknown as string);
}

export function iconClass(iconName: string | undefined): string | undefined {
  if (iconName == null) {
    return undefined;
  }
  return iconName.indexOf(''.concat(NS, '-icon-')) === 0 ? iconName : ''.concat(NS, '-icon-').concat(iconName);
}

export function intentClass(intent: IIntent | undefined): string | undefined {
  if (intent == null || intent === Intent.NONE) {
    return undefined;
  }
  return ''.concat(NS, '-intent-').concat(intent.toLowerCase());
}

export function positionClass(position: IPosition | undefined): string | undefined {
  if (position === undefined) {
    return undefined;
  }

  return ''.concat(NS, '-position-').concat(position);
}
