import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useRemote } from 'utils/hooks';
import { useDataSectionStore } from 'store';
import { DeliveryPreviewAttributes } from './delivery-list-attributes';
import { DeliveriesGenericDeliveryPackage, DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { loadDeliveryThumbnails } from 'utils/apis/package-delivery';
import { fetchConnectionById } from 'utils/apis/delivery-connections';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { Thumbnail } from 'components/thumbnail';
import { PreviewTab } from 'components/preview-tab';

import './style.scss';

const DeliveryPreviewTab = observer(() => {
  const { activeItem: delivery } = useDataSectionStore<DeliveriesGenericDeliveryPackage>();

  const fetchThumbnails = useCallback(() => {
    return loadDeliveryThumbnails(delivery);
  }, [delivery]);

  const getConnectionById = useCallback(async () => {
    const id = delivery?.connection_id;
    return fetchConnectionById(id as number);
  }, [delivery?.connection_id]);

  const [thumbnails = []] = useRemote(fetchThumbnails);
  const [connection] = useRemote(getConnectionById);

  return (
    <PreviewTab
      title={delivery?.name}
      detailsLink={`/deliveries/${delivery?.id}`}
      customThumbnail={
        thumbnails.length ? (
          <MemoizedMultiThumbnail
            height={172}
            width={308}
            className="entity-preview__thumb"
            thumbnailImages={thumbnails || [{}]}
          />
        ) : (
          <Thumbnail showType image="" />
        )
      }
    >
      <DeliveryPreviewAttributes connection={connection as DeliveriesGenericConnection} />
    </PreviewTab>
  );
});
export default DeliveryPreviewTab;
