import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { AmGenericNews } from '@mediafellows/mm3-types';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { DateListElement } from 'components/date-list-element/date-list-element';

import { IconAlias } from 'icons';

import useSelectedOption from 'utils/hooks/selected-option';
import { dateWithoutHours } from 'utils/date';
import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';
import { Classes } from 'utils/ui';

import './style.scss';

interface INewsDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  news: AmGenericNews;
  isChecked?: boolean;
  isCheckedDisabled?: boolean;
  handleClick?: (e) => void;
}

export const NewsDataSectionListItem: React.FC<INewsDataSectionListItemProps> = observer(
  ({ news, contextMenu, handleClick, isChecked, isCheckedDisabled }) => {
    const { access_level, date, created_at, updated_at, preview_image, title, meta } = news;
    const selectedOption = useSelectedOption({ value: access_level });
    const [itemChecked, setItemChecked] = useState(isChecked);

    useEffect(() => {
      setItemChecked(isChecked);
    }, [isChecked]);
    return (
      <div className="entity-list-item-row" onClick={handleClick}>
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="entity-list-item-row__thumb">
          <Thumbnail
            showType
            fallbackIconName={IconAlias.NEWS}
            image={preview_image?.url}
            placeholder={isEntityIngesting(news) && <SmallLoader size={30} />}
          />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={title}>
            <h4 className="text-nowrap text-truncate">{title}</h4>

            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className={cx('news-list-item-row__attributes', Classes.TEXT_MUTED)}>
            <DateListElement
              date={date}
              format={dateWithoutHours}
              className="text-truncate news-list-item-row__date"
              mfxIcon="published-at"
              customHoverLabel="News Date"
            />
            <DateListElement
              date={created_at}
              className="text-truncate news-list-item-row__created"
              mfxIcon="created-at"
            />
            <DateListElement
              className="text-truncate news-list-item-row__updated"
              date={updated_at}
              mfxIcon="updated-at"
            />
            <div className="text-truncate news-list-item-row__attributes">{meta?.location}</div>
          </div>
        </div>
      </div>
    );
  },
);
