import React, { useCallback, useEffect } from 'react';

import { IContact, IStyled, ItemId } from 'types';
import { FormRemoteSelect, IFormRemoteSelectProps } from 'helpers/form';
import { fetchListItems, getShowcaseByPurpose } from 'utils/apis/showcase';
import { meetingHostPurpose } from './utils';

export interface IFormHosts
  extends Partial<
      Exclude<
        IFormRemoteSelectProps,
        'name' | 'label' | 'large' | 'inline' | 'disabled' | 'placeholder' | 'showPositiveValidation' | 'ids'
      >
    >,
    IStyled {
  name: string;
  label: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPositiveValidation?: boolean;
  ids?: ItemId[] | null;
}

export const FormHosts: React.FC<IFormHosts> = ({ name, label, ids, ...rest }) => {
  const [sliderId, setSliderId] = React.useState<number | null>(null);

  useEffect(() => {
    getShowcaseByPurpose(meetingHostPurpose).then((sliderShowcase) => {
      setSliderId(sliderShowcase?.id || null);
    });
  }, []);

  const fetchHosts = useCallback(async (): Promise<IContact[]> => {
    if (!sliderId) {
      return [];
    }

    const { objects } = await fetchListItems({ listIds: [sliderId] });

    return objects.map((item) => item.entity as IContact);
  }, [sliderId]);

  return <FormRemoteSelect name={name} label={label} fetchOptions={fetchHosts} {...rest} />;
};
