import { ITreeNode } from '@blueprintjs/core';
import { ISimpleTreeNode } from './dynamic-tree-data-provider';

export enum TreeSelectedState {
  NOT_SELECTED = 'circle',
  SELECTED = 'tick-circle',
}

export const unflattenTree = (nodes: ISimpleTreeNode[]): ITreeNode[] => {
  const orderedData = nodes.sort((a, b) => nodeLevel(a) - nodeLevel(b));

  const result: ITreeNode[] = [];
  const nodeMap: { [id: number]: ITreeNode } = {};

  orderedData.map((node) => {
    if (!node.ancestry) {
      const newNode = convertNode(node);
      result.push(newNode);
      nodeMap[newNode.id] = newNode;
    } else {
      const parentId = node.ancestry.split('/').pop() || '';
      const parent = nodeMap[parentId];
      const newNode = convertNode(node);
      parent.childNodes = parent.childNodes || [];
      parent.childNodes.push(newNode);
      nodeMap[newNode.id] = newNode;
    }
  });

  return result;
};

const convertNode = (node: ISimpleTreeNode): ITreeNode => {
  return {
    id: node.value,
    label: node.label,
    isExpanded: false,
    icon: TreeSelectedState.NOT_SELECTED,
    secondaryLabel: '',
  };
};

const nodeLevel = (node: ISimpleTreeNode): number => {
  return !node.ancestry ? 0 : node.ancestry.split('/').length + 1;
};
