import { capitalize } from 'lodash';
import { changeTimeZone } from 'helpers/form/fields/helpers';
import { formatDate } from 'utils/date';

export const formatLocationType = (type: string): string => (type ? capitalize(type.replace('_', ' ')) : type);

export const formatDateToTimezone = (date?: string, time_zone?: string): string => {
  if (!date) {
    return '';
  }
  const parsedDate = time_zone ? changeTimeZone(date, time_zone) : date;
  return formatDate(parsedDate);
};
