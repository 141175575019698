import React from 'react';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { RootStore } from './root-store';

export const storeContext = React.createContext<RootStore | null>(null);
let instance: RootStore | null = null;

export function useStore<T = IObject>(): RootStore<T> {
  const store = React.useContext(storeContext) as RootStore<T>;
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
}

export const setRootStore = (root: RootStore): RootStore => {
  return (instance = root);
};

export const getRootStore = (): RootStore => {
  if (!instance) {
    throw new Error('trying to access root store before it has been created');
  }
  return instance;
};

export * from './hooks';
export { useProfileStore, ISidebarSection } from './profile-store';
