import React from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormInput, FormHtmlInput } from 'helpers/form';
import type { IUseFormReturn } from 'helpers/form';

import { IAccessPrivilege } from './types';

export type IAccessPrivilegeEmail = Pick<IAccessPrivilege, 'email_subject' | 'email_message'>;

export type IAccessPrivilegeGrantFormEmailForm = IUseFormReturn<IAccessPrivilegeEmail>;

interface IAccessPrivilegeGrantFormEmailProps {
  form: IAccessPrivilegeGrantFormEmailForm;
}

export const AccessPrivilegeGrantFormEmail: React.FC<IAccessPrivilegeGrantFormEmailProps> = ({ form }) => {
  return (
    <FormSectionWrapperV2 padded={false} gridTemplateColumns={'1fr'}>
      <FormInput name="email_subject" label="Subject" {...form.formData.email_subject} {...form.handlers} />
      <FormHtmlInput name="email_message" label="Message" large {...form.formData.email_message} {...form.handlers} />
    </FormSectionWrapperV2>
  );
};
