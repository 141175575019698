import React, { useEffect } from 'react';
import { useAnalyticsStore } from 'store/analytics-store';

export function useResetPagination(setPage: React.Dispatch<React.SetStateAction<number>>): void {
  const resetPageFilterFlag = useAnalyticsStore((store) => store.resetPageFilterFlag);

  useEffect(() => {
    if (resetPageFilterFlag === null) {
      return;
    }

    setPage(1);
  }, [setPage, resetPageFilterFlag]);
}
