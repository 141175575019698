export const analyticsContacts: Record<string, string> = {
  loginCount: 'v3/interaction/logins/count__user_logins',
  loginDevices: 'v3/interaction/logins/pie_chart__contact_platforms',
  loginActiveDaysAvg: 'v3/interaction/logins/count__logins_by_weekdays_avg',
  loginActivity: 'v3/interaction/logins/count__user_logins',
};

export const analyticsProducts: Record<string, string> = {
  productCount: 'v3/interaction/product_views/count__contact_product_views',
  topProducts: 'v3/interaction/product_views/top__contact_products',
  topGenres: 'v3/interaction/product_views/pie_chart__contact_top_genres',
  productActivity: 'v3/interaction/product_views/count__contact_product_views',
};

export const analyticsAssets: Record<string, string> = {
  screeningCount: 'v3/asset/screening/count',
  screeningTopRuntime: 'v3/interaction/screenings/list__contact_screening_top_duration',
  screeningTopVideos: 'v3/assets/top_videos_screened',
  screeningActivity: 'v3/asset/screening/count',
};

export const analyticsRecommendations: Record<string, string> = {
  recommendationSentCount: 'v3/recommendations/sent/count',
  recommendationReceivedEngagementRate: 'v3/recommendations/sent/success_rate',
  recommendationTopSenders: 'v3/recommendations/received/top_recommenders',
};

export const exportQueries = {
  analyticsProducts: 'v3/custom_exports/product_views',
  analyticsAssets: 'v3/custom_exports/asset_screenings',
  analyticsContacts: 'v3/interaction/logins/det_list__contact_logins_export',
};
