import React from 'react';
import { Link } from 'react-router-dom';

import DateElement from 'components/date-list-element/date-list-element';
import { AnalyticsContact } from 'utils/ui';

import { yn } from 'components/label-value-pair';

import { IColumn } from 'components/list-table';
import { ContactInteractions } from 'components/analytics/utils';
import { DetailsPageTabs } from 'types';

export const recommendationReceivedColumns: IColumn[] = [
  {
    title: 'Subject',
    renderer: ({ recommendation_id, subject }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ANALYTICS}`}>{subject}</Link>
    ),
  },
  {
    title: 'Products',
    renderer: ({ recommendation_id, products_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.PRODUCTS}`}>{products_count}</Link>
    ),
  },
  {
    title: 'Assets',
    renderer: ({ recommendation_id, assets_count }) => (
      <Link to={`/recommendations/${recommendation_id}/${DetailsPageTabs.ASSETS}`}>{assets_count}</Link>
    ),
  },
  { title: 'Login', renderer: ({ requires_login }) => yn(requires_login) || 'N/A' },
  // { title: 'Permissions', renderer: ({ access_level }) => access_level },
  {
    title: 'Sender',
    renderer: ({ sender }) => <AnalyticsContact user={sender} interaction={ContactInteractions.RECOMMENDATIONS_SENT} />,
  },
  { title: 'Received at', renderer: ({ sent_at }) => <DateElement date={sent_at} /> },
  { title: 'Expires at', renderer: ({ expires_at }) => <DateElement date={expires_at} /> },
  { title: 'Views', renderer: ({ views_count }) => views_count || 'N/A' },
  { title: 'Last viewed at', renderer: ({ last_viewed_at }) => <DateElement date={last_viewed_at} /> },
];
