import { useStore } from 'store';
import { BasicStore } from 'store/basic-store';
import { DialogStore } from 'store/dialog-store';
import { AssetsUploaderStore } from 'store/assets-upload-store';
import { AssetsEditStore } from 'store/assets-edit-store';
import { IDataSectionStore } from 'store/data-section-store';
import { AssetsUploadToSelectionStore } from 'store/assets-upload-to-selection-store';
import { FormsStore } from 'store/forms-store';
import { SortDataSectionStore, ISortableItem } from 'store/sort-data-store';
import { LocationsStore } from 'store/locations-store';
import { ItemId } from 'types';

export const useBasicStore = (): BasicStore => {
  return useStore().basicStore;
};

export const useDialogStore = (): DialogStore => {
  return useStore().dialogStore;
};

export const useAssetsUploadStore = (): AssetsUploaderStore => {
  return useStore().assetsUploadStore;
};

export const useAssetsUploadToSelectionStore = (): AssetsUploadToSelectionStore => {
  return useStore().assetsUploadToSelectionStore;
};

export const useAssetsEditStore = (): AssetsEditStore => {
  return useStore().assetsEditStore;
};

export const useDataSectionStore = <T = unknown>(): IDataSectionStore<T> => {
  return useStore().dataSectionStore as IDataSectionStore<T>;
};

export const useFormsStore = (): FormsStore => {
  return useStore().formsStore;
};

export const useSortDataSectionStore = <
  T extends { id?: ItemId | null } = ISortableItem,
>(): SortDataSectionStore<T> => {
  return useStore().sortDataSectionStore as unknown as SortDataSectionStore<T>;
};

export const useLocationsStore = (): LocationsStore => {
  return useStore().locationsStore;
};
