export const meetingListSchema = `
additional_seats,
affiliation,
affiliation_id,
conference,
conference_id,
created_at,
description,
ends_at,
host,
host_id,
id,
invites,
location,
location_id,
notes,
owner,
owner_id,
owner_organization,
owner_organization_id,
basket,
basket_id,
mobile_sync_group_id,
mobile_sync_group,
starts_at,
time_zone,
title,
updated_at,
type,
place,
meta,
`;

export const meetingDetailsSchema = meetingListSchema;
