import React, { useCallback } from 'react';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';

import { IDeliveryRecipientContact, deliveryRecipientcolumns } from './utils';

import { queryContacts } from 'utils/apis/contacts';
import { useRemote } from 'utils/hooks';

import './style.scss';

interface IDeliveryRecipientsTabProps {
  delivery: DeliveriesGenericDeliveryPackage;
}

const DeliveryRecipientsTab: React.FC<IDeliveryRecipientsTabProps> = ({ delivery }) => {
  const { recipient_list } = delivery || {};
  const recipientsFetcher = useCallback(async () => {
    const formattedRecipients = (recipient_list || []).reduce(
      (acc, recipient) => {
        return recipient.recipient_type === 'user'
          ? { ...acc, internalIds: [...acc.internalIds, recipient.recipient_id] }
          : { ...acc, emailRecipient: [...acc.emailRecipient, recipient] };
      },
      { internalIds: [], emailRecipient: [] },
    );
    const internalIds = formattedRecipients.internalIds;
    const contacts = internalIds?.length ? await queryContacts({ ids: internalIds, include_deleted: true }) : [];
    return [...contacts, ...formattedRecipients.emailRecipient];
  }, [recipient_list]);

  const [detailedRecipients, loading] = useRemote(recipientsFetcher);

  if (!loading && !detailedRecipients?.length) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable
        data={detailedRecipients as IDeliveryRecipientContact[]}
        columns={deliveryRecipientcolumns}
        loading={loading}
        tableClassName="delivery-recipients__table"
      />
    </div>
  );
};

export default DeliveryRecipientsTab;
