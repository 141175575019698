import React, { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';
import { Cross } from 'blueprint5-icons';

import { IAssetFields, IAssetFile, IAssetPresetFields } from 'types';
import { Model } from 'helpers/filters/types';
import { Thumbnail } from 'components/thumbnail';
import { FormInput, FormCheckbox, useForm } from 'helpers/form';

import { MantineIcon } from 'utils/ui/icon';

import AssetPresetFields from 'components/asset/asset-upload-components/preset-form/preset-form';
import AssetPresetControls from './preset-control';
import { assetFormCustomContext } from 'components/asset/asset-edit-form/constants';

import './index.scss';

export interface IAssetFormProps {
  index: number;
  initialValues: IAssetFields;
  removeFile: (file: IAssetFields) => void;
  onDataChange: (data: Partial<IAssetFile>, index: number) => void;
  setIsValidForm: (fileId: string, valid: boolean) => void;
  applyPreset: (preset: IAssetPresetFields) => void;
  savePreset: (preset: IAssetPresetFields) => void;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

const customContext = {
  properties: {
    name: { required: true },
    classification: { required: true },
    status: { required: true },
    access_level: { required: true },
    ...(assetFormCustomContext ? assetFormCustomContext['properties'] : {}),
  },
};

export const AssetForm: React.FC<IAssetFormProps> = ({
  index,
  initialValues,
  removeFile,
  onDataChange,
  setIsValidForm,
  shouldShowErrors,
  applyPreset,
  savePreset,
  withParentField,
}) => {
  const {
    formData,
    handlers: handleChanges,
    valid,
    values,
  } = useForm<IAssetFields>(initialValues, Model.ASSET_DIGITAL_IMAGE, undefined, customContext);

  const handlers = useMemo(() => {
    return {
      ...handleChanges,
      onChange(values: Partial<IAssetFile>): void {
        handleChanges.onChange(values);
        onDataChange(values, index);
      },
    };
  }, [handleChanges, onDataChange, index]);

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      handleChanges.onSetFields(initialValues);
      onDataChange(initialValues, index);
    }
  }, [initialValues, handleChanges, values, onDataChange, index]);

  useEffect(() => {
    setIsValidForm(initialValues.id, valid);
  }, [valid, initialValues, setIsValidForm]);

  const removeAsset = useCallback(() => {
    removeFile(initialValues);
  }, [removeFile, initialValues]);

  const formId = values.id;

  return (
    <form className="asset-upload-components__single-asset-form-container" id={formId}>
      <div className="asset-upload-components__single-asset-form-main-info">
        <FormCheckbox formId={formId} name="selected" {...formData.selected} {...handlers} />
        <Thumbnail
          image={initialValues.preview || initialValues.uploadURL}
          assetType={initialValues.type}
          showType
          noResize={Boolean(initialValues.preview)}
        />
        <div className="asset-upload-components__single-asset-form-fields">
          <ActionIcon className="clear-all__button" color="gray.5" variant="subtle" onClick={removeAsset}>
            <MantineIcon icon={<Cross />} />
          </ActionIcon>
          <FormInput formId={formId} name="file_name" label="File Name" disabled {...formData.file_name} />
          <FormInput formId={formId} name="name" label="Asset Name" {...formData.name} {...handlers} />
        </div>
      </div>
      <AssetPresetFields {...{ formId, formData, handlers, shouldShowErrors, values, withParentField }} />
      <AssetPresetControls {...{ values, applyPreset, savePreset }} />
    </form>
  );
};

export default React.memo(observer(AssetForm));
