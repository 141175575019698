export const categoryListSchema = `
id,
name,
updated_at,
ancestry,
color,
created_at,
id,
invisible,
parent,
parent_id,
preview_image,
preview_image_id,
sequence_no
`;
