import { create } from 'zustand';
import { subMonths, startOfDay, endOfDay, parseISO, isBefore, isValid } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { STORAGE_KEYS, getStorageItem, setStorageItem } from 'utils/storage';
import { IAnalyticsFilters, IAnalyticsStateProps } from 'types/analytics';
import { IAnalyticsEntityValues } from 'types/analytics';
import {
  IAnalyticsFiltersToApplyParams,
  IAnalyticsInteractionOption,
  getAnalyticsFiltersToApply,
} from 'components/analytics/utils';
import { today } from 'utils/date';

type IDateRange = [Date, Date];
export const initialAnalyticsMinDate = subMonths(today, 6);

export const analyticsLocalStorageKeysToRemove = [
  STORAGE_KEYS.ANALYTICS_DATE,
  STORAGE_KEYS.ANALYTICS_TERRITORIES,
  STORAGE_KEYS.ANALYTICS_CONTACTS,
  STORAGE_KEYS.ANALYTICS_PRODUCTS,
  STORAGE_KEYS.ANALYTICS_ASSETS,
  STORAGE_KEYS.ANALYTICS_DESCENDANTS,
  STORAGE_KEYS.ANALYTICS_SENDERS,
  STORAGE_KEYS.ANALYTICS_RECIPIENTS,
  STORAGE_KEYS.ANALYTICS_SCREENING_ASSETS,
];
interface IInitStoreParams {
  state: IAnalyticsStateProps;
  getMinDate: () => Promise<Date | undefined>;
  initialDateRange?: IDateRange;
}
interface IGetAnalyticsFiltersReturn extends IAnalyticsFilters {
  loading: boolean;
}
export interface IAnalyticsStore extends IAnalyticsFilters {
  resetPageFilterFlag: string | null;
  minDate: Date;
  interaction?: IAnalyticsInteractionOption;
  entity?: IAnalyticsEntityValues;
  entityId?: string;
  includeNonScreeningsAssets?: boolean;
  reset: (getMinDate: () => Promise<Date | undefined>) => void;
  onDateRangeChange: ({ dateRange }: { dateRange: IDateRange }) => void;
  onDescendantsChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onTerritoriesSelect: ({ territories }: { territories: string[] }) => void;
  onContactsSelect: ({ contactIds }: { contactIds: string[] }) => void;
  onProductsSelect: ({ productIds }: { productIds: string[] }) => void;
  onAssetsSelect: ({ assetIds }: { assetIds: string[] }) => void;
  onRecipientsSelect: ({ recipientIds }: { recipientIds: string[] }) => void;
  onSendersSelect: ({ senderIds }: { senderIds: string[] }) => void;
  onInteractionChange: ({ interaction }: { interaction: IAnalyticsInteractionOption }) => void;
  onNonScreeningsAssetsChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  getAnalyticsFilters: (params: Omit<IAnalyticsFiltersToApplyParams, 'dateRange'>) => IGetAnalyticsFiltersReturn;
  init: (params: IInitStoreParams) => void;
  loading?: boolean;
}
export const initialState = {
  recipientIds: [],
  senderIds: [],
  geoScopeIds: [],
  productIds: [],
  assetIds: [],
  userIds: [],
  includeDescendants: false,
  includeNonScreeningsAssets: false,
  dateRange: [initialAnalyticsMinDate, today] as IDateRange,
  minDate: initialAnalyticsMinDate,
};

export const triggerPageFilterReset = (resetPageFilterFlag: string): void => {
  useAnalyticsStore.setState({ resetPageFilterFlag });
};

const getDateRangeInit = (state: IAnalyticsStateProps, minDate: Date, initialDateRange?: IDateRange): IDateRange => {
  if (initialDateRange) return initialDateRange;
  const stateDateRange = state?.dateRange;

  const [storageStartDate, storageEndDate] = getStorageItem(STORAGE_KEYS.ANALYTICS_DATE, '[]') as string[];
  const [parsedStart, parsedEnd] = [parseISO(storageStartDate), parseISO(storageEndDate)];

  let startStorageDate;
  if (isValid(parsedStart)) {
    isBefore(parsedStart, minDate) ? (startStorageDate = minDate) : (startStorageDate = parsedStart);
  } else {
    isBefore(initialAnalyticsMinDate, minDate)
      ? (startStorageDate = minDate)
      : (startStorageDate = initialAnalyticsMinDate);
  }

  const endDate = isValid(parsedEnd) ? parsedEnd : today;
  const endStorageDate = isBefore(endDate, startStorageDate) ? today : endDate;

  stateDateRange && setStorageItem(STORAGE_KEYS.ANALYTICS_DATE, stateDateRange);

  return stateDateRange || [startStorageDate, endStorageDate];
};

const getInitialGeoScopeIds = (state: IAnalyticsStateProps): string[] => {
  const analyticsTerritories = getStorageItem(STORAGE_KEYS.ANALYTICS_TERRITORIES, '[]') as string[];
  analyticsTerritories && setStorageItem(STORAGE_KEYS.ANALYTICS_TERRITORIES, analyticsTerritories);

  return state?.geoScopeIds || analyticsTerritories;
};

const getInitialUserIds = (state: IAnalyticsStateProps): string[] => {
  const stateClientIds = state?.entity === 'user' && state?.entityId ? [state?.entityId as string] : state?.userIds;
  const analyticsContactIds = getStorageItem(STORAGE_KEYS.ANALYTICS_CONTACTS, '[]') as string[];
  stateClientIds && setStorageItem(STORAGE_KEYS.ANALYTICS_CONTACTS, stateClientIds);

  return stateClientIds || analyticsContactIds || [];
};

const getInitialSenderIds = (): string[] => {
  const analyticsContactIds = getStorageItem(STORAGE_KEYS.ANALYTICS_SENDERS, '[]') as string[];

  return analyticsContactIds || [];
};

const getInitialRecipientIds = (): string[] => {
  const analyticsContactIds = getStorageItem(STORAGE_KEYS.ANALYTICS_RECIPIENTS, '[]') as string[];

  return analyticsContactIds || [];
};

const getInitialProductIds = (state: IAnalyticsStateProps): string[] => {
  const stateProductIds =
    state?.entity === 'product' && state?.entityId ? [state.entityId as string] : state?.productIds;
  const analyticsProductIds = getStorageItem(STORAGE_KEYS.ANALYTICS_PRODUCTS, '[]') as string[];
  stateProductIds && setStorageItem(STORAGE_KEYS.ANALYTICS_PRODUCTS, stateProductIds);

  return stateProductIds || analyticsProductIds || [];
};

const getInitialAssetIds = (state: IAnalyticsStateProps): string[] => {
  const stateAssetIds = state?.entity === 'asset' && state?.entityId ? [state?.entityId as string] : state?.assetIds;
  const analyticsAssetIds = getStorageItem(STORAGE_KEYS.ANALYTICS_ASSETS, '[]') as string[];
  analyticsAssetIds && setStorageItem(STORAGE_KEYS.ANALYTICS_ASSETS, analyticsAssetIds);

  return stateAssetIds || analyticsAssetIds || [];
};

const getInitialIncludeDescendants = (state: IAnalyticsStateProps): boolean => {
  const analyticsIncludeDescendants = getStorageItem(STORAGE_KEYS.ANALYTICS_DESCENDANTS, 'true') as unknown as boolean;
  state?.includeDescendants && setStorageItem(STORAGE_KEYS.ANALYTICS_DESCENDANTS, state?.includeDescendants);

  return state?.includeDescendants || analyticsIncludeDescendants;
};

const getInitialIncludeNonScreeningsAssets = (state: IAnalyticsStateProps): boolean => {
  const analyticsIncludeNonScreeningsAssets = getStorageItem(
    STORAGE_KEYS.ANALYTICS_SCREENING_ASSETS,
    'false',
  ) as unknown as boolean;
  setStorageItem(STORAGE_KEYS.ANALYTICS_SCREENING_ASSETS, state?.includeNonScreeningsAssets);

  return state?.includeNonScreeningsAssets || analyticsIncludeNonScreeningsAssets;
};

export const initializeAnalyticsStore = (
  state: IAnalyticsStateProps,
  getMinDate: () => Promise<Date | undefined>,
  initialDateRange?: IDateRange,
): void => {
  getMinDate().then((d) => {
    const minDate = initialDateRange ? initialDateRange[0] : d || initialAnalyticsMinDate;
    useAnalyticsStore.setState({
      minDate,
      dateRange: getDateRangeInit(state, minDate, initialDateRange),
      productIds: getInitialProductIds(state),
      assetIds: getInitialAssetIds(state),
      geoScopeIds: getInitialGeoScopeIds(state),
      includeDescendants: getInitialIncludeDescendants(state),
      userIds: getInitialUserIds(state),
      recipientIds: getInitialRecipientIds(),
      senderIds: getInitialSenderIds(),
      includeNonScreeningsAssets: getInitialIncludeNonScreeningsAssets(state),
    });
  });

  // needed to clear the state so that when the user reloads the page filters that were cleared do not come back
  window.history.replaceState({}, document.title);
};

const resetPaginationOnFilterChange = <T>(eventHandler: (data: T) => void): ((data: T) => void) => {
  return (data: T): void => {
    triggerPageFilterReset(uuidv4());
    return eventHandler(data);
  };
};

export const useAnalyticsStore = create<IAnalyticsStore>((set, get) => ({
  resetPageFilterFlag: null,
  minDate: initialAnalyticsMinDate,
  dateRange: [initialAnalyticsMinDate, today],
  loading: true,
  onDateRangeChange: resetPaginationOnFilterChange(({ dateRange }: { dateRange: IDateRange }) => {
    const [start, end] = dateRange;
    const fullDate = [start && startOfDay(start), end && endOfDay(end)] as IDateRange;
    set({ dateRange: fullDate });
    setStorageItem(STORAGE_KEYS.ANALYTICS_DATE, fullDate);
  }),
  onDescendantsChange: resetPaginationOnFilterChange((evt: React.ChangeEvent<HTMLInputElement>) => {
    const includeDescendants = evt.target.checked;
    set({ includeDescendants });
    setStorageItem(STORAGE_KEYS.ANALYTICS_DESCENDANTS, includeDescendants);
  }),
  onNonScreeningsAssetsChange: resetPaginationOnFilterChange((evt: React.ChangeEvent<HTMLInputElement>) => {
    const includeNonScreeningsAssets = evt.target.checked;
    set({ includeNonScreeningsAssets });
  }),
  onTerritoriesSelect: resetPaginationOnFilterChange(({ territories }: { territories: string[] }) => {
    set({ geoScopeIds: territories || [] });
    setStorageItem(STORAGE_KEYS.ANALYTICS_TERRITORIES, territories);
  }),
  onContactsSelect: resetPaginationOnFilterChange(({ contactIds }: { contactIds: string[] }) => {
    set({ userIds: contactIds });
    setStorageItem(STORAGE_KEYS.ANALYTICS_CONTACTS, contactIds);
  }),
  onRecipientsSelect: resetPaginationOnFilterChange(({ recipientIds }: { recipientIds: string[] }) => {
    set({ recipientIds });
    setStorageItem(STORAGE_KEYS.ANALYTICS_RECIPIENTS, recipientIds);
  }),
  onSendersSelect: resetPaginationOnFilterChange(({ senderIds }: { senderIds: string[] }) => {
    set({ senderIds });
    setStorageItem(STORAGE_KEYS.ANALYTICS_SENDERS, senderIds);
  }),
  onProductsSelect: resetPaginationOnFilterChange(({ productIds }: { productIds: string[] }) => {
    set({ productIds });
    setStorageItem(STORAGE_KEYS.ANALYTICS_PRODUCTS, productIds);
  }),
  onAssetsSelect: resetPaginationOnFilterChange(({ assetIds }: { assetIds: string[] }) => {
    set({ assetIds });
    setStorageItem(STORAGE_KEYS.ANALYTICS_ASSETS, assetIds);
  }),
  onInteractionChange: ({ interaction }: { interaction: IAnalyticsInteractionOption }) => {
    set({ interaction });
  },
  onNonScreeningAssetChange: resetPaginationOnFilterChange((evt: React.ChangeEvent<HTMLInputElement>) => {
    const includeNonScreeningsAssets = evt.target.checked;
    set({ includeNonScreeningsAssets });
    setStorageItem(STORAGE_KEYS.ANALYTICS_DESCENDANTS, includeNonScreeningsAssets);
  }),
  getAnalyticsFilters: (params: IAnalyticsFiltersToApplyParams) => {
    const {
      productIds,
      dateRange,
      userIds,
      geoScopeIds,
      includeDescendants,
      assetIds,
      recipientIds,
      senderIds,
      includeNonScreeningsAssets,
    } = get();

    const filters = getAnalyticsFiltersToApply({
      ...params,
      productIds,
      dateRange,
      userIds,
      geoScopeIds,
      includeDescendants,
      recipientIds,
      senderIds,
      assetIds,
      includeNonScreeningsAssets,
    });

    return { ...filters, loading: false };
  },
  init: ({ state, getMinDate, initialDateRange }: IInitStoreParams) =>
    initializeAnalyticsStore(state, getMinDate, initialDateRange),
  reset: (getMinDate): void => {
    analyticsLocalStorageKeysToRemove.forEach((k) => window.localStorage.removeItem(k));
    triggerPageFilterReset(uuidv4());
    getMinDate().then((d) => {
      const startDate = d && isBefore(initialAnalyticsMinDate, d) ? d : initialAnalyticsMinDate;
      set({ ...initialState, dateRange: [startDate, today], minDate: d });
    });
  },
}));
