import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { IActionConfig } from 'utils/actions/types';

export enum ICastCrewActionName {
  UPLOAD_PREVIEW_IMAGE = 'upload-preview-image',
  DELETE_PREVIEW = 'delete-preview',
  DELETE = 'delete',
  RESTORE = 'restore',
  ASSIGN_TO_PRODUCTS = 'assign-to-products',
  EDIT_ASSIGNMENT = 'edit-assignment',
  REMOVE_FROM_PRODUCTS = 'remove-from-products',
}

export type ICastCrewActionConfig = IActionConfig<PmGenericCastCrew, ICastCrewActionName>;
