import { AmGenericNews } from '@mediafellows/mm3-types';
import omit from 'lodash/omit';

import { Model } from 'helpers/filters/types';
import { IQueryParams, ISearchFilter, ItemId } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { newsDetailsSchema, newsListSchema } from 'utils/schemas/news';
import { parseToSearchParams } from 'utils/general';

export const createNews = (body: AmGenericNews): Promise<AmGenericNews> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<AmGenericNews>(Model.NEWS, 'create', {
      body,
      schema: newsDetailsSchema,
    });

    return object;
  });
};

export const deleteNews = (ids: ItemId[]): Promise<AmGenericNews[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<AmGenericNews>(Model.NEWS, 'delete', {
      params: { news_ids: ids },
    });

    return objects;
  });
};

export const updateNews = (news: Partial<AmGenericNews>): Promise<AmGenericNews> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<AmGenericNews>(Model.NEWS, 'update', {
      params: { news_ids: [news.id] },
      body: news,
    });

    return object;
  });
};

export const fetchNews = (params: IQueryParams, additionalFilters: ISearchFilter[] = []): Promise<AmGenericNews[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<AmGenericNews>(Model.NEWS, 'search', {
      body: parseToSearchParams(params, additionalFilters),
      params: omit(params, ['ids', 'q']),
      schema: newsListSchema,
    });

    return objects;
  });
};
