import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IAsset, IGroup, IMm3Asset } from 'types';

import { IModalSize } from 'components/dialogs/types';

import ScreeningRoomWizard from './screening-room-wizard';
import { getValidIds } from 'utils/general';

interface IUseScreeningRoomDialogProps {
  assets?: (IAsset | IMm3Asset)[];
  onFinish?: (value: IGroup) => void;
}

export const useScreeningRoomDialog = ({ assets, onFinish }: IUseScreeningRoomDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <ScreeningRoomWizard
        screeningRoom={{ assets_item_ids: getValidIds(assets) }}
        onFinish={(newValue: IGroup) => {
          close();
          onFinish?.(newValue);
        }}
      />
    );
  }, [assets, close, onFinish]);

  const openDialog = (): void => {
    openModal({
      title: 'Create Screening Room',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
