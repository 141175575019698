import React from 'react';
import cx from 'classnames';
import { Spoiler } from '@mantine/core';

import { formatField } from 'utils/general';

import './style.scss';

export const ReadMore: React.FC<{ label?: string; value?: string | null; className?: string; maxHeight?: number }> = ({
  label,
  value,
  className,
  maxHeight = 210,
}) => {
  return (
    <Spoiler
      className={cx('d-flex flex-wrap', className)}
      maxHeight={maxHeight}
      showLabel={'Show more'}
      hideLabel={'Show less'}
    >
      <div className="muted-text content-label">{label}</div>
      <div dangerouslySetInnerHTML={{ __html: formatField(value) as string }} />
    </Spoiler>
  );
};
