import { action, observable, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { DialogTypes } from 'types';
import { IConfirmDialogProps, IModalDialogProps, IInfoDialogProps } from 'components/dialogs';

export interface IConfirmDialogItem {
  type: DialogTypes.CONFIRM;
  props: IConfirmDialogProps;
}

export interface IInfoDialogItem {
  type: DialogTypes.INFO;
  props: IInfoDialogProps;
}

export interface IModalDialogItem {
  type: DialogTypes.MODAL;
  props: IModalDialogProps;
}

export interface IAdditionalDialogItemProps {
  id: string;
}

export type IDialogItem = (IConfirmDialogItem | IInfoDialogItem | IModalDialogItem) & IAdditionalDialogItemProps;

export class DialogStore {
  @observable public activeDialogs = observable<IDialogItem>([]);

  @computed get current(): IDialogItem {
    return this.activeDialogs[this.activeDialogs.length - 1];
  }

  @action.bound
  public openModal(props: IModalDialogProps): void {
    this.open({ type: DialogTypes.MODAL, props });
  }

  @action.bound
  public openInfo(props: IInfoDialogProps): void {
    this.open({ type: DialogTypes.INFO, props });
  }

  @action.bound
  public openConfirm(props: IConfirmDialogProps): void {
    this.open({ type: DialogTypes.CONFIRM, props });
  }

  @action.bound
  private open(item: IConfirmDialogItem | IInfoDialogItem | IModalDialogItem): void {
    this.activeDialogs.push({ ...item, id: `${item.type}-${uuidv4()}` });
  }

  @action.bound
  public closeAllDialogs(): void {
    while (this.activeDialogs.length) {
      this.close();
    }
  }

  @action.bound
  public close(): void {
    this.activeDialogs.pop();
  }
}
