import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';

import SelectionDetailsLayout from 'components/selection-details/selection-details-layout/selection-details-layout';
import { AssetSelectionActionsList } from 'components/action';
import { mapAssetSelection } from 'pages/asset-selections/asset-selections';

import { ISelectionActionName } from 'utils/actions/selection/types';
import { IActionName, ITitleDividerActionName, Pages } from 'utils/actions/types';
import useSelection from 'utils/hooks/selection';

import { ISelectionStandardized } from 'types';
import { IGroupActionName } from 'utils/actions/common/types';
import { useRefreshDataSection } from 'utils/hooks';
import { IAssetActionName } from 'utils/actions/asset';
import { Routes } from 'utils/routes';
import { GroupDetailAssetsTab } from 'components/marketing-entity-detail';
import { ActionsMenu } from 'components/action/utils';

const page = Pages.SELECTION_DETAILS;
const options = {
  groupName: ITitleDividerActionName.SELECTION,
  groupParamName: 'selectionId',
};

const AssetSelectionDetails: React.FC<{}> = observer(() => {
  const { selectionId = '' } = useParams<{ selectionId: string }>();

  const [selection, selectionLoading, refreshSelection, setSelection] = useSelection(selectionId, mapAssetSelection);
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  const refreshDataSection = useRefreshDataSection();

  const refreshDataSectionAndSelectionDelayed = useCallback(() => {
    setTimeout(() => {
      refreshDataSection();
      refreshSelection();
    }, 1000);
  }, [refreshDataSection, refreshSelection]);

  const navigate = useNavigate();
  const onSuccess = useCallback(
    async (action?: string, selection?) => {
      switch (action) {
        case IGroupActionName.ADD_ENTITIES:
        case ISelectionActionName.UPLOAD_ASSET_TO_SELECTION:
          refreshDataSectionAndSelectionDelayed();
          break;
        case ISelectionActionName.DELETE:
          navigate(`${Routes.ASSET_SELECTIONS}`);
          break;
        case ISelectionActionName.CHANGE_ACCESS:
          setSelection(mapAssetSelection(selection));
          break;
        default:
          await refreshSelection();
      }
    },
    [refreshSelection, navigate, setSelection, refreshDataSectionAndSelectionDelayed],
  );

  const dataSectionOnSuccess = useCallback(
    (action?: IActionName): void => {
      if (action === IAssetActionName.REMOVE_FROM_GROUP) {
        refreshDataSectionAndSelectionDelayed();
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSectionAndSelectionDelayed, refreshDataSection],
  );

  if (!selectionId) {
    return <></>;
  }

  return (
    <SelectionDetailsLayout
      selection={selection as ISelectionStandardized}
      refreshSelection={refreshSelection}
      selectionLoading={selectionLoading}
      headerDropdownMenu={
        <ActionsMenu
          component={AssetSelectionActionsList}
          items={[selection as ISelectionStandardized]}
          options={{ ...options, context: 'single', page, onSuccess: onSuccess }}
        />
      }
      isSortModeEnabled={isSortModeEnabled}
      setIsSortModeEnabled={setIsSortModeEnabled}
    >
      <GroupDetailAssetsTab
        isSortModeEnabled={isSortModeEnabled}
        groupId={selectionId}
        actionOptions={{
          ...options,
          groupName: ITitleDividerActionName.SELECTION,
          groupParamName: 'selectionId',
          onSuccess: dataSectionOnSuccess,
          page: Pages.SELECTION_ENTITY_TAB,
        }}
      />
    </SelectionDetailsLayout>
  );
});

export default AssetSelectionDetails;
