import React from 'react';
import { observer } from 'mobx-react-lite';

import { Thumbnail } from 'components/thumbnail';
import { ProductSearchTitle } from 'components/product/product-search-title/product-search-title';

import { IProduct } from 'types';

import { formatLayerDuration } from 'utils/date';
import { placeholder } from 'utils/ui';
import { Classes } from 'utils/ui';

import './style.scss';

interface IProductSelectedTabItemProps {
  product: IProduct;
}

const ProductSelectedTabItem: React.FC<IProductSelectedTabItemProps> = observer(({ product }) => {
  const { type, default_layer, inherited_preview_image, title } = product || {};
  return (
    <div className={'product-selected-tab-item'}>
      <div className="product-selected-tab-item__thumb px-2">
        <Thumbnail showType image={inherited_preview_image?.url} assetType={type} />
      </div>
      <div className="product-selected-tab-item__content">
        <ProductSearchTitle
          product={product}
          title={title}
          className="product-selected-tab-item__title text-truncate mb-1"
        />
        <div className="product-selected-tab-item-info__wrapper">
          <span className={Classes.TEXT_MUTED}>{placeholder(default_layer?.meta?.year_of_production)}</span>
          <span className="product-selected-tab-item__info-text text-truncate">
            {placeholder(formatLayerDuration(default_layer))}
          </span>
        </div>
      </div>
    </div>
  );
});

export default React.memo(ProductSelectedTabItem);
