import { useCallback } from 'react';
import { map } from 'lodash';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { useStore } from 'store';

import { IUseAction } from 'utils/actions';
import { setConnectionStatus } from 'utils/apis/delivery-connections';
import { pluralEntityInflect } from 'utils/general';
import { IConnectionActionName } from 'utils/actions/connection/types';
import { shouldDisplayDeactivation } from 'utils/actions/connection/actions-acl';

export const useDeactivateConnectionAction: IUseAction<DeliveriesGenericConnection, IConnectionActionName> = (
  items,
  options,
) => {
  const { toastStore } = useStore();
  const { entity, entityWithCount } = pluralEntityInflect('Connection', items.length);

  const deactivate = useCallback(async () => {
    toastStore.info(`Deactivating Connection...`);
    try {
      await setConnectionStatus(map(items, 'id'), 'deactivate');
      toastStore.clearAll();
      toastStore.success(`${entityWithCount} deactivated`);
      return await options?.onSuccess?.(IConnectionActionName.ACTIVATE);
    } catch (error) {
      toastStore.clearAll();
      toastStore.error(`${entity} deactivation failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  return {
    name: IConnectionActionName.DEACTIVATE,
    icon: 'disable',
    title: 'Deactivate',
    shouldDisplay: shouldDisplayDeactivation,
    handler: deactivate,
  };
};
