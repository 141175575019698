import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { IScreeningRoomStepProps } from './utils';
import { FormImage, FormInput } from 'helpers/form';

export const ScreeningRoomStepGeneral: React.FC<IScreeningRoomStepProps> = ({ form }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
      <FormInput name="name" label="Title" {...form.formData.name} {...form.handlers} />
      <FormInput name="description" label="Description" {...form.formData.description} {...form.handlers} textarea />
      <FormImage
        type="attachment"
        name="group_preview_image_url"
        label="Preview Image"
        {...form.formData.group_preview_image_url}
        {...form.handlers}
      />
    </FormSectionWrapperV2>
  );
};
