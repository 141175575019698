import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import noop from 'lodash/noop';

import { IProduct } from 'types';
import { SortDataSection } from 'components/sort-data-section';
import { useSortDataSectionStore } from 'store/hooks';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';
import { ISortableItem } from 'store/sort-data-store';
import { queryProductsByBasketId, updateProductSequenceNumberInBasket } from 'utils/apis/product';

export function productItemRenderer(product: ISortableItem): JSX.Element {
  return (
    <ProductDataSectionListItem
      product={product as IProduct}
      id={product.id || 0}
      isAllItemsSelected={false}
      active={NaN}
      handleClick={noop}
      isChecked={false}
      contextMenu={<></>}
    />
  );
}

export const BasketsProductsSortDataSection: React.FC<{ basketId: string }> = observer(({ basketId }) => {
  const { initStore } = useSortDataSectionStore<ISortableItem>();
  const fetcher = useCallback(() => queryProductsByBasketId(basketId), [basketId]);

  const onSave = useCallback(
    async (list) => {
      await updateProductSequenceNumberInBasket(
        basketId,
        list.map(({ id }, index) => {
          return { id, sequence_number: index };
        }),
      );
    },
    [basketId],
  );

  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={productItemRenderer} />;
});

export default BasketsProductsSortDataSection;
