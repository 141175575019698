import React, { useCallback, useMemo } from 'react';
import { useProfileStore, useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { IActionOpts } from '@mediafellows/chipmunk';

import { ToastError } from 'components/toast';
import { ProductPreviewTab } from 'components/product-preview-tab';
import { ProductSelectedTabItem } from 'components/product-selected-tab-item';
import { IFilters, ProductFilters, getDefaultFilters, noParentFilter, stats } from 'components/product-filters';
import { DataSection, ICustomSortMenuProp } from 'components/data-section';
import { ProductDataSectionItemWrapper, getDataItemComponent } from 'components/product-data-section-item';
import { ProductActionsList } from 'components/action';
import { ActionsMenu } from 'components/action/utils';
import { Model } from 'helpers/filters/types';
import { productListSchema } from 'utils/schemas';
import { Pages, IUseActionsOptionParam } from 'utils/actions/types';
import { IProduct, ISearchFilter } from 'types';
import { IProductActionName } from 'utils/actions/product/types';
import { actionsWithDelayedRefresh, handleUpdate } from './utils';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';
import { ISubmitFiltersParams } from 'helpers/filters/helpers';
import { chipmunk, IResult } from 'utils/chipmunk';
import { parseOptionsToSearch } from 'utils/general';
import { useRefreshDataSection } from 'utils/hooks';
import useControlDataSection from 'utils/hooks/control-data-section';
import { useQuery } from 'utils/url';

const sidebarTabs = (customSubmitFilter?: (opt: ISubmitFiltersParams) => void): Record<string, React.ReactNode> => {
  return {
    preview: <ProductPreviewTab />,
    filters: <ProductFilters customSubmitFilter={customSubmitFilter} />,
    selectedItemRenderer(product: IProduct) {
      return <ProductSelectedTabItem product={product} />;
    },
  };
};

interface IProductsDataSectionProps {
  persistentFilters?: ISearchFilter[];
  showParentsOnlyFilter?: boolean;
  parentFilterDefaultValue?: boolean;
  page: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  actionName?: string;
  params?: Record<string, unknown>;
  getSortMenu?: ICustomSortMenuProp;
  executor?: (options: IActionOpts) => Promise<IResult<IProduct>>;
  defaultParams?: IDataSectionDefaultParams;
  customSubmitFilter?: (opt: ISubmitFiltersParams) => void;
  headerRightSection?: React.ReactElement;
  hideSortButtons?: boolean;
  initialFilters?: Partial<IFilters>;
  persistFilters?: boolean;
}

const urlSearchFields: (keyof Pick<IProduct, 'type' | 'created_at'>)[] = ['type', 'created_at'];
const ProductsDataSection: React.FC<IProductsDataSectionProps> = observer((props) => {
  const {
    persistentFilters,
    showParentsOnlyFilter = true,
    parentFilterDefaultValue,
    page = Pages.LIST,
    actionName = 'search',
    params,
    actionOptions = {},
    executor: customExecutor,
    defaultParams,
    persistFilters,
    customSubmitFilter,
    headerRightSection,
    hideSortButtons,
  } = props;
  const refreshDataSection = useRefreshDataSection();
  const {
    dataSectionStore: { checked },
    toastStore,
  } = useStore();
  const queryParams = useQuery<Pick<IProduct, 'type' | 'created_at'>>(urlSearchFields);
  const layout = useProfileStore(({ computed }) => computed.layout);

  const exec = useCallback(
    async (options: IActionOpts): Promise<IResult<IProduct>> => {
      try {
        const result = await chipmunk.run(({ action }) => action<IProduct>(Model.PRODUCTS, actionName, options));
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
        const objects = [];
        return { objects, object: objects[0] };
      }
    },
    [actionName, toastStore],
  );

  const executor = useCallback(
    (options: IActionOpts) => (customExecutor || exec)(parseOptionsToSearch(options, urlSearchFields)),
    [exec, customExecutor],
  );

  const { defaultFilters, initFilters } = useMemo(
    () => ({
      defaultFilters: getDefaultFilters({ showParentsOnlyFilter, parentFilterDefaultValue }),
      initFilters: showParentsOnlyFilter ? noParentFilter(parentFilterDefaultValue) : undefined,
    }),
    [showParentsOnlyFilter, parentFilterDefaultValue],
  );

  useControlDataSection({
    defaultFilters,
    defaultParams,
    executor,
    filters: persistentFilters,
    persistFilters,
    handleUpdate,
    initFilters,
    params,
    queryParams,
    schema: productListSchema,
    stats,
  });

  const onSuccess = useCallback(
    async (action?: IProductActionName): Promise<void> => {
      if (action && actionsWithDelayedRefresh.includes(action)) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection],
  );

  const ProductDataItemComponent = getDataItemComponent(layout);

  const handleItemRenderer = (product: IProduct): JSX.Element => {
    return (
      <ProductDataSectionItemWrapper
        key={product.id}
        product={product}
        contextMenu={
          <ActionsMenu
            component={ProductActionsList}
            items={[product]}
            options={{ context: 'single', page, onSuccess, ...actionOptions }}
          />
        }
        component={ProductDataItemComponent}
      />
    );
  };

  return (
    <DataSection
      headerRightSection={headerRightSection}
      tabs={sidebarTabs(customSubmitFilter)}
      itemRenderer={handleItemRenderer}
      customSortMenu={props.getSortMenu}
      hideSortButtons={hideSortButtons}
      contextMenu={
        <ActionsMenu
          component={ProductActionsList}
          items={checked}
          options={{ context: 'multi', page, onSuccess, ...actionOptions }}
        />
      }
      defaultParams={defaultParams}
      layout={layout}
    />
  );
});

export default ProductsDataSection;
