import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';

import { DataSectionPreset } from 'components/data-section-preset';
import { today } from 'utils/date';
import { addYears } from 'date-fns';

import { FilterCheckbox, FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { EqFilter, IFiltersDefinition, QFilter, RangeFilter, Model } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { useFilters } from 'helpers/filters';
import { getCustomOwnersOptionsProvider } from 'helpers/filters/option-providers';
import { isAssetRecommendationEnabled } from 'utils/recommendation';

export interface IRecommendationFilters extends IFiltersDefinition {
  _: QFilter;
  status: EqFilter;
  type: EqFilter;
  updated_at: RangeFilter;
  expires_at: RangeFilter;
  sent_at: RangeFilter;
  created_at: RangeFilter;
  owner_id: EqFilter;
}

const typeOptionsProvider = new Mm3ContextDataProvider(Model.RECOMMENDATIONS, 'type');
const statusOptionsProvider = new Mm3ContextDataProvider(Model.RECOMMENDATIONS, 'status');

export const RecommendationFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore;

  const handleSubmit = useCallback((action): void => {
    submitFilters({
      action,
    });
  }, []);

  const [filterValues, filterHandlers] = useFilters<IRecommendationFilters>(dataSectionStore, handleSubmit);

  const ownerAggregation = searchStore.aggregationValues?.('owner_id');
  const ownersOptionsProvider = useMemo(() => getCustomOwnersOptionsProvider(ownerAggregation), [ownerAggregation]);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          {isAssetRecommendationEnabled() ? (
            <FilterCheckbox
              label="Type"
              name="type"
              optionsProvider={typeOptionsProvider}
              disabled={searchStore.running}
              filter={filterValues.type}
              onChange={filterHandlers.onChange}
              aggregations={searchStore.aggregationValues('type')}
            />
          ) : (
            <></>
          )}
          <FilterCheckbox
            label="Status"
            name="status"
            optionsProvider={statusOptionsProvider}
            disabled={searchStore.running}
            filter={filterValues.status}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('status')}
          />
          <FilterDateRange
            label="Sent at"
            name="sent_at"
            filter={filterValues.sent_at}
            onChange={filterHandlers.onChange}
          />
          <FilterDateRange
            label="Expires at"
            name="expires_at"
            filter={filterValues.expires_at}
            onChange={filterHandlers.onChange}
            maxDate={addYears(today, 10)}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />
          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
            aggregations={ownerAggregation}
          />
        </form>
      </div>
    </div>
  );
});
