import {
  format,
  isValid,
  isBefore,
  endOfYear,
  startOfYear,
  subYears,
  isAfter,
  max,
  min,
  startOfWeek,
  subWeeks,
} from 'date-fns';

import { IProductLayerMeta } from 'types';

const EDGE_MINUTES = [14, 29, 44, 59, 46, 31, 16, 1];

export const defaultDateFormat = 'dd MMM yyyy, HH:mm';
export const dateWithoutHours = 'dd MMM yyyy';
export const ISODateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const dateFormat = 'yyyy-MM-dd';

export const formatDate = (
  date: Date | number | string | null | undefined,
  displayFormat = defaultDateFormat,
): string => {
  if (!date) return '';
  let d = date;

  if (!(d as Date)?.getMonth) {
    d = new Date(date);
  }

  if (isValid(d)) {
    return format(d as Date, displayFormat);
  }

  return '';
};

const padZeros = (t: number): string => `${t < 10 ? '0' : ''}${t}`;
export const formatDuration = (
  duration: number | null | undefined,
  type: 'minutes' | 'seconds' = 'minutes',
): string => {
  if (!duration && duration !== 0) return '';
  let count = Math.floor(duration);
  let seconds = 0;

  if (type === 'seconds') {
    seconds = count % 60;
    count = (count - seconds) / 60;
  }

  const minutes = count % 60;
  const hours = (count - minutes) / 60;

  return `${hours}:${padZeros(minutes)}:${padZeros(seconds)}`;
};

export const today = new Date();
// these are default min and max dates for Bpjs date picker
export const defaultMinDate = startOfYear(subYears(new Date(), 10));
export const defaultMaxDate = endOfYear(new Date());

export const parseDate = (date: Date, minDate?: Date, maxDate?: Date): Date | null => {
  if (!isValid(date)) {
    return null;
  }

  const minD = minDate || defaultMinDate;
  if (isBefore(date, minD)) {
    return minD;
  }

  const maxD = maxDate || defaultMaxDate;
  if (isAfter(date, maxD)) {
    return maxD;
  }

  return date;
};

export const parseDate15Minutes = (inputDate: Date | string, minDate?: Date, maxDate?: Date): Date | null => {
  if (typeof inputDate === 'string') {
    inputDate = new Date(inputDate);
  }
  const date = parseDate(inputDate, minDate, maxDate);
  if (!date) return date;

  let minute = date.getMinutes();

  if (!EDGE_MINUTES.includes(minute)) {
    date.setMinutes((minute = Math.ceil(minute / 15) * 15));
  }

  switch (minute) {
    case 1:
      date.setMinutes(15);
      break;
    case 14:
      date.setMinutes(0);
      break;
    case 16:
      date.setMinutes(30);
      break;
    case 29:
      date.setMinutes(15);
      break;
    case 31:
      date.setMinutes(45);
      break;
    case 44:
      date.setMinutes(30);
      break;
    case 46:
      date.setMinutes(60);
      break;
    case 59:
      date.setHours(date.getHours() - 1), date.setMinutes(45);
      break;
  }

  date.setSeconds(0);

  return date;
};

export function formatLayerDuration(
  layer?: {
    meta?: Partial<Pick<IProductLayerMeta, 'duration' | 'duration_text'>>;
  } | null,
): string | null {
  return layer?.meta?.duration_text || (layer?.meta?.duration ? `${layer.meta.duration} min` : null);
}

export const isBetween = (locationEndAt: Date, start?: Date, end?: Date): boolean => {
  if (!start || !end) {
    return false;
  }

  return isBefore(locationEndAt, end) && isAfter(locationEndAt, start);
};

export const getMaxDate = (dates: (Date | undefined | null)[]): Date => {
  return max(dates.filter(isValid) as Date[]);
};

export const getMinDate = (dates: (Date | undefined | null)[]): Date => {
  return min(dates.filter(isValid) as Date[]);
};

export const extendedFormatDuration = (durationInMs: number): string => {
  if (!durationInMs) return '-';

  const hours = Math.floor(durationInMs / (60 * 60 * 1000));
  const hoursms = durationInMs % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = durationInMs % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return hours + ':' + padZeros(minutes) + ':' + padZeros(sec);
};

export const checkIfDateWithoutHours = (dateString: string | number | Date | null | undefined): boolean => {
  if (!dateString) return false;

  if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString as string)) return true;

  return false;
};

// Parse date issue:
// https://stackoverflow.com/questions/68807970/parse-function-in-date-fns-returns-one-day-previous-value
export const formatStringDate = (
  date: string | number | Date | undefined | null,
): string | number | Date | undefined | null => {
  if (typeof date === 'string' && !!date) {
    return date.replace(/-/g, '/');
  }

  return date;
};

export const startOfThisWeek = startOfWeek(today);
export const startOfLastWeek = subWeeks(startOfThisWeek, 1);

export const createdAtRange: Record<string, [string, string]> = {
  'this-week': [startOfThisWeek.toISOString(), today.toISOString()],
  'last-week': [startOfLastWeek.toISOString(), startOfThisWeek.toISOString()],
};
