import React, { useCallback } from 'react';
import { SegmentedControl } from '@mantine/core';

import { formatFormLabel } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { FormGroup } from 'helpers/form/fields/form-group';

import { IStyled } from 'types';
export interface IFormSegmentOption {
  value: string;
  label: string;
}

export interface IFormSegment extends IFieldData<string>, IFieldHandlers<string>, IStyled {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  segments: IFormSegmentOption[];
}

export const FormSegment: React.FC<IFormSegment> = (props) => {
  const {
    name,
    label = '',
    large,
    inline,
    disabled,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    style,
    segments,
  } = props;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (item: string) => {
      onChange?.({ [name]: item });
      handleBlur();
    },
    [handleBlur, name, onChange],
  );

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      inline={inline}
      style={style}
      showError={showError}
      errorMessage={validation?.errorMessage}
    >
      <SegmentedControl
        ms={inline ? 8 : 0}
        value={value}
        onChange={handleChange}
        data={segments}
        disabled={disabled}
        fullWidth={large}
        transitionDuration={1000}
        transitionTimingFunction="linear"
        name={name}
      />
    </FormGroup>
  );
};
