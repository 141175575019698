import React, { useState, useMemo } from 'react';
import { Tabs } from '@mantine/core';

import { GroupDetailProductsTab, GroupDetailContactsTab } from 'components/marketing-entity-detail';
import { SortButton } from 'components/sort-buttons';
import { EmptySectionMessage } from 'components/section-message/section-message';
import { OrganizationDataSection } from 'components/organization';
import { DataSectionControls } from 'components/data-section-controls';
import { TabButtonsWrapper } from 'components/tabs';
import { AddItemsToAccessPrivilegeButton } from './tab-buttons';

import { Pages } from 'utils/actions/types';

import { IAccessPrivilege, DetailsPageTabs } from 'types';
import { ITitleDividerActionName } from 'utils/actions/types';
import { IFilterOption } from 'utils/hooks';
import { AccessPDetailTab, AccessPTabPanel } from './utils';

const options = {
  page: Pages.ACCESS_PRIVILEGES_DETAILS,
  groupName: ITitleDividerActionName.ACCESS_PRIVILEGE,
  groupParamName: 'accessPrivilegeId',
};

interface IAccessPrivilegeDetailsTabsView {
  accessPrivilege?: IAccessPrivilege;
  editModeEnabled?: boolean;
  currentTab: DetailsPageTabs;
  onTabChangeHandle: (DetailsPageTabs) => void;
  onSuccess: () => void;
}

const showDataSectionButtons = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.CONTACTS, DetailsPageTabs.PRODUCTS, DetailsPageTabs.ORGANIZATIONS].includes(tab);

const canAddItemsToTab = (tab: DetailsPageTabs): boolean =>
  [DetailsPageTabs.PRODUCTS, DetailsPageTabs.CONTACTS, DetailsPageTabs.ORGANIZATIONS].includes(tab);

export const AccessPrivilegeDetailsTabsView: React.FC<IAccessPrivilegeDetailsTabsView> = ({
  accessPrivilege,
  currentTab,
  onTabChangeHandle,
  editModeEnabled,
  onSuccess,
}) => {
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);
  const { id } = accessPrivilege || {};

  const groupPersistentFilters = useMemo<IFilterOption[]>(() => (id ? [['group_ids', 'eq', id]] : []), [id]);

  const actionsOptions = useMemo(
    () => ({
      ...options,
      groupId: id,
      onSuccess,
    }),
    [id, onSuccess],
  );

  if (!id || !accessPrivilege) {
    return <EmptySectionMessage />;
  }
  return (
    <Tabs value={currentTab} onChange={onTabChangeHandle} className="recommendation-details__tabs h-100">
      <Tabs.List className="d-flex flex-nowrap">
        <AccessPDetailTab
          value={DetailsPageTabs.PRODUCTS}
          isSortModeEnabled={isSortModeEnabled}
          editModeEnabled={editModeEnabled}
          activeTab={currentTab}
        />
        <AccessPDetailTab
          value={DetailsPageTabs.CONTACTS}
          activeTab={currentTab}
          isSortModeEnabled={isSortModeEnabled}
          editModeEnabled={editModeEnabled}
        />
        <AccessPDetailTab
          value={DetailsPageTabs.ORGANIZATIONS}
          activeTab={currentTab}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <TabButtonsWrapper>
          <SortButton
            isSortModeEnabled={isSortModeEnabled}
            setIsSortModeEnabled={setIsSortModeEnabled}
            hidden={currentTab !== DetailsPageTabs.PRODUCTS}
          />
          <AddItemsToAccessPrivilegeButton
            disabled={isSortModeEnabled}
            accessPrivilege={accessPrivilege}
            currentTab={currentTab}
            onSuccess={onSuccess}
            hidden={!canAddItemsToTab(currentTab)}
          />
          <DataSectionControls
            disabled={isSortModeEnabled}
            customRefresh={onSuccess}
            hidden={!showDataSectionButtons(currentTab)}
          />
        </TabButtonsWrapper>
      </Tabs.List>
      <AccessPTabPanel
        value={DetailsPageTabs.PRODUCTS}
        content={
          <GroupDetailProductsTab
            isSortModeEnabled={isSortModeEnabled}
            showParentsOnlyFilter
            parentFilterDefaultValue={false}
            groupId={id}
            options={actionsOptions}
            page={Pages.ACCESS_PRIVILEGES_DETAILS}
          />
        }
      />
      <AccessPTabPanel
        value={DetailsPageTabs.CONTACTS}
        content={
          <GroupDetailContactsTab groupId={id} options={actionsOptions} page={Pages.ACCESS_PRIVILEGES_DETAILS} />
        }
      />
      <AccessPTabPanel
        value={DetailsPageTabs.ORGANIZATIONS}
        content={<OrganizationDataSection actionsOptions={actionsOptions} persistentFilters={groupPersistentFilters} />}
      />
    </Tabs>
  );
};
