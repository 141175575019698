import React from 'react';
import { Timeline as VerticalTimeline } from '@mantine/core';
import { useStore } from 'store';
import cx from 'classnames';

import { TimelineItemDetails } from 'components/timeline-item-details';
import { UserAvatar } from 'components/user-avatar';

import { ITimelineItem } from 'types';
import { formatDate } from 'utils/date';
import { formatTimelineItemDescription } from 'utils/general';
import { Classes } from 'utils/ui';

import './style.scss';

export interface ITimelineProps {
  className?: string;
  data: ITimelineItem[];
  handleLoadMore?: () => void;
}

const Timeline: React.FC<ITimelineProps> = ({ data, handleLoadMore }) => {
  const { dialogStore } = useStore();

  const handleClick = (item: ITimelineItem): void => {
    dialogStore.openInfo({
      title: 'Event Details',
      body: <TimelineItemDetails item={item} />,
    });
  };

  const handleScrollEnd = (e): void => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (handleLoadMore && bottom) {
      handleLoadMore();
    }
  };

  return (
    <div className="timeline h-100" onScroll={handleScrollEnd}>
      <VerticalTimeline active={data.length}>
        {data?.map((item, index) => {
          const { effective_at, user_title, description, user } = item;
          const { first_name, last_name } = user || {};
          return (
            <VerticalTimeline.Item key={index} onClick={() => handleClick(item)}>
              <div className={cx(`${Classes.TEXT_MUTED}`, 'timeline__date')}>{formatDate(effective_at) || '—'}</div>
              <div
                className={cx('timeline__item', {
                  'timeline__item--no-user': !user,
                })}
              >
                <div className="timeline__avatar">
                  <UserAvatar disabled user={user} />
                </div>
                <div className="timeline__info">
                  {user && (
                    <span className={cx(`${Classes.TEXT_MUTED}`, 'timeline__user')}>
                      {first_name} {last_name}
                    </span>
                  )}
                  {!user && <span className={cx(`${Classes.TEXT_MUTED}`, 'timeline__user')}>{user_title}</span>}
                  <span className="timeline__description">{formatTimelineItemDescription(description)}</span>
                </div>
              </div>
            </VerticalTimeline.Item>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export default Timeline;
