import React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from '@mantine/core';

import { ParametronSort } from 'helpers/filters/types';
import { ICustomSortMenuProp } from 'components/data-section';
import { useDataSectionStore } from 'store';

import cx from 'classnames';

export const GroupAssetsSortMenu: React.FC<{
  sort: ParametronSort;
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}> = observer(({ sort, handleSort }) => {
  const { searchStore } = useDataSectionStore();
  const { paramsAndFiltersCount = 0 } = searchStore || {};
  const hasFiltersApplied = Boolean(paramsAndFiltersCount);

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.SEQUENCE_NUMBER}
        disabled={hasFiltersApplied}
        className={cx({ active: sort === ParametronSort.SEQUENCE_NUMBER })}
      >
        Display Order
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.UPDATED_AT}
        className={cx({ active: sort === ParametronSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Date Created
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.PUBLISHED_AT}
        className={cx({ active: sort === ParametronSort.PUBLISHED_AT })}
      >
        Date Published
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.NAME}
        className={cx({ active: sort === ParametronSort.NAME })}
      >
        Name
      </Menu.Item>
    </Menu>
  );
});

export const customSortMenu: ICustomSortMenuProp = (sort, handleSort) => (
  <GroupAssetsSortMenu sort={sort} handleSort={handleSort} />
);
