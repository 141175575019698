import React, { Fragment } from 'react';

export interface ILoadingBoundaryProps {
  loading: boolean;
  suspender: JSX.Element;
}

const LoadingBoundary: React.FC<React.PropsWithChildren<ILoadingBoundaryProps>> = ({
  loading,
  suspender,
  children,
}) => {
  return <Fragment>{loading ? suspender : children}</Fragment>;
};

export default LoadingBoundary;
