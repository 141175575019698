import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { ItemId } from 'types/utility';
import { updateCastCrew } from 'utils/apis/cast-crew';
import { deletePreviewImage, ingestPreviewImage } from 'utils/apis/preview-image';

export const uploadCastCrewAvatar = async (
  id: number,
  url: string,
  currentPreviewImageId?: ItemId | null,
): Promise<PmGenericCastCrew | undefined> => {
  if (!url || !id) {
    return;
  }

  const object = await ingestPreviewImage(url, currentPreviewImageId);

  return updateCastCrew({ id, preview_image_id: object?.id });
};

export const deleteCastCrewPreviewImage = async ({
  id,
  preview_image_id,
}: PmGenericCastCrew): Promise<PmGenericCastCrew | undefined> => {
  const result = await updateCastCrew({ id: id, preview_image_id: null });
  await deletePreviewImage(preview_image_id);

  return result;
};
