import { useCallback } from 'react';
import map from 'lodash/map';

import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { removeOrganizationsFromGroup } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { shouldDisplayRemoveFromGroup } from './actions-acl';

import { IOrganizationActionName } from './types';
import { IIdentifiable, IOrganization, ItemId } from 'types';

interface IOptions extends IUseActionsOptionParam {
  groupId?: ItemId;
}

export const useRemoveFromGroupAction = (
  items: IIdentifiable[],
  options?: IOptions,
): IActionConfig<IOrganization, IOrganizationActionName> => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithCount, entity, entityWithDemonstrative } = pluralEntityInflect('Organization', items.length);

  const executeRemove = useCallback(async () => {
    try {
      const groupId = options?.groupId;
      const itemIds = map(items, 'id');
      if (!groupId || !itemIds.length) {
        toastStore.error(`No organizations selected to remove from ${options?.groupName}`);
        return;
      }
      await removeOrganizationsFromGroup(groupId, itemIds);
      toastStore.success(`${entityWithCount} will be removed from ${options?.groupName}`);
      await options?.onSuccess?.(IOrganizationActionName.REMOVE_FROM_GROUP);
    } catch (error) {
      toastStore.error(`${entity} remove failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [options, items, toastStore, entityWithCount, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm remove',
      body: `Are you sure you want to remove ${entityWithDemonstrative} from the ${options?.groupName}?`,
      onConfirm: executeRemove,
    });
  }, [dialogStore, entityWithDemonstrative, executeRemove, options?.groupName]);

  return {
    name: IOrganizationActionName.REMOVE_FROM_GROUP,
    shouldDisplay: shouldDisplayRemoveFromGroup,
    icon: 'remove',
    title: `Remove from ${options?.groupName}`,
    intent: Intent.DANGER,
    handler,
  };
};
