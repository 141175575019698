import React from 'react';
import { AccessGroupsDataSection } from 'components/access-groups-data-section';
import { Pages } from 'utils/actions/types';
import { DataSectionPage } from 'components/layout';

const AccessGroups: React.FC = () => {
  return (
    <DataSectionPage>
      <AccessGroupsDataSection persistFilters page={Pages.ACCESS_GROUPS_LIST} />
    </DataSectionPage>
  );
};

export default AccessGroups;
