import React, { useCallback, useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { endOfDay, startOfDay } from 'date-fns';
import { IBarChartData } from 'types';
import { ChartContainer } from 'components/chart-container';
import { dateFormat } from 'utils/date';
import { pluralizeTooltipLabel, parseTooltipDateTitle as parseTitle } from 'components/analytics';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

ChartJS.defaults.color = '#8ca6b7';
ChartJS.defaults.font = { family: 'var(--font-family-sans-serif)', size: 14, weight: '500' };

interface IBarChartProps {
  data: IBarChartData[];
  startDate: Date;
  endDate: Date;
  unit?: string;
  aspectRatio?: number;
}

const DateCountBarChart: React.FC<IBarChartProps> = ({
  data,
  startDate,
  endDate,
  unit = 'view',
  aspectRatio = 1.2,
}) => {
  const formatLabel = useCallback(({ parsed: { y = 0 } }) => pluralizeTooltipLabel(y, unit), [unit]);
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio,
      resizeDelay: 50,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: formatLabel,
            title: parseTitle,
          },
        },
      },
      parsing: { xAxisKey: 'date', yAxisKey: 'count' },
      scales: {
        xAxes: {
          type: 'time' as const,
          time: { unit: 'day' as const, displayFormats: { day: dateFormat } },
          min: startOfDay(startDate).getTime(),
          max: endOfDay(endDate).getTime(),
          offset: true,
          grid: {
            display: false,
            offset: false,
            borderWidth: 2,
            borderColor: '#304658',
            color: '#304658',
          },
          afterTickToLabelConversion: (scaleInstance) => {
            scaleInstance.ticks = [scaleInstance.ticks[0], scaleInstance.ticks[scaleInstance.ticks.length - 1]];
          },
        },
        yAxes: {
          min: 0,
          beginAtZero: true,
          ticks: { precision: 0 },
          offset: false,
          grid: {
            display: false,
            borderWidth: 2,
            borderColor: '#304658',
            color: '#304658',
          },
        },
      },
    }),
    [aspectRatio, formatLabel, startDate, endDate],
  );

  const chartData = {
    datasets: [
      {
        data,
        backgroundColor: '#2b95d6',
      },
    ],
  };
  return (
    <ChartContainer aspectRatio={aspectRatio}>
      <Bar options={options} data={chartData} />
    </ChartContainer>
  );
};

export default DateCountBarChart;
