import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';

import { IconAlias } from 'icons';
import { IExtendedProductCastCrew, IProduct } from 'types';
import { Routes } from 'utils/routes';
import { SmallLoader, Thumbnail } from 'components/thumbnail';

import { IExtendedCastCrewAttributesProps } from './extended-data-section-item';
import { CastCrewListItemAttributes, ICastCrewListItemAttributesProps } from './cast-crew-item-attributes';
import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';

import './style.scss';

interface ICastCrewDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  cast: IExtendedProductCastCrew & { products?: IProduct[] };
  isChecked?: boolean;
  isCheckedDisabled?: boolean;
  handleClick?: (e) => void;
  AttributesCmp?: React.FC<IExtendedCastCrewAttributesProps | ICastCrewListItemAttributesProps>;
}

export const CastCrewDataSectionListItem: React.FC<ICastCrewDataSectionListItemProps> = ({
  cast,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
  AttributesCmp = CastCrewListItemAttributes,
}) => {
  const { name, preview_image, id, kind, cast_crew_id } = cast;
  const detailsPageLink = `${Routes.CAST_CREW}/${cast_crew_id || id}`;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div className="entity-list-item-row">
      <div className="entity-list-item-row__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
      </div>
      <div className="entity-list-item-row__thumb">
        <Thumbnail
          fallbackIconName={IconAlias.CAST_CREW}
          image={preview_image?.url}
          placeholder={isEntityIngesting(cast) && <SmallLoader size={30} />}
          showType={Boolean(kind)}
          customTag={kind}
        />
      </div>
      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={name}>
          <Link to={detailsPageLink}>
            <h4 className="text-nowrap text-truncate">{name}</h4>
          </Link>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <AttributesCmp cast={cast} />
      </div>
    </div>
  );
};
