import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import DateElement from 'components/date-list-element/date-list-element';
import { ISelectionDataSectionWrappedComponent } from 'components/selection/default-selection-list-item/types';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { getEntityType, pluralWordInflect, getEntityLabel } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { loadAssetGroupItems } from 'utils/apis/groups';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import useSelectedOption from 'utils/hooks/selected-option';
import { Classes } from 'utils/ui';

import '../style.scss';

export const AssetSelectionDataSectionListItem: React.FC<ISelectionDataSectionWrappedComponent> = ({
  selection,
  contextMenu,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const [itemChecked, setChecked] = useState(isChecked);
  const { id, name, main_entity_type, entityCount, owner, access_level, created_at } = selection;
  const fetchAssets = useCallback(async () => {
    if (!id) {
      return [];
    }
    const assetClassifications = await loadAssetGroupItems({
      schema: 'id, classification, preview_image',
      group_ids: id,
      per: 4,
    });
    return assetClassifications.map((asset) => ({
      id: asset.id,
      preview_image: asset.preview_image,
      asset_type: getEntityType(asset.classification),
    }));
  }, [id]);
  const [assets] = useRemote(fetchAssets);

  const thumbnailImages = useMemo(
    () =>
      assets && assets.length
        ? assets.map((asset) => ({ image: asset.preview_image?.url, assetType: asset.asset_type }))
        : [
            {
              image: undefined,
              assetType: undefined,
            },
          ],
    [assets],
  );
  const selectedOption = useSelectedOption({ value: access_level });

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('selections-list-item product-selections', {
        'entity-list-item--deleted': isEntityDeleted(selection),
      })}
      onClick={handleClick}
    >
      <div
        className={cx('selections-list-item__checkbox', {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
      >
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="selections-list-item__thumb">
        <MemoizedMultiThumbnail thumbnailImages={thumbnailImages} />
      </div>
      <div className="selections-list-item__content">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-row align-items-center selections-list-item__title" title={name}>
            <Link to={`/asset-selections/${id}`}>
              <h4 className="text-truncate">{name}</h4>
            </Link>
          </div>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className={`selections-list-item__row--assets ${Classes.TEXT_MUTED}`}>
          <div className="text-truncate text-capitalize asset-selections__entity">
            {pluralWordInflect(getEntityLabel(main_entity_type), entityCount)}
          </div>
          <div className="text-truncate asset-selections__owner">{owner}</div>
          <div className="text-truncate asset-selections__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
        </div>
      </div>
    </div>
  );
};
