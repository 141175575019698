import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useProfileStore, useStore } from 'store';
import { Model } from 'helpers/filters/types';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { AssetActionsList } from 'components/action';
import { DataSection } from 'components/data-section';
import { AssetPreviewTab } from 'components/asset/asset-preview-tab';
import { AssetSelectedTabItem } from 'components/asset/asset-selected-tab-item';
import { Pages } from 'utils/actions/types';
import { IAsset, IGroupEntityIdName } from 'types';
import { AssetDataSectionItemWrapper, getDataItemComponent } from 'components/asset/asset-data-section-item';
import { Loading } from 'components/loading';
import { AssetFilters, defaultAssetFilters } from 'components/asset/asset-filters';
import { chipmunk } from 'utils/chipmunk';
import { getEntityIdFromParams } from 'utils/general';
import { useAssetActionsOptions } from 'utils/hooks';
import { assetListSchema } from 'utils/schemas/asset';
import { getAssetModel } from 'utils/asset';
import { ActionsMenu } from 'components/action/utils';

const sidebarTabs = {
  preview: <AssetPreviewTab />,
  filters: <AssetFilters />,
  selectedItemRenderer(asset: IAsset) {
    return <AssetSelectedTabItem asset={asset} />;
  },
};

const page = Pages.LIST;

const MarketingEntityAssetsTab: React.FC<{ entityIdName: IGroupEntityIdName }> = observer(({ entityIdName }) => {
  const params = useParams<{ entityIdName: string }>();
  const groupId = getEntityIdFromParams(params, entityIdName);
  const [assetIds, setAssetIds] = useState<string[] | null>(null);
  const { toastStore } = useStore();

  useEffect(() => {
    chipmunk.run(
      async (chipmunk) => {
        const params = {
          group_ids: groupId,
          sort: 'sequence_number',
          order: 'asc',
          type: 'group/item/asset',
        };

        const result = await chipmunk.unfurl(Model.ASSET_GROUP_ITEMS, 'get', {
          params,
          schema: `asset_id`,
        });
        const filterAssetIds = result?.objects?.map(({ asset_id }) => asset_id.toString());
        setAssetIds(filterAssetIds);
      },
      () => {
        toastStore.error(`Failed to load group assets.`);
      },
    );
  }, [groupId, toastStore]);

  if (!assetIds) {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 justify-content-center">
            <Loading text="Preparing Data" />
          </div>
        </div>
      </div>
    );
  }

  return <MarketingEntityAssetsTabDataSection assetIds={assetIds} />;
});

const MarketingEntityAssetsTabDataSection: React.FC<{ assetIds: string[] }> = observer(({ assetIds }) => {
  const {
    dataSectionStore: { updateStore, checked },
  } = useStore();

  const layout = useProfileStore(({ computed }) => computed.layout);

  const controlDataSectionProps = useMemo(() => {
    const filters: IFilterOption[] = [['id', 'in', assetIds]];
    return {
      schema: assetListSchema,
      model: getAssetModel(),
      defaultFilters: defaultAssetFilters,
      stats: 'classification,status,access_level',
      filters,
    };
  }, [assetIds]);

  const refreshPage = useCallback(async (): Promise<void> => {
    updateStore({ activeTab: DataSectionSidebarTabs.FILTERS, checked: [] });
  }, [updateStore]);

  useControlDataSection(controlDataSectionProps);
  const AssetItemComponent = useMemo(() => getDataItemComponent(layout), [layout]);

  const customOptions = useAssetActionsOptions();
  const handleItemRenderer = useCallback(
    (asset: IAsset): JSX.Element => {
      return (
        <AssetDataSectionItemWrapper
          key={asset.id}
          asset={asset}
          contextMenu={
            <ActionsMenu
              component={AssetActionsList}
              items={[asset]}
              options={{ ...customOptions, context: 'single', onSuccess: refreshPage, page }}
            />
          }
          component={AssetItemComponent}
        />
      );
    },
    [AssetItemComponent, customOptions, refreshPage],
  );

  return (
    <div className="marketing-entity-tab__wrapper">
      <DataSection
        tabs={sidebarTabs}
        itemRenderer={handleItemRenderer}
        contextMenu={
          <ActionsMenu
            component={AssetActionsList}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshPage }}
          />
        }
        layout={layout}
      />
    </div>
  );
});

export default MarketingEntityAssetsTab;
