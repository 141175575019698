import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterCheckbox, FilterDateRange } from 'helpers/filters/fields';
import { EqFilter, FilterActions, IFiltersDefinition, RangeFilter } from 'helpers/filters/types';
import { submitFilters } from 'helpers/filters/helpers';
import { StaticDataProvider } from 'helpers/data-provider/option-data-provider';
import { useStore } from 'store';

import { useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';

export interface ICollectionItemsFilters extends IFiltersDefinition {
  updated_at: RangeFilter;
  created_at: RangeFilter;
  entity_type: EqFilter;
}

const entityTypeOptions = new StaticDataProvider([
  { value: 'product', label: 'product' },
  { value: 'list/collection', label: 'Sub-Collection' },
  { value: 'separator', label: 'separator' },
]);

const handleSubmit = (action: FilterActions): void => submitFilters({ action });

export const CollectionItemsFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const { searchStore } = dataSectionStore || {};
  const [filterValues, filterHandlers] = useFilters<ICollectionItemsFilters>(dataSectionStore, handleSubmit);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterCheckbox
            label="Type"
            name="entity_type"
            optionsProvider={entityTypeOptions}
            filter={filterValues.entity_type}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues('entity_type')}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />
        </form>
      </div>
    </div>
  );
});
