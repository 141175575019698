import React from 'react';
import { Navigate, RouteProps, Outlet } from 'react-router';
import { Routes } from 'utils/routes';
import { useLocation } from 'react-router-dom';
import { useSessionStore } from 'store/session-store';

export const PublicOnlyRoutes: React.FC<RouteProps> = () => {
  const session = useSessionStore((state) => state.session);
  const location = useLocation();

  if (session && location.pathname !== Routes.DASHBOARD) {
    return <Navigate to={Routes.DASHBOARD} state={{ from: location }} />;
  }

  return <Outlet />;
};
