import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Timeline } from 'components/timeline';
import { Loading } from 'components/loading';
import { useFetchPaginatedData } from 'utils/hooks';
import { ITimelineData } from 'types';
import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { fetchTimelineData } from './api';
import './style.scss';

const ContactTimelineTab: React.FC = observer(() => {
  const { contactId = '' } = useParams<{ contactId: string }>();

  const fetchData = useCallback(
    async (page) => {
      return await fetchTimelineData({ page, contactId });
    },
    [contactId],
  );

  const { handleLoadMore, loading, data: timeline, currentPage } = useFetchPaginatedData<ITimelineData>(fetchData);

  if (loading && currentPage === 1) {
    return <Loading text="Loading Timeline" />;
  }
  if (!timeline) return null;

  return (
    <div className="contact-timeline-tab h-100">
      <div className="d-flex justify-content-center h-100">
        <div className="contact-timeline-tab__content">
          {!!timeline?.length && <Timeline data={timeline} handleLoadMore={handleLoadMore} />}
          {!timeline?.length && <SectionMessage intent={SectionMessageType.EMPTY} />}
        </div>
        {loading && <Loading text="Loading Timeline" isLoadMore={true} />}
      </div>
    </div>
  );
});

export default ContactTimelineTab;
