import { IJsonSchemaSpec, IJsonSchemaProperty } from '@mediafellows/chipmunk';

export const getMm3Options = (
  data: IJsonSchemaSpec | IJsonSchemaProperty,
  constantName: string,
  separator = '.',
): string[] => {
  if (!constantName || !data) {
    return (data as IJsonSchemaProperty)?.enum || (data as IJsonSchemaProperty)?.items?.enum || [];
  }

  const [firstKey, ...rest] = constantName.split(separator);

  return getMm3Options(data.properties?.[firstKey], rest.join(separator));
};
