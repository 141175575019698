import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormGeoSelect } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IProductLayer } from './utils';

interface IProductEditDistributionProps {
  layerForm: IUseMm3FormReturn<IProductLayer>;
}

export const ProductEditDistribution: React.FC<IProductEditDistributionProps> = ({ layerForm }) => {
  const { formData, handlers } = layerForm;

  return (
    <FormSectionWrapperV2 className="create_product__form" title="Distribution">
      <FormGeoSelect
        name="meta.territories_sold_in"
        key="territories_sold_in"
        label="Territories Sold in"
        {...formData.meta?.territories_sold_in}
        {...handlers}
        large
      />
    </FormSectionWrapperV2>
  );
};

export default ProductEditDistribution;
