import React, { useMemo } from 'react';
import { Button, Flex, Tabs } from '@mantine/core';

import { IMarketingEvent, DetailsPageTabs } from 'types';
import { IUseFormReturn } from 'helpers/form';

import { EventAttendeeList } from 'pages/event-details/event-attendees-tab/event-attendee-emails-list-item';
import { EventDetailsOverviewSidebar } from 'pages/event-details';
import { WithOverview } from 'components/layout';
import { StepGeneralInfo, StepLayout } from 'components/event-create';
import { EventLayout } from 'components/event-layout';
import { SortButton } from 'components/sort-buttons';
import { GroupDetailAssetsTab } from 'components/marketing-entity-detail';
import { GroupDetailProductsTab, GroupTimelineTab } from 'components/marketing-entity-detail';
import { DataSectionControls } from 'components/data-section-controls';
import { EventAnalyticsTab } from 'pages/event-details/event-analytics-tab';
import { EventOverviewTab } from 'pages/event-details/event-overview-tab';
import { EventSubEventsTab } from 'pages/event-details/event-sub-events';
import { AddItemsToEventButton } from 'pages/event-details/tab-buttons';

import { Pages } from 'utils/actions';
import { IActionName, ITitleDividerActionName } from 'utils/actions/types';
import { useManageSliderShowcase } from 'utils/actions/event/manage-slider-showcase';
import { flags } from 'utils/flags';
import { useManageHostsShowcase } from 'utils/actions/event/manage-hosts';
import { MantineIcon } from 'utils/ui/icon';

import { EventDetailTab, EventTabPanel, canAddItemsToTab, isSortableTab, showDataSectionButtons } from './utils';
import { IMarketingEventEditForm } from 'components/event-create/steps';

interface IEventDetailsTabsView {
  event: IMarketingEvent;
  editModeEnabled: boolean;
  form: IUseFormReturn<IMarketingEventEditForm>;
  assets: { classification?: string; type?: string }[];
  currentTabId: DetailsPageTabs;
  onTabChangeHandle: (DetailsPageTabs) => void;
  setEvent: React.Dispatch<React.SetStateAction<IMarketingEvent>>;
  onSuccess: (action?: IActionName) => void;
  isSortModeEnabled: boolean;
  setIsSortModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}
const { showAutonomousShowcaseButtons } = flags;

const options = {
  page: Pages.EVENT_PRODUCTS_TAB,
  groupName: ITitleDividerActionName.EVENT,
  groupParamName: 'eventId',
};

export const EventDetailsTabsView: React.FC<IEventDetailsTabsView> = ({
  event,
  editModeEnabled,
  form,
  assets,
  currentTabId,
  onTabChangeHandle,
  onSuccess,
  setEvent,
  isSortModeEnabled,
  setIsSortModeEnabled,
}) => {
  const overviewTab = useMemo(() => {
    return editModeEnabled ? (
      <StepGeneralInfo form={form} editMode containerClassname="event-detail__tab__content--edit-wrap" />
    ) : (
      <EventOverviewTab event={event} />
    );
  }, [editModeEnabled, form, event]);

  const layoutTab = useMemo(() => {
    return editModeEnabled ? <StepLayout form={form} /> : <EventLayout event={event} />;
  }, [editModeEnabled, form, event]);

  const { subevent, id, subevent_group_ids } = event;
  const manageSliderShowcase = useManageSliderShowcase(event ? [event] : [], { context: 'single' });
  const manageHosts = useManageHostsShowcase(event ? [event] : [], { context: 'single' });

  return (
    <Tabs value={currentTabId} onChange={onTabChangeHandle} className={'event-details__tabs h-100'}>
      <Tabs.List className="d-flex flex-nowrap">
        <EventDetailTab
          value={DetailsPageTabs.OVERVIEW}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <EventDetailTab
          value={DetailsPageTabs.LAYOUT}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <EventDetailTab
          value={DetailsPageTabs.ATTENDEES}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
          hidden={subevent}
        />
        <EventDetailTab
          value={DetailsPageTabs.PRODUCTS}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <EventDetailTab
          value={DetailsPageTabs.ASSETS}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <EventDetailTab
          value={DetailsPageTabs.SUB_EVENTS}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
          hidden={subevent}
        />
        <EventDetailTab
          value={DetailsPageTabs.ANALYTICS}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <EventDetailTab
          value={DetailsPageTabs.TIMELINE}
          activeTab={currentTabId}
          editModeEnabled={editModeEnabled}
          isSortModeEnabled={isSortModeEnabled}
        />
        <Flex gap="xs" className="ms-auto me-4 align-self-center">
          <SortButton
            isSortModeEnabled={isSortModeEnabled}
            setIsSortModeEnabled={setIsSortModeEnabled}
            hidden={!isSortableTab(currentTabId)}
          />
          <SortButton
            setIsSortModeEnabled={setIsSortModeEnabled}
            isSortModeEnabled={isSortModeEnabled}
            hidden={subevent || currentTabId !== DetailsPageTabs.SUB_EVENTS || !subevent_group_ids?.length}
          />
          <Button
            size="xs"
            key={manageSliderShowcase.name}
            leftSection={<MantineIcon icon={manageSliderShowcase.icon} />}
            onClick={manageSliderShowcase.handler}
            hidden={!showAutonomousShowcaseButtons || currentTabId !== DetailsPageTabs.OVERVIEW}
            disabled={manageSliderShowcase.isDisabled}
            loading={manageSliderShowcase.isDisabled}
            variant="primary"
          >
            {manageSliderShowcase.title}
          </Button>

          <Button
            size="xs"
            key={manageHosts.name}
            leftSection={<MantineIcon icon={manageHosts.icon} />}
            onClick={manageHosts.handler}
            hidden={!showAutonomousShowcaseButtons || currentTabId !== DetailsPageTabs.OVERVIEW}
            disabled={manageHosts.isDisabled}
            loading={manageHosts.isDisabled}
            variant="primary"
          >
            {manageHosts.title}
          </Button>

          <AddItemsToEventButton
            disabled={isSortModeEnabled}
            event={event}
            currentTab={currentTabId}
            refreshEvent={onSuccess}
            hidden={!canAddItemsToTab(currentTabId)}
          />

          <DataSectionControls hidden={!showDataSectionButtons(currentTabId)} disabled={isSortModeEnabled} />
        </Flex>
      </Tabs.List>
      <EventTabPanel
        value={DetailsPageTabs.OVERVIEW}
        content={
          <WithOverview overview={<EventDetailsOverviewSidebar event={event} assets={assets} />}>
            {overviewTab}
          </WithOverview>
        }
      />
      <EventTabPanel value={DetailsPageTabs.LAYOUT} content={layoutTab} />
      <EventTabPanel
        value={DetailsPageTabs.ATTENDEES}
        content={
          <EventAttendeeList
            attendees={event}
            isEditModeEnabled={editModeEnabled}
            form={form}
            isActiveUsersAttendanceAllowed={event.allow_registered_user_access as boolean}
          />
        }
      />
      <EventTabPanel
        value={DetailsPageTabs.PRODUCTS}
        content={
          <GroupDetailProductsTab
            isSortModeEnabled={isSortModeEnabled}
            groupId={event.id || 0}
            options={{ ...options, onSuccess }}
            page={Pages.EVENT_PRODUCTS_TAB}
          />
        }
      />
      <EventTabPanel
        value={DetailsPageTabs.ASSETS}
        content={
          <GroupDetailAssetsTab
            isSortModeEnabled={isSortModeEnabled}
            groupId={id}
            actionOptions={{
              groupName: ITitleDividerActionName.EVENT,
              groupParamName: 'eventId',
              onSuccess,
              page: Pages.EVENT_ASSETS_TAB,
            }}
          />
        }
      />

      <EventTabPanel
        value={DetailsPageTabs.SUB_EVENTS}
        content={<EventSubEventsTab isSortModeEnabled={isSortModeEnabled} event={event} setEvent={setEvent} />}
      />
      <EventTabPanel value={DetailsPageTabs.ANALYTICS} content={<EventAnalyticsTab event={event} />} />
      <EventTabPanel value={DetailsPageTabs.TIMELINE} content={<GroupTimelineTab entityIdName={'eventId'} />} />
    </Tabs>
  );
};
