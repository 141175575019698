import { useCallback } from 'react';
import { map } from 'lodash';
import { DetailsPageTabs, IMarketingEvent } from 'types';
import { IUseAction } from 'utils/actions';
import { shouldDisplayEdit as shouldDisplay } from './actions-acl';

import { IMarketingEventActionName } from './types';
import { useNavigate } from 'react-router';

export const useEditAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items) => {
  const navigate = useNavigate();
  const handler = useCallback(() => {
    const item_ids = map(items, 'id');

    navigate(`/events/${item_ids[0]}/${DetailsPageTabs.OVERVIEW}?edit=1`);
  }, [navigate, items]);

  return {
    name: IMarketingEventActionName.EDIT,
    shouldDisplay,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
