import React from 'react';
import cx from 'classnames';
import { Menu } from '@mantine/core';
import { LocationsSort } from 'helpers/filters/types';

export const LocationCustomSortMenu = (
  sort: string,
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): React.ReactElement => {
  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={LocationsSort.UPDATED_AT}
        className={cx({ active: sort === LocationsSort.UPDATED_AT })}
      >
        Date Updated
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={LocationsSort.NAME}
        className={cx({ active: sort === LocationsSort.NAME })}
      >
        Name
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={LocationsSort.LOCATION_TYPE}
        className={cx({ active: sort === LocationsSort.LOCATION_TYPE })}
      >
        Type
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={LocationsSort.STARTS_AT}
        className={cx({ active: sort === LocationsSort.STARTS_AT })}
      >
        Starts at
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={LocationsSort.ENDS_AT}
        className={cx({ active: sort === LocationsSort.ENDS_AT })}
      >
        Ends at
      </Menu.Item>
    </Menu>
  );
};
