import React, { useCallback } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseAction } from 'utils/actions';
import { sendRecommendation } from 'utils/apis/recommendation';
import { pluralEntityInflect } from 'utils/general';
import { IRecommendationActionName } from './types';
import { isRecommendationDraftAndSingleContext as shouldDisplay } from './actions-acl';
import { ToastError } from 'components/toast';
export const useSendAction: IUseAction<McGenericRecommendationProduct, IRecommendationActionName> = (
  items,
  options,
) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entityWithCount } = pluralEntityInflect('Recommendation', items.length);
  const recommendationDraft = items[0];
  const isGLO = recommendationDraft?.generate_links_only;
  const toasterSucess = isGLO ? 'Links generated successfully' : `${entityWithCount} sent successfully`;

  const executeSend = useCallback(async () => {
    try {
      const recommendation = await sendRecommendation(recommendationDraft);
      toastStore.success(toasterSucess);
      return await options?.onSuccess?.(IRecommendationActionName.SEND, recommendation);
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
      await options?.onFailure?.();
    }
  }, [options, recommendationDraft, toastStore, toasterSucess]);

  const confirmTitle = isGLO ? 'Confirm Generate Links' : 'Confirm Send Recommendation';
  const confirmBody = isGLO
    ? 'Are you sure you want to generate recommendation links?'
    : `Are you sure you want to send ${entityWithDemonstrative}?`;
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: confirmTitle,
      body: confirmBody,
      onConfirm: executeSend,
    });
  }, [confirmBody, confirmTitle, dialogStore, executeSend]);

  const title = isGLO ? 'Generate links' : 'Send';

  return {
    name: IRecommendationActionName.SEND,
    shouldDisplay,
    icon: 'send-message',
    title,
    handler,
  };
};
