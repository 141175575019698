import { Pages } from 'utils/actions/types';
import { IShouldDisplayAccessGroupActionFunction } from './types';
import { areAllNotDeleted } from 'utils/is-entity-deleted';

export const shouldDisplayAccessGroup: IShouldDisplayAccessGroupActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.ACCESS_GROUP_DETAILS, Pages.ACCESS_GROUPS_LIST].includes(options?.page)
  );
};
