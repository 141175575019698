import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { addListener, leaveRoom } from '@mediafellows/pigeon2-client';
import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Time } from 'blueprint5-icons';

import { pigeon } from 'utils/pigeon';
import { MantineIcon } from 'utils/ui/icon';
import { formatDate } from 'utils/date';
import DateElement from 'components/date-list-element/date-list-element';
import { DeliveriesGenericConnection, DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { formatConnectionType } from 'components/connection-list-item/utils';
import ProductAssets from 'components/asset/assets-aggregation/product-assets';
import { IAsset, DetailsPageTabs, IGenericMessage } from 'types';

import { DeliveryProgressBar } from 'components/progress-bar';

import './style.scss';
import { Intent } from 'utils/ui/intent';
import { formatFullName } from 'utils/general';

interface IDeliveryAttributeListProps {
  delivery: DeliveriesGenericDeliveryPackage & { assets: Array<IAsset> };
  connection: DeliveriesGenericConnection;
  contextMenu: React.ReactElement;
}

const ScheduledComponent: React.FC<{ scheduled_at?: string }> = ({ scheduled_at }) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover trapFocus opened={opened}>
      <Popover.Target>
        <span onMouseEnter={open} onMouseLeave={close} className="mfx-text-muted">
          <MantineIcon icon={<Time />} size={14} />
        </span>
      </Popover.Target>
      <Popover.Dropdown>
        <div>{formatDate(scheduled_at)}</div>
      </Popover.Dropdown>
    </Popover>
  );
};

export const DeliveryAttributeList: React.FC<IDeliveryAttributeListProps> = observer(
  ({ delivery, connection, contextMenu }) => {
    const { pathname } = useLocation();
    const { id, uuid, name, status, delivered_at, scheduled_at, organization, owner } = delivery || {};
    const detailsPageLink = `${pathname}/${id}`;
    const [percentageDelivered, setPrecentageDelivered] = useState(0);
    const statuses = ['delivered', 'failed', 'draft'];

    useEffect(() => {
      if (status === 'delivering' || status === 'scheduled') {
        pigeon(uuid as string);
      }

      return () => {
        leaveRoom(`delivery-package-${uuid}`);
      };
    }, [pathname, status, uuid]);

    addListener(
      'room.message',
      ({ message, type }: { message: IGenericMessage<IGenericMessage<string | number>>; type: string }) => {
        if (type === 'delivery_package.transfer.update' && message?.delivery_package.uuid === uuid) {
          setPrecentageDelivered(message?.delivery_package?.percentage as number);
        }
      },
    );

    return (
      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={name}>
          <div className="entity-list-item-row__title-icon">
            <ScheduledComponent scheduled_at={scheduled_at} />
            <Link to={`${detailsPageLink}`}>
              <h4 className="text-nowrap text-truncate delivery-name">{name}</h4>
            </Link>
          </div>

          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className="delivery-list-item__attributes mfx-text-muted">
          {(statuses.includes(status as string) || percentageDelivered === 100) && (
            <div className="text-truncate delivery-list-item__status">
              {percentageDelivered === 100 ? 'delivered' : (status as string)}
            </div>
          )}

          {(status === 'delivering' || status === 'scheduled') && percentageDelivered !== 100 && (
            <DeliveryProgressBar
              className="delivery-list-item__delivery-bar delivery-bar"
              variant={Intent.SUCCESS}
              value={percentageDelivered}
            />
          )}
          <div className="text-truncate delivery-list-item__sent">
            <DateElement date={delivered_at} mfxIcon="sent-at" />
          </div>
          <div className="product-list-item-row__shortcut text-nowrap">
            <ProductAssets
              baseUrl={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
              assets={delivery?.assets}
              fallback="—"
            />
          </div>
          <div className="text-truncate delivery-list-item__organization">{organization?.name}</div>
          <div className="text-truncate delivery-list-item__owner">{formatFullName(owner)}</div>
          <div className="text-truncate delivery-list-item__connection-type">
            {formatConnectionType(connection?.type)}
          </div>
        </div>
      </div>
    );
  },
);
