import React from 'react';

import { ICategory } from 'types';
import { useDialogStore } from 'store/hooks';
import { IModalSize } from 'components/dialogs/types';
import { GenreForm, IGenreFormData } from './genre-form';

interface IUseGenreForm {
  title: string;
  genre?: ICategory;
  handleSubmit: (genre: IGenreFormData) => Promise<ICategory | void>;
  parentName?: string;
  parentId?: number;
}
export const useGenreForm = ({ title, genre, handleSubmit, parentName, parentId }: IUseGenreForm): (() => void) => {
  const { openModal } = useDialogStore();

  return () =>
    openModal({
      title,
      size: IModalSize.S,
      body: () => <GenreForm genre={genre} handleSubmit={handleSubmit} parentName={parentName} parentId={parentId} />,
    });
};
