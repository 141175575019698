import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { EventLink } from 'components/event-link';
import DateElement from 'components/date-list-element/date-list-element';
import { ListItemCountLink, placeholder } from 'utils/ui';
import { IMarketingEvent, DetailsPageTabs } from 'types';
import useSelectedOption from 'utils/hooks/selected-option';
import { Routes } from 'utils/routes';
import { IconAlias } from 'icons';
import { getAttendeesCount } from 'utils/event-utils';
import { Classes } from 'utils/ui';

import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';

import './style.scss';

interface IEventDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  event: IMarketingEvent;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick?: (e) => void;
}

export const EventDataSectionListItem: React.FC<IEventDataSectionListItemProps> = ({
  event,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
}) => {
  const {
    id,
    created_at,
    event_starts_at,
    event_ends_at,
    name,
    preview_image,
    pm_statistics_data,
    am_statistics_data,
    updated_at,
    group_ids = [],
    is_timed_event,
    subevent,
    access_level,
  } = event;

  const attendeesCount = useMemo(() => getAttendeesCount(event), [event]);
  const selectedOption = useSelectedOption({ value: access_level });
  const detailsPageLink = `${Routes.EVENTS}/${id}`;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="entity-list-item-row">
      <div
        className={cx('entity-list-item-row__checkbox', {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
      >
        <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
      </div>
      <div className="entity-list-item-row__thumb">
        <Thumbnail
          fallbackIconName={IconAlias.EVENT}
          image={preview_image?.url}
          placeholder={isEntityIngesting(event) && <SmallLoader size={30} />}
        />
      </div>
      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={name}>
          <Link to={detailsPageLink}>
            <h4 className="text-nowrap text-truncate">{name}</h4>
          </Link>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className={`event-list-item__attributes ${Classes.TEXT_MUTED}`}>
          <div className="text-truncate event-list-item__id">
            <EventLink event={event} mode="short" linkClassName="text-truncate" />
          </div>
          {is_timed_event ? (
            <>
              <div className="event-list-item__start text-truncate">
                <DateElement date={event_starts_at} mfxIcon="starts-at" />
              </div>
              <div className="event-list-item__end text-truncate">
                <DateElement date={event_ends_at} mfxIcon="expires-at" customHoverLabel="Ends At" />
              </div>
            </>
          ) : (
            <div className="event-list-item__persistent">Persistent</div>
          )}

          <div className="event-list-item__sub">
            {subevent ? (
              placeholder()
            ) : (
              <ListItemCountLink
                entityCount={group_ids?.length}
                link={`${detailsPageLink}/${DetailsPageTabs.SUB_EVENTS}`}
                linkText="Sub-event"
              />
            )}
          </div>

          <div className="event-list-item__attendees">
            {subevent ? (
              placeholder()
            ) : (
              <ListItemCountLink
                entityCount={attendeesCount}
                link={`${detailsPageLink}/${DetailsPageTabs.ATTENDEES}`}
                linkText="Attendee"
              />
            )}
          </div>
          <div className="event-list-item__products">
            <ListItemCountLink
              entityCount={pm_statistics_data?.count}
              link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
              linkText="Product"
            />
          </div>
          <div className="event-list-item__videos">
            <ListItemCountLink
              entityCount={am_statistics_data?.count}
              link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
              linkText="Asset"
            />
          </div>
          <div className="text-truncate event-list-item__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
          <div className="text-truncate event-list-item__updated">
            <DateElement date={updated_at} mfxIcon="updated-at" />
          </div>
        </div>
      </div>
    </div>
  );
};
