import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { ItemId } from 'types';

export const deleteOrganization = async (organization_ids: ItemId[]): Promise<void> =>
  chipmunk.run(async ({ action }) => {
    await action(Model.ORGANIZATIONS, 'delete', {
      schema: `id`,
      params: { organization_ids },
    });
  });

export const restoreOrganization = async (organization_ids: ItemId[]): Promise<void> =>
  chipmunk.run(async ({ action }) => {
    await action(Model.ORGANIZATIONS, 'restore', {
      schema: `id`,
      params: { organization_ids },
    });
  });
