import { ProducerGenericReportProducer } from '@mediafellows/mm3-types';

import { ReportTypes } from 'types';
import { IValidationResult, getRequiredError } from 'helpers/form/mm3';

export const initialValues: ProducerGenericReportProducer = {
  type: ReportTypes.Producer,
  file_status: 'available',
  name: '',
  date_from: '',
  date_to: '',
  source_url: '',
  producer_organization_id: null,
};

export const customValidator = (
  values: ProducerGenericReportProducer,
  validations: IValidationResult<ProducerGenericReportProducer>,
): IValidationResult<ProducerGenericReportProducer> => {
  if (values.type === ReportTypes.Producer) {
    return {
      ...validations,
      name: {
        ...validations.name,
        ...(values.name ? {} : getRequiredError()),
      },
      producer_organization_id: {
        ...validations.producer_organization_id,
        ...(values.producer_organization_id ? {} : getRequiredError()),
      },
    } as IValidationResult<ProducerGenericReportProducer>;
  }
  return {
    ...validations,
    name: {
      ...validations.name,
      ...(values.name ? {} : getRequiredError()),
    },
  };
};

const pdfExtensions = ['.pdf'];
const xlExtensions = ['.xlsx'];

export const getAllowedExtension = (type: ReportTypes): string[] =>
  [ReportTypes.Producer, ReportTypes.MarketTrends, ReportTypes.MarketTrendsFr].includes(type)
    ? pdfExtensions
    : xlExtensions;
