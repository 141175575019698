import React, { useCallback } from 'react';

import { Page } from 'components/layout';
import { MobileAppSyncWizardContainer } from 'components/mobile-app-sync-wizard';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';

export const UpsertMobileAppSync: React.FC = () => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(`${Routes.MOBILE_APP_SYNC}`);
  }, [navigate]);

  return (
    <Page title="Create Mobile Selection" className="page-component--mobile-selection">
      <MobileAppSyncWizardContainer onSuccess={onSuccess} fitInParent />
    </Page>
  );
};
