import { logger } from 'utils/logger';
import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';

export const invalidateSession = async (): Promise<void> => {
  try {
    await chipmunk.action(Model.SESSION, 'member.delete', {});
  } catch (error) {
    logger.error(error.text);
  }
};

export const getToken = async (power: string): Promise<string> => {
  let result: string | undefined;

  try {
    result = (await chipmunk.action('um.session', 'token', { params: { power } }))?.object?.token?.trim?.();
  } catch (e) {}
  return result || '';
};
