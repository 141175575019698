import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { ISearchFilter, ItemId } from 'types';
import { searchLists } from 'utils/apis/showcase';
import { UmGenericListItem, UmGenericListShowcase } from '@mediafellows/mm3-types';

export const getAllListItems = async (listId?: ItemId | null): Promise<UmGenericListItem[]> => {
  if (!listId) {
    return [];
  }

  return chipmunk.run(async ({ unfurl }) => {
    const { objects } = await unfurl(Model.LIST_ITEMS, 'search', {
      params: { lists_ids: [listId] },
      body: {
        search: {
          filters: [
            ['entity_type', 'eq', 'product'],
            ['list_id', 'eq', listId],
          ],
        },
      },
      schema: 'id, entity_id, entity_type',
    });
    return objects;
  });
};

export const createInitialFilters = (items: UmGenericListItem[]): { product?: ISearchFilter[] } => {
  return {
    product: [['id', 'in', (items || []).map((e) => e.entity_id)]],
  };
};

export const getSliderShowcase = async (
  showcase: Pick<UmGenericListShowcase, 'id'>,
): Promise<UmGenericListShowcase | null | undefined> => {
  const { object } = await searchLists({}, [['purpose', 'eq', `collection_${showcase.id}_slider`]]);
  return object;
};
