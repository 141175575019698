import React from 'react';
import cx from 'classnames';

import './style.scss';

export const Page: React.FC<{
  title: string;
  padded?: boolean;
  className?: string;
  contentClassName?: string;
  fitInParent?: boolean;
}> = ({ title, padded = false, children, className, contentClassName }) => {
  return (
    <>
      <div className={cx('page-component', className)}>
        <h2 className="mt-2 mb-4">{title}</h2>
        <div className={cx('page-component__content', contentClassName, { 'p-4': padded })}>{children}</div>
      </div>
    </>
  );
};
