import cx from 'classnames';

import { Loading } from 'components/loading';
import { EmptySectionMessage } from 'components/section-message';
import React from 'react';
import { IColumn } from './types';

interface IDataTableProps {
  loading?: boolean;
  data: unknown[];
  columns: IColumn[];
  className?: string;
  tableClassName?: string;
  onRowClick?: (e, rowData) => void;
}

export const DataTable: React.FC<IDataTableProps> = ({
  loading,
  data,
  columns,
  className,
  tableClassName,
  onRowClick,
}) => {
  if (loading) {
    return <Loading text="Loading Data" />;
  }
  if (!loading && !data.length) {
    return <EmptySectionMessage />;
  }
  return (
    <div className={cx('list-table-wrapper', className)}>
      {!loading && data.length ? (
        <table className={cx('list-table', tableClassName)}>
          <thead>
            <tr>
              {columns.map(({ title, visibleFromSize, columnClassName }) => (
                <th
                  key={title}
                  className={cx(columnClassName, {
                    [`visible-from-size-${visibleFromSize}`]: visibleFromSize,
                  })}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, idx) => (
              <tr key={idx} onClick={(e) => (onRowClick ? onRowClick(e, row) : '')}>
                {columns.map(({ title, renderer, visibleFromSize, columnClassName }) => (
                  <td
                    key={title}
                    className={cx(columnClassName, {
                      [`visible-from-size-${visibleFromSize}`]: visibleFromSize,
                    })}
                  >
                    {renderer(row)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};
