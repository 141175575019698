import { Model } from 'helpers/filters/types';
import { IFetchTimelineResponse } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { byId } from 'utils/general';

export interface IFetchTimelineParams {
  page: number;
  assetId: string;
}

export function fetchTimelineData({ page, assetId }: IFetchTimelineParams): Promise<IFetchTimelineResponse> {
  return chipmunk.run(async (chipmunk) => {
    const { objects: assets, pagination } = await chipmunk.action('ac.event/asset', 'query_timeline', {
      schema:
        'effective_at, description, user_title, user_id, client_ip, entity_title, other_entity_title, other_entity_type, other_entity_id, diff',
      params: { asset_id: assetId, page },
    });

    const userIds = [...new Set(assets.map(({ user_id }) => user_id))];

    const { objects: users } = await chipmunk.action(Model.CONTACTS, 'search', {
      schema: 'first_name, last_name, preview_image, id',
      params: { ids: userIds },
    });
    const usersById = byId(users);

    const data = assets.map(({ user_id, ...asset }) => ({
      ...asset,
      user: usersById[user_id],
    }));

    return { data, totalPages: pagination?.total_pages };
  });
}
