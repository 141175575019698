export const contextExtension = {
  properties: {
    scheduled_for: {
      required: true,
      validations: [],
    },
    access_level: {
      required: true,
    },
  },
};
