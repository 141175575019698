import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';
import React, { useMemo } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';

import { ProductDataSection } from 'components/product-data-section';
import { IProduct, ISearchFilter } from 'types';
import { Pages } from 'utils/actions';
import { ICustomSortMenuProp } from 'components/data-section';
import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';

import { ProductRelatedSortMenu } from 'pages/product-details/product-related-tab/sort-menu';

import { RecommendationProductsSortTab } from './recommendation-product-sort-tab';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { customSortExecutor } from 'utils/apis/sort';

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const getSortMenu: ICustomSortMenuProp = (sort, handleSort) => {
  return <ProductRelatedSortMenu sort={sort} handleSort={handleSort} />;
};
const executor = (options: IActionOpts): Promise<IResult<IProduct>> =>
  customSortExecutor<IProduct>(options, Model.PRODUCTS);

type IRecommendationProductsProps = {
  recommendation: McGenericRecommendationProduct;
};

export const RecommendationProductsPreviewTab: React.FC<IRecommendationProductsProps> = ({ recommendation }) => {
  const persistentFilters: ISearchFilter[] = useMemo(
    () => [['id', 'in', (recommendation.product_ids || []).filter(Boolean)]],
    [recommendation],
  );

  return (
    <ProductDataSection
      persistentFilters={persistentFilters}
      showParentsOnlyFilter={false}
      page={Pages.RECOMMENDATIONS_DETAILS}
      executor={executor}
      getSortMenu={getSortMenu}
      defaultParams={defaultParams}
      customSubmitFilter={submitFiltersWithSeqNumber}
    />
  );
};

interface IRecommendationProductsTabProps {
  setRecommendation: React.Dispatch<React.SetStateAction<McGenericRecommendationProduct>>;
  recommendation: McGenericRecommendationProduct;
  isSortModeEnabled: boolean;
}

export const RecommendationProductsTab: React.FC<IRecommendationProductsTabProps> = ({
  recommendation,
  isSortModeEnabled,
  setRecommendation,
}) => {
  if (isSortModeEnabled) {
    return <RecommendationProductsSortTab recommendation={recommendation} setRecommendation={setRecommendation} />;
  }
  return <RecommendationProductsPreviewTab recommendation={recommendation} />;
};
