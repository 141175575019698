import React from 'react';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { formatLocationType } from 'components/locations-data-section/utils';
import { Classes } from 'utils/ui';

import './style.scss';

interface IConferenceItem {
  location: CalGenericLocation;
}

export const LocationItem: React.FC<IConferenceItem> = ({ location }) => {
  const { name, id, meta } = location;

  return (
    <div key={id} className="location-selected-tab-item__content">
      <div className={`${Classes.TEXT_MUTED}`}>{name}</div>
      <div className="text-truncate">{formatLocationType(meta?.location_type as string)}</div>
    </div>
  );
};
