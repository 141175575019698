export const basketSchema = `
  id,
  name,
  products_count,
  created_at,
  updated_at,
  deleted_at,
  owner_id,
  owner {
    first_name,
    last_name
  }
`;
