import React, { useCallback } from 'react';
import { MultipleAssetsEditModal } from 'components/asset/asset-edit-form';
import { IAsset, IMm3Asset } from 'types';
import { useAssetsEditStore, useDialogStore } from 'store/hooks';

import { shouldDisplayUpdate as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { getValidIds } from 'utils/general';
import { getAssets, saveAssets } from 'utils/apis/asset';
import { IModalSize } from 'components/dialogs/types';
import MultipleAssetEditHeader from 'components/asset/asset-edit-form/components/multiple-asset-edit-header';
import SelectAssetPreset from 'components/asset/asset-edit-form/components/select-preset-modal';

export const useUpdateAction = (items: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const { setAssetIds } = useAssetsEditStore();
  const { openModal } = useDialogStore();

  const openPresetSelector = useCallback(() => {
    openModal({
      title: 'Asset(s) Preset',
      size: IModalSize.L,
      fitInView: true,
      body: () => <SelectAssetPreset />,
    });
  }, [openModal]);

  return {
    name: IAssetActionName.UPDATE,
    shouldDisplay,
    icon: 'edit',
    title: 'Edit',
    handler: (): void => {
      setAssetIds(getValidIds(items));
      openModal({
        title: 'Edit Asset(s)',
        size: IModalSize.XL,
        fitInView: true,
        header: () => <MultipleAssetEditHeader openPresetModal={openPresetSelector} />,
        body: () => <MultipleAssetsEditModal getAssets={getAssets} saveAssets={saveAssets} />,
      });
    },
  };
};
