import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { formatDuration } from 'utils/date';
import { UserAgent, AnalyticsContact, AnalyticsOrganization, AnalyticsContext, UserTerritory } from 'utils/ui';

import DateElement from 'components/date-list-element/date-list-element';
import { IAnalyticsTableItem } from 'types/analytics';

export const AssetColumns: {
  title: string;
  renderer: (data: IAnalyticsTableItem) => React.ReactNode;
  visibleFromSize?: number;
}[] = [
  {
    title: 'Contact',
    renderer: ({ user }) => <AnalyticsContact user={user} />,
  },
  {
    title: 'Organization',
    renderer: ({ user, organization }) => <AnalyticsOrganization user={user} organization={organization} />,
  },
  {
    title: 'Country (IP Country)',
    renderer: ({ user, geoip_country }) => {
      return <UserTerritory countryId={user.country_id} geoCountry={geoip_country as string} />;
    },
  },
  { title: 'Video duration', renderer: ({ asset }) => formatDuration(asset?.duration, 'seconds') },
  { title: 'Screening duration', renderer: ({ total_duration }) => formatDuration(total_duration, 'seconds') },
  {
    title: 'Screening context',
    renderer: ({ group }) => <AnalyticsContext group={group} />,
  },
  { title: 'Device info', renderer: ({ user_agent }) => <UserAgent user_agent={user_agent} /> },
  {
    title: 'Screened at',
    renderer: ({ last_occured_at }) => <DateElement date={last_occured_at} />,
  },
  {
    title: 'Responsible Contact',
    renderer: ({ responsible_user: { id, full_name, email } }) => (
      <span className="video-screenings-list-table__responsible-contact">
        {id !== '0' ? (
          <Link
            className="video-screenings-list-table__responsible-contact-name text-truncate"
            to={`${Routes.CONTACTS}/${id}`}
          >
            {full_name || email || 'N/A'}
          </Link>
        ) : (
          <span className="video-screenings-list-table__responsible-contact-name text-truncate">
            {email || 'Not assigned'}
          </span>
        )}
      </span>
    ),
    visibleFromSize: 7,
  },
];
