import React, { SetStateAction, Dispatch } from 'react';

import { IConference } from 'types';
import ConferenceContactsPreviewTab from './conference-contacts-tab-preview';

interface IConferenceContactsTabProps {
  conference: IConference;
  setConference: Dispatch<SetStateAction<IConference>>;
}

const ConferenceContactsTab: React.FC<IConferenceContactsTabProps> = ({ conference, setConference }) => {
  return <ConferenceContactsPreviewTab conference={conference} setConference={setConference} />;
};

export default ConferenceContactsTab;
