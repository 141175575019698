import React, { FC, useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { IconName } from 'blueprint5-icons';
import { Card } from '@mantine/core';

import LoadingBoundary from 'components/loading-boundary/loading-boundary';
import { Loading } from 'components/loading';
import { LinkWithArrow } from 'utils/ui';
import { MantineIcon } from 'utils/ui/icon';

import { IUseItemData } from 'types/analytics';

import './style.scss';
import { useDataSectionStore } from 'store/hooks';

interface IStatisticCardProps {
  title: string;
  icon: IconName;
  data?: IUseItemData;
  isLoading: boolean;
  path: string;
  param: string;
  formatLabel?: (key: string) => string;
  formatKeys?: (key: string) => string;
  classNames?: string;
}
export const StatisticCard: FC<IStatisticCardProps> = ({
  title,
  icon,
  classNames,
  data,
  isLoading,
  path,
  param,
  formatKeys,
  formatLabel,
}): JSX.Element => {
  const [{ difference, total }, setState] = useState({ difference: 0, total: 0 });

  useEffect(() => {
    let updateState = setState;
    const newState = Object.values(data?.items || {}).reduce(
      ({ difference, total }, [thisWeek, lastWeek, allTime]) => {
        return {
          difference: difference + thisWeek + lastWeek,
          total: total + allTime,
        };
      },
      { difference: 0, total: 0 },
    );
    updateState(newState);
    return () => {
      updateState = () => undefined;
    };
  }, [data?.items]);
  const isPositiveDiff = difference > 0;
  const percentage = difference && total ? (difference * 100) / total : 0;
  const userLocale = navigator.language || 'en-US';

  const formattedPercentage = new Intl.NumberFormat(userLocale, { maximumFractionDigits: 2 }).format(percentage);

  const { clearOldFilters } = useDataSectionStore();
  const onClick = useCallback(() => {
    clearOldFilters(path);
  }, [path, clearOldFilters]);

  return (
    <Card className={cx(classNames, 'statistics-card-container')} withBorder radius="md">
      <LoadingBoundary loading={isLoading} suspender={<Loading text={'Loading...'} />}>
        <Card.Section className="statistics-card-header__wrapper">
          <span className="statistics-card-header__title">
            <MantineIcon icon={icon} size={12} /> {title.toUpperCase()}
          </span>
          <div className="statistics-card-header__stats-wrapper">
            <span className="statistics-card-header__total">{total?.toLocaleString()}</span>
            <span
              className={cx('statistics-card-header__difference', {
                'statistics-card-header__difference--positive': isPositiveDiff,
                'statistics-card-header__difference--negative': !isPositiveDiff,
              })}
            >
              {`${isPositiveDiff ? '+' : ''}${formattedPercentage}%`}
            </span>
          </div>
        </Card.Section>
        <Card.Section className="statistics-card-content__wrapper">
          <div className="statistics-card-content-item" key="header">
            <div></div>
            <div className="statistics-card-header">Last Week</div>
            <div className="statistics-card-header">This Week</div>
            <div className="statistics-card-header ps-3">All time</div>
          </div>
          {Object.entries(data?.items || {})?.map(([key, [v1, v2, v3]]) => (
            <div className="statistics-card-content-item" key={key}>
              <div className="statistics-card-content-item__label">{formatLabel?.(key) || key}</div>
              <Link
                onClick={onClick}
                to={`${path}?${param}=${formatKeys?.(key) || key}&created_at=last-week`}
                className="statistics-card-content-item__value"
              >
                {v1.toLocaleString()}
              </Link>
              <Link
                onClick={onClick}
                to={`${path}?${param}=${formatKeys?.(key) || key}&created_at=this-week`}
                className="statistics-card-content-item__value"
              >
                {v2.toLocaleString()}
              </Link>
              <Link
                onClick={onClick}
                to={`${path}?${param}=${formatKeys?.(key) || key}`}
                className="statistics-card-content-item__value"
              >
                {v3.toLocaleString()}
              </Link>
            </div>
          ))}
        </Card.Section>
        <Card.Section className="statistics-card-viewAll">
          <LinkWithArrow to={path} text="View All" />
        </Card.Section>
      </LoadingBoundary>
    </Card>
  );
};
