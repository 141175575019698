import React from 'react';
import { PmItvstudiosProduct3MotionPictureFormat } from '@mediafellows/mm3-types';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormHtmlInput, FormInput, FormNumericInput, IFieldValidationResults } from 'helpers/form';
import { IProductEditFormProps } from './utils';

import { Product3Types } from 'types/product';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export const ProductEditMarketScreeningsSection: React.FC<IProductEditFormProps> = ({ layerForm, productForm }) => {
  const { formData, handlers } = layerForm;
  const isEditing = Boolean(productForm.values?.id);
  const productType = productForm.formData.type.value;

  return (
    <FormSectionWrapperV2 className="product-create_html-editors-container" title="Fabric">
      <FormNumericInput
        name="meta.release_year"
        key="release_year"
        label="Release Year"
        {...formData.meta?.release_year}
        {...handlers}
        disabled={isEditing}
        validation={{
          ...(formData.meta?.release_year?.validation as IFieldValidationResults),
          errorMessage: 'Must be betweeen 1896 and 2100',
        }}
      />
      <FormInput
        className="sales_synopsis"
        name="meta.sales_synopsis"
        key="sales_synopsis"
        label="Sales Synopsis - extras"
        type="textarea"
        {...formData.meta?.sales_synopsis}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        className="press_reviews"
        name="meta.press_reviews"
        key="press_reviews"
        label="Press Reviews"
        {...formData.meta?.press_reviews}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        className="press_quotes"
        name="meta.press_quotes"
        key="press_quotes"
        label="Press Quotes"
        {...formData.meta?.press_quotes}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        name="meta.awards"
        key="awards"
        label="Awards"
        {...formData.meta?.awards}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.ratings"
        key="ratings"
        label="Ratings"
        {...formData.meta?.ratings}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.cast_and_crew"
        key="cast_and_crew"
        label="Cast & Crew"
        textarea
        {...formData.meta?.cast_and_crew}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.aspect_ratio"
        key="aspect_ratio"
        label="Aspect Ratio"
        {...formData.meta?.aspect_ratio}
        {...handlers}
        disabled={isEditing}
      />
      <FormHtmlInput
        className="series_and_slot_times"
        name="meta.series_and_slot_times"
        key="series_and_slot_times"
        label="Series & Slot Times"
        {...formData.meta?.series_and_slot_times}
        {...handlers}
        large
        disabled={isEditing}
      />
      <FormHtmlInput
        className="tx_summary"
        name="meta.tx_summary"
        key="tx_summary"
        label="TX Summary"
        {...formData.meta?.tx_summary}
        {...handlers}
        large
        disabled={isEditing}
      />
      {productType === Product3Types.FORMAT ? (
        <FormHtmlInput
          className="format_dna"
          name="meta.format_dna"
          key="format_dna"
          label="Format DNA"
          {...(productForm as IUseMm3FormReturn<PmItvstudiosProduct3MotionPictureFormat>).formData.meta?.format_dna}
          {...productForm.handlers}
          large
          disabled={isEditing}
        />
      ) : (
        <></>
      )}
      <FormInput
        name="meta.fabric_id"
        key="fabric_id"
        label="Fabric ID"
        {...productForm.formData.meta?.fabric_id}
        {...productForm.handlers}
        disabled={isEditing}
      />
    </FormSectionWrapperV2>
  );
};

export default ProductEditMarketScreeningsSection;
