import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { NumberInput, NumberInputProps, Text, Flex } from '@mantine/core';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { getFieldPlaceholder } from 'helpers/form/fields/helpers';

import './style.scss';

export interface IFormNumericInputProps
  extends Omit<NumberInputProps, 'value' | 'onChange' | 'onBlur'>,
    IFieldData<number | undefined | null>,
    IFieldHandlers<number | undefined> {
  name: string;
  label?: string;
  showPositiveValidation?: boolean;
  formId?: string;
  inline?: boolean;
  helperText?: React.ReactNode;
}

const FormNumericInput: React.FC<IFormNumericInputProps> = (props) => {
  const {
    formId = 'form-id',
    showPositiveValidation,
    label = '',
    name,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    inline = false,
    min,
    max,
    placeholder,
    helperText,
    disabled,
    hideControls = false,
    size = 'md',
    className,
    ...rest
  } = props;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = showPositiveValidation && touched && Boolean(validation?.valid);
  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled,
    defaultPlaceholder: `Type ${label}`,
  });

  const handleChange = useCallback(
    (valueAsNumber: number): void => {
      if (Number.isNaN(valueAsNumber) || !valueAsNumber) {
        onChange?.({ [name]: undefined });
        return;
      }

      if (max && valueAsNumber > max) {
        onChange?.({ [name]: max });
        return;
      }

      if (min && valueAsNumber < min) {
        onChange?.({ [name]: min });
        return;
      }

      onChange?.({ [name]: valueAsNumber });
    },
    [name, onChange, min, max],
  );

  const handleBlur = useCallback((): void => {
    onBlur?.(name);
  }, [name, onBlur]);

  const rightElementComponent = useMemo(() => {
    if (showValid || showError) return validation?.errorMessage;
  }, [showValid, showError, validation]);

  return (
    <Flex direction="column" m="0 0 15px">
      <NumberInput
        id={`${formId}-${name}`}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value as number}
        className={cx('form-numeric-input', { 'form-numeric-input-inline__container': inline }, className)}
        variant="filled"
        label={label}
        withAsterisk={required}
        error={rightElementComponent}
        min={min}
        max={max}
        placeholder={formattedPlaceholder}
        disabled={disabled}
        hideControls={hideControls}
        size={size}
        {...rest}
      />
      <Text size="xs" c={'var(--mfx-muted-text)'} hidden={!helperText} component="span">
        {helperText}
      </Text>
    </Flex>
  );
};

export default FormNumericInput;
