import React, { useCallback } from 'react';
import { useStore } from 'store';

import { AmGenericNews } from '@mediafellows/mm3-types';

import { ToastError } from 'components/toast';

import { INewsActionConfig, INewsActionName } from 'utils/actions/news/types';
import { deleteNews } from 'utils/apis/news';
import { pluralEntityInflect } from 'utils/general';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { Intent } from 'utils/ui';

export const useDeleteAction = (items: AmGenericNews[], options: IUseActionsOptionParam): INewsActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithCount, entityWithDemonstrative } = pluralEntityInflect('News item', items.length);

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }
      await deleteNews(items?.reduce((acc, e) => (e?.id ? [e.id, ...acc] : acc), []));
      toastStore.success(`${entityWithCount} deleted!`);
      await options?.onSuccess?.(INewsActionName.DELETE);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  }, [entityWithCount, items, options, toastStore]);

  const handler = useCallback((): void => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm,
    });
  }, [dialogStore, entityWithDemonstrative, onConfirm]);

  return {
    name: INewsActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
