import React from 'react';

import { IUseFormReturn, FieldList, PhoneField, FormInput } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IContact } from 'types';
import { ContactAddressSection } from 'components/contact-form';
import { SocialMediaProfileItems } from 'helpers/form/custom-fields/social-media-profile-fields';

const phoneKeys = ['label', 'number'];
const socialMediaProfileKeys = ['label', 'link'];

const ContactInformationSection: React.FC<IUseFormReturn<IContact>> = (contactForm) => {
  const { formData, handlers, values } = contactForm;
  return (
    <FormSectionWrapperV2
      title="CONTACT INFORMATION"
      gridTemplateColumns="1fr"
      wrapperClassName="py-3"
      className="contact-details__contact-info-section"
      withBorder
    >
      <FormInput name="email" label="Email" {...handlers} {...formData.email} style={{ width: '49%' }} />
      <ContactAddressSection {...contactForm} />

      <FieldList
        label="Phones"
        Fields={PhoneField}
        {...{ handlers, values, formData }}
        prefix="phones"
        keys={phoneKeys}
      />
      <FieldList
        label="Social media profiles"
        Fields={SocialMediaProfileItems}
        {...{ handlers, values, formData }}
        prefix="social_media_profiles"
        keys={socialMediaProfileKeys}
      />
    </FormSectionWrapperV2>
  );
};

export default ContactInformationSection;
