import React, { useCallback, useMemo } from 'react';
import { UmGenericListCollection, UmGenericListItem, UmGenericListShowcase } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { FormSliderShowcase } from 'components/showcase';
import { createListItems, deleteListItems } from 'utils/apis/showcase-item';
import { ISearchFilter } from 'types';
import { IFormMultiSelectOption } from 'helpers/form';
import { createList } from 'utils/apis/showcase';
import { ToastError } from 'components/toast';
import { IUseActionsOptionParam } from 'utils/actions';
import { useRemote } from 'utils/hooks';
import { IModalSize } from 'components/dialogs/types';

import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { shouldDisplayForCollectionOneItem } from './acl';
import { createInitialFilters, getAllListItems, getSliderShowcase } from './apis';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';

interface ISliderFormProps {
  collection: UmGenericListCollection;
  sliderShowcase?: UmGenericListShowcase | null;
  onSubmit: (items: IFormMultiSelectOption[]) => Promise<void>;
  options: IUseActionsOptionParam<UmGenericListCollection>;
}
const allowedItems: ['product'] = ['product'];
const SliderForm: React.FC<ISliderFormProps> = observer(({ onSubmit, sliderShowcase, collection, options }) => {
  const fetchPotentialItems = useCallback(async (): Promise<UmGenericListItem[]> => {
    return getAllListItems(collection.id);
  }, [collection.id]);
  const [listItems = []] = useRemote(fetchPotentialItems);

  const additionalFilters = useMemo<{ product?: ISearchFilter[] }>(() => createInitialFilters(listItems), [listItems]);

  const { toastStore, dialogStore } = useStore();

  const handleSubmit = useCallback(
    async (items: IFormMultiSelectOption[]): Promise<void> => {
      try {
        await onSubmit(items);
        options?.onSuccess?.(IShowcaseActionName.GENERATE_SLIDER);
        toastStore.success(
          `Slider for "${collection.name}" has been ${sliderShowcase?.id ? 'updated' : 'created'} successfully!`,
        );
        dialogStore.close();
      } catch (error) {
        options?.onFailure?.();
        toastStore.error(<ToastError error={error} />);
      }
    },
    [onSubmit, options, toastStore, collection?.name, sliderShowcase?.id, dialogStore],
  );

  return (
    <FormSliderShowcase
      allowed_item_types={allowedItems}
      additionalFilters={additionalFilters}
      sliderId={sliderShowcase?.id}
      onSubmit={handleSubmit}
    />
  );
});

export function useManageSliderShowcase(
  items: UmGenericListCollection[],
  options: IUseActionsOptionParam<UmGenericListCollection>,
): IShowcaseActionConfig {
  const { dialogStore } = useStore();
  const [collection] = items;
  const { id } = collection || {};
  const onCreate = useCallback(
    async (items: IFormMultiSelectOption[]): Promise<void> => {
      const sliderShowcase = await createList({
        meta: { page: 'configuration' },
        purpose: `collection_${id}_slider`,
        name: `collection_${id}_slider`,
        type: 'List::Showcase',
        access_level: 'public',
        allowed_item_types: ['product'],
      });
      await createListItems(items, sliderShowcase?.id);
    },
    [id],
  );

  const onClick = useCallback(async () => {
    if (!collection?.id) {
      return;
    }
    const sliderShowcase = await getSliderShowcase(collection);
    const onUpdate = async (items: IFormMultiSelectOption[]): Promise<void> => {
      const listItems = await getAllListItems(sliderShowcase?.id);
      const itemsIds = listItems.reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
      await deleteListItems(sliderShowcase?.id, itemsIds);
      await createListItems(items, sliderShowcase?.id);
    };

    dialogStore.openModal({
      title: 'Manage Showcase',
      size: IModalSize.M,
      body: () => (
        <SliderForm
          collection={collection}
          onSubmit={sliderShowcase?.id ? onUpdate : onCreate}
          sliderShowcase={sliderShowcase}
          options={options}
        />
      ),
    });
  }, [dialogStore, options, collection, onCreate]);

  const { onSubmit: handler, disabled } = useDisableWhileSubmitting(onClick);

  return {
    name: IShowcaseActionName.GENERATE_SLIDER,
    shouldDisplay: shouldDisplayForCollectionOneItem,
    icon: 'layout-skew-grid',
    title: 'Manage Showcase',
    handler,
    isDisabled: disabled,
  };
}
