import { IFormMultiSelectOption } from 'helpers/form';
import { IListItem } from 'types';
import { IEntitiesToQuery } from 'utils/apis/showcase-item';

export function formatListType(type: string): string {
  if (!type) {
    return '';
  }

  return type.replace('List::', '');
}

export const includesSeparator = (list: IEntitiesToQuery[]): boolean => {
  return Boolean(list?.includes('separator'));
};

export const isSeparator = (item: IListItem): boolean => item?.entity_type === 'separator';

const valueToLabel = {
  product: 'products',
  'list/collection': 'Sub-Collections',
};

export const formatCollectionValueToOption = (value: string): IFormMultiSelectOption => ({
  value,
  id: value,
  label: valueToLabel[value] || value,
});
