import React from 'react';

import { Thumbnail } from 'components/thumbnail';
import { IconAlias } from 'icons/icon-aliases';
import { formatOwnership } from 'utils/general';
import { Classes } from 'utils/ui';
import { IGroup } from 'types';

import './style.scss';

interface IProducerPortalSelectedItemProps {
  screeningRoom: IGroup;
}

export const ScreeningRoomSelectedItem: React.FC<IProducerPortalSelectedItemProps> = ({ screeningRoom }) => {
  const { name, preview_image } = screeningRoom || {};

  return (
    <li className="screening-room-selected-item text-truncate">
      <Thumbnail
        className="selected-tab-item__thumb text-truncate"
        fallbackIconName={IconAlias.SCREENING_ROOM}
        image={preview_image?.url}
      />
      <div className="selected-tab-item__content text-truncate">
        <div className="text-truncate">{name}</div>
        <div className={`text-truncate ${Classes.TEXT_MUTED}`}>{formatOwnership(screeningRoom)}</div>
      </div>
    </li>
  );
};

export default ScreeningRoomSelectedItem;
