import { useEffect } from 'react';

export function useInterval(callback: (id?: NodeJS.Timeout | number) => Promise<void> | void, delay: number): void {
  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = window.setInterval((): void => {
        callback(id);
      }, delay);

      return () => clearInterval(id);
    }
  }, [delay, callback]);
}
