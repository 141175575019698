import React, { useCallback } from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { removeCollectionPreviewImage } from 'utils/apis/showcase';
import { Intent } from 'utils/ui';

import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { ToastError } from 'components/toast';

export const useDeletePreviewAction = (
  items: UmGenericListCollection[],
  options: IUseActionsOptionParam<UmGenericListCollection>,
): IShowcaseActionConfig => {
  const item = items[0];
  const { toastStore, dialogStore } = useStore();

  const onConfirm = useCallback(async (): Promise<void> => {
    try {
      const updatedCollection = await removeCollectionPreviewImage(item);
      toastStore.success('Successfully deleted preview image');
      options?.onSuccess?.(IShowcaseActionName.DELETE_PREVIEW, { ...item, ...updatedCollection });
      dialogStore.close();
    } catch (error) {
      options?.onFailure?.();
      toastStore.error(<ToastError error={error} placeholder={`Something went wrong when deleting preview image`} />);
    }
  }, [dialogStore, item, options, toastStore]);

  const handler = (): void => {
    dialogStore.openConfirm({
      title: `Delete preview image`,
      body: 'Are you sure you want to delete this preview image?',
      onConfirm,
    });
  };

  return {
    name: IShowcaseActionName.DELETE_PREVIEW,
    shouldDisplay: () =>
      options.context === 'single' && item.type === 'List::Collection' && item.preview_image_id !== null,
    icon: 'delete',
    title: 'Delete Preview',
    intent: Intent.DANGER,
    handler,
  };
};
