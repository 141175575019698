import React from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export const CustomFieldsPreview: React.FC<{ collection: UmGenericListCollection }> = ({}) => {
  return <></>;
};

export const CustomFieldsEdit: React.FC<{ collectionForm: IUseMm3FormReturn<UmGenericListCollection> }> = ({}) => {
  return <></>;
};
