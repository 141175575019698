import { queryDataExecutorCache, QueryDataProvider } from 'helpers/data-provider/option-data-provider';
import { IResult } from '@mediafellows/chipmunk';
import { IOrganization, ItemId } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { queryOrganizations } from 'utils/apis/organization';
import { excludeCommonPlatformRolesFilter } from 'utils/apis/role';

export const ownersOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('owners-provider-cache', (q: string) =>
    chipmunk.run(async (chipmunk) => {
      return await chipmunk.action('um.user', 'search', {
        params: { per: 25 },
        body: parseToSearchParams({ q }, [['role.internal_account', 'ne', true], excludeCommonPlatformRolesFilter]),
        schema: 'id, first_name, last_name, email',
      });
    }),
  ),
  (data: IResult) => data.objects.map((l) => ({ value: l.id, label: `${l.first_name} ${l.last_name} <${l.email}>` })),
);

export const getCustomOwnersOptionsProvider = (ownerAggregations: { value: ItemId }[]): QueryDataProvider =>
  new QueryDataProvider(
    queryDataExecutorCache(
      `owners-provider-cache-${ownerAggregations?.map((e) => e.value).join('-')}`,
      (query: string) =>
        chipmunk.run(async (chipmunk) => {
          return await chipmunk.action('um.user', 'search', {
            params: { per: 25 },
            body: parseToSearchParams({ q: query, ids: (ownerAggregations || []).map((e) => e.value) }, [
              ['role.internal_account', 'ne', true],
              excludeCommonPlatformRolesFilter,
            ]),
            schema: 'id, first_name, last_name, email',
          });
        }),
    ),
    (data: IResult) => data.objects.map((l) => ({ value: l.id, label: `${l.first_name} ${l.last_name} <${l.email}>` })),
  );

export const organizationOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('organization-provider-cache', async (q: string) => {
    const objects = await queryOrganizations({ q }, [], 'id, name');
    return { objects, object: objects[0] };
  }),
  ({ objects }: IResult<IOrganization>) => objects.map(({ id = 0, name = '-' }) => ({ value: id, label: name })),
);

export const getCustomOrganizationsOptionsProvider = (
  organizationAggregations: { value: ItemId }[],
): QueryDataProvider =>
  new QueryDataProvider(
    queryDataExecutorCache(
      `organization-provider-cache-${organizationAggregations?.map((e) => e.value).join('-')}`,
      (query: string) =>
        chipmunk.run(async (chipmunk) => {
          return await chipmunk.action('um.organization', 'search', {
            params: { per: 25 },
            body: parseToSearchParams({ q: query, ids: (organizationAggregations || []).map((e) => e.value) }),
            schema: 'id, name',
          });
        }),
    ),
    (data: IResult) => data.objects.map((l) => ({ value: l.id, label: `${l.name}` })),
  );
