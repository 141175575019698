import { IOrganizationFields } from './types';

export const initialValues: IOrganizationFields = {
  id: '',
  name: '',
  access_level: 'company',
  classification: '',
  legal_entity: '',
  language_id: '',
  currency_id: '',
  registration_number: '',
  responsible_user_id: '',
  geo_scope_ids: [],
  tax_number: '',
  withholding_tax_rate: '',
  vat_number: '',
  vat_rate: '',
  email: '',
  urls: [],
  tags: [],
  producer_refs: [],
  logo: '',
  banner: '',
  is_producer: false,
};
