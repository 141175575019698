import React, { useCallback } from 'react';

import { Page } from 'components/layout';
import { AccessGroupWizard } from 'components/access-groups';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';

export const UpsertAccessGroup: React.FC = () => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(`${Routes.ACCESS_GROUPS}`);
  }, [navigate]);

  return (
    <Page title="Create Access Group">
      <AccessGroupWizard onSuccess={onSuccess} fitInParent />
    </Page>
  );
};
