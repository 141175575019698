import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { camelCase, capitalize, values, some, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { DetailsPageTabs, IAnalyticsStateProps, IContact } from 'types';
import { useAnalytics } from 'utils/hooks';

import { AnalyticsInteractionLogin as ContactLogins } from 'components/analytics-interaction-login';
import { AnalyticsInteractionsProductViews as ProductViews } from 'components/analytics-interactions-product-views';
import { AnalyticsInteractionsVideoScreenings as VideoScreenings } from 'components/analytics-interactions-video-screenings';
// import { AnalyticsInteractionsAssetDownloads as AssetDownloads } from 'components/analytics-interactions-asset-downloads';
import {
  AnalyticsInteractionsRecommendationsSent as RecommendationsSent,
  AnalyticsInteractionsRecommendationsReceived as RecommendationsReceived,
  getContactInteractionOptions,
  contactInteractionOptions,
  AnalyticsSectionFilters,
} from 'components/analytics';

import { getProductsAncestry } from 'pages/contact-details/api';
import { IAnalyticsEntityValues } from 'types/analytics';
import { ContactInteractions } from 'components/analytics/utils';
import * as contactQueries from 'components/analytics/queries/contact-queries';

const ContactAnalyticsTab: React.FC<{ contact: IContact }> = observer(({ contact }) => {
  const { state }: { state: IAnalyticsStateProps } = useLocation();
  const [interaction, setInteraction] = useState<ContactInteractions>(
    (capitalize(state?.interaction) as ContactInteractions) || ContactInteractions.SCREENINGS,
  );
  const [toggleStatus, setToggleStatus] = useState(true);

  const getMinDate = useCallback(async () => new Date(contact.created_at), [contact.created_at]);

  const interactionOptions = useMemo(
    () => getContactInteractionOptions(contact?.role) || contactInteractionOptions,
    [contact],
  );

  const {
    minDate,
    dateRange,
    onDateRangeChange,
    onDescendantsChange,
    onProductsSelect,
    productIds,
    includeDescendants,
    resetFilters,
  } = useAnalytics({
    getMinDate,
    initialDateRange: state?.dateRange,
    state,
    entity: IAnalyticsEntityValues.CONTACT,
    tab: DetailsPageTabs.ANALYTICS,
    interaction,
  });

  const onInteractionChange = useCallback(({ interaction }: { interaction: ContactInteractions }) => {
    setInteraction(interaction || ContactInteractions.SCREENINGS);
  }, []);

  useEffect(() => {
    if (isEmpty(productIds)) {
      setToggleStatus(true);
      return;
    }

    const fetchProducts = async (product_ids): Promise<void> => {
      if (product_ids.length > 0) {
        const ancestryInfos = await getProductsAncestry(product_ids);

        const hasDescendants = some(ancestryInfos, (ancestry) => {
          return some(values(ancestry), (val) => Number(val) > 0);
        });

        setToggleStatus(!hasDescendants);
      }
    };

    fetchProducts(productIds);
  }, [productIds]);

  const ContentComponent = useMemo(() => {
    switch (interaction) {
      case ContactInteractions.VIEWS:
        return ProductViews;
      case ContactInteractions.SCREENINGS:
        return VideoScreenings;
      // case 'Asset Downloads':
      //   return AssetDownloads;
      case ContactInteractions.RECOMMENDATIONS_RECEIVED:
        return RecommendationsReceived;
      case ContactInteractions.RECOMMENDATIONS_SENT:
        return RecommendationsSent;
      default:
        return ContactLogins;
    }
  }, [interaction]);

  return (
    <div className="analytics-tab h-100">
      <AnalyticsSectionFilters
        interaction={interaction}
        onInteractionChange={onInteractionChange}
        interactionOptions={interactionOptions}
        productIds={productIds}
        onProductsSelect={onProductsSelect}
        handleDescendantsChange={onDescendantsChange}
        includeDescendants={includeDescendants}
        toggleStatus={toggleStatus}
        dateRange={dateRange}
        minDate={minDate}
        handleDateRangeChange={onDateRangeChange}
        resetFilters={resetFilters}
      />
      <div className="analytics-filters__content-wrapper">
        <div className="analytics-filters__tab-content">
          <ContentComponent
            entity="user"
            entityId={contact.id}
            queries={contactQueries[camelCase(interaction)]}
            dateRange={dateRange as [Date, Date]}
            productIds={productIds}
            includeDescendants={includeDescendants}
          />
        </div>
      </div>
    </div>
  );
});

export default ContactAnalyticsTab;
