import React, { useCallback } from 'react';
import { map } from 'lodash';
import { AmGenericAsset3, DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { useStore } from 'store';

import { IMm3Asset, IProduct } from 'types';
import { fetchGroupAssets } from 'utils/apis/asset';
import { fetchGroupProduct } from 'utils/apis/product';
import { useRemote } from 'utils/hooks';
import { IUseActionsOptionParam } from 'utils/actions';
import { IModalSize } from 'components/dialogs/types';
import { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { IDeliveryActionName } from 'utils/actions/delivery';
import { DeliveryWizardContainer } from './delivery-wizard-container';

interface IUseDeliveryDialogProps {
  delivery?: ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>;
  tab?: number;
  options?: IUseActionsOptionParam<DeliveriesGenericDeliveryPackage>;
  excludeProducts?: boolean;
  groupIds?: number[];
  selectionType?: string;
  itemAction?: boolean;
}

export const useDeliveryDialog = ({
  delivery,
  tab,
  options,
  excludeProducts,
  groupIds,
  selectionType,
  itemAction,
}: IUseDeliveryDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const fetchItems = useCallback(async () => {
    if (!groupIds || !groupIds.length) return [];
    const fetcher = selectionType === 'assets' ? fetchGroupAssets<IMm3Asset> : fetchGroupProduct;
    return fetcher(groupIds);
  }, [groupIds, selectionType]);

  const [result] = useRemote(fetchItems);

  const itemsObj =
    selectionType === 'assets'
      ? { asset_ids: map(result as AmGenericAsset3[], 'id') }
      : { product_ids: map(result as IProduct[], 'id') };

  if (groupIds?.length) {
    delivery = {
      ...delivery,
      ...itemsObj,
    } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>;
  }

  const body = useCallback((): JSX.Element => {
    return (
      <DeliveryWizardContainer
        onFinish={(newValue) => {
          close();
          options?.onSuccess?.(IDeliveryActionName.EDIT, newValue);
        }}
        delivery={delivery}
        tab={tab}
        excludeProducts={excludeProducts}
      />
    );
  }, [delivery, tab, excludeProducts, close, options]);

  const modalTitle = `${itemAction ? 'Create' : 'Edit'} Delivery Package`;
  const openDialog = (): void => {
    openModal({
      title: modalTitle,
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
