import React, { useRef, useEffect, useState } from 'react';
import { omit } from 'lodash';
import { Tooltip, TooltipProps, Text } from '@mantine/core';

interface IMfxTooltipProps extends Omit<TooltipProps, 'children'> {
  children?: React.ReactNode;
}

export const MfxTooltip = (props: IMfxTooltipProps): JSX.Element => {
  const ref = useRef<HTMLParagraphElement>(null);
  const { label, className } = props;
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = ref.current as HTMLParagraphElement;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Tooltip disabled={!isOverflown} {...omit(props, 'className')}>
      <Text component="div" truncate ref={ref} className={className}>
        {label}
      </Text>
    </Tooltip>
  );
};
