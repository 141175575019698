import { chipmunk } from 'utils/chipmunk';

import { Model } from 'helpers/filters/types';
import { IFetchTimelineResponse } from 'types';

interface IFetchTimelineParams {
  page: number;
  organizationId: string;
}

export function fetchTimelineData({ page, organizationId }: IFetchTimelineParams): Promise<IFetchTimelineResponse> {
  return chipmunk.run(async (chipmunk) => {
    const organizations = await chipmunk.action('ac.event/organization', 'query_timeline', {
      schema:
        'effective_at, description, user_title, user_id, client_ip, entity_title, other_entity_title, other_entity_type, other_entity_id, diff',
      params: { organization_id: organizationId, page },
    });

    const userId = [...new Set(organizations.objects.map((organizations) => organizations.user_id))];

    const users = (
      await chipmunk.action(Model.CONTACTS, 'search', {
        schema: 'first_name, last_name, preview_image, id',
        params: { ids: userId },
      })
    ).objects;

    const data = organizations.objects.map((organization) => {
      const {
        user_id,
        user_title,
        description,
        client_ip,
        entity_title,
        other_entity_title,
        effective_at,
        other_entity_type,
        other_entity_id,
        diff,
      } = organization;
      const user = users.find((user) => user.id === user_id);

      return {
        user_title,
        effective_at,
        description,
        client_ip,
        entity_title,
        other_entity_title,
        other_entity_type,
        other_entity_id,
        diff,
        user,
      };
    });

    return { data, totalPages: organizations.pagination?.total_pages || 0 };
  });
}
