import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { DataSection } from 'components/data-section';
import { AccessPrivilegeDataSectionItem } from 'components/access-privilege-data-section-item';
import { AccessPrivilegeFilters, IAccessPrivilegesFilters } from 'components/access-privilege-filters';
import { AccessPrivilegePreview } from 'components/access-privilege-preview';
import { IAccessPrivilege } from 'types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { accessPrivilegeListSchema } from 'utils/schemas';
import { useDataSectionStore } from 'store';
import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { Pages } from 'utils/actions/types';
import { AccessPrivilegeActions } from 'components/action';
import { IUseActionsOptionParam } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';

export const customAccessPrivilegesParam = {
  include_deleted: false,
  sort: 'updated_at',
};

const groupTypePersistentFilters: IFilterOption[] = [['type', 'eq', 'group/access_privilege']];

const defaultFilters: IAccessPrivilegesFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};

const sidebarTabs = {
  preview: <AccessPrivilegePreview />,
  filters: <AccessPrivilegeFilters />,
  selectedItemRenderer(accessPrivilege: IAccessPrivilege) {
    return <div>{accessPrivilege.name}</div>;
  },
};

interface IAccessPrivilegesDataSectionProps {
  persistentFilters?: IFilterOption[];
  page?: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  persistFilters?: boolean;
}

const AccessPrivilegesDataSection: React.FC<IAccessPrivilegesDataSectionProps> = observer(
  ({ persistentFilters, page = Pages.LIST, actionOptions, persistFilters }) => {
    const accessPrivilegeFilters = useMemo(
      () => [...groupTypePersistentFilters, ...(persistentFilters || [])],
      [persistentFilters],
    );

    useControlDataSection({
      persistFilters,
      schema: accessPrivilegeListSchema,
      model: Model.ACCESS_PRIVILEGES,
      presetSuffix: 'access-privileges',
      filters: accessPrivilegeFilters,
      defaultFilters,
      stats: 'access_level',
      params: customAccessPrivilegesParam,
    });

    const refreshDataSection = useRefreshDataSection();

    const { checked } = useDataSectionStore<IAccessPrivilege>();

    const itemRenderer = useCallback(
      (accessPrivilege): React.ReactElement => {
        return (
          <AccessPrivilegeDataSectionItem
            key={accessPrivilege.id}
            accessPrivilege={accessPrivilege}
            contextMenu={
              <ActionsMenu
                component={AccessPrivilegeActions}
                items={[accessPrivilege]}
                options={{ context: 'single', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
              />
            }
          />
        );
      },
      [actionOptions, page, refreshDataSection],
    );

    return (
      <DataSection
        showListTileViewToggleButton={false}
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={AccessPrivilegeActions}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
          />
        }
      />
    );
  },
);

export default AccessPrivilegesDataSection;
