import { useSessionStore } from 'store/session-store';

export const hasPower = (power: string): boolean => {
  return Boolean(
    useSessionStore
      .getState()
      .session?.powers?.some(({ resource, effect }) => resource === power && effect === 'Allow'),
  );
};

export const recommendationBypassSharingRestrictions = 'mc/recommendation_bypass_sharing_restrictions';
export const amWriteProtectedAttributes = 'am/write_protected_attributes';
