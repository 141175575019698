import React from 'react';
import { Button, Menu, MenuItem } from '@mantine/core';
import { NavLink } from 'react-router-dom';

import { CheckPowers } from 'components/check-power';
import { IconName } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

const DropdownMenu: React.FC<{
  title: string;
  icon: IconName;
  requiredPowers?: string | string[];
  items: { icon: IconName; text: string; link: string }[];
}> = ({ title, icon, items, requiredPowers }) => {
  return (
    <CheckPowers requiredPowers={requiredPowers}>
      <Menu trigger="hover" openDelay={100} closeDelay={400} position="right-start" offset={10}>
        <Menu.Target>
          <Button
            className="sidebar__create-item popover-menu__title ps-3 pe-3"
            leftSection={<MantineIcon icon={icon} className="sidebar__icon" />}
            rightSection={<MantineIcon icon="caret-right" className="sidebar__icon right" />}
          >
            {title}
          </Button>
        </Menu.Target>
        <Menu.Dropdown className="sidebar__create-menu">
          {items.map((item) => (
            <NavLink key={item.link} to={item.link}>
              <MenuItem
                component="li"
                className="sidebar__create-item popover-menu__title ps-2"
                leftSection={<MantineIcon icon={item.icon} />}
              >
                {item.text}
              </MenuItem>
            </NavLink>
          ))}
        </Menu.Dropdown>
      </Menu>
    </CheckPowers>
  );
};

export default DropdownMenu;
