import { IAccessPrivilege } from 'components/access-privilege-grant/types';

export const initialValues: IAccessPrivilege = {
  name: '',
  access_level: 'viewable',
  product_ids: [],
  expires_at: '',
  permit_download: false,
  delegates_access: true,
  contacts: [],
  contact_ids: [],
  organization_ids: [],
  include_descendants: true,
  include_future_descendants: true,
  email_subject: 'Access all areas (well almost!)',
  email_message: '',
};
