import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useSortDataSectionStore } from 'store';

import { assetItemRenderer } from 'components/group-items-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';

import { updateConference } from 'utils/apis/conference';

import { IAsset, IConference } from 'types';

const ConferenceAssetsSortTab: React.FC<{
  conference: IConference;
  assets: IAsset[];
  setConference: Dispatch<SetStateAction<IConference>>;
}> = observer(({ conference, setConference, assets }) => {
  const { initStore } = useSortDataSectionStore<IAsset>();

  const fetcher = useCallback(async () => assets, [assets]);

  const onSave = useCallback(
    async (list) => {
      const sortedAssetsIds = list.map(({ id }) => id);
      const sortedConference = { ...conference, asset_ids: sortedAssetsIds };
      const updatedConference = await updateConference(sortedConference);
      setConference(updatedConference);
    },
    [conference, setConference],
  );
  initStore({ fetcher, onSave });

  return <SortDataSection itemRenderer={assetItemRenderer} />;
});

export default ConferenceAssetsSortTab;
