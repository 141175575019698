import { IconName } from 'blueprint5-icons';
import { IAssetFile } from 'types';
import { useSessionStore } from 'store/session-store';

export const getAssetIconName = ({ type }: IAssetFile): IconName => {
  if (type.startsWith('video')) return 'film';
  if (type.startsWith('image')) return 'media';
  return 'document';
};

export function getWatermarkOptions({ options }: { options?: string[] }): { label: string; value: string }[] {
  const affiliationId = useSessionStore.getState().computed.affiliationId;
  return (options || []).reduce((acc, value) => {
    if (affiliationId && value.startsWith(affiliationId)) {
      return [
        ...acc,
        {
          value,
          label: value
            .replace(affiliationId, '')
            .replaceAll('_', ' ')
            .trim()
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase())),
        },
      ];
    }
    return acc;
  }, []);
}

export function getProtectionLevels({ options }: { options?: string[] }): string[] {
  return (options || []).filter((protection) => protection !== 'drm');
}
