import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Row } from 'components/label-value-pair';

import { formatDate, dateWithoutHours } from 'utils/date';
import { FormCheckbox, IUseFormReturn } from 'helpers/form';
import { formatCountryNames } from 'utils/hooks/use-countries';
import { IRegistrationData } from 'pages/registration';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';

export interface IPreviewFields {
  user_terms_accepted: boolean;
}

export interface IStepOrganizationProps {
  regInProgress: boolean;
  onBack: () => void;
  form: IUseFormReturn<Partial<IRegistrationData>>;
}

export const keysPreview = ['user_terms_accepted'];

const StepPreview: React.FC<IStepOrganizationProps> = observer((props) => {
  const { regInProgress, onBack, form } = props;
  const { formData, handlers, valid, onSubmit, values } = form;
  const [countryName, organizationCountryName] = formatCountryNames([
    values.user_country_id,
    values.organization_legal_address_country_id,
  ]);

  const orgAddressData = [
    values?.organization_legal_address_street,
    `${values?.organization_legal_address_zip_code || ''} ${values?.organization_legal_address_city || ''}`,
    values?.organization_legal_address_state,
    organizationCountryName,
  ];

  const combinedAddress = orgAddressData
    .map((f) => (f || '').trim())
    .filter((f) => f.length > 0)
    .join(', ');

  return (
    <>
      <Card className="registration__preview-card my-4">
        <div className="preview-column">
          <h5>About</h5>

          {values.user_first_name && (
            <div className="preview-row">
              <div className="label">First Name</div>
              <div className="value">{values.user_first_name}</div>
            </div>
          )}
          {values.user_last_name && (
            <div className="preview-row">
              <div className="label">Last Name</div>
              <div className="value">{values.user_last_name}</div>
            </div>
          )}
          {values.user_gender && (
            <div className="preview-row">
              <div className="label">Gender</div>
              <div className="value">{values.user_gender}</div>
            </div>
          )}
          {values.user_date_of_birth && (
            <div className="preview-row">
              <div className="label">Birthday</div>
              <div className="value">{formatDate(new Date(values.user_date_of_birth), dateWithoutHours)}</div>
            </div>
          )}
          {values.user_email && (
            <div className="preview-row">
              <div className="label">Email</div>
              <div className="value">{values.user_email}</div>
            </div>
          )}
          {values.user_phone_0_number && (
            <div className="preview-row">
              <div className="label">Phone Number</div>
              <div className="value">{values.user_phone_0_number}</div>
            </div>
          )}
          {values.user_country_id && (
            <div className="preview-row">
              <div className="label">Country</div>
              <div className="value">{countryName}</div>
            </div>
          )}
        </div>
        <div className="preview-column">
          <h5>Organization</h5>
          {values.organization_name && (
            <div className="preview-row">
              <div className="label">Organization</div>
              <div className="value">{values.organization_name}</div>
            </div>
          )}
          {values.organization_legal_entity && (
            <div className="preview-row">
              <div className="label">Legal Entity</div>
              <div className="value">{values.organization_legal_entity}</div>
            </div>
          )}
          {values.organization_classification && (
            <div className="preview-row">
              <Row
                label="Type"
                value={values.organization_classification}
                labelClassName="label"
                valueClassName="value"
              />
            </div>
          )}
          {values.organization_email && (
            <div className="preview-row">
              <div className="label">Email</div>
              <div className="value">{values.organization_email}</div>
            </div>
          )}
          {values.organization_phone_0_number && (
            <div className="preview-row">
              <div className="label">Phone</div>
              <div className="value">{values.organization_phone_0_number}</div>
            </div>
          )}
          {combinedAddress && (
            <div className="preview-row">
              <div className="label">Address</div>
              <div className="value">{combinedAddress}</div>
            </div>
          )}
        </div>
      </Card>
      <form className="" onSubmit={onSubmit}>
        <FormCheckbox
          name="user_terms_accepted"
          labelElement={
            <>
              I accept the{' '}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>{' '}
              as far as consent is legally required.
            </>
          }
          {...formData.user_terms_accepted}
          {...handlers}
        />
      </form>
      <div className="registration__btngroup">
        <a onClick={onBack}>
          <MantineIcon className="me-1" icon="arrow-left" />
          Back
        </a>
        <Button disabled={!valid} loading={regInProgress} variant={Intent.PRIMARY} type="submit" onClick={onSubmit}>
          Register
        </Button>
      </div>
    </>
  );
});

export default StepPreview;
