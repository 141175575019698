import React from 'react';

import { FormInput, FormSelect } from 'helpers/form';
import { IFormData, IFormHandlers } from 'helpers/form/types';

import { IContactFields } from 'pages/upsert-contact';

interface ISocialMediaProfileItemsProps {
  index: number;
  formData: IFormData<IContactFields>;
  handlers: IFormHandlers<IContactFields>;
}

const SocialMediaProfileItems: React.FC<ISocialMediaProfileItemsProps> = ({ index, formData, handlers }) => (
  <>
    <FormSelect
      name={`social_media_profiles[${index}].label`}
      label="Label"
      large
      {...formData[`social_media_profiles[${index}].label`]}
      {...handlers}
      options={['Blog', 'Facebook', 'LinkedIn', 'Twitter']}
      withinPortal
    />
    <FormInput
      name={`social_media_profiles[${index}].link`}
      label="Link"
      type="text"
      placeholder="Type Profile Link"
      large
      {...formData[`social_media_profiles[${index}].link`]}
      {...handlers}
    />
  </>
);

export default SocialMediaProfileItems;
