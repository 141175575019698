import React from 'react';

import { FormMultiSelect, FormRemoteSelect, useForm } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { IAsset, ItemId } from 'types';
import { queryAssets } from 'utils/apis/asset';
import { Model } from 'helpers/filters/types';

interface IFormData {
  parent_id?: ItemId;
  asset_ids: ItemId[];
}

const customContext = {
  properties: {
    asset_ids: { required: true },
    parent_id: { required: true },
  },
};

const queryParentAssets = (q, id): Promise<IAsset[]> =>
  queryAssets({ q, ids: id ? [id] : undefined }, [['type', 'eq', 'Asset3::Digital::Video']]);

const submitLabels = { confirm: 'Assign' };

export const AssignSubAssetForm: React.FC<{
  parentId?: ItemId;
  assetIds?: ItemId[];
  handleSubmit?: (values: IFormData) => void;
}> = (props) => {
  const { assetIds, parentId, handleSubmit } = props;

  const { formData, handlers, valid, onSubmit } = useForm<IFormData>(
    {
      asset_ids: assetIds || [],
      parent_id: parentId,
    },
    Model.ASSETS,
    handleSubmit,
    customContext,
  );

  return (
    <form onSubmit={onSubmit}>
      <FormRemoteSelect
        fetchOptions={queryParentAssets}
        name="parent_id"
        label="Main Asset"
        large
        {...formData.parent_id}
        {...handlers}
      />

      <FormMultiSelect
        fetchValues={queryAssets}
        name="asset_ids"
        label="Sub-Assets"
        omni
        {...formData.asset_ids}
        {...handlers}
      />
      <FormSubmitSection submitDisabled={!valid} labels={submitLabels} />
    </form>
  );
};
