import { useCallback } from 'react';

import { IConference } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayConferenceActions } from './actions-acl';
import { IConferenceActionConfig, IConferenceActionName } from './types';
import { parseConferenceUrl } from 'utils/conference';
import { useSessionStore } from 'store/session-store';
import { resetConference, startConference } from 'utils/apis/conference';

export const useStatusActions = (items: IConference[], options: IUseActionsOptionParam): IConferenceActionConfig => {
  const {
    toastStore,
    basicStore: { buyerDomain },
  } = useStore();

  const session = useSessionStore((state) => state.session);
  const openConferenceAction = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }

      const [conference] = items;
      const conferenceUrl = parseConferenceUrl(buyerDomain, conference?.code);
      const sessionId = session?.id;
      const startConferenceUrl = [conferenceUrl, sessionId].join('?sid=');
      window.open(startConferenceUrl);

      return options?.onSuccess?.();
    } catch (error) {
      toastStore.error(`Conference open failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, buyerDomain, session, toastStore, options]);

  const startConferenceAction = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }

      const [conference] = items;
      await startConference(conference?.code, conference?.owner_id);

      const conferenceUrl = parseConferenceUrl(buyerDomain, conference?.code);
      const sessionId = session?.id;
      const startConferenceUrl = [conferenceUrl, sessionId].join('?sid=');
      window.open(startConferenceUrl);

      return options?.onSuccess?.(IConferenceActionName.START);
    } catch (error) {
      toastStore.error(`Conference start failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, buyerDomain, session, toastStore, options]);

  const resetConferenceAction = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }

      const [conference] = items;
      const result = await resetConference(conference.code);
      toastStore.success(`Conference reset successfully!`);
      return options?.onSuccess?.(IConferenceActionName.RESET, result);
    } catch (error) {
      toastStore.error(`Conference restart failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, toastStore, options]);

  const statusActionsMap = {
    ready: { label: 'Start', handler: startConferenceAction },
    running: { label: 'Open', handler: openConferenceAction },
    finished: { label: 'Reset', handler: resetConferenceAction },
  };

  const currentConferenceStatus = items[0].status;

  return {
    name: IConferenceActionName.START,
    shouldDisplay: shouldDisplayConferenceActions,
    icon: 'play',
    title: statusActionsMap[currentConferenceStatus]?.label,
    handler: statusActionsMap[currentConferenceStatus]?.handler,
  };
};
