import React, { useCallback, useMemo } from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { getThumbnailImages } from 'components/website-data-section-item/website-data-section-item';

import { fetchListItems } from 'utils/apis/showcase';

import { IListItem } from 'types';
import { useRemote } from 'utils/hooks';
import { formatType } from 'utils/format-type';

interface IShowcaseSearchItem {
  showcase: UmGenericListShowcase;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ShowcaseSearchItem: React.FC<IShowcaseSearchItem> = ({ showcase, handleSelect }) => {
  const { name, id, meta, type, purpose } = showcase;
  const fetchItems = useCallback(async (): Promise<IListItem[]> => {
    if (!id) {
      return [];
    }

    const { objects = {} } = await fetchListItems({
      listIds: [id],
      per: 4,
      page: 1,
    });

    return objects as IListItem[];
  }, [id]);

  const [items] = useRemote(fetchItems, []);

  const thumbnailImages = useMemo(() => getThumbnailImages(items), [items]);
  const description = meta?.description ? ` | ${meta.description}` : '';
  const showcaseInfo = `${purpose} ${description}`;
  return (
    <li className="collection-search-item">
      <div className="searchbox__showcase" data-id={id} data-item-type={formatType(type)} onClick={handleSelect}>
        <div className="py-1">
          <MemoizedMultiThumbnail thumbnailImages={thumbnailImages} />
        </div>
        <div className="searchbox__showcase__text py-1">
          <div className="text-truncate">{name}</div>
          <div className="text-muted text-truncate">
            <span>{showcaseInfo}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

export { ShowcaseSearchItem };
