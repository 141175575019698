import { useCallback } from 'react';

import { ISubtitle } from 'types';
import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';
import { ISubtitleActionConfig, ISubtitleActionName } from './types';

export const useDownloadAction = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): ISubtitleActionConfig => {
  const { toastStore } = useStore();
  const { entity } = pluralEntityInflect('Subtitle', items.length);
  const subtitle = items[0];

  const handler = useCallback(async () => {
    try {
      window.open(subtitle?.file_attachment, '_blank');
      toastStore.success(`${entity} will be downloaded`);
      return;
    } catch (error) {
      toastStore.error(`${entity} download failed: ${error}`);
      await options?.onFailure?.();
    }
  }, [entity, options, subtitle, toastStore]);

  return {
    name: ISubtitleActionName.DOWNLOAD,
    shouldDisplay: () => true,
    icon: 'download',
    title: 'Download',
    handler,
  };
};
