import React, { useCallback } from 'react';
import { MfxIcon } from 'icons';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { generalActionAPI } from './api';
import { shouldDisplayGeneralAction as shouldDisplay } from './actions-acl';
import { IContactActionName, IContactActionConfig } from './types';

const getToasterMessage = (actionName: IContactActionName): string => {
  switch (actionName) {
    case IContactActionName.BLOCK:
    case IContactActionName.UNBLOCK:
    case IContactActionName.REVIEW:
      return `${actionName}ed`;
    case IContactActionName.UNBOUNCE:
    case IContactActionName.DELETE:
    case IContactActionName.RESTORE:
      return `${actionName}d`;
    default:
      return `${actionName}`;
  }
};

export const useContactGeneralActions = (
  contacts: IContact[],
  options: IUseActionsOptionParam,
): IContactActionConfig[] => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('contact', contacts.length);

  const generalAction = useCallback(
    async (action: IContactActionName, checked: IContact[]): Promise<void> => {
      const contactIds = checked.map((contact) => contact.id);
      try {
        await generalActionAPI(action, contactIds);
        toastStore.success(`${entityWithCount} ${getToasterMessage(action)}`);
        await options?.onSuccess?.(action);
      } catch (err) {
        toastStore.error(`${entity} ${action} failed`);
        await options?.onFailure?.();
      }
    },
    [entity, options, toastStore, entityWithCount],
  );

  const directHandler = useCallback(
    (actionName: IContactActionName) => {
      switch (actionName) {
        case IContactActionName.BLOCK:
        case IContactActionName.UNBOUNCE:
        case IContactActionName.UNBLOCK:
        case IContactActionName.RESTORE:
        case IContactActionName.REVIEW:
          generalAction(actionName, contacts);
          break;

        case IContactActionName.DELETE:
          dialogStore.openConfirm({
            title: 'Confirm Delete',
            body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
            onConfirm: () => {
              generalAction(actionName, contacts);
            },
          });
          break;
      }
    },
    [generalAction, contacts, dialogStore, entityWithDemonstrative],
  );

  const handler = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      const action = e.currentTarget.dataset.action;
      directHandler(action as IContactActionName);
    },
    [directHandler],
  );

  const deleteHandler = useCallback(() => {
    directHandler(IContactActionName.DELETE);
  }, [directHandler]);

  return [
    { shouldDisplay, title: 'In Review', handler, icon: 'eye-open', name: IContactActionName.REVIEW },
    { shouldDisplay, title: 'Unbounce', handler, icon: <MfxIcon icon="unbounce" />, name: IContactActionName.UNBOUNCE },
    { shouldDisplay, name: 'divider' },
    { shouldDisplay, title: 'Block', handler, icon: 'blocked-person', name: IContactActionName.BLOCK },
    { shouldDisplay, title: 'Unblock', handler, icon: <MfxIcon icon="unbounce" />, name: IContactActionName.UNBLOCK },
    { shouldDisplay, name: 'divider' },
    { shouldDisplay, title: 'Restore', handler, icon: 'automatic-updates', name: IContactActionName.RESTORE },
    {
      shouldDisplay,
      title: 'Delete',
      handler: deleteHandler,
      icon: 'trash',
      name: IContactActionName.DELETE,
      intent: Intent.DANGER,
    },
  ];
};
