import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from '@mantine/core';
import { ArrowLeft } from 'blueprint5-icons';
import cx from 'classnames';
import uniq from 'lodash/uniq';

import { FormAssetOmniCheckboxWithFilters, FormAllAssetOmniCheckboxWithFilters, IAssetTreeOption } from 'helpers/form';
import { Loading } from 'components/loading';
import { useRemote } from 'utils/hooks';
import { fetchAllProductsDescendants } from 'utils/apis/product';
import { Intent, MantineIcon } from 'utils/ui';
import type { ItemId } from 'types';
import { IFormAssetTreeSelector } from './types';
import {
  deserializeIds,
  fetchProductsWithAssets,
  getAssetIdsFromOptions,
  isMobileAppSyncFormData,
  serializeIds,
} from './utils';

import { IMobileAppSyncFormData } from 'components/mobile-app-sync-wizard';
import { flags } from 'utils/flags';

import './style.scss';

export const FormAssetTreeSelector: React.FC<IFormAssetTreeSelector> = ({
  form: { formData, handlers, values, onSubmit },
  assetType = 'video',
  isEditingProducts,
  showButtons = false,
  allSelected = false,
  showMetadataHover = false,
  goBack,
  className,
  setLoadingAssets,
}) => {
  const loadedDescendants = useRef({});
  const loadedOptions = useRef({});

  const { product_ids, include_descendants, asset_ids } = values;
  const initialAssetIdsRef = useRef(asset_ids);
  // ts check
  const relatedProductIds =
    isMobileAppSyncFormData(values) && flags.showRelatedProductsFeature
      ? (values as IMobileAppSyncFormData)?.relatedProductIds
      : [];

  const serializedProductIds = serializeIds(uniq(product_ids?.concat(relatedProductIds as number[])));

  const fetchProductIds = useCallback(async (): Promise<ItemId[]> => {
    const productIds = deserializeIds(serializedProductIds);
    if (!productIds?.length || isEditingProducts) {
      return [];
    }
    if (!include_descendants) {
      return productIds;
    }
    const key = serializeIds(productIds);
    if (!loadedDescendants.current[key]) {
      const descendants = await fetchAllProductsDescendants(productIds);
      loadedDescendants.current[key] = descendants.map(({ id }) => id);
    }
    const descendantsIds = loadedDescendants.current[key];

    handlers.onChange({ descendants_ids: descendantsIds });

    return uniq([...productIds, ...descendantsIds]);
  }, [serializedProductIds, isEditingProducts, include_descendants, handlers]);

  const fetchAssetOptions = useCallback(async (): Promise<IAssetTreeOption[]> => {
    const parentIds = await fetchProductIds();
    if (!parentIds?.length) {
      return [];
    }

    const key = serializeIds(parentIds);
    if (!loadedOptions.current[key]) {
      // pass video option
      loadedOptions.current[key] = await fetchProductsWithAssets(parentIds, assetType);
    }

    return loadedOptions.current[key];
  }, [fetchProductIds, assetType]);

  const [assetOptions, loading] = useRemote(fetchAssetOptions, []);
  useEffect(() => setLoadingAssets?.(loading), [setLoadingAssets, loading]);

  useEffect(() => {
    const allAssetIds = getAssetIdsFromOptions(assetOptions);
    let selectedAssetIds: string[] = [];
    const assetIds = initialAssetIdsRef.current.map((e) => String(e));
    if (!allSelected && assetIds?.length) {
      selectedAssetIds = allAssetIds.filter((composedId) => assetIds.includes(composedId));
    }

    handlers.onChange({ asset_ids: allSelected ? allAssetIds : selectedAssetIds });
  }, [allSelected, assetOptions, handlers]);

  return (
    <div className={cx('form-asset-tree-selector h-100', className)}>
      {loading && <Loading text="Loading Assets..." />}

      {!loading && assetType === 'all' && (
        <FormAllAssetOmniCheckboxWithFilters
          name="asset_ids"
          {...formData.asset_ids}
          {...handlers}
          options={assetOptions}
          onlyChildrenIds
          className="form-asset-tree-selector__wrapper h-100"
          showMetadataHover={showMetadataHover}
        />
      )}

      {!loading && (assetType === 'video' || assetType === 'recommendation') && (
        <FormAssetOmniCheckboxWithFilters
          name="asset_ids"
          {...formData.asset_ids}
          {...handlers}
          options={assetOptions}
          className="form-asset-tree-selector__wrapper h-100"
          showMetadataHover={showMetadataHover}
        />
      )}

      {showButtons && goBack && (
        <div className="d-flex justify-content-between">
          <Button onClick={goBack} leftSection={<MantineIcon icon={<ArrowLeft />} />}>
            Back
          </Button>
          <Button variant={Intent.PRIMARY} onClick={onSubmit}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
};
