import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTimeout } from '@mantine/hooks';
import { useNavigate } from 'react-router';
import { Button, Title, Text } from '@mantine/core';

import { chipmunk } from 'utils/chipmunk';
import { useStore } from 'store';
import { verify2FACode } from 'store/session-store';

import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { redirectAfterLogin } from 'utils/storage';

import { Footer } from 'components/footer';
import { ToastError } from 'components/toast';

import { Globals } from 'types/index';
import { FormCheckbox, FormInput, useForm } from 'helpers/form';

import { getPurpose } from './utils';
import { Intent } from 'utils/ui';

import './styles.scss';

export interface I2FAFields {
  auth_code: string;
  trusted: boolean;
}

const initialValues: I2FAFields = {
  auth_code: '',
  trusted: false,
};

const TwoFactorAuth = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const store = useStore();
  const { toastStore } = store;
  const purpose = getPurpose();
  const { start } = useTimeout(() => setIsRequestingCode(false), 5000);
  const navigate = useNavigate();

  const requestCode = useCallback(async () => {
    if (!purpose) {
      toastStore.error('Cannot request code - missing purpose');
      return;
    }

    setIsRequestingCode(true);
    await chipmunk.run(async (chipmunk) => {
      await chipmunk.action('um.protection_check', 'create', {
        body: { purpose },
      });

      toastStore.success('Access code requested (via email)');
    });
    start();
  }, [toastStore, purpose, start]);

  useEffect(() => {
    requestCode();
  }, [requestCode]);

  const verifyCode = async (data: I2FAFields): Promise<void> => {
    const { auth_code, trusted } = data;
    const device_id = await getFingerprint();
    setIsLoading(true);

    try {
      await verify2FACode(auth_code, device_id, trusted, purpose);
      redirectAfterLogin(navigate);
      toastStore.clearAll();
    } catch (err) {
      toastStore.error(<ToastError error={err} placeholder="This code is invalid." />);
    }

    setIsLoading(false);
  };

  const { formData, handlers, onSubmit } = useForm<I2FAFields>(initialValues, 'um.protection_check', verifyCode);

  return (
    <div className={`page--mfa-login ${Globals.MODE_DARK_CLASS}`}>
      <div className={`mfa-login`}>
        <div className="login__head">
          <Title order={3}>MFA Authentication</Title>
        </div>
        <Text size="sm" ta="center" className="mx-4">
          For additional security, we have sent a verification code to your email address.
        </Text>

        <div className="login__line" />

        <div className="login__content">
          <form onSubmit={onSubmit}>
            <FormInput
              placeholder="verification code"
              name="auth_code"
              label="Type your verification code here"
              {...formData.auth_code}
              {...handlers}
            />

            <FormCheckbox
              name="trusted"
              label="Trust this device for 30 days"
              {...formData.trusted}
              {...handlers}
              className="py-2"
            />

            <div className="d-flex justify-content-between align-items-center">
              <Button disabled={isLoading} size="md" variant={Intent.PRIMARY} type="submit">
                Verify
              </Button>

              <Button disabled={isRequestingCode} size="md" variant={Intent.PRIMARY} onClick={requestCode}>
                Resend Code
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default TwoFactorAuth;
