import React, { useMemo, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { ProductDataSection } from 'components/product-data-section';
import { IProduct, ISearchFilter } from 'types';
import { Pages } from 'utils/actions/types';
import { IActionName } from 'utils/actions/types';
import { ICustomSortMenuProp } from 'components/data-section';
import { useDataSectionStore } from 'store/hooks';
import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { useDataSectionSort } from 'utils/hooks/control-data-section';

import ProductRelatedSortTab from './product-related-sort-tab';
import { ProductRelatedSortMenu } from './sort-menu';
import { customSortExecutor } from 'utils/apis/sort';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const getSortMenu: ICustomSortMenuProp = (sort, handleSort) => {
  return <ProductRelatedSortMenu sort={sort} handleSort={handleSort} />;
};

const executor = (options: IActionOpts): Promise<IResult<IProduct>> =>
  customSortExecutor<IProduct>(options, Model.PRODUCTS);

const ProductRelatedPreviewTab: React.FC<{ product: IProduct; setProduct: Dispatch<SetStateAction<IProduct>> }> =
  observer(({ product, setProduct }) => {
    const relatedProductsFilter: ISearchFilter[] = useMemo(
      () => [['id', 'in', product.meta?.related_product_ids || []]],
      [product],
    );
    const actionOptions = {
      // eslint-disable-next-line no-unused-vars
      onSuccess: (name: IActionName, product: IProduct): void => setProduct(product),
    };
    const { searchStore, getParams } = useDataSectionStore<IProduct>();
    const { paramsAndFiltersCount = 0 } = searchStore || { paramsAndFiltersCount: 0 };
    const handleSorting = useDataSectionSort();
    const paramsCountRef = useRef(paramsAndFiltersCount);

    useEffect(() => {
      const { sort } = getParams();
      const hasFiltersChanged = paramsCountRef.current !== paramsAndFiltersCount;
      if (paramsAndFiltersCount && sort === ParametronSort.SEQUENCE_NUMBER) {
        handleSorting(ParametronSort.UPDATED_AT, ParametronOrder.DESCENDING);
      } else if (!paramsAndFiltersCount && hasFiltersChanged && sort === ParametronSort.UPDATED_AT) {
        handleSorting(ParametronSort.SEQUENCE_NUMBER, ParametronOrder.ASCENDING);
      }
      paramsCountRef.current = paramsAndFiltersCount;
    }, [paramsAndFiltersCount, getParams, handleSorting]);

    return (
      <ProductDataSection
        persistentFilters={relatedProductsFilter}
        showParentsOnlyFilter={false}
        page={Pages.PRODUCT_RELATED_TAB}
        actionOptions={actionOptions}
        executor={executor}
        getSortMenu={getSortMenu}
        defaultParams={defaultParams}
      />
    );
  });

const ProductRelatedTab: React.FC<{
  isSortModeEnabled: boolean;
  product: IProduct;
  setProduct: Dispatch<SetStateAction<IProduct>>;
}> = observer(({ isSortModeEnabled, product, setProduct }) => {
  if (isSortModeEnabled) {
    return <ProductRelatedSortTab product={product} setProduct={setProduct} />;
  }

  return <ProductRelatedPreviewTab product={product} setProduct={setProduct} />;
});

export default ProductRelatedTab;
