import React from 'react';
import cx from 'classnames';
import { IStyled } from 'types';

import './style.scss';

export interface IInfoBoxProps extends IStyled {
  gridTemplateColumns?: string | null;
  title?: boolean;
  useBackground?: boolean;
}
/** @deprecated use InfoBoxV2 instead*/

const InfoBox: React.FC<IInfoBoxProps> = ({
  children,
  className,
  gridTemplateColumns = 'minmax(130px, 1fr) 3fr',
  title = false,
  useBackground = false,
}) => {
  return (
    <div
      style={{ gridTemplateColumns: gridTemplateColumns ?? undefined }}
      className={cx('info-box', className, {
        'info-box--use-background': useBackground,
        'info-box--with-title': title,
      })}
    >
      {children}
    </div>
  );
};

export default InfoBox;
