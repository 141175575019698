import React, { useCallback } from 'react';
import { map } from 'lodash';

import { IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { shouldDisplayDelete as shouldDisplay } from './actions-acl';

import { deleteProducts } from './api';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { ToastError } from 'components/toast';

export const useDeleteAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithCount, entityWithDemonstrative } = pluralEntityInflect('Product', items.length);

  const executeDelete = useCallback(async () => {
    try {
      await deleteProducts(map(items, 'id'));
      toastStore.success(`${entityWithCount} deleted`);
      await options?.onSuccess?.(IProductActionName.DELETE);
      return true;
    } catch (error) {
      toastStore.error(
        <ToastError error={error} placeholder="Sorry, Something went wrong, Product deletion failed..." />,
      );
      await options?.onFailure?.();
    }
  }, [entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IProductActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
