import { IOrganization } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';

export enum IOrganizationActionName {
  DELETE = 'delete',
  RESTORE = 'restore',
  EDIT = 'edit',
  REMOVE_FROM_GROUP = 'remove-from-group',
  GRANT_ACCESS_PRIVILEGE = 'grant-access-privilege',
}

export type IOrganizationActionConfig = IActionConfig<IOrganization, IOrganizationActionName>;

export type IShouldDisplayOrganizationActionFunction = IShouldDisplayActionFunction<
  IOrganization,
  IOrganizationActionName
>;
