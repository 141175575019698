import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { DataSection } from 'components/data-section';
import { AccessGroupDataSectionItem } from 'components/access-groups-data-section/access-group-data-section-item';
import { AccessGroupsFilters, IAccessGroupsFilters } from 'components/access-groups-data-section/access-groups-filters';
import { AccessGroupPreview } from 'components/access-groups-data-section//access-group-preview';
import { IAccessPrivilege } from 'types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { accessPrivilegeListSchema } from 'utils/schemas';
import { useDataSectionStore } from 'store';
import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { Pages } from 'utils/actions/types';
import { AccessGroupActions } from 'components/action';
import { IUseActionsOptionParam } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';

export const customAccessGroupsParam = {
  include_deleted: false,
  sort: 'updated_at',
};

const groupTypePersistentFilters: IFilterOption[] = [['type', 'eq', 'group/general']];

const defaultFilters: IAccessGroupsFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};

const sidebarTabs = {
  preview: <AccessGroupPreview />,
  filters: <AccessGroupsFilters />,
  selectedItemRenderer(accessPrivilege: IAccessPrivilege) {
    return <div>{accessPrivilege.name}</div>;
  },
};

interface IAccessGroupsDataSectionProps {
  persistentFilters?: IFilterOption[];
  page?: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  persistFilters: boolean;
}

const AccessGroupsDataSection: React.FC<IAccessGroupsDataSectionProps> = observer(
  ({ persistentFilters, page = Pages.LIST, actionOptions, persistFilters }) => {
    const accessPrivilegeFilters = useMemo(
      () => [...groupTypePersistentFilters, ...(persistentFilters || [])],
      [persistentFilters],
    );

    useControlDataSection({
      persistFilters,
      schema: accessPrivilegeListSchema,
      model: Model.ACCESS_GROUPS,
      presetSuffix: 'access-groups',
      filters: accessPrivilegeFilters,
      defaultFilters,
      stats: 'access_level',
    });

    const refreshDataSection = useRefreshDataSection();

    const { checked } = useDataSectionStore<IAccessPrivilege>();

    const itemRenderer = useCallback(
      (accessGroup): React.ReactElement => {
        return (
          <AccessGroupDataSectionItem
            key={accessGroup.id}
            accessGroup={accessGroup}
            contextMenu={
              <ActionsMenu
                component={AccessGroupActions}
                items={[accessGroup]}
                options={{ context: 'single', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
              />
            }
          />
        );
      },
      [actionOptions, page, refreshDataSection],
    );

    return (
      <DataSection
        showListTileViewToggleButton={false}
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={AccessGroupActions}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
          />
        }
      />
    );
  },
);

export default AccessGroupsDataSection;
