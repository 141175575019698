import React from 'react';

import { Page } from 'components/layout';
import { EventCreate, useEventSave } from 'components/event-create';

export const UpsertEventPage: React.FC = () => {
  const submitCallback = useEventSave();

  return (
    <Page title="Create New Event">
      <EventCreate saveEvent={submitCallback} />
    </Page>
  );
};
