import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { handleItemClick } from 'components/data-section';
import { IBasketStandardized } from 'types';
import { IBasketDataSectionWrappedComponent } from './types';
import { BasketDataSectionListItem } from './basket-data-section-list-item';

import './style.scss';

export interface IBasketsListItemProps {
  className?: string;
  basket: IBasketStandardized;
  contextMenu: React.ReactElement;
  component?: React.FC<IBasketDataSectionWrappedComponent>;
}

const BasketListItem: React.FC<IBasketsListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();

  const { className, basket, contextMenu, component: Component = BasketDataSectionListItem } = props;
  const { id: basketId } = basket;

  const isChecked = checked.some((checked) => checked.id === basketId);
  const isActive = active === basketId;

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, basketId);
    },
    [basketId],
  );
  return (
    <div
      className={cx('default-baskets-list-item', className, {
        'entity-list-item--active': isActive,
      })}
      onClick={handleClick}
    >
      <Component
        basket={basket}
        contextMenu={contextMenu}
        isChecked={isChecked || isAllItemsSelected}
        isAllItemsSelected={isAllItemsSelected}
        handleClick={handleClick}
      />
    </div>
  );
});

export default BasketListItem;
