import { PmGenericCastCrew } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { useUploadImageDialog } from 'components/upload-preview-image';

import { uploadCastCrewAvatar } from './api';
import { ICastCrewActionConfig, ICastCrewActionName } from './types';
import { shouldDisplayForSingleItem } from './acl';

export const useUploadPreviewImageAction = (
  items: PmGenericCastCrew[],
  options: IUseActionsOptionParam,
): ICastCrewActionConfig => {
  const item = items?.[0];
  const [openDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      if (file?.url && item?.id) {
        const updatedCastCrew = await uploadCastCrewAvatar(item?.id, file.url, item.preview_image_id);

        setTimeout(() => {
          options?.onSuccess?.(ICastCrewActionName.UPLOAD_PREVIEW_IMAGE, { ...item, ...updatedCastCrew });
        }, 2000);
      } else {
        options?.onFailure?.();
      }
    },
  });

  return {
    name: ICastCrewActionName.UPLOAD_PREVIEW_IMAGE,
    shouldDisplay: shouldDisplayForSingleItem,
    icon: 'upload',
    title: 'Upload avatar',
    handler: openDialog,
  };
};
