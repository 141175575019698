import React from 'react';
import { observer } from 'mobx-react-lite';

import { ContactOverviewTabPreview } from 'components/contact';

import { IContact } from 'types';
import { Pages } from 'utils/actions/types';

import {
  PersonalDataSection,
  PreferencesSection,
  ContactInformationSection,
  BusinessSection,
  SettingsSection,
  SubscriptionsSection,
  NotesSection,
} from './sections';

import './style.scss';

interface IContactOverviewTabProps {
  contact: IContact;
  refresh: VoidFunction;
}

const page = Pages.DETAILS;

const ContactOverviewTab: React.FC<IContactOverviewTabProps> = observer(({ contact, refresh }) => {
  return (
    <div className="contact-general-tab h-100">
      <ContactOverviewTabPreview
        contact={contact}
        options={{
          context: 'single',
          page,
          onSuccess: () => {
            setTimeout(refresh, 2000);
          },
        }}
      />

      <div className="contact-general-tab__infoboxes-wrapper">
        <div className="contact-general-tab__infoboxes">
          <PersonalDataSection {...contact} />
          <ContactInformationSection {...contact} />
          <BusinessSection {...contact} />
          <NotesSection {...contact} />
          <PreferencesSection {...contact} />
          <SettingsSection {...contact} />
          <SubscriptionsSection {...contact} />
        </div>
      </div>
    </div>
  );
});

export default ContactOverviewTab;
