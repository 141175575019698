import React from 'react';

import { LabelValuePair as Row } from 'components/label-value-pair';
import { FormInput } from 'helpers/form';
import { IAssetCustomRowProps } from 'components/asset/asset-edit-form/sections/custom-row';
import { IAsset } from 'types';

type ICustomAssetMetadataEditRowProps = React.FC<IAssetCustomRowProps<IAsset>>;

export const CustomAssetMetadataRow: React.FC<{ asset: IAsset }> = ({ asset: { classification_tag } }) => {
  return <Row label="Classification Tag" value={classification_tag} size="m" />;
};

export const CustomAssetMetadataEditRow: ICustomAssetMetadataEditRowProps = ({ formData, handlers }) => {
  return (
    <Row
      label="Classification Tag"
      value={
        <FormInput
          name="classification_tag"
          placeholder="Type Classification Tag"
          {...formData.classification_tag}
          {...handlers}
        />
      }
      size="m"
    />
  );
};
