import React, { useCallback } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { map, uniq } from 'lodash';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { ISearchFilter } from 'types';
import { EqFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';
import { DeliveryListItem } from 'components/delivery-list-item';
import { DeliveryActionsList } from 'components/action';
import { DeliveryPreviewTab } from 'components/delivery-preview-tab';
import { DeliveryFilters, IDeliveryFilters } from 'components/delivery-filters';
import { ActionsMenu } from 'components/action/utils';
import { Pages } from 'utils/actions/types';
import { IUseActionsOptionParam } from 'utils/actions';
import { IDeliveryActionName } from 'utils/actions/delivery';
import useControlDataSection from 'utils/hooks/control-data-section';
import { useRefreshDataSection } from 'utils/hooks';
import { deliveryPackageSchema } from 'utils/schemas';

import { handleUpdate } from './utils';

import './style.scss';

const defaultFilters: IDeliveryFilters = {
  _: new QFilter(''),
  status: new EqFilter('status', ''),
  deliverd_at: new RangeFilter('deliverd_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  connection_type: new EqFilter('connection_type', ''),
  organization_id: new EqFilter('organization_id', ''),
};

const sidebarTabs = {
  preview: <DeliveryPreviewTab />,
  filters: <DeliveryFilters />,
};

interface IDeliveryDataSectionProps {
  persistentFilters?: ISearchFilter[];
  showParentsOnlyFilter?: boolean;
  parentFilterDefaultValue?: boolean;
  page: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  actionName?: string;
  params?: Record<string, unknown>;
  executor?: (options: IActionOpts) => Promise<IResult<DeliveriesGenericDeliveryPackage>>;
  defaultParams?: IDataSectionDefaultParams;
  headerRightSection?: React.ReactElement;
  persistFilters?: boolean;
}

export const DeliveryDataSection: React.FC<IDeliveryDataSectionProps> = observer((props) => {
  const {
    persistentFilters,
    page = Pages.DELIVERIES,
    actionName = 'search',
    params,
    actionOptions,
    executor,
    defaultParams,
    headerRightSection,
    persistFilters,
  } = props;
  const refreshDataSection = useRefreshDataSection();
  const {
    dataSectionStore: { checked },
  } = useStore();

  useControlDataSection({
    executor,
    schema: deliveryPackageSchema,
    stats: 'status,owner_id,connection_type,organization_id',
    handleUpdate,
    defaultFilters,
    actionName,
    defaultParams,
    params,
    model: Model.DELIVERY_PACKAGE,
    persistFilters,
    filters: persistentFilters,
  });

  const onSuccess = useCallback(
    async (action?: IDeliveryActionName): Promise<void> => {
      if (action) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection],
  );

  const handleItemRenderer = (delivery): React.ReactElement => {
    const asset_ids = uniq(map(delivery.delivery_items, 'asset_id'));
    const product_ids = uniq(map(delivery.delivery_items, 'product_id'));
    const user_ids = map(delivery.recipient_list, 'recipient_id');

    return (
      <DeliveryListItem
        key={delivery.id}
        delivery={delivery}
        contextMenu={
          <ActionsMenu
            component={DeliveryActionsList}
            items={[{ ...delivery, asset_ids, product_ids, recipients: user_ids }]}
            options={{ ...(actionOptions || {}), context: 'single', page, onSuccess }}
          />
        }
      />
    );
  };

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={handleItemRenderer}
      headerRightSection={headerRightSection}
      contextMenu={
        <ActionsMenu
          component={DeliveryActionsList}
          items={checked}
          options={{ ...(actionOptions || {}), context: 'multi', page, onSuccess }}
        />
      }
    />
  );
});

export default DeliveryDataSection;
