import React from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { FormActionSection } from 'components/form-submit-section';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { isValid } from 'helpers/form';
import { IconAlias } from 'icons';
import {
  AccessPrivilegeGrantFormGeneral,
  keysGeneral,
  AccessPrivilegeGrantFormProducts,
  keysProducts,
  AccessPrivilegeGrantFormContacts,
  AccessPrivilegeGrantFormSettings,
  keysSettings,
  AccessPrivilegeGrantFormSummary,
  AccessPrivilegeGrantFormEmail,
} from './';

import { SkipButton, getEmailStepNextButton } from 'components/access-privilege-grant/utils';

import { isValidContactsStep } from './access-privilege-grant-form-contacts';

import { IAccessPrivilegeForm } from './types';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

import { StepIds } from './utils';

interface IAccessPrivilegeGrantWizard {
  form: IAccessPrivilegeForm;
  isCreating: boolean;
  fitInParent?: boolean;
}

export const AccessPrivilegeGrantWizard: React.FC<IAccessPrivilegeGrantWizard> = ({
  form,
  isCreating,
  fitInParent,
}) => {
  const isFinalStepsValid = isValid(form.formData, keysSettings) && isValidContactsStep(form);

  const emailNextButton = getEmailStepNextButton(form);

  const steps = [
    { id: StepIds.General, disabled: !isValid(form.formData, keysGeneral) },
    { id: StepIds.Products, disabled: !isValid(form.formData, keysProducts) },
    { id: StepIds.Contacts, disabled: !isValidContactsStep(form) },
    { id: StepIds.Settings, disabled: !isFinalStepsValid },
    {
      id: StepIds.Email,
      nextButtonTitle: emailNextButton.title,
      disabled: !isFinalStepsValid || emailNextButton.disabled,
    },
    {
      id: StepIds.Summary,
      nextButtonTitle: 'Create',
      action: form.onSubmit,
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  return (
    <Wizard
      fitInParent
      isLoading={isCreating}
      loadingMessage="We are preparing your access privilege now."
      footerPadded={false}
      className={fitInParent ? 'p-3' : undefined}
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      footer={
        <FormActionSection className="d-flex justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            disabled={backButton.hide || isCreating}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <div className="d-flex gap-3">
            <SkipButton form={form} currentStepId={currentStepId} nextButton={nextButton} />

            <Button
              className="m-0"
              variant="primary"
              onClick={nextButton.action}
              disabled={nextButton.disabled || isCreating}
            >
              {nextButton.title}
            </Button>
          </div>
        </FormActionSection>
      }
    >
      <WizardStep
        id={StepIds.General}
        title="General info"
        icon="properties"
        disabled={isStepDisabled(StepIds.General)}
        panel={<AccessPrivilegeGrantFormGeneral form={form} />}
      />
      <WizardStep
        id={StepIds.Products}
        disabled={isStepDisabled(StepIds.Products)}
        title="Products"
        icon={IconAlias.PRODUCT}
        panel={<AccessPrivilegeGrantFormProducts form={form} />}
      />
      <WizardStep
        id={StepIds.Contacts}
        disabled={isStepDisabled(StepIds.Contacts)}
        title="Contacts"
        icon={IconAlias.CONTACT}
        panel={<AccessPrivilegeGrantFormContacts form={form} />}
      />
      <WizardStep
        id={StepIds.Settings}
        disabled={isStepDisabled(StepIds.Settings)}
        title="Settings"
        icon={IconAlias.SETTINGS}
        panel={<AccessPrivilegeGrantFormSettings form={form} />}
      />
      <WizardStep
        id={StepIds.Email}
        disabled={isStepDisabled(StepIds.Email)}
        title="Email"
        icon="envelope"
        panel={<AccessPrivilegeGrantFormEmail form={form} />}
      />
      <WizardStep
        id={StepIds.Summary}
        disabled={isStepDisabled(StepIds.Summary)}
        title="Summary"
        icon="menu"
        panel={<AccessPrivilegeGrantFormSummary form={form} />}
      />
    </Wizard>
  );
};
