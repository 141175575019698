import React from 'react';

import './style.scss';

export const WithOverview: React.FC<{ overview: React.ReactNode }> = ({ overview, children }) => {
  return (
    <div className="with-overview__container">
      <div className="with-overview__overview">{overview}</div>
      <div className="with-overview__content">{children}</div>
    </div>
  );
};
