import React from 'react';

interface IProgressCSSProps extends React.CSSProperties {
  '--progress-width': number;
  '--buffered-width': number;
}

interface IAudioProgressBarProps extends React.ComponentPropsWithoutRef<'input'> {
  duration: number;
  currentProgress: number;
  buffered: number;
}

const AudioProgressBar: React.FC<IAudioProgressBarProps> = (props) => {
  const { duration, currentProgress, buffered, ...rest } = props;

  const progressBarWidth = isNaN(currentProgress / duration) ? 0 : currentProgress / duration;
  const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;

  const progressStyles: IProgressCSSProps = {
    '--progress-width': progressBarWidth,
    '--buffered-width': bufferedWidth,
  };

  return (
    <input
      className="w-100 m-0 mt-3 audio-player__progress-bar"
      type="range"
      name="progress"
      style={progressStyles}
      min={0}
      max={duration}
      value={currentProgress}
      {...rest}
    />
  );
};
export default AudioProgressBar;
