import React, { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';
import { Star, StarEmpty } from 'blueprint5-icons';

import { IAsset, IMm3Asset, IProductAsset } from 'types';
import { IParametronData } from '@mediafellows/parametron';
import { setMarketingAsset } from 'utils/apis/product-asset';
import { useDataSectionStore } from 'store/hooks';
import { Pages } from 'utils/actions/types';

interface IMarketingStartProps {
  asset: Partial<(IAsset | IMm3Asset) & { product_asset?: IProductAsset }>;
  page?: Pages;
  disableMarketingStar?: boolean;
  setProductAssets?: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
}

export const MarketingAssetStar: React.FC<IMarketingStartProps> = ({
  asset,
  page,
  disableMarketingStar,
  setProductAssets,
}) => {
  const { searchStore } = useDataSectionStore<IAsset>();

  const setMarketingUse = useCallback(async () => {
    if (!asset?.product_asset?.id || !searchStore?.update) {
      return;
    }

    const product_asset = await setMarketingAsset(asset.product_asset.id, !asset.product_asset.marketing_use);

    searchStore.update({
      objects: searchStore.objects.map((item) => {
        if (item.id === asset.id) {
          return { ...item, product_asset };
        }
        return item;
      }),
    } as IParametronData);
    if (page === Pages.PRODUCT_ASSETS_TAB) {
      setProductAssets?.((productAssets) => {
        return [...productAssets.filter((productAsset) => product_asset.id !== productAsset.id), product_asset];
      });
    }
  }, [asset, searchStore, page, setProductAssets]);

  if (!asset?.product_asset?.id && page === Pages.PRODUCT_ASSETS_TAB) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon variant="subtle" icon="inheritance" />
      </div>
    );
  }

  if (!asset?.product_asset?.id) {
    return null;
  }

  if (disableMarketingStar) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon icon="star" />
      </div>
    );
  }

  return (
    <ActionIcon variant="subtle" color="gray.5" onClick={setMarketingUse}>
      <MantineIcon icon={asset.product_asset.marketing_use ? <Star /> : <StarEmpty />} />
    </ActionIcon>
  );
};
