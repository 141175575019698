import aspera from 'assets/img/aspera.png';
import s3 from 'assets/img/s3.png';
import sftp from 'assets/img/sftp.png';
import google from 'assets/img/google.png';
import azure from 'assets/img/azure.png';

export const toPascalCase = (s: string): string =>
  s ? s.replace(/(\w)(\w*)/g, (_, p, q) => p.toUpperCase() + q.toLowerCase()).replace(/\s/g, '') : s;

export const formatConnectionType = (type: string): string => {
  if (!type) return '';

  const formatedType = type
    .split('::')[1]
    .replace(/([A-Z])/g, ' $1')
    .trim();
  return formatedType ? formatedType : type;
};

export enum ConnectionStatus {
  active = 'success',
  inactive = 'danger',
}

export enum AvatarType {
  Aspera = aspera,
  S3 = s3,
  Sftp = sftp,
  GoogleCloudStorage = google,
  AzureBlobStorage = azure,
}
