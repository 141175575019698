import React from 'react';
import { Button } from '@mantine/core';

import { IMarketingEvent, DetailsPageTabs } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useAddAssetsAction } from 'utils/actions/event/add-assets-action';
import { useAddEventsAction } from 'utils/actions/event/add-events-action';
import { useAddProductsAction } from 'utils/actions/event/add-products-action';
import { IActionName } from 'utils/actions/types';
import { MantineIcon } from 'utils/ui/icon';

interface IAddItemsToEventButtonProps {
  currentTab: DetailsPageTabs;
  event: IMarketingEvent;
  refreshEvent: (action?: IActionName) => void;
  disabled: boolean;
  hidden: boolean;
}

export const AddItemsToEventButton: React.FC<IAddItemsToEventButtonProps> = ({
  currentTab,
  event,
  refreshEvent,
  disabled,
  hidden,
}) => {
  const options: IUseActionsOptionParam = {
    onSuccess: async (action?: IActionName) => {
      refreshEvent(action);
    },
    context: 'single',
    page: Pages.DETAILS,
  };

  const addProducts = useAddProductsAction([event], options);
  const addAssets = useAddAssetsAction([event], options);
  const addEvents = useAddEventsAction([event], options);

  let action;

  switch (currentTab) {
    case DetailsPageTabs.PRODUCTS:
      action = addProducts;
      break;

    case DetailsPageTabs.ASSETS:
      action = addAssets;
      break;

    case DetailsPageTabs.SUB_EVENTS:
      action = addEvents;
      break;

    default:
      return <></>;
  }
  const { handler, icon, title } = action;

  return (
    <Button
      leftSection={<MantineIcon icon={icon} />}
      disabled={disabled}
      onClick={handler}
      variant="primary"
      hidden={hidden}
      size="xs"
    >
      {title}
    </Button>
  );
};
