import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';

import { getIncludedAttribute } from 'components/product-preview-tab/utils';

import { IAccessPrivilegeFormComponent } from './types';
import { parseDifferentContacts } from 'utils/apis/groups';
import { formatDate } from 'utils/date';

export const AccessPrivilegeGrantFormSummary: React.FC<IAccessPrivilegeFormComponent> = ({ form: { values } }) => {
  const contacts = parseDifferentContacts(values.contacts);

  return (
    <FormSectionWrapperV2 padded={false}>
      <Row label="Name" value={values.name} />
      <Row label="Access level" value={values.access_level} />
      <Row label="Products" value={values.product_ids.length} />
      <Row label="Contacts" value={contacts.users.length + contacts.selections_users_count} />
      <Row label="Organizations" value={values.organization_ids.length} />
      <Row label="Expires at" rawLabel value={formatDate(values.expires_at)} hideIfNoValue />
      <Row label="Unpublished assets" value={getIncludedAttribute(values.delegates_access)} />
      <Row label="Descendant Products" value={getIncludedAttribute(values.include_descendants)} />
      <Row label="Future Descendant Products" value={getIncludedAttribute(values.include_future_descendants)} />
      <Row label="Download permission" value={yn(values.permit_download)} />
      <Row label="Send notification email" value={yn(values.email_subject.length && values.email_message.length)} />
    </FormSectionWrapperV2>
  );
};
