import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import ScrollWrapper from 'components/scroll-wrapper';

import { FormInput } from 'helpers/form';
import { IUseFormReturn } from 'helpers/form/use-form';
import { IAffiliationForm } from 'pages/affiliation/types';

const AffiliationEditEmailSettingsTab: React.FC<{
  affiliationForm: IUseFormReturn<Partial<IAffiliationForm>>;
}> = ({ affiliationForm }) => {
  const { formData, handlers } = affiliationForm || {};

  return (
    <ScrollWrapper>
      <InfoBoxV2 gridTemplateColumns="1fr 1fr" wrapperClassName="py-3" className="text-no-transform ">
        <FormInput
          className="email_cc"
          name="email_cc"
          key="email_cc"
          label="Email Cc"
          {...formData?.email_cc}
          {...handlers}
        />
        <FormInput
          className="email_bcc"
          name="email_bcc"
          key="email_bcc"
          label="Email Bcc"
          {...formData?.email_bcc}
          {...handlers}
        />
        <FormInput
          className="email_reply_to"
          name="email_reply_to"
          key="email_reply_to"
          label="Email Reply To"
          {...formData?.email_reply_to}
          {...handlers}
        />
        <FormInput
          className="contact_email"
          name="contact_email"
          key="contact_email"
          label="Contact Email"
          {...formData?.contact_email}
          {...handlers}
        />
        <FormInput
          className="registration_notification_emails"
          name="registration_notification_emails"
          key="registration_notification_emails"
          label="Registration Notification Emails"
          {...formData?.registration_notification_emails}
          {...handlers}
        />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};

export default AffiliationEditEmailSettingsTab;
