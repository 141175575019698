import React, { useCallback } from 'react';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import AffiliationLoaded from './affiliation-loaded';

import { loadAffiliation } from 'utils/apis/affiliation';
import { useRemote } from 'utils/hooks';
import { useRefreshPreviewForImageIngest } from './utils';

const Affiliation: React.FC = () => {
  const fetcher = useCallback(async () => {
    return loadAffiliation();
  }, []);
  const [affiliation, loading, setAffiliation, refresh] = useRemote(fetcher);
  useRefreshPreviewForImageIngest(setAffiliation, affiliation);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="background-wrapper__index w-100 h-100">
        <DashboardBreadcrumbs className="mb-3" />
        {loading && <Loading text="Loading Affiliation" />}
        {affiliation && !loading && <AffiliationLoaded affiliation={affiliation} refresh={refresh} />}
        {!affiliation && !loading && <SectionMessage intent={SectionMessageType.EMPTY} />}
      </div>
    </div>
  );
};

export default Affiliation;
