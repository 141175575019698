import { useCallback } from 'react';

import { useStore } from 'store';

import { deleteBasket } from 'utils/apis/lists';
import getPathAfterContentDeletion from 'utils/get-path-after-content-deletion';
import { IBasketActionConfig, IBasketActionName } from 'utils/actions/basket/types';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

import { IBasketStandardized } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions/types';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export const useDeleteAction = (items: IBasketStandardized[], options: IUseActionsOptionParam): IBasketActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Basket', items.length);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id);
      await deleteBasket(itemIds[0]); // TODO: BE needs to implement multi deletion
      if (options.page === Pages.BASKET_DETAILS) {
        navigate({
          pathname: getPathAfterContentDeletion(location.pathname, itemIds[0]),
          search: location.search,
        });
      } else {
        options?.onSuccess?.(IBasketActionName.DELETE);
      }

      toastStore.success(`${entityWithCount} deleted`);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, navigate, toastStore, location]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IBasketActionName.DELETE,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
