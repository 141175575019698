import React, { useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

import { FormProduct } from 'helpers/form';
import { queryProducts } from 'utils/apis/product';
import { IProduct, ItemId, ISearchFilter, DetailsPageTabs } from 'types';
import { getParentType } from './utils';
import { tabToType } from 'utils/product';

interface IParentSelectorProps {
  value: number;
  setParent: Dispatch<SetStateAction<number>>;
  activeTab: DetailsPageTabs;
  hidden?: boolean;
}

const ProductDescendantsParentSelector: React.FC<IParentSelectorProps> = ({ value, setParent, activeTab, hidden }) => {
  const { productId } = useParams<{ productId: string }>();
  const onChange = useCallback(({ value }) => setParent(value), [setParent]);
  const fetchOptions = useCallback(
    async (q?: string, id?: ItemId): Promise<IProduct[]> => {
      const parentType = getParentType(tabToType[activeTab]);

      if (!parentType || !productId) {
        return [];
      }

      const additionalFilters: ISearchFilter[] = [
        ['ancestor_ids', 'eq', productId],
        ['type', 'eq', parentType],
      ];

      return queryProducts({ q, ...(id ? { ids: [id] } : {}) }, additionalFilters);
    },
    [productId, activeTab],
  );
  useEffect(() => {
    fetchOptions().then(([product]) => {
      setParent(product?.id);
    });
  }, [fetchOptions, setParent]);

  if (!activeTab || !productId || !value || hidden) {
    return <></>;
  }

  return (
    <FormProduct
      label="Select Parent Product"
      fetchOptions={fetchOptions}
      onChange={onChange}
      value={value}
      className="product-details__descendants-selector"
    />
  );
};

export default ProductDescendantsParentSelector;
