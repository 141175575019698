import React, { useEffect, useState } from 'react';
import { isValid } from 'date-fns';

import { DateCountTotalChart } from 'components/date-count-total-bar-chart';
import { DurationCountBarChart } from 'components/duration-count-bar-chart';
import PieChart from 'components/pie-chart';
import { AttendanceListTable } from 'components/attendance-list-table';
import './style.scss';
import { SectionHeader } from 'components/section-header';
import { getHistogramData } from 'utils/apis/analytics';
import { IHistogramData, ItemId } from 'types';

const AnalyticsInteractionsAttendance: React.FC<{
  entityId?: ItemId | ItemId[] | null;
  dateRange: [Date, Date];
  entity?: string;
  queries?: Record<string, string>;
}> = ({ entityId, dateRange }) => {
  const [countByUniqueVisitorsData, setCountByUniqueVisitorsData] = useState([] as IHistogramData[]);
  const [countByDurationData, setCountByDurationData] = useState([] as IHistogramData[]);
  const [countByGroupsData, setCountByGroupsData] = useState([] as IHistogramData[]);
  const [averageDuration, setAverageDuration] = useState('');
  useEffect(() => {
    if (!dateRange.every(isValid)) {
      return;
    }

    getHistogramData({
      entity: 'event',
      queryName: 'v3/events/attendance/count_by_uniq_visitors',
      entityId,
      dateRange,
    }).then(({ data }) => {
      setCountByUniqueVisitorsData(data);
    });

    getHistogramData({
      entity: 'event',
      queryName: 'v3/events/attendance/count_by_duration',
      entityId,
      dateRange,
    }).then(({ data }) => {
      setCountByDurationData(data);
    });

    getHistogramData({
      entity: 'event',
      queryName: 'v3/events/attendance/count_by_groups',
      entityId,
      dateRange,
    }).then(({ data }) => {
      setCountByGroupsData(data);
    });

    getHistogramData({
      entity: 'event',
      queryName: 'v3/events/attendance/average_duration',
      entityId,
      dateRange,
    }).then(({ data }) => {
      setAverageDuration(data[0]['readable_average_duration']);
    });
  }, [dateRange, entityId]);

  return (
    <>
      <div className="event-analytics-tab-attendance">
        <div className="event-analytics-tab-attendance__card">
          <SectionHeader title="Attendees" useBackground useBorder />
          <div className="event-analytics-tab-attendance__card-content">
            <DateCountTotalChart
              data={countByUniqueVisitorsData}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              showLegend
            />
          </div>
        </div>
        <div className="event-analytics-tab-attendance__card">
          <SectionHeader title="Duration of Attendance" useBackground useBorder />
          <div className="event-analytics-tab-attendance__card-content">
            <DurationCountBarChart data={countByDurationData} topRightText={`Average Duration: ${averageDuration}`} />
          </div>
        </div>
        <div className="event-analytics-tab-attendance__card">
          <SectionHeader title="Top Sub-Events" useBackground useBorder />
          <div className="event-analytics-tab-attendance__card-content">
            <PieChart data={countByGroupsData} aspectRatio={1} />
          </div>
        </div>
      </div>
      <div className="event-analytics-tab-attendance-list">
        <AttendanceListTable
          entity="event"
          title="Detailed Attendance"
          queryName="v3/events/attendance/visits_list"
          entityId={entityId}
          dateRange={dateRange}
        />
      </div>
    </>
  );
};

export default AnalyticsInteractionsAttendance;
