import { AmGenericNews } from '@mediafellows/mm3-types';
import { IShouldDisplayActionFunction } from 'utils/actions/types';
import { INewsActionName } from './types';
import { flags } from 'utils/flags';

type IShouldDisplayLocationsActionFunction = IShouldDisplayActionFunction<AmGenericNews, INewsActionName>;

export const shouldDisplayEdit: IShouldDisplayLocationsActionFunction = (action, index, user, entities, options) => {
  return options.context === 'single';
};

export const shouldDisplayManageShowcase: IShouldDisplayLocationsActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.context === 'single' && flags.showNewsManageShowcaseAction;
};
