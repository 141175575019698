import React from 'react';
import { IInputGroupProps } from '@blueprintjs/core';
import { ActionIcon, Input } from '@mantine/core';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { formatFormLabel } from 'helpers/form/fields/helpers';
import { useUploadImageDialog, IUploadType } from 'components/upload-preview-image';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

export interface IFormImageProps
  extends Omit<Omit<IInputGroupProps, 'value'>, 'onChange'>,
    IFieldData<string | null>,
    IFieldHandlers<string | null> {
  name: string;
  label?: string;
  type?: IUploadType;
  extensions?: string[] | null;
  fileLabel?: string;
  customMessage?: string;
  successMessage?: string;
}

export const FormFileInput: React.FC<IFormImageProps> = (props) => {
  const {
    onChange,
    name,
    value,
    label,
    required,
    type,
    touched,
    validation,
    extensions,
    disabled,
    fileLabel = 'document',
    customMessage = 'Select a document to upload',
    successMessage = 'Document is uploading',
  } = props;

  const showError = Boolean(touched && !validation?.valid);
  const errorMessage = (value || '').length > 1 ? validation?.errorMessage : 'This Field is required';

  const [openDialog] = useUploadImageDialog({
    type,
    extensions,
    customMessage,
    successMessage,
    onSubmit: (file) => {
      if (file?.url) {
        onChange?.({ [name]: file.url });
      }
    },
  });

  const onRemove = (): void => {
    onChange?.({ [name]: '' });
  };

  const title = (value ? `Change ` : 'Upload ') + fileLabel;

  return (
    <Input.Wrapper label={formatFormLabel(label, required)} error={showError ? errorMessage : ''}>
      <Input
        onClick={openDialog}
        value={value as string}
        disabled={disabled}
        placeholder={title}
        pointer
        rightSectionPointerEvents="all"
        rightSection={
          <>
            <MantineIcon icon="document" style={{ display: value ? 'none' : undefined }} />
            <ActionIcon variant="subtle" onClick={onRemove} style={{ display: value ? undefined : 'none' }}>
              <MantineIcon icon="cross" />
            </ActionIcon>
          </>
        }
      />
    </Input.Wrapper>
  );
};
