import React from 'react';
import cx from 'classnames';

import { formatField } from 'utils/general';
import { Classes } from 'utils/ui';
import { IExtendedProductCastCrew } from 'types';
import DateElement from 'components/date-list-element/date-list-element';

import './style.scss';

export interface IExtendedCastCrewAttributesProps {
  cast: IExtendedProductCastCrew;
}

export const ExtendedCastCrewListItemAttributes: React.FC<IExtendedCastCrewAttributesProps> = ({ cast }) => {
  const { created_at, updated_at, meta, cast_character, cast_role, crew_position } = cast;

  return (
    <div className={cx('extended-cast-crew-list-item-row__attributes', Classes.TEXT_MUTED)}>
      <div className="text-truncate extended-cast-crew-list-item-row__description">{meta?.description}</div>
      <div className="text-truncate extended-cast-crew-list-item-row__role">
        {formatField(cast_role || crew_position)}
      </div>
      <div className="text-truncate extended-cast-crew-list-item-row__character-name">
        {formatField(cast_character)}
      </div>
      <DateElement
        className="text-truncate extended-cast-crew-list-item-row__created"
        date={created_at}
        mfxIcon="created-at"
      />
      <DateElement
        className="text-truncate extended-cast-crew-list-item-row__updated"
        date={updated_at}
        mfxIcon="updated-at"
      />
    </div>
  );
};
