import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { Tooltip } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { IContact } from 'types';
import { UserAvatar } from 'components/user-avatar';
import { handleItemClick } from 'components/data-section/';
import { ContactAttributeList } from 'components/contact-list-item/contact-attributes-list';

import { renderContactNamePart } from 'components/contact-list-item/utils';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import './style.scss';

interface IContactsListItemProps {
  className?: string;
  contact: IContact;
  contextMenu: React.ReactElement;
}

const ContactListItem: React.FC<IContactsListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();
  const [itemChecked, setChecked] = useState(false);
  const { className, contact, contextMenu } = props;
  const { id, status } = contact;
  const isActive = active === id;

  useEffect(() => {
    const isChecked = checked.some((checked) => checked.id === id);

    setChecked(isChecked);
  }, [id, checked]);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('contact-list-item', className, {
        'contact-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(contact),
      })}
      onClick={handleClick}
    >
      <div className="contact-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="contact-list-item__thumb">
        <Tooltip label={status} position="bottom" offset={{ mainAxis: 8, crossAxis: 20 }} arrowOffset={40}>
          <div>
            <UserAvatar user={contact} shouldRefresh={false} />
          </div>
        </Tooltip>
      </div>

      <div className="contact-list-item__content">
        <div className="contact-list-item__title">
          {renderContactNamePart(contact)}
          <div className="contact-list-item__popover-container">{Boolean(contextMenu) && contextMenu}</div>
        </div>

        <ContactAttributeList contact={contact} />
      </div>
    </div>
  );
});

export default ContactListItem;
