import React from 'react';
import { IFormHandlers, IFormData } from 'helpers/form';

export interface INewsFormProps<T> {
  formData: IFormData<T>;
  handlers: Omit<IFormHandlers<T>, 'onSetFields'>;
}

type ICustomNewsProps<T = unknown> = React.FC<INewsFormProps<T>>;

export const CustomTargetNewsField: ICustomNewsProps = () => <></>;
export const CustomTitleNewsField: ICustomNewsProps = () => <></>;
export const CustomContentNewsField: ICustomNewsProps = () => <></>;
