import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';

import DateElement from 'components/date-list-element/date-list-element';
import { IBasketDataSectionWrappedComponent } from './types';

import startCase from 'lodash/startCase';

import { getEntityLabel } from 'utils/general';

import './style.scss';

export const BasketDataSectionListItem: React.FC<IBasketDataSectionWrappedComponent> = ({
  basket,
  contextMenu,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const { name, entityType, entityCount, owner, updatedAt } = basket;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div className="baskets-list-item product-baskets">
      <div className="default-baskets-list-item__checkbox baskets-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="default-baskets-list-item__title">
        <h4 className="text-nowrap text-truncate">{name}</h4>
      </div>
      <div className="default-baskets-list-item__assets">
        {entityCount} {startCase(getEntityLabel(entityType))}
      </div>
      <div>{owner}</div>
      <div className="text-nowrap text-truncate">
        <DateElement date={updatedAt} mfxIcon="updated-at" />
      </div>
      {Boolean(contextMenu) && contextMenu}
    </div>
  );
};
