import { useState, useEffect, useRef, useMemo } from 'react';
import { Uppy as IUppy } from '@uppy/core';
import { v4 as uuidv4 } from 'uuid';

import { initializeUppy } from 'components/upload-preview-image/api';

import { IFile } from 'types';
import { IUploadType } from 'components/upload-preview-image';

export const useUppyUpload = (
  type?: IUploadType,
  extensions?: string[],
): { progress: number; uploadFinished: boolean; dropAreaId: string; file } => {
  const [progress, setProgress] = useState(0);
  const [uploadFinished, setUploadFinished] = useState(false);
  const uppyRef = useRef<IUppy | null>(null);
  const uploadedFile = useRef<IFile | null>(null);

  const dropAreaId = useMemo(() => `upload-image-drop-area-${uuidv4()}`, []);

  useEffect(() => {
    initializeUppy({ target: dropAreaId, type, extensions }).then((uppy) => {
      uppy.on('upload-success', (file, response) => {
        if (!file?.id) {
          return;
        }
        setUploadFinished(true);
        uploadedFile.current = { id: file.id, url: response['uploadURL'], name: file.name, size: file.size };
      });

      uppy.on('progress', (progress) => {
        setProgress(Math.max(progress, 1));
      });
      uppy.on('upload', () => {
        setProgress(1);
      });

      uppyRef.current = uppy;
    });

    return () => {
      uppyRef.current?.close();
    };
  }, [dropAreaId, extensions, type]);

  return { progress, uploadFinished, dropAreaId, file: uploadedFile };
};
