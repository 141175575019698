import { IAudioTrack } from 'types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useEditAudioAction } from './edit';
import { IAudioActionName } from './types';

export const useAudioActions = (
  items: IAudioTrack[],
  options: IUseActionsOptionParam<IAudioTrack>,
): IUseActionsResponse<IAudioActionName> => {
  const editAction = useEditAudioAction(items, options);
  const actions = [editAction];

  return useActions<IAudioTrack, IAudioActionName>(actions, options, items);
};
