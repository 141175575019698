import React from 'react';

import { FormCategories, FormCheckbox, FormImage, FormInput, FormMultiSelect, FormSelect } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IWebsitePageValues } from 'types';

import { ShowcaseCommonForm, useShowcaseFormHandlers } from 'components/showcase-form/showcase-common-parts';
import { IGenericListCollectionForm } from './utils';

const CollectionFormFirstStep: React.FC<
  IUseMm3FormReturn<IGenericListCollectionForm> & {
    hideNextButton?: boolean;
    section?: IWebsitePageValues;
  }
> = ({ handlers, formData, onSubmit, valid, values, section, hideNextButton = false }) => {
  const { allowedItemTypes, onAllowedItemTypesChange } = useShowcaseFormHandlers<IGenericListCollectionForm>(
    true,
    values,
    formData,
    handlers,
  );

  return (
    <ShowcaseCommonForm
      handlers={handlers}
      formData={formData}
      onSubmit={onSubmit}
      valid={valid}
      values={values}
      hideNextButton={hideNextButton}
      section={section}
    >
      <FormCategories large name="meta.category_ids" label="Genres" {...formData?.meta?.category_ids} {...handlers} />
      <FormCheckbox
        name="sublist"
        label="Is Sub-Collection"
        disabled={values.allowed_item_types?.includes('list/collection')}
        {...formData?.sublist}
        {...handlers}
      />
      <FormMultiSelect
        large
        name="allowed_item_types"
        label="Allowed objects"
        {...handlers}
        onChange={onAllowedItemTypesChange}
        {...formData?.allowed_item_types}
        options={undefined}
        fetchValues={allowedItemTypes}
      />
      <FormSelect large name="access_level" label="Access level" {...handlers} {...formData?.access_level} />
      <FormInput textarea name="meta.description" label="Description" {...handlers} {...formData?.meta?.description} />
      <FormImage
        name="previewImageUrl"
        label="Preview Image"
        className="py-3"
        {...formData.previewImageUrl}
        {...handlers}
      />
    </ShowcaseCommonForm>
  );
};

export default CollectionFormFirstStep;
