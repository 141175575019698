import { map, omit } from 'lodash';

import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { getAssets } from 'utils/apis/asset';
import {
  IAsset,
  IGroup,
  IGroupEditForm,
  IProduct,
  ItemId,
  IIdentifiable,
  IFile,
  ISearchFilter,
  ISearchParams,
  IQueryParams,
  IGroupItem,
  IGroupAddContact,
  GroupTypes,
  IProductAncestryInfo,
  IContact,
  IGroupAssetItem,
  ISelection,
} from 'types';
import {
  groupListSchema,
  assetListSchema,
  contactListSchema,
  groupDetailsSchema,
  productListSchema,
} from 'utils/schemas';
import { fetchProductsAncestry, queryProducts } from 'utils/apis/product';
import { loadOrganizationsAllUsers } from 'utils/apis/organization';
import { IFilterOption } from 'utils/hooks/control-data-section';
import { ISortableItem } from 'store/sort-data-store';
import type { IGroupAccessChange } from 'components/group-access-change';
import { mapProductToAncestryInfo } from 'utils/product';
import { getAssetModel } from 'utils/asset';
import { ingestPreviewImage } from './preview-image';
import { ISelectionEntityType } from 'utils/actions/types';
import { chipmunk, IResult } from 'utils/chipmunk';
import { parseToSearchParams, by, rearrangeItems } from 'utils/general';
import { logger } from 'utils/logger';

type IGroupIds = ItemId | ItemId[];

export const fetchGroups = <T extends IGroup = IGroup>(filters: ISearchFilter[], schema?: string): Promise<T[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<T>(Model.GROUPS, 'search', {
      body: { search: { filters } },
      schema,
    });
    return objects;
  });
};

export const loadGroup = <T = IGroup>(groupId: IGroupIds, schema = groupDetailsSchema): Promise<T> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.GROUPS, 'get', {
      params: { group_ids: groupId },
      schema,
    });
    return object;
  });
};

export const loadGroups = <T extends IGroup = IGroup>(
  groupIds: ItemId[],
  schema = groupDetailsSchema,
): Promise<T[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.GROUPS, 'get', {
      params: { group_ids: groupIds },
      schema,
    });
    return objects;
  });
};

export const queryGroups = (
  params: IQueryParams | { search: { filters: [string, string, string | string[]][] } },
  additionalFilters: ISearchFilter[] = [],
  parseToSearch = parseToSearchParams,
  schema = groupListSchema,
): Promise<IGroup[]> => searchGroups(parseToSearch(params as IQueryParams, additionalFilters), schema);

export const searchGroups = (body: ISearchParams, schema = groupListSchema): Promise<IGroup[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.GROUPS, 'search', {
      body,
      schema,
    });

    return objects;
  });
};

export interface IFetchGroupItemsProps {
  group_ids: IGroupIds;
  target: Model;
  actionName?: string;
  per?: number;
  allItems?: boolean;
  schema?: string;
  sort?: string;
  order?: string;
}

export const loadGroupItems = async <T>({
  group_ids,
  target,
  actionName = 'search',
  per = 25,
  schema,
  allItems,
  sort,
  order,
}: IFetchGroupItemsProps): Promise<T[]> => {
  const params = { group_ids, sort, order };
  return chipmunk.run(async (chipmunk) => {
    const action = allItems ? 'unfurl' : 'action';
    const { objects } = await chipmunk[action](target, actionName, {
      params,
      body: { per },
      schema,
    });

    return objects;
  });
};

export const loadProductGroupItems = async ({
  group_ids,
  per = 25,
  allItems = false,
  withAncestryInfo = false,
  order,
  sort,
}: Omit<IFetchGroupItemsProps & { withAncestryInfo?: boolean }, 'target' | 'actionName'>): Promise<IProduct[]> => {
  const actionName = allItems ? 'unfurl' : 'action';
  let products;
  // to get products sorted by sequence number
  if (sort && order) {
    const orderedProducts = await loadGroupItems({
      group_ids,
      target: Model.PRODUCT_GROUP_ITEMS,
      sort,
      order,
      actionName: 'query',
      schema: `id, product_id, sequence_number`,
    });
    if (orderedProducts.length) {
      const orderedIds = orderedProducts.map(({ product_id }) => product_id);
      const result = await queryProducts({ per }, [['id', 'in', orderedIds]], parseToSearchParams, actionName);
      products = rearrangeItems(result, orderedIds);
    } else {
      products = [];
    }
  } else {
    products = await queryProducts(
      { per },
      [['group_ids', 'eq', group_ids as string]],
      parseToSearchParams,
      actionName,
    );
  }

  let ancestryInfos: IProductAncestryInfo[] = [];
  if (withAncestryInfo) {
    ancestryInfos = await fetchProductsAncestry(products.map((e) => e.id));
  }

  return mapProductToAncestryInfo(products, ancestryInfos);
};

export const loadOrganizationCountInGroup = async (group_id?: ItemId): Promise<number> => {
  if (!group_id) {
    return 0;
  }

  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.ORGANIZATIONS, 'search', {
      params: { per: 1 },
      body: { search: { filters: [['group_ids', 'eq', group_id]] } },
    });

    return result.pagination?.total_count || 0;
  });
};

export const loadAssetGroupItems = async ({
  schema = assetListSchema,
  ...props
}: Omit<IFetchGroupItemsProps, 'target' | 'actionName'>): Promise<IAsset[]> => {
  const assetGroupItems = await loadGroupItems({
    ...props,
    target: Model.ASSET_GROUP_ITEMS,
    schema: 'asset_id',
  });
  if (!assetGroupItems) return [];
  const assetIds = map(assetGroupItems, 'asset_id');

  return assetIds.length ? getAssets(assetIds, schema) : [];
};

const loadGroupProductThumbnails = async (group_id: ItemId): Promise<{ image: string; assetType: string }[]> => {
  if (!group_id) {
    return [];
  }
  // FIX ME: for some reason neither filter nor sorting by image do not work
  // it is worth to apply either inherited_preview_image!=null or sort
  // to reduce the payload.
  // Same goes for loadGroupAssetThumbnails
  const products = (
    await chipmunk.action(Model.PRODUCTS, 'search', {
      schema: 'inherited_preview_image,type',
      body: { per: 4, search: { filters: [['group_ids', 'eq', group_id]] } },
    })
  ).objects;

  return products
    .map((product) => ({
      image: product.inherited_preview_image?.url,
      assetType: product.type,
    }))
    .filter(({ image }) => image);
};

const loadGroupAssetThumbnails = async (group_id: ItemId): Promise<{ image: string; assetType: string }[]> => {
  if (!group_id) {
    return [];
  }

  const assets = (
    await chipmunk.action(getAssetModel(), 'search', {
      schema: 'preview_image',
      body: { per: 4, search: { filters: [['group_ids', 'eq', group_id]] } },
    })
  ).objects;

  return assets
    .map(({ preview_image, ['@type']: assetType }) => ({
      image: preview_image?.url,
      assetType,
    }))
    .filter(({ image }) => image);
};

export const loadGroupThumbnails = async ({
  group_id,
}: {
  group_id?: ItemId;
}): Promise<{ image: string; assetType: string }[]> => {
  if (!group_id) {
    return [];
  }

  const thumbnails = (
    await Promise.all([loadGroupProductThumbnails(group_id), loadGroupAssetThumbnails(group_id)])
  ).flat();

  return thumbnails;
};

export const setGroupPreviewImage = async (
  groupId: ItemId,
  file: Pick<IFile, 'url'>,
  oldPreviewId?: number,
): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    const object = await ingestPreviewImage(file.url, oldPreviewId);
    return action(Model.GROUPS, 'member.update', {
      body: {
        id: groupId,
        preview_image_id: object.id,
      },
    });
  });
};

export const removeGroupPreviewImage = (group: IGroup): Promise<void> | undefined => {
  const preview_image_id = group?.preview_image_id || group.preview_image?.id;
  if (!preview_image_id) return;
  return chipmunk.run(async ({ action }) => {
    try {
      const previewImagePromise = action(Model.PREVIEW_IMAGE, 'member.delete', {
        params: {
          preview_image_id,
        },
      });
      const groupPreviewPromise = action(Model.GROUPS, 'member.update', {
        body: {
          id: group.id,
          preview_image_id: null,
        },
      });
      Promise.all([previewImagePromise, groupPreviewPromise]);
    } catch (error) {
      logger.error(error);
      return false;
    }
  });
};

export const deleteGroups = async (groupIds: ItemId[]): Promise<IResult> => {
  return chipmunk.run(async ({ action }) =>
    action(Model.GROUPS, 'collection.delete', {
      params: { group_ids: groupIds },
    }),
  );
};

export const restoreGroups = async (groups: IIdentifiable[]): Promise<IResult> => {
  const group_ids = groups.map((g) => g.id);

  return chipmunk.run(async ({ action }) =>
    action(Model.GROUPS, 'collection.restore', {
      params: { group_ids },
    }),
  );
};

type IGroupSaveKey = 'event' | 'selection';

export interface IAddToGroupParams {
  item_ids?: Array<ItemId>;
  group_id?: ItemId;
  include_descendants?: boolean;
  include_future_descendants?: boolean;
  delegates_access?: boolean;
  permit_download?: boolean;
  permissions?: string[];
  addSelectedProductAncestors?: boolean;
}

export interface IRemoveGroupParams {
  group_ids?: ItemId[];
  item_ids?: Array<ItemId>;
}

export const addEventsToGroup = async ({ group_id, item_ids = [] }: IAddToGroupParams): Promise<void> => {
  if (!group_id || item_ids.length === 0) {
    return;
  }
  await chipmunk.run(async ({ action }) =>
    action(Model.EVENTS, 'member.update', {
      params: {
        group_id,
      },
      body: {
        id: group_id,
        subevent_group_ids: item_ids,
      },
    }),
  );
};

export const addAssetToGroup = async ({
  group_id,
  item_ids = [],
}: IAddToGroupParams): Promise<void | IGroupAssetItem[]> => {
  if (!group_id || item_ids.length === 0) {
    return;
  }
  const result = await chipmunk.run(async ({ action }) => {
    const firstItem = await fetchFirstGroupElement(Model.ASSET_GROUP_ITEMS, group_id);
    const firstItemSeqNum = firstItem?.sequence_number || 0;
    const nbrItems = item_ids.length;
    return action(`${Model.ASSET_GROUP_ITEMS}/asset`, 'create', {
      params: {
        group_id,
      },
      body: {
        items: item_ids.map((asset_id, index) => ({
          asset_id,
          access_level: 'viewable',
          permissions: ['download'],
          sequence_number: firstItem?.id ? firstItemSeqNum - nbrItems + index : index + 1,
        })),
        ROR: true,
      },
    });
  });
  return result?.objects;
};

export const removeAssetFromGroup = async ({
  group_ids,
  item_ids = [],
}: IRemoveGroupParams): Promise<void | IGroupAssetItem[]> => {
  if (!group_ids?.length || item_ids.length === 0) {
    return;
  }
  const result = await chipmunk.run(async ({ action }) =>
    action(`${Model.ASSET_GROUP_ITEMS}/asset`, 'delete', {
      params: {
        group_ids,
        item_ids,
      },
      ROR: true,
    }),
  );

  return result?.objects;
};

const getPermissions = (permissions?: string[], permit_download?: boolean): string[] | undefined => {
  if (typeof permit_download === 'undefined') {
    return permissions;
  }

  return permit_download ? ['download'] : [];
};

const fetchFirstGroupElement = async (target: Model, groupId: ItemId): Promise<IGroupItem> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(target, 'query', {
      params: {
        group_ids: groupId,
        sort: ParametronSort.SEQUENCE_NUMBER,
        order: ParametronOrder.ASCENDING,
        per: 1,
        page: 1,
      },
    });

    return object;
  });
};

export const addProductToGroup = async ({
  group_id,
  item_ids = [],
  include_descendants,
  include_future_descendants = false,
  delegates_access,
  permissions = ['download'],
  permit_download,
  addSelectedProductAncestors = false,
}: IAddToGroupParams): Promise<void> => {
  if (!group_id || item_ids.length === 0) {
    return;
  }

  await chipmunk.run(async ({ action }) => {
    const firstItem = await fetchFirstGroupElement(Model.PRODUCT_GROUP_ITEMS, group_id);

    const firstItemSeqNum = firstItem?.sequence_number || 0;
    const nbrItems = item_ids.length;
    return action(`${Model.PRODUCT_GROUP_ITEMS}/product`, 'create', {
      params: { group_id },
      body: {
        items: item_ids.map((product_id, index) => ({
          product_id,
          type: 'group/item/product',
          access_level: 'viewable',
          permissions: getPermissions(permissions, permit_download),
          include_descendants,
          include_future_descendants,
          delegates_access,
          sequence_number: firstItem?.id ? firstItemSeqNum - nbrItems + index : index + 1,
        })),
      },
      ROR: false,
    });
  });

  if (addSelectedProductAncestors) {
    await addParentProductsToGroup({ group_id, item_ids });
  }
};

export const loadPropsFromExistingProductItems = async (
  group_id: ItemId,
  product_ids?: ItemId[],
): Promise<{ delegates_access: boolean; permissions: string[]; include_descendants: boolean }> => {
  const { objects } = await chipmunk.run(({ action }) => {
    const filters: IFilterOption[] = [];
    if (Array.isArray(product_ids) && product_ids.length > 0) {
      filters.push(['product_id', 'in', product_ids]);
    }

    return action(Model.PRODUCT_GROUP_ITEMS, 'search', {
      params: {
        group_ids: group_id,
      },
      body: {
        per: 1,
        search: { filters },
      },
    });
  });

  if (!objects?.length) {
    return {
      include_descendants: false,
      delegates_access: false,
      permissions: [],
    };
  }

  const product = objects[0];

  return {
    delegates_access: Boolean(product.delegates_access),
    permissions: product.permissions ?? [],
    include_descendants: Boolean(product.include_descendants),
  };
};

export const addContactToGroup = async ({ group_id, item_ids = [] }: IAddToGroupParams): Promise<void> => {
  if (!group_id || item_ids.length === 0) {
    return;
  }

  await chipmunk.run(async ({ action }) =>
    action(Model.GROUPS, 'add_users', {
      params: {
        group_id,
        user_ids: item_ids,
      },
    }),
  );
};

export const saveGroup = async <T = IGroup>(
  key: IGroupSaveKey,
  originalValues: Partial<IGroupEditForm>,
): Promise<IResult<T>> => {
  // when preview image is in processing state we don't have url and so the group_preview_image_url will be empty
  // but the preview_image_id will have a value alread and we don't want to remove the image which is in processing state
  const shouldRemovePreview = Boolean(
    !originalValues.group_preview_image_url && originalValues.preview_image_id && originalValues.preview_image?.url,
  );

  const values = {
    ...originalValues,
    // this is to remove preview image
    preview_image_id: shouldRemovePreview ? null : originalValues.preview_image_id,
  };

  const isNewGroup = !values.id;

  const result = await chipmunk.run(async ({ action }) => {
    if (!values.id) {
      return action(Model.GROUPS, 'create', {
        body: {
          type: `group/${key}`,
          ...values,
        },
      });
    }

    const updatedEvent = omit(values, ['group_parent_group_id', 'subevent', 'group_ids']);
    return action(Model.GROUPS, 'member.update', {
      params: {
        id: values.id,
      },
      body: updatedEvent,
    });
  });

  const group = result.object;

  if (isNewGroup && values.products_item_ids?.length) {
    await addProductToGroup({
      group_id: group.id,
      item_ids: values.products_item_ids,
      include_descendants: values.products_include_descendants,
      delegates_access: values.products_delegates_access,
    });
  }

  if (isNewGroup && values.assets_item_ids?.length) {
    await addAssetToGroup({ group_id: group.id, item_ids: values.assets_item_ids });
  }

  if (isNewGroup && values?.contacts_item_ids?.length) {
    await addContactToGroup({ group_id: group.id, item_ids: values.contacts_item_ids });
  }
  // we don't need to update the same image over and over again
  if (values.group_preview_image_url && values.preview_image?.url !== values.group_preview_image_url) {
    await setGroupPreviewImage(group.id, { url: values.group_preview_image_url });
  }

  // works only for newly created events, not editable for existing events
  if (isNewGroup && values.group_parent_group_id) {
    await addEventsToGroup({ group_id: values.group_parent_group_id, item_ids: [group.id] });
  }

  return result;
};

export const loadGroupContacts = async (ids: ItemId[]): Promise<IContact[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action<IContact>(Model.CONTACT_GROUP_SET, 'query_users', {
      params: { group_ids: ids },
      schema: contactListSchema,
    });
    return objects;
  });
};

export async function updateGroup<T extends Partial<IGroup>>(
  body: Partial<T>,
  schema?: string,
): Promise<T | undefined> {
  if (!body?.id) {
    return;
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action<T>(Model.GROUPS, 'member.update', {
      params: { group_ids: body.id },
      body,
      schema,
    });

    return object;
  });
}

export const queryProductGroupItems = (group_id: ItemId, product_ids: ItemId[]): Promise<IResult> => {
  return chipmunk.run(({ action }) => {
    return action(Model.PRODUCT_GROUP_ITEMS, 'search', {
      params: {
        group_ids: group_id,
      },
      body: {
        search: {
          filters: [
            ['product_id', 'in', product_ids],
            ['group_id', 'eq', group_id],
          ],
        },
      },
    });
  });
};

export const removeContactsFromGroup = (group_id: ItemId, contactIds: ItemId[]): Promise<IResult> => {
  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'member.remove_users', {
      params: { group_id, user_ids: contactIds },
    });
  });
};

export const removeOrganizationsFromGroup = (group_id: ItemId, ids: ItemId[]): Promise<IResult> => {
  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'member.remove_organizations', {
      params: { group_id, organization_ids: ids },
    });
  });
};

export const queryAssetGroupItems = (group_id: ItemId, asset_ids: ItemId[]): Promise<IResult> => {
  return chipmunk.run(({ action }) => {
    return action(Model.ASSET_GROUP_ITEMS, 'search', {
      params: {
        group_ids: group_id,
      },
      body: {
        search: {
          filters: [
            ['asset_id', 'in', asset_ids],
            ['group_id', 'eq', group_id],
          ],
        },
      },
    });
  });
};

export const loadAllGroupItems = async (
  groupIds: undefined | ItemId | ItemId[],
  objectId: 'asset_id' | 'product_id',
): Promise<ISortableItem[]> => {
  if (!groupIds) {
    return [];
  }

  const group_ids = Array.isArray(groupIds) ? groupIds : [groupIds];

  if (group_ids.length === 0) {
    return [];
  }

  let model: {
    groupItem: Model;
    objects: Model;
    schema: string;
  } = {
    groupItem: Model.ASSET_GROUP_ITEMS,
    objects: getAssetModel(),
    schema: assetListSchema,
  };

  if (objectId === 'product_id') {
    model = {
      groupItem: Model.PRODUCT_GROUP_ITEMS,
      objects: Model.PRODUCTS,
      schema: `${productListSchema}, ancestry_info`,
    };
  }

  return chipmunk.run(async ({ unfurl }) => {
    const objectsPromise = unfurl(model.objects, 'search', {
      body: { search: { filters: [['group_ids', 'in', group_ids]] } },
      schema: model.schema,
    });
    const groupItemsPromise = unfurl(model.groupItem, 'query', {
      params: { sort: 'sequence_number', group_ids },
    });

    const { objects: groupItems } = await groupItemsPromise;
    const { objects } = await objectsPromise;
    const objectsById = by(objects, 'id');
    let ancestryInfos;
    let ancestryByProductId = {};
    if (objectId === 'product_id' && objects.length > 0) {
      ancestryInfos = (
        await unfurl('pm.product/ancestry_info', 'get', {
          params: { product_ids: objects.map((obj) => obj.id) },
        })
      ).objects;
      ancestryByProductId = by(ancestryInfos, objectId);
    }

    return (groupItems as IGroupItem[]).reduce((acc, groupItem) => {
      const value = groupItem[objectId];
      const object = objectsById[value];
      if (!object) {
        return acc;
      }
      return [
        ...acc,
        {
          ...object,
          sequence_number: groupItem.sequence_number,
          groupItemId: groupItem.id,
          ...(objectId === 'product_id' && {
            ancestry_info: ancestryByProductId[object.id],
          }),
        },
      ];
    }, []);
  });
};

export const updateGroupItemsSequenceNumber = (
  model: Model,
  group_ids,
  items: ISortableItem[],
): Promise<ISortableItem[]> => {
  return chipmunk.run(({ action }) => {
    return action(model, 'update', {
      params: { group_ids, item_ids: items.map((item) => item.groupItemId) },
      body: items.map((item, index) => ({ id: item.groupItemId, sequence_number: index })),
      multi: true,
    });
  });
};

export const changeGroupAccessImmediate = async (
  data: { item_ids: (number | string)[]; access_level?: string },
  schema: string,
): Promise<IResult> => {
  const group_ids = data.item_ids;
  const groups = group_ids.map((group_id) => ({
    id: group_id,
    access_level: data.access_level,
  }));
  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'update', {
      params: { group_ids },
      schema,
      body: groups,
      multi: true,
    });
  });
};

type IAddDifferentContactEntitiesToGroup = (params: {
  group_id: ItemId;
  contacts?: IGroupAddContact[];
}) => Promise<void[]>;

interface IParseDifferentContactsResult {
  users: ItemId[];
  selections: ItemId[];
  organizations: ItemId[];
  selections_users_count: number;
  organizations_users_count: number;
}

export const parseDifferentContacts = (
  contacts: (IGroupAddContact | { id: ItemId; ['@type']?: string; users_count?: number })[] = [],
): IParseDifferentContactsResult => {
  return contacts.reduce(
    (acc, contact) => {
      switch (contact['type']) {
        case 'contact':
          acc.users.push(contact.id);
          break;
        case 'contact-selection':
          acc.selections.push(contact.id);
          acc.selections_users_count += (contact['item'] as IGroup)?.users_count ?? 0;
          break;
      }

      switch (contact['@type']) {
        case 'user':
          acc.users.push(contact.id);
          break;
        case GroupTypes.SELECTION:
          acc.selections.push(contact.id);
          acc.selections_users_count += contact['users_count'] ?? 0;
          break;
      }

      return acc;
    },
    {
      users: [],
      selections: [],
      selections_users_count: 0,
      organizations: [],
      organizations_users_count: 0,
    } as IParseDifferentContactsResult,
  );
};

export const addDifferentContactEntitiesToGroup: IAddDifferentContactEntitiesToGroup = ({
  group_id,
  contacts = [],
}) => {
  const entities = parseDifferentContacts(contacts);

  return Promise.all([
    addContactToGroup({ group_id, item_ids: entities.users }),
    addContactSelectionsToGroup({ group_id, item_ids: entities.selections }),
    addOrganizationsToGroup({ group_id, item_ids: entities.organizations }),
  ]);
};

export const loadGroupsUsers = async (group_ids?: ItemId[]): Promise<IIdentifiable[]> => {
  if (!group_ids || group_ids.length === 0) {
    return [];
  }

  const result = await chipmunk.run(({ action }) =>
    action('um.user', 'search', { body: { per: 1000, search: { filters: [['group_ids', 'in', group_ids]] } } }),
  );

  return result.objects;
};

export const loadGroupsOrganizationsUsers = async (group_ids: ItemId[]): Promise<IIdentifiable[]> => {
  if (!group_ids.length) {
    return [];
  }

  const organizations = (
    await chipmunk.run(({ action }) => action('um.group', 'query_organizations', { params: { group_ids } }))
  ).objects;

  if (!organizations.length) {
    return [];
  }

  const orgIds = organizations.map(({ id }) => id);

  const users = loadOrganizationsAllUsers({ organization_ids: orgIds });

  return users;
};

export const addContactSelectionsToGroup = async ({
  group_id,
  item_ids,
}: {
  group_id: ItemId;
  item_ids: ItemId[];
}): Promise<void> => {
  const users = await loadGroupsUsers(item_ids);
  return addContactToGroup({ group_id, item_ids: users.map((u) => u.id) });
};

export const addOrganizationsToGroup = async ({
  group_id,
  item_ids,
}: {
  group_id: ItemId;
  item_ids: ItemId[];
}): Promise<void> => {
  if (item_ids.length === 0) {
    return;
  }
  return chipmunk.run(({ action }) =>
    action(Model.GROUPS, 'member.add_organizations', { params: { group_id, organization_ids: item_ids } }),
  );
};

export interface IGroupNameEditFormFields {
  name: string;
  description?: string;
}

export const editGroupInfo = async (
  groupId: ItemId,
  newGroup?: IGroupNameEditFormFields,
  schema?: string,
): Promise<IResult> => {
  return chipmunk.action(Model.GROUPS, 'member.update', {
    params: { group_ids: groupId },
    body: {
      id: groupId,
      name: newGroup?.name,
      description: newGroup?.description,
    },
    schema,
  });
};

export interface IGroupExpiryFormFields {
  expires_at: string;
}

export const editGroupExpiry = async (
  groupId?: ItemId,
  newGroup?: IGroupExpiryFormFields,
  schema = groupListSchema,
): Promise<IResult | undefined> => {
  if (!groupId) return;
  return chipmunk.action(Model.GROUPS, 'member.update', {
    params: { group_ids: groupId },
    body: {
      id: groupId,
      expires_at: newGroup?.expires_at,
    },
    schema,
  });
};

export const loadGroupProducts = ({
  group_ids,
  product_ids,
  per = 25,
  page = 1,
}): Promise<{ id: ItemId; group_id: number }[]> => {
  return chipmunk.run(async ({ action }) => {
    const filters = [['group_id', 'in', group_ids]];

    if (Array.isArray(product_ids) && product_ids.length > 0) {
      filters.push(['product_id', 'in', product_ids]);
    }

    const result = await action('pm.group/item', 'search', {
      params: { group_ids },
      body: {
        per,
        page,
        search: {
          filters,
        },
      },
    });

    return result.objects;
  });
};

export const updateGroupProduct = ({ group_id, item_id, data }): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    await action('pm.group/item', 'member.update', {
      params: {
        group_id,
        item_id,
      },
      body: data,
    });
  });
};

export const groupItemsAccessChange = async (
  data: IGroupAccessChange,
  schema = groupListSchema,
  skipUpdateGroupProducts?: boolean,
): Promise<IResult | undefined> => {
  const { group_ids } = data;
  if (!skipUpdateGroupProducts) {
    await updateGroupProducts(data);
  }
  return await editGroupExpiry(
    group_ids[0],
    {
      expires_at: data.expires_at,
    },
    schema,
  );
};

export const updateGroupProducts = async (data: {
  permit_download: boolean;
  group_ids: ItemId[];
  delegates_access: boolean;
  product_ids?: ItemId[];
}): Promise<void> => {
  const permissions = data.permit_download ? ['download'] : [];

  let page = 1;
  while (true) {
    const objects = await loadGroupProducts({
      group_ids: data.group_ids,
      product_ids: data.product_ids,
      per: 1000,
      page: page++,
    });

    if (objects.length === 0) {
      break;
    }

    for (const o of objects) {
      updateGroupProduct({
        group_id: o.group_id,
        item_id: o.id,
        data: {
          delegates_access: data.delegates_access,
          permissions,
        },
      });
    }
  }
};

export const addParentProductsToGroup = async ({ group_id, item_ids }): Promise<void> => {
  if (!group_id || !item_ids || item_ids.length === 0) {
    return;
  }

  const infos = await fetchProductsAncestry(item_ids);

  const parent_product_ids = infos.map(({ ancestors }) => ancestors.map(({ product_id }) => product_id)).flat();

  return addProductToGroup({
    group_id,
    item_ids: parent_product_ids,
    delegates_access: false,
    include_descendants: false,
    include_future_descendants: false,
    permissions: [],
  });
};

export const cloneGroup = async ({ id, owner_id }: IGroup): Promise<IResult | void> => {
  if (!id) {
    return;
  }

  return chipmunk.run(({ action }) => {
    return action(Model.GROUPS, 'member.clone', {
      params: { group_id: id },
      body: { id, owner_id },
    });
  });
};

export interface IParseGroupAssetsResult {
  assetIds: ItemId[];
  assetSelectionIds: ItemId[];
  selectionAssetsCount: number;
}
export type IGroupAddAsset = IAsset | (IGroup & IIdentifiable);

export const parseGroupAssets = (
  assets: (IGroupAddAsset | { id: ItemId; ['@type']?: string })[] = [],
): IParseGroupAssetsResult => {
  return assets.reduce<IParseGroupAssetsResult>(
    (acc, asset) => {
      switch (asset['@type']) {
        case GroupTypes.SELECTION:
          acc.assetSelectionIds.push(asset.id);
          acc.selectionAssetsCount += (asset as IGroup)?.am_statistics_data?.count ?? 0;
          break;
        default:
          acc.assetIds.push(asset.id);
          break;
      }
      return acc;
    },
    {
      assetIds: [],
      assetSelectionIds: [],
      selectionAssetsCount: 0,
    },
  );
};

export const addAssetSelectionsToGroup = async ({
  group_id,
  item_ids,
}: {
  group_id: ItemId;
  item_ids: ItemId[];
}): Promise<void | IGroupAssetItem[]> => {
  if (!item_ids?.length || !group_id) {
    return;
  }

  const selectionAssets = await loadGroupItems<IGroupAssetItem>({
    group_ids: item_ids,
    allItems: true,
    target: Model.ASSET_GROUP_ITEMS,
  });

  return addAssetToGroup({ group_id, item_ids: selectionAssets.map((u) => u.asset_id) });
};

export const addAssetEntitiesToGroup = ({
  group_id,
  contacts = [],
}: {
  group_id: ItemId;
  contacts: (IAsset | ISelection)[];
}): Promise<[void | IGroupAssetItem[], void | IGroupAssetItem[]]> => {
  const entities = parseGroupAssets(contacts);

  return Promise.all([
    addAssetToGroup({ group_id, item_ids: entities.assetIds }),
    addAssetSelectionsToGroup({ group_id, item_ids: entities.assetSelectionIds }),
  ]);
};

export const querySelections = <T extends IGroup = IGroup>(
  params: IQueryParams,
  filters: ISearchFilter[],
  entityType: ISelectionEntityType,
): Promise<T[]> => {
  return chipmunk.run(async ({ action }) => {
    const body = parseToSearchParams(params, [
      ['type', 'eq', GroupTypes.SELECTION],
      ['main_entity_type', 'eq', entityType],
      ...filters,
    ]);
    const { objects } = await action(Model.SELECTION, 'search', {
      body,
      schema: `name, id, updated_at, access_level, main_entity_type, users_count`,
    });

    return objects;
  });
};
