import { IItemId } from 'components/form-selection-items';
import { UmGenericListItem, UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';
import { action, observable, computed } from 'mobx';

import { IProduct, IAsset, ItemId, IEntity, IExtendedProductCastCrew, IMarketingEvent } from 'types';
import { Model } from 'helpers/filters/types';

export type ISortableItem = {
  groupItemId?: number;
  sequence_number: number;
  isRemoved?: boolean;
  item?: UmGenericListItem;
  entity?: IEntity;
  id?: ItemId;
} & Partial<
  IProduct | IAsset | UmGenericListCollection | UmGenericListShowcase | IExtendedProductCastCrew | IMarketingEvent
>;

interface IUpdateListParams {
  withCustomSorter?: boolean;
  from: number;
  to: number;
}

export interface ISortDataSectionStore<T> {
  list: T[];
  objectId: 'asset_id' | 'product_id';
  groupId: string;
  model: Model;
  loadList: () => void;
  updateStore: (list: T[], options?: IUpdateListParams) => void;
  onSave: (list: T[]) => Promise<void | null>;
  fetchItems: () => Promise<T[]>;
}

type IParseUpdatedList<T> = (list: T[], from: number, to: number) => T[];
function defaultListParser<T>(list: T[]): T[] {
  return list;
}

interface IInitStoreParams<T> {
  fetcher: () => Promise<T[]>;
  onSave: (list?: T[]) => Promise<void | null>;
  updateParser?: IParseUpdatedList<T>;
}

const initialValues = {
  fetcher: async () => [],
  onSave: async () => null,
  updateParser: defaultListParser,
};

export class SortDataSectionStore<T extends { id?: IItemId | null } = ISortableItem>
  implements ISortDataSectionStore<T>
{
  fetchItems: () => Promise<T[]>;
  onSave: (list?: T[]) => Promise<void | null>;
  @observable parseUpdatedList?: IParseUpdatedList<T>;
  @observable public list: T[] = [];
  @observable groupId: string;
  @observable model: Model;
  @observable objectId: 'asset_id' | 'product_id';
  @observable isLoading: boolean;
  @observable activeId: ItemId | null;

  @action.bound
  initStore({ fetcher, onSave, updateParser }: IInitStoreParams<T>): void {
    this.parseUpdatedList = updateParser;
    this.fetchItems = fetcher;
    this.onSave = onSave;
    this.loadList();
  }

  @action.bound
  async loadList(): Promise<void> {
    this.isLoading = true;
    this.activeId = null;
    const list = await this.fetchItems();
    this.updateStore(list);
    this.isLoading = false;
  }

  @action.bound
  updateStore(list: T[], opts?: IUpdateListParams): void {
    if (opts?.withCustomSorter && this.parseUpdatedList) {
      this.list = this.parseUpdatedList(list, opts.from, opts.to);
    } else {
      this.list = list;
    }
  }

  @action.bound
  updateActive(item: T | null): void {
    this.activeId = item?.id || null;
  }

  @computed get active(): T | undefined {
    if (!this.activeId) {
      return undefined;
    }

    return this.list.find(({ id }) => id === this.activeId);
  }

  clear = (): void => {
    this.initStore(initialValues);
  };

  @action.bound
  save(): Promise<void | null> {
    return this.onSave(this.list);
  }
}
