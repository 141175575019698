import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { useAssetsUploadToSelectionStore } from 'store/hooks';

export interface IAssetsFormFooter {
  onSubmit: () => void;
  onCancel: () => void;
}

export const AssetsFormFooter: React.FC<IAssetsFormFooter> = observer(({ onSubmit, onCancel }) => {
  const { files, disableSubmit } = useAssetsUploadToSelectionStore();

  if (files.length === 0) {
    return null;
  }

  return (
    <div className="upload-assets-form-footer__wrapper">
      <Button onClick={onCancel} variant="default" className="upload-assets-form-footer__button-upload">
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        disabled={disableSubmit}
        variant="primary"
        className="upload-assets-form-footer__button-upload"
      >
        Upload
      </Button>
    </div>
  );
});

export default React.memo(AssetsFormFooter);
