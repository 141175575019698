import { compact } from 'lodash';
import { chipmunk } from 'utils/chipmunk';
import { analyticsContactSchema } from 'utils/schemas/contact';
import { byId } from 'utils/general';
import { getContacts } from 'utils/apis/contacts';
import { IAnalyticsDateParams, IAsset, IProduct, IContact, IUserAgent, ItemId } from 'types';
import { IChipmunk } from '@mediafellows/chipmunk';
import { Model } from 'helpers/filters/types';

interface IGetRecommendationReceivedTableDataParams extends IAnalyticsDateParams {
  page: number;
  per: number;
  entityId?: ItemId | null;
  entity?: string;
  query_name?: string;
  user_ids?: Array<string>;
  asset_ids?: Array<string>;
  include_all_users?: boolean;
}

interface IExportRecommendationReceivedTableDataParams extends IAnalyticsDateParams {
  entityId?: ItemId | null;
  entity?: string;
  query_name?: string;
  user_ids?: Array<string>;
}

export interface IRecommendationReceivedTableItem {
  id: string;
  name: string;
  owner: IContact;
  organization: {
    id: string;
    name: string;
  };
  country?: string;
  assets: IAsset & { type: string };
  products: IProduct & { type: string };
  user_agent: IUserAgent;
  created_at: string;
  expires_at: string;
  am_statistics_data: number;
  pm_statistics_data: number;
  views_granted: number;
  require_login: boolean;
  responsible_user: {
    id: string;
    uuid: string;
    email: string;
    full_name: string;
  };
  last_viewed_at: string | number | Date;
  downloads?: string;
}

export interface IRecommendationReceivedTableData {
  data: IRecommendationReceivedTableItem[];
  page: number;
  per: number;
  total_count: number;
  total_pages: number;
}

async function fetchExtraUserInfo(contactsWithIds: string[]): Promise<{ [id: string]: IContact }> {
  if (!contactsWithIds?.length) {
    return {};
  }
  // @todo replace with a search query to not fail when a contact is deleted / or doesn't exist anymore in the system
  const contacts = await getContacts({ contactIds: contactsWithIds, schema: analyticsContactSchema });
  if (!contacts) {
    return {};
  }
  return byId(contacts);
}

export function getRecommendationReceivedTableData(
  params: IGetRecommendationReceivedTableDataParams,
): Promise<IRecommendationReceivedTableData> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { entity, entityId, query_name, from_date, to_date, user_ids, asset_ids, page, per, include_all_users } =
      params;
    const {
      object: { data },
      pagination,
    } = await action(Model.ANALYTICS, 'member.show', {
      params: {
        [`${entity}_ids`]: entityId,
        query_name,
        from_date,
        to_date,
        user_ids: compact(user_ids),
        asset_ids: compact(asset_ids),
        include_all_users,
        page,
        per,
      },
      schema: 'data',
    });
    const { total_count = 0 } = pagination || {};

    const contactsWithIds = data?.reduce((acc, { user }) => (user.id !== '0' ? [...acc, user.id] : acc), []);
    const idToUserMap = fetchExtraUserInfo(contactsWithIds);

    if (!data?.length) {
      return {
        data: [],
        page,
        per,
        total_count: 0,
        total_pages: 0,
      };
    }

    return {
      data: data.map((item) => {
        if (item.id !== '0' && idToUserMap[item.id]) {
          return Object.assign({}, item, { user: { ...item.user, ...idToUserMap[item.id] } });
        } else {
          const first_name = item.user.full_name.trim().split(' ')[0];
          const last_name = item.user.full_name.substring(first_name.length).trim();
          return Object.assign({}, item, { user: { ...item.user, first_name, last_name } });
        }
      }),
      page,
      per,
      total_count,
      total_pages: Math.ceil(total_count / per),
    };
  });
}

export function exportRecommendationReceivedTableData(
  params: IExportRecommendationReceivedTableDataParams,
): Promise<string[]> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    const { entity, entityId, query_name, from_date, to_date, user_ids } = params;

    const {
      object: { data },
    } = await action(Model.ANALYTICS, 'collection.export', {
      body: {
        [`${entity}_ids`]: entityId,
        query_name: `${query_name}_export`,
        user_ids: compact(user_ids),
        from_date,
        to_date,
      },
    });

    return data;
  });
}
